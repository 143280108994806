import { Icon } from "@@/shared/icons/icon";
import { useTheme } from "@emotion/react";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { RemSize } from "@towni/common";
import React from "react";
import { SquashData } from "./hz-item-data";

type Props = {
    data: SquashData;
    index: number;
};
const HzSquashItem = React.forwardRef<HTMLDivElement, Props>(
    (props, elementRef) => {
        const { data, index } = props;
        const theme = useTheme();
        return (
            <div
                css={{
                    width: 48,
                }}
                ref={elementRef}>
                <Icon
                    key={data._type + index}
                    icon={faEllipsisH}
                    padding={{ leftRight: 10, top: 20 }}
                    color={theme.colors.black.light80}
                    size={2 as RemSize}
                />
            </div>
        );
    }
);

export { HzSquashItem };
