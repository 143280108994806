import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Conditional } from "@@/shared/conditional";
import { FlexColumn } from "@@/shared/flex-containers";
import { useTheme } from "@emotion/react";
import { Ticket, translation, zeroPrice } from "@towni/common";
import { TicketCustomerViewStatus } from "./ticket-customer-view-status";
import { TicketGiftCardCustomerView } from "./ticket-customer-view/giftcard/ticket-giftcard-customer-view";
import { TicketCustomerView } from "./ticket-customer-view/voucher/ticket-customer-view";

type Props = {
    ticket: Ticket;
};

const TicketForCustomer = ({ ticket }: Props) => {
    const theme = useTheme();
    const image = ticket.meta.images[0];
    const [provider, _] = useProvider(ticket.providerId);
    const backgroundColor1 = theme.colors.primary.main.light40.asString;
    const backgroundColor2 = theme.colors.primary.asString;

    const _isGiftCard = ticket.type === "GIFTCARD";

    return (
        <FlexColumn css={{ position: "relative" }}>
            <Conditional
                when={ticket.status === "USED"}
                render={() => (
                    <TicketCustomerViewStatus
                        backgroundColor={theme.colors.white}
                        title={translation({
                            sv: "Värdebevis använt ❤️",
                            en: "Voucher used ❤️",
                        })}
                        color={backgroundColor1}
                        subtitle={translation({
                            sv: "Vilken härlig dag det här kommer att bli!",
                            en: "It's a great day to be alive!",
                        })}
                        subtitleColor={theme.colors.black}
                    />
                )}
            />
            <Conditional
                when={ticket.status === "CANCELLED"}
                render={() => (
                    <TicketCustomerViewStatus
                        backgroundColor={theme.colors.danger}
                        title={translation({
                            sv: "Värdebevis annullerat",
                            en: "Voucher cancelled",
                        })}
                        color={theme.colors.white}
                    />
                )}
            />
            <Conditional
                when={ticket.status === "EXPIRED"}
                render={() => (
                    <TicketCustomerViewStatus
                        backgroundColor={theme.colors.white}
                        title={translation({
                            sv: "Giltighetstiden har passerats",
                            en: "Expiry date has passed",
                        })}
                        color={theme.colors.danger}
                    />
                )}
            />

            <Conditional when={_isGiftCard}>
                <TicketGiftCardCustomerView
                    key={ticket._id}
                    title={ticket.meta.title}
                    subtitle={ticket.meta.subtitle}
                    description={ticket.meta.description}
                    backgroundImage={image}
                    logo={provider?.logoImageRef}
                    code={ticket.code}
                    link={ticket.shortLink}
                    contentBlocks={ticket.contentBlock}
                    optionals={ticket.optionals}
                    provider={provider}
                    used={ticket.status !== "ACTIVE"}
                    colorBehind={backgroundColor2}
                    amount={
                        ticket.type === "GIFTCARD" ? ticket.amount : zeroPrice
                    }
                    amountUsed={
                        ticket.type === "GIFTCARD"
                            ? ticket.amountUsed
                            : zeroPrice
                    }
                />
            </Conditional>

            <Conditional when={!_isGiftCard}>
                <TicketCustomerView
                    key={ticket._id}
                    title={ticket.meta.title}
                    subtitle={ticket.meta.subtitle}
                    description={ticket.meta.description}
                    backgroundImage={image}
                    logo={provider?.logoImageRef}
                    code={ticket.code}
                    link={ticket.shortLink}
                    contentBlocks={ticket.contentBlock}
                    optionals={ticket.optionals}
                    provider={provider}
                    used={ticket.status !== "ACTIVE"}
                    colorBehind={backgroundColor2}
                />
            </Conditional>
        </FlexColumn>
    );
};

export { TicketForCustomer };
