import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { useDebugEnabled } from "@@/settings/debug/debug-toggle";
import { Permission, ProviderId, emptyArrayOf } from "@towni/common";
import {
    UseHasProviderScopeInput,
    useHasAlfredScope,
    useHasProviderScope,
} from "./me-context";

type Props = {
    readonly className?: string;
    readonly children?: React.ReactNode;
    readonly noPadding?: boolean;
    readonly noBackgroundColor?: boolean;
    readonly radius?: number;
    /**
     * Only shows children when the user has the alfred scope.
     * Or when the user has the scope and permission required
     * for the current provider in context.
     * @type {[
     *         providerId: ProviderId,
     *         permission: Permission
     *     ][]}
     */
    readonly orProviderScope?: [
        providerId: ProviderId,
        permission: Permission,
    ][];
};
const WithAlfred = (props: Props) => {
    const [debugEnabled] = useDebugEnabled();
    const hasAlfred = useHasAlfredScope();
    const currentProvider = useProviderFromContext({
        disableThrowWhenMissingContext: true,
    });
    const hasProviderScope = useHasProviderScope(
        props.orProviderScope?.filter(([providerId]) => {
            return currentProvider && providerId === currentProvider._id;
        }) ?? emptyArrayOf<UseHasProviderScopeInput>(),
    );

    if (!debugEnabled) return null;
    if (!hasAlfred && !hasProviderScope) return null;

    if (hasProviderScope) return <>{props.children}</>;

    return (
        <div
            className={props.className}
            css={{
                position: "relative",
                padding: props.noPadding ? 0 : 20,

                overflow: "hidden",
                //  borderColor: theme.colors.purple.asString,
                borderStyle: "solid",
                borderWidth: 3,
                borderLeftWidth: 8,
                borderImage:
                    "linear-gradient(140deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%) 1",
            }}>
            {props.children}
        </div>
    );
};

export { WithAlfred };
export type { Props as WithAlfredProps };
