import { usePromoQuery } from "@@/backoffice/for-providers/promos/use-promos-query";
import { useAppSettings } from "@@/settings";
import { HorizontalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { localFallbackProductIds } from "@@/store-fronts/shared/fallback-product-ids";
import { ResponsiveStoreFrontHzProductCard } from "@@/store-fronts/shared/store-front-hz-product-card";
import { useTheme } from "@emotion/react";
import { PromoId, RemSize, unique } from "@towni/common";
import React from "react";

const LackoPromoSection = () => {
    const theme = useTheme();
    const { isProdEnv, isSandboxEnv } = useAppSettings();
    // Läckö Promo, Säsongen 2022 promo_5vDQgkD95PoCyA280hjY
    // (sandbox Läckö Slott Promo, Säsongen 2021 promo_DShcH4tU-UEwMD5Gf2ay)
    const promoId = (
        isProdEnv ? "promo_5vDQgkD95PoCyA280hjY" : "promo_DShcH4tU-UEwMD5Gf2ay"
    ) as PromoId;
    const promoQuery = usePromoQuery(promoId);

    const productIds = unique(
        isProdEnv || isSandboxEnv
            ? promoQuery.data?.item.productIds ?? []
            : localFallbackProductIds,
    );

    return (
        <SectionArea
            sectionAreaId="promo-lacko-section"
            titleSize="XXL"
            titleWeight="900"
            subtitleColor={theme.colors.black.light45}
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map(id => {
                    return (
                        <React.Fragment key={id}>
                            <ResponsiveStoreFrontHzProductCard
                                cardSize={1.1 as RemSize}
                                aspectRatio="3:4"
                                productId={id}
                                hideSubtitle
                            />
                        </React.Fragment>
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
        </SectionArea>
    );
};

export { LackoPromoSection };
