import { parseSafely } from "@towni/common";
import { useCallback, useMemo } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { ZodSchema, z } from "zod";

type Value = string | number | boolean | Record<string, unknown> | undefined;
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
/**
 * Kind of like use state but stores value in url query string
 * and parses and validates it on retrieval. \
 * Use it when you want to preserve the state in the url to be able to share it.
 */
const useUrlState = <T extends Value = string>(params: {
    /** used as name of query string in url */
    key: string;
    /** used to parse value from url and validate it on retrieval */
    schema: ZodSchema<T>;
    /** if value not present, this value will be returned */
    defaultValue?: T;
    /** if value not present, and default value is, this setting will allow default value to be returned but not set in url */
    doNotAddDefaultValueToUrl?: boolean;
    /** complex object? then stringify is probably needed, although, don't store complex objects in url preferably! ;) */
    stringify?: boolean;
}): readonly [
    T | undefined,
    (
        valueToSet: T | undefined,
        options?: NavigateOptions & { clearSearch?: boolean },
    ) => void,
] => {
    const { stringify, key, schema, defaultValue, doNotAddDefaultValueToUrl } =
        params;
    const [searchParams, setSearchParams] = useSearchParams();

    const currentUrlValue = searchParams.get(key);
    const value =
        parseSafely({
            value: stringify
                ? JSON.parse(currentUrlValue ?? "")
                : currentUrlValue,
            schema: schema,
        }) ?? defaultValue;

    const setValue = useCallback(
        (
            valueToSet: T | undefined,
            options?: NavigateOptions & { clearSearch?: boolean },
        ) => {
            const { clearSearch, ...navOptions } = options ?? {};
            setSearchParams(prev => {
                const searchParams = clearSearch
                    ? new URLSearchParams()
                    : new URLSearchParams(prev);
                if (!valueToSet) searchParams.delete(key);
                else {
                    searchParams.set(
                        key,
                        stringify
                            ? JSON.stringify(valueToSet)
                            : String(valueToSet),
                    );
                }
                return searchParams;
            }, navOptions);
        },
        [key, setSearchParams, stringify],
    );

    return useMemo(() => [value, setValue] as const, [value, setValue]);
};

const useUrlStateBoolean = (params: {
    /** used as name of query string in url */
    key: string;
    /** if value not present, and default value is, this setting will allow default value to be returned but not set in url */
    doNotAddDefaultValueToUrl?: boolean;
}): [value: boolean, setValue: (value: boolean) => void] => {
    const [value, setValue] = useUrlState({
        key: params.key,
        schema: z.enum(["true", "false", "1", "0"]).optional(),
        defaultValue: "false",
        doNotAddDefaultValueToUrl: params.doNotAddDefaultValueToUrl ?? true,
    });

    return useMemo(() => {
        const setValueAsBoolean = (value: boolean) => {
            setValue(value ? "true" : "false");
        };
        const valueAsBoolean = value === "true" || value === "1";
        return [valueAsBoolean, setValueAsBoolean] as const;
    }, [value, setValue]);
};

export { useUrlState, useUrlStateBoolean };
