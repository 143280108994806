import { apiFetchClient } from "@@/shared/fetch-client";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    GetResponse,
    GetSingleResponse,
    MINUTES,
    Promo,
    PromoId,
    ProviderGroupId,
    ProviderId,
} from "@towni/common";

const usePromoQuery = (
    promoId: PromoId | undefined,
    options?: { enabled: boolean },
): UseQueryResult<GetSingleResponse<Promo>> => {
    const queryResult = useQuery<GetSingleResponse<Promo>, Error>({
        queryKey: ["promos", promoId],
        staleTime: 0,
        gcTime: 5 * MINUTES,
        enabled: options?.enabled ?? true,
        refetchOnWindowFocus: true,
        queryFn: ({ signal }) =>
            apiFetchClient.get<GetSingleResponse<Promo>>({
                route: `/promos/${promoId}`,
                customConfig: {
                    signal,
                },
            }),
    });
    return queryResult;
};

const usePromosQuery = (
    ownerIds: (ProviderId | ProviderGroupId)[],
    options?: { enabled: boolean },
): UseQueryResult<GetResponse<Promo>> => {
    return useQuery<GetResponse<Promo>, Error>({
        queryKey: ["promos"],
        staleTime: 0,
        gcTime: 5 * MINUTES,
        enabled: options?.enabled ?? true,
        refetchOnWindowFocus: true,
        queryFn: ({ signal }) =>
            apiFetchClient.get<GetResponse<Promo>>({
                route: `/promos/for-owners/?ownerIds=${ownerIds.join(",")}`,
                customConfig: {
                    signal,
                },
            }),
    });
};

export { usePromoQuery, usePromosQuery };
