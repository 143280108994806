import { formatOneLineAddress, translation, UserAddress } from "@towni/common";
import * as React from "react";
import { VerticalDivider } from "../../dividers";
import { FlexColumn } from "../../flex-containers";
import { TextEdit } from "../../text";
import { AddressInputWithAutocomplete } from "./address-input-with-autocomplete";

type Props = {
    current: Partial<UserAddress>;
    update: (address: Partial<UserAddress>) => void;
    noAddressFound: () => void;
    hideAddressNameField?: boolean;
};

const AddressInputGoogleAutoComplete: React.FC<Props> = props => {
    return (
        <FlexColumn shrink={0} padding={{ leftRight: 20 }} overflowY="auto">
            <AddressInputWithAutocomplete
                label={translation({
                    sv: "Adress",
                    en: "Address",
                })}
                placeholder={translation({
                    sv: "Sök",
                    en: "Search",
                })}
                initialValue={formatOneLineAddress(props.current?.address)}
                onAddressChange={result => {
                    const hasErrors = !result || !!result?.errors.length;
                    props.update({
                        ...props.current,
                        address:
                            !hasErrors && result?.address
                                ? {
                                      ...(props.current.address ?? {}),
                                      ...result?.address,
                                  }
                                : undefined,
                    });
                }}
                noAddressFound={props.noAddressFound}
                setSearchFor={formatOneLineAddress(props.current?.address)}
            />
            <VerticalDivider M />
            <TextEdit
                type="text"
                level="RECOMMENDED"
                label="Adressdetaljer"
                description={"Portkod, våning etc"}
                onChange={details =>
                    props.update({ ...props.current, details })
                }
                value={props.current.details}
            />
            <VerticalDivider L />
            <TextEdit
                type="text"
                level="RECOMMENDED"
                label="Leveransinstruktioner"
                description="Ex. ring på vid leverans, ställ brevid..."
                onChange={deliveryInstructions =>
                    props.update({
                        ...props.current,
                        deliveryInstructions,
                    })
                }
                value={props.current.deliveryInstructions}
            />
            {props.hideAddressNameField ? null : (
                <>
                    <VerticalDivider L />
                    <TextEdit
                        type="text"
                        level="RECOMMENDED"
                        label="Namnge adressen"
                        description="Hem, jobb, stugan"
                        onChange={name =>
                            props.update({ ...props.current, name })
                        }
                        value={props.current.name}
                    />
                </>
            )}

            <VerticalDivider size="3XL" />
            <VerticalDivider size="3XL" />
        </FlexColumn>
    );
};

export { AddressInputGoogleAutoComplete };
