import * as React from "react";
import { FC } from "react";
import { FlexRow } from "../../flex-containers";
import { TextBox } from "../../text";

type Props = {
    prediction: google.maps.places.AutocompletePrediction;
    onClick?: () => void;
};
const AddressPredictionRow: FC<Props> = props => {
    return (
        <FlexRow
            fillParentWidth
            crossAxis="center"
            onMouseDown={e => e.preventDefault()}
            onTouchStart={e => e.preventDefault()}
            padding={{ leftRight: 20, topBottom: 10 }}
            onClick={props.onClick}>
            <TextBox text={props.prediction.description} />
        </FlexRow>
    );
};

export { AddressPredictionRow };
