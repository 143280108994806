import { useHasAlfredScope, useMe } from "@@/authentication/me/me-context";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModal } from "@@/modals/use-modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useProvidersForUser } from "@@/providers/state/queries/providers-for-user/use-providers-for-user";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { faNodeJs } from "@fortawesome/free-brands-svg-icons";
import { Percentage, fullName } from "@towni/common";
import { ModalHeader } from "../../modals/modal-header";
import { DevToolsModal } from "./dev-tools-modal";
import { UserModalDashboardLink } from "./user-modal-dashboard-link";
import { UserModalEditEmail } from "./user-modal-edit-email";
import { UserModalEditName } from "./user-modal-edit-name";
import { UserModalEditPhone } from "./user-modal-edit-phone";
import { UserModalProviderLinks } from "./user-modal-provider-links";
import { UserModalSignOut } from "./user-modal-sign-out";
import { NotificationSoundSettings } from "./user-modal-sound-settings";

type Props = {
    modalId: ModalId;
};

const UserModal = (props: Props) => {
    const [me] = useMe();
    const { hide } = useModalActions(props.modalId);
    const [providersForUser] = useProvidersForUser(me?._id);
    const hasAlfred = useHasAlfredScope();
    const [{ show }, modalId] = useModal("dev_tools__");
    if (!me) return null;
    return (
        <Modal
            modalId={props.modalId}
            height={0.8 as Percentage}
            header={
                <ModalHeader
                    bottomBorder
                    modalId={props.modalId}
                    title={fullName(me)}
                    topRightButton={
                        hasAlfred
                            ? {
                                  _type: "TOP_RIGHT_ACTION_ICON",
                                  icon: faNodeJs,
                                  action: show,
                              }
                            : undefined
                    }
                />
            }>
            {providersForUser.length ? (
                <>
                    <VerticalDivider M />
                    <NotificationSoundSettings />
                    <HorizontalLine
                        fillParentWidth
                        css={{ marginBottom: 20, marginTop: 20 }}
                    />
                </>
            ) : null}
            <UserModalEditName padding={{ leftRight: 20 }} />
            <HorizontalLine
                fillParentWidth
                css={{ marginBottom: 20, marginTop: 20 }}
            />
            <UserModalEditPhone padding={{ leftRight: 20 }} />
            <HorizontalLine
                fillParentWidth
                css={{ marginBottom: 20, marginTop: 20 }}
            />
            <UserModalEditEmail padding={{ leftRight: 20 }} />
            <VerticalDivider L />
            <HorizontalLine fillParentWidth />
            <UserModalDashboardLink />
            <UserModalProviderLinks />
            <UserModalSignOut afterSignOut={hide} />
            <VerticalDivider />
            <DevToolsModal modalId={modalId} />
        </Modal>
    );
};

export { UserModal };
