import { useAppSettings } from "@@/settings";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { FC } from "react";
import { StoreFrontGridProductCard } from "../../shared/store-front-grid-product-card";

const OrderFoodSection: FC = () => {
    const theme = useTheme();
    const { isProdEnv, isDevEnv } = useAppSettings();
    const localDefaultProductId = "product_XwcfwcB9gVetSxl3F8ue" as ProductId;
    const devDefaultProductId = "product_mAUGHkpleK8YizKI_6QX" as ProductId;

    const defaultKicker = !isProdEnv
        ? "ANNAN PRODUKT VISAS I PROD!"
        : "TA MED HEM";
    const productIdWithFallback = (productId: string): ProductId =>
        isProdEnv
            ? (productId as ProductId)
            : isDevEnv
              ? devDefaultProductId
              : localDefaultProductId;
    return (
        <SectionArea
            sectionAreaId="food-to-enjoy-section"
            title={translation({
                sv: "Mat att njuta av!",
                en: "Food to enjoy!",
            })}
            titleSize="XXL"
            titleWeight="900"
            subtitleColor={theme.colors.black.light45}
            subtitle={
                "Mat att njuta av vid hemmafesten direkt hem från din lokala restaurang. 🙂"
            }>
            <SimpleGrid
                size={0.9 as RemSize}
                padding={{ leftRight: 20, bottom: 20 }}>
                <StoreFrontGridProductCard
                    // Fyra små rätter
                    productId={productIdWithFallback(
                        "product_DEHWk3ctHdYluHozUzPT",
                    )}
                    kicker={defaultKicker}
                />
                <StoreFrontGridProductCard
                    // Löjromstoast
                    productId={productIdWithFallback(
                        "product_CIJGvWGt3r9wNYg_C0TY",
                    )}
                    kicker={defaultKicker}
                />
                <StoreFrontGridProductCard
                    // Tryffelburgare
                    productId={productIdWithFallback(
                        "product_tqIOPWznN5JEBQ5YouXH",
                    )}
                    kicker={defaultKicker}
                />
                <StoreFrontGridProductCard
                    // Wokad biff
                    productId={productIdWithFallback(
                        "product_rB59teCTg__EqcX-Jlwy",
                    )}
                    kicker={defaultKicker}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { OrderFoodSection };
