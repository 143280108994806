import { DestinationName, destinations } from "@towni/common";

const storeFronts = destinations;

const setCurrentStorefront = (destinationName: DestinationName) => {
    sessionStorage?.setItem("current_storefront", destinationName);
};
const getCurrentStorefrontPath = () => {
    const currentStoreFront = sessionStorage?.getItem("current_storefront");
    const currentPath = storeFronts[currentStoreFront as DestinationName];
    return currentPath || "/";
};

export { storeFronts, getCurrentStorefrontPath, setCurrentStorefront };
