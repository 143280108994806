import {
    generateId,
    IsoAndUnixTimestamp,
    nowIsoAndUnix,
    Password,
} from "@towni/common";
import * as React from "react";

type State = {
    readonly _type: "CLASSIC_SIGN_IN_STATE";
    readonly _id: string;
    readonly _createdAt: IsoAndUnixTimestamp;
    username: string;
    password: Password;
};

const createInitialState = (params?: {
    _id?: string;
    username?: string;
}): State => {
    return {
        _id: params?._id ?? generateId({ prefix: "classic_sign_in_state__" }),
        _type: "CLASSIC_SIGN_IN_STATE",
        _createdAt: nowIsoAndUnix(),
        username: params?.username ?? "",
        password: "" as Password,
    };
};

type Action =
    | {
          type: "SET_USER_NAME";
          username: string;
      }
    | {
          type: "SET_PASSWORD";
          password: Password;
      }
    | {
          type: "CLEAR";
      };

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_USER_NAME": {
            return {
                ...state,
                username: action.username,
            };
        }
        case "SET_PASSWORD": {
            return {
                ...state,
                password: action.password,
            };
        }
        case "CLEAR": {
            return createInitialState();
        }
        default:
            return state;
    }
};

const ClassicSignInStateContext = React.createContext<State | undefined>(
    undefined
);
const ClassicSignInDispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

const ClassicSignInProvider = ({
    children,
    ...props
}: {
    children: React.ReactNode;
    username?: string;
}) => {
    const [state, dispatch] = React.useReducer(
        reducer,
        createInitialState({
            username: props.username,
        })
    );

    return (
        <ClassicSignInStateContext.Provider value={state}>
            <ClassicSignInDispatchContext.Provider value={dispatch}>
                {children}
            </ClassicSignInDispatchContext.Provider>
        </ClassicSignInStateContext.Provider>
    );
};
const useClassicSignInState = () => {
    const context = React.useContext(ClassicSignInStateContext);
    if (context === undefined) {
        throw new Error(
            "useClassicSignInState must be used within a ClassicSignInProvider"
        );
    }
    return context;
};

const useClassicSignInDispatch = () => {
    const dispatch = React.useContext(ClassicSignInDispatchContext);
    if (dispatch === undefined) {
        throw new Error(
            "useClassicSignInDispatch must be used within a ClassicSignInProvider"
        );
    }

    const setUsername = (username: string) =>
        dispatch({ type: "SET_USER_NAME", username });
    const setPassword = (password: Password) =>
        dispatch({ type: "SET_PASSWORD", password });
    const clear = () => dispatch({ type: "CLEAR" });

    return {
        dispatch,
        setUsername,
        setPassword,
        clear,
    };
};

export {
    ClassicSignInProvider,
    useClassicSignInDispatch,
    useClassicSignInState,
};
