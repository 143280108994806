import { useMe } from "@@/authentication/me/me-context";
import { Receipt_Accommodation_ResourceReservationRequestRow } from "@@/receipts/accommodation/receipt_resource-reservation-request-row";
import { FlexColumn } from "@@/shared/flex-containers";
import { getProviderScope } from "@@/users/scopes";
import {
    ResourceReservationRequest,
    type ProviderId,
    type ResourceReservation,
} from "@towni/common";

type Props = {
    providerId: ProviderId;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
};

const Receipt_Accommodation_ResourceReservationRequestList = (props: Props) => {
    const [me] = useMe();
    const scopes = getProviderScope({
        providerId: props.providerId,
        access: "read",
    });
    const hasScope = me?.scopes.some(item => scopes.includes(item)) ?? false;

    const reservations = hasScope
        ? props.resourceReservations
        : props.resourceReservations.filter(r => r.displayResourceTitle);

    if (reservations.length === 0) return null;
    return (
        <FlexColumn
            css={{
                cursor: "default",
            }}>
            {reservations.map((b, index) => (
                <Receipt_Accommodation_ResourceReservationRequestRow
                    key={b._id}
                    resourceReservation={b}
                    displayTime={hasScope}
                    index={index}
                />
            ))}
        </FlexColumn>
    );
};

export { Receipt_Accommodation_ResourceReservationRequestList };
