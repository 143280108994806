import { darkeningGradientReverse } from "@@/shared/bg-darkening-gradient";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { css, useTheme } from "@emotion/react";
import { RemSize } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { DaftoColorContext, daftoColors } from "./dafto-color-context";
import { DaftoCycleSection } from "./sections/cycle-section";
import { DaftoFunSeaFourSection } from "./sections/dafto-fun-sea-four-section";
import { DaftoSectionTableAndBreakfest } from "./sections/dafto-section-table-and-breakfest";

type Props = {
    title?: string;
};

const DaftoStoreFront = (props: Props) => {
    const vh100 = use100vh();
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <DaftoColorContext.Provider value={daftoColors}>
                <Helmet>
                    <title>{props.title ?? "Daftö Resort"}</title>
                </Helmet>
                <AppPage
                    pageId={appPageIdFactory("dafto-store-front")}
                    crossAxis={isDesktop ? "center" : undefined}
                    anchors={[]}
                    header={
                        <FlexRow
                            fillParentWidth
                            shrink={0}
                            mainAxis="flex-end"
                            crossAxis="center"
                            padding={{ leftRight: 20, topBottom: 10 }}
                            styling={css`
                                position: absolute;
                                z-index: 50;
                            `}>
                            <CurrentUserIcon theme="PRIMARY" />
                        </FlexRow>
                    }>
                    {/* Header Image >>>> */}
                    <FlexColumn
                        fillParentWidth
                        shrink={0}
                        height={vh100 * 0.65}
                        maxHeight={675}
                        ref={element => {
                            if (element) {
                                element.style.setProperty(
                                    "user-select",
                                    "text",
                                    "important",
                                );
                            }
                        }}
                        background={{
                            fit: "COVER",
                            imageUrl:
                                "https://we-are-alfred.imgix.net/destinations/dafto-header-2022.jpeg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ top: 55, leftRight: 32 }}
                            background={{
                                linearGradient: darkeningGradientReverse,
                            }}>
                            <TextBox
                                text="Tång, salt och äventyr"
                                userSelect="text"
                                color={"#ffffff"}
                                size={1.5 as RemSize}
                                css={{
                                    textShadow:
                                        "0px 4px 20px rgba(5, 32, 72, 0.6)",
                                }}
                            />
                            <VerticalDivider size="3XS" />
                            <TextBox
                                text="Daftö Resort"
                                userSelect="text"
                                color={"#ffffff"}
                                size={2.25 as RemSize}
                                weight="900"
                                css={{
                                    textShadow:
                                        "0px 4px 20px rgba(5, 32, 72, 0.6)",
                                }}
                            />
                        </FlexColumn>
                    </FlexColumn>
                    {/* <<<< Header Image */}

                    {/* Content >>>> */}
                    <FlexColumn fillParentWidth maxWidth={800}>
                        <VerticalDivider XL />
                        <TextBox
                            text="Upplevelser för familjeäventyr"
                            userSelect="text"
                            size={1.5 as RemSize}
                            padding={{ leftRight: 20 }}
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text="Familjeäventyr på Daftö Resort i Strömstad bland tång, salt och pirater. Upplevelser och aktiviteter för alla. Här hittar du ett stort utbud av våra bokningsbara upplevelser– från bryggbastu till cyklar och våra kända showmiddagar."
                            userSelect="text"
                            color={theme.colors.black.light30}
                            padding={{ leftRight: 20 }}
                            size={1.125 as RemSize}
                        />
                        <VerticalDivider XL />
                        {/* <DaftoSectionShows /> */}
                        <DaftoSectionTableAndBreakfest />
                        {/* <OneSquareSection
                            productId={
                                "product__MeTD4nUDs7SZADdKvxPr" as ProductId
                            }
                            sectionTitle={"Äventyrsgolf"}
                            sectionSubTitle={
                                "Utmana kompisarna och familjen i sann kämparanda! Må bästa pirat vinna! Beställ här så är det bara att hämta din klubba i receptionen. "
                            }
                            actionText={"För barnen"}
                        /> */}
                        <DaftoFunSeaFourSection />
                        {/* <OneSquareSection
                            productId={
                                "product__J2bB9M8BVbcrrXFYkc4G" as ProductId
                            }
                            sectionTitle={"Avkoppling vid havskanten"}
                            sectionSubTitle={
                                "Bryggbastu med utsikt över Daftös vackra skärgård! Boka en kväll tillsammans med nära och kära och låt er njuta av värme, hav och en vacker utsikt - och kanske nått gott till."
                            }
                            actionText={"Se tider & boka"}
                        /> */}
                        <DaftoCycleSection />
                        {/* <OneSquareSection
                            productId={
                                "product__tUR2o-XSYMINdCfxhv8h" as ProductId
                            }
                            sectionTitle={"Unikt för dig som bor här "}
                            sectionSubTitle={
                                "Hörde vi att det är någon som fyller år? Låt en pirat komma och fira! Boka här så kommer en pirat och uppvaktar dig vid ditt boende. "
                            }
                            contentTop={true}
                            actionText={"Se tider & boka"}
                        /> */}
                    </FlexColumn>
                    {/* <<<< Content */}
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </AppPage>
            </DaftoColorContext.Provider>
        </WideScreenWrapper>
    );
};

export { DaftoStoreFront };
