import { EffectCallback, useEffect, useRef, useState } from "react";

/**
 * Only runs on first mount
 */
const useMountEffect = (effect: EffectCallback) => {
    const _effect = useRef<EffectCallback>(effect);
    const _destroy = useRef<void | (() => void)>();
    const _effectCalled = useRef(false);
    const _rendered = useRef(false);
    const [, setCounter] = useState<number>(0);

    if (_effectCalled.current) {
        _rendered.current = true;
    }

    useEffect(() => {
        // execute the effect once
        if (!_effectCalled.current) {
            _destroy.current = _effect.current();
            _effectCalled.current = true;
        }

        // forces a render after the effect is run
        setCounter(count => count + 1);

        return () => {
            // if no render since the useEffect was called,
            // we know it's a dummy React cycle
            if (!_rendered.current) {
                return;
            }

            // otherwise this is not a dummy destroy, so call the destroy func
            if (_destroy.current) {
                _destroy.current();
            }
        };
    }, []);
};

export { useMountEffect };
