import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faAt, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { Provider, translation } from "@towni/common";
import { parsePhoneNumber } from "awesome-phonenumber";

type Props = {
    modalId: ModalId;
    provider: Provider;
    phoneNumber?: string;
    publicMail?: string;
};

const ContactProviderModal = (props: Props) => {
    const theme = useTheme();
    const phoneNumber = props.phoneNumber
        ? parsePhoneNumber(props.phoneNumber, {
              regionCode: "SE",
          })
        : undefined;

    // TODO: What if phoneNumber ain't valid?

    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    title={props.provider.meta.title}
                />
            }>
            <FlexColumn fillParentWidth>
                <HorizontalLine />
                {phoneNumber ? (
                    <a
                        href={`tel:${phoneNumber.number?.e164}`}
                        title={`Ring ${phoneNumber.number?.e164}`}>
                        <HorizontalDivider />

                        <FlexColumn crossAxis="center">
                            <VerticalDivider M />
                            <TextBox
                                text={translation({ sv: "Ring", en: "Call" })}
                                weight="700"
                                size={1}
                                case="UPPERCASE"
                                color={theme.colors.black}
                            />
                            <VerticalDivider />
                            <FlexRow
                                fillParentWidth
                                mainAxis="center"
                                crossAxis="center"
                                shrink={0}>
                                <Icon
                                    icon={faPhone}
                                    color={theme.colors.black.light80}
                                />
                                <HorizontalDivider />
                                <TextBox
                                    text={phoneNumber.number?.national}
                                    weight="700"
                                    size={1}
                                    color={theme.colors.black}
                                />
                            </FlexRow>
                            <VerticalDivider />
                            <VerticalDivider M />
                        </FlexColumn>
                    </a>
                ) : null}
                <HorizontalLine />
                {props.publicMail ? (
                    <a
                        href={`mailto:${props.publicMail}`}
                        title={`Mail ${props.publicMail}`}>
                        <HorizontalDivider />

                        <FlexColumn crossAxis="center">
                            <VerticalDivider M />
                            <TextBox
                                text={translation({
                                    sv: "E-post",
                                    en: "Email",
                                })}
                                weight="700"
                                size={1}
                                case="UPPERCASE"
                                color={theme.colors.black}
                            />
                            <VerticalDivider />
                            <FlexRow
                                fillParentWidth
                                mainAxis="center"
                                crossAxis="center"
                                shrink={0}>
                                <Icon
                                    icon={faAt}
                                    color={theme.colors.black.light80}
                                />
                                <HorizontalDivider />
                                <TextBox
                                    text={props.publicMail}
                                    weight="700"
                                    size={1}
                                    color={theme.colors.black}
                                />
                            </FlexRow>
                            <VerticalDivider />
                            <VerticalDivider M />
                        </FlexColumn>
                    </a>
                ) : null}
            </FlexColumn>
        </Modal>
    );
};

export { ContactProviderModal };
