import { RowSelectButton as ListSelectButton } from "@@/shared/buttons/list-select-button";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Avatar } from "@@/shared/pictures/avatar";
import { TextBox } from "@@/shared/text";
import { useSizeTracker } from "@@/shared/use-size-tracker";
import { WidthAndHeight } from "@@/shared/width-and-height";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useTheme } from "@emotion/react";
import { Padding, remSize, ResourceMap, ResourceMapId } from "@towni/common";
import * as React from "react";

type Props = {
    resourceMap: ResourceMap;
    selected: boolean;
    onSizeChange?: (size: WidthAndHeight) => void;
    toggle: (resourceMapId: ResourceMapId) => void;
    topElement?: JSX.Element;
    bottomElement?: JSX.Element;
    /**
     * defaults to leftRight: 20
     */
    padding?: Padding;
};

const ResourceMapPickerRow = (props: Props) => {
    const theme = useTheme();
    const { resourceMap, selected, toggle } = props;
    const [storageItem] = useStorageItemImageReference(
        resourceMap.storageItemId
    );
    // Track size
    const [elementRef] = useSizeTracker(props.onSizeChange);

    const inactive = false; //resource.status.type !== "ACTIVE";
    return (
        <FlexColumn
            fillParentWidth
            ref={elementRef}
            mainAxis="center"
            crossAxis="flex-start"
            padding={{ leftRight: 20, ...(props.padding ?? {}) }}>
            {props.topElement ?? null}

            <FlexRow
                fillParentWidth
                radius={10}
                background={{
                    color: inactive
                        ? theme.colors.disabled.background
                        : selected
                        ? theme.colors.primary.light
                        : theme.colors.default.background,
                }}
                css={{
                    border: `1px solid ${theme.colors.default.border.asString}`,
                }}
                padding={{ all: 15 }}
                mainAxis="space-between"
                crossAxis="center"
                onClick={() => toggle(resourceMap._id)}>
                <FlexRow grow={1} shrink={1}>
                    <Avatar
                        title={resourceMap.title}
                        image={storageItem}
                        disableBlurhash={true}
                        backgroundColor={theme.colors.white}
                        borderColor={theme.colors.white}
                        size={remSize(2.5)}
                    />
                    <HorizontalDivider M />
                    <TextBox
                        text={resourceMap.title}
                        color={
                            inactive
                                ? theme.colors.disabled.text
                                : theme.colors.default.text
                        }
                        lineClamp={2}
                        shrink={1}
                        grow={1}
                    />
                    <HorizontalDivider />
                </FlexRow>
                <ListSelectButton
                    selected={selected}
                    onClick={event => {
                        event.stopPropagation();
                        toggle(resourceMap._id);
                    }}
                />
            </FlexRow>
            {props.bottomElement ?? null}
        </FlexColumn>
    );
};

export { ResourceMapPickerRow };
