import { browserLogger } from "@@/settings";
import { asArray } from "@towni/common";
import { Component, ErrorInfo, ReactNode } from "react";

type Props = {
    children: ReactNode;
    whenNameIncludes?: string | string[];
    whenMessageIncludes: string | string[];
    onCatch: (error: Error) => Promise<void> | void;
};

class ErrorHandlerBoundary extends Component<Props> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Do we care?
        // Does the error match an error we want to handle here?
        const nameMatch = asArray(this.props.whenNameIncludes).every(
            name => !!error.name?.includes(name),
        );
        const messageMatch =
            error.message &&
            asArray(this.props.whenMessageIncludes).every(
                message => !!error.message?.includes(message),
            );

        const isMatch = nameMatch && messageMatch;

        // If it is a match, handle it
        if (isMatch) {
            browserLogger.warn("Handling error", error, errorInfo);
            this.props.onCatch?.(error);
        } else {
            throw error;
        }
    }

    render() {
        return this.props.children;
    }
}

export { ErrorHandlerBoundary };
