import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModal } from "@@/modals/use-modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { faQrcode } from "@fortawesome/pro-solid-svg-icons";
import { OrderId, Provider, translation } from "@towni/common";
import QRCode from "react-qr-code";
import { useOrderLasseMajaQR } from "../use-order-lasse-maja-qr";

type ModalProps = {
    modalId: ModalId;
    code?: string;
};

type Props = {
    provider: Provider;
    orderId: OrderId;
    showQr: boolean;
    fallback: () => React.ReactNode;
};

const LassemajaQr = ({ provider, orderId, showQr, fallback }: Props) => {
    const [{ show }, qrModalId] = useModal("qr_modal__");
    const ak = useAccessKeyIdFromUrl();
    const [lassemaja] = useOrderLasseMajaQR(
        provider?.options.qrOnBookable ? orderId : undefined,
        ak
    );
    if (!lassemaja) return <>{fallback()}</>;

    return (
        <>
            <FlexColumn
                crossAxis="center"
                onClick={() => {
                    show();
                }}>
                {showQr ? (
                    <QRCode value={lassemaja ?? ""} size={75} />
                ) : (
                    <Icon size={3} icon={faQrcode} />
                )}
            </FlexColumn>
            <LasseShowQrModal modalId={qrModalId} code={lassemaja} />
        </>
    );
};

const LasseShowQrModal = (props: ModalProps) => {
    const { hide } = useModalActions(props.modalId);
    return (
        <Modal modalId={props.modalId}>
            <FlexColumn
                crossAxis="center"
                mainAxis="space-between"
                fillParent
                padding={{ all: 20 }}>
                <QRCode value={props.code ?? ""} size={300} />
                <VerticalDivider XL />

                <ButtonPrimary onClick={hide} fillParentWidth>
                    <TextBox text={translation({ sv: "Stäng", en: "Close" })} />
                </ButtonPrimary>
            </FlexColumn>
        </Modal>
    );
};

export { LassemajaQr, LasseShowQrModal };
