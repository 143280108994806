import { VerticalDivider } from "@@/shared/dividers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { LastMileCarSection } from "./last-mile-car-section";
import { OneSquareLinkSectionAllTravel } from "./one-square-link-section-all-travel";
import { OneSquareLinkSectionElCar } from "./one-square-link-section-elcar";
import { SmartGiftCardStart } from "./smart-giftcard-start";

const SmartFindOtherThings = () => {
    const theme = useTheme();
    return (
        <>
            <SmartGiftCardStart />
            <VerticalDivider XL />
            <TextBox
                text="Fler hållbara resesätt"
                size={1.5 as RemSize}
                padding={{ leftRight: 20 }}
                weight="700"
            />
            <VerticalDivider XS />
            <TextBox
                text="Trötta i benen, ska lite längre eller inser att packningen inte ryms på elcykeln?
                Kombinera cyklingen med fler alternativ på resor, kanske egen chaufför eller elbil! "
                color={theme.colors.black.light30}
                padding={{ leftRight: 20 }}
                size={1.125 as RemSize}
            />
            <VerticalDivider ML />
            <OneSquareLinkSectionElCar />
            <VerticalDivider XL />
            <LastMileCarSection
                productId={"product__IG8ZXjoF9TW197TdJKI8" as ProductId}
                actionText={translation({
                    sv: "Boka",
                    en: "Book now!",
                })}
            />
            <VerticalDivider XL />
            <OneSquareLinkSectionAllTravel />
        </>
    );
};

export { SmartFindOtherThings };
