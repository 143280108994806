import { ContactInfoContextProvider } from "@@/carts/bookable-checkout/input-contact-info-context";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { DeliveryProvider } from "@@/carts/deliverypicker/delivery-context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { MultiCartContextProvider } from "@@/carts/multi-carts/multi-cart.context";
import { Wizard, WizardPages } from "@@/pages/wizard";
import { MultiContextProvider } from "@@/shared/multi-provider";
import {
    BookableSessionId,
    OrderGroupId,
    OrderId,
    Product,
    Provider,
    ResourceId,
    generateId,
} from "@towni/common";
import { useState } from "react";
import { BookingContextProvider } from "../booking-context";
import { BookingWizardPages } from "./booking-wizard-pages";

type Buyer = "CUSTOMER" | "ON_PREMISE";

type Props = {
    readonly product?: Product;
    readonly provider: Provider;
    readonly buyer?: Buyer;
    readonly onCheckoutComplete?: (params: {
        bookedSessionId?: BookableSessionId;
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void;
    readonly disablePageAnimation?: boolean;
    readonly modalEmbedded?: boolean;
    readonly sessionId?: BookableSessionId;
    readonly preferredResourceId?: ResourceId;
    readonly modalHeader?: React.ReactNode;
    readonly wizardElement?: typeof WizardPages | typeof Wizard;
};

const BookingWizard = (props: Props) => {
    const { product, provider } = props;
    const [contextId] = useState(() => generateId(`ctx_${product?._id}`));
    if (!product) return null;

    const WizardElement = props.wizardElement || Wizard;

    return (
        <MultiCartContextProvider
            contextId={contextId}
            preservationDisabled={true}>
            <CartContextProvider
                key={contextId}
                providerId={provider._id}
                doNotPreserveCart>
                <MultiContextProvider
                    providers={[
                        [
                            CheckoutContextProvider,
                            {
                                onPremise: props.buyer === "ON_PREMISE",
                            },
                        ],
                        DeliveryProvider,
                        [
                            BookingContextProvider,
                            {
                                product: props.product,
                                sessionId: props.sessionId,
                                preferredResourceId: props.preferredResourceId,
                                type: props.buyer,
                            },
                        ],
                        ContactInfoContextProvider,
                    ]}>
                    <BookingWizardPages
                        provider={provider}
                        product={product}
                        onCheckoutComplete={props.onCheckoutComplete}
                        wizardElement={WizardElement}
                    />
                </MultiContextProvider>
            </CartContextProvider>
        </MultiCartContextProvider>
    );
};

const BookingWizardWithoutWizardContext = (props: Props) => (
    <BookingWizard {...props} wizardElement={WizardPages} />
);

export { BookingWizard, BookingWizardWithoutWizardContext };
export type { Props as BookingWizardProps, Buyer };
