import { useMe } from "@@/authentication/me/me-context";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { PageLoad } from "@@/pages/page-load";
import { isOrderItemGiftCard_V2, support, translation } from "@towni/common";
import { Navigate, useParams } from "react-router-dom";
import { useGiftcardsForSearch } from "./giftcards/use-giftcards-search";
import { useTicketsForSearch } from "./queries/use-ticket-search";

const TicketRedirect = () => {
    const [me] = useMe();
    const { ticketRef } = useParams<{ ticketRef: string }>();
    const [tickets, ticketQuery] = useTicketsForSearch(ticketRef);
    const [giftCards, giftCardQuery] = useGiftcardsForSearch(ticketRef);

    const allTickets = [...tickets, ...giftCards];
    if (ticketQuery.isPending || giftCardQuery.isPending)
        return (
            <PageLoad
                text={translation({
                    sv: "Söker biljetter...",
                    en: "Searching for tickets...",
                })}
            />
        );

    if (allTickets.length === 0)
        return (
            <PageNotFound
                reason={translation({
                    sv: `Det verkar vara något konstigt med din länk, finns inte något att visa här. Kontakta oss på ${support.alfred.supportEmail} om du tror att länken borde fungera.`,
                    en: `It seems like there is something wrong with your link, there is nothing to show here. Contact us at ${support.alfred.supportEmail} if you think the link should work.`,
                })}
            />
        );

    const providerId = allTickets[0].providerId;

    const hasAccess = me?.scopes.some(scope => {
        const scopeSegments = scope.split(":");
        return scopeSegments.length === 2 && scopeSegments[0] === providerId;
    });

    if (hasAccess && !allTickets.some(isOrderItemGiftCard_V2)) {
        return (
            <Navigate to={`/bo/providers/${providerId}/tickets/${ticketRef}`} />
        );
    }

    return <Navigate to={`/tickets/${ticketRef}`} replace />;
};

export { TicketRedirect };
