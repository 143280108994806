import { VerticalDivider } from "@@/shared/dividers";
import { backendHost } from "@@/shared/fetch-client";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { SimpleGrid } from "@@/shared/simple-grid";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { translation } from "@towni/common";

const Footer = () => {
    const theme = useTheme();
    return (
        <FlexColumn
            crossAxis="center"
            fillParentWidth
            background={{ color: theme.colors.black.light20 }}
            padding={{ leftRight: 30, topBottom: 50 }}>
            <FlexRow fillParentWidth mainAxis="center">
                <img
                    src={`${backendHost}/public/storage/towni-brand/logos/towni-logo-hz-white-on-dark-medium.png`}
                    style={{ display: "block", width: 80 }}
                />
            </FlexRow>
            <VerticalDivider ML />
            <TextBox
                align="center"
                text={translation({
                    sv: `Tiden är nu. Vi behöver det lokala mer än någonsin. Men det lokala behöver också lika snabba digitala tjänster som det globala. Tillsammans gör vi det lokala starkare. Det brinner vi för. Hoppas att du gör det med.`,
                    en: "The time is now. We need the local more than ever. But the local also needs as fast digital services as the global. Together we make local stronger. That's what we are passionate about. We hope you are aswell.",
                })}
                color={theme.colors.white}
                size={1}
            />
            <VerticalDivider M />
            <TextBox
                align="center"
                padding={{ leftRight: 60 }}
                text={translation({
                    sv: `Utvecklat med kaffe och kärlek i Lidköping. © 2023`,
                    en: "Developed with coffee and love in Lidköping. © 2023",
                })}
                color={theme.colors.white}
                size={1}
            />
            <VerticalDivider ML />
            <a
                href="mailto:kontakt@towni.se"
                css={css`
                    display: block;
                    text-decoration: none;
                    font-weight: 800;
                    color: ${theme.colors.white.asString};
                    :hover {
                        color: ${theme.colors.white.asString};
                        text-decoration: none;
                    }
                `}>
                kontakt@towni.se
            </a>
            <VerticalDivider ML />
            <FlexRow fillParentWidth mainAxis="center">
                <img
                    src={`${backendHost}/public/storage/towni-brand/misc/love-lisch.png`}
                    style={{ display: "block", width: 60 }}
                />
            </FlexRow>
            <VerticalDivider size="3XL" />
            <a
                href="mailto:kontakt@towni.se"
                title="För partners"
                css={{
                    display: "block",
                    textDecoration: "none",
                    fontWeight: 800,
                    color: theme.colors.white.asString,
                    ":hover": {
                        color: theme.colors.white.asString,
                        textDecoration: "none",
                    },
                }}>
                För partners
            </a>
            <VerticalDivider M />
            <a
                href="mailto:kontakt@towni.se?subject=Karriär"
                title="Karriär"
                css={{
                    display: "block",
                    textDecoration: "none",
                    fontWeight: 800,
                    color: theme.colors.white.asString,
                    ":hover": {
                        color: theme.colors.white.asString,
                        textDecoration: "none",
                    },
                }}>
                Karriär
            </a>
            <VerticalDivider M />
            <a
                href="mailto:kontakt@towni.se?subject=Investera"
                css={{
                    display: "block",
                    textDecoration: "none",
                    fontWeight: 800,
                    color: theme.colors.white.asString,
                    ":hover": {
                        color: theme.colors.white.asString,
                        textDecoration: "none",
                    },
                }}>
                För investerare
            </a>
            <VerticalDivider M />
            <a
                href="mailto:kontakt@towni.se?subject=Feedback"
                css={{
                    display: "block",
                    textDecoration: "none",
                    fontWeight: 800,
                    color: theme.colors.white.asString,
                    ":hover": {
                        color: theme.colors.white.asString,
                        textDecoration: "none",
                    },
                }}>
                Feedback
            </a>
            <VerticalDivider size="3XL" />
            <TextBox
                align="center"
                text={translation({
                    sv: `I sambarbete med Leader Skaraborg & Destinationsbolaget Läckö-Kinnekulle`,
                    en: "The time is now. We need the local more than ever. But the local also needs as fast digital services as the global. Together we make local stronger. That's what we are passionate about. We hope you are aswell.",
                })}
                color={theme.colors.white}
                size={1}
            />
            <VerticalDivider M />
            <SimpleGrid width={300} size="XXS" gap={20} padding={{ all: 20 }}>
                <SectionAreaCard
                    aspectRatio="1:1"
                    background={{
                        color: theme.colors.transparent,
                        repeat: "NO-REPEAT",
                        fit: "CONTAIN",
                        imageUrl: `${backendHost}/public/storage/towni-brand/misc/eu-lb-fond-logo.png`,
                    }}
                />
                <SectionAreaCard
                    aspectRatio="1:1"
                    background={{
                        color: theme.colors.transparent,
                        repeat: "NO-REPEAT",
                        fit: "CONTAIN",
                        imageUrl: `${backendHost}/public/storage/towni-brand/misc/leader.png`,
                    }}
                />
                <SectionAreaCard
                    aspectRatio="1:1"
                    background={{
                        color: theme.colors.transparent,
                        repeat: "NO-REPEAT",
                        fit: "CONTAIN",
                        imageUrl: `${backendHost}/public/storage/towni-brand/misc/lkp-vid-vanern.png`,
                    }}
                />
                <SectionAreaCard
                    aspectRatio="1:1"
                    background={{
                        color: theme.colors.transparent,
                        repeat: "NO-REPEAT",
                        fit: "CONTAIN",
                        imageUrl: `${backendHost}/public/storage/towni-brand/misc/lacko-kinnekulle.png`,
                    }}
                />
            </SimpleGrid>
            <VerticalDivider size="XL" />
            <FlexColumn crossAxis="center">
                <TextBox
                    text={import.meta.env.VITE_BUILD_ID}
                    size={0.625}
                    color={theme.colors.black.light50}
                    userSelect="text"
                />
                <VerticalDivider XS />
                <TextBox
                    text={import.meta.env.VITE_BUILD_TIMESTAMP}
                    size={0.625}
                    color={theme.colors.black.light50}
                    userSelect="text"
                />
            </FlexColumn>
            <VerticalDivider S />
        </FlexColumn>
    );
};

export { Footer };
