import {
    ThemeProvider as EmotionThemeProvider,
    Global,
    useTheme,
} from "@emotion/react";
import { CssVarsProvider, extendTheme } from "@mui/joy";
import { ColorTheme } from "@towni/common";
import * as React from "react";
import {
    lightTheme as defaultLightTheme,
    globalCss,
} from "./themes/default.theme";

const AppThemeProvider = (props: {
    children?: React.ReactNode;
    lightColorTheme?: ColorTheme;
}) => {
    const lightTheme = React.useMemo(() => {
        return props.lightColorTheme
            ? {
                  ...defaultLightTheme,
                  colors: props.lightColorTheme,
              }
            : defaultLightTheme;
    }, [props.lightColorTheme]);
    return (
        <CssVarsProvider
            theme={extendTheme({
                fontFamily: {
                    body: lightTheme.fontFamily,
                },
            })}>
            <EmotionThemeProvider theme={lightTheme}>
                {/* <ThemeProvider theme={darkMode ? darkTheme : lightTheme}> */}
                <Global styles={globalCss} />
                {/* {darkMode ? <Global styles={darkGlobalCssOverride} /> : null} */}
                {props.children}
            </EmotionThemeProvider>
        </CssVarsProvider>
    );
};

/**
 * Quickfix, allows setting a color theme that falls back to parent theme if undefined.
 *
 * @param {({ theme: AppTheme | undefined, children?: React.ReactNode })} props
 * @return {*}
 */
const ColorThemeProvider = (props: {
    colorTheme: ColorTheme | undefined;
    children?: React.ReactNode;
}) => {
    const theme = useTheme();
    const current = React.useMemo(() => {
        if (!props.colorTheme) return theme;
        return {
            ...theme,
            colors: props.colorTheme,
        };
    }, [props.colorTheme, theme]);
    return (
        <EmotionThemeProvider theme={current}>
            <Global styles={globalCss} />
            {props.children}
        </EmotionThemeProvider>
    );
};

export { AppThemeProvider, ColorThemeProvider };
