import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FieldTitle } from "@@/shared/text/field-title";
import { TextBox } from "@@/shared/text/text-box";
import { useTheme } from "@emotion/react";
import { Slider as JoySlider } from "@mui/joy";
import { Translatable, setWithinRange } from "@towni/common";
import * as React from "react";
import { startTransition } from "react";

type Props = {
    readonly label?: Translatable;
    readonly minValue: number;
    /** Must be less then maxValue */
    readonly currentMaxValue?: number;
    readonly maxValue: number;
    readonly initialValue: number;
    readonly onChange: (value: number) => void;
    readonly required?: boolean;
    readonly step?: number;
};

const Slider = (props: Props) => {
    const theme = useTheme();
    const [value, setValue] = React.useState<number>(props.initialValue);
    if (props.currentMaxValue && props.maxValue < props.currentMaxValue)
        throw new Error("Current max value can't be greater than max value");
    const handleChange = (_: Event, newValue: number | number[]) => {
        if (typeof newValue !== "number")
            throw new Error("Invalid value, should be a number");
        startTransition(() => {
            setInputValue(newValue.toString());
            setValue(newValue);
        });
    };

    const handleChangeCommitted = (
        _: Event | React.SyntheticEvent<Element, Event>,
        newValue: number | number[],
    ) => {
        if (typeof newValue !== "number")
            throw new Error("Invalid value, should be a number");
        startTransition(() => {
            props.onChange(newValue);
        });
    };

    const [inputValue, setInputValue] = React.useState(() => value.toString());

    return (
        <div css={{ width: "100%" }}>
            <FlexColumn fillParentWidth css={{ position: "relative" }}>
                <FlexRow mainAxis="space-between" crossAxis="flex-start">
                    <FieldTitle
                        text={props.label}
                        required={!!props.required}
                    />
                    <HorizontalDivider />
                    <input
                        css={{
                            textAlign: "right",
                            fontSize: "0.875rem", // Same as field title default
                            weight: "700",
                            color: theme.colors.default.text.withAlpha(0.7)
                                .asString,
                            "&:focus": {
                                color: theme.colors.default.text.asString,
                            },
                            backgroundColor: theme.colors.transparent.asString,
                        }}
                        type="text"
                        value={inputValue}
                        onBlur={e => {
                            const inputNumber = Number.parseInt(e.target.value);
                            const inputValue = isNaN(inputNumber)
                                ? props.minValue
                                : inputNumber;
                            setInputValue(inputValue.toString());
                        }}
                        onChange={e => {
                            startTransition(() => {
                                const inputNumber = Number.parseInt(
                                    e.target.value,
                                );
                                const inputValue = isNaN(inputNumber)
                                    ? props.minValue
                                    : inputNumber;
                                const newValue = setWithinRange(inputValue, {
                                    max: props.maxValue,
                                    min: props.minValue,
                                });
                                if (inputValue > newValue)
                                    setInputValue(newValue.toString());
                                else setInputValue(e.target.value);
                                setValue(newValue);
                                props.onChange(newValue);
                            });
                        }}
                    />
                    {/* <TextBox
                        size={0.725}
                        weight="700"
                        text={`${value.toLocaleString()}`}
                        color={theme.colors.default.text.withAlpha(0.5)}
                    /> */}
                </FlexRow>
                <FlexRow
                    fillParentWidth
                    css={{
                        zIndex: 10,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}>
                    <JoySlider
                        value={value}
                        color="neutral"
                        min={props.minValue}
                        max={props.currentMaxValue ?? props.maxValue}
                        step={props.step}
                        onChange={handleChange}
                        onChangeCommitted={handleChangeCommitted}
                        valueLabelDisplay="auto"
                        css={{
                            opacity: 0.7,
                        }}
                    />
                </FlexRow>
                <FlexRow mainAxis="space-between" css={{ marginTop: -8 }}>
                    <TextBox
                        size={0.725}
                        text={`${props.minValue.toLocaleString()}`}
                        color={theme.colors.default.text.withAlpha(0.25)}
                    />
                    <TextBox
                        size={0.725}
                        text={
                            !props.currentMaxValue ||
                            props.currentMaxValue === props.maxValue
                                ? `${props.maxValue.toLocaleString()}`
                                : `${props.currentMaxValue.toLocaleString()}/${props.maxValue.toLocaleString()}`
                        }
                        color={theme.colors.default.text.withAlpha(0.25)}
                    />
                </FlexRow>
            </FlexColumn>
        </div>
    );
};

export { Slider };
