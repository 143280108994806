import * as React from "react";

import { useEffect, useState } from "react";
import { useMountEffect } from "./use-mount-effect";

const getOnlineStatus = () =>
    typeof navigator.onLine !== "undefined" ? navigator.onLine : true;

const OnlineStatusContext = React.createContext<boolean>(false);

/**
 * Keeps track of page visibility, if page is visible and active
 * on the users device or not
 * @param {*} props
 * @returns
 */
const OnlineStatusProvider = (props: {
    readonly children?: React.ReactNode;
}) => {
    const [isOnline, setIsOnline] = useState(getOnlineStatus);

    useMountEffect(() => {
        const goOnline = () => {
            setIsOnline(true);
        };
        const goOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener("online", goOnline, { passive: true });
        window.addEventListener("offline", goOffline, { passive: true });

        return () => {
            window.removeEventListener("online", goOnline);
            window.removeEventListener("offline", goOffline);
        };
    });

    return (
        <OnlineStatusContext.Provider value={isOnline}>
            {props.children}
        </OnlineStatusContext.Provider>
    );
};

const useOnlineStatus = (params?: {
    onOnline?: () => void;
    onOffline?: () => void;
    options?: { triggerOnFirst?: boolean };
}) => {
    const isOnline = React.useContext(OnlineStatusContext);
    const isFirst = React.useRef(true);
    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false;
            if (!params?.options?.triggerOnFirst) {
                return;
            }
        }
        if (isOnline && params?.onOnline) params.onOnline();
        if (!isOnline && params?.onOffline) params.onOffline();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline]);

    return isOnline;
};

export { OnlineStatusProvider, useOnlineStatus };
