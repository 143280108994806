import { useProduct } from "@@/products/state/queries/product/use-product";
import {
    darkeningGradient,
    darkeningGradientReverse,
} from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useImageUrl } from "@@/shared/image-url";
import { LinkWithLanguage } from "@@/shared/link";
import { useMediaQuery } from "@@/shared/responsiveness/use-media-query";
import {
    SectionAreaCardProps,
    SectionAreaSizedCard,
} from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    AspectRatio,
    ColorSet,
    ImageUrl,
    ProductId,
    RemSize,
    SizeName,
    Translatable,
    isAlfredItemActive,
    remSize,
    translation,
} from "@towni/common";
import { StoreFrontCardPill } from "./store-front-card-pill";

type Props = {
    readonly productId: ProductId;
    /**
     * default to `3:4`
     * @type {Translatable}
     */
    readonly aspectRatio: AspectRatio;
    /**
     * default to product title
     * @type {Translatable}
     */
    readonly title?: Translatable;
    readonly titleSize?: RemSize;
    readonly subtitle?: Translatable;
    readonly hideSubtitle?: boolean;
    readonly subtitleSize?: RemSize;
    /**
     * defaults to "Beställ"/"Order"
     * @type {Translatable}
     */
    readonly actionText?: Translatable;
    readonly actionTextColorSet?: ColorSet;
    readonly actionTextCase?: "UPPERCASE" | "LOWERCASE";
    /**
     * defaults to "Pickup eller hemleverans"/"Pickup or home delivery"
     * @type {Translatable}
     */
    readonly kicker?: Translatable;
    readonly kickerSize?: RemSize;
    readonly hideKicker?: boolean;
    readonly reversed?: boolean;
    readonly imageIdOrUrl?: string;
    /**
     * defaults to "L"
     * @type {SizeName}
     */
    readonly cardSize?: SizeName | RemSize;
    readonly children?: React.ReactNode;
};

const StoreFrontHzProductCard = (props: Props) => {
    const theme = useTheme();
    const [product] = useProduct(props.productId);
    const getImageUrl = useImageUrl();
    if (!product?.status || !isAlfredItemActive(product?.status)) return null;

    const imageRef = props.imageIdOrUrl
        ? product.meta.images.find(image =>
              typeof image === "string"
                  ? image === props.imageIdOrUrl
                  : image._id === props.imageIdOrUrl,
          ) ?? product.meta.images[0]
        : product.meta.images[0];

    const imageUrl = getImageUrl(imageRef, { imageWidth: 500 });
    const actionText =
        props.actionText ?? translation({ sv: "Beställ", en: "Order" });
    const title = props.title ?? product.meta.title;
    const subtitle = props.subtitle ?? product.meta.subtitle;
    const kicker = props.kicker ?? product.meta.kicker;
    const actionTextColorSet = props.actionTextColorSet ?? theme.colors.primary;
    const cardSize =
        typeof props.cardSize === "number"
            ? props.cardSize
            : props.cardSize ?? "L";
    return (
        <StoreFrontHzCard
            linkTo={`/products/${product._id}`}
            imageUrl={imageUrl}
            title={title}
            aspectRatio={props.aspectRatio}
            subtitle={subtitle}
            kicker={kicker}
            actionText={actionText}
            actionTextColorSet={actionTextColorSet}
            reversed={props.reversed}
            cardSize={cardSize}
            titleSize={props.titleSize}
            subtitleSize={props.subtitleSize}
            kickerSize={props.kickerSize}
            hideSubtitle={props.hideSubtitle}
            hideKicker={props.hideKicker}
        />
    );
};
type StoreFrontHzCardProps = {
    linkTo: string;
    cardSize: SizeName | RemSize;
    aspectRatio: AspectRatio;
    imageUrl: ImageUrl | undefined;
    reversed?: boolean;
    actionText: Translatable;
    actionTextColorSet: ColorSet;
    title: Translatable;
    titleSize?: number | RemSize;
    subtitle?: Translatable;
    subtitleSize?: number | RemSize;
    hideSubtitle?: boolean;
    kicker?: Translatable;
    kickerSize?: number | RemSize;
    hideKicker?: boolean;
};
const StoreFrontHzCard = (props: StoreFrontHzCardProps) => {
    const theme = useTheme();
    const textSize =
        props.titleSize ??
        1.07 *
            (typeof props.cardSize === "number"
                ? props.cardSize
                : theme.sizes.inRem[props.cardSize as SizeName]);

    const subtitleSize = props.subtitleSize ?? 0.73 * textSize;
    const kickerSize = props.kickerSize ?? 0.45 * textSize;
    return (
        <LinkWithLanguage to={props.linkTo}>
            <SectionAreaSizedCard
                size={props.cardSize}
                aspectRatio={props.aspectRatio}
                background={{
                    color: theme.colors.primary,
                    fit: "COVER",
                    repeat: "NO-REPEAT",
                    imageUrl: props.imageUrl,
                }}>
                <FlexColumn
                    fillParent
                    tag="SECTION_AREA_CARD"
                    shrink={0}
                    grow={1}
                    reversed={props.reversed}
                    padding={{ all: 20 }}
                    background={{
                        linearGradient: props.reversed
                            ? darkeningGradientReverse
                            : darkeningGradient,
                    }}
                    mainAxis="space-between"
                    crossAxis="stretch">
                    <FlexRow mainAxis="flex-end">
                        <StoreFrontCardPill
                            text={props.actionText}
                            colorSet={props.actionTextColorSet}
                        />
                    </FlexRow>
                    <FlexColumn shrink={0} crossAxis="flex-start">
                        <TextBox
                            text={props.title}
                            weight="900"
                            size={textSize}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 2px 5px rgba(0, 0, 0, 0.4)",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                            }}
                        />
                        {!props.hideSubtitle && props.subtitle ? (
                            <>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.subtitle}
                                    weight="400"
                                    size={subtitleSize}
                                    color={theme.colors.white}
                                    lineClamp={3}
                                    css={{
                                        textShadow:
                                            "0 2px 5px rgba(0, 0, 0, 0.4)",
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                    }}
                                />
                            </>
                        ) : null}
                        {!props.hideKicker && props.kicker ? (
                            <>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.kicker}
                                    weight="800"
                                    size={kickerSize}
                                    case="UPPERCASE"
                                    color={theme.colors.white}
                                    css={{
                                        textShadow:
                                            "0 2px 5px rgba(0, 0, 0, 0.4)",
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                    }}
                                />
                            </>
                        ) : null}
                    </FlexColumn>
                </FlexColumn>
            </SectionAreaSizedCard>
        </LinkWithLanguage>
    );
};

const ResponsiveStoreFrontHzProductCard = (props: Props) => {
    const theme = useTheme();
    const widthIsLessThan415 = useMediaQuery("(max-width: 414px)");

    const requestedSize =
        typeof props.cardSize === "number"
            ? props.cardSize
            : props.cardSize
              ? theme.sizes.inRem[props.cardSize]
              : (1.25 as RemSize);

    const cardSize = remSize(
        requestedSize > 1.4 || widthIsLessThan415
            ? requestedSize
            : requestedSize * 1.1,
    );

    return <StoreFrontHzProductCard {...props} cardSize={cardSize} />;
};
const ResponsiveStoreFrontHzCard = (props: StoreFrontHzCardProps) => {
    const theme = useTheme();
    const widthIsLessThan415 = useMediaQuery("(max-width: 414px)");

    const requestedSize =
        typeof props.cardSize === "number"
            ? props.cardSize
            : props.cardSize
              ? theme.sizes.inRem[props.cardSize]
              : (1.25 as RemSize);

    const cardSize = remSize(
        requestedSize > 1.4 || widthIsLessThan415
            ? requestedSize
            : requestedSize * 1.1,
    );

    return <StoreFrontHzCard {...props} cardSize={cardSize} />;
};

const ResponsiveStoreFrontHzAreaSizedCard = (
    props: SectionAreaCardProps & Pick<Props, "cardSize">,
) => {
    const theme = useTheme();
    const widthIsLessThan415 = useMediaQuery("(max-width: 414px)");

    const requestedSize =
        typeof props.cardSize === "number"
            ? props.cardSize
            : props.cardSize
              ? theme.sizes.inRem[props.cardSize]
              : (1.25 as RemSize);

    const cardSize = remSize(
        requestedSize > 1.4 || widthIsLessThan415
            ? requestedSize
            : requestedSize * 1.1,
    );

    return <SectionAreaSizedCard {...props} size={cardSize} />;
};

export {
    ResponsiveStoreFrontHzAreaSizedCard,
    ResponsiveStoreFrontHzCard,
    ResponsiveStoreFrontHzProductCard,
    StoreFrontHzCard,
    StoreFrontHzProductCard,
};
