import {
    OnResizeCallback,
    useResizeObserver,
} from "@@/shared/use-resize-observer";
import * as React from "react";
import { useRef } from "react";
import { DOMRectJSON } from "./use-size-tracker";

function useElementSize<T extends HTMLElement = HTMLDivElement>(params?: {
    targetRef?: undefined;
}): [DOMRectJSON | undefined, React.RefObject<T>];
function useElementSize<T extends HTMLElement = HTMLDivElement>(params: {
    targetRef: React.RefObject<T>;
}): [DOMRectJSON | undefined, React.RefObject<T>];
function useElementSize<T extends HTMLElement = HTMLDivElement>(params: {
    targetRef?: undefined;
    fallback: DOMRectJSON;
}): [DOMRectJSON, React.RefObject<T>];
function useElementSize<T extends HTMLElement = HTMLDivElement>(params: {
    targetRef: React.RefObject<T>;
    fallback: DOMRectJSON;
}): [DOMRectJSON, React.RefObject<T>];
function useElementSize<T extends HTMLElement = HTMLDivElement>(params?: {
    targetRef?: React.RefObject<T>;
    fallback?: DOMRectJSON;
}): readonly [DOMRectJSON | undefined, React.RefObject<T>] {
    const _requestedTargetRef = params?.targetRef;
    const _localTargetRef = useRef<T | null>(null);

    const fallbackDomRect = params?.fallback;
    const [size, setSize] = React.useState<DOMRectJSON | undefined>(
        fallbackDomRect,
    );

    const [targetRef] = React.useState<React.RefObject<T>>(
        _requestedTargetRef ?? _localTargetRef,
    );

    const onResize = React.useCallback<OnResizeCallback>(
        newSize => {
            setSize(newSize.borderBoxSize);
        },
        [setSize],
    );

    // Where the magic happens
    useResizeObserver<T>({
        targetRef,
        onResize,
    });

    // return [size, targetRef] as const;
    return React.useMemo(() => {
        return [size, targetRef] as const;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size, targetRef.current]);
}

export { useElementSize };
