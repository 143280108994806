import { useModal } from "@@/modals/use-modal";
import { AutoHeightTextModal } from "@@/shared/auto-height-text-modal";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { TextProvider, useTextState } from "@@/shared/text-context";
import { useTheme } from "@emotion/react";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import {
    Padding,
    RemSize,
    SizeName,
    Translatable,
    sizeNameMap,
    translation,
} from "@towni/common";

const AddMessageRowContent = (props: {
    size?: SizeName;
    title?: Translatable;
}) => {
    const theme = useTheme();
    const { text: message } = useTextState();
    const size = props.size ?? sizeNameMap.M;
    const hasMessage = !!message?.trim();
    return (
        <>
            {/* If there's a message this is a headline */}
            <TextBox
                text={
                    props.title ?? hasMessage
                        ? translation({
                              sv: "Särskilda önskemål",
                              en: "Special requirements",
                          })
                        : translation({
                              sv: "+ Särskilda önskemål",
                              en: "+ Special requirements",
                          })
                }
                size={size}
                color={theme.colors.black.light60}
            />
            <Conditional
                when={hasMessage}
                render={() => (
                    <>
                        <VerticalDivider XS />
                        <ForEach itemOf={message.split("\n")}>
                            {(messageRow, index) => (
                                <TextBox
                                    key={messageRow + index.toString()}
                                    text={messageRow}
                                    size={size}
                                />
                            )}
                        </ForEach>
                    </>
                )}
            />
        </>
    );
};

type Props = {
    initialMessage: string;
    title?: Translatable;
    placeholder?: Translatable;
    onSetMessage: (message: string) => void;
    size?: SizeName;
    padding?: Padding;
    showPen?: boolean;
};

const AddMessageRow = (props: Props) => {
    const [{ show }, modalId] = useModal("auto_height_text_box_modal__");

    return (
        <TextProvider
            initialText={props.initialMessage}
            onSetText={props.onSetMessage}>
            <FlexRow
                fillParentWidth
                onClick={show}
                padding={props.padding ?? { all: 20 }}
                mainAxis="space-between"
                css={{
                    cursor: "pointer",
                    userSelect: "none",
                    flexShrink: 0,
                }}>
                <FlexColumn>
                    <AddMessageRowContent
                        title={props.title}
                        size={props.size}
                    />
                </FlexColumn>
                <Conditional
                    when={!!props.showPen}
                    render={() => (
                        <Icon icon={faPencilAlt} size={1 as RemSize} />
                    )}
                />
            </FlexRow>
            <AutoHeightTextModal
                modalId={modalId}
                placeholder={props.placeholder}
            />
        </TextProvider>
    );
};

export { AddMessageRow };
