import { Section, SectionId } from "@towni/common";
import { useAlfredItemsForSection } from "@@/providers/state/provider-fetchers";
import { useMemo } from "react";
import { useSection } from "./state/section-fetchers";

const useActiveSectionItemCount = (section: Section | undefined) => {
    const [products, query] = useAlfredItemsForSection(section?._id);
    const count = useMemo(
        () =>
            products.filter(product => product.status.type === "ACTIVE").length,
        [products, section]
    );

    return [count, !query.isLoading] as const;
};
const useActiveSectionItemCountBySectionId = (
    sectionId: SectionId | undefined
) => {
    const [section, _sectionQuery] = useSection(sectionId);
    return useActiveSectionItemCount(section);
};

export { useActiveSectionItemCount, useActiveSectionItemCountBySectionId };
