import { css, useTheme } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";
import { ModalId } from "./context/modal-id";

type Props = {
    modalId: ModalId;
    ignoreAnimation: boolean;
    onClick?: () => void;
};
const ModalBackdrop = (props: Props) => {
    const theme = useTheme();
    const { modalId, onClick } = props;

    const style = css({
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        cursor: onClick ? "pointer" : "unset",
        zIndex: 0,
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        pointerEvents: onClick ? "auto" : "none",
    });
    return (
        <AnimatePresence>
            <motion.div
                layout="position"
                layoutId={modalId + "_backdrop"}
                key={modalId + "_backdrop"}
                initial={{
                    opacity: 0,
                    pointerEvents: "none",
                }}
                animate={{
                    opacity: 1,
                    pointerEvents: "auto",
                }}
                exit={{
                    opacity: 0,
                    pointerEvents: "none",
                }}
                onClick={onClick}
                css={style}
                transition={
                    props.ignoreAnimation
                        ? {
                              duration: 0,
                          }
                        : theme.spring
                }
            />
        </AnimatePresence>
    );
};

export { ModalBackdrop };
