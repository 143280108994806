import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox, TextSpan } from "@@/shared/text";
import { use100vhMotionValue } from "@@/shared/use-100-vh";
import { css, useTheme } from "@emotion/react";
import { faCookieBite as faCookieBiteSolid } from "@fortawesome/pro-light-svg-icons/faCookieBite";
import { RemSize, translation } from "@towni/common";
import { motion } from "framer-motion";
import ReactDOM from "react-dom";
import { useCookieConsent } from "./use-cookie-consent";

const cookieBannerElementId = "cookiebanner";

const CookieBanner = () => {
    const theme = useTheme();
    const height = use100vhMotionValue();

    // Currently using the same as modal, could possibly use modal?
    const { consent, setConsent } = useCookieConsent();
    const portalElement = document.getElementById(cookieBannerElementId);
    if (!portalElement)
        throw new Error(
            `Cookie banner portal container can't be found; element: ${cookieBannerElementId}`,
        );

    if (consent) return null;

    return ReactDOM.createPortal(
        <ErrorBoundary>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                }}
                exit={{
                    opacity: 0,
                }}
                transition={theme.spring}
                style={{ height }}
                css={{
                    display: "block",
                    position: "absolute",
                    width: "100%",
                    zIndex: 99999,
                    top: -20,
                    left: 0,
                }}>
                <FlexColumn
                    fillParent
                    mainAxis="flex-end"
                    crossAxis="center"
                    padding={{ leftRight: 20 }}>
                    <FlexColumn
                        padding={{ all: 20 }}
                        css={{
                            maxWidth: 700,
                            pointerEvents: "all",
                            borderRadius: 8,
                            boxShadow: `0px 1px 5px 1px ${theme.colors.default.shadow.asString}`,
                            backgroundColor:
                                theme.colors.default.background.asString,
                        }}>
                        <FlexRow padding={{ all: 5 }}>
                            <FlexRow>
                                <TextBox size={0.8}>
                                    <Icon
                                        icon={faCookieBiteSolid}
                                        size={1.6 as RemSize}
                                        color={theme.colors.primary}
                                        css={{
                                            float: "left",
                                            marginRight: 7,
                                        }}
                                    />
                                    <TextSpan
                                        text={
                                            "Vi använder kakor för att ge en snabbare och säkrare upplevelse. Genom att använda vår tjänst godkänner du dessa i enlighet med våran "
                                        }
                                        color={
                                            theme.colors.textInput.placeholder
                                        }
                                    />
                                    <TextSpan
                                        text={"policy"}
                                        onClick={() =>
                                            open(
                                                "https://storage.googleapis.com/legal-alfred/cookies.pdf",
                                            )
                                        }
                                        color={theme.colors.primary}
                                        hoverColor={theme.colors.secondary}
                                        hoverStyle={css({
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        })}
                                    />
                                </TextBox>
                            </FlexRow>
                        </FlexRow>
                        <VerticalDivider />
                        <FlexRow mainAxis="flex-end">
                            <ButtonPrimary
                                fillParentWidth
                                onClick={() => {
                                    setConsent(true);
                                }}>
                                <FlexRow
                                    mainAxis="center"
                                    fillParentWidth
                                    padding={{ topBottom: 5 }}>
                                    <TextBox
                                        text={translation({
                                            sv: "Jag fattar",
                                            en: "Understood",
                                        })}
                                        align="center"
                                        color={theme.colors.primary.text}
                                    />
                                </FlexRow>
                            </ButtonPrimary>
                        </FlexRow>
                    </FlexColumn>
                </FlexColumn>
            </motion.div>
        </ErrorBoundary>,
        portalElement,
    );
};

export { CookieBanner };
