import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import { Provider } from "@towni/common";
import { providerMainQueryKey } from "../main-query-key";
import { fetchProviders } from "./providers.fetcher";

const useProviders = (
    providerIdsOrSlugs: string[] | undefined
): readonly [Provider[], UseQueryResult<Provider[]>] => {
    const idsOrSlugs = providerIdsOrSlugs ?? [];
    return useApiResources<Provider>(
        createQueryKey(providerMainQueryKey, idsOrSlugs),
        fetchProviders(idsOrSlugs),
        {
            enabled: !!idsOrSlugs.length,
        }
    );
};

export { useProviders };
