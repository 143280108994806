import { useBackofficeContext } from "@@/backoffice/backoffice-context";
import { BreadCrumbPathProvider } from "@@/shared/bread-crumb-id-context";
import { Conditional } from "@@/shared/conditional";
import { FlexAlignment, FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { Interpolation, useTheme } from "@emotion/react";
import { ColorValue, emptyArrayOf } from "@towni/common";
import { AnimatePresence, motion, useScroll, useSpring } from "framer-motion";
// import { usePageScrollOnNavigation } from "./page-navigation/use-page-scroll-on-navigation";
import { AppTheme } from "@@/styles/theme";
import debounce from "lodash.debounce";
import * as React from "react";
import { useLayoutEffect, useRef } from "react";
import { AppPageSettingsProvider } from "./app-page-settings-context";
import {
    ContainerNavigationContextProvider,
    useContainerNavigationContext,
} from "./container-navigation.context";
import { Anchor } from "./container-navigation/anchor";
import { NavigationBarContainer } from "./container-navigation/container-navigation-bar";
import { PageLoad } from "./page-load";
import type { PageAnimationHide } from "./page-navigation/page-animation-wrapper";
import { PageAnimationWrapper } from "./page-navigation/page-animation-wrapper";
import { AppPageId } from "./page-navigation/page-navigation-context";
import {
    useAutoRestoreScrollPosition,
    useScrollPositionAutoRecorder,
} from "./scroll-position-context";

type Props = {
    pageId: AppPageId;
    scrollResetId?: string;
    pageTitle?: string;
    title?: string;
    children?: React.ReactNode;
    showNavMenu?: boolean;
    header?: React.ReactNode;
    topBar?: React.ReactNode;
    // /**
    //  * Between 0 and 1, it will count as a percentage
    //  * @type {number}
    //  */
    // height?: number;
    // /**
    //  * Between 0 and 1, it will count as a percentage
    //  * @type {number}
    //  */
    // maxHeight?: number;
    // /**
    //  * defaults to 100%
    //  *
    //  * @type {(string | number)}
    //  */
    // minHeight?: string | number;
    forgetScrollPosition?: boolean;
    backButton?: JSX.Element;
    mainAxis?: FlexAlignment;
    crossAxis?: FlexAlignment;
    backgroundColor?: ColorValue;
    overflowY?: React.CSSProperties["overflowY"];
    pageContainerCss?: Interpolation<AppTheme>;
};

const AppPage = ({ pageId, title, children, ...props }: Props) => {
    const theme = useTheme();
    const {
        anchors,
        container,
        setContainer,
        navigateToAnchorId,
        setContainerScrollPositionY,
    } = useContainerNavigationContext(context => ({
        anchors: context.anchors,
        container: context.containerElement,
        setContainer: context.setContainerElement,
        navigateToAnchorId: context.navigateToAnchorId,
        setContainerScrollPositionY: context.setContainerScrollPositionY,
    }));

    const [scrollSupport, setScrollSupport] = React.useState(true);
    const { isBackoffice } = useBackofficeContext();

    const containerRef = useRef<HTMLElement | null>(null);

    // const percentMaxHeight = props.maxHeight
    //     ? setWithinPercentageRange(props.maxHeight)
    //     : 1;

    // const percentHeight = props.height
    //     ? setWithinRange(props.height, { min: 0, max: percentMaxHeight })
    //     : percentMaxHeight;

    if (!props.forgetScrollPosition) {
        // Above conditional for below hooks is ok ONLY because it
        // always returns the same thing for the same page in this circumstance
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAutoRestoreScrollPosition(pageId, containerRef, props.scrollResetId);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollPositionAutoRecorder(pageId, containerRef);
    }

    const scrollPositionY = useSpring(container?.scrollTop ?? 0, {
        damping: 30,
        stiffness: 200,
    });

    useLayoutEffect(() => {
        // Scroll nav bar menu on navigation in
        // container if necessary
        const headerOffset = 80;
        if (!container) return;
        if (!navigateToAnchorId) return;
        const targetAnchor = anchors.get(navigateToAnchorId);
        if (!targetAnchor) return;

        const scrollTo = targetAnchor.containerItemElement?.offsetTop;
        if (typeof scrollTo === "undefined") {
            return;
        }
        scrollPositionY.set(scrollTo - headerOffset);
    }, [navigateToAnchorId, anchors, container]);

    React.useLayoutEffect(() => {
        // Scroll page when scroll position y value changes
        // to the changed y value
        if (!container) return;

        if (!container?.scrollTo) {
            setScrollSupport(false);
            return;
        }

        const unsubscribe = scrollPositionY.on("change", value => {
            container?.scrollTo?.(0, value);
        });
        return () => unsubscribe();
    }, [container]);

    const { scrollY } = useScroll({ container: containerRef });
    React.useEffect(() => {
        if (!container) return;
        const unsubscribe = scrollY.on(
            "change",
            debounce((value: number) => {
                setContainerScrollPositionY(value);
            }, 50),
        );
        return () => unsubscribe();
    }, [container]);

    return (
        <>
            {(props.backButton && (
                <div
                    css={{
                        minHeight: 30,
                        display: "block",
                        position: "sticky",
                        pointerEvents: "auto",
                        zIndex: 8888,
                        height: 40,
                        overflowY: "hidden",
                        top: 20,
                        marginTop: -40,
                        paddingLeft: 20,
                    }}>
                    {props.backButton}
                </div>
            )) ||
                null}

            <FlexColumn
                fillParentWidth
                mainAxis="space-between"
                crossAxis="stretch"
                background={{ color: theme.colors.default.background }}
                // height={
                //     !props.maxHeight
                //         ? `${Math.floor(percentHeight * 100)}%`
                //         : ""
                // }
                minHeight={"100%"}
                // maxHeight={
                //     props.maxHeight
                //         ? `${Math.floor(percentMaxHeight * 100)}%`
                //         : ""
                // }
                css={{
                    bottom: 0,
                    label: "APP-PAGE",
                }}>
                <motion.div
                    key={pageId}
                    ref={ref => {
                        containerRef.current = ref;
                        setContainer(ref);
                    }}
                    css={{
                        width: "100%",
                        height: "100%",
                        flex: 1,
                        backgroundColor:
                            theme.colors.default.background.asString,
                        pointerEvents: "auto",
                        WebkitOverflowScrolling: "touch",
                        overflowY: props.overflowY ?? "auto",
                        overflowX: "hidden",
                        position: "relative",
                        label: "page_motion",
                    }}
                    onWheel={() => {
                        scrollPositionY.stop();
                    }}
                    onMouseDown={() => {
                        scrollPositionY.stop();
                    }}
                    onTouchMove={() => {
                        scrollPositionY.stop();
                    }}
                    onTouchStart={() => {
                        scrollPositionY.stop();
                    }}
                    title={title}>
                    <FlexColumn
                        fillParentWidth
                        // fillParentHeight
                        mainAxis={props.mainAxis ?? "stretch"}
                        crossAxis={props.crossAxis ?? "stretch"}
                        background={{
                            color:
                                props.backgroundColor ?? isBackoffice
                                    ? theme.isDarkTheme
                                        ? theme.colors.default.background
                                              .light10
                                        : theme.colors.default.background
                                              .light90
                                    : theme.colors.default.background,
                        }}
                        css={[
                            {
                                position: "relative",
                                zIndex: 10,
                                label: "page_container",
                                // height: "100%",
                                flex: 1,
                                minHeight: "100%",
                                //  overflowY: props.overflowY ?? "scroll", overflow is handled by motion.div
                            },
                            props.pageContainerCss,
                        ]}>
                        {props.header || null}
                        {props.showNavMenu && (
                            <>
                                {!scrollSupport ? (
                                    <TextBox
                                        text="Din webbläsare är lite för gammal och stöder inte fullt ut vår menynavigering. Du kan fortfarande använda applikationen men måste tyvärr scrolla manuellt."
                                        size="S"
                                        color={theme.colors.black.light60}
                                        padding={{ all: 20 }}
                                    />
                                ) : (
                                    <div
                                        css={{
                                            position: "sticky",
                                            backgroundColor:
                                                theme.colors.default.background
                                                    .asString,
                                            top: 0,
                                            zIndex: 10,
                                            label: "nav_menu_container",
                                        }}>
                                        <NavigationBarContainer
                                            pageId={pageId}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {children}
                    </FlexColumn>
                </motion.div>
            </FlexColumn>
        </>
    );
};

const AppPageContainer = (
    props: Props & {
        anchors?: Anchor[];
        isLoading?: boolean;
        /**
         * Prevents page slide animation
         * @type {boolean}
         */
        pageAnimationDisabled?: boolean;
        modalEmbedded?: boolean;
        hide?: PageAnimationHide;
    },
) => {
    const theme = useTheme();
    const { anchors: _anchors, ...rest } = props;
    const anchors = _anchors?.length ? _anchors : emptyArrayOf<Anchor>();

    // If desktop (large and wide view), disable page animations no matter wjat
    const pageAnimationDisabled = !!props.pageAnimationDisabled;

    return (
        <>
            <AppPageSettingsProvider
                key={props.pageId}
                modalEmbedded={props.modalEmbedded}
                pageAnimationDisabled={pageAnimationDisabled}>
                <ContainerNavigationContextProvider
                    key={props.pageId}
                    anchors={anchors}>
                    <BreadCrumbPathProvider node={props.pageId}>
                        <AnimatePresence key={props.pageId} initial={true}>
                            <PageAnimationWrapper
                                key={props.pageId}
                                ignoreAnimation={pageAnimationDisabled}
                                pageId={props.pageId}
                                hide={props.hide}>
                                <Conditional
                                    key={props.pageId + "-spinner"}
                                    when={!!props.isLoading}
                                    render={() => (
                                        <PageLoad
                                            backgroundColor={
                                                theme.colors.default.background
                                            }
                                        />
                                    )}
                                />
                                <FlexColumn
                                    tag="PAGE"
                                    key={props.pageId}
                                    fillParent
                                    overflow="hidden"
                                    shrink={0}
                                    css={{
                                        position: "relative",
                                        opacity: props.isLoading ? 0 : 1,
                                        label: "APP_PAGE_VISIBILITY_WRAPPER",
                                    }}>
                                    <AppPage key={props.pageId} {...rest} />
                                </FlexColumn>
                            </PageAnimationWrapper>
                        </AnimatePresence>
                    </BreadCrumbPathProvider>
                </ContainerNavigationContextProvider>
            </AppPageSettingsProvider>
        </>
    );
};

export { AppPageContainer as AppPage };
export type { Props as PageProps };
