import { useModal } from "@@/modals/use-modal";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    HexColor,
    RemSize,
    Translatable,
    colorFactory,
    translation,
} from "@towni/common";
import copy from "copy-to-clipboard";
import { useRef, useState } from "react";
import { Conditional } from "../conditional";
import { VerticalDivider } from "../dividers";
import { ColorPickerModal } from "./color-picker-modal";

type Props = {
    title?: Translatable;
    /**
     * defaults to white (#ffffff)
     */
    color: HexColor | undefined;
    onSelect: (color: HexColor) => void;
};

const ColorPicker = (props: Props) => {
    const theme = useTheme();
    const color = props.color;
    const colorRef = useRef(color);
    const [initialColor, setInitialColor] = useState(colorRef.current);

    const [{ toggle }, modalId] = useModal("color_picker_modal__");

    const onColorPick = (_color: HexColor) => {
        colorRef.current = _color;
        props.onSelect(_color);
    };
    const parsedColor = color ? colorFactory(color) : undefined;
    //url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><g stroke="%23000" stroke-width="2" stroke-linejoin="round"><path fill="%23FFF" d="m4 16-1 5 5-1 6-6-4-4-6 6z"></path><path fill="%23000" d="M19 5a2.84 2.84 0 0 0-4 0l-3 3-1-1c-.55-.55-1.45-.55-2 0s-.55 1.45 0 2l1 1 4 4 1 1c.55.55 1.45.55 2 0s.55-1.45 0-2l-1-1 3-3c1.1-1.1 1.1-2.9 0-4Z"></path></g></svg>')
    return (
        <>
            <FlexColumn fillParentWidth onClick={toggle}>
                <FlexRow
                    mainAxis="stretch"
                    crossAxis="stretch"
                    css={[
                        {
                            borderRadius: 5,
                            backgroundColor:
                                color ??
                                theme.colors.default.background.asString,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.colors.textInput.border.asString,
                        },
                        !color
                            ? {
                                  // Draw a red diagonal line if no color is selected
                                  background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M0 10 L10 0' stroke='%23efefef' stroke-width='2%' fill='red' /></svg>")`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center center",
                                  backgroundSize: "100% 100%, auto",
                              }
                            : {},
                    ]}>
                    <Conditional when={!color}>
                        <FlexRow
                            mainAxis="center"
                            crossAxis="center"
                            css={{ flex: 1 }}>
                            <TextBox
                                text={translation({
                                    sv: "Ingen färg vald",
                                    en: "No color selected",
                                })}
                                align="center"
                                css={{
                                    padding: 15,
                                }}
                                color={theme.colors.default.text.value.withAlpha(
                                    0.3,
                                )}
                            />
                        </FlexRow>
                    </Conditional>
                    {parsedColor ? (
                        <FlexRow
                            mainAxis="flex-end"
                            crossAxis="flex-end"
                            css={{
                                flex: 1,
                                padding: 5,
                                paddingTop: 30,
                            }}>
                            <FlexRow
                                onClick={e => {
                                    copy(parsedColor.value.asHex);
                                    e.stopPropagation();
                                }}
                                css={{
                                    padding: "3px 6px",
                                    borderRadius: 3,
                                    backgroundColor: parsedColor.value.isLight
                                        ? theme.colors.default.background.withAlpha(
                                              0.3,
                                          ).asString
                                        : theme.colors.default.text.value.withAlpha(
                                              0.3,
                                          ).asString,
                                    color: parsedColor.value.isLight
                                        ? theme.colors.default.text.value.withAlpha(
                                              0.5,
                                          ).asString
                                        : theme.colors.default.background.withAlpha(
                                              0.5,
                                          ).asString,

                                    //          cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z"/></svg>'),16 16 default`,
                                }}>
                                <TextBox
                                    text={parsedColor.value.asHex}
                                    size={0.625}
                                    weight="700"
                                />
                            </FlexRow>
                        </FlexRow>
                    ) : null}
                </FlexRow>
                <VerticalDivider />
                <TextBox
                    text={props.title}
                    align="center"
                    size={0.65 as RemSize}
                    weight="800"
                    case="UPPERCASE"
                    color={theme.colors.default.text.value.withAlpha(0.5)}
                />
            </FlexColumn>
            <ColorPickerModal
                modalId={modalId}
                initialColor={
                    initialColor ?? theme.colors.default.background.value.asHex
                }
                title={props.title}
                onSelect={onColorPick}
                onHide={() => {
                    // When modal closes, set initial color to current color
                    // to be able to revert to it instead of the initial initial 👀 color
                    setInitialColor(colorRef.current);
                }}
            />
        </>
    );
};

export { ColorPicker };
