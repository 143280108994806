import { useDiscountCodeCheckMutation } from "@@/backoffice/for-providers/discounts/discount-code/hooks/use-discount-code-check";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox, TextEdit } from "@@/shared/text";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { useTheme } from "@emotion/react";
import { isApiError, isOrderItemDiscount_V2, translation } from "@towni/common";
import { useState } from "react";
import { useCartContext } from "../multi-carts/cart.context";

import { browserLogger } from "@@/settings";
import { calculateDiscountRows } from "./discount-service";
type Props = {
    modalId: ModalId;
};

const AddDiscountModal = (props: Props) => {
    const theme = useTheme();
    const { hide } = useModalActions(props.modalId);
    const [code, setCode] = useState<string>();
    const [codeWithError, setCodeWithError] = useState<string>();

    const { cart, addItemToCart } = useCartContext(context => ({
        cart: context.cart,
        addItemToCart: context.addItemToCart,
    }));
    const [cartProvider, _cartProviderQuery] = useProvider(cart.providerId);
    const checkCodeMutation = useDiscountCodeCheckMutation();

    const containsCode = cart.orderItems.some(isOrderItemDiscount_V2);
    useUpdateEffect(() => {
        if (checkCodeMutation.error) setCodeWithError(code);
        else setCodeWithError(undefined);
    }, [checkCodeMutation.error]);

    if (!cartProvider) return null;
    const hasApiError =
        codeWithError === code &&
        checkCodeMutation.isError &&
        isApiError(checkCodeMutation.error);

    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    title={translation({
                        sv: "Lägg till rabattkod",
                        en: "Add discount code",
                    })}
                />
            }>
            <FlexColumn fillParentWidth crossAxis="center" mainAxis="flex-end">
                <FlexRow
                    fillParentWidth
                    overflowY="hidden"
                    css={{ padding: 20, paddingBottom: 0 }}>
                    <TextEdit
                        placeholder={translation({
                            sv: "Rabattkod",
                            en: "Discount code",
                        })}
                        type="text"
                        onChange={setCode}
                        errorMessage={
                            (hasApiError &&
                                isApiError(checkCodeMutation.error) &&
                                checkCodeMutation.error.reason) ||
                            undefined
                        }
                        value={code}
                        //    multiline={1}
                        showSpinner={checkCodeMutation.isPending}
                    />
                </FlexRow>
                <Conditional when={!containsCode && !hasApiError}>
                    <FlexColumn padding={{ top: 5, leftRight: 25 }}>
                        <TextBox
                            size={0.8}
                            color={theme.colors.default.text.light60}
                            text={translation({
                                sv: "Det går bara att använda en rabattkod åt gången, lägger du till en ny tas den nuvarande bort.",
                                en: "You can only use one discount code at a time, if you add a new one the current one will be removed.",
                            })}
                        />
                    </FlexColumn>
                </Conditional>

                <FlexRow
                    mainAxis="flex-end"
                    crossAxis="center"
                    fillParentWidth
                    css={{ padding: 20 }}>
                    <ButtonWhite onClick={hide}>
                        <TextBox
                            text={translation({
                                sv: "Avbryt",
                                en: "Cancel",
                            })}
                        />
                    </ButtonWhite>
                    <HorizontalDivider />
                    <ButtonPrimary
                        disabled={!code}
                        onClick={async () => {
                            if (cartProvider?._id && code) {
                                try {
                                    const response =
                                        await checkCodeMutation.mutateAsync({
                                            providerId: cartProvider?._id,
                                            id: code,
                                        });
                                    if (response) {
                                        setCode(undefined);
                                        const items = calculateDiscountRows(
                                            cartProvider._id,
                                            cart.orderItems,
                                            response,
                                        );
                                        if (items.length === 1)
                                            addItemToCart(items[0]);
                                        setCode("");
                                        hide();
                                    }
                                } catch (error) {
                                    browserLogger.error(error);
                                }
                            }
                        }}>
                        <TextBox
                            text={translation({
                                sv: "Använd rabattkod",
                                en: "Use discount",
                            })}
                        />
                    </ButtonPrimary>
                </FlexRow>
            </FlexColumn>
        </Modal>
    );
};

export { AddDiscountModal };
