import { ifApiErrorDontThrow } from "@@/authentication/phone/steps/if-api-error-dont-throw";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { Inputs } from "@@/shared/form/standalones/inputs";
import { TextBox } from "@@/shared/text";
import { PhoneNumber, translation } from "@towni/common";
import {
    usePhoneSignInOrUpDispatch,
    usePhoneSignInOrUpState,
} from "../phone-sign-in-or-sign-in-context";
import { usePhoneSignInInitiateCommand } from "../use-phone-sign-in-initiate-command";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};
/**
 *
 *
 * @param {Props} _props
 * @return {*}
 */
const PhoneSignIn = (_props: Props) => {
    const state = usePhoneSignInOrUpState();
    const [actions] = usePhoneSignInOrUpDispatch();
    const phoneSignInInit = usePhoneSignInInitiateCommand();

    const initiatePhoneAuthentication = async () => {
        await phoneSignInInit
            .mutateAsync({
                phoneNumber: state.data.phoneNumber,
                signUpId: state.data._id,
            })
            .catch(ifApiErrorDontThrow);
    };

    return (
        <FlexColumn fillParent overflow="hidden">
            <FlexColumn overflowY="scroll" crossAxis="center">
                <Inputs.PhoneNumberInput
                    fillParentWidth
                    placeholder={translation({
                        sv: "Telefonnummer",
                        en: "Phone number",
                    })}
                    onChange={({ phoneNumber, isValid }) => {
                        if (isValid) actions.setPhoneNumber(phoneNumber);
                        else {
                            actions.setUnvalidatedPhoneNumber(
                                phoneNumber ?? ("" as PhoneNumber),
                            );
                            actions.setPhoneNumber("" as PhoneNumber);
                        }
                    }}
                    // onRawChange={actions.setUnvalidatedPhoneNumber}
                    // onChange={actions.setPhoneNumber}
                    initialPhoneNumber={state.data.unvalidatedPhoneNumber}
                />
                <VerticalDivider L />
                <ButtonPrimary
                    fillParentWidth
                    onClick={initiatePhoneAuthentication}
                    spin={phoneSignInInit.isPending}
                    disabled={
                        !state.data.phoneNumber || phoneSignInInit.isPending
                    }>
                    <TextBox
                        text={translation({
                            sv: "Fortsätt",
                            en: "Continue",
                        })}
                        weight="700"
                    />
                </ButtonPrimary>
            </FlexColumn>
        </FlexColumn>
    );
};

export { PhoneSignIn };
