import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import { Provider } from "@towni/common";
import { providerMainQueryKey } from "../main-query-key";
import { fetchProvidersForArea } from "./providers-for-area.fetcher";

const useProvidersForArea = (
    areas: string[]
): readonly [Provider[], UseQueryResult<Provider[]>] => {
    return useApiResources(
        createQueryKey(providerMainQueryKey, areas),
        fetchProvidersForArea(areas),
        { enabled: !!areas.length }
    );
};

export { useProvidersForArea };
