import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { InfoBox_FlexColumn } from "@@/backoffice/shared/info-box.flex-column";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { CheckBoxWithText, Paragraph } from "@@/shared/text";
import { useLocalStorageFlag } from "@@/shared/use-local-storage";
import { translation } from "@towni/common";
import { z } from "zod";

const debugEnabledLocalStorageKey = "debug_enabled";

const DebugToggle = () => {
    const [debugEnabled, setDebugEnabled] = useLocalStorageFlag(
        debugEnabledLocalStorageKey,
        false,
    );

    const hasAlfredScope = useHasAlfredScope();
    if (!hasAlfredScope) return null;

    return (
        <FlexColumn fillParentWidth>
            <CheckBoxWithText
                checked={debugEnabled}
                disabled={!hasAlfredScope}
                onChange={value => {
                    setDebugEnabled(value);
                }}
                text={translation({
                    en: "Debug enabled",
                    sv: "Debug aktiverat",
                })}
            />
            <VerticalDivider />
            <InfoBox_FlexColumn>
                <Paragraph
                    content={[
                        translation({
                            sv: "När debug-läge är aktiverat, spottar appen ur sig fler konsoll-loggningar och kan också visa debug-komponenter och verktyg för de som har rättigheter till det.",
                            en: "When debug mode is enabled, the app spits out more console logs and can also show debug components and tools for those who have the right permissions.",
                        }),
                    ]}
                />
            </InfoBox_FlexColumn>
        </FlexColumn>
    );
};

const hasLocalStorage = typeof window !== "undefined" && window.localStorage;
const isDebugEnabled = () =>
    hasLocalStorage &&
    z.coerce
        .boolean()
        .parse(localStorage.getItem(debugEnabledLocalStorageKey) === "true");
const useDebugEnabled = () =>
    useLocalStorageFlag(debugEnabledLocalStorageKey, false);

export {
    DebugToggle,
    debugEnabledLocalStorageKey,
    isDebugEnabled,
    useDebugEnabled,
};
