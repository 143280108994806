import {
    Byte,
    CreateSignedUploadUrlCommand,
    createSignedUploadUrlCommandFactory,
    FinishUploadCommand,
    finishUploadCommandFactory,
    guessContentTypeFromFilename,
    isStorageItemId,
    Percentage,
    ProviderId,
    StorageItemId,
    UploadId,
} from "@towni/common";
import { apiFetchClient } from "../fetch-client";
import { UploadError } from "./upload-error";
type CustomTextFile = { _type: "TEXT_FILE"; name: string; data: string };
const isCustomTextFile = (
    input: File | CustomTextFile
): input is CustomTextFile => {
    return "_type" in input && input._type === "TEXT_FILE";
};
const uploadFile = async <Id extends StorageItemId>(params: {
    file: File | CustomTextFile;
    /**
     * If left undefined, the file is interpreted as a global file
     * and special privileges are required to upload it
     * @type {(ProviderId | undefined)}
     */
    providerId: ProviderId | undefined;
    storageItemId: Id;
    /**
     * uploads can be grouped by upload id \
     * e.g. if an upload is part of a form, upload id could be the same
     * for all uploades within a form
     * @type {string}
     */
    uploadId: UploadId;
    onProgress?: (storageItemId: Id, progress: Percentage) => void;
}): Promise<Id> => {
    const { file, providerId } = params;

    // Validate storage item id
    const storageItemId = params.storageItemId;
    if (
        typeof storageItemId !== "undefined" &&
        !isStorageItemId(storageItemId)
    ) {
        throw new Error("bad storageItemId provided; " + storageItemId);
    }
    try {
        // Get content type
        const contentType = guessContentTypeFromFilename(file.name.trim());

        // If upload is a text file, use the string data as file data
        const isFile = !isCustomTextFile(file);
        const fileData = isFile ? file : file.data;
        const filesize = (isFile ? file.size : file.data.length) as Byte;

        // Request a signed upload url
        const signedUploadUrl = await apiFetchClient.post<
            CreateSignedUploadUrlCommand,
            string
        >({
            route: `/storage-items/uploads/create-signed-upload-url`,
            body: createSignedUploadUrlCommandFactory({
                contentType,
                filesize,
                originalFilename: file.name,
                providerId,
                storageItemId,
                uploadId: params.uploadId,
            }),
        });

        // Upload image
        // - progress? (can't be done with fetch though, have to switch to xhr)
        const upload = new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUploadUrl);
            xhr.upload.addEventListener("progress", event => {
                if (params.onProgress) {
                    const percent = (
                        event.lengthComputable ? event.loaded / event.total : 0
                    ) as Percentage;
                    params.onProgress(storageItemId, percent);
                }
            });
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status >= 200 || xhr.status < 300) {
                        return resolve();
                    }
                    return reject(new Error(xhr.statusText));
                }
            };
            xhr.setRequestHeader("Content-Type", contentType);
            xhr.send(fileData);
        });
        await upload;

        // Finish upload by calling api
        await apiFetchClient.post<FinishUploadCommand>({
            route: `/storage-items/uploads/finish-upload`,
            body: finishUploadCommandFactory({
                storageItemId,
            }),
        });

        return storageItemId;
    } catch (error) {
        throw new UploadError(
            `Uppladdning av ${params.file.name} misslyckades`,
            storageItemId,
            error
        );
    }
};

export { uploadFile };
