import { useCart } from "@@/carts/multi-carts/cart.context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import {
    getChildOptionals,
    isOrderItemBuyable_V2,
    ProductId,
    Translatable,
    translation,
} from "@towni/common";
import { useMemo } from "react";
import { ExtrasProps } from "../product-details-page";
import { BookableExtraOrderItemRow } from "./bookable-extra-order-item-row";

type Props = {
    extras: ExtrasProps[];
    productIdsToIgnore: ProductId[];
    title?: Translatable;
    canBeRemoved?: boolean;
    disableActions?: boolean;
    onRemove?: () => void;
};
const ExtrasOrderItemsV2 = (props: Props) => {
    const cart = useCart();
    const orderItems = useMemo(
        () =>
            cart.orderItems.filter(
                item =>
                    isOrderItemBuyable_V2(item) &&
                    !props.productIdsToIgnore?.includes(item.productId),
            ),
        [cart.orderItems, props.productIdsToIgnore],
    );
    if (orderItems.length === 0) return null;

    const legacyOrderItems = orderItems.filter(
        r =>
            r.extraGroup === undefined ||
            !props.extras.some(eg => eg.extras._id === r.extraGroup?._id),
    );

    return (
        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
            <Conditional
                when={!!props.title}
                render={() => (
                    <FlexColumn fillParentWidth crossAxis="center">
                        <VerticalDivider L />
                        <TextBox
                            size={"ML"}
                            weight="700"
                            text={translation({
                                sv: "Tillagda tillval",
                                en: "Added extras",
                            })}
                        />
                        <VerticalDivider L />
                    </FlexColumn>
                )}
            />
            <ForEach
                itemOf={props.extras}
                getKey={e => e.extras._id}
                divider={<VerticalDivider L />}>
                {(extraProp, _index) => {
                    const extra = extraProp.extras;
                    const extraItems = orderItems.filter(
                        oi => oi.extraGroup?._id === extra._id, //&& oi.parentId === extraProp.parentOrderItemId
                    );

                    if (extraItems.length === 0) return null;

                    return (
                        <>
                            <TextBox weight="700" text={extra.meta.title} />
                            <VerticalDivider S />
                            <ForEach
                                itemOf={extraItems}
                                divider={<VerticalDivider L />}>
                                {(orderItem, _index) => {
                                    return (
                                        <BookableExtraOrderItemRow
                                            orderItem={orderItem}
                                            picked={getChildOptionals(
                                                orderItem,
                                                cart.orderItems,
                                            )}
                                            extras={extraProp}
                                            disableActions={
                                                props.disableActions
                                            }
                                            onRemove={props.onRemove}
                                            backgroundColor
                                        />
                                    );
                                }}
                            </ForEach>
                        </>
                    );
                }}
            </ForEach>

            <ForEach
                itemOf={legacyOrderItems}
                beginWith={
                    <>
                        <VerticalDivider />
                        <TextBox
                            size="S"
                            text={
                                props.extras.length > 0
                                    ? translation({
                                          sv: "Gamla tillval som inte går att match med nuvarande produkt:",
                                          en: "Old extras that can't be matched with current product:",
                                      })
                                    : translation({
                                          sv: "Valda tillval:",
                                          en: "Picked extras:",
                                      })
                            }
                        />
                        <VerticalDivider S />
                    </>
                }
                divider={<VerticalDivider />}>
                {(orderItem, _index) => {
                    return (
                        <BookableExtraOrderItemRow
                            orderItem={orderItem}
                            picked={getChildOptionals(
                                orderItem,
                                cart.orderItems,
                            )}
                            // extras={extraProp}
                            disableActions={props.disableActions}
                            onRemove={props.onRemove}
                            backgroundColor
                        />
                    );
                }}
            </ForEach>
            <VerticalDivider L />
        </FlexColumn>
    );
};

export { ExtrasOrderItemsV2 };
