/* eslint-disable @typescript-eslint/no-unused-vars */
import { useResourceReservationRequestInfoForProduct } from "@@/resource-reservations/state/resource-reservation-info/use-resource-reservation-info-for-product";
import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    calculateAvailabilitySpecialized,
    generateResourceTimeRanges,
    getBookableResourceReferences,
    getOverrideResource,
    ResourceAvailability,
    TimeRange,
    TimeRangeResourceAvailability,
} from "@towni/common";
import { useCallback, useMemo } from "react";
import { useBookingContext } from "../booking-context";
import { useRequestedQuantities } from "./use-requested-quantities";

const useBookingForTimeRangeChecker = () => {
    const state = useBookingContext(state => ({
        sku: state.sku,
        product: state.product,
        bookable: state.bookable,
        session: state.session,
        isOnPremiseBooking: state.isOnPremiseBooking,
        allowBookingIsSplit: state.allowBookingIsSplit,
        ignoreOrder: state.ignoreOrder,
    }));
    const requestedQuantities = useRequestedQuantities();
    const [globalResources, _] = useBookableResources(state.sku.productId);

    const [resourceReservationInfo, _resourceReservationInfoQuery] =
        useResourceReservationRequestInfoForProduct(
            state.product._id,
            state.ignoreOrder,
        );

    const skuReferences = getBookableResourceReferences(state.bookable);
    const resources = skuReferences.map(item => item.resource);

    const checkAvailability = useCallback(
        (timeRange: TimeRange, overrideSession?: boolean) => {
            const resourcesAvailability = Array.from(requestedQuantities.keys())
                .map(resourceId => {
                    const resource = resources.find(
                        item => item._id === resourceId,
                    );
                    const skuReference = skuReferences.find(
                        s => s.resource._id === resourceId,
                    );
                    const requestedQuantity =
                        requestedQuantities.get(resourceId);
                    if (!resource) return undefined;
                    if (!skuReference) return undefined;
                    if (typeof requestedQuantity === "undefined")
                        return undefined;

                    const specialized = skuReferences.flatMap(current => {
                        const times = generateResourceTimeRanges(
                            timeRange,
                            current,
                            state.session,
                        );
                        return times;
                    });
                    //If the resourceId is overridden, we need to use the overridden resource
                    //Resource_Local
                    const overrideResource = getOverrideResource(
                        state.session,
                        skuReference,
                    );

                    const resourceAvailability =
                        calculateAvailabilitySpecialized(
                            [overrideResource],
                            requestedQuantities,
                            globalResources,
                            specialized,
                            resourceReservationInfo,
                            state.session?.bookableSettings ??
                                state.bookable.settings,
                            {
                                allowSplitBookings: state.allowBookingIsSplit,
                                maxGuestPerTime:
                                    state.bookable.type === "TABLE_BOOKING"
                                        ? state.bookable.maxGuestsPerSession
                                        : undefined,
                                limitTableToClosest:
                                    skuReference?.resourcePickerEnabled ===
                                    "LIMIT_FOR_CUSTOMER",
                                sessionId: state.session?._id,
                                overrideSession: overrideSession,
                            },
                        );

                    return resourceAvailability[0];
                })
                .filter(Boolean) as ResourceAvailability[];

            const result: TimeRangeResourceAvailability = {
                timeRange,
                resourcesAvailability,
            };

            return result;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            requestedQuantities,
            resources,
            skuReferences,
            globalResources,
            resourceReservationInfo,
            state.session,
            state.bookable.settings,
            state.bookable.type,
            state.allowBookingIsSplit,
        ],
    );

    return [checkAvailability] as const;
};

const useBookingForTimeRanges = (timeRanges: TimeRange[]) => {
    const [checkAvailability] = useBookingForTimeRangeChecker();
    const resourceAvailabilityPerTimeRange = useMemo(() => {
        return timeRanges.map(timeRange => {
            return checkAvailability(timeRange);
        });
    }, [checkAvailability, timeRanges]);

    return [resourceAvailabilityPerTimeRange] as const;
};

export { useBookingForTimeRangeChecker, useBookingForTimeRanges };
