import { useUpdateEffect } from "@@/shared/use-update-effect";
import {
    FreePaymentProvider,
    OnPremisePaymentProvider,
    StripePaymentProvider,
    SwishPaymentProvider,
} from "@towni/common";
import { createContext, useContext, useRef } from "react";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { PaymentDetails } from "./payment-details";

type SwishPaymentTypeSelection = {
    name: SwishPaymentProvider["name"];
    type: SwishPaymentProvider["paymentTypes"][number];
};

type StripePaymentTypeSelection = {
    name: StripePaymentProvider["name"];
    type: StripePaymentProvider["paymentTypes"][number];
};

type ProviderPaymentTypeSelection = {
    name: OnPremisePaymentProvider["name"];
    type: OnPremisePaymentProvider["paymentTypes"][number];
};

type FreePaymentTypeSelection = {
    name: FreePaymentProvider["name"];
    type: FreePaymentProvider["paymentTypes"][number];
};

type PaymentTypeSelection =
    | SwishPaymentTypeSelection
    | StripePaymentTypeSelection
    | ProviderPaymentTypeSelection
    | FreePaymentTypeSelection;

type ContextState = State & Actions;
type State = {
    readonly selectedPaymentType: PaymentTypeSelection | undefined;
    readonly paymentDetails: PaymentDetails | undefined;
    readonly paymentFinished: boolean;
    readonly acceptSwish: boolean;
};
type Actions = {
    readonly selectPaymentType: (
        selection: PaymentTypeSelection | undefined,
    ) => void;
    readonly setPaymentDetails: (details: PaymentDetails | undefined) => void;
    readonly setPaymentFinished: () => void;
};

const PaymentDetailsContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    readonly initialPaymentTypeSelection?: PaymentTypeSelection;
    readonly initialPaymentDetails?: PaymentDetails;
    readonly acceptSwish?: boolean;
    readonly children?: React.ReactNode;
};

const PaymentDetailsContextProvider = (props: Props) => {
    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, _get) => {
            const selectPaymentType = (
                selection: PaymentTypeSelection | undefined,
            ) => {
                set({ selectedPaymentType: selection });
            };
            const setPaymentDetails = (details: PaymentDetails | undefined) => {
                set({ paymentDetails: details });
            };
            const setPaymentFinished = () => {
                set({ paymentFinished: true });
            };

            const context: ContextState = {
                selectedPaymentType: props.initialPaymentTypeSelection,
                acceptSwish: props.acceptSwish ?? true,
                paymentDetails: props.initialPaymentDetails,
                paymentFinished: false,
                selectPaymentType,
                setPaymentDetails,
                setPaymentFinished,
            };

            return context;
        }),
    );

    useUpdateEffect(() => {
        store.current.setState({ acceptSwish: props.acceptSwish });
    }, [props.acceptSwish]);

    return (
        <PaymentDetailsContext.Provider value={store.current}>
            {props.children}
        </PaymentDetailsContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const usePaymentDetailsContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(PaymentDetailsContext);
    if (store === undefined) {
        throw new Error(
            "usePaymentDetailsContext must be used within a PaymentDetailsContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { PaymentDetailsContextProvider, usePaymentDetailsContext };
export type { PaymentTypeSelection };
