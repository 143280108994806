import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useTheme } from "@emotion/react";
import { ColorItem, colorAsString } from "@towni/common";

const TicketDivider = (props: {
    topBackgroundColor: ColorItem;
    bottomBackgroundColor: ColorItem;
    colorBehind?: ColorItem;
}) => {
    const theme = useTheme();
    return (
        <FlexRow
            fillParentWidth
            crossAxis="stretch"
            background={{ color: theme.colors.transparent }}
            css={{ height: 22, overflow: "hidden" }}>
            <div
                css={{
                    position: "relative",
                    width: 11,
                    height: "100%",
                    flexShrink: 0,
                    // transform: "scale(-1, 1)",
                    // transformOrigin: "center",
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 50 100"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%">
                    <mask id="circular-hole">
                        <rect
                            x="0"
                            y="0"
                            width="50"
                            height="100"
                            fill="white"></rect>
                        <circle cx="0" cy="50" r="50" fill="black"></circle>
                    </mask>
                    <rect
                        width="50"
                        height="50"
                        fill="#ffffff"
                        mask="url(#circular-hole)"></rect>
                    <rect
                        width="50"
                        height="50"
                        y="50"
                        fill="#ffffff"
                        mask="url(#circular-hole)"></rect>
                </svg>
            </div>
            <FlexColumn grow={1} shrink={1} crossAxis="stretch">
                <FlexRow
                    fillParentWidth
                    height={"55%"}
                    background={{ color: props.topBackgroundColor }}
                    css={{
                        borderBottom:
                            "2px dashed " +
                            colorAsString(
                                props.colorBehind ?? theme.colors.black.light35,
                            ),
                    }}></FlexRow>
                <FlexRow
                    fillParentWidth
                    height={"45%"}
                    background={{
                        color: props.bottomBackgroundColor,
                    }}></FlexRow>
            </FlexColumn>
            <div
                css={{
                    position: "relative",
                    width: 11,
                    height: "100%",
                    transform: "scale(-1, 1)",
                    transformOrigin: "center",
                    flexShrink: 0,
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 50 100"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%">
                    <mask id="circular-hole">
                        <rect
                            x="0"
                            y="0"
                            width="50"
                            height="100"
                            fill="white"></rect>
                        <circle cx="0" cy="50" r="50" fill="black"></circle>
                    </mask>
                    <rect
                        width="50"
                        height="50"
                        fill="#ffffff"
                        mask="url(#circular-hole)"></rect>
                    <rect
                        width="50"
                        height="50"
                        y="50"
                        fill="#ffffff"
                        mask="url(#circular-hole)"></rect>
                </svg>
            </div>
        </FlexRow>
    );
};

export { TicketDivider };
