/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import * as React from "react";
import { ComponentType } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ContextProviderTuple<P = any> = [
    provider: ComponentType<P>,
    props: P | undefined
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ContextProvider<P = any> = ComponentType<P> | ContextProviderTuple<P>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const contextProviderFactory = <P extends unknown = any>(
    provider: ComponentType<P>,
    props: P
): ContextProviderTuple<P> => {
    return [provider, props] as ContextProviderTuple<P>;
};

type Props = {
    providers: ContextProvider[];
    children?: React.ReactNode;
};

const MultiContextProvider: ComponentType<Props> = props => {
    if (!props.providers?.length) {
        return <>props.children</> ?? null;
    }

    return (
        <>
            {props.providers.reduceRight((agg, item) => {
                const Provider = Array.isArray(item) ? item[0] : item;
                const props = Array.isArray(item) ? item[1] : {};
                return props ? (
                    <Provider {...(props || {})}>{agg}</Provider>
                ) : (
                    <Provider>{agg}</Provider>
                );
            }, props.children)}
        </>
    );
};

export { MultiContextProvider, contextProviderFactory };
export type { ContextProviderTuple, ContextProvider };
