import { darkeningGradientDual } from "@@/shared/bg-darkening-gradient";
import { BackButtonNoCircle } from "@@/shared/buttons/back-button-without-circle";
import { Conditional } from "@@/shared/conditional";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { MetaImage, Translatable } from "@towni/common";
import * as React from "react";
import { FC } from "react";

type Props = {
    poster: MetaImage | undefined;
    title: Translatable;
    shortDescription?: Translatable;
    children?: React.ReactNode;
};

const SectionGroupPoster: FC<Props> = props => {
    const theme = useTheme();
    return (
        <FlexColumn
            fillParentWidth
            background={{
                imageReference: props.poster,
                fit: "COVER",
                imageSize: { imageWidth: theme.sizes.imageSizes.large },
            }}
            css={{
                maxHeight: 400,
                height: props.poster ? "50vh" : undefined,
            }}
            overflow="hidden"
            shrink={0}
            mainAxis="stretch"
            crossAxis="stretch"
            tag="SECTION_GROUP_POSTER">
            <FlexColumn
                fillParent
                background={{
                    color: theme.colors.transparent,
                    linearGradient: props.poster
                        ? darkeningGradientDual
                        : undefined,
                }}
                padding={{ all: 20 }}
                mainAxis="space-between"
                crossAxis="stretch">
                <FlexRow shrink={0} mainAxis="space-between" crossAxis="center">
                    <BackButtonNoCircle
                        size="XL"
                        color={theme.colors.white.value.withAlpha(0.9)}
                    />
                    <Spacer shrink={0}>
                        <CurrentUserIcon size="XL" theme="WHITE" />
                    </Spacer>
                </FlexRow>
                <FlexColumn
                    shrink={0}
                    mainAxis="flex-end"
                    crossAxis="flex-start">
                    <TextBox
                        text={props.title}
                        size="3XL"
                        weight="800"
                        color={theme.colors.white}
                        css={{
                            textShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
                        }}
                    />
                    <Conditional
                        when={!!props.shortDescription}
                        render={() => (
                            <>
                                <VerticalDivider XXS />
                                <TextBox
                                    text={props.shortDescription}
                                    size="ML"
                                    weight="400"
                                    color={theme.colors.white}
                                    css={{
                                        textShadow:
                                            "0 4px 12px rgba(0, 0, 0, 0.3)",
                                    }}
                                />
                            </>
                        )}
                    />
                </FlexColumn>
            </FlexColumn>
        </FlexColumn>
    );
};

export { SectionGroupPoster };
