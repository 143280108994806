import { useCart } from "@@/carts/multi-carts/cart.context";
import { useProducts } from "@@/products/state/queries/products/use-products";
import {
    OptionalGroup,
    OrderItem,
    calculateMinMaxRange,
    checkExtrasConditions,
    isOrderItemBookableQuantity_V2,
    isProductOrderItem,
    orderItemsFromSku,
    sum,
} from "@towni/common";
import { useMemo } from "react";
import { useBookingContext } from "../../booking-context";

const useIsBookingCompleteWithExtrasAndOptionals = (
    optionals: OptionalGroup[],
) => {
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        optionValues: context.optionValues,
        isOnPremiseBooking: context.isOnPremiseBooking,
        requestedResources: context.requestedResources,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        settings: context.bookable.settings,
        pickedOptionalsMap: context.pickedOptionalsMap,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));
    const cart = useCart();

    const extras = useMemo(
        () => state.bookable.extrasGroups ?? [],
        [state.bookable.extrasGroups],
    );

    return useMemo(() => {
        const continueDisabled =
            // where there is an option group...
            !!optionals
                // with a required minimal number of options...
                .some(optionalGroupOrOptionalAmount => {
                    if (!optionalGroupOrOptionalAmount.minMax.min) return false; // undefined or 0, not required
                    const picked = state.pickedOptionalsMap.get(
                        optionalGroupOrOptionalAmount._id,
                    );
                    if (!picked) return true;

                    const notEnough =
                        picked.length <
                        optionalGroupOrOptionalAmount.minMax.min;
                    return notEnough;
                });

        const allExtrasPicked = extras
            .filter(extra => {
                const conditionsMet = checkExtrasConditions(extra, {
                    onPremise: state.isOnPremiseBooking,
                });
                if (!conditionsMet) return false;
                return true;
            })
            .map(extra => {
                const orderItemsForExtra = cart.orderItems.filter(
                    oi => oi.extraGroup?._id === extra._id,
                );

                const settings = extra.settings;
                if (settings._type === "FREE_AMOUNT") return true;

                if (settings._type === "BOOKABLE_OPTION_AMOUNT") {
                    if (settings.bookableOptions.length === 0) return true;

                    const quantityItems = cart.orderItems.filter(
                        oi =>
                            isOrderItemBookableQuantity_V2(oi) &&
                            oi.optionId &&
                            settings.bookableOptions.includes(oi.optionId),
                    );

                    const items = orderItemsForExtra.filter(
                        oi =>
                            isProductOrderItem(oi) &&
                            extra.products
                                .map(p => p.productId)
                                .includes(oi.productId),
                    );

                    const amountPicked = sum(items, oi => oi.quantity.value);

                    const amountToBePicked = sum(
                        quantityItems,
                        oi => oi.quantity.value,
                    );

                    const minMax = calculateMinMaxRange(
                        extra,
                        amountToBePicked,
                    );

                    if (
                        amountPicked >= (minMax.min ?? 0) &&
                        amountPicked <= (minMax.max ?? Number.MAX_SAFE_INTEGER)
                    )
                        return true;
                    return false;
                }

                if (settings._type === "MIN_MAX_AMOUNT") {
                    const items = orderItemsForExtra.filter(
                        oi =>
                            isProductOrderItem(oi) &&
                            extra.products
                                .map(p => p.productId)
                                .includes(oi.productId),
                    );

                    const amount = sum(items, oi => oi.quantity.value);

                    if (
                        amount >= (settings.minMax.min ?? 0) &&
                        amount <=
                            (settings.minMax.max ?? Number.MAX_SAFE_INTEGER)
                    )
                        return true;
                }
                return false;
            })
            .every(x => x);
        return !!continueDisabled || !allExtrasPicked;
    }, [optionals, state.pickedOptionalsMap, extras, cart.orderItems]);
};

const useCalculateObligatoryExtras = (_optionals: OptionalGroup[]) => {
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        provider: context.provider,
        optionValues: context.optionValues,
        isOnPremiseBooking: context.isOnPremiseBooking,
        requestedResources: context.requestedResources,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        settings: context.bookable.settings,
        pickedOptionalsMap: context.pickedOptionalsMap,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));

    const extras = useMemo(
        () => state.bookable.extrasGroups ?? [],
        [state.bookable.extrasGroups],
    );

    const [products] = useProducts(
        extras.flatMap(r => r.products.map(p => p.productId)),
    );

    const getExtras = (orderItems: OrderItem[]) => {
        const allExtrasPicked = extras.flatMap(extra => {
            const orderItemsForExtra = orderItems.filter(
                oi => oi.extraGroup?._id === extra._id,
            );

            if (extra.products.length !== 1) return [];

            const obligatoryProduct = products.find(
                p => p._id === extra.products[0].productId,
            );

            if (!obligatoryProduct) return [];

            const settings = extra.settings;
            if (settings._type === "FREE_AMOUNT") return [];

            if (settings._type === "BOOKABLE_OPTION_AMOUNT") {
                if (settings.bookableOptions.length === 0) return [];

                const quantityItems = orderItems.filter(
                    oi =>
                        isOrderItemBookableQuantity_V2(oi) &&
                        oi.optionId &&
                        settings.bookableOptions.includes(oi.optionId),
                );

                const items = orderItemsForExtra.filter(
                    oi =>
                        isProductOrderItem(oi) &&
                        extra.products
                            .map(p => p.productId)
                            .includes(oi.productId),
                );

                const amountPicked = sum(items, oi => oi.quantity.value);

                const amountToBePicked = sum(
                    quantityItems,
                    oi => oi.quantity.value,
                );

                const minMax = calculateMinMaxRange(extra, amountToBePicked);

                if (
                    amountPicked >= (minMax.min ?? 0) &&
                    amountPicked <= (minMax.max ?? Number.MAX_SAFE_INTEGER)
                )
                    return [];

                return orderItemsFromSku({
                    sku: obligatoryProduct.skus[0],
                    product: obligatoryProduct,
                    provider: state.provider,
                    quantity: {
                        amount: obligatoryProduct.skus[0].acquire.amount,
                        value: amountToBePicked,
                    },
                    discounts: [],
                    pickedOptions: [],
                    // requiredOptionals.map(ro => {
                    //     return {
                    //         quantity: {
                    //             value: 1,
                    //             amount: ro.amount ?? defaultAmount,
                    //         },
                    //         optional: ro,
                    //     };
                    // }),
                    extra: {
                        _id: extra._id,
                        meta: extra.meta,
                    },
                });
            }

            if (settings._type === "MIN_MAX_AMOUNT") {
                const items = orderItemsForExtra.filter(
                    oi =>
                        isProductOrderItem(oi) &&
                        extra.products
                            .map(p => p.productId)
                            .includes(oi.productId),
                );

                const amount = sum(items, oi => oi.quantity.value);

                if (
                    amount >= (settings.minMax.min ?? 0) &&
                    amount <= (settings.minMax.max ?? Number.MAX_SAFE_INTEGER)
                ) {
                    return [];
                }

                return orderItemsFromSku({
                    sku: obligatoryProduct.skus[0],
                    product: obligatoryProduct,
                    provider: state.provider,
                    quantity: {
                        amount: obligatoryProduct.skus[0].acquire.amount,
                        value: settings.minMax.min ?? 0,
                    },
                    discounts: [],
                    pickedOptions: [],
                    // requiredOptionals.map(ro => {
                    //     return {
                    //         quantity: {
                    //             value: 1,
                    //             amount: ro.amount ?? defaultAmount,
                    //         },
                    //         optional: ro,
                    //     };
                    // }),
                    extra: {
                        _id: extra._id,
                        meta: extra.meta,
                    },
                });
            }
            return [];
        });
        return allExtrasPicked;
    };
    return getExtras;
};
export {
    useCalculateObligatoryExtras,
    useIsBookingCompleteWithExtrasAndOptionals,
};
