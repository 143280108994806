import { WithAlfred } from "@@/authentication/me/with-alfred";
import { darkeningGradientReverse } from "@@/shared/bg-darkening-gradient";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { HorizontalDivider } from "@@/shared/dividers";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { faLanguage } from "@fortawesome/pro-regular-svg-icons";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { OneSquareSection } from "../shared/one-square-section";
import { NavenColorContext, navenColors } from "./naven-color-context";
import { NavenLanguagePicker } from "./naven-language-picker";
import { NavenExperienceSection } from "./sections/nave-experience-section";
import { NavenLackoExperienceSection } from "./sections/nave-lacko-experience-section";
import { NavenDiscoverMoreSection } from "./sections/naven-discover-more-section";
import { NavenExploreSection } from "./sections/naven-explore-section";
import { NavenFourCanoe } from "./sections/naven-four-canoe-section";
import { NavenLackoFourCanoe } from "./sections/naven-lacko-four-canoe-section";
import { NavenLearnCanoe } from "./sections/naven-learn-canoe-section";

type Props = {
    title?: string;
};

const NavenStoreFront = (props: Props) => {
    const vh100 = use100vh();
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <NavenColorContext.Provider value={navenColors}>
                <Helmet>
                    <title>{props.title ?? "Naven Outdoor"}</title>
                </Helmet>
                <AppPage
                    pageId={appPageIdFactory("naven-store-front")}
                    crossAxis={isDesktop ? "center" : undefined}
                    anchors={[]}
                    header={
                        <FlexRow
                            fillParentWidth
                            shrink={0}
                            mainAxis="flex-end"
                            crossAxis="center"
                            padding={{ leftRight: 20, topBottom: 10 }}
                            css={{
                                position: "absolute",
                                zIndex: 50,
                            }}>
                            <CurrentUserIcon theme="PRIMARY" />
                        </FlexRow>
                    }>
                    {/* Header Image >>>> */}
                    <FlexColumn
                        fillParentWidth
                        shrink={0}
                        height={vh100 * 0.45}
                        maxHeight={675}
                        background={{
                            fit: "COVER",
                            imageUrl:
                                "https://we-are-alfred.imgix.net/destinations/naven-header-2022.png?dpr=2&q=60&fm=jpg&w=1200&fit=crop&crop=edges",
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ top: 55, leftRight: 32 }}
                            background={{
                                linearGradient: darkeningGradientReverse,
                            }}>
                            <TextBox
                                text="Naven outdoor"
                                color={"#ffffff"}
                                size={2.25 as RemSize}
                                weight="900"
                                css={{
                                    textShadow:
                                        "0px 4px 20px rgba(5, 32, 72, 0.6)",
                                }}
                            />
                            <VerticalDivider size="3XS" />
                            <TextBox
                                text={translation({
                                    sv: "Aktiviter i Vänerskärgården",
                                    en: "Activities in the Vänern Archipelago",
                                })}
                                color={"#ffffff"}
                                size={1.5 as RemSize}
                                css={{
                                    textShadow:
                                        "0px 4px 20px rgba(5, 32, 72, 0.6)",
                                }}
                            />
                        </FlexColumn>
                    </FlexColumn>
                    {/* <<<< Header Image */}

                    {/* Content >>>> */}
                    <FlexColumn fillParentWidth maxWidth={800}>
                        <WithAlfred css={{ marginTop: 20 }}>
                            <NavenLanguagePicker />
                        </WithAlfred>
                        <VerticalDivider XL />
                        <OneSquareSection
                            productId={
                                "product__-Au9W2Cla7UIpLEL8l_G" as ProductId
                            }
                            sectionTitle={translation({
                                sv: "Upplevelser på kring Läckö och Naven",
                                en: "Experiences around Läckö and Naven",
                            })}
                            sectionSubTitle={translation({
                                sv: "Låt oss hjälpa dig ut bland kobbar och skär! Vare sig du vill paddla, gå en utbildning, få en guidad tur eller bara njuta på vår bryggbastu!",
                                en: "Let us help you out among the rocks and reefs! Whether you want to paddle, take a course, get a guided tour or just enjoy our pier sauna!",
                            })}
                            actionText={translation({
                                sv: "Upptäck & Boka",
                                en: "Discover & Book",
                            })}
                            colorSet={navenColors.primary}
                        />
                        <VerticalDivider XL />
                        <NavenFourCanoe />
                        <VerticalDivider XL />
                        <OneSquareSection
                            productId={
                                "product__RxdPdJ26sautittVnHOq" as ProductId
                            }
                            sectionTitle={translation({
                                sv: "Hyr bryggabastu med badtunna grillkåta och utsikt!",
                                en: "Rent pier sauna with hot tub, grill hut and view!",
                            })}
                            sectionSubTitle={translation({
                                sv: "Gemenskap, mat och avkoppling. Finns det en bättre lugn stund än med solnedgång över kobbar och skär?",
                                en: "Community, food and relaxation. Is there a better quiet moment than with a sunset over the rocks and reefs?",
                            })}
                            actionText={translation({
                                sv: "Läs & Boka",
                                en: "Read & Book",
                            })}
                            colorSet={navenColors.primary}
                        />
                        <VerticalDivider XL />
                        <NavenExploreSection />
                        <VerticalDivider XL />
                        <OneSquareSection
                            productId={
                                "product__xYalQSQVWbgd9UITjaUU" as ProductId
                            }
                            sectionTitle={translation({
                                sv: "Upplevelser vid slottet",
                                en: "Experiences at the castle",
                            })}
                            sectionSubTitle={translation({
                                sv: "Läckö är otroligt pampigt från vattnet. Vill du testa på kajak så har vi en perfekt aktivitet för dig nedan",
                                en: "Läckö is incredibly pompous from the water. If you want to try kayaking, we have a perfect activity for you below",
                            })}
                            actionText={translation({
                                sv: "Se tider & boka",
                                en: "See times & book",
                            })}
                            colorSet={navenColors.primary}
                        />
                        <VerticalDivider XL />
                        <NavenLackoFourCanoe />

                        <VerticalDivider XL />
                        <NavenLackoExperienceSection />

                        <VerticalDivider XL />

                        <NavenLearnCanoe />
                        <VerticalDivider XL />

                        <NavenExperienceSection />
                        <VerticalDivider XL />
                        <OneSquareSection
                            productId={
                                "product__eggKSmAJ4SgsftBfv-4f" as ProductId
                            }
                            sectionTitle={translation({
                                sv: "Hyr bastu med grillkåta",
                                en: "Rent sauna with grill hut",
                            })}
                            sectionSubTitle={translation({
                                sv: "Vill du inte ha badtunnan utan bara vår bastu och grillkåta. Den har  fortfarande magisk utsikt och en grillkåta för härlig matlagning!",
                                en: "Do you not want the hot tub but just our sauna and grill hut. It still has a magical view and a grill hut for great cooking!",
                            })}
                            actionText={translation({
                                sv: "Se tider & boka",
                                en: "See times & book",
                            })}
                            colorSet={navenColors.primary}
                        />
                        <VerticalDivider XL />
                        <FlexColumn crossAxis="center">
                            <TextBox
                                text={translation({
                                    sv: "Upplev mer lokalt",
                                    en: "Experience more local",
                                })}
                                size={2}
                                weight="700"
                                align="center"
                            />
                            <VerticalDivider M />
                            <TextBox
                                text={translation({
                                    sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                    en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                                })}
                                color={theme.colors.black.light50}
                                size={1.125}
                                weight="400"
                                align="center"
                                css={{
                                    lineHeight: "1.6rem",
                                }}
                            />
                        </FlexColumn>
                        <VerticalDivider XXL />
                        <NavenDiscoverMoreSection />
                    </FlexColumn>
                    <VerticalDivider size="3XL" />
                    <FlexColumn
                        fillParentWidth
                        crossAxis="flex-start"
                        padding={{ all: 20 }}
                        css={{ flex: 1, maxWidth: 500 }}>
                        <FlexRow
                            crossAxis="center"
                            fillParentWidth
                            css={{ flex: 1 }}>
                            <Icon
                                icon={faLanguage}
                                size={1.8}
                                color={theme.colors.primary}
                            />
                            <HorizontalDivider />
                            <TextBox
                                size={1.125}
                                weight="700"
                                color={theme.colors.primary}
                                text={translation({
                                    sv: "Välj språk",
                                    en: "Select language",
                                })}
                            />
                        </FlexRow>
                        <VerticalDivider XXS />
                        <NavenLanguagePicker />
                    </FlexColumn>
                    {/* <<<< Content */}
                    <VerticalDivider size="3XL" />
                </AppPage>
            </NavenColorContext.Provider>
        </WideScreenWrapper>
    );
};

export { NavenStoreFront };
