import { hejalfredScope, Permission, ProviderId } from "@towni/common";

const asScope = (resourceId: string, permission: Permission) => {
    if (!resourceId || resourceId.length < 10)
        throw new Error(
            "Invalid resource id, either null or less then than 10 characters"
        );

    return `${resourceId}-${permission}`;
};

const getProviderScope = (params: {
    providerId: ProviderId;
    subScope?: string;
    access: Permission;
}) => {
    const subScope = params.subScope?.toLowerCase();
    const { providerId: mainScope, access } = params;

    const scopeRead = `${mainScope}:read`;
    const scopeWrite = `${mainScope}:write`;
    const scopeOwner = `${mainScope}:owner`;

    const subScopeRead = subScope ? `${mainScope}:${subScope}:read` : undefined;
    const subScopeWrite = subScope
        ? `${mainScope}:${subScope}:write`
        : undefined;
    const subScopeOwner = subScope
        ? `${mainScope}:${subScope}:owner`
        : undefined;

    if (subScope) {
        return [
            hejalfredScope,
            ...(access === "read"
                ? ([scopeRead, scopeWrite, subScopeRead, subScopeWrite].filter(
                      Boolean
                  ) as string[])
                : []),
            ...(access === "write"
                ? ([
                      scopeWrite,
                      subScopeWrite,
                      scopeOwner,
                      subScopeOwner,
                  ].filter(Boolean) as string[])
                : []),
            ...(access === "owner"
                ? ([scopeOwner, subScopeOwner].filter(Boolean) as string[])
                : []),
        ];
    } else {
        return [
            hejalfredScope,
            ...(access === "read" ? [scopeRead, scopeWrite, scopeOwner] : []),
            ...(access === "write" ? [scopeWrite, scopeOwner] : []),
            ...(access === "owner" ? [scopeOwner] : []),
        ];
    }
};

export { asScope, getProviderScope };
