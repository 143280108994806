import { useModal } from "@@/modals/use-modal";
import { PageLoad } from "@@/pages/page-load";
import { CheckoutModal } from "@@/payments/checkout-modal";
import { PaymentDetailsContextProvider } from "@@/payments/v2/payment-details.context";
import { AddMessageRow } from "@@/products/add-message-row";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { AddressLink } from "@@/section-groups/address-link";
import { Circle } from "@@/shared/circle";
import { Conditional } from "@@/shared/conditional";
import useConfirm from "@@/shared/confirm/use-confirm";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
    OrderGroupId,
    Provider,
    SECOND,
    asDate,
    getChildOptionals,
    getProviderPrepTime,
    isOrderItemGiftCard_V2,
    isProductOrderItem,
    priceFactory,
    support,
    translation,
} from "@towni/common";
import { isPast, subMinutes } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartItemRow } from "./bookable-checkout/cart-items/cart-item-row";
import { CartRowsAndTotal } from "./bookable-checkout/cart-rows-and-total";
import { CheckoutButton } from "./checkout-button";
import { useCheckoutContext } from "./checkout.context";
import { DeliveryProvider } from "./deliverypicker/delivery-context";
import { DeliveryPickerModal } from "./deliverypicker/delivery-picker";
import {
    calculateOpenTimeSpotsForProvider,
    getFirstOpenTimeSpotForProvider,
} from "./deliverypicker/provider-time-slot-helper";
import { DiscountInCartRow } from "./discount-codes/discount-in-cart-row";
import { GiftCardInCartRow } from "./giftcard/giftcard-in-cart-row";
import { LeaveMyStuffOutsideRow } from "./leave-my-stuff-outside-row";
import { useCart, useCartContext } from "./multi-carts/cart.context";
import { SelectDeliveryMethodRow } from "./select-delivery-method-row";
import { useVerifiedCartState } from "./use-verified-cart";

// COMPONENT
type Props = {
    hide: () => void;
};

const Cart = (props: Props) => {
    const [Confirmation, confirm] = useConfirm();
    const { hide: hideCart } = props;
    const theme = useTheme();
    const translate = useTranslate();
    const {
        cart,
        setCart,
        clearCart,
        setDeliverySelection,
        setMessageToProvider,
    } = useCartContext(context => ({
        cart: context.cart,
        setCart: context.setCart,
        clearCart: context.clearCart,
        setMessageToProvider: context.setMessageToProvider,
        setDeliverySelection: context.setDeliverySelection,
    }));
    const queryClient = useQueryClient();

    useVerifiedCartState(cart, validatedCart => {
        if (validatedCart) setCart(validatedCart);
        else clearCart();
    });
    const madeDeliveryChoice = useCheckoutContext(
        context => context.madeDeliveryChoice,
    );
    const pickup = useIsPickupAvailableForFirstProviderOfCart();
    const pickupUnavailable = !pickup && cart.delivery.method === "PICK_UP";
    const [{ show: showCheckout, hide: hideCheckout }, checkoutModalId] =
        useModal("checkout_modal__");
    const [{ show: showDelivery }, deliveryPickerModalId] = useModal(
        "checkout_delivery_modal__",
    );

    const [paused, setPaused] = useState(false);
    const showCheckoutModal = () => {
        setPaused(true);
        showCheckout();
    };
    const _hideCheckoutModal = () => {
        setPaused(false);
        hideCheckout();
    };

    const navigate = useNavigate();
    const [provider, providerQuery] = useProvider(cart.providerId);
    const messageToProvider = cart.messageToProvider || null;
    const [_tipPrice, setTipPrice] = useState(cart.tipPrice);

    //TIMER
    const [count, setCount] = useState<number | null>(1);
    useEffect(() => {
        if (!provider || !!paused) return;
        // console.log("CART EFFECT _ GET BEST TIME FOR PROVIDER");
        const bestTime = getFirstOpenTimeSpotForProvider(provider, 31);
        // console.log("CART EFFECT _ GET BEST TIME FOR PROVIDER", bestTime);
        if (cart.delivery.method === "PICK_UP") {
            //We have a best time but we have and old time
            if (bestTime === undefined && !!cart.delivery.slot) {
                const time = subMinutes(
                    asDate(cart.delivery.slot.start),
                    getProviderPrepTime(provider),
                );
                if (isPast(time) && !paused) {
                    setDeliverySelection({
                        ...cart.delivery,
                        slot: bestTime,
                    });
                }
            } else if (
                bestTime !== undefined &&
                cart.delivery.slot === undefined
            ) {
                //CART has a ASAP time but there is no ASAP time left
                setDeliverySelection({
                    ...cart.delivery,
                    slot: bestTime,
                });
            }
        }
    }, [provider, count, paused]);

    useEffect(() => {
        if (cart.orderItems.length) return;
        // console.log("CART EFFECT _ HIDING CART");
        hideCart();
    }, [cart]);

    useEffect(() => {
        setTipPrice(
            priceFactory({
                amountIncludingVat: Math.floor(
                    cart.price.amountIncludingVat * cart.tip,
                ),
                vat: 0, // no vat on tips
                currencyCode: cart.price.currencyCode,
            }),
        );
    }, [cart.tip, cart.tipPrice]);

    //Refreshes timespots
    useEffect(() => {
        if (!count) return;
        // console.log("CART EFFECT _ REFRESHING TIME SLOTS");
        const timer = setTimeout(() => {
            setCount(old => {
                return old ? old + 1 : null;
            });
        }, 30 * SECOND);
        return () => {
            clearTimeout(timer);
        };
    }, [count]);

    if (providerQuery.isPending) return <PageLoad />;

    if (!provider) {
        throw new Error(
            `Kunde inte ladda säljardata, försök strax igen eller kontakta ${support.towni.supportEmail} för assistans`,
            {
                cause:
                    providerQuery.error ||
                    new Error(
                        `Provider for cart not found; ${
                            cart._id
                        }, ${cart.orderItems
                            .map(oi => oi.providerId)
                            .join(", ")}`,
                    ),
            },
        );
    }

    return (
        <DeliveryProvider>
            <FlexColumn
                crossAxis="stretch"
                mainAxis="flex-start"
                // overflowY="scroll"
                fillParent
                maxWidth={500}
                grow={1}>
                <FlexColumn
                    fillParentWidth
                    crossAxis="flex-start"
                    shrink={0}
                    padding={{ left: 20 }}>
                    <TextBox
                        text={provider?.meta.title}
                        size="L"
                        weight="900"
                    />
                    <VerticalDivider S />
                    <AddressLink address={provider?.address} size="S" />
                </FlexColumn>
                <VerticalDivider ML />
                <Conditional
                    when={madeDeliveryChoice}
                    render={() => <VerticalDivider M />}
                />
                <Conditional
                    when={cart.delivery.method !== "DIGITAL"}
                    render={() => (
                        <>
                            <HorizontalLine />
                            <SelectDeliveryMethodRow
                                padding={{ topBottom: 15, leftRight: 20 }}
                                provider={provider}
                                deliverySelection={cart.delivery}
                                showModal={showDelivery}
                            />
                            <HorizontalLine />
                            <LeaveMyStuffOutsideRow
                                disabled={pickupUnavailable}
                            />
                        </>
                    )}
                />
                <HorizontalLine />
                {cart.orderItems.map(cartItem => {
                    if (!isProductOrderItem(cartItem)) return;

                    return (
                        <CartItemRow
                            key={cartItem._id}
                            cartItem={cartItem}
                            optional={getChildOptionals(
                                cartItem,
                                cart.orderItems,
                            )}
                        />
                    );
                })}
                <Conditional when={cart.delivery.method !== "DIGITAL"}>
                    <FlexRow
                        onClick={hideCart}
                        crossAxis="center"
                        shrink={0}
                        padding={{ leftRight: 20, topBottom: 15 }}>
                        <Circle
                            size={27}
                            contentSize="M"
                            backgroundColor={theme.colors.primary.light}
                            textColor={theme.colors.primary}>
                            <Icon icon={faPlus} />
                        </Circle>
                        <HorizontalDivider S />
                        <TextBox
                            text={translation({
                                sv: "Fortsätt handla",
                                en: "Continue shopping",
                            })}
                            color={theme.colors.primary}
                            size="S"
                            weight="700"
                        />
                    </FlexRow>
                    <HorizontalLine />
                </Conditional>
                <CartRowsAndTotal />
                <HorizontalLine />
                <GiftCardInCartRow />
                <Conditional
                    when={!cart.orderItems.some(isOrderItemGiftCard_V2)}
                    else={() => <HorizontalLine />}>
                    <HorizontalLine />
                    <DiscountInCartRow />
                </Conditional>

                <AddMessageRow
                    initialMessage={messageToProvider || ""}
                    onSetMessage={setMessageToProvider}
                    padding={{ leftRight: 20, topBottom: 15 }}
                    size="S"
                />
                <HorizontalLine />
                <VerticalDivider size={1.5} />
                <TextBox
                    padding={{ leftRight: 20 }}
                    text={translation({
                        sv: `Genom att gå vidare godkänner du Townis avtal och de regler som är förbundna med ovan objekt. För matallegier eller speciella kostbehov, kontakta vänligen handlaren innan du beställer.`,
                        en: `By proceeding you accept Towni's agreement and the rules associated with the above objects. For food allergies or special dietary needs, please contact the retailer before ordering.`,
                    })}
                    size={0.8}
                    color={theme.colors.default.text.light60}
                />
                <VerticalDivider XL />
                <FlexRow
                    mainAxis="center"
                    padding={{ top: 20, bottom: 40 }}
                    css={{ flexShrink: 0 }}>
                    <TextBox
                        text={translation({
                            sv: "Töm kundkorgen",
                            en: "Empty the shopping cart",
                        })}
                        onClick={async () => {
                            if (
                                await confirm({
                                    message: translation({
                                        sv: "Är du säker på att du vill tömma kundkorgen?",
                                        en: "Are you sure you want to empty your shopping cart?",
                                    }),
                                    confirmBtnText: translation({
                                        sv: "Töm",
                                        en: "Do it",
                                    }),
                                })
                            ) {
                                clearCart();
                                hideCart();
                            }
                        }}
                        size="S"
                        color={theme.colors.default.text.light70}
                    />
                </FlexRow>
                <VerticalDivider XXL />
                <VerticalDivider XXL />
                <FlexRow fillParentWidth>
                    <CheckoutButton
                        text={
                            pickupUnavailable
                                ? translation({
                                      sv: "Ingen avhämtningstid tillgänglig",
                                      en: "No pick-up time available",
                                  })
                                : undefined
                        }
                        disabled={pickupUnavailable}
                        onClick={showCheckoutModal}
                    />
                </FlexRow>
            </FlexColumn>
            <PaymentDetailsContextProvider
                acceptSwish={
                    provider ? provider.options.acceptSwish ?? false : false
                }>
                <CheckoutModal
                    modalId={checkoutModalId}
                    onHide={async () => setPaused(false)}
                    showDeliveryModal={showDelivery}
                    onFinished={(orderGroupId: OrderGroupId) => {
                        queryClient.invalidateQueries({ queryKey: ["orders"] });
                        clearCart();
                        navigate(
                            `/receipts/${encodeURIComponent(
                                orderGroupId,
                            )}?waitforit=true`,
                        );
                    }}
                    delivery={cart.delivery.method !== "DIGITAL"}
                    forceFullHeightMode={true}
                />
            </PaymentDetailsContextProvider>
            <DeliveryPickerModal
                modalId={deliveryPickerModalId}
                provider={provider}
            />
            <Confirmation />
        </DeliveryProvider>
    );
};

const useIsPickupAvailable = (provider?: Provider) => {
    const pickupAvailable = useMemo(() => {
        if (!provider) return false;
        const timeSpotsByFullDate = calculateOpenTimeSpotsForProvider(
            provider,
            31,
        );
        if (!timeSpotsByFullDate) return false;

        const firstDateWithTimeStamp = Object.keys(timeSpotsByFullDate)
            .sort()
            .some(fullDate => timeSpotsByFullDate[fullDate].length > 1);
        return firstDateWithTimeStamp;
    }, [provider]);

    if (!provider) return false;
    return pickupAvailable;
};

const useIsPickupAvailableForFirstProviderOfCart = () => {
    const cart = useCart();
    const [provider] = useProvider(cart.providerId);
    const pickupAvailable = useIsPickupAvailable(provider);
    return pickupAvailable;
};

export {
    Cart,
    useIsPickupAvailable,
    useIsPickupAvailableForFirstProviderOfCart,
};
