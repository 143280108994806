import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import * as React from "react";

type Props = {
    onClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
    spin?: boolean;
};
const AddToCartPillButton = (props: Props) => {
    const { disabled, onClick, className, children } = props;
    return (
        <ButtonPrimary
            radius={3000}
            spin={props.spin}
            disabled={disabled}
            dataTestId="add-to-cart-pill-button"
            onClick={(!disabled && onClick) || undefined}
            fillParentWidth
            padding={{ topBottom: 13, leftRight: 30 }}
            className={className}
            css={[{ borderRadius: 40 }]}>
            {children}
        </ButtonPrimary>
    );
};

export { AddToCartPillButton };
