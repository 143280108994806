import { AccommodationReceipt } from "@@/receipts/accommodation/accommodation-receipt";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import {
    assertNever,
    Order,
    OrderGroup,
    orderHasBookable,
    UserReference,
} from "@towni/common";
import { MutableRefObject } from "react";
import { BookableReceipt } from "./bookables/bookable-receipt";
import { BuyableReceipt } from "./buyables/buyable-receipt";
import { DigitalGiftCardReceipt } from "./digitals/giftcards/digital-giftcard-receipt";
import { DigitalVoucherReceipt } from "./digitals/vouchers/digital-voucher-receipt";

type Props = {
    orderGroup: OrderGroup | undefined;
    orders: Order[];
    customer: UserReference | undefined;
    spin?: boolean;
    printRef: MutableRefObject<HTMLDivElement | null>;
};
const _ReceiptOrderInformation = (props: Props) => {
    const { orders, customer, spin, orderGroup, printRef } = props;

    return (
        <ForEach itemOf={orders} getKey={order => order._id}>
            {order => {
                const type = order.type ?? "BUYABLE";

                if (spin || !customer || !orderGroup) {
                    return <TextShimmer rows={4} />;
                    // isLoading should be enough, but it's not, some kind
                    // of race condition?
                }

                switch (type) {
                    case "BOOKABLE":
                        return (
                            <BookableReceipt
                                customer={customer}
                                orderGroup={orderGroup}
                                orders={orders}
                                printRef={printRef}
                            />
                        );
                    case "MIXED":
                        //Check if bookable or accommodation
                        if (orderHasBookable(order)) {
                            return (
                                <BookableReceipt
                                    customer={customer}
                                    orderGroup={orderGroup}
                                    orders={orders}
                                    printRef={printRef}
                                />
                            );
                        } else {
                            return (
                                <AccommodationReceipt
                                    customer={customer}
                                    orderGroup={orderGroup}
                                    orders={orders}
                                    printRef={printRef}
                                />
                            );
                        }

                        return "MIXED";

                    case "BUYABLE":
                        return (
                            <BuyableReceipt
                                customer={customer}
                                orderGroup={orderGroup}
                                orders={orders}
                                printRef={printRef}
                            />
                        );
                    case "VOUCHER":
                        return (
                            <DigitalVoucherReceipt
                                orderGroup={orderGroup}
                                orders={orders}
                                printRef={printRef}
                            />
                        );
                    case "GIFTCARD":
                        return (
                            <DigitalGiftCardReceipt
                                orderGroup={orderGroup}
                                orders={orders}
                                printRef={printRef}
                            />
                        );
                    case "ACCOMMODATION":
                        return (
                            <AccommodationReceipt
                                customer={customer}
                                orderGroup={orderGroup}
                                orders={orders}
                                printRef={printRef}
                            />
                        );
                    default:
                        assertNever(type);
                }
            }}
        </ForEach>
    );
};

export { _ReceiptOrderInformation };
