import { HorizontalDivider, HorizontalLine } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import type { ResourceGraph } from "@towni/common";

const ResourceGraphRow = (props: {
    resourceGraph: ResourceGraph;
    onClick: () => void;
}) => {
    return (
        <FlexRow
            fillParentWidth
            mainAxis="space-between"
            crossAxis="center"
            onClick={props.onClick}
            padding={{
                all: 20,
            }}>
            <TextBox text={props.resourceGraph.resourceGroup?.meta.title} />
            <HorizontalDivider />
            <Icon icon={faChevronRight} />
        </FlexRow>
    );
};

const ResourceGraphRows = (props: {
    resourceGraphs: ResourceGraph[];
    onClick: (resourceGraph: ResourceGraph) => void;
}) => {
    const { resourceGraphs } = props;
    const onGraphRowClick = (resourceGraph: ResourceGraph) => (): void => {
        props.onClick(resourceGraph);
    };
    return (
        <ForEach
            itemOf={resourceGraphs}
            getKey={resourceGraph => resourceGraph._id}
            divider={<HorizontalLine />}>
            {resourceGraph => (
                <ResourceGraphRow
                    resourceGraph={resourceGraph}
                    onClick={onGraphRowClick(resourceGraph)}
                />
            )}
        </ForEach>
    );
};

export { ResourceGraphRow, ResourceGraphRows };
