import { useLanguageToEditContext } from "@@/backoffice/for-providers/shared/language-to-edit.context";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { ButtonPurpleLight } from "@@/shared/buttons_v2/button-purple-light";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormField } from "@@/shared/form/use-form-field";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { useTextTranslationMutation } from "@@/shared/form/use-text-translation-mutation";
import { Icon } from "@@/shared/icons/icon";
import { paddingToCssValue } from "@@/shared/padding";
import { FieldTitle } from "@@/shared/text/field-title";
import { TextBox } from "@@/shared/text/text-box";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, css, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faLanguage } from "@fortawesome/pro-regular-svg-icons";
import {
    ColorItem,
    Padding,
    RemSize,
    Translatable,
    TranslatableValue,
    asArray,
    compareTranslatables,
    generateId,
    isTranslatableValue,
    svTranslator,
    translatableValueFactory,
    translateCommandFactory,
} from "@towni/common";
import { Draft } from "immer";
import * as React from "react";
import { ZodSchema } from "zod";

type PossibleValueTypes = TranslatableValue | string | undefined | null;
type _DefaultValueType = TranslatableValue | string | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly fieldSchema?: ZodSchema;

    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    readonly fontSize?: RemSize;
    readonly disabled?: boolean;
    readonly spin?: boolean;

    /**
     * defaults to `text`
     * @type {("email" | "text" | "password" | "number" | "time" | "date")}
     */
    readonly type?:
        | "email"
        | "text"
        | "password"
        | "number"
        | "time"
        | "date"
        | "phone";
    /**
     * Optional element to be rendered before the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly preElement?: JSX.Element;
    /**
     * Optional element to be rendered after the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly postElement?: JSX.Element;

    /**
     * Decides wether field should be a text area or regular input of type text. \
     * When set to a number > 0; the number will be used as the number of rows visible for the text-area. \
     * Number of rows defaults to `4` if value of property is set to `true`
     * @type {(boolean | number)}
     */
    readonly multiline?: boolean | number;

    // Css properties
    readonly autoComplete?: string;
    readonly padding?: Padding;
    readonly charFilters?: RegExp | RegExp[];
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly onChangePreProcess?: (value: string) => string;
};

const createFormTranslatableInputComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2TranslatableInput = <
        Value extends PossibleValueTypes = PossibleValueTypes,
    >(
        props: Props<State, Value>,
    ) => {
        const [key, setKey] = React.useState(generateId);
        const provider = useProviderFromContext({
            disableThrowWhenMissingContext: true,
        });
        const theme = useTheme();
        const translate = useTranslate();
        const { language } = useLanguageToEditContext();

        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            formId: formId,
            fieldSchema: props.fieldSchema,
        });

        const setValue = React.useCallback(
            (newValue: string, isAutoTranslated = false): void => {
                const processedNewValue = props.onChangePreProcess
                    ? props.onChangePreProcess(newValue)
                    : newValue;

                // TRANSLATABLE
                // Update the correct value of the translatable
                // If field.value is a string, and language is sv just set it
                // If field.value is null or undefined, create a translatable object and set the correct language
                // If field.value is a translatable, set the correct language
                const newTranslatable = ((): Value => {
                    const currentValue = field.value;

                    if (typeof currentValue === "string") {
                        // If the current value is a string and
                        // the language is sv, just set the value
                        if (language === "sv")
                            return processedNewValue as Value;

                        // If the current value is a string and
                        // but the language edited is not sv,
                        // create a translatable object and set
                        // the correct language and the sv value
                        // to the current value
                        return translatableValueFactory.from({
                            sv: currentValue,
                            [language]: processedNewValue,
                            isAutoTranslated: {
                                sv: false,
                                [language]: isAutoTranslated,
                            },
                        }) as Value;
                    }
                    if (
                        currentValue === null ||
                        typeof currentValue === "undefined"
                    ) {
                        // If no value currently exists
                        // create a translatable object and set
                        // the correct language and the sv value
                        return language === "sv"
                            ? (processedNewValue as Value)
                            : (translatableValueFactory.from({
                                  sv: "",
                                  [language]: processedNewValue,
                                  isAutoTranslated: {
                                      sv: false,
                                      [language]: isAutoTranslated,
                                  },
                              }) as Value);
                    }

                    // Current value is a translatable value
                    // So just set the correct language
                    if (isTranslatableValue(currentValue)) {
                        return {
                            ...(currentValue as TranslatableValue),
                            [language]: processedNewValue,
                            isAutoTranslated: {
                                ...currentValue.isAutoTranslated,
                                [language]: isAutoTranslated,
                            },
                        } as Value;
                    }
                    throw new Error(
                        "Invalid field value type: " +
                            JSON.stringify(currentValue),
                    );
                })();

                field.setValue(newTranslatable);
                field.setDirty(
                    !compareTranslatables(field.initialValue, newTranslatable),
                );
                field.setTouched(true);
            },
            [props, field, language],
        );

        const translateTextMutation = useTextTranslationMutation({
            onSuccess: (translation, _command) => {
                // Update the value of the field with the translation
                setValue(translation.result, true);
                setKey(generateId()); // makes sure textbox gets re-rendered
            },
        });

        const padding = paddingToCssValue(props.padding ?? { all: 14 });
        // const fontSize = remSize(props.fontSize ?? 1);
        const hasError = !!field.errors?.length;

        const containerStyles = css({
            cursor: "pointer",
            flex: 1,
        });

        // Colors
        const isAutoTranslated =
            isTranslatableValue(field.value) &&
            field.value?.isAutoTranslated?.[language];

        const borderColor = hasError
            ? theme.colors.danger.border.asString
            : isAutoTranslated
              ? theme.colors.purple.main.light80.asString
              : theme.colors.textInput.border.asString;
        const backgroundColor = hasError
            ? theme.colors.danger.light.asString
            : theme.colors.textInput.background.asString;
        const textColor = hasError
            ? theme.colors.danger.asString
            : theme.colors.textInput.text.asString;
        const spinnerColor = hasError
            ? theme.colors.danger.asString
            : theme.colors.textInput.placeholder.asString;

        const validationTrigger = useFormFieldValidation<State, Value>({
            field,
            initialValidationType: "manual",
        });

        const hasErrors = field.errors.length > 0;
        const inputRef: React.MutableRefObject<
            HTMLInputElement | HTMLTextAreaElement | null
        > = React.useRef(null);
        const InputElement = props.multiline ? "textarea" : "input";

        const translator = (input: Translatable | undefined) => {
            if (typeof input === "undefined") return undefined;
            if (typeof input === "string" && language === "sv") return input;
            if (typeof input === "string") return undefined;
            if (input?._type === "TRANSLATABLE_VALUE")
                return input?.[language] || undefined;
            return undefined;
        };
        const translatedValue =
            typeof field.value === "undefined"
                ? undefined
                : translator(field.value);

        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                tag={"form_field_" + props.fieldId}
                css={[containerStyles]}
                crossAxis="stretch">
                <Conditional when={!!props.label}>
                    <FlexRow mainAxis="space-between">
                        <FlexRow
                            mainAxis="space-between"
                            crossAxis="center"
                            css={{
                                flex: 1,
                                flexShrink: 0,
                            }}>
                            <FieldTitle
                                htmlFor={props.fieldId}
                                padding={{ left: 2 }}
                                text={props.label ?? ""} // already checked with conditional above
                                color={props.labelColor}
                                disabled={props.disabled}
                                required={field.isRequired}
                            />
                            <Conditional when={!!props.labelDescription}>
                                <HorizontalDivider XXS />
                                <FieldTitle
                                    padding={{ left: 2 }}
                                    text={props.labelDescription ?? ""} // already checked with conditional above
                                    color={props.labelColor}
                                    disabled={props.disabled}
                                    weight="400"
                                    size="S"
                                    css={{
                                        opacity: 0.5,
                                    }}
                                />
                            </Conditional>
                        </FlexRow>
                        <Conditional when={language !== "sv"}>
                            <ButtonPurpleLight
                                padding={{ leftRight: 8, topBottom: 4 }}
                                disabled={
                                    field.isSubmitting ||
                                    !!props.disabled ||
                                    !svTranslator(field.value) ||
                                    translateTextMutation.isPending
                                }
                                confetti
                                onClick={() => {
                                    // Call for a translation of the swedish value into the current language
                                    const svValue = svTranslator(field.value);
                                    if (!svValue) return;
                                    translateTextMutation.mutate(
                                        translateCommandFactory({
                                            text: svValue,
                                            providerId: provider?._id,
                                            from: "sv",
                                            to: language,
                                        }),
                                    );
                                }}>
                                <FlexRow>
                                    <Icon icon={faLanguage} size={0.9} />
                                    <HorizontalDivider />
                                    <TextBox
                                        text={language}
                                        case="UPPERCASE"
                                        size={0.825}
                                    />
                                </FlexRow>
                            </ButtonPurpleLight>
                        </Conditional>
                    </FlexRow>
                    <VerticalDivider XS />
                </Conditional>
                <FlexRow
                    css={[
                        {
                            border: `1px solid ${borderColor}`,
                            backgroundColor: backgroundColor,
                            borderRadius: theme.radius,
                            padding,
                        },
                        containerStyles,
                        props.containerCss,
                    ]}
                    crossAxis="center"
                    mainAxis="space-between"
                    onClick={() => {
                        inputRef.current?.focus();
                    }}>
                    <Conditional when={!!props.preElement}>
                        <FlexRow css={{ flexShrink: 0, flexGrow: 0 }}>
                            {props.preElement ?? null}
                        </FlexRow>
                        <HorizontalDivider />
                    </Conditional>
                    <InputElement
                        key={`${language}_${key}`}
                        ref={(
                            node: HTMLInputElement | HTMLTextAreaElement | null,
                        ) => {
                            inputRef.current = node;
                        }}
                        placeholder={translate(props.placeholder) || undefined}
                        onBeforeInput={
                            // If charFilters is set,
                            // prevent input of characters that match the filter(s)
                            props.charFilters
                                ? event => {
                                      const input = (
                                          event as unknown as InputEvent
                                      ).data as string;
                                      const prevent = asArray(
                                          props.charFilters,
                                      ).some(filter => {
                                          filter.lastIndex = 0; // because of global flag weirdness
                                          return filter.test(input);
                                      });
                                      if (prevent) {
                                          event.preventDefault();
                                          return false;
                                      }
                                      return true;
                                  }
                                : undefined
                        }
                        className={props.className}
                        type={props.type ?? "text"}
                        rows={
                            !props.multiline
                                ? undefined
                                : typeof props.multiline === "number"
                                  ? props.multiline
                                  : 4
                        }
                        autoComplete={props.autoComplete}
                        defaultValue={translatedValue}
                        onBlur={() => {
                            field.setTouched(true);
                            validationTrigger();
                        }}
                        disabled={field.isSubmitting || !!props.disabled}
                        onChange={e => {
                            const newValue = e.target.value.trim();
                            setValue(newValue);
                        }}
                        css={[
                            {
                                userSelect: "auto",
                                border: "none",
                                flex: 1,
                                fontSize: "1rem",
                                backgroundColor,
                                color: textColor,
                            },
                            props.inputCss,
                            hasErrors && {
                                borderColor: theme.colors.danger.asString,
                            },
                        ]}
                    />

                    <Conditional when={!!props.postElement}>
                        <HorizontalDivider />
                        <FlexRow shrink={0} grow={0}>
                            {props.postElement ?? null}
                            {props.postElement ?? null}
                        </FlexRow>
                    </Conditional>
                    <Conditional
                        when={!!props.spin || translateTextMutation.isPending}>
                        <FlexRow shrink={0} grow={0}>
                            <HorizontalDivider />
                            <Icon
                                icon={faSpinnerThird}
                                spin
                                color={spinnerColor}
                            />
                        </FlexRow>
                    </Conditional>
                </FlexRow>
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        marginTop: 5,
                    }}
                />
                {language !== "sv" ? (
                    <>
                        <VerticalDivider XXS />
                        <FlexRow
                            crossAxis="flex-start"
                            padding={{ leftRight: 3, topBottom: 5 }}>
                            <TextBox
                                padding={{ top: 5, leftRight: 5 }}
                                text={"SE"}
                                color={theme.colors.black.light70}
                                size={0.625}
                                userSelect="text"
                                weight="700"
                                css={{
                                    width: "min-content",
                                    padding: 2,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor:
                                        theme.colors.black.light85.asString,
                                    borderRadius: 3,
                                }}
                            />
                            <HorizontalDivider />
                            <TextBox
                                text={svTranslator(field.value)}
                                padding={{ top: 1 }}
                                color={theme.colors.black.light70}
                                size={0.8}
                                userSelect="text"
                            />
                        </FlexRow>
                    </>
                ) : null}
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2TranslatableInput;
};
export { createFormTranslatableInputComponent };
export type { Props as Form2TextInputProps };
