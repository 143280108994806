import { Form } from "@@/shared/form/form.zustand-store";
import type {
    Form2TextInputPossibleValueTypes,
    Form2TextInputProps,
} from "@@/shared/form/form2-text-input";
import { Translatable, translation } from "@towni/common";
import { ReactNode } from "react";
import { Except } from "type-fest";
import { z } from "zod";

const stateZodObject = z.object({
    value: z.string().optional(),
});
type State = z.infer<typeof stateZodObject>;
type Value = Form2TextInputPossibleValueTypes;

type Props = {
    readonly initialValue?: string;
    readonly onChange: (value: string | undefined) => void;
    readonly children?: ReactNode;
    readonly label?: Translatable;
    readonly zodSchema?: z.ZodType<Value>;
} & Except<Form2TextInputProps<State, Value>, "fieldId" | "getter" | "setter">;

const TextInput = (props: Props) => {
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        value: props.zodSchema ?? z.string().optional(),
                    }),
                    initialState: {
                        value: props.initialValue ?? undefined,
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.TextInput
                    {...props}
                    fieldId="textInput"
                    label={
                        props.label ??
                        translation({
                            sv: "Text",
                            en: "Text",
                        })
                    }
                    getter={data => data.value}
                    setter={(data, newValue) => {
                        data.value = newValue;
                        props.onChange(newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { TextInput };
