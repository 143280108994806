import { useLanguageContext } from "@@/settings/language-context";
import { dateTimeFormatter, LanguageCode } from "@towni/common";
import { useMemo } from "react";

const useDateTimeFormatter = (languageCode?: LanguageCode) => {
    const { language } = useLanguageContext();
    const _languageCode = languageCode ?? language;
    return useMemo(() => dateTimeFormatter(_languageCode), [_languageCode]);
};

export { useDateTimeFormatter };
