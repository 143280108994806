import { useAppSettings } from "@@/settings";
import { useTranslate } from "@@/translations/use-translate";
import { Translatable } from "@towni/common";
import { Helmet } from "react-helmet-async";

type Props = {
    title?: Translatable | (Translatable | undefined)[] | undefined;
};

/**
 * Sets the page title, the one visible in the browser tab
 */
const HeadTitle = (props: Props) => {
    const { title } = props;
    const translate = useTranslate();
    const settings = useAppSettings();
    const sections = [
        ...(settings.isProdEnv
            ? []
            : [`[${settings.env.APP_ENV.replace(/GCP_/g, "").toUpperCase()}]`]),
        ...(title
            ? Array.isArray(title)
                ? title
                      .map(titleSegment =>
                          titleSegment ? translate(titleSegment) : null
                      )
                      .filter(Boolean)
                : title
                ? [translate(title)]
                : []
            : []),
        "Towni ♥ Lokalt",
    ];
    return (
        <Helmet>
            <title>{sections.join(" - ")}</title>
        </Helmet>
    );
};

export { HeadTitle };
