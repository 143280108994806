import { apiFetchClient } from "@@/shared/fetch-client";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    CartId,
    GetSingleResponse,
    isProduct,
    Product,
    ProductId,
    ProviderId,
    SkuId,
    StockBalanceSkuQuantityProjection,
} from "@towni/common";
import { stockBalanceMainQueryKey } from "./stock-balance-main-query-key";

type UseStockBalanceSkuResult = readonly [
    StockBalanceSkuQuantityProjection | undefined,
    UseQueryResult<
        GetSingleResponse<StockBalanceSkuQuantityProjection | "NOT_TRACKED">,
        unknown
    >,
];

function useStockBalanceSku(
    product: Product,
    disabled?: boolean,
): UseStockBalanceSkuResult;
function useStockBalanceSku(
    input: {
        providerId: ProviderId;
        productId: ProductId;
        skuId: SkuId;
        cartId?: CartId;
    },
    disabled?: boolean,
): UseStockBalanceSkuResult;
function useStockBalanceSku(
    input:
        | Product
        | {
              providerId: ProviderId;
              productId: ProductId;
              skuId: SkuId;
              cartId?: CartId;
          },
    disabled?: boolean,
): UseStockBalanceSkuResult {
    // Parse required ids from input
    const { providerId, productId, skuId } = isProduct(input)
        ? {
              providerId: input.providerId,
              productId: input._id,
              skuId: input.skus[0]._id,
          }
        : input;

    const cartId = !isProduct(input) ? input.cartId : undefined;

    // Fetch stock balance
    const queryResult = useQuery({
        enabled: !disabled,
        queryKey: [stockBalanceMainQueryKey, providerId, productId, skuId],
        queryFn: ({ signal }) => {
            const queryParams = [];
            if (cartId) queryParams.push(cartId);

            const queryString = cartId
                ? `?cartId=${encodeURIComponent(cartId)}`
                : "";

            return apiFetchClient.get<
                GetSingleResponse<
                    StockBalanceSkuQuantityProjection | "NOT_TRACKED"
                >
            >({
                route: `/stock-balances/products/${encodeURIComponent(
                    productId,
                )}/skus/${encodeURIComponent(skuId)}/quantity${queryString}`,
                customConfig: {
                    signal,
                },
            });
        },
    });

    const snapshot =
        queryResult.data?.item === "NOT_TRACKED" ||
        typeof queryResult.data?.item === "undefined"
            ? undefined
            : queryResult.data.item;

    // Return results
    return [snapshot, queryResult] as const;
}

export { useStockBalanceSku };
