import {
    clearStorage,
    loadFromStorage,
    saveToStorage,
} from "@@/shared/local-storage";
import {
    Cart,
    ProviderId,
    cartFactory,
    cartZodSchema,
    providerIdZodSchema,
} from "@towni/common";
import objectHash from "object-hash";
import { createContext, useContext, useRef } from "react";
import { z } from "zod";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

type ContextState = State & Actions;
type State = {
    readonly carts: Map<ProviderId, Cart>;
};
type Actions = {
    readonly addCart: (cart: Cart) => Cart;
    readonly getCart: (providerId: ProviderId) => Cart | undefined;
    readonly removeCart: (providerId: ProviderId) => void;
    readonly setCart: (cart: Cart) => void | Promise<void>;
    readonly getCarts: () => Cart[];
    readonly clearCartFromLocalStorage: (providerId: ProviderId) => void;
    readonly clearCartsFromLocalStorage: () => void;
};

const MultiCartContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

const loadCartIdsInStorage = (contextId: string | undefined): ProviderId[] => {
    const storedCartProviderIds =
        loadFromStorage<ProviderId[]>(
            `carts${contextId ? "" : "_" + contextId}`,
            z.array(providerIdZodSchema),
        ) || [];
    return storedCartProviderIds;
};
const saveCartInStorage = (cart: Cart, contextId: string | undefined) => {
    if (!cart.preserve) return;
    const currentCartProviderIdsInStorage = loadCartIdsInStorage(contextId);
    if (!currentCartProviderIdsInStorage.includes(cart.providerId)) {
        saveToStorage<ProviderId[]>(
            `carts${contextId ? "" : "_" + contextId}`,
            [...currentCartProviderIdsInStorage, cart.providerId],
        );
    }
    saveToStorage<Cart>(
        `cart_for_${contextId ? "" : "_" + contextId + "_"}${cart.providerId}`,
        cart,
    );
};
const loadCartsFromStorage = (contextId: string | undefined): Cart[] => {
    const storedCartProviderIds = loadCartIdsInStorage(contextId);
    const carts = storedCartProviderIds
        .map(providerId => {
            const cart = loadFromStorage<Cart>(
                `cart_for_${
                    contextId ? "" : "_" + contextId + "_"
                }${providerId}`,
                cartZodSchema,
            );
            return cart;
        })
        .filter(Boolean) as unknown as Cart[];
    return carts;
};

const removeCartFromStorage = (
    providerId: ProviderId,
    contextId: string | undefined,
) => {
    const currentCartProviderIdsInStorage = loadCartIdsInStorage(contextId);
    if (currentCartProviderIdsInStorage.includes(providerId)) {
        saveToStorage<ProviderId[]>(
            `carts${contextId ? "" : "_" + contextId}`,
            currentCartProviderIdsInStorage.filter(id => id !== providerId),
        );
    }
    clearStorage(
        `cart_for_${contextId ? "" : "_" + contextId + "_"}${providerId}`,
    );
};

type Props = {
    readonly children?: React.ReactNode;
    /**
     * A unique identifier for the context.
     * Else contexts may share same carts through local storage, that's ugly
     * @type {(string | undefined)}
     */
    readonly contextId: string;
    readonly preservationDisabled?: boolean;
    readonly initialCarts?: Cart[];
};
const MultiCartContextProvider = (props: Props) => {
    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, get) => {
            // browserLogger.info("INIT CARTS", {
            //     contextId: props.contextId,
            //     preservationDisabled: props.preservationDisabled,
            // });

            // If any initial carts are provided, store them to use them
            if (props.initialCarts) {
                props.initialCarts.forEach(cart => {
                    saveCartInStorage(cart, props.contextId);
                });
            }

            // Load carts from local storage
            const cartsFromStorage = props.preservationDisabled
                ? []
                : loadCartsFromStorage(props.contextId);

            const carts = new Map<ProviderId, Cart>(
                cartsFromStorage.map(cart => [cart.providerId, cart]),
            );

            const addCart = (cart: Cart) => {
                const carts = new Map(get().carts);
                carts.set(cart.providerId, cart);
                set({ carts });

                !props.preservationDisabled &&
                    saveCartInStorage(cart, props.contextId);

                return cart;
            };

            const getCart = (providerId: ProviderId) => {
                // browserLogger.info("GET CART" + props.contextId, {
                //     providerId,
                // });
                return get().carts.get(providerId);
            };

            const removeCart = (providerId: ProviderId) => {
                const carts = new Map(get().carts);
                carts.delete(providerId);
                set({
                    carts,
                });
                removeCartFromStorage(providerId, props.contextId);
            };

            const setCart = (cart: Cart) => {
                const currentCart = get().carts.get(cart.providerId);
                const { hash: _oldHash, ...cartUpdateWithoutHash } = cart;
                const update = cartFactory(
                    {
                        ...cartUpdateWithoutHash,
                    },
                    objectHash,
                );

                // console.log("SET CART INIT", { cart, currentCart, update });
                if (!currentCart) {
                    throw new Error(
                        "Cart does not exist; " +
                            update._id +
                            " " +
                            update.providerId,
                    );
                }

                // Any change?
                if (currentCart.hash === update.hash) {
                    // console.log("SET CART IGNORED", {
                    //     oldHash: currentCart.hash,
                    //     hash: update.hash,
                    // });
                    // No change, ignore
                    return;
                }
                // console.log("SET CART UPDATE", {
                //     oldHash: currentCart.hash,
                //     hash: update.hash,
                // });

                const carts = new Map(get().carts);
                carts.set(update.providerId, update);
                set({ carts });
                !props.preservationDisabled &&
                    saveCartInStorage(update, props.contextId);
            };

            const getCarts = () => {
                const cartProviderIds = Array.from(get().carts.keys());
                return cartProviderIds
                    .map(cartId => get().carts.get(cartId))
                    .filter(Boolean) as Cart[];
            };

            const clearCartFromLocalStorage = (providerId: ProviderId) => {
                removeCartFromStorage(providerId, props.contextId);
            };
            const clearCartsFromLocalStorage = () => {
                const cartProviderIds = loadCartIdsInStorage(props.contextId);
                cartProviderIds.forEach(providerId => {
                    removeCartFromStorage(providerId, props.contextId);
                });
            };

            return {
                carts,
                addCart,
                getCart,
                removeCart,
                setCart,
                getCarts,
                clearCartFromLocalStorage,
                clearCartsFromLocalStorage,
            };
        }),
    );

    return (
        <MultiCartContext.Provider value={store.current}>
            {props.children}
        </MultiCartContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useMultiCartContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(MultiCartContext);
    if (store === undefined) {
        throw new Error(
            "useMultiCartContext must be used within a MultiCartContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

const useMultiCartStore = () => {
    const store = useContext(MultiCartContext);
    if (store === undefined) {
        throw new Error(
            "useMultiCartContext must be used within a MultiCartContextProvider",
        );
    }
    return store;
};

export { MultiCartContextProvider, useMultiCartContext, useMultiCartStore };
