import { useLanguageContext } from "@@/settings/language-context";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    FullDate,
    IsoAndUnixTimestamp,
    LanguageCode,
    MINUTES,
    Provider,
    TimeSpot,
    Translatable,
    WithUnixTimestamp,
    asDate,
    getLocale,
    getProviderPrepTime,
    isTheSameHourAndMinute,
    isoAndUnixFactory,
    timeSpotToStringRounded,
    toFullDate,
    translation,
} from "@towni/common";
import { format, isToday, isTomorrow } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useDeliveryDispatch, useDeliveryState } from "./delivery-context";
import { calculateOpenTimeSpotsForProvider } from "./provider-time-slot-helper";
import { KeyValue, SelectPicker } from "./select-picker";

type PickUpProps = {
    provider: Provider;
};

const PickupTimePicker = (props: PickUpProps) => {
    const theme = useTheme();
    const state = useDeliveryState();
    const { setPickupSlot } = useDeliveryDispatch();

    const translate = useTranslate();
    const { language } = useLanguageContext();

    //TIMER
    const [count, setCount] = useState<number | null>(1);

    const allTimeSpots = useMemo(() => {
        return calculateOpenTimeSpotsForProvider(props.provider);
    }, [count, props.provider]);

    const [value, setValue] = useState<number | undefined>(
        state.selected === "PICK_UP"
            ? state.pickupState.slot?.start.unix
            : undefined,
    );
    const days = allTimeSpots ? Object.keys(allTimeSpots) : [];
    const defaultDay = useMemo(() => {
        const slot = state.pickupState.slot;
        if (slot) {
            const day = days.find(f => f === toFullDate(slot.start).toString());
            if (day) return day;
        }
        return days[0];
    }, [days, state.pickupState.slot]);

    const [dayValue, setDayValue] = useState(defaultDay);

    //Filter timespots
    const timeSpots = useMemo(() => {
        if (!props.provider) return [];
        if (!allTimeSpots) return [];
        //Check if we have a match on timespot on the new day
        if (
            !allTimeSpots[dayValue]?.some(
                timeSpot => timeSpot?.start.unix === value,
            ) &&
            value
        ) {
            const selectedDate = isoAndUnixFactory(new Date(value * 1000));
            // console.log({ selectedDate });
            const timeSpotSameHourAndMinute = allTimeSpots[dayValue].find(
                timeSpot =>
                    timeSpot &&
                    isTheSameHourAndMinute(timeSpot.start, selectedDate),
            );
            if (timeSpotSameHourAndMinute)
                setValue(timeSpotSameHourAndMinute.start.unix);
            else setValue(allTimeSpots[dayValue][0]?.start.unix);
        }
        return allTimeSpots[dayValue];
    }, [dayValue, count, props.provider]);

    useEffect(() => {
        const selected = timeSpots.find(
            timeSpot => timeSpot?.start.unix === value,
        );
        setPickupSlot(selected);
    }, [value]);

    //Refreshes timespots
    useEffect(() => {
        if (!count) return;
        const timer = setTimeout(() => {
            setCount(old => {
                return old ? old + 1 : null;
            });
        }, 1 * MINUTES);
        return () => {
            clearTimeout(timer);
        };
    }, [count]);

    const formatDay = (day: string) => {
        const first = allTimeSpots?.[day][0];

        if (first === undefined)
            return `${translate(
                translation({
                    sv: "Idag",
                    en: "Today",
                }),
            )} ${format(new Date(), "d MMM", {
                locale: getLocale(language),
            })}`;

        const date = asDate(first.start);
        return humanizeDate(date, translate, language);
    };
    return (
        <>
            <TextBox
                padding={{ leftRight: 20, top: 20 }}
                text={translation({
                    sv: "När?",
                    en: "When?",
                })}
                weight="800"
                size="ML"
            />

            <HorizontalDivider L />
            <FlexRow
                padding={{ top: 20, bottom: 40 }}
                css={{
                    marginRight: 20,
                    marginLeft: 20,
                }}>
                <SelectPicker<string | number | undefined>
                    data={timeSpots.map(timeSpot => {
                        return {
                            value: timeSpot?.start.unix,
                            name: pickupTimeFormatter(
                                timeSpot,
                                getProviderPrepTime(props.provider),
                                translate,
                            ),
                        };
                    })}
                    selectedValue={value}
                    onSelect={({ value }) => {
                        if (value) setValue(Number(value));
                        else setValue(undefined);
                    }}
                    styling={css`
                        height: 52px;
                        border-radius: ${theme.radius}px 0 0 ${theme.radius}px;
                    `}
                />
                <SelectPicker<string>
                    data={days.map(day => {
                        return {
                            name: formatDay(day),
                            value: day,
                        };
                    })}
                    selectedValue={dayValue}
                    onSelect={({ value }: KeyValue) => {
                        setDayValue(value.toString());
                    }}
                    styling={css`
                        height: 52px;
                        border-radius: 0 ${theme.radius}px ${theme.radius}px 0;
                    `}
                />
            </FlexRow>
        </>
    );
};

const timeSpotTimeFormatter = (
    time: TimeSpot,
    translate: (value: Translatable) => string,
) => {
    return `${translate(
        translation({
            sv: "kl",
            en: "at",
        }),
    )} ${timeSpotToStringRounded(time, 5)}`;
};

const pickupTimeFormatter = (
    time: TimeSpot | undefined,
    prepTime: number,
    translate: (value: Translatable) => string,
) => {
    if (time) return timeSpotTimeFormatter(time, translate);

    return ` ${translate(
        translation({
            sv: `Snarast ~${prepTime} min`,
            en: `ASAP ~${prepTime} min`,
        }),
    )} `;
};

//TODO Rename
function humanizeDate(
    date: WithUnixTimestamp | IsoAndUnixTimestamp | Date | FullDate,
    translate: (value: Translatable) => string,
    locale: LanguageCode,
) {
    const _date = asDate(date);
    if (!_date) throw new Error("invalid date");
    if (isToday(_date))
        return `${translate(
            translation({
                sv: "Idag",
                en: "Today",
            }),
        )} ${format(_date, "d MMM", {
            locale: getLocale(locale),
        })}`;
    if (isTomorrow(_date))
        return `${translate(
            translation({
                sv: "Imorgon",
                en: "Tomorrow",
            }),
        )} ${format(_date, "d MMM", {
            locale: getLocale(locale),
        })}`;

    return format(_date, "EEEE d MMM", {
        locale: getLocale(locale),
    });
}

export { PickupTimePicker, humanizeDate };
