import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { FlexRow } from "@@/shared/flex-containers";
import { isRunningAsPwa } from "@@/shared/is-running-as-pwa";
import { Spacer } from "@@/shared/spacer";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { Translatable, translation } from "@towni/common";
import { useCheckoutContext } from "./checkout.context";
import { useCart } from "./multi-carts/cart.context";

type Props = {
    disabled?: boolean;
    onClick: () => void;

    text?: Translatable;
};

const CheckoutButton = (props: Props) => {
    const theme = useTheme();
    const totalCheckoutPrice = useCheckoutContext(
        context => context.totalCheckoutPrice,
    );
    const isOnPremise = useCheckoutContext(context => context.onPremise);
    const cart = useCart();
    const { disabled } = props;

    const output = (
        <FlexRow
            fillParentWidth
            mainAxis="center"
            crossAxis="center"
            css={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 100,
                flexShrink: 0,
            }}>
            <FlexRow
                fillParentWidth
                mainAxis="center"
                crossAxis="center"
                maxWidth={500}
                padding={{ bottom: isRunningAsPwa ? 30 : 20 }}
                background={{
                    linearGradient: {
                        _type: "LINEAR_GRADIENT_SIMPLE",
                        from: theme.colors.default.background,
                        to: theme.colors.transparent.asString,
                    },
                }}>
                <Spacer grow={0.05} shrink={0} />
                <Conditional
                    when={!!cart.orderItems?.length}
                    render={() => (
                        <ButtonPrimary
                            css={{ flex: 1 }}
                            dataTestId="checkout-button"
                            contentContainerCss={{
                                flex: 1,
                                label: "checkout_button",
                            }}
                            onClick={props.onClick}
                            padding={{ leftRight: 20, topBottom: 15 }}
                            disabled={disabled}>
                            <FlexRow
                                fillParentWidth
                                mainAxis="space-between"
                                crossAxis="center">
                                {}
                                <Conditional
                                    when={
                                        !!totalCheckoutPrice.amountIncludingVat
                                    }
                                    render={() => (
                                        <>
                                            <TextBox
                                                dataTestId="checkout-button-title"
                                                size={1.1}
                                                text={
                                                    props.text ||
                                                    (isOnPremise
                                                        ? translation({
                                                              sv: "Välj betalningsmetod",
                                                              en: "Choose payment method",
                                                          })
                                                        : translation({
                                                              sv: "Godkänn och betala",
                                                              en: "Confirm and pay",
                                                          }))
                                                }
                                            />
                                            <PriceTextBox
                                                dataTestId="checkout-button-price"
                                                size={1.1}
                                                price={totalCheckoutPrice}
                                            />
                                        </>
                                    )}
                                    else={() => (
                                        <TextBox
                                            dataTestId="checkout-button-title"
                                            size={1.1}
                                            text={
                                                props.text ||
                                                translation({
                                                    sv: "Godkänn och boka",
                                                    en: "Confirm and book",
                                                })
                                            }
                                        />
                                    )}
                                />
                            </FlexRow>
                        </ButtonPrimary>
                    )}
                />

                <Spacer grow={0.05} shrink={0} />
            </FlexRow>
        </FlexRow>
    );

    return output;
};

export { CheckoutButton };
