import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Provider, ProviderId } from "@towni/common";
import * as React from "react";
import { LinkTo } from "./link-to";

type Props =
    | {
          ["data-testid"]?: string;
          readonly providerId: ProviderId;
          readonly children?: React.ReactNode;
          readonly className?: string;
          readonly target?: string;
      }
    | {
          ["data-testid"]?: string;
          readonly provider: Provider;
          readonly children?: React.ReactNode;
          readonly className?: string;
          readonly target?: string;
      };
const LinkToProviderHome = (props: Props) => {
    const [_provider, _providerQuery] = useProvider(
        "providerId" in props ? props.providerId : undefined,
    );
    const provider =
        _provider ?? ("provider" in props ? props.provider : undefined);
    const homePath = useHomePath(provider);
    if (!provider) return <>{props.children}</>;
    return (
        <LinkTo
            data-testid={props["data-testid"]}
            className={props.className}
            target={props.target}
            to={homePath}>
            {props.children}
        </LinkTo>
    );
};

export { LinkToProviderHome };
