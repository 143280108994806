import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { useNavenColorContext } from "../naven-color-context";

const NavenLearnCanoe: FC = () => {
    const getProductId = useProductIdWithFallback();
    const _colors = useNavenColorContext();

    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const cannoe1 = React.useMemo(
        () => getProductId("product__JoPsVH0pYSPN_jOZR0ML" as ProductId),
        []
    );
    const cannoe2 = React.useMemo(
        () => getProductId("product__Fk7PWadFp-NGmqM3mqkK" as ProductId),
        []
    );
    const cannoe3 = React.useMemo(
        () => getProductId("product__56OPry6_qgw_oE9cguvD" as ProductId),
        []
    );
    const cannoe4 = React.useMemo(
        () => getProductId("product__iGI1PxCSAkQLq93HpF-q" as ProductId),
        []
    );
    const productIds = [cannoe1, cannoe2, cannoe3, cannoe4];

    return (
        <SectionArea
            title={translation({
                sv: "Lär dig kajak på riktigt!",
                en: "Learn to canoe properly!",
            })}
            subtitle={translation({
                sv: "Kajak blir bara roligare och roligare ju mer man kan. Vi har lätta intron till er som vill ta er vidare.",
                en: "Canoeing is only more fun the more you can do. We have easy introductions for those who want to go further.",
            })}
            sectionAreaId="naven-lacko-learn-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id}
                            productId={id}
                            // contentTop={index === 3 ? true : index % 2 === 0}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={_colors.primary}
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

const actionTexts = [
    translation({
        sv: "Boka nu",
        en: "Book now",
    }),
    translation({
        sv: "Se & Boka",
        en: "Book",
    }),
    translation({
        sv: "Se tider",
        en: "See times",
    }),
];

export { NavenLearnCanoe };
