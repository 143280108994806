import { Unit } from "@towni/common";
import { useSelectedLanguageCode } from "./use-selected-language-code";

const useUnitTranslator = () => {
    const languageCode = useSelectedLanguageCode();
    // const { units } = useAppSettings();
    return (value: number, unit: Unit, type: "short" | "long" = "long") => {
        // const unitNameValue =
        //     units[unit.type].name[languageCode] ?? units[unit.type].name["sv"];

        const unitNameValue =
            languageCode === "sv" ? unit.name.sv : unit.name.en;

        switch (type) {
            case "short":
                if (value !== 1)
                    return unitNameValue.shortPlural ?? unitNameValue.short;
                return unitNameValue.short;
            default:
                if (value !== 1) return unitNameValue.plural;
                return unitNameValue.singular;
        }
    };
};

export { useUnitTranslator };
