import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { FontWeight } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    ColorItem,
    Optional,
    OptionalPercentage,
    SizeName,
} from "@towni/common";
type Props = {
    pickedOptionals: (Optional | OptionalPercentage)[];
    color?: ColorItem;
    weight?: FontWeight;
    size?: SizeName | number;
};

const ProductOptionalsTextBox = ({ pickedOptionals, ...props }: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const weight = props.weight || "400";
    const size = props.size || 1;
    const color = props.color || theme.colors.default.text.light40;

    if (!pickedOptionals?.length) return null;

    return (
        <FlexRow wrap={"wrap"}>
            <TextBox
                color={color}
                size={size}
                weight={weight}
                text={pickedOptionals
                    .map(optional => translate(optional.meta.title))
                    .join(", ")}
            />
        </FlexRow>
    );
};

export { ProductOptionalsTextBox };
