import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { TextBox } from "@@/shared/text";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { css, useTheme } from "@emotion/react";
import { SectionItemProps } from "../section-item";

const SectionItemBox = (props: SectionItemProps) => {
    const theme = useTheme();
    const [discountsForProduct] = useDiscountsForProduct(props.item);

    const baseSize = theme.sizes.inPixels[props.sectionTheme.size];
    const boxSize = baseSize * 4;

    const boxStyles = css`
        text-transform: uppercase;
    `;
    const { item } = props;

    if (!item) {
        return (
            <FlexColumn
                mainAxis="space-between"
                overflow="hidden"
                width={boxSize}
                height={boxSize}
                styling={boxStyles}>
                <TextShimmer rows={2} />
                <TextShimmer rows={1} width="80px" />
            </FlexColumn>
        );
    }

    return (
        <FlexColumn
            mainAxis="space-between"
            overflow="hidden"
            background={item.meta.background}
            width={boxSize}
            height={boxSize}
            styling={boxStyles}>
            <TextBox text={item.meta.title} />

            <PriceWithDiscountsTextBox
                price={item.price}
                fractions="NONE"
                discounts={discountsForProduct}
            />
        </FlexColumn>
    );
};

export { SectionItemBox };
