import { useModal } from "@@/modals/use-modal";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { colorAsString, RemSize, translation } from "@towni/common";
import { Conditional } from "./conditional";
import { FlexRow } from "./flex-containers";
import { Icon } from "./icons/icon";
import { TextBox } from "./text";
import { UserSignInOrUpModal } from "./user-sign-in-or-up-modal";

type Props = {
    theme?: "PRIMARY" | "WHITE" | "DEFAULT";
    mini?: boolean;
    size?: RemSize;
    spin?: boolean;
};

const LoginButton = (props: Props) => {
    const theme = useTheme();
    const [{ toggle: toggleSignIn }, signInModalId] =
        useModal("sign_in_modal__");

    const viewAs = props.theme ?? "DEFAULT";

    const color =
        viewAs === "PRIMARY"
            ? theme.colors.primary
            : viewAs === "WHITE"
              ? theme.colors.white.value.withAlpha(0.9)
              : theme.colors.primary ?? theme.colors.default.text.light40;
    const borderColor =
        viewAs === "PRIMARY"
            ? "transparent"
            : colorAsString(theme.colors.black.light90);

    const backgroundColor =
        viewAs === "PRIMARY"
            ? theme.colors.primary.light
            : theme.colors.black.value.withAlpha(0.2);

    const size = props.size || 1;

    return (
        <>
            <FlexRow
                data-testid="login-button"
                shrink={0}
                onClick={toggleSignIn}
                mainAxis="flex-end"
                css={{
                    fontSize: `${size}rem`,
                }}>
                {props.mini ? (
                    <Icon
                        icon={props.spin ? faSpinnerThird : faUser}
                        cursor="pointer"
                        spin={props.spin}
                        color={color}
                        css={{
                            fontSize: "1.3em",
                        }}
                        title={translation({
                            sv: "Logga in",
                            en: "Sign in",
                        })}
                    />
                ) : (
                    <Conditional when={!props.mini}>
                        <TextBox
                            text={translation({
                                sv: "Logga in",
                                en: "Sign in",
                            })}
                            spin={props.spin}
                            padding={{
                                leftRight: size * (props.size ? 20 : 15),
                                topBottom: size * 10,
                            }}
                            radius={3000}
                            weight="700"
                            css={{
                                border: `1px solid ${borderColor}`,
                                fontSize: "0.825em",
                            }}
                            backgroundColor={backgroundColor}
                            color={color}
                        />
                    </Conditional>
                )}
            </FlexRow>
            <UserSignInOrUpModal modalId={signInModalId} />
        </>
    );
};

export { LoginButton };
