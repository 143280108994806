import { SubmitButton } from "@@/shared/buttons/submit-button";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { translation } from "@towni/common";
import { usePaymentDetailsContext } from "../../payment-details.context";

type Props = {
    submitting: boolean;
};

const PaymentBuyButton = (props: Props) => {
    const theme = useTheme();
    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails,
    );
    const disabled = props.submitting;

    return (
        <FlexRow padding={{ leftRight: 30 }} shrink={0} fillParentWidth>
            <SubmitButton disabled={disabled}>
                {props.submitting ? (
                    <Icon icon={faSpinnerThird} spin size="L" />
                ) : null}
                {!props.submitting ? (
                    <>
                        <TextBox
                            text={translation({
                                sv: "Köp",
                                en: "Buy",
                            })}
                            size="L"
                            weight="600"
                            color={theme.colors.primary.text}
                        />
                        <HorizontalDivider S />
                        {paymentDetails?.price ? (
                            <PriceTextBox
                                price={paymentDetails.price}
                                size="L"
                                weight="600"
                                color={theme.colors.primary.text}
                            />
                        ) : (
                            <Icon
                                icon={faSpinnerThird}
                                spin
                                size="L"
                                color={theme.colors.primary.text}
                            />
                        )}
                    </>
                ) : null}
            </SubmitButton>
        </FlexRow>
    );
};

export { PaymentBuyButton };
