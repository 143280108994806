type ParagraphLineBreak = {
    _type: "PARAGRAPH_LINE_BREAK";
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphLineBreak = (item: any): item is ParagraphLineBreak => {
    return item?._type === "PARAGRAPH_LINE_BREAK";
};

const paragraphLineBreakFactory = (): ParagraphLineBreak => {
    return {
        _type: "PARAGRAPH_LINE_BREAK",
    };
};

const ParagraphLineBreak = () => {
    return <br />;
};

export { ParagraphLineBreak, isParagraphLineBreak, paragraphLineBreakFactory };
