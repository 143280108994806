import { css, keyframes, useTheme } from "@emotion/react";
import { ColorItem, colorAsString, remSize } from "@towni/common";
import { HorizontalDivider } from "../dividers";
import { FlexRow } from "../flex-containers";

type Props = {
    size?: number;
    color?: ColorItem;
};

const AnimatedDot = (
    props: Props & {
        animation: ReturnType<typeof keyframes>;
        delay: number;
    },
) => {
    const theme = useTheme();
    const color = props.color || theme.colors.primary.text;
    const size = (props.size || 1) + "rem";
    const dot = css`
        display: inline-block;
        width: ${size};
        height: ${size};
        border-radius: 50%;
        background-color: ${colorAsString(color)};

        animation: ${props.animation} 1s infinite linear alternate;
        animation-delay: ${props.delay}s;
    `;

    return <div css={dot} />;
};

const ThreeDotSpinner = (props: Props) => {
    const distance = (props.size || 1) * 0.5;
    const spinning = keyframes`
        0% {
            opacity: 1;
        }
        50%,
        100% {
            opacity: 0.3;
        }
    `;

    return (
        <FlexRow>
            <AnimatedDot {...props} animation={spinning} delay={0} />
            <HorizontalDivider size={remSize(distance)} />
            <AnimatedDot {...props} animation={spinning} delay={0.65} />
            <HorizontalDivider size={remSize(distance)} />
            <AnimatedDot {...props} animation={spinning} delay={1.3} />
        </FlexRow>
    );
};

export { ThreeDotSpinner };
