import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import { GetSingleResponse, HomeSettingGlobal } from "@towni/common";

const useHomeSettingQuery = (): UseQueryResult<HomeSettingGlobal> => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<HomeSettingGlobal> = async ({ signal }) => {
        const data = await fetchClient.get<
            GetSingleResponse<HomeSettingGlobal>
        >({
            route: `/settings/home`,
            customConfig: {
                signal,
            },
        });
        return data.item;
    };
    return useQuery({
        queryKey: ["home-setting"],
        queryFn,
    });
};

export { useHomeSettingQuery };
