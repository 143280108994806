import { css, useTheme } from "@emotion/react";
import { Translatable } from "@towni/common";
import * as React from "react";
import { FlexRow } from "../flex-containers";
import { TextBox } from "../text";
import { Button } from "./button";

type Props = {
    readonly disabled?: boolean;
    readonly onClick: () => void;
    readonly text: Translatable;
    readonly children?: React.ReactNode;
};

const BottomButton = (props: Props) => {
    const theme = useTheme();
    return (
        <FlexRow
            styling={css`
                max-width: 700px;
                position: absolute;
                z-index: 100;
                bottom: 0;
                pointer-events: none;
            `}
            padding={{ leftRight: 20, bottom: 20, top: 80 }}
            background={{
                linearGradient: {
                    _type: "LINEAR_GRADIENT",
                    degrees: 0,
                    steps: [
                        {
                            percentage: 0.35,
                            color: theme.colors.default.background,
                        },
                        {
                            percentage: 1,
                            color: theme.colors.transparent,
                        },
                    ],
                },
            }}
            fillParentWidth>
            {props.children}
            <Button
                fillWidth
                buttonTheme="primary"
                disabled={props.disabled}
                onClick={props.onClick}>
                <FlexRow crossAxis="center" mainAxis="center">
                    <TextBox
                        text={props.text}
                        color={theme.colors.primary.text}
                    />
                </FlexRow>
            </Button>
        </FlexRow>
    );
};

export { BottomButton };
