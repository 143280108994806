import { createContext, useContext } from "react";

type ContextState = boolean;

const WithinModalContext = createContext<ContextState>(false);

type Props = {
    readonly children: React.ReactNode;
};

const WithinModalContextProvider = (props: Props) => {
    return (
        <WithinModalContext.Provider value={true}>
            {props.children}
        </WithinModalContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useIsWithinModal = (): boolean => {
    return useContext(WithinModalContext);
};

export { WithinModalContextProvider, useIsWithinModal };
