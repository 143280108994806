import { useTheme } from "@emotion/react";
import { assertNever, ColorItem, SizeName } from "@towni/common";
import * as React from "react";
import { useMemo } from "react";
import { CircleSpinner } from "../spinners/circle-spinner";
import { CircleSelect, CircleSelected } from "./circle-select";
import { SquareSelect, SquareSelected } from "./square-select";

type Props = {
    readonly selected: boolean;
    readonly toggle?: () => void;
    readonly size: SizeName | number;
    readonly shape: "CIRCLE" | "SQUARE";
    readonly spin?: boolean;
    readonly borderColor?: ColorItem;
    readonly backgroundColor?: ColorItem;
    readonly disabled?: boolean;
    readonly dataTestId?: string;
};

const getShape = (shape: "CIRCLE" | "SQUARE", selected: boolean) => {
    switch (shape) {
        case "CIRCLE":
            return selected ? CircleSelected : CircleSelect;
        case "SQUARE":
            return selected ? SquareSelected : SquareSelect;
        default:
            assertNever(shape);
    }
};

const SelectBox = (props: Props) => {
    const theme = useTheme();
    const spin = !!props.spin;
    const shape = props.shape ?? "CIRCLE";
    const size = props.size ?? "XL";
    const disabled = !!props.disabled;

    const Shape = useMemo(
        () => getShape(shape, props.selected),
        [shape, props.selected]
    );

    if (spin)
        return (
            <div
                data-testid={props.dataTestId}
                css={{
                    width: size,
                    height: size,
                    display: "flex",
                    position: "relative",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexShrink: 0,
                    opacity: props.disabled ? 0.5 : 1,
                }}>
                <CircleSpinner
                    size={
                        typeof size === "string"
                            ? size
                            : Math.round(
                                  ((size / theme.sizes.base) * 0.9 +
                                      Number.EPSILON) *
                                      100
                              ) / 100
                    }
                    color={props.borderColor ?? "#888"}
                />
            </div>
        );

    const toggle = () => {
        if (disabled) return;
        props.toggle?.();
    };

    return (
        <Shape
            disabled={disabled}
            size={size}
            onClick={toggle}
            borderColor={props.borderColor}
            backgroundColor={props.backgroundColor}
            dataTestId={props.dataTestId}
            css={{
                cursor: disabled ? undefined : "pointer",
            }}
        />
    );
};

export { SelectBox };
