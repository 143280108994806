import { SerializedStyles, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Padding, RemSize } from "@towni/common";
import * as React from "react";
import { Conditional } from "../conditional";
import { FlexAlignment, FlexRow } from "../flex-containers";
import { Icon } from "../icons/icon";
import { paddingToCssValue } from "../padding";

type Props = {
    readonly children: React.ReactNode;
    readonly type?: "primary" | "tertiary" | "normal" | "danger";
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly padding?: Padding;
    readonly fillWidth?: boolean;
    readonly mainAxis?: FlexAlignment;
    readonly submitButtonStyling?: SerializedStyles;
    readonly formId?: string;
    readonly className?: string;
    readonly dataTestId?: string;
};

const SubmitButton = (props: Props) => {
    const theme = useTheme();
    const paddingCssValue = paddingToCssValue(
        props.padding ?? { topBottom: 15, leftRight: 20 },
    );

    return (
        <button
            type="submit"
            data-testid={props.dataTestId}
            form={props.formId}
            disabled={props.disabled}
            className={props.className}
            css={[
                {
                    color: props.disabled
                        ? theme.colors.disabled.text.asString
                        : theme.colors.primary.text.asString,
                    background: props.disabled
                        ? theme.colors.disabled.asString
                        : theme.colors.primary.asString,
                    borderRadius: theme.radius,
                    fontWeight: 600,
                    width: "100%",
                    minHeight: theme.sizes.base * 1.25,
                    padding: paddingCssValue,
                    cursor: "pointer",
                },
                props.submitButtonStyling,
            ]}>
            <FlexRow shrink={0} crossAxis="center" mainAxis="center">
                <Conditional
                    when={!props.spin}
                    render={() => <>{props.children}</>}
                    else={() => (
                        <Icon
                            icon={faSpinnerThird}
                            size={1.25 as RemSize}
                            spin
                        />
                    )}
                />
            </FlexRow>
        </button>
    );
};

export { SubmitButton };
