import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { useTheme } from "@emotion/react";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";

const BauerCampingInNatureSection: FC = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const camping = React.useMemo(
        () => getProductId("product__ezKxYN2nV0xaehGHFEzo" as ProductId),
        []
    );
    const dropin = React.useMemo(
        () => getProductId("product__FQx6-Fh6F8HaWFoU-v9b" as ProductId),
        []
    );

    const productIds = [camping, dropin];

    return (
        <SectionArea
            title={translation({
                sv: "Exklusiv ställplats i harmoni med naturen",
                en: "Camping in harmony and nature",
            })}
            subtitle={translation({
                sv: "Vår exklusiva ställplats erbjuder 70 elförsedda platser, servicehus med hög standard, grillplats, utegym och närheten till naturen. Bastubad och bad från bryggan ingår såklart. Välkomna till Bunn! ",
                en: "",
            })}
            sectionAreaId="bauer-camping-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id + "_" + index}
                            padding={{}}
                            productId={id}
                            aspectRatio={"4:3"}
                            contentTop={false}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={theme.colors.primary}
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Boka",
        en: "Book",
    }),
    explore: translation({
        sv: "Beställ",
        en: "Book",
    }),
    seeTime: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
};

const actionTexts = [text.book, text.explore, text.seeTime];

export { BauerCampingInNatureSection };
