import { Transition, Variants } from "framer-motion";

const slideVariants: Variants = {
    in: (direction: "FORWARD" | "BACKWARD") => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        position: "absolute",
        x: direction === "FORWARD" ? "-100%" : "100%",
        opacity: 0,
    }),
    center: {
        x: "0%",
        zIndex: 1,
        opacity: 1,
        transitionEnd: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            position: "relative",
        },
    },
    out: (direction: "FORWARD" | "BACKWARD") => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        position: "absolute",
        opacity: 0,
        zIndex: 0,
        x: direction === "FORWARD" ? "-100%" : "100%",
    }),
};

const slideTransition: Transition = {
    x: {
        stiffness: 400,
        damping: 60,
        mass: 1,
    } as Transition,
    opacity: { duration: 0.2 },
};

export { slideTransition, slideVariants };
