import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import {
    AccessKeyId,
    ProductId,
    ProviderId,
    ResourceGlobal,
    ResourceGraph,
    ResourceId,
} from "@towni/common";
import { resourcesMainQueryKey } from "../main-query-key";
import { fetchResource } from "./resource.fetcher";
import {
    fetchBookableResourcesForProduct,
    fetchResourcesForProviderRequests,
} from "./resources.fetcher";

const useResourcesForProvider = (
    providerId?: ProviderId,
): readonly [ResourceGlobal[], UseQueryResult<ResourceGlobal[]>] => {
    const id = providerId ?? "";
    return useApiResources<ResourceGlobal>(
        createQueryKey(resourcesMainQueryKey, id),
        fetchResourcesForProviderRequests(providerId),
        {
            enabled: !!providerId,
        },
    );
};

const useResource = (
    resourceId?: ResourceId,
    accessKey?: AccessKeyId,
): readonly [
    ResourceGlobal | undefined,
    UseQueryResult<ResourceGlobal, unknown>,
] => {
    const id = resourceId ?? "";
    return useSingleApiResource<ResourceGlobal>(
        [resourcesMainQueryKey, id],
        fetchResource(resourceId, accessKey),
        {
            enabled: !!resourceId,
        },
    );
};

//Bookable flattened resources
const useBookableResources = (
    productId?: ProductId,
): readonly [ResourceGraph[], UseQueryResult<ResourceGraph[]>] => {
    return useApiResources<ResourceGraph>(
        createQueryKey(resourcesMainQueryKey, productId ?? ""),
        fetchBookableResourcesForProduct(productId),
        {
            enabled: !!productId,
        },
    );
};

export { useBookableResources, useResource, useResourcesForProvider };
