import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useElementSize } from "@@/shared/use-element-size";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DurationInMs, SECONDS } from "@towni/common";
import { motion, usePresence } from "framer-motion";
import * as React from "react";
import { useToastContext } from "./context/toast-context";
import type { Toast as ToastData } from "./context/toast.model";
import { toastColorSet } from "./toast-color-set";
import { toastIcon } from "./toast-icon";
type Props = {
    toast: ToastData;
    onHeightChange?: (height: number) => void;
};

// eslint-disable-next-line react/display-name
const Toast = React.forwardRef(
    (props: Props, toastRef: React.Ref<HTMLDivElement>) => {
        const theme = useTheme();
        const toasts = useToastContext();
        const translate = useTranslate();
        const icon = toastIcon(props.toast.type);
        const [isPresent, safeToRemove] = usePresence();
        const [isRemoved, setIsRemoved] = React.useState(false);
        const isMounted = useIsMountedRef();
        const onHeightChangeRef = React.useRef(props.onHeightChange);
        onHeightChangeRef.current = props.onHeightChange;

        React.useEffect(() => {
            // If the toast is not present, it's been removed
            // tell framer motion to remove it from the DOM
            // and set the isRemoved state to true
            if (!isPresent) {
                setTimeout(() => {
                    safeToRemove();
                    if (isMounted.current) {
                        setIsRemoved(true);
                    }
                }, 1000);
            }
        });
        const timeout = ((props.toast.timeout ?? 5) * SECONDS) as DurationInMs;
        const colorSet = toastColorSet(props.toast.type, theme);
        const isLongText = translate(props.toast.message).length > 100;

        // Toast height
        const [toastHeight, setToastHeight] = React.useState<
            number | undefined
        >(undefined);
        const [toastSize, toastSizeRef] = useElementSize<HTMLDivElement>();
        React.useEffect(() => {
            if (toastSize && toastSize.height !== toastHeight) {
                onHeightChangeRef?.current?.(toastSize.height);
                setToastHeight(toastSize.height);
            }
        }, [props, toastHeight, toastSize]);

        // Auto hide on notification timeout
        // (if not sticky)
        useMountEffect(() => {
            props.toast.onShow?.();
            if (props.toast.sticky) return;
            const timer = setTimeout(() => {
                toasts.hide(props.toast._id);
            }, timeout);
            return () => {
                clearTimeout(timer);
            };
        });

        return (
            <motion.div
                key={props.toast._id}
                ref={toastRef}
                style={{
                    width: "100%",
                }}
                initial={{
                    opacity: 0,
                    height: toastHeight ? toastHeight / 3 : 0,
                    marginBottom: 0,
                }}
                animate={{
                    opacity: 1,
                    height: "max-content",
                    marginBottom: 5,
                }}
                exit={{
                    opacity: 0,
                    height: 0,
                    marginBottom: 0,
                    overflow: "hidden",
                }}
                layout
                transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 40,
                }}>
                {!isRemoved ? (
                    <>
                        <FlexRow
                            key={props.toast._id}
                            mainAxis="center"
                            fillParentWidth>
                            <FlexRow
                                ref={toastSizeRef}
                                data-testid={props.toast.dataTestId}
                                onClick={() => {
                                    props.toast.onClick
                                        ? props.toast.onClick()
                                        : toasts.hide(props.toast._id);
                                }}
                                mainAxis="space-between"
                                crossAxis="center"
                                maxWidth={500}
                                padding={{ topBottom: 10, leftRight: 15 }}
                                css={{
                                    width: "100%",
                                    pointerEvents: "auto",
                                    borderRadius: isLongText ? 20 : 3000,
                                    boxShadow: `0px 1px 5px 1px ${theme.colors.default.shadow.asString}`,
                                }}
                                color={colorSet.text}
                                background={{
                                    color: colorSet.background,
                                }}>
                                {icon && (
                                    <>
                                        <FontAwesomeIcon
                                            icon={icon}
                                            style={{
                                                marginTop: -1,
                                                fontSize: "1.35rem",
                                                color:
                                                    colorSet.accent?.asString ||
                                                    colorSet.text.asString,
                                            }}
                                        />
                                        <HorizontalDivider M />
                                    </>
                                )}
                                <FlexColumn
                                    grow={1}
                                    padding={{ leftRight: 10 }}
                                    crossAxis="stretch"
                                    mainAxis="center">
                                    {props.toast.title && (
                                        <>
                                            <TextBox
                                                text={props.toast.title}
                                                color={colorSet.text.value.withAlpha(
                                                    0.7,
                                                )}
                                                weight="700"
                                                align="center"
                                            />
                                            <VerticalDivider size="3XS" />
                                        </>
                                    )}
                                    <TextBox
                                        text={props.toast.message}
                                        color={colorSet.text}
                                        weight="700"
                                        align="center"
                                    />
                                </FlexColumn>
                                <HorizontalDivider M />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={event => {
                                        toasts.hide(props.toast._id);
                                        event.stopPropagation();
                                    }}
                                    color={
                                        colorSet.accent?.asString ||
                                        colorSet.text.asString
                                    }
                                    style={{
                                        padding: 5,
                                        pointerEvents: "auto",
                                        marginTop: -1,
                                        fontSize: "1.2rem",
                                        opacity: 0.5,
                                        cursor: "pointer",
                                    }}
                                />
                            </FlexRow>
                        </FlexRow>
                        <VerticalDivider />
                    </>
                ) : null}
            </motion.div>
        );
    },
);

export { Toast };
