import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import { AccessKeyId, Page, PageId, PageIdOrSlug } from "@towni/common";
import { pageMainQueryKey } from "../../pages-query-key";
import { fetchPageById, fetchPageForProvider } from "./page.fetcher";

const usePageById = (pageId: PageId | undefined, accessKeyId?: AccessKeyId) =>
    useSingleApiResource<Page>(
        [pageMainQueryKey, pageId],
        fetchPageById(pageId, accessKeyId),
        {
            enabled: !!pageId,
        }
    );

const usePageForProvider = (
    providerIdOrSlug: string | undefined,
    pageIdOrSlug: PageIdOrSlug | undefined,
    accessKeyId?: AccessKeyId
) =>
    useSingleApiResource<Page>(
        [pageMainQueryKey, pageIdOrSlug],
        fetchPageForProvider(providerIdOrSlug, pageIdOrSlug, accessKeyId),
        {
            enabled: !!pageIdOrSlug,
        }
    );

export { usePageById, usePageForProvider };
