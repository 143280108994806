import { loadFromStorage, saveToStorage } from "@@/shared/local-storage";
import * as React from "react";

const consentStorageKey = "cookie_consent";
const useCookieConsent = () => {
    const [consent, setConsent] = React.useState(() =>
        loadFromStorage<boolean>(consentStorageKey),
    );

    const _setConsent = (consent: boolean) => {
        saveToStorage<boolean>(consentStorageKey, consent);
        setConsent(consent);
    };

    return { consent, setConsent: _setConsent };
};

export { useCookieConsent };
