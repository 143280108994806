import { browserLogger } from "@@/settings";
import { useLocalStorageSetting } from "@@/shared/use-local-storage";
import { useMountEffect } from "@@/shared/use-mount-effect";
import {
    LanguageCode,
    assertNever,
    isFunction,
    languageCodeMap,
    languageCodeZodSchema,
    languageCodes,
    parseSafely,
} from "@towni/common";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";

type State = {
    language: LanguageCode;
    availableLanguages: LanguageCode[];
};

type Actions = {
    setLanguage: (language: LanguageCode) => void;
};

type Context = State & Actions;
const languageContext = createContext<Context | undefined>(undefined);
const globalLanguageQueryParamName = "lang";

const useLanguageFromUrl = (
    scope: string | undefined,
): readonly [
    LanguageCode | undefined,
    (
        language:
            | (LanguageCode | undefined)
            | ((currentLanguage: LanguageCode | undefined) => LanguageCode),
    ) => void,
] => {
    const [searchParams, setSearchParams] = useSearchParams();
    const language = useMemo(() => {
        if (!scope) return undefined;
        if (!searchParams.has(scope)) return undefined;
        return parseSafely({
            schema: languageCodeZodSchema,
            value: searchParams.get(scope),
        });
    }, [scope, searchParams]);
    const setLanguage = useCallback(
        (
            newLanguage:
                | (LanguageCode | undefined)
                | ((currentLanguage: LanguageCode | undefined) => LanguageCode),
        ) => {
            if (!scope) {
                browserLogger.warn(
                    `Cannot set language "${newLanguage}" without scope provided`,
                );
                return;
            }
            const newValue = isFunction(newLanguage)
                ? newLanguage(language)
                : newLanguage;

            // If it's the default language, we don't need to store it in the url
            if (newValue === "sv" && scope === globalLanguageQueryParamName)
                searchParams.delete(scope);

            // If it's not the default language, we store it in the url
            if (newValue) searchParams.set(scope, newValue);
            // or remove it if it's undefined
            else searchParams.delete(scope);

            setSearchParams(searchParams);
        },
        [language, scope, searchParams, setSearchParams],
    );

    return useMemo(
        () => [language, setLanguage] as const,
        [language, setLanguage],
    );
};

function LanguageProvider(props: {
    readonly availableLanguages?: LanguageCode[];
    readonly children?: React.ReactNode;
}): JSX.Element;
function LanguageProvider(props: {
    readonly scope: string;
    readonly availableLanguages?: LanguageCode[];
    readonly children?: React.ReactNode;
}): JSX.Element;
function LanguageProvider(props: {
    readonly language: LanguageCode;
    readonly availableLanguages?: LanguageCode[];
    readonly children?: React.ReactNode;
}): JSX.Element;
function LanguageProvider(props: {
    readonly scope?: string;
    readonly availableLanguages?: LanguageCode[];
    readonly children?: React.ReactNode;
    readonly language?: LanguageCode;
}): JSX.Element {
    const availableLanguages: LanguageCode[] = useMemo(() => {
        return props.availableLanguages ?? [...languageCodes].sort();
    }, [props.availableLanguages]);

    // First, we figure out what mode this provider is in
    // We have three modes: global, scope, language
    // - Global: The language is the default language for the default scope
    // - Scope: The language is the default language for the given scope
    // - Language: The language is the default language for this context alone
    const mode: "global" | "scope" | "local" = (() => {
        if (props.scope) return "scope";
        if (props.language) return "local";
        return "global";
    })();

    // Then we figure out the scope setting
    const scope = useMemo(() => {
        if (props.scope) return encodeURIComponent(`lang_${props.scope}`);
        if (mode === "global") return globalLanguageQueryParamName;
        // Or if we're in a local scope for only the provider
        // we won't be using a scope at all
        return undefined;
    }, [mode, props.scope]);

    // Local storage
    const localStorageKey = scope;
    const [languageFromLocalStorage, setLanguageInLocalStorage] =
        useLocalStorageSetting<LanguageCode | undefined>(
            localStorageKey,
            undefined,
        );

    // Url query parameter
    const [languageFromUrl, setLanguageInUrl] = useLanguageFromUrl(scope);
    const [customLanguage, setCustomLanguage] = useState<
        LanguageCode | undefined
    >();

    // Automatically set local storage value
    // if language parameter for scope changes in url
    useEffect(() => {
        // if there is not scope, we don't need to do anything
        if (!scope) return;
        // if language is not defined in url, we don't need to do anything
        if (typeof languageFromUrl === "undefined") return;
        setLanguageInLocalStorage(languageFromUrl);
    }, [languageFromUrl, scope, setLanguageInUrl, setLanguageInLocalStorage]);

    // Automatically set url parameter if
    // language in local storage is not the default language
    useMountEffect(() => {
        // if there is no scope, we don't need to do anything
        if (!scope) return;
        const valueFromLocalStorage = parseSafely({
            schema: languageCodeZodSchema,
            value: languageFromLocalStorage,
        });
        if (!valueFromLocalStorage) return;
        if (valueFromLocalStorage === "sv") return;
        if (typeof languageFromUrl !== "undefined") return;
        setLanguageInUrl(valueFromLocalStorage);
    });

    // Select language to provide from sources
    const language: LanguageCode = useMemo(() => {
        browserLogger.info("Language provider > Language update check", {
            mode,
            scope,
            ...(scope === "local"
                ? {
                      customLanguage,
                  }
                : {
                      languageFromLocalStorage,
                      valueParsedFromLocalStorage: parseSafely({
                          schema: languageCodeZodSchema,
                          value: languageFromLocalStorage,
                      }),
                  }),
        });
        switch (mode) {
            case "global":
            case "scope": {
                // Get the local storage value, it should have been
                // updated automatically by any url language query parameter
                const valueFromLocalStorage = parseSafely({
                    schema: languageCodeZodSchema,
                    value: languageFromLocalStorage,
                });
                if (valueFromLocalStorage) return valueFromLocalStorage;
                // as a last fallback, use swedish as default language
                return languageCodeMap.sv;
            }
            case "local": {
                // If a custom language has been manually set, use that
                if (customLanguage) return customLanguage;
                // else use language given in props
                if (props.language) return props.language;
                // Since we can't a a local scope without
                // a language set in props, we'll throw here
                throw new Error("Local scope must have language set");
            }
            default:
                assertNever(mode);
        }
    }, [mode, scope, customLanguage, props.language, languageFromLocalStorage]);

    const setLanguage = useCallback(
        (language: LanguageCode) => {
            switch (mode) {
                case "global":
                case "scope": {
                    setLanguageInUrl(language);
                    setLanguageInLocalStorage(language);
                    break;
                }
                case "local":
                    setCustomLanguage(language);
                    break;
                default:
                    assertNever(mode);
            }
        },
        [mode, setLanguageInUrl, setLanguageInLocalStorage],
    );

    const context = useMemo(() => {
        browserLogger.info("Language provider > Context update", {
            mode,
            scope,
            language,
            availableLanguages,
        });
        return {
            language,
            availableLanguages,
            setLanguage,
        };
    }, [mode, scope, language, availableLanguages, setLanguage]);

    return (
        <languageContext.Provider value={context}>
            {props.children}
        </languageContext.Provider>
    );
}

const useLanguageContext = (): Context => {
    const context = useContext(languageContext);
    if (context === undefined) {
        throw new Error(
            "useLanguageContext must be used within a LanguageProvider",
        );
    }
    return context;
};

export { LanguageProvider, useLanguageContext, useLanguageFromUrl };
