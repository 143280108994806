import { useMe } from "@@/authentication/me/me-context";
import { ActionAndCancelButtons } from "@@/backoffice/shared/action-and-cancel-buttons";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox, TextEdit } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faEdit, faSignature, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Padding, fullName, translation } from "@towni/common";
import * as React from "react";
import { useSetUserName } from "../use-set-user-name";

type Props = {
    padding?: Padding;
};

const UserModalEditName = (props: Props) => {
    const theme = useTheme();
    const [me, { meQuery }] = useMe();
    const [status, setStatus] = React.useState<"VIEW" | "EDIT">("VIEW");
    const setUserName = useSetUserName({
        onSuccess: () => {
            setStatus("VIEW");
        },
    });
    const [updatedFirstName, setUpdatedFirstName] = React.useState<string>();
    const [updatedLastName, setUpdatedLastName] = React.useState<string>();
    const currentFirstName = me?.firstName;
    const currentLastName = me?.lastName;
    const isNewFirstName =
        typeof updatedFirstName !== "undefined" &&
        !!updatedFirstName &&
        updatedFirstName !== currentFirstName;
    const isNewLastName =
        typeof updatedLastName !== "undefined" &&
        !!updatedLastName &&
        updatedLastName !== currentLastName;

    return (
        <>
            <FlexRow fillParentWidth crossAxis="center" padding={props.padding}>
                {status === "VIEW" ? (
                    <FlexColumn fillParentWidth>
                        <FlexRow
                            fillParentWidth
                            mainAxis="space-between"
                            crossAxis="flex-start"
                            tag="name_row">
                            <FlexRow>
                                <Icon
                                    icon={faSignature}
                                    color={theme.colors.black.light65}
                                    padding={{ top: 3 }}
                                    css={{
                                        minWidth: 16,
                                    }}
                                />
                                <HorizontalDivider S />
                                <TextBox
                                    text={fullName({
                                        firstName: currentFirstName ?? "",
                                        lastName: currentLastName ?? "",
                                    })}
                                    color={theme.colors.default.text}
                                    grow={1}
                                    shrink={1}
                                    css={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                />
                            </FlexRow>
                            <FlexRow onClick={() => setStatus("EDIT")}>
                                <Icon
                                    icon={faEdit}
                                    size="XS"
                                    color={theme.colors.default.text.value.withAlpha(
                                        0.3,
                                    )}
                                />
                                <HorizontalDivider XXS />
                                <TextBox
                                    text="Ändra"
                                    size="XS"
                                    color={theme.colors.default.text.value.withAlpha(
                                        0.5,
                                    )}
                                />
                            </FlexRow>
                        </FlexRow>
                    </FlexColumn>
                ) : null}
                {status === "EDIT" ? (
                    <FlexColumn fillParentWidth>
                        <FlexRow crossAxis="center">
                            <TextEdit
                                type="text"
                                label={translation({
                                    sv: "Förnamn",
                                    en: "First name",
                                })}
                                placeholder={translation({
                                    sv: "Förnamn",
                                    en: "First name",
                                })}
                                value={
                                    updatedFirstName ?? currentFirstName ?? ""
                                }
                                onChange={setUpdatedFirstName}
                                disabled={meQuery.isPending}
                                showSpinner={meQuery.isPending}
                            />
                            {isNewFirstName ? (
                                <FlexRow
                                    padding={{ top: 30 }}
                                    crossAxis="center"
                                    onClick={() =>
                                        setUpdatedFirstName(undefined)
                                    }>
                                    <HorizontalDivider S />
                                    <FontAwesomeIcon
                                        icon={faUndo}
                                        color={
                                            theme.colors.black.light65.asString
                                        }
                                        style={{ fontSize: "1.5rem" }}
                                    />
                                </FlexRow>
                            ) : null}
                        </FlexRow>
                        <VerticalDivider S />
                        <FlexRow crossAxis="center">
                            <TextEdit
                                type="text"
                                label={translation({
                                    sv: "Efternamn",
                                    en: "Last name",
                                })}
                                placeholder={translation({
                                    sv: "Efternamn",
                                    en: "Last name",
                                })}
                                value={updatedLastName ?? currentLastName ?? ""}
                                onChange={setUpdatedLastName}
                                disabled={meQuery.isPending}
                                showSpinner={meQuery.isPending}
                            />
                            {isNewLastName ? (
                                <FlexRow
                                    padding={{ top: 30 }}
                                    crossAxis="center"
                                    onClick={() =>
                                        setUpdatedLastName(undefined)
                                    }>
                                    <HorizontalDivider S />
                                    <FontAwesomeIcon
                                        icon={faUndo}
                                        color={
                                            theme.colors.black.light65.asString
                                        }
                                        style={{ fontSize: "1.5rem" }}
                                    />
                                </FlexRow>
                            ) : null}
                        </FlexRow>
                        <ActionAndCancelButtons
                            actionTitle="Spara"
                            padding={{ top: 10, bottom: 0 }}
                            disabled={
                                (!isNewFirstName && !isNewLastName) ||
                                !me?._id ||
                                setUserName.isPending
                            }
                            isLoading={setUserName.isPending}
                            onCancel={() => setStatus("VIEW")}
                            onAction={() => {
                                if (!me?._id) return;
                                setUserName.mutateAsync({
                                    userId: me._id,
                                    firstName:
                                        updatedFirstName || currentFirstName,
                                    lastName:
                                        updatedLastName || currentLastName,
                                });
                            }}
                        />
                    </FlexColumn>
                ) : null}
            </FlexRow>
        </>
    );
};

export { UserModalEditName };
