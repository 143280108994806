import { useUpdateEffect } from "@@/shared/use-update-effect";
import * as Sentry from "@sentry/react";
import { emptyArrayOf, generateId } from "@towni/common";
import * as React from "react";
import { createContext, useContext, useRef } from "react";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { Anchor } from "./container-navigation/anchor";
import { AnchorId } from "./container-navigation/anchor-id";

type ContextState = State & Actions;
type State = {
    // state
    readonly _id: string;
    readonly _type: "CONTAINER_NAVIGATION_STATE";
    readonly activeAnchorId: AnchorId | undefined;
    readonly navigateToAnchorId: AnchorId | undefined;
    readonly containerElement: HTMLDivElement | null;
    readonly navBarElement: HTMLDivElement | null;
    // readonly navigationBarRef: HTMLDivElement | null | undefined;
    readonly anchors: Map<AnchorId, Anchor>;
    readonly anchorOrder: AnchorId[];
    // readonly anchorPositions: AnchorPositions;
    readonly containerScrollPositionY: number;
};
type Actions = {
    setAnchors: (anchors: Anchor[]) => void;
    setActiveAnchorId: (anchorId: AnchorId) => void;
    setNavigateToAnchorId: (anchorId: AnchorId) => void;
    setContainerElement: (element: HTMLDivElement | null) => void;
    setNavBarElement: (element: HTMLDivElement | null) => void;
    setAnchorNavBarItemElement: (
        anchorId: AnchorId,
    ) => (element: HTMLDivElement | null) => void;
    setAnchorContainerItemElement: (
        anchorId: AnchorId,
    ) => (element: HTMLDivElement | null) => void;
    addAnchor: (anchor: Anchor) => void;
    setContainerScrollPositionY: (y: number) => void;
};

const ContainerNavigationContext = createContext<
    StoreApi<ContextState> | undefined
>(undefined);

type Props = {
    anchors: Anchor[] | undefined;
    children: React.ReactNode;
};

const ContainerNavigationContextProvider = (props: Props) => {
    const parseAnchorValues = (anchors: Anchor[] | undefined) => {
        const _anchors = anchors ?? emptyArrayOf<Anchor>();
        return {
            anchors: new Map<AnchorId, Anchor>(
                _anchors.map(anchor => [anchor._id, anchor] as const),
            ),
            anchorOrder: _anchors.map(anchor => anchor._id),
        };
    };

    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, _get) => {
            const currentAnchors = parseAnchorValues(props.anchors);
            return {
                // state
                _id: generateId({ prefix: "container_navigation_state__" }),
                _type: "CONTAINER_NAVIGATION_STATE",
                activeAnchorId: undefined,
                navigateToAnchorId: undefined,
                containerElement: null,
                navBarElement: null,
                anchors: currentAnchors.anchors,
                anchorOrder: currentAnchors.anchorOrder,
                containerScrollPositionY: 0,
                // actions
                setAnchors: (anchors: Anchor[]) => {
                    set(_ => parseAnchorValues(anchors));
                },
                setActiveAnchorId: (anchorId: AnchorId) => {
                    set(_ => ({ activeAnchorId: anchorId }));
                },
                setNavigateToAnchorId: (anchorId: AnchorId) => {
                    set(_ => ({ navigateToAnchorId: anchorId }));
                },
                setContainerElement: (element: HTMLDivElement | null) => {
                    set(_ => ({ containerElement: element }));
                },
                setNavBarElement: (element: HTMLDivElement | null) => {
                    set(_ => ({ navBarElement: element }));
                },
                setAnchorNavBarItemElement:
                    (anchorId: AnchorId) =>
                    (element: HTMLDivElement | null) => {
                        set(state => {
                            if (
                                state.anchors.get(anchorId)
                                    ?.navBarItemElement === element
                            ) {
                                return state;
                            }
                            const update = new Map(state.anchors);
                            const current = update.get(anchorId);
                            if (!current) {
                                Sentry.withScope(scope => {
                                    scope.setExtras(state),
                                        Sentry.captureException(
                                            new Error(
                                                `Anchor with anchorId ${anchorId} does not exist`,
                                            ),
                                        );
                                });
                                // Ignore error
                                // menu will probably not work as expected
                                // but page won't crash
                                return state;
                            }
                            update.set(anchorId, {
                                ...current,
                                navBarItemElement: element,
                            });
                            return {
                                ...state,
                                anchors: update,
                            };
                        });
                    },
                setAnchorContainerItemElement:
                    (anchorId: AnchorId) =>
                    (element: HTMLDivElement | null) => {
                        set(state => {
                            if (
                                state.anchors.get(anchorId)
                                    ?.containerItemElement === element
                            ) {
                                return state;
                            }

                            const update = new Map(state.anchors);
                            const current = update.get(anchorId);
                            if (!current) {
                                if (!current) {
                                    Sentry.withScope(scope => {
                                        scope.setExtras(state),
                                            Sentry.captureException(
                                                new Error(
                                                    `Anchor with anchorId ${anchorId} does not exist`,
                                                ),
                                            );
                                    });
                                    // Ignore error
                                    // menu will probably not work as expected
                                    // but page won't crash
                                    return state;
                                }
                            }
                            update.set(anchorId, {
                                ...current,
                                containerItemElement: element,
                            });
                            return {
                                ...state,
                                anchors: update,
                            };
                        });
                    },
                addAnchor: (anchor: Anchor) => {
                    set(state => {
                        const update = new Map(state.anchors);
                        update.set(anchor._id, anchor);
                        return {
                            anchors: update,
                            anchorOrder: [...state.anchorOrder, anchor._id],
                        };
                    });
                },
                setContainerScrollPositionY: (y: number) => {
                    set(_ => ({ containerScrollPositionY: y }));
                },
            };
        }),
    );

    useUpdateEffect(() => {
        store.current
            .getState()
            .setAnchors(props.anchors ?? emptyArrayOf<Anchor>());
    }, [store.current, props.anchors]);

    return (
        <ContainerNavigationContext.Provider value={store.current}>
            {props.children}
        </ContainerNavigationContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useContainerNavigationContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(ContainerNavigationContext);
    if (store === undefined) {
        throw new Error(
            "useContainerNavigationContext must be used within a ContainerNavigationContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};
export { ContainerNavigationContextProvider, useContainerNavigationContext };
