import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormColors } from "@@/shared/form/use-form-colors";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { paddingToCssValue } from "@@/shared/padding";
import { FieldTitle } from "@@/shared/text/field-title";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, css, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { Padding, Translatable, asArray } from "@towni/common";
import { Draft } from "immer";
import { useRef, useState } from "react";
import { ZodSchema } from "zod";

type PossibleValueTypes = string | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value | undefined;
    readonly setter: (
        state: Draft<Partial<State>>,
        newValue: Value | undefined,
    ) => void;
    readonly fieldSchema?: ZodSchema;
    readonly type?: "text" | "password" | "email" | "url" | "date";
    readonly autoComplete?: string;
    /**
     * Decides wether field should be a text area or regular input of type text. \
     * When set to a number > 0; the number will be used as the number of rows visible for the text-area. \
     * Number of rows defaults to `4` if value of property is set to `true`
     * @type {(boolean | number)}
     */
    readonly multiline?: boolean | number;
    /**
     * Optional element to be rendered before the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly preElement?: JSX.Element;
    /**
     * Optional element to be rendered after the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly postElement?: JSX.Element;
    readonly description?: Translatable;
    readonly placeholder?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    readonly label?: Translatable;
    readonly charFilters?: RegExp | RegExp[];
    readonly labelDescription?: Translatable;
    readonly hideErrors?: boolean;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    /** Visar fältet som required, även om det inte krävs enligt zod schemat. */
    readonly showAsRequired?: boolean;
    readonly containerCss?: Interpolation<AppTheme> | Interpolation<AppTheme>[];
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
    readonly onChangePreProcess?: (
        value: Value | undefined,
    ) => Value | undefined;
};

const createFormTextInputComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2TextInput = <
        Value extends PossibleValueTypes = PossibleValueTypes,
    >(
        props: Props<State, Value>,
    ) => {
        const theme = useTheme();
        const translate = useTranslate();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value | undefined>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            formId: props.formId,
            fieldSchema: props.fieldSchema,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );

        const [initialValue] = useState(field.value);
        const hasErrors = field.errors.length > 0;
        const validationTrigger = useFormFieldValidation<
            State,
            Value | undefined
        >({
            field,
            initialValidationType: "manual",
            preProcessValue: props.onChangePreProcess,
        });

        const InputElement = props.multiline ? "textarea" : "input";

        // Colors
        const { borderColor, backgroundColor, textColor, spinnerColor } =
            useFormColors({ hasErrors });

        // Styles
        const padding = paddingToCssValue(props.padding ?? { all: 14 });
        const containerStyles = css({
            cursor: "pointer",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor,
            backgroundColor,
            borderRadius: theme.radius,
            flex: 1,
            opacity: props.disabled ? 0.5 : 1,
            padding,
        });

        const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(
            null,
        );
        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                tag={`form-field_${props.fieldId}`}
                crossAxis="stretch">
                <Conditional when={!!props.label}>
                    <FlexRow
                        shrink={0}
                        crossAxis="center"
                        mainAxis="space-between">
                        <FieldTitle
                            htmlFor={props.fieldId}
                            padding={{ left: 2 }}
                            text={props.label ?? ""} // already checked with conditional above
                            // color={props.labelColor}
                            required={props.showAsRequired || field.isRequired}
                        />
                        <Conditional when={!!props.labelDescription}>
                            <HorizontalDivider XXS />
                            <FieldTitle
                                padding={{ left: 2 }}
                                text={props.labelDescription ?? ""} // already checked with conditional above
                                // color={props.labelColor}
                                weight="400"
                                size="S"
                                css={{
                                    opacity: 0.5,
                                }}
                            />
                        </Conditional>
                    </FlexRow>
                    <VerticalDivider XS />
                </Conditional>
                {/* <pre>
                    {JSON.stringify(
                        {
                            initValue: field.initialValue,
                            value: field.value,
                            preProcess: !!props.onChangePreProcess,
                            afterPreProcess: props.onChangePreProcess?.(
                                field.initialValue,
                            ),
                        },
                        null,
                        2,
                    )}
                </pre> */}
                <FlexRow
                    // width={props.width}
                    css={[
                        containerStyles,
                        ...(props.containerCss
                            ? asArray(props.containerCss)
                            : []),
                    ]}
                    crossAxis="center"
                    mainAxis="space-between"
                    onClick={() => {
                        inputRef.current?.focus();
                    }}>
                    <Conditional when={!!props.preElement}>
                        <FlexRow
                            css={{
                                flexShrink: 0,
                                flexGrow: 0,
                            }}>
                            {props.preElement ?? null}
                        </FlexRow>
                        <HorizontalDivider />
                    </Conditional>
                    <InputElement
                        ref={(
                            node: HTMLInputElement | HTMLTextAreaElement | null,
                        ) => {
                            inputRef.current = node;
                        }}
                        placeholder={translate(props.placeholder)}
                        onBeforeInput={
                            // If charFilters is set,
                            // prevent input of characters that match the filter(s)
                            props.charFilters
                                ? event => {
                                      const input = (
                                          event as unknown as InputEvent
                                      ).data as string;
                                      const prevent = asArray(
                                          props.charFilters,
                                      ).some(filter => {
                                          filter.lastIndex = 0; // because of global flag weirdness
                                          return filter.test(input);
                                      });
                                      if (prevent) {
                                          event.preventDefault();
                                          return false;
                                      }
                                      return true;
                                  }
                                : undefined
                        }
                        className={props.className}
                        type={props.type ?? "text"}
                        rows={
                            !props.multiline
                                ? undefined
                                : typeof props.multiline === "number"
                                  ? props.multiline
                                  : 4
                        }
                        autoComplete={props.autoComplete}
                        defaultValue={field.value || undefined}
                        onBlur={() => {
                            field.setTouched(true);
                            validationTrigger();
                        }}
                        disabled={field.isSubmitting}
                        onChange={e => {
                            const newValue = e.target.value.trim() as Value;
                            const processedNewValue = props.onChangePreProcess
                                ? props.onChangePreProcess(newValue)
                                : newValue;
                            // console.log({
                            //     newValue,
                            //     processedNewValue,
                            //     initialValue,
                            // });
                            field.setValue(processedNewValue);
                            field.setDirty(processedNewValue !== initialValue);
                            field.setTouched(true);
                        }}
                        css={[
                            {
                                userSelect: "auto",
                                border: "none",
                                flex: 1,
                                fontSize: "1rem",
                                backgroundColor,
                                color: textColor,
                            },
                            props.inputCss,
                            hasErrors && {
                                borderColor: theme.colors.danger.asString,
                            },
                        ]}
                    />
                    <Conditional when={!!props.postElement}>
                        <HorizontalDivider />
                        <FlexRow
                            css={{
                                flexGrow: 0,
                                flexShrink: 0,
                            }}>
                            {props.postElement ?? null}
                        </FlexRow>
                    </Conditional>
                    <Conditional when={!!props.spin}>
                        <FlexRow
                            css={{
                                flexGrow: 0,
                                flexShrink: 0,
                            }}>
                            <HorizontalDivider />
                            <Icon
                                icon={faSpinnerThird}
                                spin
                                color={spinnerColor}
                            />
                        </FlexRow>
                    </Conditional>
                </FlexRow>
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        marginTop: 5,
                    }}
                />
                <Conditional when={!props.hideErrors}>
                    <FormErrorMessages errors={field.errors} />
                </Conditional>
            </FlexColumn>
        );
    };

    return Form2TextInput;
};
export { createFormTextInputComponent };
export type {
    PossibleValueTypes as Form2TextInputPossibleValueTypes,
    Props as Form2TextInputProps,
};
