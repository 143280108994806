import { Me } from "@towni/common";
import * as React from "react";
import { RequiredMeContext } from "./required-me.context";

const useMeFromContext = <T extends boolean>(params?: {
    disableThrowWhenMissingContext?: T;
}): [T] extends [true] ? Me | undefined : Me => {
    const meFromContext = React.useContext(RequiredMeContext);
    const disableThrow = params?.disableThrowWhenMissingContext;
    if (!meFromContext && !disableThrow) {
        throw new Error(
            "useMeFromContext must be used within a RequiredMeContext"
        );
    }

    // any because of: https://github.com/Microsoft/TypeScript/issues/24929
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return meFromContext as any;
};

export { useMeFromContext };
