import { Translatable } from "@towni/common";
import { createContext, useContext, useRef } from "react";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

type ContextState = State & Actions;
type State = {
    errorText: Translatable | undefined;
    failedCount: number;
    value: string | undefined;
};
type Actions = {
    setErrorText: (errorText: Translatable | undefined) => void;
    setValue: (value: string | undefined) => void;
    increaseFailedCount: (value: number) => void;
    resetFailedCount: () => void;
};

const CaptchaContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    children: React.ReactNode;
};
const CaptchaContextProvider = (props: Props) => {
    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, _get) => {
            const setErrorText = (
                errorText: Translatable | undefined,
            ): void => {
                set({ errorText });
                return;
            };
            const setValue = (value: string | undefined): void => {
                set({ value });
                return;
            };
            const increaseFailedCount = (value: number): void => {
                set(state => {
                    return {
                        failedCount: state.failedCount + value,
                    };
                });
            };
            const resetFailedCount = (): void => {
                set({
                    failedCount: 0,
                });
            };
            return {
                errorText: undefined,
                failedCount: 0,
                value: undefined,
                setErrorText,
                setValue,
                increaseFailedCount,
                resetFailedCount,
            };
        }),
    );

    return (
        <CaptchaContext.Provider value={store.current}>
            {props.children}
        </CaptchaContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useCaptchaContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(CaptchaContext);
    if (store === undefined) {
        throw new Error(
            "useCaptchaContext must be used within a CaptchaContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { CaptchaContextProvider, useCaptchaContext };
