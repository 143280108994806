import { apiFetchClient } from "@@/shared/fetch-client";
import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import { createQueryKey } from "@@/shared/state/use-api-resources";
import {
    DiscountCode,
    DiscountCodeId,
    GetSingleResponse,
    ProviderId,
} from "@towni/common";

const mainQueryKey = "discount-codes";

const fetch = (providerId: ProviderId, id: DiscountCodeId) => async () => {
    const result = await apiFetchClient.get<GetSingleResponse<DiscountCode>>({
        route: `/discounts/codes/for-provider/${encodeURIComponent(
            providerId ?? ""
        )}/${encodeURIComponent(id ?? "")}`,
    });
    return result.item;
};

const useDiscountCode = (
    providerId: ProviderId | undefined,
    codeId: DiscountCodeId | undefined
) => {
    const id = codeId ?? ("" as DiscountCodeId);
    const pid = providerId ?? ("" as ProviderId);

    return useSingleApiResource<DiscountCode>(
        createQueryKey(mainQueryKey, id),
        fetch(pid, id),
        {
            enabled: !!codeId,
        }
    );
};

export { useDiscountCode, mainQueryKey as discountCodeQueryKey };
