import type { AppTheme } from "@@/styles/theme";
import type { Interpolation } from "@emotion/react";
import type { RemSize } from "@towni/common";

type ParagraphHzSpace = {
    _type: "PARAGRAPH_HZ_SPACE";
    size: number;
    css?: Interpolation<AppTheme>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphHzSpace = (item: any): item is ParagraphHzSpace => {
    return item?._type === "PARAGRAPH_HZ_SPACE";
};

/**
 * Defaults to size 0.25rem
 *
 * @param {(number | RemSize)} [size=0.25]
 * @return {*}  {ParagraphHzSpace}
 */
const paragraphHzSpaceFactory = (
    size: number | RemSize = 0.25,
): ParagraphHzSpace => {
    return {
        _type: "PARAGRAPH_HZ_SPACE",
        size,
    };
};

const ParagraphHzSpace = (props: {
    hzSpace: ParagraphHzSpace;
    className?: string;
}) => {
    return (
        <span
            className={props.className}
            css={[
                ...(props.hzSpace.css ? [props.hzSpace.css] : []),
                {
                    width: `${props.hzSpace.size}rem`,
                    display: "inline-block",
                },
            ]}
        />
    );
};

export { ParagraphHzSpace, isParagraphHzSpace, paragraphHzSpaceFactory };
