import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useProductDetailsContext } from "@@/products/product-details-context";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    MinMaxRange,
    OrderItemId,
    Padding,
    getChildOptionals,
    isOrderItemOptional_V2,
    translation,
    unique,
} from "@towni/common";
import { motion } from "framer-motion";
import { CartQuantityPicker } from "./cart-quantity-picker";

type Props = {
    readonly padding?: Padding;
    readonly background?: BackgroundStyle;
    readonly hide?: () => void;
    readonly clearBeforeAdding: boolean;
    readonly orderItemId: OrderItemId;
    readonly pickLimit?: {
        readonly alreadyPicked: number;
        readonly minMax: MinMaxRange;
    };
};

const EditInCartWithQuantity = (props: Props) => {
    const theme = useTheme();
    const state = useProductDetailsContext();
    const { cart, removeItemFromCart, addItemsToCart, clearCart } =
        useCartContext(context => ({
            cart: context.cart,
            removeItemFromCart: context.removeItemFromCart,
            clearCart: context.clearCart,
            addItemsToCart: context.addItemsToCart,
        }));
    const translate = useTranslate();

    const originalCartOrderItem = cart.orderItems.find(
        item => item._id === state.orderItemToReplaceId,
    );

    if (!originalCartOrderItem)
        throw new Error("Order item to edit not found in cart");

    useMountEffect(() => {
        state.setQuantity(originalCartOrderItem.quantity.value);
        const items = getChildOptionals(
            originalCartOrderItem,
            cart.orderItems,
        ).filter(isOrderItemOptional_V2);

        const map = items.map(item => {
            const value = item.quantity;
            const optional = item.optional;

            return {
                optional,
                quantity: value,
            };
        });
        state.setPickedOptionals(map);
    });
    const addButtonText = state.addToCartDisabled
        ? translation({
              sv: "Välj först tillval",
              en: "First select options",
          })
        : translation({
              sv: "Spara",
              en: "Update",
          });

    const originalQuantity = originalCartOrderItem.quantity.value;

    const totalPickedAmountOverLimit =
        state.quantityValue -
            originalQuantity +
            (props.pickLimit?.alreadyPicked ?? 0) >
        (props.pickLimit?.minMax.max ?? Number.MAX_SAFE_INTEGER);
    const maxAmount =
        props.pickLimit?.minMax.max ??
        Number.MAX_SAFE_INTEGER - (props.pickLimit?.alreadyPicked ?? 0);

    const output = (
        <motion.div
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
            transition={theme.spring}
            css={{
                display: "block",
                position: "absolute",
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                zIndex: 9999,
                top: 0,
                left: 0,
            }}>
            <FlexColumn fillParent mainAxis="flex-end" crossAxis="stretch">
                <FlexColumn
                    crossAxis="stretch"
                    background={{
                        linearGradient: {
                            _type: "LINEAR_GRADIENT",
                            steps: [
                                {
                                    color: theme.colors.default.background,
                                    percentage: 0.5,
                                },
                                {
                                    color: theme.colors.white.value.withAlpha(
                                        0.001,
                                    ), // to prevent weird errors with alpha 0
                                    percentage: 1,
                                },
                            ],
                        },
                    }}>
                    <VerticalDivider XL />
                    <Conditional
                        when={totalPickedAmountOverLimit}
                        render={() => (
                            <FlexColumn
                                fillParentWidth
                                padding={{ all: 20, bottom: 0 }}>
                                <FlexColumn
                                    css={{
                                        borderRadius: 10,
                                        backgroundColor:
                                            theme.colors.black.light95.asString,
                                        padding: 15,
                                    }}>
                                    <TextBox
                                        color={theme.colors.danger}
                                        size={0.9}
                                        text={translation({
                                            sv:
                                                "Du kan inte lägga till fler än " +
                                                maxAmount +
                                                " st.",
                                            en:
                                                "You can't add more than " +
                                                maxAmount +
                                                " items.",
                                        })}
                                    />
                                </FlexColumn>
                            </FlexColumn>
                        )}
                    />
                    <FlexRow
                        css={{ pointerEvents: "all" }}
                        mainAxis="space-between"
                        padding={{
                            ...props.padding,
                            top: props.padding?.top || 15,
                        }}>
                        <FlexRow grow={0}>
                            <CartQuantityPicker
                                quantity={state.quantityValue}
                                decrease={state.decreaseQuantity}
                                increase={state.increaseQuantity}
                                disabled={false}
                            />
                        </FlexRow>
                        <HorizontalDivider />
                        <FlexRow grow={1}>
                            <ButtonPrimary
                                fillParentWidth
                                disabled={
                                    !!state.addToCartDisabled ||
                                    totalPickedAmountOverLimit
                                }
                                onClick={() => {
                                    // If there's no item to add, ignore click
                                    const cartItem = state.getOrderItem();
                                    if (!cartItem) return;
                                    if (props.clearBeforeAdding) clearCart();

                                    // What provider do the items in cart belong to?
                                    const cartProviderId = unique(
                                        cart?.orderItems?.map(
                                            orderItem => orderItem.providerId,
                                        ) ?? [],
                                    )[0];

                                    // If it's a different provider than current item belongs to
                                    // warn customer and ask if they want to continue/switch provider
                                    if (
                                        cartProviderId &&
                                        cartProviderId !==
                                            cartItem[0].providerId
                                    ) {
                                        alert(
                                            translate(
                                                translation({
                                                    sv: "Du har redan varor i din varukorg från en annan butik/restaurang. Handla gärna från flera men från en i taget. 🙂",
                                                    en: "You already have items in your cart from another store/restaurant. Please order from one at a time. 🙂",
                                                }),
                                            ),
                                        );
                                        return;
                                    }

                                    // Replace item in cart (in other words updates if item already exists)
                                    removeItemFromCart(props.orderItemId);
                                    // cartItem.map(ca =>
                                    //     cartActions.addToCart(ca)
                                    // ); //cartActions.addToCart(cartItem);
                                    addItemsToCart(cartItem);

                                    // Do requested action after adding item to cart
                                    // if there is one
                                    props.hide?.();
                                }}
                                padding={{ topBottom: 13, leftRight: 30 }}
                                css={{ borderRadius: 40 }}>
                                <TextBox weight="700" text={addButtonText} />
                            </ButtonPrimary>
                        </FlexRow>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </motion.div>
    );

    return output;
};

export { EditInCartWithQuantity };
