import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { useCart } from "@@/carts/multi-carts/cart.context";
import { useBookingContext } from "@@/products/bookables/booking-context";
import { useCalculateObligatoryExtras } from "@@/products/bookables/booking-wizard/extras-and-optionals.tsx/use-is-booking-complete-with-extras-and-optionals";
import { useCreateReservationFromBookingContext } from "@@/products/bookables/hooks/use-create-reservation";
import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    BookableSession,
    Quantity,
    asDate,
    defaultAmount,
    findMinPrice,
    getBookableResourceReferences,
    isQuantityPickerBookableOption,
    isQuantityPickerBookableOptionValue,
    isTimeRange,
    orderItemsFromSku,
    quantityPickerBookableOptionValueFactory,
    resourceReservationRequestsFactory,
    sumPrices,
    timeRangeByDurationFactory,
} from "@towni/common";
import { isFuture } from "date-fns";
import React, { useCallback } from "react";

const useCurrentBookablePrice = () => {
    const state = useBookingContext(context => ({
        product: context.product,
        provider: context.provider,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        messageToProvider: context.messageToProvider,
        sessions: context.sessions,
        bookable: context.bookable,
        pickedOptionalsMap: context.pickedOptionalsMap,
        resourcePickerEnabled: context.resourcePickerEnabled,
        optionValues: context.optionValues,
    }));

    const [createReservationFromBookingContext] =
        useCreateReservationFromBookingContext();
    const obligatoryExtras = useCalculateObligatoryExtras([]);
    const [providerResources, _] = useBookableResources(state.product._id);
    const cart = useCart();
    const [discountsForProduct, _discountsIsLoading] = useDiscountsForProduct(
        state.product,
    );

    const minPrice = React.useCallback(() => {
        const picked = Array.from(state.pickedOptionalsMap.values()).flat();

        const prices = state.sessions
            .filter(s => isFuture(asDate(s.start)))
            .splice(0, 200)
            .flatMap(session => {
                const defaultTimeRange = timeRangeByDurationFactory(
                    session.start,
                    {
                        value: session.durationMinMax.min,
                        granularity: session.durationGranularity,
                    },
                );
                const optionValues = getBookableResourceReferences(
                    state.bookable,
                ).flatMap(r => {
                    return r.options.flatMap(option => {
                        if (isQuantityPickerBookableOption(option)) {
                            const override = state.optionValues.find(
                                o => o.parentId === option._id,
                            );
                            const quantity =
                                isQuantityPickerBookableOptionValue(override)
                                    ? override.quantity
                                    : ({
                                          amount: defaultAmount,
                                          value:
                                              option.default ??
                                              option.minMax.min ??
                                              0,
                                      } as Quantity);

                            const optionValue =
                                quantityPickerBookableOptionValueFactory({
                                    meta: option.meta,
                                    quantity: quantity,
                                    parentId: option._id,
                                    resourceId: option.resourceId,
                                    resourceReferenceId:
                                        option.resourceReferenceId,
                                    resourcePrice: option.resourcePrice,
                                });
                            return [optionValue];
                        }

                        return [];
                    });
                });

                const res = resourceReservationRequestsFactory({
                    bookableSku: state.sku,
                    globalResources: providerResources,
                    previousRequests: [],
                    session,
                    optionValues,
                    sessionTimeRange: defaultTimeRange,
                    resourceReservationInfo: [],
                    requestedResources: undefined,
                    settings:
                        session.bookableSettings ?? state.bookable.settings,
                    cartId: cart._id,
                    overrideSessionSettings: false,
                });
                if (res.length > 0) {
                    const orderItems = orderItemsFromSku({
                        sku: state.sku,
                        product: state.product,
                        provider: state.provider,
                        quantity: {
                            amount: state.sku.acquire.amount,
                            value: 1,
                        },
                        pickedOptions: picked,
                        discounts: discountsForProduct,
                        messageToProvider: state.messageToProvider,
                        time: defaultTimeRange,
                        resourceReservations: res,
                        bookableSettings:
                            session.bookableSettings ?? state.bookable.settings,
                    });

                    const obligatory = obligatoryExtras(orderItems);

                    return sumPrices([
                        ...orderItems.map(oi => oi.price),
                        ...obligatory.map(e => e.price),
                    ]);
                }
                return [];
            });

        return findMinPrice(prices, price => price, false);
    }, [
        state.pickedOptionalsMap,
        state.sessions,
        state.bookable,
        state.sku,
        state.optionValues,
        state.product,
        state.provider,
        state.messageToProvider,
        providerResources,
        cart._id,
        discountsForProduct,
        obligatoryExtras,
    ]);

    const calculateCurrentPrice = useCallback(
        (specificSession: BookableSession | undefined) => {
            const session = specificSession ?? state.session;
            const defaultTimeRange2 = specificSession
                ? timeRangeByDurationFactory(specificSession.start, {
                      value: specificSession.durationMinMax.min,
                      granularity: specificSession.durationGranularity,
                  })
                : null;

            const timeRange = defaultTimeRange2 ?? state.timeRange;
            if (isTimeRange(timeRange) && session) {
                const picked = Array.from(
                    state.pickedOptionalsMap.values(),
                ).flat();

                const res = createReservationFromBookingContext(session);
                const orderItems = orderItemsFromSku({
                    sku: state.sku,
                    product: state.product,
                    provider: state.provider,
                    quantity: {
                        amount: state.sku.acquire.amount,
                        value: 1,
                    },
                    pickedOptions: picked,

                    discounts: discountsForProduct,
                    messageToProvider: state.messageToProvider,
                    time: timeRange,
                    resourceReservations: res,
                    bookableSettings: session.bookableSettings,
                });
                const obligatory = obligatoryExtras(orderItems);

                return sumPrices([
                    ...orderItems.map(oi => oi.price),
                    ...obligatory.map(e => e.price),
                ]);
            } else if (
                state.sessions.filter(s => isFuture(asDate(s.start))).length > 0
            ) {
                return minPrice();
            }
            return minPrice();
        },
        [
            createReservationFromBookingContext,
            discountsForProduct,
            minPrice,
            obligatoryExtras,
            state.messageToProvider,
            state.pickedOptionalsMap,
            state.product,
            state.provider,
            state.session,
            state.sessions,
            state.sku,
            state.timeRange,
        ],
    );

    return calculateCurrentPrice;
};

export { useCurrentBookablePrice };
