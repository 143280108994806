import {
    useSessions,
    useSessionsByProducts,
} from "@@/products/state/queries/session/use-sessions";
import {
    Product,
    calculatePossibleSessionLengths,
    isBookable,
    isInTheFuture,
    timeRangeFactory,
} from "@towni/common";
import { addYears, startOfToday, startOfYesterday } from "date-fns";
import { useEffect, useMemo, useState } from "react";

const useSessionAvailability = (product?: Product) => {
    const sku = product?.skus[0];
    const [sessions, sessionsQuery] = useSessions(
        product?._id,
        timeRangeFactory(startOfYesterday(), addYears(startOfToday(), 2))
    );
    const [available, setAvailable] = useState(false);

    useEffect(() => {
        const _available =
            sku && isBookable(sku.acquire)
                ? sessions.some(session => {
                      const times = calculatePossibleSessionLengths(session);
                      if (times.length === 0) return false;
                      return isInTheFuture(
                          session.bookableSettings.allowBookingDuringSession
                              ? times[0].end
                              : session.start
                      );
                  })
                : false;

        setAvailable(_available);
    }, [sku, sessions]);

    return useMemo(
        () => [available, sessions, sessionsQuery] as const,
        [available, sessions, sessionsQuery]
    );
};

const useFilterProductsWithActiveSessions = (products: Product[]) => {
    const [sessions, _sessionsQuery] = useSessionsByProducts(
        products.map(p => p._id),
        timeRangeFactory(startOfYesterday(), addYears(startOfToday(), 1))
    );

    const filteredProducts = useMemo(() => {
        const filteredProducts = products.filter(product => {
            const sku = product?.skus[0];
            const _available =
                sku && isBookable(sku.acquire)
                    ? sessions.some(session => {
                          const times =
                              calculatePossibleSessionLengths(session);
                          if (times.length === 0) return false;
                          return isInTheFuture(
                              session.bookableSettings.allowBookingDuringSession
                                  ? times[0].end
                                  : session.start
                          );
                      })
                    : false;

            return _available;
        });
        return filteredProducts;
    }, [sessions, products]);
    return filteredProducts;
};

export { useFilterProductsWithActiveSessions, useSessionAvailability };
