import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { emSize } from "@towni/common";
import { Conditional } from "../../conditional";
import { ForEach } from "../../for-each";
import { TabViewProps } from "../shared/tab-view-props";
import { useTabView } from "../shared/use-tabs";

const TabView = (props: TabViewProps) => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const theme = useTheme();
    const { selectedTabIndex, setSelectedTabIndex } = useTabView({
        id: props.id,
        tabs: props.tabs,
    });

    return (
        <FlexColumn
            fillParent
            mainAxis="flex-start"
            crossAxis="stretch"
            tag="tab_view"
            className={props.className}>
            <FlexRow
                fillParentWidth
                mainAxis="stretch"
                crossAxis="center"
                padding={{
                    top: 20,
                    bottom: props.bottomPadding ?? 20,
                    leftRight: !props.fullWidthForDesktopOnly
                        ? 0
                        : isDesktop
                          ? 0
                          : 20,
                }}
                tag="tab_view_row">
                <Conditional
                    when={
                        isDesktop ||
                        props.disableFallbackToDropdownOnSmallScreen
                    }
                    render={() => (
                        <ForEach itemOf={props.tabs} getKey={tab => tab.id}>
                            {(tab, index) => {
                                const isSelected = selectedTabIndex === index;
                                const color = isSelected
                                    ? theme.colors.primary
                                    : theme.colors.default.text.light70;
                                const lineColor = isSelected
                                    ? theme.colors.primary
                                    : theme.colors.default.text.light90;

                                return (
                                    <FlexColumn
                                        key={tab.id}
                                        basis={1 / (props.tabs.length ?? 1)}
                                        mainAxis="space-between"
                                        crossAxis="stretch">
                                        <FlexColumn
                                            mainAxis="center"
                                            crossAxis="center"
                                            onClick={() =>
                                                setSelectedTabIndex(index)
                                            }>
                                            <Conditional exists={tab.icon}>
                                                {icon => (
                                                    <>
                                                        <Icon
                                                            icon={icon}
                                                            color={color}
                                                            relativeSize={emSize(
                                                                1.3,
                                                            )}
                                                        />
                                                        <VerticalDivider />
                                                    </>
                                                )}
                                            </Conditional>
                                            <TextBox
                                                text={tab.title}
                                                align="center"
                                                weight="900"
                                                color={color}
                                                shrink={0}
                                                padding={{ leftRight: 5 }}
                                            />
                                        </FlexColumn>
                                        <VerticalDivider S />
                                        <HorizontalLine
                                            size={3}
                                            color={lineColor}
                                        />
                                    </FlexColumn>
                                );
                            }}
                        </ForEach>
                    )}
                    else={() => (
                        <SelectPicker
                            data={props.tabs.map((tab, index) => ({
                                name: tab.title,
                                value: index,
                            }))}
                            selectedValue={selectedTabIndex}
                            onSelect={({ value }) => setSelectedTabIndex(value)}
                        />
                    )}
                />
            </FlexRow>
            <Conditional
                when={!isDesktop && props.bottomBorderWhenDropdownFallback}
                render={() => <HorizontalLine />}
            />
            <ForEach itemOf={props.tabs} getKey={tab => tab.id}>
                {(tab, index) => {
                    if (index === selectedTabIndex) return tab.content;
                    if (props.options?.keepTabStateWhileSwitching === true) {
                        <div key={tab.id} style={{ display: "none" }}>
                            {tab.content}
                        </div>;
                    }
                    return null;
                }}
            </ForEach>
        </FlexColumn>
    );
};

export { TabView };
