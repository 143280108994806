import * as React from "react";
import { AudioContext } from "./audio-context";

const useAudio = () => {
    const context = React.useContext(AudioContext);
    if (!context)
        throw new Error(`🔈 useAudio must be used within an AudioContext`);

    return [context] as const;
};

export { useAudio };
