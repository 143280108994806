import { ProductOptionalsTextBox } from "@@/products/product-optionals-text-box";
import { ProductTitleTextBox } from "@@/products/product-title-text-box";
import { Circle } from "@@/shared/circle";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { useTheme } from "@emotion/react";
import { faMinus, faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import {
    EmSize,
    OrderItem,
    OrderItemBuyable_V2,
    OrderItemOptional_V2,
    translation,
    unitTypeMap,
} from "@towni/common";

type Props = {
    cartItem: OrderItemBuyable_V2;
    optional: OrderItemOptional_V2[];
    actionsAllowed: boolean;
    decrease?: (orderItem: OrderItem) => void;
    increase?: (orderItem: OrderItem) => void;
};

const BookableExtrasRow = ({
    cartItem,
    optional,
    actionsAllowed,
    decrease,
    increase,
}: Props) => {
    const theme = useTheme();
    const unitTranslator = useUnitTranslator();
    return (
        <FlexRow
            crossAxis="flex-start"
            shrink={0}
            fillParentWidth
            padding={{
                leftRight: 20,
                topBottom: 10,
            }}>
            <FlexRow shrink={0} tag="antal">
                <TextBox
                    size="S"
                    padding={{ top: 1 }}
                    color={theme.colors.black.light20}
                    text={cartItem.quantity.value.toString()}
                />
                <HorizontalDivider S />
            </FlexRow>
            <FlexColumn shrink={1} grow={1}>
                <FlexColumn
                    key={cartItem._id}
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="stretch"
                    grow={1}
                    shrink={0}
                    tag="produkt_price_column">
                    <FlexRow
                        mainAxis="space-between"
                        crossAxis="center"
                        fillParentWidth
                        tag="product_price_container">
                        <FlexRow shrink={1} tag="product_title_container">
                            <ProductTitleTextBox
                                meta={cartItem.meta}
                                weight="700"
                                size="S"
                                lineClamp={1}
                            />
                        </FlexRow>
                        <HorizontalDivider />
                        {/* <PriceTextBox
                            price={cartItem.price}
                            originalPrice={cartItem.originalPrice}
                            size="S"
                            color={theme.colors.black.light20}
                            reversed
                        /> */}
                    </FlexRow>
                    <VerticalDivider XXS />
                    <FlexRow
                        mainAxis="space-between"
                        crossAxis="center"
                        fillParentWidth
                        tag="optionals_and_actions_row">
                        <FlexColumn
                            fillParentWidth
                            tag="optionals_and_quantity_container">
                            <ProductOptionalsTextBox
                                pickedOptionals={optional.map(o => o.optional)}
                                size="XS"
                                color={theme.colors.black.light20}
                            />
                            {cartItem.quantity.amount.unit.type !==
                                unitTypeMap.pcs && (
                                <>
                                    {optional?.length ? (
                                        <VerticalDivider XS />
                                    ) : null}
                                    <TextBox
                                        size="XS"
                                        color={
                                            theme.colors.default.text.light40
                                        }
                                        text={unitTranslator(
                                            cartItem.quantity.amount.value,
                                            cartItem.quantity.amount.unit,
                                        )}
                                    />
                                </>
                            )}
                        </FlexColumn>
                        {actionsAllowed && (
                            <FlexRow mainAxis="flex-end" tag="actions">
                                <Circle
                                    size={29}
                                    contentSize="S"
                                    backgroundColor={theme.colors.transparent}
                                    textColor={theme.colors.black.light60}
                                    onClick={() => {
                                        if (decrease) decrease(cartItem);
                                    }}>
                                    <Icon
                                        icon={
                                            cartItem.quantity.value > 1
                                                ? faMinus
                                                : faTrashAlt
                                        }
                                        relativeSize={
                                            (cartItem.quantity.value > 1
                                                ? 1.2
                                                : 1.1) as EmSize
                                        }
                                    />
                                </Circle>
                                <HorizontalDivider XS />
                                <Circle
                                    size={29}
                                    contentSize="S"
                                    backgroundColor={theme.colors.transparent}
                                    textColor={theme.colors.primary}
                                    onClick={() => {
                                        if (increase) increase(cartItem);
                                    }}>
                                    <Icon
                                        icon={faPlus}
                                        relativeSize={1.2 as EmSize}
                                    />
                                </Circle>
                            </FlexRow>
                        )}
                    </FlexRow>
                    {cartItem.messageToProvider && (
                        <>
                            <VerticalDivider XXS />
                            <FlexRow tag="requirements">
                                <TextBox
                                    text={translation({
                                        sv: "Önskemål:",
                                        en: "Requirements:",
                                    })}
                                    color={theme.colors.default.text.light40}
                                    size="XS"
                                />
                                <HorizontalDivider XS />
                                <TextBox
                                    text={cartItem.messageToProvider}
                                    color={theme.colors.default.text.light40}
                                    size="XS"
                                />
                            </FlexRow>
                        </>
                    )}
                </FlexColumn>
            </FlexColumn>
        </FlexRow>
    );
};

export { BookableExtrasRow };
