import { browserLogger } from "@@/settings/browser-logger";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexAlignment, FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { use100vh } from "@@/shared/use-100-vh";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useTheme } from "@emotion/react";
import {
    AlignmentValue,
    BackgroundStyle,
    HzAlignment,
    PageBlockTitleSize,
    PosterPageBlock,
    RemSize,
    assertNever,
    remSize,
} from "@towni/common";
import { usePageViewContext } from "../../../editor/page-view-context";
import { PosterPageBlockProvider } from "./poster-page-block-view-context";

type Props = {
    block: PosterPageBlock;
};

const alignmentToFlex = (alignment: AlignmentValue): FlexAlignment => {
    switch (alignment) {
        case "center":
            return "center";
        case "left":
        case "top":
            return "flex-start";
        case "bottom":
        case "right":
            return "flex-end";
        case "auto":
            return "flex-start";
        default:
            assertNever(alignment);
    }
};

const hzTextAlignment = (
    hzAlignment: HzAlignment,
): Exclude<HzAlignment, "auto"> => {
    switch (hzAlignment) {
        case "auto":
            return "left";
        case "center":
            return hzAlignment;
        case "left":
            return hzAlignment;
        case "right":
            return hzAlignment;
        default:
            browserLogger.error(`Unknown horizontal alignment: ${hzAlignment}`);
            assertNever(hzAlignment);
    }
};

const subtitleTextSizeRatio = (size: PageBlockTitleSize): number => {
    switch (size) {
        case "XS":
            return 0.83;
        case "S":
            return 0.85;
        case "M":
            return 1;
        case "L":
            return 1.1;
        case "XL":
            return 1.25;
        default:
            assertNever(size);
    }
};

const textSizeRatio = (size: PageBlockTitleSize): number => {
    switch (size) {
        case "XS":
            return 0.75;
        case "S":
            return 0.8;
        case "M":
            return 1;
        case "L":
            return 1.2;
        case "XL":
            return 1.5;
        default:
            assertNever(size);
    }
};
const textSize = (size: PageBlockTitleSize, medium = 2): RemSize => {
    const ratio = textSizeRatio(size);
    return remSize(medium * ratio);
};

const PosterPageBlockView = (props: Props) => {
    const { pageInnerWidth, pageMaxWidth } = usePageViewContext();
    const block = props.block;
    const theme = useTheme();
    const vh100 = use100vh();
    const isMobile = usePageBreakpoint({ when: "📱" });

    const vertically = alignmentToFlex(block.alignment.vertical);
    const horizontally = alignmentToFlex(block.alignment.horizontal);
    const hzAlign = hzTextAlignment(block.alignment.horizontal);

    const [imageReference] = useStorageItemImageReference(block.posterImage);

    const titleSize = textSize(block.titleSize, isMobile ? 1.5 : 2) * 1.5; // 1.5 since poster sizes are bigger
    const subtitleSize = subtitleTextSizeRatio(block.titleSize) * 1.125 * 1.5; // 1.5 since poster sizes are bigger

    const title = (
        <TextBox
            text={block.title}
            color={block.textColor ?? theme.colors.black.asString}
            size={titleSize}
            weight="700"
            align={hzAlign}
            css={{
                textShadow: block.textShadow
                    ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                    : undefined,
            }}
        />
    );

    const subtitle = (
        <TextBox
            text={block.subtitle}
            color={block.textColor ?? theme.colors.black.asString}
            size={subtitleSize}
            align={hzAlign}
            css={{
                opacity: 0.8,
                lineHeight: 1.3,
                textShadow: block.textShadow
                    ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                    : undefined,
            }}
        />
    );

    const background: BackgroundStyle = imageReference
        ? {
              imageReference,
              fit: "COVER",
              imageSize: {
                  imageWidth: isMobile
                      ? breakpointRange.MOBILE.max
                      : pageMaxWidth,
              },
          }
        : {
              color: block.backgroundColor ?? theme.colors.default.background,
          };

    return (
        <PosterPageBlockProvider block={block}>
            <FlexRow
                fillParentWidth
                mainAxis={"center"}
                crossAxis={vertically}
                padding={{ top: 35, leftRight: 12, bottom: 12 }}
                height={vh100 * (props.block.height ?? 0.45)}
                // maxHeight={600}
                tag="poster_block"
                css={{
                    flexShrink: 0,
                    overflow: "hidden",
                }}
                background={background}>
                <FlexRow
                    fillParent
                    maxWidth={pageInnerWidth}
                    mainAxis={horizontally ?? "flex-start"}
                    padding={{ all: 20 }}
                    crossAxis={vertically}>
                    <FlexColumn fillParentWidth>
                        {block.subtitlePlacement === "above" ? subtitle : title}
                        <VerticalDivider size="3XS" />
                        {block.subtitlePlacement === "below" ? subtitle : title}
                    </FlexColumn>
                </FlexRow>
            </FlexRow>
        </PosterPageBlockProvider>
    );
};

export { PosterPageBlockView };
