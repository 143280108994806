import { setWithinRange } from "@towni/common";
import { Tab } from "./tab";

const findFirstDefaultTabIndex = (tabs: Tab[]) => {
    return setWithinRange(
        tabs.findIndex(tab => tab.default),
        { min: 0 }
    );
};

export { findFirstDefaultTabIndex };
