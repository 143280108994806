import { BookableCart } from "@@/carts/bookable-checkout/bookable-cart";
import { useOptionalGroups } from "@@/optional-groups/state/optional-group-fetchers";
import { Wizard, wizardPage, WizardPages } from "@@/pages/wizard";
import {
    BookableSessionId,
    OrderGroupId,
    OrderId,
    Product,
    Provider,
} from "@towni/common";
import { useBookingContext } from "../booking-context";
import { ResourcePickerPage } from "./bookable-resource-map-picker/resource-picker-page";
import { BookableTimePicker } from "./bookable-time-picker/bookable-time-picker";
import { ExtrasAndOptionalsPage } from "./extras-and-optionals.tsx/extras-and-optionals-page-v1";

type Buyer = "CUSTOMER" | "ON_PREMISE";

type Props = {
    readonly product?: Product;
    readonly provider: Provider;
    readonly onCheckoutComplete?: (params: {
        bookedSessionId?: BookableSessionId;
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void;
    readonly wizardElement: typeof WizardPages | typeof Wizard;
};

const BookingWizardPages = (props: Props) => {
    const { product, provider } = props;
    const [optionalGroups] = useOptionalGroups(product?.optionalGroupIds);

    const resourcePickerEnabled = useBookingContext(
        state => state.resourcePickerEnabled
    );
    const hasAvailableExtrasOrOptionals = useBookingContext(
        state => state.hasAvailableExtrasOrOptionals
    );

    if (!product) return null;
    const WizardElement = props.wizardElement || Wizard;
    return (
        <WizardElement ignoreAnimation={false}>
            {[
                wizardPage(
                    "time-picker",
                    <BookableTimePicker provider={provider} product={product} />
                ),
                ...(resourcePickerEnabled
                    ? [
                          wizardPage(
                              "map-resource-picker",
                              <ResourcePickerPage />
                          ),
                      ]
                    : []),
                ...(hasAvailableExtrasOrOptionals
                    ? [
                          wizardPage(
                              "extras-and-optionals",
                              <ExtrasAndOptionalsPage
                                  optionals={optionalGroups}
                                  provider={provider}
                              />
                          ),
                      ]
                    : []),
                wizardPage(
                    "bookable-cart",
                    <BookableCart
                        onCheckoutComplete={props.onCheckoutComplete}
                    />
                ),
            ]}
        </WizardElement>
    );
};

export { BookingWizardPages };
export type { Props as BookingWizardProps, Buyer };
