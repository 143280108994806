import { FlexRow } from "@@/shared/flex-containers";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { ColorItem, Price, translation } from "@towni/common";

type Props = {
    price: Price;
    color?: ColorItem;
};
const VatAmountRow = ({ price, ...props }: Props) => {
    return (
        <FlexRow mainAxis="space-between" shrink={0}>
            <TextBox
                text={translation({
                    sv: "Varav moms",
                    en: "Of which VAT",
                })}
                weight="400"
                color={props.color}
            />
            <PriceTextBox
                price={price}
                valueToFormat="VAT_AMOUNT"
                weight="400"
                color={props.color}
            />
        </FlexRow>
    );
};

export { VatAmountRow };
