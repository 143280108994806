import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { PageLoad } from "@@/pages/page-load";
import { browserLogger } from "@@/settings";
import { translation } from "@towni/common";
import { Component, ErrorInfo, ReactNode } from "react";

type Props = {
    children: ReactNode;
};

type State = {
    hasError: boolean;
    error?: Error;
};
class LoaderErrorBoundary extends Component<Props, State> {
    public state: State = { hasError: false, error: undefined };

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        browserLogger.warn(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            switch (this.state.error?.name) {
                case "ProviderIsLoadingError":
                case "ProductIsLoadingError":
                    return <PageLoad />;
                case "ProviderNotFoundError":
                    return (
                        <PageNotFound
                            reason={translation({
                                sv: "Oops, ingenting att se här",
                                en: "Oops, nothing to see here",
                            })}
                        />
                    );
                case "ProductNotFoundError":
                    return (
                        <PageNotFound
                            reason={translation({
                                sv: "Produkt ej funnen",
                                en: "Product not found",
                            })}
                        />
                    );
                case "PageNotFoundError":
                    return <PageNotFound />;
                default:
                    throw this.state.error;
            }
        }

        return this.props.children;
    }
}

export { LoaderErrorBoundary };
