import { SizeName, sizeNames } from "@towni/common";
import * as React from "react";
import { calculateDividerSize, getDividerSizeInPixels } from "./divider-size";

type VerticalProps = { [key in SizeName]?: boolean } & {
    readonly size?: number | SizeName;
    readonly shrink?: React.CSSProperties["flexShrink"];
    readonly className?: string;
};

const VerticalDividerComponent = (props: VerticalProps) => {
    const { size: sizeInput, ...rest } = props;
    const size =
        typeof sizeInput === "number"
            ? `${sizeInput}rem`
            : sizeInput && sizeNames.includes(sizeInput)
            ? `${getDividerSizeInPixels(sizeInput)}px`
            : `${calculateDividerSize(rest, 7)}px`;

    return (
        <div
            css={{
                width: 1,
                maxWidth: 1,
                minWidth: 1,
                flexShrink: props.shrink ?? 1,
                height: size,
                maxHeight: size,
                minHeight: size,
            }}
            className={props.className}
        />
    );
};

export { VerticalDividerComponent as VerticalDivider };
