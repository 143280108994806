import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { KronocampingDiscoverMoreSection } from "../kronocamping/sections/kronocamping-discover-more-section";
import { LastMileCarSectionAlt } from "./sections/last-mile-car-section-alt";
import { OneSquareLinkSectionElCar } from "./sections/one-square-link-section-elcar";
import { OneSquareLinkSectionGotene } from "./sections/one-square-link-section-gotene";
import { OneSquareLinkSectionGrastorp } from "./sections/one-square-link-section-grastorp";
import { OneSquareLinkSectionVara } from "./sections/one-square-link-section-grastorp-vara";
import { OneSquareLinkSectionSkara } from "./sections/one-square-link-section-skara";
import { OneSquareSection } from "./sections/one-square-section";
import { SmartLateSummerSection } from "./sections/smart-late-summer-section";

type Props = {
    title?: string;
};

const SmartStoreFrontSummer2022 = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>{props.title ?? "SMaRT Resande"}</title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory("smart-store-front")}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/destination-smart-resande-poster.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <TextBox
                        text="Ut på elcykel-äventyr!"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Upptäck med el i benen"
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider XL />
                    <TextBox
                        text="Med fokus på upplevelser"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Elcykel är ett underbart sätt att utforska sin omgiving. Därför har vi satt ihop förslag på rundor runt om i Götene, Skara, Lidköping, Vara och Grästorp. Självklart kan du skippa rundan och ta din egna väg, låna till ett transportkärra för det längre äventyret eller barntransport till familjen. "
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider XXL />
                    <SmartLateSummerSection />
                    <VerticalDivider XL />
                    <TextBox
                        text="Utvalda upplevelser"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Nedan ser du exempel på upplevelser vi satt samman. Och även om du inte hyr kan alltid uppleva dem med egen cykel om du laddar ner appen Läckö Kinnekulle"
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Långa landsbygds-turen
                        productId={"product__z5OERGnTXSqytv7NNd1M" as ProductId}
                        actionText={translation({
                            sv: "Upptäck",
                            en: "Explore",
                        })}
                    />
                    <OneSquareSection
                        // Skara
                        productId={"product__TJyASLfLfTLAY6MZ9xta" as ProductId}
                        actionText={translation({
                            sv: "Utforska",
                            en: "Explore",
                        })}
                    />
                    <OneSquareSection
                        // Trötta ut barnen-turen
                        productId={"product__zIHz-FXwrwzXlAIZgyqL" as ProductId}
                        actionText={translation({
                            sv: "För barnen",
                            en: "For the kids",
                        })}
                    />
                    <OneSquareSection
                        // Historiska kålland-turen
                        productId={"product__4VP_F_HE24SIodGeOG3q" as ProductId}
                        actionText={translation({
                            sv: "Upptäck",
                            en: "Explore",
                        })}
                    />
                    <OneSquareSection
                        // Getter och fiskar-turen
                        productId={"product__qMODJ8azFcW6-wCS3Gos" as ProductId}
                        actionText={translation({
                            sv: "För barnen",
                            en: "For the kids",
                        })}
                    />
                    <VerticalDivider XXL />
                    <TextBox
                        text="Cyklar och turer över bygden"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Med elcykel kan du upptäcka mer av landskapet och besöksmålen i närheten. Utforska nya delar av kommunen varje vecka."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareLinkSectionGotene />
                    <OneSquareLinkSectionSkara />
                    <OneSquareLinkSectionGrastorp />
                    <OneSquareLinkSectionVara />
                    <VerticalDivider XL />
                    <TextBox
                        text="Fler hållbara resesätt"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Vill du kombinera din cykling eller passade ikanske inte ovan in i resplanen? Här nedan kan du hitta vidare till fler altiernativ"
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareLinkSectionElCar
                        title={translation({
                            sv: "Elbilar i bygden",
                            en: "Electric bikes in the neighbourhood",
                        })}
                        subtitle={translation({
                            sv: "Känn friheten i att njuta av landskapet med el i benen.",
                            en: "Enjoy the freedom of nature with electric bikes.",
                        })}
                        kicker={translation({
                            sv: "ELCYKEL • AKTIVITET",
                            en: "ELECTRIC BIKE • ACTIVITY",
                        })}
                        actionTitle={translation({
                            sv: "Se mer",
                            en: "See more",
                        })}
                    />
                    <VerticalDivider XXL />
                    <LastMileCarSectionAlt
                        productId={"product__IG8ZXjoF9TW197TdJKI8" as ProductId}
                        title="Sista-milen-bilen"
                        subtitle="Res för 95 kr till ditt favorit mål! Det är sant!"
                        kicker={translation({
                            sv: "TRANSPORT • CHAUFFÖR",
                            en: "TRANSPORT • DRIVER",
                        })}
                        actionText={translation({
                            sv: "Boka",
                            en: "Book",
                        })}
                    />
                    <VerticalDivider XXL />
                    <FlexColumn crossAxis="center">
                        <TextBox
                            text={translation({
                                sv: "Upplev mer lokalt",
                                en: "Experience more local",
                            })}
                            size={2}
                            weight="700"
                            align="center"
                        />
                        <VerticalDivider M />
                        <TextBox
                            text={translation({
                                sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                            })}
                            color={theme.colors.black.light50}
                            size={1.125}
                            weight="400"
                            align="center"
                            css={{
                                lineHeight: "1.6rem",
                            }}
                        />
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <KronocampingDiscoverMoreSection />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                {/* <<<< Content */}
            </AppPage>
        </WideScreenWrapper>
    );
};

export { SmartStoreFrontSummer2022 };
