import { SmallGrip } from "@@/pages/grips";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Avatar } from "@@/shared/pictures/avatar";
import { TextBox } from "@@/shared/text";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Product, ProductId, RemSize } from "@towni/common";

type Props = {
    product: Product;
    toggle: (productId: ProductId) => void;
    dragListeners?: DraggableSyntheticListeners;
};

const ProductPickedRow = (props: Props) => {
    const theme = useTheme();
    const { product, toggle } = props;
    const inactive = product.status.type !== "ACTIVE";

    return (
        <FlexColumn
            fillParentWidth
            mainAxis="center"
            crossAxis="flex-start"
            padding={{ topBottom: 5 }}>
            <FlexRow
                fillParentWidth
                radius={10}
                background={{
                    color: inactive
                        ? theme.colors.disabled.background
                        : theme.colors.default.background,
                }}
                css={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: theme.colors.default.border.asString,
                }}
                padding={{ all: 15 }}
                mainAxis="space-between"
                crossAxis="center">
                <Conditional
                    when={!!props.dragListeners}
                    render={() => (
                        <div {...props.dragListeners}>
                            <SmallGrip
                                size={0.8 as RemSize}
                                color={theme.colors.black.light60}
                                padding={{ left: 0, right: 15 }}
                                dragListeners={props.dragListeners}
                            />
                            {/* <Icon
                                icon={faGripVertical}
                                cursor="pointer"
                                color={theme.colors.black.light60}
                                padding={{ right: 13 }}
                                onClick={() => toggle(product._id)}
                            /> */}
                        </div>
                    )}
                />
                <FlexRow grow={1} shrink={1}>
                    <Avatar
                        title={product.meta.title}
                        image={product.meta.images[0]}
                        size={2.5 as RemSize}
                        backgroundColor={theme.colors.primary.main.value.withAlpha(
                            0.6
                        )}
                        borderColor={theme.colors.primary.main.value.withAlpha(
                            0.6
                        )}
                    />
                    <HorizontalDivider S />
                    <TextBox
                        text={product.meta.title}
                        color={
                            inactive
                                ? theme.colors.disabled.text
                                : theme.colors.default.text
                        }
                        lineClamp={2}
                        shrink={1}
                        grow={1}
                        css={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                    />
                    <HorizontalDivider />
                </FlexRow>
                <Icon
                    icon={faTimes}
                    cursor="pointer"
                    color={theme.colors.black.light60}
                    padding={{ all: 10, right: 5 }}
                    onClick={() => toggle(product._id)}
                />
            </FlexRow>
        </FlexColumn>
    );
};

export { ProductPickedRow };
