import { useLanguageContext } from "@@/settings/language-context";
import { Locale } from "date-fns";
import {
    de as deLocale,
    enGB as enLocale,
    sv as svLocale,
} from "date-fns/locale";

const useLocale = (): Locale => {
    const { language } = useLanguageContext();
    switch (language) {
        case "de":
            return deLocale;
        case "en":
            return enLocale;
        case "sv":
        default:
            return svLocale;
    }
};

export { useLocale };
