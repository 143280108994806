import { useModal } from "@@/modals/use-modal";
import { PageLoad } from "@@/pages/page-load";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { CheckoutModal } from "@@/payments/checkout-modal";
import { PaymentDetailsContextProvider } from "@@/payments/v2/payment-details.context";
import { AddMessageRow } from "@@/products/add-message-row";
import { useBookingContext } from "@@/products/bookables/booking-context";
import { ContentBlockView } from "@@/products/bookables/content-block-view";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { AddressLink } from "@@/section-groups/address-link";
import { Card } from "@@/sections/content/items/card/card";
import { browserLogger } from "@@/settings";
import { Conditional } from "@@/shared/conditional";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Label } from "@@/shared/label";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import {
    BookableSessionId,
    OrderGroupId,
    OrderId,
    Provider,
    Translatable,
    isBookable,
    isManifestBookableOptionValue,
    isOrderItemBookable_V2,
    translation,
} from "@towni/common";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutButton } from "../checkout-button";
import { useCheckoutContext } from "../checkout.context";
import { DiscountInCartRow } from "../discount-codes/discount-in-cart-row";
import { GiftCardInCartRow } from "../giftcard/giftcard-in-cart-row";
import { useCartContext } from "../multi-carts/cart.context";
import { useVerifiedCartState } from "../use-verified-cart";
import { BookableExtrasPanel } from "./bookable-extras-panel";
import { BookableManifest } from "./bookable-manifest";
import { BookablePanel } from "./bookable-panel";
import { CartRowsAndTotal } from "./cart-rows-and-total";
import { InputContactInfo } from "./input-contact-info";

type Props = {
    onCheckoutComplete?: (params: {
        bookedSessionId?: BookableSessionId;
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void;
};

const BookableCart = (_props: Props) => {
    const theme = useTheme();
    const { cart, setCart, clearCart, setMessageToProvider } = useCartContext(
        context => ({
            cart: context.cart,
            setCart: context.setCart,
            clearCart: context.clearCart,
            setMessageToProvider: context.setMessageToProvider,
        }),
    );

    const contentBlocks = useBookingContext(
        state => state.bookable.contentBlocks,
    );
    const clearSession = useBookingContext(state => state.clearSession);
    const session = useBookingContext(state => state.session);
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const wizardActions = useWizardDispatch();
    useVerifiedCartState(cart, validatedCart => {
        if (validatedCart) setCart(validatedCart);
        else clearCart();
    });

    React.useEffect(() => {
        if (!cart.orderItems.some(oi => oi.acquireType === "BOOKABLE")) {
            browserLogger.info("Bookable missing bookable");
            clearSession();
            wizardActions.goBack();
        }
    }, [cart, clearSession, wizardActions]);

    const { onPremise, contactInfo } = useCheckoutContext(context => ({
        onPremise: context.onPremise,
        contactInfo: context.contactInfo,
    }));
    const [{ show: showCheckout, hide: hideCheckout }, checkoutModalId] =
        useModal("checkout_modal__");

    const showCheckoutModal = () => {
        showCheckout();
    };
    const hideCheckoutModal = () => {
        hideCheckout();
    };

    const [cartProvider, cartProviderQuery] = useProvider(cart.providerId);
    const messageToProvider = cart.messageToProvider || null;

    const [orderCanNotBePlaced, setOrderCanNotBePlaced] = React.useState<
        Translatable | false
    >(
        translation({
            sv: "Fyll i alla obligatoriska fält",
            en: "Please fill in all required fields",
            de: "Bitte füllen Sie alle Pflichtfelder aus",
        }),
    );

    React.useEffect(() => {
        if (onPremise && !contactInfo) {
            setOrderCanNotBePlaced(
                translation({
                    sv: "Kontaktuppgifter saknas",
                    en: "Contact information is missing",
                }),
            );
            return;
        }

        // Checks if all resources have been fully done
        const incompleteOrderItem = cart.orderItems.find(oi => {
            if (!isBookable(oi)) return false;
            return cart.reservationRequests.some(r =>
                r.optionValues.some(
                    f =>
                        isManifestBookableOptionValue(f) &&
                        f.manifest.find(r => r.data.NAME?.trim() === ""),
                ),
            );
        });
        if (incompleteOrderItem) {
            setOrderCanNotBePlaced(
                translation({
                    sv: "Manifest saknar uppgifter",
                    en: "Manifest is missing information",
                }),
            );
            return;
        }

        setOrderCanNotBePlaced(false);
    }, [cart.orderItems, cart.reservationRequests, onPremise, contactInfo]);

    if (cartProviderQuery.isPending || !cartProvider) return <PageLoad />;

    return (
        <>
            <FlexColumn
                fillParent
                background={{
                    color: theme.colors.default.background.asString,
                }}
                css={{
                    backgroundColor: theme.colors.default.background.asString,
                    flexShrink: 0,
                    label: "bookable_cart",
                }}
                crossAxis="stretch"
                mainAxis="flex-start">
                <FlexColumn
                    crossAxis="stretch"
                    mainAxis="flex-start"
                    overflowY="scroll"
                    grow={1}>
                    {cart.orderItems.map(orderItem => {
                        if (isOrderItemBookable_V2(orderItem) && cartProvider)
                            return (
                                <BookablePanel
                                    key={orderItem._id}
                                    orderItem={orderItem}
                                    resourceReservations={
                                        cart.reservationRequests
                                    }
                                />
                            );
                        return null;
                    })}
                    <BookableExtrasPanel />
                    <HorizontalSectionDivider key={"bc2_start"} />
                    <ForEach
                        itemOf={contentBlocks}
                        getKey={block => block._id}
                        divider={<HorizontalLine />}>
                        {(block, index) => {
                            return (
                                <FlexColumn
                                    key={block._id}
                                    fillParentWidth
                                    padding={{ leftRight: 20 }}>
                                    <VerticalDivider M />
                                    <ContentBlockView
                                        key={block._id}
                                        contentBlock={block}
                                        index={index}
                                    />
                                    <VerticalDivider M />
                                </FlexColumn>
                            );
                        }}
                    </ForEach>
                    <Conditional
                        when={!!contentBlocks.length}
                        render={() => (
                            <HorizontalSectionDivider key={"bc2_start"} />
                        )}
                    />
                    <CartRowsAndTotal />
                    <VerticalDivider M />
                    {cartProvider ? (
                        <FlexRow>
                            <Card
                                width={80}
                                height={80}
                                background={{
                                    imageReference: cartProvider.meta.images[0],
                                    imageSize: {
                                        imageWidth:
                                            theme.sizes.imageSizes.closest(80),
                                    },
                                    color: theme.colors.black.light95,
                                    fit: "COVER",
                                }}
                            />
                            <FlexColumn
                                fillParentWidth
                                crossAxis="flex-start"
                                shrink={0}
                                padding={{ left: 20 }}>
                                <TextBox
                                    text={cartProvider.meta.title}
                                    size="L"
                                    weight="900"
                                />
                                <VerticalDivider S />
                                <AddressLink
                                    address={cartProvider.address}
                                    size="S"
                                />
                            </FlexColumn>
                        </FlexRow>
                    ) : null}
                    <VerticalDivider M />
                    <HorizontalSectionDivider />

                    {cart.orderItems.map(oi => {
                        if (isOrderItemBookable_V2(oi))
                            return (
                                <BookableManifest
                                    key={oi._id}
                                    reservations={cart.reservationRequests}
                                />
                            );
                        return null;
                    })}
                    <Conditional
                        when={onPremise}
                        render={() => (
                            <>
                                <InputContactInfo />
                                <HorizontalSectionDivider />
                            </>
                        )}
                    />
                    <VerticalDivider size={1.5} />
                    <FlexRow padding={{ leftRight: 20 }} crossAxis="center">
                        <TextBox
                            size="L"
                            weight="800"
                            text={translation({
                                sv: "Särskilda önskemål",
                                en: "Special requirements",
                            })}
                            grow={1}
                        />
                        <Label text={"VALFRI"} colorTheme="" />
                    </FlexRow>
                    <VerticalDivider XS />
                    <AddMessageRow
                        initialMessage={messageToProvider || ""}
                        title={translation({
                            sv: "Ange eventuella önskemål",
                            en: "Enter your special requirements",
                        })}
                        onSetMessage={setMessageToProvider}
                        padding={{ leftRight: 20, topBottom: 15 }}
                        size="S"
                    />
                    <VerticalDivider M />

                    <HorizontalLine />
                    <GiftCardInCartRow />
                    <HorizontalLine />
                    <DiscountInCartRow />
                    <HorizontalSectionDivider />
                    <VerticalDivider M />
                    <ProviderBookingText provider={cartProvider} />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
            </FlexColumn>
            <CheckoutButton
                disabled={orderCanNotBePlaced !== false}
                onClick={showCheckoutModal}
                text={
                    orderCanNotBePlaced !== false
                        ? orderCanNotBePlaced
                        : undefined
                }
            />

            <PaymentDetailsContextProvider
                acceptSwish={
                    cartProvider
                        ? cartProvider.options.acceptSwish ?? false
                        : false
                }>
                <CheckoutModal
                    modalId={checkoutModalId}
                    showDeliveryModal={() => false}
                    onFinished={(orderGroupId, orderId) => {
                        const sessionId = session?._id;
                        queryClient.invalidateQueries({ queryKey: ["orders"] });
                        hideCheckoutModal();
                        clearCart();
                        if (_props.onCheckoutComplete)
                            _props.onCheckoutComplete({
                                bookedSessionId: sessionId,
                                orderGroupId,
                                orderId,
                            });

                        if (!onPremise)
                            navigate(
                                `/receipts/${encodeURIComponent(
                                    orderGroupId,
                                )}?waitforit=true`,
                            );
                    }}
                    delivery={false}
                    forceFullHeightMode={true}
                />
            </PaymentDetailsContextProvider>
        </>
    );
};

const ProviderBookingText = ({
    provider,
}: {
    provider: Provider | undefined;
}) => {
    const theme = useTheme();
    const translate = useTranslate();
    if (provider) {
        const providerTitle = translate(provider.meta.title);
        return (
            <TextBox
                padding={{ leftRight: 20 }}
                text={translation({
                    sv: `Genom att gå vidare godkänner du ${providerTitle} och Townis avtal och de regler som är förbundna med ovan objekt. För matallergier eller speciella behov, kontakta vänligen oss innan.`,
                    en: `By proceeding you accept Towni's agreement and the rules associated with the above objects. For food allergies or special dietary needs, please contact the retailer before ordering.`,
                })}
                size={0.8}
                color={theme.colors.default.text.light60}
            />
        );
    }
    return (
        <TextBox
            padding={{ leftRight: 20 }}
            text={translation({
                sv: `Genom att gå vidare godkänner du Townis avtal och de regler som är förbundna med ovan objekt. För matallergier eller speciella behov, kontakta vänligen oss innan.`,
                en: `By proceeding you accept Towni's agreement and the rules associated with the above objects. For food allergies or special dietary needs, please contact the retailer before ordering.`,
            })}
            size={0.8}
            color={theme.colors.default.text.light60}
        />
    );
};

export { BookableCart };
