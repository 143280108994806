import {
    darkeningGradient,
    darkeningGradientReverse,
} from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTheme } from "@emotion/react";
import {
    ColorSet,
    RemSize,
    Translatable,
    generateComboId,
    translation,
} from "@towni/common";
import { useState } from "react";
import { Link } from "react-router-dom";

type Props = {
    title: Translatable;
    subtitle: Translatable;
    kicker?: Translatable;
    actionText?: Translatable;
    colorSet?: ColorSet;
    className?: string;
    titleTopPlacement?: boolean;
    relativeUrl: string;
    imageUrl?: string;
};
/**
 * Note! It's not always square since it's now responsive for widescreen
 */

const OneSquareLink = (props: Props) => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const [renderId] = useState(generateComboId);

    const callToAction = (
        <FlexRow fillParentWidth mainAxis="flex-end">
            <StoreFrontCardPill
                text={
                    props.actionText ??
                    translation({
                        sv: "Visa",
                        en: "Show",
                    })
                }
                colorSet={props.colorSet}
            />
        </FlexRow>
    );

    const title = (
        <FlexColumn fillParentWidth shrink={0}>
            <TextBox
                text={props.title}
                weight="900"
                size={2 as RemSize}
                color={theme.colors.white}
                css={{
                    textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                }}
            />
            <VerticalDivider />
            <TextBox
                text={props.subtitle}
                weight="500"
                size={1 as RemSize}
                color={theme.colors.white}
                css={{
                    textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                }}
            />
            <Conditional
                when={!!props.kicker}
                render={() => (
                    <>
                        <VerticalDivider S />
                        <TextBox
                            text={props.kicker}
                            weight="800"
                            case="UPPERCASE"
                            size={0.75 as RemSize}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                            }}
                        />
                    </>
                )}
            />
        </FlexColumn>
    );

    return (
        <SectionArea
            sectionAreaId={
                "one-square-link_" + renderId + "_" + props.relativeUrl
            }
            className={props.className}
            padding={{ top: 10 }}>
            <Link to={`${props.relativeUrl}`}>
                <FlexColumn
                    fillParentWidth
                    padding={{ leftRight: 20 }}
                    crossAxis="flex-start"
                    shrink={0}>
                    <SectionAreaCard
                        aspectRatio={isDesktop ? "3:2" : "1:1"}
                        radius={16}
                        background={{
                            fit: "COVER",
                            imageUrl: props.imageUrl,
                            imageSize: {
                                imageWidth: 500,
                            },
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ all: 20 }}
                            background={{
                                linearGradient: props.titleTopPlacement
                                    ? darkeningGradientReverse
                                    : darkeningGradient,
                            }}
                            mainAxis="space-between"
                            crossAxis="center">
                            <Conditional
                                when={props.titleTopPlacement}
                                render={() => (
                                    <>
                                        {title}
                                        {callToAction}
                                    </>
                                )}
                                else={() => (
                                    <>
                                        {callToAction}
                                        {title}
                                    </>
                                )}
                            />
                        </FlexColumn>
                    </SectionAreaCard>
                </FlexColumn>
            </Link>
        </SectionArea>
    );
};

export { OneSquareLink };
