import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../shared/store-front-hz-product-card";

const actionTexts = [
    translation({
        sv: "Utforska",
        en: "Explore",
    }),
    translation({
        sv: "Utforska",
        en: "Explore",
    }),
    translation({
        sv: "Hyr nu",
        en: "Rent now",
    }),
    translation({
        sv: "Läs mer",
        en: "Read more",
    }),
];

const SmartHzSectionVaraBjertorp = () => {
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            //getProductId("product__9uOJZqbG-T3QMSOE0zCF" as ProductId), // Natur- kulturrundan, vara
            getProductId("product__u88tQd-Z9b9VnLkY6ULZ" as ProductId), // Hyr elcykel vara
            getProductId("product__kNzVm9HOCd8YA_Ez83ly" as ProductId), // Cykelbarnvagn vara
            getProductId("product__F_XoSkKrSmJijTbt4ZYS" as ProductId), // Transportvagn vara
        ],
        []
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea sectionAreaId="vara-bjertorp-hz" padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : id}
                            cardSize={1.4 as RemSize}
                            aspectRatio="1:1"
                            actionText={actionTexts[index]}
                            productId={id}
                            hideKicker
                        />
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { SmartHzSectionVaraBjertorp };
