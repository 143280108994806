import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const _BackButton = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <ButtonWhite
            onClick={goBack}
            css={{
                borderRadius: 3000,
                minHeight: 40,
            }}>
            <FlexRow css={{ backgroundColor: "#ffffff" }}>
                <Icon icon={faChevronLeft} />
            </FlexRow>
        </ButtonWhite>
    );
};

export { _BackButton };
