import { useBookingContext } from "@@/products/bookables/booking-context";

import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { useSizeTracker } from "@@/shared/use-size-tracker";
import { WidthAndHeight } from "@@/shared/width-and-height";
import {
    BookableSession,
    Price,
    TimeRange,
    TimeRangeResourceAvailability,
} from "@towni/common";
import * as React from "react";
import { BookableSessionListDayHeader } from "./bookable-session-select-day-header";
import { BookableSessionSelectListRow } from "./bookable-session-select-list-row";

const BookableSessionSelectListSectionPure = React.memo((props: Props) => {
    return <BookableSessionSelectListSection {...props} />;
});
BookableSessionSelectListSectionPure.displayName =
    "BookableSessionSelectListSectionPure";

type Props = {
    sessions: BookableSession[];
    onSizeChange?: (size: WidthAndHeight) => void;
    readonly checkAvailability: (
        timeRange: TimeRange,
    ) => TimeRangeResourceAvailability;
    isLoading: boolean;
    currentPrice: (session: BookableSession) => Price;
};

const BookableSessionSelectListSection = (props: Props) => {
    const state = useBookingContext(state => ({
        optionValues: state.optionValues,
        session: state.session,
        setSessionAndTimeRange: state.setSessionAndTimeRange,
    }));

    const dispatchSetSession = React.useCallback(
        (timeRange: TimeRange, session: BookableSession) => {
            state.setSessionAndTimeRange(timeRange, session);
        },
        [state.optionValues],
    );

    const [elementRef] = useSizeTracker(props.onSizeChange);
    return (
        <FlexColumn
            ref={elementRef}
            padding={{
                top: 20,
                leftRight: 20,
            }}
            key={"jippie"}>
            <BookableSessionListDayHeader day={props.sessions[0].start} />
            <VerticalDivider M />
            <ForEach
                itemOf={props.sessions ?? []}
                getKey={session => session._id}>
                {(session: BookableSession) => {
                    const selected = state.session?._id === session._id;
                    return (
                        <BookableSessionSelectListRow
                            key={session._id}
                            selected={selected}
                            session={session}
                            onTimeRangeChange={dispatchSetSession}
                            checkAvailability={props.checkAvailability}
                            isLoading={props.isLoading}
                            currentPrice={props.currentPrice}
                        />
                    );
                }}
            </ForEach>
        </FlexColumn>
    );
};

export { BookableSessionSelectListSectionPure as BookableSessionSelectListSection };
