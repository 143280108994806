import {
    Cart,
    ExtrasGroup,
    assertNever,
    calculateMinMaxRange,
    isOrderItemAccommodation_V2,
    isOrderItemBookableQuantity_V2,
    sum,
} from "@towni/common";

const handleFreeAmount = () => {
    return { min: undefined, max: undefined };
};

const handleBookableOptionAmount = (cart: Cart, extra: ExtrasGroup) => {
    const settings = extra.settings;
    if (settings._type !== "BOOKABLE_OPTION_AMOUNT") {
        throw new Error("NOT BOOKABLE_OPTION_AMOUNT");
    }
    // const _items = orderItemsForExtra.filter(
    //     oi => isProductOrderItem(oi) && extra.products.map(p => p.productId).includes(oi.productId)
    // );

    const quantityItems = cart.orderItems.filter(
        oi =>
            isOrderItemBookableQuantity_V2(oi) &&
            oi.optionId &&
            settings.bookableOptions.includes(oi.optionId),
    );

    const accommodationItems = cart.orderItems.filter(
        isOrderItemAccommodation_V2,
    );

    const accommodationAmount = accommodationItems.map(oi => {
        const quantityItems = oi.quantities.filter(qI =>
            settings.bookableOptions.includes(qI.type.type),
        );
        return sum(quantityItems, oi => oi.quantity.value);
    });

    const amountToBePicked =
        sum(accommodationAmount) + sum(quantityItems, oi => oi.quantity.value);

    return calculateMinMaxRange(extra, amountToBePicked);
};

const getMinMaxForExtrasGroup = (cart: Cart, extra: ExtrasGroup) => {
    const settings = extra.settings;

    switch (settings._type) {
        case "FREE_AMOUNT":
            return handleFreeAmount();

        case "BOOKABLE_OPTION_AMOUNT":
            return handleBookableOptionAmount(cart, extra);
        case "MIN_MAX_AMOUNT":
            return settings.minMax;
        default:
            assertNever(settings);
    }
};
export { getMinMaxForExtrasGroup };
