import {
    HexColor,
    Page,
    PageBlock,
    PageBlockId,
    PageState,
} from "@towni/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const usePageBlocks = (page: Page | undefined) => {
    const blocks = page?.blocks ?? {};
    const blockSortOrder = page?.blockSortOrder ?? [];
    const blocksSorted: PageBlock[] = useMemo(() => {
        if (!blockSortOrder || !blocks) return [];
        return blockSortOrder
            .map(id => blocks[id])
            .filter(Boolean) as PageBlock[];
    }, [blockSortOrder, blocks]);
    if (!page) return [];
    return blocksSorted;
};

const usePageSettingsFromFormContext = (): {
    backgroundColor: HexColor | undefined;
    pageState: PageState;
} => {
    const backgroundColor: HexColor | undefined = useWatch({
        name: "backgroundColor",
    });
    const pageState: PageState = useWatch({ name: "state" });

    return {
        backgroundColor,
        pageState,
    };
};

const usePageBlocksFromFormContext = () => {
    const { setValue } = useFormContext();

    const blocks: Record<PageBlockId, PageBlock> = useWatch({
        name: "blocks",
    });

    const globalBlockSortOrder: PageBlockId[] =
        useWatch({
            name: "blockSortOrder",
        }) ?? Object.keys(blocks);

    const [localBlockSortOrder, setLocalBlockSortOrder] =
        useState(globalBlockSortOrder);

    useEffect(() => {
        if (localBlockSortOrder.length !== globalBlockSortOrder.length) {
            setLocalBlockSortOrder(globalBlockSortOrder);
            return;
        }
        if (
            localBlockSortOrder.some(
                (id, index) => globalBlockSortOrder[index] !== id
            )
        ) {
            setLocalBlockSortOrder(globalBlockSortOrder);
            return;
        }
    }, [globalBlockSortOrder]);

    const blocksSorted = useMemo(() => {
        return localBlockSortOrder.map(id => blocks[id]).filter(Boolean);
    }, [localBlockSortOrder, globalBlockSortOrder, blocks]);

    const setBlockSortOrder = useCallback(
        (order: PageBlockId[]) => {
            setLocalBlockSortOrder(order);
            setValue("blockSortOrder", order, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            });
        },
        [setValue, setLocalBlockSortOrder]
    );

    return {
        blocks: blocksSorted,
        blocksIndexed: blocks,
        blockSortOrder: localBlockSortOrder,
        setBlockSortOrder,
    };
};

export {
    usePageBlocksFromFormContext,
    usePageSettingsFromFormContext,
    usePageBlocks,
};
