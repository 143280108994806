import { ProviderId } from "@towni/common";
import * as React from "react";
import { useReducer } from "react";

type CacheState = {
    providerSortOrderByArea: {
        [area: string]: {
            order: ProviderId[];
            tag: number;
        };
    };
};

const initialState: CacheState = {
    providerSortOrderByArea: {},
};

type Action = {
    type: "SET_PROVIDER_SORT_ORDER_BY_AREA";
    area: string;
    sortOrder: ProviderId[];
    tag: number;
};

const reducer = (state: CacheState, action: Action): CacheState => {
    switch (action.type) {
        case "SET_PROVIDER_SORT_ORDER_BY_AREA": {
            return {
                ...state,
                providerSortOrderByArea: {
                    ...state.providerSortOrderByArea,
                    [action.area]: {
                        ...state.providerSortOrderByArea[action.area],
                        order: action.sortOrder,
                        tag: action.tag,
                    },
                },
            };
        }
        default:
            return state;
    }
};
type Dispatch = (action: Action) => void;
const ClientMemoryCacheStateContext =
    React.createContext<CacheState>(initialState);
const ClientMemoryCacheDispatchContext = React.createContext<
    Dispatch | undefined
>(undefined);
const ClientMemoryCacheProvider = (props: {
    readonly children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <ClientMemoryCacheStateContext.Provider value={state}>
            <ClientMemoryCacheDispatchContext.Provider value={dispatch}>
                {props.children}
            </ClientMemoryCacheDispatchContext.Provider>
        </ClientMemoryCacheStateContext.Provider>
    );
};

const useClientMemoryCache = () => {
    const context = React.useContext(ClientMemoryCacheStateContext);
    if (context === undefined) {
        throw new Error(
            "useClientMemoryCache must be used within a ClientMemoryCacheProvider"
        );
    }
    return context;
};
const useClientMemoryCacheDispatch = () => {
    const context = React.useContext(ClientMemoryCacheDispatchContext);
    if (context === undefined) {
        throw new Error(
            "useClientMemoryCacheDispatch must be used within a ClientMemoryCacheProvider"
        );
    }
    return context;
};

export {
    ClientMemoryCacheProvider,
    useClientMemoryCache,
    useClientMemoryCacheDispatch,
};
