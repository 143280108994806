import { useTheme } from "@emotion/react";
import { Except } from "type-fest";
import type { ButtonColorPropNames, ButtonProps_v2 } from "./button";
import { Button_v2 } from "./button";

const ButtonPurpleLight = (
    props: Except<ButtonProps_v2, ButtonColorPropNames>,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            {...props}
            colorSet={{
                main: theme.colors.purple.light,
                text: theme.colors.purple.main,
                border: theme.colors.purple.light,
            }}>
            {props.children}
        </Button_v2>
    );
};

export { ButtonPurpleLight };
