import {
    getDailySchedulesForWeekdayAndProvider,
    getMinutesOfDay,
    Provider,
    ProviderStatusType,
    providerStatusTypeMap,
    TimePadding,
} from "@towni/common";
import { isProviderHidden } from "./is-provider-hidden";
import { isProviderPaused } from "./is-provider-paused";

type HoursCheckResult = {
    withinBusinessHours: boolean;
    withinOrderingHours: boolean;
    businessHoursTimeLeftInMinutes: number;
    orderingHoursTimeLeftInMinutes: number;
};

// /**
//  * Get the `BusinessHoursWeek` set for the specific time, wither default or exception
//  * Note that when you've got the `BusinessHoursWeek` it's only guaranteed to be valid
//  * for the given time in question, not for the whole week, there might be other exceptions.
//  *
//  * @param {Provider} provider
//  * @param {Date} at
//  * @returns {BusinessHoursWeek}
//  */
// const getBusinessHoursWeek = (
//     provider: Provider,
//     at: Date
// ): BusinessHoursWeek => {
//     const businessHoursWeek =
//         provider.options.businessHourExceptions.find(bhe => {
//             return (
//                 bhe.start.unix <= Math.round(at.getTime() / 1000) &&
//                 bhe.end.unix > Math.round(at.getTime() / 1000)
//             );
//         })?.businessHours || provider.options.businessHours;

//     return businessHoursWeek;
// };

const businessAndOrderingHoursCheck = (
    provider: Provider,
    at: Date = new Date()
): HoursCheckResult => {
    // What we know:
    const minutesOfDay = getMinutesOfDay(at);
    // const weekdayShortName = getWeekdayShortName(at);
    const defaultOrderTimePadding = provider.options
        ?.defaultOrderTimePadding ?? {
        startInMinutes: 0,
        endInMinutes: 0,
    };

    // Get the hours set for the time to check (`at`)
    // Will be either ordinary business hours or exception hours
    const businessHoursRanges = getDailySchedulesForWeekdayAndProvider(
        at,
        provider
    ); // getBusinessHoursWeek(provider, at);

    // Find slot during business hours for time to check (`at`)
    const range = businessHoursRanges.find(
        range =>
            range.timeRange.startInMinutes <= minutesOfDay &&
            range.timeRange.endInMinutes > minutesOfDay
    ); // within time range

    // If slot not found, no hours for given time (`at`)
    if (!range)
        return {
            withinBusinessHours: false,
            withinOrderingHours: false,
            businessHoursTimeLeftInMinutes: 0,
            orderingHoursTimeLeftInMinutes: 0,
        };

    // Slot found, calculate ordering hours from business hours
    const orderTimePadding: TimePadding =
        range.orderTimePadding ?? defaultOrderTimePadding;

    const isWithinOrderingHours =
        range.timeRange.startInMinutes + orderTimePadding.startInMinutes <=
            minutesOfDay &&
        range.timeRange.endInMinutes - orderTimePadding.endInMinutes >
            minutesOfDay;

    const businessHoursTimeLeftInMinutes =
        range.timeRange.endInMinutes - minutesOfDay;
    const orderingHoursTimeLeftInMinutes = isWithinOrderingHours
        ? range.timeRange.endInMinutes -
          orderTimePadding.endInMinutes -
          minutesOfDay
        : 0;

    return {
        withinBusinessHours: true,
        withinOrderingHours: isWithinOrderingHours,
        businessHoursTimeLeftInMinutes,
        orderingHoursTimeLeftInMinutes,
    };
};

// const findNextExceptionTimeRange = (params: {
//     readonly provider: Provider;
//     readonly after: Date;
//     readonly before: Date;
// }) => {
//     const { provider, after, before } = params;
//     const firstExceptionWithinTimeFrame:
//         | BusinessHourException
//         | undefined = provider.options.businessHourExceptions
//         // Find exceptions where start is between from and before
//         .filter(
//             item =>
//                 item.start.unix > toUnix(after) &&
//                 item.start.unix <= toUnix(before)
//         )
//         .sort(sortBy(item => item.start.unix))[0];

//     return firstExceptionWithinTimeFrame;
// };
// const findNextBusinessAndOrderingHoursException = (provider: Provider, after: Date = new Date()) => {

//     // What we know
//     const minutesOfDay = getMinutesOfDay(after);
//     const weekday = getWeekdayShortName(after);

//     // Find first exception with at least one slot after given date
//     const nextException: BusinessHourException | undefined = provider.options.businessHourExceptions
//         .sort(sortBy(item => item.start.unix, "DESCENDING"))
//         .filter(item => toUnix(after) > item.start.unix &&
//             Object.values(item.businessHours).flatMap(bh => bh).length > 0
//          )[0];
//     const nextExceptionRange = nextException?.businessHours[weekday]

//     // Loop through the days of week and find the next slot
//     exceptions.find(exception => {
//         // find first slot of exception
//         const firstWeekdayOfException = format(asDate(exception.start), "EEE").toLowerCase() as keyof typeof provider.options.businessHours;
//         const weekday = repeatIndex(weekdayShortNames.length)
//             .map(index => index % 7) // get the days in order, e.g. [5, 6, 0, 1, 2, 3, 4];
//             .filter(numberOfDay => exception.businessHours[weekdayShortNames[numberOfDay]].length)[0]; // find first

//         if (typeof weekday === "undefined")
//             return undefined;

//         const sortedBusinessHoursForTheDay = exception.businessHours[weekdayShortNames[weekday]]
//         const slot = sortedBusinessHoursForTheDay
//             .find(numberOfDay => {
//                     .sort(sortBy(item => item.timeRange.startInMinutes));
//                 const next = sortedBusinessHoursForTheDay[0];

//             })
//     })

//     let index = ;
//     let next: BusinessHourRange | undefined = undefined;
//     do {
//         // Check for the first item available for every day for one week forward
//         const sortedBusinessHoursForDay = provider.options.businessHours[weekdayShortNames[index]]
//             .filter(item => item.timeRange.startInMinutes > minutesOfDay)
//             .sort(sortBy(item => item.timeRange.startInMinutes));

//         next = sortedBusinessHoursForDay.find(item => index > startIndex || item.timeRange.startInMinutes > minutesOfDay);
//         if (next) {
//             // Item found

//             //

//             console.log("Found next: ", )
//             break;
//         }

//         // item not found, continue with next day
//         index = ((index + 1) % 7);
//     } while (index !== startIndex);

//     return exception
// }

// const findNextBusinessAndOrderingHours = (provider: Provider, after: Date = new Date()) => {

//     // What we know
//     const minutesOfDay = getMinutesOfDay(after);
//     const weekdayShortName = getWeekdayShortName(after);
//     const startIndex = weekdayShortNames.findIndex(item => item === weekdayShortName);

//     // Find first exception from the defaults with a slot specified
//     const exception = findNextBusinessAndOrderingHoursException(provider, after);

//     // Loop through the days of week and find the next slot
//     let index = startIndex;
//     let next: BusinessHourRange | undefined = undefined;
//     do {
//         // Check for the first item available for every day for one week forward
//         const sortedBusinessHoursForDay = provider.options.businessHours[weekdayShortNames[index]]
//             .filter(item => item.timeRange.startInMinutes > minutesOfDay)
//             .sort(sortBy(item => item.timeRange.startInMinutes));

//         next = sortedBusinessHoursForDay.find(item => index > startIndex || item.timeRange.startInMinutes > minutesOfDay);
//         if (next) {
//             // Item found

//             //

//             console.log("Found next: ", )
//             break;
//         }

//         // item not found, continue with next day
//         index = ((index + 1) % 7);
//     } while (index !== startIndex);

//     return next;
// }

/**
 * Calculates provider status depending on multiple data like e.g. business hours, ordering hours, paused, next slot etc
 *
 * @param {Provider} provider
 * @param {Date} [at=new Date()]
 * @returns
 */
const calculateProviderStatus = (
    provider: Provider | undefined,
    at: Date = new Date()
): ProviderStatusType => {
    // Is provider undefined
    if (!provider) return providerStatusTypeMap.DOES_NOT_EXIST;
    // Is provider hidden
    if (isProviderHidden(provider)) {
        return providerStatusTypeMap.HIDDEN;
    }

    // If provider has been paused and the pause is still active
    if (isProviderPaused(provider, at)) {
        // return a paused status that ends when pause is set to end
        return providerStatusTypeMap.PAUSED;
    }

    // Parse business hours and ordering hours for current time (`at`)
    const businessAndOrderingHours = businessAndOrderingHoursCheck(
        provider,
        at
    );

    // // Find next status change..
    // const nextException = findNextExceptionTimeRange({
    //     provider,
    //     after: at,
    //     before: addMinutes(
    //         at,
    //         businessAndOrderingHours.businessHoursTimeLeftInMinutes
    //     ),
    // });

    // const until: Until = {
    //     time: (nextException && { ...nextException.start }) ||
    //     createDateTimeShort(
    //         addMinutes(
    //             at,
    //             businessAndOrderingHours.businessHoursTimeLeftInMinutes
    //         )
    //     ),
    //     nextStatus: nextException?.type ?? businessAndOrderingHours. ,

    // }

    // create status and return is a result
    const providerStatus = businessAndOrderingHours.withinOrderingHours
        ? providerStatusTypeMap.ACTIVE
        : businessAndOrderingHours.withinBusinessHours
        ? providerStatusTypeMap.OPEN
        : providerStatusTypeMap.CLOSED;
    return providerStatus;
};

const useCalculatedProviderStatus = (
    provider: Provider | undefined,
    date = new Date()
) => {
    if (!provider) return undefined;

    const status = calculateProviderStatus(provider, date);
    return status;
};

export { calculateProviderStatus, useCalculatedProviderStatus };
