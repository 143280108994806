import { useCart } from "@@/carts/multi-carts/cart.context";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { browserLogger } from "@@/settings";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { whiteningGradient } from "@@/shared/bg-lightening-gradient";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { OptionalGroup, Provider, translation } from "@towni/common";
import { useEffect, useState } from "react";
import { BookableExtrasGrid } from "../../bookable-extras-grid";
import { BookableExtrasOrderItems } from "../../bookable-extras-order-item-list";
import { useBookingContext } from "../../booking-context";
import { useAddBookableToCart } from "../../hooks/use-add-bookable-to-cart";
import { BookableDetailsOptionals } from "../bookable-details/bookable-details-optionals";
import { ExtrasAndOptionalsPageV2 } from "./extras-and-optionals-page-v2";

type Props = {
    readonly optionals: OptionalGroup[];
    readonly provider: Provider;
};
const ExtrasAndOptionalsPageV1 = (props: Props) => {
    const theme = useTheme();
    const wizardActions = useWizardDispatch();
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        optionValues: context.optionValues,
        isOnPremiseBooking: context.isOnPremiseBooking,
        requestedResources: context.requestedResources,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        settings: context.bookable.settings,
        pickedOptionalsMap: context.pickedOptionalsMap,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));
    const cart = useCart();
    const actions = useBookingContext(context => {
        return {
            setMessageToProvider: context.setMessageToProvider,
            setAddToCartDisabled: context.setAddToCartDisabled,
            setPickedOptionalsMap: context.setPickedOptionalsMap,
        };
    });
    const addToCart = useAddBookableToCart();

    const [continueDisabled, setContinueDisabled] = useState(true);

    useEffect(() => {
        const continueDisabled =
            // where there is an option group...
            !!props.optionals
                // with a required minimal number of options...
                .some(optionalGroupOrOptionalAmount => {
                    if (!optionalGroupOrOptionalAmount.minMax.min) return false; // undefined or 0, not required
                    const picked = state.pickedOptionalsMap.get(
                        optionalGroupOrOptionalAmount._id,
                    );
                    if (!picked) return true;

                    const notEnough =
                        picked.length <
                        optionalGroupOrOptionalAmount.minMax.min;
                    return notEnough;
                });

        setContinueDisabled(!!continueDisabled);
    }, [props.optionals, state.pickedOptionalsMap]);

    return (
        <>
            <FlexColumn
                fillParent
                css={{
                    backgroundColor: theme.colors.default.background.asString,
                }}>
                <FlexColumn fillParent overflowY="scroll">
                    <BookableDetailsOptionals
                        initialValues={state.pickedOptionalsMap}
                        optionals={props.optionals}
                        onChange={pickedOptionalsMap => {
                            actions.setPickedOptionalsMap(pickedOptionalsMap);
                            if (!cart.orderItems.length) {
                                //Fixes problem when cart is emptying after order.
                                browserLogger.info(
                                    "Could not find order item to update",
                                );
                                return;
                            }
                            addToCart(pickedOptionalsMap);
                        }}
                    />
                    <VerticalDivider M />
                    <HorizontalSectionDivider />
                    <VerticalDivider M />
                    <FlexColumn fillParentWidth>
                        <BookableExtrasOrderItems
                            title={translation({
                                sv: "Tillagda tillval",
                                en: "Selected extras",
                            })}
                            productIdsToIgnore={[state.product._id]}
                        />
                        <VerticalDivider M />
                        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
                            <BookableExtrasGrid aspectRatio="1:1" />
                        </FlexColumn>
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                <FlexRow
                    css={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        pointerEvents: "none",
                    }}
                    background={{
                        linearGradient: theme.isLightTheme
                            ? whiteningGradient
                            : darkeningGradient,
                    }}
                    fillParentWidth
                    mainAxis="flex-end"
                    padding={{ all: 20, top: 60 }}>
                    <FlexColumn crossAxis="flex-end">
                        <ButtonPrimary
                            disabled={continueDisabled}
                            onClick={() => {
                                wizardActions.goForward();
                            }}
                            css={{ pointerEvents: "all", borderRadius: 3000 }}>
                            <TextBox
                                text={translation({
                                    sv: "Fortsätt",
                                    en: "Continue",
                                })}
                                padding={{
                                    leftRight: 15,
                                    topBottom: 2,
                                }}
                            />
                        </ButtonPrimary>
                        <Conditional
                            when={continueDisabled}
                            render={() => (
                                <>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={translation({
                                            sv: "* Det finns val kvar att göra",
                                            en: "* You still have options you need to pick",
                                        })}
                                        color={
                                            theme.isLightTheme
                                                ? theme.colors.black.light60
                                                : theme.colors.black.light40
                                        }
                                        size={0.8}
                                    />
                                </>
                            )}
                        />
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        </>
    );
};

const ExtrasAndOptionalsPage = (props: Props) => {
    const state = useBookingContext(context => ({
        bookable: context.bookable,
    }));
    if (state.bookable.extrasGroups)
        return <ExtrasAndOptionalsPageV2 {...props} />;
    return <ExtrasAndOptionalsPageV1 {...props} />;
};

export { ExtrasAndOptionalsPage };
