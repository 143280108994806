import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    AccessKeyId,
    TableBookingCancelCommand,
    TableBookingCancelCommandFactoryParams,
    Translatable,
    isApiError,
    tableBookingCancelCommandFactory,
} from "@towni/common";

const useTableBookingCancellationCommand = (
    params: TableBookingCancelCommandFactoryParams & {
        providerTitle: Translatable;
    },
    accessKey?: AccessKeyId,
) => {
    const toast = useToast();
    const { providerTitle, ...factoryParams } = params;
    const queryClient = useQueryClient();
    const mutationFn = () => {
        return apiFetchClient.post<TableBookingCancelCommand>({
            route: `/orders/${params.orderId}/cancellations${
                accessKey ? `?ak=${accessKey}` : ""
            }`,
            body: tableBookingCancelCommandFactory(factoryParams),
        });
    };
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["orders"] });
        },
        onError: error => {
            if (isApiError(error)) toast.fromError(error);
            toast.danger({
                message: `Avbokning kunde inte göras, om problemet kvarstår, kontakta ${providerTitle} för assistans.`,
            });
            throw error;
        },
    });
};

export { useTableBookingCancellationCommand };
