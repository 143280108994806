import { AccommodationCart_SubTypePicker } from "@@/carts/accommodation-checkout/accommodation-cart.sub-type-picker";
import { Cart_AccommodationPanel } from "@@/carts/accommodation-checkout/accommodation-panel";
import { AccommodationExtrasPanel } from "@@/carts/accommodation-checkout/extras-panel";
import { useHasMissingSubType } from "@@/carts/accommodation-checkout/use-has-missing-sub-type";
import { AccommodationPurpose_Select } from "@@/carts/bookable-checkout/accommodation-purpose.select";
import { InputContactInfo } from "@@/carts/bookable-checkout/input-contact-info";
import { useCheckoutContext } from "@@/carts/checkout.context";
import { useModal } from "@@/modals/use-modal";
import { PageLoad } from "@@/pages/page-load";
import { CheckoutModal } from "@@/payments/checkout-modal";
import { PaymentDetailsContextProvider } from "@@/payments/v2/payment-details.context";
import { AddMessageRow } from "@@/products/add-message-row";
import { ContentBlockView } from "@@/products/bookables/content-block-view";
import { useProduct } from "@@/products/state/queries/product/use-product";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { useBookableResources } from "@@/resources/resources/use-resources";
import { AddressLink } from "@@/section-groups/address-link";
import { Card } from "@@/sections/content/items/card/card";
import { Conditional } from "@@/shared/conditional";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import {
    OrderGroupId,
    OrderId,
    Translatable,
    flattenGraph,
    isOrderItemAccommodation_V2,
    isOrderItemGiftCard_V2,
    support,
    translation,
} from "@towni/common";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CartRowsAndTotal } from "../bookable-checkout/cart-rows-and-total";
import { CheckoutButton } from "../checkout-button";
import { DiscountInCartRow } from "../discount-codes/discount-in-cart-row";
import { GiftCardInCartRow } from "../giftcard/giftcard-in-cart-row";
import { useCartContext } from "../multi-carts/cart.context";
import { useVerifiedCartState } from "../use-verified-cart";

// COMPONENT
type Props = {
    hide: () => void;
    readonly onCheckoutComplete?: (params: {
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void;
};

const AccommodationCart = (props: Props) => {
    const { hide: hideCart } = props;
    const theme = useTheme();
    const translate = useTranslate();
    const { cart, setCart, clearCart, setMessageToProvider } = useCartContext(
        context => ({
            cart: context.cart,
            setCart: context.setCart,
            clearCart: context.clearCart,
            setMessageToProvider: context.setMessageToProvider,
            setDeliverySelection: context.setDeliverySelection,
        }),
    );

    const { onPremise, contactInfo } = useCheckoutContext(context => ({
        onPremise: context.onPremise,
        contactInfo: context.contactInfo,
    }));

    const queryClient = useQueryClient();

    useVerifiedCartState(cart, validatedCart => {
        if (validatedCart) setCart(validatedCart);
        else clearCart();
    });
    const [{ show: showCheckout }, checkoutModalId] =
        useModal("checkout_modal__");

    const showCheckoutModal = () => {
        showCheckout();
    };

    const navigate = useNavigate();
    const [provider, providerQuery] = useProvider(cart.providerId);
    const [product] = useProduct(
        cart.orderItems.find(isOrderItemAccommodation_V2)?.productId,
    );
    const [globalResources, _] = useBookableResources(product?._id);

    const flatten = globalResources.flatMap(graph => flattenGraph(graph));
    const contentBlocks =
        product?.skus[0].acquire?._type === "ACCOMMODATION"
            ? product.skus[0].acquire.contentBlocks
            : [];

    const messageToProvider = cart.messageToProvider || null;

    useEffect(() => {
        if (cart.orderItems.length) return;

        hideCart();
    }, [cart, hideCart]);

    const [orderCanNotBePlaced, setOrderCanNotBePlaced] = React.useState<
        Translatable | false
    >(
        translation({
            sv: "Fyll i alla obligatoriska fält",
            en: "Please fill in all required fields",
        }),
    );

    const hasMissingSubType = useHasMissingSubType();
    React.useEffect(() => {
        if (onPremise && !contactInfo) {
            setOrderCanNotBePlaced(
                translation({
                    sv: "Kontaktuppgifter saknas",
                    en: "Contact information missing",
                }),
            );
            return;
        }
        if (hasMissingSubType) {
            setOrderCanNotBePlaced(
                translation({
                    sv: "Välj först boendetyp",
                    en: "Select type required",
                }),
            );
            return;
        }

        setOrderCanNotBePlaced(false);
    }, [
        cart.orderItems,
        cart.reservationRequests,
        onPremise,
        contactInfo,
        hasMissingSubType,
    ]);
    if (providerQuery.isPending) return <PageLoad />;

    if (!provider) {
        throw new Error(
            `Kunde inte ladda säljardata, försök strax igen eller kontakta ${support.towni.supportEmail} för assistans`,
            {
                cause:
                    providerQuery.error ||
                    new Error(
                        `Provider for cart not found; ${
                            cart._id
                        }, ${cart.orderItems
                            .map(oi => oi.providerId)
                            .join(", ")}`,
                    ),
            },
        );
    }

    return (
        <FlexColumn
            fillParent
            background={{
                color: theme.colors.default.background.asString,
            }}
            css={{
                backgroundColor: theme.colors.default.background.asString,
                flexShrink: 0,
                label: "accommodation_cart",
            }}
            crossAxis="stretch"
            mainAxis="flex-start">
            <FlexColumn
                crossAxis="stretch"
                mainAxis="flex-start"
                overflowY="scroll"
                grow={1}>
                {cart.orderItems.map(cartItem => {
                    if (isOrderItemAccommodation_V2(cartItem)) {
                        const resources = flatten.filter(r =>
                            cart.reservationRequests.some(
                                rr => rr.resourceId === r._id,
                            ),
                        );
                        return (
                            <Cart_AccommodationPanel
                                key={cartItem._id}
                                orderItem={cartItem}
                                resourceReservations={cart.reservationRequests}
                                resources={resources}
                            />
                        );
                    }

                    return null;
                })}
                {product ? (
                    <AccommodationExtrasPanel product={product} />
                ) : null}

                <AccommodationCart_SubTypePicker />

                <ForEach
                    itemOf={contentBlocks}
                    getKey={cb => cb._id}
                    divider={<HorizontalLine />}>
                    {(block, index) => {
                        return (
                            <FlexColumn
                                key={block._id}
                                fillParentWidth
                                padding={{ leftRight: 20 }}>
                                <VerticalDivider M />
                                <ContentBlockView
                                    key={block._id}
                                    contentBlock={block}
                                    index={index}
                                />
                                <VerticalDivider M />
                            </FlexColumn>
                        );
                    }}
                </ForEach>
                <Conditional
                    when={!!contentBlocks.length}
                    render={() => (
                        <HorizontalSectionDivider key={"bc2_start"} />
                    )}
                />

                <CartRowsAndTotal />
                <VerticalDivider M />

                <FlexRow>
                    <Card
                        width={80}
                        height={80}
                        background={{
                            imageReference: provider.meta.images[0],
                            imageSize: {
                                imageWidth: theme.sizes.imageSizes.closest(80),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <FlexColumn
                        fillParentWidth
                        crossAxis="flex-start"
                        shrink={0}
                        padding={{ left: 20 }}>
                        <TextBox
                            text={provider.meta.title}
                            size="L"
                            weight="900"
                        />
                        <VerticalDivider S />
                        <AddressLink address={provider.address} size="S" />
                    </FlexColumn>
                </FlexRow>

                <VerticalDivider M />
                <HorizontalSectionDivider />
                <Conditional
                    when={onPremise}
                    render={() => (
                        <>
                            <InputContactInfo />
                            <HorizontalSectionDivider />
                            <AccommodationPurpose_Select />
                            <HorizontalSectionDivider />
                        </>
                    )}
                />

                <HorizontalLine />
                <GiftCardInCartRow />
                <Conditional
                    when={!cart.orderItems.some(isOrderItemGiftCard_V2)}
                    else={() => <HorizontalLine />}>
                    <HorizontalLine />
                    <DiscountInCartRow />
                </Conditional>

                <AddMessageRow
                    initialMessage={messageToProvider || ""}
                    onSetMessage={setMessageToProvider}
                    padding={{ leftRight: 20, topBottom: 15 }}
                    size="S"
                />
                <HorizontalLine />
                <VerticalDivider size={1.5} />
                <TextBox
                    padding={{ leftRight: 20 }}
                    text={translation({
                        sv: `Genom att gå vidare godkänner du Townis avtal och de regler som är förbundna med ovan objekt. För matallergier eller speciella kostbehov, kontakta vänligen handlaren innan du beställer.`,
                        en: `By proceeding you accept Towni's agreement and the rules associated with the above objects. For food allergies or special dietary needs, please contact the retailer before ordering.`,
                    })}
                    size={0.8}
                    color={theme.colors.default.text.light60}
                />
                <VerticalDivider XL />
                <FlexRow
                    mainAxis="center"
                    shrink={0}
                    padding={{ top: 20, bottom: 40 }}>
                    <TextBox
                        text={translation({
                            sv: "Töm kundkorgen",
                            en: "Empty the shopping cart",
                        })}
                        onClick={() => {
                            if (
                                confirm(
                                    translate(
                                        translation({
                                            sv: "Är du säker på att du vill tömma kundkorgen?",
                                            en: "Are you sure you want to empty your shopping cart?",
                                        }),
                                    ),
                                )
                            ) {
                                clearCart();
                                hideCart();
                            }
                        }}
                        size="S"
                        color={theme.colors.default.text.light70}
                    />
                </FlexRow>
                <VerticalDivider XXL />
                <VerticalDivider XXL />
                <FlexRow fillParentWidth>
                    <CheckoutButton
                        onClick={showCheckoutModal}
                        disabled={orderCanNotBePlaced !== false}
                        text={
                            orderCanNotBePlaced !== false
                                ? orderCanNotBePlaced
                                : undefined
                        }
                    />
                </FlexRow>
            </FlexColumn>
            <PaymentDetailsContextProvider
                acceptSwish={
                    provider ? provider.options.acceptSwish ?? false : false
                }>
                <CheckoutModal
                    modalId={checkoutModalId}
                    onHide={async () => {
                        //hide}
                    }}
                    onFinished={(orderGroupId, orderId) => {
                        queryClient.invalidateQueries({
                            queryKey: ["orders"],
                        });
                        clearCart();

                        if (props.onCheckoutComplete)
                            props.onCheckoutComplete({
                                orderGroupId,
                                orderId: orderId,
                            });

                        if (!onPremise) {
                            navigate(
                                `/receipts/${encodeURIComponent(
                                    orderGroupId,
                                )}?waitforit=true`,
                            );
                        }
                    }}
                    delivery={false}
                    forceFullHeightMode={true}
                    showDeliveryModal={() => false}
                />
            </PaymentDetailsContextProvider>
        </FlexColumn>
    );
};

export { AccommodationCart };
