import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { FontWeight } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { css } from "@emotion/react";
import { ColorItem, MetaContent, SizeName, Translatable } from "@towni/common";
const productTitle = (
    meta: MetaContent,
    translate: (translatable: Translatable) => string | undefined
) => {
    let output =
        (meta.subtitle &&
            meta.subtitle !== meta.title &&
            translate(meta.subtitle) + " - ") ||
        "";
    output += translate(meta.title)?.trim();
    return output;
};

type Props = {
    meta: MetaContent;
    weight?: FontWeight;
    size?: SizeName | number;
    noWrap?: boolean;
    lineClamp?: number;
    shrink?: number;
    color?: ColorItem;
};

const ProductTitleTextBox = ({ meta, ...props }: Props) => {
    const translate = useTranslate();
    const weight = props.weight || "400";
    const size = props.size || 1;
    const noWrap = !!props.noWrap;

    const noWrapProps = {
        ...(noWrap
            ? {
                  styling: css`
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow-x: hidden;
                  `,
              }
            : {}),
    };

    return (
        <FlexColumn wrap="wrap" shrink={props.shrink}>
            <TextBox
                text={meta.title}
                weight={weight}
                size={size}
                lineClamp={props.lineClamp}
                color={props.color}
                {...noWrapProps}
            />
            {meta.subtitle &&
            translate(meta.title) !== translate(meta.subtitle) ? (
                <>
                    <TextBox
                        text={meta.subtitle}
                        weight={"300"}
                        size={size}
                        lineClamp={props.lineClamp}
                        color={props.color}
                        {...noWrapProps}
                    />
                </>
            ) : null}
        </FlexColumn>
    );
};

export { ProductTitleTextBox, productTitle };
