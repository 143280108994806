import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { CartId, GetSingleResponse } from "@towni/common";

type CartQuery = {
    cartId: CartId;
    exist: boolean;
};
const useCartIdIsUnused = (cartId: CartId) => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<GetSingleResponse<CartQuery>> = async ({
        signal,
    }) => {
        return await fetchClient.get({
            route: `/carts/status/${cartId}`,
            customConfig: {
                signal,
            },
        });
    };
    return useQuery({
        queryKey: ["carts", cartId],
        queryFn,
    });
};

export { useCartIdIsUnused };
