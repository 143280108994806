import { useTranslate } from "@@/translations/use-translate";
import {
    asDate,
    DateRange,
    isDate,
    isoAndUnixFactory,
    IsoAndUnixTimestamp,
    TimeRange,
    Translatable,
} from "@towni/common";
import { isThisYear } from "date-fns";
import { Except } from "type-fest";
import { useDateTimeFormatter } from "../use-date-time-formatter";
import { TextBox, TextBoxProps } from "./text-box";

type Format = "COMPACT" | "SHORT" | "MEDIUM" | "LONG" | "YEAR" | "DAY_OF_MONTH";

type Props = Except<TextBoxProps, "text" | "children"> & {
    readonly date: IsoAndUnixTimestamp | Date | undefined;
    /**
     * _Exempel_ \
     * COMPACT: `ÅÅ-MM-DD` \
     * SHORT: `ÅÅÅÅ-MM-DD` \
     * MEDIUM: `11:e mars` eller `11 mars 2020` \
     * LONG: `Måndag den 11:e mars` eller `Måndag den 11 mars 2020` \
     * _defaults to type `MEDIUM`
     */
    readonly format?: Format;
    /**
     * _defaults to `false` \
     * Can't be true together with `hideYear`
     * @type {boolean}
     */
    readonly forceIncludeYear?: boolean;
    /**
     * Can't be true together with `forceIncludeYear`
     * @type {boolean}
     */
    readonly hideYear?: boolean;
    readonly fallbackWhenUndefined?: string;
    readonly className?: string;
};

const useDateFormatterSelector = (format: Format) => {
    const dateFormatter = useDateTimeFormatter();
    switch (format) {
        case "COMPACT":
            return dateFormatter.formatDateShort;
        case "SHORT":
            return dateFormatter.formatDate;
        case "MEDIUM":
            return dateFormatter.formatDateMedium;
        case "LONG":
            return dateFormatter.formatDateLong;
        case "DAY_OF_MONTH":
            return dateFormatter.formatDayOfMonth;
        case "YEAR":
            return (
                dateTime: IsoAndUnixTimestamp,
                _options?: { includeYear?: boolean },
            ) => asDate(dateTime).getFullYear().toString();
        default:
            throw new Error("Unhandled date format type; " + format);
    }
};

const useDateRangeFormatted = (
    dateRange: DateRange | undefined,
    options?: {
        /**
         * _Exempel_ \
         * COMPACT: `ÅÅ-MM-DD` \
         * SHORT: `ÅÅÅÅ-MM-DD` \
         * MEDIUM: `11:e mars` eller `11 mars 2020` \
         * LONG: `Måndag den 11:e mars` eller `Måndag den 11 mars 2020` \
         * _defaults to type `MEDIUM`
         */
        readonly format?: Format;
        /**
         * _defaults to `false` \
         * Can't be true together with `hideYear`
         * @type {boolean}
         */
        readonly forceIncludeYear?: boolean;
        /**
         * Can't be true together with `forceIncludeYear`
         * @type {boolean}
         */
        readonly hideYear?: boolean;
        /** Defaults to `-` */
        readonly whenUndefined?: Translatable;
    },
): string => {
    const translate = useTranslate();
    const forceIncludeYear = !!options?.forceIncludeYear;
    const hideYear = !!options?.hideYear;
    const format = options?.format ?? "MEDIUM";
    const formatter = useDateFormatterSelector(format);
    if (forceIncludeYear && hideYear)
        throw new Error("force year and hide year can't be true together");

    if (!dateRange)
        return options?.whenUndefined ? translate(options.whenUndefined) : "-";

    const start = dateRange.start;
    const end = dateRange.end;

    const formattedStart = formatter(start, {
        includeYear:
            !hideYear && (!!forceIncludeYear || !isThisYear(asDate(start))),
    });
    const formattedEnd = formatter(end, {
        includeYear:
            !hideYear && (!!forceIncludeYear || !isThisYear(asDate(start))),
    });

    return formattedStart === formattedEnd
        ? formattedStart
        : `${formattedStart} - ${formattedEnd}`;
};

const DateTextBox = (props: Props) => {
    const { format, forceIncludeYear, hideYear, ...textBoxProps } = props;
    if (forceIncludeYear && hideYear)
        throw new Error("force year and hide year can't be true together");
    const date = isDate(props.date)
        ? isoAndUnixFactory(props.date)
        : props.date;
    const formatter = useDateFormatterSelector(format ?? "MEDIUM");
    const formattedDate = date
        ? formatter(date, {
              includeYear:
                  !hideYear &&
                  (!!forceIncludeYear || !isThisYear(asDate(date))),
          })
        : props.fallbackWhenUndefined ?? "";
    return <TextBox {...textBoxProps} text={formattedDate} />;
};
const DateRangeTextBox = (props: {
    dateRange: DateRange | undefined;
    /**
     * _Exempel_ \
     * COMPACT: `ÅÅ-MM-DD` \
     * SHORT: `ÅÅÅÅ-MM-DD` \
     * MEDIUM: `11:e mars` eller `11 mars 2020` \
     * LONG: `Måndag den 11:e mars` eller `Måndag den 11 mars 2020` \
     * _defaults to type `MEDIUM`
     */
    readonly format?: Format;
    className?: string;
    /**
     * _defaults to `false` \
     * Can't be true together with `hideYear`
     * @type {boolean}
     */
    readonly forceIncludeYear?: boolean;
    /**
     * Can't be true together with `forceIncludeYear`
     * @type {boolean}
     */
    readonly hideYear?: boolean;
    /** Defaults to `-` */
    readonly whenUndefined?: string;
}) => {
    const { forceIncludeYear, hideYear, ...textBoxProps } = props;

    const formattedDateRange = useDateRangeFormatted(props.dateRange, {
        format: props.format,
        forceIncludeYear: forceIncludeYear,
        hideYear: hideYear,
        whenUndefined: props.whenUndefined,
    });

    return <TextBox {...textBoxProps} text={formattedDateRange} />;
};

const TimeRangeTextBox = (props: {
    readonly timeRange: TimeRange | undefined;
    /** Defaults to `-` */
    readonly delimiter?: Translatable;
    readonly className?: string;
    /**
     * _defaults to `false` \
     * Can't be true together with `hideYear`
     * @type {boolean}
     */
    readonly forceIncludeYear?: boolean;
    /**
     * Can't be true together with `forceIncludeYear`
     * @type {boolean}
     */
    readonly hideYear?: boolean;
    /** Defaults to `-` */
    readonly whenUndefined?: string;
    readonly color?: TextBoxProps["color"];
    readonly weight?: TextBoxProps["weight"];
    readonly size?: TextBoxProps["size"];
    readonly hideDates?: boolean;
    readonly case?: TextBoxProps["case"];
}) => {
    const translate = useTranslate();
    const { forceIncludeYear, hideYear, ...textBoxProps } = props;
    const formatter = useDateTimeFormatter();

    if (forceIncludeYear && hideYear)
        throw new Error("force year and hide year can't be true together");

    if (!props.timeRange)
        return <TextBox {...textBoxProps} text={props.whenUndefined ?? "-"} />;

    const start = props.timeRange.start;
    const end = props.timeRange.end;

    const formattedStart = !props.hideDates
        ? formatter.formatDateAndTimeLong(start, {
              includeYear:
                  !hideYear &&
                  (!!forceIncludeYear || !isThisYear(asDate(start))),
          })
        : formatter.formatTime(start);
    const formattedEnd = !props.hideDates
        ? formatter.formatDateAndTimeLong(end, {
              includeYear:
                  !hideYear &&
                  (!!forceIncludeYear || !isThisYear(asDate(start))),
          })
        : formatter.formatTime(end);
    return (
        <TextBox
            {...textBoxProps}
            text={
                formattedStart === formattedEnd
                    ? formattedStart
                    : `${formattedStart} ${translate(props.delimiter ?? "-")} ${formattedEnd}`
            }
        />
    );
};

export {
    DateRangeTextBox,
    DateTextBox,
    TimeRangeTextBox,
    useDateRangeFormatted,
};
