import { browserLogger } from "@@/settings/browser-logger";
import { Conditional } from "@@/shared/conditional";
import { useDebounce } from "@@/shared/use-debounce";
import { FontWeight } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { SerializedStyles, css, useTheme } from "@emotion/react";
import {
    IconDefinition,
    faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ColorItem,
    MILLISECONDS,
    Padding,
    Password,
    RemSize,
    SizeName,
    Translatable,
    repeatIndex,
    sizeNameMap,
    translation,
} from "@towni/common";
import * as React from "react";
import { useEffect, useId, useState } from "react";
import { z } from "zod";
import { HorizontalDivider, VerticalDivider } from "../dividers";
import { FlexColumn, FlexRow } from "../flex-containers";
import { paddingToCssValue } from "../padding";
import { SelectBox } from "../select-box";
import { TextBox } from "./text-box";

type HTMLInputType = "email" | "text" | "file" | "tel" | "password" | "date";

type Props = {
    /**
     * Defaults to 300
     * @type {number}
     */
    readonly debounceMs?: number;
    readonly validateInput?: (input: string) => Promise<true | Translatable>;
    readonly type: HTMLInputType;
    readonly onChange: ((value: string) => void) | undefined;
    readonly onRawChange?: (value: string) => void;
    /**
     * Modifies text input
     * This happens before adding prefix (if any)
     */
    readonly modify?: (inputValue: string) => string;
    readonly onValidationFailed?: (message?: Translatable) => void;
    readonly onValidationSuccess?: () => void;
    readonly initialValue?: string;
    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly errorMessage?: Translatable;
    readonly size?: SizeName | RemSize;
    readonly padding?: Padding;
    readonly color?: string;
    readonly textInputStyling?: SerializedStyles;
    readonly inputStyling?: SerializedStyles;
    readonly disabled?: boolean;
    readonly prefix?: string;
    readonly fillParentWidth?: boolean;
    readonly shrink?: number;
    readonly grow?: number;
    readonly autoComplete?: string;
    readonly className?: string;
    readonly preElement?: JSX.Element;
    readonly postElement?: JSX.Element;
    readonly icon?: IconDefinition | string;
    readonly onFocus?: (event: React.FocusEvent) => void;
    readonly onBlur?: (event: React.FocusEvent) => void;
};

// eslint-disable-next-line react/display-name
const TextInput = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLInputElement>) => {
        const theme = useTheme();
        const initialValue =
            props.prefix && props.initialValue?.startsWith(props.prefix)
                ? props.initialValue.substring(props.prefix.length)
                : props.initialValue || "";
        const [value, setValue] = useState("");
        const [isDirty, _setIsDirty] = useState(false);
        const [hasBeenBlurred, setHasBlurred] = useState(false);
        const [working, setWorking] = useState(false);
        const translate = useTranslate();
        const placeholder = translate(props.placeholder);
        const description = translate(props.description);
        const setIsDirty = (newOutput: string) => {
            if (props.initialValue && initialValue === newOutput) {
                _setIsDirty(false);
            }
            _setIsDirty(true);
        };

        const update = (_value: string) => {
            props.onRawChange?.(_value);
            const outputValue = `${props.prefix || ""}${
                props.modify?.(_value) || _value
            }`;

            if (props.validateInput) {
                setWorking(true);
                props
                    .validateInput(outputValue)
                    .then(validationResult => {
                        if (validationResult === true) {
                            props.onChange?.(outputValue);
                            setIsDirty(outputValue);
                            props.onValidationSuccess?.();
                        } else {
                            props.onValidationFailed?.(validationResult);
                        }
                    })
                    .catch(error => {
                        browserLogger.warn(
                            "TEXT INPUT VALIDATION ERROR",
                            error,
                        );
                        props.onValidationFailed?.();
                    })
                    .finally(() => {
                        setWorking(false);
                    });
            } else {
                props.onChange?.(outputValue);
                setIsDirty(outputValue);
                setWorking(false);
            }
        };

        useDebounce(
            () => {
                update(value);
            },
            (props.debounceMs ?? 300) as MILLISECONDS,
            [value],
        );

        useEffect(() => {
            if (initialValue) {
                setValue(initialValue);
                update(initialValue);
            }
        }, [initialValue]);

        useEffect(() => {
            if (props.prefix) {
                update(value);
            }
        }, [props.prefix]);

        const _type: HTMLInputType = props.type || "text";
        const { size, color, fillParentWidth, padding } = props;
        const fontRemSize =
            typeof size === "number"
                ? (size as RemSize)
                : (theme.sizes.inRem[size || sizeNameMap.M] as RemSize);

        const hasErrors = !!props.errorMessage && hasBeenBlurred;
        const textInputFlexRowContainerStyles = css`
            border: 1px solid ${theme.colors.textInput.border.asString};
            border-radius: ${theme.radius}px;
            font-weight: 800;
            cursor: pointer;
            font-size: ${fontRemSize}rem;
            background-color: ${theme.colors.textInput.background.asString};
            color: ${color || theme.colors.textInput.text.asString};
            ${(fillParentWidth !== false && `width: 100%;`) || ""}
            ${(padding && `padding:${paddingToCssValue(padding)};`) ||
            "padding: 16px;"}
            ${hasErrors
                ? `border-color: ${theme.colors.danger.border.asString};`
                : ""}
            ${props.textInputStyling || ""}
        ${working ? "padding-right: 40px;" : ""}
        ${props.shrink ? `flex-shrink: ${props.shrink};` : ""}
        ${props.grow ? `flex-grow: ${props.grow};` : ""}
        `;

        const inputStyles = css({
            userSelect: "auto",
            border: "none",
            position: "relative",
            flex: 1,
            fontWeight: "normal",
            fontSize: `${fontRemSize}rem`,
            backgroundColor: theme.colors.textInput.background.asString,
            color: hasErrors
                ? theme.colors.danger.asString
                : props.color || theme.colors.textInput.text.asString,
            ...(hasErrors
                ? {
                      "::placeholder": {
                          color: theme.colors.danger.main.withAlpha(0.5)
                              .asString,
                      },
                  }
                : {}),
        });

        const htmlInputId = useId();

        return (
            <FlexColumn
                fillParentWidth={!!props.fillParentWidth}
                crossAxis="stretch"
                css={{ minWidth: props.shrink ? 150 : undefined }}
                grow={props.grow}
                shrink={props.shrink ?? 0}>
                <FlexRow
                    tag="text_input_container"
                    crossAxis="stretch"
                    mainAxis="stretch"
                    onClick={() => {
                        htmlInputId &&
                            document.getElementById(htmlInputId)?.focus();
                    }}
                    css={[
                        textInputFlexRowContainerStyles,
                        {
                            cursor:
                                props.disabled || !htmlInputId
                                    ? undefined
                                    : "text",
                        },
                    ]}
                    className={props.className}>
                    {props.preElement && (
                        <>
                            {props.preElement}
                            <HorizontalDivider />
                        </>
                    )}
                    <input
                        id={htmlInputId}
                        type={_type}
                        ref={ref}
                        placeholder={placeholder}
                        css={[inputStyles, props.inputStyling]}
                        value={value}
                        autoComplete={props.autoComplete}
                        onChange={event => setValue(event.target.value)}
                        onFocus={props.onFocus}
                        onBlur={(event: React.FocusEvent) => {
                            setHasBlurred(true);
                            props.onBlur?.(event);
                        }}
                        disabled={!!props.disabled}
                    />
                    {props.postElement && (
                        <>
                            <HorizontalDivider />
                            {props.postElement}
                        </>
                    )}
                    {working && (
                        <div css={{ marginLeft: -30 }}>
                            {working}
                            <FontAwesomeIcon icon={faSpinnerThird} spin />
                        </div>
                    )}
                    {!working && props.icon && (
                        <div css={{ marginLeft: -40 }}>
                            {(typeof props.icon !== "string" && (
                                <FontAwesomeIcon icon={props.icon} />
                            )) || (
                                <img
                                    src={props.icon as string}
                                    alt={placeholder || description || "icon"}
                                    css={{ height: 25 }}
                                />
                            )}
                        </div>
                    )}
                </FlexRow>
                {props.description &&
                    !(props.errorMessage && hasBeenBlurred) &&
                    !(props.hideDescriptionAfterInput && isDirty) && (
                        <>
                            <VerticalDivider XS />
                            <TextBox
                                padding={{ all: 5 }}
                                text={props.description}
                                color={theme.colors.black.light50}
                                size={0.8}
                            />
                        </>
                    )}
                {props.errorMessage && hasBeenBlurred && (
                    <>
                        <VerticalDivider XS />
                        <TextBox
                            padding={{ all: 5 }}
                            text={props.errorMessage}
                            color={theme.colors.danger}
                            size={0.8}
                            italic
                        />
                    </>
                )}
            </FlexColumn>
        );
    },
);

const zodEmail = z.string().email();
type EmailInputProps = {
    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly styling?: SerializedStyles;
    readonly inputStyling?: SerializedStyles;
    readonly onChange: (value: string) => void;
    readonly onValidationError?: (message: Translatable) => void;
    readonly onValidationSuccess?: () => void;
    readonly fillParentWidth?: boolean;
    readonly shrink?: number;
    readonly grow?: number;
    readonly initialValue?: string;
    readonly disabled?: boolean;
    readonly className?: string;
};
const EmailInput = (props: EmailInputProps) => {
    const [errorMessage, setErrorMessage] = useState<Translatable>("");
    const validateEmail = async (
        email: string,
    ): Promise<true | Translatable> => {
        // // just a minor verification that it looks like an email address
        // const match = /^[^@|^\s]+@[^@|^\s]+\.[^@|^\s]+$/.test(email);
        const match = zodEmail.safeParse(email);
        return match.success === true
            ? true
            : translation({
                  sv: "Inte en giltig e-postadress",
                  en: "Not a valid email address",
              });
    };

    return (
        <TextInput
            type="email"
            className={props.className}
            disabled={props.disabled}
            inputStyling={props.inputStyling}
            textInputStyling={css`
                flex-shrink: 0;
                ${props.inputStyling || ""}
            `}
            initialValue={props.initialValue}
            onValidationFailed={message => {
                const _message =
                    message ??
                    translation({
                        sv: "Validering av fält misslyckades",
                        en: "Validation of field failed",
                    });
                setErrorMessage(_message);
                props.onChange("");
                props.onValidationError?.(_message);
            }}
            onValidationSuccess={props.onValidationSuccess}
            placeholder={props.placeholder || "Email"}
            onChange={value => {
                // validation is ok
                setErrorMessage("");
                props.onChange(value);
            }}
            description={props.description}
            validateInput={validateEmail}
            autoComplete="email"
            errorMessage={errorMessage}
            shrink={props.shrink ?? 0}
            grow={props.grow}
            fillParentWidth={props.fillParentWidth}
        />
    );
};

type PasswordInputProps = {
    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly minLength?: number;
    readonly onChange: (value: string) => void;
    readonly onValidationError?: (message: Translatable) => void;
    readonly onValidationSuccess?: () => void;
};
const PasswordInput = (props: PasswordInputProps) => {
    const [errorMessage, setErrorMessage] = useState<Translatable>("");
    const validatePassword = async (
        password: Password,
    ): Promise<true | Translatable> => {
        // just a minor verification that it looks like an password address
        const minLength = props.minLength || 12;
        const toFewChars = password.length < minLength;
        if (toFewChars) {
            return translation({
                sv: `Lösenordet måste innehålla minst ${minLength} tecken`,
                en: `Password must be at least ${minLength} characters long`,
            });
        }
        const match =
            password.length >= (props.minLength || 12) &&
            /[\w~@#$%^&*+=/\\`|{}:;!.?"()[\]-]*/.test(password);
        if (!match) {
            return translation({
                sv: "Innehåller otillåtna tecken",
                en: "Contains illicit characters",
            });
        }
        return true;
    };

    return (
        <TextInput
            type="password"
            textInputStyling={css`
                flex-shrink: 0;
            `}
            onValidationFailed={message => {
                const _message =
                    message ??
                    translation({
                        sv: "Validering av fält misslyckades",
                        en: "Validation of field failed",
                    });
                setErrorMessage(_message);
                props.onChange("");
                props.onValidationError?.(_message);
            }}
            onValidationSuccess={props.onValidationSuccess}
            placeholder={props.placeholder || "Password"}
            onChange={value => {
                setErrorMessage("");
                props.onChange(value);
            }}
            description={props.description}
            errorMessage={errorMessage}
            validateInput={input => validatePassword(input as Password)}
            hideDescriptionAfterInput
            autoComplete="new-password"
        />
    );
};

type VerificationCodeInputProps = {
    readonly length: number;
    readonly description?: string;
    readonly errorMessage?: Translatable;
    readonly disabled?: boolean;
    readonly fillParentWidth?: boolean;
    readonly onChange: (value: string) => void;
};
const VerificationCodeInput = (props: VerificationCodeInputProps) => {
    const [errorMessage, setErrorMessage] = useState<Translatable>("");
    const validateVerificationCode = async (
        verificationCode: string,
    ): Promise<true | Translatable> => {
        if (verificationCode.length !== props.length) {
            return translation({
                sv: "Felaktig längd på verifikationskod",
                en: "Wrong length of verification code",
            });
        }
        return true;
    };

    const placeholder = repeatIndex(props.length)
        .map(_ => "0")
        .join("");

    return (
        <TextInput
            fillParentWidth={props.fillParentWidth}
            type="text"
            onValidationFailed={message => {
                setErrorMessage(
                    message ??
                        translation({
                            sv: "Validering av fält misslyckades",
                            en: "Validation of field failed",
                        }),
                );
                props.onChange("");
            }}
            disabled={props.disabled}
            inputStyling={css({
                letterSpacing: 5,
            })}
            description={
                props.description ||
                "Det kan ta en liten stund innan koden dyker upp, vänta gärna här"
            }
            autoComplete="one-time-code"
            errorMessage={props.errorMessage ?? errorMessage}
            placeholder={placeholder}
            onChange={value => {
                setErrorMessage("");
                props.onChange(value);
            }}
            validateInput={validateVerificationCode}
        />
    );
};

type DualNameInputProps = {
    readonly description?: string;
    readonly onChange: (values: {
        firstName: string;
        lastName: string;
    }) => void;
    readonly fillParentWidth?: boolean;
    readonly onValidationError?: (message: Translatable) => void;
    readonly onValidationSuccess?: () => void;
};
const DualNameInput = (props: DualNameInputProps) => {
    const theme = useTheme();
    const [firstNameError, setFirstNameError] = useState<Translatable>("");
    const [lastNameError, setLastNameError] = useState<Translatable>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");

    const validateFirstName = async (
        name: string,
    ): Promise<true | Translatable> => {
        return (
            (!!name?.length && name.length > 1) ||
            translation({
                sv: "Förnamnet är för kort",
                en: "Given name to short",
            })
        );
    };
    const validateLastName = async (
        name: string,
    ): Promise<true | Translatable> => {
        return (
            (!!name?.length && name.length > 1) ||
            translation({
                sv: "Efternamnet är för kort",
                en: "Family name to short",
            })
        );
    };

    useEffect(() => {
        props.onChange && props.onChange({ firstName, lastName });
    }, [firstName, lastName, props]);

    return (
        <FlexColumn
            fillParentWidth={!!props.fillParentWidth}
            shrink={0}
            crossAxis="stretch">
            <FlexColumn
                fillParentWidth={!!props.fillParentWidth}
                shrink={0}
                css={{
                    borderRadius: theme.radius,
                    border:
                        firstNameError || lastNameError
                            ? `1px solid ${theme.colors.danger.border.asString}`
                            : `1px solid ${theme.colors.white.asString}`,
                }}>
                <TextInput
                    fillParentWidth={!!props.fillParentWidth}
                    type="text"
                    onValidationFailed={message => {
                        const _message =
                            message ??
                            translation({
                                sv: "Validering av fält misslyckades",
                                en: "Validation of field failed",
                            });
                        setFirstNameError(_message);
                        setFirstName("");
                        props.onValidationError?.(_message);
                    }}
                    onValidationSuccess={props.onValidationSuccess}
                    textInputStyling={css({
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    })}
                    placeholder={translation({
                        sv: "Förnamn",
                        en: "Given name",
                    })}
                    autoComplete="given-name"
                    onChange={value => {
                        setFirstName(value ?? "");
                        setFirstNameError("");
                    }}
                    validateInput={validateFirstName}
                />
                <TextInput
                    fillParentWidth={!!props.fillParentWidth}
                    type="text"
                    onValidationFailed={message => {
                        const _message =
                            message ??
                            translation({
                                sv: "Validering av fält misslyckades",
                                en: "Validation of field failed",
                            });
                        setLastNameError(_message);
                        setLastName("");
                        props.onValidationError?.(_message);
                    }}
                    onValidationSuccess={props.onValidationSuccess}
                    textInputStyling={css({
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTop: "none",
                    })}
                    placeholder={translation({
                        sv: "Efternamn",
                        en: "Family name",
                    })}
                    autoComplete="family-name"
                    onChange={value => {
                        setLastName(value ?? "");
                        setLastNameError("");
                    }}
                    validateInput={validateLastName}
                />
            </FlexColumn>
            {firstNameError || lastNameError ? (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        padding={{ all: 5 }}
                        text={firstNameError || lastNameError}
                        color={theme.colors.danger}
                        size={0.8}
                        italic
                        shrink={0}
                    />
                </>
            ) : null}
        </FlexColumn>
    );
};

type InterestedProps = {
    readonly checked: boolean;
    readonly onChange: (value: boolean) => void;
};
const InterestedInLocal = (props: InterestedProps) => {
    const theme = useTheme();
    return (
        <>
            <FlexRow shrink={0}>
                <SelectBox
                    toggle={() => props.onChange(!props.checked)}
                    selected={props.checked}
                    shape={"SQUARE"}
                    size="L"
                />
                <TextBox
                    onClick={() => props.onChange(!props.checked)}
                    text={"Jag vill ha lokala tips och erbjudanden"}
                    size="S"
                    weight={"500"}
                    padding={{ topBottom: 10, left: 10 }}
                />
            </FlexRow>
            <FlexRow shrink={0}>
                <TextBox
                    text={
                        "Towni kommer att kunna erbjuda lokala tips och erbjudanden. Genom att sätta en bock ovan meddelar du intresse för detta. Du kan ändra valet senare."
                    }
                    size={0.8}
                    color={theme.colors.textInput.placeholder}
                />
            </FlexRow>
        </>
    );
};

type CheckBoxProps = {
    readonly text: Translatable;
    readonly subTitle?: Translatable;
    readonly textColor?: ColorItem;
    /**
     * defaults to "L"
     * @type {SizeName}
     */
    readonly checkBoxSize?: SizeName;
    /**
     * defaults to "S"
     * @type {SizeName}
     */
    readonly fontSize?: SizeName;
    /**
     * defaults to "500"
     * @type {FontWeight}
     */
    readonly fontWeight?: FontWeight;
    /**
     * defaults to 1
     * @type {FontWeight}
     */
    readonly lineClamp?: number;
    readonly isLoading?: boolean;
    readonly disabled?: boolean;
    readonly className?: string;
    readonly boxBorderColor?: ColorItem;
    readonly boxBackgroundColor?: ColorItem;
    readonly shape?: "SQUARE" | "CIRCLE";
} & InterestedProps;

const CheckBoxWithText = (props: CheckBoxProps) => {
    const theme = useTheme();
    const toggle = () =>
        !props.isLoading && !props.disabled && props.onChange(!props.checked);

    const textColor =
        props.textColor ?? theme.colors.default.text.value.withAlpha(0.7);
    const subTitleColor = theme.colors.default.text.value.withAlpha(0.5);
    return (
        <>
            <FlexRow
                onClick={toggle}
                padding={{ topBottom: 5 }}
                className={props.className}>
                <SelectBox
                    selected={props.checked}
                    shape={props.shape ?? "SQUARE"}
                    size={props.checkBoxSize ?? "L"}
                    spin={props.isLoading}
                    disabled={props.disabled}
                    borderColor={props.boxBorderColor}
                    backgroundColor={props.boxBackgroundColor}
                />
                <FlexColumn>
                    <TextBox
                        text={props.text}
                        size={props.fontSize ?? "S"}
                        weight={props.fontWeight ?? "500"}
                        color={textColor}
                        padding={{ topBottom: 0, left: 10 }}
                        lineClamp={props.lineClamp ?? 1}
                        css={{
                            overflow: "hidden",
                            opacity: props.disabled ? 0.5 : 1,
                        }}
                    />
                    <Conditional when={!!props.subTitle}>
                        <TextBox
                            text={props.subTitle}
                            size={"XS"}
                            color={subTitleColor}
                            padding={{ topBottom: 0, left: 10 }}
                            lineClamp={1}
                            css={{
                                overflow: "hidden",
                                opacity: props.disabled ? 0.5 : 1,
                            }}
                        />
                    </Conditional>
                </FlexColumn>
            </FlexRow>
        </>
    );
};

export {
    CheckBoxWithText,
    DualNameInput,
    EmailInput,
    InterestedInLocal,
    PasswordInput,
    TextInput,
    VerificationCodeInput,
};
