import { currencies, currency, CurrencyCode } from "@towni/common";
import * as React from "react";

/**
 * Selected currency and a list of all available currencies for the app
 * todo: move to non static list
 */
type State = {
    readonly currency: CurrencyCode;
    readonly availableCurrencies: CurrencyCode[];
};

const CurrencySettingContext = React.createContext<State | undefined>(
    undefined
);

type Actions = {
    setCurrency: (currency: CurrencyCode) => void;
};

const CurrencySettingActionContext = React.createContext<Actions | undefined>(
    undefined
);

const CurrencySettingProvider = (props: {
    readonly initOptions?: {
        readonly currency?: CurrencyCode;
        readonly availableCurrencies?: CurrencyCode[];
    };
    readonly children?: React.ReactNode;
}) => {
    const [state, setState] = React.useState<State>({
        currency: props.initOptions?.currency ?? currency.SEK,
        availableCurrencies: props.initOptions?.availableCurrencies ?? [
            ...currencies,
        ],
    });

    const actions: Actions = React.useMemo(() => {
        return {
            setCurrency: (locale: CurrencyCode) => {
                setState(old => ({
                    ...old,
                    currency: locale,
                }));
            },
        };
    }, [setState]);

    return (
        <CurrencySettingContext.Provider value={state}>
            <CurrencySettingActionContext.Provider value={actions}>
                {props.children}
            </CurrencySettingActionContext.Provider>
        </CurrencySettingContext.Provider>
    );
};

const useCurrencySettingState = () => {
    const context = React.useContext(CurrencySettingContext);
    if (context === undefined) {
        throw new Error(
            "useCurrencySettingState must be used within a CurrencySettingContext"
        );
    }
    return context;
};

const useCurrencySettingActions = () => {
    const context = React.useContext(CurrencySettingActionContext);
    if (context === undefined) {
        throw new Error(
            "useCurrencySettingActions must be used within a CurrencySettingActionContext"
        );
    }
    return context;
};

export {
    CurrencySettingProvider,
    useCurrencySettingState,
    useCurrencySettingActions,
};
