import {
    useScrollPosition,
    useScrollPositionStore,
} from "@@/pages/scroll-position-context";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { AutoSizerInFlex } from "@@/shared/flexed-auto-resizer";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { TextBox } from "@@/shared/text/text-box";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { WidthAndHeight } from "@@/shared/width-and-height";
import { useTheme } from "@emotion/react";
import { Branded, generateComboId, translation } from "@towni/common";
import * as React from "react";
import { List, ListRowProps, ScrollParams } from "react-virtualized";

type VirtualRowId = Branded<string, "VirtualRowId">;
type VirtualRow<RowType, RowData extends { _type: RowType }> = {
    readonly _id: VirtualRowId;
    readonly data: RowData;
};
type VirtualRowHeightChange = (size: WidthAndHeight) => void;
type VirtualRowRenderer<RowType, RowData extends { _type: RowType }> = (data: {
    readonly item: VirtualRow<RowType, RowData>;
    readonly isLast: boolean;
    readonly isFirst: boolean;
    readonly index: number;
    readonly onHeightChange: VirtualRowHeightChange;
}) => JSX.Element;

type Props<RowType, RowData extends { _type: RowType }> = {
    readonly listId: string;
    readonly items: VirtualRow<RowType, RowData>[];
    readonly rowRenderer: VirtualRowRenderer<RowType, RowData>;
    readonly spin?: boolean;
    readonly forgetScrollPosition?: boolean;
    /**
     * Defaults to FlexRow with TextShimmer with 2 rows
     */
    readonly spinner?: JSX.Element;
    /**
     * What to render when no content \
     * Defaults to FlexRow with title "(tomt)"
     */
    readonly whenNoContent?: JSX.Element;
    listRef?: React.MutableRefObject<List | null>;
    /**
     * What height to use if none is reported
     * if 0 can be problem when content is loaded later (search-result)
     * Defaults to 0
     */

    readonly defaultRowHeight?: number;
};

const VirtualList = <RowType, RowData extends { _type: RowType }>(
    props: Props<RowType, RowData>,
) => {
    const { listId, items, rowRenderer, spin, forgetScrollPosition } = props;
    const theme = useTheme();
    const rowHeights = React.useRef(new Map<VirtualRowId, number>());
    const lastScrollPosition = useScrollPosition(listId);
    const listContainer = React.useRef<HTMLDivElement>(null);
    const hasRenderedOnce = React.useRef(false);
    const listRef = React.useRef<List | null>(null);

    const scrollPositionRecorder = useScrollPositionStore(
        state => state.recordScrollPosition,
    );
    const renderRow = (params: ListRowProps) => {
        const { index, style, parent } = params;

        const _parent = parent;
        const item = items[index];
        const isFirst = index === 0;
        const isLast = index === items.length - 1;

        const content = rowRenderer({
            index,
            isFirst,
            isLast,
            item,
            onHeightChange: (size: WidthAndHeight) => {
                const old = rowHeights.current.get(item._id);
                rowHeights.current.set(item._id, size.height);

                if (_parent.recomputeGridSize && old !== size.height) {
                    _parent.recomputeGridSize({
                        columnIndex: 0,
                        rowIndex: index,
                    });
                }
            },
        });

        return (
            <div key={content.key} style={{ ...style }}>
                {content}
            </div>
        );
    };

    const [uglyListKey, setUglyListKey] = React.useState<string | undefined>(
        generateComboId(),
    );
    useMountEffect(() => {
        setUglyListKey(generateComboId());
    });

    const listRefSetter = React.useCallback(
        (item: List | null) => {
            listRef.current = item;
            if (props.listRef) {
                props.listRef.current = item;
            }
        },
        [props.listRef],
    );

    return (
        <FlexColumn
            ref={listContainer}
            tag={"VIRTUAL_CONTAINER"}
            css={{ flex: 1 }}
            overflow="hidden"
            mainAxis="space-between"
            crossAxis="stretch">
            {spin ? (
                props.spinner ?? (
                    <FlexRow
                        fillParentWidth
                        padding={{ leftRight: 20 }}
                        mainAxis="flex-start">
                        <TextShimmer rows={2} />
                    </FlexRow>
                )
            ) : (
                <AutoSizerInFlex>
                    {({ width, height }) => {
                        return (
                            <List
                                key={uglyListKey}
                                width={width}
                                height={height}
                                ref={listRefSetter}
                                overscanRowCount={5}
                                rowCount={items.length}
                                noContentRenderer={() => {
                                    return (
                                        props.whenNoContent ?? (
                                            <FlexRow
                                                fillParent
                                                padding={{ all: 40 }}
                                                mainAxis="center"
                                                crossAxis="flex-start">
                                                <TextBox
                                                    text={translation({
                                                        sv: "(tomt)",
                                                        en: "(empty)",
                                                    })}
                                                    spin={props.spin}
                                                    color={
                                                        theme.colors.black
                                                            .light70
                                                    }
                                                />
                                            </FlexRow>
                                        )
                                    );
                                }}
                                rowHeight={({ index }) => {
                                    const height =
                                        rowHeights.current.get(
                                            items[index]._id,
                                        ) ??
                                        props.defaultRowHeight ??
                                        80;

                                    return height;
                                }}
                                onRowsRendered={_ => {
                                    if (!hasRenderedOnce.current) {
                                        hasRenderedOnce.current = true;
                                        if (lastScrollPosition?.scrollTop) {
                                            listRef?.current?.scrollToPosition(
                                                lastScrollPosition.scrollTop,
                                            );
                                        }
                                    }
                                }}
                                rowRenderer={renderRow}
                                onScroll={({
                                    scrollTop,
                                    scrollLeft,
                                }: ScrollParams) => {
                                    if (forgetScrollPosition) return;
                                    if (!hasRenderedOnce.current) return;
                                    scrollPositionRecorder({
                                        id: listId,
                                        position: {
                                            scrollLeft,
                                            scrollTop,
                                        },
                                    });
                                }}
                            />
                        );
                    }}
                </AutoSizerInFlex>
            )}
        </FlexColumn>
    );
};

export { VirtualList };
export type {
    VirtualRow,
    VirtualRowHeightChange,
    VirtualRowId,
    VirtualRowRenderer,
};
