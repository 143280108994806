import { useApiResources } from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import { AcquireType, Product, ProviderId } from "@towni/common";
import { productMainQueryKey } from "../main-query-key";
import { fetchProductsForProvider } from "./products-for-provider.fetcher";

const useProductsForProvider = (
    providerId?: ProviderId,
    type?: AcquireType,
    disabled = false,
): readonly [Product[], UseQueryResult<Product[]>] => {
    return useApiResources(
        [productMainQueryKey, providerId],
        fetchProductsForProvider(providerId, type),
        { enabled: !!providerId || disabled },
    );
};

export { useProductsForProvider };
