const isProbablyIOS =
    /(iPad|iPhone|iPod)/g.test(navigator.userAgent) ||
    (navigator.userAgent.includes("Mac") &&
        "ontouchend" in document &&
        navigator.maxTouchPoints > 2 &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !(window as any).MSStream);

const isProbablyMacOS = navigator.userAgent.includes("Macintosh");
const isProbablyAppleDevice = isProbablyIOS || isProbablyMacOS;

const isProbablySafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1;

export {
    isProbablyAppleDevice,
    isProbablyIOS,
    isProbablyMacOS, isProbablySafari
};

