// import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { PhoneNumber } from "@towni/common";
import * as React from "react";
import { StoreApi, createStore, useStore } from "zustand";

type ContextState = State & Actions;
type State = {
    firstName: string;
    lastName: string;
    email: string;
    phone: PhoneNumber | undefined;
};
type Actions = {
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setEmail: (email: string) => void;
    setPhone: (phone: PhoneNumber | undefined) => void;
};

const ContactInfoContext = React.createContext<
    StoreApi<ContextState> | undefined
>(undefined);

type Props = {
    children: React.ReactNode;
};
const ContactInfoContextProvider = (props: Props) => {
    const store = React.useRef<StoreApi<ContextState>>(
        createStore<ContextState>((set, _get) => ({
            firstName: "",
            lastName: "",
            email: "",
            phone: undefined,
            setFirstName: firstName => set({ firstName }),
            setLastName: lastName => set({ lastName }),
            setEmail: email => set({ email }),
            setPhone: phone => set({ phone }),
        })),
    );

    return (
        <ContactInfoContext.Provider value={store.current}>
            {props.children}
        </ContactInfoContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useContactInfoContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = React.useContext(ContactInfoContext);
    if (store === undefined) {
        throw new Error(
            "useContactInfoContext must be used within a ContactInfoContextProvider",
        );
    }
    return useStore(store, selector);
};

export { ContactInfoContextProvider, useContactInfoContext };
