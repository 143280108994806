import { useRef } from "react";
import { useMountEffect } from "./use-mount-effect";

const useIsMountedRef = (): { current: boolean } => {
    const componentIsMounted = useRef(true);
    useMountEffect(() => () => {
        componentIsMounted.current = false;
    });
    return componentIsMounted;
};

export { useIsMountedRef };
