import { useProduct } from "@@/products/state/queries/product/use-product";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ImageShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTheme } from "@emotion/react";
import {
    ColorSet,
    ProductId,
    RemSize,
    Translatable,
    isAlfredItemPaused,
    translation,
} from "@towni/common";
import * as React from "react";
import { Link } from "react-router-dom";

// type Props = {};
const LastMileCarSectionAlt = (props: {
    productId: ProductId;
    title?: Translatable;
    subtitle?: Translatable;
    actionText?: Translatable;
    kicker?: Translatable;
    colorSet?: ColorSet;
    className?: string;
}) => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    const getProductId = useProductIdWithFallback();
    const productId = React.useMemo(() => getProductId(props.productId), []);
    const [product, productQuery] = useProduct(productId);

    // Hide card if product is paused
    const isPaused = isAlfredItemPaused(product?.status);
    if (isPaused) return null;

    const productImageRef = product?.meta.images[0];
    return (
        <>
            <TextBox
                text="95 kr för egen transport!"
                size={1.5 as RemSize}
                padding={{ leftRight: 20 }}
                weight="700"
            />
            <VerticalDivider XS />
            <TextBox
                text="Behöver du komma fram till cyklarna? Eller ska du en liten längre bit? I sommar finns en helt ny form av transport för att kunna uppleva mer."
                color={theme.colors.black.light30}
                padding={{ leftRight: 20 }}
                size={1.125 as RemSize}
            />
            <SectionArea
                sectionAreaId={"one-square-section_" + productId}
                className={props.className}
                padding={{ top: 10 }}>
                <Link to={`/products/${productId}`}>
                    <FlexColumn
                        fillParentWidth
                        padding={{ leftRight: 20 }}
                        crossAxis="flex-start"
                        shrink={0}>
                        {productQuery.isPending ? (
                            <ImageShimmer radius={16} maxHeight="335px" />
                        ) : (
                            <SectionAreaCard
                                aspectRatio={isDesktop ? "3:2" : "1:1"}
                                radius={16}
                                background={{
                                    fit: "COVER",
                                    imageReference: productImageRef,
                                    imageSize: {
                                        imageWidth: 500,
                                    },
                                }}>
                                <FlexColumn
                                    shrink={0}
                                    grow={1}
                                    padding={{ all: 20 }}
                                    background={{
                                        linearGradient: darkeningGradient,
                                    }}
                                    mainAxis="space-between"
                                    crossAxis="center">
                                    <FlexColumn fillParentWidth shrink={0}>
                                        <Conditional
                                            when={
                                                !!props.kicker ||
                                                !!product?.meta.kicker
                                            }
                                            render={() => (
                                                <>
                                                    <VerticalDivider S />
                                                    <TextBox
                                                        text={
                                                            props.kicker ||
                                                            product?.meta.kicker
                                                        }
                                                        weight="800"
                                                        case="UPPERCASE"
                                                        size={0.75 as RemSize}
                                                        color={
                                                            theme.colors.white
                                                        }
                                                        css={{
                                                            textShadow: `0 0 4px ${theme.colors.black.light25}`,
                                                        }}
                                                    />
                                                    <VerticalDivider XS />
                                                </>
                                            )}
                                        />
                                        <TextBox
                                            text={
                                                props.title ??
                                                product?.meta.title
                                            }
                                            weight="900"
                                            size={2 as RemSize}
                                            color={theme.colors.white}
                                            css={{
                                                textShadow: `0 4px 4px ${theme.colors.black.light25}`,
                                            }}
                                        />
                                        <VerticalDivider />
                                        <TextBox
                                            text={
                                                props.subtitle ??
                                                product?.meta.subtitle
                                            }
                                            weight="500"
                                            size={1 as RemSize}
                                            color={theme.colors.white}
                                            css={{
                                                textShadow: `0 4px 4px ${theme.colors.black.light25}`,
                                            }}
                                        />
                                    </FlexColumn>
                                    <FlexRow
                                        fillParentWidth
                                        mainAxis="flex-end">
                                        <StoreFrontCardPill
                                            text={
                                                props.actionText ??
                                                translation({
                                                    sv: "Läs mer",
                                                    en: "Read more",
                                                })
                                            }
                                            colorSet={props.colorSet}
                                        />
                                    </FlexRow>
                                </FlexColumn>
                            </SectionAreaCard>
                        )}
                    </FlexColumn>
                </Link>
            </SectionArea>
        </>
    );
};

export { LastMileCarSectionAlt };
