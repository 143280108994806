import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faSadCry } from "@fortawesome/pro-light-svg-icons";
import { RemSize, translation } from "@towni/common";

const BookableTimePickerNothingAvailable = () => {
    const theme = useTheme();
    return (
        <FlexColumn fillParent mainAxis="center" crossAxis="center">
            <FlexRow fillParentWidth mainAxis="center" crossAxis="center">
                <Icon
                    icon={faSadCry}
                    size={5 as RemSize}
                    color={theme.colors.black.light40}
                />
            </FlexRow>
            <VerticalDivider />
            <TextBox
                text={translation({
                    sv: "Inga tillgängliga tider",
                    en: "Nothing available",
                })}
                align="center"
                size={1.2}
                color={theme.colors.black.light40}
                padding={{ all: 40, bottom: 60 }}
            />
        </FlexColumn>
    );
};

export { BookableTimePickerNothingAvailable };
