import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { useOrderSocketEventsForMe } from "@@/backoffice/for-providers/orders/use-orders-events-for-me";
import { useOrderGroup } from "@@/orders/order-groups/use-order-group";
import { useOrdersForOrderGroup } from "@@/orders/use-orders-for-order-group";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { useNavigateTo } from "@@/shared/text/navigate-to";
import { useKeyboardShortcut } from "@@/shared/use-keyboard-shortcut";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { ColorThemeProvider } from "@@/styles/app-theme-provider";
import { OrderGroupAccessRequired } from "@@/users/access-required";
import { useUserReference } from "@@/users/state/user-reference-fetchers";
import { css, Global, useTheme } from "@emotion/react";
import {
    isVerifiedByAccessKey,
    OrderGroupId,
    orderGroupIdZodSchema,
    translation,
} from "@towni/common";
import { useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { _ReceiptGreeting } from "./_receipt-greeting";
import { _ReceiptOrderInformation } from "./_receipt-order-information";
import { _ReceiptToolbar } from "./_receipt-toolbar";
import { OrderStatusCustomerBanner } from "./order-status-customer-banner";
import { useVerifyStripePayment } from "./shared/use-verify-stripe-payments";
import { useAccessKeyIdFromUrl } from "./use-access-key-id-from-url";

const ReceiptPage = () => {
    const theme = useTheme();
    const isAlfred = useHasAlfredScope();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const paramOrderGroupId = useParams<{ orderGroupId: OrderGroupId }>()
        .orderGroupId;
    const orderGroupId = orderGroupIdZodSchema.parse(paramOrderGroupId);
    const accessKeyId = useAccessKeyIdFromUrl();
    useVerifyStripePayment(orderGroupId, accessKeyId);

    const [searchParams] = useSearchParams();
    const waitForIt = searchParams.get("waitforit") === "true";
    const navigate = useNavigateTo();

    // Load data
    const [orderGroup, orderGroupQuery] = useOrderGroup(
        orderGroupId,
        accessKeyId,
    );
    const [orders, orderQuery] = useOrdersForOrderGroup(
        orderGroupId,
        accessKeyId,
    );
    const [customer, customerQuery] = useUserReference(orderGroup?.customerId);
    const [provider] = useProvider(orders[0]?.providerId);
    const { branding, setProviderId } = useBrandingContext();
    useEffect(() => {
        setProviderId(provider?._id);
    });

    useKeyboardShortcut({
        keys: ["v", "p"], // vad ska vi ha?
        modifiers: {
            ctrl: true,
        },
        callback: () => {
            const orderId = orders?.[0]._id;
            if (!isAlfred || !orderId || !provider) return;
            navigate(`/bo/providers/${provider.slug}/orders/${orderId}`);
        },
    });
    useKeyboardShortcut({
        keys: ["v", "o"], // vad ska vi ha?
        modifiers: {
            ctrl: true,
        },
        callback: () => {
            const orderId = orders?.[0]._id;
            if (!isAlfred || !orderId || !provider) return;
            window.open(
                `/bo/providers/${provider.slug}/orders/${orderId}`,
                "_blank",
            );
        },
    });

    // Refetch order data on server notifications
    useOrderSocketEventsForMe({
        onOrderEvent: (event, _room) => {
            if (event.data.orderGroupId === orderGroupId) {
                orderQuery.refetch();
            }
        },
    });

    const paymentIsPending = orders.some(
        order =>
            order.status.type === "PENDING_PAYMENT_CONFIRMATION" ||
            order.status.type === "CREATED",
    );

    const printRef = useRef<HTMLDivElement | null>(null);
    const backgroundCss = useBrandingBackgroundCss(
        branding?.receipt.background,
    );
    const peripheralBackgroundCss = useBrandingBackgroundCss(
        branding?.receipt.peripheralBackground,
    );

    return (
        <OrderGroupAccessRequired orderGroupId={orderGroupId}>
            <ColorThemeProvider colorTheme={branding?.colorThemes.light}>
                <HeadTitle
                    title={[
                        translation({
                            sv: "Ordersammanställning",
                            en: "Order summary",
                        }),
                        orderGroup?.reference,
                    ]}
                />
                <Conditional
                    when={paymentIsPending && waitForIt}
                    render={() => (
                        <Global
                            styles={css({
                                body: {
                                    filter: "grayscale(100%)",
                                },
                            })}
                        />
                    )}
                />
                <WideScreenWrapper css={peripheralBackgroundCss}>
                    <FlexColumn
                        ref={printRef}
                        crossAxis="center"
                        fillParent
                        overflowY="scroll"
                        css={[
                            backgroundCss,
                            {
                                label: "receipt_page_container",
                                "@media print": {
                                    background: (
                                        branding?.colorThemes.light ||
                                        theme.colors
                                    ).white.asString,
                                },
                            },
                        ]}>
                        <_ReceiptToolbar />
                        <VerticalDivider
                            size={isDesktop ? "XXL" : "XXS"}
                            css={{
                                "@media print": {
                                    display: "none",
                                },
                            }}
                        />
                        <Conditional
                            when={
                                orderGroup && !isVerifiedByAccessKey(orderGroup)
                            }>
                            <_ReceiptGreeting
                                customerId={orderGroup?.customerId}
                                order={orders?.[0]}
                                paymentIsPending={paymentIsPending && waitForIt}
                            />
                            <VerticalDivider XL />
                        </Conditional>
                        <Conditional
                            when={
                                orders?.[0]?.amountUnpaid > 0 &&
                                !paymentIsPending
                            }>
                            <OrderStatusCustomerBanner
                                order={orders?.[0]}
                                css={{
                                    opacity: orders?.[0]?.inactive ? 0.5 : 1,
                                }}
                            />
                            <VerticalDivider XL />
                        </Conditional>
                        <_ReceiptOrderInformation
                            orderGroup={orderGroup}
                            orders={orders}
                            customer={customer}
                            printRef={printRef}
                            spin={
                                customerQuery.isPending ||
                                orderQuery.isPending ||
                                orderGroupQuery.isPending ||
                                !customer ||
                                !orderGroup
                            }
                        />
                        <VerticalDivider L />
                        <VerticalDivider XXL />
                    </FlexColumn>
                </WideScreenWrapper>
            </ColorThemeProvider>
        </OrderGroupAccessRequired>
    );
};

export { ReceiptPage };
