import { calculateAvgNightlyPrice } from "@@/carts/bookable-checkout/accommodation-cost";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";

import { formatAccommodationTimeRange } from "@@/products/accommodations/modals/accommodation-date-picker-modal";
import { useAddAccommodationToCart } from "@@/products/accommodations/use-add-accommodation-to-cart";
import { browserLogger } from "@@/settings";
import { darkeningGradientDualHeavierTopSide_80 } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { Paragraph, TextBox, paragraphPriceFactory } from "@@/shared/text";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useTheme } from "@emotion/react";
import {
    AccommodationInventory,
    CalculatedRatePrice,
    Product,
    Resource,
    ResourceAvailability,
    ResourceGraph,
    ResourceImageReference,
    TimeRange,
    colorFactory,
    getPossibilities,
    isResourceAccommodation,
    isResourceGraph,
    isResourceGroup,
    isResourcesAccommodationsWithinCapacity,
    isTimeRange,
    makeResourceGraph,
    sum,
    translation,
    uniqueBy,
    zeroPrice,
} from "@towni/common";
import { useMemo } from "react";

type Props = {
    readonly product: Product;
    readonly resource: Resource;
    readonly allResources: Resource[];
    readonly calculatedRates: CalculatedRatePrice[];
    readonly range: TimeRange | undefined;
    readonly availability: ResourceAvailability[];
    readonly inventory: AccommodationInventory;
};

const getBestImagesFromGraph = (
    graph: ResourceGraph,
): ResourceImageReference[] => {
    const stack = [graph];

    while (stack.length > 0) {
        const currentGraph = stack.pop();

        if (!currentGraph) continue;

        const rgImages = currentGraph.resourceGroup.imageRefs ?? [];
        if (rgImages.length > 0) {
            return rgImages;
        }

        for (const resource of currentGraph.children) {
            if (isResourceGraph(resource)) {
                stack.push(resource);
            }
            if (
                isResourceAccommodation(resource) &&
                resource.imageRefs &&
                resource.imageRefs.length > 0
            ) {
                return resource.imageRefs ?? [];
            }
        }
    }

    // If no images are found after iterating through the entire graph
    return [];
};

const _cardColor = colorFactory({
    hue: 120,
    saturation: 0.24,
    light: 0.89,
});

const useResourceImage = (resource: Resource, resources: Resource[]) => {
    const images = isResourceGroup(resource)
        ? getBestImagesFromGraph(makeResourceGraph(resource, resources))
        : isResourceAccommodation(resource)
          ? resource.imageRefs ?? []
          : [];

    //Todo handle multiple images
    const [image] = useStorageItemImageReference(
        images.length > 0 ? images[0].imageId : undefined,
    );

    return image;
};

const AccommodationCard = (props: Props) => {
    const theme = useTheme();
    const {
        quantities,
        time: _time,
        requestedQuantity,
    } = useBookingAccommodationContext(state => ({
        quantities: state.quantities,
        time: state.timeRange,
        requestedQuantity: state.requestedResourceQuantity,
    }));

    const [addToCart, currentPrice] = useAddAccommodationToCart(props.product);

    const title = props.resource.meta.title;
    const kicker = props.resource.meta.kicker;

    const matchingRates = props.calculatedRates.filter(r =>
        r.rateWithPrices.appliesTo.includes(props.resource._id),
    );

    const imageUrl = useResourceImage(props.resource, props.allResources);

    const availableResources = uniqueBy(
        props.availability.flatMap(item => item.availableSubResources),
        r => r._id,
    );

    // const resourceChildren = props.aav.filter(
    //     r =>
    //         isResourceGroup(props.resource) &&
    //         props.resource.resources.some(rr => rr._id === r._id),
    // );

    // console.log("🐙 RES ALL", props.allResources);
    // console.log("🐙 RES AVAILABLE", availableResources);
    // console.log(
    //     "🐙 RES AVAILABLE ACC",
    //     availableResources.filter(isResourceAccommodation),
    // );
    /** Resources checked for capacity match, contains error message if no match */
    const resourcesWithCapacityResult = isResourcesAccommodationsWithinCapacity(
        availableResources.filter(isResourceAccommodation),
        requestedQuantity,
        quantities,
    );

    const possibilities = getPossibilities({
        availableResources: availableResources.filter(isResourceAccommodation),
        numberOfResources: requestedQuantity,
        requestedQuantities: quantities,
    });
    browserLogger.log("POSSIBILITIES", possibilities);

    // console.log("🐙 RES WITH CAPACITY", resourcesWithCapacityResult);
    /** Resource with the searched for capacity */
    const resourcesWithCapacity = resourcesWithCapacityResult
        .filter(r => !r.error)
        .map(r => r.resource);

    /** Number of resources available in total */
    const availableResourceCount = sum(
        props.availability.map(item => item.availableSubResources.length),
    );

    /** Number of resources available matching requested capacity */
    const availableResourcesWithCapacityCount = sum(
        props.availability.map(
            item =>
                item.availableSubResources.filter(sub =>
                    resourcesWithCapacity.some(rwc => rwc._id === sub._id),
                ).length,
        ),
    );

    /** Are their resources with the requested capacity at all? */
    const hasCapacity = !!resourcesWithCapacity.length;

    /** Are their resources with the requested quantity available? */
    const hasQuantity = requestedQuantity <= availableResourceCount;

    /** Are their resources with the requested capacity and quantity available? */
    const hasQuantityWithCapacity =
        requestedQuantity <= availableResourcesWithCapacityCount;

    const avgPrice = useMemo(() => {
        if (!matchingRates.length) return undefined;
        if (!props.range) return zeroPrice;

        const resourceAccommodationWithEnforcedCapacityHash = Array.from(
            possibilities.resourcesWithHashMapped.values(),
        );

        const current =
            isResourceGroup(props.resource) && isTimeRange(_time)
                ? currentPrice({
                      time: _time,
                      resource: props.resource,
                      rates: matchingRates,
                      inventory: props.inventory,
                      quantity: requestedQuantity,
                      resourceAccommodationWithEnforcedCapacityHash:
                          resourceAccommodationWithEnforcedCapacityHash,
                  })
                : undefined;

        if (!current) {
            return undefined;
        }

        //  const ratesToUse = matchingRates;
        //  const price = findMinPrice(ratesToUse, p => p.price, false);
        const [avgPrice, _night] = calculateAvgNightlyPrice(
            current,
            props.range,
        );

        return avgPrice;
    }, [
        _time,
        currentPrice,
        matchingRates,
        possibilities,
        props.inventory,
        props.range,
        props.resource,
        requestedQuantity,
    ]);

    /** Can the requested be picked for booking? */
    const canBePicked =
        hasQuantityWithCapacity &&
        !!avgPrice &&
        availableResourcesWithCapacityCount >= requestedQuantity;

    browserLogger.log("canBePicked", {
        canBePicked,
        hasCapacity,
        hasQuantity,
        hasQuantityWithCapacity,
        avgPrice,
        availableQuantity: availableResourcesWithCapacityCount,
        availability: props.availability,
        requestedQuantity,
        resourcesWithCapacity,
        availableResourceCount,
        availableResourcesWithCapacityCount,
        resourcesWithCapacityResult,
    });

    const hasBackgroundImage = !!imageUrl;
    const textColor = hasBackgroundImage
        ? theme.colors.white
        : theme.colors.black;
    const dimmedTextColor = hasBackgroundImage
        ? theme.colors.white.light70
        : theme.colors.black.light30;

    const textShadow = hasBackgroundImage
        ? "0 2px 5px rgba(0, 0, 0, 0.4)"
        : undefined;

    // const canIncrease = requestedQuantity < availableQuantity;
    // const canDecrease = requestedQuantity > 1;
    // const quantityCanBeChanged = canIncrease || canDecrease;
    // const increaseRequestedQuantity = () => {
    //     if (!canIncrease) return;
    //     setRequestedQuantity(current =>
    //         setWithinRange(current + 1, {
    //             min: 1,
    //             max: availableQuantity > 1 ? availableQuantity : 1,
    //         }),
    //     );
    // };
    // const decreaseRequestedQuantity = () => {
    //     if (!canDecrease) return;
    //     setRequestedQuantity(current =>
    //         setWithinRange(current - 1, {
    //             min: 1,
    //             max: availableQuantity > 1 ? availableQuantity : 1,
    //         }),
    //     );
    // };

    const onBookClick = canBePicked
        ? () => {
              if (!hasQuantityWithCapacity) return;
              if (isResourceGroup(props.resource)) {
                  const resourceAccommodationWithEnforcedCapacityHash =
                      Array.from(
                          possibilities.resourcesWithHashMapped.values(),
                      );

                  //   props.resource,
                  //   matchingRates,
                  //   requestedQuantity,
                  //   resourcesWithCapacity,
                  if (!isTimeRange(_time)) return;

                  addToCart({
                      time: _time,
                      resource: props.resource,
                      rates: matchingRates,
                      inventory: props.inventory,
                      quantity: requestedQuantity,
                      resourceAccommodationWithEnforcedCapacityHash:
                          resourceAccommodationWithEnforcedCapacityHash,
                  });
              }
          }
        : undefined;

    return (
        <SectionAreaCard
            aspectRatio={"4:3"}
            radius={10}
            onClick={onBookClick}
            background={{
                color: _cardColor, //theme.colors.primary.main.value.withAlpha(0.6), //theme.colors.primary,
                fit: "COVER",
                repeat: "NO-REPEAT",
                imageReference: imageUrl,
            }}
            css={{
                borderRadius: 10,
                overflow: "hidden",
                opacity: 1,
            }}>
            <FlexColumn
                fillParent
                tag="SECTION_AREA_CARD"
                background={
                    imageUrl
                        ? {
                              linearGradient:
                                  darkeningGradientDualHeavierTopSide_80,
                          }
                        : undefined
                }
                mainAxis="space-between"
                crossAxis="stretch"
                css={{
                    flexShrink: 0,
                    flexGrow: 1,
                    padding: 20,
                }}>
                {/* Card title and kicker */}
                <FlexColumn
                    crossAxis="flex-start"
                    css={{
                        flexShrink: 0,
                    }}>
                    <TextBox
                        text={title}
                        weight="900"
                        size={"L"}
                        color={textColor}
                        css={{ textShadow }}
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text={kicker}
                        weight="800"
                        size={1}
                        color={textColor}
                        css={{ textShadow }}
                    />
                </FlexColumn>
                <FlexColumn
                    mainAxis="flex-end"
                    crossAxis="flex-end"
                    css={{ flex: 1 }}>
                    {/* Availability and price info */}
                    <FlexRow
                        fillParentWidth
                        mainAxis="flex-end"
                        crossAxis="stretch">
                        {/* Availability information text */}
                        <FlexColumn mainAxis="center" crossAxis="flex-end">
                            <TextBox
                                color={textColor}
                                weight="600"
                                align="right"
                                text={(() => {
                                    if (!props.range)
                                        return translation({
                                            sv: "Välj datum",
                                            en: "Select date",
                                        });
                                    if (canBePicked)
                                        return formatAccommodationTimeRange({
                                            time: props.range,
                                            currentTimezoneName:
                                                "Europe/Stockholm",
                                        });
                                    if (hasQuantity && !hasQuantityWithCapacity)
                                        return translation({
                                            sv: `Ingen matchning för valt antal gäster`,
                                            en: `Not available for selected number of guests`,
                                        });
                                    if (
                                        requestedQuantity === 1 &&
                                        availableResourcesWithCapacityCount ===
                                            0
                                    )
                                        return translation({
                                            sv: "Inget ledigt på valt datum",
                                            en: "No availability on selected date",
                                        });

                                    return translation({
                                        sv: `Går inte att boka ${requestedQuantity} st`,
                                        en: `Cannot book ${requestedQuantity} accommodations`,
                                    });
                                })()}
                            />
                            {/* Minimal price per night */}
                            <Paragraph
                                css={{
                                    fontWeight: 600,
                                    color: dimmedTextColor.asString,
                                }}
                                content={
                                    avgPrice
                                        ? [
                                              //   paragraphTextFactory({
                                              //       text: translation({
                                              //           sv: `${requestedQuantity} st =`,
                                              //           en: `${requestedQuantity} pcs =`,
                                              //       }),
                                              //   }),
                                              //   paragraphHzSpaceFactory(),
                                              paragraphPriceFactory({
                                                  price: avgPrice,
                                              }),
                                              " / ",
                                              translation({
                                                  sv: "natt",
                                                  en: "night",
                                              }),
                                              //   ...(requestedQuantity > 1
                                              //       ? [
                                              //             translation({
                                              //                 sv: ", för ",
                                              //                 en: ", for ",
                                              //             }),
                                              //             translation({
                                              //                 sv: `${requestedQuantity} st`,
                                              //                 en: `${requestedQuantity} pcs`,
                                              //             }),
                                              //         ]
                                              //       : []),
                                          ]
                                        : ["-"]
                                }
                            />
                        </FlexColumn>
                        <HorizontalDivider M />
                        {/* Quantity picker */}
                        {/* <Conditional when={quantityCanBeChanged}>
                            <FlexRow
                                background={{
                                    color: hasBackgroundImage
                                        ? theme.colors.black.withAlpha(0.2)
                                        : theme.colors.transparent,
                                }}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                css={{
                                    borderRadius: theme.radius,
                                    border: `1px solid ${textColor.asString}`,
                                    backdropFilter: "blur(5px)",
                                }}>
                                <ButtonTransparent
                                    disabled={!canDecrease}
                                    onClick={decreaseRequestedQuantity}>
                                    <Icon
                                        icon={faMinus}
                                        size="S"
                                        color={
                                            canDecrease
                                                ? textColor
                                                : textColor.withAlpha(0.3)
                                        }
                                    />
                                </ButtonTransparent>
                                <NumberTextBox
                                    value={requestedQuantity}
                                    size="S"
                                    weight="600"
                                    align="center"
                                    color={textColor}
                                    css={{
                                        textShadow,
                                    }} 
                                />
                                <ButtonTransparent
                                    disabled={!canIncrease}
                                    onClick={increaseRequestedQuantity}>
                                    <Icon
                                        icon={faPlus}
                                        size="S"
                                        color={
                                            canIncrease
                                                ? textColor
                                                : textColor.withAlpha(0.3)
                                        }
                                    />
                                </ButtonTransparent>
                            </FlexRow>
                            <HorizontalDivider XS />
                        </Conditional> */}
                        {/* Booking button */}
                        <Conditional
                            when={canBePicked && availableResourceCount !== 0}>
                            <FlexColumn
                                mainAxis="center"
                                background={{
                                    color: hasBackgroundImage
                                        ? theme.colors.black.withAlpha(0.2)
                                        : theme.colors.transparent,
                                }}
                                padding={{
                                    leftRight:
                                        availableResourcesWithCapacityCount <= 1
                                            ? 32
                                            : 20,
                                    topBottom: 12,
                                }}
                                css={{
                                    borderRadius: theme.radius,
                                    border: `1px solid ${textColor.asString}`,
                                    backdropFilter: "blur(5px)",
                                }}>
                                <TextBox
                                    text={
                                        canBePicked
                                            ? requestedQuantity > 1
                                                ? translation({
                                                      sv: `Boka (${requestedQuantity} st)`,
                                                      en: `Book (${requestedQuantity} pcs)`,
                                                  })
                                                : translation({
                                                      sv: "Boka",
                                                      en: "Book",
                                                  })
                                            : availableResourceCount === 0
                                              ? translation({
                                                    sv: "Utsålt",
                                                    en: "Sold out",
                                                })
                                              : translation({
                                                    sv: "-",
                                                    en: "-",
                                                })
                                    }
                                    size="S"
                                    weight="600"
                                    align="center"
                                    color={textColor}
                                    css={{
                                        textShadow,
                                        whiteSpace: "nowrap",
                                    }}
                                />
                            </FlexColumn>
                        </Conditional>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </SectionAreaCard>
    );
};

export { AccommodationCard };
