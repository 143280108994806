import type {
    ButtonColorPropNames,
    ButtonProps_v2,
} from "@@/shared/buttons_v2/button";
import { ButtonGray } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Translatable } from "@towni/common";
import type { Except } from "type-fest";
import { ButtonDangerLight } from "../buttons_v2/button-danger-light";
import { ButtonPrimaryLight } from "../buttons_v2/button-primary-light";

type Props = {
    readonly icon: IconDefinition | IconProp | undefined;
    readonly onClick: () => void;
    readonly text: Translatable;
    readonly children?: React.ReactNode;
    readonly disabled?: boolean;
};
const _ActionRowButton = (
    props: Props & {
        readonly backgroundColor: string;
        readonly Button: (
            props: Except<ButtonProps_v2, ButtonColorPropNames>,
        ) => JSX.Element;
    },
) => {
    const iconContainerWidth = 52;
    const Button = props.Button;
    return (
        <Button
            removeBorder
            radius={10}
            disabled={props.disabled}
            padding={{ all: 0 }}
            css={{
                justifyContent: "flex-start",
                padding: 0,
                filter: props.disabled ? "grayscale(100%)" : "none",
            }}
            contentContainerCss={{
                width: "100%",
            }}
            fillParentWidth
            onClick={() => {
                props.onClick();
            }}>
            <FlexRow fillParent mainAxis="flex-start">
                <FlexColumn
                    minWidth={iconContainerWidth}
                    maxWidth={iconContainerWidth}
                    css={{
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        opacity: props.disabled ? 0.5 : 1,
                    }}
                    padding={{ all: 18 }}
                    fillParentHeight
                    background={{
                        color: props.backgroundColor,
                    }}>
                    <Icon icon={props.icon} size={1} />
                </FlexColumn>
                <TextBox
                    text={props.text}
                    size={1}
                    weight={"700"}
                    align="center"
                    css={{
                        flex: 1,
                        paddingRight: iconContainerWidth,
                    }}
                />
            </FlexRow>
        </Button>
    );
};
const ActionRowButtonGray = (props: Props) => {
    const theme = useTheme();
    return (
        <_ActionRowButton
            Button={ButtonGray}
            backgroundColor={theme.colors.black.light80.asString}
            {...props}
        />
    );
};

const ActionRowButtonPrimary = (props: Props) => {
    const theme = useTheme();
    return (
        <_ActionRowButton
            Button={ButtonPrimary}
            backgroundColor={theme.colors.primary.dark.asString}
            {...props}
        />
    );
};
const ActionRowButtonPrimaryLight = (props: Props) => {
    const theme = useTheme();
    return (
        <_ActionRowButton
            Button={ButtonPrimaryLight}
            backgroundColor={theme.colors.primary.light.light90.asString}
            {...props}
        />
    );
};

const ActionRowButtonDangerLight = (props: Props) => {
    const theme = useTheme();
    return (
        <_ActionRowButton
            Button={ButtonDangerLight}
            backgroundColor={theme.colors.danger.main.light90.asString}
            {...props}
        />
    );
};

export {
    ActionRowButtonDangerLight,
    ActionRowButtonGray,
    ActionRowButtonPrimary,
    ActionRowButtonPrimaryLight,
};
