import { css } from "@emotion/react";

const customThinScrollbarCss = css({
    ["&::-webkit-scrollbar-track"]: {
        backgroundColor: "transparent",
        border: `4px solid #fff`,
    },
    ["&::-webkit-scrollbar"]: {
        width: 7,
        height: 7,
        backgroundColor: "transparent",
    },
    ["&::-webkit-scrollbar-thumb"]: {
        borderTop: `3px solid hsla(0, 0%, 100%, 0.000001)`,
        backgroundColor: "#eee",
        backgroundClip: "padding-box",
        cursor: "pointer",
    },
    ["&:hover::-webkit-scrollbar-thumb"]: {
        border: 0,
        backgroundColor: "#ccc",
        cursor: "pointer",
    },
    scrollbarColor: "#ccc transparent",
    scrollbarWidth: "thin",
});

export { customThinScrollbarCss };
