import { generateId, ImageUrl } from "@towni/common";

type ImageSrc = {
    _id: string;
    url: ImageUrl;
    mime: string;
};

const createImageSrc = (params: { url: string; mime: string }): ImageSrc => ({
    _id: generateId({ prefix: "image_src__" }),
    url: params.url as ImageUrl,
    mime: params.mime,
});

export type { ImageSrc };
export { createImageSrc };
