import { asArray } from "@towni/common";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const useQueryParameter = (queryParameter: string | string[]) => {
    const location = useLocation();
    const queries = new URLSearchParams(location.search);

    const value = asArray(queryParameter)
        .map(param => queries.get(param) || undefined)
        .filter(Boolean) as string[];

    return value?.[0] ?? "";
};

/**
 * Finds first matching url parameter with value from given paremeter names
 * @param {(string | string[])} urlParameter
 * @returns
 */
const useUrlParameter = <T extends string = string>(
    urlParameter: string | string[] | undefined,
) => {
    const params = useParams<{ [name: string]: string }>();
    const value = asArray(urlParameter)
        .map(param => params[param] || undefined)
        .filter(Boolean) as string[];
    return value?.[0] as T;
};

const useQueryOrParam = (
    name: string | string[],
): string | string[] | undefined => {
    const loc = useLocation();
    const paramValue = useUrlParameter(name);
    const queryValue = useQueryParameter(name);
    const toOutputValue = () =>
        asArray(paramValue || queryValue).filter(Boolean);
    const [output, setOutput] = useState<string[]>(toOutputValue());

    useEffect(() => {
        setOutput(toOutputValue());
    }, [loc, paramValue, queryValue]);

    switch (output.length) {
        case 0:
            return undefined;
        case 1:
            return output[0];
        default:
            return output;
    }
};

const useQueryOrParamSingleValue = <Value extends string = string>(
    name: string | string[],
    options?: {
        override?: Value;
        validate?: (value: Value) => boolean;
    },
): Value | undefined => {
    const output = useQueryOrParam(name);
    if (options?.override) return options?.override;
    const value = (
        Array.isArray(output)
            ? output.length >= 1
                ? output[0]
                : undefined
            : output
    ) as Value;
    if (options?.validate && !options?.validate(value))
        throw new Error("query or url param, invalid value; " + value);
    return value;
};
const useQueryOrParamCommaSeparatedValue = <Value extends string = string>(
    name: string | string[],
    options?: {
        override?: Value[];
        validate?: (values: Value[]) => boolean;
    },
): Value[] => {
    const output = useQueryOrParam(name) as unknown as
        | Value
        | Value[]
        | undefined;
    if (options?.override) return options?.override;
    const values = asArray<Value>(output);
    if (options?.validate && !options?.validate(values))
        throw new Error("query or url param, invalid value; " + values);
    return values;
};

export {
    useQueryOrParam,
    useQueryOrParamCommaSeparatedValue,
    useQueryOrParamSingleValue,
    useQueryParameter,
    useUrlParameter,
};
