import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../shared/store-front-hz-product-card";

const actionTexts = [
    translation({
        sv: "Utforska",
        en: "Explore",
    }),
    translation({
        sv: "Hyr nu",
        en: "Rent now",
    }),
    translation({
        sv: "Läs mer",
        en: "Read more",
    }),
];

const SmartTopSectionLidkopingKrono = () => {
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            getProductId("product__PEOwU3YM3ltIzFIeZKso" as ProductId), // Hyr elcykel
            getProductId("product__ArKy7gT7drXQTkBHyXaA" as ProductId), // Transportvagn
            getProductId("product__lgjdiGFz-2hXkKcWjTds" as ProductId), //
        ],
        []
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea sectionAreaId="smart-top" padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : id}
                            cardSize={1.4 as RemSize}
                            aspectRatio="1:1"
                            actionText={actionTexts[index]}
                            productId={id}
                            hideKicker
                        />
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { SmartTopSectionLidkopingKrono };
