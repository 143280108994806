import { Conditional } from "@@/shared/conditional";
import { Icon } from "@@/shared/icons/icon";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, useTheme } from "@emotion/react";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { Translatable } from "@towni/common";
import { Link } from "react-router-dom";
import { Except } from "type-fest";

type ParagraphLink = {
    _type: "PARAGRAPH_LINK";
    text: Translatable;
    href: string;
    showIcon?: boolean;
    target?: string;
    css?: Interpolation<AppTheme>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphLink = (item: any): item is ParagraphLink => {
    return item?._type === "PARAGRAPH_LINK";
};

const paragraphLinkFactory = (
    params: Except<ParagraphLink, "_type">,
): ParagraphLink => {
    return {
        _type: "PARAGRAPH_LINK",
        ...params,
    };
};

const ParagraphLink = (props: { link: ParagraphLink; className?: string }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const linkColor = theme.colors.primary.asString;
    const styles = [
        {
            display: "inline-block",
            color: linkColor,
            ":hover,:visited,:focus,:active": {
                color: linkColor,
                textDecoration: "none",
            },
            ":hover": {
                color: theme.colors.secondary.asString,
                textDecoration: "underline",
            },
            cursor: "pointer",
        },
        props.link.css,
    ];

    if (
        props.link.href.startsWith("http") ||
        props.link.href.startsWith("mailto") ||
        props.link.href.startsWith("tel") ||
        props.link.href.startsWith("callto")
    ) {
        return (
            <a
                className={props.className}
                css={styles}
                href={props.link.href}
                target={props.link.target}>
                <Conditional when={props.link.showIcon}>
                    <Icon icon={faLink} css={{ paddingRight: 5 }} size="XS" />
                </Conditional>
                {translate(props.link.text)}
            </a>
        );
    }

    return (
        <Link
            to={props.link.href}
            {...{ target: props.link.target ?? "" }}
            css={styles}
            className={props.className}>
            <Conditional when={props.link.showIcon}>
                <Icon icon={faLink} css={{ paddingRight: 5 }} size="XS" />
            </Conditional>
            {translate(props.link.text)}
        </Link>
    );
};

export { ParagraphLink, isParagraphLink, paragraphLinkFactory };
