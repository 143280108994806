import { useTranslate } from "@@/translations/use-translate";
import { css, SerializedStyles } from "@emotion/react";
import { colorAsString } from "@towni/common";
import * as React from "react";
import { CircleSpinner } from "../spinners/circle-spinner";
import { TextBoxProps } from "./text-box";
/**
 * @deprecated You should probable use Paragraph component instead of
 * text box wrapped text spans.
 */
const TextSpan = (
    props: TextBoxProps & {
        hoverStyle?: SerializedStyles;
    },
) => {
    const translate = useTranslate();
    const text = translate(props.text);

    if (!text) return null;
    const { color, weight, hoverColor } = props;

    return (
        (props.spin && <CircleSpinner color={color} />) || (
            <span
                onClick={props.onClick}
                className={props.className}
                css={[
                    {
                        textTransform:
                            props.case?.toLowerCase() as React.CSSProperties["textTransform"],
                    },
                    props.styling,
                    props.hoverStyle
                        ? css({
                              "&:hover": props.hoverStyle,
                          })
                        : {},
                    css({
                        color: color ? colorAsString(color) : "inherit",
                        "&:hover": {
                            color: hoverColor
                                ? colorAsString(hoverColor)
                                : undefined,
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        fontWeight: weight
                            ? (weight as React.CSSProperties["fontWeight"])
                            : "inherit",
                    }),
                ]}>
                {text}
            </span>
        )
    );
};

export { TextSpan };
