import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { SubmitButton } from "@@/shared/buttons/submit-button";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Spacer } from "@@/shared/spacer";
import { TextBox, TextSpan } from "@@/shared/text";
import { ModalTitle } from "@@/shared/text/modal-title";
import {
    DualNameInput,
    EmailInput,
    InterestedInLocal,
} from "@@/shared/text/text-input";
import { useTheme } from "@emotion/react";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import { translation } from "@towni/common";
import * as React from "react";
import {
    useSignUpDispatch,
    useSignUpState,
} from "../../sign-up/sign-up-context";
import { useFinishSignUp } from "../../sign-up/use-finish-signup";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const SignUpRegistration = (_props: Props) => {
    const theme = useTheme();
    const wizardState = useWizardState();
    const wizardDispatch = useWizardDispatch();
    const state = useSignUpState();
    const [errors, setErrors] = React.useState<Record<string, boolean>>({});
    const formHasErrors = () =>
        Object.values(errors).some(item => item === true);

    const [dispatch] = useSignUpDispatch();

    const finishSignUp = useFinishSignUp();

    const disabled = [
        !state.data.firstName,
        !state.data.lastName,
        //  !state.data.password,
        !state.data.emailAddress,
        formHasErrors(),
        finishSignUp.isPending,
    ].some(Boolean);

    const setNames = (names: { firstName: string; lastName: string }) => {
        dispatch.setFirstName(names.firstName);
        dispatch.setLastName(names.lastName);
    };

    return (
        <FlexColumn height="100%" overflowY="scroll">
            <FlexRow
                mainAxis="space-between"
                crossAxis="center"
                padding={{ leftRight: 20, topBottom: 20 }}
                shrink={0}>
                <Spacer width={40} shrink={0}>
                    <Icon
                        onClick={() => wizardDispatch.goBack()}
                        icon={!wizardState.isFirst ? faChevronLeft : faTimes}
                        size={1.25}
                    />
                </Spacer>
                <FlexColumn crossAxis="center">
                    <ModalTitle
                        text={translation({
                            sv: "Slutför registreringen",
                            en: "Finish sign up",
                        })}
                    />
                </FlexColumn>
                <Spacer width={40} shrink={0} />
            </FlexRow>
            <HorizontalLine />
            <FlexColumn
                overflowY="scroll"
                crossAxis="center"
                padding={{ topBottom: 40, leftRight: 20 }}>
                <TextBox
                    align="center"
                    text={translation({
                        sv: "För kvitto och hantering av beställningar behöver vi några sista uppgifter",
                        en: "This information is used to process your orders",
                    })}
                    weight="300"
                    size="ML"
                    shrink={0}
                    padding={{ leftRight: 40 }}
                />
                <VerticalDivider XL />
                <form
                    onSubmit={event => {
                        // We don't want to let default form submission happen here,
                        // which would refresh the page.
                        event.preventDefault();
                        finishSignUp.mutateAsync(state.data);
                    }}
                    style={{ margin: 0 }}>
                    <DualNameInput
                        onChange={setNames}
                        onValidationError={() => {
                            setErrors({ ...errors, names: true });
                        }}
                        onValidationSuccess={() =>
                            setErrors({ ...errors, names: false })
                        }
                    />
                    <VerticalDivider L />
                    <EmailInput
                        fillParentWidth
                        onChange={dispatch.setEmailAddress}
                        description={translation({
                            sv: "Email används för kvitto och orderbekräftelse",
                            en: "Email is used for order information",
                        })}
                        onValidationError={() => {
                            setErrors({ ...errors, email: true });
                        }}
                        onValidationSuccess={() =>
                            setErrors({ ...errors, email: false })
                        }
                    />
                    <VerticalDivider L />
                    {/* <PasswordInput
                        onChange={input =>
                            dispatch.setPassword(input as Password)
                        }
                        description={translation({
                            sv: "Lösenordet ska vara minst 12 tecken",
                            en:
                                "The password must be at least 12 characters long",
                        })}
                        onValidationError={() => {
                            setErrors({ ...errors, password: true });
                        }}
                        onValidationSuccess={() =>
                            setErrors({ ...errors, password: false })
                        }
                    /> */}

                    <VerticalDivider XL />

                    <TextBox size="S" weight={"300"} shrink={0}>
                        <TextSpan text={"Genom att välja "} />
                        <TextSpan
                            text={"Godkänn och registrera "}
                            weight="500"
                        />
                        <TextSpan
                            text={"nedan godkänner jag Alfred Technologies "}
                        />
                        <TextSpan
                            onClick={() =>
                                open(
                                    "https://storage.googleapis.com/legal-alfred/user_agreement.pdf",
                                )
                            } //TODO move link to config?
                            text={"Användarvillkor "}
                            color={theme.colors.primary}
                        />
                        <TextSpan text={"och "} />
                        <TextSpan
                            onClick={() =>
                                open(
                                    "https://storage.googleapis.com/legal-alfred/privacy_policy.pdf",
                                )
                            } //TODO update with correct link
                            text={"sekretesspolicy."}
                            color={theme.colors.primary}
                        />
                    </TextBox>

                    <VerticalDivider S />

                    <SubmitButton
                        disabled={disabled}
                        spin={finishSignUp.isPending}>
                        <TextBox
                            text={translation({
                                sv: "Godkänn och registrera",
                                en: "Confirm and continue",
                            })}
                            weight="700"
                            shrink={0}
                            color={theme.colors.primary.text}
                        />
                    </SubmitButton>

                    <VerticalDivider S />

                    <InterestedInLocal
                        checked={state.data.tips}
                        onChange={() => dispatch.setInterest(!state.data.tips)}
                    />
                    <VerticalDivider />
                </form>
            </FlexColumn>
        </FlexColumn>
    );
};

export { SignUpRegistration };
