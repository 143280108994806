import { useIsBackoffice } from "@@/backoffice/backoffice-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { GlobalPeripheralBackgroundColor } from "@@/shared/global-peripheral-background-color";
import { useDelay } from "@@/shared/pictures/use-wait-for-it";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Color, MILLISECONDS, PixelSize, Translatable } from "@towni/common";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
    /**
     * Defaults to 500ms
     *
     * @type {number}
     */
    delayInMs?: MILLISECONDS;
    text?: Translatable;
    desktopMaxWidth?: PixelSize;
    className?: string;
    backgroundColor?: Color;
    spinnerSize?: SizeProp;
};
const PageLoad = (props: Props) => {
    const theme = useTheme();
    const isBackoffice = useIsBackoffice();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const [isWaiting] = useDelay(props.delayInMs ?? (500 as MILLISECONDS));
    const text = props.text;

    const backgroundColor = props.backgroundColor
        ? typeof props.backgroundColor === "string"
            ? props.backgroundColor
            : props.backgroundColor.asString
        : theme.colors.black.light90.asString;

    return (
        <>
            <Conditional
                when={isBackoffice && isDesktop}
                render={() => (
                    <GlobalMaxWidthCss maxWidth={props.desktopMaxWidth} />
                )}
            />
            <GlobalPeripheralBackgroundColor color={backgroundColor} />
            <div
                className={props.className}
                css={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor,
                    lable: "page-load",
                }}>
                <AnimatePresence>
                    {isWaiting ? null : (
                        <motion.div
                            css={{ width: "100%", height: "100%" }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}>
                            <FlexColumn
                                fillParent
                                crossAxis="center"
                                mainAxis="center">
                                <FlexRow
                                    fillParentWidth
                                    grow={isDesktop ? 0 : 1}
                                    shrink={1}
                                    mainAxis="center"
                                    crossAxis="center">
                                    <FontAwesomeIcon
                                        icon={faSpinnerThird}
                                        color={"#666666"}
                                        size={props.spinnerSize || "5x"}
                                        style={{ display: "block" }}
                                        spin
                                    />
                                </FlexRow>
                                <VerticalDivider />
                                <TextBox
                                    text={text}
                                    align="center"
                                    css={{ maxWidth: 400, padding: 40 }}
                                    color={"#666666"}
                                />
                            </FlexColumn>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};
export { PageLoad };
