import { useCart } from "@@/carts/multi-carts/cart.context";
import { useModal } from "@@/modals/use-modal";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { browserLogger } from "@@/settings";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { whiteningGradient } from "@@/shared/bg-lightening-gradient";
import { ButtonGray } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Circle } from "@@/shared/circle";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { OptionalGroup, Provider, sum, translation } from "@towni/common";
import { ExtrasOrderItemsV2 } from "../../bookable-extras-order-item-list-v2";
import { BookableProductExtrasGroupModal } from "../../bookable-product-extras-group-modal";
import { useBookingContext } from "../../booking-context";
import { useAddOptionalToCart } from "../../hooks/use-add-bookable-to-cart";
import { BookableDetailsOptionals } from "../bookable-details/bookable-details-optionals";
import { BookableExtrasGroupGrid } from "./bookable-extras-group-grid";
import { useExtraProps } from "./use-extras-props";
import { useIsBookingCompleteWithExtrasAndOptionals } from "./use-is-booking-complete-with-extras-and-optionals";

type Props = {
    readonly optionals: OptionalGroup[];
    readonly provider: Provider;
};

const ExtrasAndOptionalsPageV2 = (props: Props) => {
    const theme = useTheme();
    const wizardActions = useWizardDispatch();
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        optionValues: context.optionValues,
        isOnPremiseBooking: context.isOnPremiseBooking,
        requestedResources: context.requestedResources,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        settings: context.bookable.settings,
        pickedOptionalsMap: context.pickedOptionalsMap,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));
    const cart = useCart();
    const actions = useBookingContext(context => {
        return {
            setMessageToProvider: context.setMessageToProvider,
            setAddToCartDisabled: context.setAddToCartDisabled,
            setPickedOptionalsMap: context.setPickedOptionalsMap,
        };
    });
    const addToCart = useAddOptionalToCart();

    const [{ show: showPickedExtras }, extraModalId] =
        useModal("extras_modal__");

    const extras = state.bookable.extrasGroups ?? [];

    const continueDisabled = useIsBookingCompleteWithExtrasAndOptionals(
        props.optionals,
    );

    const extraProps = useExtraProps(extras, state.isOnPremiseBooking);

    const amountExtras = sum(extraProps, x => x.pickedAmount);

    return (
        <>
            <FlexColumn
                fillParent
                css={{
                    backgroundColor: theme.colors.default.background.asString,
                }}>
                <FlexColumn fillParent overflowY="scroll">
                    <FlexColumn fillParentWidth>
                        <ExtrasOrderItemsV2
                            extras={extraProps}
                            title={translation({
                                sv: "Tillagda tillval",
                                en: "Selected extras",
                            })}
                            productIdsToIgnore={[state.product._id]}
                        />
                        <Conditional when={amountExtras > 0}>
                            <HorizontalSectionDivider />
                        </Conditional>

                        <VerticalDivider M />
                        <Conditional when={props.optionals.length > 0}>
                            <BookableDetailsOptionals
                                initialValues={state.pickedOptionalsMap}
                                optionals={props.optionals}
                                onChange={pickedOptionalsMap => {
                                    actions.setPickedOptionalsMap(
                                        pickedOptionalsMap,
                                    );
                                    if (!cart.orderItems.length) {
                                        //Fixes problem when cart is emptying after order.
                                        browserLogger.info(
                                            "Could not find order item to update",
                                        );
                                        return;
                                    }
                                    addToCart(pickedOptionalsMap);
                                }}
                            />
                            <VerticalDivider M />
                            <HorizontalSectionDivider />
                            <VerticalDivider M />
                        </Conditional>
                        <FlexColumn
                            //  overflowY="scroll"
                            padding={{ leftRight: 20 }}>
                            <ForEach
                                itemOf={extraProps.filter(
                                    x =>
                                        x.minMax.max === undefined ||
                                        x.minMax.max > 0,
                                )}
                                divider={
                                    <>
                                        <VerticalDivider M />
                                        <HorizontalLine />
                                        <VerticalDivider M />
                                    </>
                                }>
                                {extra => {
                                    return (
                                        <BookableExtrasGroupGrid
                                            product={state.product}
                                            extraGroup={extra.extras}
                                            minMax={extra.minMax}
                                            aspectRatio="1:1"
                                        />
                                    );
                                }}
                            </ForEach>
                        </FlexColumn>
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                <FlexRow
                    css={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        pointerEvents: "none",
                    }}
                    background={{
                        linearGradient: theme.isLightTheme
                            ? whiteningGradient
                            : darkeningGradient,
                    }}
                    fillParentWidth
                    mainAxis="flex-end"
                    padding={{ all: 20, top: 60 }}>
                    <FlexColumn crossAxis="flex-end">
                        <FlexRow crossAxis="center">
                            <Conditional when={amountExtras > 0}>
                                <ButtonGray
                                    onClick={showPickedExtras}
                                    padding={{
                                        left: 15,
                                        right: 3,
                                        topBottom: 15,
                                    }}
                                    css={{
                                        maxHeight: "40px",
                                        pointerEvents: "all",
                                        borderRadius: 3000,
                                    }}>
                                    <FlexRow maxHeight={20}>
                                        <TextBox
                                            color={theme.colors.black}
                                            text={translation({
                                                sv: "Tillval",
                                                en: "Extras",
                                            })}
                                            padding={{
                                                leftRight: 15,
                                            }}
                                        />
                                        <Circle size={34}>
                                            <TextBox
                                                text={amountExtras.toString()}
                                            />
                                        </Circle>
                                    </FlexRow>
                                </ButtonGray>
                                <HorizontalDivider S />
                            </Conditional>
                            <ButtonPrimary
                                disabled={continueDisabled}
                                onClick={() => {
                                    wizardActions.goForward();
                                }}
                                css={{
                                    maxHeight: "40px",
                                    pointerEvents: "all",
                                    borderRadius: 3000,
                                }}>
                                <TextBox
                                    text={translation({
                                        sv: "Fortsätt",
                                        en: "Continue",
                                    })}
                                    padding={{
                                        leftRight: 15,
                                        topBottom: 2,
                                    }}
                                />
                            </ButtonPrimary>
                        </FlexRow>

                        <Conditional
                            when={continueDisabled}
                            render={() => (
                                <>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={translation({
                                            sv: "* Det finns val kvar att göra",
                                            en: "* You still have options you need to pick",
                                        })}
                                        color={
                                            theme.isLightTheme
                                                ? theme.colors.black.light60
                                                : theme.colors.black.light40
                                        }
                                        size={0.8}
                                    />
                                </>
                            )}
                        />
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
            <BookableProductExtrasGroupModal
                product={state.product}
                modalId={extraModalId}
                extras={extraProps}
            />
        </>
    );
};

export { ExtrasAndOptionalsPageV2 };
