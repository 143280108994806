import { OrderExtras } from "@@/products/bookables/bookable-order-extras";
import { OrderKeyAccess } from "@@/products/bookables/bookable-order-key-access";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { HorizontalLine } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import {
    isOrderItemBookable_V2,
    Order,
    ResourceReservation,
} from "@towni/common";
import { BookableReceipt_OrderDetailsHeader } from "./bookable-receipt-order-details-header";
import { BookableReceipt_OrderItemBody } from "./bookable-receipt-order-item-body";

type Props = {
    order: Order;
    resourceReservations: ResourceReservation[];
};

const BookableReceipt_OrderDetails = (props: Props) => {
    const { order } = props;
    const [provider, { isPending: _isLoadingProvider }] = useProvider(
        order.providerId,
    );

    return (
        <>
            <ForEach
                itemOf={order.orderItems.filter(isOrderItemBookable_V2)}
                divider={<HorizontalLine />}>
                {orderItem => (
                    <>
                        <BookableReceipt_OrderDetailsHeader
                            orderItem={orderItem}
                        />
                        {provider ? (
                            <BookableReceipt_OrderItemBody
                                orderItem={orderItem}
                                order={order}
                                provider={provider}
                                resourceReservations={
                                    props.resourceReservations ?? []
                                }
                            />
                        ) : (
                            <TextShimmer rows={5} />
                        )}
                        <OrderExtras order={order} />
                        <OrderKeyAccess order={order} />
                    </>
                )}
            </ForEach>
        </>
    );
};

export { BookableReceipt_OrderDetails };
