import { FontWeight } from "@@/styles/theme";
import { useTheme } from "@emotion/react";
import { ColorItem, RemSize, SizeName, Translatable } from "@towni/common";
import { FC } from "react";
import { TextBox } from "../text/text-box";

const SectionAreaSubtitle: FC<{
    subtitle: Translatable;
    color?: ColorItem;
    size?: SizeName | RemSize;
    weight?: FontWeight;
    lineHeight?: RemSize;
    userSelect?: React.CSSProperties["userSelect"];
}> = props => {
    const theme = useTheme();
    const textSize =
        typeof props.size === "string"
            ? theme.sizes.inRem[props.size]
            : props.size ?? 1.125;
    return (
        <TextBox
            renderMarkdown
            text={props.subtitle}
            color={props.color ?? theme.colors.black.light40}
            size={textSize}
            userSelect={props.userSelect}
            weight={props.weight}
            css={{
                lineHeight: props.lineHeight
                    ? `${props.lineHeight}rem;`
                    : `${textSize * 1.3}rem`,
            }}
        />
    );
};
export { SectionAreaSubtitle };
