import {
    colorFactory,
    colorSetFactory,
    transparentColor,
    whiteColor,
} from "@towni/common";
import React, { useContext } from "react";

const kronocampingColors = {
    // YELLOW/ORANGE
    primary: colorSetFactory({
        main: colorFactory({ hue: 34, saturation: 1, light: 0.56 }), // #,
        dark: colorFactory({ hue: 34, saturation: 1, light: 0.46 }), // #
        light: colorFactory({ hue: 34, saturation: 0.58, light: 0.96 }), // #
        background: colorFactory({ hue: 34, saturation: 1, light: 0.56 }), // #
        backgroundGradientAlt: colorFactory({
            hue: 47,
            saturation: 1,
            light: 0.67,
        }), // #
        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: transparentColor,
        shadow: colorFactory({ hue: 34, saturation: 1, light: 0.9 }), // #
    }),
    // BLUE
    secondary: colorSetFactory({
        main: colorFactory({ hue: 208, saturation: 0.66, light: 0.3 }), // #,
        dark: colorFactory({ hue: 208, saturation: 0.66, light: 0.2 }), // #
        light: colorFactory({ hue: 208, saturation: 0.16, light: 0.4 }), // #
        background: colorFactory({ hue: 208, saturation: 0.66, light: 0.3 }), // #
        backgroundGradientAlt: colorFactory({
            hue: 195,
            saturation: 0.87,
            light: 0.44,
        }), // #
        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: transparentColor,
        shadow: colorFactory({ hue: 208, saturation: 0.66, light: 0.9 }), // #
    }),
    lightBackground: "hsl(39, 95%, 94%)",
};
const KronocampingColorContext = React.createContext(kronocampingColors);

const useKronocampingColorContext = (): typeof kronocampingColors => {
    const context = useContext(KronocampingColorContext);
    if (!context)
        throw new Error("must be used within a KronocampingColorContext");

    return context;
};

export {
    useKronocampingColorContext,
    KronocampingColorContext,
    kronocampingColors,
};
