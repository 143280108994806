import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    Amenity,
    AmenityId,
    AmenityType,
    GetResponse,
    ProviderId,
    emptyArrayOf,
} from "@towni/common";

function useAmenitiesQuery(
    amenityIds: AmenityId[] | undefined | null,
): UseQueryResult<Amenity[]>;
function useAmenitiesQuery(params?: {
    typeFilter?: AmenityType;
    providerId?: ProviderId;
}): UseQueryResult<Amenity[]>;
function useAmenitiesQuery(
    params?:
        | {
              typeFilter?: AmenityType;
              providerId?: ProviderId;
          }
        | AmenityId[]
        | undefined
        | null,
): UseQueryResult<Amenity[]> {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<Amenity[]> = async ({ signal }) => {
        const queryParams = Array.isArray(params)
            ? []
            : ([
                  ...(params?.providerId
                      ? [["providerId", params.providerId] as const]
                      : []),
                  ...(params?.typeFilter
                      ? [["type", params.typeFilter] as const]
                      : []),
              ] as const);

        const queryString = Array.isArray(params)
            ? []
            : queryParams.length
              ? `?${queryParams.map(([key, value]) => `${key}=${value}`).join("&")}`
              : "";

        const data = await fetchClient.get<GetResponse<Amenity>>({
            route: Array.isArray(params)
                ? `/resources/accommodations/amenities/${params.join(",")}`
                : `/resources/accommodations/amenities${queryString}`,
            customConfig: {
                signal,
            },
        });

        return data.items ?? emptyArrayOf<Amenity>();
    };
    return useQuery({
        queryKey: [
            "amenities",
            ...(Array.isArray(params)
                ? params.sort()
                : [params?.providerId, params?.typeFilter]),
        ],
        queryFn,
    });
}

export { useAmenitiesQuery };
