/* eslint-disable react/display-name */
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { ThreeDotSpinner } from "@@/shared/spinners/three-dot-spinner";
import { TextBox } from "@@/shared/text/text-box";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import {
    deliveryMethodFaIconMap,
    DeliverySelection,
    Padding,
    Provider,
    RemSize,
    translation,
} from "@towni/common";
import { useIsPickupAvailable } from "./cart";
import { useCheckoutContext } from "./checkout.context";
import { DeliveryMethodText } from "./deliverypicker/delivery-method-text";

type Props = {
    provider: Provider | undefined;
    deliverySelection: DeliverySelection;
    showModal: () => void;
    padding?: Padding;
};

const SelectDeliveryMethodRow = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const { provider } = props;
    const madeDeliveryChoice = useCheckoutContext(
        context => context.madeDeliveryChoice,
    );
    const pickup = useIsPickupAvailable(provider);

    const pickupUnavailable =
        props.deliverySelection.method === "PICK_UP" && !pickup;

    if (props.deliverySelection.method === "DIGITAL") return null;

    if (!provider)
        return (
            <FlexRow
                shrink={0}
                fillParentWidth
                mainAxis="center"
                crossAxis="center"
                padding={{ all: 15 }}>
                <ThreeDotSpinner size={1.5} />
            </FlexRow>
        );
    if (pickupUnavailable)
        return (
            <FlexRow
                css={{
                    width: "100%",
                    padding: 20,
                    paddingLeft: 40,
                    paddingRight: 40,
                    backgroundColor: theme.colors.danger.light.asString,
                }}>
                <TextBox
                    color={theme.colors.danger}
                    align="center"
                    text={translation({
                        sv: `${translate(
                            provider.meta.title,
                        )} har för tillfället inga öppettider tillgängliga för avhämtning`,
                        en: `${translate(
                            provider.meta.title,
                        )} currently doesn't have any open hours available for pick-up`,
                    })}
                />
            </FlexRow>
        );
    return (
        <FlexRow
            fillParentWidth
            shrink={0}
            mainAxis="space-between"
            crossAxis="stretch"
            onClick={props.showModal}
            padding={props.padding}
            css={{
                cursor: "pointer",
                userSelect: "none",
                minWidth: "min-content",
            }}
            background={{
                color: madeDeliveryChoice
                    ? theme.colors.primary.main.value.withAlpha(0.1)
                    : "#fffdd0",
            }}>
            <FlexRow grow={1}>
                <Icon
                    icon={
                        deliveryMethodFaIconMap[props.deliverySelection.method]
                    }
                    size={1.2 as RemSize}
                    color={theme.colors.default.text}
                    css={{
                        right: 0,
                    }}
                />
                <HorizontalDivider XS />
                <DeliveryMethodText
                    provider={provider}
                    deliverySelection={props.deliverySelection}
                />
            </FlexRow>
            <FlexRow shrink={1} css={{ marginLeft: "auto" }}>
                <TextBox
                    text={translation({
                        sv: "ÄNDRA",
                        en: "PICK",
                    })}
                    css={{
                        textAlign: "center",
                        verticalAlign: "middle",
                    }}
                    weight="900"
                    size="XS"
                />
                <HorizontalDivider XS />
                <Icon
                    icon={faChevronRight}
                    size={1 as RemSize}
                    color={theme.colors.default.text}
                />
            </FlexRow>
        </FlexRow>
    );
};

export { SelectDeliveryMethodRow };
