import { useAvailableLanguagesContext } from "@@/settings/available-languages-context";
import { useLanguageContext } from "@@/settings/language-context";
import {
    LanguageCode,
    Translatable,
    UseTranslateResult,
    UseTranslateTextInput,
    fromTranslatableString,
    isTranslatableString,
    translation,
    valueTranslator,
} from "@towni/common";
import { useCallback } from "react";
import { useTranslator } from "./use-translator";

const useTranslate = (): UseTranslateResult => {
    const translator = useTranslator();
    const { language: _language } = useLanguageContext();
    const { availableLanguages } = useAvailableLanguagesContext();

    return useCallback(
        (
            text: UseTranslateTextInput,
            language: LanguageCode = _language,
            ignoreLanguageFallbacks = false,
        ) => {
            if (!text) return "";
            if (isTranslatableString(text)) {
                return valueTranslator(
                    language,
                    ignoreLanguageFallbacks,
                    availableLanguages,
                )(fromTranslatableString(text));
            }
            if (typeof text === "string") {
                return translator(text);
            }
            if (!("_type" in text) && "sv" in text) {
                // text is of type `TranslatableValueFactoryParams`
                // should be converted to a translatable
                return valueTranslator(
                    language,
                    ignoreLanguageFallbacks,
                    availableLanguages,
                )(translation(text));
            }
            return valueTranslator(
                language,
                ignoreLanguageFallbacks,
                availableLanguages,
            )(text);
        },
        [_language, availableLanguages, translator],
    );
};

/** Checks if a translatable value is empty after translation and returns a true/false ansewer  */
const useIsTranslatableEmpty = () => {
    const translate = useTranslate();
    return useCallback(
        (value: Translatable | undefined): boolean => {
            const valueTranslated = translate(value);
            if (typeof valueTranslated === "string")
                return !valueTranslated.trim();
            return true;
        },
        [translate],
    );
};
export { useIsTranslatableEmpty, useTranslate, useTranslator };
