import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { KronocampingDiscoverMoreSection } from "../kronocamping/sections/kronocamping-discover-more-section";
import { LastMileCarSection } from "./sections/last-mile-car-section";
import { OneSquareLinkSectionAllTravel } from "./sections/one-square-link-section-all-travel";
import { OneSquareLinkSectionElBike } from "./sections/one-square-link-section-elbike";
import { OneSquareSection } from "./sections/one-square-section";
import { SmartGiftCard } from "./sections/smart-giftcard";

type Props = {
    title?: string;
};

const SmartStoreFrontElCar = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>{props.title ?? "SMaRT Resande"}</title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory("smart-store-front")}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/destination-smart_elbil.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <TextBox
                        text="Modernt, tyst och hållbart"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Hyr elbil här "
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider XL />
                    <TextBox
                        text="Res som du vill – hållbart"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Res hit med kollektivtrafiken och hyr en elbil på plats för att upptäcka området. Med elbil kan du åka dit du vill – hållbart. Vid bokning av elbil hittar du också laddstationerna. Och kom ihåg, det går alltid att ladda övernatten varhelst det finns el. Så, var hämtar du bilen? "
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Elbil lidköping
                        productId={"product__97Pdivfgw5i-TAoXXRDj" as ProductId}
                        actionText={translation({
                            sv: "Boka nu",
                            en: "Book now!",
                        })}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Elbil hellkis TODO gissar kinne
                        productId={"product__3otRxMD4Sb4mS83JkfbH" as ProductId}
                        actionText={translation({
                            sv: "Boka nu",
                            en: "Book now!",
                        })}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Elbil vara
                        productId={"product__AJSCy2B2OjIlKwX2Zm40" as ProductId}
                        actionText={translation({
                            sv: "Boka nu",
                            en: "Book now!",
                        })}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Elbil vara
                        productId={"product__10rLfxV9DAA_PsE2JS4Q" as ProductId}
                        actionText={translation({
                            sv: "Boka nu",
                            en: "Book now!",
                        })}
                    />
                    <VerticalDivider L />
                    <SmartGiftCard />
                    <VerticalDivider L />
                    <TextBox
                        text="Fler hållbara resesätt"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Med elcykel, elbil eller sista-milen-bilen kan du upptäcka mer av landskapet och besöksmålen i närheten. Utforska vilka fler alternativ som finns för att ta dig dit du vill."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareLinkSectionElBike />

                    <VerticalDivider L />

                    <LastMileCarSection
                        productId={"product__IG8ZXjoF9TW197TdJKI8" as ProductId}
                        actionText={translation({
                            sv: "Se & Boka",
                            en: "Book now!",
                        })}
                    />

                    <VerticalDivider />
                    <OneSquareLinkSectionAllTravel />
                    <VerticalDivider XXL />
                    <FlexColumn crossAxis="center">
                        <TextBox
                            text={translation({
                                sv: "Upplev mer lokalt",
                                en: "Experience more local",
                            })}
                            size={2}
                            weight="700"
                            align="center"
                        />
                        <VerticalDivider M />
                        <TextBox
                            text={translation({
                                sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                            })}
                            color={theme.colors.black.light50}
                            size={1.125}
                            weight="400"
                            align="center"
                            css={{
                                lineHeight: "1.6rem",
                            }}
                        />
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <KronocampingDiscoverMoreSection />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                {/* <<<< Content */}
            </AppPage>
        </WideScreenWrapper>
    );
};

export { SmartStoreFrontElCar };
