import { useMediaQuery } from "@@/shared/responsiveness/use-media-query";
import { remSize, RemSize } from "@towni/common";

const useHzCardSize = (defaultSize: RemSize): RemSize => {
    const widthIsLessThan415 = useMediaQuery("(max-width: 414px)");

    return remSize(widthIsLessThan415 ? defaultSize : defaultSize * 1.2);
};

export { useHzCardSize };
