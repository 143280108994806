import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FieldTitle } from "@@/shared/text/field-title";
import { TextBox } from "@@/shared/text/text-box";
import { useTheme } from "@emotion/react";
import { Slider } from "@mui/joy";
import { MinMaxRange, Translatable } from "@towni/common";
import * as React from "react";

type Props = {
    readonly label?: Translatable;
    readonly minValue: number;
    readonly maxValue: number;
    readonly initialValues: Required<MinMaxRange>;
    readonly onChange: (minMax: MinMaxRange) => void;
    readonly required?: boolean;
};

const RangeSlider = (props: Props) => {
    const theme = useTheme();
    const [value, setValue] = React.useState<number[]>([
        props.initialValues.min,
        props.initialValues.max,
    ]);

    const handleChange = (_: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    const handleChangeCommitted = (
        _: Event | React.SyntheticEvent<Element, Event>,
        newValue: number | number[],
    ) => {
        if (typeof newValue === "number")
            throw new Error("Invalid value, should be a tuple");
        props.onChange({
            min: newValue[0],
            max: newValue[1],
        });
    };

    return (
        <div css={{ width: "100%" }}>
            <FlexColumn fillParentWidth css={{ position: "relative" }}>
                <FlexRow mainAxis="space-between" crossAxis="flex-start">
                    <FieldTitle
                        text={props.label}
                        required={!!props.required}
                        size={"S"}
                    />
                    <HorizontalDivider />
                    <TextBox
                        size={"S"}
                        weight="700"
                        text={`${value[0].toLocaleString()} - ${value[1].toLocaleString()}`}
                        color={theme.colors.default.text.withAlpha(0.5)}
                    />
                </FlexRow>
                <FlexRow
                    fillParentWidth
                    css={{
                        zIndex: 10,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}>
                    <Slider
                        value={value}
                        color="neutral"
                        min={props.minValue}
                        max={props.maxValue}
                        onChange={handleChange}
                        onChangeCommitted={handleChangeCommitted}
                        valueLabelDisplay="auto"
                        css={{
                            opacity: 0.7,
                        }}
                    />
                </FlexRow>
                <FlexRow mainAxis="space-between" css={{ marginTop: -5 }}>
                    <TextBox
                        size={0.725}
                        text={`${props.minValue.toLocaleString()}`}
                        color={theme.colors.default.text.withAlpha(0.25)}
                    />
                    <TextBox
                        size={0.725}
                        text={`${props.maxValue.toLocaleString()}`}
                        color={theme.colors.default.text.withAlpha(0.25)}
                    />
                </FlexRow>
            </FlexColumn>
        </div>
    );
};

export { RangeSlider };
