import { css, SerializedStyles, useTheme } from "@emotion/react";
import { colorAsString, ColorItem } from "@towni/common";
import * as React from "react";

type ContentSize = "S" | "M" | "L";
type Props = {
    size?: number;
    onClick?: (event: React.MouseEvent) => void;
    backgroundColor?: ColorItem;
    textColor?: ColorItem;
    children?: React.ReactNode;
    contentSize?: ContentSize;
    className?: string;
    transparent?: boolean; // bad name
    styling?: SerializedStyles;
    /**
     * defaults to 5
     */
    radius?: React.CSSProperties["borderRadius"];
};

const defaultSize = 25;
const RoundedSquare = (props: Props) => {
    const theme = useTheme();
    const { children, onClick, transparent } = props;
    const size = props.size ?? defaultSize;

    const contentRatio: { [key in ContentSize]: number } = {
        S: 0.7 / defaultSize,
        M: 0.9 / defaultSize,
        L: 1.2 / defaultSize,
    };
    const fontSize = contentRatio[props.contentSize ?? "M"] * size;

    const backgroundColor = colorAsString(
        props.backgroundColor ?? theme.colors.default.background,
    );
    const textColor = colorAsString(
        props.textColor ?? theme.colors.default.text,
    );
    const styles = css({
        width: size,
        height: size,
        borderRadius: props.radius ?? 5,
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: `${fontSize}rem`,
        color: textColor,
        flexGrow: 0,
        flexShrink: 0,
        cursor: onClick ? "pointer" : undefined,
        opacity: transparent ? 0.6 : undefined,
        ":active": {
            backgroundColor,
            color: textColor,
            opacity: transparent ? 0.9 : undefined,
        },
    });
    return (
        <div
            onClick={onClick}
            className={props.className}
            css={[styles, props.styling]}>
            {children}
        </div>
    );
};

export { RoundedSquare };
// TODO needs refactoring/simplification
