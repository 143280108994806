export {};

// Polyfill node global by setting it to window
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare global {
    interface Window {
        global: Window;
    }
}

// Polyfill flat and flatMap for browsers not using those
import "array-flat-polyfill";

window.global = window;
