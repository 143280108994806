import { useAcceptInviteCommand } from "@@/backoffice/dashboard/providers/users/invite-user/command-hooks/use-accept-invite-command";
import { WithProvider } from "@@/providers/state/queries/provider/with-provider";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ImageV2 } from "@@/shared/pictures/image";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    Invitation,
    Provider,
    acceptInvitationCommandFactory,
    parseProviderPosterImage,
    translation,
} from "@towni/common";
import { useNavigate } from "react-router-dom";
import { useMe } from "../me/me-context";

type Props = {
    invitation: Invitation;
};

const AcceptInvitation = (props: Props) => {
    const theme = useTheme();
    const [me] = useMe();
    const navigate = useNavigate();
    const accept = useAcceptInviteCommand();

    if (!me) return <></>;

    return (
        <FlexColumn padding={{ leftRight: 20 }} crossAxis="center">
            <VerticalDivider />
            <VerticalDivider />
            <WithProvider providerIdOrSlug={props.invitation.providerId}>
                {provider => <ProviderImage provider={provider} />}
            </WithProvider>
            <VerticalDivider />
            <VerticalDivider />
            <ButtonPrimary
                padding={{ topBottom: 15 }}
                css={{
                    borderRadius: 40,
                }}
                onClick={async () => {
                    await accept.mutateAsync(
                        acceptInvitationCommandFactory({
                            invitationId: props.invitation._id,
                        }),
                    );
                    navigate(`/bo/providers/${props.invitation.providerId}`);
                }}>
                <TextBox
                    text={translation({
                        sv: "Acceptera inbjudan",
                        en: "Accept invitation",
                    })}
                    size={"L"}
                    padding={{ leftRight: 40 }}
                />
            </ButtonPrimary>
            <VerticalDivider XL />

            <TextBox
                padding={{ leftRight: 50 }}
                text={translation({
                    sv: "Detta kommer att ge ditt personliga konto access till det företag du blivit inbjuden till.",
                    en: "This will give your personal account access to the company you have been invited to.",
                })}
                size="S"
                align="center"
                color={theme.colors.black.light50}
            />
        </FlexColumn>
    );
};

type ProviderImageProps = { provider: Provider };
const ProviderImage = (props: ProviderImageProps) => {
    const translate = useTranslate();
    const image = parseProviderPosterImage(props.provider);
    const logo = props.provider.logoImageRef;

    return (
        <FlexColumn
            key={props.provider._id}
            shrink={0}
            crossAxis="center"
            css={{
                position: "relative",
                maxWidth: 400,
            }}>
            <TextBox
                text={translation({
                    sv: "Hurra! Nu drar vi igång!",
                    en: "Hurray! Let's get started!",
                })}
                weight="700"
                size="M"
                align="center"
            />
            <VerticalDivider />
            <ImageV2
                imageSource={image}
                radius={10}
                title={translate(props.provider.meta.title)}
                imageOptions={{
                    imageWidth: 400,
                    imageHeight: 240,
                    fit: true,
                }}
            />
            <div css={{ position: "relative", width: "100%" }}>
                <FlexRow
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="flex-end"
                    css={{
                        position: "absolute",
                        marginTop: -58,
                        paddingLeft: 8,
                        paddingRight: 8,
                        zIndex: 10,
                    }}>
                    <div
                        css={{
                            width: 50,
                            height: 50,
                            display: "inline-flex",
                            borderRadius: 3000,
                            overflow: "hidden",
                            backgroundColor: "#fff",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #fff",
                            visibility: logo ? "visible" : "hidden",
                        }}>
                        <Conditional
                            when={!!logo}
                            render={() => (
                                <ImageV2
                                    imageSource={logo}
                                    radius={10}
                                    title={translate(props.provider.meta.title)}
                                    imageOptions={{
                                        imageWidth: 50,
                                        imageHeight: 50,
                                        fit: true,
                                    }}
                                />
                            )}
                        />
                    </div>
                </FlexRow>
            </div>

            <VerticalDivider />
            {/* <TextBox
                text={props.provider.meta.title}
                weight="700"
                size="ML"
                align="center"
            /> */}
            <VerticalDivider />
        </FlexColumn>
    );
};

export { AcceptInvitation };
