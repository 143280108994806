import { ModalId } from "@@/modals/context/modal-id";
import { useModalContext } from "@@/modals/context/modal.context";
import { Modal } from "@@/modals/modal";
import { WithProvider } from "@@/providers/state/queries/provider/with-provider";
import { FlexColumn } from "@@/shared/flex-containers";
import { use100vh } from "@@/shared/use-100-vh";
import { Percentage, Product, minOf } from "@towni/common";
import { Navigate } from "react-router-dom";
import ProductDetailsPage, { ExtrasProps } from "../product-details-page";

type Props = {
    readonly product: Product;
    readonly modalId: ModalId;
    extras?: ExtrasProps;
};

const BookableProductExtraModal = (props: Props) => {
    const hideModal = useModalContext(context => context.hideModal);
    const vh100 = use100vh();
    return (
        <Modal height={0.9 as Percentage} modalId={props.modalId}>
            <FlexColumn
                fillParent
                // minHeight={"fit-content"}
                padding={{ bottom: 20 }}
                crossAxis="stretch"
                css={{
                    minHeight: minOf([vh100 * 0.85, 1000]),
                    maxHeight: vh100 * 0.9,
                    backgroundColor: "white",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <WithProvider
                    providerIdOrSlug={props.product.providerId}
                    options={{
                        whenNotFound: <Navigate to="/" replace />,
                    }}>
                    {provider => {
                        return (
                            <ProductDetailsPage
                                pageAnimationDisabled={true}
                                provider={provider}
                                product={props.product}
                                hideLinkToShop={true}
                                modalMode={true}
                                extras={props.extras}
                                goBack={() => hideModal(props.modalId)}
                            />
                        );
                    }}
                </WithProvider>
            </FlexColumn>
        </Modal>
    );
};

export { BookableProductExtraModal };
