import { apiFetchClient } from "@@/shared/fetch-client";
import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import { AccessKeyId, GetSingleResponse, OrderId } from "@towni/common";
const key = "lm-qr-orders";

const fetchForOrder =
    (order: OrderId | undefined, ak: AccessKeyId | undefined) => async () => {
        if (!order) throw new Error("missing orderId id");
        const data = await apiFetchClient.get<GetSingleResponse<string>>({
            route: `/orders/${encodeURIComponent(order)}/lasse-maja${
                ak ? `?ak=${ak}` : ""
            }`,
        });
        return data.item;
    };

const useOrderLasseMajaQR = (
    orderId: OrderId | undefined,
    ak: AccessKeyId | undefined
) =>
    useSingleApiResource<string>([key, orderId], fetchForOrder(orderId, ak), {
        enabled: !!orderId,
    });

export { useOrderLasseMajaQR };
