import * as React from "react";
import { useEffect } from "react";
import { Except } from "type-fest";
import { WizardAnimationWrapper } from "./page-navigation/wizard-animation-wrapper";
import {
    useWizardDispatch,
    useWizardState,
    WizardDispatchActions,
    WizardProvider,
    WizardState,
} from "./wizard-context";

type WizardPageRender = (wizard: {
    state: WizardState;
    dispatch: WizardDispatchActions;
}) => React.ReactNode;
type WizardPage = {
    id: string;
    render?: JSX.Element | WizardPageRender;
};
const wizardPage = (
    id: string,
    render: WizardPageRender | JSX.Element,
): WizardPage => ({
    id,
    render,
});
type Props = {
    onCancel?: () => void;
    onFinish?: () => void;
    children: WizardPage[];
    initialPageId?: string | number;
    ignoreAnimation: boolean;
};

const WizardPages = ({
    children: pages,
    initialPageId,
}: Except<Props, "onCancel" | "onFinish">) => {
    const dispatch = useWizardDispatch();
    const state = useWizardState();
    const [initialPageIndex] = React.useState<number>(
        typeof initialPageId !== "undefined"
            ? typeof initialPageId === "string"
                ? pages.findIndex(item => item.id === initialPageId)
                : initialPageId
            : 0,
    );
    const [hasSetInitial, setInit] = React.useState(false);

    const page = pages[state.index];
    const pageIdsString = pages.map(p => p.id).join("-");
    useEffect(() => {
        // on first run, set initial pages for context
        // console.log("INITIAL PAGE INDEX", {
        //     initialPageId,
        //     initialPageIndex,
        //     pages,
        //     state,
        //     hasSetInitial,
        // });

        dispatch.setPages(
            pages.map(page => page.id),
            hasSetInitial ? state.index : initialPageIndex,
        );
        setInit(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIdsString]);

    const pageToRender = page ?? pages[0] ?? null;
    return (
        <WizardAnimationWrapper
            ignoreAnimation={state.ignoreAnimation}
            wizardId={pageToRender?.id ?? "null"}
            direction={state.latestDirection}>
            {pageToRender.render && React.isValidElement(pageToRender.render)
                ? pageToRender.render
                : (pageToRender.render as WizardPageRender)?.({
                      state,
                      dispatch,
                  }) || null}
        </WizardAnimationWrapper>
    );
};

const Wizard = (props: Props) => {
    return (
        <WizardProvider
            ignoreAnimation={props.ignoreAnimation}
            onCancel={props.onCancel}
            onFinish={props.onFinish}>
            <WizardPages {...props} />
        </WizardProvider>
    );
};

export { Wizard, wizardPage, WizardPages };
