import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Paragraph, TextBox, paragraphLineBreakFactory } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { OrderItemOptional_V2, translation } from "@towni/common";
type Props = {
    orderItems: OrderItemOptional_V2[];
};

const BookableLegacyOptional = (props: Props) => {
    const theme = useTheme();
    if (props.orderItems.length === 0) return null;
    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.light50}
                    text={translation({ sv: "Tillval", en: "Optional" })}
                />

                <VerticalDivider XXS />
                <Paragraph
                    css={{ fontWeight: 800, fontSize: "1rem" }}
                    content={props.orderItems.flatMap(orderitem => {
                        return [
                            orderitem.meta.title,
                            paragraphLineBreakFactory(),
                        ];
                    })}
                />
            </FlexColumn>
        </FlexRow>
    );
};

export { BookableLegacyOptional };
