import { Except } from "type-fest";

type ParagraphJsxElement = {
    _type: "PARAGRAPH_JSX_ELEMENT";
    element: JSX.Element;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphJsxElement = (item: any): item is ParagraphJsxElement => {
    return item?._type === "PARAGRAPH_JSX_ELEMENT";
};

const paragraphJsxElementFactory = (
    params: Except<ParagraphJsxElement, "_type">,
): ParagraphJsxElement => {
    return {
        _type: "PARAGRAPH_JSX_ELEMENT",
        ...params,
    };
};

const ParagraphJsxElement = (props: {
    params: ParagraphJsxElement;
    className?: string;
}) => {
    return <>{props.params.element}</>;
};

export {
    ParagraphJsxElement,
    isParagraphJsxElement,
    paragraphJsxElementFactory,
};
