/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import {
    useScrollPosition,
    useScrollPositionStore,
} from "@@/pages/scroll-position-context";
import { browserLogger } from "@@/settings";
import { WidthAndHeight } from "@@/shared/width-and-height";
import { setWithinRange } from "@towni/common";
import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
    ListChildComponentProps,
    VariableSizeList,
    VariableSizeListProps,
} from "react-window";
import { Except } from "type-fest";

// type MapValueType<T> = T extends Map<unknown, infer V> ? V : never;
// type ArrayType<T> = T extends (infer U)[] ? U : never;
const defaultItemWidth = 100;
type RenderListItemProps<Item extends unknown = unknown> =
    ListChildComponentProps<Item[]> & {
        onSizeChange: (size: WidthAndHeight) => void | Promise<void>;
        isFirst: boolean;
        isLast: boolean;
        item: Item;
    };

type RenderListItem<Item extends unknown = unknown> = (
    props: RenderListItemProps<Item>,
) => JSX.Element;

type Props<ItemArray extends unknown[] = unknown[]> = Except<
    VariableSizeListProps<ItemArray>,
    "children" | "itemSize" | "itemCount"
> & {
    itemData: NonNullable<VariableSizeListProps<ItemArray>["itemData"]>;
} & {
    /**
     * defaults to vertical
     *
     * @type {("horizontal" | "vertical")}
     */
    readonly layout?: "horizontal" | "vertical";
    readonly spinner?: JSX.Element;
    readonly renderItem: RenderListItem<ItemArray[number]>;
    readonly paddingStart?: number;
    readonly paddingEnd?: number;
    readonly gap?: number;
    readonly listRef?: MutableRefObject<VariableSizeList<ItemArray> | null>;
    readonly listId?: string;
};

const VirtualList = <ItemArray extends unknown[] = unknown[]>(
    props: Props<ItemArray> & { dataTestId?: string },
) => {
    const {
        spinner: _spinner,
        layout = "vertical",
        // itemKey: itemKey,
        listId,
        paddingStart,
        paddingEnd,
        gap,
        renderItem,

        ...listProps
    } = props;

    const listRef = useRef<VariableSizeList<ItemArray> | null>(null);
    const sizeCache = useRef(new Map<number, WidthAndHeight>());

    const lastScrollPosition = useScrollPosition(listId ?? "");

    const scrollPositionRecorder = useScrollPositionStore(
        state => state.recordScrollPosition,
    );

    const renderItemFunc = useCallback(
        (params: ListChildComponentProps<ItemArray>) => {
            const { style, data, ...rest } = params;
            const item = data[rest.index];
            const isFirst = rest.index === 0;
            const isLast = rest.index === data.length - 1;
            const isHz = layout === "horizontal";

            const paddingStartValue =
                paddingStart ??
                (isHz
                    ? style.paddingLeft ?? style.padding ?? 0
                    : style.paddingTop ?? style.padding ?? 0);
            const paddingEndValue =
                paddingEnd ??
                (isHz
                    ? style.paddingRight ?? style.padding ?? 0
                    : style.paddingBottom ?? style.padding ?? 0);
            const gapValue = gap ?? 0;

            return (
                <div
                    key={rest.index}
                    style={{
                        overflow: "hidden",
                        paddingTop: isHz
                            ? undefined
                            : isFirst
                              ? paddingStartValue
                              : 0,
                        paddingLeft: isHz
                            ? isFirst
                                ? paddingStartValue
                                : 0
                            : undefined,
                        paddingBottom: isHz
                            ? undefined
                            : isLast
                              ? paddingEndValue
                              : gapValue,
                        paddingRight: isHz
                            ? isLast
                                ? paddingEndValue
                                : gapValue
                            : undefined,
                        ...style,
                    }}>
                    {renderItem({
                        ...rest,
                        style,
                        data,
                        item,
                        isFirst: params.index === 0,
                        isLast: params.index === data.length - 1,
                        onSizeChange: (size: WidthAndHeight) => {
                            const calculateSize = (
                                sizeType: "width" | "height",
                                isFirst: boolean,
                                isLast: boolean,
                            ): number => {
                                let value = size[sizeType];
                                value += isFirst ? paddingStart ?? 0 : 0;
                                value += isLast ? paddingEnd ?? 0 : 0;
                                value += !isLast ? gapValue : 0;
                                return value;
                            };

                            const _size: WidthAndHeight = {
                                width: isHz
                                    ? calculateSize("width", isFirst, isLast)
                                    : size.width,
                                height: !isHz
                                    ? calculateSize("height", isFirst, isLast)
                                    : size.height,
                            };
                            const current = sizeCache.current.get(params.index);
                            if (
                                current?.width === _size.width &&
                                current?.height === _size.height
                            ) {
                                // Size hasn't changed
                                return;
                            }
                            if (
                                (current && _size.width === 0) ||
                                _size.height === 0
                            ) {
                                browserLogger.debug({
                                    message: "Size is 0 - will not update size",
                                    data: {
                                        current,
                                        _size,
                                    },
                                });
                                return;
                            }
                            sizeCache.current.set(params.index, _size);
                            // clear cached data and rerender
                            listRef.current?.resetAfterIndex(
                                setWithinRange(params.index, {
                                    min: 0,
                                    max: data.length - 1,
                                }),
                            );
                        },
                    })}
                </div>
            );
        },
        [renderItem, paddingStart, paddingEnd, layout],
    );

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [props.itemData]);
    return (
        <VariableSizeList<ItemArray>
            ref={node => {
                if (!node) return;

                listRef.current = node;
                if (props.listRef) {
                    props.listRef.current = node;
                }
            }}
            {...listProps}
            className={props.dataTestId ?? ""}
            itemCount={props.itemData.length}
            itemSize={(index: number) => {
                const width =
                    sizeCache.current?.get(index)?.[
                        layout === "horizontal" ? "width" : "height"
                    ] ??
                    props.estimatedItemSize ??
                    defaultItemWidth;
                return width;
            }}
            initialScrollOffset={
                layout === "horizontal"
                    ? lastScrollPosition?.scrollLeft
                    : lastScrollPosition?.scrollTop
            }
            onScroll={params => {
                if (!listId) return;
                scrollPositionRecorder({
                    id: listId,
                    position: {
                        scrollLeft: params.scrollOffset,
                        scrollTop: params.scrollOffset,
                    },
                });
            }}
            layout={layout}>
            {renderItemFunc}
        </VariableSizeList>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const AutoSizedVirtualList = <ItemArray extends unknown[] = unknown[]>(
    props: Except<Props<ItemArray>, "width" | "height"> & {
        dataTestId?: string;
    },
) => {
    return (
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <VirtualList<ItemArray>
                        {...props}
                        dataTestId={props.dataTestId}
                        width={width ?? defaultItemWidth}
                        height={height ?? defaultItemWidth}
                    />
                );
            }}
        </AutoSizer>
    );
};

export { AutoSizedVirtualList, VirtualList as VirtualList2 };
export type { RenderListItem, RenderListItemProps };
