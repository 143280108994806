import { useModal } from "@@/modals/use-modal";
import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { Provider, emailTags, translation } from "@towni/common";
import { FC } from "react";
import { ContactProviderModal } from "./contact-provider-modal";
import { useProviderPublicPhoneNumber } from "./use-provider-public-phone-number";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
    provider: Provider;
};

const ContactProvider: FC<Props> = props => {
    const [{ toggle }, modalId] = useModal("contact_provider_modal__");

    const publicPhoneNumber = useProviderPublicPhoneNumber(
        props.provider,
        true
    );
    const providerPublicMail = props.provider?.emails.find(
        email =>
            email.categoryTags.includes(emailTags.public.tag) && email.verified
    );
    // const firstRun = useRef(true);
    // if (!publicPhoneNumber) {
    //     firstRun.current = false;
    //     return null;
    // }

    return (
        <>
            <FlexRow
                onClick={toggle}
                css={{
                    "@media print": {
                        display: "none",
                    },
                }}>
                <TextBox
                    text={translation({
                        sv: "Kontakt",
                        en: "Contact",
                    })}
                    weight="400"
                />
                {/* todo: make above replacements dynamic as a global setting (regex based) to easily support more in the future without app deploys */}
            </FlexRow>
            <ContactProviderModal
                modalId={modalId}
                provider={props.provider}
                phoneNumber={publicPhoneNumber}
                publicMail={providerPublicMail?.emailAddress}
            />
        </>
    );
};

export { ContactProvider };
