import {
    OnResizeCallback,
    useResizeObserver,
} from "@@/shared/use-resize-observer";
import { useCallback, useRef } from "react";
import { Except } from "type-fest";

type DomRectJSON = Except<DOMRect, "toJSON">;
const defaultRect: DomRectJSON = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
};

const useSizeTracker = <T extends HTMLElement = HTMLDivElement>(
    onSizeChange?: (size: DomRectJSON) => void,
    boxToObserve: ResizeObserverBoxOptions = "border-box",
    sizeBox: "border-box" | "content-box" = "border-box",
) => {
    const targetRef = useRef<T | null>(null);
    const onSizeChangeRef = useRef(onSizeChange);
    onSizeChangeRef.current = onSizeChange;

    const onResize = useCallback<OnResizeCallback>(
        newSize => {
            // Since resize observer only measures the content box
            // we'll get the border box size and return that instead if requested
            const _newSize =
                sizeBox === "content-box"
                    ? newSize.contentBoxSize
                    : newSize.borderBoxSize;
            if (!_newSize) return;
            onSizeChangeRef?.current?.(_newSize);
        },
        [sizeBox],
    );

    // Where the magic happens
    useResizeObserver<T>({ targetRef, onResize, sizeToWatch: boxToObserve });
    return [targetRef] as const;
};

export { defaultRect, useSizeTracker };
export type { DomRectJSON as DOMRectJSON };
