import { browserLogger } from "@@/settings";
import {
    asDate,
    DurationInMs,
    DurationInSeconds,
    isoAndUnixFactory,
    IsoAndUnixTimestamp,
    TimeRange,
    timeRangeByDurationFactory,
    timeRangeFactory,
} from "@towni/common";
import { addMilliseconds, isSameDay } from "date-fns";
import { useState } from "react";

type DateSelectType = "SINGLE" | "RANGE" | "FIXED-LENGTH";

const useDateSelectRange = (props: {
    initiallySelected?: TimeRange;
    onSelect: (timeRange: TimeRange | IsoAndUnixTimestamp | undefined) => void;
    validate: (timeRange: TimeRange) => boolean;
    selectableDates: IsoAndUnixTimestamp[];
}): [
    IsoAndUnixTimestamp | undefined,
    IsoAndUnixTimestamp | undefined,
    (date: IsoAndUnixTimestamp) => void,
] => {
    const { initiallySelected, onSelect, validate } = props;
    const [startDate, setStartDate] = useState<IsoAndUnixTimestamp | undefined>(
        initiallySelected?.start,
    );
    const [endDate, setEndDate] = useState<IsoAndUnixTimestamp | undefined>(
        initiallySelected?.end,
    );

    const pickStartDate = (date: IsoAndUnixTimestamp) => {
        browserLogger.debug("pickStartDate", date);
        const currentDate = asDate(date);

        if (date.unix === startDate?.unix) {
            setStartDate(undefined);
            onSelect(undefined);
            return;
        }
        setStartDate(date);

        const range = timeRangeByDurationFactory(
            currentDate,
            0 as DurationInMs,
        );
        const validated = validate(range);

        if (validated) {
            setStartDate(date);
            setEndDate(date);
            onSelect(range);
            return;
        } else {
            setStartDate(date);
            setEndDate(undefined);
            onSelect(date);
            return;
        }
    };

    const toggleDate = (date: IsoAndUnixTimestamp) => {
        if (!startDate) {
            pickStartDate(date);
        }

        if (startDate && endDate && isSameDay(asDate(endDate), asDate(date))) {
            pickStartDate(date);
            return;
        }

        if (startDate) {
            const endDate = asDate(date);
            const range = timeRangeFactory(asDate(startDate), endDate);

            const validated = validate(range);

            if (validated) {
                setEndDate(date);
                onSelect(range);
                return;
            } else {
                //Check if we can pick only start date
                //if(date.unix !== startDate.unix)
                pickStartDate(date);
                return;
            }
        }
    };

    return [startDate, endDate, toggleDate];
};

type DateSelectSingleResult = [
    IsoAndUnixTimestamp | undefined,
    (date: IsoAndUnixTimestamp | undefined) => void,
];
function useDateSelectSingle(params: {
    onSelect: (date: IsoAndUnixTimestamp | undefined) => void;
    /**
     * Always force one date to be selected
     * @type {boolean}
     */
    forceSelection?: boolean;
    initiallySelected?: IsoAndUnixTimestamp;
}): DateSelectSingleResult {
    if (params.forceSelection && !params.initiallySelected)
        throw new Error(
            "initially selected date required when forceSelection is true",
        );

    const [startDate, setStartDate] = useState<IsoAndUnixTimestamp | undefined>(
        () => params.initiallySelected,
    );

    const selectDate = (date: IsoAndUnixTimestamp | undefined) => {
        if (params.forceSelection && typeof date === "undefined") return;
        setStartDate(date);
        params.onSelect(date);
    };

    return [startDate, selectDate];
}

const useDateSelectFixedLength = (props: {
    duration: DurationInSeconds;
    initiallySelected?: IsoAndUnixTimestamp;
    onSelect: (timeRange: TimeRange) => void;
}): [
    IsoAndUnixTimestamp | undefined,
    IsoAndUnixTimestamp | undefined,
    (date: IsoAndUnixTimestamp) => void,
] => {
    const { initiallySelected, onSelect, duration } = props;
    const [startDate, setStartDate] = useState<IsoAndUnixTimestamp | undefined>(
        initiallySelected,
    );
    const [endDate, setEndDate] = useState<IsoAndUnixTimestamp | undefined>(
        initiallySelected &&
            isoAndUnixFactory(
                addMilliseconds(asDate(initiallySelected), duration),
            ),
    );

    const toggleDate = (date: IsoAndUnixTimestamp) => {
        setStartDate(date);
        const calculatedEndDate = isoAndUnixFactory(
            addMilliseconds(asDate(date), duration),
        );
        setEndDate(calculatedEndDate);
        (onSelect as (timeRange: TimeRange) => void)(
            timeRangeFactory(asDate(date), asDate(calculatedEndDate)),
        );
    };

    return [startDate, endDate, toggleDate];
};

export { useDateSelectFixedLength, useDateSelectRange, useDateSelectSingle };
export type { DateSelectType };
