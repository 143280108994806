import { useModal } from "@@/modals/use-modal";
import { HorizontalDivider, HorizontalLine } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useManyGiftcardsForSearch } from "@@/tickets/giftcards/use-many-giftcards-search";
import { useTheme } from "@emotion/react";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import {
    OrderItemUsedGiftCard_V2,
    Price,
    RemSize,
    TicketGiftCard,
    defaultAmount,
    isOrderItemUsedGiftCard_V2,
    negatePrice,
    orderItemUsedGiftCardFactory_V2,
    priceFactory,
    subtractFromPrice,
    sumPrices,
    translation,
    uniqueBy,
} from "@towni/common";
import { useEffect, useState } from "react";
import { useCartContext } from "../multi-carts/cart.context";

import { useToast } from "@@/toasts/context/toast-context";
import { AddGiftCardModal } from "./add-giftcard-in-cart-modal";

const howMuchToUseOnGiftCard = (cartAmount: Price, giftcardAmount: Price) => {
    if (cartAmount.amountIncludingVat >= giftcardAmount.amountIncludingVat)
        return giftcardAmount.amountIncludingVat;

    return cartAmount.amountIncludingVat;
};

const GiftCardInCartRow = () => {
    const [{ show }, modalId] = useModal("gift_card_modal__");

    const theme = useTheme();
    const { cart, removeItemFromCart, addItemToCart } = useCartContext(
        context => ({
            cart: context.cart,
            removeItemFromCart: context.removeItemFromCart,
            addItemToCart: context.addItemToCart,
        }),
    );

    const toast = useToast();
    const providers = cart.orderItems.map(oi => oi.providerId);
    const giftcardItems = cart.orderItems.filter(
        isOrderItemUsedGiftCard_V2,
    ) as OrderItemUsedGiftCard_V2[];
    const [alreadyExisting, _query] = useManyGiftcardsForSearch(
        giftcardItems.map(g => g.code),
    );

    const [giftcards, setGiftCards] = useState<TicketGiftCard[]>([]);

    useEffect(() => {
        setGiftCards(uniqueBy([...alreadyExisting, ...giftcards], g => g._id));
    }, [alreadyExisting]);

    useEffect(() => {
        if (giftcards.length) {
            giftcards.map(giftcard => {
                if (!providers.includes(giftcard.providerId)) {
                    toast.warning({
                        message: translation({
                            sv: "Presentkort är inte giltigt",
                            en: "Giftcard is not valid",
                        }),
                    });
                    setGiftCards(giftcards.filter(g => g._id !== giftcard._id));
                    return;
                }

                if (
                    giftcard.amountUsed.amountIncludingVat >=
                    giftcard.amount.amountIncludingVat
                ) {
                    toast.warning({
                        message: translation({
                            sv: "Presentkortet är slut",
                            en: "Giftcard is already used",
                        }),
                    });
                    setGiftCards(giftcards.filter(g => g._id !== giftcard._id));
                    return;
                }

                const amount = sumPrices(
                    cart.orderItems
                        .filter(
                            oi =>
                                !(
                                    isOrderItemUsedGiftCard_V2(oi) &&
                                    oi.ticketId === giftcard._id
                                ),
                        )
                        .map(o => o.price),
                );

                const left = subtractFromPrice(giftcard.amount, [
                    giftcard.amountUsed,
                ]);

                if (
                    left.amountIncludingVat > 0 &&
                    amount.amountIncludingVat > 0
                ) {
                    const amountToUse = priceFactory({
                        amountIncludingVat: howMuchToUseOnGiftCard(
                            amount,
                            left,
                        ),
                        vat: giftcard.amount.vat,
                    });

                    const item = orderItemUsedGiftCardFactory_V2({
                        meta: giftcard.meta,
                        providerId: giftcard.providerId,
                        quantity: {
                            amount: defaultAmount,
                            value: 1,
                        },
                        messageToProvider: null,
                        price: negatePrice(amountToUse),
                        pricePerItem: negatePrice(amountToUse),
                        discounts: [],
                        ticketId: giftcard._id,
                        code: giftcard.code,
                    });

                    const existing = cart.orderItems.find(
                        oi =>
                            isOrderItemUsedGiftCard_V2(oi) &&
                            oi.ticketId === giftcard._id,
                    );
                    if (
                        !existing ||
                        existing.price.amountIncludingVat !==
                            item.price.amountIncludingVat
                    ) {
                        if (existing) removeItemFromCart(existing?._id);
                        addItemToCart(item);
                    }
                }
            });
        }
    }, [giftcards, cart]);

    return (
        <>
            <FlexColumn crossAxis="flex-start" fillParentWidth>
                <ForEach
                    itemOf={giftcards}
                    divider={<HorizontalLine fillParentWidth />}>
                    {giftcard => {
                        const splitGiftCode = [...giftcard.code]
                            .map((d, i) => (i % 4 === 0 ? " " + d : d))
                            .join("")
                            .trim();

                        return (
                            <>
                                <FlexRow
                                    fillParentWidth
                                    padding={{ leftRight: 20, topBottom: 15 }}>
                                    <FlexRow fillParentWidth>
                                        <TextBox
                                            text={translation({
                                                sv: "Presentkort tillagt:",
                                                en: "Giftcard added:",
                                            })}
                                            size="S"
                                            color={theme.colors.primary}
                                        />
                                        <HorizontalDivider XS />
                                        <TextBox
                                            text={splitGiftCode}
                                            size="S"
                                            color={theme.colors.black.light60}
                                        />
                                    </FlexRow>
                                    <FlexRow
                                        mainAxis="flex-end"
                                        css={{
                                            marginRight: -5,
                                        }}
                                        tag="actions">
                                        <Icon
                                            icon={faTrashAlt}
                                            cursor="pointer"
                                            onClick={() => {
                                                const existing =
                                                    cart.orderItems.find(
                                                        oi =>
                                                            isOrderItemUsedGiftCard_V2(
                                                                oi,
                                                            ) &&
                                                            oi.ticketId ===
                                                                giftcard._id,
                                                    );

                                                setGiftCards(
                                                    giftcards.filter(
                                                        g =>
                                                            g._id !==
                                                            giftcard._id,
                                                    ),
                                                );

                                                if (existing)
                                                    removeItemFromCart(
                                                        existing._id,
                                                    );
                                            }}
                                            color={theme.colors.black.light60}
                                            size={0.9 as RemSize}
                                        />
                                    </FlexRow>
                                </FlexRow>
                                <HorizontalLine fillParentWidth />
                            </>
                        );
                    }}
                </ForEach>
                <FlexRow
                    onClick={show}
                    fillParentWidth
                    padding={{ leftRight: 20, topBottom: 15 }}>
                    <TextBox
                        text={translation({
                            sv: "+ Använd presentkort",
                            en: "+ Use giftcard",
                        })}
                        size="S"
                        color={theme.colors.black.light60}
                    />
                </FlexRow>
            </FlexColumn>

            <AddGiftCardModal
                modalId={modalId}
                addGiftCard={newGiftCard => {
                    if (!giftcards.some(g => g._id === newGiftCard._id))
                        setGiftCards([...giftcards, newGiftCard]);
                }}
            />
        </>
    );
};

export { GiftCardInCartRow };
