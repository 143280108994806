import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { KronocampingDiscoverMoreSection } from "../kronocamping/sections/kronocamping-discover-more-section";
import { OneSquareLinkSectionSeeAll } from "./sections/one-square-link-section-see-all";
import { OneSquareSection } from "./sections/one-square-section";

type Props = {
    title?: string;
};

const StoreFront = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>{props.title ?? "SMaRT Resande"}</title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory("smart-store-front-grastorp")}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/nossans-hotell-vandrarhem/images/nossans-hotell-vandrarhem_image_reference__cD7e6MOyLmAw4NJq8EC2.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <TextBox
                        text="Grästorp elcykel äventyr"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Upptäck med el i benen"
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider M />
                    <TextBox
                        text="Upptäck grästorp vecka 23-25"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Här har vi satt samman sköna turer för dig som vill se landskap och ta nån avstickare."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider XL />

                    <OneSquareSection
                        // elcykel
                        productId={"product__McrtXJ0w62qyYKrUzShi" as ProductId}
                        actionText={translation({
                            sv: "Utforska",
                            en: "Explore",
                        })}
                    />
                    <OneSquareSection
                        // rundan
                        productId={"product__S-EIL76GtiGVCuh7BKWU" as ProductId}
                        actionText={translation({
                            sv: "Natur",
                            en: "Explore",
                        })}
                    />
                    <OneSquareSection
                        // transport
                        productId={"product__HPDJPePJzQkJoYQTi1cW" as ProductId}
                        actionText={translation({
                            sv: "Utforska",
                            en: "Explore",
                        })}
                    />
                    <OneSquareLinkSectionSeeAll />
                    <VerticalDivider XXL />
                    <FlexColumn crossAxis="center">
                        <TextBox
                            text={translation({
                                sv: "Upplev mer lokalt",
                                en: "Experience more local",
                            })}
                            size={2}
                            weight="700"
                            align="center"
                        />
                        <VerticalDivider M />
                        <TextBox
                            text={translation({
                                sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                            })}
                            color={theme.colors.black.light50}
                            size={1.125}
                            weight="400"
                            align="center"
                            css={{
                                lineHeight: "1.6rem",
                            }}
                        />
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <KronocampingDiscoverMoreSection />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                {/* <<<< Content */}
            </AppPage>
        </WideScreenWrapper>
    );
};

export { StoreFront as SmartStoreFrontGrastorp };
