import { useBrandingForProviderQuery } from "@@/providers/state/queries/provider/use-branding-for-provider";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { AppThemeProvider } from "@@/styles/app-theme-provider";
import { lightColorTheme } from "@@/styles/themes/default.theme";
import { UseQueryResult } from "@tanstack/react-query";
import { Branding, ProviderId } from "@towni/common";
import React, { createContext, useMemo } from "react";
import { defaultBranding } from "./default-branding";

type State = {
    providerId: ProviderId | undefined;
    branding: Branding | undefined;
    brandingQuery: UseQueryResult<Branding | null>;
};
type Actions = {
    setProviderId: (providerId: ProviderId | undefined) => void;
};

type Context = State & Actions;

const brandingContext = createContext<Context | undefined>(undefined);

const BrandingProvider = (props: {
    providerId?: ProviderId;
    children?: React.ReactNode;
}) => {
    const brandingQuery = useBrandingForProviderQuery(props.providerId);
    const branding = brandingQuery.data || defaultBranding;
    const [providerId, setProviderId] = React.useState(props.providerId);

    useUpdateEffect(() => {
        setProviderId(props.providerId);
    }, [props.providerId]);

    const context = useMemo(() => {
        return {
            providerId,
            branding,
            brandingQuery,
            setProviderId,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerId, branding, lightColorTheme]);

    return (
        <brandingContext.Provider value={context}>
            <AppThemeProvider lightColorTheme={branding?.colorThemes?.light}>
                {props.children}
            </AppThemeProvider>
        </brandingContext.Provider>
    );
};

const useBrandingContext = () => {
    const context = React.useContext(brandingContext);
    if (context === undefined) {
        throw new Error(
            "useBrandingContext must be used within a BrandingProvider",
        );
    }
    return context;
};

export { BrandingProvider, useBrandingContext };
