import { apiFetchClient } from "@@/shared/fetch-client";
import {
    asArray,
    BookableSession,
    emptyArrayOf,
    GetResponse,
    ProductId,
    TimeRange,
    UnixTimestamp,
} from "@towni/common";

const fetchSessions =
    (productId: ProductId | undefined, timeRange?: TimeRange) => async () => {
        if (!productId) throw new Error("missing product id");
        const data = await apiFetchClient.get<GetResponse<BookableSession>>({
            route: `/sessions/${encodeURIComponent(productId)}${
                timeRange
                    ? `?from=${timeRange.start.unix}&to=${timeRange.end.unix}`
                    : ""
            }`,
        });
        return data.items;
    };

const fetchSessionsForProducts =
    (productIds: ProductId[] | ProductId | undefined, timeRange?: TimeRange) =>
    async () => {
        if (typeof productIds === "undefined")
            return emptyArrayOf<BookableSession>();
        const ids = asArray(productIds);
        if (!ids?.length) return emptyArrayOf<BookableSession>();
        const data = await apiFetchClient.post<
            {
                productIds: ProductId[];
                to?: UnixTimestamp;
                from?: UnixTimestamp;
            },
            GetResponse<BookableSession>
        >({
            route: `/sessions/for-products`,

            body: {
                productIds: ids,
                from: timeRange?.start.unix,
                to: timeRange?.end.unix,
            },
        });
        return data.items;
    };
export { fetchSessions, fetchSessionsForProducts };
