import { apiFetchClient } from "@@/shared/fetch-client";
import { CompanyDetails, GetResponse, ProviderId } from "@towni/common";

const fetchCompanyDetailsForProvider =
    (providerId?: ProviderId) => async (): Promise<CompanyDetails[]> => {
        if (!providerId) throw new Error("missing id");
        const data = await apiFetchClient.get<GetResponse<CompanyDetails>>({
            route: `/company-details/for-provider/${providerId}`,
        });
        return data.items;
    };

export { fetchCompanyDetailsForProvider };
