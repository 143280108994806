import { ModalId } from "@@/modals/context/modal-id";
import { css, useTheme } from "@emotion/react";
import { UserAddress } from "@towni/common";
import { FC, useState } from "react";
import { FlexColumn } from "../../flex-containers";
import { AddressPickerManualForm } from "./address-picker-manual-form";
import { AddressPickerManualMap } from "./address-picker-manual-map";

type Props = {
    readonly current: Partial<UserAddress>;
    readonly modalId: ModalId;
    readonly update: (address: Partial<UserAddress>) => void;
    readonly save: () => void;
    readonly isEdit: boolean;
    readonly goBack: () => void;
    hideAddressNameField?: boolean;
};

const AddressPickerManual: FC<Props> = props => {
    const theme = useTheme();
    const [status, setStatus] = useState<"FORM" | "MAP">(
        props.isEdit ? "FORM" : "MAP",
    );

    return (
        <FlexColumn
            fillParent
            overflowY="auto"
            background={{ color: theme.colors.default.background }}>
            <div
                css={css`
                    height: ${status === "FORM" ? "40vh" : "100%"};
                `}>
                <AddressPickerManualMap
                    modalId={props.modalId}
                    current={props.current}
                    save={props.save}
                    goBack={props.goBack}
                    update={props.update}
                    isPreview={status === "FORM"}
                    toggleFullscreen={() => {
                        // console.log("FINISH", status);
                        setStatus(status === "FORM" ? "MAP" : "FORM");
                    }}
                />
            </div>
            {status === "FORM" ? (
                <AddressPickerManualForm
                    modalId={props.modalId}
                    current={props.current}
                    save={props.save}
                    update={props.update}
                    isEdit={props.isEdit}
                    hideAddressNameField={props.hideAddressNameField}
                />
            ) : null}
        </FlexColumn>
    );
};

export { AddressPickerManual };
