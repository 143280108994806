import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { useTheme } from "@emotion/react";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";

const BauerVinterMysSection: FC = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const christmasConcert = React.useMemo(
        () => getProductId("product__u6_QrEdMVwIUae3nlsZj" as ProductId),
        []
    );
    const moniker = React.useMemo(
        () => getProductId("product__4QtdeDHHDnBbgRVMVoBB" as ProductId),
        []
    );
    const winterwalk = React.useMemo(
        () => getProductId("product__U7KSSS_UtQHEhBsRaaba" as ProductId),
        []
    );
    const newYear = React.useMemo(
        () => getProductId("product__C8zx-TYeVT6ARNaM6nbV" as ProductId),
        []
    );
    const productIds = [christmasConcert, moniker, winterwalk, newYear];

    return (
        <SectionArea
            title={translation({
                sv: "Vintermys i Bunn",
                en: "Winter in Bunn",
            })}
            subtitle={translation({
                sv: "Välkomna till vackra Bunn, en trolsk plats nära naturen. Här kan du landa i en rofylld miljö och njuta av god mat och varma bastubad. Vandra, cykla eller bara njuta av lugnet - stanna och upplev den trolska platsen! ",
                en: "",
            })}
            sectionAreaId="bauer-vinter-4-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id}
                            productId={id}
                            aspectRatio={"1:1"}
                            contentTop={index === 3 ? true : index % 2 === 0}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={theme.colors.primary}
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Boka",
        en: "Book",
    }),
    explore: translation({
        sv: "Beställ",
        en: "Book",
    }),
    seeTime: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
};

const actionTexts = [text.book, text.explore, text.seeTime];

export { BauerVinterMysSection };
