import { useCart } from "@@/carts/multi-carts/cart.context";
import { browserLogger } from "@@/settings";
import {
    Discount,
    OrderItemBuyable,
    OrderItemBuyable_V2,
    OrderItemId,
    OrderItem_V2,
    PickedOptional,
    Product,
    Provider,
    Sku,
    SkuId,
    emptyArrayOf,
    getChildOptionals,
    orderItemsFromSku,
    setWithinRange,
} from "@towni/common";
import * as React from "react";
import { StoreApi, create as zustand } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { ExtrasProps } from "./product-details-page";

type State = {
    readonly addToCartDisabled: boolean;
    readonly product: Product;
    readonly provider: Provider;
    readonly quantityValue: number;
    readonly selectedSku: Sku | undefined;
    readonly orderItemToReplaceId?: OrderItemId;
    readonly pickedOptionals?: PickedOptional[];
    readonly discounts?: Discount[];
    readonly messageToProvider?: string;
};

type Actions = {
    readonly setAddToCartDisabled: (disabled: boolean) => void;
    readonly getOrderItem: () => OrderItem_V2[] | undefined;
    readonly setQuantity: (value: number) => void;
    readonly increaseQuantity: (value?: number) => void;
    readonly decreaseQuantity: (value?: number) => void;
    readonly selectSku: (skuId: SkuId) => void;
    readonly setPickedOptionals: (pickedOptionals: PickedOptional[]) => void;
    readonly setDiscounts: (discounts: Discount[]) => void;
    readonly setMessageToProvider: (message: string) => void;
};

type Context = State & Actions;

const ProductDetailContext = React.createContext<StoreApi<Context>>(
    undefined as unknown as StoreApi<Context>,
);

type Props = {
    readonly initialQuantity?: number;
    readonly orderItemId?: OrderItemId;
    readonly extras?: ExtrasProps;
    readonly product: Product;
    readonly provider: Provider;
    readonly children: React.ReactNode;
};

const ProductDetailsContextProvider = (props: Props) => {
    const cart = useCart();
    const originalOrderItem = cart.orderItems.find(
        item =>
            item._id === props.orderItemId && item.acquireType === "BUYABLE",
    ) as OrderItemBuyable | OrderItemBuyable_V2 | undefined;
    if (
        originalOrderItem &&
        (props.product.providerId !== originalOrderItem.providerId ||
            props.product._id !== originalOrderItem.productId)
    ) {
        throw new Error(
            "ProductDetailsContextProvider: product and orderItem do not match",
        );
    }

    const createStore = () => {
        return zustand<Context>((set, get) => {
            // set initial state
            const optionals = originalOrderItem
                ? getChildOptionals(originalOrderItem, cart.orderItems).map(
                      o => {
                          return { ...o, quantity: o.quantity ?? 1 };
                      },
                  )
                : emptyArrayOf<PickedOptional>();
            const state: State = {
                addToCartDisabled: true,
                quantityValue: props.initialQuantity ?? 1,
                selectedSku: originalOrderItem
                    ? props.product.skus.find(
                          item => item._id === originalOrderItem?.skuId,
                      )
                    : props.product.skus.length === 1
                      ? props.product.skus[0]
                      : undefined,
                orderItemToReplaceId: props.orderItemId,
                pickedOptionals: optionals,
                //  originalOrderItem?.pickedOptionals ??
                //   emptyArrayOf<PickedOptional>(),
                discounts:
                    originalOrderItem?.discounts ?? emptyArrayOf<Discount>(),
                messageToProvider:
                    originalOrderItem?.messageToProvider ?? undefined,
                product: props.product,
                provider: props.provider,
            };

            const actions: Actions = {
                setAddToCartDisabled: (disabled: boolean) => {
                    set({ addToCartDisabled: disabled });
                },
                getOrderItem: () => {
                    const state = get();
                    if (!state.product) {
                        browserLogger.log(
                            "ProductDetailsContextProvider: no product provided",
                        );
                        return undefined;
                    }
                    if (!state.selectedSku) {
                        browserLogger.log(
                            "ProductDetailsContextProvider: no sku selected",
                        );
                        return undefined;
                    }
                    if (!state.quantityValue) {
                        browserLogger.log(
                            "ProductDetailsContextProvider: no quantity selected",
                        );
                        return undefined;
                    }

                    const sku = state.product.skus.find(
                        sku => sku._id === state.selectedSku?._id,
                    );
                    if (!sku) {
                        browserLogger.log(
                            "ProductDetailsContextProvider: sku not found within given product",
                        );
                        return undefined;
                    }
                    browserLogger.log(
                        "ProductDetailsContextProvider: creating order item",
                        orderItemsFromSku,
                    );

                    const orderItems = orderItemsFromSku({
                        sku,
                        product: state.product,
                        provider: state.provider,
                        quantity: {
                            amount: sku.acquire.amount,
                            value: state.quantityValue,
                        },
                        pickedOptions: state.pickedOptionals ?? [],
                        discounts: state.discounts ?? [],
                        messageToProvider: state.messageToProvider,
                        //parentId: props.extras?.parentOrderItemId,
                        extra: props.extras
                            ? {
                                  _id: props.extras.extras._id,
                                  meta: props.extras.extras.meta,
                              }
                            : undefined,
                    });
                    return orderItems;
                },
                setQuantity: (value: number) => {
                    set({ quantityValue: setWithinRange(value, { min: 1 }) });
                },
                increaseQuantity: (value = 1) => {
                    set(state => {
                        return {
                            ...state,
                            quantityValue: setWithinRange(
                                state.quantityValue + value,
                                { min: 1 },
                            ),
                        };
                    });
                },
                decreaseQuantity: (value = 1) => {
                    set(state => ({
                        ...state,
                        quantityValue: setWithinRange(
                            state.quantityValue - value,
                            { min: 1 },
                        ),
                    }));
                },
                selectSku: (skuId: SkuId) => {
                    const state = get();
                    const sku = state.product.skus.find(
                        sku => sku._id === skuId,
                    );
                    if (!sku) {
                        throw new Error(
                            "ProductDetailsContextProvider: sku not found",
                        );
                    }
                    set({ selectedSku: sku });
                },
                setPickedOptionals: (pickedOptionals: PickedOptional[]) => {
                    // TODO! verify all optionals exists on product
                    set({ pickedOptionals });
                },
                setDiscounts: (discounts: Discount[]) => {
                    // TODO! verify all discounts exists on product
                    set({ discounts });
                },
                setMessageToProvider: (message: string) => {
                    set({ messageToProvider: message });
                },
            };

            const context: Context = {
                ...state,
                ...actions,
            };

            return context;
        });
    };
    const store = React.useRef(createStore()).current;

    return (
        <ProductDetailContext.Provider value={store}>
            {props.children}
        </ProductDetailContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useProductDetailsContext = <U extends unknown = Context>(
    selector: (context: Context) => U = context => context as unknown as U,
): U => {
    const store = React.useContext(ProductDetailContext);
    if (store === undefined) {
        throw new Error(
            "useProductDetailsContext must be used within a ProductDetailContext",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { ProductDetailsContextProvider, useProductDetailsContext };
