import { Provider } from "@towni/common";
import { useEffect, useState } from "react";

const isProviderHidden = (provider: Provider) => !!provider.options?.hidden;

const useIsProviderHidden = (provider: Provider) => {
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        setIsHidden(!!(provider && isProviderHidden(provider)));
    }, [provider]);

    return isHidden;
};

export { isProviderHidden, useIsProviderHidden };
