import { Conditional } from "@@/shared/conditional";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { DateSelectionHzSingle } from "@@/shared/date-time/date-time-selection/dates/date-selection-hz/data-selection-hz-single";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useQueryOrParam } from "@@/shared/use-query-or-param";
import { useTheme } from "@emotion/react";
import {
    BookableSession,
    IsoAndUnixTimestamp,
    Time,
    TimeRange,
    asDate,
    calculateMaxSessionLengths,
    firstOf,
    isoAndUnixFactory,
    lastOf,
    startOfDayZoned,
    timeRangeFactory,
    toFullDate,
    toUnix,
    translation,
} from "@towni/common";
import { compareAsc, endOfDay, isSameDay, lastDayOfMonth } from "date-fns";
import * as React from "react";
import { useMemo } from "react";
import { useBookingContext } from "../../../booking-context";
import { BookableDateVtSelect } from "./bookable-date-vt-select";
import { BookableSessionSelectList } from "./bookable-session-select-list";
import { BookableSessionSelectTimeGrid } from "./bookable-session-select-time-grid";

type Props = {
    sessions: BookableSession[];
    onTimeSelect?: (time: Time) => void;
    //Used by orderChange to ignore session unaviabliry due to time
    readonly ignoreSession?: boolean;
};

const BookableSessionSelect = (props: Props) => {
    const theme = useTheme();
    const override = useQueryOrParam("overridepicker");
    const state = useBookingContext(state => ({
        session: state.session,
        bookable: state.bookable,
        timeRange: state.timeRange,
        clearSession: state.clearSession,
    }));
    const selectableDates = React.useMemo(() => {
        const selectables = props.sessions
            .filter(s => s._status === "ACTIVE")
            .flatMap(t => {
                if (
                    t.durationGranularity === "DAY" ||
                    t.durationGranularity === "NIGHT"
                ) {
                    //Needs to get max date if end-date is another months
                    const maxed = calculateMaxSessionLengths(t);
                    return [maxed.start, maxed.end];
                }
                return [t.start];
            })
            .map(a => asDate(a))
            .sort(compareAsc);
        const ret = Array.from(
            new Set(selectables.map((item: Date) => item.getTime())),
        ).map(n => new Date(n));
        return ret;
    }, [props.sessions]);

    const forDayOrNight = React.useMemo(() => {
        const pickerFor = props.sessions[0]?.durationGranularity;
        return pickerFor === "DAY" || pickerFor === "NIGHT";
    }, [props.sessions]);

    const view = useMemo(() => {
        if (override) {
            return override;
        }

        if (forDayOrNight) return "DATE_VT";

        switch (state.bookable.type) {
            case "TABLE_BOOKING":
                return "TIME_GRID";
            default:
                return "DEFAULT";
        }
    }, [state.bookable.type, forDayOrNight]);

    const [selectedDate, setSelectedDate] = React.useState<
        IsoAndUnixTimestamp | Date | undefined
    >(() => {
        // if (view !== "TIME_GRID") return undefined;
        // First pick date from selected session in context if any...
        return (
            state.session?.start ??
            // else try to find next date available from beginning of day and forward..
            selectableDates.find(
                date =>
                    toUnix(date) >
                    toUnix(startOfDayZoned(new Date(), currentTimezoneName)),
            )
        );
    });

    React.useEffect(() => {
        if (
            selectedDate &&
            state.timeRange &&
            toFullDate(asDate(selectedDate), currentTimezoneName) !==
                toFullDate(state.timeRange.start, currentTimezoneName)
        ) {
            state.clearSession();
        }
    }, [selectedDate]);

    const bookableSessions = React.useMemo(() => {
        const data = !selectedDate
            ? props.sessions.filter(f => f._status === "ACTIVE")
            : props.sessions
                  .filter(f => f._status === "ACTIVE")
                  .filter(time =>
                      isSameDay(asDate(time.start), asDate(selectedDate)),
                  );

        return data;
    }, [props.sessions, selectedDate]);

    const timeRange: TimeRange = React.useMemo(
        () =>
            timeRangeFactory(
                firstOf(selectableDates),
                endOfDay(lastDayOfMonth(lastOf(selectableDates))),
            ),
        [selectableDates],
    );

    const selectableDateTimes = React.useMemo(() => {
        return selectableDates.map(isoAndUnixFactory);
    }, [selectableDates]);

    // If there are not active sessions, there's no date/time to select
    if (!props.sessions.some(session => session._status === "ACTIVE")) {
        return (
            <FlexRow mainAxis="center">
                <TextBox
                    size={1.1}
                    text={translation({
                        sv: "Inga bokningsbara datum tillgängliga 😢",
                        en: "No bookable dates available 😢",
                    })}
                />
            </FlexRow>
        );
    }
    return (
        <>
            <Conditional
                when={view === "DATE_VT"}
                render={() => (
                    <>
                        <VerticalDivider />
                        <BookableDateVtSelect
                            sessions={props.sessions}
                            timeRange={timeRange}
                            selectableDates={selectableDateTimes}
                            ignoreSession={props.ignoreSession}
                        />
                    </>
                )}
            />
            <Conditional
                when={view === "TIME_GRID"}
                render={() => (
                    <>
                        <FlexColumn
                            tag="DateBookableSelect"
                            fillParentWidth
                            shrink={0}
                            overflow="hidden"
                            padding={{ top: 10 }}
                            css={{
                                zIndex: 5,
                                borderBottom: `1px solid ${
                                    theme.isLightTheme
                                        ? theme.colors.default.main.light95
                                              .asString
                                        : theme.colors.default.main.light05
                                              .asString
                                }`,
                            }}>
                            <DateSelectionHzSingle
                                dataTestId="date-selection-hz-single__time-grid"
                                timeRange={timeRange}
                                onSelect={setSelectedDate}
                                selectableDates={selectableDateTimes}
                                initiallySelected={selectedDate}
                                forceSelection={true}
                            />
                        </FlexColumn>
                        <FlexColumn
                            fillParent
                            overflow="hidden"
                            shrink={1}
                            grow={1}
                            tag="bookable-session-select">
                            <BookableSessionSelectTimeGrid
                                sessions={props.sessions}
                                fullDateFilter={toFullDate(
                                    selectedDate,
                                    currentTimezoneName,
                                )}
                            />
                        </FlexColumn>
                    </>
                )}
            />
            <Conditional
                when={view === "DEFAULT"}
                render={() => (
                    <>
                        <FlexColumn
                            tag="DateBookableSelect"
                            fillParentWidth
                            shrink={0}
                            overflow="hidden"
                            padding={{ top: 10 }}
                            css={{
                                zIndex: 5,
                                borderBottom: `1px solid ${
                                    theme.isLightTheme
                                        ? theme.colors.default.main.light95
                                              .asString
                                        : theme.colors.default.main.light05
                                              .asString
                                }`,
                            }}>
                            <DateSelectionHzSingle
                                dataTestId="date-selection-hz-single__default"
                                timeRange={timeRange}
                                onSelect={setSelectedDate}
                                selectableDates={selectableDateTimes}
                                initiallySelected={selectedDate}
                                forceSelection={false}
                            />
                        </FlexColumn>
                        <FlexColumn
                            fillParent
                            overflow="hidden"
                            shrink={1}
                            grow={1}
                            tag="bookable-session-select">
                            <BookableSessionSelectList
                                sessions={bookableSessions}
                                ignoreSession={props.ignoreSession}
                            />
                        </FlexColumn>
                    </>
                )}
            />
        </>
    );
};

export { BookableSessionSelect };
export type { Props as BookableSessionSelectProps };
