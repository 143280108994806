import { Conditional } from "@@/shared/conditional";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faTimes, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { isTranslatable, Translatable } from "@towni/common";
import * as React from "react";
import { useMemo } from "react";
import { FlexRow } from "../shared/flex-containers";
import { Spacer } from "../shared/spacer";
import { ModalTitle } from "../shared/text/modal-title";
import { ModalId } from "./context/modal-id";
import { useModalActions } from "./use-modal-actions";

type Props = {
    /**
     * Override top left button \
     * _(defaults to close button)_
     */
    readonly topLeftButton?:
        | {
              icon: IconDefinition;
              action: () => void | Promise<void>;
          }
        | JSX.Element;
    readonly topRightButton?:
        | {
              _type: "TOP_RIGHT_ACTION_ICON";
              icon: IconDefinition;
              action: () => void | Promise<void>;
          }
        | {
              _type: "TOP_RIGHT_ACTION_TEXT";
              text: Translatable;
              action: () => void | Promise<void>;
          }
        | JSX.Element;
    readonly modalId: ModalId;
    readonly title: Translatable | JSX.Element | undefined;
    readonly hideTopLeftButton?: boolean;
    readonly className?: string;
    readonly bottomBorder?: boolean;
    readonly spacerBasis?: number;
};

// eslint-disable-next-line react/display-name
const ModalHeader = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLDivElement>) => {
        const theme = useTheme();
        const { hide } = useModalActions(props.modalId);

        const topLeft = useMemo(() => {
            if (
                props.topLeftButton &&
                !(
                    "icon" in props.topLeftButton &&
                    "action" in props.topLeftButton
                )
            ) {
                // Probably JSX element
                return props.topLeftButton;
            }

            const icon = props.topLeftButton?.icon ?? faTimes;
            const action = props.topLeftButton?.action ?? hide;
            return (
                <Icon
                    onClick={action}
                    icon={icon}
                    color={
                        theme.isDarkTheme
                            ? "#808182"
                            : theme.colors.default.text
                    }
                    size="L"
                    cursor="pointer"
                />
            );
        }, [props.topLeftButton, hide, theme]);

        return (
            <FlexRow
                fillParentWidth
                tag="ModalHeader"
                ref={ref}
                padding={{ topBottom: 20, leftRight: 20 }}
                crossAxis="center"
                css={{
                    height: "min-content",
                    label: "modal_header",
                    ...(props.bottomBorder
                        ? {
                              borderBottom: `1px solid ${theme.colors.default.border.asString}`,
                          }
                        : {}),
                }}
                className={props.className}
                mainAxis="space-between">
                <Spacer basis={props.spacerBasis ?? 0.2} shrink={0}>
                    {topLeft}
                </Spacer>
                <Conditional
                    when={isTranslatable(props.title)}
                    render={() => (
                        <ModalTitle text={props.title as Translatable} />
                    )}
                    else={() => props.title as JSX.Element | undefined}
                />
                <Spacer basis={props.spacerBasis ?? 0.2} shrink={0}>
                    {props.topRightButton &&
                    "_type" in props.topRightButton &&
                    props.topRightButton._type === "TOP_RIGHT_ACTION_ICON" ? (
                        <FlexRow
                            fillParentWidth
                            mainAxis="flex-end"
                            crossAxis="center">
                            <Icon
                                onClick={props.topRightButton.action}
                                icon={props.topRightButton.icon}
                                color={
                                    theme.isDarkTheme
                                        ? "#808182"
                                        : theme.colors.default.text
                                }
                                size="L"
                                cursor="pointer"
                            />
                        </FlexRow>
                    ) : null}
                    {props.topRightButton &&
                    "_type" in props.topRightButton &&
                    props.topRightButton._type === "TOP_RIGHT_ACTION_TEXT" ? (
                        <TextBox
                            display="block"
                            onClick={props.topRightButton.action}
                            text={props.topRightButton.text}
                            color={
                                theme.isDarkTheme
                                    ? "#808182"
                                    : theme.colors.default.text
                            }
                            size={1}
                            weight="600"
                            cursor="pointer"
                            align="right"
                            css={{
                                width: "100%",
                                ":hover": {
                                    color: theme.colors.information.asString,
                                },
                            }}
                        />
                    ) : null}
                    {props.topRightButton && !("_type" in props.topRightButton)
                        ? // Should be a JSX Element
                          props.topRightButton
                        : null}
                </Spacer>
            </FlexRow>
        );
    },
);

export { ModalHeader };
