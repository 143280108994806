import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { Wizard, wizardPage } from "@@/pages/wizard";
import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { ButtonDangerLight } from "@@/shared/buttons_v2/button-danger-light";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    BookableTableBooking,
    Order,
    OrderGroupId,
    OrderItemAccommodation_V2,
    OrderItemBookable_V2,
    PhoneNumber,
    Translatable,
    asDate,
    translation,
} from "@towni/common";
import { isPast } from "date-fns";
import * as React from "react";
import { TableBookingCancelSuccess } from "./table-booking-cancel-success";
import { TableBookingCancelUnavailable } from "./table-booking-cancel-unavailable";
import { useCancellationAvailability } from "./use-cancellation-availability";
import { useTableBookingCancellationCommand } from "./use-table-booking-cancellation-command";

type Props = {
    modalId: ModalId;
    tableBooking: OrderItemBookable_V2 | OrderItemAccommodation_V2;
    bookable: BookableTableBooking;
    orderGroupId: OrderGroupId;
    providerTitle: Translatable;
    providerPhoneNumber: PhoneNumber | undefined;
    order: Order;
};

const CustomerCancelTableBookingModal = (props: Props) => {
    const theme = useTheme();
    const { modalId, tableBooking: tableBooking, bookable, order } = props;
    const { hide } = useModalActions(modalId);
    const accesskey = useAccessKeyIdFromUrl();
    const [hasPassed] = React.useState(isPast(asDate(tableBooking.time.end)));
    const cancellationMutation = useTableBookingCancellationCommand(
        {
            orderGroupId: props.orderGroupId,
            orderId: props.order._id,
            orderItemId: tableBooking._id,
            productId: tableBooking.productId,
            providerId: tableBooking.providerId,
            providerTitle: props.providerTitle,
        },
        accesskey,
    );

    const { cancellationAvailable } = useCancellationAvailability({
        orderItem: tableBooking,
        bookable,
        order,
    });

    return (
        <Modal
            modalId={modalId}
            header={
                <ModalHeader
                    modalId={modalId}
                    bottomBorder
                    title={translation({
                        sv: "Avbokning",
                        en: "Cancel table reservation",
                    })}
                />
            }>
            <Wizard ignoreAnimation={false}>
                {[
                    wizardPage(
                        "cancelReservation",
                        ({ state: _s, dispatch }) => (
                            <Conditional
                                when={!cancellationAvailable}
                                render={() => (
                                    <TableBookingCancelUnavailable
                                        bookable={bookable}
                                        modalId={modalId}
                                        order={order}
                                        tableBooking={tableBooking}
                                        providerTitle={props.providerTitle}
                                        providerPhoneNumber={
                                            props.providerPhoneNumber
                                        }
                                    />
                                )}
                                else={() => (
                                    <FlexColumn
                                        padding={{ all: 20 }}
                                        fillParentWidth
                                        crossAxis="center">
                                        <Conditional
                                            when={!hasPassed}
                                            render={() => (
                                                <>
                                                    <TextBox
                                                        text={translation({
                                                            sv: "Är du säker på att du vill avboka?",
                                                            en: "Are you sure you want to cancel the table reservation?",
                                                        })}
                                                        align="center"
                                                        weight="900"
                                                    />
                                                    <VerticalDivider M />
                                                    <TextBox
                                                        text={translation({
                                                            sv: "Det är inte säkert att det finns platser kvar ifall att du ångrar dig.",
                                                            en: "It is not guaranteed that there are seats left if you regret your decision to cancel.",
                                                        })}
                                                        padding={{
                                                            leftRight: 20,
                                                        }}
                                                        align="center"
                                                        color={
                                                            theme.colors.black
                                                                .light40
                                                        }
                                                    />
                                                </>
                                            )}
                                            else={() => (
                                                <TextBox
                                                    text={translation({
                                                        sv: "Tiden för bokningen är redan passerad. Går inte och finns heller inte längre någon anledning att avboka.",
                                                        en: "The time for the reservation has already passed. It is not possible to cancel anymore.",
                                                    })}
                                                    color={
                                                        theme.colors.black
                                                            .light40
                                                    }
                                                />
                                            )}
                                        />
                                        <VerticalDivider L />
                                        <ButtonDangerLight
                                            fillParentWidth
                                            disabled={
                                                hasPassed ||
                                                cancellationMutation.isPending
                                            }
                                            spin={
                                                cancellationMutation.isPending
                                            }
                                            onClick={async () => {
                                                await cancellationMutation.mutateAsync();
                                                dispatch.goForward();
                                            }}>
                                            <TextBox
                                                text={translation({
                                                    sv: "Avboka",
                                                    en: "Cancel reservation",
                                                })}
                                            />
                                        </ButtonDangerLight>
                                        <VerticalDivider S />
                                        <ButtonWhite
                                            fillParentWidth
                                            onClick={() => hide()}>
                                            <TextBox
                                                text={translation({
                                                    sv: "Avbryt",
                                                    en: "Cancel",
                                                })}
                                            />
                                        </ButtonWhite>
                                    </FlexColumn>
                                )}
                            />
                        ),
                    ),
                    wizardPage(
                        "success",
                        <TableBookingCancelSuccess modalId={modalId} />,
                    ),
                ]}
            </Wizard>
        </Modal>
    );
};

export { CustomerCancelTableBookingModal };
