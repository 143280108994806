import { ModalId } from "@@/modals/context/modal-id";
import { browserLogger } from "@@/settings";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { useTheme } from "@emotion/react";
import {
    assertNever,
    generateId,
    OrderGroupId,
    OrderId,
    Percentage,
} from "@towni/common";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMe } from "../authentication/me/me-context";
import { Modal } from "../modals/modal";
import { useModalActions } from "../modals/use-modal-actions";
import { FlexColumn, FlexRow } from "../shared/flex-containers";
import { CheckoutAuthentication } from "./checkout-modal-authentication";
import { CheckoutDelivery } from "./checkout-modal-delivery";
import { CheckoutPayment } from "./checkout-modal-payment";

type Props = {
    modalId: ModalId;
    onFinished: (orderGroupId: OrderGroupId, orderId: OrderId) => void;
    showDeliveryModal: () => void;
    onHide?: () => void;
    delivery: boolean;
    forceFullHeightMode?: boolean;
};

// TODO: SWITCH TO USE NEW WIZARD COMPONENT INSIDE
const CheckoutModal = (props: Props) => {
    const [mountId, setMountId] = useState(generateId);
    const theme = useTheme();
    const [me] = useMe();
    const { hide: hideModal } = useModalActions(props.modalId);

    const [status, setStatus] = useState<
        "AUTHENTICATION" | "DELIVERY" | "CHECKOUT_AND_PAYMENT"
    >("AUTHENTICATION");

    const hide = React.useCallback(() => {
        browserLogger.info("Checkout modal hide");
        if (!me) setStatus("AUTHENTICATION");
        else if (props.delivery) setStatus("DELIVERY");
        else setStatus("CHECKOUT_AND_PAYMENT");
        hideModal();
    }, [props.delivery, me]);

    // Watch use isAnonymous and change state when user is logged in
    useEffect(() => {
        const isAnonymous = !me;
        if (!isAnonymous && props.delivery) {
            status !== "DELIVERY" && setStatus("DELIVERY");
        } else if (!isAnonymous && !props.delivery) {
            status !== "CHECKOUT_AND_PAYMENT" &&
                setStatus("CHECKOUT_AND_PAYMENT");
        }
        status !== "AUTHENTICATION" && setStatus("AUTHENTICATION");
    }, [me]);

    const content = React.useMemo(() => {
        const spinner = (
            <FlexRow fillParentWidth padding={{ all: 20 }} mainAxis="center">
                <CircleSpinner size="XXL" />
            </FlexRow>
        );

        //if (!checkoutData) return spinner;
        switch (status) {
            case "AUTHENTICATION":
                //when user isn't signed in
                return <CheckoutAuthentication closeModal={hideModal} />;
            case "DELIVERY":
                // Make sure user has picked delivery
                return (
                    <CheckoutDelivery
                        modalId={props.modalId}
                        closeModal={hide}
                        showDeliveryModal={props.showDeliveryModal}
                        onContinue={() => setStatus("CHECKOUT_AND_PAYMENT")}
                    />
                );
            case "CHECKOUT_AND_PAYMENT":
                browserLogger.info("Checkout and payment");
                // If user has been signed out somehow...
                // todo! maybe notify user, it's weird
                if (!me) {
                    setStatus("AUTHENTICATION");
                    return spinner;
                }
                // Let user fulfill payment
                return (
                    <CheckoutPayment
                        me={me}
                        modalId={props.modalId}
                        onFinished={(
                            orderGroupId: OrderGroupId,
                            orderId: OrderId,
                        ) => {
                            props.onFinished?.(orderGroupId, orderId);
                        }}
                    />
                );
            default:
                assertNever(status);
        }
    }, [status, hideModal, props, hide, me]);

    return (
        <Modal
            key={mountId}
            modalId={props.modalId}
            onHide={() => {
                props.onHide?.();
                setMountId(generateId());
            }}
            height={0.9 as Percentage}
            forceFullHeightMode={props.forceFullHeightMode}>
            <FlexColumn
                shrink={0}
                fillParentWidth
                background={{ color: theme.colors.default.background }}
                crossAxis="stretch">
                {/* // Content */}
                {content}
            </FlexColumn>
        </Modal>
    );
};

export { CheckoutModal };
