import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    flattenGraph,
    getPickableSubTypes,
    isAccommodationResourceReservationRequest,
    isOrderItemAccommodation_V2,
    isResourceAccommodation,
} from "@towni/common";
import { useMemo } from "react";
import { useCart } from "../multi-carts/cart.context";

/**
 * The hook returns the resources that have pickable sub types and
 * the reservation request that is associated with the resource.
 * It also returns status if a sub type has been picked or not.
 *
 * NOTE!
 * This only has support for one accommodation product in the cart.
 * Won't work if there are multiple accommodation products in the cart.
 */
const useAccommodationCartResourcesWithSubType = () => {
    const cart = useCart();
    const reservationRequests = cart.reservationRequests;

    const [orderItem] = cart.orderItems.filter(isOrderItemAccommodation_V2);
    const [globalResources, _] = useBookableResources(orderItem?.productId);
    // TODO! This only has support for one accommodation product in the cart
    // TODO! Won't work if there are multiple accommodation products in the cart
    const flattened = useMemo(
        () => globalResources.flatMap(graph => flattenGraph(graph)),
        [globalResources],
    );
    const resourcesWithPickableSubType = useMemo(
        () =>
            reservationRequests
                .filter(isAccommodationResourceReservationRequest)
                .flatMap(rr => {
                    const resource = flattened.find(
                        resource => resource._id === rr.resourceId,
                    );

                    if (!resource) return [];
                    if (!isResourceAccommodation(resource)) return [];
                    const pickableSubTypes = getPickableSubTypes(resource);

                    if (pickableSubTypes.length <= 0) return [];
                    return {
                        resource,
                        reservation: rr,
                    };
                }),
        [flattened, reservationRequests],
    );

    return resourcesWithPickableSubType;
};

export { useAccommodationCartResourcesWithSubType };
