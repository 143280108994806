import { repeatIndex } from "@towni/common";
import { useMemo } from "react";
import { ProgressPill } from "./slide-show-progress-pill";

type Props = {
    count: number;
    index: number;
    duration: number;
};

const SlideShowProgress = (props: Props) => {
    const duration = props.duration || 5000;

    const pills = useMemo(() => {
        return repeatIndex(props.count).map(index => (
            <ProgressPill
                key={index}
                status={
                    index === props.index
                        ? "ACTIVE"
                        : index < props.index
                        ? "FINISHED"
                        : "INACTIVE"
                }
                duration={duration}
            />
        ));
    }, [props.count, props.index]);

    return (
        <div
            css={{
                position: "absolute",
                bottom: 10,
                zIndex: 100,
                left: 0,
                right: 0,
                display: "flex",
                height: 15,
                justifyContent: "center",
                alignItems: "center",
            }}>
            {pills}
        </div>
    );
};

export { SlideShowProgress };
