import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { GetResponse, ResourceGlobal, ResourceId } from "@towni/common";

const useResourcesForResourceGroupQuery = (
    resourceGroupId: ResourceId | undefined,
) => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<ResourceGlobal[]> = async ({ signal }) => {
        const response = await fetchClient.get<GetResponse<ResourceGlobal>>({
            route: `/resources/for-resource-group/${encodeURIComponent(resourceGroupId ?? "")}`,
            // route: `/api/inventory/resources?resourceId=${resourceId}`,
            customConfig: { signal },
        });
        return response.items;
    };
    const resourceQuery = useQuery({
        queryKey: ["resource-group", resourceGroupId],
        queryFn,
        enabled: !!resourceGroupId,
    });

    return resourceQuery;
};
export { useResourcesForResourceGroupQuery };
