import { useModal } from "@@/modals/use-modal";
import { AccommodationWizardModal } from "@@/products/accommodations/booking-accommodation-modal";
import { useStartingPriceQuery } from "@@/routes/products/$productId/_bookable/generic/use-starting-price";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { Paragraph, TextBox, paragraphPriceFactory } from "@@/shared/text";
import { Product, Provider, translation } from "@towni/common";
type Props = {
    product: Product;
    provider: Provider;
    disabled?: boolean;
};
const _GenericBooking_ModalButtonSection = (props: Props) => {
    const { product, provider } = props;
    const [{ show }, modalId] = useModal("generic_booking_button__");

    const startingPriceQuery = useStartingPriceQuery(product);
    const startingPrice = startingPriceQuery.data?.data;
    const startingPriceIsPending = startingPriceQuery.isPending;

    if (product.skus[0]?.acquire._type !== "ACCOMMODATION") return null;

    const containFutureAvailability = product.skus.some(
        r =>
            r.acquire._type === "ACCOMMODATION" &&
            r.acquire.availability.availabilityRanges.some(
                r => r.end.unix > Date.now() / 1000,
            ),
    );

    return (
        <>
            <FlexRow fillParentWidth mainAxis="flex-end" crossAxis="flex-start">
                <Conditional
                    when={containFutureAvailability}
                    else={() => <HorizontalDivider />}>
                    <Conditional
                        when={startingPriceIsPending && !startingPrice}
                        render={() => <CircleSpinner />}
                    />
                    <Conditional
                        when={!!startingPrice}
                        render={() => (
                            <>
                                {startingPrice === "NONE" ? (
                                    <TextBox
                                        text={translation({
                                            sv: "Ej tillgänglig",
                                            en: "Unavailable",
                                        })}
                                    />
                                ) : (
                                    <Paragraph
                                        css={{
                                            marginTop: 5,
                                            textAlign: "right",
                                        }}
                                        content={
                                            startingPrice
                                                ? [
                                                      paragraphPriceFactory({
                                                          price: startingPrice,
                                                          css: {
                                                              fontWeight: 700,
                                                          },
                                                      }),
                                                      " / ",
                                                      translation({
                                                          sv: "natt",
                                                          en: "night",
                                                      }),
                                                  ]
                                                : ["-"]
                                        }
                                    />
                                )}
                                <HorizontalDivider />
                            </>
                        )}
                    />
                </Conditional>
                <HorizontalDivider L />
                <ButtonPrimary
                    onClick={show}
                    padding={{ leftRight: 30, topBottom: 14 }}
                    disabled={props.disabled || startingPrice === "NONE"}
                    spin={startingPriceIsPending}
                    css={{ borderRadius: 3000 }}>
                    <TextBox
                        weight="900"
                        text={translation({
                            sv: "Se tider & boende",
                            en: "See times & accommodations",
                        })}
                    />
                </ButtonPrimary>
            </FlexRow>
            <AccommodationWizardModal
                modalId={modalId}
                product={product}
                provider={provider}
            />
        </>
    );
};

export { _GenericBooking_ModalButtonSection };
