import { HorizontalDivider } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import { isVoucher, Order, OrderItemVoucher } from "@towni/common";
import { useMemo } from "react";
import { DigitalVoucherReceipt_Ticket } from "./digital-voucher-receipt-ticket";

type Props = {
    orders: Order[];
};

const DigitalVoucherReceipt_Tickets = (props: Props) => {
    const { orders } = props;
    const voucherOrderItems = useMemo(
        () =>
            orders.flatMap(order =>
                order.orderItems
                    .filter(isVoucher)
                    .map(
                        orderItem =>
                            [order, orderItem] as [Order, OrderItemVoucher],
                    ),
            ),
        [orders],
    );

    return (
        <ForEach
            divider={<HorizontalDivider />}
            itemOf={voucherOrderItems}
            getKey={([, oi]) => oi._id}>
            {([order, orderItem]) => {
                return (
                    <DigitalVoucherReceipt_Ticket
                        key={orderItem._id}
                        order={order}
                        orderItem={orderItem}
                    />
                );
            }}
        </ForEach>
    );
};

export { DigitalVoucherReceipt_Tickets };
