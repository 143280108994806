import {
    asDate,
    BookableTableBooking,
    DurationInMinutes,
    Order,
    OrderItemAccommodation_V2,
    OrderItemBookable_V2,
} from "@towni/common";
import { addMinutes, isFuture } from "date-fns";
import { useMemo } from "react";

const useCancellationAvailability = (params: {
    bookable: BookableTableBooking | undefined;
    orderItem: OrderItemBookable_V2 | OrderItemAccommodation_V2;
    order: Order;
}) => {
    const { bookable, orderItem: tableBooking, order } = params;
    const cancellationByCustomerAllowed =
        bookable?.type === "TABLE_BOOKING" &&
        !!bookable?.cancellationByCustomerAllowed;
    const cancellationByCustomerTimeLimit =
        (bookable?.type === "TABLE_BOOKING" &&
            bookable.cancellationByCustomerTimeLimit) ??
        (0 as DurationInMinutes);
    const cancellationUnavailableFrom = addMinutes(
        asDate(tableBooking.time.start),
        -(cancellationByCustomerTimeLimit || 0),
    );
    const isCancellableInAnyWay =
        order.status.type !== "CANCELLED" &&
        order.status.type !== "REJECTED" &&
        order.status.type !== "FULFILLED";
    const cancellationAvailable =
        cancellationByCustomerAllowed &&
        isFuture(cancellationUnavailableFrom) &&
        isCancellableInAnyWay;

    return useMemo(
        () => ({
            cancellationByCustomerAllowed,
            cancellationByCustomerTimeLimit,
            cancellationUnavailableFrom,
            cancellationAvailable,
            isCancellableInAnyWay,
        }),
        [
            cancellationByCustomerAllowed,
            cancellationByCustomerTimeLimit,
            cancellationUnavailableFrom,
            cancellationAvailable,
            isCancellableInAnyWay,
        ],
    );
};

export { useCancellationAvailability };
