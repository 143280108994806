import {
    useAutoRestoreScrollPosition,
    useScrollPositionAutoRecorder,
} from "@@/pages/scroll-position-context";
import { Padding } from "@towni/common";
import * as React from "react";
import { useBreadCrumbPathState } from "../bread-crumb-id-context";
import { FlexColumn, FlexRow } from "../flex-containers";

type Props = {
    scroll: boolean;
    padding?: Padding;
    /**
     * defaults to 5px
     * @type {number}
     */
    contentMargin?: number;
    children?: React.ReactNode;
};

const SectionAreaList = ({ scroll, children, ...props }: Props) => {
    // >> Remember scroll position
    const [breadCrumbPath] = useBreadCrumbPathState();
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    useAutoRestoreScrollPosition(breadCrumbPath, scrollContainerRef);
    useScrollPositionAutoRecorder(breadCrumbPath, scrollContainerRef);
    // << Remember scroll position

    return (
        <FlexColumn
            fillParentWidth
            shrink={0}
            ref={scrollContainerRef}
            overflowX={scroll ? "scroll" : "hidden"}
            padding={props.padding ?? { topBottom: 5, left: 5 }}>
            <FlexRow
                crossAxis="stretch"
                wrap={scroll ? "nowrap" : "wrap"}
                wrapMargin={props.contentMargin ?? 5}>
                {children}
            </FlexRow>
        </FlexColumn>
    );
};

export { SectionAreaList };
