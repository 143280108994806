import { SerializedStyles, useTheme } from "@emotion/react";
import * as React from "react";
import { GlobalMaxWidthCss } from "./global-max-width";

type Props = {
    readonly disabled?: boolean;
    readonly className?: string;
    readonly maxContentWidth?: React.CSSProperties["maxWidth"];
    readonly children?: React.ReactNode;
    readonly contentContainerCss?: SerializedStyles;
};
const WideScreenWrapper = (props: Props) => {
    const theme = useTheme();
    const { children, disabled, className } = props;
    return (
        <>
            {!disabled ? <GlobalMaxWidthCss maxWidth={undefined} /> : null}
            <div
                className={className}
                css={{
                    width: "100%",
                    display: "flex",
                    minHeight: "100%",
                    maxHeight: "100%",
                    justifyContent: "center",
                    backgroundColor: theme.colors.black.light95.asString,
                    label: "widescreen_wrapper",
                    position: "relative",
                }}>
                <div
                    css={[
                        {
                            width: "100%",
                            display: "flex",
                            backgroundColor: theme.colors.white.asString,
                            justifyContent: "center",
                            maxWidth: props.maxContentWidth ?? 1200,
                            boxShadow: "0px 0px 200px rgba(0, 0, 0, 0.3)",
                            label: "widescreen_wrapper_content",
                            position: "relative",
                        },
                        props.contentContainerCss,
                    ]}>
                    {children ?? null}
                </div>
            </div>
        </>
    );
};

export { WideScreenWrapper };
