import { useMe } from "@@/authentication/me/me-context";
import { useModal } from "@@/modals/use-modal";
import { useTheme } from "@emotion/react";
import { faChevronDown, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import {
    ColorItem,
    RemSize,
    SizeName,
    colorAsString,
    fullName,
    generateId,
} from "@towni/common";
import * as React from "react";
// import { Gravatar } from "./gravatar";
import { UserModal } from "../users/modal/user-modal";
import { HorizontalDivider } from "./dividers/horizontal-divider";
import { FlexRow } from "./flex-containers";
import { Icon } from "./icons/icon";
import { LoginButton } from "./login-button";

type Props = {
    size?: SizeName;
    theme?: "PRIMARY" | "WHITE" | "DEFAULT";
    color?: ColorItem;
    loginTheme?: "PRIMARY" | "WHITE" | "DEFAULT";
    options?: {
        loginMini?: boolean;
    };
};

/**
 * @deprecated Use `AccountIcon` instead
 *
 * @param {Props} props
 * @return {*}
 */
const CurrentUserIcon = (props: Props) => {
    const renderId = React.useRef<string>(generateId());
    const viewAs = props.theme ?? "DEFAULT";
    const loginTheme = props.loginTheme ?? props.theme ?? "PRIMARY";
    const [me, { meQuery }] = useMe();
    const theme = useTheme();
    const [{ toggle }, modalId] = useModal("user_modal__");
    // const bgColor = theme.colors.default.text;

    const fontSize = ((props.size ? theme.sizes.inPixels[props.size] : 21) /
        16) as RemSize;
    const color =
        viewAs === "PRIMARY"
            ? theme.colors.primary
            : viewAs === "WHITE"
              ? theme.colors.white.value.withAlpha(0.9)
              : props.color ?? theme.colors.default.text.light40;

    if (
        meQuery.isError ||
        meQuery.isPending ||
        !me ||
        !me.email?.emailAddress
    ) {
        return (
            <LoginButton
                key={renderId.current}
                spin={meQuery.isPending}
                theme={props.options?.loginMini ? props.theme : loginTheme}
                mini={props.options?.loginMini}
            />
        );
    }

    return (
        <>
            <FlexRow shrink={0} onClick={toggle}>
                <Icon
                    icon={faChevronDown}
                    size={(fontSize * 0.62) as RemSize}
                    color={color}
                />
                <HorizontalDivider XS />
                <Icon
                    icon={faUserCircle}
                    size={fontSize}
                    cursor="pointer"
                    color={colorAsString(color)}
                    title={fullName(me)}
                />
            </FlexRow>
            <UserModal modalId={modalId} />
        </>
    );
};

export { CurrentUserIcon };
