import {
    useFetch,
    useFetchMultiple,
    useFetchMultipleForMultiple,
    useFetchMultipleForSingle,
    useInfiniteFetchFor,
} from "@@/shared/generic-fetchers";
import { useUrlParameter } from "@@/shared/use-query-or-param";
import {
    AccessKeyId,
    Order,
    OrderStatusType,
    ProductId,
    ProviderId,
    UnixTimestamp,
} from "@towni/common";

const mainQueryKey = "orders";
const mainRoute = "orders";

const useOrder = (orderId?: string, accessKeyId?: AccessKeyId) =>
    useFetch<Order>(mainQueryKey, mainRoute, orderId, undefined, accessKeyId);

const useOrders = (orderIds?: string[], accessKeyId?: AccessKeyId) =>
    useFetchMultiple<Order>(
        mainQueryKey,
        mainRoute,
        orderIds,
        undefined,
        accessKeyId
    );

const useOrdersForProvider = (params: {
    providerId?: ProviderId;
    includeFilter?: OrderStatusType[];
    excludeFilter?: OrderStatusType[];
    fromDate?: UnixTimestamp;
    toDate?: UnixTimestamp;
    options?: { enabled?: boolean };
}) => {
    const { providerId, includeFilter, excludeFilter, fromDate, toDate } =
        params;
    const result = useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "provider",
        id: providerId, //+ (status ? "?status=" + status.join(",") : "")
        options: {
            enabled:
                typeof params.options?.enabled === "boolean"
                    ? params.options.enabled && !!providerId
                    : !!providerId,
        },
        filters: {
            includeFilter: includeFilter ?? [],
            excludeFilter: excludeFilter ?? [],
            from: (fromDate ? [fromDate] : []) as unknown as string[],
            to: (toDate ? [toDate] : []) as unknown as string[],
        },
    });
    return result;
};

const useSearchOrdersForProvider = (params: {
    providerId?: ProviderId;
    search?: string;
}) => {
    const { providerId, search } = params;
    const result = useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute: mainRoute,
        fetchFor: "search",
        id: search, //+ (status ? "?status=" + status.join(",") : "")
        options: {
            enabled: !!search,
        },
        filters: { providerId: providerId ? [providerId] : [] },
    });
    return result;
};

const useOrdersForProduct = (params: {
    productId: ProductId;
    includeFilter?: OrderStatusType[];
    excludeFilter?: OrderStatusType[];
    fromDate?: UnixTimestamp;
    toDate?: UnixTimestamp;
    byBookingDate?: boolean;
    options?: { enabled?: boolean };
}) => {
    const {
        productId,
        includeFilter,
        excludeFilter,
        fromDate,
        toDate,
        byBookingDate,
    } = params;
    const result = useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "product",
        id: productId, //+ (status ? "?status=" + status.join(",") : "")
        options: {
            enabled:
                typeof params.options?.enabled === "boolean"
                    ? params.options.enabled && !!productId
                    : !!productId,
        },
        filters: {
            include: includeFilter ?? [],
            exclude: excludeFilter ?? [],
            from: (fromDate ? [fromDate] : []) as unknown as string[],
            to: (toDate ? [toDate] : []) as unknown as string[],
            byBookingDate: byBookingDate ? ["true"] : ["false"],
        },
    });
    return result;
};
const useInfiniteOrdersForProvider = (providerId?: ProviderId) =>
    useInfiniteFetchFor<Order>(mainQueryKey, mainRoute, "provider", providerId);
const useOrdersForProviders = (providerIds?: string[]) =>
    useFetchMultipleForMultiple<Order>(
        mainQueryKey,
        mainRoute,
        "provider",
        providerIds
    );

const useOrdersForShipping = (shippingId?: string, refetchInterval?: number) =>
    useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "shipping",
        id: shippingId,
        options: { refetchInterval },
    });

const useOrdersForMe = () =>
    useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "user",
        id: "me",
    });

const useOrderByUrlParam = (parameterNames: string | string[] | undefined) => {
    const orderIdOrSlug = useUrlParameter(parameterNames);

    return useOrder(orderIdOrSlug);
};

export {
    useOrder,
    useOrders,
    useOrdersForProvider,
    useOrdersForProviders,
    useInfiniteOrdersForProvider,
    useOrderByUrlParam,
    useOrdersForShipping,
    useOrdersForMe,
    useOrdersForProduct,
    useSearchOrdersForProvider,
    mainQueryKey as ordersMainQueryKey,
};
