import { useCheckoutContext } from "@@/carts/checkout.context";
import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useModal } from "@@/modals/use-modal";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { useIsAccommodationBookingCompleteWithExtras } from "@@/products/accommodations/hooks/use-is-booking-complete-with-extras-and-optionals";
import { ExtrasOrderItemsV2 } from "@@/products/bookables/bookable-extras-order-item-list-v2";
import { BookableProductExtrasGroupModal } from "@@/products/bookables/bookable-product-extras-group-modal";
import { BookableDetailsOptionals } from "@@/products/bookables/booking-wizard/bookable-details/bookable-details-optionals";
import { BookableExtrasGroupGrid } from "@@/products/bookables/booking-wizard/extras-and-optionals.tsx/bookable-extras-group-grid";
import { useExtraProps } from "@@/products/bookables/booking-wizard/extras-and-optionals.tsx/use-extras-props";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { whiteningGradient } from "@@/shared/bg-lightening-gradient";
import { ButtonGray } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Circle } from "@@/shared/circle";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    OptionalGroup,
    PickedOptional,
    Product,
    Provider,
    defaultAmount,
    getChildAccommodationRates,
    getChildOptionals,
    isOrderItemAccommodation_V2,
    orderItemsOptional,
    sum,
    sumPrices,
    translation,
} from "@towni/common";

type Props1 = {
    product: Product;
    provider: Provider;
    readonly optionals: OptionalGroup[];
};

const useAddOptionalToCart = () => {
    const cartContext = useCartContext(context => ({
        cart: context.cart,
        setReservationRequests: context.setReservationRequests,
        removeProductFromCart: context.removeProductFromCart,
        addItemToCart: context.addItemToCart,
        addItemsToCart: context.addItemsToCart,
        removeItemFromCart: context.removeItemFromCart,
        setBookableOptionValues: context.setBookableOptionValues,
    }));

    const addToCartClick = (
        pickedOptionalsMap?: Map<string, PickedOptional[]>,
    ) => {
        const item = cartContext.cart.orderItems.find(
            isOrderItemAccommodation_V2,
        );
        if (!item) return;

        const child = getChildAccommodationRates(
            item,
            cartContext.cart.orderItems,
        );
        const childOptional = getChildOptionals(
            item,
            cartContext.cart.orderItems,
        );

        const total = sumPrices(child.map(r => r.price));
        childOptional.map(oi => oi._id).forEach(cartContext.removeItemFromCart); // and add the updated items

        const orderItems = orderItemsOptional({
            quantity: {
                amount: defaultAmount,
                value: 1,
            },
            pickedOptions: Array.from(
                pickedOptionalsMap ? pickedOptionalsMap.values() : [],
            ).flat(), //pickedOptions,

            parent: item,
            pricePerItem: item.pricePerItem,
            totalPrice: total,
        });
        if (!orderItems.length) return;
        orderItems.forEach(cartContext.addItemToCart); // and add the updated items
    };

    return addToCartClick;
};

const AccommodationExtrasPickerPage = (props: Props1) => {
    const theme = useTheme();
    const wizardActions = useWizardDispatch();
    const { onPremise } = useCheckoutContext(context => ({
        onPremise: context.onPremise,
    }));
    const addToCartClick = useAddOptionalToCart();
    const accommodation = props.product.skus[0].acquire;
    const extras =
        accommodation._type === "ACCOMMODATION"
            ? accommodation.extrasGroups ?? []
            : [];
    const extraProps = useExtraProps(extras, onPremise);

    const [{ show: showPickedExtras }, extraModalId] =
        useModal("extras_modal__");

    const continueDisabled =
        useIsAccommodationBookingCompleteWithExtras(onPremise);

    const amountExtras = sum(extraProps, x => x.pickedAmount);

    return (
        <>
            <FlexColumn
                fillParent
                css={{
                    backgroundColor: theme.colors.default.background.asString,
                }}>
                <FlexColumn fillParent overflowY="scroll">
                    <FlexColumn fillParentWidth>
                        <ExtrasOrderItemsV2
                            extras={extraProps}
                            title={translation({
                                sv: "Tillagda tillval",
                                en: "Selected extras",
                            })}
                            productIdsToIgnore={[props.product._id]}
                        />
                        <Conditional when={amountExtras > 0}>
                            <HorizontalSectionDivider />
                        </Conditional>
                        <VerticalDivider M />

                        <Conditional when={props.optionals.length > 0}>
                            <BookableDetailsOptionals
                                //   initialValues={}
                                optionals={props.optionals}
                                onChange={pickedOptionalsMap => {
                                    addToCartClick(pickedOptionalsMap);
                                }}
                            />
                            <VerticalDivider M />
                            <HorizontalSectionDivider />
                            <VerticalDivider M />
                        </Conditional>
                        <FlexColumn
                            //  overflowY="scroll"
                            padding={{ leftRight: 20 }}>
                            <ForEach
                                itemOf={extraProps}
                                divider={
                                    <>
                                        <VerticalDivider M />
                                        <HorizontalLine />
                                        <VerticalDivider M />
                                    </>
                                }>
                                {extra => {
                                    return (
                                        <BookableExtrasGroupGrid
                                            product={props.product}
                                            extraGroup={extra.extras}
                                            aspectRatio="1:1"
                                            minMax={extra.minMax}
                                        />
                                    );
                                }}
                            </ForEach>
                        </FlexColumn>
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                <FlexRow
                    css={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        pointerEvents: "none",
                    }}
                    background={{
                        linearGradient: theme.isLightTheme
                            ? whiteningGradient
                            : darkeningGradient,
                    }}
                    fillParentWidth
                    mainAxis="flex-end"
                    padding={{ all: 20, top: 60 }}>
                    <FlexColumn crossAxis="flex-end">
                        <FlexRow crossAxis="center">
                            <Conditional when={amountExtras > 0}>
                                <ButtonGray
                                    onClick={showPickedExtras}
                                    padding={{
                                        left: 15,
                                        right: 3,
                                        topBottom: 15,
                                    }}
                                    css={{
                                        maxHeight: "40px",
                                        pointerEvents: "all",
                                        borderRadius: 3000,
                                    }}>
                                    <FlexRow maxHeight={20}>
                                        <TextBox
                                            color={theme.colors.black}
                                            text={translation({
                                                sv: "Tillval",
                                                en: "Extras",
                                            })}
                                            padding={{
                                                leftRight: 15,
                                            }}
                                        />
                                        <Circle size={34}>
                                            <TextBox
                                                text={amountExtras.toString()}
                                            />
                                        </Circle>
                                    </FlexRow>
                                </ButtonGray>
                                <HorizontalDivider S />
                            </Conditional>
                            <ButtonPrimary
                                disabled={continueDisabled}
                                onClick={() => {
                                    wizardActions.goForward();
                                }}
                                css={{
                                    maxHeight: "40px",
                                    pointerEvents: "all",
                                    borderRadius: 3000,
                                }}>
                                <TextBox
                                    text={translation({
                                        sv: "Fortsätt",
                                        en: "Continue",
                                    })}
                                    padding={{
                                        leftRight: 15,
                                        topBottom: 2,
                                    }}
                                />
                            </ButtonPrimary>
                        </FlexRow>

                        <Conditional
                            when={continueDisabled}
                            render={() => (
                                <>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={translation({
                                            sv: "* Det finns val kvar att göra",
                                            en: "* You still have options you need to pick",
                                        })}
                                        color={
                                            theme.isLightTheme
                                                ? theme.colors.black.light60
                                                : theme.colors.black.light40
                                        }
                                        size={0.8}
                                    />
                                </>
                            )}
                        />
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
            <BookableProductExtrasGroupModal
                product={props.product}
                modalId={extraModalId}
                extras={extraProps}
            />
        </>
    );
};

export { AccommodationExtrasPickerPage };
