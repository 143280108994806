import { browserLogger } from "@@/settings";
import {
    Me,
    TranslatableValue,
    asDate,
    hejalfredScope,
    translation,
} from "@towni/common";
//import confetti from "canvas-confetti";

const welcomeMessage = (
    me: Me,
): {
    message: TranslatableValue;
    //confetti?: confetti.Options["shapes"];
} => {
    try {
        const t = new Date();
        const hasHejAlfredScope = me?.scopes.includes(hejalfredScope);
        if (hasHejAlfredScope) {
            const meDay = asDate(me._createdAt);

            if (
                meDay.getMonth() === t.getMonth() &&
                meDay.getDate() === t.getDate() &&
                meDay.getFullYear() !== t.getFullYear() // Don't congratulate on the same day
            ) {
                return {
                    message: translation({
                        sv: `Glad kontofödelsedag!`,
                        en: `Happy user-birthday!`,
                    }),
                    // confetti: [
                    //     confetti.shapeFromText({ text: "🎉", scalar: 2 }),
                    //     confetti.shapeFromText({ text: "🎂", scalar: 2 }),
                    // ],
                };
            }

            const customDay = customDays.find(
                d =>
                    d.month === t.getMonth() + 1 &&
                    d.day === t.getDate() &&
                    (d.year === undefined || d.year === t.getFullYear()),
            );
            if (customDay)
                return {
                    message: customDay.title,
                    //  confetti: customDay.confetti,
                };
        }
    } catch (error) {
        browserLogger.error(error);
    }

    return {
        message: translation({
            sv: `Välkommen!`,
            en: `Welcome`,
        }),
    };
};

const yearLessDays = [
    {
        year: undefined,
        month: 3,
        day: 14,
        title: translation({
            sv: "Glad 🥧-dag!",
            en: "Happy 🥧-day!",
        }),
        // confetti: [confetti.shapeFromText({ text: "🥧", scalar: 2 })],
    },
    {
        year: undefined,
        month: 5,
        day: 4,
        title: translation({
            sv: "Må kraften vara med dig",
            en: "May the force be with you",
        }),
    },
    {
        year: undefined,
        month: 10,
        day: 31,
        title: translation({
            sv: "Glad Halloween!",
            en: "Happy Halloween!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🎃", scalar: 2 }),
        //     confetti.shapeFromText({ text: "👻", scalar: 2 }),
        // ],
    },
    {
        year: undefined,
        month: 12,
        day: 13,
        title: translation({
            sv: "Glad Lucia!",
            en: "Merry Lucia!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "⭐️", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🕯️", scalar: 2 }),
        // ],
    },
    {
        year: undefined,
        month: 12,
        day: 24,
        title: translation({
            sv: "God jul!",
            en: "Merry Christmas!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🎄", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🎅🏻", scalar: 2 }),
        //     confetti.shapeFromText({ text: "⭐️", scalar: 2 }),
        // ],
    },
    {
        year: undefined,
        month: 12,
        day: 25,
        title: translation({
            sv: "God jul!",
            en: "Merry Christmas!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🎄", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🎅🏻", scalar: 2 }),
        //     confetti.shapeFromText({ text: "⭐️", scalar: 2 }),
        // ],
    },
    {
        year: undefined,
        month: 12,
        day: 26,
        title: translation({
            sv: "God jul!",
            en: "Merry Christmas!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🎄", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🎅🏻", scalar: 2 }),
        //     confetti.shapeFromText({ text: "⭐️", scalar: 2 }),
        // ],
    },
    {
        year: undefined,
        month: 2,
        day: 14,
        title: translation({
            sv: "Glad alla hjärtans dag!",
            en: "Happy Valentine's day!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "❤️", scalar: 2 }),
        //     confetti.shapeFromText({ text: "💜", scalar: 2 }),
        // ],
    },
    {
        year: undefined,
        month: 6,
        day: 6,
        title: translation({
            sv: "Glad nationaldag 🇸🇪!",
            en: "Happy national day 🇸🇪!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🇸🇪", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🎉", scalar: 2 }),
        // ],
    },
];

const specificDays = [
    {
        year: 2024,
        month: 6,
        day: 22,
        title: translation({
            sv: "Glad midsommar!",
            en: "Happy midsummer!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🌸", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🌼", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🌷", scalar: 2 }),
        //     confetti.shapeFromText({ text: "☀️", scalar: 2 }),
        // ],
    },
    {
        year: 2025,
        month: 6,
        day: 21,
        title: translation({
            sv: "Glad midsommar!",
            en: "Happy midsummer!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🌸", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🌼", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🌷", scalar: 2 }),
        //     confetti.shapeFromText({ text: "☀️", scalar: 2 }),
        // ],
    },
    {
        year: 2026,
        month: 6,
        day: 19,
        title: translation({
            sv: "Glad midsommar!",
            en: "Happy midsummer!",
        }),
        // confetti: [
        //     confetti.shapeFromText({ text: "🌸", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🌼", scalar: 2 }),
        //     confetti.shapeFromText({ text: "🌷", scalar: 2 }),
        //     confetti.shapeFromText({ text: "☀️", scalar: 2 }),
        // ],
    },
];
const customDays = [...yearLessDays, ...specificDays];

export { welcomeMessage };
