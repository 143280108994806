import { useRef } from "react";

const usePrevious = <T>(
    value: T,
    options?: {
        startFromUndefined?: boolean;
    }
) => {
    const currentRef = useRef<T | undefined>(value);
    const previousRef = useRef<T | undefined>(
        options?.startFromUndefined ? undefined : value
    );

    if (currentRef.current !== value) {
        previousRef.current = currentRef.current;
        currentRef.current = value;
    }

    return previousRef.current;
};

export { usePrevious };
