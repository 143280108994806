import { HorizontalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ResponsiveStoreFrontHzProductCard } from "@@/store-fronts/shared/store-front-hz-product-card";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
// type Props = {};
const NavenLackoExperienceSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            // naven
            getProductId("product__sejNlbfid_V6ET3_FPZp" as ProductId),
            // lackö
            getProductId("product__RWTFPlh_Y53KpRH303uG" as ProductId),
        ],
        [],
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="naven-explore-unique-2"
            title={translation({
                sv: "Unika upplevelser vid Läckö",
                en: "Unique experiences at Läckö",
            })}
            subtitle={translation({
                sv: "Kom nära naturen med kanoter. Styr kosan mot glassen eller en fin kobbe för picknick och bad",
                en: "Get close to nature with canoes. Steer the kosa towards the ice cream or a nice cobble for a picnic and bath",
            })}
            subtitleColor={theme.colors.black.light15}
            subTitleLineHeight={1.5 as RemSize}
            subtitleSize={1 as RemSize}
            padding={isDesktop ? { all: 10 } : { topBottom: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <React.Fragment key={hasDuplicates ? index : id}>
                            <ResponsiveStoreFrontHzProductCard
                                cardSize={1.6 as RemSize}
                                productId={id}
                                actionText={text.book}
                                aspectRatio={isDesktop ? "4:3" : "3:4"}
                            />
                        </React.Fragment>
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
};

export { NavenLackoExperienceSection };
