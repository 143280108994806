import { useMutation } from "@tanstack/react-query";
import {
    SetUserPhoneNumberInitCommand,
    setUserPhoneNumberInitCommandFactory,
    SetUserPhoneNumberInitCommandFactoryParams,
} from "@towni/common";
import { apiFetchClient } from "../shared/fetch-client";

const useSetUserPhoneNumberInit = (options: {
    onSuccess: (
        data: void,
        input: SetUserPhoneNumberInitCommandFactoryParams,
    ) => void;
    onError?: (
        error: Error,
        input: SetUserPhoneNumberInitCommandFactoryParams,
    ) => void;
}) => {
    const setPhoneNumberCommand = async (
        params: SetUserPhoneNumberInitCommandFactoryParams,
    ) => {
        await apiFetchClient.post<SetUserPhoneNumberInitCommand>({
            route: `/users/me/phone`,
            body: setUserPhoneNumberInitCommandFactory(params),
        });
    };

    return useMutation<void, Error, SetUserPhoneNumberInitCommandFactoryParams>(
        {
            mutationFn: setPhoneNumberCommand,
            onSuccess: options.onSuccess,
            onError: options.onError,
        },
    );
};
export { useSetUserPhoneNumberInit };
