import { useBackgroundStyleToCss } from "@@/shared/stylable/background-style-css";
import { css, SerializedStyles, useTheme } from "@emotion/react";
import { BackgroundStyle } from "@towni/common";
import * as React from "react";

type Props = {
    width: number;
    height: number;
    background: BackgroundStyle;
    styling?: SerializedStyles;
    children?: React.ReactNode;
};

const Card = (props: Props) => {
    const theme = useTheme();
    const backgroundStyleToCss = useBackgroundStyleToCss();

    const styles = css`
        border-radius: 6px;
        width: ${props.width}px;
        height: ${props.height}px;
        overflow: hidden;
        ${backgroundStyleToCss(
            {
                ...props.background,
                imageSize: {
                    imageWidth:
                        props.background.imageSize?.imageWidth ?? props.width,
                    imageHeight: props.background.fit
                        ? undefined
                        : props.background.imageSize?.imageHeight ??
                          props.height,
                },
            },
            theme.colors.default.background,
        ) || ""}
        ${props.styling || ""}
    `;

    return <div css={styles}>{props.children}</div>;
};

export { Card };
