import { useTheme } from "@emotion/react";
import {
    AspectRatio,
    maxOf,
    minOf,
    Orientation,
    RemSize,
    SizeName,
    SizeTheme,
} from "@towni/common";
import { MergeExclusive } from "type-fest";

type NumericDimensions = {
    width: number;
    height: number;
    aspectRatio: number;
    orientation: Orientation;
};
type StringDimensions = {
    width: string;
    height: string;
    aspectRatio: "AUTO";
    orientation: Orientation;
};
type Dimensions = MergeExclusive<NumericDimensions, StringDimensions>;

const getDimensions =
    (sizes: SizeTheme) =>
    (params: {
        size: SizeName | RemSize;
        aspectRatio?: AspectRatio;
    }): NumericDimensions => {
        const widthAspectRatio = params.aspectRatio
            ? Number(params.aspectRatio.split(":")[0])
            : 3;
        const heightAspectRatio = params.aspectRatio
            ? Number(params.aspectRatio.split(":")[1])
            : 4;
        const orientation =
            widthAspectRatio === heightAspectRatio
                ? "SQUARE"
                : widthAspectRatio > heightAspectRatio
                  ? "LANDSCAPE"
                  : "PORTRAIT";
        const min = minOf([widthAspectRatio, heightAspectRatio]);
        const max = maxOf([widthAspectRatio, heightAspectRatio]);
        const basicSize =
            typeof params.size === "string"
                ? sizes.inPixels[params.size]
                : sizes.base * params.size;
        const smallSide = basicSize * 10.8; // 10.125;
        const largeSide = (max / min) * smallSide; // aspect ratio max:min/min:max

        // Return dimension data
        switch (orientation) {
            case "PORTRAIT":
                return {
                    width: smallSide,
                    height: largeSide,
                    aspectRatio: smallSide / largeSide,
                    orientation,
                };

            case "LANDSCAPE":
                return {
                    width: largeSide,
                    height: smallSide,
                    aspectRatio: largeSide / smallSide,
                    orientation,
                };
            case "SQUARE":
                return {
                    width: smallSide,
                    height: smallSide,
                    aspectRatio: 1,
                    orientation,
                };
        }
    };

const useDimensions = () => {
    const theme = useTheme();
    return getDimensions(theme.sizes);
};

export { getDimensions, useDimensions };
export type { Dimensions, NumericDimensions, StringDimensions };
