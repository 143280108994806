import {
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { ToastType } from "./toast-type";

const toastIcon = (type: ToastType) => {
    switch (type) {
        case "DANGER":
            return faExclamationTriangle;
        case "INFORMATION":
            return faInfoCircle;
        case "WARNING":
            return faExclamationCircle;
        case "SUCCESS":
            return faCheckCircle;
        default:
            return undefined;
    }
};

export { toastIcon };
