import { PageLoad } from "@@/pages/page-load";
import { AccommodationReceipt_OrderDetails } from "@@/receipts/accommodation/accommodation-receipt-order-details";
import { BookableReceipt_Tickets } from "@@/receipts/bookables/shared/bookable-receipt-tickets";
import { Affirmation } from "@@/receipts/shared/affirmation";
import { useResourceReservationsForOrders } from "@@/reservations/state/reservations-for-orders/use-resource-reservation-requests-for-orders";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { browserLogger } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { ConditionalSpin } from "@@/shared/show-or-spin";
import {
    Order,
    OrderGroup,
    OrderProjection,
    UserReference,
    isVerifiedByAccessKey,
} from "@towni/common";
import { MutableRefObject } from "react";
import { Receipt } from "../shared/receipt";
import { Receipt_OrderGroupDetails } from "../shared/receipt-order-group-details";
import { useOrderReceiptsQuery } from "../use-order-receipts-query";

type Props = {
    orderGroup: OrderGroup;
    orders: Order[];
    customer: UserReference;
    printRef: MutableRefObject<HTMLDivElement | null>;
};

const AccommodationReceipt = (props: Props) => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const { orders, orderGroup, customer } = props;
    const { branding } = useBrandingContext();
    const [resourceReservations, _resourceReservationsQuery] =
        useResourceReservationsForOrders(orders.map(order => order._id));
    const receiptsQuery = useOrderReceiptsQuery(orderGroup._id);

    const paymentIsPending = orders.some(
        order =>
            order.status.type === "PENDING_PAYMENT_CONFIRMATION" ||
            order.status.type === "CREATED",
    );

    if (receiptsQuery.isPending) return <PageLoad />;
    const order: OrderProjection | undefined = orders?.[0];

    browserLogger.info({ data: receiptsQuery.data });

    return (
        <>
            <BookableReceipt_Tickets
                customer={customer}
                orderGroup={orderGroup}
                orders={orders}
                resourceReservations={resourceReservations}
            />
            <VerticalDivider L />
            <div
                css={{
                    width: "100%",
                    filter: order?.inactive ? "grayscale(100%)" : "none",
                    opacity: order?.inactive ? 0.6 : 1,
                }}>
                <Receipt_OrderGroupDetails orders={orders}>
                    <ForEach
                        itemOf={orders}
                        getKey={order => order._id}
                        divider={<HorizontalSectionDivider />}>
                        {order => (
                            <AccommodationReceipt_OrderDetails
                                order={order}
                                resourceReservations={resourceReservations}
                            />
                        )}
                    </ForEach>
                </Receipt_OrderGroupDetails>
            </div>

            <ConditionalSpin
                spinWhen={receiptsQuery.isPending}
                spinner={<TextShimmer rows={4} />}>
                <ForEach
                    itemOf={receiptsQuery.data?.item.receipts ?? []}
                    getKey={receipt => receipt._id}
                    divider={<VerticalDivider size={isDesktop ? "XL" : "L"} />}
                    beginWith={
                        <VerticalDivider size={isDesktop ? "XL" : "L"} />
                    }>
                    {receipt => {
                        return (
                            <Receipt
                                orderGroup={orderGroup}
                                receipt={receipt}
                            />
                        );
                    }}
                </ForEach>
            </ConditionalSpin>
            <Affirmation
                printRef={props.printRef}
                isPaymentPending={paymentIsPending}
                nextUrlOverride={branding?.storefrontUrl}
                verifiedByAccessKey={isVerifiedByAccessKey(orderGroup)}
            />
        </>
    );
};

export { AccommodationReceipt };
