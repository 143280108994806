import { PageViewContextProvider } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { PageBlocksView } from "@@/backoffice/for-providers/pages/page-view";
import {
    usePageForIdFromUrl,
    usePageForProviderFromUrl,
} from "@@/backoffice/for-providers/pages/queries/page/use-page-from-url";
import { usePageBlocks } from "@@/backoffice/for-providers/pages/use-page-blocks-from-edit-context";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { ShowCartButton } from "@@/carts";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { AppPage } from "@@/pages/app-page";
import { PageLoad } from "@@/pages/page-load";
import { AppPageId } from "@@/pages/page-navigation/page-navigation-context";
import { AvailableLanguagesProvider } from "@@/settings/available-languages-context";
import { AccountIcon } from "@@/shared/account-icon";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, HorizontalLine } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { LanguagePicker, LanguagePickerMini } from "@@/shared/language-picker";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox, TextSpan } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { Global, useTheme } from "@emotion/react";
import {
    Page,
    ProviderId,
    RemSize,
    languageCodes,
    translation,
} from "@towni/common";
import { AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet-async";

const PageView = (props: { page: Page | undefined; isPending: boolean }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { page, isPending: isLoading } = props;
    const blocks = usePageBlocks(page);
    const availableLanguages = page?.languages || [...languageCodes];

    if (!page && isLoading) return <PageLoad />;
    if (!page)
        return (
            <PageNotFound
                reason={translation({
                    sv: "Sidan hittades inte",
                    en: "Page not found",
                })}
            />
        );
    if (!blocks?.length) return <NotFoundOrEmpty />;

    return (
        <AvailableLanguagesProvider languages={page.languages}>
            <PageCartWrapper providerId={page.ownerId}>
                <Global
                    styles={{
                        body: {
                            backgroundColor: page.backgroundColor ?? "#eeeeee",
                        },
                    }}
                />
                <GlobalMaxWidthCss maxWidth={undefined} />
                <Helmet>
                    <title>
                        {page.state !== "published"
                            ? translate(
                                  translation({
                                      sv: "Förhandsvisning",
                                      en: "Preview",
                                  }),
                              ) + " - "
                            : ""}
                        {translate(page.meta.title) ?? "(no name)"}
                    </title>
                </Helmet>
                <AppPage
                    pageId={page._id as string as AppPageId}
                    anchors={[]}
                    header={
                        <FlexRow
                            fillParentWidth
                            mainAxis="center"
                            crossAxis="center"
                            css={{
                                position: "absolute",
                                zIndex: 50,
                                flexShrink: 0,
                            }}>
                            <FlexColumn width={page.pageMaxWidth ?? 1200}>
                                <FlexRow
                                    fillParentWidth
                                    padding={{
                                        right: isMobile ? 20 : 30,
                                        top: isMobile ? 20 : 30,
                                    }}
                                    mainAxis="space-between"
                                    crossAxis="flex-start">
                                    <Conditional
                                        when={page.state !== "published"}>
                                        <FlexRow
                                            crossAxis="center"
                                            mainAxis="space-between"
                                            padding={{ top: 10 }}
                                            css={{
                                                marginLeft: -5,
                                                paddingTop: 13,
                                                paddingBottom: 13,
                                                paddingLeft: 25,
                                                paddingRight: 25,
                                                backgroundColor:
                                                    theme.colors.danger.main.withAlpha(
                                                        0.5,
                                                    ).asString,
                                            }}>
                                            <TextBox
                                                text={translation({
                                                    sv: "Förhandsvisning",
                                                    en: "Preview",
                                                })}
                                                weight="700"
                                                size={1.4}
                                                color={theme.colors.danger.text}
                                            />
                                        </FlexRow>
                                    </Conditional>
                                    <HorizontalDivider />
                                    <FlexRow>
                                        <Conditional
                                            when={
                                                availableLanguages.length > 1
                                            }>
                                            <LanguagePickerMini
                                                languages={availableLanguages}
                                                fontSize={1}
                                                iconSize={1.25}
                                                hideContainer
                                                dropShadow
                                                iconColor={
                                                    theme.colors.white.asString
                                                }
                                                css={{
                                                    color: theme.colors.white
                                                        .asString,
                                                }}
                                            />
                                            <HorizontalDivider />
                                            <HorizontalDivider />
                                        </Conditional>
                                        <AccountIcon />
                                    </FlexRow>
                                </FlexRow>
                            </FlexColumn>
                        </FlexRow>
                    }>
                    <PageViewContextProvider
                        pageId={page._id}
                        providerId={page.ownerId}
                        pageMaxWidth={page.pageMaxWidth}
                        pageInnerWidth={page.pageInnerWidth}
                        defaultBorderRadius={
                            page.branding?.borderRadius?.topLeft
                        }>
                        <PageBlocksView
                            blocksSorted={blocks}
                            pageSettings={{
                                backgroundColor: page.backgroundColor,
                            }}
                        />
                    </PageViewContextProvider>
                    <Conditional when={availableLanguages.length > 1}>
                        <FlexColumn
                            fillParentWidth
                            mainAxis="flex-start"
                            crossAxis="center"
                            css={{
                                backgroundColor:
                                    page.backgroundColor ?? "#eeeeee",
                            }}>
                            <HorizontalLine />
                            <FlexRow
                                mainAxis="center"
                                crossAxis="center"
                                css={{
                                    width: page.pageMaxWidth ?? 1200,
                                    backgroundColor:
                                        theme.colors.default.background
                                            .asString,
                                    paddingTop: 40,
                                    paddingBottom: 50,
                                }}>
                                <FlexRow css={{ width: 200 }}>
                                    <LanguagePicker
                                        languages={availableLanguages}
                                    />
                                </FlexRow>
                            </FlexRow>
                        </FlexColumn>
                    </Conditional>
                </AppPage>

                {props.page?.ownerId ? (
                    <AnimatePresence>
                        <ShowCartButton />
                    </AnimatePresence>
                ) : null}
            </PageCartWrapper>
        </AvailableLanguagesProvider>
    );
};

const NotFoundOrEmpty = () => {
    return (
        <>
            <GlobalMaxWidthCss maxWidth={undefined} />
            <FlexColumn
                mainAxis="center"
                crossAxis="center"
                padding={{ topBottom: 70, leftRight: 40 }}>
                <TextBox text="👀" size={4 as RemSize} />
                {/* <Icon icon={faEmptySet} size={4 as RemSize} color={"#cccccc"} /> */}
                <FlexRow mainAxis="center" padding={{ topBottom: 40 }}>
                    <TextBox
                        weight="900"
                        size={3.2}
                        align="center"
                        color={"#333"}
                        css={{ maxWidth: 400 }}>
                        <TextSpan
                            text={translation({
                                sv: "Tomt... ",
                                en: "Empty... ",
                            })}
                        />
                        <TextSpan
                            text={translation({
                                sv: "tomt... ",
                                en: "empty... ",
                            })}
                            css={{ fontSize: "2.9rem", opacity: 0.8 }}
                        />
                        <TextSpan
                            text={translation({ sv: "tom... ", en: "emp... " })}
                            css={{ fontSize: "2.6rem", opacity: 0.6 }}
                        />
                        <TextSpan
                            text={translation({ sv: "to... ", en: "em... " })}
                            css={{ fontSize: "2.3rem", opacity: 0.4 }}
                        />
                        <TextSpan
                            text={translation({ sv: "t... ", en: "e... " })}
                            css={{ fontSize: "2rem", opacity: 0.2 }}
                        />
                    </TextBox>
                </FlexRow>
            </FlexColumn>
        </>
    );
};

const PageCartWrapper = (props: {
    readonly providerId: ProviderId | undefined;
    readonly children: React.ReactNode;
}) => {
    const { children, providerId: pageProviderId } = props;

    if (!pageProviderId) {
        return <>{children}</>;
    }

    return (
        <CartContextProvider key={pageProviderId} providerId={pageProviderId}>
            <CheckoutContextProvider>{children}</CheckoutContextProvider>
        </CartContextProvider>
    );
};

const PageViewForProvider = (props: { providerId?: ProviderId }) => {
    const [, pageQuery] = usePageForProviderFromUrl({
        providerId: props.providerId,
    });
    const page = pageQuery.data;
    return <PageView page={page} isPending={pageQuery.isPending} />;
};
const PageViewForId = () => {
    const [page, { isPending }] = usePageForIdFromUrl();
    return <PageView page={page} isPending={isPending} />;
};

export { PageView, PageViewForId, PageViewForProvider };
