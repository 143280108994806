import { useMutation } from "@tanstack/react-query";
import {
    SetUserEmailAddressInitCommand,
    setUserEmailAddressInitCommandFactory,
    SetUserEmailAddressInitFactoryParams,
} from "@towni/common";
import { apiFetchClient } from "../shared/fetch-client";

const useInitSetUserEmailAddress = (options: {
    onSuccess: (
        data: void,
        input: SetUserEmailAddressInitFactoryParams,
    ) => void;
    onError?: (
        error: Error,
        input: SetUserEmailAddressInitFactoryParams,
    ) => void;
}) => {
    const setUserEmailAddress = async (
        params: SetUserEmailAddressInitFactoryParams,
    ) => {
        await apiFetchClient.post<SetUserEmailAddressInitCommand>({
            route: `/users/me/email`,
            body: setUserEmailAddressInitCommandFactory(params),
        });
    };

    return useMutation<void, Error, SetUserEmailAddressInitFactoryParams>({
        mutationFn: setUserEmailAddress,
        onSuccess: options.onSuccess,
        onError: options.onError,
    });
};
export { useInitSetUserEmailAddress };
