import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import {
    useFetch,
    useFetchMultiple,
    useFetchMultipleForMultiple,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";
import { useUrlParameter } from "@@/shared/use-query-or-param";
import { ProviderId, SectionGroup } from "@towni/common";

const mainQueryKey = "section-groups";
const mainRoute = "section-groups";

const useSectionGroup = (id?: string) =>
    useFetch<SectionGroup>(mainQueryKey, mainRoute, id);
const useSectionGroups = (ids?: string[]) =>
    useFetchMultiple<SectionGroup>(mainQueryKey, mainRoute, ids);
const useSectionGroupsForProvider = (providerId?: ProviderId) => {
    return useFetchMultipleForSingle<SectionGroup>({
        mainQueryKey,
        mainRoute,
        fetchFor: "provider",
        id: providerId,
    });
};
const useSectionGroupsForProviders = (providerIds?: ProviderId[]) =>
    useFetchMultipleForMultiple<SectionGroup>(
        mainQueryKey,
        mainRoute,
        "providers",
        providerIds
    );

const useDefaultSectionGroupForProvider = (
    providerId: ProviderId | undefined,
    sectionGroups: SectionGroup[]
) => {
    const provider = useProvider(providerId);
    const urlSectionGroupId = useUrlParameter("sectionGroupId");

    if (!providerId || !provider) return undefined;

    return (
        sectionGroups
            .filter(item => item.ownerId === providerId)
            .find(sg => sg._id === urlSectionGroupId) ||
        (provider &&
            sectionGroups.find(
                sg =>
                    sg.meta.tags.categories.includes("provider-default") ||
                    sg.meta.tags.custom.includes("default")
            )) ||
        sectionGroups?.[0]
    );
};

export {
    useSectionGroup,
    useSectionGroups,
    useSectionGroupsForProvider,
    useSectionGroupsForProviders,
    useDefaultSectionGroupForProvider,
};
