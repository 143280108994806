import { asDate, isoAndUnixFactory, IsoAndUnixTimestamp } from "@towni/common";
import * as React from "react";

type Props<DateType extends Date | IsoAndUnixTimestamp> = {
    selectedDate: DateType | undefined;
    onChange: (date: DateType) => void;
    children: React.ReactNode;
    className?: string;
};
const NativeDatePicker = <DateType extends Date | IsoAndUnixTimestamp>(
    props: Props<DateType>,
) => {
    const selectedDate = asDate(props.selectedDate);
    return (
        <label
            css={{
                display: "inline-flex",
                position: "relative",
                overflow: "clip",
            }}
            className={props.className}>
            {props.children}
            <input
                css={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    "::-webkit-calendar-picker-indicator": {
                        position: "absolute",
                        top: "-150%",
                        left: "-150%",
                        width: "300%",
                        height: "300%",
                        cursor: "pointer",
                    },
                }}
                type="date"
                value={selectedDate?.toISOString().slice(0, 10)}
                onChange={s => {
                    const newDate = s.target.valueAsDate;
                    if (!newDate) return;
                    const output: DateType = (
                        props.selectedDate instanceof Date
                            ? newDate
                            : isoAndUnixFactory(newDate)
                    ) as DateType;
                    props.onChange(output);
                }}
            />
        </label>
    );
};

export { NativeDatePicker };
