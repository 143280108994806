import { useMutation } from "@tanstack/react-query";
import {
    SendEmailVerificationCommand,
    sendEmailVerificationCommandFactory,
    SendEmailVerificationCommandFactoryParams,
    SetUserEmailAddressInitFactoryParams,
} from "@towni/common";
import { apiFetchClient } from "../shared/fetch-client";

const useSendUserEmailVerification = (options: {
    onSuccess: (
        data: void,
        input: SendEmailVerificationCommandFactoryParams,
    ) => void;
    onError?: (
        error: Error,
        input: SendEmailVerificationCommandFactoryParams,
    ) => void;
}) => {
    const sendUserEmailVerificationCommand = async (
        params: SendEmailVerificationCommandFactoryParams,
    ) => {
        await apiFetchClient.post<SendEmailVerificationCommand>({
            route: `/users/me/email/send-email-verification`,
            body: sendEmailVerificationCommandFactory(params),
        });
    };

    return useMutation<void, Error, SetUserEmailAddressInitFactoryParams>({
        mutationFn: sendUserEmailVerificationCommand,
        onSuccess: options.onSuccess,
        onError: options.onError,
    });
};
export { useSendUserEmailVerification };
