import { HorizontalDivider } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import {
    isBookable,
    Order,
    OrderGroup,
    OrderItemBookable,
    ResourceReservation,
    Translatable,
    translation,
    UserReference,
} from "@towni/common";
import { useMemo } from "react";
import { BookableReceipt_Ticket } from "./bookable-receipt-ticket";

type Props = {
    readonly orderGroup: OrderGroup;
    readonly orders: Order[];
    readonly customer: UserReference;
    readonly resourceReservations: ResourceReservation[];
};

const BookableReceipt_Tickets = (props: Props) => {
    const { orders, resourceReservations, customer } = props;

    const bookableOrderItems = useMemo(
        () =>
            orders.flatMap(order =>
                order.orderItems
                    .filter(isBookable)
                    .map(
                        orderItem =>
                            [order, orderItem] as [Order, OrderItemBookable],
                    ),
            ),
        [orders],
    );

    return (
        <ForEach divider={<HorizontalDivider />} itemOf={bookableOrderItems}>
            {([order, orderItem]) => {
                // TODO: WHAT STATUSES SHOULD BE VISIBLE ON RECEIPT?
                const inactiveText = ((): Translatable => {
                    if (order.hasBeenCancelled)
                        return translation({
                            sv: "Avbokad",
                            en: "Cancelled",
                        });
                    if (order.hasBeenRejected)
                        return translation({
                            sv: "Nekad",
                            en: "Rejected",
                        });
                    if (order.hasPassed)
                        return translation({
                            sv: "Passerad",
                            en: "Passed",
                        });
                    return null;
                })();

                return (
                    <BookableReceipt_Ticket
                        key={orderItem._id}
                        customer={customer}
                        order={order}
                        css={{
                            label: "bookable_receipt_ticket",
                            opacity: order.inactive ? 0.5 : 1,
                        }}
                        orderItem={orderItem}
                        resourceReservations={resourceReservations}
                        inactiveText={inactiveText}
                        inactive={order.inactive}
                    />
                );
            }}
        </ForEach>
    );
};

export { BookableReceipt_Tickets };
