import { ResourceIcon } from "@@/backoffice/for-providers/inventory/resources/resource-icon";
import { useLanguageToEditContext } from "@@/backoffice/for-providers/shared/language-to-edit.context";
import { SmallGrip } from "@@/pages/grips";
import { useLanguageContext } from "@@/settings/language-context";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    RemSize,
    ResourceGlobal,
    ResourceId,
    valueTranslator,
} from "@towni/common";

type Props = {
    resource: ResourceGlobal;
    toggle: (resourceId: ResourceId) => void;
    dragListeners?: DraggableSyntheticListeners;
    containerWidth: number | undefined;
};

const middleTruncate = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    const leftCount = Math.floor(maxLength / 2);
    const rightCount = Math.ceil(maxLength / 2);
    const ellipsis = "…"; // "…" is a single character
    return (
        text.slice(0, leftCount).trim() +
        ellipsis +
        text.slice(-rightCount).trim()
    );
};

const ResourcePickedPill = (props: Props) => {
    const theme = useTheme();
    const { resource, toggle } = props;
    const inactive = false; // resource.status.type !== "ACTIVE";
    const { language } = useLanguageToEditContext();
    const langContext = useLanguageContext();
    const translator = valueTranslator(language || langContext.language);
    const resourceColor =
        props.resource._type === "RESOURCE_GROUP"
            ? theme.colors.secondary.main.value.withAlpha(0.6).asString
            : theme.colors.primary.main.value.withAlpha(0.6).asString;

    const title = (() => {
        const width = props.containerWidth;
        if (typeof width === "undefined")
            return translator(resource.meta.title);
        const maxLength = (() => {
            if (width < 218) return 4;
            if (width < 235) return 5;
            if (width < 260) return 6;
            if (width < 280) return 7;
            if (width < 300) return 8;
            if (width < 350) return 6;
            if (width < 400) return 9;
            if (width < 500) return 8;
            if (width < 600) return 10;
            return null;
        })();

        if (maxLength === null) return translator(resource.meta.title);
        return middleTruncate(translator(resource.meta.title), maxLength);
    })();

    return (
        <FlexRow
            background={{
                color: inactive
                    ? theme.colors.disabled.background
                    : theme.colors.default.background,
            }}
            css={{
                width: "100%",
                borderRadius: 5,
                backgroundColor: resourceColor,
                color: theme.colors.white.asString,
                border: "none",
            }}
            mainAxis="space-between"
            crossAxis="stretch"
            {...(props.dragListeners ?? {})}>
            <Conditional
                when={!!props.dragListeners}
                render={() => (
                    <>
                        <FlexColumn
                            mainAxis="center"
                            {...props.dragListeners}
                            css={{
                                paddingLeft: 15,
                                "@container picked (width < 400px)": {
                                    display: "none",
                                },
                            }}>
                            <SmallGrip
                                size={0.625 as RemSize}
                                color={theme.colors.white.asString}
                                dragListeners={props.dragListeners}
                            />
                        </FlexColumn>
                        <HorizontalDivider S />
                    </>
                )}
            />
            <FlexRow
                css={{
                    flex: 1,
                    paddingTop: 5,
                    paddingBottom: 5,
                    overflow: "hidden",
                }}>
                <div
                    css={{
                        display: "flex",
                        flexDirection: "row",
                        "@container picked (width < 300px)": {
                            display: "none",
                        },
                        label: "resourceIcon",
                    }}>
                    <ResourceIcon resource={resource} size={1} />
                    <HorizontalDivider S />
                </div>
                <TextBox
                    text={title}
                    lineClamp={1}
                    size={0.825}
                    css={{ width: "100%", flexShrink: 1 }}
                />
            </FlexRow>
            <ButtonTransparent
                onPointerDown={event => {
                    // Let's ignore any drag events form dnd-kit
                    event.stopPropagation();
                }}
                onClick={event => {
                    event.stopPropagation();
                    toggle(resource._id);
                }}>
                <Icon
                    icon={faTimes}
                    cursor="pointer"
                    color={theme.colors.white}
                    padding={{ all: 0 }}
                />
            </ButtonTransparent>
        </FlexRow>
    );
};

export { ResourcePickedPill };
