import { VerticalDivider } from "@@/shared/dividers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";

type Props = {
    errorMessage: string | undefined;
};

const PaymentErrorMessage = (props: Props) => {
    const theme = useTheme();
    if (!props.errorMessage) return null;

    return (
        <>
            <VerticalDivider />
            <TextBox
                padding={{
                    topBottom: 5,
                    leftRight: 35,
                }}
                text={props.errorMessage}
                color={theme.colors.danger}
                size={0.8}
                shrink={0}
            />
            <VerticalDivider />
        </>
    );
};

export { PaymentErrorMessage };
