import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Picture } from "@@/shared/pictures/picture";
import { TextBox } from "@@/shared/text";
import { LogoCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { useTheme } from "@emotion/react";
import {
    MetaImage,
    RemSize,
    StorageItemImage,
    Translatable,
} from "@towni/common";

const TicketCustomerViewHeader = (props: {
    backgroundImage: MetaImage | StorageItemImage;
    logo?: MetaImage | StorageItemImage;
    title: Translatable;
    subtitle: Translatable;
    /**
     * defaults to `left`
     */
    logoPlacement?: "left" | "right";
    dataTestIds?: {
        backgroundElementId: string;
        logoId: string;
    };
}) => {
    const theme = useTheme();
    const { backgroundImage, logo, title, subtitle } = props;

    return (
        <FlexColumn
            fillParentWidth
            css={{ position: "relative" }}
            background={{ color: theme.colors.white }}>
            <Picture
                imageRef={backgroundImage}
                minHeight={130}
                height={200}
                imageOptions={{ imageHeight: 200, imageWidth: 310 }}
                data-testid={props.dataTestIds?.backgroundElementId}
            />

            <FlexRow
                fillParentWidth
                padding={{ all: 10 }}
                css={{ position: "relative", zIndex: 10, marginTop: -60 }}>
                <LogoCircle
                    logo={logo}
                    size={0.5 as RemSize}
                    boxShadow={`0px 4px 10px ${
                        theme.colors.black.value.withAlpha(0.1).asString
                    }`}
                    data-testid={props.dataTestIds?.logoId}
                />
            </FlexRow>

            <TextBox
                text={title}
                size={1.1875}
                weight="900"
                padding={{ leftRight: 20 }}
                color={theme.colors.black}
            />
            <VerticalDivider XS />
            <TextBox
                text={subtitle}
                padding={{ leftRight: 20 }}
                size={0.95}
                color={theme.colors.black.light30}
            />
            <VerticalDivider M />
        </FlexColumn>
    );
};

export { TicketCustomerViewHeader };
