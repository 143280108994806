import { useRateAdjustments } from "@@/backoffice/for-providers/products/accommodation/calendar/rate-adjustments/use-rate-adjustments";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";

import { useResourceReservationRequestInfoForProduct } from "@@/resource-reservations/state/resource-reservation-info/use-resource-reservation-info-for-product";
import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    AccommodationFeed,
    AccommodationQuantities,
    DateRange,
    OrderId,
    Product,
    RatePriceAdjustment,
    ResourceGraph,
    ResourceId,
    ResourceReservationRequestInfo,
    TimeRange,
    TimeRangeResourceAvailability,
    accommodationFeedFactory,
    calculateAvailability,
    emptyArrayOf,
    getAccommodationSettings,
    getDefaultEmptyFeed,
    timeRangeFactory,
} from "@towni/common";
import { useCallback, useMemo } from "react";

type AccommodationFeedResult = [
    feed: AccommodationFeed,
    reservations: ResourceReservationRequestInfo[],
    isPending: boolean,
];

//Move to server side but is expensive???
const useAccommodationFeed = (params: {
    product: Product;
    dateRange: DateRange | undefined;
    options?: {
        /** When given, the feed will ignore reservations connected to the given order id.
         * This is helpful during, for example, order changes when we care about all
         * reservations except the ones for the current orderId we're trying to change */
        ignoreResourceReservationsForOrderId?: OrderId;

        /** When true, the feed will ignore the time availability settings and show all resources
         * regardless of time availability. Still checks resource availabilt */
        overrideTimeAvailability?: boolean;
    };
}): AccommodationFeedResult => {
    const { product, dateRange, options } = params;
    const [, resourceGraphQuery] = useBookableResources(product._id);
    const globalResources =
        resourceGraphQuery.data ?? emptyArrayOf<ResourceGraph>();
    const rateAdjustmentsQuery = useRateAdjustments(product._id, dateRange);
    const rateAdjustments =
        rateAdjustmentsQuery.data ?? emptyArrayOf<RatePriceAdjustment>();

    const [, resourceReservationInfoQuery] =
        useResourceReservationRequestInfoForProduct(
            product._id,
            options?.ignoreResourceReservationsForOrderId,
            dateRange ? timeRangeFactory(dateRange) : undefined,
        );
    const resourceReservationInfo =
        resourceReservationInfoQuery.data ??
        emptyArrayOf<ResourceReservationRequestInfo>();

    const isPending =
        resourceGraphQuery.isPending ||
        rateAdjustmentsQuery.isPending ||
        resourceReservationInfoQuery.isPending;

    const feed = useMemo(() => {
        if (
            resourceGraphQuery.isPending ||
            rateAdjustmentsQuery.isPending ||
            resourceReservationInfoQuery.isPending
        )
            return getDefaultEmptyFeed(product);

        const newFeed = accommodationFeedFactory(
            product,
            resourceReservationInfo,
            globalResources,
            rateAdjustments,
            {
                overrideTimeAvailability: options?.overrideTimeAvailability,
            },
        );
        return newFeed;
    }, [
        resourceGraphQuery.isPending,
        rateAdjustmentsQuery.isPending,
        resourceReservationInfoQuery.isPending,
        product,
        resourceReservationInfo,
        globalResources,
        rateAdjustments,
        options?.overrideTimeAvailability,
    ]);

    return useMemo(
        () => [feed, resourceReservationInfo, isPending] as const,
        [feed, resourceReservationInfo, isPending],
    );
};

type AccommodationAvailabilityPending = {
    timeRange: TimeRange;
    resourcesAvailability: [];
    isLoaded: false;
};

const useCheckAccommodationAvailability = (product: Product) => {
    const { onPremiseSettings, resourceReservationInfo } =
        useBookingAccommodationContext(state => ({
            onPremiseSettings: state.onPremiseSettings,
            resourceReservationInfo: state.reservations,
        }));

    const [globalResources, globalResourcesQuery] = useBookableResources(
        product._id,
    );

    const checkAvailability = useCallback(
        (params: {
            timeRange: TimeRange;
            quantity: number;
            resourceIds: ResourceId[];
            quantities?: AccommodationQuantities;
        }):
            | TimeRangeResourceAvailability
            | AccommodationAvailabilityPending => {
            const { timeRange, quantity, resourceIds, quantities } = params;
            if (globalResourcesQuery.isPending)
                return {
                    timeRange,
                    resourcesAvailability: [],
                    isLoaded: false,
                };

            const settings = getAccommodationSettings(product, timeRange);
            if (!settings)
                return {
                    timeRange,
                    resourcesAvailability: [],
                    isLoaded: false,
                };

            const timeRangeResourceAvailability = calculateAvailability({
                timeRange,
                quantity,
                reservations: resourceReservationInfo,
                resourceIds,
                globalResources,
                quantities,
                settings: settings,
                options: {
                    overrideSession: !!onPremiseSettings,
                },
            });

            return timeRangeResourceAvailability;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            globalResources,
            resourceReservationInfo,
            globalResourcesQuery,
            onPremiseSettings,
        ],
    );

    return checkAvailability;
};

export { useAccommodationFeed, useCheckAccommodationAvailability };
export type { AccommodationFeedResult };
