import { useInvitation } from "@@/backoffice/dashboard/providers/users/invite-user/queries/use-invitation";
import { AppPage } from "@@/pages/app-page";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { TextBox } from "@@/shared/text";
import { ModalTitle } from "@@/shared/text/modal-title";
import * as Sentry from "@sentry/react";
import { isInvitationId, translation } from "@towni/common";
import { useLocation } from "react-router-dom";
import { useMe } from "../me/me-context";
import { PhoneSignInOrUpPage } from "../phone/phone-sign-in-or-up-page";
import { AcceptInvitation } from "./accept-invitation";

const InvitationPage = () => {
    const [me] = useMe();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const inviteStr = params.get("invite");
    const isInviteId = isInvitationId(inviteStr);
    const [invite] = useInvitation(isInviteId && me ? inviteStr : undefined);

    if (!me)
        return (
            <PhoneSignInOrUpPage
                hide={() => {
                    //TODO
                }}
            />
        );

    return (
        <Sentry.ErrorBoundary>
            <HeadTitle
                title={translation({
                    sv: "Kontoinbjudan",
                    en: "Account invite",
                })}
            />
            <AppPage pageId={appPageIdFactory("sign-in")}>
                <FlexColumn padding={{ leftRight: 20 }} crossAxis="center">
                    <FlexRow
                        mainAxis="space-between"
                        crossAxis="center"
                        padding={{ topBottom: 20 }}>
                        <FlexColumn crossAxis="center">
                            <ModalTitle
                                text={translation({
                                    sv: "Kontoinbjudan",
                                    en: "Invite",
                                })}
                            />
                        </FlexColumn>
                    </FlexRow>
                    <HorizontalLine fillParentWidth />

                    <VerticalDivider />
                    {invite ? (
                        <AcceptInvitation invitation={invite} />
                    ) : (
                        <TextBox
                            text={translation({
                                sv: "Inbjudan has passerat, be admin att skicka en ny",
                                en: "Invitation has expired, please ask admin to send a new one",
                            })}
                        />
                    )}
                    <VerticalDivider />
                </FlexColumn>
            </AppPage>
        </Sentry.ErrorBoundary>
    );
};

export { InvitationPage };
