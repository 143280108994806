import { SelectAccommodationSubType } from "@@/carts/accommodation-checkout/accommodation-cart.sub-type-select";
import { useAccommodationCartUniqueActiveSubTypes } from "@@/carts/accommodation-checkout/use-accommodation-cart-active-sub-types";
import { useAccommodationCartResourcesWithSubType } from "@@/carts/accommodation-checkout/use-accommodation-sub-type-picked";
import { useHasMissingSubType } from "@@/carts/accommodation-checkout/use-has-missing-sub-type";
import { Conditional } from "@@/shared/conditional";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Label } from "@@/shared/label";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    AccommodationSubTypeId,
    ResourceReservationRequest,
    isAccommodationResourceReservationRequest,
    isOrderItemAccommodation_V2,
    translation,
} from "@towni/common";
import { useEffect } from "react";
import { useCartContext } from "../multi-carts/cart.context";

const AccommodationCart_SubTypePicker_Single = () => {
    const theme = useTheme();
    const { cart, setAccommodationSubTypes } = useCartContext(context => ({
        cart: context.cart,
        setAccommodationSubTypes: context.setAccommodationSubTypes,
    }));

    const [orderItem] = cart.orderItems.filter(isOrderItemAccommodation_V2);
    const resourcesWithPickableSubType =
        useAccommodationCartResourcesWithSubType();
    const subTypes = useAccommodationCartUniqueActiveSubTypes();
    const hasPicked = !useHasMissingSubType();
    const updateReservation =
        (
            reservation: ResourceReservationRequest,
            subTypeData: (typeof subTypes)[0],
        ) =>
        () => {
            const [_, __, accommodationTypeAndSubTypeId] = subTypeData;
            if (!isAccommodationResourceReservationRequest(reservation)) return;
            if (!reservation.accommodationType) return;
            if (!orderItem) return;
            setAccommodationSubTypes({
                [accommodationTypeAndSubTypeId]: 1,
            } as Record<AccommodationSubTypeId, number>);
        };

    // If there is only one sub type, select it
    // and set count to the number of resources needing it
    useEffect(
        function autoSelectSubTypeWhenOnlyOneChoice() {
            if (subTypes.length === 1) {
                const [_, __, accommodationTypeAndSubTypeId] = subTypes[0];
                setAccommodationSubTypes({
                    [accommodationTypeAndSubTypeId]:
                        resourcesWithPickableSubType.length,
                });
            }
        },
        [subTypes, resourcesWithPickableSubType, setAccommodationSubTypes],
    );

    if (!orderItem || resourcesWithPickableSubType.length === 0)
        return <HorizontalSectionDivider key={"bc2_start"} />;

    if (subTypes.length <= 1) return null;
    return (
        <FlexColumn
            fillParent
            css={{
                label: "pick_accommodation_subtype",
            }}>
            <HorizontalLine />
            <ForEach
                itemOf={resourcesWithPickableSubType}
                getKey={resource => resource.reservation._id}>
                {({ reservation }) => {
                    return (
                        <FlexColumn
                            css={{
                                borderLeft: `6px solid ${hasPicked ? theme.colors.primary.asString : theme.colors.danger.asString}`,
                                label: "pick_accommodation_subtype",
                            }}
                            key={reservation._id}>
                            <VerticalDivider S />

                            <FlexRow
                                mainAxis="space-between"
                                crossAxis="center"
                                padding={{ leftRight: 10 }}>
                                <TextBox
                                    color={
                                        hasPicked
                                            ? undefined
                                            : theme.colors.danger.asString
                                    }
                                    text={translation({
                                        sv: "Välj typ",
                                        en: "Choose type",
                                    })}
                                    weight="700"
                                    size={"M"}
                                />
                                <Label
                                    text={translation({
                                        sv: "obligatorisk",
                                        en: "mandatory",
                                    })}
                                    colorTheme="RED"
                                />
                            </FlexRow>
                            <VerticalDivider S />
                            <Conditional when={subTypes.length > 1}>
                                <FlexColumn>
                                    <ForEach
                                        getKey={([subType]) => subType._id}
                                        itemOf={subTypes}>
                                        {subTypeData => {
                                            const [
                                                subType,
                                                ,
                                                accommodationTypeAndSubTypeId,
                                            ] = subTypeData;
                                            const isPicked =
                                                (cart
                                                    .accommodationSubTypeCount?.[
                                                    accommodationTypeAndSubTypeId
                                                ] ?? 0) > 0;

                                            return (
                                                <SelectAccommodationSubType
                                                    option={subType}
                                                    selected={isPicked}
                                                    toggle={updateReservation(
                                                        reservation,
                                                        subTypeData,
                                                    )}
                                                />
                                            );
                                        }}
                                    </ForEach>
                                </FlexColumn>
                            </Conditional>
                        </FlexColumn>
                    );
                }}
            </ForEach>

            <HorizontalLine />
            <VerticalDivider S />
        </FlexColumn>
    );
};

export { AccommodationCart_SubTypePicker_Single };
