import { toObjectMap } from "@towni/common";
import { z } from "zod";

const toastTypes = ["INFORMATION", "DANGER", "WARNING", "SUCCESS"] as const;
const toastType = toObjectMap<ToastType>([...toastTypes]);
const toastTypeZodEnum = z.enum([...toastTypes]);
type ToastType = z.infer<typeof toastTypeZodEnum>;

export { toastType, toastTypeZodEnum, toastTypes };
export type { ToastType };
