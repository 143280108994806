import { FieldId, FormId } from "@@/shared/form/form-and-field-id";

import { useModal } from "@@/modals/use-modal";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { useFormId } from "@@/shared/form/form-id.context";
import { AccommodationSubTypeRow } from "@@/shared/form/form2-accommodation-sub-types/accommodation-sub-type-row";
import { AddAccommodationSubTypeModal } from "@@/shared/form/form2-accommodation-sub-types/add-accommodation-sub-type.modal";
import { useFormField } from "@@/shared/form/use-form-field";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text/text-box";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
    AccommodationSubType,
    AccommodationSubTypeId,
    Padding,
    Translatable,
    emptyArrayOf,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { ZodSchema } from "zod";

type PossibleValueTypes = AccommodationSubType[] | undefined | null;
type DefaultValueType = AccommodationSubType[] | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
};

const createFormAccommodationSubTypesComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2AccommodationSubTypes = <
        Value extends PossibleValueTypes = DefaultValueType,
    >(
        props: Props<State, Value>,
    ) => {
        const [{ show }, modalId] = useModal();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            fieldSchema: props.fieldSchema,
            formId: props.formId,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );
        const onRemove = (subTypeId: AccommodationSubTypeId) => {
            const value = field.value;
            if (!value) return;
            const update = value.filter(
                subType => subType._id !== subTypeId,
            ) as NonNullable<Value>;
            field.setValue(update);
        };
        return (
            <FlexColumn fillParentWidth>
                <ForEach
                    itemOf={field.value ?? emptyArrayOf<AccommodationSubType>()}
                    divider={<VerticalDivider />}
                    endWith={<VerticalDivider S />}>
                    {(subType, _index) => (
                        <AccommodationSubTypeRow
                            subType={subType}
                            onRemove={onRemove}
                            onEdit={subType => {
                                if (!field.value) return;
                                const update = field.value.map(value =>
                                    value._id === subType._id ? subType : value,
                                ) as NonNullable<Value>;
                                field.setValue(update);
                            }}
                        />
                    )}
                </ForEach>
                <ButtonWhite
                    padding={{ all: 15 }}
                    fillParentWidth
                    onClick={show}>
                    <Icon icon={faPlus} />
                    <HorizontalDivider />
                    <TextBox
                        text={translation({
                            sv: "Lägg till subtyp",
                            en: "Add sub type",
                        })}
                    />
                </ButtonWhite>
                <AddAccommodationSubTypeModal
                    key={field.value?.length}
                    modalId={modalId}
                    onAdd={subType => {
                        if (field.value) {
                            field.setValue([
                                ...(field.value ?? []),
                                subType,
                            ] as NonNullable<Value>);
                        } else {
                            field.setValue([subType] as Value);
                        }
                    }}
                />
            </FlexColumn>
        );
    };

    return Form2AccommodationSubTypes;
};

export { createFormAccommodationSubTypesComponent };
