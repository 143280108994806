import { AccommodationCost } from "@@/carts/bookable-checkout/accommodation-cost";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    Percentage,
    getChildOptionals,
    isOrderItemBuyable_V2,
    isOrderItemDiscount_V2,
    isOrderItemUsedGiftCard_V2,
    percentageToHuman,
    sumPrices,
    zeroPrice,
} from "@towni/common";
import { useCart } from "../multi-carts/cart.context";
import { TotalAmountRow } from "../total-amount-row";
import { VatAmountRow } from "../vat-amount-row";
import { BookedResourceCost } from "./resource-cost";

const CartRowsAndTotal = () => {
    const theme = useTheme();
    const cart = useCart();
    const prices = [cart.totalPrice, cart?.delivery?.price || zeroPrice];
    const total = sumPrices(prices);

    return (
        <FlexColumn
            shrink={0}
            padding={{ leftRight: 20, top: 10, bottom: 20 }}
            css={{ label: "CART_ROWS_AND_TOTAL" }}
            fillParentWidth>
            <AccommodationCost orderItems={cart.orderItems} />
            <BookedResourceCost
                orderItems={cart.orderItems}
                //    reservations={reservations}
            />
            <ForEach
                itemOf={cart.orderItems.filter(isOrderItemBuyable_V2)}
                getKey={orderItem => orderItem._id}>
                {orderItem => {
                    const optional = getChildOptionals(
                        orderItem,
                        cart.orderItems,
                    );
                    const totalPriceWithOptional = sumPrices([
                        orderItem.price,
                        ...optional.map(o => o.price),
                    ]);

                    return (
                        <FlexRow
                            shrink={0}
                            mainAxis="space-between"
                            key={orderItem._id}>
                            <FlexRow>
                                <TextBox
                                    text={`${orderItem.quantity.value}x`}
                                    size="S"
                                    color={theme.colors.black.light60}
                                />
                                <HorizontalDivider XXS />
                                <TextBox
                                    text={orderItem.meta.title}
                                    size="S"
                                    color={theme.colors.black.light60}
                                />
                            </FlexRow>
                            <PriceTextBox
                                price={totalPriceWithOptional}
                                size="S"
                                color={theme.colors.black.light60}
                            />
                        </FlexRow>
                    );
                }}
            </ForEach>

            <ForEach
                itemOf={cart.orderItems.filter(isOrderItemUsedGiftCard_V2)}>
                {orderItem => {
                    return (
                        <FlexRow
                            shrink={0}
                            mainAxis="space-between"
                            key={orderItem._id}>
                            <FlexRow shrink={0} mainAxis="flex-start">
                                <TextBox
                                    text={orderItem.meta.title}
                                    size="M"
                                    color={theme.colors.black.light60}
                                />
                            </FlexRow>
                            <PriceTextBox
                                price={orderItem.price}
                                size="M"
                                color={theme.colors.black.light60}
                            />
                        </FlexRow>
                    );
                }}
            </ForEach>
            <ForEach itemOf={cart.orderItems.filter(isOrderItemDiscount_V2)}>
                {orderItem => {
                    return (
                        <FlexRow
                            shrink={0}
                            mainAxis="space-between"
                            key={orderItem._id}>
                            <FlexRow shrink={0} mainAxis="flex-start">
                                <TextBox
                                    text={orderItem.meta.title}
                                    size="M"
                                    color={theme.colors.black.light60}
                                />
                                <Conditional
                                    when={orderItem.type === "PERCENTAGE"}>
                                    <HorizontalDivider XS />
                                    <TextBox
                                        text={percentageToHuman(
                                            orderItem.value as Percentage,
                                        )}
                                        size="M"
                                        color={theme.colors.black.light60}
                                    />
                                </Conditional>
                            </FlexRow>
                            <PriceTextBox
                                price={orderItem.price}
                                size="M"
                                color={theme.colors.black.light60}
                            />
                        </FlexRow>
                    );
                }}
            </ForEach>
            <VerticalDivider S />
            <TotalAmountRow price={total} size="M" />
            <VerticalDivider XXS />
            <VatAmountRow
                price={sumPrices([
                    cart.price,
                    cart?.delivery?.price || zeroPrice,
                ])}
                color={theme.colors.black.light60}
            />
        </FlexColumn>
    );
};

export { CartRowsAndTotal };
