import { ModalId } from "@@/modals/context/modal-id";
import { useModalActions } from "@@/modals/use-modal-actions";
import { css, useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    UserAddress,
    anonymousUserAddressZodSchema,
    translation,
} from "@towni/common";
import * as React from "react";
import { useRef } from "react";
import { BottomButton } from "../../buttons/bottom-button";
import { FlexColumn, FlexRow } from "../../flex-containers";
import { Spacer } from "../../spacer";
import { ModalTitle } from "../../text/modal-title";
import { useElementSize } from "../../use-element-size";
import { useIsContentScrolled } from "../../use-is-content-scrolled";
import { AddressInputGoogleAutoComplete } from "./address-input-google-auto-complete";

type Props = {
    current: Partial<UserAddress>;
    modalId: ModalId;
    update: (address: Partial<UserAddress>) => void;
    save: () => void;
    isEdit: boolean;
    noAddressFound: () => void;
    hideAddressNameField?: boolean;
};

const AddressPickerGoogleAutoCompleteModal: React.FC<Props> = props => {
    const theme = useTheme();
    const { hide } = useModalActions(props.modalId);

    const topBarRef = useRef<HTMLDivElement>(null);
    const [topBarSize] = useElementSize({ targetRef: topBarRef });
    const [contentRef, setContentRef] = React.useState<HTMLDivElement | null>();
    const isContentScrolled = useIsContentScrolled(contentRef);

    return (
        <>
            <FlexColumn
                fillParent
                background={{ color: theme.colors.default.background }}
                crossAxis="stretch"
                overflow="hidden">
                <FlexRow
                    shrink={0}
                    mainAxis="space-between"
                    crossAxis="center"
                    ref={topBarRef}
                    styling={css`
                        ${isContentScrolled
                            ? `
                            :before {
                                content: "";
                                position: absolute;
                                top: ${topBarSize?.height ?? 0}px;
                                left: 0;
                                width: 100%;
                                height: 50px;
                                pointer-events: none;
                                z-index: 100;
                                background: linear-gradient(180deg, #DDDDDD44, rgba(255,255,255,0.0001));
                            }`
                            : ""}
                    `}
                    padding={{ leftRight: 20, topBottom: 20 }}>
                    <Spacer basis={0.2} shrink={0}>
                        <FontAwesomeIcon
                            onClick={hide}
                            icon={faChevronLeft}
                            style={{
                                fontSize: 20,
                                cursor: "pointer",
                            }}
                        />
                    </Spacer>
                    <FlexColumn basis={0.6} crossAxis="center">
                        <FlexRow crossAxis="center">
                            <ModalTitle
                                text={translation(
                                    props.isEdit
                                        ? {
                                              sv: "Ändra adress",
                                              en: "Update address",
                                          }
                                        : {
                                              sv: "Lägg till adress",
                                              en: "Add address",
                                          },
                                )}
                            />
                        </FlexRow>
                    </FlexColumn>
                    <Spacer basis={0.2} shrink={0} />
                </FlexRow>
                <FlexColumn ref={setContentRef} shrink={1} overflowY="auto">
                    <AddressInputGoogleAutoComplete {...props} />
                </FlexColumn>
            </FlexColumn>
            <BottomButton
                disabled={
                    !anonymousUserAddressZodSchema.safeParse(props.current)
                        .success
                }
                onClick={props.save}
                text={
                    props.isEdit
                        ? translation({
                              sv: "Spara",
                              en: "Save",
                          })
                        : translation({
                              sv: "Lägg till",
                              en: "Add",
                          })
                }
            />
        </>
    );
};

export { AddressPickerGoogleAutoCompleteModal };
