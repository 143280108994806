import { AppTheme } from "@@/styles/theme";
import { ColorSet } from "@towni/common";
import { ToastType } from "./toast-type";

const toastColorSet = (type: ToastType, theme: AppTheme): ColorSet => {
    switch (type) {
        case "DANGER":
            return theme.colors.danger;
        case "INFORMATION":
            return theme.colors.information;
        case "WARNING":
            return theme.colors.warning;
        case "SUCCESS":
            return theme.colors.primary;
        default:
            return theme.colors.default;
    }
};

export { toastColorSet };
