import { useAccommodationCartResourcesWithSubType } from "@@/carts/accommodation-checkout/use-accommodation-sub-type-picked";
import {
    AccommodationSubType,
    AccommodationTypeAndSubTypeId,
    AccommodationTypeId,
    accommodationTypeAndSubTypeIdFactory,
    uniqueBy,
} from "@towni/common";
import { useMemo } from "react";

/**
 * Returns a list of unique active sub types that are available to be selected
 * for the resources in the accommodation cart and it's related data.
 */
const useAccommodationCartUniqueActiveSubTypes = (): (readonly [
    subType: AccommodationSubType,
    AccommodationTypeId,
    AccommodationTypeAndSubTypeId,
])[] => {
    const resourcesWithPickableSubType =
        useAccommodationCartResourcesWithSubType();
    const uniqueActiveSubTypes = useMemo(() => {
        return uniqueBy(
            resourcesWithPickableSubType.flatMap(item => {
                const accommodationType = item.resource.accommodationType;
                const allSubTypes =
                    item.resource.accommodationType.subTypes ?? [];
                const activeSubTypes = allSubTypes?.filter(
                    subType =>
                        !item.resource.disabledAccommodationSubTypeIds?.includes(
                            subType._id,
                        ),
                );
                const result = (activeSubTypes ?? []).map(subType => {
                    return [
                        subType,
                        accommodationType._id,
                        accommodationTypeAndSubTypeIdFactory(
                            accommodationType._id,
                            subType._id,
                        ),
                    ] as const;
                });
                return result;
            }),
            ([subType]) => subType._id,
        );
    }, [resourcesWithPickableSubType]);

    return uniqueActiveSubTypes;
};

export { useAccommodationCartUniqueActiveSubTypes };
