/* eslint-disable react/prop-types */
// import { ReactQueryDevtools } from "react-query-devtools";
import { useMountEffect } from "@@/shared/use-mount-effect";

const PreventValueScrollingOnNumberInputs = () => {
    useMountEffect(() => {
        const listener = (event: WheelEvent) => {
            if (!document.activeElement) return;
            if (document.activeElement.tagName !== "INPUT") return;
            const inputElement = document.activeElement as HTMLInputElement;
            if (inputElement.type === "number") {
                inputElement.blur();
                event.preventDefault();
            }
        };
        document.addEventListener("wheel", listener, { passive: false });
        return () => {
            document.removeEventListener("wheel", listener);
        };
    });
    return null;
};

export { PreventValueScrollingOnNumberInputs };
