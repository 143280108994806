import {
    debugEnabledLocalStorageKey,
    isDebugEnabled,
} from "@@/settings/debug/debug-toggle";
import {
    LocalStorageFlagChanged,
    alfredLocalStorageFlagChangeCustomEventName,
} from "@@/shared/use-local-storage";

const checkDebugEnabled = (_event?: CustomEvent<LocalStorageFlagChanged>) => {
    const debugEnabled = isDebugEnabled();
    browserLogger.toggle(_event?.detail.newValue ?? debugEnabled);
    // if (debugEnabled) {
    //     // eslint-disable-next-line no-console
    //     browserLogger.log("Debug enabled");
    //     browserLogger.warn("Debug enabled");
    //     browserLogger.error(new Error("Debug enabled"));
    //     browserLogger.count("Debug enabled");
    //     browserLogger.table("Debug enabled");
    //     browserLogger.dir("Debug enabled");
    //     browserLogger.dirxml("Debug enabled");
    //     browserLogger.trace("Debug enabled");
    // }
};

const consoleMethods = [
    "log",
    "warn",
    "error",
    "info",
    "debug",
    "table",
    "trace",
    "clear",
    "count",
    "countReset",
    "group",
    "groupCollapsed",
    "groupEnd",
    "time",
    "timeLog",
    "timeEnd",
    "assert",
    "dir",
    "dirxml",
    "profile",
    "profileEnd",
    "timeStamp",
] as const;
type ConsoleMethod = (typeof consoleMethods)[number];

const browserLogger = {
    enabled: false,
    toggle: (enabled?: boolean): void => {
        browserLogger.enabled = !!enabled;
        // eslint-disable-next-line no-console
        console.log(
            "logger " + (browserLogger.enabled ? "enabled" : "disabled"),
        );
    },
    ...consoleMethods.reduce(
        (acc, method) => {
            return {
                ...acc,
                [method]: (...params: unknown[]) => {
                    try {
                        const emoji = (() => {
                            switch (method) {
                                // case "error":
                                //     return "🚨";
                                // case "warn":
                                //     return "⚠️";
                                case "info":
                                    return "ℹ️";
                                case "debug":
                                    return "🐞";
                                case "log":
                                    return "🪵";
                                default:
                                    return undefined;
                            }
                        })();
                        if (!browserLogger.enabled) return;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if (emoji) (console as any)[method](emoji, ...params);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        else (console as any)[method](...params);
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }
                },
            };
        },
        {} as Record<ConsoleMethod, (...params: unknown[]) => void>,
    ),
} as {
    enabled: boolean;
    toggle: (enabled?: boolean) => void;
} & {
    [key in ConsoleMethod]: (...params: unknown[]) => void;
};

// Check if enabled directly
checkDebugEnabled();

// When changes in local local happens, check if debug mode enabled has changed
// window.addEventListener("storage", checkDebugEnabled);
window.addEventListener(alfredLocalStorageFlagChangeCustomEventName, event => {
    const _event = event as CustomEvent<LocalStorageFlagChanged>;
    if (_event.detail.key !== debugEnabledLocalStorageKey) return;
    checkDebugEnabled(event as CustomEvent<LocalStorageFlagChanged>);
});

export { browserLogger };
