import {
    priceFactory,
    repeat,
    sumPrices,
    zeroPrice,
    type OrderItem,
    type Price,
    type ResourceReservationRequest,
} from "@towni/common";

type CartOrderItemPrices = {
    price: Price;
    tipPrice: Price;
    totalPrice: Price;
};

const calculateCartOrderItemPrices = (
    orderItems: OrderItem[],
    tip: number,
    _reservations: ResourceReservationRequest[],
): CartOrderItemPrices => {
    const price = sumPrices(orderItems.map(oi => calculateOrderItemPrice(oi)));
    const tipPrice = tip
        ? priceFactory({
              amountIncludingVat: price.amountIncludingVat * tip,
              vat: 0,
          })
        : zeroPrice;
    const totalPrice = sumPrices([price, tipPrice]);

    return {
        price,
        tipPrice,
        totalPrice,
    };
};

const calculateOrderItemPrice = (
    oi: OrderItem,
    //reservations: ResourceReservationRequest[]
) => {
    //if (oi.acquireType === "BUYABLE")
    return sumPrices(repeat(oi.pricePerItem, oi.quantity.value));

    // //BOOKABLE MOVE
    // const numberOfBookedTime = inMinutes({
    //     seconds: oi.time.duration,
    // });
    // const resourcePricePerTime = sumPrices(
    //     reservations.flatMap(b =>
    //         b.optionValues.flatMap(o =>
    //             bookableOptionPriceCalculator({
    //                 bookedMinutes: numberOfBookedTime,
    //                 option: o,
    //                 quantity: isQuantityPickerBookableOptionValue(o)
    //                     ? o.quantity.value
    //                     : 0,
    //             })
    //         )
    //     )
    // );
    // console.log("PRICESSS", oi.pricePerItem, resourcePricePerTime);
    // const price = sumPrices([
    //     oi.pricePerItem, //Original Price (Base price)
    //     resourcePricePerTime,
    // ]);

    //  return price;
};

export { calculateCartOrderItemPrices };
