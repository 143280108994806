import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTheme } from "@emotion/react";
import { RemSize, Translatable, translation } from "@towni/common";
import { Link } from "react-router-dom";

// type Props = {};
const OneSquareLinkSectionTakeAway = (props: {
    title?: Translatable;
    subtitle?: Translatable;
    kicker?: Translatable;
    actionTitle?: Translatable;
}) => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const imageUrl =
        "https://we-are-alfred.imgix.net/destinations/bauer-food-takeaway.jpg?dpr=2&q=60&fm=jpg&w=800&fit=crop&crop=edges";

    return (
        <SectionArea
            sectionAreaId="take-away-bunn-section"
            padding={{ top: 10 }}>
            <Link to={`/shop/bauergarden-i-bunn/`}>
                <FlexColumn
                    fillParentWidth
                    padding={{ leftRight: 20 }}
                    crossAxis="flex-start"
                    shrink={0}>
                    <SectionAreaCard
                        aspectRatio={isDesktop ? "3:2" : "1:1"}
                        radius={16}
                        background={{
                            fit: "COVER",
                            imageUrl,
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ all: 20 }}
                            background={{
                                linearGradient: darkeningGradient,
                            }}
                            mainAxis="space-between"
                            crossAxis="center">
                            <FlexColumn fillParentWidth shrink={0}>
                                <TextBox
                                    text={
                                        props.title ||
                                        translation({
                                            sv: "Bauergårdens mat",
                                            en: "Bauergårdens food",
                                        })
                                    }
                                    weight="900"
                                    size={2 as RemSize}
                                    color={theme.colors.white}
                                    css={{
                                        textShadow:
                                            "0 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                />
                                <VerticalDivider />
                                <TextBox
                                    text={props.subtitle || ""}
                                    weight="500"
                                    size={1 as RemSize}
                                    color={theme.colors.white}
                                    css={{
                                        textShadow:
                                            "0 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                />
                                <Conditional when={!!props.kicker}>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={props.kicker}
                                        weight="800"
                                        size={0.75 as RemSize}
                                        case="UPPERCASE"
                                        color={theme.colors.white}
                                        css={{
                                            textShadow:
                                                "0 4px 4px rgba(0, 0, 0, 0.25)",
                                        }}
                                    />
                                </Conditional>
                                <VerticalDivider S />
                            </FlexColumn>
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={
                                        props.actionTitle ||
                                        translation({
                                            sv: "Se allt utbud!",
                                            en: "Bauergården food!",
                                        })
                                    }
                                />
                            </FlexRow>
                        </FlexColumn>
                    </SectionAreaCard>
                </FlexColumn>
            </Link>
        </SectionArea>
    );
};

export { OneSquareLinkSectionTakeAway };
