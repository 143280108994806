import { css } from "@emotion/react";
import { Padding } from "@towni/common";
import * as React from "react";
import { paddingToCssValue } from "./padding";

type Props = Partial<React.HTMLAttributes<HTMLDivElement>> & {
    readonly overflow?: React.CSSProperties["overflow"];
    readonly overflowY?: React.CSSProperties["overflowY"];
    readonly overflowX?: React.CSSProperties["overflowX"];
    readonly gridTemplateColumns?: React.CSSProperties["gridTemplateColumns"];
    readonly gridTemplateRows?: React.CSSProperties["gridTemplateRows"];
    readonly fillParent?: boolean;
    readonly fillParentWidth?: boolean;
    readonly fillParentHeight?: boolean;
    readonly gap?: React.CSSProperties["gap"];
    readonly rowGap?: React.CSSProperties["rowGap"];
    readonly columnGap?: React.CSSProperties["columnGap"];
    readonly padding?: Padding;
    readonly gridAutoFlow?: React.CSSProperties["gridAutoFlow"];
    readonly className?: string;
    readonly dataTestId?: string;
    readonly tabIndex?: HTMLElement["tabIndex"];
};

const LayoutGrid = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        className,
        fillParent,
        fillParentHeight,
        fillParentWidth,
        gridTemplateColumns,
        gridTemplateRows,
        gridAutoFlow,
        overflow,
        overflowX,
        overflowY,
        gap,
        padding,
        rowGap,
        columnGap,
        dataTestId,
        ...divProps
    } = props;
    const paddingCss = paddingToCssValue(padding);

    const styles = css({
        display: "grid",
        width: fillParent || fillParentWidth ? "100%" : undefined,
        height: fillParent || fillParentHeight ? "100%" : undefined,
        rowGap: rowGap || gap || 0,
        columnGap: columnGap || gap || 0,
        gridTemplateColumns,
        gridTemplateRows,
        overflow,
        overflowY,
        overflowX,
        gridAutoFlow,
        padding: paddingCss,
    });

    return (
        <div
            {...divProps}
            ref={ref}
            css={styles}
            tabIndex={props.tabIndex}
            className={className}
            style={{}}
            data-testid={dataTestId}>
            {props.children}
        </div>
    );
});

export { LayoutGrid };
