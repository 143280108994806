import { FontWeight } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    colorAsString,
    ColorItem,
    Padding,
    RemSize,
    SizeName,
    sizeNameMap,
    sizeNames,
    Translatable,
} from "@towni/common";
import { Conditional } from "../conditional";
import { HorizontalDivider } from "../dividers";
import { FlexRow } from "../flex-containers";
import { paddingToCssValue } from "../padding";
import { TextBox, TextBoxProps } from "./text-box";

type Props = {
    htmlFor?: string;
    text: TextBoxProps["text"];
    description?: Translatable;
    children?: never;
    size?: SizeName | RemSize;
    weight?: FontWeight;
    color?: ColorItem;
    padding?: Padding;
    required?: boolean;
    hide?: boolean;
    className?: string;
    disabled?: boolean;
};
const FieldTitle = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const fontSize = props.size
        ? (sizeNames as unknown as string[]).includes(props.size.toString())
            ? `${theme.sizes.inRem[props.size as SizeName]}rem`
            : `${props.size}rem`
        : `${theme.sizes.inRem[sizeNameMap.S]}rem`;
    const color = props.color ? colorAsString(props.color) : undefined;

    if (props.hide) return null;
    return (
        <FlexRow className={props.className}>
            <label
                htmlFor={props.htmlFor}
                title={translate(props.description)}
                css={{
                    fontSize,
                    color,
                    fontWeight: props.weight ?? "800",
                    opacity: props.disabled ? 0.5 : undefined,
                    padding: props.padding
                        ? paddingToCssValue(props.padding)
                        : "unset",
                }}>
                {translate(props.text)}
            </label>
            <Conditional
                when={!!props.required}
                render={() => (
                    <>
                        <HorizontalDivider XXS />
                        <TextBox
                            text="*"
                            color={theme.colors.danger}
                            css={{
                                fontSize,
                                opacity: props.disabled ? 0.5 : undefined,
                            }}
                        />
                    </>
                )}
            />
        </FlexRow>
    );
};

export { FieldTitle };
