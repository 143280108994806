import { AutoSizedVirtualList } from "@@/backoffice/shared/virtual-list-2";
import { useBookingForTimeRangeChecker } from "@@/products/bookables/hooks/use-booking-for-timeranges";
import { useCheckBookableSession } from "@@/products/bookables/hooks/use-check-bookable-session";
import { useCurrentBookablePrice } from "@@/shared/buttons/bookables/use-current-bookable-price";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import {
    BookableSession,
    createSessionStatus,
    emptyArrayOf,
    FullDate,
    TimeRange,
    toFullDate,
} from "@towni/common";
import * as React from "react";
import { VariableSizeList } from "react-window";
import { BookableSessionSelectListSection } from "./bookable-session-select-list-section";

type Props = {
    readonly sessions: BookableSession[];

    // If true, the session will be ignored when checking availability
    readonly ignoreSession?: boolean;
};
const BookableSessionSelectList = (props: Props) => {
    const { sessions, ignoreSession } = props;
    const [checkSessionAvailability, isLoading] = useCheckBookableSession();
    const currentPrice = useCurrentBookablePrice();
    const [checkTimeRangeAvailability] = useBookingForTimeRangeChecker();

    const checkTimeRange = React.useCallback(
        (timeRange: TimeRange) => {
            const val = checkTimeRangeAvailability(timeRange, ignoreSession);

            return val;
        },
        [ignoreSession, checkTimeRangeAvailability],
    );

    const sessionsByFullDate = React.useMemo(() => {
        return sessions.reduce(
            (prev, current) => {
                const fullDate = toFullDate(current.start);
                prev[fullDate] = prev[fullDate] || [];
                prev[fullDate].push(current);

                return prev;
            },
            {} as Record<FullDate, BookableSession[]>,
        );
    }, [sessions]);

    const [fullDates, setFullDates] =
        React.useState<FullDate[]>(emptyArrayOf<FullDate>());

    const prevFullDates = React.useRef(fullDates);
    React.useEffect(() => {
        // Compare if there are changes
        const oldFullDates = prevFullDates.current;
        const newFullDates = Object.keys(
            sessionsByFullDate,
        ) as unknown as FullDate[];

        if (
            oldFullDates.length === newFullDates.length &&
            oldFullDates.every(
                (fullDate, index) => newFullDates[index] === fullDate,
            )
        ) {
            return;
        }
        setFullDates(newFullDates);
        prevFullDates.current = newFullDates;
    }, [sessionsByFullDate]);

    const listRef = React.useRef<VariableSizeList<FullDate[]> | null>(null);

    React.useEffect(() => {
        listRef.current?.scrollToItem(0);
        listRef.current?.scrollTo(0);
        listRef.current?.resetAfterIndex(0);
        listRef.current?.forceUpdate();
    }, [fullDates]);

    return (
        <FlexColumn fillParent shrink={1} grow={1}>
            <AutoSizedVirtualList<FullDate[]>
                key={"session-list"}
                paddingEnd={100}
                listRef={listRef}
                renderItem={params => {
                    const { item: fullDate, onSizeChange } = params;
                    //checkAvailability
                    const sessions = sessionsByFullDate[fullDate];
                    if (!sessions) return <TextShimmer rows={2} />;
                    //checkAvailability

                    const sessionsAvailability = sessions.map(item => {
                        const resourceAvailability = checkSessionAvailability(
                            item,
                            undefined,
                            ignoreSession,
                        );
                        const isBookable =
                            !resourceAvailability.resourceAvailabilities.some(
                                a => !a.available,
                            );

                        if (isBookable) return item;
                        // If not bookable, set session to full and unbookable
                        return setSessionToFull(item);
                    });

                    return (
                        <BookableSessionSelectListSection
                            key={fullDate}
                            sessions={sessionsAvailability}
                            onSizeChange={onSizeChange}
                            checkAvailability={checkTimeRange}
                            isLoading={isLoading}
                            currentPrice={currentPrice}
                        />
                    );
                }}
                overscanCount={0}
                itemData={fullDates}
                itemKey={(index, data) => data[index]}
                estimatedItemSize={500}
            />
        </FlexColumn>
    );
};

const setSessionToFull = (session: BookableSession) => {
    return {
        ...session,
        status: createSessionStatus({
            type: "FULL",
        }),
    };
};

export { BookableSessionSelectList };
