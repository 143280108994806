import { browserLogger } from "@@/settings";
import { isApiError } from "@towni/common";

//As react-query onError only works a middleware, we need to catch and ignore the error if its handled by the middleware
//Removes handled error from sentry
const ifApiErrorDontThrow = (e: unknown) => {
    if (isApiError(e)) {
        browserLogger.error("Catch and ignore ApiError", e);
        return;
    }
    throw e;
};

export { ifApiErrorDontThrow };
