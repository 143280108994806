import {
    ApiError,
    DurationInSeconds,
    Translatable,
    capitalCase,
    isApiError,
    isoAndUnixZodSchema,
    nowIsoAndUnix,
    support,
    translatableZodSchema,
    translation,
} from "@towni/common";
import { Except } from "type-fest";
import { z } from "zod";
import { toastIdFactory, toastIdZodSchema } from "./toast-id";
import { ToastType, toastTypeZodEnum } from "./toast-type";

const toastZodObject = z.object({
    _id: toastIdZodSchema,
    _createdAt: isoAndUnixZodSchema,
    _type: z.literal("TOAST"),

    key: z.string().optional(),
    title: translatableZodSchema.optional(),
    message: translatableZodSchema,
    type: toastTypeZodEnum,
    sticky: z.boolean(),
    closeable: z.boolean(),
    dataTestId: z.string().optional(),
    timeout: z
        .number()
        .int()
        .positive() as unknown as z.ZodSchema<DurationInSeconds>,
    onClick: z
        .function()
        .returns(z.void().or(z.promise(z.void())))
        .optional(),
    onShow: z
        .function()
        .returns(z.void().or(z.promise(z.void())))
        .optional(),
});
type Toast = z.infer<typeof toastZodObject>;

type ToastFactoryDefaultParams = Except<
    Partial<Toast>,
    "_createdAt" | "_type"
> & {
    type: Toast["type"];
    message: Toast["message"];
};

type ToastFactoryFromErrorParams = Except<
    ToastFactoryDefaultParams,
    "message" | "type" | "title"
> & {
    error: Error | ApiError | unknown;
    /**
     * defaults to "DANGER"
     * @type {ToastType}
     */
    type?: ToastType;
    /**
     * If error is a regular error and not an ApiError,
     * this will be used as the message instead of the error message
     * @type {Translatable}
     */
    messageOverrideWhenNotApiError?: Translatable;
};
type ToastFactoryParams =
    | ToastFactoryDefaultParams
    | ToastFactoryFromErrorParams;
const isFromApiErrorToastFactoryParams = (
    input: ToastFactoryParams | undefined,
): input is ToastFactoryFromErrorParams => {
    if (typeof input === "undefined") return false;
    if ("error" in input) {
        return isApiError(input.error) || input.error instanceof Error;
    }
    return false;
};

function toastFactory(params: ToastFactoryDefaultParams): Toast;
function toastFactory(params: ToastFactoryFromErrorParams): Toast;
function toastFactory(
    params: ToastFactoryDefaultParams | ToastFactoryFromErrorParams,
): Toast {
    if (isFromApiErrorToastFactoryParams(params)) {
        const isSticky =
            typeof params.sticky !== "undefined"
                ? params.sticky
                : params.type === "DANGER";
        const message = isApiError(params.error)
            ? params.error.reason
            : params.messageOverrideWhenNotApiError ??
              (params.error instanceof Error
                  ? params.error.message
                  : translation({
                        sv: `Ett fel uppstod, kontakta ${support.towni.supportEmail} om felet kvarstår`,
                        en: `An error occurred, please contact ${support.towni.supportEmail} if the error persists`,
                    }));
        const title = isApiError(params.error)
            ? params.error.reasonTitle
            : params.messageOverrideWhenNotApiError
              ? undefined
              : params.error instanceof Error && params.error.name
                ? capitalCase(params.error.name)
                : undefined;

        return {
            ...params,
            _id: params._id ?? toastIdFactory(),
            _type: "TOAST",
            _createdAt: nowIsoAndUnix(),
            message,
            title,
            type: params.type ?? "DANGER",
            sticky: isSticky,
            closeable: isSticky || !!params.closeable, // todo, icky that sticky demands true, clean up, stateify
            timeout: params.timeout ?? (5 as DurationInSeconds),
        };
    }

    // Default
    const isSticky =
        typeof params.sticky !== "undefined"
            ? params.sticky
            : params.type === "DANGER";
    return {
        ...params,
        _id: params._id ?? toastIdFactory(),
        _type: "TOAST",
        _createdAt: nowIsoAndUnix(),
        sticky: isSticky,
        closeable: isSticky || !!params.closeable, // todo, icky that sticky demands true, clean up, stateify
        timeout: params.timeout ?? (5 as DurationInSeconds),
    };
}

export { toastFactory };
export type {
    Toast,
    ToastFactoryDefaultParams,
    ToastFactoryFromErrorParams,
    ToastFactoryParams,
};
