import { HorizontalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { useTheme } from "@emotion/react";
import { ColorItem, emptyArrayOf, MILLISECONDS, Provider } from "@towni/common";
import { FC } from "react";
import { ProviderLogoLinkCircle } from "./provider-logo-link-circle";

type Props = {
    providers: Provider[] | undefined;
    backgroundColor?: ColorItem;
    sectionId: string;
};

const ProviderLogoSection: FC<Props> = props => {
    const theme = useTheme();
    const providers = props.providers ?? emptyArrayOf<Provider>();
    return (
        <SectionArea
            sectionAreaId={props.sectionId}
            titleColor={theme.colors.primary}
            subtitleColor={theme.colors.black.light20}
            background={{
                color: props.backgroundColor ?? "#ffffff",
            }}>
            <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {!providers.length ? (
                    <CircleSpinner
                        size={5.2}
                        delayInMs={100 as MILLISECONDS}
                        color={"#eee"}
                    />
                ) : null}
                {providers.map(provider => {
                    return (
                        <ProviderLogoLinkCircle
                            key={provider._id}
                            provider={provider}
                        />
                    );
                })}
                <HorizontalDivider XS />
            </SectionAreaList>
        </SectionArea>
    );
};

export { ProviderLogoSection };
