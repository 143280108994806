import { OrderGiftCardCodes } from "@@/products/giftcards/order-giftcard-codes";
import { Affirmation } from "@@/receipts/shared/affirmation";
import { Receipt } from "@@/receipts/shared/receipt";
import { Receipt_OrderGroupDetails } from "@@/receipts/shared/receipt-order-group-details";
import { useOrderReceiptsQuery } from "@@/receipts/use-order-receipts-query";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { ConditionalSpin } from "@@/shared/show-or-spin";
import { Order, OrderGroup, isVerifiedByAccessKey } from "@towni/common";
import { MutableRefObject } from "react";
import { DigitalVoucherReceipt_OrderDetails } from "../vouchers/shared/digital-voucher-receipt-order-details";
import { DigitalGiftCardReceipt_Tickets } from "./digital-giftcards-receipt-tickets";

type Props = {
    orderGroup: OrderGroup;
    orders: Order[];
    printRef: MutableRefObject<HTMLDivElement | null>;
};

const DigitalGiftCardReceipt = (props: Props) => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const { orders, orderGroup } = props;
    const { branding } = useBrandingContext();
    const receiptsQuery = useOrderReceiptsQuery(orderGroup._id);

    const paymentIsPending = orders.some(
        order =>
            order.status.type === "PENDING_PAYMENT_CONFIRMATION" ||
            order.status.type === "CREATED",
    );

    return (
        <>
            <DigitalGiftCardReceipt_Tickets orders={orders} />
            <VerticalDivider L />

            <Receipt_OrderGroupDetails orders={orders} posterDisabled>
                <ForEach
                    itemOf={orders}
                    getKey={o => o._id}
                    divider={<HorizontalSectionDivider />}>
                    {order => (
                        <>
                            <DigitalVoucherReceipt_OrderDetails order={order} />
                            <OrderGiftCardCodes order={order} />
                        </>
                    )}
                </ForEach>
            </Receipt_OrderGroupDetails>
            <VerticalDivider L />
            <ConditionalSpin
                spinWhen={receiptsQuery.isPending}
                spinner={<TextShimmer rows={4} />}>
                <ForEach
                    itemOf={receiptsQuery.data?.item.receipts ?? []}
                    divider={<VerticalDivider size={isDesktop ? "XL" : "L"} />}
                    beginWith={
                        <VerticalDivider size={isDesktop ? "XL" : "L"} />
                    }>
                    {receipt => {
                        return (
                            <Receipt
                                orderGroup={orderGroup}
                                receipt={receipt}
                            />
                        );
                    }}
                </ForEach>
            </ConditionalSpin>
            <Affirmation
                printRef={props.printRef}
                isPaymentPending={paymentIsPending}
                nextUrlOverride={branding?.storefrontUrl}
                verifiedByAccessKey={isVerifiedByAccessKey(orderGroup)}
            />
        </>
    );
};

export { DigitalGiftCardReceipt };
