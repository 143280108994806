import { useUpdateEffect } from "@@/shared/use-update-effect";
import { useMemo, useState } from "react";
import { ModalId, isModalId, modalIdFactory } from "./context/modal-id";
import { useModalActions } from "./use-modal-actions";

const getModalId = (nameSuffixOrModalId?: `${string}__` | ModalId) => {
    return isModalId(nameSuffixOrModalId)
        ? nameSuffixOrModalId
        : modalIdFactory(nameSuffixOrModalId);
};

const useModal = (nameSuffixOrModalId?: `${string}__` | ModalId) => {
    const [modalId, setModalId] = useState(() =>
        getModalId(nameSuffixOrModalId),
    );
    const actions = useModalActions(modalId);

    useUpdateEffect(() => {
        setModalId(getModalId(nameSuffixOrModalId));
    }, [nameSuffixOrModalId]);

    const result = useMemo(
        () => [actions, modalId] as const,
        [actions, modalId],
    );
    return result;
};
export { useModal };
