import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { PayRequestProcessingPage } from "@@/pay-requests/pay-request-processing.page";
import { PayRequestPage } from "@@/pay-requests/pay-request.page";
import { CheckSwishPayment } from "@@/payments/check-swish-payment";
import { ReceiptPage } from "@@/receipts/receipt.page";
import { useDebugEnabled } from "@@/settings/debug/debug-toggle";
import { LanguageProvider } from "@@/settings/language-context";
import { MultiContextProvider } from "@@/shared/multi-provider";
import { Redirect } from "@@/shared/redirect";
import { FailedToLoadBackOfficeDynamically } from "@@/shared/text/failed-to-load-backoffice-dynamically.error";
import { useKeyboardShortcut } from "@@/shared/use-keyboard-shortcut";
import {
    ToastContextProvider,
    useToast,
} from "@@/toasts/context/toast-context";
import { ToastContainer } from "@@/toasts/toast-container";
import { RequiredMeProvider } from "@@/users/required-me.context-provider";
import { translation } from "@towni/common";
import * as React from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { InvitationPage } from "../authentication/invitation/invitation-page";
import { SignIn } from "../authentication/sign-in.page";
import { AccessDeniedPage } from "../backoffice/shared/access-denied-page";
import { OrderGroupSuccessPage } from "../orders/order-groups/order-group-success";
import { PageLoad } from "../pages/page-load";
import { ProviderShopPage } from "../providers/provider-shop-page";
import {
    PageViewForId,
    PageViewForProvider,
} from "../store-fronts/pages/page-view";
import { TicketRedirect } from "../tickets/ticket-redirect";
import { TicketsForCustomerPage } from "../tickets/tickets-for-customer-page";
import { DestinationPage } from "./destinations/$destination/destination.page";
import { MainErrorBoundaries } from "./main-error-boundaries";
import { MainStoreFront } from "./main-store-front";
import { ProductsPage } from "./products/products-page";
import ProviderHome from "./provider-home";
import { BrandingProvider } from "./shared/branding-context";
import { KittensAndRainbowsError } from "./shared/kittens-and-rainbows-error";
import { VouchersPage } from "./vouchers/vouchers.page";

const BackOffice = React.lazy(() =>
    import("../backoffice/backoffice.routes")
        .then(module => ({
            default: module.BackOfficeRoutes,
        }))
        .catch(error => {
            throw new FailedToLoadBackOfficeDynamically(error);
        }),
);

const MainRoutes = () => {
    const toast = useToast();
    const [debugEnabled, setDebugEnabled] = useDebugEnabled();
    const location = useLocation();
    const navigate = useNavigate();
    const isAlfred = useHasAlfredScope();
    useKeyboardShortcut({
        keys: ["d", "a", "s", "h"],
        callback: () => {
            navigate("/bo/dashboard");
        },
        disabled: !isAlfred,
    });
    useKeyboardShortcut({
        keys: "debug".split(""),
        callback: () => {
            const newValue = !debugEnabled;
            setDebugEnabled(newValue);
            toast.info({
                message: newValue
                    ? translation({
                          sv: `🥳  Debug-läge aktiverat`,
                          en: `🥳  Debug mode enabled`,
                      })
                    : translation({
                          sv: `😢  Debug-läge inaktiverat`,
                          en: `😢  Debug mode disabled`,
                      }),
            });
        },
        disabled: !isAlfred,
    });
    return (
        <MainErrorBoundaries>
            <Routes>
                <Route path="/" element={<MainStoreFront />} />
                <Route path="/shop" element={<Redirect to="/" />} />
                <Route path="/areas/lidkoping" element={<MainStoreFront />} />
                <Route path="/areas/lidköping" element={<MainStoreFront />} />

                <Route key="pages" path="pages">
                    {/* OBS! dessa måste matcha med de som finns på servern i serveIndexHtml */}
                    <Route
                        key="provider-page"
                        path=":providerIdOrSlug/:pageIdOrSlug/*"
                        element={<PageViewForProvider />}
                    />
                    <Route
                        key="page"
                        path=":pageId"
                        element={<PageViewForId />}
                    />
                </Route>
                <Route
                    key="destinationer-lista"
                    path="/destinations"
                    element={<Navigate to="/" replace />}
                />
                <Route
                    key="destinationer"
                    path="/destinations/:destination/*"
                    element={<DestinationPage />}
                />
                <Route
                    key="vouchers"
                    path="/vouchers"
                    element={<VouchersPage />}
                />
                <Route
                    // OBS! denna måste matcha med den som finns på servern i serveIndexHtml
                    key="voucher-details"
                    path="/vouchers/*"
                    element={<ProductsPage />}
                />

                <Route
                    key="home"
                    path="/home/:providerIdOrSlug"
                    element={<ProviderHome />}
                />
                <Route
                    key="shop1"
                    path="/shop/:providerSlug"
                    element={<ProviderShopPage />}
                />
                <Route
                    key="shop2"
                    path="/shop/:providerSlug/:sectionGroupId"
                    element={<ProviderShopPage />}
                />
                <Route
                    // OBS! denna måste matcha med den som finns på servern i serveIndexHtml
                    key="/products"
                    path="/products/*"
                    element={<ProductsPage />}
                />
                <Route
                    // OBS! denna måste matcha med den som finns på servern i serveIndexHtml
                    key="/bookables"
                    path="/bookables/*"
                    element={<ProductsPage />}
                />
                <Route
                    key="/payments/success"
                    path="/payments/success"
                    element={<OrderGroupSuccessPage />}
                />
                <Route
                    key="/pay-requests"
                    path="/pay-requests/:payRequestId"
                    element={<PayRequestPage />}
                />
                <Route
                    key="/pay-requests"
                    path="/pay-requests/:payRequestId/processing"
                    element={<PayRequestProcessingPage />}
                />
                <Route
                    key="/receipts"
                    path="/receipts/:orderGroupId"
                    element={
                        <MultiContextProvider providers={[BrandingProvider]}>
                            <ReceiptPage />
                        </MultiContextProvider>
                    }
                />
                <Route
                    key="/swish-check"
                    path="/swish-check/:orderGroupId/:paymentUUID"
                    element={
                        <RequiredMeProvider>
                            <CheckSwishPayment />
                        </RequiredMeProvider>
                    }
                />

                <Route
                    key="/tickets-check"
                    path="/tickets/check/:ticketRef"
                    element={<TicketRedirect />}
                />
                <Route
                    key="/tickets-check"
                    path="/tickets/c/:ticketRef"
                    element={<TicketRedirect />}
                />
                <Route
                    key="/tickets-customer-page"
                    path="/tickets/:ticketRef"
                    element={<TicketsForCustomerPage />}
                />

                {/* BACKOFFICE */}
                <Route
                    key="/login"
                    path="/login"
                    element={
                        <SignIn
                            close={() => {
                                navigate("/");
                            }}
                        />
                    }
                />
                <Route
                    key="/invitation"
                    path="/invitation"
                    element={<InvitationPage />}
                />
                <Route
                    key="/backoffice"
                    path="/backoffice/*"
                    element={
                        <Navigate
                            to={`${location.pathname.replace(
                                "/backoffice",
                                "/bo",
                            )}`}
                            replace
                        />
                    }
                />
                <Route
                    key="/bo/*"
                    path="/bo/*"
                    element={
                        <React.Suspense
                            fallback={
                                <PageLoad
                                    text={translation({
                                        sv: "Laddar administratörssidor",
                                        en: "Loading backoffice",
                                    })}
                                />
                            }>
                            <LanguageProvider scope="bo">
                                <ToastContextProvider>
                                    <ToastContainer />
                                    <BackOffice />
                                </ToastContextProvider>
                            </LanguageProvider>
                        </React.Suspense>
                    }
                />
                <Route
                    key="/access-denied"
                    path={"/access-denied"}
                    element={<AccessDeniedPage />}
                />
                <Route
                    key="/page-not-found"
                    path={"/page-not-found"}
                    element={<PageNotFound />}
                />
                <Route
                    key="/give-me-an-error-please"
                    path={"/give-me-an-error-please"}
                    element={<KittensAndRainbowsError />}
                />
                <Route
                    key="/load-me-up"
                    path={"/load-me-up"}
                    element={
                        <PageLoad text="🎶 Load me up, before you go go 🎶" />
                    }
                />
            </Routes>
        </MainErrorBoundaries>
    );
};

export { MainRoutes };
