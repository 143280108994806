import { setWithinPercentageRange } from "@towni/common";
import * as React from "react";

type Props = {
    basis?: number;
    children?: React.ReactNode;
    grow?: number;
    shrink?: number;
    width?: number;
    height?: number;
    className?: string;
};
const Spacer = ({ width, height, children, grow, ...props }: Props) => {
    const shrink = props.shrink || 0;
    const basis = props.basis && setWithinPercentageRange(props.basis);
    return (
        <div
            className={props.className}
            css={{
                flexGrow: grow,
                flexBasis: basis ? `${basis * 100}%` : undefined,
                width,
                height,
                flexShrink: shrink,
                label: "spacer",
            }}>
            {children}
        </div>
    );
};

export { Spacer };
