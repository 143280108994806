import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";
import { Except } from "type-fest";

type ParagraphIcon = {
    _type: "PARAGRAPH_ICON";
    icon: IconDefinition;
    fixedWidth?: boolean;
    faIconProps?: Except<FontAwesomeIconProps, "icon" | "fixedWidth">;
    onClick?: MouseEventHandler<SVGSVGElement>;
    css?: Interpolation<AppTheme>;
    spin?: boolean;
    disabled?: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphIcon = (item: any): item is ParagraphIcon => {
    return item?._type === "PARAGRAPH_ICON";
};

const paragraphIconFactory = (
    params: Except<ParagraphIcon, "_type">,
): ParagraphIcon => {
    return {
        _type: "PARAGRAPH_ICON",
        ...params,
    };
};

const ParagraphIcon = (props: {
    iconData: ParagraphIcon;
    className?: string;
}) => {
    const theme = useTheme();
    const linkColor = theme.colors.primary.asString;
    const onClick = props.iconData.disabled
        ? undefined
        : props.iconData.onClick;
    const styles = [
        ...(onClick
            ? [
                  {
                      color: linkColor,
                      ":hover,:visited,:focus,:active": {
                          color: linkColor,
                          textDecoration: "none",
                      },
                      ":hover": {
                          color: theme.colors.secondary.asString,
                          textDecoration: "underline",
                      },
                      cursor: "pointer",
                  },
              ]
            : []),
        props.iconData.css,
    ];
    return (
        <FontAwesomeIcon
            onClick={onClick}
            {...(props.iconData.faIconProps ?? {})}
            icon={props.iconData.spin ? faSpinnerThird : props.iconData.icon}
            spin={!!props.iconData.spin}
            css={styles}
            fixedWidth={props.iconData.fixedWidth}
        />
    );
};

export { ParagraphIcon, isParagraphIcon, paragraphIconFactory };
