/* eslint-disable react/display-name */
import { Space } from "@@/shared/space";
import { TextBox, TextSpan } from "@@/shared/text";
import { FontWeight } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    Color,
    ColorItem,
    DeliverySelection,
    DigitalDeliverySelection,
    Padding,
    Provider,
    Time,
    Translatable,
    UserAddress,
    asDate,
    deliveryMethods,
    getNameOfDay,
    getProviderPrepTime,
    timeToString,
    timeToStringRounded,
    translation,
} from "@towni/common";
import { format, isToday, isTomorrow } from "date-fns";
import { enGB as enLocale, sv as svLocale } from "date-fns/locale";
import * as React from "react";

type DProps = {
    provider: Provider;
    deliverySelection: Exclude<DeliverySelection, DigitalDeliverySelection>;
    color?: Color;
};

const DeliveryMethodText = (props: DProps) => {
    const theme = useTheme();
    const { deliverySelection: delivery } = props;
    let method = deliveryMethods.find(f => delivery.method === f.type);
    const prepTime = getProviderPrepTime(props.provider);
    if (!method) method = deliveryMethods[0];
    const timeSpotFormatter = useTimeSpotFormatter();

    const [formatted, setFormatted] = React.useState(
        timeSpotFormatter({ prepTime, selected: delivery.slot }),
    );

    React.useEffect(() => {
        setFormatted(timeSpotFormatter({ prepTime, selected: delivery.slot }));
    }, [delivery.slot]);

    const row =
        delivery.method === "HOME_DELIVERY" ? (
            <HomeDeliverySpan
                address={delivery.destination}
                prepTime={prepTime + delivery.shippingTime}
                selected={delivery.slot}
                padding={{ top: 3, bottom: 4, leftRight: 6 }}
                radius={4}
                backgroundColor={theme.colors.white}
                color={theme.colors.black}
                shrink={1}
                lineClamp={1}
                css={{ border: "1px solid #ddd" }}
            />
        ) : (
            <TextBox
                shrink={1}
                lineClamp={1}
                text={formatted}
                size="S"
                padding={{ topBottom: 3, leftRight: 6 }}
                radius={4}
                backgroundColor={theme.colors.white}
                color={theme.colors.black}
                css={{ border: "1px solid #ddd" }}
            />
        );

    return (
        <>
            <TextBox
                shrink={0}
                text={method.title}
                size="S"
                weight="400"
                lineClamp={1}
                color={props.color ?? theme.colors.default.text}
            />
            <Space />
            {row}
            <Space />
        </>
    );
};

type hdProps = {
    address: UserAddress;
    prepTime: number;
    selected?: Time;
    color: Color;
    weight?: FontWeight;
    padding?: Padding;
    radius?: number;
    backgroundColor?: ColorItem;
    className?: string;
    lineClamp?: number;
    shrink?: number;
};

const HomeDeliverySpan = (props: hdProps) => {
    const { address, prepTime, selected } = props;
    if (!selected) {
        return (
            <TextBox
                shrink={props.shrink ?? 1}
                lineClamp={props.lineClamp ?? 1}
                size="S"
                radius={props.radius}
                color={props.color}
                backgroundColor={props.backgroundColor}
                className={props.className}
                padding={props.padding}>
                <TextSpan
                    text={translation({
                        sv: `om ~${prepTime} min`,
                        en: `in ~${prepTime} min`,
                    })}
                    color={props.color}
                    weight={props.weight}
                />
                <Space />
                <ToAddress userAddress={address} color={props.color} />
            </TextBox>
        );
    } else if (selected?.start) {
        return (
            <TextBox
                shrink={props.shrink ?? 1}
                lineClamp={props.lineClamp ?? 1}
                size="S"
                radius={props.radius}
                color={props.color}
                backgroundColor={props.backgroundColor}
                className={props.className}
                padding={props.padding}>
                <TodayOrTomorrowSpan time={selected} color={props.color} />
                <Space />
                <TimeSpan time={selected} color={props.color} />
                <Space />
                <ToAddress userAddress={address} color={props.color} />
            </TextBox>
        );
    }
    return <></>;
};

const useTimeSpotFormatter = () => {
    const translate = useTranslate();

    const formatTimeSpot = React.useCallback(
        (params: {
            prepTime: number;
            selected?: Time;
            prefix?: Translatable;
        }) => {
            const { prepTime, selected, prefix } = params;

            const prefixSv = translate(prefix, "sv");
            const prefixEn = translate(prefix, "en");

            if (!selected) {
                return translation({
                    sv: `${prefixSv} om ~${prepTime} min`,
                    en: `${prefixEn} in ~${prepTime} min`,
                });
            }

            if (selected?.start) {
                const date = asDate(selected.start);

                const today = translation({
                    sv: `${prefixSv} idag kl ~${timeToStringRounded(
                        selected,
                        5,
                    )}`,
                    en: `${prefixEn} today ~${timeToStringRounded(
                        selected,
                        5,
                    )}`,
                });

                const tomorrow = translation({
                    sv: `${prefixSv} imorgon kl ~${timeToStringRounded(
                        selected,
                        5,
                    )}`,
                    en: `${prefixEn} tomorrow ~${timeToStringRounded(
                        selected,
                        5,
                    )}`,
                });
                if (isToday(date)) return today;
                if (isTomorrow(date)) return tomorrow;

                return translation({
                    sv: `${prefixSv} kl ${timeToString(selected)} den ${format(
                        date,
                        "d MMM",
                        {
                            locale: svLocale,
                        },
                    )}`,
                    en: `${prefixEn} at ${timeToString(selected)} ${format(
                        date,
                        " d MMM",
                        {
                            locale: enLocale,
                        },
                    )}`,
                });
            }

            return translation({ en: "", sv: "" });
        },
        [],
    );

    return formatTimeSpot;
};
type timeProps = {
    time: Time;
    color: ColorItem;
};
const TimeSpan = ({ time, color }: timeProps) => (
    <TextSpan
        weight="700"
        color={color}
        text={timeToStringRounded(time, 5, true)}
    />
);

type addressProps = {
    userAddress: UserAddress;
    color: Color;
};

const ToAddress = ({ userAddress, color }: addressProps) => {
    return (
        <>
            <TextSpan
                text={translation({ sv: "till", en: "to" })}
                color={color}
            />
            <Space />
            <TextSpan weight="700" text={userAddress.name} color={color} />
        </>
    );
};

const TodayOrTomorrowSpan = ({ time, color }: timeProps) => {
    const date = asDate(time.start);

    const todayOrTomorrow = isToday(date)
        ? translation({ sv: "idag", en: "today" })
        : isTomorrow(date)
          ? translation({ sv: "imorgon", en: "tomorrow" })
          : translation({
                sv: getNameOfDay(time.start, svLocale),
                en: getNameOfDay(time.start, enLocale),
            });

    return todayOrTomorrow ? (
        <TextSpan color={color} text={todayOrTomorrow} />
    ) : (
        <></>
    );
};

export { DeliveryMethodText, useTimeSpotFormatter };
