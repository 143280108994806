import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import { GetResponse, Provider } from "@towni/common";

const fetchProvider =
    (providerIdOrSlug: string | undefined): QueryFunction<Provider> =>
    async ({ signal }) => {
        if (!providerIdOrSlug) throw new Error("missing provider id or slug");
        const data = await apiFetchClient.get<GetResponse<Provider>>({
            route: `/providers/${encodeURIComponent(providerIdOrSlug)}`,
            customConfig: {
                signal,
            },
        });
        return data.items?.[0] || null;
    };

export { fetchProvider };
