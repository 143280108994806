import { PageLoad } from "@@/pages/page-load";
import { MILLISECONDS, units } from "@towni/common";
import * as React from "react";
import { AppSettings } from "./app-settings";
import { useEnvironmentRuntimeSettings } from "./app-settings/environment-settings-context";

type Settings = AppSettings & { readonly darkMode: boolean };

type Actions = {
    setDarkMode: (darkMode: boolean) => void;
};

const AppSettingsContext = React.createContext<Settings | undefined>(undefined);
const AppSettingsDispatchContext = React.createContext<Actions | undefined>(
    undefined,
);

/**
 * Returns the curren domain including protocol
 * E.g. https://towni.se, http://localhost:3000
 */
const currentHost = () =>
    `${window.location.protocol}//${window.location.host}`;

const AppSettingsProvider = (props: {
    readonly children?: React.ReactNode;
}) => {
    const runtimeSettings = useEnvironmentRuntimeSettings();
    const [state, setState] = React.useState<Settings>({
        env: runtimeSettings,
        apiEndpoint: "/api",
        host: currentHost(),
        isDebug: Boolean(
            runtimeSettings.DEBUG?.trim().toLowerCase() !== "false",
        ),
        isProdEnv: !!runtimeSettings.APP_ENV?.endsWith("_PROD"),
        isProduction: !!runtimeSettings.APP_ENV?.endsWith("_PROD"),
        isDemoEnv: !!runtimeSettings.APP_ENV?.endsWith("_DEMO"),
        isDevEnv:
            !!runtimeSettings.APP_ENV?.endsWith("_DEV") ||
            !!runtimeSettings.APP_ENV?.endsWith("_SANDBOX"),
        isSandboxEnv:
            !!runtimeSettings.APP_ENV?.endsWith("_DEV") ||
            !!runtimeSettings.APP_ENV?.endsWith("_SANDBOX"),
        mode: ["fullscreen", "standalone", "minimal-ui"].some(
            displayMode =>
                window.matchMedia("(display-mode: " + displayMode + ")")
                    .matches,
        )
            ? ("PWA" as const)
            : ("WEB" as const),
        darkMode:
            window.matchMedia("(prefers-color-scheme: dark)").matches ?? false,
        units, // Todo: LOAD FROM REMOTE LOCATIONS INSTEAD
    });

    const setDarkMode = React.useCallback(
        (darkMode: boolean) => {
            setState(current => ({ ...current, darkMode }));
        },
        [setState],
    );
    const actions = React.useMemo(() => {
        return { setDarkMode };
    }, [setDarkMode]);
    if (!state) return <PageLoad delayInMs={300 as MILLISECONDS} />;
    return (
        <AppSettingsContext.Provider value={state}>
            <AppSettingsDispatchContext.Provider value={actions}>
                {props.children}
            </AppSettingsDispatchContext.Provider>
        </AppSettingsContext.Provider>
    );
};

const useAppSettings = () => {
    const context = React.useContext(AppSettingsContext);
    if (context === undefined) {
        throw new Error(
            "useAppSettings must be used within a AppSettingsProvider",
        );
    }
    return context;
};
const useAppSettingsActions = () => {
    const context = React.useContext(AppSettingsDispatchContext);
    if (context === undefined) {
        throw new Error(
            "useAppSettingsActions must be used within a AppSettingsProvider",
        );
    }
    return context;
};

export {
    AppSettingsContext,
    AppSettingsProvider,
    currentHost,
    useAppSettings,
    useAppSettingsActions,
};
