import { paddingToCssValue } from "@@/shared/padding";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { ColorItem, Padding, RemSize } from "@towni/common";
import { Except } from "type-fest";

type Props = {
    /**
     * Unit in rem
     * @type {(RemSize | number)}
     */
    size?: RemSize | number;
    dots: 4 | 6;
    color?: ColorItem;
    padding?: Padding;
    dragListeners: DraggableSyntheticListeners;
    className?: string;
};

const Grip = (props: Props) => {
    const padding = paddingToCssValue(props.padding);

    const color =
        typeof props.color === "undefined"
            ? "#C4C4C4"
            : typeof props.color === "string"
            ? props.color
            : props.color.asString;

    const width = (props.size ?? 1) * 15;
    const height = (props.size ?? 1) * 20;

    return (
        <div
            {...props.dragListeners}
            className={props.className}
            css={{
                padding,
                cursor: props.dragListeners ? "pointer" : "default",
                opacity: props.dragListeners ? 1 : 0.4,
            }}>
            {props.dots !== 4 ? (
                <svg
                    width={width}
                    height={height}
                    style={{ display: "block" }}
                    viewBox="0 0 15 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" fill={color} />
                    <circle cx="2" cy="10" r="2" fill={color} />
                    <circle cx="2" cy="18" r="2" fill={color} />
                    <circle cx="13" cy="10" r="2" fill={color} />
                    <circle cx="13" cy="18" r="2" fill={color} />
                    <circle cx="13" cy="2" r="2" fill={color} />
                </svg>
            ) : (
                <svg
                    width={width}
                    height={width}
                    style={{ display: "block" }}
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" fill={color} />
                    <circle cx="2" cy="13" r="2" fill={color} />
                    <circle cx="13" cy="13" r="2" fill={color} />
                    <circle cx="13" cy="2" r="2" fill={color} />
                </svg>
            )}
        </div>
    );
};

const LargeGrip = (props: Except<Props, "dots">) => {
    return <Grip dots={6} {...props} />;
};
const SmallGrip = (props: Except<Props, "dots">) => {
    return <Grip dots={4} {...props} />;
};

export { Grip, LargeGrip, SmallGrip };
