import { useProduct } from "@@/products/state/queries/product/use-product";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ImageShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { css, useTheme } from "@emotion/react";
import { ProductId, RemSize } from "@towni/common";
import * as React from "react";
import { Link } from "react-router-dom";
import { kronocampingColors } from "../kronocamping-color-context";

// type Props = {};
const KronocampingSupSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();
    const supProductId = React.useMemo(
        () => getProductId("product_dDpV5eNFdOJvNmka0wYB" as ProductId),
        [],
    );
    const [sup, supQuery] = useProduct(supProductId);
    const supImageRef = sup?.meta.images[0];
    return (
        <Link to={`/products/${supProductId}`}>
            <FlexColumn
                fillParentWidth
                padding={{ leftRight: 20 }}
                crossAxis="flex-start"
                shrink={0}>
                {supQuery.isPending ? (
                    <ImageShimmer radius={16} maxHeight="335px" />
                ) : (
                    <SectionAreaCard
                        aspectRatio={isDesktop ? "3:2" : "1:1"}
                        radius={16}
                        background={{
                            fit: "COVER",
                            imageReference: supImageRef,
                            imageSize: {
                                imageWidth: 500,
                            },
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ all: 20 }}
                            background={{
                                linearGradient: darkeningGradient,
                            }}
                            mainAxis="space-between"
                            crossAxis="center">
                            <FlexColumn fillParentWidth shrink={0}>
                                <TextBox
                                    text={sup?.meta.title}
                                    weight="900"
                                    size={2 as RemSize}
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 4px
                                            rgba(0, 0, 0, 0.25);
                                    `}
                                />
                                <VerticalDivider XS />
                                <TextBox
                                    text={sup?.meta.subtitle}
                                    weight="500"
                                    size={1 as RemSize}
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 4px
                                            rgba(0, 0, 0, 0.25);
                                    `}
                                />
                                <VerticalDivider XS />
                                <TextBox
                                    text={sup?.meta.kicker}
                                    weight="900"
                                    size={0.625 as RemSize}
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 4px
                                            rgba(0, 0, 0, 0.25);
                                    `}
                                />
                            </FlexColumn>
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={"Se & boka"}
                                    colorSet={kronocampingColors.primary}
                                />
                            </FlexRow>
                        </FlexColumn>
                    </SectionAreaCard>
                )}
            </FlexColumn>
        </Link>
    );
};

export { KronocampingSupSection };
