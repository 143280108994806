import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import { Provider, UserId } from "@towni/common";
import { providerMainQueryKey } from "../main-query-key";
import { fetchProvidersForUser } from "./providers-for-user.fetcher";

const useProvidersForUser = (
    userId: UserId | undefined
): readonly [Provider[], UseQueryResult<Provider[]>] => {
    return useApiResources(
        createQueryKey(providerMainQueryKey, userId ? [userId] : []),
        fetchProvidersForUser(userId),
        { enabled: !!userId }
    );
};

export { useProvidersForUser };
