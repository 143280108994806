import { apiFetchClient } from "@@/shared/fetch-client";
import {
    AccessKeyId,
    asArray,
    emptyArrayOf,
    GetResponse,
    OrderId,
    ResourceReservation,
} from "@towni/common";

export { fetchReservationsForOrders };

const fetchReservationsForOrders =
    (orderIds: OrderId[] | OrderId | undefined, accessKeyId?: AccessKeyId) =>
    async () => {
        if (typeof orderIds === "undefined")
            return emptyArrayOf<ResourceReservation>();
        const ids = asArray(orderIds);
        if (!ids?.length) return emptyArrayOf<ResourceReservation>();
        const data = await apiFetchClient.get<GetResponse<ResourceReservation>>(
            {
                route: `/reservations/for-orders/${encodeURIComponent(
                    ids.join(",")
                )}${accessKeyId ? `?ak=${accessKeyId}` : ""}`,
            }
        );
        return data.items;
    };
