import { PillButton } from "@@/shared/buttons/pill-button";
import { TextBox } from "@@/shared/text/text-box";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    ColorSet,
    ColorValue,
    colorValueFactory,
    HexColor,
    isColorSet,
    isColorValue,
    isLightColor,
    RemSize,
    Translatable,
    transparentColor,
} from "@towni/common";
import { useMemo } from "react";

const StoreFrontCardPill = (
    props: {
        readonly text: Translatable;
    } & (
        | {
              readonly accentColor?: HexColor | string | ColorValue;
              readonly colorSet?: never;
          }
        | {
              readonly accentColor?: never;
              /**
               * defaults to primary
               * @type {ColorSet}
               */
              readonly colorSet?: ColorSet;
          }
    ),
) => {
    const theme = useTheme();
    const translate = useTranslate();
    const colorSet = props.colorSet ?? theme.colors.primary;
    const accentColor = props.accentColor ?? theme.colors.primary.main.value;

    const colors = useMemo(() => {
        if (isColorSet(props.colorSet)) {
            return {
                background: colorSet.background,
                text: colorSet.text,
                backgroundGradientAlt: colorSet.backgroundGradientAlt,
            };
        }
        if (isColorValue(accentColor)) {
            const isLight = isLightColor(accentColor);
            return {
                background: accentColor,
                text: isLight ? theme.colors.black : theme.colors.white,
            };
        }
        const colorValue = colorValueFactory(accentColor);
        return {
            background: colorValue,
            text: isLightColor(colorValue)
                ? theme.colors.black
                : theme.colors.white,
        };
    }, [accentColor, colorSet, theme]);

    const hasText = !!translate(props.text)?.trim();

    return (
        <PillButton
            onClick={undefined}
            backgroundColor={colors.background}
            borderColor={transparentColor}
            backgroundColorGradientAlt={colors.backgroundGradientAlt}
            removeBorder={!!colors.backgroundGradientAlt}
            opacity={
                // Don't render empty pill
                !hasText ? 0 : 1
            }
            padding={{
                leftRight: 12,
                topBottom: 9,
            }}>
            <TextBox
                text={props.text}
                weight="400"
                size={0.875 as RemSize}
                color={colors.text}
            />
        </PillButton>
    );
};

export { StoreFrontCardPill };
