import { ModalId } from "@@/modals/context/modal-id";
import { useModalActions } from "@@/modals/use-modal-actions";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { translation } from "@towni/common";

type Props = {
    modalId: ModalId;
};

const TableBookingCancelSuccess = (props: Props) => {
    const theme = useTheme();
    const { modalId } = props;
    const { hide } = useModalActions(modalId);

    return (
        <FlexColumn padding={{ all: 20 }} fillParentWidth crossAxis="center">
            <VerticalDivider M />
            <TextBox
                text={translation({
                    sv: "Din avbokning är klar!",
                    en: "Your table reservation has been canceled!",
                })}
                color={theme.colors.black.light40}
            />
            <VerticalDivider L />
            <TextBox
                text={translation({
                    sv: "Ibland blir det inte som man tänkt sig.",
                    en: "Sometimes things doesn't work out as planned.",
                })}
                weight="900"
            />
            <VerticalDivider XXS />
            <TextBox
                text={translation({
                    sv: "Hoppas att vi ses snart igen ☀️",
                    en: "Hope to see you some other time ☀️",
                })}
                weight="900"
            />
            <VerticalDivider XXL />
            <ButtonWhite onClick={() => hide()}>
                <TextBox
                    padding={{ leftRight: 30 }}
                    text={translation({
                        sv: "Klar",
                        en: "Ready",
                    })}
                />
            </ButtonWhite>
        </FlexColumn>
    );
};

export { TableBookingCancelSuccess };
