import { useTheme } from "@emotion/react";
import { useMemo } from "react";

const useFormColors = (params: { hasErrors?: boolean }) => {
    const { hasErrors } = params;
    const theme = useTheme();
    // Colors
    const borderColor = hasErrors
        ? theme.colors.danger.border.asString
        : theme.colors.textInput.border.asString;
    const backgroundColor = hasErrors
        ? theme.colors.danger.light.asString
        : theme.colors.textInput.background.asString;
    const textColor = hasErrors
        ? theme.colors.danger.asString
        : theme.colors.textInput.text.asString;
    const spinnerColor = hasErrors
        ? theme.colors.danger.asString
        : theme.colors.textInput.placeholder.asString;

    return useMemo(
        () => ({ borderColor, backgroundColor, textColor, spinnerColor }),
        [backgroundColor, borderColor, spinnerColor, textColor],
    );
};
export { useFormColors };
