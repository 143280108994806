import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { Paragraph, paragraphLinkFactory, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { translation } from "@towni/common";

const ShopLocallyHereSection = () => {
    const theme = useTheme();
    return (
        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
            <TextBox
                text={`Upptäck lokalt!`}
                size={1.5}
                weight={"800"}
                padding={{ right: 50 }}
            />
            <VerticalDivider S />
            <Paragraph
                css={{
                    color: theme.colors.black.light40.asString,
                }}
                content={[
                    translation({
                        sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport på ",
                        en: "Explore adventures, food and experiences and get around with smart transport find everything here on ",
                    }),
                    paragraphLinkFactory({
                        text: "towni.se",
                        href: "https://towni.se",
                    }),
                ]}
            />
        </FlexColumn>
    );
};

export { ShopLocallyHereSection };
