import { useCart } from "@@/carts/multi-carts/cart.context";
import { useResourceReservationRequestInfoForProduct } from "@@/resource-reservations/state/resource-reservation-info/use-resource-reservation-info-for-product";
import { useBookableResources } from "@@/resources/resources/use-resources";
import { browserLogger } from "@@/settings";
import {
    consolidateResourceRequestsOptionPerResourceReference,
    emptyArrayOf,
    isBookable,
    isQuantityPickerBookableOptionValue,
    isTimeRange,
    missingOptionValueFactory,
    resourceReservationRequestsFactory,
    timeRangeByDurationFactory,
    type BookableOptionValue,
    type BookableSession,
    type BookableSettings,
    type MissingOptionValue,
    type Product,
    type ResourceId,
    type ResourceReservationRequest,
    type Sku,
    type TimeRange,
} from "@towni/common";
import { useCallback } from "react";
import { useBookingContext } from "../booking-context";

const useCreateReservationFromBookingContext = () => {
    const state = useBookingContext(context => ({
        product: context.product,
        session: context.session,
        timeRange: context.timeRange,
        sku: context.sku,
        optionValues: context.optionValues,
        requestedResources: context.requestedResources,
        bookable: context.bookable,

        resourcePickerEnabled: context.resourcePickerEnabled,
        isOnPremiseBooking: context.isOnPremiseBooking,
        allowBookingIsSplit: context.allowBookingIsSplit,
        ignoreOrder: context.ignoreOrder,
    }));
    const cart = useCart();
    const [providerResources, { isPending: isProviderResourcesLoading }] =
        useBookableResources(state.product._id);
    const [
        resourceReservationInfo,
        { isPending: isResourceReservationRequestInfoLoading },
    ] = useResourceReservationRequestInfoForProduct(
        state.product._id,
        state.ignoreOrder,
    );

    const createReservationFromBookingContext = useCallback(
        (sessionInput: BookableSession | undefined) => {
            const session = sessionInput ?? state.session;
            if (!session) throw new Error("session not set");

            const timeRange =
                state.timeRange ??
                timeRangeByDurationFactory(session.start, {
                    value: session.durationMinMax.min,
                    granularity: session.durationGranularity,
                });
            const bookableSku = state.sku;
            if (!isTimeRange(timeRange)) throw new Error("time range not set");
            if (!isBookable(bookableSku.acquire))
                throw new Error("is not a bookable");

            const resourceReservationRequest =
                resourceReservationRequestsFactory({
                    bookableSku,
                    globalResources: providerResources,
                    previousRequests: cart.reservationRequests,
                    session,
                    optionValues: state.optionValues,
                    sessionTimeRange: timeRange,
                    resourceReservationInfo,
                    requestedResources:
                        state.requestedResources.length > 0 ||
                        state.resourcePickerEnabled
                            ? state.requestedResources
                            : undefined,
                    settings: state.bookable.settings,
                    allowSplitBooking: state.allowBookingIsSplit,
                    cartId: cart._id,
                    overrideSessionSettings: state.isOnPremiseBooking,
                });

            const reservedOptionalValues =
                consolidateResourceRequestsOptionPerResourceReference(
                    resourceReservationRequest,
                );

            const missing = state.optionValues.flatMap(optionValue => {
                if (isQuantityPickerBookableOptionValue(optionValue)) {
                    const quantityPickerOptionValue =
                        reservedOptionalValues.find(
                            f => f._id === optionValue._id,
                        );

                    if (!quantityPickerOptionValue) {
                        return missingOptionValueFactory({
                            optionValue: optionValue,
                            missingQuantity: optionValue.quantity.value,
                        });
                    }

                    if (
                        quantityPickerOptionValue.quantity.value <
                        optionValue.quantity.value
                    ) {
                        return missingOptionValueFactory({
                            optionValue: optionValue,
                            missingQuantity:
                                optionValue.quantity.value -
                                quantityPickerOptionValue.quantity.value,
                        });
                    }

                    return emptyArrayOf<MissingOptionValue>();
                }
                return emptyArrayOf<MissingOptionValue>();
            });

            if (missing.find(f => f.missingQuantity > 0))
                return emptyArrayOf<ResourceReservationRequest>();
            return resourceReservationRequest;
        },
        [
            state,
            cart._id,
            providerResources,
            cart.reservationRequests,
            resourceReservationInfo,
        ],
    );

    return [
        createReservationFromBookingContext,
        {
            isLoading:
                isProviderResourcesLoading ||
                isResourceReservationRequestInfoLoading,
        },
    ] as const;
};

//* This hook is used to check if the reservation can be made based on the booking context or if somethings missing
const useCheckReservationFromBookingContext = (
    product: Product,
    checkRequested: boolean,
) => {
    const cart = useCart();
    const state = useBookingContext(state => ({
        ignoreOrder: state.ignoreOrder,
        isOnPremiseBooking: state.isOnPremiseBooking,
        timeRange: state.timeRange,
    }));
    const [providerResources, _] = useBookableResources(product._id);
    const [resourceReservationInfo, _resourceReservationInfoQuery] =
        useResourceReservationRequestInfoForProduct(
            product._id,
            state.ignoreOrder,
            state.timeRange,
        );

    const checkReservationFromBookingContext = useCallback(
        (params: {
            session: BookableSession;
            timeRange: TimeRange;
            sku: Sku;
            optionValues: BookableOptionValue[];
            requestedResources: ResourceId[];
            settings: BookableSettings;
        }) => {
            const {
                session,
                timeRange,
                sku: bookableSku,
                optionValues,
                requestedResources,
                settings,
            } = params;
            if (!session) throw new Error("session not set");
            if (!isTimeRange(timeRange)) throw new Error("time range not set");
            if (!isBookable(bookableSku.acquire))
                throw new Error("is not a bookable");

            const resourceReservationRequest =
                resourceReservationRequestsFactory({
                    bookableSku,
                    globalResources: providerResources,
                    previousRequests: cart.reservationRequests,
                    session,
                    optionValues,
                    sessionTimeRange: timeRange,
                    resourceReservationInfo: resourceReservationInfo,
                    requestedResources: checkRequested
                        ? requestedResources
                        : undefined,
                    settings,
                    cartId: cart._id,
                    overrideSessionSettings: state.isOnPremiseBooking,
                });
            browserLogger.debug("Checking reservation from booking context", {
                old: cart.reservationRequests,
                optionValues,
                checkRequested,
                requestedResources,
                resourceReservationRequest,
                settings,
                isOnPremiseBooking: state.isOnPremiseBooking,
            });

            const reservedOptionals =
                consolidateResourceRequestsOptionPerResourceReference(
                    resourceReservationRequest,
                );
            const missing = optionValues.flatMap(optionValue => {
                if (isQuantityPickerBookableOptionValue(optionValue)) {
                    const reservedOptional = reservedOptionals.find(
                        f => f._id === optionValue._id,
                    );

                    if (!reservedOptional) {
                        return missingOptionValueFactory({
                            optionValue: optionValue,
                            missingQuantity: optionValue.quantity.value,
                        });
                    }

                    if (
                        reservedOptional.quantity.value <
                        optionValue.quantity.value
                    ) {
                        return missingOptionValueFactory({
                            optionValue: optionValue,
                            missingQuantity:
                                optionValue.quantity.value -
                                reservedOptional.quantity.value,
                        });
                    }
                    return emptyArrayOf<MissingOptionValue>();
                }
                return emptyArrayOf<MissingOptionValue>();
            });
            return missing.filter(m => m.missingQuantity > 0);
        },
        [
            providerResources,
            cart.reservationRequests,
            resourceReservationInfo,
            cart._id,
            checkRequested,
            state.isOnPremiseBooking,
        ],
    );

    return checkReservationFromBookingContext;
};

export {
    useCheckReservationFromBookingContext,
    useCreateReservationFromBookingContext,
};
