import { HorizontalDivider } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import { isGiftCard, Order, OrderItemGiftCard } from "@towni/common";
import { useMemo } from "react";
import { DigitalGiftcardReceipt_Ticket } from "./digital-giftcard-receipt-ticket";

type Props = {
    orders: Order[];
};

const DigitalGiftCardReceipt_Tickets = (props: Props) => {
    const { orders } = props;
    const items = useMemo(
        () =>
            orders.flatMap(order =>
                order.orderItems
                    .filter(isGiftCard)
                    .map(
                        orderItem =>
                            [order, orderItem] as [Order, OrderItemGiftCard],
                    ),
            ),
        [orders],
    );

    return (
        <ForEach
            divider={<HorizontalDivider />}
            itemOf={items}
            getKey={([, oi]) => oi._id}>
            {([order, orderItem]) => {
                return (
                    <DigitalGiftcardReceipt_Ticket
                        key={orderItem._id}
                        order={order}
                        orderItem={orderItem}
                    />
                );
            }}
        </ForEach>
    );
};

export { DigitalGiftCardReceipt_Tickets };
