import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { css, useTheme } from "@emotion/react";
import { ProviderIdOrSlug } from "@towni/common";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ProviderLogoLinkCircle } from "./providers/provider-logo-link-circle";

const HenrysShrimpSalladSection: FC = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    const [henrys] = useProvider("henrys" as ProviderIdOrSlug);
    return (
        <SectionArea
            sectionAreaId="henrys-shrimp-sallad"
            padding={{ leftRight: 20, top: 10, bottom: 30 }}
            background={{
                color: theme.colors.default.background,
            }}>
            <Link to="/products/product_PL9Zp6YpoR0kf9u2_5vC">
                <SectionAreaCard
                    aspectRatio={isDesktop ? "3:2" : "1:1"}
                    background={{
                        fit: "COVER",
                        imageUrl:
                            "https://towni.se/public/images/imgix/henrys/images/henrys_image-reference_HCqLz_F6ZrUPBrn5z9ec.jpeg?dpr=2&q=60&fm=jpg&w=500&fit=crop",
                    }}>
                    <FlexColumn
                        shrink={0}
                        grow={1}
                        padding={{ all: 20 }}
                        background={{ linearGradient: darkeningGradient }}
                        mainAxis="space-between"
                        crossAxis="flex-start">
                        <FlexRow fillParentWidth mainAxis="flex-end">
                            <StoreFrontCardPill
                                text={"Nyhet"}
                                colorSet={theme.colors.secondary}
                            />
                        </FlexRow>
                        <FlexRow
                            mainAxis="flex-start"
                            crossAxis="flex-end"
                            shrink={0}>
                            <ProviderLogoLinkCircle
                                provider={henrys}
                                hideTitle
                                size="3XS"
                            />
                            <HorizontalDivider S />
                            <FlexColumn shrink={1} crossAxis="flex-start">
                                <TextBox
                                    text="Räksallad!"
                                    weight="900"
                                    size="XXL"
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 15px
                                            rgba(0, 0, 0, 0.8);
                                    `}
                                />
                                <VerticalDivider XXS />
                                <TextBox
                                    text="Härlig måltid som fungerar morgon, middag och kväll."
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 15px
                                            rgba(0, 0, 0, 0.8);
                                    `}
                                />
                            </FlexColumn>
                        </FlexRow>
                    </FlexColumn>
                </SectionAreaCard>
            </Link>
        </SectionArea>
    );
};

export { HenrysShrimpSalladSection };
