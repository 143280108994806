import { AccommodationCart_SubType_QuantityPicker } from "@@/carts/accommodation-checkout/accommodation-cart.sub-type-quantity-picker.row";
import { useAccommodationCartUniqueActiveSubTypes } from "@@/carts/accommodation-checkout/use-accommodation-cart-active-sub-types";
import { useAccommodationCartResourcesWithSubType } from "@@/carts/accommodation-checkout/use-accommodation-sub-type-picked";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Label } from "@@/shared/label";
import { Paragraph, TextBox } from "@@/shared/text";
import { useMap } from "@@/shared/use-map";
import { useTheme } from "@emotion/react";
import {
    AccommodationTypeAndSubTypeId,
    isOrderItemAccommodation_V2,
    setWithinRange,
    sum,
    translation,
} from "@towni/common";
import { useCallback, useEffect } from "react";
import { useCartContext } from "../multi-carts/cart.context";

const AccommodationCart_SubTypePicker_Multi = () => {
    const theme = useTheme();
    const { cart, setAccommodationSubTypes } = useCartContext(context => ({
        cart: context.cart,
        setAccommodationSubTypes: context.setAccommodationSubTypes,
    }));

    const accommodationOrderItems = cart.orderItems.filter(
        isOrderItemAccommodation_V2,
    );
    const resourcesWithPickableSubType =
        useAccommodationCartResourcesWithSubType();
    const subTypes = useAccommodationCartUniqueActiveSubTypes();
    const totalQuantityToSelect = resourcesWithPickableSubType.length;
    const selectedQuantities = useMap<AccommodationTypeAndSubTypeId, number>(
        cart.accommodationSubTypeCount
            ? new Map(
                  Array.from(Object.entries(cart.accommodationSubTypeCount)),
              )
            : new Map(),
    );
    const selectedQuantity = sum(Array.from(selectedQuantities.values()));
    const totalQuantityIsSelected = selectedQuantity === totalQuantityToSelect;
    const hasPicked = totalQuantityIsSelected;

    const selectQuantity = useCallback(
        (subTypeData: (typeof subTypes)[0], quantity: number) => () => {
            // console.log("SELECT QUANTITY1", {
            //     subTypeData,
            //     quantity,
            // });
            const [_, __, accommodationTypeAndSubTypeId] = subTypeData;

            // Update the selectedQuantities map
            selectedQuantities.set(accommodationTypeAndSubTypeId, quantity);

            // Check if the total quantity has been selected
            const selectedQuantity = sum(
                Array.from(selectedQuantities.values()),
            );
            const totalQuantitySelected =
                selectedQuantity === totalQuantityToSelect;

            // console.log("SELECT QUANTITY2", {
            //     selectedQuantity,
            //     totalQuantityToSelect,
            //     totalQuantitySelected,
            //     selectedQuantities,
            //     toCart: Object.fromEntries(selectedQuantities.entries()),
            // });

            if (!totalQuantitySelected)
                setAccommodationSubTypes(
                    {} as Record<AccommodationTypeAndSubTypeId, number>,
                );
            else
                setAccommodationSubTypes(
                    Object.fromEntries(selectedQuantities.entries()) as Record<
                        AccommodationTypeAndSubTypeId,
                        number
                    >,
                );
        },
        [selectedQuantities, setAccommodationSubTypes, totalQuantityToSelect],
    );

    // If there is only one sub type, select it
    // and set count to the number of resources needing it
    useEffect(
        function autoSelectSubTypeWhenOnlyOneChoice() {
            if (subTypes.length === 1) {
                selectQuantity(subTypes[0], totalQuantityToSelect)();
            }
        },
        [subTypes, selectQuantity, totalQuantityToSelect],
    );
    if (
        !accommodationOrderItems.length ||
        resourcesWithPickableSubType.length === 0
    )
        return <HorizontalSectionDivider key={"bc2_start"} />;

    if (subTypes.length <= 1) return null;
    return (
        <FlexColumn
            fillParent
            css={{
                label: "pick_accommodation_subtype",
            }}>
            <HorizontalLine />
            <FlexColumn
                css={{
                    borderLeft: `6px solid ${hasPicked ? theme.colors.primary.asString : theme.colors.danger.asString}`,
                    label: "pick_accommodation_subtype",
                }}>
                <VerticalDivider S />

                <FlexRow
                    mainAxis="space-between"
                    crossAxis="center"
                    padding={{ leftRight: 10 }}>
                    <TextBox
                        color={
                            hasPicked ? undefined : theme.colors.danger.asString
                        }
                        text={translation({
                            sv: "Välj typ",
                            en: "Choose type",
                        })}
                        weight="700"
                        size={"M"}
                    />
                    <Label
                        text={translation({
                            sv: "obligatorisk",
                            en: "mandatory",
                        })}
                        colorTheme={hasPicked ? "PRIMARY" : "RED"}
                    />
                </FlexRow>
                <VerticalDivider XXS />
                <Paragraph
                    css={{
                        paddingLeft: 10,
                        color: hasPicked
                            ? undefined
                            : theme.colors.danger.asString,
                        opacity: 0.5,
                    }}
                    content={
                        selectedQuantity > 0
                            ? [
                                  selectedQuantity.toLocaleString(),
                                  translation({
                                      sv: " av ",
                                      en: " of ",
                                  }),
                                  totalQuantityToSelect.toLocaleString(),
                                  translation({
                                      sv: " valda",
                                      en: " selected",
                                  }),
                              ]
                            : [
                                  translation({
                                      sv: "Välj ",
                                      en: "Choose ",
                                  }),
                                  totalQuantityToSelect.toLocaleString(),
                                  translation({
                                      sv: " st",
                                      en: " items",
                                  }),
                              ]
                    }
                />
                <VerticalDivider S />
                <FlexColumn>
                    <ForEach
                        getKey={([subType]) => subType._id}
                        beginEndAndDivideWith={
                            <FlexRow
                                fillParentWidth
                                css={{
                                    height: "1rem",
                                    backgroundColor: theme.isLightTheme
                                        ? theme.colors.primary.light.asString
                                        : theme.colors.primary.dark.asString,
                                }}
                            />
                        }
                        itemOf={subTypes}>
                        {subTypeData => {
                            const [subType, __, accommodationTypeAndSubTypeId] =
                                subTypeData;

                            return (
                                <AccommodationCart_SubType_QuantityPicker
                                    option={subType}
                                    value={
                                        selectedQuantities.get(
                                            accommodationTypeAndSubTypeId,
                                        ) || 0
                                    }
                                    maxValue={
                                        totalQuantityToSelect -
                                        selectedQuantity +
                                        (selectedQuantities.get(
                                            accommodationTypeAndSubTypeId,
                                        ) ?? 0)
                                    }
                                    onIncrease={selectQuantity(
                                        subTypeData,
                                        setWithinRange(
                                            (selectedQuantities.get(
                                                accommodationTypeAndSubTypeId,
                                            ) ?? 0) + 1,
                                            { min: 0 },
                                        ),
                                    )}
                                    onDecrease={selectQuantity(
                                        subTypeData,
                                        setWithinRange(
                                            (selectedQuantities.get(
                                                accommodationTypeAndSubTypeId,
                                            ) ?? 0) - 1,
                                            { min: 0 },
                                        ),
                                    )}
                                />
                            );
                        }}
                    </ForEach>
                </FlexColumn>
            </FlexColumn>
            <HorizontalLine />
            <VerticalDivider S />
        </FlexColumn>
    );
};

export { AccommodationCart_SubTypePicker_Multi };
