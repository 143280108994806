import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import { FC } from "react";

const LackoFamilyAdventure: FC = () => {
    return (
        <SectionArea
            title={"Familjeäventyr med twist"}
            subtitle={
                "Blanda bad och klippor med historia och äventyr på Läckö Slott"
            }
            sectionAreaId="lacko-sea-2-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                <OneSquareSection
                    productId={"product__e6jyvptcV_GDwVdPlKJc" as ProductId}
                    actionText={translation({
                        sv: "Läs mer",
                    })}
                    contentTop
                />
                <OneSquareSection
                    productId={"product_ZzX_V5-oYGnIDZs93xt_" as ProductId}
                    actionText={translation({
                        sv: "Boka nu",
                    })}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { LackoFamilyAdventure };
