import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import { FC } from "react";

const ElectricBikesFourSection: FC = () => {
    return (
        <SectionArea
            title={"Elcykla – längre och härligare"}
            subtitle={
                "Upptäck den fantastiska landsbygden runt Lidköping. Välj mellan att hyra din elcykel på Kronocamping eller Stadshotellet i Lidköping."
            }
            sectionAreaId="electrical-4-section"
            //crossAxis="center"
            padding={{ top: 0, leftRight: 40 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                <OneSquareSection
                    padding={{ all: 0 }}
                    productId={"product__z5OERGnTXSqytv7NNd1M" as ProductId}
                    actionText={translation({
                        sv: "Boka nu",
                    })}
                    aspectRatio="1:1"
                />
                <OneSquareSection
                    padding={{ all: 0 }}
                    productId={"product__l8vWZxpjVouA6KBIAuTI" as ProductId}
                    actionText={translation({
                        sv: "Se & Boka",
                    })}
                    aspectRatio="1:1"
                />
                <OneSquareSection
                    padding={{ all: 0 }}
                    productId={"product__PEOwU3YM3ltIzFIeZKso" as ProductId}
                    actionText={translation({
                        sv: "Se & Boka",
                    })}
                    aspectRatio="1:1"
                />
                <OneSquareSection
                    padding={{ all: 0 }}
                    productId={"product_R_b0awdjkd9ZNb2itw6l" as ProductId}
                    actionText={translation({
                        sv: "Boka nu",
                    })}
                    aspectRatio="1:1"
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { ElectricBikesFourSection };
