import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareLinkSection } from "@@/store-fronts/shared/one-square-link-section";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { useTheme } from "@emotion/react";
import { ProductId, translation } from "@towni/common";
import * as React from "react";

const BauerBreakfastSection = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const breakfast = React.useMemo(
        () => getProductId("product__CzVjo_07_Nw1j2eeL5a4" as ProductId),
        [],
    );

    return (
        <SectionArea
            title={translation({
                sv: "Boka frukost och mat",
                en: "Breakfast and food",
            })}
            subtitle={translation({
                sv: "",
                en: "",
            })}
            sectionAreaId="bauer-vinter-4-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid
                size={"M"}
                gap={28}
                padding={{ leftRight: 60, bottom: 10 }}>
                <OneSquareSection
                    padding={{}}
                    key={breakfast}
                    productId={breakfast}
                    aspectRatio={"1:1"}
                    contentTop={true}
                    actionText={translation({
                        sv: "Boka",
                        en: "Book",
                    })}
                    hideKicker
                    colorSet={theme.colors.primary}
                />
                <OneSquareLinkSection
                    padding={{ top: 20 }}
                    key={breakfast}
                    contentTop={true}
                    aspectRatio={"1:1"}
                    actionText={translation({
                        sv: "Boka",
                        en: "Book",
                    })}
                    imageUrl={
                        "https://we-are-alfred.imgix.net/bauergarden-i-bunn/images/bauergarden-i-bunn_image_reference__OTKG7CcqFFWlVmg9Fz1F.jpeg?dpr=2&q=60&fm=webp&w=500&fit=crop"
                    }
                    link={"/products/product__YVHKUhpawjIR1BepcTy8"}
                    title={translation({
                        sv: "Avhämtning Bauergårdens Schnitzel",
                        en: "Takeaway Bauergården's Schnitzel",
                    })}
                    subTitle={"Serveras mellan 12:00-14:30."}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { BauerBreakfastSection };
