import { useFetchClient } from "@@/shared/fetch-client";
import { usePrevious } from "@@/shared/use-previous";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import {
    AccommodationPurpose,
    Cart,
    CheckoutCommand,
    checkoutCommandFactory,
    CheckoutCommandResponse,
    ContactInfo,
    generateId,
    OrderGroupId,
    Price,
    sumPrices,
    Translatable,
    zeroPrice,
} from "@towni/common";
import * as React from "react";
import { useContext, useState } from "react";
import { createStore, StoreApi } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { useCart } from "./multi-carts/cart.context";

type CheckoutStatusType = State["status"]["type"];
type ContextState = State & Actions;
type State = {
    readonly _id: string;
    readonly _type: "CHECKOUT_STATE";
    readonly contactInfo: ContactInfo | null;
    readonly cart: Cart;
    readonly shippingCost: Price;
    readonly totalCheckoutPrice: Price;
    readonly leaveMyStuffOutside: boolean;
    readonly canUsePaymentRequestButton: "LOADING" | boolean;
    readonly madeDeliveryChoice: boolean;
    readonly accommodationPurpose?: AccommodationPurpose;
    readonly onPremise: boolean;
    readonly status:
        | {
              _type: "CHECKOUT_STATUS";
              type: "FULFILLED";
              orderGroupId: OrderGroupId;
          }
        | {
              _type: "CHECKOUT_STATUS";
              type: "DRAFT";
          }
        | {
              _type: "CHECKOUT_STATUS";
              type: "INITIATED";
              orderGroupId: OrderGroupId;
          }
        | {
              _type: "CHECKOUT_STATUS";
              type: "REJECTED";
              errorMessage: Translatable;
          };
};
// eslint-disable-next-line @typescript-eslint/ban-types
type Actions = {
    readonly setContactInfo: (contactInfo: ContactInfo | null) => void;
    readonly setAccommodationPurpose: (
        accommodationPurpose: AccommodationPurpose | undefined,
    ) => void;
    readonly setLeaveMyStuffOutside: (leaveMyStuffOutside: boolean) => void;
    readonly setMadeDeliveryChoice: (madeDeliveryChoice: boolean) => void;
    readonly setCanUsePaymentRequestButton: (
        canUsePaymentRequestButton: boolean | "LOADING",
    ) => void;
    readonly initiateCheckout: () => Promise<CheckoutCommandResponse>;
    readonly setCheckoutFulfilled: (orderGroupId: OrderGroupId) => void;
    readonly setCheckoutRejected: (errorMessage: Translatable) => void;
};

const CheckoutContext = React.createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    readonly onPremise?: boolean;
    readonly children?: React.ReactNode;
    readonly initialContextInfo?: ContactInfo;
};

const CheckoutContextProvider = (props: Props) => {
    const cart = useCart();
    const fetchClient = useFetchClient();
    const [store] = useState<StoreApi<ContextState>>(() =>
        createStore<ContextState>()((set, get) => {
            const shippingCost = cart.delivery?.price ?? zeroPrice;
            const totalCheckoutPrice = sumPrices([
                cart.totalPrice,
                shippingCost,
            ]);

            const setContactInfo = (contactInfo: ContactInfo | null) => {
                set({
                    contactInfo,
                });
            };
            const setAccommodationPurpose = (
                accommodationPurpose: AccommodationPurpose | undefined,
            ) => {
                set({ accommodationPurpose });
            };
            const setLeaveMyStuffOutside = (leaveMyStuffOutside: boolean) => {
                set({ leaveMyStuffOutside });
            };
            const setMadeDeliveryChoice = (madeDeliveryChoice: boolean) => {
                set({ madeDeliveryChoice });
            };
            const setCanUsePaymentRequestButton = (
                canUsePaymentRequestButton: boolean | "LOADING",
            ) => {
                set({ canUsePaymentRequestButton });
            };
            const setCheckoutRejected = (errorMessage: Translatable) => {
                set({
                    status: {
                        _type: "CHECKOUT_STATUS",
                        type: "REJECTED",
                        errorMessage,
                    },
                });
            };
            const setCheckoutFulfilled = (orderGroupId: OrderGroupId) => {
                set({
                    status: {
                        _type: "CHECKOUT_STATUS",
                        type: "FULFILLED",
                        orderGroupId,
                    },
                    contactInfo: null,
                });
            };
            const initiateCheckout =
                async (): Promise<CheckoutCommandResponse> => {
                    const command: CheckoutCommand = checkoutCommandFactory({
                        cart: get().cart,
                        checkoutId: get().cart._id,
                        contactInfo: get().contactInfo,
                        onPremiseBooking: get().onPremise,
                        optionValues: get().cart.optionValues,
                        accommodationPurpose: get().accommodationPurpose,
                        reservations: get().cart.reservationRequests,
                        leaveMyStuffOutside: get().leaveMyStuffOutside,
                        forceReservation: get().cart.reservationRequests.some(
                            s => s.pickedBy === "USER",
                        ),
                    });
                    const commandResponse = await fetchClient.post<
                        CheckoutCommand,
                        CheckoutCommandResponse
                    >({
                        route: "/commands/init-checkout",
                        body: command,
                    });

                    set({
                        status: {
                            _type: "CHECKOUT_STATUS",
                            type: "INITIATED",
                            orderGroupId: commandResponse.orderGroupId,
                        },
                    });

                    return commandResponse;
                };

            const context: ContextState = {
                _id: generateId({ prefix: "checkout__" }),
                _type: "CHECKOUT_STATE",
                contactInfo: props.initialContextInfo ?? null,
                cart,
                leaveMyStuffOutside: false,
                madeDeliveryChoice: false,
                shippingCost,
                totalCheckoutPrice,
                canUsePaymentRequestButton: "LOADING",
                onPremise: props.onPremise ?? false,
                status: {
                    _type: "CHECKOUT_STATUS",
                    type: "DRAFT",
                },
                setContactInfo,
                setAccommodationPurpose,
                setLeaveMyStuffOutside,
                setMadeDeliveryChoice,
                setCanUsePaymentRequestButton,
                setCheckoutFulfilled,
                initiateCheckout,
                setCheckoutRejected,
            };

            return context;
        }),
    );

    const previousCartHash = usePrevious(cart.hash);
    useUpdateEffect(() => {
        if (previousCartHash === cart.hash) return;
        const shippingCost = cart.delivery?.price ?? zeroPrice;
        const totalCheckoutPrice = sumPrices([cart.totalPrice, shippingCost]);
        store.setState({
            cart,
            shippingCost,
            totalCheckoutPrice,
            status: {
                _type: "CHECKOUT_STATUS",
                type: "DRAFT",
            },
        });
    }, [cart]);

    return (
        <CheckoutContext.Provider value={store}>
            {props.children}
        </CheckoutContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useCheckoutContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(CheckoutContext);
    if (store === undefined) {
        throw new Error(
            "useCheckoutContext must be used within a CheckoutContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { CheckoutContextProvider, useCheckoutContext };
export type { CheckoutStatusType };
