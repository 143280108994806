import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { browserLogger } from "@@/settings";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormComponents } from "@@/shared/form/form-components";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormState } from "@@/shared/form/form.zustand-store";
import { Icon } from "@@/shared/icons/icon";
import {
    Paragraph,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { faEye } from "@fortawesome/pro-solid-svg-icons";

import {
    Translatable,
    emptyArrayOf,
    repeatCount,
    sortNumerically,
    toTranslatableString,
    translation,
    unique,
} from "@towni/common";
import { Draft } from "immer";
import { useCallback, useMemo } from "react";
import { ZodSchema, z } from "zod";

type Value = number[] | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly fieldSchema?: ZodSchema;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
};
// type PickByType<T, Value> = {
//     [P in keyof T as T[P] extends Value ? string : never]: T[P];
// };

const Form2CsvNumberInput = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const FormComponents = useFormComponents<State>();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const [{ show: showPreviewModal }, previewModalId] = useModal();
    const formState = useFormState<State>(formId);

    /**
     * Transform comma separated string of numbers and
     * number ranges to an array of numbers
     */
    const deserialize = useCallback((value: string): NonNullable<Value> => {
        const segments = (value?.split(",") ?? [])
            .map(value => value.trim())
            .filter(value => value.length > 0);

        const result: number[] = [];
        for (const segment of segments) {
            const range = segment.split("-").map(value => value.trim());
            if (range.length === 1) {
                // When it's only one number
                result.push(Math.trunc(Number(range[0])));
                continue;
            }
            if (range.length === 2) {
                // When it's a range of numbers, e.g. 7-10
                const start = Math.trunc(Number(range[0]));
                const end = Math.trunc(Number(range[1]));
                if (start > end) continue;
                repeatCount({
                    countFrom: start,
                    countTo: end,
                }).forEach(value => result.push(value));
                continue;
            }
            throw new Error("Invalid range: " + segment);
        }
        return unique(
            result.filter(
                item =>
                    !isNaN(item) &&
                    item !== null &&
                    typeof item !== "undefined",
            ),
        ) as number[];
    }, []);

    /**
     * Transform an array of numbers into a comma separated
     * string of numbers and number ranges
     */
    const serialize = useCallback((values: Value): string => {
        const _values =
            values?.slice().sort(sortNumerically).map(Math.trunc) || [];
        const valuesSorted = unique(_values);
        const serializedValues: string[] = [];
        let lastRangeStart = valuesSorted[0];
        let lastValue = valuesSorted[0];
        for (let index = 0; index < valuesSorted.length; index++) {
            const value = valuesSorted[index];
            if (index === valuesSorted.length - 1) {
                // It is the last value
                // if it's part of a range, add the range
                if (value === lastValue + 1) {
                    serializedValues.push(`${lastRangeStart}-${value}`);
                    continue;
                }
                // if it's a single value
                // and there's no unsaved previous range
                // add the last single value, and the new value
                if (lastRangeStart === lastValue)
                    serializedValues.push(
                        lastValue.toString(),
                        value.toString(),
                    );
                // if it's a single value
                // and there's an unsaved previous range
                // add the range, and the single value
                else
                    serializedValues.push(
                        `${lastRangeStart}-${lastValue}`,
                        value.toString(),
                    );
            }

            // If the last value is the same as this one
            // we should ignore it, we don't care about duplicates
            if (value === lastValue) continue;

            // If this value is the next in the sequence
            // we should just update the last value
            if (value === lastValue + 1) {
                lastValue = value;
                continue;
            }

            // If the value is not the next in the sequence
            // we should add the range or single value
            // from the last range start to the last value
            if (lastRangeStart === lastValue)
                serializedValues.push(lastValue.toString());
            else serializedValues.push(`${lastRangeStart}-${lastValue}`);

            // ... and update the last range start
            // ... and the last value
            lastRangeStart = value;
            lastValue = value;
            continue;
        }

        return serializedValues.join(",");
    }, []);

    const valuePreview = useMemo(
        (): Value => (formState ? props.getter(formState?.state) || [] : []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.getter, formState?.state],
    );
    const inputPreview = useMemo(
        () => serialize(valuePreview || []),
        [serialize, valuePreview],
    );

    return (
        <FormComponents.TextInput
            formId={formId}
            spin={props.spin}
            label={props.label}
            description={props.description}
            hideDescriptionAfterInput={props.hideDescriptionAfterInput}
            labelDescription={props.labelDescription}
            fieldId={props.fieldId}
            disabled={props.disabled}
            postElement={
                <ButtonTransparent
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        showPreviewModal();
                    }}
                    padding={{ all: 0 }}>
                    <Icon icon={faEye} />
                    <CsvNumberPreviewModal
                        modalId={previewModalId}
                        csv={inputPreview}
                        csvNumbers={valuePreview ?? emptyArrayOf<number>()}
                    />
                </ButtonTransparent>
            }
            fieldSchema={z
                .string()
                .refine(
                    value => {
                        // TODO! This won't really work yet
                        // TODO! It's validating the stringified array
                        // TODO! from the getter, not the actual
                        // TODO! text input
                        // TODO! Although errors won't be seen
                        // TODO! They will be ignored and the working
                        // TODO! values will be valid and work
                        try {
                            deserialize(value);
                            return true;
                        } catch (error) {
                            browserLogger.error(error);
                            return false;
                        }
                    },
                    toTranslatableString({
                        sv: "Vänligen ange en kommaseparerad lista av nummer",
                        en: "Please provide a comma separated list of numbers",
                    }),
                )
                .optional()}
            getter={(state: Partial<State>) => {
                const value = props.getter(state);
                // value is an array of numbers
                // we want to convert it into a comma separated string of numbers
                // it could contain ranges like "1-5,7,9-11"
                // we need to check for ranges and convert them to e.g. 8-12
                // if allowRanges is true
                // single numbers should be separated by commas
                return serialize(value);
            }}
            setter={(state, newValue) => {
                // parse an array of numbers from the comma separated numbers from string newValue
                const result = newValue ? deserialize(newValue) : [];
                return props.setter(state, result);
            }}
        />
    );
};

const CsvNumberPreviewModal = (props: {
    readonly csv: string;
    readonly csvNumbers: number[];
    readonly modalId: ModalId;
}) => {
    const { hide: _hide } = useModalActions(props.modalId);
    const sorted = props.csvNumbers.slice().sort(sortNumerically);
    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    title={translation({
                        sv: "Förhandsgranskning",
                        en: "Preview",
                    })}
                    bottomBorder
                    modalId={props.modalId}
                />
            }>
            <FlexColumn fillParentWidth padding={{ all: 20 }}>
                <Paragraph
                    content={[
                        paragraphTextFactory({
                            text: translation({
                                sv: "Nummerserie (csv):",
                                en: "Number series (csv):",
                            }),
                            css: { fontWeight: "bold" },
                        }),
                        paragraphLineBreakFactory(),
                        props.csv,
                    ]}
                />
                <VerticalDivider M />
                <Paragraph
                    content={[
                        paragraphTextFactory({
                            text: translation({
                                sv: "Resultat:",
                                en: "Result:",
                            }),
                            css: { fontWeight: "bold" },
                        }),
                        paragraphLineBreakFactory(),
                        sorted.join(", "),
                    ]}
                />
            </FlexColumn>
        </Modal>
    );
};

export { Form2CsvNumberInput };
