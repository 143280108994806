import { useMe } from "@@/authentication/me/me-context";
import { AccessDeniedPage } from "@@/backoffice/shared/access-denied-page";
import { PageLoad } from "@@/pages/page-load";
import { Conditional } from "@@/shared/conditional";
import { isApiError, Me } from "@towni/common";
import * as React from "react";

type Props = {
    children: (me: Me) => React.ReactNode;
};
const WithMe = ({ children }: Props) => {
    const [me, { meQuery }] = useMe();

    if (meQuery.isError) {
        if (isApiError(meQuery.error)) {
            if (
                meQuery.error.statusCode !== 401 &&
                meQuery.error.statusCode !== 403
            ) {
                throw meQuery.error;
            }
        }
    }

    return (
        <>
            <Conditional when={!meQuery.isError && meQuery.isPending && !me}>
                <PageLoad />
            </Conditional>
            <Conditional
                when={meQuery.isError || (!meQuery.isPending && !me)}
                type="hide">
                <AccessDeniedPage key="access_denied" type="unAuthenticated" />
            </Conditional>
            {!!me && <>{children(me)}</>}
        </>
    );
};

export { WithMe };
