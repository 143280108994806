import { useAccommodationCartResourcesWithSubType } from "@@/carts/accommodation-checkout/use-accommodation-sub-type-picked";
import { useCart } from "@@/carts/multi-carts/cart.context";
import * as Sentry from "@sentry/react";
import { sum } from "@towni/common";

/**
 * Checks if there are any missing sub types in the accommodation cart.
 *
 * @returns {boolean} Returns true if there are missing sub types, otherwise false.
 */
const useHasMissingSubType = (): boolean => {
    const cart = useCart();
    const resourcesWithPickableSubType =
        useAccommodationCartResourcesWithSubType();

    if (cart.accommodationSubTypeCount) {
        const selectedCount = sum(
            Object.values(cart.accommodationSubTypeCount),
        );

        if (selectedCount < resourcesWithPickableSubType.length) {
            return true; // missing sub type, less than required are selected
        }
        if (selectedCount > resourcesWithPickableSubType.length) {
            // We'll log it with sentry so we can rectify it
            // should never happen though, too many sub types selected
            Sentry.withScope(scope => {
                scope.setExtra("selectedCount", selectedCount);
                scope.setExtra(
                    "resourcesWithPickableSubTypeCount",
                    resourcesWithPickableSubType.length,
                );
                scope.setExtra(
                    "resourcesWithPickableSubType",
                    resourcesWithPickableSubType.map(r => r.resource._id),
                );
            });
        }
        return false; // all sub types are selected
    }
    return resourcesWithPickableSubType.length > 0; // missing sub type
};

export { useHasMissingSubType };
