import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ProductId, RemSize, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../shared/store-front-hz-product-card";
import { kronocampingColors } from "../kronocamping-color-context";

const KronocampingGetOutAndMoveSection: FC = () => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            // Cyklar
            getProductId("product_svcKZVDdvG8ybX6jGlhH" as ProductId),
            // Familjebil
            getProductId("product_p5sFn0QjjEmFGR4CC8gh" as ProductId),
            // Trampbil
            getProductId("product_WqIvYGcdrXAT72xsxKCh" as ProductId),
        ],
        []
    );

    return (
        <SectionArea
            sectionAreaId="kronocamping-out-and-move-mind"
            title="Ut och röra sig"
            titleSize={2 as RemSize}
            subtitle="Vi har mycket för att göra din vistelse full av liv och rörelse"
            background={{
                color: kronocampingColors.lightBackground,
            }}
            css={{
                borderRadius: isDesktop ? 10 : 0,
            }}
            padding={isDesktop ? { all: 10 } : { topBottom: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[0]}
                    actionText={text.readMore}
                    actionTextColorSet={kronocampingColors.secondary}
                    aspectRatio="1:1"
                    hideKicker
                    hideSubtitle
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[1]}
                    actionText={text.bookHere}
                    actionTextColorSet={kronocampingColors.primary}
                    aspectRatio="1:1"
                    hideKicker
                    hideSubtitle
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[2]}
                    actionText={text.readMore}
                    actionTextColorSet={kronocampingColors.secondary}
                    aspectRatio="1:1"
                    hideKicker
                    hideSubtitle
                />
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

const text = {
    readMore: translation({
        sv: "Läs mer",
        en: "Read more",
    }),
    bookHere: translation({
        sv: "Boka här",
        en: "Book here",
    }),
};

export { KronocampingGetOutAndMoveSection };
