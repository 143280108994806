import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    AccommodationQuantities,
    Product,
    ResourceId,
    ResourceReservationRequest,
    TimeRange,
    getAccommodationSettings,
    isAccommodation,
    resourceReservationAccommodationFactory,
} from "@towni/common";

const useCreateAccommodationReservationRequests = (product: Product) => {
    const { onPremiseSettings, resourceReservationInfo } =
        useBookingAccommodationContext(state => ({
            onPremiseSettings: state.onPremiseSettings,
            resourceReservationInfo: state.reservations,
        }));

    const [globalResources, _] = useBookableResources(product._id);

    const createReservationRequests = (
        time: TimeRange,
        pickedResourceGroups: {
            resourceGroupId: ResourceId;
            quantity: number;
            displayResourceTitle: boolean;
            requestedResources?: ResourceId[];
            quantities: AccommodationQuantities;
        }[],
    ): ResourceReservationRequest[] => {
        const sku = product.skus[0];
        if (!isAccommodation(sku.acquire))
            throw new Error("Not an accommodation");

        const settings = getAccommodationSettings(product, time);
        if (!settings) throw new Error("No settings found for accommodation");

        return resourceReservationAccommodationFactory({
            bookableSku: product.skus[0],
            globalResources,
            timeRange: time,
            resourceReservationInfo,
            pickedResourceGroups,
            settings: settings.settings,
            ignoreTime: !!onPremiseSettings,
        });
    };

    return createReservationRequests;
};

export { useCreateAccommodationReservationRequests };
