import { ModalId } from "@@/modals/context/modal-id";
import { useModalActions } from "@@/modals/use-modal-actions";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { Paragraph, paragraphLinkFactory, TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    BookableTableBooking,
    dateTimeFormatter,
    isoAndUnixFactory,
    Order,
    OrderItemAccommodation_V2,
    OrderItemBookable_V2,
    Translatable,
    translation,
} from "@towni/common";
import { useCancellationAvailability } from "./use-cancellation-availability";

type Props = {
    modalId: ModalId;
    providerTitle: Translatable;
    providerPhoneNumber: string | undefined;
    bookable: BookableTableBooking;
    tableBooking: OrderItemBookable_V2 | OrderItemAccommodation_V2;
    order: Order;
};

const TableBookingCancelUnavailable = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const {
        modalId,
        providerTitle,
        providerPhoneNumber,
        tableBooking,
        bookable,
        order,
    } = props;
    const { hide } = useModalActions(modalId);
    const {
        cancellationByCustomerAllowed,
        cancellationUnavailableFrom,
        cancellationAvailable,
    } = useCancellationAvailability({
        orderItem: tableBooking,
        bookable,
        order,
    });

    return (
        <FlexColumn padding={{ all: 20 }} fillParentWidth crossAxis="center">
            <VerticalDivider M />
            <TextBox
                text={translation({
                    sv: `För avbokning kontakta ${translate(providerTitle)}`,
                    en: `To cancel contact ${translate(providerTitle)}`,
                })}
                color={theme.colors.black}
                weight="700"
                size={1.1}
            />
            <VerticalDivider M />
            <Conditional when={!cancellationByCustomerAllowed}>
                <Paragraph
                    css={{
                        color: theme.colors.black.light40.asString,
                        userSelect: "text",
                        textAlign: "center",
                    }}
                    content={
                        providerPhoneNumber?.trim()
                            ? [
                                  translation({
                                      sv: `Denna bokning kan inte avbokas online. Kontakta `,
                                      en: `This product cannot be canceled online. Contact `,
                                  }),
                                  providerTitle,
                                  translation({
                                      sv: ` på telefonnummer `,
                                      en: ` at `,
                                  }),
                                  paragraphLinkFactory({
                                      text: providerPhoneNumber,
                                      href: `tel:${providerPhoneNumber}`,
                                  }),
                                  translation({
                                      sv: ` för assistans.`,
                                      en: ` for assistance.`,
                                  }),
                              ]
                            : translation({
                                  sv: `Denna bokning kan inte avbokas online.`,
                                  en: `This product cannot be canceled online.`,
                              })
                    }
                />
            </Conditional>
            <Conditional
                when={cancellationByCustomerAllowed && !cancellationAvailable}>
                <Paragraph
                    css={{
                        color: theme.colors.black.light40.asString,
                        userSelect: "text",
                        textAlign: "center",
                    }}
                    content={[
                        translation({
                            sv: `Denna bokning kunde endast avbokas online fram till den `,
                            en: `This booking could only be canceled online until `,
                        }),
                        dateTimeFormatter("sv").formatDateAndTimeLong(
                            isoAndUnixFactory(cancellationUnavailableFrom),
                        ),
                        translation({
                            sv: `. Kontakta `,
                            en: `. Contact `,
                        }),
                        providerTitle,
                        translation({
                            sv: ` på telefonnummer `,
                            en: ` at `,
                        }),
                        paragraphLinkFactory({
                            text: providerPhoneNumber as string,
                            href: `tel:${providerPhoneNumber}`,
                        }),
                        translation({
                            sv: ` för assistans.`,
                            en: ` for assistance.`,
                        }),
                    ]}
                />
            </Conditional>
            <VerticalDivider XL />
            <ButtonWhite onClick={() => hide()}>
                <TextBox
                    padding={{ leftRight: 30 }}
                    text={translation({
                        sv: "Okej",
                        en: "Okey",
                    })}
                />
            </ButtonWhite>
        </FlexColumn>
    );
};

export { TableBookingCancelUnavailable };
