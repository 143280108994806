import { AppPage } from "@@/pages/app-page";
import { PageLoad } from "@@/pages/page-load";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { useCalculatedProviderStatus } from "@@/providers/calculate-provider-status";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { whiteningGradient } from "@@/shared/bg-lightening-gradient";
import { Conditional } from "@@/shared/conditional";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { Label } from "@@/shared/label";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { ImageV2 } from "@@/shared/pictures/image";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { ColorThemeProvider } from "@@/styles/app-theme-provider";
import { css, useTheme } from "@emotion/react";
import {
    Product,
    Provider,
    isAlfredItemActive,
    joinTranslatables,
    translation,
} from "@towni/common";
import { _BackButton } from "./generic/_back-button";
import { _ColumnOne } from "./generic/_column-one";
import { _ColumnTwo } from "./generic/_column-two";
import { _GenericBooking_ModalButtonSection } from "./generic/_generic-booking_modal-button-section";
import { _ProductImages } from "./generic/_product_images";
import { _ColumnTwo_TableBooking } from "./table-booking/_column-two-table-booking";
import { _TableBooking_ModalButtonSection } from "./table-booking/_table-booking_modal-button-section";

type Props = {
    product: Product;
    provider: Provider;
};

const _ProductBookablePage = (props: Props) => {
    const theme = useTheme();
    const isMedium = usePageBreakpoint({ when: "M" });
    const isMediumSmall = usePageBreakpoint({ when: "MS" });
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { product, provider } = props;
    const providerStatus = useCalculatedProviderStatus(provider);
    const { branding } = useBrandingContext();

    if (product.skus[0]?.acquire._type !== "BOOKABLE")
        throw new Error("Product is not a bookable; " + product._id);
    const bookableType = product.skus[0]?.acquire.type;

    const providerIsHidden = providerStatus === "HIDDEN";
    const productDisabled =
        providerStatus === "PAUSED" ||
        (product.status && !isAlfredItemActive(product.status));

    const headerContent = (
        <>
            <_BackButton provider={provider} />
            <Conditional when={!isMobile}>
                <HorizontalDivider />
                <CurrentUserIcon />
            </Conditional>
        </>
    );

    const oneColumn = isMobile || isMediumSmall;
    const backgroundCss = useBrandingBackgroundCss(
        branding?.product.background,
    );
    const peripheralBackgroundCss = useBrandingBackgroundCss(
        branding?.product.peripheralBackground,
    );

    if (!branding) return <PageLoad />;

    return (
        <ColorThemeProvider colorTheme={branding.colorThemes.light}>
            <AppPage
                pageId={appPageIdFactory(product._id)}
                pageContainerCss={css({ height: "100%" })}>
                <HeadTitle title={[product.meta.title, provider.meta.title]} />
                <WideScreenWrapper css={peripheralBackgroundCss}>
                    <FlexColumn
                        css={[
                            {
                                width: "100%",
                                height: "100%",
                                overflowY: "scroll",
                                overflowX: "hidden",
                            },
                            backgroundCss,
                        ]}>
                        {/* HEADER */}
                        <Conditional when={!isMobile}>
                            <FlexRow
                                css={{
                                    width: "100%",
                                    padding: 20,
                                    label: "back_button_area",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor:
                                        theme.colors.white.asString,
                                    borderBottom: "1px solid #e5e5e5",
                                    zIndex: 9999,
                                    position: "sticky",
                                    top: 0,
                                    alignSelf: "start",
                                }}>
                                {headerContent}
                            </FlexRow>
                        </Conditional>
                        {/* HIDDEN? */}
                        <Conditional when={providerIsHidden}>
                            <FlexRow fillParentWidth mainAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "GÖMD",
                                        en: "HIDDEN",
                                    })}
                                    css={{ padding: 20, width: "100%" }}
                                    size={2}
                                    align="center"
                                    weight="900"
                                    backgroundColor={
                                        theme.colors.danger.main.light95
                                    }
                                    color={theme.colors.danger}
                                />
                            </FlexRow>
                        </Conditional>
                        <LayoutGrid
                            css={{
                                label: "layout-grid",
                                margin: isMobile
                                    ? branding.product.margin[2]
                                    : isMedium
                                      ? branding.product.margin[1]
                                      : branding.product.margin[0],
                                // overflowX: "hidden",
                                // overflowY: "scroll",
                                flex: 1,
                                backgroundColor: theme.colors.white.asString,
                                borderRadius: 20,
                                gridTemplateColumns:
                                    isMobile || isMediumSmall
                                        ? "1fr"
                                        : "minmax(0, 1fr) 50px minmax(0, 1fr)",
                                gridTemplateRows: "min-content",
                                gridAutoRows: "min-content",
                                paddingBottom: 120,
                                opacity: productDisabled ? 0.8 : 1,
                                filter: productDisabled
                                    ? "grayscale(100%)"
                                    : "unset",
                                pointerEvents: productDisabled
                                    ? "none"
                                    : "unset",
                            }}>
                            <Conditional when={isMobile}>
                                {/* HEADER */}
                                <LayoutCell
                                    css={{
                                        padding: 20,
                                        gridRow: 1,
                                        label: "back_button_area",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        zIndex: 9999,
                                        borderBottom: "none",
                                        gridColumn: "1 / -1",
                                        alignItems: "flex-start",
                                        position: "sticky",
                                        top: 0,
                                        alignSelf: "start",
                                        backgroundColor:
                                            theme.colors.transparent.asString,
                                    }}>
                                    {headerContent}
                                </LayoutCell>
                            </Conditional>

                            {/* IMAGES_AREA */}
                            <LayoutCell
                                css={{
                                    label: "images_area",
                                    display: "flex",
                                    justifyContent: "stretch",
                                    alignTracks: "stretch",
                                    gridColumn: "1 / -1",
                                    gridRow: 1,
                                    paddingTop: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    position: "relative",
                                    height: "fit-content",
                                    overflow: "hidden",
                                    backgroundColor:
                                        theme.colors.default.background
                                            .asString,
                                    borderRadius: isMobile ? 0 : 10,
                                    ...(!isMobile
                                        ? {
                                              marginTop: isMedium ? 27 : 54,
                                              marginLeft: isMedium ? 30 : 60,
                                              marginRight: isMedium ? 30 : 60,
                                              marginBottom: isMedium ? 30 : 40,
                                          }
                                        : {}),
                                }}>
                                <_ProductImages
                                    product={product}
                                    provider={provider}
                                />
                                <div
                                    css={{
                                        position: "absolute",
                                        bottom: 20,
                                        left: 20,
                                        zIndex: 100,
                                        label: "LOGO",
                                    }}>
                                    <ImageV2
                                        imageSource={provider.logoImageRef}
                                        title={joinTranslatables([
                                            provider.meta.title,
                                            " - Logo",
                                        ])}
                                        css={{
                                            width: 60,
                                            height: 60,
                                            borderRadius: 3000,
                                            overflow: "hidden",
                                        }}
                                    />
                                </div>
                            </LayoutCell>

                            {/* COLUMN_ONE */}
                            <LayoutCell
                                css={{
                                    gridColumn: 1,
                                    label: "column_one",
                                    paddingTop: 20,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    backgroundColor:
                                        theme.colors.default.background
                                            .asString,
                                    ...(!isMobile
                                        ? {
                                              paddingLeft: isMedium ? 35 : 60,
                                              paddingRight: oneColumn
                                                  ? isMedium
                                                      ? 35
                                                      : 60
                                                  : 0,

                                              paddingTop: 0,
                                          }
                                        : {}),
                                }}>
                                <Conditional when={productDisabled}>
                                    <Label
                                        text={translation({
                                            sv: "Ej tillgänglig",
                                            en: "Unavailable",
                                        })}
                                        colorTheme="YELLOW"
                                        size={1.5}
                                    />
                                    <VerticalDivider L />
                                </Conditional>
                                <_ColumnOne
                                    product={product}
                                    provider={provider}
                                />
                                <Conditional when={oneColumn}>
                                    <VerticalDivider L />
                                    <HorizontalLine fillParentWidth />
                                    <VerticalDivider S />
                                </Conditional>
                            </LayoutCell>

                            <Conditional when={!oneColumn}>
                                {/* SPACER */}
                                <LayoutCell
                                    css={{
                                        label: "spacer",
                                        gridColumn: 2,
                                    }}
                                />
                            </Conditional>

                            {/* COLUMN_TWO */}
                            <LayoutCell
                                css={{
                                    label: "book_area",
                                    gridColumn: 1,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    backgroundColor:
                                        theme.colors.default.background
                                            .asString,
                                    ...(!isMobile
                                        ? {
                                              gridColumn: oneColumn ? 1 : 3,
                                              paddingLeft: oneColumn
                                                  ? isMedium
                                                      ? 35
                                                      : 60
                                                  : 0,
                                              paddingRight: isMedium ? 35 : 60,
                                          }
                                        : {}),
                                }}>
                                <Conditional
                                    when={bookableType === "TABLE_BOOKING"}
                                    render={() => (
                                        <_ColumnTwo_TableBooking
                                            product={product}
                                            provider={provider}
                                            disabled={productDisabled}
                                        />
                                    )}
                                    else={() => (
                                        <_ColumnTwo
                                            product={product}
                                            provider={provider}
                                            disabled={productDisabled}
                                        />
                                    )}
                                />
                            </LayoutCell>
                        </LayoutGrid>
                    </FlexColumn>
                </WideScreenWrapper>
                <Conditional
                    when={!!oneColumn}
                    render={() => (
                        <FlexRow
                            background={{
                                linearGradient: whiteningGradient,
                            }}
                            css={{
                                position: "absolute",
                                backgroundColor: theme.colors.white.asString,
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                borderTop: `1px solid ${theme.colors.default.border.asString}`,
                                boxShadow: "0px -5px 10px rgba(0, 0, 0, 0.1)",
                                padding: 20,
                                bottom: 0,
                                left: 0,
                                right: 0,
                            }}>
                            <Conditional
                                when={bookableType === "TABLE_BOOKING"}
                                render={() => (
                                    <_TableBooking_ModalButtonSection
                                        product={product}
                                        provider={provider}
                                        disabled={productDisabled}
                                    />
                                )}
                                else={() => (
                                    <_GenericBooking_ModalButtonSection
                                        product={product}
                                        provider={provider}
                                        disabled={productDisabled}
                                    />
                                )}
                            />
                        </FlexRow>
                    )}
                />
            </AppPage>
        </ColorThemeProvider>
    );
};

export { _ProductBookablePage };
