import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { FlexRow } from "@@/shared/flex-containers";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { GridCardContainer } from "./grid-views/cards/grid-card-container";
import { inactiveCss } from "./grid-views/shared/inactive-css";

import { getBlockContentTargetUrl } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/get-block-content-target-url";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { HzScrollContainer } from "@@/shared/hz-scroll-container";
import { LayoutCell } from "@@/shared/layout-cell";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useIsTranslatableEmpty } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { BlockContent, ContentAutoPageBlock, assertNever } from "@towni/common";
import { useMemo } from "react";
import { ContentGrid_1_Large } from "./grid-views/content-grid-1-large";
import { ContentGrid_1_Medium } from "./grid-views/content-grid-1-medium";
import { ContentGrid_1_Small } from "./grid-views/content-grid-1-small";
import { ContentGrid_2_Large } from "./grid-views/content-grid-2-large";
import { ContentGrid_2_Medium } from "./grid-views/content-grid-2-medium";
import { ContentGrid_2_Small } from "./grid-views/content-grid-2-small";
import { ContentGrid_3_Large } from "./grid-views/content-grid-3-large";
import { ContentGrid_3_Medium } from "./grid-views/content-grid-3-medium";
import { ContentGrid_3_Small } from "./grid-views/content-grid-3-small";
import { ContentGrid_4_Large } from "./grid-views/content-grid-4-large";
import { ContentGrid_4_Medium } from "./grid-views/content-grid-4-medium";
import { ContentGrid_4_Small } from "./grid-views/content-grid-4_small";
import { ContentGrid_5_Large } from "./grid-views/content-grid-5-large";
import { ContentGrid_5_Medium } from "./grid-views/content-grid-5-medium";
import { ContentGrid_5_Small } from "./grid-views/content-grid-5_small";
import { ContentGrid_6_Large } from "./grid-views/content-grid-6-large";
import { ContentGrid_6_Medium } from "./grid-views/content-grid-6-medium";
import { ContentGrid_6_Small } from "./grid-views/content-grid-6-small";
import { ContentGrid_7x_Large } from "./grid-views/content-grid-7x-large";
import { ContentGrid_7x_Medium } from "./grid-views/content-grid-7x-medium";
import { ContentGrid_7x_Small } from "./grid-views/content-grid-7x-small";

type Props = {
    readonly block: ContentAutoPageBlock;
    readonly content: BlockContent[];
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const ContentCardView = (props: Props) => {
    const { block } = props;

    switch (block.layout) {
        case "hzScroll":
            return <ContentCardsHzScroll {...props} />;
        default:
            return <ContentCardsAuto {...props} />;
    }
};

const ContentCardsAuto = (props: Props) => {
    const { block, content } = props;
    const numberOfItems = content.length;

    switch (true) {
        case numberOfItems === 0:
            return null;
        case numberOfItems === 1: {
            switch (block.preferredContentSize) {
                case "S":
                    return <ContentGrid_1_Small content={content[0]} />;
                case "M":
                    return <ContentGrid_1_Medium content={content[0]} />;
                case "L":
                    return <ContentGrid_1_Large content={content[0]} />;

                default:
                    assertNever(block.preferredContentSize);
            }
        }
        case numberOfItems === 2: {
            switch (block.preferredContentSize) {
                case "S":
                    return (
                        <ContentGrid_2_Small
                            content={[content[0], content[1]]}
                        />
                    );
                case "M":
                    return (
                        <ContentGrid_2_Medium
                            content={[content[0], content[1]]}
                        />
                    );
                case "L":
                    return (
                        <ContentGrid_2_Large
                            content={[content[0], content[1]]}
                        />
                    );

                default:
                    assertNever(block.preferredContentSize);
            }
        }
        case numberOfItems === 3: {
            switch (block.preferredContentSize) {
                case "S":
                    return (
                        <ContentGrid_3_Small
                            content={[content[0], content[1], content[2]]}
                        />
                    );
                case "M":
                    return (
                        <ContentGrid_3_Medium
                            content={[content[0], content[1], content[2]]}
                        />
                    );
                case "L":
                    return (
                        <ContentGrid_3_Large
                            content={[content[0], content[1], content[2]]}
                        />
                    );
                default:
                    assertNever(block.preferredContentSize);
            }
        }
        case numberOfItems === 4: {
            switch (block.preferredContentSize) {
                case "S":
                    return (
                        <ContentGrid_4_Small
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                            ]}
                        />
                    );
                case "M":
                    return (
                        <ContentGrid_4_Medium
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                            ]}
                        />
                    );
                case "L":
                    return (
                        <ContentGrid_4_Large
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                            ]}
                        />
                    );
                default:
                    assertNever(block.preferredContentSize);
            }
        }
        case numberOfItems === 5: {
            switch (block.preferredContentSize) {
                case "S":
                    return (
                        <ContentGrid_5_Small
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                                content[4],
                            ]}
                        />
                    );
                case "M":
                    return (
                        <ContentGrid_5_Medium
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                                content[4],
                            ]}
                        />
                    );
                case "L":
                    return (
                        <ContentGrid_5_Large
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                                content[4],
                            ]}
                        />
                    );
                default:
                    assertNever(block.preferredContentSize);
            }
        }
        case numberOfItems === 6: {
            switch (block.preferredContentSize) {
                case "S":
                    return (
                        <ContentGrid_6_Small
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                                content[4],
                                content[5],
                            ]}
                        />
                    );
                case "M":
                    return (
                        <ContentGrid_6_Medium
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                                content[4],
                                content[5],
                            ]}
                        />
                    );
                case "L":
                    return (
                        <ContentGrid_6_Large
                            content={[
                                content[0],
                                content[1],
                                content[2],
                                content[3],
                                content[4],
                                content[5],
                            ]}
                        />
                    );
                default:
                    assertNever(block.preferredContentSize);
            }
        }
        case numberOfItems > 6: {
            switch (block.preferredContentSize) {
                case "S":
                    return <ContentGrid_7x_Small content={content} />;
                case "M":
                    return <ContentGrid_7x_Medium content={content} />;
                case "L":
                    return <ContentGrid_7x_Large content={content} />;
                default:
                    assertNever(block.preferredContentSize);
            }
        }
        default:
            throw new Error(
                "Eehh, there can't be less than zero items in an array",
            );
    }
};

const ContentCardsHzScroll = (props: Props) => {
    const theme = useTheme();
    const { block, content } = props;
    // const isMobile = usePageBreakpoint({ when: "📱" });

    const itemWidth = ((): number => {
        const mediumSize = 250;
        switch (block.preferredContentSize) {
            case "S":
                return mediumSize * 0.9;
            case "L":
                return mediumSize * 1.25;
            case "M":
            default:
                return mediumSize;
        }
    })();
    const items = useMemo(
        () =>
            content.map(item => (
                <div key={item._id} css={{ width: itemWidth }}>
                    {(() => {
                        switch (block.preferredContentSize) {
                            case "S":
                                return (
                                    <ContentCardWithLabel
                                        key={item._id}
                                        content={item}
                                        block={block}
                                    />
                                );
                            case "L":
                                return (
                                    <ContentCardWithLabel
                                        key={item._id}
                                        content={item}
                                        block={block}
                                    />
                                );
                            case "M":
                            default:
                                return (
                                    <ContentCardWithLabel
                                        key={item._id}
                                        content={item}
                                        block={block}
                                    />
                                );
                        }
                    })()}
                </div>
            )),
        [content, block, itemWidth],
    );

    return (
        <HzScrollContainer
            gap={{
                startGap: 20,
                dividingGap: 20,
                endGap: 20,
                bottomGap: 40,
            }}
            items={items}
        />
    );
};

const ContentCardWithLabel = (props: {
    block: ContentAutoPageBlock;
    content: BlockContent;
    className?: string;
}) => {
    const theme = useTheme();
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const { block, content: item } = props;
    const { defaultTextShadow, defaultBorderRadius, providerId } =
        usePageViewContext();
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const aspectRatio = (() => {
        switch (block.preferredContentSize) {
            case "S":
                return "1:1";
            case "L":
                return "2:3";
            case "M":
            default:
                return "3:4";
        }
    })();

    return (
        <Link
            to={getBlockContentTargetUrl(item, providerId)}
            title={item.title}
            disabled={item.inactive}
            css={{ cursor: "pointer" }}>
            <LayoutCell
                key={item._id}
                className={props.className}
                css={{
                    width: "100%",
                    textAlign: "left",
                }}>
                <GridCardContainer
                    aspectRatio={aspectRatio}
                    css={props.content.inactive ? inactiveCss : undefined}>
                    <FlexColumn
                        fillParent
                        css={{
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-1-medium",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: isSmall ? 500 : 800,
                            },
                        }}>
                        <FlexColumn
                            fillParent
                            crossAxis="flex-start"
                            mainAxis="space-between"
                            padding={{ all: 20 }}
                            background={{ linearGradient: darkeningGradient }}>
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={props.content.actionText}
                                    accentColor={
                                        "accentColor" in props.content &&
                                        props.content.accentColor
                                            ? props.content.accentColor
                                            : block.accentColor
                                    }
                                />
                            </FlexRow>
                            <FlexColumn>
                                <Conditional
                                    when={
                                        !isTranslatableEmpty(
                                            props.content.kicker,
                                        )
                                    }>
                                    <VerticalDivider M />
                                    <TextBox
                                        text={props.content.kicker}
                                        case="UPPERCASE"
                                        size={0.725}
                                        weight="800"
                                        color={
                                            "textColor" in props.content &&
                                            props.content.textColor
                                                ? props.content.textColor
                                                : theme.colors.white
                                        }
                                        css={{
                                            textShadow: defaultTextShadow,
                                        }}
                                    />
                                    <VerticalDivider XXS />
                                </Conditional>
                                <TextBox
                                    text={props.content.title}
                                    lineClamp={2}
                                    weight="800"
                                    size={
                                        block.preferredContentSize === "S"
                                            ? 1.35
                                            : 1.5
                                    }
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        // overflow: "unset",
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                                <Conditional
                                    when={
                                        !isTranslatableEmpty(
                                            props.content.subtitle,
                                        )
                                    }>
                                    <VerticalDivider XS />
                                    <TextBox
                                        text={props.content.subtitle}
                                        lineClamp={2}
                                        weight="400"
                                        size={0.825}
                                        color={
                                            "textColor" in props.content &&
                                            props.content.textColor
                                                ? props.content.textColor
                                                : theme.colors.white
                                        }
                                        css={{
                                            // overflow: "unset",
                                            overflowWrap: "break-word",
                                            wordBreak: "break-word",
                                            textShadow: defaultTextShadow,
                                        }}
                                    />
                                </Conditional>
                            </FlexColumn>
                        </FlexColumn>
                    </FlexColumn>
                </GridCardContainer>
            </LayoutCell>
        </Link>
    );
};

export { ContentCardView };
