/* eslint-disable react/display-name */
import { browserLogger } from "@@/settings/browser-logger";
import { useTranslate } from "@@/translations/use-translate";
import { css, SerializedStyles, useTheme } from "@emotion/react";
import {
    faSpinnerThird,
    IconDefinition,
} from "@fortawesome/pro-duotone-svg-icons";
import {
    ColorItem,
    generateId,
    LanguageCode,
    Padding,
    SizeName,
    sizeNameMap,
    Translatable,
    translation,
    whatKey,
} from "@towni/common";
import * as React from "react";
import { useRef, useState } from "react";
import { HorizontalDivider, VerticalDivider } from "../dividers";
import { FlexColumn, FlexRow } from "../flex-containers";
import { Icon } from "../icons/icon";
import { paddingToCssValue } from "../padding";
import { FieldTitle } from "./field-title";
import { TextBox } from "./text-box";

type HTMLInputType =
    | "email"
    | "text"
    | "file"
    | "tel"
    | "password"
    | "number"
    | "time"
    | "date"
    | "search";
type Props = {
    /**
     * use ref first, if you don't wanna mess with merging multiple refs
     * you can set the id in html with this property
     * MAKE SURE it's always unique!
     * @type {string}
     */
    readonly htmlId?: string;
    /**
     * Defaults to 0
     * @type {number}
     */
    readonly debounceMs?: number;
    readonly validator?: (input: string) => Promise<boolean>;
    readonly type: HTMLInputType;
    readonly onChange?: (value: string) => void;
    /**
     * Modifies text input
     * This happens before adding prefix (if any)
     */
    readonly modify?: (inputValue: string) => string;
    readonly onValidationFailed?: (message?: string) => void;
    readonly onEnter?: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    readonly onTab?: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    readonly onEscape?: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    readonly value?: Translatable;

    readonly defaultValue?: string;
    readonly pattern?: string;
    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly errorMessage?: Translatable;
    readonly size?: SizeName;
    readonly padding?: Padding;
    readonly color?: string;
    readonly fillParentWidth?: boolean;
    readonly styling?: SerializedStyles;
    readonly inputStyling?: SerializedStyles;
    readonly disabled?: boolean;
    readonly autoFocus?: boolean;
    readonly prefix?: string;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    /**
     * defaults to swedish `sv`
     * @type {LanguageCode}
     */
    readonly languageToEdit?: LanguageCode;
    /**
     * NOTE: only valuable for fields with multiline true at the moment
     * @type {boolean}
     */
    readonly isMarkdown?: boolean;
    /**
     * defaults to "RECOMMENDED"
     *
     * @type {("RECOMMENDED" | "REQUIRED" | "OPTIONAL")}
     */
    readonly level?: "RECOMMENDED" | "REQUIRED" | "OPTIONAL";
    readonly autoComplete?: string;
    readonly step?: string;
    readonly min?: string;
    readonly preElement?: JSX.Element;
    readonly postElement?: JSX.Element;
    readonly icon?: IconDefinition | string;
    readonly onFocus?: (
        focusEvent: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    readonly onBlur?: (
        blurEvent: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    readonly onClick?: (
        clickEvent: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    readonly multiline?: boolean | number;
    readonly basis?: string | number;
    readonly grow?: number;
    readonly shrink?: number;
    readonly align?: "left" | "right" | "center";
    readonly showSpinner?: boolean;
    readonly className?: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextEdit = React.forwardRef<any, Props>((props, ref) => {
    const theme = useTheme();
    const translate = useTranslate();

    const translatedValue = translate(props.value);
    const [initialValue] = useState(translatedValue);
    const isDirty = initialValue !== translatedValue;
    const level = props.level ?? "RECOMMENDED";
    const labelColor =
        props.labelColor ?? level === "OPTIONAL"
            ? theme.colors.textInput.placeholder
            : theme.colors.default.text;
    // Spinning
    const [showSpinner, setShowSpinner] = useState(!!props.showSpinner);
    React.useEffect(() => {
        if (showSpinner !== !!props.showSpinner) {
            setShowSpinner(!!props.showSpinner);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showSpinner]);

    // const [preElementRef, setpreElementRef] =
    //     React.useState<HTMLDivElement | null>(null);

    const placeholder = translate(
        props.placeholder ??
            translation({
                sv: `Lägg till ${translate(props.label).toLocaleLowerCase()}`,
                en: `Add ${translate(props.label).toLocaleLowerCase()}`,
            }),
    );
    const description = translate(props.description);
    const update = (newValue: string) => {
        // Add prefix to output if request,
        // and make modifications to output using provided modifier functions
        const outputValue = `${props.prefix || ""}${
            props.modify?.(newValue) ?? newValue
        }`;
        // console.log("outputValue", { newValue, outputValue });
        if (newValue === translatedValue) return; // no change

        if (props.validator) {
            setShowSpinner(true);
            props
                .validator(outputValue)
                .then(isValid => {
                    // browserLogger.log("TEXT INPUT IS VALID", isValid);
                    if (isValid) {
                        props.onChange?.(outputValue);
                    } else {
                        props.onValidationFailed?.();
                    }
                })
                .catch(error => {
                    browserLogger.warn("TEXT INPUT VALIDATION WARNING", error);
                    props.onValidationFailed?.();
                })
                .finally(() => {
                    setShowSpinner(false);
                });
        } else {
            props.onChange?.(outputValue);
            setShowSpinner(false);
        }
    };
    const _type: HTMLInputType = props.type || "text";
    const { size, color, fillParentWidth, padding } = props;
    const textEditContainerStyles = css`
        border: 1px solid ${theme.colors.textInput.border.asString};
        border-radius: ${theme.radius}px;
        font-weight: 800;
        font-size: ${theme.sizes.inRem[size || sizeNameMap.M]}rem;
        background-color: ${theme.colors.textInput.background.asString};
        color: ${color || theme.colors.textInput.text.asString};
        ${typeof fillParentWidth === "undefined" || fillParentWidth === true
            ? `width: 100%;`
            : ""}
        padding:${paddingToCssValue(padding ?? { all: 16 })};

        ${props.styling || ""}
        /* ${showSpinner ? "padding-right: 16px;" : ""} */
        ${props.disabled ? "cursor: unset;" : "cursor: text !important;"}
    `;
    const htmlInputStyles = css`
        user-select: auto;
        border: none;
        position: relative;
        font-weight: 800;
        font-size: 1em;
        ${props.align ? `text-align: ${props.align};` : ""}
        background-color: ${theme.colors.textInput.background.asString};
        color: ${props.color || theme.colors.textInput.text.asString};
        width: ${props.preElement ? `` : `100%`};
        ${props.inputStyling || ""};
    `;
    const _htmlId = useRef(generateId({ prefix: "html_input__" }));
    const htmlId = props.htmlId ?? _htmlId.current;
    const { basis, grow, shrink } = props;
    const fontSizeRem = theme.sizes.inRem[size || sizeNameMap.M];

    return (
        <FlexColumn
            basis={basis}
            grow={grow}
            shrink={shrink}
            className={props.className}
            fillParentWidth={
                typeof fillParentWidth === "undefined" || fillParentWidth
            }
            css={{
                fontSize: `${fontSizeRem}rem`,
                label: "text-edit",
            }}
            crossAxis="stretch">
            {props.label ? (
                <>
                    <FlexRow
                        shrink={0}
                        crossAxis="center"
                        mainAxis="space-between">
                        <FieldTitle
                            htmlFor={htmlId}
                            padding={{ left: 2 }}
                            text={props.label}
                            color={labelColor}
                            required={level === "REQUIRED"}
                        />
                        {props.labelDescription ? (
                            <>
                                <HorizontalDivider XXS />
                                <FieldTitle
                                    padding={{ left: 2 }}
                                    text={props.labelDescription}
                                    color={labelColor}
                                    weight="400"
                                    size="S"
                                    css={{
                                        opacity: 0.5,
                                    }}
                                />
                            </>
                        ) : null}
                    </FlexRow>
                    <VerticalDivider XS />
                </>
            ) : null}
            <FlexRow
                grow={1}
                shrink={0}
                crossAxis="flex-start"
                mainAxis="space-between"
                styling={textEditContainerStyles}
                css={props.className}
                onClick={() => document.getElementById(htmlId)?.focus()}>
                <FlexRow grow={1}>
                    {props.preElement && (
                        <>
                            {props.preElement}
                            <HorizontalDivider />
                        </>
                    )}
                    {!props.multiline ? (
                        <input
                            autoFocus={props.autoFocus}
                            id={htmlId}
                            type={_type}
                            pattern={props.pattern}
                            ref={ref}
                            placeholder={placeholder}
                            css={htmlInputStyles}
                            value={translatedValue ?? ""}
                            defaultValue={props.defaultValue}
                            autoComplete={props.autoComplete}
                            step={props.step}
                            min={props.min}
                            onChange={event => {
                                update(event.target.value);
                            }}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            onClick={props.onClick}
                            disabled={!!props.disabled}
                            onKeyDown={e => {
                                const key = whatKey(e);
                                switch (key) {
                                    case "ENTER":
                                        props.onEnter?.(e);
                                        break;
                                    case "ESCAPE":
                                        props.onEscape?.(e);
                                        break;
                                    case "TAB":
                                        props.onTab?.(e);
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        />
                    ) : (
                        <textarea
                            autoFocus={props.autoFocus}
                            placeholder={placeholder}
                            css={[
                                {
                                    resize: "vertical",
                                },
                                htmlInputStyles,
                            ]}
                            ref={ref}
                            autoComplete={props.autoComplete}
                            onChange={event => update(event.target.value)}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            disabled={!!props.disabled}
                            value={translatedValue ?? ""}
                            onKeyDown={e => {
                                const key = whatKey(e);
                                switch (key) {
                                    case "ENTER":
                                        props.onEnter?.(e);
                                        break;
                                    case "ESCAPE":
                                        props.onEscape?.(e);
                                        break;
                                    case "TAB":
                                        props.onTab?.(e);
                                        break;
                                    default:
                                        break;
                                }
                            }}
                            rows={
                                typeof props.multiline === "number"
                                    ? props.multiline
                                    : 7
                            }
                        />
                    )}
                    {props.postElement && (
                        <>
                            {props.postElement}
                            <HorizontalDivider />
                        </>
                    )}
                </FlexRow>
                {showSpinner && (
                    <div style={{ paddingLeft: 5 }}>
                        {showSpinner}
                        <Icon icon={faSpinnerThird} spin />
                    </div>
                )}
                {!showSpinner && props.icon && (
                    <div style={{ paddingLeft: 5 }}>
                        {(typeof props.icon !== "string" && (
                            <Icon
                                icon={props.icon}
                                color={theme.colors.primary}
                            />
                        )) || (
                            <img
                                src={props.icon as string}
                                alt={placeholder || description || "icon"}
                                css={{
                                    height: 25,
                                }}
                            />
                        )}
                    </div>
                )}
            </FlexRow>
            {props.description &&
                !props.errorMessage &&
                !(props.hideDescriptionAfterInput && isDirty) && (
                    <>
                        <VerticalDivider XXS />
                        <TextBox
                            padding={{ all: 5 }}
                            text={props.description}
                            color={theme.colors.black.light70}
                            size={fontSizeRem * 0.8}
                            userSelect="text"
                        />
                    </>
                )}
            {props.errorMessage && (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        padding={{ all: 5 }}
                        text={props.errorMessage}
                        color={theme.colors.danger}
                        size={fontSizeRem * 0.8}
                        italic
                    />
                </>
            )}
        </FlexColumn>
    );
});

export { TextEdit };
export type { HTMLInputType, Props as TextEditProps };
