import { useMe } from "@@/authentication/me/me-context";
import {
    createSocketEventListeners,
    useSocket,
} from "@@/shared/sockets/use-socket";
import { PaymentSocketEvent, paymentSocketEventTypes } from "@towni/common";
import * as React from "react";
/**
 * Listen to realtime order updates for given provider
 */
const usePaymentSocketEvents = (params: {
    onPaymentSocketEvent: (
        event: PaymentSocketEvent,
        room: string | undefined
    ) => void;
    verbose?: boolean;
}) => {
    const [me] = useMe();
    const [{ onPaymentSocketEvent, verbose }] = React.useState(params);
    const socketListeners = React.useMemo(() => {
        if (!me) return undefined;
        return [
            ...createSocketEventListeners<PaymentSocketEvent>({
                eventTypes: [...paymentSocketEventTypes],
                rooms: [me._id],
                onEvent: onPaymentSocketEvent,
            }),
        ];
    }, [me]);

    return useSocket(socketListeners, { verbose });
};

export { usePaymentSocketEvents };
