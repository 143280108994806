import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import { Product } from "@towni/common";
import { productMainQueryKey } from "../main-query-key";
import { fetchProducts } from "./products.fetcher";

const useProducts = (
    productIds?: string[]
): readonly [Product[], UseQueryResult<Product[]>] => {
    const ids = productIds ?? [];
    return useApiResources<Product>(
        createQueryKey(productMainQueryKey, ids),
        fetchProducts(ids),
        {
            enabled: !!ids.length,
        }
    );
};

export { useProducts };
