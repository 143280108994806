import { Branded, genericId } from "@towni/common";

type AnchorId = Branded<string, "AnchorId">;
const {
    zodSchema: anchorIdZodSchema,
    factory: anchorIdFactory,
    assert: isAnchorId,
} = genericId<AnchorId>("anchor__", {
    regexMatch: /(^anchor_.*$)/,
});

export { anchorIdZodSchema, anchorIdFactory, isAnchorId };
export type { AnchorId };
