import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { isApiError, SignUpData, translation } from "@towni/common";
import { useMe } from "../me/me-context";

const finishSignUp = (anonTag?: string) => (signUpData: SignUpData) => {
    return apiFetchClient.post<SignUpData & { anonTag?: string }>({
        route: `/authentications/sign-ups/${signUpData.signUpId}`,
        body: {
            anonTag,
            ...signUpData,
        },
    });
};

const useFinishSignUp = () => {
    const [_, { meQuery, anonymousTag: anonTag }] = useMe();
    const toast = useToast();
    const mutationFn = finishSignUp(anonTag);
    return useMutation({
        mutationFn,
        onSuccess: () => {
            meQuery.refetch();
        },
        onError: (error, _command, _previous) => {
            isApiError(error)
                ? toast.fromError(error)
                : toast.danger({
                      message: translation({
                          sv: "Registrering misslyckades. Försök igen om en liten stund.",
                          en: "Registration failed. Try again in a little while.",
                      }),
                  });
            Sentry.captureMessage("Registrering misslyckades");
            throw error;
        },
    });
};

export { useFinishSignUp };
