import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { FlexRow } from "@@/shared/flex-containers";
import { CheckBoxWithText } from "@@/shared/text";
import { translation } from "@towni/common";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const aiFredModeAtom = atomWithStorage("aiFred-mode", false);

const DevAiFred = () => {
    const [aiFredMode, setAiFredMode] = useAtom(aiFredModeAtom);

    const hasAlfredScope = useHasAlfredScope();
    if (!hasAlfredScope) return null;

    return (
        <FlexRow>
            <CheckBoxWithText
                checked={aiFredMode}
                onChange={setAiFredMode}
                text={translation({
                    en: "AiFred mode",
                    sv: "AiFred-läge",
                })}
            />
        </FlexRow>
    );
};

export { DevAiFred, aiFredModeAtom };
