// BASED ON https://usehooks.com/useSessionStorage/

import { browserLogger } from "@@/settings";
import { useState } from "react";

// Hook
const useSessionStorage = <T>(
    uniqueKey: string,
    initialValue: T
): readonly [T, (value: T) => void] => {
    const hasSessionStorage =
        typeof window !== "undefined" && window.sessionStorage;

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (!hasSessionStorage) {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.sessionStorage.getItem(uniqueKey);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            browserLogger.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to sessionStorage.
    const setValue = (value: T) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (hasSessionStorage) {
                window.sessionStorage.setItem(
                    uniqueKey,
                    JSON.stringify(valueToStore)
                );
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            browserLogger.log(error);
        }
    };
    return [storedValue, setValue] as const;
};

export { useSessionStorage };
