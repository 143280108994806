import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { Modal } from "@@/modals/modal";
import { useModal } from "@@/modals/use-modal";
import { useProviders } from "@@/providers/state/queries/providers/use-providers";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { ModalTitle } from "@@/shared/text/modal-title";
import { css } from "@emotion/react";
import {
    Provider,
    ProviderId,
    enTranslator,
    svTranslator,
    translation,
} from "@towni/common";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    providerId: ProviderId | undefined;
};

const CartProviderCheckModal = (props: Props) => {
    const { cart, clearCart } = useCartContext(context => ({
        cart: context.cart,
        clearCart: context.clearCart,
    }));
    const navigate = useNavigate();
    const [{ show, hide }, modalId] = useModal("cart_provider_check_modal__");
    const [newShopProvider, setNewShopProvider] = useState<Provider>();
    const [oldShopProvider, setOldShopProvider] = useState<Provider>();

    const oldProviderIds = useMemo(() => {
        return Array.from(
            new Set(cart?.orderItems?.map(oi => oi.providerId)) || []
        );
    }, [cart?.orderItems]);

    const [providers] = useProviders(
        props.providerId ? [props.providerId, ...oldProviderIds] : []
    );
    // const [providers] = hello;

    useEffect(() => {
        if (!providers) return;
        setOldShopProvider(
            providers.find(provider => oldProviderIds.includes(provider._id))
        );
        setNewShopProvider(
            providers?.find(provider => provider._id === props.providerId)
        );
    }, [providers, oldProviderIds, props.providerId]);

    useEffect(() => {
        if (
            !cart.orderItems ||
            !props.providerId ||
            !oldShopProvider ||
            !newShopProvider
        ) {
            hide();
            return;
        }
        if (cart.orderItems?.some(oi => oi.providerId !== props.providerId)) {
            show();
            return;
        }
    }, [props.providerId, cart.orderItems, oldShopProvider, newShopProvider]);

    // const newProviderHomePath = useHomePath(newShopProvider);
    const oldProviderHomePath = useHomePath(oldShopProvider);

    return (
        <Modal modalId={modalId} backgroundCloseDisabled={true}>
            <FlexColumn
                crossAxis="center"
                styling={css`
                    background-color: white;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                `}>
                <FlexColumn
                    fillParentWidth
                    mainAxis="center"
                    crossAxis="center"
                    padding={{ leftRight: 20, topBottom: 20 }}>
                    <TextBox
                        text={translation({
                            sv: "Ny kundkorg?",
                            en: "New shopping cart?",
                        })}
                    />
                    <VerticalDivider XXS />
                    <ModalTitle
                        size="L"
                        weight="800"
                        text={translation({
                            sv: "Beställa från flera?",
                            en: "Order from many?",
                        })}
                    />
                </FlexColumn>
                <TextBox
                    padding={{ bottom: 20, leftRight: 25 }}
                    align="center"
                    text={translation({
                        sv: `Vill du beställa från flera? Inga problem!\\\nFärdigställ din första order från ${
                            svTranslator(oldShopProvider?.meta.title) ??
                            "den första butiken"
                        } innan du fyller på med varor från ${
                            svTranslator(newShopProvider?.meta.title) ??
                            "den andra butiken"
                        }. Olika öppettider etc gör att vi vill säkra ordrarna var för sig. Vill du ha varorna hemleverarade samtidigt? Välj hemfrakt som leveransalternativ och välj samma leveranstid så dyker de upp samtidigt hemma hos dig!\\\nHelgblommor och mat. 😉`,
                    })}
                    renderMarkdown
                    size={1}
                />
                <HorizontalLine fillParentWidth />
                <TextBox
                    text={translation({
                        sv: `Ny kundkorg ${
                            newShopProvider
                                ? svTranslator(newShopProvider.meta.title)
                                : "hos den nya butiken"
                        }`,
                        en: `New cart ${
                            newShopProvider
                                ? enTranslator(newShopProvider.meta.title)
                                : "at the new store"
                        }`,
                    })}
                    case={"UPPERCASE"}
                    weight="700"
                    size={1}
                    padding={{ topBottom: 30 }}
                    onClick={() => {
                        clearCart();
                        hide();
                    }}
                />
                <HorizontalLine fillParentWidth />
                <TextBox
                    text={translation({
                        sv: `Tillbaka till ${
                            oldShopProvider
                                ? svTranslator(oldShopProvider.meta.title)
                                : "den tidigare butiken"
                        }`,
                        en: `Go back to ${
                            oldShopProvider
                                ? enTranslator(oldShopProvider.meta.title)
                                : "the previous store"
                        }`,
                    })}
                    case={"UPPERCASE"}
                    weight="700"
                    padding={{ topBottom: 30 }}
                    size={1}
                    onClick={() => {
                        if (!oldShopProvider) {
                            navigate(-1);
                            hide();
                            return;
                        }
                        navigate(oldProviderHomePath);
                        hide();
                    }}
                />
                <VerticalDivider />
            </FlexColumn>
        </Modal>
    );
};

export { CartProviderCheckModal };
