import { ContentAutoView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-auto-page-block-view";
import { ContentPageBlockViewContextProvider } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { useBlockContentQuery } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/use-block-content";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useIsTranslatableEmpty } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    BlockContent,
    ContentAutoPageBlock,
    emptyArrayOf,
    replacePlaceholderInTranslatable,
} from "@towni/common";
import { useMemo } from "react";

type Props = {
    block: ContentAutoPageBlock;
};

const ContentPageBlockView = (props: Props) => {
    const block = props.block;
    const theme = useTheme();
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const { pageInnerWidth, pageMaxWidth, defaultGridGap } =
        usePageViewContext();
    const isMobile = usePageBreakpoint({ when: "📱" });

    const [imageReference] = useStorageItemImageReference(
        block.backgroundImage,
    );
    const hasAnyTitle =
        !isTranslatableEmpty(block.title) ||
        !isTranslatableEmpty(block.subtitle);

    const blockContentQuery = useBlockContentQuery(block.contentIds, {
        showInactive: !!block.showInactiveContent,
    });
    const blockContent = useMemo(() => {
        return blockContentQuery.data?.items
            ? blockContentQuery.data?.items.sort(
                  (a, b) =>
                      block.contentIds.indexOf(a.contentId) -
                      block.contentIds.indexOf(b.contentId),
              )
            : emptyArrayOf<BlockContent>();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockContentQuery.data?.items,
        block.contentIds,
        block.showInactiveContent,
    ]);

    const titleColor =
        block.titleColor ??
        block.textColor ??
        theme.colors.default.text.asString;
    const subtitleColor =
        block.subtitleColor ??
        block.textColor ??
        theme.colors.default.text.withAlpha(0.5).asString;
    const kickerColor =
        block.kickerColor ??
        block.textColor ??
        theme.colors.default.text.asString;

    const background: BackgroundStyle = imageReference
        ? {
              imageReference,
              fit: "COVER",
              imageSize: {
                  imageWidth: isMobile
                      ? breakpointRange.MOBILE.max
                      : Math.min(breakpointRange.DESKTOP.max, pageMaxWidth),
              },
          }
        : {
              color: block.backgroundColor ?? theme.colors.default.background,
          };

    const isLoading =
        blockContentQuery.isPending && blockContentQuery.fetchStatus !== "idle";

    if (!isLoading && !blockContent.length)
        return (
            <FlexColumn
                fillParentWidth
                mainAxis={"flex-start"}
                crossAxis={"center"}
                padding={{ all: 20 }}
                css={{
                    flexShrink: 0,
                    label: "content_auto_block_view__no_content",
                }}>
                <TextBox
                    text={"-"}
                    size={2}
                    color={theme.colors.default.text.withAlpha(0.5)}
                />
            </FlexColumn>
        );

    const titleAlignment = (() => {
        if (block.textAlignment === "auto") return "center";
        if (block.textAlignment) return block.textAlignment;
        // if (block.layout === "hzScroll") return "left";
        return "center";
    })();

    return isLoading ? (
        <FlexRow fillParentWidth mainAxis="center" padding={{ all: 20 }}>
            <FlexRow fillParentWidth maxWidth={pageInnerWidth}>
                <TextShimmer rows={3} rowHeight={"20px"} />
            </FlexRow>
        </FlexRow>
    ) : (
        <FlexColumn
            fillParentWidth
            mainAxis={"flex-start"}
            crossAxis={"center"}
            padding={{
                top:
                    block.contentIds.length === 1 &&
                    block.preferredContentSize === "L"
                        ? 0
                        : block.layout === "hzScroll"
                          ? 20
                          : 40,
                bottom:
                    block.layout === "hzScroll" ||
                    (block.contentIds.length === 1 &&
                        block.preferredContentSize === "L")
                        ? 0
                        : 20,
            }}
            css={{ label: "content_auto_block_view", flexShrink: 0 }}
            background={background}>
            <FlexColumn
                fillParentWidth
                maxWidth={
                    pageInnerWidth
                    // block.layout !== "hzScroll" ? pageInnerWidth : undefined
                }
                mainAxis={"flex-start"}
                crossAxis={"stretch"}
                css={{
                    paddingTop: hasAnyTitle ? 10 : 0,
                    paddingBottom: hasAnyTitle ? 20 : 0,
                    paddingLeft: isMobile ? 0 : 20,
                    paddingRight: isMobile ? 0 : 20,
                    flexShrink: 0,
                    label: `content_page_block_titles_container`,
                }}>
                <Conditional when={!isTranslatableEmpty(block.kicker)}>
                    <TextBox
                        text={replacePlaceholderInTranslatable(
                            block.kicker || "",
                            "*",
                            "•",
                        )}
                        case="UPPERCASE"
                        padding={{
                            left: defaultGridGap,
                            right: defaultGridGap,
                        }}
                        size={0.625}
                        weight="700"
                        align={titleAlignment}
                        color={kickerColor}
                    />
                    <VerticalDivider XXS />
                </Conditional>

                <Conditional when={!isTranslatableEmpty(block.title)}>
                    <TextBox
                        text={block.title}
                        padding={{
                            left: defaultGridGap,
                            right: defaultGridGap,
                        }}
                        size={isMobile ? 1.5 : 2}
                        weight="700"
                        align={titleAlignment}
                        color={titleColor}
                    />
                </Conditional>
                <Conditional when={!isTranslatableEmpty(block.subtitle)}>
                    <VerticalDivider XS />
                    <TextBox
                        text={block.subtitle}
                        padding={{
                            left: defaultGridGap,
                            right: defaultGridGap,
                        }}
                        size={1.125}
                        align={titleAlignment}
                        color={subtitleColor}
                        css={{ opacity: 0.6, lineHeight: 1.3 }}
                    />
                </Conditional>
                <Conditional when={hasAnyTitle}>
                    <VerticalDivider XS />
                </Conditional>
            </FlexColumn>
            <FlexColumn
                fillParentWidth
                maxWidth={pageInnerWidth}
                mainAxis={"flex-start"}
                crossAxis={"stretch"}
                css={{
                    label: `content_page_block_container`,
                    flexShrink: 0,
                }}>
                <Conditional
                    when={block.type === "CONTENT_AUTO_PAGE_BLOCK"}
                    render={() => {
                        return (
                            <ContentPageBlockViewContextProvider
                                block={block as ContentAutoPageBlock}>
                                <ContentAutoView
                                    key={block._id}
                                    content={blockContent}
                                />
                            </ContentPageBlockViewContextProvider>
                        );
                    }}
                />
            </FlexColumn>
        </FlexColumn>
    );
};

export { ContentPageBlockView };
