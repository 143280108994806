import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    GetResponse,
    GetSingleResponse,
    ProviderId,
    ResourceMap,
    ResourceMapId,
} from "@towni/common";
import { resourceMapMainQueryKey } from "./resource-map-main-query-key";

function useResourceMapQuery(
    resourceMapId: ResourceMapId | undefined,
): UseQueryResult<ResourceMap> {
    const queryFn: QueryFunction<ResourceMap | undefined> = async ({
        signal,
    }) => {
        if (!resourceMapId) return undefined;
        const data = await apiFetchClient.get<GetSingleResponse<ResourceMap>>({
            route: `/resources/maps/${encodeURIComponent(resourceMapId)}`,
            customConfig: {
                signal,
            },
        });
        return data.item || undefined;
    };
    return useQuery({
        queryKey: [resourceMapMainQueryKey, resourceMapId],
        queryFn,
        enabled: !!resourceMapId,
    });
}
function useResourceMapsQuery(
    providerId: ProviderId,
): UseQueryResult<ResourceMap[]> {
    const queryFn: QueryFunction<ResourceMap[]> = async ({ signal }) => {
        const data = await apiFetchClient.get<GetResponse<ResourceMap>>({
            route: `/resources/maps/for-provider/${encodeURIComponent(
                providerId,
            )}`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };
    return useQuery({
        queryKey: [resourceMapMainQueryKey, providerId],
        queryFn: queryFn,
        enabled: !!providerId,
    });
}

export { useResourceMapQuery, useResourceMapsQuery };
