import { usePageById } from "@@/backoffice/for-providers/pages/queries/page/use-page";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { PageLoad } from "@@/pages/page-load";
import { ProviderShopPage } from "@@/providers/provider-shop-page";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { NavigateTo } from "@@/shared/text/navigate-to";
import { PageView } from "@@/store-fronts/pages/page-view";
import {
    PageId,
    ProviderIdOrSlug,
    assertNever,
    parseSafely,
} from "@towni/common";
import { useParams } from "react-router-dom";
import { z } from "zod";

const ProviderPageHome = (props: { pageId: PageId }) => {
    const [, pageQuery] = usePageById(props.pageId);
    if (pageQuery.isPending) return <PageLoad />;
    const page = pageQuery.data;
    return <PageView page={page} isPending={pageQuery.isPending} />;
};

const ProviderHome = () => {
    const providerIdOrSlug = useParams<{ providerIdOrSlug: ProviderIdOrSlug }>()
        .providerIdOrSlug;
    const [, providerQuery] = useProvider(providerIdOrSlug);
    const provider = providerQuery.data;

    if (providerQuery.isPending) return <PageLoad />;
    if (!provider) return <PageNotFound />;
    const home = provider.options.home;
    if (!home || home.type === "LEGACY") {
        return <ProviderShopPage providerId={provider?._id} />;
    }
    if (home.type === "URL") {
        const value = home.url.trim();
        const url = parseSafely({
            value,
            schema: z.string().url(),
        });
        if (!url) return <PageNotFound />;
        return <NavigateTo to={url} />;
    }
    if (home.type === "PAGE_ID") {
        const pageId = home.pageId;
        if (!pageId) return <PageNotFound />;
        return <ProviderPageHome pageId={pageId} />;
    }

    assertNever(home);
};

export default ProviderHome;
