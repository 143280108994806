import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { useLocale } from "@@/translations/use-locale";
import {
    endOfDayZoned,
    endOfMonthZoned,
    endOfWeekZoned,
    repeatCount,
    startOfDayZoned,
    startOfMonthZoned,
    startOfWeekZoned,
    Translatable,
    translation,
} from "@towni/common";
import { addDays, addMonths, addWeeks } from "date-fns";
import { useMemo } from "react";
import { useGetMonthName } from "./use-month-name";

type DateRangePreset = {
    name: Translatable;
    from: Date;
    to: Date;
};

const useDefaultPastTimeRangePresets = (): DateRangePreset[] => {
    const getMonthName = useGetMonthName();
    const locale = useLocale();

    const presets = useMemo(() => {
        const now = new Date();

        const months = repeatCount({ countFrom: 1, countTo: 37 }).map(i => {
            const month = addMonths(now, -i);
            const sameYear = month.getFullYear() === now.getFullYear();

            return {
                name: sameYear
                    ? getMonthName(addMonths(now, -i))
                    : `${getMonthName(
                          addMonths(now, -i),
                      )} ${month.getFullYear()} `,
                from: startOfDayZoned(
                    startOfMonthZoned(addMonths(now, -i), currentTimezoneName),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfMonthZoned(addMonths(now, -i), currentTimezoneName),
                    currentTimezoneName,
                ),
            };
        });

        return [
            {
                name: translation({
                    sv: "Idag",
                    en: "Today",
                }),
                from: startOfDayZoned(now, currentTimezoneName),
                to: endOfDayZoned(now, currentTimezoneName),
            },
            // {
            //     name: translation({
            //         sv: "Igår",
            //         en: "Yesterday",
            //     }),
            //     from: startOfDayZoned(addDays(now, -1), currentTimezoneName),
            //     to: endOfDayZoned(addDays(now, -1), currentTimezoneName),
            // },
            {
                name: translation({
                    sv: "Denna vecka",
                    en: "This week",
                }),
                from: startOfDayZoned(
                    startOfWeekZoned(now, currentTimezoneName, { locale }),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfWeekZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
            },
            {
                name: translation({
                    sv: "Denna månad",
                    en: "This month",
                }),
                from: startOfDayZoned(
                    startOfMonthZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfMonthZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
            },
            {
                name: translation({
                    sv: "Senaste 30 dagarna",
                    en: "Last 30 days",
                }),
                from: startOfDayZoned(addDays(now, -30), currentTimezoneName),
                to: endOfDayZoned(now, currentTimezoneName),
            },

            ...months,
        ];
    }, [getMonthName, locale]);

    return presets;
};
const useDefaultFutureTimeRangePresets = (): DateRangePreset[] => {
    const getMonthName = useGetMonthName();
    const locale = useLocale();

    const presets = useMemo(() => {
        const now = new Date();

        const months = repeatCount({ countFrom: 1, countTo: 37 }).map(i => {
            const month = addMonths(now, i);
            const sameYear = month.getFullYear() === now.getFullYear();

            return {
                name: sameYear
                    ? getMonthName(addMonths(now, i))
                    : `${getMonthName(
                          addMonths(now, i),
                      )} ${month.getFullYear()} `,
                from: startOfDayZoned(
                    startOfMonthZoned(addMonths(now, i), currentTimezoneName),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfMonthZoned(addMonths(now, i), currentTimezoneName),
                    currentTimezoneName,
                ),
            };
        });

        return [
            {
                name: translation({
                    sv: "Idag",
                    en: "Today",
                }),
                from: startOfDayZoned(now, currentTimezoneName),
                to: endOfDayZoned(now, currentTimezoneName),
            },
            // {
            //     name: translation({
            //         sv: "Igår",
            //         en: "Yesterday",
            //     }),
            //     from: startOfDayZoned(addDays(now, -1), currentTimezoneName),
            //     to: endOfDayZoned(addDays(now, -1), currentTimezoneName),
            // },
            {
                name: translation({
                    sv: "Denna vecka",
                    en: "This week",
                }),
                from: startOfDayZoned(
                    startOfWeekZoned(now, currentTimezoneName, { locale }),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfWeekZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
            },
            {
                name: translation({
                    sv: "Nästa vecka",
                    en: "Next week",
                }),
                from: startOfDayZoned(
                    startOfWeekZoned(addWeeks(now, 1), currentTimezoneName, {
                        locale,
                    }),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfWeekZoned(addWeeks(now, 1), currentTimezoneName),
                    currentTimezoneName,
                ),
            },
            {
                name: translation({
                    sv: "Denna månad",
                    en: "This month",
                }),
                from: startOfDayZoned(
                    startOfMonthZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfMonthZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
            },
            {
                name: translation({
                    sv: "Idag och 30 dagar framåt",
                    en: "Today and 30 days forward",
                }),
                from: startOfDayZoned(now, currentTimezoneName),
                to: endOfDayZoned(addDays(now, 30), currentTimezoneName),
            },
            {
                name: translation({
                    sv: "Idag och 60 dagar framåt",
                    en: "Today and 60 days forward",
                }),
                from: startOfDayZoned(now, currentTimezoneName),
                to: endOfDayZoned(addDays(now, 60), currentTimezoneName),
            },

            ...months,
        ];
    }, [getMonthName, locale]);

    return presets;
};
const useDefaultMonthDateRangePresets = (
    mode: "past" | "future",
): DateRangePreset[] => {
    const getMonthName = useGetMonthName();

    const presets = useMemo(() => {
        const now = new Date();

        const months = repeatCount({ countFrom: 1, countTo: 37 }).map(i => {
            const index = mode === "past" ? -i : i;
            const month = addMonths(now, index);
            const sameYear = month.getFullYear() === now.getFullYear();

            return {
                name: sameYear
                    ? getMonthName(addMonths(now, index))
                    : `${getMonthName(
                          addMonths(now, index),
                      )} ${month.getFullYear()} `,
                from: startOfDayZoned(
                    startOfMonthZoned(
                        addMonths(now, index),
                        currentTimezoneName,
                    ),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfMonthZoned(addMonths(now, index), currentTimezoneName),
                    currentTimezoneName,
                ),
            };
        });

        return [
            {
                name: translation({
                    sv: "Denna månad",
                    en: "This month",
                }),
                from: startOfDayZoned(
                    startOfMonthZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
                to: endOfDayZoned(
                    endOfMonthZoned(now, currentTimezoneName),
                    currentTimezoneName,
                ),
            },
            ...months,
        ];
    }, [getMonthName, mode]);

    return presets;
};

export {
    useDefaultFutureTimeRangePresets,
    useDefaultMonthDateRangePresets,
    useDefaultPastTimeRangePresets,
};
export type { DateRangePreset };
