import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { ResourcePicker } from "@@/resources/picker/resource-picker";
import { useResourcesForProvider } from "@@/resources/resources/use-resources";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { PaddingProps } from "@@/shared/padding";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import {
    ProviderId,
    Resource,
    ResourceGlobal,
    ResourceId,
    emptyArrayOf,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { useMemo, useState } from "react";
import { ZodSchema } from "zod";

type Value = ResourceId | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly spin?: boolean;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly fieldSchema?: ZodSchema;
    readonly providerId?: ProviderId;
    /** Optional render, not of the picker, but of the component that triggers
     * the picker. Instead of rendering the component, the result of this function
     * will be rendered if provided */
    readonly render?: (
        resource: Resource | undefined,
        showPicker: () => void,
    ) => JSX.Element;
    readonly padding?: PaddingProps;
    /** Filter */
    readonly filter?: (resource: Resource) => boolean;

    // Css properties
    readonly containerCss?: Interpolation<AppTheme>;
};

const Form2ResourcePickerSingle = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const provider = useProviderFromContext();
    const providerId = props.providerId || provider._id;
    if (!providerId)
        throw new Error("ProviderId is required to fetch resources");

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: formId,
    });
    const [_initialValue] = useState(field.value);

    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
        customFieldValidationErrorMessage: translation({
            sv: "Du måste välja en resurs",
            en: "You must choose a resource",
        }),
    });

    const [__, resourceQuery] = useResourcesForProvider(providerId);
    const spin = resourceQuery.isPending || !!props.spin;

    const resources = useMemo(() => {
        if (!resourceQuery.data) return emptyArrayOf<ResourceGlobal>();
        return resourceQuery.data.filter(r => !props.filter || props.filter(r));
    }, [props, resourceQuery.data]);

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            fillParentWidth
            css={props.containerCss}>
            <ResourcePicker
                key={resourceQuery.isPending ? "pending" : "ready"}
                title={translation({
                    sv: "Resurs",
                    en: "Resource",
                })}
                spin={spin}
                mode="SINGLE"
                renderSingle={props.render}
                padding={props.padding}
                required={field.isRequired}
                initiallySelected={
                    new Set<ResourceId>([...(field.value ? [field.value] : [])])
                }
                resources={resources} //{resourceQuery.data ?? emptyArrayOf<ResourceGlobal>()}
                onChange={newValues => {
                    field.setValue(newValues?.[0]);
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2ResourcePickerSingle };
export type { Props as Form2ResourcePickerSingleProps };
