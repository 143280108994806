import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Picture } from "@@/shared/pictures/picture";
import { ImageShimmer, TextShimmer } from "@@/shared/pictures/shimmers";
import { TextBox } from "@@/shared/text";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { css, useTheme } from "@emotion/react";
import { SectionItemProps } from "../section-item";

const SectionItemRow = (props: SectionItemProps) => {
    const theme = useTheme();
    const [discountsForProduct] = useDiscountsForProduct(props.item);

    if (!props.item) {
        return (
            <FlexRow mainAxis="space-between" crossAxis="stretch">
                <FlexColumn mainAxis="flex-start">
                    <TextShimmer rows={1} />
                    <VerticalDivider XS />
                    <TextShimmer rows={1} rowHeight="14px" />
                    <VerticalDivider XS />
                    <TextShimmer rows={1} rowHeight="14px" width="80px" />
                </FlexColumn>
                <ImageShimmer width="82px" height="82px" radius={5} />
            </FlexRow>
        );
    }

    const { meta } = props.item;
    const firstImage = meta.images[0];
    const firstImageIdOrUrl =
        firstImage && "_id" in firstImage ? firstImage._id : firstImage;
    return (
        <FlexRow
            onClick={props.onClick}
            mainAxis="space-between"
            crossAxis="center">
            <FlexColumn mainAxis="flex-start">
                <TextBox
                    text={meta.title}
                    weight="700"
                    color={theme.colors.black.light15}
                />
                <VerticalDivider XS />
                <TextBox
                    text={meta.subtitle}
                    size="S"
                    color={theme.colors.black.light50}
                />
                <VerticalDivider XS />

                <PriceWithDiscountsTextBox
                    discounts={discountsForProduct}
                    price={props.item.price}
                    size="S"
                    fractions="NONE"
                    color={theme.colors.black.light30}
                />
            </FlexColumn>
            {meta.images && (
                <>
                    <HorizontalDivider />
                    <Picture
                        imageRef={firstImage}
                        width={theme.sizes.imageSizes.thumb}
                        height={theme.sizes.imageSizes.thumb}
                        radius={4}
                        styling={css`
                            flex-shrink: 0;
                            flex-grow: 0;
                        `}
                    />
                    <Picture
                        key={`preload_large_${firstImageIdOrUrl}`}
                        imageRef={firstImage}
                        imageOptions={{
                            imageWidth: theme.sizes.imageSizes.large,
                        }}
                        preload
                    />
                </>
            )}
        </FlexRow>
    );
};

export { SectionItemRow };
