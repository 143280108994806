import {
    AddAddressCommand,
    ApiError,
    EditAddressCommand,
    GetResponse,
    UserAddress,
} from "@towni/common";
import { apiFetchClient } from "../../fetch-client";

const useAddressService = () => {
    const addAddressCommand = (add: AddAddressCommand) => {
        return apiFetchClient.post<
            AddAddressCommand,
            GetResponse<UserAddress> | ApiError
        >({
            route: `/commands/add-address`,
            body: add,
        });
    };

    const editAddressCommand = (edit: EditAddressCommand) => {
        return apiFetchClient.post<
            EditAddressCommand,
            Record<string, unknown> | ApiError
        >({
            route: `/commands/edit-address`,
            body: edit,
        });
    };

    return { addAddressCommand, editAddressCommand };
};

export { useAddressService };
