import { parseZodErrorMessages } from "@@/backoffice/dashboard/shared/parse-zod-error-messages";
import * as Sentry from "@sentry/react";
import {
    DestinationName,
    destinationNameZodSchema,
    svTranslator,
} from "@towni/common";
import * as React from "react";

type State = { area: string; domain: string; destinationName: DestinationName };

const DestinationContext = React.createContext<State | undefined>(undefined);
const fullDomain: Domain = window.location.hostname;

type Area = string;
type Domain = string;
type Match = string;
const getDestinationState = (): State => {
    const mappings = [
        ["lacko", "läckö"],
        ["lacko-slott", "läckö"],
        ["lackö-slott", "läckö"],
        ["lackoslott", "läckö"],
        ["lacköslott", "läckö"],
        ["ngrok", "läckö"],
        ["tau", "läckö"],
        ["kronocamping", "kronocamping"],
        ["daftö", "daftö"],
        ["dafto", "daftö"],
    ] as [Match, Area][];

    const area =
        mappings.find(item =>
            fullDomain.toLowerCase().includes(item[0]),
        )?.[1] ?? "lidköping";

    const _destinationName = fullDomain.toLowerCase().split(".")[0] || "towni";
    const name = destinationNameZodSchema.safeParse(_destinationName);
    if (
        !name.success &&
        _destinationName !== "sandbox" &&
        _destinationName !== "localhost"
    ) {
        Sentry.captureException(
            new Error(
                `Invalid destination sub domain; ${_destinationName}; ${parseZodErrorMessages(
                    name.error,
                    svTranslator,
                ).join(", ")}`,
            ),
        );
    }

    return {
        area,
        domain: fullDomain,
        destinationName: name.success ? name.data : "towni",
    };
};

type Props = Partial<State> & {
    readonly children?: React.ReactNode;
};
const DestinationProvider = (props: Props) => {
    const { area, domain, destinationName } = getDestinationState();
    const state = React.useMemo(
        () => ({ area, domain, destinationName }),
        [area, domain, destinationName],
    );

    return (
        <DestinationContext.Provider value={state}>
            {props.children}
        </DestinationContext.Provider>
    );
};

const useDestination = () => {
    const state = React.useContext(DestinationContext);
    if (!state) {
        throw new Error(
            "useDestination must be used within a DestinationContext",
        );
    }
    return state;
};

export { DestinationProvider, getDestinationState, useDestination };
