import { useMe } from "@@/authentication/me/me-context";
import { useModal } from "@@/modals/use-modal";
import { useTheme } from "@emotion/react";
import { faChevronDown, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { ColorItem, RemSize, fullName, generateId } from "@towni/common";
import * as React from "react";
// import { Gravatar } from "./gravatar";
import { UserModal } from "../users/modal/user-modal";
import { HorizontalDivider } from "./dividers/horizontal-divider";
import { FlexRow } from "./flex-containers";
import { Icon } from "./icons/icon";
import { LoginButton } from "./login-button";
import { useKeyboardShortcut } from "./use-keyboard-shortcut";

type Props = {
    size?: RemSize | number;
    color?: ColorItem;
    bgColor?: ColorItem;
    disableShadow?: boolean;
};

const AccountIcon = (props: Props) => {
    const renderId = React.useRef<string>(generateId());
    const loginTheme = "PRIMARY";
    const [me, { meQuery }] = useMe();
    const theme = useTheme();
    const [{ toggle }, modalId] = useModal("user_modal__");

    const fontSize = ((props.size ?? 22) / 16) as RemSize;
    const color = props.color ?? theme.colors.default.background;
    const bgColor = props.bgColor
        ? props.bgColor
        : theme.colors.default.text.withAlpha(0.8).asString;

    useKeyboardShortcut({
        keys: ["a", "a"],
        modifiers: {
            ctrl: true,
        },
        callback: () => {
            toggle();
        },
    });

    if (meQuery.isError || !me || !me.email?.emailAddress) {
        return (
            <LoginButton
                key={renderId.current}
                spin={meQuery.isPending}
                theme={loginTheme}
                mini={false}
            />
        );
    }

    return (
        <>
            <FlexRow
                shrink={0}
                onClick={toggle}
                data-testid="account-icon"
                css={
                    props.disableShadow
                        ? undefined
                        : {
                              willChange: "filter",
                              filter: `drop-shadow(0px 0px 10px ${bgColor})`,
                          }
                }>
                <Icon
                    icon={faChevronDown}
                    size={(fontSize * 0.62) as RemSize}
                    color={color}
                />
                <HorizontalDivider XS />
                <Icon
                    icon={faUserCircle}
                    size={fontSize}
                    title={fullName(me)}
                    cursor="pointer"
                    color={color}
                />
            </FlexRow>
            <UserModal modalId={modalId} />
        </>
    );
};

export { AccountIcon };
