import { useTranslate } from "@@/translations/use-translate";
import { exists, sortAlphabetically, translation } from "@towni/common";
import { getCountryCodeForRegionCode } from "awesome-phonenumber";
import { useMemo, useState } from "react";

const usePhoneRegions = () => {
    const [regions] = useState([
        [
            "AD",
            translation({
                sv: "Andorra",
                en: "Andorra",
            }),
        ],
        [
            "AL",
            translation({
                sv: "Albanien",
                en: "Albania",
            }),
        ],
        [
            "AT",
            translation({
                sv: "Österrike",
                en: "Austria",
            }),
        ],
        [
            "BA",
            translation({
                sv: "Bosnien",
                en: "Bosnia",
            }),
        ],
        [
            "BE",
            translation({
                sv: "Belgien",
                en: "Belgium",
            }),
        ],
        [
            "BG",
            translation({
                sv: "Bulgarien",
                en: "Bulgaria",
            }),
        ],
        [
            "CH",
            translation({
                sv: "Schweiz",
                en: "Switzerland",
            }),
        ],
        [
            "CZ",
            translation({
                sv: "Tjeckien",
                en: "Czech Republic",
            }),
        ],
        [
            "DE",
            translation({
                sv: "Tyskland",
                en: "Germany",
            }),
        ],
        [
            "DK",
            translation({
                sv: "Danmark",
                en: "Denmark",
            }),
        ],
        [
            "EE",
            translation({
                sv: "Estland",
                en: "Estonia",
            }),
        ],
        [
            "ES",
            translation({
                sv: "Spanien",
                en: "Spain",
            }),
        ],
        [
            "FI",
            translation({
                sv: "Finland",
                en: "Finland",
            }),
        ],
        [
            "FO",
            translation({
                sv: "Färöarna",
                en: "Faroe Islands",
            }),
        ],
        [
            "FR",
            translation({
                sv: "Frankrike",
                en: "France",
            }),
        ],
        [
            "GB",
            translation({
                sv: "Storbritannien",
                en: "United Kingdom",
            }),
        ],
        [
            "GI",
            translation({
                sv: "Gibraltar",
                en: "Gibraltar",
            }),
        ],
        [
            "GR",
            translation({
                sv: "Grekland",
                en: "Greece",
            }),
        ],
        [
            "HR",
            translation({
                sv: "Kroatien",
                en: "Croatia",
            }),
        ],
        [
            "IE",
            translation({
                sv: "Irland",
                en: "Ireland",
            }),
        ],
        [
            "IS",
            translation({
                sv: "Island",
                en: "Iceland",
            }),
        ],
        [
            "IT",
            translation({
                sv: "Italien",
                en: "Italy",
            }),
        ],
        [
            "LI",
            translation({
                sv: "Liechtenstein",
                en: "Liechtenstein",
            }),
        ],
        [
            "LT",
            translation({
                sv: "Litauen",
                en: "Lithuania",
            }),
        ],
        [
            "LU",
            translation({
                sv: "Luxemburg",
                en: "Luxembourg",
            }),
        ],
        [
            "LV",
            translation({
                sv: "Lettland",
                en: "Latvia",
            }),
        ],
        [
            "MC",
            translation({
                sv: "Monaco",
                en: "Monaco",
            }),
        ],
        [
            "MD",
            translation({
                sv: "Moldavien",
                en: "Moldova",
            }),
        ],
        [
            "ME",
            translation({
                sv: "Montenegro",
                en: "Montenegro",
            }),
        ],
        [
            "MK",
            translation({
                sv: "Nordmakedonien",
                en: "North Macedonia",
            }),
        ],
        [
            "MT",
            translation({
                sv: "Malta",
                en: "Malta",
            }),
        ],
        [
            "NL",
            translation({
                sv: "Nederländerna",
                en: "Netherlands",
            }),
        ],
        [
            "NO",
            translation({
                sv: "Norge",
                en: "Norway",
            }),
        ],
        [
            "PL",
            translation({
                sv: "Polen",
                en: "Poland",
            }),
        ],
        [
            "PT",
            translation({
                sv: "Portugal",
                en: "Portugal",
            }),
        ],
        [
            "RO",
            translation({
                sv: "Rumänien",
                en: "Romania",
            }),
        ],
        [
            "RS",
            translation({
                sv: "Serbien",
                en: "Serbia",
            }),
        ],
        [
            "SE",
            translation({
                sv: "Sverige",
                en: "Sweden",
            }),
        ],
        [
            "SI",
            translation({
                sv: "Slovenien",
                en: "Slovenia",
            }),
        ],
        [
            "SK",
            translation({
                sv: "Slovakien",
                en: "Slovakia",
            }),
        ],
        [
            "SM",
            translation({
                sv: "San Marino",
                en: "San Marino",
            }),
        ],
        [
            "UA",
            translation({
                sv: "Ukraina",
                en: "Ukraine",
            }),
        ],
    ] as const);

    return regions;
};

type PhoneRegionCode = ReturnType<typeof usePhoneRegions>[number][0];

type PhoneRegionData = {
    id: string;
    name: string;
    countryCode: `+${number}`;
    regionCode: PhoneRegionCode;
    regionName: string;
};

const usePhoneRegionCodes = () => {
    const translate = useTranslate();
    const regions = usePhoneRegions();

    return useMemo(
        () =>
            regions
                .map(([regionCode, regionName]) => {
                    const countryCode = getCountryCodeForRegionCode(regionCode);
                    if (!countryCode) return undefined;
                    const _regionName = translate(regionName);
                    const result: PhoneRegionData = {
                        id: `+${countryCode} ${regionCode}`,
                        name: `${_regionName} (+${countryCode})`,
                        countryCode: `+${countryCode}`,
                        regionCode,
                        regionName: _regionName,
                    };
                    return result;
                })
                .filter(exists)
                .sort(sortAlphabetically(item => item.name)),
        [regions, translate],
    );
};

export { usePhoneRegionCodes, usePhoneRegions };
