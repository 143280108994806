import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import { MinMaxRange, Padding, Translatable } from "@towni/common";
import { Draft } from "immer";
import { useState } from "react";
import { ZodSchema } from "zod";
import { RangeSlider } from "./range-slider";

type PossibleValueTypes = MinMaxRange | undefined | null;
type DefaultValueType = MinMaxRange | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly minValue: number;
    readonly maxValue: number;

    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    readonly label?: Translatable;
    readonly charFilters?: RegExp | RegExp[];
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
};
// type PickByType<T, Value> = {
//     [P in keyof T as T[P] extends Value ? string : never]: T[P];
// };
const createFormRangeSliderComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2RangeSliderInput = <
        Value extends PossibleValueTypes = DefaultValueType,
    >(
        props: Props<State, Value>,
    ) => {
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            fieldSchema: props.fieldSchema,
            formId: props.formId,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );

        const [_initialValue] = useState(field.value);
        const hasErrors = field.errors.length > 0;
        useFormFieldValidation<State, Value>({
            field,
            initialValidationType: "automatic",
        });

        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                tag={`form-field_${props.fieldId}`}
                crossAxis="stretch">
                <RangeSlider
                    minValue={props.minValue}
                    maxValue={props.maxValue}
                    initialValues={{
                        min: field.value?.min || 0,
                        max: field.value?.max || 0,
                    }}
                    label={props.label}
                    required={field.isRequired}
                    onChange={value => {
                        field.setValue(value as Value);
                    }}
                />
                <VerticalDivider />
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        marginLeft: -10,
                        marginTop: 10,
                        marginBottom: -5,
                    }}
                />
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2RangeSliderInput;
};
export { createFormRangeSliderComponent };
