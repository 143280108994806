import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Pillify } from "@@/shared/pillify";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    WeekAvailability,
    WeekdayShortName,
    dateAgnosticTimeRangeToString,
    swedishWeekdayShortNameLookup,
    translation,
    weekdayLookup,
    weekdayShortNames,
} from "@towni/common";

type Props = {
    availability: WeekAvailability | undefined;
    removeDay?: (day: WeekdayShortName) => void | Promise<void>;
};

const AvailabilityVisualization = (props: Props) => {
    const { availability } = props;
    const theme = useTheme(); // const dailySchedule =
    //     availability && weekAvailabilityToWeeklySchedule(availability);
    // const hello = deparseWeeklySchedule(dailySchedule);
    const week = [...weekdayShortNames];
    if (!availability)
        return (
            <TextBox
                text={translation({
                    sv: "Produkten är alltid tillgänglig",
                    en: "Product is always available",
                })}
                padding={{ all: 5 }}
            />
        );
    return (
        <FlexColumn fillParentWidth>
            {/* <TextBox text={hello} color={theme.colors.danger} /> */}
            <FlexRow
                fillParentWidth
                wrap="wrap"
                shrink={0}
                wrapMargin={10}
                padding={{ topBottom: 5 }}>
                {week
                    .map(
                        weekdayShortName =>
                            swedishWeekdayShortNameLookup[weekdayShortName],
                    )
                    .map(weekdayShortName => {
                        const ranges =
                            availability.week?.[
                                weekdayLookup[weekdayShortName]
                            ];
                        if (!ranges?.length) return null;
                        return (
                            <Pillify key={weekdayShortName}>
                                <FlexRow>
                                    <TextBox
                                        text={weekdayShortName}
                                        color={theme.colors.black.light60}
                                    />
                                    <HorizontalDivider />
                                    <TextBox
                                        text={ranges
                                            ?.map(range =>
                                                dateAgnosticTimeRangeToString(
                                                    range,
                                                    {
                                                        ignoreMinutesIfZero:
                                                            true,
                                                    },
                                                ),
                                            )
                                            .join(", ")}
                                    />
                                    {props.removeDay ? (
                                        <>
                                            <HorizontalDivider />
                                            <Icon
                                                icon={faTimes}
                                                color={
                                                    theme.colors.black.light30
                                                }
                                                padding={{
                                                    topBottom: 3,
                                                    left: 6,
                                                    right: 0,
                                                }}
                                                cursor="pointer"
                                                onClick={() =>
                                                    props.removeDay?.(
                                                        weekdayLookup[
                                                            weekdayShortName
                                                        ],
                                                    )
                                                }
                                            />
                                        </>
                                    ) : null}
                                </FlexRow>
                            </Pillify>
                        );
                    })
                    .filter(Boolean)}
            </FlexRow>
        </FlexColumn>
    );
};

export { AvailabilityVisualization };
