import {
    keepPreviousData,
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { emptyArrayOf } from "@towni/common";
import { useMemo } from "react";

const createQueryKey = (mainKey: string, keys: string | string[]) => [
    mainKey,
    ...(Array.isArray(keys) ? ["multi", ...keys.sort()] : [keys]),
];

type UseApiResourceResult<Resource> = readonly [
    Resource[],
    UseQueryResult<Resource[]>,
];

const useApiResources = <Resource>(
    queryKey: QueryKey,
    fetcher: QueryFunction<Resource[]>,
    options?: {
        enabled?: boolean;
        gcTime?: number;
        keepPreviousData?: boolean;
        staleTime?: number;
        refetchOnWindowFocus?: boolean;
    },
): UseApiResourceResult<Resource> => {
    const enabled =
        typeof options?.enabled === "undefined" ? true : !!options.enabled;

    const queryResult = useQuery<Resource[]>({
        queryKey,
        queryFn: fetcher,
        enabled,
        gcTime: options?.gcTime,
        placeholderData: options?.keepPreviousData
            ? keepPreviousData
            : undefined,
        refetchOnWindowFocus: options?.refetchOnWindowFocus,
        staleTime: options?.staleTime,
    });

    const result = useMemo(() => {
        return [
            queryResult.data ?? emptyArrayOf<Resource>(),
            queryResult,
        ] as const;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryResult, queryResult.data]);
    return result;
};

export { createQueryKey, useApiResources };
export type { UseApiResourceResult };
