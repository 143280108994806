import { useMemo } from "react";
import { ModalId } from "./context/modal-id";
import { useModalContext } from "./context/modal.context";

const useModalActions = (modalId: ModalId) => {
    const actions = useModalContext(context => ({
        show: context.showModal,
        hide: context.hideModal,
        toggle: context.toggleModal,
        exists: context.exists,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(
        () => ({
            show: () => actions.show(modalId),
            hide: () => actions.hide(modalId),
            toggle: () => actions.toggle(modalId),
            exists: () => actions.exists(modalId),
        }),
        [actions, modalId],
    );
};

export { useModalActions };
