import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { browserLogger } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { useDimensions } from "@@/shared/section-area/card-size";
import { useBackgroundStyleToCss } from "@@/shared/stylable/background-style-css";
import { TextBox } from "@@/shared/text";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { css, useTheme } from "@emotion/react";
import {
    AspectRatio,
    BackgroundStyle,
    getOriantationFromItemDisplayType,
} from "@towni/common";
import { SectionItemProps } from "../section-item";
import { Card } from "./card";

const SectionItemCard = (props: SectionItemProps) => {
    const backgroundStyleToCss = useBackgroundStyleToCss();
    const [discountsForProduct] = useDiscountsForProduct(props.item);

    const theme = useTheme();
    const getDimensions = useDimensions();

    const { itemDisplayType: displayType, size } = props.sectionTheme;
    const orientation = getOriantationFromItemDisplayType(displayType);
    const aspectRatio: AspectRatio =
        orientation === "PORTRAIT"
            ? "3:4"
            : orientation === "SQUARE"
              ? "1:1"
              : "4:3";
    const dimensions = getDimensions({ aspectRatio, size });
    const width = dimensions.width * 0.7;
    const height = dimensions.height * 0.7;

    const background: BackgroundStyle = props.item?.meta.background || {
        color: theme.colors.default.background.value.asCssHsla,
    };
    const cardStyles = css`
        width: ${width};
        height: ${height};
        border-radius: 3;
        overflow: hidden;
        ${backgroundStyleToCss(background, theme.colors.default.background) ||
        ""}
        background-color: fuchsia;
    `;
    switch (size) {
        case "XXL":
            if (!props.item) {
                return (
                    <FlexColumn width={`${width}px`} padding={{ bottom: 10 }}>
                        <Card
                            width={width}
                            height={height}
                            background={{
                                color: theme.colors.black.light95,
                                fit: "COVER",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextShimmer rows={2} rowHeight="20px" />
                        <TextShimmer rows={1} rowHeight="18px" width="80px" />
                    </FlexColumn>
                );
            }
            return (
                <FlexColumn
                    width={`${width}px`}
                    padding={{ bottom: 10 }}
                    onClick={props.onClick}>
                    <Card
                        width={width}
                        height={height}
                        background={{
                            imageReference: props.item.meta.images[0],
                            imageSize: {
                                imageWidth:
                                    theme.sizes.imageSizes.closest(width),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <VerticalDivider XS />
                    <FlexColumn
                        fillParentWidth
                        minHeight={80}
                        tag="TITLE_AND_PRICE">
                        <TextBox
                            text={props.item.meta.title}
                            size="L"
                            lineClamp={2}
                            weight="700"
                        />
                        <PriceWithDiscountsTextBox
                            price={props.item.price}
                            discounts={discountsForProduct}
                            size="L"
                            fractions="NONE"
                            color={theme.colors.black.light50}
                            weight="700"
                        />
                    </FlexColumn>
                </FlexColumn>
            );
        case "XL":
            if (!props.item) {
                return (
                    <FlexColumn width={`${width}px`} padding={{ bottom: 10 }}>
                        <Card
                            width={width}
                            height={height}
                            background={{
                                color: theme.colors.black.light95,
                                fit: "COVER",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextShimmer rows={2} rowHeight="18px" />
                        <TextShimmer rows={1} rowHeight="16px" width="75px" />
                    </FlexColumn>
                );
            }
            return (
                <FlexColumn
                    width={`${width}px`}
                    tag="XL_CARD"
                    padding={{ bottom: 10 }}
                    onClick={props.onClick}>
                    <Card
                        width={width}
                        height={height}
                        background={{
                            imageReference: props.item.meta.images[0],
                            imageSize: {
                                imageWidth:
                                    theme.sizes.imageSizes.closest(width),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <VerticalDivider XS />
                    <FlexColumn
                        fillParentWidth
                        minHeight={80}
                        tag="TITLE_AND_PRICE">
                        <TextBox
                            text={props.item.meta.title}
                            size="L"
                            lineClamp={2}
                            weight="700"
                        />
                        <PriceWithDiscountsTextBox
                            price={props.item.price}
                            discounts={discountsForProduct}
                            size="L"
                            fractions="NONE"
                            color={theme.colors.black.light50}
                            weight="700"
                        />
                    </FlexColumn>
                </FlexColumn>
            );
        case "L":
            if (!props.item) {
                return (
                    <FlexColumn
                        width={`${width}px`}
                        padding={{ bottom: 10 }}
                        tag="L-CARD">
                        <Card
                            width={width}
                            height={height}
                            background={{
                                color: theme.colors.black.light95,
                                fit: "COVER",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextShimmer rows={2} rowHeight="16px" />
                        <TextShimmer rows={1} rowHeight="14px" width="65px" />
                    </FlexColumn>
                );
            }

            return (
                <FlexColumn
                    width={`${width}px`}
                    padding={{ bottom: 10 }}
                    tag="L-CARD"
                    onClick={props.onClick}>
                    <Card
                        width={width}
                        height={height}
                        background={{
                            imageReference: props.item.meta.images[0],
                            imageSize: {
                                imageWidth:
                                    theme.sizes.imageSizes.closest(width),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <VerticalDivider XS />
                    <FlexColumn
                        fillParentWidth
                        minHeight={60}
                        tag="TITLE_AND_PRICE">
                        <TextBox
                            text={props.item.meta.title}
                            lineClamp={2}
                            weight="700"
                        />
                        <PriceWithDiscountsTextBox
                            discounts={discountsForProduct}
                            price={props.item.price}
                            size="S"
                            fractions="NONE"
                            color={theme.colors.black.light50}
                            weight="700"
                        />
                    </FlexColumn>
                </FlexColumn>
            );
        case "M":
            if (!props.item) {
                return (
                    <FlexColumn
                        width={`${width}px`}
                        padding={{ bottom: 10 }}
                        tag="M-CARD">
                        <Card
                            width={width}
                            height={height}
                            background={{
                                color: theme.colors.black.light95,
                                fit: "COVER",
                            }}
                        />

                        <VerticalDivider XS />
                        <TextShimmer rows={2} rowHeight="16px" />
                        <TextShimmer rows={1} rowHeight="14px" width="60px" />
                    </FlexColumn>
                );
            }

            return (
                <FlexColumn
                    tag="M-CARD"
                    width={`${width}px`}
                    padding={{ bottom: 10 }}
                    onClick={props.onClick}>
                    <Card
                        width={width}
                        height={height}
                        background={{
                            imageReference: props.item.meta.images[0],
                            imageSize: {
                                imageWidth:
                                    theme.sizes.imageSizes.closest(width),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <VerticalDivider XS />
                    <FlexColumn
                        fillParentWidth
                        minHeight={60}
                        tag="TITLE_AND_PRICE">
                        <TextBox
                            text={props.item.meta.title}
                            lineClamp={2}
                            weight="700"
                        />
                        <PriceWithDiscountsTextBox
                            discounts={discountsForProduct}
                            price={props.item.price}
                            size="S"
                            fractions="NONE"
                            color={theme.colors.black.light50}
                            weight="700"
                            fallbackPriceZero={<></>}
                        />
                    </FlexColumn>
                </FlexColumn>
            );
        case "S":
            if (!props.item) {
                return (
                    <FlexColumn
                        width={`${width}px`}
                        padding={{ bottom: 10 }}
                        tag="S-CARD">
                        <Card
                            width={width}
                            height={height}
                            background={{
                                color: theme.colors.black.light95,
                                fit: "COVER",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextShimmer rows={2} rowHeight="14px" />
                        <TextShimmer rows={1} rowHeight="12px" width="55px" />
                    </FlexColumn>
                );
            }
            return (
                <FlexColumn
                    width={`${width}px`}
                    tag="S-CARD"
                    padding={{ bottom: 10 }}
                    onClick={props.onClick}>
                    <Card
                        width={width}
                        height={height}
                        background={{
                            imageReference: props.item.meta.images[0],
                            imageSize: {
                                imageWidth:
                                    theme.sizes.imageSizes.closest(width),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <VerticalDivider XS />
                    <FlexColumn
                        fillParentWidth
                        minHeight={60}
                        tag="TITLE_AND_PRICE">
                        <TextBox
                            text={props.item.meta.title}
                            size="S"
                            lineClamp={2}
                            weight="700"
                        />
                        <PriceWithDiscountsTextBox
                            discounts={discountsForProduct}
                            price={props.item.price}
                            size="XS"
                            fractions="NONE"
                            color={theme.colors.black.light50}
                            weight="700"
                            fallbackPriceZero={<></>}
                        />
                    </FlexColumn>
                </FlexColumn>
            );
        case "XS":
            if (!props.item) {
                return (
                    <FlexColumn
                        width={`${width}px`}
                        padding={{ bottom: 10 }}
                        tag="XS-CARD">
                        <Card
                            width={width}
                            height={height}
                            background={{
                                color: theme.colors.black.light95,
                                fit: "COVER",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextShimmer rows={2} rowHeight="12px" />
                        <TextShimmer rows={1} rowHeight="10px" width="50px" />
                    </FlexColumn>
                );
            }
            return (
                <FlexColumn
                    width={`${width}px`}
                    padding={{ bottom: 10 }}
                    tag="XS-CARD"
                    onClick={props.onClick}>
                    <Card
                        width={width}
                        height={height}
                        background={{
                            imageReference: props.item.meta.images[0],
                            imageSize: {
                                imageWidth:
                                    theme.sizes.imageSizes.closest(width),
                            },
                            color: theme.colors.black.light95,
                            fit: "COVER",
                        }}
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text={props.item.meta.title}
                        size="XS"
                        lineClamp={2}
                        weight="700"
                    />
                    <PriceWithDiscountsTextBox
                        discounts={discountsForProduct}
                        price={props.item.price}
                        size="XXS"
                        fractions="NONE"
                        color={theme.colors.black.light50}
                        weight="700"
                    />
                </FlexColumn>
            );
        case "XXS":
            if (!props.item) {
                return (
                    <FlexColumn tag="XXS-CARD">
                        <div css={cardStyles} />
                        <TextShimmer rows={1} rowHeight="10px" />
                        <TextShimmer rows={1} rowHeight="8px" width="40px" />
                    </FlexColumn>
                );
            }
            return (
                <FlexColumn tag="XXS-CARD">
                    <div css={cardStyles} />

                    <TextBox size="XXS" text={props.item.meta.title} />
                    <TextBox size="3XS" text={props.item.meta.subtitle} />
                </FlexColumn>
            );
        default:
            browserLogger.error("UNHANDLED SECTION ITEM CARD SIZE", size);
            return null;
    }
};

export { SectionItemCard };
