import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    GetSingleResponse,
    PayRequestForPayerProjection,
    PayRequestId,
} from "@towni/common";
type ResponseType = GetSingleResponse<PayRequestForPayerProjection>;
const usePayRequestForPayerQuery = (
    payRequestId: PayRequestId | undefined,
): UseQueryResult<ResponseType> => {
    const fetchClient = useFetchClient();
    const fetchPayRequest: QueryFunction<ResponseType> = async ({ signal }) => {
        return fetchClient.get<ResponseType>({
            route: `/payments/pay-requests/${encodeURIComponent(
                payRequestId ?? "",
            )}/for-payer`,
            customConfig: {
                signal,
            },
        });
    };
    return useQuery({
        queryKey: ["pay-requests", payRequestId, "for-payer"],
        queryFn: fetchPayRequest,
        enabled: !!payRequestId,
        refetchOnWindowFocus: "always",
    });
};

export { usePayRequestForPayerQuery };
