import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    SetUserNameCommand,
    SetUserNameCommandFactoryParams,
    setUserNameCommandFactory,
} from "@towni/common";
import { apiFetchClient } from "../shared/fetch-client";

const useSetUserName = (options: {
    onSuccess: (data: void, input: SetUserNameCommandFactoryParams) => void;
    onError?: (error: Error, input: SetUserNameCommandFactoryParams) => void;
}) => {
    const queryClient = useQueryClient();
    const setPhoneNumberCommand = async (
        params: SetUserNameCommandFactoryParams,
    ) => {
        await apiFetchClient.post<SetUserNameCommand>({
            route: `/users/me/name`,
            body: setUserNameCommandFactory(params),
        });
    };

    return useMutation<void, Error, SetUserNameCommandFactoryParams>({
        mutationFn: setPhoneNumberCommand,
        onSuccess: options.onSuccess,

        onMutate: () => {
            void queryClient.cancelQueries({
                queryKey: ["me"],
            });
            const previous = queryClient.getQueryData(["me"]);
            void queryClient.invalidateQueries({
                queryKey: ["me"],
            });
            return previous;
        },
        onSettled: () => {
            // console.log("onSettled");
            void queryClient.invalidateQueries({
                queryKey: ["me"],
            });
        },
        onError: (_error, _command, previous) => {
            queryClient.setQueryData(["me"], previous);
            options?.onError?.(_error, _command);
        },
    });
};
export { useSetUserName };
