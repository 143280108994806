import { colorFactory, colorSetFactory, whiteColor } from "@towni/common";
import React, { useContext } from "react";

const lackoColors = {
    primary: colorSetFactory({
        main: colorFactory({ hue: 354, saturation: 1, light: 0.32 }), // #,
        dark: colorFactory({ hue: 354, saturation: 1, light: 0.22 }), // #
        light: colorFactory({ hue: 354, saturation: 0.58, light: 0.96 }), // #
        background: colorFactory({ hue: 354, saturation: 1, light: 0.32 }), // #
        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: colorFactory({ hue: 354, saturation: 1, light: 0.23 }), // #
        shadow: colorFactory({ hue: 354, saturation: 1, light: 0.8 }), // #
    }),
    lightGreenBackground: colorFactory({
        hue: 112,
        saturation: 0.18,
        light: 0.89,
    }),
    lightBrownBackground: colorFactory({
        hue: 21,
        saturation: 0.16,
        light: 0.94,
    }),
};
const LackoColorContext = React.createContext(lackoColors);

const useLackoColorContext = (): typeof lackoColors => {
    const context = useContext(LackoColorContext);
    if (!context) throw new Error("must be used within a LackoColorContext");

    return context;
};

export { useLackoColorContext, LackoColorContext, lackoColors };
