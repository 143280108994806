import { browserLogger } from "@@/settings";
import { SerializedStyles } from "@emotion/react";
import * as Sentry from "@sentry/react";
import {
    ImageUrlCreatorOptions,
    MetaImage,
    SECONDS,
    StorageItemImage,
} from "@towni/common";
import * as React from "react";
import { useState } from "react";
import { apiFetchClient } from "../fetch-client";
import { useImageUrl } from "../image-url";
import { useIsMountedRef } from "../use-is-mounted-ref";
import { ImageContainer } from "./image-container";
import { ImageSrc, createImageSrc } from "./image-src";

type Props = {
    readonly imageRef: MetaImage | StorageItemImage | undefined;
    /**
     * container width
     * @type {(number | string)}
     */
    readonly width?: number | string;
    /**
     * container height
     * @type {(number | string)}
     */
    readonly height?: number | string;
    /**
     * container min height
     * @type {(number | string)}
     */
    readonly minHeight?: number | string;
    /**
     * container min width
     * @type {(number | string)}
     */
    readonly minWidth?: number | string;
    /**
     * border radius
     * @type {(number | string)}
     */
    readonly radius?: number;
    readonly imageOptions?: ImageUrlCreatorOptions;
    readonly onClick?: () => void;
    readonly styling?: SerializedStyles;
    /**
     * defaults to 0
     * @type {SECONDS}
     */
    readonly fade?: SECONDS;
    readonly preload?: boolean;
    readonly fallback?: JSX.Element;
    readonly disableBlurhash?: boolean;
    readonly loadingSpinner?: JSX.Element;
    readonly onImageLoad?: (
        event: React.SyntheticEvent<HTMLImageElement>,
    ) => void;
};

const Picture = (props: Props) => {
    const getImageUrl = useImageUrl();
    const isMounted = useIsMountedRef();
    const [blurhash, setBlurhash] = useState(
        !props.disableBlurhash && typeof props.imageRef !== "string"
            ? props.imageRef?.blurhash
            : undefined,
    );

    // Blurhash
    React.useEffect(() => {
        if (blurhash) return;
        if (props.disableBlurhash) return;
        const blurhashUrl = getImageUrl(props.imageRef, {
            ...(props.imageOptions ?? {}),
            imageWidth: 50,
            imageHeight: undefined,
            format: "blurhash",
        });

        if (!blurhashUrl) return;

        apiFetchClient
            .get({
                route: blurhashUrl as string,
                customConfig: {
                    credentials: "omit",
                    headers: {
                        accept: "text/plain",
                    },
                },
                endpoint: "",
            })
            .then(hash => {
                if (!isMounted.current) return;
                setBlurhash(hash);
            })
            .catch(error => {
                browserLogger.error(error);
                Sentry.withScope(scope => {
                    scope.setExtra("note", "failed to load imgix blurhash");
                    Sentry.captureException(error);
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.imageRef,
        props.disableBlurhash,
        blurhash,
        props.imageOptions,
        getImageUrl,
    ]);

    const imageOptions: ImageUrlCreatorOptions = props.imageOptions
        ? {
              ...props.imageOptions,
              ...(props.imageOptions.imageWidth
                  ? {}
                  : {
                        imageWidth:
                            typeof props.width !== "string"
                                ? props.width
                                : undefined,
                    }),
          }
        : {
              imageWidth:
                  typeof props.width !== "string" ? props.width : undefined,
          };

    const urls = imageOptions?.format
        ? { [imageOptions.format]: getImageUrl(props.imageRef, imageOptions) }
        : {
              webp: getImageUrl(props.imageRef, {
                  ...imageOptions,
                  format: "webp",
              }),
              jpg: getImageUrl(props.imageRef, {
                  ...imageOptions,
                  format: "jpg",
              }),
          };

    if (!urls) return null;

    const title = props.imageRef
        ? "meta" in props.imageRef
            ? props.imageRef.meta.title
            : "title" in props.imageRef
              ? props.imageRef.title
              : ""
        : "";

    return (
        <ImageContainer
            sources={
                Object.entries(urls)
                    .map(([format, url]) => {
                        if (!url) return undefined;
                        return createImageSrc({
                            url,
                            mime: "image/" + format,
                        });
                    })
                    .filter(Boolean) as ImageSrc[]
            }
            onImageLoad={props.onImageLoad}
            fit={props.imageOptions?.fit}
            preload={props.preload}
            title={title}
            blurhash={blurhash}
            width={props.width}
            height={props.height}
            minWidth={props.minWidth}
            minHeight={props.minHeight}
            fallback={props.fallback}
            radius={props.radius}
            fade={props.fade}
            css={[
                {
                    display: "block",
                    borderRadius: props.radius,
                    label: "picture",
                },
                props.styling,
            ]}
            spinner={props.loadingSpinner}
        />
    );
};
export { Picture };
