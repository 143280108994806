import { AvailabilityVisualization } from "@@/backoffice/shared/availability-visualization";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { WeekAvailability, translation } from "@towni/common";

type Props = {
    availability: WeekAvailability | undefined;
    dividers?: {
        top?: JSX.Element;
        /**
         * defaults to `<VerticalDivider M />`
         * @type {JSX.Element}
         */
        bottom?: JSX.Element;
    };
};

const CartItemAvailability = ({ availability, dividers }: Props) => {
    const theme = useTheme();
    if (!availability) return null;
    return (
        <>
            {dividers?.top ? dividers.top : null}
            <FlexRow padding={{ leftRight: 20 }} shrink={0} fillParentWidth>
                <FlexRow
                    padding={{
                        leftRight: 15,
                        topBottom: 10,
                    }}
                    mainAxis="flex-start"
                    crossAxis="flex-start"
                    radius={10}
                    grow={1}
                    background={{
                        color: theme.colors.quaternary.light,
                    }}>
                    <TextBox size="XL" text="⚠️" shrink={0} grow={0} />
                    <HorizontalDivider S />
                    <FlexColumn grow={1} shrink={1}>
                        <TextBox
                            text={translation({
                                sv: "Notera när du väljer tid för upphämtning eller frakt att ovan produkt endast är tillgänglig:",
                                en: "Note when selecting a pick up time that the product above is only available for delivery at:",
                            })}
                            size="S"
                            renderMarkdown
                            grow={1}
                            shrink={1}
                            color={theme.colors.black.light30}
                        />
                        <VerticalDivider />
                        <AvailabilityVisualization
                            availability={availability}
                        />
                    </FlexColumn>
                </FlexRow>
            </FlexRow>
            {dividers?.bottom ? dividers.bottom : <VerticalDivider M />}
        </>
    );
};

export { CartItemAvailability };
