import { useTheme } from "@emotion/react";
import { TextBox, TextBoxProps } from "./text-box";

const PageTitle = (props: TextBoxProps) => {
    const theme = useTheme();
    return (
        <TextBox
            align="center"
            size={1.25}
            weight="900"
            color={theme.colors.default.text}
            {...props}
            shrink={1}
            grow={1}
        />
    );
};

export { PageTitle };
