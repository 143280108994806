import { useOrderSocketEventsForMe } from "@@/backoffice/for-providers/orders/use-orders-events-for-me";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useGiftCardsForOrder } from "@@/tickets/giftcards/use-giftcards";
import { TicketReceiptView } from "@@/tickets/ticket-receipt-top-view";
import { Order, OrderItem, translation } from "@towni/common";
import { motion } from "framer-motion";

type Props = {
    order: Order;
    orderItem: OrderItem;
};

const DigitalGiftcardReceipt_Ticket = (props: Props) => {
    const { order, orderItem: voucher } = props;
    const [tickets, ticketQuery] = useGiftCardsForOrder(order._id);

    // Listen to order events and if the order has changes then
    // refetch tickets in case anything has happened with them as well
    useOrderSocketEventsForMe({
        onOrderEvent: (event, _room) => {
            if (event.data.orderId === order._id) {
                ticketQuery.refetch();
            }
        },
    });

    return (
        <>
            <VerticalDivider S />

            <Conditional
                when={!!tickets.length}
                render={() => (
                    <motion.div
                        initial={{
                            height: 0,
                            opacity: 0,
                        }}
                        animate={{
                            height: "auto",
                            opacity: 1,
                        }}
                        exit={{ opacity: 0 }}>
                        <FlexRow>
                            <FlexColumn
                                grow={1}
                                shrink={0}
                                key={tickets[0]._id}
                                padding={{ leftRight: 10 }}>
                                <TicketReceiptView
                                    key={tickets[0]._id}
                                    title={tickets[0].meta.title}
                                    subtitle={tickets[0].meta.subtitle}
                                    backgroundImage={tickets[0].meta.images[0]}
                                    link={`/tickets/${order.orderGroupId}`}
                                    showText={translation({
                                        sv: "Visa presentkort",
                                        en: "Show gift card",
                                    })}
                                />
                            </FlexColumn>
                        </FlexRow>
                    </motion.div>
                )}
                else={() => (
                    <motion.div
                        initial={{
                            height: 0,
                            opacity: 0,
                        }}
                        animate={{
                            height: "auto",
                            opacity: 1,
                        }}
                        exit={{ opacity: 0 }}>
                        {voucher ? (
                            <FlexRow>
                                <FlexColumn
                                    grow={1}
                                    shrink={0}
                                    padding={{ leftRight: 10 }}>
                                    <TicketReceiptView
                                        title={voucher?.meta.title}
                                        subtitle={voucher?.meta.subtitle}
                                        backgroundImage={
                                            voucher?.meta.images[0]
                                        }
                                        showText={translation({
                                            sv: "Visa presentkort",
                                            en: "Show gift card",
                                        })}
                                    />
                                </FlexColumn>
                            </FlexRow>
                        ) : null}
                    </motion.div>
                )}
            />
        </>
    );
};

export { DigitalGiftcardReceipt_Ticket };
