import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontGridProductCard } from "@@/store-fronts/shared/store-front-grid-product-card";
import { ProductId, RemSize, unique } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { lackoColors } from "../lacko-color-context";

const LackoKidsTwoSection: FC = () => {
    const getProductId = useProductIdWithFallback();

    // TODO!: SWITCH TO PROD PRODUCT IDS
    const drakskattenId = React.useMemo(
        () => getProductId("product_4Tx8w0MkN2j7eTNF0jtO" as ProductId),
        []
    );
    const _creepylackoId = React.useMemo(
        () => getProductId("product_rau_KHV1MKvhrpT7Zuuu" as ProductId),
        []
    );
    const bugsafariId = React.useMemo(
        () => getProductId("product_rLf1-xdrJpBAaHT3_Bkb" as ProductId),
        []
    );
    const _escapefromprisonId = React.useMemo(
        () => getProductId("product_7fMc6qh2aCMAUbPPy7ZX" as ProductId),
        []
    );
    const productIds = [drakskattenId, bugsafariId];
    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea sectionAreaId="lacko-kids-2-section" padding={{ top: 0 }}>
            <SimpleGrid
                size={0.9 as RemSize}
                padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <StoreFrontGridProductCard
                            key={hasDuplicates ? index : id}
                            productId={id}
                            kickerColor={lackoColors.primary}
                            showPrice
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

export { LackoKidsTwoSection };
