import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { useTheme } from "@emotion/react";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";

const BauerMoreFromSection: FC = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const presentkort = React.useMemo(
        () => getProductId("product__7Y4H5vy7HJ1V-7-I5tXx" as ProductId),
        []
    );
    const littleLakeHouse = React.useMemo(
        () => getProductId("product__c61WrbRXk1ElwfQJsfWJ" as ProductId),
        []
    );

    const productIds = [presentkort, littleLakeHouse];

    return (
        <SectionArea
            title={translation({
                sv: "Mer från Bauergården",
                en: "",
            })}
            subtitle={translation({
                sv: "Kanske överraska någon med ett presentkort? Kolla in vårt övriga utbud.",
                en: "",
            })}
            sectionAreaId="bauer-more-from-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id}
                            productId={id}
                            aspectRatio={"1:1"}
                            contentTop={false}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={theme.colors.primary}
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Läs & Beställ",
        en: "Book",
    }),
    explore: translation({
        sv: "Se & Boka",
        en: "See & book",
    }),
};

const actionTexts = [text.book, text.explore];

export { BauerMoreFromSection };
