import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, css, useTheme } from "@emotion/react";
import { IsoAndUnixTimestamp, asDate } from "@towni/common";
import {
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isMonday,
    isSunday,
} from "date-fns";

type Props = {
    readonly key: string;
    readonly onToggle: () => void;
    readonly date: IsoAndUnixTimestamp;
    readonly status:
        | "SELECTED"
        | "FIRST_SELECTED"
        | "LAST_SELECTED"
        | "DISABLED"
        | "AVAILABLE"
        | "PASSED"
        | "BOOKED"
        | "SOLO_SELECTED"
        | "AVAILABLE_IN_TIMERANGE";
};

const circleSize = 44;

const DateSelect = (props: Props) => {
    const theme = useTheme();
    const { status, date, onToggle: toggle } = props;

    const alternateBackgroundColor =
        theme.colors.primary.main.value.withModifications({
            saturation: 0.6,
            light: 0.88,
        });

    // const selected = status === "SELECTED";
    const disabled = status === "DISABLED"; // || status === "BOOKED";
    // const available = status === "AVAILABLE";
    const passed = status === "PASSED"; //|| isBefore(asDate(date), startOfDay(new Date()));

    const styling = css`
        display: flex;
        width: ${circleSize}px;
        height: ${circleSize}px;
        justify-content: center;
        align-items: center;
        border-radius: 2000px;
        font-size: 1em; //4.6vw;
        background-color: transparent;
        color: ${theme.colors.default.text.value.asString};
        user-select: none;
    `;
    const disabledStyling = css`
        ${styling}
        border: none;
        opacity: 0.5;
    `;

    const bookedStyling = css`
        ${styling}
        border: none;
        opacity: 0.5;
        background-color: ${theme.colors.danger.asString};
    `;

    const selectedStyling = css`
        ${styling}
        border: 2px solid ${alternateBackgroundColor.asString};
        background-color: ${alternateBackgroundColor.asString};
        color: ${theme.colors.default.text.value.asString};
        border-radius: 2000px;
    `;

    const firstStyling = css`
        ${styling}
        border: 2px solid ${theme.colors.primary.asString};
        background-color: ${theme.colors.primary.asString};
        color: ${theme.colors.primary.text.value.asCssHsla};
        border-radius: 2000px;
        // border-radius: 2000px 0% 0% 2000px;
    `;

    const lastStyling = css`
        ${styling}
        border: 2px solid ${theme.colors.primary.asString};
        background-color: ${theme.colors.primary.asString};
        color: ${theme.colors.primary.text.value.asCssHsla};
        border-radius: 2000px;
        // border-radius: 0% 2000px 2000px 0%;
    `;

    const soloStyling = css`
        ${styling}
        border: 2px solid ${theme.colors.primary.asString};
        background-color: ${theme.colors.primary.asString};
        color: ${theme.colors.primary.text.value.asCssHsla};
        border-radius: 2000px;
    `;

    const availableStyling = css`
        ${styling}
        border: 2px solid ${theme.colors.primary.main.light40.asString};
        color: ${theme.colors.black.light20.asString};
    `;

    const availableInTimeRangeStyling = css`
        ${styling}
        border: 2px solid ${theme.colors.primary.main.light40.asString};
        color: ${theme.colors.primary.asString};
        // background-color: ${alternateBackgroundColor.asString};
    `;

    const passedStyling = css`
        ${styling}
        border: none;
        opacity: 0.2;
    `;

    let currentStyling = passedStyling;
    switch (status) {
        case "SELECTED":
            currentStyling = selectedStyling;
            break;
        case "FIRST_SELECTED":
            currentStyling = firstStyling;
            break;
        case "LAST_SELECTED":
            currentStyling = lastStyling;
            break;
        case "AVAILABLE":
            currentStyling = availableStyling;
            break;
        case "BOOKED":
            currentStyling = bookedStyling;
            break;
        case "DISABLED":
            currentStyling = disabledStyling;
            break;
        case "SOLO_SELECTED":
            currentStyling = soloStyling;
            break;
        case "AVAILABLE_IN_TIMERANGE":
            currentStyling = availableInTimeRangeStyling;
            break;
        default:
            currentStyling = passedStyling;
            break;
    }

    const firstOfMonth = isFirstDayOfMonth(asDate(props.date));
    const lastOfMonth = isLastDayOfMonth(asDate(props.date));
    const firstOfWeek = isMonday(asDate(props.date));
    const lastOfWeek = isSunday(asDate(props.date));

    return (
        <FlexRow
            mainAxis="flex-start"
            crossAxis="center"
            overflow="hidden"
            css={[
                {
                    width: "100%",
                    minWidth: circleSize + 2,
                    minHeight: circleSize,
                    borderRadius: (() => {
                        if (firstOfWeek || firstOfMonth) {
                            return "5px 0% 0% 5px";
                        }
                        if (lastOfWeek || lastOfMonth) {
                            return "0% 5px 5px 0%";
                        }
                        return undefined;
                    })(),
                },
                ((): Interpolation<AppTheme> => {
                    switch (status) {
                        case "FIRST_SELECTED":
                            return {
                                background: `linear-gradient(
                                90deg,
                                ${theme.colors.transparent.asString} 50%,
                                ${alternateBackgroundColor.asString} 50%
                            )`,
                            };
                        case "LAST_SELECTED":
                            return {
                                background: `linear-gradient(
                                90deg,
                                ${alternateBackgroundColor.asString} 50%,
                                ${theme.colors.transparent.asString} 50%
                            )`,
                            };
                        case "SELECTED":
                            return {
                                background: alternateBackgroundColor.asString,
                            };
                        default:
                            return undefined;
                    }
                })(),
            ]}>
            <FlexColumn
                styling={currentStyling}
                onClick={() => {
                    if (disabled) return;
                    if (passed) return;
                    toggle();
                }}>
                <a>
                    <div
                        css={{
                            position: "relative",
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: disabled || passed ? "cursor" : "pointer",
                            userSelect: "none",
                            lineHeight: "1em",
                            fontSize: "1em",
                            fontWeight: 600,
                        }}>
                        {disabled && (
                            <div
                                css={{
                                    position: "absolute",
                                    width: circleSize * 0.8,
                                    height: 2,
                                    backgroundColor:
                                        theme.colors.black.asString,
                                    transform: "rotate(135deg)",
                                    opacity: 0.5,
                                    zIndex: 10,
                                }}
                            />
                        )}
                        {asDate(date).getDate()}
                    </div>
                </a>
            </FlexColumn>
        </FlexRow>
    );
};

export { DateSelect };
