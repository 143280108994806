import { WithAlfred } from "@@/authentication/me/with-alfred";
import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { useLanguageContext } from "@@/settings/language-context";
import { css, useTheme } from "@emotion/react";
import { faEarthEurope } from "@fortawesome/pro-solid-svg-icons";
import {
    LanguageCode,
    Padding,
    RemSize,
    languageCodes,
    languageNameNative,
} from "@towni/common";
import { FlexRow } from "./flex-containers";
import { usePageBreakpoint } from "./responsiveness/use-page-breakpoint";

type Props = {
    languages?: LanguageCode[];
    forceGlobal?: boolean;
    override?: {
        language?: LanguageCode;
        setLanguage?: (language: LanguageCode) => void;
    };
    className?: string;
    iconColor?: string;
};

const LanguagePicker = (props: Props) => {
    const languageContext = useLanguageContext();
    const language = props.override?.language || languageContext.language;
    const availableLanguages =
        props.languages || languageContext.availableLanguages || languageCodes;
    const setLanguage =
        props.override?.setLanguage || languageContext.setLanguage;
    return (
        <SelectPicker<LanguageCode>
            data={availableLanguages.map(language => ({
                value: language,
                name: languageNameNative[language],
            }))}
            icon={faEarthEurope}
            selectedValue={language}
            onSelect={item => setLanguage(item.value)}
            className={props.className}
            iconColor={props.iconColor}
        />
    );
};

type MiniProps = Props & {
    fontSize?: RemSize | number;
    iconSize?: RemSize | number;
    dropShadow?: boolean;
    hideContainer?: boolean;
    padding?: Padding;
};

const LanguagePickerMini = (props: MiniProps) => {
    const theme = useTheme();
    const languageContext = useLanguageContext();
    const language = props.override?.language || languageContext.language;
    const setLanguage =
        props.override?.setLanguage || languageContext.setLanguage;
    const availableLanguages =
        props.languages || languageContext.availableLanguages || languageCodes;
    const fontSize = props.fontSize || 0.85;
    const iconSize = props.iconSize || fontSize;
    return (
        <SelectPicker<LanguageCode>
            data={availableLanguages.map(language => ({
                value: language,
                name: language.toLocaleUpperCase(),
            }))}
            icon={faEarthEurope}
            selectedValue={language}
            onSelect={item => setLanguage(item.value)}
            className={props.className}
            fontSize={fontSize}
            iconSize={iconSize}
            hideContainer={props.hideContainer}
            containerCss={
                props.dropShadow
                    ? css({
                          willChange: "filter",
                          filter: `drop-shadow(0px 0px 6px ${
                              theme.colors.default.text.withAlpha(0.8).asString
                          })`,
                      })
                    : undefined
            }
            padding={{
                topBottom: 7,
                leftRight: props.hideContainer ? 0 : 10,
                ...(props.padding ?? {}),
            }}
            iconColor={props.iconColor}
            css={{
                width:
                    fontSize < 0.9
                        ? props.hideContainer
                            ? 55
                            : 65
                        : props.hideContainer
                          ? 65
                          : 75,
            }}
        />
    );
};

const LanguagePickerToolbarItem = (props: MiniProps) => {
    // const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    switch (isDesktop) {
        case true:
            return (
                <WithAlfred css={{ padding: "2px 10px" }}>
                    <LanguagePickerMini
                        {...props}
                        // iconColor={theme.colors.default.text.asString}
                        hideContainer
                        // css={{ color: "#fff" }}
                        // iconColor={"#fff"}
                    />
                </WithAlfred>
            );
        default:
            return (
                <FlexRow fillParentWidth css={{ padding: 20 }}>
                    <WithAlfred css={{ width: "100%" }}>
                        <LanguagePicker
                            {...props}
                            // iconColor={theme.colors.default.text.asString}
                        />
                    </WithAlfred>
                </FlexRow>
            );
    }
};

export { LanguagePicker, LanguagePickerMini, LanguagePickerToolbarItem };
