import { useMe } from "@@/authentication/me/me-context";
import { ifApiErrorDontThrow } from "@@/authentication/phone/steps/if-api-error-dont-throw";
import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { isProbablyBot } from "@@/settings/bot-detection";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Inputs } from "@@/shared/form/standalones/inputs";
import { Icon } from "@@/shared/icons/icon";
import { Spacer } from "@@/shared/spacer";
import { ThreeDotSpinner } from "@@/shared/spinners/three-dot-spinner";
import { TextBox } from "@@/shared/text";
import { ModalTitle } from "@@/shared/text/modal-title";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import { PhoneNumber, translation } from "@towni/common";
import * as React from "react";
import { useEffect } from "react";
import {
    usePhoneSignInOrUpDispatch,
    usePhoneSignInOrUpState,
} from "../phone-sign-in-or-sign-in-context";
import { usePhoneSignInInitiateCommand } from "../use-phone-sign-in-initiate-command";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const PhoneRegistration = (_props: Props) => {
    const wizardState = useWizardState();
    const wizardDispatch = useWizardDispatch();
    const [me, { meQuery }] = useMe();

    const state = usePhoneSignInOrUpState();
    const [bdStall, setBdStall] = React.useState(true);
    const [actions] = usePhoneSignInOrUpDispatch();
    const phoneSignInInit = usePhoneSignInInitiateCommand();
    const disabled =
        !state.data.phoneNumber || phoneSignInInit.isPending || bdStall;
    React.useEffect(() => {
        if (me) {
            // Already signed in
            wizardDispatch.finish();
        }
    }, [me, wizardDispatch]);

    const isMountedRef = useIsMountedRef();
    useEffect(() => {
        if (!state.data.phoneNumber) {
            setBdStall(true);
            return;
        }
        let timeout: NodeJS.Timeout | null = null;
        isProbablyBot().then(result => {
            timeout = setTimeout(
                () => {
                    if (!isMountedRef.current) return;
                    setBdStall(false);
                },
                result ? 5000 : 0,
            );
        });
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [isMountedRef, state.data.phoneNumber]);

    const initiatePhoneAuthentication = async () => {
        if (disabled) return;
        await phoneSignInInit
            .mutateAsync({
                phoneNumber: state.data.phoneNumber,
                signUpId: state.data._id,
            })
            .catch(ifApiErrorDontThrow);
    };
    if (me) return null;
    return (
        <FlexColumn fillParent overflow="hidden">
            <FlexRow
                mainAxis="space-between"
                crossAxis="center"
                padding={{ leftRight: 20, topBottom: 20 }}>
                <Spacer basis={0.2} shrink={0}>
                    <Icon
                        onClick={
                            wizardState.isFirst
                                ? wizardDispatch.cancel
                                : () => wizardDispatch.goBack()
                        }
                        icon={!wizardState.isFirst ? faChevronLeft : faTimes}
                        size="L"
                        cursor="pointer"
                    />
                </Spacer>
                <FlexColumn basis={0.6} crossAxis="center">
                    <FlexRow crossAxis="center">
                        <ModalTitle
                            text={translation({
                                sv: "Logga in/",
                                en: "Sign in/",
                            })}
                        />
                        <ModalTitle
                            text={translation({
                                sv: "Registrering",
                                en: "Sign up",
                            })}
                        />
                    </FlexRow>
                </FlexColumn>
                <Spacer basis={0.2} shrink={0} />
            </FlexRow>
            <HorizontalLine />
            {meQuery.isPending ? (
                <>
                    <VerticalDivider XXL />
                    <FlexRow crossAxis="center" mainAxis="center">
                        <ThreeDotSpinner color="#ccc" />
                    </FlexRow>
                    <VerticalDivider XXL />
                </>
            ) : (
                <FlexColumn
                    overflowY="scroll"
                    crossAxis="center"
                    padding={{ top: 40, bottom: 20, leftRight: 20 }}>
                    <TextBox
                        text={translation({
                            sv: "Vi sätter igång",
                            en: "Let's get started",
                        })}
                        weight="700"
                        size="L"
                    />
                    <VerticalDivider />
                    <TextBox
                        align="center"
                        text={translation({
                            sv: "Ditt telefonnummer används för uppdateringar av bland annat orderinformation. Ingen reklam.",
                            en: "Your phone number is used for updates e.g. regarding your order information. No advertising.",
                        })}
                        weight="300"
                        size="ML"
                    />
                    <VerticalDivider XL />
                    <Inputs.PhoneNumberInput
                        fillParentWidth
                        placeholder={translation({
                            sv: "Telefonnummer",
                            en: "Phone number",
                        })}
                        onChange={({ phoneNumber, isValid }) => {
                            if (isValid) actions.setPhoneNumber(phoneNumber);
                            else {
                                actions.setUnvalidatedPhoneNumber(
                                    phoneNumber ?? ("" as PhoneNumber),
                                );
                                actions.setPhoneNumber("" as PhoneNumber);
                            }
                        }}
                        // onRawChange={actions.setUnvalidatedPhoneNumber}
                        // onChange={actions.setPhoneNumber}
                        initialPhoneNumber={state.data.unvalidatedPhoneNumber}
                    />

                    <VerticalDivider L />
                    <ButtonPrimary
                        fillParentWidth
                        onClick={initiatePhoneAuthentication}
                        disabled={disabled}
                        spin={
                            phoneSignInInit.isPending ||
                            (state.data.phoneNumber && bdStall)
                        }
                        padding={{ all: 15 }}>
                        <TextBox
                            text={translation({
                                sv: "Fortsätt",
                                en: "Continue",
                            })}
                            weight="700"
                        />
                    </ButtonPrimary>
                </FlexColumn>
            )}
        </FlexColumn>
    );
};

export { PhoneRegistration };
