import { apiFetchClient } from "@@/shared/fetch-client";
import {
    GetResponse,
    GetSingleResponse,
    Invitation,
    InvitationId,
    ProviderId,
} from "@towni/common";

const fetchInvitationsForProvider =
    (providerIdOrSlug: ProviderId | string | undefined) => async () => {
        if (!providerIdOrSlug) throw new Error("missing provider id");
        const data = await apiFetchClient.get<GetResponse<Invitation>>({
            route: `/invitations/for-provider/${encodeURIComponent(
                providerIdOrSlug
            )}`,
        });
        return data.items;
    };

const fetchInvitation =
    (invitationId: InvitationId | undefined) => async () => {
        if (!invitationId) throw new Error("missing invitationId id");
        const data = await apiFetchClient.get<GetSingleResponse<Invitation>>({
            route: `/invitations/${encodeURIComponent(invitationId)}`,
        });
        return data.item;
    };

export { fetchInvitationsForProvider, fetchInvitation };
