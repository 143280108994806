import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import {
    useIsTranslatableEmpty,
    useTranslate,
} from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    AspectRatio,
    BlockContent,
    PageBlock,
    addAlphaToHexColor,
    isHexColor,
} from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: [BlockContent, BlockContent, BlockContent];

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly height?: React.CSSProperties["height"];
    readonly className?: string;
};

type CardProps = {
    imageWidth: number;
    content: BlockContent;
    block: PageBlock;
    defaultTextShadow: string;
    defaultBorderRadius: number;
};

const _CardMobile_Landscape = (props: CardProps) => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const providerId = usePageViewContext(context => context.providerId);
    const {
        imageWidth: maxWidth,
        defaultTextShadow,
        defaultBorderRadius,
    } = props;
    const theme = useTheme();
    const translate = useTranslate();

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <FlexColumn css={props.content.inactive ? inactiveCss : undefined}>
                <GridCardContainer aspectRatio={"16:9"}>
                    <FlexColumn
                        fillParent
                        mainAxis="flex-end"
                        css={{
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-2-small",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: maxWidth,
                            },
                        }}>
                        <FlexColumn
                            fillParentWidth
                            padding={{ all: 20 }}
                            background={{ linearGradient: darkeningGradient }}>
                            <TextBox
                                text={props.content.title}
                                lineClamp={2}
                                weight="800"
                                size={1.5}
                                color={theme.colors.white}
                                css={{
                                    textShadow: defaultTextShadow,
                                }}
                            />
                            <Conditional
                                when={
                                    !isTranslatableEmpty(props.content.subtitle)
                                }>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.subtitle}
                                    lineClamp={2}
                                    weight="400"
                                    size={0.9}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                        </FlexColumn>
                    </FlexColumn>
                </GridCardContainer>
            </FlexColumn>
        </Link>
    );
};

const _CardMobile = (props: CardProps & { aspectRatio: AspectRatio }) => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const providerId = usePageViewContext(context => context.providerId);
    const { imageWidth: maxWidth, block, defaultBorderRadius } = props;
    const theme = useTheme();
    const translate = useTranslate();
    const textColor = block.textColor ?? theme.colors.default.text;
    const textColorWithAlpha = isHexColor(textColor)
        ? addAlphaToHexColor(textColor, 0.5)
        : textColor.withAlpha(0.5);

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer", overflow: "hidden" }}>
            <FlexColumn css={props.content.inactive ? inactiveCss : undefined}>
                <GridCardContainer aspectRatio={props.aspectRatio}>
                    <FlexColumn
                        fillParent
                        css={{
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-2-small",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: maxWidth,
                            },
                        }}
                    />
                </GridCardContainer>
                <FlexColumn padding={{ top: 8, leftRight: 2 }}>
                    <TextBox
                        text={props.content.title}
                        lineClamp={3}
                        weight="800"
                        size={1}
                        color={textColor}
                    />
                    <Conditional
                        when={!isTranslatableEmpty(props.content.subtitle)}>
                        <VerticalDivider XXS />
                        <TextBox
                            text={props.content.subtitle}
                            lineClamp={2}
                            weight="400"
                            size={0.75}
                            color={textColorWithAlpha}
                        />
                    </Conditional>
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

const _CardDesktop = (props: CardProps) => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const providerId = usePageViewContext(context => context.providerId);
    const {
        imageWidth: maxWidth,
        block,
        defaultTextShadow,
        defaultBorderRadius,
    } = props;
    const theme = useTheme();
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const translate = useTranslate();

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <GridCardContainer
                aspectRatio={isSmall ? "4:3" : "1:1"}
                css={props.content.inactive ? inactiveCss : undefined}>
                <FlexColumn
                    fillParent
                    css={{
                        borderRadius: defaultBorderRadius,
                        position: "relative",
                        overflow: "hidden",
                        label: "content-grid-card-2-small",
                    }}
                    background={{
                        fit: "COVER",
                        imageReference: props.content.poster,
                        color:
                            props.content.backgroundColor ??
                            theme.colors.default.background.asString,
                        imageSize: {
                            imageWidth: maxWidth,
                        },
                    }}>
                    <FlexColumn
                        fillParent
                        crossAxis="flex-start"
                        mainAxis="space-between"
                        padding={{ all: 20 }}
                        background={{ linearGradient: darkeningGradient }}>
                        <FlexRow fillParentWidth mainAxis="flex-end">
                            <StoreFrontCardPill
                                text={props.content.actionText}
                                accentColor={
                                    "accentColor" in props.content &&
                                    props.content.accentColor
                                        ? props.content.accentColor
                                        : block.accentColor
                                }
                            />
                        </FlexRow>
                        <FlexColumn>
                            <TextBox
                                text={props.content.title}
                                lineClamp={2}
                                weight="800"
                                size={isSmall ? 1.3 : 1.2}
                                color={
                                    "textColor" in props.content &&
                                    props.content.textColor
                                        ? props.content.textColor
                                        : theme.colors.white
                                }
                                css={{
                                    textShadow: defaultTextShadow,
                                }}
                            />
                            <Conditional
                                when={
                                    isSmall &&
                                    !isTranslatableEmpty(props.content.subtitle)
                                }>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.subtitle}
                                    lineClamp={2}
                                    weight="400"
                                    size={isSmall ? 0.9 : 0.9}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
            </GridCardContainer>
        </Link>
    );
};

const ContentGrid_3_Small = (props: Props) => {
    const { width, height } = props;
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const {
        pageInnerWidth: maxWidth,
        defaultGridGap,
        defaultTextShadow,
        defaultBorderRadius,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();

    const containerMaxWidth = maxWidth * 0.85;

    return (
        <>
            <Conditional when={isSmall}>
                <LayoutGrid
                    gap={defaultGridGap}
                    // padding={{ all: gridCardGap }}
                    gridTemplateColumns={"1fr"}
                    className={props.className}
                    css={{
                        maxWidth: containerMaxWidth,
                        paddingLeft: isSmall ? 20 : 0,
                        paddingRight: isSmall ? 20 : 0,
                        width: width ?? "100%",
                        height: height ?? "100%",
                        label: "content_grid_3_small_mobile_1x",
                    }}>
                    <_CardMobile_Landscape
                        imageWidth={500}
                        content={props.content[0]}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                    />
                </LayoutGrid>
                <VerticalDivider M />
                <LayoutGrid
                    gap={defaultGridGap}
                    // padding={{ all: gridCardGap }}
                    gridTemplateColumns={"1fr 1fr"}
                    className={props.className}
                    css={{
                        maxWidth: containerMaxWidth,
                        paddingLeft: isSmall ? 20 : 0,
                        paddingRight: isSmall ? 20 : 0,
                        width: width ?? "100%",
                        height: height ?? "100%",
                        label: "content_grid_3_small_mobile_2x",
                    }}>
                    <_CardMobile
                        aspectRatio="3:4"
                        imageWidth={250}
                        content={props.content[1]}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                    />
                    <_CardMobile
                        aspectRatio="3:4"
                        imageWidth={250}
                        content={props.content[2]}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                    />
                </LayoutGrid>
            </Conditional>
            <Conditional when={!isSmall}>
                <LayoutGrid
                    gap={defaultGridGap}
                    // padding={{ all: gridCardGap }}
                    gridTemplateColumns={"1fr 1fr 1fr"}
                    className={props.className}
                    css={{
                        maxWidth: containerMaxWidth,
                        paddingLeft: isSmall ? 20 : 0,
                        paddingRight: isSmall ? 20 : 0,
                        width: width ?? "100%",
                        height: height ?? "100%",
                        label: "content_grid_3_small_dektop",
                    }}>
                    <_CardDesktop
                        imageWidth={500}
                        content={props.content[0]}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                    />
                    <_CardDesktop
                        imageWidth={500}
                        content={props.content[1]}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                    />
                    <_CardDesktop
                        imageWidth={500}
                        content={props.content[2]}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                    />
                </LayoutGrid>
            </Conditional>
        </>
    );
};

export { ContentGrid_3_Small };
