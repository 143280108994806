import { SoundName } from "@@/audio/audio-context";
import { useAudio } from "@@/audio/use-audio";
import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { LinkTo } from "@@/shared/text/link-to";
import { css, useTheme } from "@emotion/react";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { translation } from "@towni/common";
import { useState } from "react";

type Props = {
    intialShow?: boolean;
};

const NotificationSoundSettings = (props: Props) => {
    const [audio] = useAudio();
    const theme = useTheme();
    const [show, setShow] = useState(props.intialShow ?? false);

    if (!show)
        return (
            <FlexColumn padding={{ leftRight: 20 }}>
                <FlexRow
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="center">
                    <FlexRow
                        onClick={() => {
                            audio.playSound();
                        }}>
                        <Icon
                            icon={audio.icon}
                            color={theme.colors.black.light65}
                            padding={{ top: 3 }}
                            fixedWidth
                        />
                        <HorizontalDivider S />
                        <TextBox
                            text={translation({
                                sv: "Notifikationsljud",
                                en: "Notification sound",
                            })}
                            color={theme.colors.default.text}
                        />
                        <HorizontalDivider S />
                    </FlexRow>
                    <FlexRow onClick={() => setShow(true)}>
                        <Icon
                            icon={faEdit}
                            size="XS"
                            color={theme.colors.default.text.value.withAlpha(
                                0.3,
                            )}
                        />
                        <HorizontalDivider XXS />
                        <TextBox
                            text="Ändra"
                            size="XS"
                            color={theme.colors.default.text.value.withAlpha(
                                0.5,
                            )}
                        />
                    </FlexRow>
                </FlexRow>
            </FlexColumn>
        );

    return (
        <FlexColumn>
            <FlexRow
                key="sound"
                fillParentWidth
                crossAxis="center"
                padding={{ leftRight: 20 }}
                mainAxis="stretch"
                onClick={() => {
                    audio.playSound();
                }}>
                <FlexRow>
                    <Icon
                        icon={audio.icon}
                        fixedWidth
                        color={theme.colors.black.light65}
                        padding={{ top: 3 }}
                        style={{
                            minWidth: 18,
                            textAlign: "left",
                        }}
                    />
                    <HorizontalDivider S />
                    <TextBox
                        text={translation({
                            sv: "Notifikationsljud",
                            en: "Notification sound",
                        })}
                        color={theme.colors.default.text}
                    />
                    {/* <HorizontalDivider S />
                    <Label
                        text="BETA"
                        colors={{
                            background: theme.colors.primary,
                            text: theme.colors.primary.text,
                        }}
                        styles={css({
                            opacity: 0.6,
                        })}
                    /> */}
                </FlexRow>
                {/* <FlexRow mainAxis="flex-end">
                    <ToggleIconButton
                        toggle={() => audio.mute(!audio.isMuted)}
                        toggled={audio.isMuted}
                    />
                </FlexRow> */}
            </FlexRow>
            <FlexRow padding={{ leftRight: 20, top: 10 }}>
                <SelectPicker<SoundName>
                    data={audio.sounds.map(sound => ({
                        name: sound.title,
                        value: sound.key,
                    }))}
                    selectedValue={audio.selectedSound}
                    onSelect={selection => {
                        audio.selectSound(selection.value);
                        audio.playSound(selection.value);
                    }}
                />
            </FlexRow>
            <FlexColumn padding={{ top: 10, leftRight: 22 }}>
                <TextBox
                    size="XS"
                    color={theme.colors.default.text.value.withAlpha(0.5)}
                    text={translation({
                        sv: `Ljudnotifikationer kräver att du som användare har interagerat med applikationen för att fungera. Efter sidladdning är därför notifikationsljud alltid avstängt men aktiveras automatiskt när du scrollar, trycker eller klickar i applikationen. Hör du inget ändå, kontrollera att din enhet inte är satt i tyst läge.`,
                        en: `Sound notifications require you as a user to have interacted with the application to work. After page reload, therefore, the notification sound is always turned off but is automatically activated when you scroll, press or click anywhere in the application. If you can't hear anything, make sure your device is not set to silent mode.`,
                    })}
                />
            </FlexColumn>
            <FlexRow padding={{ top: 18, leftRight: 22 }}>
                <TextBox
                    size={0.5}
                    case="UPPERCASE"
                    color={theme.colors.default.text.value.withAlpha(0.3)}
                    text={translation({
                        en: `Some Sounds from `,
                        sv: `Vissa ljud från `,
                    })}
                    italic
                />
                <HorizontalDivider XXS />
                <LinkTo
                    to="https://www.zapsplat.com/"
                    target="Z"
                    color={theme.colors.default.text.value.withAlpha(0.35)}
                    styling={css`
                        font-style: italic;
                        text-transform: uppercase;
                        font-size: 0.5rem;
                    `}>
                    ZapSplat
                </LinkTo>
            </FlexRow>
        </FlexColumn>
    );
};

export { NotificationSoundSettings };
