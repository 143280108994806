import { useWizardDispatch } from "@@/pages/wizard-context";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { initiateStripePaymentCommandFactory } from "@towni/common";
import * as React from "react";
import { usePaymentDetailsContext } from "../payment-details.context";
import {
    useStripePaymentDispatch,
    useStripePaymentState,
} from "./stripe-payment.context";

type Props = {
    onClick: () => void;
    onCancel?: () => void;
};

const StripePaymentRequestButton = (props: Props) => {
    const theme = useTheme();
    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails
    );
    const { intent, paymentRequest, status, error } = useStripePaymentState();
    const { createPaymentIntent } = useStripePaymentDispatch();
    const { finish } = useWizardDispatch();

    React.useEffect(() => {
        if (status === "pr_success") finish();
        if (status === "pr_error") props.onCancel?.();
    }, [status]);

    React.useEffect(() => {
        // console.log("APPLE_PAY", intent, status);
        if (!intent?.intentSecret && status !== "creating_intent") {
            if (!paymentDetails?.orderGroupId) {
                throw new Error("missing order group id");
            }
            // console.log("APPLE_PAY", intent, status);
            createPaymentIntent(
                initiateStripePaymentCommandFactory({
                    orderGroupId: paymentDetails.orderGroupId,
                    delay: true,
                }),
                "APPLE_PAY"
            );
        }
    }, [intent?.intentSecret, status]);

    // console.log("#######", { payment, paymentRequest, intent, status, error });

    if (paymentRequest && intent) {
        return (
            <>
                <PaymentRequestButtonElement
                    onClick={props.onClick}
                    options={{
                        paymentRequest,
                        style: {
                            paymentRequestButton: {
                                theme: "dark",
                                type: "default",
                                height: `${55}px`,
                            },
                        },
                    }}
                />
                {status === "pr_error" ? (
                    <>
                        <VerticalDivider XXS />
                        <TextBox
                            text={error ?? "Betalningen misslyckades"}
                            color={theme.colors.danger}
                        />
                    </>
                ) : null}
                <VerticalDivider />
            </>
        );
    }

    return null;
};

export { StripePaymentRequestButton };
