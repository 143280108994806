import { useMe } from "@@/authentication/me/me-context";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useToast } from "@@/toasts/context/toast-context";
import {
    UserAddress,
    anonymousEmptyUserAddressFactory,
    anonymousUserAddressZodSchema,
    nowIsoAndUnix,
    translation,
    userAddressIdFactory,
    userAddressZodSchema,
} from "@towni/common";
import { FC, useEffect, useState } from "react";
import { ZodError } from "zod";
import { AddressPickerGoogleAutoCompleteModal } from "./address-picker-google-auto-complete-modal";
import { AddressPickerManual } from "./address-picker-manual";

type Props = {
    initialAddress?: UserAddress;
    modalId: ModalId;
    save: (address: UserAddress) => void;
    onHide?: () => void;
    hideAddressNameField?: boolean;
};
const AddressPickerModal: FC<Props> = ({
    modalId,
    initialAddress,
    ...props
}) => {
    const [me] = useMe();
    const toast = useToast();
    const { hide } = useModalActions(modalId);
    const [isEdit] = useState(!!initialAddress);

    const [userAddress, setUserAddress] = useState<Partial<UserAddress>>(
        initialAddress ?? anonymousEmptyUserAddressFactory({ userId: me?._id }),
    );
    const [pickerType, setPickerType] = useState(
        userAddress.inputType ?? "GOOGLE_AUTO_COMPLATE",
    );

    useEffect(() => {
        setUserAddress(old => ({ ...old, inputType: pickerType }));
    }, [pickerType]);

    const saveAddress = () => {
        const finalAddress = {
            ...userAddress,
            address: {
                ...(userAddress.address ?? {}),
                // Add country if it hasn't been set
                country: userAddress.address?.country?.trim() || "Sverige",
            },
            deliveryInstructions: userAddress.deliveryInstructions ?? "",
            details: userAddress.details ?? "",
            name: userAddress.name ?? "",
            inputType: userAddress.inputType ?? pickerType,
            _id: userAddress._id ?? userAddressIdFactory(),
            _createdAt: userAddress._createdAt ?? nowIsoAndUnix(),
            _status: userAddress._status ?? "ACTIVE",
            _type: "USERADDRESS",
            userId: me?._id,
        } as UserAddress;
        try {
            const _validAddress = me
                ? userAddressZodSchema.safeParse(finalAddress).success
                : anonymousUserAddressZodSchema.safeParse(finalAddress).success;
            props.save(finalAddress as UserAddress);
            hide();
            return;
        } catch (error) {
            if (error instanceof ZodError) {
                toast.warning({
                    message: error.message,
                    title: translation({
                        sv: "Komplettera adress",
                        en: "Complete address",
                    }),
                });
                return;
            }
            throw error;
        }
    };

    const updateAddress = (update: Partial<UserAddress>) => {
        setUserAddress(update);
    };

    useEffect(() => {
        // Update userId on change
        setUserAddress(value => ({ ...value, userId: me?._id }));
    }, [me?._id]);

    return (
        <Modal height={"full"} modalId={modalId} onHide={props.onHide}>
            {pickerType === "GOOGLE_AUTO_COMPLATE" ? (
                <AddressPickerGoogleAutoCompleteModal
                    current={userAddress}
                    update={updateAddress}
                    modalId={modalId}
                    save={saveAddress}
                    isEdit={isEdit}
                    noAddressFound={() => setPickerType("MANUAL")}
                    hideAddressNameField={props.hideAddressNameField}
                />
            ) : null}
            {pickerType === "MANUAL" ? (
                <AddressPickerManual
                    current={userAddress}
                    update={updateAddress}
                    modalId={modalId}
                    save={saveAddress}
                    goBack={() =>
                        initialAddress?.inputType === "MANUAL"
                            ? hide()
                            : setPickerType("GOOGLE_AUTO_COMPLATE")
                    }
                    isEdit={isEdit}
                    hideAddressNameField={props.hideAddressNameField}
                />
            ) : null}
        </Modal>
    );
};

export { AddressPickerModal };
