import { useOptionalGroups } from "@@/optional-groups/state/optional-group-fetchers";
import { useProduct } from "@@/products/state/queries/product/use-product";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { isProductId, ProductId, productIdZodSchema } from "@towni/common";
import { useParams } from "react-router-dom";

const useVoucherData = (params?: { voucherId?: ProductId }) => {
    const urlParams = useParams<{
        voucherId?: ProductId;
        productId?: ProductId;
    }>();

    const voucherId = productIdZodSchema.parse(
        params?.voucherId ?? urlParams.voucherId ?? urlParams.productId,
    );
    if (!isProductId(voucherId))
        throw new Error(`Voucher id missing; ${voucherId}`);

    const [voucher, voucherQuery] = useProduct(voucherId);
    const [provider, providerQuery] = useProvider(voucher?.providerId);
    const [optionalGroups, optionalGroupsQuery] = useOptionalGroups(
        voucher?.optionalGroupIds,
    );

    // && !isVoucher(voucher))
    // if (!voucherQuery.isPending)
    //     throw new Error(`Product is not a voucher; ${voucherId}`);

    return {
        voucher,
        provider,
        optionalGroups,
        hasError:
            voucherQuery.isError ||
            providerQuery.isError ||
            optionalGroupsQuery.isError,
        errors: {
            voucherError: voucherQuery.error,
            providerError: providerQuery.error,
            optionalGroupsError: optionalGroupsQuery.error,
            hasVoucherError: voucherQuery.isError,
            hasProviderError: providerQuery.isError,
        },
        isLoading:
            voucherQuery.isPending ||
            providerQuery.isPending ||
            optionalGroupsQuery.isPending,
        loading: {
            voucherIsLoading: voucherQuery.isPending,
            providerIsLoading: providerQuery.isPending,
            optionalGroupsIsLoading: optionalGroupsQuery.isPending,
        },
        isStale:
            voucherQuery.isStale ||
            providerQuery.isStale ||
            optionalGroupsQuery.isStale,
        stale: {
            voucherIsStale: voucherQuery.isStale,
            providerIsStale: providerQuery.isStale,
            optionalGroupsIsStale: optionalGroupsQuery.isStale,
        },
    };
};

export { useVoucherData };
