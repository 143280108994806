import {
    ButtonWhite,
    CustomizedButtonProps,
} from "@@/shared/buttons_v2/button-gray";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Translatable } from "@towni/common";
import { useRef } from "react";
import { Except } from "type-fest";
import { TextBox } from "../text-box";

type ParagraphButton = {
    _type: "PARAGRAPH_BUTTON";
    icon?: IconDefinition;
    text?: Translatable;
    fixedWidth?: boolean;
    faIconProps?: Except<FontAwesomeIconProps, "icon" | "fixedWidth">;
    onClick?: (event: React.MouseEvent) => void;
    iconCss?: Interpolation<AppTheme>;
    css?: Interpolation<AppTheme>;
    contentContainerCss?: Interpolation<AppTheme>;
    button?: (props: CustomizedButtonProps) => JSX.Element;
    spin?: boolean;
    disabled?: boolean;
    textCss?: Interpolation<AppTheme>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphButton = (item: any): item is ParagraphButton => {
    return item?._type === "PARAGRAPH_BUTTON";
};

const paragraphButtonFactory = (
    params: Except<ParagraphButton, "_type">,
): ParagraphButton => {
    return {
        _type: "PARAGRAPH_BUTTON",
        ...params,
    };
};

const ParagraphButton = (props: {
    params: ParagraphButton;
    className?: string;
}) => {
    const theme = useTheme();
    const linkColor = theme.colors.primary.asString;
    const onClick = props.params.disabled ? undefined : props.params.onClick;
    const styles = [
        ...(onClick
            ? [
                  {
                      color: linkColor,
                      ":hover,:visited,:focus,:active": {
                          color: linkColor,
                          textDecoration: "none",
                      },
                      ":hover": {
                          color: theme.colors.secondary.asString,
                          textDecoration: "underline",
                      },
                      cursor: "pointer",
                  },
              ]
            : []),
        props.params.iconCss,
    ];

    const Button = useRef(props.params.button ?? ButtonWhite);

    return (
        <Button.current
            css={[
                {
                    display: "inline-block",
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 3,
                    paddingBottom: 3,
                    marginTop: 8,
                    marginBottom: 4,
                },
                props.params.css,
            ]}
            onClick={onClick}
            contentContainerCss={props.params.contentContainerCss}
            disabled={props.params.disabled}
            spin={props.params.spin}>
            <FlexRow crossAxis="center">
                {props.params.icon ? (
                    <>
                        <FontAwesomeIcon
                            onClick={onClick}
                            {...(props.params.faIconProps ?? {})}
                            icon={
                                props.params.spin
                                    ? faSpinnerThird
                                    : props.params.icon
                            }
                            spin={!!props.params.spin}
                            css={styles}
                            fixedWidth={props.params.fixedWidth}
                        />
                        <HorizontalDivider />
                    </>
                ) : null}
                <TextBox text={props.params.text} css={props.params.textCss} />
            </FlexRow>
        </Button.current>
    );
};

export { ParagraphButton, isParagraphButton, paragraphButtonFactory };
