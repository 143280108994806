import { useWizardDispatch } from "@@/pages/wizard-context";
import { Button } from "@@/shared/buttons";
import { createAppUrl } from "@@/shared/create-app-url";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { translation } from "@towni/common";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { usePaymentDetailsContext } from "../payment-details.context";
import { SwishHzTextLogo } from "./swish-hz-text-logo";
import { SwishMessage } from "./swish-status-message";
import { createSwishOnDeviceUrl, useSwishPayment } from "./swish.context";

const SwishPaymentOnDevice = () => {
    const theme = useTheme();
    const location = useLocation();

    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails,
    );
    const [state, _actions] = useSwishPayment();
    const { finish } = useWizardDispatch();
    const [buttonHasBeenPressed, setButtonHasBeenPressed] =
        React.useState(false);

    React.useEffect(() => {
        if (!state.swishPayment) return;
        if (state.swishPayment.status === "PAID") {
            finish();
            return;
        }
    }, [state.swishPayment, finish]);

    return (
        <FlexColumn padding={{ all: 20 }} fillParentWidth crossAxis="center">
            {!state.swishPayment ? (
                <>
                    <TextBox
                        text={translation({
                            sv: "Initierar betalning",
                            en: "Initiating payment",
                        })}
                    />
                    <VerticalDivider XL />
                    <CircleSpinner size="XL" />
                    <VerticalDivider XL />
                </>
            ) : null}
            {
                // PENDING
                state.swishPayment?.status === "PENDING" ? (
                    <>
                        <TextBox
                            text={translation({
                                sv: `Slutför i swish-appen på denna enhet.`,
                                en: `Fullfil payment in the swish app on this device.`,
                            })}
                            align="center"
                            color={theme.colors.default.text.light50}
                            weight="400"
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={translation({
                                sv: `Kom sedan tillbaka hit.`,
                                en: `Then come back here.`,
                            })}
                            align="center"
                            color={theme.colors.default.text.light60}
                            weight="400"
                            size={0.9}
                        />
                        <VerticalDivider M />

                        <FlexRow fillParentWidth padding={{ leftRight: 20 }}>
                            <Button
                                buttonTheme="primary"
                                styling={css`
                                    width: 100%;
                                    padding-top: 12px;
                                    padding-bottom: 12px;
                                    border: none;
                                    background: linear-gradient(
                                        300deg,
                                        ${theme.colors.primary.asString} -7.06%,
                                        ${theme.colors.primary.main.light40
                                                .asString}
                                            100.35%
                                    );
                                `}
                                onClick={() => {
                                    if (!paymentDetails?.orderGroupId)
                                        throw new Error(
                                            "No order group id available for payment",
                                        );
                                    if (!state.swishPayment?.paymentToken)
                                        throw new Error(
                                            "No payment token received from swish",
                                        );

                                    const swishOnDeviceUrl =
                                        createSwishOnDeviceUrl(
                                            state.swishPayment.paymentToken,
                                            createAppUrl(
                                                `/swish-check/${
                                                    paymentDetails.orderGroupId
                                                }/${
                                                    state.paymentUUID
                                                }?back=${encodeURI(
                                                    location.pathname,
                                                )}`,
                                            ),
                                        );
                                    setButtonHasBeenPressed(true);
                                    window.open(swishOnDeviceUrl, "_blank");
                                }}>
                                <FlexRow
                                    fillParentWidth
                                    crossAxis="center"
                                    mainAxis="space-between">
                                    <TextBox
                                        text={translation({
                                            sv: "Öppna swish-appen",
                                            en: "Open swish app",
                                        })}
                                        grow={1}
                                        shrink={1}
                                    />
                                    <HorizontalDivider />
                                    {buttonHasBeenPressed ? (
                                        <CircleSpinner
                                            size="XL"
                                            opacity={
                                                buttonHasBeenPressed ? 1 : 0
                                            }
                                            color={theme.colors.primary.text}
                                        />
                                    ) : (
                                        <SwishHzTextLogo />
                                    )}
                                </FlexRow>
                            </Button>
                        </FlexRow>
                        <VerticalDivider XL />
                        <TextBox
                            text={translation({
                                sv: `Har denna enhet inte Swish? Gå tillbaka ett steg och välj istället "swish på annan enhet".`,
                                en: `Does this device not have Swish app installed? Go back one step and choose "swish on another device" instead.`,
                            })}
                            color={theme.colors.default.text.light60}
                            size={0.9}
                            padding={{ leftRight: 20 }}
                            align="center"
                        />
                        <VerticalDivider S />
                    </>
                ) : null
            }
            <SwishMessage swishPayment={state.swishPayment} />
        </FlexColumn>
    );
};

export { SwishPaymentOnDevice };
