import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { SelectBox } from "@@/shared/select-box";
import { TextBox } from "@@/shared/text";
import { usePriceFormatter } from "@@/translations/use-price-formatter";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { css, useTheme } from "@emotion/react";
import { Sku, Translatable, acquireType } from "@towni/common";

const selectedRowStyle = css`
    background-color: #e8fbf6;
`;

type Props = {
    readonly sku: Sku;
    readonly productTitle: Translatable;
    readonly selected: boolean;
    readonly select: () => void;
};

const BuyableSkuRow = (props: Props) => {
    const theme = useTheme();
    const unitTranslate = useUnitTranslator();
    const priceFormatter = usePriceFormatter();
    const { sku, selected, select } = props;
    if (sku.acquire._type !== acquireType.BUYABLE)
        return (
            <div
                style={{
                    padding: "10px 15px",
                    backgroundColor: "#f5f5dc",
                    color: "black",
                }}>
                🚨 Ej köpbar
            </div>
        );
    const { price, amount } = sku.acquire;
    const unitName = unitTranslate(amount.value, amount.unit);
    return (
        <FlexRow
            key={sku._id}
            onClick={select}
            fillParentWidth
            padding={{ leftRight: 20, topBottom: 15 }}
            mainAxis="space-between"
            crossAxis="center"
            styling={(selected && selectedRowStyle) || undefined}>
            <FlexRow>
                <SelectBox shape="CIRCLE" selected={selected} size="L" />
                <HorizontalDivider M />
                <TextBox text={sku.meta.title || props.productTitle} />
            </FlexRow>
            <HorizontalDivider M />
            <FlexRow>
                <TextBox
                    text={`${priceFormatter(price)} ${
                        (amount && `${amount}/${unitName}`) || ""
                    }`}
                    color={theme.colors.default.text}
                    css={{ fontWeight: 300 }}
                />
            </FlexRow>
        </FlexRow>
    );
};

export { BuyableSkuRow };
