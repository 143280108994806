import { Modal } from "@@/modals/modal";
import { useModal } from "@@/modals/use-modal";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { PillButton } from "@@/shared/buttons/pill-button";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { ModalTitle } from "@@/shared/text/modal-title";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    ProviderId,
    enTranslator,
    svTranslator,
    translation,
} from "@towni/common";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { calculateProviderStatus } from "./calculate-provider-status";

type Props = {
    providerId: ProviderId | undefined;
};

const ProviderPauseCheckModal = ({ providerId }: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const navigate = useNavigate();
    const [provider] = useProvider(providerId);

    const { formatDistanceFromNow } = useDateTimeFormatter();
    const [
        {
            show: showProviderPauseCheckModal,
            hide: hideProviderPauseCheckModal,
            exists: modalExists,
        },
        modalId,
    ] = useModal("provider_pause_check_modal__");

    const providerStatus = calculateProviderStatus(provider);

    useEffect(() => {
        if (provider) {
            if (!provider?.options.paused?.until) {
                hideProviderPauseCheckModal();
            }

            if (providerStatus === "PAUSED") {
                showProviderPauseCheckModal();
            }
            if (providerStatus === "DOES_NOT_EXIST") {
                navigate("/");
            }
            // return;
        }
    }, [
        modalExists(),
        provider,
        provider?.options.paused?.until,
        providerStatus,
    ]);

    const isPaused = providerStatus === "PAUSED";
    const comingSoon: boolean =
        isPaused &&
        translate(provider?.options.paused?.reasonTitle, "sv")
            .toLowerCase()
            .includes("kommer snart");

    const pauseReasonTitle = translate(provider?.options.paused?.reasonTitle);
    const pauseReason = translate(provider?.options.paused?.reason);

    return (
        <Modal modalId={modalId} backgroundCloseDisabled={true}>
            <FlexColumn
                crossAxis="center"
                styling={css`
                    background-color: white;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                `}>
                <FlexRow
                    grow={1}
                    mainAxis="center"
                    crossAxis="center"
                    padding={{ leftRight: 20, topBottom: 20 }}>
                    <ModalTitle
                        text={
                            pauseReasonTitle ||
                            translation({
                                sv: "Stängt",
                                en: "Closed",
                            })
                        }
                    />
                </FlexRow>

                <VerticalDivider />
                <TextBox
                    padding={{ top: 20, bottom: 5, leftRight: 40 }}
                    align="center"
                    text={
                        pauseReason ||
                        translation({
                            sv: `${
                                translate(provider?.meta.title, "sv") ||
                                "Leverantören tar inte emot nya ordrar just nu."
                            }`,
                            en: `${
                                translate(provider?.meta.title, "en") ||
                                "The provider doesn't accept new orders at the moment."
                            }`,
                        })
                    }
                    size={1}
                />
                {provider?.options.paused?.until && comingSoon && (
                    <TextBox
                        padding={{ top: 5, bottom: 5, leftRight: 40 }}
                        align="center"
                        text={translation({
                            sv: `Försök igen om ${svTranslator(
                                formatDistanceFromNow(
                                    provider.options.paused.until,
                                ).formatted,
                            )}.`,
                            en: `Try again in ${enTranslator(
                                formatDistanceFromNow(
                                    provider.options.paused.until,
                                ).formatted,
                            )}.`,
                        })}
                        size={1}
                    />
                )}
                <VerticalDivider XXL />
                <PillButton
                    onClick={() => {
                        hideProviderPauseCheckModal();
                        navigate("/#goback");
                        return;
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Ok, se annat utbud",
                            en: "Ok, see other offerings ",
                        })}
                        weight="700"
                        color={theme.colors.primary.text}
                        size={1.2}
                        padding={{ topBottom: 15, leftRight: 25 }}
                    />
                </PillButton>
                <VerticalDivider XL />
            </FlexColumn>
        </Modal>
    );
};

export { ProviderPauseCheckModal };
