import { useMe } from "@@/authentication/me/me-context";
import { Button } from "@@/shared/buttons";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox, TextEdit } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faCheck, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { Padding, PhoneNumber, translation } from "@towni/common";
import * as React from "react";
import { useSetUserPhoneNumberInit } from "../use-set-user-phone-number-init";
import { SetUserPhoneNumberVerificationField } from "./set-user-phone-number-verification-field";
import { VerificationBadge } from "./verified-badge";

type Props = {
    padding?: Padding;
};

const UserModalEditPhone = (props: Props) => {
    const [me, { meQuery }] = useMe();
    const theme = useTheme();
    const [status, setStatus] = React.useState<"VIEW" | "EDIT" | "VERIFY">(
        "VIEW",
    );
    const initSetUserPhoneNumber = useSetUserPhoneNumberInit({
        onSuccess: () => {
            setStatus("VERIFY");
        },
    });
    const [updatedPhoneNumber, setUpdatedPhoneNumber] = React.useState<
        PhoneNumber | undefined
    >();

    const currentPhoneNumber = me?.phone?.phoneNumber;

    const isNew = updatedPhoneNumber !== currentPhoneNumber;

    if (!me) return null;
    if (status === "VERIFY" && !updatedPhoneNumber) {
        throw new Error("Status verify and no new phone number");
    }

    return (
        <>
            {/* <UserModalEditPhoneView > */}
            <FlexRow fillParentWidth padding={props.padding}>
                {status === "VIEW" ? (
                    <FlexRow
                        fillParentWidth
                        mainAxis="space-between"
                        crossAxis="center">
                        <FlexRow>
                            <Icon
                                icon={faPhone}
                                color={theme.colors.black.light65}
                                padding={{ top: 3 }}
                            />
                            <HorizontalDivider S />
                            <TextBox
                                text={currentPhoneNumber}
                                color={theme.colors.default.text}
                            />
                            <HorizontalDivider S />
                        </FlexRow>
                        <VerificationBadge verified={!!me?.phone?.verified} />
                    </FlexRow>
                ) : null}
                {status === "EDIT" ? (
                    <>
                        <TextEdit
                            type="tel"
                            placeholder={translation({
                                sv: "Telefonnr",
                                en: "Phone",
                            })}
                            value={updatedPhoneNumber || me?.phone?.phoneNumber}
                            onChange={phoneNumber => {
                                setUpdatedPhoneNumber(
                                    phoneNumber as PhoneNumber,
                                );
                            }}
                            disabled={meQuery.isPending}
                            showSpinner={meQuery.isPending}
                            onFocus={() => setStatus("EDIT")}
                            onBlur={() => !isNew && setStatus("VIEW")}
                        />
                        <HorizontalDivider />
                        <Button
                            buttonTheme="primary"
                            onClick={() => {
                                if (typeof updatedPhoneNumber === "undefined")
                                    return;
                                // init set phone number
                                initSetUserPhoneNumber.mutateAsync({
                                    phoneNumber: updatedPhoneNumber,
                                });
                                // show verification code input field
                            }}>
                            <Icon icon={faCheck} />
                        </Button>
                    </>
                ) : null}
            </FlexRow>
            {status === "VERIFY" ? (
                <SetUserPhoneNumberVerificationField
                    codeLength={6}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    phoneNumber={updatedPhoneNumber!}
                    onSuccess={() => {
                        setUpdatedPhoneNumber(undefined);
                        setStatus("VIEW");
                    }}
                />
            ) : null}
        </>
    );
};

export { UserModalEditPhone };
