import {
    UseTranslateResult,
    ValueTranslator,
    fromTranslatableString,
} from "@towni/common";
import { ZodError } from "zod";

const parseZodErrorMessages = (
    error: ZodError,
    translator: UseTranslateResult | ValueTranslator,
) => {
    const messages = error.issues.map(issue => {
        const message = fromTranslatableString(issue.message);
        return `[${issue.path.join(".")}] ${translator(message)}`;
    });

    return messages;
};

export { parseZodErrorMessages };
