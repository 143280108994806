import { useLanguageContext } from "@@/settings/language-context";
import { fromUnix, getLocale, IsoAndUnixTimestamp } from "@towni/common";
import { format as dateFormat } from "date-fns";

const useTimeFormatter = () => {
    const { language } = useLanguageContext();

    // todo! switch datefns locale based on given locale

    const formatter = (dateTime: IsoAndUnixTimestamp, format: string) => {
        return dateFormat(fromUnix(dateTime.unix), format, {
            locale: getLocale(language),
        });
    };

    return formatter;
};

export { useTimeFormatter };
