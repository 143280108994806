import {
    asDate,
    createTimeSpot,
    DailySchedule,
    getDailySchedulesForWeekdayAndProvider,
    getProviderPrepTime,
    isoAndUnixFactory,
    IsoAndUnixTimestamp,
    Provider,
    repeatIndex,
    TimePadding,
    TimeSpot,
    toFullDate,
} from "@towni/common";
import {
    addDays,
    addMinutes,
    isFuture,
    startOfDay,
    subMinutes,
} from "date-fns";

const getFirstOpenTimeSpotForProvider = (
    provider: Provider,
    howManyDaysMustAMan = 31
): TimeSpot | undefined => {
    const timeSpotsByFullDate = calculateOpenTimeSpotsForProvider(
        provider,
        howManyDaysMustAMan
    );
    if (!timeSpotsByFullDate) return undefined;

    const firstDateWithTimeStamp = Object.keys(timeSpotsByFullDate)
        .sort()
        .find(fullDate => timeSpotsByFullDate[fullDate].length);

    return firstDateWithTimeStamp
        ? timeSpotsByFullDate[firstDateWithTimeStamp][0]
        : undefined;
};

type FullDateAsString = string;
const calculateOpenTimeSpotsForProvider = (
    provider: Provider,
    howManyDaysMustAMan = 31
) => {
    const startOfToday = startOfDay(new Date());

    const sevenDays = repeatIndex(howManyDaysMustAMan).map(i => {
        return addDays(startOfToday, i);
    });

    const sevenHoursDays = sevenDays
        .flatMap(dayDate => {
            const business = getDailySchedulesForWeekdayAndProvider(
                dayDate,
                provider
            );

            return calculateOpenTimeSpots(
                isoAndUnixFactory(dayDate),
                getProviderPrepTime(provider),
                business
            );
        })
        .sort((t1, t2) => {
            if (!t1) return 0;
            if (!t2) return 0;

            if (t1.start.unix > t2.start.unix) {
                return 1;
            }
            if (t1.start.unix < t2.start.unix) {
                return -1;
            }

            return 0;
        });
    let asap = false;
    const perDay = sevenHoursDays.reduce((timeSpotsByFullDate, timeSpot) => {
        const fullDate = toFullDate(timeSpot.start).toString();
        if (
            isFuture(
                subMinutes(
                    asDate(timeSpot.start),
                    getProviderPrepTime(provider)
                )
            )
        ) {
            timeSpotsByFullDate[fullDate] = timeSpotsByFullDate[fullDate] || [];
            timeSpotsByFullDate[fullDate].push(timeSpot);
        } else if (!asap && isFuture(asDate(timeSpot.start))) {
            asap = true; //We can only have one asap
            timeSpotsByFullDate[fullDate] = timeSpotsByFullDate[fullDate] || [];
            timeSpotsByFullDate[fullDate].push(undefined);
        }

        return timeSpotsByFullDate;
    }, {} as Record<FullDateAsString, (TimeSpot | undefined)[]>);

    if (!Object.values(perDay).some(item => typeof item !== "undefined")) {
        return undefined;
    }
    return perDay;
};

const calculatePadding = (
    businessHourRange: DailySchedule,
    providerDefaultPadding?: TimePadding
) => {
    let paddingStartInMinutes = 0;
    let paddingEndInMinutes = 0;

    if (providerDefaultPadding) {
        paddingStartInMinutes = providerDefaultPadding.startInMinutes;
        paddingEndInMinutes = providerDefaultPadding.endInMinutes;
    }

    if (businessHourRange.orderTimePadding) {
        paddingStartInMinutes =
            businessHourRange.orderTimePadding.startInMinutes;
        paddingEndInMinutes = businessHourRange.orderTimePadding.endInMinutes;
    }
    return [paddingStartInMinutes, paddingEndInMinutes];
};

const calculateOpenTimeSpots = (
    day: IsoAndUnixTimestamp,
    prepTime: number,
    hours: DailySchedule[],
    providerDefaultPadding?: TimePadding
) => {
    return hours.flatMap(closest => {
        const [paddingStartInMinutes, paddingEndInMinutes] = calculatePadding(
            closest,
            providerDefaultPadding
        );
        const date = addMinutes(
            startOfDay(asDate(day)),
            closest.timeRange.startInMinutes + paddingStartInMinutes
        );

        const minutes = 10;
        const numberOf =
            (closest.timeRange.durationInMinutes + paddingEndInMinutes) /
            minutes;

        const timeSpots = repeatIndex(numberOf)
            .map((index: number) => {
                const time = addMinutes(date, index * minutes);

                return createTimeSpot(time);
            })
            .map(t => {
                if (isFuture(subMinutes(asDate(t.start), prepTime))) return t;
                return t;
            });
        return [...new Set(timeSpots)];
    });
};
export { calculateOpenTimeSpotsForProvider, getFirstOpenTimeSpotForProvider };
