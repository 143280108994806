import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import { IsoAndUnixTimestamp } from "@towni/common";
import { Except } from "type-fest";

type ParagraphDateTime = {
    _type: "PARAGRAPH_DATE_TIME";
    dateTime: IsoAndUnixTimestamp;
    css?: Interpolation<AppTheme>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphDateTime = (item: any): item is ParagraphDateTime => {
    return item?._type === "PARAGRAPH_DATE_TIME";
};

const paragraphDateTimeFactory = (
    params: Except<ParagraphDateTime, "_type">
): ParagraphDateTime => {
    return {
        _type: "PARAGRAPH_DATE_TIME",
        ...params,
    };
};

const ParagraphDateTime = (props: {
    dateTime: ParagraphDateTime;
    className?: string;
}) => {
    const dateTimeFormat = useDateTimeFormatter();

    return (
        <span
            className={props.className}
            css={[{ whiteSpace: "nowrap" }, props.dateTime.css]}>
            {dateTimeFormat.formatDateMedium(props.dateTime.dateTime)}
        </span>
    );
};

export { ParagraphDateTime, isParagraphDateTime, paragraphDateTimeFactory };
