import { css } from "@emotion/react";
import { Padding, RemSize, SizeName } from "@towni/common";
import { FC } from "react";
import { FlexRow } from "./flex-containers";
import { paddingToCssValue } from "./padding";
import { useDimensions } from "./section-area/card-size";

type Props = {
    /**
     * defaults to 10px
     *
     * @type {number}
     */
    gap?: number;
    size: SizeName | RemSize;
    padding?: Padding;
    width?: number;
    children?: React.ReactNode;
};

const browserHasCssGridSupport = (() => {
    let testElement: HTMLDivElement | undefined;
    try {
        testElement = document.createElement("div");
        testElement.style.display = "grid";
        return testElement.style.display === "grid";
    } finally {
        testElement?.parentNode?.removeChild(testElement);
    }
})();

const SimpleGrid: FC<Props> = props => {
    const getDimensions = useDimensions();
    const dimensions = getDimensions({
        aspectRatio: "1:1",
        size: props.size,
    });

    const columnMin = dimensions.width;
    const rowMin = dimensions.height;
    switch (browserHasCssGridSupport) {
        case true: {
            const gridCss = css`
                display: grid;
                gap: ${props.gap === undefined ? 10 : props.gap}px;
                border: none;
                grid-template-columns: repeat(
                    auto-fit,
                    minMax(${columnMin}px, 1fr)
                );
                grid-auto-rows: minmax(${rowMin}px, 1fr);
                justify-items: stretch;
                align-items: stretch;
                ${props.padding
                    ? `padding: ${paddingToCssValue(props.padding)};`
                    : ""}
                ${props.width
                    ? `width:${props.width}px;max-width:${props.width}px;`
                    : ""}
            `;

            return <div css={gridCss}>{props.children}</div>;
        }
        case false: {
            const children = Array.isArray(props.children)
                ? props.children
                : [props.children];

            const containedChildren = children.map((child, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            width: dimensions.width,
                            minHeight: dimensions.height,
                        }}>
                        {child}
                    </div>
                );
            });

            return (
                <FlexRow
                    fillParentWidth
                    padding={props.padding}
                    wrap="wrap"
                    wrapMargin={props.gap ?? 10}
                    // crossAxis="center"
                    mainAxis="space-around">
                    {containedChildren}
                </FlexRow>
            );
        }
    }
};

export { SimpleGrid };
