import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { PageLoad } from "@@/pages/page-load";
import ProductDetailsPage from "@@/products/product-details-page";
import { BrandingProvider } from "@@/routes/shared/branding-context";
import { useProductLoader } from "@@/routes/shared/loaders/product-loader";
import { useProviderLoader } from "@@/routes/shared/loaders/provider-loader";
import { VoucherPage } from "@@/routes/vouchers/$voucher/voucher-page";
import { useKeyboardShortcut } from "@@/shared/use-keyboard-shortcut";
import {
    Product,
    ProductId,
    productIdZodSchema,
    Provider,
    translation,
} from "@towni/common";
import { useNavigate, useParams } from "react-router-dom";
import { _ProductAccommodationPage } from "./_accommodation/_product-accommodation.page";
import { _ProductBookablePage } from "./_bookable/_product-bookable.page";

type Props = {
    productId?: ProductId;
};

const ProductPage = (props: Props) => {
    const paramProductId = useParams<{ productId?: string }>().productId;
    const productId = productIdZodSchema.safeParse(
        props.productId ?? paramProductId,
    );
    const [product, isLoadingProduct] = useProductLoader(
        productId.success ? productId.data : undefined,
    );
    const [provider, isLoadingProvider] = useProviderLoader(
        product?.providerId,
    );

    if (isLoadingProduct || isLoadingProvider)
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar produkt...",
                    en: "Loading product...",
                })}
            />
        );

    return (
        <CartContextProvider key={provider._id} providerId={provider._id}>
            <CheckoutContextProvider>
                <BrandingProvider providerId={provider._id}>
                    <ProductPageSelector
                        product={product}
                        provider={provider}
                    />
                </BrandingProvider>
            </CheckoutContextProvider>
        </CartContextProvider>
    );
};

const ProductPageSelector = (props: {
    product: Product;
    provider: Provider;
}) => {
    const hasAlfredScope = useHasAlfredScope();
    const { product, provider } = props;
    const navigate = useNavigate();
    useKeyboardShortcut({
        keys: ["v", "p"],
        callback: () => {
            if (!hasAlfredScope) return;
            navigate(`/bo/providers/${provider._id}/products/${product._id}`);
        },
        modifiers: {
            ctrl: true,
        },
    });
    useKeyboardShortcut({
        keys: ["v", "o"],
        callback: () => {
            window.open(
                `/bo/providers/${provider._id}/products/${product._id}`,
                "_blank",
            );
        },
        modifiers: {
            ctrl: true,
        },
    });
    switch (product.acquirableAs) {
        case "BOOKABLE":
            return (
                <_ProductBookablePage product={product} provider={provider} />
            );
        case "BUYABLE":
            return (
                <ProductDetailsPage
                    provider={provider}
                    product={product}
                    hideLinkToShop={false}
                    modalMode={false}
                />
            );
        case "VOUCHER":
            return <VoucherPage voucherId={product._id} provider={provider} />;
        case "GIFTCARD":
            return <VoucherPage voucherId={product._id} provider={provider} />;
        case "ACCOMMODATION":
            return (
                <_ProductAccommodationPage
                    product={product}
                    provider={provider}
                />
            );
    }
};

export { ProductPage };
