import { useProduct } from "@@/products/state/queries/product/use-product";
import { darkeningGradientReverse } from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ImageShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { css, useTheme } from "@emotion/react";
import { ProductId, RemSize } from "@towni/common";
import * as React from "react";
import { Link } from "react-router-dom";
import { lackoColors } from "../lacko-color-context";

// type Props = {};
const LackoStayTheNightSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();
    const guestHarborProductId = React.useMemo(
        // Gästhamn
        () => getProductId("product_lViGWBY82qZVgmJXDwkk" as ProductId),
        [],
    );
    const [guestHarbor, guestHarborQuery] = useProduct(guestHarborProductId);
    const guestHarborImageRef = guestHarbor?.meta.images[0];
    return (
        <SectionArea
            sectionAreaId="stay-the-night-section"
            title="Stanna över natten"
            subtitle="Upptäck och upplev historia"
            padding={{ top: 10 }}>
            <Link to={`/products/${guestHarborProductId}`}>
                <FlexColumn
                    fillParentWidth
                    padding={{ leftRight: 20 }}
                    crossAxis="flex-start"
                    shrink={0}>
                    {guestHarborQuery.isPending ? (
                        <ImageShimmer radius={16} maxHeight="335px" />
                    ) : (
                        <SectionAreaCard
                            aspectRatio={isDesktop ? "3:2" : "1:1"}
                            radius={16}
                            background={{
                                fit: "COVER",
                                imageReference: guestHarborImageRef,
                                imageSize: {
                                    imageWidth: 500,
                                },
                            }}>
                            <FlexColumn
                                shrink={0}
                                grow={1}
                                padding={{ all: 20 }}
                                background={{
                                    linearGradient: darkeningGradientReverse,
                                }}
                                mainAxis="space-between"
                                crossAxis="center">
                                <FlexColumn fillParentWidth shrink={0}>
                                    <TextBox
                                        text={"Gästhamn"}
                                        weight="900"
                                        size={2 as RemSize}
                                        color={theme.colors.white}
                                        styling={css`
                                            text-shadow: 0 4px 4px
                                                rgba(0, 0, 0, 0.25);
                                        `}
                                    />
                                    <VerticalDivider />
                                    <TextBox
                                        text={
                                            "Nära till både slott, kobbar och finrestaurang ett stenkast bort"
                                        }
                                        weight="500"
                                        size={1 as RemSize}
                                        color={theme.colors.white}
                                        styling={css`
                                            text-shadow: 0 4px 4px
                                                rgba(0, 0, 0, 0.25);
                                        `}
                                    />
                                </FlexColumn>
                                <FlexRow fillParentWidth mainAxis="flex-end">
                                    <StoreFrontCardPill
                                        text={"Angör brygga"}
                                        colorSet={lackoColors.primary}
                                    />
                                </FlexRow>
                            </FlexColumn>
                        </SectionAreaCard>
                    )}
                </FlexColumn>
            </Link>
        </SectionArea>
    );
};

export { LackoStayTheNightSection };
