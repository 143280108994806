import { Wizard, wizardPage } from "@@/pages/wizard";
import { ErrorBoundary } from "@sentry/react";
import { SignUpDataProvider } from "../sign-up/sign-up-context";
import { PhoneSignInOrUpDataProvider } from "./phone-sign-in-or-sign-in-context";
import { CaptchaWizardStep } from "./steps/captcha-wizard-step";
import { PhoneRegistration } from "./steps/phone-registration";
import { PhoneVerificationPage } from "./steps/phone-verification-page";
import { SignUpRegistration } from "./steps/sign-up-registration";

type Props = {
    hide: () => void;
    finished?: () => void;
};

const PhoneSignInOrUpPage = (props: Props) => {
    return (
        <ErrorBoundary>
            <PhoneSignInOrUpDataProvider>
                <Wizard
                    ignoreAnimation={true}
                    onCancel={props.hide}
                    onFinish={props.finished}>
                    {[
                        wizardPage("phone-registration", <PhoneRegistration />),
                        wizardPage("captcha", <CaptchaWizardStep />),
                        wizardPage("verification", <PhoneVerificationPage />),
                        wizardPage(
                            "phone-sign-up",
                            <SignUpDataProvider>
                                <SignUpRegistration />
                            </SignUpDataProvider>,
                        ),
                    ]}
                </Wizard>
            </PhoneSignInOrUpDataProvider>
        </ErrorBoundary>
    );
};

export { PhoneSignInOrUpPage };
