import { isQuantityPickerBookableOptionValue, ResourceId } from "@towni/common";
import { useMemo } from "react";
import { useBookingContext } from "../booking-context";

const useRequestedQuantities = () => {
    const optionValues = useBookingContext(state => state.optionValues);

    const requestedQuantities = useMemo(() => {
        const requestedQuantities = optionValues
            .filter(isQuantityPickerBookableOptionValue)
            .map(value => [value.resourceId, value.quantity.value] as const)
            .reduce((agg, item) => {
                const current = agg.get(item[0]) ?? 0;
                agg.set(item[0], current + item[1]);
                return agg;
            }, new Map<ResourceId, number>());
        return requestedQuantities;
    }, [optionValues]);

    return requestedQuantities;
};
export { useRequestedQuantities };
