import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    GetResponse,
    Product,
    ProductId,
    isAccommodation,
    isBookable,
} from "@towni/common";
import React from "react";

type ResponseType = GetResponse<ProductId>;
const useAvailableExtrasQuery = (
    product: Product,
): UseQueryResult<ResponseType> => {
    const fetchClient = useFetchClient();
    const availableExtrasCheck: QueryFunction<ResponseType> = React.useCallback(
        ({ signal }) => {
            return fetchClient.get<ResponseType>({
                route: `/api/products/${product._id}/available-extras`,
                customConfig: { signal },
            });
        },
        [fetchClient, product._id],
    );
    return useQuery({
        queryKey: ["products", product._id, "availableExtras"],
        queryFn: availableExtrasCheck,
        enabled:
            !!product._id && (isBookable(product) || isAccommodation(product)),
    });
};
export { useAvailableExtrasQuery };
