import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { HorizontalDivider } from "@@/shared/dividers";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { backendHost } from "@@/shared/fetch-client";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox, TextSpan } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { css } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { RemSize } from "@towni/common";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import {
    appPageIdFactory,
    usePageHide,
} from "../../pages/page-navigation/page-navigation-context";
import { VoucherList } from "./sections/voucher-list";

const backgroundColor1 = "#ccc";
const backgroundColor2 = "#aaa";

const lightLinkCss = css({
    color: "#00a27e",
    fontStyle: "italic",
    ":hover": {
        color: "#00a27e",
    },
});
const darkLinkCss = css({
    color: "#ffffff",
    fontStyle: "italic",
    ":hover": {
        color: "palegreen",
    },
});

type Props = {
    title?: string;
};

const StoreFront = (props: Props) => {
    const vh100 = use100vh();
    const pageHide = usePageHide("/vouchers");
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const heroMaxHeight = isDesktop ? 450 : 300;
    return (
        <React.Fragment key="voucher-store-front-page">
            <Helmet>
                <title>{props.title ?? "Lokala Presentkort - Towni"}</title>
            </Helmet>
            <AppPage
                key="voucher-store-front-page"
                pageId={appPageIdFactory("voucher-store-front")}
                hide={pageHide}
                forgetScrollPosition={true}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="WHITE" />
                    </FlexRow>
                }>
                {/* Header Image */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ top: 55, leftRight: 32 }}
                    height={vh100 * 0.65}
                    maxHeight={heroMaxHeight}
                    background={{
                        imageUrl: `${backendHost}/public/storage/towni-brand/misc/voucher-store-front-poster.png?hello`,
                        fit: "COVER",
                    }}
                />
                <FlexColumn fillParentWidth padding={{ all: 20, top: 40 }}>
                    <FlexColumn fillParentWidth padding={{ leftRight: 10 }}>
                        <TextBox
                            text="Upplevelser som gåvor!"
                            size={1.5 as RemSize}
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={
                                "Ge bort någonting att göra tillsammans lokalt! Till kollegorna, kompisen barnbarnen eller nått du vill göra med en vän. Tillsammans!"
                            }
                            css={{ lineHeight: 1.3 }}
                        />
                        <VerticalDivider M />
                        <FlexRow fillParentWidth>
                            <a
                                href={`http://skaraborg.nu`}
                                title="Skaraborg turistorganisationer"
                                target="_blank"
                                rel="noreferrer"
                                css={[
                                    lightLinkCss,
                                    {
                                        display: "block",
                                        width: "30%",
                                    },
                                ]}>
                                <img
                                    src={`${backendHost}/public/storage/towni-brand/logos/skaraborg-logo-small.png`}
                                    width={"100%"}
                                />
                            </a>
                            <HorizontalDivider M />
                            <a
                                href={`https://towni.se`}
                                title="Towni"
                                target="_blank"
                                rel="noreferrer"
                                css={[
                                    lightLinkCss,
                                    {
                                        display: "block",
                                        width: "20%",
                                    },
                                ]}>
                                <img
                                    src={`${backendHost}/public/storage/towni-brand/logos/towni-logo-hz-small-gray-on-transparent.png`}
                                    width={"100%"}
                                />
                            </a>
                        </FlexRow>
                    </FlexColumn>
                    <VerticalDivider XL />
                    <VoucherList
                        divider={index => {
                            return index !== 1 ? (
                                <VerticalDivider L />
                            ) : (
                                <FlexColumn
                                    padding={{
                                        topBottom: 40,
                                        leftRight: 10,
                                    }}>
                                    <TextBox
                                        text="Beställ och boka lokalt!"
                                        size={1.5 as RemSize}
                                        weight="700"
                                    />
                                    <VerticalDivider XS />
                                    <TextBox size={1.125 as RemSize}>
                                        <TextSpan
                                            text={
                                                "I samarbete med Skaraborg turistorganisationer är detta ett inititiativ till att få mer handel att stanna lokalt. "
                                            }
                                            css={{ lineHeight: 1.3 }}
                                        />
                                        <a
                                            href={`http://skaraborg.nu`}
                                            title="Skaraborg turistorganisationer"
                                            target="_blank"
                                            rel="noreferrer"
                                            css={lightLinkCss}>
                                            <TextSpan
                                                text={"skaraborg.nu »"}
                                                css={{ lineHeight: 1.3 }}
                                            />
                                        </a>
                                    </TextBox>
                                </FlexColumn>
                            );
                        }}
                    />
                    <VerticalDivider XL />
                </FlexColumn>
                <FlexColumn
                    css={{
                        background: `linear-gradient(134.24deg, ${backgroundColor1} -8.03%, ${backgroundColor2} 98.12%)`,
                    }}>
                    <FlexRow
                        fillParentWidth
                        padding={{
                            all: isDesktop ? 40 : 30,
                            bottom: isDesktop ? 30 : 20,
                        }}
                        mainAxis="center"
                        crossAxis="center">
                        <a
                            href={`http://skaraborg.nu`}
                            title="Skaraborg turistorganisationer"
                            target="_blank"
                            rel="noreferrer"
                            css={darkLinkCss}>
                            <img
                                src={`${backendHost}/public/storage/towni-brand/logos/skaraborg-logo-circle-small.png`}
                                alt="Skaraborg logo"
                                style={{ width: 80, height: 80 }}
                            />
                        </a>
                        <Icon
                            icon={faTimes}
                            color="#ffffff"
                            padding={{ leftRight: 15 }}
                            opacity={0.7}
                        />
                        <a
                            href={`https://towni.se`}
                            title="Towni"
                            target="_blank"
                            rel="noreferrer"
                            css={darkLinkCss}>
                            <img
                                src={`${backendHost}/public/storage/towni-brand/logos/towni-logo-circle-green-on-white-small.png`}
                                alt="Towni logo"
                                style={{ width: 80, height: 80 }}
                            />
                        </a>
                    </FlexRow>
                    <TextBox
                        text="Lokala upplevelsepresentkort är ett samarbete mellan Skaraborg turistorganisationer och towni.se"
                        align="center"
                        padding={{ leftRight: 20 }}
                        color="#ffffff"
                        size={0.9375}
                    />
                    <TextBox
                        padding={{ leftRight: 20 }}
                        align="center"
                        color="#ffffff"
                        size={0.9375}>
                        <TextSpan text={"Besök oss på "} />
                        <a
                            href={`http://skaraborg.nu`}
                            title="Skaraborg turistorganisationer"
                            target="_blank"
                            rel="noreferrer"
                            css={darkLinkCss}>
                            <TextSpan text={"skaraborg.nu »"} />
                        </a>
                        <TextSpan text={" eller "} />
                        <a
                            href={`https://towni.se`}
                            title="Towni"
                            target="_blank"
                            rel="noreferrer"
                            css={darkLinkCss}>
                            <TextSpan text={"towni.se »"} />
                        </a>
                    </TextBox>
                    <VerticalDivider XXL />
                </FlexColumn>
            </AppPage>
        </React.Fragment>
    );
};

export { StoreFront as VoucherStoreFront };
