import { HorizontalDivider } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import {
    isBookable,
    Order,
    OrderGroup,
    OrderItemBookable,
    ResourceReservation,
    Translatable,
    translation,
    UserReference,
} from "@towni/common";
import { useMemo } from "react";
import { BuyableReceipt_Ticket } from "./buyable-receipt-ticket";

type Props = {
    readonly orderGroup: OrderGroup;
    readonly orders: Order[];
    readonly customer: UserReference;
    readonly resourceReservations: ResourceReservation[];
};

const BuyableReceipt_Tickets = (props: Props) => {
    const { orders, resourceReservations, customer } = props;

    const bookableOrderItems = useMemo(
        () =>
            orders.flatMap(order =>
                order.orderItems
                    .filter(isBookable)
                    .map(
                        orderItem =>
                            [order, orderItem] as [Order, OrderItemBookable],
                    ),
            ),
        [orders],
    );

    return (
        <>
            <ForEach
                divider={<HorizontalDivider />}
                itemOf={bookableOrderItems}
                getKey={([order, orderItem]) =>
                    `${order._id}__${orderItem._id}`
                }>
                {([order, orderItem]) => {
                    // TODO: WHAT STATUSES SHOULD BE VISIBLE ON RECEIPT?
                    const inactiveText = ((): Translatable => {
                        if (order.hasBeenCancelled)
                            return translation({
                                sv: "Avbokad",
                                en: "Cancelled",
                            });
                        if (order.hasBeenRejected)
                            return translation({
                                sv: "Nekad",
                                en: "Rejected",
                            });
                        if (order.hasPassed)
                            return translation({
                                sv: "Passerad",
                                en: "Passed",
                            });
                        return null;
                    })();

                    return (
                        <BuyableReceipt_Ticket
                            key={orderItem._id}
                            customer={customer}
                            order={order}
                            css={{
                                opacity: order.inactive ? 0.5 : 1,
                                filter: order.inactive
                                    ? "grayscale(100%)"
                                    : undefined,
                            }}
                            orderItem={orderItem}
                            resourceReservations={resourceReservations}
                            inactiveText={inactiveText}
                            inactive={order.inactive}
                        />
                    );
                }}
            </ForEach>
        </>
    );
};

export { BuyableReceipt_Tickets };
