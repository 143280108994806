import { useAppSettings } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { TextBox } from "@@/shared/text";
import { linkCss } from "@@/styles/themes/default.theme";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize } from "@towni/common";
import { FC } from "react";
import { Link } from "react-router-dom";
import { StoreFrontGridProductCard } from "../../shared/store-front-grid-product-card";

const InspirerasSection: FC = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const { isProdEnv, isDevEnv } = useAppSettings();
    const localDefaultProductId = "product_XwcfwcB9gVetSxl3F8ue" as ProductId;
    const devDefaultProductId = "product_mAUGHkpleK8YizKI_6QX" as ProductId;

    const defaultKicker = !isProdEnv
        ? "ANNAN PRODUKT VISAS I PROD!"
        : "INKL HEMLEVERANS";
    const productIdWithFallback = (productId: string): ProductId =>
        isProdEnv
            ? (productId as ProductId)
            : isDevEnv
              ? devDefaultProductId
              : localDefaultProductId;

    return (
        <SectionArea
            sectionAreaId="be-inspired-section"
            title={"Inspireras!"}
            padding={{ top: 10 }}
            subtitleColor={theme.colors.black.light45}
            titleSize="XXL"
            css={{ borderRadius: isDesktop ? 10 : 0 }}
            titleWeight="900"
            background={{
                color: theme.colors.primary.light,
            }}
            subtitle="Mysiga och vackra saker till ditt hem">
            <SimpleGrid size={0.9 as RemSize} padding={{ leftRight: 20 }}>
                <StoreFrontGridProductCard
                    productId={productIdWithFallback(
                        "product_lP7PsXBPL33qM_Itc-zi",
                    )}
                    kicker={defaultKicker}
                />
                <StoreFrontGridProductCard
                    productId={productIdWithFallback(
                        "product_iVAj3mQ2q-XvhIN8e2Cm",
                    )}
                    kicker={defaultKicker}
                />
                <StoreFrontGridProductCard
                    productId={productIdWithFallback(
                        "product_MC9qtTcRP9SAds4FMeiC",
                    )}
                    kicker={defaultKicker}
                />
                <StoreFrontGridProductCard
                    productId={productIdWithFallback(
                        "product_GsLM438y0bD1N709YuA9",
                    )}
                    kicker={defaultKicker}
                />
            </SimpleGrid>
            <VerticalDivider ML />
            <Link to="/shop/inspirera">
                <TextBox
                    text="Se mer från Inspirera »"
                    size="XS"
                    weight="700"
                    case="UPPERCASE"
                    padding={{ leftRight: 20 }}
                    styling={linkCss()}
                />
            </Link>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { InspirerasSection };
