import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { SwishPayment, swishErrorText, translation } from "@towni/common";

type Props = {
    swishPayment: SwishPayment | undefined;
};

const SwishMessage = (props: Props) => {
    const { swishPayment } = props;
    const theme = useTheme();
    if (!swishPayment) return null;

    return (
        <>
            {
                // Avbröts
                swishPayment?.status === "CANCELLED" ? (
                    <>
                        <TextBox
                            text={translation({
                                sv: `Swishbetalning avbröts`,
                                en: `Swish payment cancelled`,
                            })}
                            align="center"
                            color={theme.colors.default.text.light50}
                            weight="400"
                        />
                        <VerticalDivider />
                        <TextBox
                            text={
                                swishPayment.reasonCode
                                    ? swishErrorText(swishPayment.reasonCode)
                                    : swishPayment.reason
                            }
                            padding={{ leftRight: 6, topBottom: 4 }}
                            backgroundColor={theme.colors.black.light90}
                        />
                        <VerticalDivider S />
                    </>
                ) : null
            }
            {
                // Avböjdes
                swishPayment?.status === "DECLINED" ? (
                    <>
                        <TextBox
                            text={translation({
                                sv: `Swishbetalning avböjdes`,
                                en: `Swish payment declined`,
                            })}
                            align="center"
                            color={theme.colors.default.text.light50}
                            weight="400"
                        />
                        <VerticalDivider />
                        <TextBox
                            text={
                                swishPayment.reasonCode
                                    ? swishErrorText(swishPayment.reasonCode)
                                    : swishPayment.reason
                            }
                            padding={{ leftRight: 6, topBottom: 4 }}
                            backgroundColor={theme.colors.black.light90}
                        />
                        <VerticalDivider S />
                    </>
                ) : null
            }
            {
                // Avböjdes
                swishPayment?.status === "ERROR" ? (
                    <>
                        <TextBox
                            text={translation({
                                sv: `Swish error`,
                                en: `Swish payment error`,
                            })}
                            align="center"
                            color={theme.colors.default.text.light50}
                            weight="400"
                        />
                        <VerticalDivider />
                        <TextBox
                            text={
                                swishPayment.errorCode
                                    ? swishErrorText(swishPayment.errorCode)
                                    : swishPayment.errorMessage
                            }
                            padding={{ leftRight: 6, topBottom: 4 }}
                            backgroundColor={theme.colors.black.light90}
                        />
                        <VerticalDivider S />
                    </>
                ) : null
            }
            <Conditional when={swishPayment?.status === "PAID"}>
                <>
                    <TextBox
                        text={translation({
                            sv: `Swishbetalning klar`,
                            en: `Swish payment complete`,
                        })}
                        align="center"
                        color={theme.colors.default.text.light50}
                        weight="400"
                    />
                    <VerticalDivider XL />
                    <CircleSpinner size="XXL" />
                    <VerticalDivider L />
                </>
            </Conditional>
        </>
    );
};

export { SwishMessage, swishErrorText };
