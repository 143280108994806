/* eslint-disable react/display-name */
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { FlexRow } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { CheckBoxWithText } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { translation } from "@towni/common";
import { useCheckoutContext } from "./checkout.context";
import { useCart } from "./multi-carts/cart.context";

const LeaveMyStuffOutsideRow = (props: { disabled?: boolean }) => {
    const theme = useTheme();
    const { delivery, providerId } = useCart();
    const [, providerQuery] = useProvider(providerId);
    const { leaveMyStuffOutside, setLeaveMyStuffOutside } = useCheckoutContext(
        context => ({
            leaveMyStuffOutside: context.leaveMyStuffOutside,
            setLeaveMyStuffOutside: context.setLeaveMyStuffOutside,
        }),
    );

    if (delivery.method === "TABLE_SERVICE") return null;
    // eslint-disable-next-line no-constant-condition
    if (providerQuery.isPending) {
        // Loading
        return (
            <FlexRow fillParentWidth padding={{ leftRight: 20, topBottom: 15 }}>
                <TextShimmer rows={1} />
            </FlexRow>
        );
    }
    if (!providerQuery.data?.options) return null;
    if (providerQuery.data.options.leaveMyStuffOutsideEnabled === false)
        return null;

    return (
        <FlexRow
            mainAxis="flex-start"
            crossAxis="center"
            fillParentWidth
            css={{
                flexShrink: 0,
                padding: 20,
                paddingTop: 15,
                paddingBottom: 15,
            }}>
            <CheckBoxWithText
                checked={leaveMyStuffOutside}
                disabled={props.disabled}
                text={
                    delivery.method === "PICK_UP"
                        ? // Pickup
                          translation({
                              sv: "Jag hämtar min beställning vid dörren tack",
                              en: "I'll pick up my order at the door",
                          })
                        : // Home delivery
                          translation({
                              sv: "Lämna min beställning utanför dörren tack",
                              en: "Leave my order outside the door",
                          })
                }
                onChange={setLeaveMyStuffOutside}
                checkBoxSize="M"
                textColor={
                    leaveMyStuffOutside
                        ? theme.colors.black
                        : theme.colors.black.light40
                }
            />
        </FlexRow>
    );
};

export { LeaveMyStuffOutsideRow };
