import { Form } from "@@/shared/form/form.zustand-store";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import {
    IsoAndUnixTimestamp,
    Translatable,
    isoAndUnixFactory,
    isoAndUnixZodSchema,
    now,
} from "@towni/common";
import { ReactNode } from "react";
import { z } from "zod";

type Props = {
    readonly initialDateTime?: IsoAndUnixTimestamp | Date;
    readonly onChange: (dateTime: IsoAndUnixTimestamp | undefined) => void;
    readonly children?: ReactNode;
    readonly label?: Translatable;
    readonly disabled?: boolean;
    readonly css?: Interpolation<AppTheme>;
    /** Defaults to 1. When 1 pickers shows every minute, when 5 every 5 minutes is shown in picker and so on. */
    readonly minuteOptionStep?: 1 | 5 | 10 | 15 | 20;
    /**
     * When `true` as soon as a date, hour or minute is picked `onChange` is triggered.
     * When `false`, the user has to click the "Select/Ready" button to trigger `onChange`. \
     * Defaults to `false`
     */
    readonly selectDirect?: boolean;
};

const DateTimePicker = (props: Props) => {
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        dateTime: isoAndUnixZodSchema,
                    }),
                    initialState: {
                        dateTime: props.initialDateTime
                            ? isoAndUnixFactory(props.initialDateTime)
                            : now(),
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.DateTimePicker
                    fieldId="dateTime"
                    label={props.label}
                    disabled={props.disabled}
                    getter={data => data.dateTime}
                    css={props.css}
                    minuteOptionStep={props.minuteOptionStep}
                    selectDirect={props.selectDirect}
                    setter={(data, newValue) => {
                        data.dateTime = newValue;
                        props.onChange(newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { DateTimePicker };
