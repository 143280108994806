import { useStripeInit } from "@@/carts/use-stripe";
import { PageLoad } from "@@/pages/page-load";
import { browserLogger } from "@@/settings";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { Stripe } from "@stripe/stripe-js";
import * as React from "react";

type StripeContextStatus = "loading" | "loaded" | "error";
const StripeContext = React.createContext<
    { stripe: Stripe | undefined; status: StripeContextStatus } | undefined
>(undefined);

const StripeProvider = (props: { readonly children?: React.ReactNode }) => {
    const stripePromise = useStripeInit();
    const [status, setStatus] = React.useState<StripeContextStatus>("loading");
    const [stripe, setStripe] = React.useState<Stripe | undefined>();

    useMountEffect(() => {
        stripePromise
            .then(value => {
                if (value) {
                    setStripe(value);
                    setStatus(value ? "loaded" : "error");
                }
            })
            .catch(error => {
                browserLogger.error(error);
                setStatus("error");
            });
    });

    if (status === "loading")
        return (
            <PageLoad text="Initierar uppkoppling mot betalningsleverantör" />
        );
    if (status === "loaded" && !stripe)
        throw new Error("Stripe kunde inte laddas");

    return (
        <StripeContext.Provider value={{ stripe, status }}>
            {props.children}
        </StripeContext.Provider>
    );
};

const useStripeClient = () => {
    const state = React.useContext(StripeContext);
    if (!state) {
        throw new Error("useStripeClient must be used within a StripeProvider");
    }
    const { stripe: stripeClient, status: stripeClientStatus } = state;
    return { stripeClient, stripeClientStatus };
};

export { StripeProvider, useStripeClient };
