import { useDiscountCodeCheck } from "@@/backoffice/for-providers/discounts/discount-code/hooks/use-discount-code-check";
import { useCartIdIsUnused } from "@@/carts/multi-carts/use-cart-id-is-unused";
import { useDiscountsForProvider } from "@@/discounts/discount-fetchers";
import { useOptionalGroupsForProviders } from "@@/optional-groups/state/optional-group-fetchers";
import { useProducts } from "@@/products/state/queries/products/use-products";
import { useProviders } from "@@/providers/state/queries/providers/use-providers";
import { browserLogger } from "@@/settings";
import { Toaster, useToast } from "@@/toasts/context/toast-context";
import * as Sentry from "@sentry/react";
import {
    Cart,
    DiscountCode,
    OptionalGroup,
    OrderItem_V2,
    Product,
    Provider,
    Translatable,
    calculateDiscountedPrice,
    calculateOrderItemPrice,
    cartZodSchema,
    enTranslator,
    getProductIds,
    isBookable,
    isOrderItemBookableQuantity_V2,
    isOrderItemBookable_V2,
    isOrderItemDiscount_V2,
    isOrderItemGiftCard_V2,
    isOrderItemOptional_V2,
    isOrderItemUsedGiftCard_V2,
    isProductOrderItem,
    repeat,
    sumPrices,
    support,
    svTranslator,
    translation,
} from "@towni/common";
import { useEffect } from "react";
import { calculateDiscountRows } from "./discount-codes/discount-service";
import { useMultiCartContext } from "./multi-carts/multi-cart.context";

const useVerifiedCartState = (
    cart: Cart,
    setCart: (updatedCart?: Cart) => void,
) => {
    const cartResult = useCartIdIsUnused(cart._id);

    const cartIdHasAnActiveOrder = !!cartResult.data?.item.exist;
    const clearCartFromLocalStorage = useMultiCartContext(
        context => context.clearCartFromLocalStorage,
    );
    const toast = useToast();
    const providerIds = Array.from(
        new Set(cart?.orderItems?.map(oi => oi.providerId) || []),
    );

    const [discounts, discountQuery] = useDiscountsForProvider(providerIds[0]);
    const codeUsed = cart.orderItems.find(isOrderItemDiscount_V2);
    const [discountCode, discountCodeQuery] = useDiscountCodeCheck(
        providerIds[0],
        codeUsed?.code,
    );

    const productIds = Array.from(
        new Set(getProductIds(cart?.orderItems || [])),
    );
    const [products, productQuery] = useProducts(productIds);
    const [providers, query] = useProviders(providerIds);

    const [optionalGroups, optionalGroupQuery] =
        useOptionalGroupsForProviders(providerIds);

    useEffect(() => {
        let cartIsBad = false;
        if (cart) {
            const data = verifyCart(
                {
                    savedCart: cart,
                    toast,
                    exists:
                        cartIdHasAnActiveOrder &&
                        !cart.orderItems.some(isBookable), //Todo only check for buyable for now
                },
                () => clearCartFromLocalStorage(cart.providerId),
            );
            if (data.cart === undefined) cartIsBad = true;

            setCart(data.cart);
        }

        if (
            !cartIsBad &&
            cart &&
            (products.length || !productQuery.isStale) &&
            (discounts.length || !discountQuery.isPending) &&
            (discountCode || !discountCodeQuery.isPending) &&
            (providers.length || !query.isStale) &&
            (optionalGroups.length || !optionalGroupQuery.isStale)
        ) {
            // console.log("VERIFY PROD IN CART 2", { products, providers });
            const data = verifyProductsInCart({
                savedCart: cart,
                products: Array.isArray(products) ? products : [products],
                optionalGroups,
                providers,
                toast,
                discountCode,
            });
            setCart(data.cart);
        }
    }, [
        products,
        providers,
        discounts,
        optionalGroups,
        cartIdHasAnActiveOrder,
    ]);
};

const verifyCart = (
    params: { savedCart: Cart; toast: Toaster; exists: boolean },
    clear: () => void,
) => {
    const { savedCart, toast, exists } = params;

    if (
        new Set(savedCart?.orderItems?.map(oi => oi.providerId) || []).size > 1
    ) {
        const error = Error("no support for multiple providers in cart yet");
        Sentry.withScope(scope => {
            scope.setExtras({ erroneousCart: savedCart });
            Sentry.captureException(error);
        });

        clear();
        toast.warning({
            title: "Problem med kundkorgen",
            message: `Än så länge går det inte att handla från fler än en restaurang/butik på samma order, och din kundkorg innehöll varor från flera. Du kan handla från flera men måste då göra en order per restaurang/butik. Din kundkorg rensas för att åtgärda problemet. Om problem kvarstår kontakta ${support.towni.supportEmail} för assistans.`,
        });

        return {
            cart: undefined,
        };
    }

    const cartVerification = cartZodSchema.safeParse(savedCart);
    if (!cartVerification.success) {
        browserLogger.error("cartVerification.error", savedCart);
        browserLogger.error("cartVerification.error", cartVerification);

        const error = Error("Zod failed to verify cart");
        Sentry.withScope(scope => {
            scope.setExtras({
                erroneousCart: savedCart,
                zodError: cartVerification.error,
            });
            Sentry.captureException(error);
        });

        //Korrupt cart
        clear();

        toast.warning({
            message: translation({
                sv: "Tyvärr kunde vi inte ladda din kundkorg men vi har skapat en ny åt dig.",
                en: "Sorry but we failed to load your shopping cart. We have created a new one for you.",
            }),
        });

        //Sentry

        return {
            cart: undefined,
        };
    }

    if (exists) {
        //Order has been placed
        browserLogger.error(
            "cartVerification order has already beenplaced",
            savedCart,
        );
        clear();
        return {
            cart: undefined,
        };
    }

    return { cart: savedCart };
};

const verifyProductsInCart = (params: {
    savedCart: Cart;
    products: Product[];
    optionalGroups: OptionalGroup[];
    providers: Provider[];
    toast: Toaster;
    discountCode?: DiscountCode;
}) => {
    const {
        savedCart,
        products,
        // optionalGroups,
        providers,
        toast,
        discountCode,
    } = params;

    const notifications = savedCart.orderItems.map(cartItem => {
        if (isProductOrderItem(cartItem)) {
            const product = products.find(
                item => item._id === cartItem.productId,
            );
            if (
                !product ||
                product._status !== "ACTIVE" ||
                product.status.type !== "ACTIVE"
            ) {
                return {
                    cartItem: undefined,
                    message: orderItemIsSoldOut(cartItem.meta.title),
                };
            }

            const sku = product.skus.find(item => item._id === cartItem.skuId);
            if (!sku || sku._status !== "ACTIVE") {
                return {
                    cartItem: undefined,
                    message: orderItemIsSoldOut(cartItem.meta.title),
                };
            }

            const provider = providers.find(
                prov => prov._id === product.providerId,
            );
            if (!provider) {
                return { cartItem: undefined, message: undefined };
            }

            const pricePerItem = calculateOrderItemPrice({
                sku,
                pickedOptions: [],
            });
            const price = sumPrices(
                repeat(pricePerItem, cartItem.quantity.value),
            );
            const discountedPricePerItem = cartItem.discounts?.length
                ? calculateDiscountedPrice(cartItem.discounts ?? [])(cartItem) //TODO PRICEPER????
                : undefined;
            const discountedPrice = cartItem.discounts?.length
                ? calculateDiscountedPrice(cartItem.discounts ?? [])(cartItem)
                : undefined;

            if (
                (cartItem.originalPricePerItem &&
                    cartItem.originalPricePerItem.amountIncludingVat !==
                        pricePerItem.amountIncludingVat) ||
                (!cartItem.originalPricePerItem &&
                    cartItem.pricePerItem.amountIncludingVat !==
                        pricePerItem.amountIncludingVat)
            ) {
                return {
                    cartItem: {
                        ...cartItem,
                        pricePerItem: discountedPricePerItem ?? pricePerItem,
                        price: discountedPrice ?? price,
                        originalPricePerItem: pricePerItem,
                        originalPrice: price,
                    },
                    message: orderItemPriceHasBeenUpdated(cartItem.meta.title),
                };
            }
            if (cartItem.discounts === null) {
                return {
                    cartItem: { ...cartItem, discounts: [] },
                    message: undefined,
                };
            }
        }

        if (isOrderItemOptional_V2(cartItem)) {
            //TODO STUFF VERFICATION

            return { cartItem, message: undefined };
        }
        if (isOrderItemBookable_V2(cartItem)) {
            //TODO STUFF VERFICATION

            return { cartItem, message: undefined };
        }

        if (isOrderItemBookableQuantity_V2(cartItem)) {
            //TODO STUFF VERFICATION

            return { cartItem, message: undefined };
        }

        if (isOrderItemGiftCard_V2(cartItem)) {
            //TODO STUFF VERFICATION

            return { cartItem, message: undefined };
        }
        if (isOrderItemUsedGiftCard_V2(cartItem)) {
            //TODO STUFF VERFICATION

            return { cartItem, message: undefined };
        }

        if (isOrderItemDiscount_V2(cartItem)) {
            //TODO STUFF VERFICATION
            if (!discountCode)
                return {
                    cartItem: undefined,
                    message: orderItemIsSoldOut(cartItem.meta.title),
                };

            const item = calculateDiscountRows(
                cartItem.providerId,
                savedCart.orderItems.filter(oi => oi._id !== cartItem._id),
                discountCode,
            );

            return { cartItem: item[0], message: undefined };
        }

        return { cartItem, message: undefined };
    });

    notifications.map(m => {
        if (m.message) {
            toast.warning({
                message: m.message,
            });
        }
    });

    const cartItems = notifications
        .map(n => n.cartItem)
        .filter(m => !!m) as Array<OrderItem_V2>;
    const cart = {
        ...savedCart,
        orderItems: cartItems,
        price: sumPrices(cartItems.map(oi => oi.price)),
        totalPrice: sumPrices(cartItems.map(oi => oi.price)),
    } as Cart;
    return { cart: cart, message: notifications.find(f => !!f.message) };
};

const orderItemIsSoldOut = (title: Translatable) => {
    return translation({
        sv: `${svTranslator(title)} är slut. Vi har uppdaterat kundkorgen.`,
        en: `${enTranslator(title)} is sold out. The cart has been updated.`,
    });
};

const orderItemPriceHasBeenUpdated = (title: Translatable) => {
    return translation({
        sv: `${svTranslator(title)} har ett nytt pris.`,
        en: `${enTranslator(title)} has been updated with a new price.`,
    });
};

export {
    orderItemIsSoldOut,
    orderItemPriceHasBeenUpdated,
    useVerifiedCartState,
};
