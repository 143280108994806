import { browserLogger } from "@@/settings";
import { apiFetchClient } from "@@/shared/fetch-client";
import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import {
    GetResponse,
    OrderId,
    ProductId,
    ResourceReservationRequestInfo,
    TimeRange,
    emptyArrayOf,
    isOrderId,
} from "@towni/common";
import { resourceReservationsMainQueryKey } from "../main-query-key";

const fetchResourceReservationRequestInfo =
    (productId?: ProductId, ignoreOrder?: OrderId, timeRange?: TimeRange) =>
    async () => {
        if (typeof productId === "undefined")
            return emptyArrayOf<ResourceReservationRequestInfo>();
        if (!productId) return emptyArrayOf<ResourceReservationRequestInfo>();
        browserLogger.info("fetchResourceReservationRequestInfo", {
            timeRange,
            ignoreOrder,
        });
        const queryParams = new URLSearchParams({
            ...(timeRange
                ? {
                      from: timeRange.start.unix.toString(),
                      to: timeRange.end.unix.toString(),
                  }
                : {}),
            ...(isOrderId(ignoreOrder)
                ? {
                      ignoreOrder: ignoreOrder,
                  }
                : {}),
        });

        const data = await apiFetchClient.get<
            GetResponse<ResourceReservationRequestInfo>
        >({
            route: `/resources/reservations/reservation-info/for-product/${encodeURIComponent(
                productId,
            )}?${queryParams.toString()}`,
        });
        return data.items;
    };

const useResourceReservationRequestInfoForProduct = (
    productId?: ProductId,
    ignoreOrder?: OrderId,
    timeRange?: TimeRange,
): readonly [
    ResourceReservationRequestInfo[],
    UseQueryResult<ResourceReservationRequestInfo[]>,
] => {
    return useApiResources<ResourceReservationRequestInfo>(
        createQueryKey(resourceReservationsMainQueryKey, [
            productId ?? "",
            ignoreOrder ?? "",
            ...(timeRange ? [timeRange.start.iso, timeRange.end.iso] : []),
        ]),
        fetchResourceReservationRequestInfo(productId, ignoreOrder, timeRange),
        {
            enabled: !!productId,
        },
    );
};

export { useResourceReservationRequestInfoForProduct };
