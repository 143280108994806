import { useApiResources } from "@@/shared/state/use-api-resources";
import { OrderId, Ticket } from "@towni/common";
import { fetchTicketsForOrder } from "./ticket.fetcher";

export const ticketMainQueryKey = "tickets";

const useTicketForOrder = (orderId: OrderId | undefined) =>
    useApiResources<Ticket>(
        [ticketMainQueryKey, orderId],
        fetchTicketsForOrder(orderId),
        {
            enabled: !!orderId,
        }
    );

export { useTicketForOrder };
