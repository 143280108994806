import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { RemSize, translation } from "@towni/common";
import { isAfter } from "date-fns";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { LackoColorContext, lackoColors } from "./lacko-color-context";
import { LackoDiscoverMoreSection } from "./sections/lacko-discover-more-section";
import { LackoEnglishToursSection } from "./sections/lacko-english-tours-section";
import { LackoExperienceNatureSection } from "./sections/lacko-experience-nature-section";
import { LackoGoodToKnowSection } from "./sections/lacko-good-to-know-section";
import { LackoKidsMaidPromoSection } from "./sections/lacko-kids-maid-section";
import { LackoKidsStrangeThingsPromoSection } from "./sections/lacko-kids-strange-things-section";
import { LackoKidsTwoSection } from "./sections/lacko-kids-two-section";
import { LackoLostBraceletSection } from "./sections/lacko-lost-bracelet";
import { LackoPromoSection } from "./sections/lacko-promo-section";
import { LackoStayTheNightTwoSection } from "./sections/lacko-stay-the-night-2-section";
import { LackoStayTheNightSection } from "./sections/lacko-stay-the-night-section";

type Props = {
    title?: string;
};

const LackoStoreFront = (props: Props) => {
    const vh100 = use100vh();
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <LackoColorContext.Provider value={lackoColors}>
                <Helmet>
                    <title>{props.title ?? "Läckö Slott"}</title>
                </Helmet>
                <AppPage
                    pageId={appPageIdFactory("lacko-store-front")}
                    crossAxis={isDesktop ? "center" : undefined}
                    anchors={[]}
                    header={
                        <FlexRow
                            fillParentWidth
                            shrink={0}
                            mainAxis="flex-end"
                            crossAxis="center"
                            padding={{ leftRight: 20, topBottom: 10 }}
                            css={{
                                position: "absolute",
                                zIndex: 50,
                            }}>
                            <CurrentUserIcon theme="PRIMARY" />
                        </FlexRow>
                    }>
                    {/* Header Image >>>> */}
                    <FlexColumn
                        fillParentWidth
                        shrink={0}
                        padding={{ top: 55, leftRight: 32 }}
                        height={vh100 * 0.65}
                        maxHeight={675}
                        background={{
                            imageUrl:
                                "https://we-are-alfred.imgix.net/destinations/lacko-slott-destination-poster.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                            fit: "COVER",
                        }}>
                        <TextBox
                            text="Ett litet universum"
                            color={"#ffffff"}
                            size={1.5 as RemSize}
                            css={{
                                textShadow: "0px 4px 20px rgba(5, 32, 72, 0.6)",
                            }}
                        />
                        <VerticalDivider size="3XS" />
                        <TextBox
                            text="Läckö Slott & naturum"
                            color={"#ffffff"}
                            size={2.25 as RemSize}
                            weight="900"
                            css={{
                                textShadow: "0px 4px 20px rgba(5, 32, 72, 0.6)",
                            }}
                        />
                    </FlexColumn>
                    {/* <<<< Header Image */}

                    {/* Content >>>> */}
                    <FlexColumn
                        fillParentWidth
                        maxWidth={800}
                        padding={{ leftRight: 20 }}>
                        <VerticalDivider XL />
                        <TextBox
                            text={translation({
                                sv: "Upplevelser utöver det vanliga",
                                en: "Experiences beyond the ordinary",
                            })}
                            size={1.5 as RemSize}
                            padding={
                                isDesktop ? { right: 20 } : { leftRight: 20 }
                            }
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={translation({
                                sv: "Läckö är en plats att spendera tid på. Här finns historia, barnturer, god mat och fin natur.",
                                en: "Läckö is a place to spend time. Here you will find history, children's tours, good food and beautiful nature.",
                            })}
                            padding={
                                isDesktop ? { right: 20 } : { leftRight: 20 }
                            }
                            size={1.125 as RemSize}
                        />
                        <VerticalDivider />
                        <VerticalDivider XL />
                        {/* >> Höst och vinter på Läckö */}
                        <TextBox
                            text={
                                isAfter(new Date(), new Date(2023, 4, 15))
                                    ? translation({
                                          sv: "Sommar på Läckö",
                                          en: "Summer at Läckö",
                                      })
                                    : translation({
                                          sv: "Våren på Läckö",
                                          en: "Spring at Läckö",
                                      })
                            }
                            size={2}
                            weight="900"
                            padding={{ left: 0 }}
                        />
                        <VerticalDivider S />
                        <LackoPromoSection />
                        <VerticalDivider S />
                        {/* 
                                    Helgfrukost 9 & 15 oktober, product__beVLjII4mrEybZ1C6J8S
                                    Föredrag naturnära skogsbruk, product_1MsqiX8Ng4LXUTLsNt8-
                                    Vad döljer sig på Lurö i Vänern? Föredrag på naturum den 10 november kl 18.30, product__LTalfmWyPesMe4Jl0-O5
                                    Läckö vaknar 1673, product__7Uarul8W87VVQCJzeCAj
                                */}
                        {/* <LackoFall2022Section /> */}
                        {/* <FlexRow
                            css={{
                                borderRadius: 10,
                                backgroundColor: "#E5D3B8",
                                padding: 40,
                                paddingLeft: 30,
                            }}>
                            <TextBox
                            text={translation({
                                sv: "🍂 Höstlovsaktiviteter utannonseras snart...",
                                en: "🍂 Activities for the autumn break will be announced soon...",
                            })}
                            size={2}
                                weight="400"
                                css={{ opacity: 0.7 }}
                                padding={{ left: 0 }}
                                />
                                </FlexRow>
                                <VerticalDivider XL />
                            <VerticalDivider XL /> */}
                        {/* << Höst och vinter på Läckö */}
                        {/* >> Nästa sommar */}
                        <VerticalDivider XXL />
                        <TextBox
                            text={translation({
                                sv: "På återseende till sommaren...",
                                en: "See you in the summer...",
                            })}
                            size={2}
                            weight="900"
                            padding={{ left: 0 }}
                        />
                        <VerticalDivider S />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                opacity: 0.8,
                                filter: "grayscale(30%)",
                                background: theme.colors.primary.light.asString,
                                borderRadius: 15,
                            }}>
                            {/* <FlexColumn css={{ paddingLeft: 20 }}>
                                <LackoGuidedToursSection />
                                <VerticalDivider S />
                            </FlexColumn> */}
                            {/* <LackoEntresSection />
                            <VerticalDivider S /> */}
                            <LackoLostBraceletSection />
                            <VerticalDivider S />
                            <LackoKidsTwoSection />
                            <VerticalDivider S />
                            <LackoKidsMaidPromoSection />
                            <VerticalDivider S />
                            <LackoKidsStrangeThingsPromoSection />
                            <VerticalDivider S />
                            <LackoStayTheNightSection />
                            <VerticalDivider />
                            <LackoStayTheNightTwoSection />
                            <VerticalDivider S />
                            <LackoExperienceNatureSection />
                            <VerticalDivider size={isDesktop ? "L" : "S"} />
                            <LackoGoodToKnowSection />
                            <VerticalDivider S />
                            <VerticalDivider S />
                            <LackoEnglishToursSection />
                            <VerticalDivider S />
                            <FlexColumn crossAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "Upplev mer lokalt",
                                        en: "Experience more local",
                                    })}
                                    size={2}
                                    weight="700"
                                    align="center"
                                />
                                <VerticalDivider M />
                                <TextBox
                                    text={translation({
                                        sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                        en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                                    })}
                                    color={theme.colors.black.light50}
                                    size={1.125}
                                    weight="400"
                                    align="center"
                                    css={{
                                        lineHeight: "1.6rem",
                                    }}
                                />
                                <VerticalDivider XXL />
                                <LackoDiscoverMoreSection />
                                <VerticalDivider />
                            </FlexColumn>
                        </FlexColumn>
                        <VerticalDivider XXL />
                        <VerticalDivider XXL />
                    </FlexColumn>
                    {/* <<<< Content */}
                </AppPage>
            </LackoColorContext.Provider>
        </WideScreenWrapper>
    );
};

export { LackoStoreFront };
