import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
    AccommodationType,
    GetResponse,
    ProviderId,
    emptyArrayOf,
} from "@towni/common";

const useAccommodationTypesQuery = (params?: { providerId?: ProviderId }) => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<AccommodationType[]> = async ({ signal }) => {
        const queryParams = [
            ...(params?.providerId
                ? [["providerId", params.providerId] as const]
                : []),
        ] as const;

        const queryString = queryParams.length
            ? `?${queryParams.map(([key, value]) => `${key}=${value}`).join("&")}`
            : "";

        const data = await fetchClient.get<GetResponse<AccommodationType>>({
            route: `/resources/accommodations/types${queryString}`,
            customConfig: {
                signal,
            },
        });

        return data.items ?? emptyArrayOf<AccommodationType>();
    };
    return useQuery({
        queryKey: ["accommodation-types", params?.providerId],
        queryFn,
    });
};

export { useAccommodationTypesQuery };
