import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
    GetResponse,
    StorageItemImage,
    UploadId,
    isImageContentType,
    isUploadId,
    unique,
} from "@towni/common";

const useStorageItemImagesForUpload = (uploadId: UploadId | undefined) => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<StorageItemImage[]> = async ({ signal }) => {
        if (!uploadId) throw new Error("missing upload id");
        const data = await fetchClient.get<GetResponse<StorageItemImage>>({
            route: `/storage-items/for-upload/${encodeURIComponent(uploadId)}`,
            customConfig: { signal },
        });
        // Verify content types om retrieved items
        // Make sure they are all images
        if (data.items.some(item => !isImageContentType(item.contentType))) {
            throw new Error(
                "There are image items retrieved not matching contentType. " +
                    unique(data.items.map(item => item.contentType)).join(", "),
            );
        }
        return data.items;
    };
    return useQuery<StorageItemImage[]>({
        queryKey: ["storage-items", uploadId],
        queryFn,
        enabled: isUploadId(uploadId),
    });
};

export { useStorageItemImagesForUpload };
