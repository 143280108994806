import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import { KronocampingDiscoverMoreSection } from "../kronocamping/sections/kronocamping-discover-more-section";
import { LastMileCarSection } from "./sections/last-mile-car-section";
import { OneSquareLinkSectionElBikeStart } from "./sections/one-square-link-section-elbike-from-start";
import { OneSquareLinkSectionElCar } from "./sections/one-square-link-section-elcar";
import { OneSquareLinkSectionSmart } from "./sections/one-square-link-section-smart";
import { SmartGiftCardStart } from "./sections/smart-giftcard-start";

type Props = {
    title?: string;
};

const SmartStoreFrontStart = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>{props.title ?? "SMaRT Resande"}</title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory("smart-store-front")}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-start"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/destination-smart_resande.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <VerticalDivider L />
                    <TextBox
                        text="Smart resande"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Boka elbilar, elcyklar och sista-milen-bilen!"
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider XL />
                    <TextBox
                        text="Hållbar mobilitet – från elbilar till elcyklar"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Vi vill att det ska vara hållbart, smart och roligt att ta sig runt i bygden. Därför hjälps vi åt och testar nya former av resande för framtidens mobilitet. Utforska och boka ditt sätt att resa på. Alla som bokar och svarar på en undersökning har också chans att vinna presentkort på upp till 1500kr."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider XL />

                    <TextBox
                        text="Elbilar att hyra"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Många sevärdheter och besöksmål i Västra Skaraborg ligger naturnära på landsbygden. Med el i tanken kommer du modernt och hållbart fram dit du ska. Kombinera med tåg och cykel, eller kanske sista-milen-bilen! "
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />

                    <OneSquareLinkSectionElCar />

                    <VerticalDivider L />
                    <LastMileCarSection
                        productId={"product__IG8ZXjoF9TW197TdJKI8" as ProductId}
                        actionText={translation({
                            sv: "Se & Boka",
                            en: "Book now!",
                        })}
                    />

                    <VerticalDivider L />

                    <TextBox
                        text="Elcyklar och turer"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Med elcykel kan du upptäcka mer av landskapet och besöksmålen i närheten. Cyklarna finns att hyra på olika platser runt om i Skaraborg under sommaren. "
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareLinkSectionElBikeStart />
                    <VerticalDivider XL />
                    <SmartGiftCardStart />
                    <VerticalDivider XL />
                    <TextBox
                        text="Vad är SMaRT projektet?"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="SMaRT står för Sustainable Mobility for Rural and Urban Transport = ungefär Hållbar rörlighet för lantliga och urbana transporter.  SMaRT har som huvudsyfte att stimulera ett förändrat resebeteende och förmå fler att välja hållbara alternativ. "
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />

                    <OneSquareLinkSectionSmart />
                    <VerticalDivider XL />
                    <FlexColumn
                        background={{ color: theme.colors.black.light80 }}>
                        <TextBox
                            text="Att hyra elbil under sommaren 2022 är ett test som genomförs i ett EU-finansierat projekt SMaRT.
                        Projektet medfinansieras av Västra Götalandsregionen, Sparbanken Skaraborg och Lidköping. "
                            color={theme.colors.black.light10}
                            padding={{ all: 20 }}
                            size={1.125 as RemSize}
                        />
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <FlexColumn crossAxis="center">
                        <TextBox
                            text={translation({
                                sv: "Upplev mer lokalt",
                                en: "Experience more local",
                            })}
                            size={2}
                            weight="700"
                            align="center"
                        />
                        <VerticalDivider M />
                        <TextBox
                            text={translation({
                                sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                            })}
                            color={theme.colors.black.light50}
                            size={1.125}
                            weight="400"
                            align="center"
                            css={{
                                lineHeight: "1.6rem",
                            }}
                        />
                    </FlexColumn>
                    <VerticalDivider XXL />
                    <KronocampingDiscoverMoreSection />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                {/* <<<< Content */}
            </AppPage>
        </WideScreenWrapper>
    );
};

export { SmartStoreFrontStart };
