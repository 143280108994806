import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    faClipboardCheck,
    faHeart,
    faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { translation } from "@towni/common";

const UspInfoBlock = () => {
    const theme = useTheme();
    return (
        <FlexColumn>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faClipboardCheck}
                        color={theme.colors.primary}
                        size="M"
                        fixedWidth={true}
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={translation({
                                sv: "Boka direkt, säkra din plats",
                                en: "Book directly, secure your spot",
                            })}
                            userSelect="text"
                            size={"M"}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={translation({
                            sv: "Utforska och beställ direkt här. Mindre köande. Snabbt och enkelt säkrar du din bokning.",
                            en: "Explore and order directly here. Less queuing. Quickly and easily you secure your booking.",
                        })}
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                    <VerticalDivider M />
                </FlexColumn>
            </FlexRow>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faUsers}
                        color={theme.colors.primary}
                        size="M"
                        fixedWidth={true}
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={translation({
                                sv: "Dela och upplev tillsammans",
                                en: "Share and experience together",
                            })}
                            userSelect="text"
                            size={"M"}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={translation({
                            sv: "Dela gärna din bokning eller beställning. Tillsammans blir det roligare!",
                            en: "Feel free to share your booking or order. Together it becomes more fun!",
                        })}
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                    <VerticalDivider M />
                </FlexColumn>
            </FlexRow>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faHeart}
                        color={theme.colors.primary}
                        size="M"
                        fixedWidth={true}
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={translation({
                                sv: "Klart på studs, bara dyka upp",
                                en: "Ready on the spot, just show up",
                            })}
                            userSelect="text"
                            size={"M"}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={translation({
                            sv: "Du får bekräftelse på momangen, där finns all information du behöver inför besöket. Smidigt!",
                            en: "You will receive confirmation of the appointment, which contains all the information you need before the visit. Smooth!",
                        })}
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                </FlexColumn>
            </FlexRow>
        </FlexColumn>
    );
};

export { UspInfoBlock };
