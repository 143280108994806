import { AppPage } from "@@/pages/app-page";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { RemSize } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { BauerAdventuresSection } from "./sections/bauer-adventure-section";
import { BauerBreakfastSection } from "./sections/bauer-breakfast-section";
import { BauerCampingInNatureSection } from "./sections/bauer-camping-in-nature-section";
import { BauerMoreFromSection } from "./sections/bauer-more-from-section";
import { BauerPopularFoodSection } from "./sections/bauer-popular-food-section";
import { BauerTimeTogether } from "./sections/bauer-time-together";
import { BauerUniqueFoodSection } from "./sections/bauer-unique-food-section";
import { BauerVinterMysSection } from "./sections/bauer-vinter-mys-section";
import { OneSquareLinkSectionOutdoor } from "./sections/one-square-link-section-outdoor-food";
import { OneSquareLinkSectionTakeAway } from "./sections/one-square-link-section-take-away";
type Props = {
    title?: string;
};

const BauerGardenstoreFront = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>{props.title ?? "Bauergården"}</title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory("bauer-garden-i-bunn-storefront")}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/bauer-top-poster-2x.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <TextBox
                        text="Bauergården"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Stanna och upplev den trollska platsen"
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider XL />

                    <BauerVinterMysSection />
                    <VerticalDivider XL />
                    <BauerCampingInNatureSection />
                    <VerticalDivider XL />
                    <BauerBreakfastSection />
                    <VerticalDivider XL />
                    <TextBox
                        text="Take away"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Njut av utvalda klassiker hemma eller i husbilen. Du kan hämta din måltid från klockan 12 de dagar då köket är öppet. 
                        Välkommen!"
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <OneSquareLinkSectionTakeAway
                        kicker={"TAKE AWAY • RESTAURANG"}
                    />
                    <VerticalDivider XL />
                    <BauerPopularFoodSection />
                    <VerticalDivider XL />
                    <BauerUniqueFoodSection />
                    <VerticalDivider XL />
                    <BauerAdventuresSection />
                    <VerticalDivider XL />
                    <OneSquareLinkSectionOutdoor kicker={"UTOMHUS • ELD"} />
                    <VerticalDivider XL />
                    <BauerTimeTogether />
                    <VerticalDivider XL />
                    <BauerMoreFromSection />
                    <VerticalDivider XL />
                </FlexColumn>
            </AppPage>
        </WideScreenWrapper>
    );
};

export { BauerGardenstoreFront };
