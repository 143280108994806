import * as React from "react";
import { RequiredMeContext } from "./required-me.context";
import { WithMe } from "./with-me";
type Props = {
    readonly children?: React.ReactNode;
};
const RequiredMeProvider = ({ children }: Props) => {
    return (
        <WithMe>
            {me => (
                <RequiredMeContext.Provider value={me}>
                    {children}
                </RequiredMeContext.Provider>
            )}
        </WithMe>
    );
};

export { RequiredMeProvider };
