import { AccountIcon } from "@@/shared/account-icon";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { Icon } from "@@/shared/icons/icon";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { RemSize, translation } from "@towni/common";

type Props = {
    type?: "unAuthorized" | "unAuthenticated";
};

const AccessDeniedPage = (props: Props) => {
    const theme = useTheme();
    return (
        <>
            <GlobalMaxWidthCss />
            <FlexColumn
                fillParent
                crossAxis="center"
                css={{ backgroundColor: theme.colors.black.light95.asString }}>
                <FlexRow
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="center"
                    css={{ backgroundColor: theme.colors.white.asString }}
                    padding={{ leftRight: 20, topBottom: 20 }}>
                    <Spacer width={40} grow={1} shrink={0} />
                    <Spacer width={40} grow={1} shrink={0}>
                        <FlexRow
                            fillParentWidth
                            mainAxis="flex-end"
                            crossAxis="center">
                            <AccountIcon
                                disableShadow
                                color={theme.colors.default.text.withAlpha(0.6)}
                            />
                        </FlexRow>
                    </Spacer>
                </FlexRow>
                <FlexRow
                    fillParentWidth
                    mainAxis="center"
                    crossAxis="center"
                    css={{ paddingTop: 10, flex: 1 }}>
                    <Icon
                        icon={faLock}
                        size={4 as RemSize}
                        color={theme.colors.black.light40}
                    />
                </FlexRow>

                <FlexColumn
                    mainAxis="flex-start"
                    crossAxis="center"
                    css={{ flex: 2 }}>
                    <TextBox
                        text={translation({
                            sv: "Åtkomst nekad",
                            en: "Access denied",
                        })}
                        weight="900"
                        size="3XL"
                        padding={{ all: 20 }}
                    />
                    {props.type === "unAuthenticated" ? (
                        <TextBox
                            text={translation({
                                sv: "Du kanske behöver logga in?",
                                en: "Have you tried login in?",
                            })}
                            weight="700"
                            size="L"
                            align="center"
                            padding={{ all: 40 }}
                        />
                    ) : null}
                    {props.type === "unAuthorized" ? (
                        <TextBox
                            text={translation({
                                sv: "Du har inte åtkomst till efterfrågad resurs. Är du inloggad på rätt konto?",
                                en: "You don't have access to the requested resource. Are you logged in on the correct account?",
                            })}
                            weight="700"
                            align="center"
                            size="L"
                            padding={{ all: 40 }}
                        />
                    ) : null}
                </FlexColumn>
            </FlexColumn>
        </>
    );
};
export { AccessDeniedPage };
