import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import {
    useIsTranslatableEmpty,
    useTranslate,
} from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    BlockContent,
    PageBlock,
    addAlphaToHexColor,
    isHexColor,
} from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: [BlockContent, BlockContent];

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

type CardProps = {
    imageWidth: number;
    content: BlockContent;
    block: PageBlock;
    defaultTextShadow: string;
    defaultBorderRadius: number;
};

const _CardMobile = (props: CardProps) => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const providerId = usePageViewContext(context => context.providerId);
    const { imageWidth: maxWidth, block, defaultBorderRadius } = props;
    const theme = useTheme();
    const translate = useTranslate();
    const textColor = block.textColor ?? theme.colors.default.text;
    const textColorWithAlpha = isHexColor(textColor)
        ? addAlphaToHexColor(textColor, 0.5)
        : textColor.withAlpha(0.5);

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <FlexColumn css={props.content.inactive ? inactiveCss : undefined}>
                <GridCardContainer aspectRatio={"3:4"}>
                    <FlexColumn
                        fillParent
                        css={{
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-2-small",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: maxWidth,
                            },
                        }}
                    />
                </GridCardContainer>
                <FlexColumn padding={{ top: 8, leftRight: 2 }}>
                    <TextBox
                        text={props.content.title}
                        // lineClamp={isMobile ? 1 : 2}
                        weight="800"
                        size={1}
                        color={textColor}
                        css={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                    />
                    <Conditional
                        when={!isTranslatableEmpty(props.content.subtitle)}>
                        <VerticalDivider XXS />
                        <TextBox
                            text={props.content.subtitle}
                            lineClamp={2}
                            weight="400"
                            size={0.75}
                            color={textColorWithAlpha}
                            css={{
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                            }}
                        />
                    </Conditional>
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

const _CardDesktop = (props: CardProps) => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const providerId = usePageViewContext(context => context.providerId);
    const {
        imageWidth: maxWidth,
        block,
        defaultTextShadow,
        defaultBorderRadius,
    } = props;
    const theme = useTheme();
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const translate = useTranslate();

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <GridCardContainer
                aspectRatio={isSmall ? "4:3" : "1:1"}
                css={props.content.inactive ? inactiveCss : undefined}>
                <FlexColumn
                    fillParent
                    css={{
                        borderRadius: defaultBorderRadius,
                        position: "relative",
                        overflow: "hidden",
                        label: "content-grid-card-2-small",
                    }}
                    background={{
                        fit: "COVER",
                        imageReference: props.content.poster,
                        color:
                            props.content.backgroundColor ??
                            theme.colors.default.background.asString,
                        imageSize: {
                            imageWidth: maxWidth,
                        },
                    }}>
                    <FlexColumn
                        fillParent
                        crossAxis="flex-start"
                        mainAxis="space-between"
                        padding={{ all: 20 }}
                        background={{ linearGradient: darkeningGradient }}>
                        <FlexRow fillParentWidth mainAxis="flex-end">
                            <StoreFrontCardPill
                                text={props.content.actionText}
                                accentColor={
                                    "accentColor" in props.content &&
                                    props.content.accentColor
                                        ? props.content.accentColor
                                        : block.accentColor
                                }
                            />
                        </FlexRow>
                        <FlexColumn
                            mainAxis="flex-end"
                            crossAxis="stretch"
                            css={{ width: "100%", overflow: "hidden" }}>
                            <TextBox
                                text={props.content.title}
                                lineClamp={1}
                                weight="800"
                                size={isSmall ? 1.3 : 1.4}
                                color={
                                    "textColor" in props.content &&
                                    props.content.textColor
                                        ? props.content.textColor
                                        : theme.colors.white
                                }
                                css={{
                                    textShadow: defaultTextShadow,
                                }}
                            />
                            <Conditional
                                when={
                                    !isTranslatableEmpty(props.content.subtitle)
                                }>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.subtitle}
                                    lineClamp={2}
                                    weight="400"
                                    size={isSmall ? 0.725 : 0.9}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
            </GridCardContainer>
        </Link>
    );
};

const ContentGrid_2_Small = (props: Props) => {
    const { width } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });
    const {
        pageInnerWidth: maxWidth,
        defaultGridGap,
        defaultTextShadow,
        defaultBorderRadius,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();

    return (
        <LayoutGrid
            gap={defaultGridGap}
            // padding={{ all: gridCardGap }}
            gridTemplateColumns={"1fr 1fr"}
            className={props.className}
            css={{
                maxWidth: maxWidth * 0.65,
                paddingLeft: 20,
                paddingRight: 20,
                width: width ?? "100%",
                label: "content_grid_2_small",
            }}>
            <Conditional when={isMobile}>
                <_CardMobile
                    imageWidth={250}
                    content={props.content[0]}
                    block={block}
                    defaultTextShadow={defaultTextShadow}
                    defaultBorderRadius={defaultBorderRadius}
                />
                <_CardMobile
                    imageWidth={250}
                    content={props.content[1]}
                    block={block}
                    defaultTextShadow={defaultTextShadow}
                    defaultBorderRadius={defaultBorderRadius}
                />
            </Conditional>
            <Conditional when={!isMobile}>
                <_CardDesktop
                    imageWidth={800}
                    content={props.content[0]}
                    block={block}
                    defaultTextShadow={defaultTextShadow}
                    defaultBorderRadius={defaultBorderRadius}
                />
                <_CardDesktop
                    imageWidth={800}
                    content={props.content[1]}
                    block={block}
                    defaultTextShadow={defaultTextShadow}
                    defaultBorderRadius={defaultBorderRadius}
                />
            </Conditional>
        </LayoutGrid>
    );
};

export { ContentGrid_2_Small };
