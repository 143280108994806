import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import { emptyArrayOf, GetResponse, Provider, unique } from "@towni/common";

const fetchProviders =
    (providerIdsOrSlugs: string[]): QueryFunction<Provider[]> =>
    async ({ signal }) => {
        if (!providerIdsOrSlugs.length) return emptyArrayOf<Provider>();
        const data = await apiFetchClient.get<GetResponse<Provider>>({
            route: `/providers/?ids=${encodeURIComponent(
                unique(providerIdsOrSlugs).join(",")
            )}`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };
export { fetchProviders };
