import {
    createSocketEventListeners,
    useSocket,
} from "@@/shared/sockets/use-socket";
import {
    TicketId,
    TicketSocketEvent,
    UserId,
    ticketSocketEventTypes,
} from "@towni/common";
import React from "react";

/**
 * Listen to realtime ticket updates for given tickets
 */
const useTicketEvents = (params: {
    onTicketEvent: (event: TicketSocketEvent, room: string | undefined) => void;
    verbose?: boolean;
    ticketIds: TicketId[];
}) => {
    const [{ onTicketEvent, verbose }] = React.useState(params);
    const socketListeners = React.useMemo(() => {
        if (!params.ticketIds.length) return undefined;
        return [
            ...createSocketEventListeners<TicketSocketEvent>({
                eventTypes: [...ticketSocketEventTypes],
                rooms: params.ticketIds,
                onEvent: onTicketEvent,
            }),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.ticketIds]);

    return useSocket(socketListeners, { verbose });
};
const useTicketEventForUser = (params: {
    onTicketEvent: (event: TicketSocketEvent, room: string | undefined) => void;
    verbose?: boolean;
    userId?: UserId;
}) => {
    const [{ onTicketEvent, verbose }] = React.useState(params);
    const socketListeners = React.useMemo(() => {
        if (!params.userId) return undefined;
        return [
            ...createSocketEventListeners<TicketSocketEvent>({
                eventTypes: [...ticketSocketEventTypes],
                rooms: [params.userId],
                onEvent: onTicketEvent,
            }),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.userId]);

    return useSocket(socketListeners, { verbose });
};
export { useTicketEventForUser, useTicketEvents };
