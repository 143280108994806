import * as React from "react";
import { Size } from "react-virtualized";
import { useElementSize } from "./use-element-size";

type Props = {
    readonly style?: React.CSSProperties;
    readonly className?: string;
    readonly children: (size: Size) => React.ReactNode;
};
const AutoSizerInFlex = (props: Props) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [containerSize] = useElementSize({
        targetRef: containerRef,
    });

    return (
        <div
            ref={containerRef}
            className={props.className}
            style={{
                display: "block",
                flex: 1,
                ...props.style,
            }}>
            {props.children?.({
                width: containerSize?.width ?? 0,
                height: containerSize?.height ?? 0,
            })}
        </div>
    );
};

export { AutoSizerInFlex };
