import { useCart } from "@@/carts/multi-carts/cart.context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    getChildOptionals,
    isOrderItemBuyable_V2,
    ProductId,
    Translatable,
    translation,
} from "@towni/common";
import { useMemo } from "react";
import { BookableExtraOrderItemRow } from "./bookable-extra-order-item-row";

type Props = {
    productIdsToIgnore: ProductId[];
    title?: Translatable;
};
const BookableExtrasOrderItems = (props: Props) => {
    const cart = useCart();
    const theme = useTheme();
    const orderItems = useMemo(
        () =>
            cart.orderItems.filter(
                item =>
                    isOrderItemBuyable_V2(item) &&
                    !props.productIdsToIgnore?.includes(item.productId),
            ),
        [cart.orderItems, cart],
    );
    // if (orderItems.length === 0) return null;

    return (
        <FlexColumn fillParentWidth padding={{ all: 20, top: 10 }}>
            <Conditional
                when={!!props.title}
                render={() => (
                    <>
                        <TextBox
                            weight="700"
                            text={translation({
                                sv: "Tillagda tillval",
                                en: "Added extras",
                            })}
                        />
                        <VerticalDivider S />
                    </>
                )}
            />
            <ForEach
                itemOf={orderItems}
                divider={<VerticalDivider />}
                whenEmpty={
                    <>
                        <FlexColumn
                            fillParentWidth
                            crossAxis="center"
                            radius={10}
                            background={{
                                color: theme.colors.black.light95,
                            }}>
                            <VerticalDivider XL />
                            <TextBox
                                color={theme.colors.black.light50}
                                text={translation({
                                    sv: "Inga tillval valda ",
                                    en: "No extras selected",
                                })}
                            />
                            <VerticalDivider XL />
                        </FlexColumn>
                    </>
                }>
                {(orderItem, _index) => {
                    return (
                        <BookableExtraOrderItemRow
                            orderItem={orderItem}
                            picked={getChildOptionals(
                                orderItem,
                                cart.orderItems,
                            )}
                        />
                    );
                }}
            </ForEach>
        </FlexColumn>
    );
};

export { BookableExtrasOrderItems };
