import { LanguageCode, languageCodes } from "@towni/common";
import React, { createContext, useEffect, useMemo } from "react";
import { useLanguageContext } from "./language-context";

type State = {
    availableLanguages: LanguageCode[];
};

type ContextState = State;

const AvailableLanguagesContext = createContext<ContextState | undefined>(
    undefined
);

const AvailableLanguagesProvider = (props: {
    languages?: LanguageCode[];
    children?: React.ReactNode;
    ignoreParentContext?: boolean;
}) => {
    const {
        availableLanguages: parentAvailableLanguages,
        language,
        setLanguage,
    } = useLanguageContext();
    const context = useMemo(
        () => ({
            availableLanguages:
                props.languages ?? parentAvailableLanguages ?? languageCodes,
        }),
        [props.languages, parentAvailableLanguages]
    );

    useEffect(() => {
        // If current language is not available, set to first available language of english or swedish
        if (context.availableLanguages.includes(language)) return;
        if (context.availableLanguages.includes("en")) {
            setLanguage("en");
            return;
        }
        setLanguage("sv");
    }, [context.availableLanguages, language, setLanguage]);

    return (
        <AvailableLanguagesContext.Provider value={context}>
            {props.children}
        </AvailableLanguagesContext.Provider>
    );
};

const useAvailableLanguagesContext = () => {
    const context = React.useContext(AvailableLanguagesContext);
    if (context === undefined) {
        throw new Error(
            "useLanguageContext must be used within a AvailableLanguagesProvider"
        );
    }
    return context;
};

export { AvailableLanguagesProvider, useAvailableLanguagesContext };
