import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { useLanguageContext } from "@@/settings/language-context";
import { LanguageCode } from "@towni/common";

const NavenLanguagePicker = () => {
    const { language, setLanguage } = useLanguageContext();

    return (
        <SelectPicker<LanguageCode>
            data={[
                { value: "sv", name: "Svenska - Swedish" },
                { value: "en", name: "English" },
            ]}
            selectedValue={language}
            onSelect={item => setLanguage(item.value)}
        />
    );
};

export { NavenLanguagePicker };
