import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { dropDownPortalTarget } from "@@/shared/dropdown-portal-target";
import { FlexRow } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormComponents } from "@@/shared/form/form-components";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { usePhoneRegionCodes } from "@@/shared/text/use-phone-region-codes";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import { faMobile } from "@fortawesome/pro-regular-svg-icons";
import {
    ColorItem,
    Padding,
    PhoneNumber,
    RemSize,
    Translatable,
    generateId,
    phoneNumberZodSchema,
    translation,
} from "@towni/common";
import { parsePhoneNumber } from "awesome-phonenumber";
import { Draft } from "immer";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactSelect from "react-select";

type Value = PhoneNumber | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    readonly fontSize?: RemSize;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly hideIcon?: boolean;
    readonly hideErrors?: boolean;
    /** Visar fältet som required, även om det inte krävs enligt zod schemat. */
    readonly showAsRequired?: boolean;
    readonly onErrorsUpdate?: (errors: string[]) => void;
    // Css properties
    readonly autoComplete?: string;
    readonly padding?: Padding;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
};

const Form2PhoneNumberInput = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const [inputKey, setInputKey] = useState(generateId);
    const [prefixKey, setPrefixKey] = useState(generateId);
    const FormComponents = useFormComponents<State>();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: formId,
    });

    const phoneCountryCodes = usePhoneRegionCodes();
    const hasErrors = field.errors.length > 0;

    const initialPhoneNumber = useMemo(() => {
        if (field.initialValue) {
            const phoneNumber = parsePhoneNumber(field.initialValue);
            return phoneNumber;
        }
        return undefined;
    }, [field.initialValue]);
    const [countryCode, setCountryCode] = useState(
        phoneCountryCodes.find(
            item =>
                item.regionCode === (initialPhoneNumber?.regionCode ?? "SE"),
        )?.countryCode,
    );
    const options = phoneCountryCodes.map(item => ({
        value: item.countryCode,
        label: item.name,
    }));
    const defaultOption = options.find(item => item.value === countryCode);

    const onValidationFailedRef = useRef(props.onErrorsUpdate);
    onValidationFailedRef.current = props.onErrorsUpdate;

    useEffect(() => {
        onValidationFailedRef.current?.(field.errors);
    }, [field.errors]);

    return (
        <FormComponents.TextInput<PhoneNumber>
            key={inputKey}
            hideErrors={props.hideErrors}
            fieldId={props.fieldId}
            getter={state => {
                const value = props.getter(state);
                const phoneNumber = parsePhoneNumber(value || "", {
                    regionCode:
                        phoneCountryCodes.find(
                            item => item.countryCode === countryCode,
                        )?.regionCode ?? "SE",
                });
                return phoneNumber.valid
                    ? (phoneNumber.number.significant as Value)
                    : value;
            }}
            fieldSchema={phoneNumberZodSchema}
            setter={props.setter}
            autoComplete={props.autoComplete}
            charFilters={/[^0-9+]/g}
            className={props.className}
            containerCss={[
                { paddingTop: 4.5, paddingBottom: 4.5 },
                props.containerCss,
            ]}
            description={props.description}
            disabled={props.disabled}
            formId={props.formId}
            hideDescriptionAfterInput={props.hideDescriptionAfterInput}
            labelDescription={props.labelDescription}
            inputCss={[
                {
                    minWidth: 40,
                    flex: 1,
                },
                props.inputCss,
            ]}
            multiline={false}
            showAsRequired={props.showAsRequired}
            onChangePreProcess={_value => {
                if (typeof _value === "undefined") return _value;
                const startsWithPlus = _value.startsWith("+");
                const startsWithDoubleZero = _value.startsWith("00");
                const value = startsWithDoubleZero
                    ? _value.replace(/^00/g, "+")
                    : _value;
                const parsed = (() => {
                    try {
                        if (startsWithPlus || startsWithDoubleZero) {
                            return parsePhoneNumber(value);
                        }
                        return parsePhoneNumber(value, {
                            regionCode:
                                phoneCountryCodes.find(item => {
                                    return item.countryCode === countryCode;
                                })?.regionCode ?? "SE",
                        });
                    } catch {
                        return undefined;
                    }
                })();

                const output = (parsed?.number?.e164 || undefined) as
                    | PhoneNumber
                    | undefined;

                if (
                    parsed?.valid &&
                    parsed?.countryCode &&
                    Number(countryCode?.replace("+", "")) !==
                        parsed?.countryCode
                ) {
                    setCountryCode(`+${parsed.countryCode}`);
                    setPrefixKey(generateId());
                }

                if (parsed?.valid && (startsWithDoubleZero || startsWithPlus)) {
                    setInputKey(generateId());
                }

                return output;
            }}
            label={props.label}
            preElement={
                <FlexRow
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    <ReactSelect
                        key={prefixKey}
                        options={options}
                        defaultValue={defaultOption}
                        menuPortalTarget={dropDownPortalTarget}
                        isDisabled={props.disabled}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        noOptionsMessage={() => (
                            <TextBox
                                text={translation({
                                    sv: "Landskod saknas",
                                    en: "No country code found",
                                    de: "Kein Ländercode gefunden",
                                })}
                            />
                        )}
                        formatOptionLabel={(option, { context }) => {
                            /*
                            menu - dropdown
                            value - value displayed
                        */
                            switch (context) {
                                case "value":
                                    return (
                                        <FlexRow>
                                            <Conditional when={!props.hideIcon}>
                                                <Icon
                                                    icon={faMobile}
                                                    color={
                                                        hasErrors
                                                            ? theme.colors
                                                                  .danger
                                                                  .asString
                                                            : undefined
                                                    }
                                                />
                                                <HorizontalDivider XS />
                                            </Conditional>
                                            <TextBox
                                                size={1}
                                                text={option.value}
                                            />
                                        </FlexRow>
                                    );
                                case "menu":
                                default:
                                    return option.label;
                            }
                        }}
                        styles={{
                            singleValue: current => ({
                                ...current,
                                ...(hasErrors
                                    ? {
                                          color: theme.colors.danger.asString,
                                      }
                                    : {}),
                            }),
                            menu: current => ({
                                ...current,
                                width: "fit-content",
                            }),
                            input: current => ({
                                ...current,
                                padding: 0,
                                ...(hasErrors
                                    ? {
                                          color: theme.colors.danger.asString,
                                      }
                                    : {}),
                            }),
                            valueContainer: current => ({
                                ...current,
                                padding: 0,
                                paddingLeft: 3,
                                paddingRight: 3,
                            }),
                            dropdownIndicator: current => ({
                                ...current,
                                padding: 0,
                            }),
                            control: current => ({
                                ...current,
                                color: theme.colors.danger.asString,
                                borderColor: theme.colors.transparent.asString,
                                backgroundColor:
                                    theme.colors.transparent.asString,
                                "&:hover": {
                                    borderColor:
                                        theme.colors.transparent.asString,
                                },
                            }),
                        }}
                        onChange={option => {
                            setCountryCode(option?.value);
                        }}
                    />
                </FlexRow>
            }
        />
    );
};

export { Form2PhoneNumberInput };
export type { Props as Form2TextInputProps };
