import { MILLISECONDS } from "@towni/common";
import * as React from "react";

const useWaitForIt = (
    waitFor: unknown,
    delay: MILLISECONDS = 100 as MILLISECONDS,
) => {
    const [isWaiting, setIsWaiting] = React.useState(delay > 0);

    React.useEffect(() => {
        setIsWaiting(delay > 0 || !waitFor);
        const timeout = setTimeout(() => {
            setIsWaiting(false);
        }, delay);
        return () => clearTimeout(timeout);
    }, [waitFor, delay]);

    return [isWaiting] as const;
};

const useDelay = (delay: MILLISECONDS = 100 as MILLISECONDS) =>
    useWaitForIt(undefined, delay);

export { useDelay, useWaitForIt };
