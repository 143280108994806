import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    AccessKeyId,
    GetResponse,
    Order,
    OrderGroupId,
    OrderProjection,
    emptyArrayOf,
} from "@towni/common";
import { useMemo } from "react";

const mainQueryKey = "orders";
const mainRoute = "orders";

const useOrdersForOrderGroup = (
    orderGroupId?: OrderGroupId,
    accessKeyId?: AccessKeyId,
): readonly [OrderProjection[], UseQueryResult<GetResponse<Order>>] => {
    const fetchClient = useFetchClient();
    const fetcher: QueryFunction<GetResponse<Order>> = ({ signal }) => {
        return fetchClient.get<GetResponse<Order>>({
            route: `/${mainRoute}/for-order-group/${encodeURIComponent(
                orderGroupId ?? "",
            )}${accessKeyId ? `?ak=${accessKeyId}` : ""}`,
            customConfig: { signal },
        });
    };

    const queryResult = useQuery<GetResponse<Order>>({
        queryKey: [mainQueryKey, orderGroupId],
        queryFn: fetcher,
        enabled: !!orderGroupId,
    });

    return useMemo(
        () =>
            [
                queryResult.data?.items || emptyArrayOf<Order>(),
                queryResult,
            ] as const,
        [queryResult],
    );
};

export { useOrdersForOrderGroup };
