import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import {
    IsoAndUnixTimestamp,
    RemSize,
    asDate,
    capitalCase,
    getMonthShortNameInSwedishFromDate,
    getWeekdayShortNameInSwedishFromDate,
} from "@towni/common";
import { isToday } from "date-fns";
import * as React from "react";

type Props = {
    readonly onToggle: (date: IsoAndUnixTimestamp | undefined) => void;
    readonly date: IsoAndUnixTimestamp;
    readonly status: "SELECTED" | "DISABLED" | "AVAILABLE" | "PASSED";
};

const DateSelectCardPure = React.memo(
    ({ status, date, onToggle: toggle }: Props) => {
        return <DateSelectCard status={status} date={date} onToggle={toggle} />;
    },
);
DateSelectCardPure.displayName = "DateSelectCardPure";

const DateSelectCard = ({ status, date, onToggle: toggle }: Props) => {
    const theme = useTheme();
    const disabled = status === "DISABLED";
    const _isToday = isToday(asDate(date));
    const styling = css({
        display: "block",
        border: 2,
        borderColor: "transparent",
        borderRadius: 6,
        fontSize: "1rem",
        backgroundColor: "transparent",
        cursor: "pointer",
        minWidth: 60,
    });

    const disabledStyling = css([
        styling,
        {
            opacity: 1,
            border: _isToday
                ? `2px solid ${
                      theme.isLightTheme
                          ? theme.colors.black.light95.asString
                          : theme.colors.black.light05.asString
                  }
                    }`
                : `2px solid ${
                      theme.isLightTheme
                          ? theme.colors.black.light85.asString
                          : theme.colors.black.light15.asString
                  }`,
            backgroundColor: theme.isLightTheme
                ? theme.colors.black.light95.asString
                : theme.colors.black.light05.asString,
            cursor: "default",
        },
    ]);

    const selectedStyling = css([
        styling,
        {
            border: `2px solid ${theme.colors.primary.asString}`,
            backgroundColor: theme.colors.primary.asString,
        },
    ]);

    const availableStyling = css([
        styling,
        {
            backgroundColor: _isToday
                ? theme.isLightTheme
                    ? theme.colors.quaternary.light.light95.asString
                    : theme.colors.quaternary.light.light05.asString
                : theme.isLightTheme
                  ? theme.colors.white.asString
                  : theme.colors.black.asString,
            border: _isToday
                ? `2px solid ${theme.colors.quaternary.asString}`
                : `2px solid ${
                      theme.isLightTheme
                          ? theme.colors.primary.main.value.withAlpha(0.8)
                                .asString
                          : theme.colors.black.light20.asString
                  }`,
            color: theme.colors.black.light50.asString,
        },
    ]);

    const passedStyling = css([
        styling,
        { opacity: 0.2, color: theme.colors.default.text.value.asCssHsla },
    ]);

    const dayColor =
        status === "SELECTED"
            ? theme.isLightTheme
                ? theme.colors.white
                : theme.colors.black.light05
            : status === "AVAILABLE"
              ? theme.colors.black.light50
              : theme.isLightTheme
                ? theme.colors.black.light75
                : theme.colors.black.light25;

    const color =
        status === "SELECTED"
            ? theme.isLightTheme
                ? theme.colors.white
                : theme.colors.black.light05
            : status === "AVAILABLE"
              ? theme.isLightTheme
                  ? theme.colors.black.light30
                  : theme.colors.black.light70
              : theme.isLightTheme
                ? theme.colors.black.light75
                : theme.colors.black.light25;

    let currentStyling = passedStyling;
    switch (status) {
        case "SELECTED":
            currentStyling = selectedStyling;
            break;
        case "AVAILABLE":
            currentStyling = availableStyling;
            break;
        case "DISABLED":
            currentStyling = disabledStyling;
            break;
        default:
            currentStyling = passedStyling;
            break;
    }
    const theDate = asDate(date);
    return (
        <a
            css={currentStyling}
            data-testid={`date-select-card-${theDate.getFullYear()}-${(
                theDate.getMonth() + 1
            )
                // Get YYYY-MM-DD as string in sv (but actually local :/) time zone
                .toLocaleString()
                .padStart(2, "0")}-${theDate
                .getDate()
                .toLocaleString()
                .padStart(2, "0")}`}
            onClick={() => {
                if (!disabled)
                    //&& !passed)
                    toggle(status === "SELECTED" ? undefined : date);
            }}>
            <FlexColumn
                shrink={0}
                grow={1}
                mainAxis="center"
                crossAxis="center"
                padding={{ topBottom: 5, leftRight: 7 }}
                css={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                }}>
                <TextBox
                    text={capitalCase(
                        getWeekdayShortNameInSwedishFromDate(theDate),
                    )}
                    size={0.625 as RemSize}
                    weight="600"
                    color={dayColor}
                    css={{
                        marginBottom: -2,
                    }}
                />
                <TextBox
                    text={theDate.getDate().toString()}
                    case={"UPPERCASE"}
                    size={1.5 as RemSize}
                    weight="800"
                    color={color}
                    css={{
                        marginBottom: -2,
                        ...(status === "DISABLED"
                            ? {
                                  position: "relative",
                                  "&::after": {
                                      borderBottom:
                                          "0.1em solid hsl(0, 0%, 75%)",
                                      content: `""`,
                                      left: -3,
                                      marginTop: "calc(0.1em / 2 * -1)",
                                      position: "absolute",
                                      right: -3,
                                      top: "50%",
                                  },
                              }
                            : {}),
                    }}
                />
                <TextBox
                    text={getMonthShortNameInSwedishFromDate(theDate)}
                    case={"UPPERCASE"}
                    size={0.875 as RemSize}
                    weight="700"
                    color={color}
                />
            </FlexColumn>
        </a>
    );
};

export { DateSelectCard };
