import { useSizeTracker } from "@@/shared/use-size-tracker";
import { WidthAndHeight } from "@@/shared/width-and-height";
import { assertNever, IsoAndUnixTimestamp } from "@towni/common";
import { HzDateItem } from "./hz-date-item";
import { HzItemData } from "./hz-item-data";
import { HzSquashItem } from "./hz-squash-item";

type Props = {
    readonly index: number;
    readonly dataLength: number;
    readonly data: HzItemData;
    readonly onSizeChange?: (size: WidthAndHeight) => void;
    readonly toggler: (date: IsoAndUnixTimestamp | undefined) => void;
    readonly className?: string;
};

const HzItem = (props: Props) => {
    const { data, onSizeChange, index, toggler } = props;
    const [elementRef] = useSizeTracker(onSizeChange);

    if (data._type === "SQUASH")
        return <HzSquashItem ref={elementRef} data={data} index={index} />;

    if (data._type === "DATA")
        return (
            <HzDateItem
                index={index}
                ref={elementRef}
                className={props.className}
                data={data}
                toggleDate={toggler}
            />
        );

    assertNever(data);
    throw new Error("unknown data type; " + data._type);
};

export { HzItem };
