import { useBookingContext } from "@@/products/bookables/booking-context";
import {
    BookableSession,
    BookableSessionId,
    TimeRange,
    Translatable,
    asDate,
    convertLegacyBookableSettings,
    isSessionAvailable,
    minSessionTimeRange,
    translation,
} from "@towni/common";
import { isPast, subMinutes } from "date-fns";

const generateMessages = (
    originalSession: BookableSession,
    timeRange: TimeRange,
) => {
    const messages = [];

    const settings = convertLegacyBookableSettings(
        originalSession.bookableSettings,
    );

    if (
        !settings.allowBookingDuringSession &&
        isPast(asDate(timeRange.start))
    ) {
        messages.push(
            translation({
                sv: "⚠️ Start har passerat",
                en: "⚠️ Start time has passed",
            }),
        );
    }

    const pastBefore = settings.allowBookingDuringSession
        ? asDate(timeRange.end)
        : asDate(timeRange.start);

    if (
        !!settings.advanceNotice &&
        settings.advanceNotice > 0 &&
        isPast(subMinutes(pastBefore, settings.advanceNotice))
    ) {
        messages.push(
            translation({
                sv: `⚠️ Varseltid (${settings.advanceNotice}min) passerad`,
                en: `⚠️ Prep time (${settings.advanceNotice}) has passed`,
            }),
        );
    }

    if (isPast(asDate(timeRange.end))) {
        messages.push(
            translation({
                sv: "⚠️ Tiden är passerad, är du säker du vill boka?",
                en: "⚠️ Endtime has passed, are you sure you want to book?",
            }),
        );
    }
    return messages;
};

//Checks if session is bookable, if on premise booking it will give messages why.
const useCheckSessionBookableStatus = () => {
    const { originalSessions, isOnPremiseBooking } = useBookingContext(
        context => ({
            originalSessions: context.originalSessions,
            isOnPremiseBooking: context.isOnPremiseBooking,
        }),
    );
    const check = (
        sessionId?: BookableSessionId,
    ): CheckSessionBookableStatus => {
        if (!isOnPremiseBooking)
            return { isSessionBookableByEveryone: true, messages: [] };

        const originalSession = originalSessions.find(s => s._id === sessionId);

        if (!originalSession)
            return { isSessionBookableByEveryone: true, messages: [] };

        const converted = convertLegacyBookableSettings(
            originalSession.bookableSettings,
        );
        const timeRange = minSessionTimeRange(originalSession);
        const isSessionBookableByEveryone = isSessionAvailable({
            settings: converted,
            timeRange: timeRange,
            ignoreSessionAvailability: false,
        });

        if (!isSessionBookableByEveryone) {
            const messages = generateMessages(originalSession, timeRange);
            // browserLogger.info({
            //     isSessionBookableByEveryone,
            //     messages,
            //     originalSession,
            // });
            return { isSessionBookableByEveryone, messages };
        }

        return { isSessionBookableByEveryone: true, messages: [] };
    };
    return check;
};

type CheckSessionBookableStatus = {
    isSessionBookableByEveryone: boolean;
    messages: Translatable[];
};

export { useCheckSessionBookableStatus };
