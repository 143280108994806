/* eslint-disable react/prop-types */
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { AudioProvider } from "./audio/audio-context";
import { MeProvider } from "./authentication/me/me-context";
import { BackofficeProvider } from "./backoffice/backoffice-context";
import { OrderProvider } from "./backoffice/for-providers/orders/order-context";
import { CookieBanner } from "./cookie-banner/cookie-banner";
import { KeyboardListener } from "./keyboard-listener";
import { NavigationProvider } from "./pages/page-navigation/history-context";
import { ScrollPositionProvider } from "./pages/scroll-position-context";
import { StripeProvider } from "./payments/v2/stripe/stripe.context";
import { MainRoutes } from "./routes/main.routes";
import { ToastContextProvider } from "./toasts/context/toast-context";
import { ToastContainer } from "./toasts/toast-container";
// import { ReactQueryDevtools } from "react-query-devtools";
import { PreventValueScrollingOnNumberInputs } from "@@/prevent-scrolling-value-on-number-inputs";
import { SetTranslatedZodErrorMap } from "@@/set-translated-zod-error-map";
import { Provider as JotaiProvider } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/react/utils";
import { HelmetProvider } from "react-helmet-async";
import { MultiCartContextProvider } from "./carts/multi-carts/multi-cart.context";
import { ModalContextProvider } from "./modals/context/modal.context";
import { AppSettingsProvider } from "./settings";
import { CurrencySettingProvider } from "./settings/app-settings/currency-setting-context";
import { EnvironmentRuntimeSettingsProvider } from "./settings/app-settings/environment-settings-context";
import { AvailableLanguagesProvider } from "./settings/available-languages-context";
import { BuildVersionMajorMismatchHandler } from "./settings/build-version-major-mismatch-handler";
import { BuildVersionMinorMismatchHandler } from "./settings/build-version-minor-mismatch-handler";
import { ClientMemoryCacheProvider } from "./settings/cache-context";
import { LanguageProvider } from "./settings/language-context";
import { BreadCrumbPathProvider } from "./shared/bread-crumb-id-context";
import { DestinationProvider } from "./shared/destination-context";
import { MultiContextProvider } from "./shared/multi-provider";
import { PreviousPageProvider } from "./shared/pictures/previous-page-context";
import { WebpSupportProvider } from "./shared/pictures/webp-detection-context";
import { queryClient } from "./shared/queries/query-client";
import { BreakpointProvider } from "./shared/responsiveness/breakpoint-context";
import { WebSocketProvider } from "./shared/sockets/socket-context";
import { OnlineStatusProvider } from "./shared/use-online-status";
import { PageVisibilityProvider } from "./shared/visibility/visibility.context";
import { AppThemeProvider } from "./styles/app-theme-provider";

const onMinorMismatch = () => {
    alert("Minor mismatch");
};
const onMajorMismatch = () => {
    alert("Major mismatch");
};

const HydrateAtoms = ({ children }: { children: React.ReactNode }) => {
    useHydrateAtoms(new Map([[queryClientAtom, queryClient]]));
    return children;
};

const App: React.FunctionComponent = () => {
    return (
        <Sentry.ErrorBoundary
            showDialog={true}
            dialogOptions={{
                labelClose: "Stäng",
                labelEmail: "E-post",
                labelComments: "Vad hände?",
                labelName: "Namn",
                labelSubmit: "Skicka",
                title: "Det verkar som att vi har det lite struligt just nu",
                subtitle2: "Vårt team har meddelats",
                subTitle:
                    "Vårt team har meddelats men du får gärna lämna lite extra feedback. 🙂",
                successMessage: "Din feedback har skickats! Tack!",
            }}
            fallback={<span>Vi verkar ha det lite struligt just nu.. :(</span>}>
            <MultiContextProvider
                providers={[
                    [QueryClientProvider, { client: queryClient }],
                    JotaiProvider,
                    HydrateAtoms,
                    PageVisibilityProvider,
                    OnlineStatusProvider,
                    ToastContextProvider,
                    EnvironmentRuntimeSettingsProvider,
                    AppSettingsProvider,
                    NavigationProvider,
                    MeProvider,
                    WebSocketProvider,
                    LanguageProvider,
                    AvailableLanguagesProvider,
                    [BreadCrumbPathProvider, { node: "towni" }],
                    WebpSupportProvider,
                    [BreakpointProvider, { onMinorMismatch, onMajorMismatch }],
                    DestinationProvider,
                    CurrencySettingProvider,
                    AppThemeProvider,
                    HelmetProvider,
                    AudioProvider,
                    ModalContextProvider,
                    BackofficeProvider,
                    PreviousPageProvider,
                    ScrollPositionProvider,
                    [MultiCartContextProvider, { contextId: "root" }],
                    ClientMemoryCacheProvider,
                    OrderProvider,
                    StripeProvider,
                ]}>
                <PreventValueScrollingOnNumberInputs />
                <SetTranslatedZodErrorMap />
                <KeyboardListener />
                <Sentry.ErrorBoundary>
                    <ToastContainer />
                    <MainRoutes />
                </Sentry.ErrorBoundary>
                <CookieBanner />
                <BuildVersionMinorMismatchHandler />
                <BuildVersionMajorMismatchHandler />
            </MultiContextProvider>
        </Sentry.ErrorBoundary>
    );
};

export { App };
