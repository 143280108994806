import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { useCalculateObligatoryExtras } from "@@/products/bookables/booking-wizard/extras-and-optionals.tsx/use-is-booking-complete-with-extras-and-optionals";
import {
    PickedOptional,
    getChildOptionals,
    getChildQuantities,
    isBuyable,
    isOrderItemBookable,
    isProductOrderItem,
    isTimeRange,
    orderItemsFromSku,
    orderItemsOptional,
    sumPrices,
} from "@towni/common";
import { useBookingContext } from "../booking-context";
import { useCreateReservationFromBookingContext } from "./use-create-reservation";
/**
 * Adds bookable to cart, if unable goes to next step in wizard
 * Refactor to be go next
 * @return {*}
 */
const useAddBookableToCartOrGoNext = () => {
    const addToCartClick = useAddBookableToCart();
    const wizardDispatch = useWizardDispatch();

    const addToCartClickAndGoNext = () => {
        addToCartClick();
        wizardDispatch.goForward();
    };

    return addToCartClickAndGoNext;
};

/**
 * Adds bookable to cart
 */
const useAddBookableToCart = () => {
    const state = useBookingContext(context => ({
        sku: context.sku,
        product: context.product,
        provider: context.provider,
        timeRange: context.timeRange,
        session: context.session,
        messageToProvider: context.messageToProvider,
        bookable: context.bookable,
        freeBookable: context.freeBookable,
        optionValues: context.optionValues,
        settings: context.bookable.settings,
        requestedResources: context.requestedResources,
        pickedOptionalsMap: context.pickedOptionalsMap,
        resourcePickerEnabled: context.resourcePickerEnabled,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));

    const addExtras = useCalculateObligatoryExtras([]);

    const [createReservationFromBookingContext, { isLoading: _isDataLoading }] =
        useCreateReservationFromBookingContext();

    const cartContext = useCartContext(context => ({
        cart: context.cart,
        setReservationRequests: context.setReservationRequests,
        removeProductFromCart: context.removeProductFromCart,
        addItemToCart: context.addItemToCart,
        addItemsToCart: context.addItemsToCart,
        setBookableOptionValues: context.setBookableOptionValues,
    }));
    const [discountsForProduct, _discountsIsLoading] = useDiscountsForProduct(
        state.product,
    );

    const addToCartClick = (
        pickedOptionalsMap?: Map<string, PickedOptional[]>,
    ) => {
        const timeRange = state.timeRange;
        if (!timeRange || !isTimeRange(timeRange)) return;
        if (!state.session) return;

        const resourceReservationRequest = createReservationFromBookingContext(
            state.session,
        );

        const orderItems = orderItemsFromSku({
            sku: state.sku,
            product: state.product,
            provider: state.provider,
            quantity: {
                amount: state.sku.acquire.amount,
                value: 1,
            },
            pickedOptions: Array.from(
                (pickedOptionalsMap ?? state.pickedOptionalsMap).values(),
            ).flat(), //pickedOptions,
            discounts: discountsForProduct,
            messageToProvider: state.messageToProvider,
            time: timeRange,
            resourceReservations: resourceReservationRequest,
            bookableSettings: state.session.bookableSettings,
        });

        const extras = addExtras([
            ...orderItems,
            ...cartContext.cart.orderItems.filter(oi => isBuyable(oi)),
        ]);

        if (!orderItems.length) return;
        cartContext.setReservationRequests(resourceReservationRequest);
        cartContext.setBookableOptionValues(state.optionValues);
        cartContext.removeProductFromCart(state.product._id); // remove previous versions of items
        extras.forEach(e =>
            isProductOrderItem(e)
                ? cartContext.removeProductFromCart(e.productId)
                : null,
        ); // and add the updated items
        cartContext.addItemsToCart([
            ...orderItems,
            ...extras.filter(e => e.quantity.value > 0),
        ]);
        //  orderItems.forEach(cartContext.addItemToCart); // and add the updated items
    };

    return addToCartClick;
};

/**
 * Adds bookable to cart
 */
const useAddOptionalToCart = () => {
    const state = useBookingContext(context => ({
        sku: context.sku,
        product: context.product,
        provider: context.provider,
        timeRange: context.timeRange,
        session: context.session,
        messageToProvider: context.messageToProvider,
        bookable: context.bookable,
        freeBookable: context.freeBookable,
        optionValues: context.optionValues,
        settings: context.bookable.settings,
        requestedResources: context.requestedResources,
        pickedOptionalsMap: context.pickedOptionalsMap,
        resourcePickerEnabled: context.resourcePickerEnabled,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));

    const cartContext = useCartContext(context => ({
        cart: context.cart,
        setReservationRequests: context.setReservationRequests,
        removeProductFromCart: context.removeProductFromCart,
        addItemToCart: context.addItemToCart,
        addItemsToCart: context.addItemsToCart,
        removeItemFromCart: context.removeItemFromCart,
        setBookableOptionValues: context.setBookableOptionValues,
    }));

    const addToCartClick = (
        pickedOptionalsMap?: Map<string, PickedOptional[]>,
    ) => {
        const timeRange = state.timeRange;
        if (!timeRange || !isTimeRange(timeRange)) return;
        if (!state.session) return;

        const item = cartContext.cart.orderItems.find(isOrderItemBookable);
        if (!item) return;

        const child = getChildQuantities(item, cartContext.cart.orderItems);
        const childOptional = getChildOptionals(
            item,
            cartContext.cart.orderItems,
        );

        const total = sumPrices(child.map(r => r.price));
        childOptional.map(oi => oi._id).forEach(cartContext.removeItemFromCart); // and add the updated items

        const orderItems = orderItemsOptional({
            quantity: {
                amount: state.sku.acquire.amount,
                value: 1,
            },
            pickedOptions: Array.from(
                (pickedOptionalsMap ?? state.pickedOptionalsMap).values(),
            ).flat(), //pickedOptions,

            parent: item,
            pricePerItem: item.pricePerItem,
            totalPrice: total,
        });
        if (!orderItems.length) return;
        orderItems.forEach(cartContext.addItemToCart); // and add the updated items
    };

    return addToCartClick;
};

export {
    useAddBookableToCart,
    useAddBookableToCartOrGoNext,
    useAddOptionalToCart,
};
