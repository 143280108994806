import { useMe } from "@@/authentication/me/me-context";
import { Button } from "@@/shared/buttons";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox, TextInput } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { EmSize, Password, Translatable, translation } from "@towni/common";
import { useEffect, useState } from "react";
import {
    useClassicSignInDispatch,
    useClassicSignInState,
} from "../classic-sign-in-context";
import { useClassicSignInCommand } from "../commands/use-classic-sign-in-command";

type Props = {
    finish: () => void;
};
const ClassicSignInForm = (props: Props) => {
    const [me] = useMe();
    const theme = useTheme();
    const { password, username } = useClassicSignInState();
    const { setPassword, setUsername } = useClassicSignInDispatch();
    const classicSignIn = useClassicSignInCommand();
    const [errorMessage, setErrorMessage] = useState<Translatable>("");

    useEffect(() => {
        if (me || classicSignIn.isSuccess) {
            props.finish();
        }
    }, [me, classicSignIn.isSuccess]);
    return (
        <FlexColumn fillParentWidth shrink={0}>
            <TextInput
                fillParentWidth
                type="text"
                onValidationFailed={() => {
                    return false;
                }}
                autoComplete="username"
                disabled={classicSignIn.isPending}
                textInputStyling={css`
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                `}
                placeholder={translation({
                    sv: "Användarnamn",
                    en: "Username",
                })}
                onChange={setUsername}
            />
            <TextInput
                fillParentWidth
                type="password"
                onValidationFailed={() => {
                    return;
                }}
                disabled={classicSignIn.isPending}
                textInputStyling={css`
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-top: none;
                `}
                placeholder={translation({
                    sv: "Lösenord",
                    en: "Password",
                })}
                autoComplete="current-password"
                onChange={input => setPassword(input as Password)}
                errorMessage={errorMessage}
            />
            <VerticalDivider L />
            <Button
                fillWidth
                onClick={async () => {
                    try {
                        setErrorMessage("");
                        await classicSignIn.mutateAsync({
                            username,
                            password,
                        });
                    } catch (error) {
                        setErrorMessage(
                            translation({
                                sv: "Inloggning misslyckades, försök igen",
                                en: "Sign in failed, try again",
                            }),
                        );
                    }
                }}
                disabled={
                    !username ||
                    username.length < 5 ||
                    !password ||
                    password.length < 12
                }>
                {classicSignIn.isPending ? (
                    <Icon
                        icon={faSpinnerThird}
                        spin
                        color={theme.colors.primary.text}
                        relativeSize={1.1 as EmSize}
                    />
                ) : (
                    <TextBox
                        text={translation({
                            sv: "Logga in",
                            en: "Sign in",
                        })}
                        weight="700"
                        color={theme.colors.primary.text}
                    />
                )}
            </Button>
        </FlexColumn>
    );
};

export { ClassicSignInForm };
