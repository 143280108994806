import { Branded, genericId } from "@towni/common";

type ToastId = Branded<string, "ToastId">;
const toastIdPrefix = "toast__";
const {
    zodSchema: toastIdZodSchema,
    factory: _toastIdFactory,
    assert: isToastId,
} = genericId<ToastId>(toastIdPrefix);

const toastIdFactory = (custom?: `${string}__`): ToastId => {
    return `${_toastIdFactory()}__${custom ?? ""}` as ToastId;
};

const toastIdFromOrderIdFactory = (orderId: `${string}__`) =>
    `${toastIdPrefix}${orderId ?? ""}` as ToastId;

export {
    toastIdFactory,
    toastIdFromOrderIdFactory,
    isToastId,
    toastIdZodSchema,
};
export type { ToastId };
