import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { ModalTitle } from "@@/shared/text/modal-title";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useLocale } from "@@/translations/use-locale";
import { PositioningPortal } from "@codastic/react-positioning-portal";
import { useTheme } from "@emotion/react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import {
    IsoAndUnixTimestamp,
    Translatable,
    asDate,
    generateId,
    isoAndUnixFactory,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { useCallback, useEffect, useState } from "react";
import { DayPicker, type DayPickerSingleProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Except } from "type-fest";
import { ZodSchema } from "zod";

type PossibleValueTypes = IsoAndUnixTimestamp | undefined | null;
type DefaultValueType = IsoAndUnixTimestamp | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    readonly compact?: boolean;
    readonly disabled?: boolean;
    readonly datePickerProps?: Except<
        DayPickerSingleProps,
        "selected" | "onSelect" | "mode" | "disabled"
    >;
};

const createFormDatePickerComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2DatePicker = <
        Value extends PossibleValueTypes = DefaultValueType,
    >(
        props: Props<State, Value>,
    ) => {
        const theme = useTheme();
        const locale = useLocale();
        const [{ show, hide }, modalId] = useModal("day_picker_modal__");
        const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
        const isCompact = props.compact ?? true;
        const { formatDateMedium } = useDateTimeFormatter();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            fieldSchema: props.fieldSchema,
            formId: props.formId,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );

        const [_initialValue] = useState(field.value);
        const hasErrors = field.errors.length > 0;
        const [key, setKey] = useState(generateId);
        const [defaultMonth, _setDefaultMonth] = useState<Date | undefined>(
            asDate(field.value),
        );
        const setDefaultMonth = useCallback((date: Date | undefined) => {
            _setDefaultMonth(date);
            setKey(generateId());
        }, []);

        const [pickerIsOpen, setPickerIsOpen] = useState(false);
        const validationTrigger = useFormFieldValidation<State, Value>({
            field,
            initialValidationType: "manual",
        });
        const dayPicker = (
            <DayPicker
                key={`${defaultMonth?.toISOString()}_${key}`}
                {...(props.datePickerProps ?? {})}
                mode="single"
                defaultMonth={defaultMonth}
                disabled={props.disabled || field.isSubmitting}
                selected={asDate(field.value)}
                onDayBlur={(_date, _modifiers, _focusEvent) => {
                    field.setTouched(true);
                    validationTrigger();
                }}
                styles={{
                    row: {
                        borderTop: `1px solid ${theme.colors.transparent.asString}`,
                        borderBottom: `1px solid ${theme.colors.transparent.asString}`,
                    },
                }}
                modifiersStyles={{
                    selected: {
                        backgroundColor: theme.colors.primary.asString,
                        color: theme.colors.primary.text.asString,
                    },
                }}
                locale={locale}
                onSelect={date => {
                    const newValue = (
                        date instanceof Date
                            ? isoAndUnixFactory(date)
                            : undefined
                    ) as Value;
                    field.setValue(newValue);
                    field.setDirty(true);
                    field.setTouched(true);
                    if (isCompact) {
                        setPickerIsOpen(false);
                    }
                }}
            />
        );

        const pickerField = (
            <FlexRow
                crossAxis="center"
                onClick={() => setPickerIsOpen(value => !value)}
                css={{
                    cursor: "pointer",
                    backgroundColor: theme.colors.textInput.background.asString,
                    borderRadius: theme.radius,
                    border: `1px solid ${theme.colors.textInput.border.asString}`,
                }}>
                <TextBox
                    text={
                        field.value
                            ? formatDateMedium(field.value, {
                                  includeYearIfNotCurrent: true,
                              })
                            : translation({
                                  sv: "Välj ett datum",
                                  en: "Pick a date",
                              })
                    }
                    css={{
                        flex: 1,
                        padding: 15,
                    }}
                    color={
                        !field.value
                            ? theme.colors.textInput.placeholder.asString
                            : undefined
                    }
                />
                <HorizontalDivider />
                <Icon
                    icon={faCalendar}
                    css={{ paddingRight: 10 }}
                    fixedWidth
                    opacity={props.disabled ? 0.5 : 1}
                />
            </FlexRow>
        );

        useEffect(() => {
            if (!isSmallScreen) return;
            if (pickerIsOpen) show();
            else hide();
        }, [isSmallScreen, pickerIsOpen, show, hide]);

        const picker = isSmallScreen ? (
            <>
                {pickerField}
                <Modal
                    modalId={modalId}
                    onHide={() => {
                        setDefaultMonth(asDate(field.value));
                    }}
                    header={
                        <ModalHeader
                            modalId={modalId}
                            bottomBorder
                            title={
                                <ModalTitle
                                    text={translation({
                                        sv: "Välj ett datum",
                                        en: "Pick a date",
                                    })}
                                />
                            }
                        />
                    }>
                    <FlexColumn
                        fillParentWidth
                        css={{ padding: 20 }}
                        crossAxis="center"
                        mainAxis="center">
                        {dayPicker}
                    </FlexColumn>
                </Modal>
            </>
        ) : (
            <PositioningPortal
                isOpen={pickerIsOpen}
                onOpen={() => setPickerIsOpen(true)}
                onClose={() => {
                    setDefaultMonth(asDate(field.value));
                }}
                onShouldClose={() => setPickerIsOpen(false)}
                closeOnScroll={false}
                portalElement={<div style={{ zIndex: 33 }} />}
                portalContent={
                    <div
                        css={{
                            backgroundColor: "white",
                            borderRadius: 10,
                            padding: 5,
                            willChange: "filter",
                            transition: "opacity 0.2s ease-in-out",
                            filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                            opacity: pickerIsOpen ? 1 : 0,
                        }}>
                        {dayPicker}
                    </div>
                }>
                {pickerField}
            </PositioningPortal>
        );

        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                tag={`form-field_${props.fieldId}`}
                crossAxis="stretch">
                <Conditional when={!!props.label}>
                    <FlexRow
                        shrink={0}
                        crossAxis="center"
                        mainAxis="space-between">
                        <FieldTitle
                            htmlFor={props.fieldId}
                            padding={{ left: 2 }}
                            text={props.label ?? ""} // already checked with conditional above
                            required={field.isRequired}
                        />
                        <Conditional when={!!props.labelDescription}>
                            <HorizontalDivider XXS />
                            <FieldTitle
                                padding={{ left: 2 }}
                                text={props.labelDescription ?? ""} // already checked with conditional above
                                weight="400"
                                size="S"
                                css={{
                                    opacity: 0.5,
                                }}
                            />
                        </Conditional>
                    </FlexRow>
                    <VerticalDivider XS />
                </Conditional>
                {!isCompact ? (
                    <div
                        css={{
                            width: "100%",
                            backgroundColor: "white",
                            borderRadius: 10,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: theme.colors.textInput.border.asString,
                        }}>
                        {dayPicker}
                    </div>
                ) : (
                    <>{picker}</>
                )}
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        marginTop: 5,
                    }}
                />
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2DatePicker;
};
export { createFormDatePickerComponent };
