import { ImageUrl } from "@towni/common";
import { decode, isBlurhashValid } from "blurhash";
import { useEffect, useState } from "react";

const useBlurhash = (
    blurhash: string | undefined,
    options?: Partial<{
        /**
         * defaults to 50
         * @type {number}
         */
        resolutionX: number;
        /**
         * defaults to 50
         * @type {number}
         */
        resolutionY: number;
        /**
         * defaults to 1
         * @type {number}
         */
        punch: number;
    }>,
) => {
    const width = options?.resolutionX ?? 50;
    const height = options?.resolutionY ?? 50;
    const punch = options?.resolutionY ?? 1;

    const [dataUrl, setDataUrl] = useState<ImageUrl>();

    useEffect(() => {
        if (!blurhash || (blurhash && !isBlurhashValid(blurhash))) return;
        const pixels = decode(blurhash, width, height, punch);

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        const imageData = context?.createImageData(width, height);
        if (!context || !imageData) {
            // No context or no image data
            // Nothing to blurhash
            return;
        }
        imageData.data.set(pixels);
        context.putImageData(imageData, 0, 0);
        setDataUrl(canvas.toDataURL() as ImageUrl);
        context.clearRect(0, 0, width, height);
    }, [blurhash, height, punch, width]);

    return dataUrl;
};

export { useBlurhash };
