import { defaultBranding } from "@@/routes/shared/default-branding";
import { apiFetchClient } from "@@/shared/fetch-client";
import { lightColorTheme } from "@@/styles/themes/default.theme";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    Branding,
    BrandingDto,
    brandingFactory,
    GetSingleResponse,
    ProviderIdOrSlug,
} from "@towni/common";

const mainQueryKey = "brandings";

const useBrandingForProviderQuery = (
    providerIdOrSlug: ProviderIdOrSlug | undefined,
): UseQueryResult<Branding> => {
    return useQuery<Branding>({
        queryKey: [mainQueryKey, providerIdOrSlug],
        queryFn: async () => {
            const result = await apiFetchClient.get<
                GetSingleResponse<BrandingDto>
            >({
                route: `/providers/${encodeURIComponent(
                    providerIdOrSlug ?? "",
                )}/branding`,
            });
            return result.item
                ? brandingFactory(result.item, { light: lightColorTheme })
                : // It still only returns default branding
                  // if it makes a request, not if it is not run
                  // so if the query is disabled data is still undefined
                  defaultBranding;
        },

        enabled: !!providerIdOrSlug,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

export { useBrandingForProviderQuery };
