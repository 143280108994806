import { PillButton } from "@@/shared/buttons";
import { SerializedStyles } from "@emotion/react";
import { translation } from "@towni/common";
import * as React from "react";

const OpenResourceModalPillButton = ({
    onClick,
    children,
    disabled,
    styling,
}: {
    onClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    styling?: SerializedStyles;
}) => {
    return (
        <PillButton
            disabled={disabled}
            onClick={(!disabled && onClick) || undefined}
            description={translation({
                sv: "Ändra antal",
                en: "Change quantity",
            })}
            padding={{ topBottom: 10, leftRight: 20 }}
            fillParentWidth
            css={styling}>
            {children}
        </PillButton>
    );
};

export { OpenResourceModalPillButton };
