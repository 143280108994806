import { useStockBalanceSku } from "@@/backoffice/for-providers/products/stock-balance/use-stock-balance-sku";
import { CartItemAvailability } from "@@/carts/cart-item-availability";
import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { ProductOptionalsTextBox } from "@@/products/product-optionals-text-box";
import { ProductTitleTextBox } from "@@/products/product-title-text-box";
import { browserLogger } from "@@/settings";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { useTheme } from "@emotion/react";
import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import {
    OrderItemOptional_V2,
    ProductOrderItem_V2,
    RemSize,
    sumPrices,
    translation,
    unitTypeMap,
} from "@towni/common";
import * as React from "react";
import { CartItemMessageToProvider } from "./cart-item-message-to-provider";

type Props = {
    cartItem: ProductOrderItem_V2;
    optional: OrderItemOptional_V2[];
};
const CartItemRow = (props: Props) => {
    const theme = useTheme();
    const { cart, decreaseQuantity, increaseQuantity } = useCartContext(
        context => ({
            cart: context.cart,
            decreaseQuantity: context.decreaseQuantity,
            increaseQuantity: context.increaseQuantity,
        }),
    );

    const unitTranslate = useUnitTranslator();
    const { cartItem, optional } = props;
    const stockBalanceEnabled = !!cartItem.trackStockBalance;

    const [stockBalance, stockBalanceQuery] = useStockBalanceSku(
        {
            providerId: cartItem.providerId,
            skuId: cartItem.skuId,
            productId: cartItem.productId,
            cartId: cart._id,
        },
        !stockBalanceEnabled,
    );

    const totalPriceWithOptional = sumPrices([
        cartItem.price,
        ...optional.map(o => o.price),
    ]);

    return (
        <React.Fragment key={cartItem._id}>
            <FlexRow
                crossAxis="flex-start"
                shrink={0}
                fillParentWidth
                padding={{
                    leftRight: 20,
                    topBottom: 10,
                }}>
                <FlexRow shrink={0} tag="antal">
                    <TextBox
                        size="S"
                        padding={{ top: 1 }}
                        color={theme.colors.black.light20}
                        text={cartItem.quantity.value.toString()}
                    />
                    <HorizontalDivider S />
                </FlexRow>

                <FlexColumn shrink={1} grow={1}>
                    <FlexColumn
                        key={cartItem._id}
                        fillParentWidth
                        mainAxis="space-between"
                        crossAxis="stretch"
                        grow={1}
                        shrink={0}
                        tag="produkt_price_column">
                        <FlexRow
                            mainAxis="space-between"
                            crossAxis="center"
                            fillParentWidth
                            tag="product_price_container">
                            <FlexRow shrink={1} tag="product_title_container">
                                <ProductTitleTextBox
                                    meta={cartItem.meta}
                                    weight="700"
                                    size="S"
                                    lineClamp={1}
                                />
                            </FlexRow>
                            <HorizontalDivider />
                            <PriceTextBox
                                price={totalPriceWithOptional}
                                originalPrice={totalPriceWithOptional}
                                size="S"
                                color={theme.colors.black.light20}
                                reversed
                            />
                        </FlexRow>
                        <VerticalDivider XXS />
                        <FlexRow
                            mainAxis="space-between"
                            crossAxis="center"
                            fillParentWidth
                            tag="optionals_and_actions_row">
                            <FlexColumn
                                fillParentWidth
                                tag="optionals_and_quantity_container">
                                <ProductOptionalsTextBox
                                    pickedOptionals={optional.map(
                                        o => o.optional,
                                    )}
                                    size="XS"
                                    color={theme.colors.black.light20}
                                />
                                {cartItem.quantity.amount.unit.type !==
                                    unitTypeMap.pcs && (
                                    <>
                                        {optional.length ? (
                                            <VerticalDivider XS />
                                        ) : null}
                                        <TextBox
                                            size="XS"
                                            color={
                                                theme.colors.default.text
                                                    .light40
                                            }
                                            text={`${
                                                cartItem.quantity.amount.value
                                            } ${unitTranslate(
                                                cartItem.quantity.amount.value,
                                                cartItem.quantity.amount.unit,
                                                "long",
                                            )}`}
                                        />
                                    </>
                                )}
                            </FlexColumn>

                            <FlexRow
                                mainAxis="flex-end"
                                css={{
                                    marginRight: -5,
                                }}
                                tag="actions">
                                <Icon
                                    icon={
                                        cartItem.quantity.value > 1
                                            ? faMinus
                                            : faTrashAlt
                                    }
                                    padding={{
                                        all: 9,
                                    }}
                                    cursor="pointer"
                                    onClick={() => {
                                        browserLogger.warn(
                                            "Cart item decrease quantity",
                                            {
                                                cartItemId: cartItem._id,
                                                quantity:
                                                    cartItem.quantity.value,
                                            },
                                        );
                                        decreaseQuantity(cartItem._id);
                                    }}
                                    color={theme.colors.black.light60}
                                    size={
                                        (cartItem.quantity.value > 1
                                            ? 1
                                            : 0.9) as RemSize
                                    }
                                />
                                <HorizontalDivider S />
                                <Icon
                                    icon={faPlus}
                                    padding={{
                                        all: 9,
                                    }}
                                    cursor="pointer"
                                    color={theme.colors.primary}
                                    size={1 as RemSize}
                                    onClick={() =>
                                        increaseQuantity(cartItem._id)
                                    }
                                />
                            </FlexRow>
                        </FlexRow>
                        <Conditional
                            when={
                                stockBalanceEnabled &&
                                !stockBalanceQuery.isPending &&
                                !stockBalanceQuery.isError &&
                                stockBalance &&
                                stockBalance.quantity <
                                    cartItem.quantity.value *
                                        (cartItem.acquireType !== "BOOKABLE" &&
                                        cartItem.acquireType !== "ACCOMMODATION"
                                            ? cartItem.amount?.quantity || 1
                                            : 1)
                            }
                            render={() => (
                                <FlexRow
                                    css={{
                                        borderRadius: 5,
                                        backgroundColor:
                                            theme.colors.danger.light.asString,
                                        padding: 10,
                                    }}>
                                    <Conditional
                                        when={
                                            stockBalance &&
                                            stockBalance.quantity < 1
                                        }
                                        render={() => (
                                            <TextBox
                                                text={translation({
                                                    sv: `Den här produkten finns i ett begränsat antal. Och tyvärr är den just nu inte tillgänglig.`,
                                                    en: `This product is limited in stock. And unfortunately it is not available at the moment.`,
                                                })}
                                                color={theme.colors.danger}
                                                size={0.75}
                                            />
                                        )}
                                        else={() => (
                                            <TextBox
                                                text={translation({
                                                    sv: `Den här produkten finns i ett begränsat antal. För tillfället finns det inte ${cartItem.quantity.value} st att beställa. Du kan försöka minska antalet och beställa färre.`,
                                                    en: `This product is limited in stock. At the moment there is not ${cartItem.quantity.value} pcs available. You can try to decrease the amount and order less.`,
                                                })}
                                                color={theme.colors.danger}
                                                size={0.75}
                                            />
                                        )}
                                    />
                                </FlexRow>
                            )}
                        />
                        {cartItem.messageToProvider && (
                            <>
                                <VerticalDivider XXS />
                                <CartItemMessageToProvider
                                    cartItem={cartItem}
                                />
                            </>
                        )}
                    </FlexColumn>
                </FlexColumn>
            </FlexRow>
            <CartItemAvailability availability={cartItem.availability} />
            <HorizontalLine />
        </React.Fragment>
    );
};

export { CartItemRow };
