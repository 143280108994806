import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    ColorItem,
    ContentBlock,
    MetaImage,
    PickedOptional,
    Price,
    Provider,
    StorageItemImage,
    Translatable,
} from "@towni/common";
import { TicketDivider } from "../ticket-divider";
import { TicketCustomerViewHeader } from "../voucher/ticket-customer-view-header";
import { TicketGiftcardCustomerViewFooter } from "./ticket-giftcard-customer-view-footer";

type Props = {
    backgroundImage: MetaImage | StorageItemImage;
    logo?: MetaImage | StorageItemImage;
    title: Translatable;
    subtitle: Translatable;
    description: Translatable;

    //extraInformation
    //

    optionals?: PickedOptional[];
    contentBlocks?: ContentBlock[];

    /**
     * defaults to `left`
     */
    logoPlacement?: "left" | "right";
    code: string;
    link?: string;
    colorBehind?: ColorItem;
    provider: Provider | undefined;
    used: boolean;

    dataTestIds?: {
        containerId: string;
    };
    amount: Price;
    amountUsed: Price;
};

const borderRadius = 14;

const TicketGiftCardCustomerView = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    return (
        <FlexColumn
            fillParentWidth
            maxWidth={310}
            css={{
                position: "relative",
                borderTopLeftRadius: borderRadius,
                borderTopRightRadius: borderRadius,
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
                overflow: "hidden",
                opacity: props.used ? 0.6 : 1,
                backgroundColor: theme.colors.transparent.asString,
                willChange: "filter",
                filter: `drop-shadow(0px 3.80066px 9.50166px ${
                    theme.colors.black.value.withAlpha(0.15).asString
                }) ${props.used ? "grayscale(100%)" : ""}`,
            }}
            data-testid={props.dataTestIds?.containerId}>
            <TicketCustomerViewHeader
                logo={props.logo}
                title={props.title}
                subtitle={props.subtitle}
                backgroundImage={props.backgroundImage}
                logoPlacement={props.logoPlacement}
            />

            {/* {props.optionals?.map(c => {
                return (
                    <FlexColumn
                        key={c.optional._id}
                        fillParentWidth
                        css={{ position: "relative" }}
                        padding={{ leftRight: 20 }}
                        background={{ color: theme.colors.white }}>
                        <HorizontalLine />
                        <VerticalDivider S />
                        <TextBox
                            text={"Tillval"}
                            size={0.875}
                            weight="500"
                            color={theme.colors.black.light45}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={"+ " + translate(c.optional.meta.title)}
                            size={"M"}
                            weight="700"
                            color={theme.colors.black.light20}
                        />
                        <VerticalDivider M />
                    </FlexColumn>
                );
            })} */}

            <FlexColumn
                fillParentWidth
                css={{
                    position: "relative",
                    display: translate(props.description)?.trim()
                        ? "flex"
                        : "none",
                }}
                padding={{ leftRight: 20 }}
                background={{ color: theme.colors.white }}>
                <HorizontalLine />
                <VerticalDivider S />
                <TextBox
                    text={"Information"}
                    size={0.875}
                    weight="500"
                    color={theme.colors.black.light45}
                />
                <VerticalDivider XS />
                <TextBox
                    renderMarkdown
                    text={props.description}
                    size={0.875}
                    color={theme.colors.black}
                />
                <VerticalDivider M />
                <HorizontalLine />
            </FlexColumn>

            {props.contentBlocks?.map(contentBlock => {
                return (
                    <FlexColumn
                        key={contentBlock._id}
                        fillParentWidth
                        css={{ position: "relative" }}
                        padding={{ leftRight: 20 }}
                        background={{ color: theme.colors.white }}>
                        <VerticalDivider S />
                        <TextBox
                            text={contentBlock.title}
                            size={0.875}
                            weight="500"
                            color={theme.colors.black.light45}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            renderMarkdown
                            text={contentBlock.content}
                            size={0.875}
                            color={theme.colors.black}
                        />
                        <VerticalDivider M />
                        <HorizontalLine />
                    </FlexColumn>
                );
            })}

            <FlexRow
                fillParentWidth
                color={theme.colors.black.light60}
                background={{ color: theme.colors.white }}
                minHeight={60}
                mainAxis="center"
                padding={{ leftRight: 25, top: 15, bottom: 8 }}>
                <TextBox
                    text={`Detta är ett presentkort som löses in och ersätts hos ${translate(
                        props.provider?.meta.title,
                    )}`}
                    size={0.75}
                    align="center"
                    spin={!props.provider}
                    color={theme.colors.black.light60}
                />
            </FlexRow>
            <TicketDivider
                topBackgroundColor={theme.colors.white}
                bottomBackgroundColor={theme.colors.white}
                colorBehind={props.colorBehind}
            />
            <TicketGiftcardCustomerViewFooter
                code={props.code}
                link={props.link}
                amount={props.amount}
                amountUsed={props.amountUsed}
            />
        </FlexColumn>
    );
};

export { TicketGiftCardCustomerView };
