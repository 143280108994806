import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { RemSize } from "@towni/common";

const SmartGiftCardStart = () => {
    const theme = useTheme();
    return (
        <>
            <TextBox
                text="Vill du ha chans att vinna?"
                size={1.5 as RemSize}
                padding={{ leftRight: 20 }}
                weight="700"
            />
            <VerticalDivider XS />
            <TextBox
                text="Hjälp oss utveckla framtidens hållbara resande och ta chansen att vinna presentkort på hotellpaket och restaurang till ett värde på upp till 1500kr. Svara då på några korta frågor efter att du bokat och genomfört din aktivitet, så enkelt är det!"
                color={theme.colors.black.light30}
                padding={{ leftRight: 20 }}
                size={1.125 as RemSize}
            />
            <VerticalDivider ML />
            <FlexColumn
                padding={{ top: 20 }}
                fillParentWidth
                mainAxis="center"
                crossAxis="center">
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    radius={15}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    maxHeight={675}
                    minHeight={222}
                    maxWidth={335}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/smart-giftcard.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}></FlexColumn>
            </FlexColumn>
        </>
    );
};

export { SmartGiftCardStart };
