import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontHzProductCard } from "@@/store-fronts/shared/store-front-hz-product-card";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";

const BauerUniqueFoodSection: FC = () => {
    const getProductId = useProductIdWithFallback();
    // const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const picknick = React.useMemo(
        () => getProductId("product__I8e1qN-X9glSrYp2rXq_" as ProductId),
        []
    );
    const grill = React.useMemo(
        () => getProductId("product__wtI2Aj0Gs---AA84KJdO" as ProductId),
        []
    );
    const bastu = React.useMemo(
        () => getProductId("product__VZ_ZL4L3Ag56zR4tf7Dk" as ProductId),
        []
    );

    const productIds = [picknick, grill, bastu];

    return (
        <SectionArea
            title={translation({
                sv: "Unika matpaket",
                en: "Unique food",
            })}
            subtitle={translation({
                sv: "Med våra populära korgar bestämmer du själv vart och när du vill äta. Beställ gärna dagen innan!",
                en: "",
            })}
            sectionAreaId="bauer-unique-food-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid
                size={"M"}
                gap={10}
                padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <StoreFrontHzProductCard
                            key={id}
                            productId={id}
                            aspectRatio={"1:1"}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            reversed
                        />
                    );
                })}
                {/* {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            padding={{}}
                            key={id}
                            productId={id}
                            aspectRatio={"1:1"}
                            contentTop={index === 3 ? true : index % 2 === 0}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={theme.colors.primary}
                        />
                    );
                })} */}
            </SimpleGrid>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Boka",
        en: "Book",
    }),
    explore: translation({
        sv: "Beställ",
        en: "Book",
    }),
    seeTime: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
};

const actionTexts = [text.book, text.explore, text.seeTime];

export { BauerUniqueFoodSection };
