import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import {
    CheckBoxWithText,
    Paragraph,
    paragraphJsxElementFactory,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { useToast } from "@@/toasts/context/toast-context";
import { css, useTheme } from "@emotion/react";
import { shuffle, translation } from "@towni/common";
import { useEffect, useState } from "react";
const warningMessages = [
    translation({
        sv: "Nej sluta, du får inte slå på den!",
        en: "No stop, you can't turn it on!",
    }),
    translation({
        sv: "Nej, du får inte slå på den!",
        en: "No, you can't turn it on!",
    }),
    translation({
        sv: "Tänk två gånger innan du fortsätter!",
        en: "Think twice before proceeding!",
    }),
    translation({
        sv: "Detta är inte en bra idé!",
        en: "This is not a good idea!",
    }),
    translation({
        sv: "Var försiktig!",
        en: "Be careful!",
    }),
];

const dangerMessages = [
    translation({
        sv: "NEJ SA JAG!",
        en: "NO I SAID!",
    }),
    translation({
        sv: "DU KAN HA SÖNDER DEN",
        en: "YOU MIGHT BREAK IT",
    }),
    translation({
        sv: "NEEEEEEEEJ",
        en: "NOOOOOOOOO",
    }),
    translation({
        sv: "SLUTA NU!",
        en: "STOP RIGHT NOW!",
    }),
    translation({
        sv: "VAD GÖR DU?!",
        en: "WHAT ARE YOU DOING?!",
    }),
];

const rebootingSteps = [
    "REBOOTING...",
    "1. Formatting the servers",
    "2. Degrafing the icon factories",
    "3. Reordering the events",
    "4. Untangling the pixel spaghetti",
    "5. Refluffing the cloud cushions",
    "6. Polishing the bits and bytes",
    "7. Taking a byte-break (a little snack time!)",
    "8. Recalculating the gigawhatits",
    "9. Redefining quantum sandwiches",
    "10. Depixelating the resolutionary imageries",
    "11. Rerouting the digital traffic jams",
    "12. Compressing the pixel pickles",
    "13. Debugging the virtual fish tank",
    "14. Rebooting the cyber-coffee machine",
    "15. Wrangling the wild widgets",
];

const limit = 13;
const DoNotToggle = () => {
    const hasAlfredScope = useHasAlfredScope();
    const toast = useToast();
    const [toggled, setToggled] = useState(false);
    const [gameOver, setGameOver] = useState(false);

    const [amount, setAmount] = useState(0);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;

        if (toggled) {
            timeout = setTimeout(() => {
                if (toggled) {
                    setToggled(false);
                    setAmount(amount + 1);
                    if (amount < 5)
                        toast.warning({
                            message: shuffle(warningMessages)[0],
                        });
                    else if (amount < limit) {
                        toast.danger({
                            message: shuffle(dangerMessages)[0],
                        });
                    } else {
                        toast.danger({
                            message: "Du har förstört allt",
                        });
                        setGameOver(true);
                    }
                }
            }, 250);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [amount, toast, toggled]);

    if (!hasAlfredScope) return null;

    if (gameOver)
        return (
            <div
                css={css`
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.9);
                    z-index: 9999;
                `}>
                <div
                    css={css`
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100vw;
                        min-width: 300px;
                        transform: translate(-50%, -50%);
                    `}>
                    <FlexColumn fillParent mainAxis="center" crossAxis="center">
                        <TypingEffect
                            typingText={rebootingSteps.join("\n")}
                            typingSpeed={150}
                        />
                    </FlexColumn>
                </div>
            </div>
        );

    return (
        <FlexRow>
            <CheckBoxWithText
                checked={toggled}
                onChange={setToggled}
                text={translation({
                    sv: "⚠️ SLÅ EJ IGÅNG DENNA ⚠️",
                    en: "⚠️ DO NOT TURN THIS ON ⚠️",
                })}
            />
        </FlexRow>
    );
};

type TypingEffectProps = {
    typingText: string;
    typingSpeed: number;
};
const TypingEffect = (props: TypingEffectProps) => {
    const theme = useTheme();
    const { typingText, typingSpeed } = props;
    const [displayedText, setDisplayedText] = useState({ text: "", index: 0 });

    useEffect(() => {
        const timer = setInterval(() => {
            const index = displayedText.index;
            if (index < typingText.length) {
                setDisplayedText(prevText => {
                    return {
                        text: prevText.text + typingText.charAt(index),
                        index: prevText.index + 1,
                    };
                });
            } else {
                clearInterval(timer);
            }
        }, typingSpeed);

        return () => {
            clearInterval(timer);
        };
    }, [typingText, typingSpeed, displayedText]);

    const lines = displayedText.text.split("\n");
    return (
        <Paragraph
            content={lines.flatMap((line, index) => {
                const isLast = index === lines.length - 1;
                return [
                    paragraphTextFactory({
                        css: {
                            fontSize: `${1}rem`,
                            color: theme.colors.danger.asString,
                            fontWeight: 900,
                        },
                        text: line,
                    }),
                    isLast
                        ? paragraphJsxElementFactory({
                              element: (
                                  <span
                                      css={css`
                                          display: inline-block;
                                          width: 0.8em;
                                          height: 0.8em;
                                          margin-left: 5px;
                                          background-color: white;
                                          animation: blink 1s steps(2) infinite;
                                          @keyframes blink {
                                              from {
                                                  opacity: 0;
                                              }
                                          }
                                      `}></span>
                              ),
                          })
                        : undefined,
                    paragraphLineBreakFactory(),
                ];
                // return fee._hardCodedId;
            })}
        />
    );
};

export { DoNotToggle };
