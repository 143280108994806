import { apiFetchClient } from "@@/shared/fetch-client";
import {
    AccessKeyId,
    GetSingleResponse,
    ResourceGlobal,
    ResourceId,
} from "@towni/common";

const fetchResource =
    (resourceId: ResourceId | undefined, accessKeyId?: AccessKeyId) =>
    async () => {
        if (!resourceId) throw new Error("missing id");
        const data = await apiFetchClient.get<
            GetSingleResponse<ResourceGlobal>
        >({
            route: `/resources/${resourceId}${
                accessKeyId ? `?ak=${accessKeyId}` : ""
            }`,
        });
        return data.item || undefined;
    };
export { fetchResource };
