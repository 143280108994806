import { ContentCardView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/content-card-view";
import { ContentCircleView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/content-circle-view";
import { ContentPillView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/content-pill-view";
import { ContentSquaresWithoutTextView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/content-squares-without-text-view";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { assertTypeMatch, BlockContent, BlockContentSize } from "@towni/common";
import { useMemo } from "react";
import { IsEqual } from "type-fest";
import { usePageBlockViewContext } from "../../../../page-block-view-context-factory";

const ContentAutoView = (props: {
    content: BlockContent[];
    preferredContentSize?: BlockContentSize;
}): JSX.Element | null => {
    const content = props.content;
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { block } = usePageBlockViewContext();
    const numberOfItems = content.length;
    if (block.type !== "CONTENT_AUTO_PAGE_BLOCK")
        throw new Error(
            "Invalid block type for auto content view; " + block.type,
        );

    const contentViewToRender = useMemo(() => {
        const style = block.style || "cards";
        if (style === "circles") {
            // RETURN A CIRCLE VIEW
            return <ContentCircleView block={block} content={content} />;
        }
        if (style === "pills") {
            // RETURN A PILLS VIEW
            return <ContentPillView block={block} content={content} />;
        }
        if (style === "squaresWithoutText") {
            // RETURN A GRID WITH SQUARES WITHOUT TEXT VIEW
            return (
                <ContentSquaresWithoutTextView
                    block={block}
                    content={content}
                />
            );
        }

        // Make sure at compile time that style can only be cards now
        assertTypeMatch<IsEqual<typeof style, "cards">>();
        return <ContentCardView block={block} content={content} />;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block, numberOfItems, "1:1", isMobile, content]);

    return <>{contentViewToRender}</>;
};

export { ContentAutoView };
