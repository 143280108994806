import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import {
    ProductId,
    RemSize,
    Translatable,
    translation,
    unique,
} from "@towni/common";
import * as React from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../../shared/store-front-hz-product-card";

const HzSectionVouchers = () => {
    const getProductId = useProductIdWithFallback();

    const products: [ProductId, Translatable][] = React.useMemo(
        () => [
            // Voucher 4 personer
            [
                getProductId("product__UptSPqmNDkZZinLREyxE" as ProductId),
                translation({
                    sv: "Köp",
                    en: "Buy",
                }),
            ],
            // Voucher 2 pers
            [
                getProductId("product__MmC5aZdWXi6-xzpye1qN" as ProductId),
                translation({
                    sv: "Köp",
                    en: "Buy",
                }),
            ],
            // Vouchers 2 heat
            [
                getProductId("product__JHGLEwIYRIptj-DMGQ47" as ProductId),
                translation({
                    sv: "Köp",
                    en: "Buy",
                }),
            ],
        ],
        []
    );

    const hasDuplicates =
        unique(products.map(item => item[0])).length !== products.length;

    return (
        <SectionArea sectionAreaId="motorstadion-drop-in" padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {products.map(([id, actionText], index) => {
                    return (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : id}
                            cardSize={1.4 as RemSize}
                            aspectRatio="1:1"
                            actionText={actionText}
                            productId={id}
                            hideKicker
                        />
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { HzSectionVouchers };
