import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import {
    maxOf,
    Padding,
    Percentage,
    percentageToHuman,
    roundPercentage,
    sortBy,
    Translatable,
    VAT,
    vats,
} from "@towni/common";

type Props = {
    /**
     * Needs to round up to a listed VAT value \
     * else it will throw an Unsupported VAT error
     *
     * @type {Percentage | VAT}
     */
    selectedVat: Percentage | VAT;
    onChange: (vat: VAT) => void;
    readonly title?: Translatable;
    readonly description?: Translatable;
    readonly padding?: Padding;
};

const VatPicker = (props: Props) => {
    const requestedVat = roundPercentage(
        props.selectedVat as Percentage,
    ) as VAT;
    const selectedVat = vats.includes(requestedVat)
        ? requestedVat
        : maxOf(vats);

    return (
        <SelectPicker<VAT>
            padding={props.padding ?? { all: 16 }}
            selectedValue={selectedVat}
            data={vats
                .map(vat => ({
                    name: percentageToHuman(vat as Percentage),
                    value: vat,
                }))
                .sort(sortBy(item => item.value, "DESCENDING"))}
            onSelect={selection => props.onChange(selection.value)}
            description={props.description}
            title={props.title}
        />
    );
};

export { VatPicker };
