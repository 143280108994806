import { MILLISECONDS } from "@towni/common";
import { useCallback, useRef, useState } from "react";
import { useMountEffect } from "./use-mount-effect";

/**
 * @param {(T | (() => T))} initialState
 * @param {MILLISECONDS} [delay=200 as Milliseconds] defaults to 200ms
 */
const useDebouncedState = <T>(
    initialState: T | (() => T),
    delay: MILLISECONDS = 200 as MILLISECONDS,
) => {
    const [state, setState] = useState(initialState);

    const timeout = useRef<NodeJS.Timeout | null>(null);
    useMountEffect(() => () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    });

    const setDebouncedState = useCallback(
        (newValue: T) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            timeout.current = setTimeout(() => {
                setState(newValue);
            }, delay);
        },
        [delay, setState],
    );

    return [state, setDebouncedState] as const;
};
export { useDebouncedState };
