import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { WithQuery, WithQueryOptions } from "@@/shared/with-query";
import { UseQueryResult } from "@tanstack/react-query";
import { Provider, ProviderId, ProviderSlug, translation } from "@towni/common";
import * as React from "react";
import { useParams } from "react-router-dom";
import { MergeExclusive } from "type-fest";
import { providerMainQueryKey } from "../main-query-key";
import { fetchProvider } from "./provider.fetcher";

type Props = {
    options?: WithQueryOptions<Provider>;
    children: (
        data: Provider,
        query: UseQueryResult<Provider>,
    ) => React.ReactNode;
} & MergeExclusive<
    { providerIdOrSlug: ProviderSlug | ProviderId; fromUrl?: false },
    { fromUrl: true }
>;
const WithProvider = (props: Props) => {
    const urlParams = useParams<{
        providerId?: ProviderId;
        providerSlug?: ProviderSlug;
        providerIdOrSlug?: ProviderId | ProviderSlug;
    }>();
    const providerIdOrSlug =
        props.providerIdOrSlug ??
        urlParams.providerSlug ??
        urlParams.providerIdOrSlug;
    if (!providerIdOrSlug) {
        throw new Error("missing provider id or slug");
    }

    return (
        <WithQuery<Provider>
            queryKey={[providerMainQueryKey, providerIdOrSlug]}
            fetcher={fetchProvider(providerIdOrSlug)}
            options={{
                whenNotFound: (
                    <PageNotFound
                        reason={translation({
                            sv: `Kunde inte hitta "${providerIdOrSlug}"`,
                            en: `Could not find "${providerIdOrSlug}"`,
                        })}
                    />
                ),
                ...(props.options ?? {}),
            }}
            single>
            {props.children}
        </WithQuery>
    );
};

export { WithProvider };
