import * as React from "react";
import { useMemo } from "react";
import { useMatch } from "react-router-dom";

const BackofficeContext = React.createContext<
    | {
          isBackoffice: boolean;
      }
    | undefined
>(undefined);

const useIsBackoffice = () => {
    // const match1 = !!useMatch({ path: "/backoffice/*" });
    const isMatch = useMatch("/bo/*");
    // console.log({ isMatch });
    return !!isMatch;
};

type Props = {
    children?: React.ReactNode;
};
// Webp detection copied/based on https://stackoverflow.com/a/27232658
const BackofficeProvider = ({ children }: Props) => {
    const isBackoffice = useIsBackoffice();
    const state = useMemo(() => {
        return {
            isBackoffice,
        };
    }, [isBackoffice]);

    return (
        <BackofficeContext.Provider value={state}>
            {children}
        </BackofficeContext.Provider>
    );
};

const useBackofficeContext = () => {
    const context = React.useContext(BackofficeContext);
    if (context === undefined) {
        throw new Error(
            "useBackofficeContext must be used within a BackofficeContext"
        );
    }
    return context;
};

export { BackofficeProvider, useBackofficeContext, useIsBackoffice };
