import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { PageBlockId, PageId, ProviderId } from "@towni/common";
import { createContext, useContext, useEffect, useRef } from "react";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

type State = {
    pageId: PageId;
    isEditor: boolean;
    providerId: ProviderId | undefined;
    pageInnerWidth: number;
    pageMaxWidth: number;
    /**
     * defaults to 16px
     * @type {number}
     */
    defaultBorderRadius: number;
    defaultTextShadow: string;
    defaultGridGap: number;
};

type Actions = {
    onBlockClick?: (blockId: PageBlockId) => void | Promise<void>;
};

type ContextState = State & Actions;

const PageViewContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    pageId: PageId;
    isEditor?: boolean;
    providerId: ProviderId | undefined;
    onBlockClick?: (blockId: PageBlockId) => void | Promise<void>;
    children: React.ReactNode;
    pageInnerWidth?: number;
    pageMaxWidth?: number;
    /**
     * defaults to 16px
     * @type {number}
     */
    defaultBorderRadius?: number;
};

const PageViewContextProvider = (props: Props) => {
    const isMobile = usePageBreakpoint({ when: "📱" });

    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((_set, _get) => {
            return {
                pageId: props.pageId,
                providerId: props.providerId,
                onBlockClick: props.onBlockClick,
                isEditor: !!props.isEditor,
                pageInnerWidth: props.pageInnerWidth ?? 800,
                pageMaxWidth: props.pageMaxWidth ?? 1200,
                defaultBorderRadius: props.defaultBorderRadius ?? 16,
                defaultTextShadow: "0px 3px 6px rgba(0,0,0, 0.25)",
                defaultGridGap: isMobile ? 20 : 30,
            };
        }),
    );

    useEffect(() => {
        store.current.setState({
            pageInnerWidth: props.pageInnerWidth ?? 800,
        });
    }, [props.pageInnerWidth]);
    useEffect(() => {
        store.current.setState({
            pageMaxWidth: props.pageMaxWidth ?? 1200,
        });
    }, [props.pageMaxWidth]);
    useEffect(() => {
        store.current.setState({
            defaultBorderRadius: props.defaultBorderRadius ?? 16,
        });
    }, [props.defaultBorderRadius]);
    useEffect(() => {
        store.current.setState({
            defaultGridGap: isMobile ? 20 : 30,
        });
    }, [isMobile]);

    return (
        <PageViewContext.Provider value={store.current}>
            {props.children}
        </PageViewContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const usePageViewContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(PageViewContext);
    if (store === undefined) {
        throw new Error(
            "usePageViewContext must be used within a PageViewContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { PageViewContextProvider, usePageViewContext };
