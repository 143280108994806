import { useSizeTracker } from "@@/shared/use-size-tracker";
import { useTheme } from "@emotion/react";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import React, { useState } from "react";
import {
    ReactZoomPanPinchRef,
    TransformComponent,
    TransformWrapper,
} from "react-zoom-pan-pinch";
import { HorizontalLine } from "../dividers";
import { FlexColumn, FlexRow } from "../flex-containers";
import { Icon } from "../icons/icon";
import { ResourceMapView, ResourceMapViewProps } from "./resource-map-view";

const ZoomableResourceMapView = React.forwardRef<
    ReactZoomPanPinchRef,
    ResourceMapViewProps
>((props, ref) => {
    const theme = useTheme();
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [initialScale, _setInitialScale] = useState(1);

    const [sizeTrackerRef] = useSizeTracker(size => {
        setContainerSize(current => {
            if (
                size.width === containerSize.width &&
                size.height === containerSize.height
            )
                return current;
            return { width: size.width, height: size.height };
        });
    });

    return (
        <TransformWrapper
            key={`resource_map_${containerSize.width}_${containerSize.height}_${initialScale}`}
            ref={ref}
            initialScale={initialScale}
            minScale={initialScale}
            centerOnInit={true}
            centerZoomedOut={true}>
            {({
                zoomIn,
                zoomOut,
                resetTransform: _resetTransform,
                zoomToElement: _zoomToElement,
                centerView: _centerView,
            }) => {
                return (
                    <div
                        ref={sizeTrackerRef}
                        css={{
                            width: "100%",
                            height: "100%",
                        }}>
                        <div
                            css={{
                                zIndex: 100,
                                width: "100%",
                                padding: 20,
                                position: "absolute",
                                pointerEvents: "none",
                                label: "zoomable_resource_map_view",
                            }}>
                            <FlexRow
                                fillParentWidth
                                mainAxis="flex-end"
                                css={{
                                    label: "zoomable_resource_map_view_zoom_controls_container",
                                }}>
                                <FlexColumn
                                    css={{
                                        pointerEvents: "all",
                                        label: "zoomable_resource_map_view_zoom_controls",
                                    }}>
                                    <a
                                        css={{
                                            backgroundColor:
                                                theme.colors.primary.asString,
                                            padding: "7px 10px",
                                            borderTopLeftRadius: 3000,
                                            borderTopRightRadius: 3000,
                                        }}
                                        onClick={() => zoomIn()}>
                                        <Icon
                                            icon={faPlus}
                                            color={theme.colors.primary.text}
                                            size={0.7}
                                        />
                                    </a>
                                    <HorizontalLine
                                        fillParentWidth
                                        color={theme.colors.white.light90}
                                    />
                                    <a
                                        css={{
                                            backgroundColor:
                                                theme.colors.primary.asString,
                                            padding: "7px 10px",
                                            borderBottomLeftRadius: 3000,
                                            borderBottomRightRadius: 3000,
                                        }}
                                        onClick={() => zoomOut()}>
                                        <Icon
                                            icon={faMinus}
                                            color={theme.colors.primary.text}
                                            size={0.7}
                                        />
                                    </a>
                                </FlexColumn>
                            </FlexRow>
                        </div>
                        <TransformComponent
                            wrapperStyle={{
                                width: "100%",
                                height: "100%",
                                backgroundColor:
                                    theme.colors.black.light90.asString,
                            }}>
                            <ResourceMapView
                                {...props}
                                height={containerSize.height}
                            />
                        </TransformComponent>
                    </div>
                );
            }}
        </TransformWrapper>
    );
});

export { ZoomableResourceMapView };
