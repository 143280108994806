import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<Data extends Record<string, any>> = {
    id: string;
    data?: Data;
    children:
        | React.ReactNode
        | ((params: {
              isDragging: boolean;
              dragListeners: DraggableSyntheticListeners;
          }) => React.ReactNode);
    style?: React.CSSProperties;
    withoutDragOverlay?: boolean;
    noDefaultDraggableHandle?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Sortable = <Data extends Record<string, any>>(props: Props<Data>) => {
    const sortable = useSortable({ id: props.id, data: props.data });
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = sortable;

    const dragStyles: React.CSSProperties = {
        ...(props.style ?? {}),
        transform: CSS.Transform.toString(transform),
        transition: transition || props.style?.transition,
        zIndex: isDragging
            ? props.withoutDragOverlay
                ? 99
                : undefined
            : props.style?.zIndex,
        opacity: isDragging
            ? props.withoutDragOverlay
                ? 0.7
                : 0.35
            : props.style?.opacity,
    };

    return (
        <div
            {...attributes}
            ref={setNodeRef}
            style={dragStyles}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
            }}>
            <div
                onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
                {...(props.noDefaultDraggableHandle ? {} : listeners)}>
                {typeof props.children === "function"
                    ? props.children({ isDragging, dragListeners: listeners })
                    : props.children}
            </div>
        </div>
    );
};
export { Sortable };
