import { InfoBox_FlexColumn } from "@@/backoffice/shared/info-box.flex-column";
import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Paragraph, TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    MappableId,
    ResourceGlobal,
    ResourceId,
    ResourceMapId,
    ResourceMapMap,
    ResourceTableGroup,
    emptyArrayOf,
    sortAlphabeticallyAndNumerically,
    translation,
} from "@towni/common";
import { useEffect, useMemo, useState } from "react";
import { useResourceMapQuery } from "./use-resource-map-query";

type Props = {
    modalId: ModalId;
    resources: ResourceGlobal[];
    resourceMapId: ResourceMapId;
    resourceMapMap: ResourceMapMap;
    onSave: (update: ResourceMapMap) => void;
};

const isUnmappable = (
    resource: ResourceGlobal,
): resource is Exclude<ResourceGlobal, ResourceTableGroup> => {
    return resource._type === "RESOURCE_TABLE_GROUP";
};

const ResourceMapMapperModal = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const { hide } = useModalActions(props.modalId);
    const resourceMapQuery = useResourceMapQuery(props.resourceMapId);
    const resourceMap = resourceMapQuery.data;
    const [resourceMapMap, setResourceMapMap] = useState<
        Map<MappableId, ResourceId | undefined>
    >(new Map<MappableId, ResourceId | undefined>());

    /** Unmappable resources, e.g. we currently we do not support table groups for mapping,
     * they are a resource combined of other resources */
    const hasUnmappableResources = useMemo(() => {
        const result = props.resources.some(isUnmappable);
        return result;
    }, [props.resources]);

    useEffect(() => {
        setResourceMapMap(
            currentMap =>
                new Map([
                    ...(props.resourceMapMap
                        ? (Object.entries(props.resourceMapMap) as [
                              MappableId,
                              ResourceId,
                          ][])
                        : []),
                    ...currentMap.entries(),
                ]),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resourceMapMap, setResourceMapMap]);

    const onClick = () => {
        const mapMap = Object.fromEntries(
            Array.from(resourceMapMap).filter(
                (_, value) => typeof value !== "undefined",
            ),
        );
        props.onSave(mapMap);
        hide();
    };

    const sortedMappableIds = useMemo(
        () =>
            resourceMap?.mappableIds.sort(sortAlphabeticallyAndNumerically()) ??
            emptyArrayOf<MappableId>(),
        [resourceMap?.mappableIds],
    );

    const options = useMemo(() => {
        const opts =
            props.resources?.map(resource => ({
                value: resource._id,
                name: resource.meta.title,
                disabledOption: isUnmappable(resource),
            })) ?? [];
        const sorted = opts.sort(
            sortAlphabeticallyAndNumerically(opt => translate(opt.name)),
        );
        return [
            {
                value: undefined,
                name: translation({
                    sv: "- ingen koppling -",
                    en: "- none connected -",
                }),
                disabledOption: false,
            },
            ...sorted,
        ];
    }, [props.resources, translate]);

    if (!props.resourceMapId) return null;
    // console.log("ResourceMapMapperModal", {
    //     hasUnmappableResources,
    //     resources: props.resources,
    // });
    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    title={translation({
                        sv: "Koppla resurser",
                        en: "Connect resources",
                    })}
                    topRightButton={{
                        _type: "TOP_RIGHT_ACTION_TEXT",
                        text: translation({
                            sv: "Klar",
                            en: "Done",
                        }),
                        action: onClick,
                    }}
                />
            }>
            <FlexColumn
                key={props.resourceMapId}
                fillParent
                padding={{ all: 20 }}>
                <Conditional when={hasUnmappableResources}>
                    <InfoBox_FlexColumn>
                        <Paragraph
                            content={[
                                translation({
                                    sv: "Du har minst en bordsgrupp i resursgruppen, dessa går i dagsläget inte att koppla till en karta och de är därför inaktiverade.",
                                    en: "You have at least one table group in the resource group, these cannot currently be connected to a map and are therefore disabled.",
                                }),
                            ]}
                        />
                    </InfoBox_FlexColumn>
                    <VerticalDivider M />
                </Conditional>
                <table
                    cellPadding={0}
                    cellSpacing={0}
                    css={{
                        "& > tbody > tr > td": {
                            paddingBottom: 10,
                        },
                    }}>
                    <tbody>
                        <ForEach
                            itemOf={sortedMappableIds}
                            getKey={mappableId => mappableId}>
                            {(mappableId: MappableId) => {
                                return (
                                    <tr>
                                        <td
                                            css={{
                                                maxWidth: "50%",
                                            }}>
                                            <FlexRow padding={{ right: 10 }}>
                                                <TextBox
                                                    text={mappableId}
                                                    align="center"
                                                    padding={{
                                                        leftRight: 15,
                                                        topBottom: 10,
                                                    }}
                                                    css={{
                                                        width: "100%",
                                                        borderRadius:
                                                            theme.radius,
                                                        backgroundColor:
                                                            theme.colors
                                                                .textInput
                                                                .background
                                                                .asString,
                                                        border: `1px solid ${theme.colors.textInput.border.asString}`,
                                                    }}
                                                />
                                            </FlexRow>
                                        </td>
                                        <td css={{ width: "100%" }}>
                                            <SelectPicker<
                                                ResourceId | undefined
                                            >
                                                css={{
                                                    width: "100%",
                                                }}
                                                onSelect={resourceOption => {
                                                    setResourceMapMap(old => {
                                                        const newMap = new Map(
                                                            old,
                                                        );
                                                        newMap.set(
                                                            mappableId,
                                                            resourceOption.value,
                                                        );
                                                        return newMap;
                                                    });
                                                }}
                                                selectedValue={resourceMapMap.get(
                                                    mappableId,
                                                )}
                                                data={options}
                                            />
                                        </td>
                                    </tr>
                                );
                            }}
                        </ForEach>
                    </tbody>
                </table>
                <VerticalDivider M />
            </FlexColumn>
        </Modal>
    );
};

export { ResourceMapMapperModal };
