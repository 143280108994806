import * as React from "react";
type State = {
    pageAnimationDisabled: boolean;
    modalEmbedded: boolean;
};

const AppPageSettingsContext = React.createContext<State | undefined>(
    undefined
);

type Props = Partial<State> & {
    readonly children?: React.ReactNode;
};
const AppPageSettingsProvider = (props: Props) => {
    const { children } = props;

    const [state, _setState] = React.useState<State>({
        modalEmbedded: props.modalEmbedded ?? false,
        pageAnimationDisabled: props.pageAnimationDisabled ?? false,
    });

    return (
        <AppPageSettingsContext.Provider value={state}>
            {children}
        </AppPageSettingsContext.Provider>
    );
};

const useAppPageSettings = () => {
    const settings = React.useContext(AppPageSettingsContext);
    if (!settings) {
        throw new Error(
            "useAppPageSettings must be used within a AppPageSettingsContext"
        );
    }
};

export { AppPageSettingsProvider, useAppPageSettings };
