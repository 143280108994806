import { ScrollPosition } from "@@/shared/scroll-position";
import { generateId } from "@towni/common";
import * as React from "react";
import { useLayoutEffect, useRef, useState } from "react";
import { StoreApi, create as zustand } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

type State = {
    readonly _id: string;
    readonly _type: "SCROLL_POSITION_STATE";
    readonly scrollPositions: Map<string, ScrollPosition>;
    readonly recordScrollPosition: (params: {
        id: string;
        position: ScrollPosition;
    }) => void;
};

const ScrollPositionContext = React.createContext<StoreApi<State>>(
    undefined as unknown as StoreApi<State>,
);

type Props = {
    scrollPositions?: Map<string, ScrollPosition>;
    children?: React.ReactNode;
};

const ScrollPositionProvider = (props: Props) => {
    const createStore = () => {
        return zustand<State>((set, _get) => {
            return {
                // state
                _id: generateId({ prefix: "scroll_position_state__" }),
                _type: "SCROLL_POSITION_STATE",
                scrollPositions: props.scrollPositions ?? new Map(),
                // actions
                recordScrollPosition: (params: {
                    id: string;
                    position: ScrollPosition;
                }) => {
                    set(state => {
                        const current = state.scrollPositions.get(params.id);
                        if (current === params.position) return state;
                        const update = new Map(state.scrollPositions);
                        update.set(params.id, params.position);
                        return { ...state, scrollPositions: update };
                    });
                },
            };
        });
    };
    const store = React.useRef(createStore());

    return (
        <ScrollPositionContext.Provider value={store.current}>
            {props.children}
        </ScrollPositionContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useScrollPositionStore = <U extends unknown = State>(
    selector: (context: State) => U = context => context as unknown as U,
): U => {
    const store = React.useContext(ScrollPositionContext);
    if (store === undefined) {
        throw new Error(
            "useScrollPositionStore must be used within a ScrollPositionContext",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

const useScrollPosition = (id: string) => {
    const position = useScrollPositionStore(state =>
        state.scrollPositions.get(id),
    );
    return position;
};

/**
 * Auto records scroll position of given element by given id
 * @param {string} id
 * @param {(HTMLElement | undefined | null)} element
 * @param {{
 *     debounceInMs?: number;
 *     initialScrollPosition?: ScrollPosition;}} [options]
 * @return {*}
 */
const useScrollPositionAutoRecorder = (
    id: string,
    elementRef: React.MutableRefObject<HTMLElement | null>,
    options?: {
        debounceInMs?: number;
        initialScrollPosition?: ScrollPosition;
    },
) => {
    const recordScrollPosition = useScrollPositionStore(
        state => state.recordScrollPosition,
    );

    useLayoutEffect(() => {
        if (!elementRef.current) return;
        const _element = elementRef.current;
        let debouncer: NodeJS.Timeout | undefined;
        const listener = (scrollEvent: Event) => {
            if (!debouncer) {
                debouncer = setTimeout(
                    () => {
                        const target = scrollEvent.target as HTMLElement;
                        const scrollPosition = {
                            scrollLeft: target.scrollLeft || 0,
                            scrollTop: target.scrollTop || 0,
                        };
                        recordScrollPosition({
                            id,
                            position: scrollPosition,
                        });
                        debouncer = undefined;
                    },
                    options?.debounceInMs ?? 200,
                );
            }
        };
        _element.addEventListener("scroll", listener, { passive: true });
        return () => {
            _element.removeEventListener("scroll", listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, options?.debounceInMs, recordScrollPosition]);

    return;
};

/**
 * Auto restores scroll position of given element by scroll position previously recorded for given id
 * @param {string} id
 * @param {(HTMLElement | undefined | null)} element
 * @param {string} [scrollResetId]
 * @return {*}
 */
const useAutoRestoreScrollPosition = (
    id: string,
    elementRef: React.MutableRefObject<HTMLElement | null>,
    scrollResetId?: string,
) => {
    const lastScrollPosition = useScrollPosition(id);
    const [restoredPosition, setRestoredPosition] = useState<ScrollPosition>();
    const [prevScrollResetId, setPrevScrollResetId] = useState<
        string | undefined
    >();

    const isFirstRunForElement = useRef(true);
    useLayoutEffect(() => {
        const _element = elementRef.current;
        if (!_element) return;
        isFirstRunForElement.current = false;
        if (
            typeof scrollResetId !== "undefined" &&
            scrollResetId === prevScrollResetId
        )
            return;
        if (lastScrollPosition && restoredPosition !== lastScrollPosition) {
            _element.scrollTop = lastScrollPosition.scrollTop;
            _element.scrollLeft = lastScrollPosition.scrollLeft;
            setPrevScrollResetId(scrollResetId);
            setRestoredPosition(lastScrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        scrollResetId,
        lastScrollPosition,
        restoredPosition,
        prevScrollResetId,
    ]);

    return restoredPosition;
};

export {
    ScrollPositionProvider,
    useAutoRestoreScrollPosition,
    useScrollPosition,
    useScrollPositionAutoRecorder,
    useScrollPositionStore,
};
