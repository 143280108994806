import { useBookingForTimeRangeChecker } from "@@/products/bookables/hooks/use-booking-for-timeranges";
import { useCheckBookableSession } from "@@/products/bookables/hooks/use-check-bookable-session";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { DateSelectionVtRange } from "@@/shared/date-time/date-time-selection/dates/date-selection-vt";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import {
    asDate,
    BookableSession,
    calculateBookableSessionDefaultTimeRange,
    calculatePossibleSessionLengths,
    calculateSpanningTimeRanges,
    emptyArrayOf,
    inDays,
    inMilliseconds,
    IsoAndUnixTimestamp,
    isSameDayInTimezone,
    isSessionAvailable,
    isTimeRange,
    minSessionTimeRange,
    sortBy,
    TimeRange,
    timeRangeByDurationFactory,
    timeRangeFactory,
} from "@towni/common";
import { useMemo } from "react";
import { useBookingContext } from "../../../booking-context";

type Props = {
    readonly sessions: BookableSession[];
    readonly timeRange: TimeRange;
    readonly initiallySelected?: TimeRange;
    readonly selectableDates: IsoAndUnixTimestamp[];
    // readonly bookedDates: IsoAndUnixTimestamp[];
    readonly className?: string;
    readonly ignoreSession?: boolean;
};

const BookableDateVtSelect = (props: Props) => {
    const { timeRange, initiallySelected, className, ignoreSession } = props;

    const clearSession = useBookingContext(state => state.clearSession);
    const allSessions = useBookingContext(state => state.sessions);
    const [checkAvailability] = useCheckBookableSession();
    const setSessionAndTimeRange = useBookingContext(
        state => state.setSessionAndTimeRange,
    );

    const allAvaibleSessions = useMemo(() => {
        const result = allSessions
            .filter((session: BookableSession) => {
                const resourceAvailability = checkAvailability(
                    session,
                    undefined,
                    ignoreSession,
                );

                const isBookable =
                    !resourceAvailability.resourceAvailabilities.some(
                        a => !a.available,
                    );
                if (ignoreSession && isBookable) return true;

                return (
                    isBookable &&
                    isSessionAvailable({
                        settings: session.bookableSettings,
                        timeRange: minSessionTimeRange(session),
                        ignoreSessionAvailability: true,
                    })
                );
            })
            .sort(sortBy(f => f.start.unix))
            .filter(f => f.status.type === "ACTIVE");

        return result;
    }, [allSessions, checkAvailability, ignoreSession]);

    const contextTimeRange = useBookingContext(state => state.timeRange);
    const bookable = useBookingContext(state => state.bookable);
    const spanning = bookable.type === "RENTAL" && !!bookable.spanning;

    const contextSession = useBookingContext(state => state.session);
    const [checker] = useBookingForTimeRangeChecker();
    const timeRangeSelect = (
        timeRange: TimeRange | IsoAndUnixTimestamp | undefined,
    ) => {
        if (timeRange && !isTimeRange(timeRange)) {
            const matchedSession = allAvaibleSessions.find(session =>
                isSameDayInTimezone(
                    session.start,
                    timeRange,
                    currentTimezoneName,
                ),
            );

            if (matchedSession)
                setSessionAndTimeRange(undefined, matchedSession);
            else clearSession();
            return;
        }

        if (!timeRange || timeRange.duration === 0) {
            clearSession();
            return;
        }

        const matchedSession = allAvaibleSessions.find(session =>
            isSameDayInTimezone(
                session.start,
                isTimeRange(timeRange) ? timeRange.start : timeRange,
                currentTimezoneName,
            ),
        );
        if (!matchedSession || matchedSession._status !== "ACTIVE") {
            clearSession();
            return;
        }

        if (spanning) {
            const allTimes = calculateSpanningTimeRanges(
                matchedSession,
                allAvaibleSessions,
            );

            const matchedEndSession = allTimes.filter(min =>
                isSameDayInTimezone(
                    min.end,
                    timeRange.end,
                    currentTimezoneName,
                ),
            );

            if (matchedEndSession.length === 0) return;
            const newRange = timeRangeFactory(
                asDate(matchedSession.start),
                asDate(matchedEndSession[matchedEndSession.length - 1].end),
            );
            setSessionAndTimeRange(newRange, matchedSession);
            return;
        }

        const newRange = timeRangeByDurationFactory(
            asDate(matchedSession.start),
            inMilliseconds({ seconds: timeRange.duration }),
        );

        if (newRange.duration > 0) {
            setSessionAndTimeRange(newRange, matchedSession);
        } else {
            if (
                matchedSession.durationGranularity === "DAY" &&
                matchedSession.durationMinMax.min === 1
            ) {
                //If min is one day select one date
                setSessionAndTimeRange(undefined, matchedSession);
            } else {
                setSessionAndTimeRange(undefined, matchedSession);
                return;
            }
        }

        setSessionAndTimeRange(newRange, matchedSession);
        return;
    };

    const insideSelectableSpan = useMemo(() => {
        if (contextSession) {
            const ranges = spanning
                ? calculateSpanningTimeRanges(
                      contextSession,
                      allSessions, //  allAvaibleSessions
                  )
                : calculatePossibleSessionLengths(contextSession);

            const r = ranges.filter(time => {
                const resourceAvailability = checkAvailability(
                    contextSession,
                    time,
                );
                const isBookable =
                    !resourceAvailability.resourceAvailabilities.some(
                        a => !a.available,
                    );
                return isBookable;
            });
            return r;
        }
        return emptyArrayOf<TimeRange>();
    }, [contextSession, spanning, allSessions, checkAvailability]);

    const selectables = insideSelectableSpan.map(s => s.end);
    const all = [...allAvaibleSessions.map(s => s.start), ...selectables];

    return (
        <FlexColumn
            overflowY="scroll"
            fillParent
            shrink={1}
            grow={1}
            className={className}
            padding={{ top: 20, bottom: 100 }}>
            <DateSelectionVtRange
                timeRange={timeRange}
                onSelect={timeRangeSelect}
                selectableDates={all}
                selectableDatesForTimeRange={selectables}
                bookedDates={[]}
                validate={(timeRange: TimeRange) => {
                    if (timeRange.duration === 0) return false;

                    const matchedSession = allAvaibleSessions.find(session =>
                        isSameDayInTimezone(
                            session.start,
                            timeRange.start,
                            currentTimezoneName,
                        ),
                    );

                    if (!matchedSession) return false;

                    const sessionRange =
                        calculateBookableSessionDefaultTimeRange(
                            matchedSession,
                        );
                    const sameDay = isSameDayInTimezone(
                        sessionRange.start,
                        sessionRange.end,
                        currentTimezoneName,
                    );
                    if (!sameDay && timeRange.duration === 0) return false;

                    const spanning =
                        bookable.type === "RENTAL" && !!bookable.spanning;
                    //use end time of session as end time of timeRange
                    if (spanning) {
                        const matchingSpanningTimeRanges =
                            calculateSpanningTimeRanges(
                                matchedSession,
                                allAvaibleSessions,
                            );
                        const matchedEndSession =
                            matchingSpanningTimeRanges.find(session =>
                                isSameDayInTimezone(
                                    session.end,
                                    timeRange.end,
                                    currentTimezoneName,
                                ),
                            );
                        if (!matchedEndSession) return false;

                        const newRange = timeRangeFactory(
                            asDate(matchedSession.start),
                            asDate(matchedEndSession.end),
                        );
                        // return true;
                        const vals = checker(newRange);
                        return vals.resourcesAvailability.every(
                            resource => resource.available,
                        );
                    }

                    const maxDays = inDays({ seconds: timeRange.duration });

                    if (maxDays > (matchedSession.durationMinMax.max ?? 1))
                        return false;
                    if (maxDays < (matchedSession.durationMinMax.min ?? 1))
                        return false;
                    //As Vtrange only cares about dates and not times we need to adjust to a session time
                    const newRange = timeRangeByDurationFactory(
                        asDate(matchedSession.start),
                        inMilliseconds({ seconds: timeRange.duration }),
                    );

                    const vals = checker(newRange, props.ignoreSession);
                    return vals.resourcesAvailability.every(
                        resource => resource.available,
                    );
                }}
                initiallySelected={
                    initiallySelected ??
                    (contextTimeRange && isTimeRange(contextTimeRange)
                        ? timeRangeFactory(
                              asDate(contextTimeRange.start),
                              asDate(contextTimeRange.end),
                          )
                        : undefined)
                }
            />
            <VerticalDivider XXL />
        </FlexColumn>
    );
};

export { BookableDateVtSelect };
