const pageAnimationFade = {
    initial: {
        position: "absolute" as const,
        opacity: 0,
        zIndex: 0,
        top: 0,
    },
    enter: {
        position: "absolute" as const,
        opacity: 1,
        zIndex: 1,
        top: 0,
    },
    exit: {
        position: "absolute" as const,
        opacity: 0,
        zIndex: 0,
        top: 0,
        transitionEnd: {
            opacity: 0,
            zIndex: 0,
        },
    },
};

export { pageAnimationFade };
