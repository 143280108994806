import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { useBookingContext } from "@@/products/bookables/booking-context";
import { browserLogger } from "@@/settings";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { TextBox } from "@@/shared/text";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    asDate,
    BookableSession,
    calculateSpanningTimeRanges,
    inHours,
    inMinutes,
    TimeRange,
    TimeRangeResourceAvailability,
    translation,
} from "@towni/common";
import { intervalToDuration, isSameDay } from "date-fns";
import * as React from "react";

type Props = {
    selectedSession: BookableSession;
    readonly onDurationChange: (timeRange: TimeRange) => void;
    readonly checkAvailability: (
        timeRange: TimeRange,
    ) => TimeRangeResourceAvailability;
    readonly ranges: TimeRange[];
};

const DurationPicker = (props: Props) => {
    const theme = useTheme();
    const formatter = useDateTimeFormatter();
    const translate = useTranslate();
    const contextTimeRange = useBookingContext(state => state.timeRange);
    const bookable = useBookingContext(state => state.bookable);
    const [selectedDuration, setSelectedDuration] = React.useState<
        TimeRange | undefined
    >(contextTimeRange);
    const sessions = useBookingContext(state => state.sessions);

    const timeRangeResourceAvailability = React.useMemo(() => {
        if (bookable.type === "RENTAL" && bookable.spanning) {
            const possibleSessionLengths = calculateSpanningTimeRanges(
                props.selectedSession,
                sessions,
            );
            browserLogger.info({ possibleSessionLengths });
            return possibleSessionLengths.map(timeRange => {
                return props.checkAvailability(timeRange);
            });
        } else {
            return props.ranges.map(timeRange => {
                return props.checkAvailability(timeRange);
            });
        }
    }, [props.checkAvailability, props.ranges]);

    React.useEffect(() => {
        if (
            timeRangeResourceAvailability.length > 0 &&
            selectedDuration === undefined
        )
            setSelectedDuration(timeRangeResourceAvailability[0].timeRange);
    }, [timeRangeResourceAvailability]);

    if (!selectedDuration) return null;
    return (
        <>
            <Conditional when={bookable.type === "RENTAL" && bookable.spanning}>
                <TextBox text={translation({ sv: "Till " })} />
                <HorizontalDivider XS />
            </Conditional>
            <SelectPicker<string | number | undefined>
                data={timeRangeResourceAvailability
                    .filter(availability =>
                        availability.resourcesAvailability.every(
                            resource => resource.available,
                        ),
                    )
                    .map(({ timeRange }) => {
                        if (
                            props.selectedSession.durationGranularity === "HOUR"
                        ) {
                            const start = asDate(timeRange.start);
                            const end = asDate(timeRange.end);
                            const sameDay = isSameDay(start, end);
                            const spanning =
                                bookable.type === "RENTAL" &&
                                !!bookable.spanning;

                            return {
                                value: timeRange._id,
                                name:
                                    sameDay && !spanning
                                        ? `${inHours(
                                              { seconds: timeRange.duration },
                                              { noRounding: true },
                                          ).toLocaleString()} timmar`
                                        : translate(
                                              formatter.formatRelativeDateMedium(
                                                  timeRange.end,
                                                  { includeTime: true },
                                              ),
                                          ) +
                                          ` (${getDurationString(start, end)})`,
                            };
                        }

                        return {
                            value: timeRange._id,
                            name:
                                inMinutes(
                                    { seconds: timeRange.duration },
                                    { noRounding: true },
                                ) + " minuter",
                        };
                    })}
                selectedValue={selectedDuration._id}
                onSelect={({ value: timeRangeId }) => {
                    const available = timeRangeResourceAvailability.find(
                        item => item.timeRange._id === timeRangeId,
                    );
                    if (available) {
                        setSelectedDuration(available.timeRange);
                        props.onDurationChange(available.timeRange);
                    }
                }}
                css={{
                    //height: 52,

                    borderRadius: `${theme.radius}px 0 0 ${theme.radius}px`,
                }}
            />
        </>
    );
};

export { DurationPicker };

const getDurationString = (start: Date, end: Date) => {
    const duration = intervalToDuration({
        start,
        end,
    });

    const str = [];

    if (duration.days) str.push(`${duration.days}d`);

    if (duration.hours) str.push(`${duration.hours}h`);
    if (duration.minutes) str.push(`${duration.minutes}min`);

    return str.join(",");
};
