import { SerializedStyles } from "@emotion/react";
import { colorAsString, ColorItem } from "@towni/common";
import { motion } from "framer-motion";
import * as React from "react";
import { useState } from "react";

type Props = {
    baseColor: ColorItem;
    children: React.ReactNode;
    styling?: SerializedStyles;
    onClick: () => void;
    pressedColor: ColorItem;
    grow?: number;
    shrink?: number;
    className?: string;
};

const AnimatedButton = ({ children, styling, onClick, ...props }: Props) => {
    const [pressed, setPressed] = useState(false);
    const pressedColor = colorAsString(props.pressedColor);
    const baseColor = colorAsString(props.baseColor);
    const backgroundColor = pressed ? pressedColor : baseColor;

    return (
        <motion.a
            className={props.className}
            css={[
                {
                    display: "block",
                    flexGrow: props.grow,
                    flexShrink: props.shrink,
                },
                styling,
            ]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initial={{ backgroundColor: baseColor }}
            animate={{ backgroundColor }}
            onMouseDown={() => setPressed(true)}
            onTouchStart={() => setPressed(true)}
            onTouchEnd={() => setPressed(false)}
            onTouchCancel={() => setPressed(false)}
            onClick={() => {
                setPressed(false);
                onClick();
            }}
            onMouseLeave={() => setPressed(false)}>
            {children}
        </motion.a>
    );
};

export { AnimatedButton };
