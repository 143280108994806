import {
    useFetch,
    useFetchMultiple,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";
import { Section } from "@towni/common";
import { ProviderId } from "@towni/common";

const mainQueryKey = "sections";
const mainRoute = "sections";

const useSection = (id?: string) =>
    useFetch<Section>(mainQueryKey, mainRoute, id);
const useSections = (ids?: string[]) =>
    useFetchMultiple<Section>(mainQueryKey, mainRoute, ids);
const useSectionsForProvider = (providerId?: ProviderId) => {
    return useFetchMultipleForSingle<Section>({
        mainQueryKey,
        mainRoute,
        fetchFor: "provider",
        id: providerId,
    });
};

export { useSection, useSections, useSectionsForProvider };
