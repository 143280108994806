import { useModal } from "@@/modals/use-modal";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { css, useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import {
    Product,
    Provider,
    RReferences,
    isBookableOptionValueWithQuantity,
    isQuantityPickerBookableOption,
    isResourceReference,
    sum,
    units,
} from "@towni/common";
import { BookableResourceModal } from "./bookable-resource-modal";
import { useBookingContext } from "./booking-context";
import { OpenResourceModalPillButton } from "./open-resource-modal-pill-button";

type Props = {
    readonly product: Product;
    readonly provider: Provider;
    readonly resourceRef: RReferences;
};

const getRReferencesUnit = (r: RReferences) => {
    if (isResourceReference(r)) return r.resource.unit;
    return r.unit ?? r.references[0].resource.unit ?? units.pcs;
};

const useTotalQuantityUnit = (r: RReferences) => {
    const optionValues = useBookingContext(state => state.optionValues);
    if (isResourceReference(r)) {
        const totalQuantity = sum(
            optionValues.filter(o => o.resourceReferenceId === r._id) ?? [],
            item =>
                isBookableOptionValueWithQuantity(item)
                    ? item.quantity.value
                    : 0,
        );

        return totalQuantity;
    } else {
        const totalQuantity = sum(
            optionValues.filter(o =>
                r.references.find(rr => o.resourceReferenceId === rr._id),
            ) ?? [],
            item =>
                isBookableOptionValueWithQuantity(item)
                    ? item.quantity.value
                    : 0,
        );
        return totalQuantity;
    }
};

const ResourcePill = (props: Props) => {
    const theme = useTheme();
    const unitTranslate = useUnitTranslator();
    const [{ show: showResource }, modalId] = useModal("resource_modal__");

    const totalQuantity = useTotalQuantityUnit(props.resourceRef);
    const unit = getRReferencesUnit(props.resourceRef);
    const unitString = unitTranslate(totalQuantity, unit, "long");

    const allOptions =
        props.resourceRef._type === "RESOURCE_REFERENCE"
            ? props.resourceRef.options
            : props.resourceRef.references.flatMap(r => r.options);

    const noOptionToPickForCustomer = allOptions.every(
        o => isQuantityPickerBookableOption(o) && o.minMax.min === o.minMax.max,
    );

    if (noOptionToPickForCustomer) {
        return null;
    }

    return (
        <FlexColumn padding={{ leftRight: 20 }} crossAxis="center">
            <OpenResourceModalPillButton
                styling={css`
                    width: 100%;
                `}
                onClick={showResource}>
                <FlexRow fillParentWidth mainAxis="center">
                    <TextBox
                        text={totalQuantity.toString()}
                        weight="500"
                        color={theme.colors.primary.text}
                    />
                    <HorizontalDivider XS />
                    <TextBox
                        text={unitString}
                        weight="500"
                        color={theme.colors.primary.text}
                    />
                    <HorizontalDivider XS />
                    <Icon icon={faChevronDown} />
                </FlexRow>
            </OpenResourceModalPillButton>
            <BookableResourceModal
                modalId={modalId}
                product={props.product} //From context?
                provider={props.provider}
                resourceRef={props.resourceRef}
                unit={unit}
            />
        </FlexColumn>
    );
};

export { ResourcePill, getRReferencesUnit, useTotalQuantityUnit };
