import { FlexRow } from "@@/shared/flex-containers";
import { PriceTextBox, TextBox } from "@@/shared/text";
import {
    ColorItem,
    Padding,
    Price,
    SizeName,
    translation,
} from "@towni/common";

type Props = {
    readonly price: Price;
    readonly padding?: Padding;
    readonly color?: ColorItem;
    readonly size?: SizeName | number;
};
const TotalAmountRow = ({ price, padding, color, size }: Props) => {
    const sized = size ?? "S";
    return (
        <FlexRow mainAxis="space-between" shrink={0} padding={padding}>
            <TextBox
                shrink={0}
                text={translation({
                    sv: "Totalt",
                    en: "Total",
                })}
                weight="900"
                size={sized}
                color={color}
            />
            <PriceTextBox
                shrink={0}
                price={price}
                weight="900"
                size={sized}
                color={color}
            />
        </FlexRow>
    );
};

export { TotalAmountRow };
