import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
    GetSingleResponse,
    ReservationCheckInStatus,
    ResourceReservationId,
} from "@towni/common";

const useReservationCheckInStatusQuery = (
    resourceReservationId: ResourceReservationId | undefined,
) => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<
        ReservationCheckInStatus | undefined
    > = async ({ signal }) => {
        const result = await fetchClient.get<
            GetSingleResponse<ReservationCheckInStatus>
        >({
            route: `/reservations/check-ins/${resourceReservationId}/status`,
            customConfig: { signal },
        });
        return result.item;
    };

    return useQuery({
        queryKey: [
            "check-in-statuses",
            "resource-reservation",
            resourceReservationId,
        ],
        queryFn,
        enabled: !!resourceReservationId,
    });
};

export { useReservationCheckInStatusQuery };
