import { SmallGrip } from "@@/pages/grips";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Avatar } from "@@/shared/pictures/avatar";
import { TextBox } from "@@/shared/text";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { RemSize, ResourceMap, ResourceMapId } from "@towni/common";

type Props = {
    resourceMap: ResourceMap;
    toggle: (resourceMapId: ResourceMapId) => void;
    dragListeners?: DraggableSyntheticListeners;
};

const ResourceMapPickedRow = (props: Props) => {
    const theme = useTheme();
    const { resourceMap, toggle } = props;
    const [storageItem] = useStorageItemImageReference(
        resourceMap.storageItemId,
    );

    const inactive = false; // resource.status.type !== "ACTIVE";

    return (
        <FlexColumn
            fillParentWidth
            mainAxis="center"
            crossAxis="flex-start"
            padding={{ topBottom: 5 }}>
            <FlexRow
                fillParentWidth
                radius={10}
                background={{
                    color: inactive
                        ? theme.colors.disabled.background
                        : theme.colors.default.background,
                }}
                css={{
                    padding: 15,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: theme.colors.default.border.asString,
                }}
                mainAxis="space-between"
                crossAxis="center">
                <Conditional
                    when={!!props.dragListeners}
                    render={() => (
                        <div {...props.dragListeners}>
                            <SmallGrip
                                size={0.8 as RemSize}
                                color={theme.colors.black.light60}
                                padding={{ left: 0, right: 15 }}
                                dragListeners={props.dragListeners}
                            />
                        </div>
                    )}
                />
                <FlexRow grow={1} shrink={1}>
                    <Avatar
                        title={resourceMap.title}
                        image={storageItem}
                        disableBlurhash={true}
                        backgroundColor={theme.colors.white}
                        borderColor={theme.colors.white}
                        size={2.5 as RemSize}
                    />
                    <HorizontalDivider S />
                    <TextBox
                        text={resourceMap.title}
                        color={
                            inactive
                                ? theme.colors.disabled.text
                                : theme.colors.default.text
                        }
                        lineClamp={2}
                        shrink={1}
                        grow={1}
                    />
                    <HorizontalDivider />
                </FlexRow>
                <Icon
                    icon={faTimes}
                    cursor="pointer"
                    color={theme.colors.black.light60}
                    padding={{ all: 10, right: 5 }}
                    onClick={() => toggle(resourceMap._id)}
                />
            </FlexRow>
        </FlexColumn>
    );
};

export { ResourceMapPickedRow };
