import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import { GetResponse, ProductExternalAccess, ProductId } from "@towni/common";

type ProductExternalAccessType = ProductExternalAccess["type"];
type Response = GetResponse<ProductExternalAccessType>;

const useProductExternalAccessTypesForProducts = (
    productIds?: ProductId[]
): UseQueryResult<Response> => {
    const apiFetchClient = useFetchClient();
    const productIdsCS = productIds?.join(",");
    const queryFn: QueryFunction<Response> = async ({ signal }) => {
        return await apiFetchClient.get<Response>({
            route: `/external-access/access-types/for-products/${productIdsCS}`,
            customConfig: {
                signal,
            },
        });
    };
    const result = useQuery<Response>({
        queryKey: ["products", productIdsCS, "external-access", "access-types"],
        queryFn,
        enabled: !!productIdsCS,
    });

    return result;
};

export { useProductExternalAccessTypesForProducts };
