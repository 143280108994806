import { useCart, useCartContext } from "@@/carts/multi-carts/cart.context";
import { ModalId } from "@@/modals/context/modal-id";
import { ModalHeader } from "@@/modals/modal-header";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Conditional } from "@@/shared/conditional";
import { Icon } from "@@/shared/icons/icon";
import { useTheme } from "@emotion/react";
import {
    DeliveryMethodType,
    asDate,
    deliveryMethodFaIconMap,
    getDailySchedulesForWeekday,
    remSize,
    translation,
} from "@towni/common";
import { isToday } from "date-fns";
import { useDeliveryDispatch } from "../carts/deliverypicker/delivery-context";
import { SelectDeliveryMethodRow } from "../carts/select-delivery-method-row";
import { AddMessageRow } from "../products";
import { Button } from "../shared/buttons";
import { HorizontalLine, VerticalDivider } from "../shared/dividers";
import { FlexColumn, FlexRow } from "../shared/flex-containers";
import { Paragraph, TextBox, paragraphTextFactory } from "../shared/text";

type Props = {
    modalId: ModalId;
    closeModal: () => void;
    showDeliveryModal: () => void;
    onContinue: () => void;
};

const CheckoutDelivery = ({ showDeliveryModal, ...props }: Props) => {
    const theme = useTheme();
    const cart = useCart();
    const setDeliveryInstructions = useCartContext(
        context => context.setDeliveryInstructions,
    );
    const [cartProvider, _cartProviderQuery] = useProvider(cart.providerId);
    const deliveryIsPickupAndNotToday =
        cart.delivery.method === "PICK_UP" &&
        cart.delivery.slot?.start &&
        !isToday(asDate(cart.delivery.slot.start));
    const isClosedToday = cartProvider
        ? !getDailySchedulesForWeekday(
              new Date(),
              cartProvider.options.businessHours,
              cartProvider.options.businessHourExceptions,
          ).length
        : false;

    const { setMethod } = useDeliveryDispatch();

    return (
        <FlexColumn shrink={0} fillParentWidth>
            <ModalHeader
                title={text.title}
                modalId={props.modalId}
                bottomBorder={true}
            />
            <FlexColumn
                overflowY="auto"
                fillParentWidth
                padding={{ bottom: 10 }}
                css={{ maxHeight: "calc(100vh - 80px)" }}
                background={{ color: theme.colors.default.background }}>
                <FlexColumn
                    mainAxis="space-between"
                    crossAxis="center"
                    shrink={0}
                    padding={{ leftRight: 20, top: 20 }}>
                    <TextBox
                        text={text.correctDelivery}
                        weight="800"
                        size={"XXL"}
                        color={theme.colors.default.text}
                    />
                    <VerticalDivider M />
                    <Conditional
                        when={deliveryIsPickupAndNotToday}
                        render={() => (
                            <Paragraph
                                css={{
                                    backgroundColor:
                                        theme.colors.danger.light.asString,
                                    fontSize: "1.125rem",
                                    color: theme.colors.danger.asString,
                                    textAlign: "center",
                                    padding: 15,
                                    borderRadius: 10,
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                                content={[
                                    translation({
                                        sv: "Notera att ",
                                        en: "Please note that ",
                                    }),
                                    ...(isClosedToday
                                        ? [
                                              cartProvider?.meta.title,
                                              translation({
                                                  sv: " har ",
                                                  en: " is ",
                                              }),
                                              paragraphTextFactory({
                                                  text: translation({
                                                      sv: "stängt",
                                                      en: "closed",
                                                  }),
                                                  css: {
                                                      textDecoration:
                                                          "underline",
                                                  },
                                              }),
                                              translation({
                                                  sv: " och att din beställning ",
                                                  en: " and that your order therefore ",
                                              }),
                                          ]
                                        : [
                                              translation({
                                                  sv: "din beställning ",
                                                  en: "your order is ",
                                              }),
                                          ]),
                                    paragraphTextFactory({
                                        text: translation({
                                            sv: "inte",
                                            en: "not",
                                        }),
                                        css: {
                                            textDecoration: "underline",
                                        },
                                    }),
                                    translation({
                                        sv: " är satt att hämtas upp idag.",
                                        en: " set to be picked up today.",
                                    }),
                                ]}
                            />
                            //     <TextBox
                            //     text={translation({
                            //         sv: "Snart där! Kontrollera bara dina leveransval så att allt är rätt",
                            //         en: "Almost there! Just make sure your delivery choices are correct",
                            //     })}
                            //     weight="400"
                            //     align="center"
                            //     size={"ML"}
                            //     color={theme.colors.black.light60}
                            // />
                        )}
                        else={() => (
                            <TextBox
                                text={translation({
                                    sv: "Snart där! Kontrollera bara dina leveransval så att allt är rätt",
                                    en: "Almost there! Just make sure your delivery choices are correct",
                                })}
                                weight="400"
                                align="center"
                                size={"ML"}
                                color={theme.colors.black.light60}
                            />
                        )}
                    />
                    <VerticalDivider XL />
                    <FlexRow
                        shrink={0}
                        mainAxis={"space-evenly"}
                        fillParentWidth>
                        {/* <DeliveryChoiceBig
                            onClick={() => {
                                return;
                                // if (
                                //     cartProvider.meta.tags.delivery.includes(
                                //         "HOME_DELIVERY"
                                //     )
                                // ) {
                                //     setMethod("HOME_DELIVERY");
                                //     showDeliveryModal();
                                // }
                            }}
                            method={"HOME_DELIVERY"}
                            selected={cart.delivery.method === "HOME_DELIVERY"}
                        /> */}
                        <DeliveryChoiceBig
                            onClick={() => {
                                setMethod("PICK_UP");
                                showDeliveryModal();
                            }}
                            method={"PICK_UP"}
                            selected={cart.delivery.method === "PICK_UP"}
                        />
                    </FlexRow>
                </FlexColumn>
                <VerticalDivider L />
                <FlexColumn shrink={0} fillParentWidth>
                    <HorizontalLine />
                    <SelectDeliveryMethodRow
                        padding={{ topBottom: 15, leftRight: 20 }}
                        provider={cartProvider}
                        deliverySelection={cart.delivery}
                        showModal={showDeliveryModal}
                    />
                    <HorizontalLine />
                </FlexColumn>
                {cart.delivery.method === "HOME_DELIVERY" && (
                    <>
                        <FlexRow>
                            <AddMessageRow
                                title={text.infoForDriver}
                                placeholder={text.sampleInfoForDriver}
                                initialMessage={
                                    cart.delivery.deliveryInstruction ||
                                    cart.delivery.destination
                                        .deliveryInstructions ||
                                    ""
                                }
                                onSetMessage={setDeliveryInstructions}
                                padding={{ leftRight: 20, topBottom: 15 }}
                                size="S"
                                showPen
                            />
                        </FlexRow>
                        <HorizontalLine />
                    </>
                )}
                <VerticalDivider XL />

                <FlexRow
                    padding={{ leftRight: 20, topBottom: 10 }}
                    shrink={0}
                    fillParentWidth>
                    <Button fillWidth onClick={props.onContinue}>
                        <TextBox
                            text={translation({
                                sv: "Det är rätt!",
                                en: "It's correct!",
                            })}
                            weight="700"
                            color={theme.colors.primary.text}
                        />
                    </Button>
                </FlexRow>
            </FlexColumn>
        </FlexColumn>
    );
};

type choiceProps = {
    method: DeliveryMethodType;
    selected: boolean;
    onClick: () => void;
};
const DeliveryChoiceBig = ({ method, selected, onClick }: choiceProps) => {
    const theme = useTheme();
    return (
        <FlexColumn
            onClick={onClick}
            crossAxis="stretch"
            basis={0.5}
            css={{
                opacity: selected ? 1 : 0.2,
            }}>
            <FlexColumn mainAxis="space-between" crossAxis="center">
                <Icon
                    icon={deliveryMethodFaIconMap[method]}
                    size={remSize(2)}
                    color={theme.colors.default.text}
                />
                <TextBox
                    text={
                        method === "PICK_UP"
                            ? text.pickUp
                            : text.localDeliverySummer
                    }
                    weight="700"
                    size={"L"}
                    color={theme.colors.default.text}
                />
            </FlexColumn>
            <VerticalDivider M />
            <HorizontalLine
                size={selected ? 3 : 2}
                color={
                    selected
                        ? theme.colors.primary
                        : theme.colors.textInput.placeholder
                }
            />
        </FlexColumn>
    );
};

const text = {
    title: translation({
        sv: "Utcheckning",
        en: "Check-out",
    }),
    correctDelivery: translation({
        sv: "Rätt leverans?",
        en: "Correct delivery?",
    }),
    infoForDriver: translation({
        sv: "Info till chauffören",
        en: "Driver information", // todo: is it driver? or is there a better word?
    }),
    sampleInfoForDriver: translation({
        sv: "Ex. ring på vid leverans, ställ bredvid...",
        en: "E.g. ring the doorbell, put next to...",
    }),
    pickUp: translation({
        sv: "Egen pick-up",
        en: "Pick-up",
    }),
    localDelivery: translation({
        sv: "Lokal leverans",
        en: "Local delivery",
    }),
    localDeliverySummer: translation({
        sv: "Ej tillgängligt för närvarande",
        en: "Not available at the moment",
    }),
};

export { CheckoutDelivery, DeliveryChoiceBig };
