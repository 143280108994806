import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import {
    isImageContentType,
    StorageItem,
    StorageItemId,
    StorageItemImage,
    StorageItemImageId,
} from "@towni/common";
import { storageItemMainQueryKey } from "../main-query-key";
import { fetchStorageItem } from "./storage-item.fetcher";

const useStorageItem = <StorageItemType extends StorageItem = StorageItem>(
    storageItemId: StorageItemId | undefined,
    contentTypeVerifier?: (contentType: string) => boolean,
) => {
    // console.log("USE STORAGE ITEM", storageItemId);
    const result = useSingleApiResource<StorageItemType>(
        [storageItemMainQueryKey, storageItemId],
        fetchStorageItem(storageItemId, contentTypeVerifier),
        {
            enabled: !!storageItemId,
        },
    );
    // console.log("USE STORAGE ITEM RES", result);
    return result;
};

const useImageStorageItem = (storageItemId: StorageItemImageId | undefined) =>
    useStorageItem<StorageItemImage>(storageItemId, isImageContentType);

export { useImageStorageItem, useStorageItem };
