import { ModalHeader } from "@@/modals/modal-header";
import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { translation } from "@towni/common";
import type { BookingWizardModalProps } from "./booking-wizard-modal";

const BookingWizardModalHeader = (
    props: Pick<BookingWizardModalProps, "buyer" | "modalId">,
) => {
    const theme = useTheme();
    const wizardState = useWizardState();
    const wizardActions = useWizardDispatch();
    const topLeftButton = wizardState.isFirst
        ? undefined
        : {
              icon: faChevronLeft,
              action: () => wizardActions.goBack(),
          };

    return (
        <ModalHeader
            modalId={props.modalId}
            bottomBorder
            css={{
                backgroundColor:
                    props.buyer === "ON_PREMISE"
                        ? theme.isLightTheme
                            ? theme.colors.black.light90.asString
                            : theme.colors.black.light10.asString
                        : undefined,
            }}
            title={translation(
                props.buyer === "ON_PREMISE"
                    ? {
                          sv: "Internbokning",
                          en: "On premise booking",
                      }
                    : {
                          sv: "Bokning",
                          en: "Booking",
                      },
            )}
            topLeftButton={topLeftButton}
        />
    );
};

export { BookingWizardModalHeader };
