import { humanizeDate } from "@@/carts/deliverypicker/pickup-time-picker";
import { useLanguageContext } from "@@/settings/language-context";
import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { asDate, IsoAndUnixTimestamp } from "@towni/common";

type DayProps = {
    day: IsoAndUnixTimestamp;
};
const DayHeader = (props: DayProps) => {
    const translate = useTranslate();
    const { language } = useLanguageContext();

    const date = asDate(props.day);

    return (
        <FlexRow>
            <TextBox
                weight="700"
                text={humanizeDate(date, translate, language)}
            />
        </FlexRow>
    );
};

export { DayHeader as BookableSessionListDayHeader };
