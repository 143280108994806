import { VatTable } from "@@/pay-requests/pay-request-item-price-table";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import {
    Paragraph,
    PriceTextBox,
    TextBox,
    paragraphPriceFactory,
} from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { useTranslate } from "@@/translations/use-translate";
import { useUserReference } from "@@/users/state/user-reference-fetchers";
import { ClassNames, useTheme } from "@emotion/react";
import {
    faCancel,
    faCheckCircle,
    faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";
import {
    OrderGroup,
    RefundReceiptForCustomer,
    assertNever,
    fullName,
    translation,
} from "@towni/common";
import { MutableRefObject, ReactInstance, useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";
import { ReceiptCompanyDetails } from "./receipt-company-details";

type Props = {
    readonly orderGroup: OrderGroup;
    readonly receipt: RefundReceiptForCustomer;
    readonly ref?: MutableRefObject<ReactInstance>;
};

/**
 * Since a regular payment receipt only can be available for
 * orders that are paid for by customer directly, we know that
 * this is the original receipt for the order group.
 * This type of payment can only be made for an order created event.
 * @param props
 * @returns
 */
const RefundReceipt = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const isExtraSmall = usePageBreakpoint({ when: "XS" });
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { orderGroup, receipt } = props;
    const [customer, customerQuery] = useUserReference(orderGroup?.customerId);
    const [provider, _providerQuery] = useProvider(
        receipt.receiptFor.providerId,
    );

    const defPadding = 20;
    const printRef = useRef(null);

    const titleColor = theme.colors.black.light30;
    const textColor = theme.colors.black.light60;

    const title = useMemo(() => {
        switch (receipt.status) {
            case "PENDING":
                return translation({
                    sv: "Återbetalning begärd",
                    en: "Refund requested",
                });
            case "CANCELLED":
                return translation({
                    sv: "Återbetalning avbruten",
                    en: "Refund cancelled",
                });
            case "FULFILLED":
                return translation({
                    sv: "Återbetalning",
                    en: "Refund",
                });
            default:
                assertNever(receipt.status, "Unknown receipt type");
        }
    }, [receipt.status]);

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 20px",
            }}>
            <FlexRow
                fillParentWidth
                crossAxis="stretch"
                ref={printRef}
                css={{
                    maxWidth: 700,
                    borderRadius: 20,
                    backgroundColor: theme.colors.white.asString,
                    overflow: "hidden",
                }}>
                <Conditional
                    when={receipt.refunds.some(
                        item => item.status === "CANCELLED",
                    )}>
                    <div
                        css={{
                            flex: 1,
                            width: 20,
                            maxWidth: 20,
                            backgroundColor: theme.colors.danger.asString,
                        }}
                    />
                </Conditional>
                <FlexColumn
                    css={{
                        flex: 1,
                        opacity: receipt.status === "CANCELLED" ? 0.6 : 1,
                        zoom: isMobile ? (isExtraSmall ? 0.6 : 0.8) : 1,
                    }}>
                    {/* Header */}
                    <FlexRow
                        fillParentWidth
                        crossAxis="center"
                        mainAxis="space-between"
                        css={{
                            opacity: 0.8,
                            padding: defPadding,
                            paddingTop: defPadding * 1.5,
                            paddingBottom: 10,
                        }}>
                        <FlexColumn>
                            <TextBox text={title} weight="700" size={1.125} />
                            <VerticalDivider XXS />
                            <DateTimeTextBox
                                dateTime={receipt._createdAt}
                                userSelect="text"
                                weight="700"
                                size={0.9}
                                color={textColor}
                                forceIncludeYear
                            />
                        </FlexColumn>
                        <HorizontalDivider />
                        <FlexRow>
                            <Conditional when={receipt.status === "PENDING"}>
                                <TextBox
                                    size={1.2}
                                    css={{
                                        borderRadius: 5,
                                        padding: 15,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        backgroundColor:
                                            receipt.status === "CANCELLED"
                                                ? theme.colors.black.light95
                                                      .asString
                                                : theme.colors.danger.light
                                                      .asString,
                                        color:
                                            receipt.status === "CANCELLED"
                                                ? theme.colors.black.light40
                                                      .asString
                                                : theme.colors.danger.asString,
                                    }}
                                    weight="700"
                                    text={translation({
                                        sv: "Pågående",
                                        en: "In progress",
                                    })}
                                />
                            </Conditional>
                            <Conditional when={receipt.status === "CANCELLED"}>
                                <TextBox
                                    size={1.2}
                                    css={{
                                        borderRadius: 5,
                                        padding: 15,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        backgroundColor:
                                            receipt.status === "CANCELLED"
                                                ? theme.colors.black.light95
                                                      .asString
                                                : theme.colors.danger.light
                                                      .asString,
                                        color:
                                            receipt.status === "CANCELLED"
                                                ? theme.colors.black.light40
                                                      .asString
                                                : theme.colors.danger.asString,
                                    }}
                                    weight="700"
                                    text={translation({
                                        sv: "Avbruten",
                                        en: "Cancelled",
                                    })}
                                />
                            </Conditional>
                            <Conditional when={receipt.status === "FULFILLED"}>
                                <ButtonWhite
                                    css={{ border: "none", paddingRight: 8 }}>
                                    <ClassNames>
                                        {({ css }) => (
                                            <ReactToPrint
                                                copyStyles={true}
                                                bodyClass={css({
                                                    overflow: "visible",
                                                    height: "auto",
                                                    width: "100%",
                                                    backgroundColor: "white",
                                                    boxSizing: "border-box",
                                                    justifyContent: "stretch",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    fontFamily:
                                                        theme.fontFamily,
                                                    fontSize: theme.sizes.base,
                                                    letterSpacing: "0.01em",
                                                    zoom: 1,
                                                    margin: 40,

                                                    userSelect: "none",
                                                })}
                                                trigger={() => (
                                                    <Icon
                                                        icon={faPrint}
                                                        size={1.1}
                                                        css={{
                                                            "@media print": {
                                                                display: "none",
                                                            },
                                                        }}
                                                    />
                                                )}
                                                content={() =>
                                                    printRef?.current || null
                                                }
                                            />
                                        )}
                                    </ClassNames>
                                </ButtonWhite>
                            </Conditional>
                        </FlexRow>
                    </FlexRow>
                    <Conditional when={receipt.status === "PENDING"}>
                        <VerticalDivider M />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                paddingLeft: defPadding,
                                paddingRight: defPadding,
                            }}>
                            <Paragraph
                                css={{
                                    color: theme.colors.black.light60.asString,
                                    textAlign: "left",
                                }}
                                content={[
                                    translation({
                                        sv: `Väntar på bekräftelse att återbetalning på `,
                                        en: `Waiting for confirmation that a refund of `,
                                    }),
                                    paragraphPriceFactory({
                                        price: receipt.amount,
                                    }),
                                    translation({
                                        sv: ` slutförts.`,
                                        en: ` has been completed.`,
                                    }),
                                ]}
                            />
                        </FlexColumn>
                    </Conditional>
                    <Conditional when={receipt.status === "CANCELLED"}>
                        <VerticalDivider M />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                paddingLeft: defPadding,
                                paddingRight: defPadding,
                            }}>
                            <Paragraph
                                css={{
                                    color: theme.colors.black.light60.asString,
                                    textAlign: "left",
                                }}
                                content={[
                                    translation({
                                        sv: `Återbetalning på `,
                                        en: `Refund of `,
                                    }),
                                    paragraphPriceFactory({
                                        price: receipt.amount,
                                    }),
                                    translation({
                                        sv: ` har avbrutits.`,
                                        en: ` has been cancelled.`,
                                    }),
                                ]}
                            />
                        </FlexColumn>
                    </Conditional>
                    <FlexColumn fillParentWidth padding={{ all: 20 }}>
                        <Conditional
                            when={
                                !!receipt.refunds.length &&
                                receipt.refunds.every(
                                    item =>
                                        item.paymentProvider === "ON_PREMISE",
                                )
                            }>
                            <FlexRow>
                                <TextBox
                                    text={translation({
                                        sv: "Detta är inget kvitto",
                                        en: "This is not a receipt",
                                    })}
                                    case="UPPERCASE"
                                    size={0.7}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                            <FlexRow>
                                <TextBox
                                    text={translation({
                                        sv: "Återbetalning ej hanterad online",
                                        en: "Refund not handled online",
                                    })}
                                    case="UPPERCASE"
                                    size={0.7}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                            <VerticalDivider ML />
                        </Conditional>
                        <ForEach
                            itemOf={receipt.refunds}
                            divider={<VerticalDivider S />}>
                            {refund => {
                                const backgroundColor =
                                    refund.paymentProvider === "ON_PREMISE"
                                        ? theme.colors.warning.light.light95
                                              .asString
                                        : refund.status === "FULFILLED"
                                          ? theme.colors.primary.light.asString
                                          : refund.status === "PENDING"
                                            ? theme.colors.warning.light.light95
                                                  .asString
                                            : theme.colors.black.light95
                                                  .asString;
                                const color =
                                    refund.paymentProvider === "ON_PREMISE"
                                        ? theme.colors.warning.main.light40
                                              .asString
                                        : refund.status === "FULFILLED"
                                          ? theme.colors.primary.asString
                                          : refund.status === "PENDING"
                                            ? theme.colors.warning.main.light40
                                                  .asString
                                            : theme.colors.black.asString;
                                const icon =
                                    refund.status === "FULFILLED"
                                        ? faCheckCircle
                                        : refund.status === "PENDING"
                                          ? faClock
                                          : faCancel;
                                const statusText =
                                    refund.status === "FULFILLED"
                                        ? translation({
                                              sv: "Klar",
                                              en: "Done",
                                          })
                                        : refund.status === "PENDING"
                                          ? translation({
                                                sv: "Pågående",
                                                en: "In progress",
                                            })
                                          : translation({
                                                sv: "Avbruten",
                                                en: "Cancelled",
                                            });
                                const paymentProviderText =
                                    refund.paymentProvider === "STRIPE"
                                        ? translation({
                                              sv: "Kortbetalning",
                                              en: "Card payment",
                                          })
                                        : refund.paymentProvider === "SWISH"
                                          ? translation({
                                                sv: "Swish",
                                                en: "Swish",
                                            })
                                          : refund.paymentProvider ===
                                              "GIFTCARD"
                                            ? translation({
                                                  sv: "Presentkort",
                                                  en: "Gift card",
                                              })
                                            : refund.paymentProvider ===
                                                "ON_PREMISE"
                                              ? translation({
                                                    sv:
                                                        "Kontant hos " +
                                                        (provider?.meta.title
                                                            ? translate(
                                                                  provider?.meta
                                                                      .title,
                                                              )
                                                            : " leverantör"),
                                                    en:
                                                        "Cash at " +
                                                        (provider?.meta.title
                                                            ? translate(
                                                                  provider?.meta
                                                                      .title,
                                                              )
                                                            : " provider"),
                                                })
                                              : "";

                                return (
                                    <FlexColumn
                                        css={{
                                            flex: 1,
                                            padding: 15,
                                            borderRadius: 5,
                                            backgroundColor,
                                            color,
                                        }}>
                                        <FlexRow
                                            mainAxis="space-between"
                                            fillParentWidth>
                                            <FlexRow>
                                                <Icon
                                                    icon={icon}
                                                    size={1.2}
                                                    fixedWidth
                                                />
                                                <HorizontalDivider />
                                                <TextBox
                                                    text={statusText}
                                                    weight="900"
                                                />
                                            </FlexRow>
                                            <HorizontalDivider />
                                            <FlexRow>
                                                <Conditional
                                                    when={
                                                        receipt.refunds.length >
                                                        0
                                                    }>
                                                    <TextBox
                                                        text={
                                                            paymentProviderText
                                                        }
                                                        size={0.8}
                                                        color={color}
                                                    />
                                                    <HorizontalDivider />
                                                    <PriceTextBox
                                                        price={refund.amount}
                                                        size={0.8}
                                                        color={color}
                                                    />
                                                    <HorizontalDivider />
                                                    <TextBox
                                                        text={"-"}
                                                        size={0.8}
                                                        color={color}
                                                    />
                                                    <HorizontalDivider />
                                                </Conditional>
                                                <DateTimeTextBox
                                                    dateTime={refund._createdAt}
                                                    size={0.8}
                                                    align="right"
                                                    color={color}
                                                />
                                            </FlexRow>
                                        </FlexRow>
                                        <Conditional
                                            when={
                                                // When refund has been made
                                                // online and not on premise
                                                refund.paymentProvider !==
                                                "ON_PREMISE"
                                            }>
                                            <VerticalDivider M />
                                            <FlexColumn
                                                crossAxis="stretch"
                                                css={{
                                                    padding: 20,
                                                    borderRadius: 5,
                                                    border: `1px dotted ${theme.colors.primary.asString}`,
                                                    backgroundColor:
                                                        theme.colors.default
                                                            .background
                                                            .asString,
                                                }}>
                                                {/* Show row with refund title and total sum */}
                                                <FlexRow
                                                    mainAxis="space-between"
                                                    css={{ flex: 1 }}>
                                                    <TextBox
                                                        text={{
                                                            sv: "Återbetalning",
                                                            en: "Refund",
                                                        }}
                                                        color={
                                                            theme.colors.default
                                                                .text
                                                        }
                                                    />
                                                    <HorizontalDivider />
                                                    <PriceTextBox
                                                        price={refund.amount}
                                                        color={
                                                            theme.colors.default
                                                                .text
                                                        }
                                                    />
                                                </FlexRow>
                                                <VerticalDivider M />
                                                <VatTable
                                                    price={refund.amount}
                                                />
                                            </FlexColumn>
                                            <VerticalDivider XS />
                                        </Conditional>
                                        <Conditional
                                            when={
                                                refund.paymentProvider ===
                                                "ON_PREMISE"
                                            }>
                                            <VerticalDivider S />
                                            <Paragraph
                                                css={{
                                                    color: theme.colors.warning
                                                        .main.light40.asString,
                                                    fontSize: "0.825rem",
                                                }}
                                                content={[
                                                    translation({
                                                        sv: `Denna del av återbetalningen är gjord/görs på plats då betalningen inte skett online. Kvitto för denna del av återbetalningen erhölls/erhålls därför av `,
                                                        en: `This part of the refund was/is to be refunded on premise. Receipt for this part of the refund transaction was/is therefore received from `,
                                                    }),
                                                    provider?.meta.title ??
                                                        translation({
                                                            sv: `leverantören`,
                                                            en: `the provider`,
                                                        }),
                                                    translation({
                                                        sv: " direkt eftersom betalningen inte är gjord online.",
                                                        en: " directly since the payment was not made online.",
                                                    }),
                                                ]}
                                            />
                                        </Conditional>
                                    </FlexColumn>
                                );
                            }}
                        </ForEach>
                    </FlexColumn>

                    <Conditional when={receipt.status === "FULFILLED"}>
                        <FlexRow fillParentWidth padding={{ top: 10 }}>
                            <HorizontalLine fillParentWidth />
                        </FlexRow>
                        {/* <VerticalDivider S /> */}
                        <FlexColumn fillParentWidth>
                            {/* <FlexColumn
                                fillParentWidth
                                css={{
                                    paddingLeft: defPadding,
                                    paddingRight: defPadding,
                                }}>
                                <PayRequestItemPriceTable
                                    items={receipt.items}
                                    payRequestPrice={receipt.amount}
                                />
                            </FlexColumn>
                            <VerticalDivider L /> */}
                            {/* <Conditional
                                when={receipt.refunds.some(refund => {
                                    return (
                                        refund.paymentProvider === "ON_PREMISE"
                                    );
                                })}>
                                <FlexRow fillParentWidth>
                                    <Paragraph
                                        css={{
                                            backgroundColor:
                                                theme.colors.warning.light
                                                    .light95.asString,
                                            color: theme.colors.warning.main
                                                .light40.asString,
                                            padding: 20,
                                            margin: 20,
                                            borderRadius: 10,
                                        }}
                                        content={[
                                            translation({
                                                sv: "Av totalen på ",
                                                en: "Of the total of ",
                                            }),
                                            paragraphPriceFactory({
                                                price: receipt.amount,
                                            }),
                                            translation({
                                                sv: " så har ",
                                                en: " ",
                                            }),
                                            paragraphPriceFactory({
                                                price: sumPrices(
                                                    receipt.refunds
                                                        .filter(
                                                            item =>
                                                                item.paymentProvider ===
                                                                "ON_PREMISE",
                                                        )
                                                        .map(
                                                            item => item.amount,
                                                        ),
                                                ),
                                            }),
                                            translation({
                                                sv: " återbetalats på plats (ej automatiskt online) av ",
                                                en: " has been refunded on premise (not automatically online) by ",
                                            }),
                                            provider?.meta.title ??
                                                translation({
                                                    sv: `leverantören`,
                                                    en: `the provider`,
                                                }),
                                            translation({
                                                sv: ". Kvitto för den delen av transaktionen/återbetalningssumman erhålls/erhölls därför av ",
                                                en: `. Receipt for this part of the refund transaction was therefore received by `,
                                            }),
                                            provider?.meta.title ??
                                                translation({
                                                    sv: `leverantören`,
                                                    en: `the provider`,
                                                }),
                                            translation({
                                                sv: ` direkt.`,
                                                en: ` directly.`,
                                            }),
                                        ]}
                                    />
                                </FlexRow>
                            </Conditional> */}
                            <FlexColumn
                                fillParentWidth
                                css={{
                                    paddingTop: 30,
                                    backgroundColor:
                                        theme.colors.primary.main.withAlpha(0.1)
                                            .asString,
                                }}>
                                <FlexRow
                                    fillParentWidth
                                    mainAxis="space-between">
                                    <FlexColumn
                                        css={{
                                            height: "100%",
                                            paddingLeft: 20,
                                            maxWidth: "50%",
                                        }}
                                        crossAxis="flex-start"
                                        mainAxis="flex-end">
                                        <Conditional when={!!provider}>
                                            {provider ? (
                                                <ReceiptCompanyDetails
                                                    provider={provider}
                                                />
                                            ) : null}
                                        </Conditional>
                                        <VerticalDivider M />
                                        <FlexColumn
                                            crossAxis="flex-start"
                                            mainAxis="center">
                                            <TextBox
                                                text={translation({
                                                    sv: "Köpare",
                                                    en: "Buyer",
                                                })}
                                                case="UPPERCASE"
                                                size={0.7}
                                                weight="900"
                                                userSelect="text"
                                                color={titleColor}
                                            />
                                            <VerticalDivider XXS />
                                            <TextBox
                                                text={
                                                    customer
                                                        ? fullName(customer)
                                                        : ""
                                                }
                                                spin={customerQuery.isPending}
                                                weight="400"
                                                userSelect="text"
                                                size={0.8}
                                                color={textColor}
                                            />
                                        </FlexColumn>
                                        <VerticalDivider XXS />
                                        <FlexRow
                                            mainAxis="flex-start"
                                            crossAxis="center">
                                            <TextBox
                                                text={orderGroup.customerId}
                                                weight="400"
                                                userSelect="text"
                                                size={0.8}
                                                color={textColor}
                                            />
                                        </FlexRow>
                                    </FlexColumn>
                                    <FlexColumn
                                        css={{
                                            height: "100%",
                                            maxWidth: "50%",
                                        }}
                                        crossAxis="flex-end"
                                        mainAxis="space-between">
                                        <FlexColumn
                                            fillParentWidth
                                            crossAxis="flex-end">
                                            <TextBox
                                                text={translation({
                                                    sv: "Orderdatum",
                                                    en: "Order date",
                                                })}
                                                case="UPPERCASE"
                                                size={0.7}
                                                weight="900"
                                                userSelect="text"
                                                padding={{ leftRight: 20 }}
                                                color={titleColor}
                                            />
                                            <VerticalDivider XXS />
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{ leftRight: 20 }}>
                                                <DateTimeTextBox
                                                    dateTime={
                                                        orderGroup._createdAt
                                                    }
                                                    weight="400"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                        </FlexColumn>
                                        <VerticalDivider M />
                                        <FlexColumn
                                            fillParentWidth
                                            crossAxis="flex-end">
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{ leftRight: 20 }}>
                                                <TextBox
                                                    text={orderGroup.slug}
                                                    weight="700"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                            <VerticalDivider XXS />
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{ leftRight: 20 }}>
                                                <TextBox
                                                    text={orderGroup.reference}
                                                    weight="700"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                            <VerticalDivider XXS />
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{
                                                    leftRight: 20,
                                                }}>
                                                <TextBox
                                                    text={
                                                        receipt.receiptFor
                                                            .orderId
                                                    }
                                                    weight="400"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexColumn>
                                </FlexRow>
                                <VerticalDivider L />
                            </FlexColumn>
                        </FlexColumn>
                    </Conditional>
                </FlexColumn>
            </FlexRow>
        </div>
    );
};

export { RefundReceipt };
