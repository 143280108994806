import { useQueryOrParamSingleValue } from "@@/shared/use-query-or-param";
import { isOrderGroupId, OrderGroupId } from "@towni/common";
import { Navigate } from "react-router-dom";
/**
 * @deprecated only here for old urls in prod.
 * */
const OrderGroupSuccessPage = (props: { orderGroupId?: OrderGroupId }) => {
    const orderGroupId = useQueryOrParamSingleValue<OrderGroupId>(
        "orderGroupId",
        {
            override: props.orderGroupId,
            validate: isOrderGroupId,
        }
    );

    if (!orderGroupId) {
        throw new Error("Order group not found; " + orderGroupId);
    }
    return <Navigate to={`/receipts/${encodeURIComponent(orderGroupId)}`} />;
};

export { OrderGroupSuccessPage };
