import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontGridProductCard } from "@@/store-fronts/shared/store-front-grid-product-card";
import { ProductId, RemSize, unique } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { lackoColors } from "../lacko-color-context";

const LackoStayTheNightTwoSection: FC = () => {
    const getProductId = useProductIdWithFallback();

    const caravanId = React.useMemo(
        () => getProductId("product_Np93zC-1BKViiX0qPugE" as ProductId),
        []
    );
    const campingId = React.useMemo(
        () => getProductId("product_fFtaNTb8SqtzecRo2o_e" as ProductId),
        []
    );
    const productIds = [caravanId, campingId];
    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea sectionAreaId="lacko-stay-2-section" padding={{ top: 10 }}>
            <SimpleGrid
                size={0.9 as RemSize}
                padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <StoreFrontGridProductCard
                            key={hasDuplicates ? index : id}
                            productId={id}
                            kickerColor={lackoColors.primary}
                            showPrice
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

export { LackoStayTheNightTwoSection };
