import {
    colorFactory,
    colorSetFactory,
    transparentColor,
    whiteColor,
} from "@towni/common";
import React, { useContext } from "react";
//hsl(191, 49%, 21%)
const navenColors = {
    primary: colorSetFactory({
        main: colorFactory({ hue: 191, saturation: 0.49, light: 0.21 }), // #,
        dark: colorFactory({ hue: 191, saturation: 0.49, light: 0.2 }), // #
        light: colorFactory({ hue: 191, saturation: 0.49, light: 0.4 }), // #
        background: colorFactory({ hue: 191, saturation: 0.49, light: 0.21 }), // #

        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: transparentColor,
        shadow: colorFactory({ hue: 191, saturation: 0.49, light: 0.9 }), // #
    }),

    secondary: colorSetFactory({
        main: colorFactory({ hue: 354, saturation: 1, light: 0.32 }), // #,
        dark: colorFactory({ hue: 354, saturation: 1, light: 0.22 }), // #
        light: colorFactory({ hue: 354, saturation: 0.58, light: 0.96 }), // #
        background: colorFactory({ hue: 354, saturation: 1, light: 0.32 }), // #
        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: colorFactory({ hue: 354, saturation: 1, light: 0.23 }), // #
        shadow: colorFactory({ hue: 354, saturation: 1, light: 0.8 }), // #
    }),
    lightGreenBackground: colorFactory({
        hue: 112,
        saturation: 0.18,
        light: 0.89,
    }),
    lightBrownBackground: colorFactory({
        hue: 21,
        saturation: 0.16,
        light: 0.94,
    }),
};
const NavenColorContext = React.createContext(navenColors);

const useNavenColorContext = (): typeof navenColors => {
    const context = useContext(NavenColorContext);
    if (!context) throw new Error("must be used within a NavenColorContext");

    return context;
};

export { useNavenColorContext, NavenColorContext, navenColors };
