import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { AutoHeightTextArea } from "@@/products/auto-height-textarea";
import { useTheme } from "@emotion/react";
import { Translatable, translation } from "@towni/common";
import { FlexColumn } from "./flex-containers";
import { TextBox } from "./text/text-box";

type Props = {
    placeholder?: Translatable;
    modalId: ModalId;
    onHide?: () => void;
};

const defaultHeight = 87;
// eslint-disable-next-line react/display-name
const AutoHeightTextModal = (props: Props) => {
    const theme = useTheme();
    const { hide } = useModalActions(props.modalId);

    return (
        <Modal modalId={props.modalId} onHide={props.onHide}>
            <FlexColumn fillParentWidth crossAxis="center" mainAxis="flex-end">
                <AutoHeightTextArea
                    placeholder={props.placeholder}
                    initialHeight={defaultHeight}
                    hide={hide}
                />
                <div
                    onClick={hide}
                    css={{
                        padding: 20,
                        width: "100%",
                        height: 80,
                        backgroundColor: theme.colors.primary.asString,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Klart",
                            en: "Ready",
                        })}
                        size="L"
                        color={theme.colors.primary.text}
                    />
                </div>
            </FlexColumn>
        </Modal>
    );
};

export { AutoHeightTextModal };
