import { WithAlfred } from "@@/authentication/me/with-alfred";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Paragraph, TextBox, paragraphLineBreakFactory } from "@@/shared/text";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import {
    faArrowRotateRight,
    faFaceParty,
} from "@fortawesome/pro-regular-svg-icons";
import { IsoTimestamp, asDate, parseSafely, translation } from "@towni/common";
import { differenceInCalendarDays } from "date-fns";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { browserLogger } from "./browser-logger";
import { useBuildVersionStore } from "./build-version.store";

const BuildVersionMinorMismatchHandler = () => {
    const toast = useToast();
    const buildVersions = useBuildVersionStore();

    useEffect(() => {
        if (buildVersions.majorMismatch) return;
        if (buildVersions.minorMismatch) {
            browserLogger.warn(
                "Build version mismatch",
                buildVersions.server,
                buildVersions.client,
            );

            toast.warning({
                title: "Det finns en ny version av appen",
                message: "Ladda om sidan för att få senaste versionen.",
                sticky: true,
                key: "NEW_VERSION_TOASTY",
            });
        }
    }, [buildVersions, toast]);

    return null;
};

const BuildVersionMinorMismatchBanner = () => {
    const theme = useTheme();
    const buildVersions = useBuildVersionStore();
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });

    useEffect(() => {
        // Log build versions to console log
        browserLogger.log(buildVersions);
    }, [buildVersions]);

    const data = useMemo(() => {
        // Calculate how old client build is
        // compared to the server build
        const serverBuildTimeIso = parseSafely({
            schema: z.string().datetime() as unknown as z.ZodType<IsoTimestamp>,
            value: buildVersions.server.buildTimestamp,
        });
        const clientBuildTimeIso = parseSafely({
            schema: z.string().datetime() as unknown as z.ZodType<IsoTimestamp>,
            value: buildVersions.client.buildTimestamp,
        });
        const serverBuildTime = serverBuildTimeIso
            ? asDate(serverBuildTimeIso)
            : undefined;
        const clientBuildTime = clientBuildTimeIso
            ? asDate(clientBuildTimeIso)
            : undefined;
        const dayDiff =
            serverBuildTime && clientBuildTime
                ? differenceInCalendarDays(serverBuildTime, clientBuildTime)
                : 0;
        return {
            serverBuildTime,
            clientBuildTime,
            dayDiff,
        };
    }, [buildVersions]);
    if (!data) return null;
    if (data.dayDiff < 1) return null;

    const Container = isSmall ? FlexColumn : FlexRow;

    return (
        <Container
            fillParentWidth
            mainAxis={isSmall ? "flex-start" : "space-between"}
            css={{
                backgroundColor: theme.colors.warning.light.asString,
                color: theme.colors.warning.dark.asString,
                borderBottom: `1px solid ${theme.colors.black.light90.asString}`,
                padding: 20,
            }}>
            <FlexRow>
                <Icon icon={faFaceParty} size={2} />
                <HorizontalDivider M />
                <FlexColumn>
                    <Paragraph
                        css={{
                            fontWeight: 700,
                        }}
                        content={[
                            translation({
                                sv: "Det finns en ny version av appen.",
                                en: "There is a new version of the app.",
                            }),
                            paragraphLineBreakFactory(),
                            translation({
                                sv: "Ladda om sidan för att få senaste versionen.",
                                en: "Reload the page to get the latest version.",
                            }),
                        ]}
                    />
                    <WithAlfred noPadding>
                        <VerticalDivider />
                        <Paragraph
                            content={[
                                `Server: ${buildVersions.server.buildId}, ${data.serverBuildTime?.toLocaleString()}`,
                                paragraphLineBreakFactory(),
                                `Client: ${buildVersions.client.buildId}, ${data.clientBuildTime?.toLocaleString()}`,
                            ]}
                        />
                    </WithAlfred>
                </FlexColumn>
            </FlexRow>
            {isSmall ? <VerticalDivider S /> : <HorizontalDivider />}
            <ButtonWhite
                fillParentWidth={isSmall}
                onClick={() => {
                    window.location.reload();
                }}>
                <FlexRow>
                    <Icon icon={faArrowRotateRight} />
                    <HorizontalDivider S />
                    <TextBox
                        text={translation({
                            sv: "Ladda om för senaste version",
                            en: "Reload for latest version",
                        })}
                    />
                </FlexRow>
            </ButtonWhite>
        </Container>
    );
};

export { BuildVersionMinorMismatchBanner, BuildVersionMinorMismatchHandler };
