import { browserLogger } from "@@/settings/browser-logger";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useToast } from "@@/toasts/context/toast-context";
import {
    IconDefinition,
    faVolume,
    faVolumeMute,
    faVolumeSlash,
} from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { Translatable, exists, translation } from "@towni/common";
import { Howl, Howler } from "howler";
import * as React from "react";
import { useState } from "react";

type SoundKey =
    | "none"
    | "wooden-pling"
    | "pling-2"
    | "alarm"
    | "plingeling"
    | "supermarketbeep";
type Sound = {
    key: SoundKey;
    title: Translatable;
    sound: Howl | null;
};

const AudioContext = React.createContext<
    | {
          readonly selectedSound: SoundKey;
          readonly selectSound: (sound: SoundKey) => void;
          readonly defaultSound: SoundKey;
          readonly icon: IconDefinition;
          readonly description: Translatable;
          readonly audioEnabled: boolean;
          readonly playSound: (name?: SoundKey) => Promise<void>;
          readonly mute: (value: boolean) => void;
          readonly isMuted: boolean;
          readonly sounds: Sound[];
      }
    | undefined
>(undefined);
const AudioProvider = (props: { readonly children?: React.ReactNode }) => {
    const [defaultSound] = useState<SoundKey>("wooden-pling");
    const toast = useToast();

    useMountEffect(() => {
        if (!localStorage && toast) {
            toast.warning({
                message: `Din webbläsare stöder inte "local storage" vilket betyder att vi inte kan spara eventuella inställningar du gör till nästa gång du använder applikationen`,
            });
        }
    });

    const [selectedSound, setSelectedSound] = useState(
        (localStorage?.getItem("notification_sound") as SoundKey) ||
            defaultSound,
    );
    const selectSound = React.useCallback((sound: SoundKey) => {
        if (localStorage) {
            localStorage.setItem("notification_sound", sound);
        }
        setSelectedSound(sound);
    }, []);
    const [isMuted, setIsMuted] = useState(
        localStorage?.getItem("mute") === "true",
    );
    const mute = React.useCallback((value: boolean) => {
        if (localStorage) {
            localStorage.setItem("mute", value ? "true" : "false");
        }
        setIsMuted(value);
    }, []);

    const [soundMap] = useState(() => {
        const none = [
            "none",
            {
                key: "none",
                title: translation({ sv: "Inget ljud", en: "No sound" }),
                sound: null,
            },
        ] as [SoundKey, Sound];

        const sound = (key: string, title: string, relativePath: string) => {
            try {
                return [
                    key,
                    {
                        key,
                        title: title,
                        sound: new Howl({
                            src: [relativePath],
                            volume: 1.0,
                            onplay: () =>
                                browserLogger.log(`🔈 play sound ${key}`),
                            onplayerror: (soundId, error) => {
                                Sentry.captureException(error);
                                browserLogger.error(error, soundId);
                                // alert(JSON.stringify(error));
                            },
                            onloaderror: (soundId, error) => {
                                //Sentry.captureException(error);
                                browserLogger.error(error, soundId);
                                // alert(JSON.stringify(error));
                            },
                        }),
                    },
                ] as [SoundKey, Sound];
            } catch (error) {
                Sentry.captureException(error);
                return undefined;
            }
        };

        const sounds = [
            none,
            sound("wooden-pling", "Pling", "/sounds/pling1.mp3"),
            sound("pling-2", "Pling 2", "/sounds/pling2.mp3"),
            sound("plingeling", "Plingeling", "/sounds/plingeling1.mp3"),
            sound("alarm", "Alarm", "/sounds/alarm1.mp3"),
            sound(
                "supermarketbeep",
                "Supermarket Beep",
                "/sounds/supermarketbeep.mp3",
            ),
        ].filter(exists);
        return new Map<SoundKey, Sound>(sounds);
    });
    const sounds = React.useMemo(
        () => Array.from(soundMap.values()),
        [soundMap],
    );

    const playSound = React.useCallback(
        async (key?: SoundKey) => {
            try {
                browserLogger.log(`🔈 play sound request, ${key}`);
                if (key === "none") {
                    browserLogger.log("🔈 sound is muted");
                    return;
                }
                if (Howler.noAudio) {
                    browserLogger.warn("🔈 Audio is not yet available");
                    alert("🔈 Audio is not yet available");
                    return;
                }
                const audio = soundMap.get(key ?? selectedSound);
                if (!audio) {
                    browserLogger.warn("🔈 Audio is not yet available");
                    alert("🔈 Audio is not yet available");
                    return;
                }
                if (audio.sound === null) {
                    browserLogger.log("🔈 sound is muted");
                    return;
                }

                if (audio.sound.state() === "loaded") {
                    audio.sound.play();
                }
            } catch (error) {
                browserLogger.error(error);
            }
        },
        [selectedSound, soundMap],
    );

    const state = React.useMemo(() => {
        const _audioEnabled = !Howler.noAudio && !isMuted;
        const _isMuted = isMuted || selectedSound === "none";
        return {
            sounds,
            defaultSound,
            audioEnabled: _audioEnabled,
            playSound,
            mute,
            icon: _isMuted
                ? faVolumeMute
                : _audioEnabled
                  ? faVolume
                  : faVolumeSlash,
            description: _isMuted
                ? translation({
                      en: "Sound is muted",
                      sv: "Inget ljud",
                  })
                : _audioEnabled
                  ? translation({
                        sv: "Ljudet är på",
                        en: "Sound is on",
                    })
                  : translation({
                        sv: "Ljudet är av",
                        en: "Sound is off",
                    }),
            isMuted: _isMuted,
            selectSound,
            selectedSound,
        };
    }, [
        defaultSound,
        isMuted,
        mute,
        playSound,
        selectSound,
        selectedSound,
        sounds,
    ]);

    return (
        <AudioContext.Provider value={state}>
            {props.children}
        </AudioContext.Provider>
    );
};

export { AudioContext, AudioProvider };
export type { SoundKey as SoundName };
