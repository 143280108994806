import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { useTheme } from "@emotion/react";
import { ProductId, translation } from "@towni/common";
import React, { FC } from "react";

// type Props = {};
const BauerTimeTogether: FC = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const sauna = React.useMemo(
        () => getProductId("product__B9pqXMzWNIv52dg9Fjbt" as ProductId),
        []
    );

    return (
        <SectionArea
            title={translation({
                sv: "Tid tillsammans",
                en: "Time together",
            })}
            subtitle={translation({
                sv: "Isande vinterbad och värmande bastu, en härlig stund för kropp och själ. Vill du ha egentid eller kanske uppleva detta tillsammans med någon du bryr dig om?",
                en: "",
            })}
            sectionAreaId="bauer-vinter-4-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                <OneSquareSection
                    key={sauna}
                    productId={sauna}
                    aspectRatio={"16:9"}
                    contentTop={false}
                    actionText={translation({ sv: "Ta det lungt" })}
                    hideKicker
                    colorSet={theme.colors.primary}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { BauerTimeTogether };
