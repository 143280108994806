import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Percentage, Translatable, remSize, translation } from "@towni/common";

type Option<OptionId = string> = {
    id: OptionId;
    name: Translatable;
    description?: Translatable;
    icon?: IconDefinition;
    nonStrictPathMatch?: boolean;
};

type Props<OptionId = string> = {
    modalId: ModalId;
    title?: Translatable;
    options: Option<OptionId>[];
    onSelect: (id: OptionId) => void;
    render?: (option: Option<OptionId>) => JSX.Element;
};

const OptionPickerModal = <OptionId extends string | number = string>(
    props: Props<OptionId>,
) => {
    const theme = useTheme();
    const { hide } = useModalActions(props.modalId);

    return (
        <Modal
            modalId={props.modalId}
            height={0.9 as Percentage}
            header={
                <>
                    <ModalHeader
                        modalId={props.modalId}
                        bottomBorder={true}
                        title={
                            props.title ??
                            translation({
                                sv: "Välj sida",
                                en: "Page",
                            })
                        }
                    />
                </>
            }>
            <ForEach
                itemOf={props.options}
                divider={<HorizontalLine fillParentWidth />}>
                {option => {
                    return (
                        props.render?.(option) ?? (
                            <FlexRow
                                key={option.id}
                                fillParentWidth
                                onClick={() => {
                                    props.onSelect(option.id);
                                    hide();
                                }}
                                padding={{ all: 20 }}
                                crossAxis="center"
                                mainAxis={
                                    option.icon ? "flex-start" : "center"
                                }>
                                {option.icon ? (
                                    <FlexRow width={35} mainAxis="center">
                                        <Icon
                                            icon={option.icon}
                                            size={remSize(1.125)}
                                            color={theme.colors.default.text}
                                        />
                                        <HorizontalDivider M />
                                    </FlexRow>
                                ) : null}
                                <FlexColumn>
                                    <TextBox
                                        key={option.id + "_name"}
                                        color={theme.colors.default.text}
                                        text={option.name}
                                        size={1.125}
                                    />
                                    <VerticalDivider XXS />
                                    <TextBox
                                        key={option.id + "_description"}
                                        color={theme.colors.default.text.withAlpha(
                                            0.4,
                                        )}
                                        text={option.description}
                                        size={1}
                                    />
                                </FlexColumn>
                            </FlexRow>
                        )
                    );
                }}
            </ForEach>
        </Modal>
    );
};

export { OptionPickerModal };
export type { Option as OptionPickerOption };
