const initialForward = {
    position: "absolute" as const,
    x: "70%",
    opacity: 0,
    zIndex: 1,
};
const initialBackward = {
    position: "absolute" as const,
    x: "-70%",
    opacity: 0,
    zIndex: 1,
};

const pageAnimationSlideAndFade = (direction: "FORWARD" | "BACKWARD") => {
    const slideAndFade = {
        initial: direction === "FORWARD" ? initialForward : initialBackward,
        enter: {
            x: "0%",
            opacity: 1,
            zIndex: 1,
            transitionEnd: {
                position: "relative" as const,
            },
        },
        exit: {
            position: "absolute" as const,
            x: "0%",
            opacity: 0,
            zIndex: 0,
            transitionEnd:
                direction === "FORWARD" ? initialBackward : initialForward,
        },
    };

    return slideAndFade;
};

const pageTransition = {
    stiffness: 400, // tension
    friction: 20,
    mass: 1,
};

export { pageAnimationSlideAndFade, pageTransition };
