import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { ShowCartButton } from "@@/carts";
import { AppPage } from "@@/pages/app-page";
import { Anchor, anchorFactory } from "@@/pages/container-navigation/anchor";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { CartProviderCheckModal } from "@@/providers/cart-provider-check-modal";
import { ProviderPauseCheckModal } from "@@/providers/provider-pause-check-modal";
import { SectionById } from "@@/sections/section-by-id";
import { useSections } from "@@/sections/state/section-fetchers";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { HeadTitle } from "@@/shared/head-title";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { useQueryOrParamSingleValue } from "@@/shared/use-query-or-param";
import { css, useTheme } from "@emotion/react";
import {
    MetaImage,
    SectionGroupId,
    Translatable,
    emptyArrayOf,
    parseMetaContentPosterImage,
    sortBy,
} from "@towni/common";
import { AnimatePresence } from "framer-motion";
import * as React from "react";
import { SectionGroup } from "./section-group";
import { SectionGroupPoster } from "./section-group-poster";
import { useSectionGroup } from "./state/section-group-fetchers";

type Props = {
    sectionGroupId?: SectionGroupId;
    posterImage?: MetaImage;
    information?: React.ReactNode;
    headerMiddle?: React.ReactNode;
    title?: Translatable;
    subtitle?: Translatable;
    hidden?: boolean;
};
const SectionGroupPage = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const sectionGroupId = useQueryOrParamSingleValue<SectionGroupId>(
        "sectionGroupId",
        { override: props.sectionGroupId },
    );
    if (!sectionGroupId) throw new Error("Section group id not available");
    const [sectionGroup, { isLoading, isError, error, isStale }] =
        useSectionGroup(sectionGroupId);
    const [sections, sectionsQuery] = useSections(
        sectionGroup
            ? sectionGroup.items
                  .filter(item => item.type === "SECTION")
                  .map(item => item.itemId)
            : undefined,
    );

    const content = React.useMemo(() => {
        if (!sectionGroup?.items?.length || !sections?.length)
            return emptyArrayOf<{
                anchor: Anchor;
                item: JSX.Element;
            }>();
        return (
            sectionGroup?.items
                // Only show sections loaded and that has items
                .filter(
                    item =>
                        item.type !== "SECTION" ||
                        !!sections.find(sect => sect._id === item.itemId)?.items
                            .length,
                )
                .sort(sortBy(item => item.sortNumber))
                .map(item => {
                    const anchor = anchorFactory({
                        title: item.title,
                        description: item.title,
                        sectionId: item.itemId,
                    });

                    const component =
                        item.type === "SECTION" ? (
                            <SectionById
                                key={item.itemId}
                                sectionId={item.itemId}
                                anchor={anchor}
                            />
                        ) : (
                            <SectionGroup
                                key={item.itemId}
                                sectionGroupId={item.itemId}
                                anchor={anchor}
                            />
                        );

                    return {
                        anchor,
                        item: component,
                    };
                }) ??
            emptyArrayOf<{
                anchor: Anchor;
                item: JSX.Element;
            }>()
        );
    }, [sectionGroup?.items, sections]);

    const anchors = React.useMemo(() => content.map(c => c.anchor), [content]);

    let loading =
        isLoading ||
        sectionsQuery.isPending ||
        (!sections && !!sectionsQuery.isStale);
    // if (isLoading) return <PageLoad />;
    // if (sectionsQuery.isPending) return <PageLoad />;
    // if (!sections && !!sectionsQuery.isStale) return <PageLoad />;
    if (isError) throw error;

    if (!loading && !sectionGroup && !isStale) {
        return <PageNotFound />;
    }
    if (!loading && !sections && !sectionsQuery.isStale) {
        return <PageNotFound />;
    }
    loading = loading || !sectionGroup || !sections;
    // if (!sectionGroup) return <PageLoad />;
    // if (!sections) return <PageLoad />;

    const posterImage =
        props.posterImage ?? parseMetaContentPosterImage(sectionGroup?.meta);

    const title = props.title || sectionGroup?.meta.title;
    const subtitle = props.subtitle || sectionGroup?.meta.subtitle;
    const appPageId = appPageIdFactory(sectionGroupId);

    return (
        <>
            <ErrorBoundary>
                <HeadTitle title={[props.title, props.subtitle]} />
                <AppPage
                    key={appPageId}
                    pageId={appPageId}
                    anchors={anchors}
                    isLoading={loading || !content.length}
                    pageContainerCss={css({
                        paddingBottom: vh100 * 0.5,
                    })}
                    showNavMenu
                    header={
                        <FlexColumn
                            fillParentWidth
                            shrink={0}
                            crossAxis="stretch"
                            css={{
                                maxWidth: theme.sizes.pageMaxWidthDesktop,
                                backgroundColor:
                                    theme.colors.default.background.asString,
                                position: "relative",
                                zIndex: 50,
                            }}>
                            <Conditional
                                when={props.hidden}
                                render={() => (
                                    <FlexRow
                                        mainAxis="center"
                                        fillParentWidth
                                        background={{
                                            color: theme.colors.danger,
                                        }}
                                        padding={{ all: 20 }}>
                                        <TextBox
                                            color={theme.colors.white}
                                            text={"⚠️ Gömd ⚠️"}
                                            weight="900"
                                        />
                                    </FlexRow>
                                )}
                            />
                            <SectionGroupPoster
                                title={title ?? null}
                                shortDescription={subtitle}
                                poster={posterImage}
                            />

                            <VerticalDivider M />
                            <Conditional
                                when={!!props.information}
                                render={() => (
                                    <>
                                        {props.information}
                                        <VerticalDivider S />
                                    </>
                                )}
                            />
                        </FlexColumn>
                    }>
                    <FlexColumn fillParentWidth overflowX="hidden">
                        <ForEach
                            itemOf={content}
                            divider={<VerticalDivider S />}>
                            {({ item }) => item}
                        </ForEach>
                    </FlexColumn>
                    <VerticalDivider size="3XL" />
                </AppPage>
            </ErrorBoundary>
            <CartProviderCheckModal providerId={sectionGroup?.ownerId} />
            <ProviderPauseCheckModal providerId={sectionGroup?.ownerId} />
            <AnimatePresence>
                <ShowCartButton />
            </AnimatePresence>
        </>
    );
};

export { SectionGroupPage };
