import * as React from "react";
import { HorizontalLine } from "@@/shared/dividers";
import { Translatable } from "@towni/common";
import { BuyableSkuRow } from "./skus/buyable-sku-row";
import { Join } from "@@/shared/join";
import { Sku, SkuId } from "@towni/common";

type Props = {
    skus: Sku[];
    productTitle: Translatable;
    select: (skuId: SkuId) => void;
    selectedId: SkuId | undefined;
    divider?: JSX.Element;
};

const PickSkuList = (props: Props) => {
    const selectSku = (skuId: SkuId) => () => props.select(skuId);
    const divider = props.divider || <HorizontalLine />;
    const buyableSkuRows = props.skus.map(sku => {
        return (
            <BuyableSkuRow
                key={sku._id}
                productTitle={props.productTitle}
                sku={sku}
                selected={sku._id === props.selectedId}
                select={selectSku(sku._id)}
            />
        );
    });

    return <Join divider={divider}>{buyableSkuRows}</Join>;
};

export { PickSkuList };
