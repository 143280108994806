import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { useApiResources } from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import {
    emptyArrayOf,
    MINUTES,
    OrderId,
    ResourceReservation,
} from "@towni/common";
import { reservationsMainQueryKey } from "../main-query-key";
import { fetchReservationsForOrders } from "./reservations-for-orders.fetcher";

const useResourceReservationsForOrders = (
    orderIds?: OrderId[],
): readonly [ResourceReservation[], UseQueryResult<ResourceReservation[]>] => {
    const accessKeyId = useAccessKeyIdFromUrl();
    const ids = orderIds ?? emptyArrayOf<OrderId>();

    return useApiResources<ResourceReservation>(
        [reservationsMainQueryKey, ...ids, accessKeyId],
        fetchReservationsForOrders(ids, accessKeyId),
        {
            enabled: !!ids.length,
            gcTime: 30 * MINUTES,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );
};

const useResourceReservationsForOrder = (orderId?: OrderId) => {
    return useResourceReservationsForOrders(orderId ? [orderId] : undefined);
};

export { useResourceReservationsForOrder, useResourceReservationsForOrders };
