import { useApiResources } from "@@/shared/state/use-api-resources";
import { CompanyDetails, ProviderId } from "@towni/common";
import { useMemo } from "react";
import { fetchCompanyDetailsForProvider } from "./details.fetcher";

const companyDetailsQueryKey = "company-details";

const useCompanyDetails = (providerId?: ProviderId) => {
    const result = useApiResources<CompanyDetails>(
        [companyDetailsQueryKey, providerId],
        fetchCompanyDetailsForProvider(providerId),
        {
            enabled: !!providerId,
        }
    );
    return useMemo(
        () => [result?.[0]?.[0] || undefined, result[1]] as const,
        [result]
    );
};

export { useCompanyDetails, companyDetailsQueryKey };
