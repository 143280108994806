import { Discount } from "@towni/common";
import * as React from "react";
import {
    PriceFractions,
    PriceTextBox,
    PriceTextBoxProps,
} from "./price-text-box";

type Props = PriceTextBoxProps & {
    readonly discounts: Discount[];
    readonly fractions?: PriceFractions;
    readonly priceIsDiscounted?: boolean;
};

const PriceWithDiscountsTextBox: React.FC<Props> = props => {
    const { discounts, ...priceTextBoxProps } = props;
    const { price, ..._textBoxProps } = priceTextBoxProps;
    // Todo: Decide on business logic
    // Todo: For now pick price or else buyable price or else bookable price.
    if (!price) throw new Error("Missing price");

    if (!discounts.length) return <PriceTextBox {...priceTextBoxProps} />;
    return <PriceTextBox {...priceTextBoxProps} />;
};

export { PriceWithDiscountsTextBox };
