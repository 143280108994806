import { AppTheme } from "@@/styles/theme";
import { customThinScrollbarCss } from "@@/styles/themes/custom-thin-scrollbars";
import { Interpolation } from "@emotion/react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { useCallback, useRef, useState } from "react";
import { ButtonWhitePrimary } from "./buttons_v2/button-gray";
import { FlexColumn, FlexRow } from "./flex-containers";
import { ForEach } from "./for-each";
import { Icon } from "./icons/icon";

type Props = {
    readonly className?: string;
    readonly items: JSX.Element[];
    readonly gap?:
        | number
        | {
              dividingGap: number;
              startGap: number;
              endGap: number;
              bottomGap: number;
          };
    readonly itemContainerCss?: Interpolation<AppTheme>;
};

const HzScrollContainer = (props: Props) => {
    const minHeight = 50;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const scrollContainer = useRef<HTMLDivElement | null>(null);
    const leftButton = useRef<HTMLDivElement | null>(null);
    const rightButton = useRef<HTMLDivElement | null>(null);
    const [_isScrollable, setIsScrollable] = useState(true);
    const [isScrollableToRight, setIsScrollableToRight] = useState(false);
    const [isScrollableToLeft, setIsScrollableToLeft] = useState(false);
    const dividingGap =
        typeof props.gap === "number" ? props.gap : props.gap?.dividingGap ?? 0;
    const startGap =
        typeof props.gap === "number" ? props.gap : props.gap?.startGap ?? 0;
    const endGap =
        typeof props.gap === "number" ? props.gap : props.gap?.endGap ?? 0;
    const bottomGap =
        typeof props.gap === "number" ? props.gap : props.gap?.bottomGap ?? 0;

    const setScrollContainer = useCallback((element: HTMLDivElement | null) => {
        const scrollCheck = (element: HTMLDivElement | null) => {
            const isScrollable =
                !!element && element.scrollWidth > element.clientWidth;
            const isScrollableToRight =
                isScrollable &&
                element.scrollWidth - element.scrollLeft > element.clientWidth;
            const isScrollableToLeft = isScrollable && element.scrollLeft > 0;

            setIsScrollable(isScrollable);
            setIsScrollableToRight(isScrollableToRight);
            setIsScrollableToLeft(isScrollableToLeft);
        };
        const onScroll = (scrollEvent: Event) => {
            const target: HTMLDivElement = scrollEvent.target as HTMLDivElement;
            scrollCheck(target);
        };
        scrollContainer.current?.removeEventListener("scroll", onScroll);
        scrollContainer.current = element;
        if (!scrollContainer.current) return;
        scrollContainer.current.addEventListener("scroll", onScroll);
        scrollCheck(scrollContainer.current);
    }, []);

    return (
        <div
            ref={containerRef}
            className={props.className}
            css={{
                width: "100%",
                height: "fit-content",
                minHeight,
                position: "relative",
            }}>
            <FlexColumn mainAxis="stretch" css={{ flex: 1, minHeight: "100%" }}>
                <FlexRow
                    ref={setScrollContainer}
                    crossAxis="stretch"
                    mainAxis={_isScrollable ? "flex-start" : "center"}
                    css={[
                        {
                            flex: 1,
                            overflowX: "auto",
                            scrollSnapType: "x mandatory",
                        },
                        customThinScrollbarCss,
                    ]}>
                    <ForEach
                        itemOf={props.items}
                        getKey={(item, index) => item.key || index}>
                        {(item, index) => {
                            const isFirst = index === 0;
                            const isLast = index === props.items.length - 1;
                            return (
                                <FlexColumn
                                    key={item.key}
                                    css={[
                                        {
                                            paddingLeft: isFirst
                                                ? startGap
                                                : dividingGap,
                                            paddingRight: isLast ? endGap : 0,
                                            scrollSnapAlign: "start",
                                            flex: "none",
                                            paddingBottom: bottomGap,
                                        },
                                        props.itemContainerCss,
                                    ]}>
                                    {item}
                                </FlexColumn>
                            );
                        }}
                    </ForEach>
                </FlexRow>
            </FlexColumn>
            <FlexColumn
                mainAxis="center"
                ref={leftButton}
                css={{
                    position: "absolute",
                    zIndex: 100,
                    top: 0,
                    left: 10,
                    transition: "opacity 0.2s",
                    opacity: isScrollableToLeft ? 1 : 0,
                    height: scrollContainer.current?.clientHeight ?? "100%",
                }}>
                <ButtonWhitePrimary
                    onClick={() => {
                        scrollContainer.current?.scrollBy({
                            left: -(
                                containerRef.current?.getBoundingClientRect()
                                    .width ?? 200
                            ),
                            behavior: "smooth",
                        });
                    }}
                    padding={{ all: 5 }}
                    css={{
                        borderRadius: 3000,
                        marginBottom: bottomGap,
                    }}>
                    <Icon icon={faChevronLeft} fixedWidth />
                </ButtonWhitePrimary>
            </FlexColumn>
            <FlexColumn
                mainAxis="center"
                ref={rightButton}
                css={{
                    position: "absolute",
                    zIndex: 100,
                    top: 0,
                    right: 10,
                    transition: "opacity 0.2s",
                    opacity: isScrollableToRight ? 1 : 0,
                    height: scrollContainer.current?.clientHeight ?? "100%",
                }}>
                <ButtonWhitePrimary
                    onClick={() => {
                        scrollContainer.current?.scrollBy({
                            left:
                                containerRef.current?.getBoundingClientRect()
                                    .width ?? 200,
                            behavior: "smooth",
                        });
                    }}
                    padding={{ all: 5 }}
                    css={{
                        borderRadius: 3000,
                        marginBottom: bottomGap,
                    }}>
                    <Icon icon={faChevronRight} fixedWidth />
                </ButtonWhitePrimary>
            </FlexColumn>
        </div>
    );
};

export { HzScrollContainer };
