import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { PriceEdit_V2_WithLegacyPadding } from "@@/shared/text/price-text-edit_v2";
import { Price, Translatable, isPriceEqual, zeroPrice } from "@towni/common";
import { Draft } from "immer";
import { ZodSchema } from "zod";

type Value = Price;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (
        state: Draft<Partial<State>>,
        newValue: Value | undefined,
    ) => void;
    readonly fieldSchema?: ZodSchema;

    readonly label?: Translatable;
};

const Form2PriceInput = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field?.value)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
    });

    return (
        <PriceEdit_V2_WithLegacyPadding
            vat={field.value.vat}
            required={field.isRequired}
            label={props.label}
            inputExclVat={false}
            initialPrice={field.initialValue}
            onBlur={() => {
                field.setTouched(true);
                validationTrigger();
            }}
            errorMessage={field.errors.join(", ")}
            onChange={newPrice => {
                field.setValue(newPrice ?? zeroPrice);
                field.setDirty(isPriceEqual(newPrice, field.initialValue));
                field.setTouched(true);
                validationTrigger();
            }}
        />
    );
};

export { Form2PriceInput };
