import { css } from "@emotion/react";
import * as React from "react";

const MapHomeIconSvg = () => {
    return (
        <div
            css={css`
                width: 57px;
                height: 74px;
                margin-top: -74px;
            `}>
            <svg
                width="57px"
                height="74px"
                viewBox="0 0 57 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="Frame 234">
                    <g id="Ellipse 7" filter="url(#filter0_f)">
                        <ellipse
                            cx="29"
                            cy="48"
                            rx="28"
                            ry="17"
                            fill="black"
                            fillOpacity="0.15"
                        />
                    </g>
                    <circle
                        id="Ellipse 5"
                        cx="28.5"
                        cy="74.5"
                        r="4.5"
                        fill="#0C9F80"
                    />
                    <path
                        id="Vector"
                        d="M0 27.375C0 38.4124 5.00336 42.4946 26.571 72.5271C27.9862 74.4909 29.0136 74.491 30.429 72.5271C51.9966 42.4946 57 38.4124 57 27.375C57 12.2562 44.2402 0 28.5 0C12.7598 0 0 12.2562 0 27.375Z"
                        fill="white"
                    />
                    <path
                        id="Vector_2"
                        d="M27.8895 19.1522L19 27.7142V36.955C19 37.1943 19.0813 37.4237 19.226 37.5929C19.3706 37.7621 19.5669 37.8571 19.7715 37.8571L25.1745 37.8408C25.3784 37.8396 25.5737 37.744 25.7175 37.575C25.8614 37.4059 25.9421 37.1771 25.9421 36.9386V31.5421C25.9421 31.3028 26.0234 31.0734 26.1681 30.9042C26.3127 30.735 26.509 30.6399 26.7136 30.6399H29.7994C30.004 30.6399 30.2002 30.735 30.3449 30.9042C30.4895 31.0734 30.5708 31.3028 30.5708 31.5421V36.9347C30.5705 37.0534 30.5902 37.171 30.6288 37.2808C30.6675 37.3906 30.7242 37.4904 30.7959 37.5744C30.8676 37.6585 30.9527 37.7252 31.0465 37.7707C31.1402 37.8162 31.2408 37.8397 31.3423 37.8397L36.7434 37.8571C36.948 37.8571 37.1442 37.7621 37.2889 37.5929C37.4336 37.4237 37.5149 37.1943 37.5149 36.955V27.708L28.6272 19.1522C28.5228 19.0537 28.3926 19 28.2584 19C28.1242 19 27.994 19.0537 27.8895 19.1522Z"
                        fill="black"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f"
                        x="-5"
                        y="25"
                        width="68"
                        height="46"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="3"
                            result="effect1_foregroundBlur"
                        />
                    </filter>
                </defs>
            </svg>
        </div>
    );
};

export { MapHomeIconSvg };
