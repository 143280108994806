import { ForEach } from "@@/shared/for-each";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId } from "@towni/common";
import { useMemo } from "react";
const ExploreBreathOfTowni = () => {
    const getProductId = useProductIdWithFallback();

    const productIds = useMemo(
        () => [
            // Bordsbokning, Mellbygatan
            getProductId("product__NBu1_tL2AkYywtiiDBKZ" as ProductId),
            // Bastuflotte med badtunna, Naven
            getProductId("product__RxdPdJ26sautittVnHOq" as ProductId),
            // Guidad visning vår & höst, Läckö
            getProductId("product_eQTf5_hmu17c-hDCZHas" as ProductId),
            // Relax med bastu och bubbelpool  2022, Kronocamping
            getProductId("product_ynBPw0Zenij0G1kFELAD" as ProductId),
        ],
        [],
    );

    return (
        <SectionArea
            title={"Mer från dina favoriter"}
            subtitle={
                "Hitta mer från dina favoriter. Från guidade turer till spa-bad och matupplevelser."
            }
            sectionAreaId="dafto-sea-4-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid
                size={"XL"}
                gap={10}
                padding={{ leftRight: 20, bottom: 10 }}>
                <ForEach itemOf={productIds} getKey={productId => productId}>
                    {(productId, index) => (
                        <OneSquareSection
                            productId={productId}
                            contentTop={index % 2 === 0}
                            hideKicker
                        />
                        // <OneSquareLinkSection
                        //     padding={{}}
                        //     imageUrl="https://we-are-alfred.imgix.net/destinations/smart-resande-se-alla.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges"
                        //     link={`/destinations/smart-resande-start`}
                        //     title={translation({ sv: "Smart resande" })}
                        //     subTitle={translation({
                        //         sv: "Elbil, elcyklar, turer och mer!",
                        //     })}
                        //     actionText={translation({
                        //         sv: "Utforska",
                        //     })}
                        //     contentTop={true}
                        // />
                    )}
                </ForEach>
            </SimpleGrid>
        </SectionArea>
    );
};

export { ExploreBreathOfTowni };
