import { AppPage } from "@@/pages/app-page";
import { PageLoad } from "@@/pages/page-load";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { BrandingProvider } from "@@/routes/shared/branding-context";
import { useProviderLoader } from "@@/routes/shared/loaders/provider-loader";
import { HeadTitle } from "@@/shared/head-title";
import { sortBy, translation } from "@towni/common";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTicketsForSearch } from "./queries/use-ticket-search";
import { TicketsForCustomer } from "./tickets-for-customer";
import { useTicketEvents } from "./use-ticket-socket";

const TicketsForCustomerPage = () => {
    const { ticketRef } = useParams<{ ticketRef: string }>();
    const [tickets, ticketQuery] = useTicketsForSearch(ticketRef);

    const ticketsSorted = useMemo(() => {
        return [...tickets].sort(sortBy(ticket => ticket._id));
    }, [tickets]);

    const providerId = ticketsSorted[0]?.providerId;
    const [provider, isLoadingProvider] = useProviderLoader(providerId);

    useTicketEvents({
        ticketIds: ticketsSorted.map(t => t._id),
        onTicketEvent: _event => {
            ticketQuery.refetch();
            if (navigator.vibrate) {
                navigator.vibrate([200, 200, 200]);
            }
        },
    });

    if (isLoadingProvider || ticketQuery.isPending)
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar biljetter...",
                    en: "Loading tickets...",
                })}
            />
        );

    return (
        <>
            <HeadTitle
                title={[
                    translation({
                        sv: "Biljetter",
                        en: "Tickets",
                    }),
                ]}
            />
            <AppPage pageId={appPageIdFactory(`ticket-${ticketRef}`)}>
                <BrandingProvider providerId={provider?._id}>
                    <TicketsForCustomer tickets={ticketsSorted} />
                </BrandingProvider>
            </AppPage>
        </>
    );
};

export { TicketsForCustomerPage };
