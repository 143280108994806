import { SectionArea } from "@@/shared/section-area";
import { FC } from "react";

const DaftoFunSeaFourSection: FC = () => {
    // const getProductId = useProductIdWithFallback();
    // const _colors = useDaftoColorContext();

    // const _isDesktop = usePageBreakpoint({ when: "🖥" });

    // const supId = React.useMemo(
    //     () => getProductId("product__NwfcsbZ42lt3KzcOMrBI" as ProductId),
    //     []
    // );
    // const cannoeId = React.useMemo(
    //     () => getProductId("product__OZvXZNYmL7RInnsmTbLz" as ProductId),
    //     []
    // );
    // const cannoe2Id = React.useMemo(
    //     () => getProductId("product__1bCS2Tv321bSD3jeTNmy" as ProductId),
    //     []
    // );
    // const trampBoatId = React.useMemo(
    //     () => getProductId("product__01pRs07HU3YVOPuglFOQ" as ProductId),
    //     []
    // );
    // const productIds = [supId, cannoeId, cannoe2Id, trampBoatId];

    return (
        <SectionArea
            title={"Kul på havet"}
            subtitle={
                "Ta dig ut på havet! Här finns det flera alternativ för en kul stund tillsammans på Kosterhavet. Hyr SUP, kanot, Kajak och trampbåt här."
            }
            sectionAreaId="dafto-sea-4-section"
            //crossAxis="center"
            userSelect="text"
            padding={{ top: 0 }}>
            {/* <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id}
                            productId={id}
                            contentTop={index === 3 ? true : index % 2 === 0}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                        />
                    );
                })}
            </SimpleGrid> */}
        </SectionArea>
    );
};

// const text = {
//     book: translation({
//         sv: "Boka nu",
//         en: "Book",
//     }),
//     explore: translation({
//         sv: "Utforska",
//         en: "Book",
//     }),
//     seeTime: translation({
//         sv: "Se tider",
//         en: "Book",
//     }),
// };

// const actionTexts = [text.book, text.explore, text.seeTime];

export { DaftoFunSeaFourSection };
