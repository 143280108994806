import { css, SerializedStyles } from "@emotion/react";
import type { Padding as TPadding } from "@towni/common";
import * as React from "react";

type PaddingProps = TPadding & {
    children?: React.ReactNode;
    width?: string;
    height?: string;
    className?: string;
    styles?: SerializedStyles;
    onClick?: () => void;
};
const firstNumber = (values: (number | undefined)[]): number => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = values.find(val => !isNaN(val as any));
    return result || 0;
};

const usePaddingToCss = (
    padding: TPadding | undefined,
    fallback?: TPadding
) => {
    return paddingToCssValue(padding ?? fallback);
};

const paddingToCssValue = (props?: TPadding): string | undefined => {
    if (!props) return undefined;
    const top = firstNumber([props.top, props.topBottom, props.all]);
    const bottom = firstNumber([props.bottom, props.topBottom, props.all]);
    const left = firstNumber([props.left, props.leftRight, props.all]);
    const right = firstNumber([props.right, props.leftRight, props.all]);
    const unit = "px";

    const padding = `${top}${top ? unit : ""} ${right}${
        right ? unit : ""
    } ${bottom}${bottom ? unit : ""} ${left}${left ? unit : ""}`;
    return padding;
};

const Padding = (props: PaddingProps) => {
    const paddingCss = paddingToCssValue(props);

    const style = css({
        margin: 0,
        padding: paddingCss,
        width: props.fillParentWidth ? "100%" : props.width,
        height: props.height,
    });

    return (
        <div
            css={[style, props.styles]}
            className={props.className}
            onClick={props.onClick}>
            {props.children}
        </div>
    );
};

export type { PaddingProps };
export { Padding, paddingToCssValue, usePaddingToCss };
