import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { ForEach } from "@@/shared/for-each";
import { useFormComponents } from "@@/shared/form/form-components";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { useTheme } from "@emotion/react";
import {
    Translatable,
    WeekdaysOnOff,
    translation,
    weekdaysMeta,
    weekdaysOnDefault,
} from "@towni/common";
import { Draft } from "immer";
import "react-day-picker/dist/style.css";
import { ZodSchema, z } from "zod";

type Value = WeekdaysOnOff | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    /** When value for day has not been set, default to this, if not set it will default to `true` */
    readonly defaultsTo?: boolean;
    readonly fieldSchema?: ZodSchema;

    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
};

const Form2WeekdayPicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const FormComponents = useFormComponents<State>();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    const defaultsTo = props.defaultsTo ?? true;

    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    // const [_initialValue] = useState(field.value);
    const hasErrors = field.errors.length > 0;
    // const [pickerIsOpen, setPickerIsOpen] = useState(false);
    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
    });

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <FieldTitle
                text={
                    props.label ??
                    translation({
                        sv: "Veckodagar",
                        en: "Weekdays",
                    })
                }
                description={props.labelDescription}
            />
            <VerticalDivider XS />
            <FlexRow
                mainAxis="space-between"
                css={{
                    flex: 1,
                    padding: 20,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.textInput.border.asString}`,
                    backgroundColor: theme.colors.textInput.background.asString,
                }}>
                <ForEach
                    itemOf={weekdaysMeta}
                    getKey={wd => wd.key}
                    divider={<HorizontalDivider />}>
                    {meta => (
                        <FlexColumn crossAxis="center">
                            <TextBox text={meta.name.short} case="CAPITALIZE" />
                            <VerticalDivider XS />
                            <FormComponents.Checkbox
                                fieldId={`${props.fieldId}_${meta.key}`}
                                fieldSchema={z.boolean()}
                                getter={state => {
                                    const value = props.getter(state);
                                    return value?.[meta.key] ?? defaultsTo;
                                }}
                                setter={(state, newValue) => {
                                    const currentValue = props.getter(
                                        state as State,
                                    );
                                    props.setter(state, {
                                        ...weekdaysOnDefault,
                                        ...(currentValue ?? {}),
                                        [meta.key]: !!newValue,
                                    });
                                    return;
                                }}
                            />
                        </FlexColumn>
                    )}
                </ForEach>
            </FlexRow>
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2WeekdayPicker };
