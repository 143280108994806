import { OptionalGroup } from "@towni/common";
import {
    useFetch,
    useFetchMultiple,
    useFetchMultipleForMultiple,
    useFetchAll,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";

const mainQueryKey = "optional-groups";
const mainRoute = "optional-groups";

const useOptionalGroup = (id?: string) =>
    useFetch<OptionalGroup>(mainQueryKey, mainRoute, id);
const useAllOptionalGroups = () =>
    useFetchAll<OptionalGroup>(mainQueryKey, mainRoute);
const useOptionalGroups = (ids?: string[]) =>
    useFetchMultiple<OptionalGroup>(mainQueryKey, mainRoute, ids);
const useOptionalGroupsForProvider = (providerId?: string) =>
    useFetchMultipleForSingle<OptionalGroup>({
        mainQueryKey,
        mainRoute,
        fetchFor: "provider",
        id: providerId,
    });
const useOptionalGroupsForProviders = (providerIds?: string[]) =>
    useFetchMultipleForMultiple<OptionalGroup>(
        mainQueryKey,
        mainRoute,
        "provider",
        providerIds
    );

export {
    useOptionalGroup,
    useOptionalGroups,
    useAllOptionalGroups,
    useOptionalGroupsForProvider,
    useOptionalGroupsForProviders,
};
