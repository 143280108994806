import { css, useTheme } from "@emotion/react";
import { Padding, Optional as TOption } from "@towni/common";
import { HorizontalDivider } from "../dividers";
import { FlexRow } from "../flex-containers";
import { SelectBox } from "../select-box";
import { PriceTextBox, TextBox } from "../text";

type Props = {
    option: TOption;
    selected: boolean;
    toggle: () => void;
    shape: "CIRCLE" | "SQUARE";
    padding?: Padding;
    hidePrice?: boolean;
};
const Optional = (props: Props) => {
    const theme = useTheme();
    const { toggle, selected, option, shape, padding } = props;

    const selectedStyle = css({
        backgroundColor: theme.isLightTheme
            ? theme.colors.primary.light.asString
            : theme.colors.primary.dark.asString,
    });

    const priceTextStyle = css({
        opacity: 0.6,
        fontWeight: 300,
    });

    return (
        <FlexRow
            onClick={toggle}
            padding={padding}
            mainAxis="space-between"
            crossAxis="center"
            styling={selected ? selectedStyle : undefined}>
            <FlexRow crossAxis="center">
                <SelectBox selected={selected} shape={shape} size="L" />
                <HorizontalDivider M />
                <TextBox text={option.meta.title} shrink={1} />
            </FlexRow>
            {(!props.hidePrice && option.price?.amountIncludingVat && (
                <FlexRow mainAxis="flex-end">
                    <TextBox
                        text="+"
                        shrink={1}
                        color={theme.colors.default.text}
                        padding={{ leftRight: 5 }}
                        styling={priceTextStyle}
                    />
                    <PriceTextBox
                        price={option.price}
                        fractions="NONE"
                        color={theme.colors.default.text}
                        styling={priceTextStyle}
                    />
                </FlexRow>
            )) ||
                null}
        </FlexRow>
    );
};

export { Optional };
