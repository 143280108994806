import { apiFetchClient } from "@@/shared/fetch-client";
import { useApiResources } from "@@/shared/state/use-api-resources";
import { GetResponse, OrderId, TicketGiftCard } from "@towni/common";
export const giftCardsMainQueryKey = "giftcards";

const fetchForOrder = (order: OrderId | undefined) => async () => {
    if (!order) throw new Error("missing orderId id");
    const data = await apiFetchClient.get<GetResponse<TicketGiftCard>>({
        route: `/tickets/giftcards/for-order/${encodeURIComponent(order)}`,
    });
    return data.items;
};

const useGiftCardsForOrder = (orderId: OrderId | undefined) =>
    useApiResources<TicketGiftCard>(
        [giftCardsMainQueryKey, orderId],
        fetchForOrder(orderId),
        {
            enabled: !!orderId,
        }
    );

export { useGiftCardsForOrder };

