import { useCart } from "@@/carts/multi-carts/cart.context";
import {
    ExtrasGroup,
    checkExtrasConditions,
    isProductOrderItem,
    sum,
} from "@towni/common";
import { useMemo } from "react";
import { getMinMaxForExtrasGroup } from "./extras-helper";

const useExtraProps = (extras: ExtrasGroup[], onPremise: boolean) => {
    const cart = useCart();

    const extraProps = useMemo(() => {
        return extras.flatMap(extra => {
            const conditionsMet = checkExtrasConditions(extra, { onPremise });
            if (!conditionsMet) return [];

            const minMax = getMinMaxForExtrasGroup(cart, extra);

            const items = cart.orderItems.filter(
                oi =>
                    oi.extraGroup?._id === extra._id && isProductOrderItem(oi),
            );
            const pickedAmount = sum(items, oi => oi.quantity.value);

            const disabled =
                pickedAmount >= (minMax.max ?? Number.MAX_SAFE_INTEGER);

            return {
                extras: extra,
                parentOrderItemId: undefined,
                minMax,
                pickedAmount,
                disabled,
            };
        });
    }, [cart, extras, onPremise]);

    return extraProps;
};

export { checkExtrasConditions, useExtraProps };
