import { CheckmarkCircleSvg } from "@@/orders/order-groups/checkmark-circle-svg";
import { AccountIcon } from "@@/shared/account-icon";
import { BackButton } from "@@/shared/buttons/back-button";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { apiFetchClient } from "@@/shared/fetch-client";
import { FlexColumn } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useQueryParameter } from "@@/shared/use-query-or-param";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import {
    GetSingleResponse,
    MINUTES,
    OrderGroupId,
    SwishPayment,
    UUID,
    translation,
} from "@towni/common";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CheckSwishPayment = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const translate = useTranslate();
    const navigate = useNavigate();
    //Loading
    const params = useParams<{
        orderGroupId: OrderGroupId;
        paymentUUID: UUID;
    }>();

    const backuri = useQueryParameter("back");

    const queryResult = useQuery<GetSingleResponse<SwishPayment>, Error>({
        queryKey: ["payments", params.paymentUUID],
        retry: 3,
        staleTime: 0,
        gcTime: 5 * MINUTES,
        enabled: !!params.paymentUUID, //isInitiated && !isFinished,
        refetchOnWindowFocus: true,
        refetchInterval: 2000,
        queryFn: ({ signal }) =>
            apiFetchClient.get<GetSingleResponse<SwishPayment>>({
                route: `/payments/swish/${params.paymentUUID}`,
                customConfig: {
                    signal,
                },
            }),
    });

    const payment = queryResult.data?.item;
    useEffect(() => {
        if (payment?.status === "PAID" && params.orderGroupId) {
            navigate("/receipts/" + encodeURIComponent(params.orderGroupId));
        }
    }, [payment]);

    return (
        <>
            <HeadTitle
                title={[
                    payment?.status === "PENDING" ? "Kollar betalstatus" : "",
                ]}
            />

            <WideScreenWrapper>
                <FlexColumn
                    crossAxis="center"
                    fillParent
                    overflowY="scroll"
                    css={{
                        background: `linear-gradient(333.07deg, rgba(17, 20, 37, 0.5) 31.71%, rgba(17, 20, 37, 0) 97.4%), gray}`,
                    }}>
                    <div
                        css={{
                            width: "100%",
                            padding: 20,
                            label: "back_button_area",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: theme.colors.white.asString,
                            borderBottom: "1px solid #e5e5e5",
                            zIndex: 9999,
                            position: "sticky",
                            top: 0,
                            alignSelf: "start",
                            "@media print": {
                                display: "none",
                            },
                            ...(!isDesktop
                                ? {
                                      borderBottom: "none",
                                      gridColumn: 1,
                                      position: "sticky",
                                      top: 0,
                                      alignSelf: "start",
                                      backgroundColor:
                                          theme.colors.transparent.asString,
                                  }
                                : {}),
                        }}>
                        <BackButton
                            size={30}
                            path={backuri.length ? decodeURI(backuri) : `/`}
                        />

                        <Conditional when={isDesktop}>
                            <HorizontalDivider />
                            <AccountIcon
                                disableShadow
                                color={theme.colors.default.text.withAlpha(0.6)}
                            />
                        </Conditional>
                    </div>
                    <VerticalDivider
                        size={isDesktop ? "XXL" : "XXS"}
                        css={{
                            "@media print": {
                                display: "none",
                            },
                        }}
                    />
                    <Conditional when={queryResult.isLoading}>
                        <CheckmarkCircleSvg
                            size={"4rem"}
                            circleColor={theme.colors.white.value.withAlpha(
                                0.7,
                            )}
                            checkmarkColor={theme.colors.white}
                        />
                        <VerticalDivider M />
                        <TextBox
                            text={`${translate(
                                translation({
                                    sv: "Väntar på bekräftelse från betalningsleverantör",
                                    en: "Loading",
                                }),
                            )}`}
                            css={{
                                color: theme.colors.primary.light.asString,
                                "@media print": {
                                    color: theme.colors.black.asString,
                                },
                            }}
                            weight="700"
                            spin={queryResult.isLoading}
                            size={1.125}
                            padding={{ bottom: 3 }}
                        />
                        <VerticalDivider XXS />
                        <TextBox
                            size={0.9}
                            text={translation({
                                sv: "",
                                en: "",
                            })}
                            css={{
                                opacity: 0.8,
                                color: theme.colors.primary.light.asString,
                                "@media print": {
                                    color: theme.colors.black.asString,
                                },
                            }}
                        />
                    </Conditional>
                    <Conditional when={payment?.status === "DECLINED"}>
                        <Icon
                            icon={faBan}
                            size={"3XL"}
                            color={theme.colors.white}
                        />

                        <VerticalDivider M />
                        <TextBox
                            text={`${translate(
                                translation({
                                    sv: "Betalflödet avbrutet",
                                    en: "Payment has been aborted",
                                }),
                            )}`}
                            css={{
                                color: theme.colors.primary.light.asString,
                            }}
                            weight="700"
                            spin={queryResult.isLoading}
                            size={1.125}
                            padding={{ bottom: 3 }}
                        />
                        <VerticalDivider XXS />
                        <TextBox
                            size={0.9}
                            text={translation({
                                sv:
                                    payment?.status === "DECLINED"
                                        ? payment.reason
                                        : "",
                                en: "",
                            })}
                            css={{
                                opacity: 0.8,
                                color: theme.colors.primary.light.asString,
                            }}
                        />
                    </Conditional>

                    <VerticalDivider XL />

                    <VerticalDivider XXL />
                </FlexColumn>
            </WideScreenWrapper>
        </>
    );
};

export { CheckSwishPayment };
