import {
    useFetch,
    useFetchMultipleForMultiple,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";
import { Shipping } from "@towni/common";

const mainQueryKey = "shipping";
const mainRoute = "shipping";

const useShippingForZip = (zip?: string) => {
    const result = useFetchMultipleForSingle<Shipping>({
        mainQueryKey,
        mainRoute,
        fetchFor: "zip",
        id: zip,
    });
    return result;
};

const useShipping = (id?: string) =>
    useFetch<Shipping>(mainQueryKey, mainRoute, id);

const useCustomerShippingForZones = (
    zones?: string[],
    providerId?: string[]
) => {
    const result = useFetchMultipleForMultiple<Shipping>(
        mainQueryKey,
        mainRoute,
        "zones",
        zones,
        {
            providerId: providerId ?? [],
            tags: ["CUSTOMER_SHIPPING_TAG"],
        },
        undefined,
        { staleTime: 0 }
    );
    return result;
};

const useProviderShippingForZones = (
    zones?: string[],
    providerId?: string[]
) => {
    const result = useFetchMultipleForMultiple<Shipping>(
        mainQueryKey,
        mainRoute,
        "zones",
        zones,
        {
            providerId: providerId ?? [],
            tags: ["PROVIDER_SHIPPING_TAG"],
        },
        undefined,
        { staleTime: 0 }
    );
    return result;
};

const useShippingsForArea = (area?: string) => {
    const result = useFetchMultipleForSingle<Shipping>({
        mainQueryKey,
        mainRoute,
        fetchFor: "area",
        id: area,
    });
    return result;
};

export {
    useShippingForZip,
    useShippingsForArea,
    useCustomerShippingForZones,
    useShipping,
    useProviderShippingForZones,
};
