import { ErrorPage } from "@@/backoffice/shared/error-page";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { isApiError, translation } from "@towni/common";
import * as React from "react";
import { FlexColumn } from "./flex-containers";

type WithQueryOptions<T> = {
    whenError?: JSX.Element;
    whenLoading?: JSX.Element;
    whenNotFound?: JSX.Element;
    queryConfig?: UseQueryOptions<T>;
};
type WithQueryProps<T> = {
    queryKey: QueryKey;
    fetcher: QueryFunction<T>;
    options?: WithQueryOptions<T>;
    children: (data: T, query: UseQueryResult<T>) => React.ReactNode;
    single?: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const WithQuery = <T extends unknown>(props: WithQueryProps<T>) => {
    const queryResult = useQuery<T>({
        queryKey: props.queryKey,
        queryFn: props.fetcher,
        staleTime: 0,
        ...(props.options?.queryConfig ?? {}),
    });

    if (!props.fetcher || !props.queryKey || queryResult.error) {
        if (props.options?.whenError) return props.options.whenError;
        if (isApiError(queryResult.error)) {
            if (queryResult.error.statusCode === 404) {
                if (props.options?.whenNotFound)
                    return props.options.whenNotFound;
                return (
                    <PageNotFound
                        reason={translation({
                            sv: "Kunde inte hitta det du letade efter",
                            en: "Could not find what you were looking for",
                        })}
                    />
                );
            }
            if (queryResult.error.statusCode > 500)
                if (props.options?.whenError) return props.options.whenError;
            return <ErrorPage error={queryResult.error} />;
        }

        if (!queryResult.error) {
            throw new Error("Missing fetcher or query key");
        }

        throw queryResult.error;
    }
    if (queryResult.isPending || queryResult.isLoading) {
        if (props.options?.whenLoading) return props.options?.whenLoading;
        return (
            <FlexColumn fillParent crossAxis="center" mainAxis="center">
                <CircleSpinner size="3XL" />
            </FlexColumn>
        );
    }

    if (!queryResult.data) {
        if (props.options?.whenNotFound) return props.options?.whenNotFound;
        return (
            <PageNotFound
                reason={translation({
                    sv: "Kunde inte hitta det du letade efter",
                    en: "Could not find what you were looking for",
                })}
            />
        );
    }

    const output: T =
        props.single && Array.isArray(queryResult.data)
            ? queryResult.data[0]
            : queryResult.data;
    return <>{props.children(output, queryResult)}</>;
};

export { WithQuery };
export type { WithQueryOptions, WithQueryProps };
