import { colorFactory, LinearGradient, transparentColor } from "@towni/common";

/**
 * Darker at the bottom, transparent from 0% to 50%
 *  @type {*} */
const darkeningGradient: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.4,
            }),
        },
        {
            percentage: 0.5,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
/**
 * Darker at the top, transparent from 50% to 100%
 *  @type {*} */
const darkeningGradientReverse: LinearGradient = {
    ...darkeningGradient,
    degrees: 180,
};

const darkeningGradientDual: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.5,
            }),
        },
        {
            percentage: 0.5,
            color: transparentColor,
        },
        {
            percentage: 0.7,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.5,
            }),
        },
    ],
    degrees: 0,
};

const darkeningGradientDualHeavierTopSide_80: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.8,
            }),
        },
        {
            percentage: 0.25,
            color: transparentColor,
        },
        {
            percentage: 0.6,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.8,
            }),
        },
    ],
    degrees: 0,
};
const darkeningGradientDualHeavierTopSide_60: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.6,
            }),
        },
        {
            percentage: 0.25,
            color: transparentColor,
        },
        {
            percentage: 0.6,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.6,
            }),
        },
    ],
    degrees: 0,
};
const darkeningGradientDualHeavierTopSide_40: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.4,
            }),
        },
        {
            percentage: 0.25,
            color: transparentColor,
        },
        {
            percentage: 0.6,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: colorFactory({
                hue: 0,
                light: 0,
                saturation: 0,
                alpha: 0.4,
            }),
        },
    ],
    degrees: 0,
};

export {
    darkeningGradient,
    darkeningGradientDual,
    darkeningGradientDualHeavierTopSide_40,
    darkeningGradientDualHeavierTopSide_60,
    darkeningGradientDualHeavierTopSide_80,
    darkeningGradientReverse,
};
