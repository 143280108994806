import { apiFetchClient } from "@@/shared/fetch-client";
import { useApiResources } from "@@/shared/state/use-api-resources";
import {
    GetResponse,
    isImageContentType,
    StorageItem,
    StorageItemId,
    StorageItemImage,
    StorageItemImageId,
} from "@towni/common";
import { storageItemMainQueryKey } from "../main-query-key";

const fetchStorageItems =
    <StorageItemType extends StorageItem = StorageItem>(
        storageItemIds: StorageItemId[],
        contentTypeVerifier?: (contentType: string) => boolean,
    ) =>
    async () => {
        if (storageItemIds.length === 0)
            throw new Error("missing storage item id");
        const data = await apiFetchClient.get<GetResponse<StorageItemType>>({
            route: `/storage-items/for-list/${encodeURIComponent(
                storageItemIds.join(","),
            )}`,
        });
        if (contentTypeVerifier) {
            const isNot = data.items.find(
                i => !contentTypeVerifier(i.contentType),
            );
            if (isNot) {
                throw new Error(
                    "Not the requested contentType: " + isNot.contentType,
                );
            }
        }
        return data.items;
    };

const useStorageItems = <StorageItemType extends StorageItem = StorageItem>(
    storageItemIds: StorageItemImageId[],
    contentTypeVerifier?: (contentType: string) => boolean,
) => {
    // console.log("USE STORAGE ITEM", storageItemId);
    const result = useApiResources<StorageItemType>(
        [storageItemMainQueryKey, "list", ...storageItemIds],
        fetchStorageItems(storageItemIds, contentTypeVerifier),
        {
            enabled: storageItemIds.length > 0,
        },
    );
    // console.log("USE STORAGE ITEM RES", result);
    return result;
};

const useImageStorageItems = (storageItemIds: StorageItemImageId[]) =>
    useStorageItems<StorageItemImage>(storageItemIds, isImageContentType);

export { useImageStorageItems, useStorageItems };
