import { DeliveryZone } from "@towni/common";
import { useFetchMultipleForSingle } from "@@/shared/generic-fetchers";

const mainQueryKey = "delivery-zones";
const mainRoute = "delivery-zones";

const useZonesForArea = (area?: string) => {
    const result = useFetchMultipleForSingle<DeliveryZone>({
        mainQueryKey,
        mainRoute,
        fetchFor: "area",
        id: area,
    });
    return result;
};
const useZonesForZip = (zip?: string) => {
    const result = useFetchMultipleForSingle<DeliveryZone>({
        mainQueryKey,
        mainRoute,
        fetchFor: "zip",
        id: zip,
    });
    return result;
};

export { useZonesForArea, useZonesForZip };
