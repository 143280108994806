import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { AvailabilityVisualization } from "@@/backoffice/shared/availability-visualization";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { useCalculatedProviderStatus } from "@@/providers/calculate-provider-status";
import { CartProviderCheckModal } from "@@/providers/cart-provider-check-modal";
import { ProviderPauseCheckModal } from "@@/providers/provider-pause-check-modal";
import { browserLogger } from "@@/settings";
import { AddToCartWithQuantity } from "@@/shared/buttons/add-to-cart-with-quantity-picker/add-to-cart-with-quantity";
import { FadedBottomContainer } from "@@/shared/buttons/faded-bottom-container";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { isRunningAsPwa } from "@@/shared/is-running-as-pwa";
import { Label } from "@@/shared/label";
import { OptionalAmount } from "@@/shared/options/optional-amount-group";
import { Picture } from "@@/shared/pictures/picture";
import { ImageShimmer, TextShimmer } from "@@/shared/pictures/shimmers";
import { SlideShow } from "@@/shared/pictures/slide-show";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { LinkTo } from "@@/shared/text/link-to";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { useRadioList } from "@@/shared/use-radio-list";
import { ProviderLogoLinkCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { linkCss } from "@@/styles/themes/default.theme";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    faClipboardCheck,
    faGift,
    faHeart,
} from "@fortawesome/pro-solid-svg-icons";
import {
    MILLISECONDS,
    PickedOptional,
    Product,
    Provider,
    RemSize,
    SECONDS,
    Sku,
    OptionalAmount as TOptionalAmount,
    OptionalGroup as TOptionalGroup,
    generateId,
    isAlfredItemActive,
    isNotUndefined,
    isUndefined,
    isVoucher,
    providerStatusTypeMap,
    sumPrices,
    translation,
    zeroPrice,
} from "@towni/common";
import * as React from "react";
import { useState } from "react";
import { OptionalGroup } from "../../shared/options";
import { AddMessageRow } from "../add-message-row";
import { ContentBlockView } from "../bookables/content-block-view";
import { DetailsPickerHeader } from "../pick-sku-header";
import { PickSkuList } from "../pick-sku-list";
import { useProductDetailsContext } from "../product-details-context";
import { _mapPickedOptionalsByOptionalGroupId } from "./_map-picked-optionals-by-optional-group-id";

type Props = {
    readonly product: Product | undefined;
    readonly provider: Provider | undefined;
    readonly hideLinkToShop: boolean;

    readonly optionalGroups: Array<TOptionalGroup>;
};

const VoucherDetails: React.FC<Props> = props => {
    const { provider, product, optionalGroups } = props;
    const theme = useTheme();
    const translate = useTranslate();
    const wizardActions = useWizardDispatch();
    const state = useProductDetailsContext();
    const [discountsForProduct, _discountsIsLoading] =
        useDiscountsForProduct(product);
    const providerStatus = useCalculatedProviderStatus(provider);

    const pickedOptionsMap = React.useMemo(
        _mapPickedOptionalsByOptionalGroupId(state.pickedOptionals ?? []),
        [state.pickedOptionals],
    );

    browserLogger.info(state.pickedOptionals);

    const [messageToProvider, setMessageToProvider] = useState<string>(
        state.messageToProvider ?? "",
    );

    // if only one sku, select it by default
    const initiallySelectedSkuId =
        product?.skus?.length === 1 ? product?.skus[0]._id : undefined;

    const [selectedSkuId, selectSku] = useRadioList(
        product?.skus?.map(sku => ({
            _id: sku._id,
            selected: sku._id === initiallySelectedSkuId,
        })) || [],
    );

    React.useEffect(() => {
        // Select initial sku
        if (!selectedSkuId && !!initiallySelectedSkuId) {
            selectSku(initiallySelectedSkuId);
        }
    }, [initiallySelectedSkuId, selectSku, selectedSkuId]);

    const selectedSku: Sku | undefined = product?.skus?.find(
        sku => sku._id === selectedSkuId,
    );
    const disabled =
        providerStatus === providerStatusTypeMap.PAUSED ||
        (product?.status && !isAlfredItemActive(product?.status));

    const isHidden = providerStatus === providerStatusTypeMap.HIDDEN;

    const slideContent = product?.meta.images?.map(imageRef => {
        return (
            <Picture
                key={typeof imageRef === "string" ? imageRef : imageRef._id}
                imageRef={imageRef}
                width="100%"
                height="100%"
                minHeight="250px"
                imageOptions={{
                    imageWidth: theme.sizes.imageSizes.large,
                    disabled,
                    fit: true,
                }}
                fade={0.1 as SECONDS}
                loadingSpinner={
                    <CircleSpinner size={5} delayInMs={300 as MILLISECONDS} />
                }
            />
        );
    });

    React.useEffect(
        function checkIfAddToCartButtonShouldBeDisabled() {
            const addToCartDisabled =
                disabled ||
                !state.selectedSku ||
                // where there is an option group...
                !!optionalGroups
                    // with a required minimal number of options...
                    .some(optionalGroupOrOptionalAmount => {
                        if (!optionalGroupOrOptionalAmount.minMax.min)
                            return false; // undefined or 0
                        const picked = pickedOptionsMap.get(
                            optionalGroupOrOptionalAmount._id,
                        );
                        if (!picked) return true;

                        const notEnough =
                            picked.length <
                            optionalGroupOrOptionalAmount.minMax.min;
                        return notEnough;
                    });

            if (addToCartDisabled !== state.addToCartDisabled) {
                // Set button as disabled
                state.setAddToCartDisabled(addToCartDisabled);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            state.selectedSku,
            state.addToCartDisabled,
            state.setAddToCartDisabled,
            optionalGroups,
            pickedOptionsMap,
            disabled,
        ],
    );

    const contentBlocks =
        product?.skus[0].acquire._type === "VOUCHER" ||
        product?.skus[0].acquire._type === "GIFTCARD"
            ? product?.skus[0].acquire.contentBlocks
            : [];

    const onChange =
        (optionalGroup: TOptionalGroup | TOptionalAmount) =>
        (pickedOptionals: PickedOptional[]) => {
            const optionalsToKeep =
                state.pickedOptionals?.filter(
                    picked =>
                        picked.optional.optionalGroupId !== optionalGroup._id,
                ) ?? [];
            const update = [...optionalsToKeep, ...pickedOptionals];
            state.setPickedOptionals(update);
        };

    const cartItem = state.getOrderItem();

    const currentPrice = props.product?.price
        ? sumPrices([
              // props.product.price,
              ...(cartItem ? cartItem.map(p => p.price) : [zeroPrice]),
          ])
        : undefined;

    /** Sorted optionals, required optionals first */
    const sortedOptionals = React.useMemo(
        () =>
            Array.from(optionalGroups?.values() || []).reduce(
                (agg, next) => {
                    // Sort required first
                    next.minMax.min ?? 0 > 0
                        ? agg.unshift(next)
                        : agg.push(next);
                    return agg;
                },
                [] as (TOptionalGroup | TOptionalAmount)[],
            ),
        [optionalGroups],
    );

    const providerHomePath = useHomePath(provider);

    return (
        <>
            <FlexColumn
                grow={1}
                shrink={0}
                fillParent
                mainAxis="space-between"
                crossAxis="stretch">
                <FlexColumn grow={1} shrink={0}>
                    {slideContent && slideContent.length > 1 && (
                        // todo: vet inte hur height 110vw funkar med slideshow
                        // todo: stod 40% tidigare
                        <SlideShow
                            styling={css`
                                width: 100%;
                                height: 110vw;
                                min-height: 250px;
                                max-height: 500px;
                                flex-shrink: 0;
                            `}
                            slideContents={slideContent}
                        />
                    )}
                    {slideContent && slideContent.length === 1 && (
                        <div
                            css={{
                                width: "100%",
                                height: "110vw",
                                minHeight: 250,
                                maxHeight: 500,
                                flexShrink: 0,
                            }}>
                            {slideContent[0]}
                            <div
                                css={{
                                    position: "absolute",
                                    marginTop: -90,
                                    left: 20,
                                    zIndex: 2,
                                }}>
                                <ProviderLogoLinkCircle
                                    provider={props.provider}
                                    hideTitle
                                    size="3XS"
                                    disableLink
                                />
                            </div>
                        </div>
                    )}
                    {(isUndefined(slideContent) && (
                        <ImageShimmer height="110vw" maxHeight="500px" />
                    )) ||
                        null}
                    {slideContent && slideContent.length <= 0 && (
                        <VerticalDivider size={3.5} />
                    )}
                    <VerticalDivider M />
                    {(isUndefined(product) && (
                        <TextShimmer rowHeight="22px" rows={1} />
                    )) ||
                        null}
                    <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
                        {product?.meta.kicker && isVoucher(product) ? (
                            <>
                                <VerticalDivider M />
                                <TextBox
                                    case={"UPPERCASE"}
                                    color={theme.colors.primary}
                                    text={product.meta.kicker}
                                    size={"XXS"}
                                    weight={"900"}
                                />
                            </>
                        ) : null}
                        <FlexRow mainAxis="space-between">
                            <TextBox
                                size={2}
                                shrink={1}
                                weight="900"
                                text={product?.meta.title}
                                userSelect="text"
                            />
                            {disabled ? (
                                <Label
                                    text={translation({
                                        sv: "Ej tillgänglig",
                                        en: "Not available",
                                    })}
                                    colorTheme="YELLOW"
                                    size="L"
                                />
                            ) : isHidden ? (
                                <Label
                                    text={translation({
                                        sv: "Gömd",
                                        en: "Hidden",
                                    })}
                                    colorTheme="RED"
                                    size="L"
                                />
                            ) : null}
                        </FlexRow>
                        <Conditional
                            when={!product?.meta.subtitle}
                            render={() => <VerticalDivider M />}
                        />
                    </FlexColumn>
                    <Conditional
                        when={typeof product === "undefined"}
                        render={() => <TextShimmer rows={2} />}
                    />
                    {product?.meta.subtitle ? (
                        <>
                            <VerticalDivider XXS />
                            <TextBox
                                text={product.meta.subtitle}
                                color={theme.colors.white.light50}
                                size="ML"
                                userSelect="text"
                                padding={{ leftRight: 20 }}
                            />

                            <VerticalDivider M />
                        </>
                    ) : null}
                    {product?.meta.description &&
                    isNaN(
                        Number(translate(product.meta.description).trim()),
                    ) ? (
                        <>
                            <HorizontalLine />
                            <VerticalDivider M />
                            <TextBox
                                text={product.meta.description}
                                color={theme.colors.white.light50}
                                userSelect="text"
                                size={1}
                                padding={{ leftRight: 20 }}
                                renderMarkdown
                                styling={css`
                                    h1,
                                    h2,
                                    h3,
                                    h4 {
                                        color: ${theme.colors.white.light20
                                            .asString};
                                    }
                                `}
                            />
                        </>
                    ) : null}
                    {product?.availability ? (
                        <FlexColumn padding={{ leftRight: 20 }}>
                            <TextBox
                                text={"Tillgänglighet"}
                                color={theme.colors.white.light60}
                                userSelect="text"
                            />
                            <VerticalDivider />
                            <TextBox
                                text={
                                    "Produkten är tillgänglig för leverans följande dagar/tider:"
                                }
                                color={theme.colors.white.light70}
                                userSelect="text"
                                size="S"
                            />
                            <VerticalDivider />
                            <AvailabilityVisualization
                                availability={product.availability}
                            />
                            <VerticalDivider L />
                        </FlexColumn>
                    ) : null}
                    {product?.skus?.[0]?.articleNumber ? (
                        <>
                            <FlexRow padding={{ leftRight: 20 }}>
                                <TextBox
                                    text={"Artikelnr: "}
                                    color={theme.colors.white.light70}
                                    userSelect="text"
                                    size="XS"
                                />
                                <HorizontalDivider XS />
                                <TextBox
                                    text={product.skus[0].articleNumber}
                                    color={theme.colors.white.light70}
                                    userSelect="text"
                                    size="XS"
                                />
                            </FlexRow>
                            <VerticalDivider XS />
                        </>
                    ) : null}
                    <Conditional when={contentBlocks.length > 0}>
                        <VerticalDivider M />
                        <HorizontalLine />
                        <VerticalDivider M />
                        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
                            <VoucherBlock />
                        </FlexColumn>

                        <ForEach
                            itemOf={contentBlocks}
                            beginWith={
                                <>
                                    <VerticalDivider M />
                                    <HorizontalLine />
                                    <VerticalDivider M />
                                </>
                            }
                            divider={
                                <>
                                    <VerticalDivider M />
                                    <HorizontalLine />
                                    <VerticalDivider M />
                                </>
                            }
                            endWith={<VerticalDivider M />}>
                            {(item, index) => {
                                return (
                                    <FlexColumn
                                        key={item._id}
                                        fillParentWidth
                                        padding={{ leftRight: 20 }}>
                                        <ContentBlockView
                                            key={item._id}
                                            contentBlock={item}
                                            index={index}
                                        />
                                    </FlexColumn>
                                );
                            }}
                        </ForEach>
                    </Conditional>

                    {(!disabled && isNotUndefined(product) && (
                        <>
                            <HorizontalSectionDivider />
                            {((product && product.skus.length > 1) ||
                                !selectedSku) && (
                                <React.Fragment>
                                    <VerticalDivider L />
                                    <DetailsPickerHeader
                                        title="Välj variant"
                                        minMax={{ min: 1, max: 1 }}
                                        padding={{ leftRight: 20 }}
                                        hideDescription={
                                            product?.skus.length === 1
                                        }
                                    />
                                    <VerticalDivider L />
                                    <PickSkuList
                                        skus={product?.skus || []}
                                        productTitle={
                                            product?.meta.title || null
                                        }
                                        selectedId={selectedSkuId}
                                        select={selectSku}
                                    />
                                    <HorizontalSectionDivider />
                                </React.Fragment>
                            )}
                            <ForEach
                                itemOf={sortedOptionals}
                                endWith={
                                    <HorizontalSectionDivider
                                        key={generateId({ length: 4 })}
                                    />
                                }
                                divider={
                                    <HorizontalSectionDivider
                                        key={generateId({ length: 4 })}
                                    />
                                }>
                                {optionalGroup => {
                                    switch (optionalGroup._type) {
                                        case "OPTIONAL_AMOUNT": {
                                            return (
                                                <OptionalAmount
                                                    optionalGroup={
                                                        optionalGroup
                                                    }
                                                    onChange={onChange(
                                                        optionalGroup,
                                                    )}
                                                    padding={{
                                                        leftRight: 20,
                                                        top: 20,
                                                    }}
                                                    optionPadding={{
                                                        leftRight: 20,
                                                        topBottom: 15,
                                                    }}
                                                />
                                            );
                                        }
                                        case "OPTIONAL_GROUP":
                                        default:
                                            return (
                                                <OptionalGroup
                                                    key={optionalGroup._id}
                                                    padding={{
                                                        leftRight: 20,
                                                        top: 20,
                                                    }}
                                                    optionPadding={{
                                                        leftRight: 20,
                                                        topBottom: 15,
                                                    }}
                                                    optionalGroup={
                                                        optionalGroup
                                                    }
                                                    onChange={onChange(
                                                        optionalGroup,
                                                    )}
                                                />
                                            );
                                    }
                                }}
                            </ForEach>
                            <AddMessageRow
                                onSetMessage={message => {
                                    setMessageToProvider(message);
                                    state.setMessageToProvider(message);
                                }}
                                initialMessage={messageToProvider}
                            />
                            <HorizontalLine />
                            <Conditional
                                when={provider?.meta.tags.categories.includes(
                                    "edible",
                                )}
                                render={() => (
                                    <>
                                        <TextBox
                                            padding={{ all: 20 }}
                                            text={translation({
                                                sv: "För frågor kring matallergier eller speciella kostbehov, vänligen kontakta restaurangen innan du beställer.",
                                                en: "For questions about allergies or any special food requirements, please contact the restaurant before ordering.",
                                            })}
                                            size="XS"
                                            color={
                                                theme.colors.textInput
                                                    .placeholder
                                            }
                                        />
                                        <HorizontalLine />
                                    </>
                                )}
                            />
                            <FlexRow
                                padding={{ all: 20 }}
                                mainAxis="space-between">
                                <TextBox
                                    text={translation({
                                        sv: "Totalt",
                                        en: "Total",
                                    })}
                                />
                                <PriceWithDiscountsTextBox
                                    price={currentPrice || zeroPrice}
                                    discounts={discountsForProduct}
                                    fractions="NONE"
                                    reversed
                                />
                            </FlexRow>
                            <VerticalDivider XL />
                            <Conditional
                                when={provider && !props.hideLinkToShop}
                                render={() => {
                                    if (!provider)
                                        throw new Error(
                                            "Provider is undefined",
                                        );
                                    return (
                                        <>
                                            <VerticalDivider M />
                                            <FlexRow mainAxis="center">
                                                <LinkTo to={providerHomePath}>
                                                    <TextBox
                                                        text={`Se fler produkter från ${provider.meta.title} `}
                                                        size="XXS"
                                                        weight="400"
                                                        case="UPPERCASE"
                                                        styling={linkCss(
                                                            theme.colors.white
                                                                .light70,
                                                        )}
                                                    />
                                                </LinkTo>
                                            </FlexRow>
                                            <VerticalDivider M />
                                        </>
                                    );
                                }}
                            />
                        </>
                    )) ||
                        null}
                </FlexColumn>
                <Conditional
                    when={isAlfredItemActive(props.product?.status)}
                    render={() => (
                        <FadedBottomContainer position="sticky">
                            <AddToCartWithQuantity
                                afterAddNavigation={() => {
                                    wizardActions.goForward();
                                }}
                                addButtonText={translation({
                                    sv: "Köp",
                                    en: "Buy",
                                })}
                                clearBeforeAdding={true}
                                padding={
                                    isRunningAsPwa
                                        ? {
                                              top: 15,
                                              bottom: 25,
                                              leftRight: 20,
                                          }
                                        : { all: 20 }
                                }
                            />
                        </FadedBottomContainer>
                    )}
                />
            </FlexColumn>
            <CartProviderCheckModal providerId={product?.providerId} />
            <ProviderPauseCheckModal providerId={product?.providerId} />
        </>
    );
};

const VoucherBlock = () => {
    const theme = useTheme();
    return (
        <FlexColumn>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faClipboardCheck}
                        color={theme.colors.primary}
                        size="M"
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={"Levereras direkt"}
                            userSelect="text"
                            size={1}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={
                            "Ni får digital leverans direkt via e-post och sms. Done and done!"
                        }
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                    <VerticalDivider M />
                </FlexColumn>
            </FlexRow>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faGift}
                        color={theme.colors.primary}
                        size="M"
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={"Alltid med - tappas inte bort"}
                            userSelect="text"
                            size={1}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={
                            "Ingen papperslapp som tappas bort. I mobilen och molnet så är det alltid med."
                        }
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                    <VerticalDivider M />
                </FlexColumn>
            </FlexRow>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faHeart}
                        color={theme.colors.primary}
                        size={1 as RemSize}
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={"Enkelt att dela eller ge bort"}
                            userSelect="text"
                            size={1}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={
                            "Ni kan direkt dela värdebeviset och den som tar emot får en fin länk direkt till gåvan. Går också att skriva ut (och slå in med knorr!)"
                        }
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                </FlexColumn>
            </FlexRow>
        </FlexColumn>
    );
};

export { VoucherDetails };
