import { useModal } from "@@/modals/use-modal";
import { useHasAvailableExtrasOrOptionals } from "@@/products/bookables/booking-context";
import { BookingWizardModal } from "@@/products/bookables/booking-wizard/booking-wizard-modal";
import { browserLogger } from "@@/settings";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { Product, Provider, translation } from "@towni/common";
import { useSessionAvailability } from "./use-sessions-available";
import { useStartingPriceQuery } from "./use-starting-price";

type Props = {
    product: Product;
    provider: Provider;
    disabled?: boolean;
};
const _GenericBooking_ModalButtonSection = (props: Props) => {
    const { product, provider } = props;
    const [{ show }, modalId] = useModal("generic_booking_button__");

    const startingPriceQuery = useStartingPriceQuery(product);
    const startingPrice = startingPriceQuery.data?.data;
    const startingPriceIsPending = startingPriceQuery.isPending;

    const [
        hasAvailableSessions,
        ,
        { isLoading: sessionsAvailabilityIsLoading },
    ] = useSessionAvailability(product);

    const hasAvailableExtrasOrOptionals = useHasAvailableExtrasOrOptionals(
        product._id,
    );

    return (
        <>
            <FlexRow
                fillParentWidth
                mainAxis="space-between"
                crossAxis="center">
                <Conditional
                    when={hasAvailableSessions}
                    else={() => <HorizontalDivider />}>
                    <Conditional
                        when={startingPriceIsPending && !startingPrice}
                        render={() => <CircleSpinner />}
                    />
                    <Conditional
                        when={!!startingPrice}
                        render={() => (
                            <>
                                {startingPrice === "NONE" ? (
                                    <TextBox
                                        text={translation({
                                            sv: "Ej tillgänglig",
                                            en: "Unavailable",
                                        })}
                                    />
                                ) : (
                                    <PriceTextBox
                                        price={startingPrice}
                                        size={1.25}
                                        weight="700"
                                    />
                                )}
                                <HorizontalDivider />
                            </>
                        )}
                    />
                </Conditional>

                <ButtonPrimary
                    onClick={show}
                    // onClick={() => {
                    //     browserLogger.info(
                    //         "GenericBooking_ModalButtonSection: onClick",
                    //     );
                    //     show();
                    // }}
                    disabled={
                        props.disabled ||
                        startingPrice === "NONE" ||
                        !hasAvailableSessions
                    }
                    spin={
                        startingPriceIsPending || sessionsAvailabilityIsLoading
                    }
                    css={{ borderRadius: 3000 }}>
                    <TextBox
                        text={
                            !hasAvailableSessions
                                ? translation({
                                      sv: "Inga tider att boka",
                                      en: "Nothing available",
                                  })
                                : hasAvailableExtrasOrOptionals
                                  ? translation({
                                        sv: "Se tider & tillval",
                                        en: "See times & extras",
                                    })
                                  : translation({
                                        sv: "Se tider & boka",
                                        en: "See times & book",
                                    })
                        }
                    />
                </ButtonPrimary>
            </FlexRow>
            <BookingWizardModal
                modalId={modalId}
                provider={provider}
                product={product}
            />
        </>
    );
};

export { _GenericBooking_ModalButtonSection };
