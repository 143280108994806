import { browserLogger } from "@@/settings";
import type { Branded } from "@towni/common";
import { generateId } from "@towni/common";
import * as React from "react";
import { useMatch } from "react-router-dom";
import type { TransitionType } from "../page-type";
import type { NavDirection } from "./nav-direction";
import type { NavTarget } from "./nav-target";
import type { PageAnimationHide } from "./page-animation-wrapper";

type AppPageId = Branded<string, "AppPageId">;
const appPageIdFactory = (id: string) => id as AppPageId;
type State = {
    readonly _id: string;
    readonly _type: "PAGE_NAVIGATION_STATE";
    readonly current: NavTarget | undefined;
    readonly previous: NavTarget | undefined;
    readonly next: NavTarget | undefined;

    readonly direction: NavDirection | undefined;
    readonly transitionType: TransitionType;

    readonly pages: Map<AppPageId, NavTarget>;
    readonly layers: Map<number, AppPageId>;
    readonly order: AppPageId[];
    readonly orderIndex: number;
};

const initialState = ((): State => {
    const initialNavTarget = {
        pageId: window.location.pathname as AppPageId,
        path: window.location.pathname,
        layer: 0,
        lastPosition: undefined,
        orderIndex: 0,
    };
    const pages = new Map<AppPageId, NavTarget>();
    pages.set(initialNavTarget.pageId, initialNavTarget);

    const layers = new Map<number, AppPageId>();
    layers.set(initialNavTarget.layer, initialNavTarget.pageId);
    return {
        _id: generateId({ prefix: "page_navigation_state__" }),
        _type: "PAGE_NAVIGATION_STATE",
        pages,
        current: initialNavTarget,
        previous: undefined,
        next: undefined,
        layers,
        order: [initialNavTarget.pageId],
        orderIndex: initialNavTarget.orderIndex,
        direction: "FORWARD",
        transitionType: "NONE",
    };
})();

type Action =
    | {
          type: "NAVIGATE_TO";
          to: NavTarget;
          direction: NavDirection;
          transitionType: TransitionType;
          layerDepthDifference: number;
      }
    | {
          type: "NAVIGATE_BACK";
          direction: NavDirection;
          transitionType: TransitionType;
          layerDepthDifference: number;
      }
    | {
          type: "NAVIGATE_FORWARD";
          direction: NavDirection;
          transitionType: TransitionType;
          layerDepthDifference: number;
      }
    | {
          type: "NAVIGATE_REPLACE";
          to: string;
      }
    | {
          type: "RECORD_PAGE_POSITION";
          pageId: AppPageId;
          position: DOMRect;
      };

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    // browserLogger.log("HISTORY REDUCE", action.type, { action, before: state });
    switch (action.type) {
        case "NAVIGATE_TO": {
            const pages = new Map(state.pages);
            pages.set(action.to.pageId, action.to);

            const layers = new Map(state.layers);
            layers.set(action.to.layer, action.to.pageId);

            const newState: State = {
                ...state,
                current: action.to,
                previous: state.current,
                next: undefined,

                pages,
                layers,
                order: [...state.order, action.to.pageId],
                orderIndex: state.order.length,
                direction: action.direction,
                transitionType: action.transitionType,
            };

            // // browserLogger.log("HISTORY NAVIGATE_TO", newState);

            return newState;
        }
        case "NAVIGATE_BACK": {
            const previousIndex = state.orderIndex - 1;
            const newState = {
                ...state,
                current: state.previous,
                previous: state.pages.get(state.order[previousIndex]),
                next: state.current,
                direction: action.direction,
                transitionType: action.transitionType,
                orderIndex: previousIndex,
            };

            // // browserLogger.log("HISTORY NAVIGATE_BACK", newState);

            return newState;
        }
        case "NAVIGATE_FORWARD": {
            const currentOrderIndex = state.orderIndex;
            const nextIndex = currentOrderIndex + 1;
            return {
                ...state,
                current: state.next,
                previous: state.previous,
                next:
                    nextIndex + 1 >= state.order.length
                        ? undefined
                        : state.pages.get(state.order[nextIndex]),
                direction: action.direction,
                transitionType: action.transitionType,
                orderIndex: nextIndex,
            };
        }
        case "RECORD_PAGE_POSITION": {
            const pages = new Map(state.pages);
            const page = state.pages.get(action.pageId);
            if (!page) {
                browserLogger.error(
                    "Can't save page position of non existent page",
                    action.pageId,
                );
                return state;
            }
            pages.set(action.pageId, {
                ...page,
                lastPosition: {
                    ...action.position,
                },
            });
            return {
                ...state,
                pages,
            };
        }
        default:
            return state;
    }
};

const PageNavigationStateContext = React.createContext<State | undefined>(
    undefined,
);
const PageNavigationDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const PageNavigationProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <PageNavigationStateContext.Provider value={state}>
            <PageNavigationDispatchContext.Provider value={dispatch}>
                {children}
            </PageNavigationDispatchContext.Provider>
        </PageNavigationStateContext.Provider>
    );
};

const usePageNavigationState = () => {
    const context = React.useContext(PageNavigationStateContext);
    if (context === undefined) {
        throw new Error(
            "usePageNavigationState must be used within a PageNavigationProvider",
        );
    }
    // // browserLogger.log("HISTORY USE PAGE NAV STATE", { state: context });
    return context;
};

const usePageNavigationDispatch = () => {
    const state = usePageNavigationState();
    const dispatch = React.useContext(PageNavigationDispatchContext);
    if (dispatch === undefined) {
        throw new Error(
            "usePageNavigationDispatch must be used within a PageNavigationProvider",
        );
    }

    // console.log("PAGE NAV DISP", { state });
    const navigateForward = (params: {
        direction?: NavDirection;
        transitionType?: TransitionType;
    }) => {
        // browserLogger.log("HISTORY STATE NAV FORW", { state });

        if (!state.next) {
            browserLogger.error(
                "Can't navigate forward, already at the most recent page",
            );
            return;
        }

        const to = state.next;
        const from = state.current;

        const direction = params.direction ?? "FORWARD";
        const transitionType = params.transitionType ?? state.transitionType;
        const layerDepthDifference = (to.layer ?? 0) - (from?.layer ?? 0);

        dispatch({
            type: "NAVIGATE_FORWARD",
            direction,
            transitionType,
            layerDepthDifference,
        });
    };
    const navigateBack = React.useCallback(
        (params: {
            direction?: NavDirection;
            transitionType?: TransitionType;
        }) => {
            // browserLogger.log("HISTORY STATE NAV BACK", { state });

            if (!state.previous) {
                browserLogger.error(
                    "Can't navigate back, already at the beginning",
                    state,
                );
                return;
            }

            const to = state.previous;
            const from = state.current;

            const direction = params.direction ?? "BACKWARD";
            const transitionType =
                params.transitionType ?? state.transitionType;
            const layerDepthDifference = (to.layer ?? 0) - (from?.layer ?? 0);

            // // browserLogger.log("HISTORY BACK DISPATCH", {
            //     type: "NAVIGATE_BACK",
            //     direction,
            //     transitionType,
            //     layerDepthDifference,
            // });

            dispatch({
                type: "NAVIGATE_BACK",
                direction,
                transitionType,
                layerDepthDifference,
            });
        },
        [dispatch, state],
    );

    const navigateTo = (params: {
        path: string;
        pageId: AppPageId;
        direction?: NavDirection;
        layer?: number;
        transitionType?: TransitionType;
    }) => {
        // browserLogger.log("HISTORY STATE NAV TO", { state });

        const from = state.current;
        const to: NavTarget = {
            pageId: params.pageId,
            path: params.path,
            orderIndex: state.order.length,
            layer: params.layer ?? from?.layer ?? 0,
            lastPosition: undefined,
        };

        const direction = params.direction ?? "FORWARD";
        const transitionType = params.transitionType ?? "PAGE";
        const layerDepthDifference = to.layer - (from?.layer ?? 0);

        dispatch({
            type: "NAVIGATE_TO",
            to,
            direction,
            transitionType,
            layerDepthDifference,
        });
    };

    const navigateReplace = (params: { path: string }) => {
        dispatch({
            type: "NAVIGATE_REPLACE",
            to: params.path,
        });
    };

    const recordPagePosition = (params: {
        pageId: AppPageId;
        position: ClientRect;
    }) => {
        dispatch({
            type: "RECORD_PAGE_POSITION",
            ...params,
        });
    };

    // TODO! ADD SUPPORT FOR REPLACE

    return {
        navigateForward,
        navigateBack,
        navigateTo,
        navigateReplace,
        recordPagePosition,
    };
};

const useIsFirstPageLoad = () => {
    return !usePageNavigationState().previous;
};
const useIsFirstPageLoadAndMatch = (params: {
    path: string;
    exact: boolean;
}) => {
    const isFirstPageLoad = useIsFirstPageLoad();
    const unexactMatch = useMatch({
        path: params.path,
        end: params.exact,
    });
    return !!unexactMatch && isFirstPageLoad;
};

const usePageHide = (path: string): PageAnimationHide | undefined => {
    const isFirstPageLoad = useIsFirstPageLoad();
    const isExactRouteMatch = !!useMatch({
        path,
        end: true,
    });

    if (isExactRouteMatch) return undefined;
    if (isFirstPageLoad) return "initially";
    return "animate";
};

export {
    PageNavigationProvider,
    appPageIdFactory,
    useIsFirstPageLoad,
    useIsFirstPageLoadAndMatch,
    usePageHide,
    usePageNavigationDispatch,
    usePageNavigationState,
};
export type { AppPageId };
