import { FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormState } from "@@/shared/form/form.zustand-store";
import { assertNever } from "@towni/common";

const FormDebug = <State extends Record<string, unknown>>(props: {
    formId?: FormId;
    /** defaults to `console` */
    mode?: "console" | "render";
    className?: string;
    singleProperty?: keyof State;
}) => {
    const mode = props.mode ?? "render";
    const _formIdFromContext = useFormId({
        doNotThrow: true,
    });
    const formId = props.formId || _formIdFromContext;
    if (!formId)
        throw new Error("Form id not set and no form id provider is available");
    const form = useFormState<State>(formId);
    if (!form) return null;
    switch (mode) {
        case "console":
            if (props.singleProperty) {
                // eslint-disable-next-line no-console
                console.log(form.state[props.singleProperty]);
            }
            // eslint-disable-next-line no-console
            else console.log(form);
            return null;
        case "render":
            return (
                <div
                    className={props.className}
                    css={{
                        color: "rgba(0,0,0,0.8)",
                        backgroundColor: "rgba(0,0,0,0.1)",
                        borderRadius: 10,
                        padding: 20,
                        overflowX: "hidden",
                        overflowY: "visible",
                        minHeight: 150,
                    }}>
                    <span>DEBUG</span>
                    <pre>
                        <code
                            css={{
                                userSelect: "text",
                                whiteSpace: "pre-wrap",
                            }}>
                            {props.singleProperty
                                ? `${props.singleProperty.toLocaleString()}: ${JSON.stringify(form.state[props.singleProperty], null, 2)}`
                                : JSON.stringify(form, null, 2)}
                        </code>
                    </pre>
                </div>
            );
        default:
            assertNever(mode);
    }
};

export { FormDebug };
