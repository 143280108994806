import { useScript } from "@@/carts/deliverypicker/use-script";
import { browserLogger } from "@@/settings/browser-logger";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import {
    Address,
    ColorItem,
    Translatable,
    addressFactory,
    createGeoLocationPoint,
    createMapUrl,
    emptyAddress,
    emptyArrayOf,
    metaContentFactory,
    toObjectMap,
    translation,
} from "@towni/common";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { HorizontalLine, VerticalDivider } from "../../dividers";
import { FlexColumn, FlexRow } from "../../flex-containers";
import { Join } from "../../join";
import { TextBox, TextEdit, TextSpan } from "../../text";
import { AddressPredictionRow } from "./adress-prediction-row";

const addressComponents = [
    "zipCode",
    "street",
    "streetNumber",
    "city",
    "country",
] as const;
type AddressComponent = (typeof addressComponents)[number];
type AddressRequirements = { [key in AddressComponent]: boolean };

type Props = {
    /**
     * If required, will return an error message if missing, else a warning message
     * All defaults to true
     * @type {AddressRequirements}
     */
    readonly requirements?: AddressRequirements;
    readonly onAddressChange: (result: AddressResult | undefined) => void;
    readonly initialValue?: string;
    readonly placeholder?: Translatable;
    readonly label?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    readonly noAddressFound: () => void;
    readonly setSearchFor?: string;
};

const parseAddressComponent = (
    type: string,
    place: google.maps.places.PlaceResult,
) => {
    return place.address_components?.find(component =>
        component.types.includes(type),
    )?.long_name;
};

type AddressResult = {
    formattedAddress: string;
    address: Address;
    warnings: Translatable[];
    errors: Translatable[];
};

const validateAddress = (
    place: google.maps.places.PlaceResult | undefined,
    prediction: google.maps.places.AutocompletePrediction,
    requirements: AddressRequirements,
): AddressResult => {
    if (place?.formatted_address && place?.address_components) {
        const _route = parseAddressComponent("route", place);
        const _streetNumber =
            parseAddressComponent("street_number", place) ??
            prediction.structured_formatting.main_text
                .toLocaleLowerCase()
                .replace(_route?.toLocaleLowerCase() ?? "", "")
                .trim();
        const parsedAddressComponents: { [key in AddressComponent]: string } = {
            zipCode: parseAddressComponent("postal_code", place) ?? "",
            city: parseAddressComponent("postal_town", place) ?? "",
            country: parseAddressComponent("country", place) ?? "",
            street: _route ?? "",
            streetNumber: _streetNumber ?? "",
        };
        const { zipCode, city, country, street, streetNumber } =
            parsedAddressComponents;

        const latitude = place.geometry?.location?.lat();
        const longitude = place.geometry?.location?.lng();
        const latLng =
            latitude && longitude
                ? ([latitude, longitude] as const)
                : undefined;

        // Valid address
        const newAddress = addressFactory({
            addressRows: [`${street} ${streetNumber}`],
            zipCode,
            city,
            country,
            geoLocation: latLng
                ? createGeoLocationPoint({
                      coordinates: [...latLng],
                      meta: metaContentFactory({
                          title: place.formatted_address,
                      }),
                  })
                : undefined,
            mapUrls: [
                ...(place.url
                    ? [
                          createMapUrl({
                              title: translation({
                                  sv: place.formatted_address,
                              }),
                              url: place.url,
                          }),
                      ]
                    : []),
            ],
        });

        // WARNINGS
        const warningProps = Object.entries(requirements)
            .filter(([_, isError]) => !isError)
            .map(([key]) => key);
        const errorProps = Object.entries(requirements)
            .filter(([_, isError]) => isError)
            .map(([key]) => key);

        const messages: { [key in AddressComponent]: Translatable } = {
            zipCode: translation({
                sv: "postnummer",
                en: "postal code",
            }),
            street: translation({
                sv: "gata",
                en: "street name",
            }),
            streetNumber: translation({
                sv: "gatunummer",
                en: "street number",
            }),
            city: translation({
                sv: "postaddress",
                en: "city",
            }),
            country: translation({
                sv: "land",
                en: "country",
            }),
        };

        // WARNINGS
        const warnings: Translatable[] = Object.entries(parsedAddressComponents)
            .filter(([key, value]) => {
                return !value?.trim() && warningProps.includes(key);
            })
            .map(([key]) => messages[key as AddressComponent]);

        // ERRORS
        const errors: Translatable[] = Object.entries(parsedAddressComponents)
            .filter(([key, value]) => {
                return !value?.trim() && errorProps.includes(key);
            })
            .map(([key]) => messages[key as AddressComponent]);

        return {
            formattedAddress: place.formatted_address,
            address: newAddress,
            warnings,
            errors,
        };
    }
    return {
        formattedAddress: "",
        address: emptyAddress,
        errors: ["Adress ej angiven"],
        warnings: [],
    };
};

// Prefer adresses in lidköping area,
// but do not restrict to them
const lidkopingCoordinates = () =>
    new google.maps.LatLng(58.50675442781556, 13.156808797751646);

const getPlaceDetailsStatus = [
    "INVALID_REQUEST",
    "NOT_FOUND",
    "OK",
    "OVER_QUERY_LIMIT",
    "REQUEST_DENIED",
    "UNKNOWN_ERROR",
    "ZERO_RESULTS",
] as const;
type GetPlaceDetailsStatus = (typeof getPlaceDetailsStatus)[number];
const placeDetailsStatusMap = toObjectMap<GetPlaceDetailsStatus>([
    ...getPlaceDetailsStatus,
]);

const AddressInputWithAutocomplete = (props: Props) => {
    // Todo: load google maps api key from settings
    const googleMapsApiKey = "AIzaSyAzLb42Hir4zVAduo1UTK8j3v3TmVybyQw";
    const googleMapsScriptLoadStatus = useScript(
        `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    );
    const theme = useTheme();
    const translate = useTranslate();
    const addressRequirements: AddressRequirements = props.requirements ?? {
        city: true,
        country: true,
        street: true,
        streetNumber: true,
        zipCode: true,
    };

    const [autocomplete, setAutoComplete] =
        useState<google.maps.places.AutocompleteService | null>(null);
    const [places, setPlaces] =
        useState<google.maps.places.PlacesService | null>(null);

    useEffect(() => {
        if (googleMapsScriptLoadStatus !== "READY") return;
        const auto = new google.maps.places.AutocompleteService();
        setAutoComplete(auto);
        setPlaces(
            new google.maps.places.PlacesService(
                document.getElementById("attributions") as HTMLDivElement,
            ),
        );
    }, [googleMapsScriptLoadStatus]);

    const [warnings, setWarnings] = useState<Translatable[]>([]);
    const [errors, setErrors] = useState<Translatable[]>([]);
    const [noAddress, setNoAddress] = useState(false);
    const [predictions, setPredictions] =
        useState<google.maps.places.AutocompletePrediction[]>(
            emptyArrayOf<google.maps.places.AutocompletePrediction>(),
        );
    const [searchFor, setSearchFor] = useState(props.initialValue);
    const [showPredictions, setShowPredictions] = useState(false);
    const [selectedPrediction, setSelectedPrediction] =
        useState<google.maps.places.AutocompletePrediction>();

    useEffect(() => {
        if (!selectedPrediction) return;
        setShowPredictions(false);
        const placeTask = new Promise<google.maps.places.PlaceResult>(
            (resolve, reject) => {
                if (!places) return reject("Google maps not loaded");
                if (!selectedPrediction)
                    return reject("No prediction selected");

                places.getDetails(
                    {
                        placeId: selectedPrediction.place_id,
                    },
                    (result, status) => {
                        browserLogger.log("GetPlaceDetailsStatus", {
                            result,
                            status,
                        });
                        if (status === placeDetailsStatusMap.OK && result) {
                            return resolve(result);
                        } else {
                            return reject(status as GetPlaceDetailsStatus);
                        }
                    },
                );
            },
        );
        placeTask
            .then(
                place => {
                    setSearchFor(selectedPrediction.description);
                    const addressResult = validateAddress(
                        place,
                        selectedPrediction,
                        addressRequirements,
                    );
                    if (!place?.geometry) {
                        // User entered the name of a Place that was not suggested and
                        // pressed the Enter key, or the Place Details request failed.
                        setNoAddress(true);
                        setErrors([]);
                        setWarnings([]);
                        props.onAddressChange(addressResult);
                        return;
                    }
                    setNoAddress(false);
                    browserLogger.log("PLACE CHANGED", {
                        place,
                        addressResult,
                    });
                    props.onAddressChange(addressResult);
                    setWarnings(addressResult.warnings);
                    setErrors(addressResult.errors);
                },
                placeRejection => {
                    browserLogger.log("placeRejection", {
                        placeRejection,
                    });
                },
            )
            .catch(error => {
                setErrors([error]);

                Sentry.captureException(error);
            });
    }, [
        selectedPrediction,
        //addressRequirements, rerendering bug causes everything to
        //   places,
        // props?.onAddressChange,
    ]);

    useEffect(() => {
        if (!autocomplete) return;
        if (!searchFor) return;

        debounce(() => {
            // console.log("getPlacePredictions", searchFor);
            autocomplete.getPlacePredictions(
                {
                    input: searchFor,
                    location: lidkopingCoordinates(),
                    radius: 60_000, // meters
                    componentRestrictions: { country: "se" },
                    types: ["address"],
                },
                (result, status) => {
                    setPredictions(
                        result ??
                            emptyArrayOf<google.maps.places.AutocompletePrediction>(),
                    );
                    if (status === "ZERO_RESULTS") setNoAddress(true);
                },
            );
        }, 300)();
    }, [searchFor, autocomplete]);

    useEffect(() => {
        if (props.setSearchFor) {
            setSearchFor(props.setSearchFor);
        }
    }, [props.setSearchFor]);
    return (
        <div>
            <TextEdit
                type="text"
                level="REQUIRED"
                label={props.label}
                autoComplete="off"
                value={searchFor}
                onChange={value => {
                    // console.log("TextEdit On change", value);
                    setSearchFor(value);
                    setShowPredictions(true);
                    props.onAddressChange(undefined);
                }}
                onClick={() => setShowPredictions(true)}
                onBlur={() => setShowPredictions(false)}
                description={props.description}
                hideDescriptionAfterInput={props.hideDescriptionAfterInput}
                placeholder={
                    props.placeholder ??
                    translation({
                        sv: "Sök adress",
                        en: "Search for address",
                    })
                }
            />

            {showPredictions && predictions.length ? (
                <div
                    css={css`
                        width: 100%;
                        position: relative;
                    `}>
                    <FlexColumn
                        radius={6}
                        fillParentWidth
                        background={{ color: "#fff" }}
                        styling={css`
                            box-shadow: 0px 4px 10px #ccc;
                            border: 1px solid "#ccc";
                            position: absolute;
                            z-index: 50;
                        `}>
                        <Join divider={<HorizontalLine />}>
                            {predictions.map(prediction => (
                                <AddressPredictionRow
                                    key={prediction.place_id}
                                    prediction={prediction}
                                    onClick={() => {
                                        setSelectedPrediction(prediction);
                                    }}
                                />
                            ))}
                        </Join>
                    </FlexColumn>
                </div>
            ) : null}
            {!noAddress ? (
                <FlexRow
                    fillParentWidth
                    padding={{ top: 10, right: 6 }}
                    mainAxis="flex-end"
                    onClick={props.noAddressFound}>
                    <TextBox
                        text="välj på karta »"
                        size="XS"
                        color={theme.colors.black.light60}
                    />
                </FlexRow>
            ) : null}
            {noAddress ? (
                <FlexColumn
                    radius={6}
                    background={{
                        color: "#fffdd0", //TODO
                    }}
                    css={{
                        marginTop: 10,
                    }}
                    onClick={props.noAddressFound}>
                    <TextBox
                        padding={{ topBottom: 12, leftRight: 15 }}
                        size="S"
                        styling={css`
                            line-height: 20px;
                        `}>
                        <TextSpan
                            text={translation({
                                sv: `Vi verkar ha problem att hitta din adress men du kan själv få namnge och peka ut den på en karta`,
                            })}
                            color={theme.colors.black}
                            weight="400"
                        />
                        <TextSpan
                            styling={css`
                                cursor: pointer;
                            `}
                            text={translation({
                                sv: ` här » 🙂`,
                            })}
                            color={theme.colors.primary}
                            weight="700"
                        />
                    </TextBox>
                </FlexColumn>
            ) : null}
            {errors.length ? (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        padding={{ all: 5 }}
                        text={translation({
                            sv: `Var vänlig ange: ${errors
                                .map(error => translate(error))
                                .join(", ")}`,
                            en: `Please enter: ${errors
                                .map(error => translate(error))
                                .join(", ")}`,
                        })}
                        color={theme.colors.danger}
                        weight="700"
                        size="S"
                        italic
                    />
                </>
            ) : null}
            {warnings.length ? (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        padding={{ all: 5 }}
                        text={translation({
                            sv: `Saknar: ${warnings
                                .map(warning => translate(warning))
                                .join(", ")}`,
                            en: `Missing: ${warnings
                                .map(warning => translate(warning))
                                .join(", ")}`,
                        })}
                        color={theme.colors.danger}
                        size="S"
                        weight="700"
                        italic
                    />
                </>
            ) : null}
        </div>
    );
};

export { AddressInputWithAutocomplete };
