import { useDiscountsForProvider } from "@@/discounts/discount-fetchers";
import { DiscountId, ProviderId } from "@towni/common";
import { useMemo } from "react";

const useDiscountsForProduct = (
    product: { providerId: ProviderId; discountIds: DiscountId[] } | undefined,
) => {
    const [discounts, discountQuery] = useDiscountsForProvider(
        product?.providerId,
    );
    const discountsForProduct = useMemo(() => {
        return discounts.filter(discount =>
            product?.discountIds?.includes(discount._id),
        );
    }, [discounts, product]);
    return [discountsForProduct, discountQuery.isPending] as const;
};

export { useDiscountsForProduct };
