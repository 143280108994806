import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Paragraph, PriceTextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    OrderItemBookableQuantity_V2,
    OrderItemBookable_V2,
} from "@towni/common";

type Props = {
    item: OrderItemBookable_V2;
    items: OrderItemBookableQuantity_V2[];
    //    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
};

const BookedResourcesCostRow = (props: Props) => {
    const theme = useTheme();
    const { items } = props;

    return (
        <FlexColumn>
            <ForEach itemOf={items} getKey={item => item._id}>
                {value => (
                    <FlexRow
                        fillParentWidth
                        mainAxis="space-between"
                        key={value._id}>
                        <Paragraph
                            content={[
                                value.quantity.value.toString(),
                                " x ",
                                value.meta.title,
                            ]}
                            css={{
                                color: theme.colors.black.light60.asString,
                            }}
                        />
                        <HorizontalDivider />
                        <PriceTextBox
                            price={value.price}
                            color={theme.colors.black.light60}
                        />
                    </FlexRow>
                )}
            </ForEach>
        </FlexColumn>
    );
};

export { BookedResourcesCostRow };
