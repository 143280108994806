import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
    DurationInSeconds,
    PayRequestId,
    PayRequestPaymentId,
    SECONDS,
} from "@towni/common";

const useRecheckPayRequestPaymentStatus = (
    payRequestId: PayRequestId | undefined,
    payRequestPaymentId: PayRequestPaymentId | undefined,
    refetchInterval: DurationInSeconds | undefined = undefined
) => {
    // console.log("useRecheckPayRequestPaymentStatus", {
    //     payRequestId,
    //     payRequestPaymentId,
    //     refetchInterval,
    // });
    const apiFetchClient = useFetchClient();
    const queryFn: QueryFunction<unknown> = async ({ signal }) => {
        const response = await apiFetchClient.get<unknown>({
            route: `/payments/pay-requests/${payRequestId}/recheck-payment-status?payRequestPaymentId=${payRequestPaymentId}`,
            customConfig: { signal },
        });
        return response;
    };
    return useQuery<unknown>({
        queryKey: [
            "pay-requests",
            payRequestId,
            "recheck-pay-request-payment-status",
        ],
        queryFn,
        enabled: !!payRequestId && !!payRequestPaymentId,
        refetchInterval: refetchInterval ? refetchInterval * SECONDS : false,
    });
};

export { useRecheckPayRequestPaymentStatus };
