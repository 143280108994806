import { useMe } from "@@/authentication/me/me-context";
import { useProducts } from "@@/products/state/queries/products/use-products";
import { isProviderHidden } from "@@/providers/is-provider-hidden";
import { useProviders } from "@@/providers/state/queries/providers/use-providers";
import { browserLogger } from "@@/settings";
import { HorizontalDivider } from "@@/shared/dividers";
import { backendHost, fetchClient } from "@@/shared/fetch-client";
import { FlexRow } from "@@/shared/flex-containers";
import { ImageShimmer, TextShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { TextBox } from "@@/shared/text/text-box";
import { useMountEffect } from "@@/shared/use-mount-effect";
import {
    ProductId,
    emptyArrayOf,
    emptySetOf,
    generateId,
    hejalfredScope,
    isAlfredItemActive,
    isVoucher,
    shuffle,
} from "@towni/common";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VoucherCardWithChin } from "./voucher-card-with-chin";

// Load voucher ids from cloud storage
const voucherEndpoint = `${backendHost}/public/storage/towni-brand`;
let voucherPath = "/misc/voucher-ids-for-storefront.json";
if (window.runtimeSettings.APP_ENV !== "GCP_PROD") {
    voucherPath = voucherPath.replace(
        ".json",
        `-${encodeURIComponent(
            window.runtimeSettings.APP_ENV?.toLowerCase() ?? "unknown",
        )}.json`,
    );
}
type Props = {
    readonly divider?: JSX.Element | ((index: number) => JSX.Element);
    readonly numberOfVouchers: number;
};
const VoucherListHz = (props: Props) => {
    const [me] = useMe();
    const navigate = useNavigate();
    const [mountId] = useState(() => generateId({ length: 8 }));
    const [voucherIds, setVoucherIds] = useState(emptyArrayOf<ProductId>());
    const [vouchers, voucherQuery] = useProducts(voucherIds);
    const [unavailableVoucherIds, setUnavailableVouchers] =
        useState(emptySetOf<ProductId>());
    const providerIds = useMemo(
        () => vouchers.map(voucher => voucher.providerId),
        [vouchers],
    );
    const [providers, providerQuery] = useProviders(providerIds);
    const isLoading = voucherQuery.isPending || providerQuery.isPending;

    useMountEffect(() => {
        // Fetch voucher ids from cloud storage json file
        fetchClient(voucherEndpoint)
            .get<ProductId[]>({
                route: voucherPath + "?" + mountId,
            })
            .then(voucherIds => {
                setVoucherIds(voucherIds);
            })
            .catch(error => {
                browserLogger.error(error);
            });
    });

    useEffect(() => {
        // Filter out unavailable vouchers
        // - If we're still loading data, wait for everything to finish
        if (isLoading) return;
        // - If there are no vouchers or providers, then there are no unavailable vouchers either
        if (!vouchers.length || !providers.length) {
            setUnavailableVouchers(emptySetOf<ProductId>());
            return;
        }

        // - Check for voucher availability
        const unavailableVouchers = vouchers.filter(voucher => {
            const provider = providers.find(
                provider => provider._id === voucher.providerId,
            );
            // -- if provider doesn't exist, then the voucher is unavailable
            if (!provider) return true;

            // -- if product is not a voucher, then the "voucher" is unavailable
            if (!isVoucher(voucher)) return true;

            // -- if product isn't active, then it's unavailable
            if (!isAlfredItemActive(voucher.status)) return true;

            // -- if provider is hidden, then the voucher is unavailable
            if (isProviderHidden(provider)) return true;

            // -- otherwise, the voucher is available
            return false;
        });

        setUnavailableVouchers(
            new Set(unavailableVouchers.map(voucher => voucher._id)),
        );
    }, [vouchers, providers, isLoading]);

    // Randomize the order of the vouchers
    const randomizedVoucherIds = useMemo(
        () => shuffle(voucherIds),
        [voucherIds],
    );

    const isMini = usePageBreakpoint({ when: "XS" });
    const itemsToRender = useMemo(
        () =>
            randomizedVoucherIds
                .filter(voucherId => !unavailableVoucherIds.has(voucherId))
                .slice(0, props.numberOfVouchers)
                .map((voucherId, index) => {
                    const minWidth = isMini ? 250 : 290;

                    if (isLoading)
                        // When we are still loading data, show a shimmer
                        return (
                            <FlexRow
                                shrink={0}
                                grow={1}
                                minWidth={minWidth}
                                minHeight={300}
                                key={`${voucherId}_${index}`}
                                mainAxis="center"
                                crossAxis="center"
                                padding={{ all: 10 }}>
                                <TextShimmer rows={3} />
                            </FlexRow>
                        );

                    const isUnavailable = unavailableVoucherIds.has(voucherId);
                    if (isUnavailable && !me?.scopes.includes(hejalfredScope))
                        return null;
                    const voucher = vouchers.find(
                        voucher => voucher._id === voucherId,
                    );
                    const provider = providers.find(
                        provider => provider._id === voucher?.providerId,
                    );

                    return (
                        <React.Fragment key={`${voucherId}_${index}`}>
                            <VoucherCardWithChin
                                key={`${voucherId}_${index}`}
                                voucher={voucher}
                                provider={provider}
                                minWidth={minWidth}
                                unavailable={unavailableVoucherIds.has(
                                    voucherId,
                                )}
                            />
                            <HorizontalDivider XS />
                        </React.Fragment>
                    );
                })
                .filter(Boolean),
        [
            randomizedVoucherIds,
            props.numberOfVouchers,
            unavailableVoucherIds,
            isMini,
            isLoading,
            me?.scopes,
            vouchers,
            providers,
        ],
    );

    return (
        <SectionAreaList
            scroll={true}
            contentMargin={10}
            padding={{
                top: 15,
                bottom: 30,
                left: 20,
            }}>
            {itemsToRender.length > 0 ? (
                itemsToRender
            ) : (
                <FlexRow fillParentWidth>
                    <FlexRow
                        shrink={0}
                        grow={1}
                        mainAxis="center"
                        minHeight={300}
                        minWidth={300}
                        crossAxis="center"
                        padding={{ all: 10 }}>
                        <ImageShimmer />
                    </FlexRow>
                    <HorizontalDivider XS />

                    <FlexRow
                        shrink={0}
                        grow={1}
                        mainAxis="center"
                        minHeight={300}
                        minWidth={300}
                        crossAxis="center"
                        padding={{ all: 10 }}>
                        <ImageShimmer />
                    </FlexRow>
                </FlexRow>
            )}
            <div
                onClick={() => {
                    navigate("/vouchers");
                }}
                css={{
                    position: "relative",
                    willChange: "filter",
                    background:
                        "linear-gradient(96.92deg, hsla(352, 90%, 31%, 1.00) 0%, hsla(352, 90%, 35%, 1.00) 100%)",
                    minHeight: 50,
                    minWidth: 250,
                    borderRadius: 10,
                    marginRight: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // objectFit: "cover",
                    filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15))",
                }}>
                <TextBox
                    text="Upptäck fler presentkort »"
                    weight="900"
                    align="center"
                    size={2}
                    color="#ffffff"
                    padding={{ all: 15 }}
                />
            </div>
            <HorizontalDivider ML />
        </SectionAreaList>
    );
};

export { VoucherListHz };
