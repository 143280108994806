import { Card } from "@@/sections/content/items/card/card";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { PaddingProps } from "@@/shared/padding";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    MetaImage,
    OrderItemAccommodation_V2,
    StorageItemImage,
} from "@towni/common";

type Props = {
    orderItem: OrderItemAccommodation_V2;
    imageReference: MetaImage | StorageItemImage | undefined;
    padding?: PaddingProps;
};

const AccommodationOrderItemHeader = (props: Props) => {
    const theme = useTheme();
    const { orderItem, imageReference } = props;
    return (
        <FlexRow padding={props.padding ?? { all: 20 }}>
            <Card
                width={80}
                height={80}
                background={{
                    imageReference,
                    imageSize: {
                        imageWidth: theme.sizes.imageSizes.closest(80),
                    },
                    color: theme.colors.black.light95,
                    fit: "COVER",
                }}
            />
            <HorizontalDivider L />
            <FlexColumn>
                <TextBox weight="900" size="L" text={orderItem.meta.title} />
                <TextBox text={orderItem.meta.kicker} size="S" weight="400" />
                <TextBox
                    text={orderItem.accommodation.title}
                    size="S"
                    weight="400"
                />
            </FlexColumn>
        </FlexRow>
    );
};

export { AccommodationOrderItemHeader };
