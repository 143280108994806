import { Form } from "@@/shared/form/form.zustand-store";
import {
    type Form2SelectPossibleValueTypes,
    type Form2SelectProps,
} from "@@/shared/form/form2-select";
import {
    ZodObjectOf,
    ZodTypeOf,
    translation,
    type Translatable,
} from "@towni/common";
import { Draft } from "immer";
import { type ReactNode } from "react";
import { type Except } from "type-fest";
import { z } from "zod";

type State<Value extends Form2SelectPossibleValueTypes> = {
    value: Value;
};

type Props<Value extends Form2SelectPossibleValueTypes> = {
    readonly initialValue?: Value;
    readonly onChange: (value: Value | undefined) => void;
    readonly children?: ReactNode;
    readonly label?: Translatable;
    readonly zodSchema: ZodTypeOf<Value>;
} & Except<
    Form2SelectProps<State<Value>, Value>,
    "fieldId" | "getter" | "setter"
>;

const Select = <Value extends Form2SelectPossibleValueTypes>(
    props: Props<Value>,
) => {
    return (
        <Form<State<Value>>
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        value: props.zodSchema,
                    }) as ZodObjectOf<State<Value>>,
                    initialState: {
                        value: props.initialValue,
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.Select<Value | undefined>
                    {...props}
                    fieldId="select"
                    label={
                        props.label ??
                        translation({
                            sv: "Välj ett alternativ",
                            en: "Choose an option",
                        })
                    }
                    getter={data => data.value}
                    setter={(data, newValue) => {
                        if (!newValue) return;
                        data.value = newValue as Draft<Value>;
                        props.onChange(newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { Select };
