import {
    useFetch,
    useFetchMultiple,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";
import { Discount, ProviderId } from "@towni/common";

const mainQueryKey = "discounts";
const mainRoute = "discounts";

const useDiscount = (id?: string) =>
    useFetch<Discount>(mainQueryKey, mainRoute, id);
const useDiscounts = (ids?: string[]) =>
    useFetchMultiple<Discount>(mainQueryKey, mainRoute, ids);
const useDiscountsForProvider = (providerId?: ProviderId) =>
    useFetchMultipleForSingle<Discount>({
        mainQueryKey,
        mainRoute,
        fetchFor: "provider",
        id: providerId,
    });

export {
    useDiscount,
    useDiscounts,
    useDiscountsForProvider,
    mainQueryKey as mainQueryKeyDiscounts,
};
