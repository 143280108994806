import { OrderExtras } from "@@/products/bookables/bookable-order-extras";
import { OrderKeyAccess } from "@@/products/bookables/bookable-order-key-access";
import { AccommodationReceipt_OrderDetailsHeader } from "@@/receipts/accommodation/accommodation-receipt-order-details-header";
import { AccommodationReceipt_OrderItemBody } from "@@/receipts/accommodation/accommodation-receipt-order-item-body";
import { HorizontalLine } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import {
    Order,
    ResourceReservation,
    isOrderItemAccommodation_V2,
} from "@towni/common";

type Props = {
    order: Order;
    resourceReservations: ResourceReservation[];
};

const AccommodationReceipt_OrderDetails = (props: Props) => {
    const { order } = props;

    return (
        <>
            <ForEach
                itemOf={order.orderItems.filter(isOrderItemAccommodation_V2)}
                divider={<HorizontalLine />}>
                {orderItem => (
                    <>
                        <AccommodationReceipt_OrderDetailsHeader
                            orderItem={orderItem}
                        />
                        <AccommodationReceipt_OrderItemBody
                            orderItem={orderItem}
                            order={order}
                            resourceReservations={
                                props.resourceReservations ?? []
                            }
                        />

                        <OrderExtras order={order} />
                        <OrderKeyAccess order={order} />
                    </>
                )}
            </ForEach>
        </>
    );
};

export { AccommodationReceipt_OrderDetails };
