import { useCart } from "@@/carts/multi-carts/cart.context";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import {
    calculateMinMaxRange,
    checkExtrasConditions,
    isOrderItemAccommodation_V2,
    isProductOrderItem,
    sum,
} from "@towni/common";
import { useMemo } from "react";

const useIsAccommodationBookingCompleteWithExtras = (onpremise: boolean) => {
    const state = useBookingAccommodationContext(context => ({
        accommodation: context.accommodation,
    }));

    const cart = useCart();

    const extras = useMemo(
        () => state.accommodation.extrasGroups ?? [],
        [state.accommodation.extrasGroups],
    );

    return useMemo(() => {
        const allExtrasPicked = extras
            .filter(extra => {
                const conditionsMet = checkExtrasConditions(extra, {
                    onPremise: onpremise,
                });
                if (!conditionsMet) return false;
                return true;
            })
            .map(extra => {
                const orderItemsForExtra = cart.orderItems.filter(
                    oi => oi.extraGroup?._id === extra._id,
                );

                const settings = extra.settings;
                if (settings._type === "FREE_AMOUNT") return true;

                if (settings._type === "BOOKABLE_OPTION_AMOUNT") {
                    if (settings.bookableOptions.length === 0) return true;

                    const accommodationItems = cart.orderItems.filter(
                        isOrderItemAccommodation_V2,
                    );

                    const accommodationAmount = accommodationItems.map(oi => {
                        const quantityItems = oi.quantities.filter(qI =>
                            settings.bookableOptions.includes(qI.type.type),
                        );
                        return sum(quantityItems, oi => oi.quantity.value);
                    });

                    const amountToBePicked = sum(accommodationAmount);

                    const items = orderItemsForExtra.filter(
                        oi =>
                            isProductOrderItem(oi) &&
                            extra.products
                                .map(p => p.productId)
                                .includes(oi.productId),
                    );

                    const amountPicked = sum(items, oi => oi.quantity.value);

                    const minMax = calculateMinMaxRange(
                        extra,
                        amountToBePicked,
                    );

                    if (
                        amountPicked >= (minMax.min ?? 0) &&
                        amountPicked <= (minMax.max ?? Number.MAX_SAFE_INTEGER)
                    )
                        return true;
                    return false;
                }

                if (settings._type === "MIN_MAX_AMOUNT") {
                    const items = orderItemsForExtra.filter(
                        oi =>
                            isProductOrderItem(oi) &&
                            extra.products
                                .map(p => p.productId)
                                .includes(oi.productId),
                    );

                    const amount = sum(items, oi => oi.quantity.value);

                    if (
                        amount >= (settings.minMax.min ?? 0) &&
                        amount <=
                            (settings.minMax.max ?? Number.MAX_SAFE_INTEGER)
                    )
                        return true;
                }
                return false;
            })
            .every(x => x);
        return !allExtrasPicked;
    }, [extras, onpremise, cart.orderItems]);
};

export { useIsAccommodationBookingCompleteWithExtras };
