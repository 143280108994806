import { useTheme } from "@emotion/react";
import { colorAsString, ColorItem, SizeName } from "@towni/common";

type Props = {
    readonly size: SizeName | number;
    readonly onClick?: () => void;
    readonly borderColor?: ColorItem;
    readonly backgroundColor?: ColorItem;
    readonly disabled?: boolean;
    readonly className?: string;
    readonly dataTestId?: string;
};

const SquareSelect = (props: Props) => {
    const theme = useTheme();
    const size =
        typeof props.size === "number"
            ? props.size
            : theme.sizes.inPixels[props.size];

    const borderColor = colorAsString(
        props.borderColor || theme.colors.black.light50,
    );
    const background = colorAsString(
        props.backgroundColor || theme.colors.default.background,
    );

    return (
        <div
            className={props.className}
            onClick={props.onClick}
            data-testid={props.dataTestId}
            css={{
                width: size,
                height: size,
                border: `${Math.floor(size / 10)}px solid ${borderColor}`,
                borderRadius: 3,
                backgroundColor: background,
                flexShrink: 0,
                opacity: props.disabled ? 0.5 : 1,
            }}
        />
    );
};

const SquareSelected = (props: Props) => {
    const theme = useTheme();
    const size =
        typeof props.size === "number"
            ? props.size
            : theme.sizes.inPixels[props.size];

    const background = props.disabled
        ? theme.colors.primary.main.value.withAlpha(0.2).asString
        : theme.colors.primary.asString;

    return (
        <SquareSelect
            disabled={props.disabled}
            size={size}
            className={props.className}
            onClick={props.onClick}
            dataTestId={props.dataTestId}
            backgroundColor={background}
            css={{
                backgroundColor: background,
                boxShadow: `inset 0px 0px 0px ${Math.floor(size / 10)}px ${
                    theme.isLightTheme
                        ? theme.colors.white.asString
                        : theme.colors.black.asString
                }`,
            }}
        />
    );
};

export { SquareSelect, SquareSelected };
