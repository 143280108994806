import { usePaymentStyles } from "@@/payments/payment-form/styles";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FieldTitle as FieldTitle } from "@@/shared/text/field-title";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import React from "react";

type Props = {
    onFocus: () => void;
    disabled?: boolean;
};

const PaymentCardElement = (props: Props) => {
    const paymentStyles = usePaymentStyles();

    return (
        <FlexRow fillParentWidth shrink={0} padding={{ leftRight: 30 }}>
            <FlexColumn fillParentWidth shrink={0}>
                <FieldTitle text="Nytt kort" />
                <VerticalDivider XS />
                <FlexRow
                    fillParentWidth
                    shrink={0}
                    styling={paymentStyles.fieldContainerCss}>
                    <CardElement
                        css={paymentStyles.fieldCss}
                        onFocus={props.onFocus}
                        options={
                            {
                                ...paymentStyles.defaultCardElementOptions,
                                disabled: props.disabled,
                            } as StripeCardElementOptions
                        }
                    />
                </FlexRow>
            </FlexColumn>
        </FlexRow>
    );
};

export { PaymentCardElement };
