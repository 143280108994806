import { css, useTheme } from "@emotion/react";
import { Padding, Price } from "@towni/common";
import { FlexRow } from "../flex-containers";
import { PriceTextBox, TextBox } from "../text";
import { OptionalAmountPicker } from "./optional-amount-picker";

type Props = {
    padding?: Padding;
    increase: () => void;
    decrease: () => void;
    count: number;
    price?: Price;
};
const OptionalWithAmountPicker = (props: Props) => {
    const theme = useTheme();
    const priceTextStyle = css`
        opacity: 0.6;
        font-weight: 300;
    `;

    return (
        <FlexRow
            padding={props.padding}
            mainAxis="space-between"
            crossAxis="center">
            <OptionalAmountPicker
                count={props.count}
                increase={props.increase}
                decrease={props.decrease}
            />
            {(props.price && (
                <FlexRow mainAxis="flex-end">
                    <TextBox
                        text="+"
                        shrink={1}
                        color={theme.colors.default.text}
                        padding={{ right: 5 }}
                        styling={priceTextStyle}
                    />
                    <PriceTextBox
                        price={props.price}
                        fractions="NONE"
                        color={theme.colors.default.text}
                        styling={priceTextStyle}
                    />
                </FlexRow>
            )) ||
                null}
        </FlexRow>
    );
};

export { OptionalWithAmountPicker };
