import * as React from "react";

type State = {
    path: string[];
    pathSerialized: string;
    node: string;
};

const BreadCrumbPathContext = React.createContext<State | undefined>(undefined);

type Props = {
    node: string;
    children?: React.ReactNode;
};
const BreadCrumbPathProvider = (props: Props) => {
    const parent = React.useContext(BreadCrumbPathContext);

    const [state] = React.useState(() => {
        if (!props.node) throw new Error("bread crumb path id cannot be empty");
        const path = [...(parent?.path ?? []), props.node];
        return {
            path,
            pathSerialized: path.join("_"),
            node: props.node,
        };
    });

    return (
        <BreadCrumbPathContext.Provider value={state}>
            {props.children}
        </BreadCrumbPathContext.Provider>
    );
};

const useBreadCrumbPathState = () => {
    const context = React.useContext(BreadCrumbPathContext);
    if (context === undefined) {
        throw new Error(
            "useBreadCrumbPathState must be used within a BreadCrumbPathContext"
        );
    }
    return [context.pathSerialized, context.path, context.node] as const;
};

export { BreadCrumbPathProvider, useBreadCrumbPathState };
