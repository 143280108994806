import { MILLISECONDS } from "@towni/common";
import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useTimeout } from "./use-timeout";

const useKeyboardShortcut = (params: {
    keys: string[];
    callback: () => void | Promise<void>;
    target?: HTMLDivElement;
    disabled?: boolean;
    modifiers?: {
        ctrl?: boolean;
        alt?: boolean;
        shift?: boolean;
        meta?: boolean;
    };
}) => {
    // implement the callback ref pattern
    const callbackRef = useRef(params.callback);
    const lastKeyPresses = useRef<string[]>([]);
    useLayoutEffect(() => {
        callbackRef.current = params.callback;
    });

    const { reset } = useTimeout(() => {
        lastKeyPresses.current = [];
    }, 1000 as MILLISECONDS);

    // handle what happens on key press
    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            // Ignore if disabled
            if (params.disabled) return;
            // Ignore if the user is typing in an input field
            if (
                ["INPUT", "SELECT", "TEXTAREA"].includes(
                    (event.target as HTMLElement).tagName.toUpperCase(),
                )
            ) {
                return;
            }

            // Ignore if modifier is required and not pressed
            if (params.modifiers?.ctrl && !event.ctrlKey) return;
            if (params.modifiers?.alt && !event.altKey) return;
            if (params.modifiers?.meta && !event.metaKey) return;
            if (params.modifiers?.shift && !event.shiftKey) return;

            // Ignore if modifier is not required and pressed
            if (event.ctrlKey && !params.modifiers?.ctrl) return;
            if (event.altKey && !params.modifiers?.alt) return;
            if (event.metaKey && !params.modifiers?.meta) return;
            if (event.shiftKey && !params.modifiers?.shift) return;

            // Reset timeout to clear if next key press
            // is not within set timeout time
            reset();

            const keys = params.keys;

            // Add pressed key to array of last pressed keys
            lastKeyPresses.current.push(event.key);
            if (lastKeyPresses.current.length > keys.length) {
                lastKeyPresses.current.shift();
            }

            // check if last pressed keys match the keys provider in the params
            // if so, call the callback
            const match =
                keys.length &&
                lastKeyPresses.current.length === keys.length &&
                lastKeyPresses.current.every(
                    (key, index) => key === keys[index],
                );

            if (match) {
                // if so, call the callback
                callbackRef.current();
                // and reset the last pressed keys
                // and ignore the event, it's already been handled
                reset();
                event.stopPropagation();
                event.preventDefault();
            }
        },
        [params.keys],
    );

    useEffect(() => {
        // target is either the provided node or the document
        const targetNode = params.target ?? document;
        // attach the event listener
        const keydown = "keydown";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        targetNode?.addEventListener(keydown, handleKeyPress as any);

        // remove the event listener
        return () =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            targetNode?.removeEventListener(keydown, handleKeyPress as any);
    }, [handleKeyPress, params.target]);
};

export { useKeyboardShortcut };
