import { UseTranslateResult, translatableString, unique } from "@towni/common";
import { ZodError, ZodIssueCode } from "zod";

const parseZodError = (
    error: ZodError,
    translate: UseTranslateResult,
): string[] => {
    const result = unique(
        error.issues.flatMap(issue => {
            if (issue.code === ZodIssueCode.custom) {
                if (issue.params?.translatedMessage) {
                    return translate(issue.params?.translatedMessage);
                }
            }
            if (issue.code === "invalid_union") {
                const a = issue.unionErrors.flatMap(unionError => {
                    return parseZodError(unionError, translate);
                });
                return a;
            }
            const message = translate(
                translatableString.fromTranslatableString(issue.message),
            );
            return message;
        }),
    );
    return result;
};

export { parseZodError };
