import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Label } from "@@/shared/label";
import { loadFromStorage, saveToStorage } from "@@/shared/local-storage";
import {
    EmailInput,
    Paragraph,
    TextBox,
    TextEdit,
    paragraphHzSpaceFactory,
    paragraphIconFactory,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faAt, faMobile, faSignature } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons";
// import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { useProductExternalAccessTypesForProducts } from "@@/backoffice/for-providers/products/bookable/digital-doors/queries/use-product-external-access-types-for-products";
import { Inputs } from "@@/shared/form/standalones/inputs";
import {
    Translatable,
    contactInfoFactory,
    fullName,
    isOrderItemBookable,
    translation,
} from "@towni/common";
import * as React from "react";
import { useCheckoutContext } from "../checkout.context";
import { useContactInfoContext } from "./input-contact-info-context";

//TODO MAKE IT NOT DEPENDANT ON useCheckoutDispatch
const InputContactInfo = () => {
    const theme = useTheme();
    const { setContactInfo, contextId, bookableProductsWithExternalAccessIds } =
        useCheckoutContext(context => ({
            setContactInfo: context.setContactInfo,
            contactInfo: context.contactInfo,
            contextId: context._id,
            bookableProductsWithExternalAccessIds: context.cart.orderItems
                .filter(isOrderItemBookable)
                .filter(item => !!item.hasAccess)
                .map(item => item.productId),
        }));

    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phone,
        setPhone,
    } = useContactInfoContext();

    const externalAccessTypesQuery = useProductExternalAccessTypesForProducts(
        bookableProductsWithExternalAccessIds,
    );
    const hasParakeyAccess = React.useMemo(() => {
        return externalAccessTypesQuery.data?.items.some(
            item => item === "PARAKEY",
        );
    }, [externalAccessTypesQuery]);
    const speedyInputDisabled =
        hasParakeyAccess ||
        externalAccessTypesQuery.isInitialLoading ||
        externalAccessTypesQuery.isRefetching ||
        externalAccessTypesQuery.isError;
    const [speedyInputSetting, setSpeedyInputSetting] = React.useState(() => {
        return loadFromStorage<boolean>("_speedy_on_premise_contact_info");
    });
    const speedyInput = !speedyInputDisabled && speedyInputSetting;

    const [phoneInputErrorMessage, setPhoneInputErrorMessage] = React.useState<
        Translatable | undefined
    >();

    const full =
        !firstName && !lastName ? undefined : fullName({ firstName, lastName });
    const hasContactInfo = firstName && lastName && email && phone;
    React.useEffect(() => {
        if (
            (speedyInput && phone) ||
            (firstName && lastName && email && phone)
        ) {
            const contact = contactInfoFactory({
                name: full,
                lastName,
                firstName,
                phoneNumber: phone,
                emailAddress: email,
                image: null,
            });
            // console.log("SET CONTACT INFO", contact);
            setContactInfo(contact);
            return;
        }

        setContactInfo(null);
    }, [
        firstName,
        lastName,
        email,
        phone,
        setContactInfo,
        contextId,
        full,
        speedyInput,
    ]);

    const backgroundColor =
        !speedyInputDisabled && speedyInput && !!phone
            ? theme.colors.default.background.asString
            : hasContactInfo
              ? theme.colors.default.background.asString
              : theme.isLightTheme
                ? theme.colors.warning.light.light95.asString
                : theme.colors.warning.dark.light05.asString;

    return (
        <FlexColumn
            fillParentWidth
            css={{
                backgroundColor,
            }}
            tag={"customer-contact-info"}>
            <VerticalDivider size={1.5} />
            <FlexRow
                padding={{ leftRight: 20 }}
                crossAxis="flex-start"
                fillParentWidth
                css={{ flex: 1 }}>
                <FlexColumn
                    crossAxis="space-between"
                    mainAxis="flex-start"
                    css={{ flex: 1 }}>
                    <TextBox
                        size="L"
                        weight="800"
                        text={translation({
                            sv: "Kunduppgifter",
                            en: "Customer info",
                        })}
                    />
                    <VerticalDivider XS />
                    <Paragraph
                        css={{
                            fontSize: "0.825rem",
                        }}
                        content={[
                            paragraphIconFactory({
                                icon: speedyInput ? faCheckSquare : faSquare,
                                fixedWidth: true,
                                spin: externalAccessTypesQuery.isInitialLoading,
                                disabled: speedyInputDisabled,
                                onClick: () => {
                                    saveToStorage<boolean>(
                                        "_speedy_on_premise_contact_info",
                                        !speedyInputSetting,
                                    );
                                    setSpeedyInputSetting(!speedyInputSetting);
                                },
                                css: {
                                    opacity: speedyInputDisabled ? 0.5 : 1,
                                    color: theme.colors.default.text.withAlpha(
                                        speedyInput ? 0.5 : 0.4,
                                    ).asString,
                                    "&:hover": {
                                        color: theme.colors.default.text.withAlpha(
                                            speedyInput ? 0.5 : 0.4,
                                        ).asString,
                                    },
                                },
                            }),
                            paragraphHzSpaceFactory(0.2),
                            paragraphTextFactory({
                                disabled: speedyInputDisabled,
                                onClick: () => {
                                    saveToStorage<boolean>(
                                        "_speedy_on_premise_contact_info",
                                        !speedyInputSetting,
                                    );
                                    setSpeedyInputSetting(!speedyInputSetting);
                                },
                                css: {
                                    textDecoration: speedyInputDisabled
                                        ? "line-through"
                                        : undefined,
                                    color: theme.colors.default.text.withAlpha(
                                        speedyInput ? 0.5 : 0.4,
                                    ).asString,
                                    "&:hover": {
                                        textDecoration: speedyInputDisabled
                                            ? "line-through"
                                            : "none",
                                        color: theme.colors.default.text.withAlpha(
                                            speedyInput ? 0.5 : 0.4,
                                        ).asString,
                                    },
                                },
                                text: translation({
                                    sv: "Snabbinmatning",
                                    en: "Speedy input",
                                }),
                            }),
                            ...((hasParakeyAccess && speedyInputDisabled) ||
                            (speedyInputSetting && !speedyInputDisabled)
                                ? [
                                      paragraphLineBreakFactory(),
                                      paragraphTextFactory({
                                          css: {
                                              paddingTop: 8,
                                              display: "inline-block",
                                              fontSize: "0.825em",
                                              color: theme.colors.default.text.withAlpha(
                                                  speedyInput ? 0.5 : 0.4,
                                              ).asString,
                                          },
                                          text:
                                              hasParakeyAccess &&
                                              speedyInputDisabled
                                                  ? translation({
                                                        sv: "Ordern innehåller en eller flera digitala nycklar till parakey lås. E-postadress krävs för att kunden ska kunna tillgodogöra sig nycklar till parakeylås.",
                                                        en: "The order contains one or more digital keys to parakey locks. Email address is required for the customer to be able to use the keys to parakey locks.",
                                                    })
                                                  : speedyInputSetting &&
                                                      !speedyInputDisabled
                                                    ? translation({
                                                          sv: "Vid snabbinmatning krävs endast telefonnummer. Används med försiktighet då kundkommunikationen blir begränsad.",
                                                          en: "When using speedy input, only the phone number is required. Use with caution as customer communication will be limited.",
                                                      })
                                                    : undefined,
                                      }),
                                  ]
                                : []),
                        ]}
                    />
                </FlexColumn>
                {/* <Conditional when={!speedyInput}> */}
                <Label
                    text={translation({
                        sv: "obligatoriskt",
                        en: "mandatory",
                    })}
                    colorTheme="RED"
                    css={{
                        marginTop: 5,
                    }}
                />
                {/* </Conditional> */}
            </FlexRow>
            <VerticalDivider M />
            <HorizontalLine />
            <FlexRow
                fillParentWidth
                padding={{ left: 20 }}
                crossAxis="center"
                css={{
                    flex: 1,
                    backgroundColor,
                }}>
                <Icon
                    icon={faMobile}
                    color={theme.colors.default.placeholder}
                    style={{
                        minWidth: 20,
                    }}
                />
                <Inputs.PhoneNumberInput
                    containerCss={{
                        flex: 1,
                        border: "none",
                        backgroundColor,
                    }}
                    inputCss={{
                        fontWeight: 800,
                        border: "none",
                        backgroundColor,
                    }}
                    hideIcon
                    hideErrors
                    initialPhoneNumber={phone}
                    placeholder={phone}
                    onChange={({ phoneNumber, isValid }) => {
                        if (isValid) {
                            setPhone(phoneNumber);
                            setPhoneInputErrorMessage(undefined);
                        } else setPhone(undefined);
                    }}
                    onErrorsUpdate={errors => {
                        if (!errors.length)
                            setPhoneInputErrorMessage(undefined);
                        else setPhoneInputErrorMessage(errors.join(", "));
                    }}
                />
            </FlexRow>
            <Paragraph
                css={{
                    fontSize: "0.825rem",
                    padding: 20,
                    paddingTop: 0,
                    paddingBottom: 15,
                    backgroundColor,
                    // fontStyle: "italic",
                    color: theme.colors.danger.asString,
                }}
                content={
                    phoneInputErrorMessage
                        ? [
                              "*",
                              paragraphHzSpaceFactory(0.3),
                              phoneInputErrorMessage,
                          ]
                        : []
                }
            />
            <HorizontalLine />
            <FlexRow
                fillParentWidth
                padding={{ left: 20 }}
                crossAxis="center"
                css={{
                    flex: 1,
                    backgroundColor: speedyInput
                        ? theme.colors.default.background.asString
                        : backgroundColor,
                }}>
                <Icon
                    icon={faSignature}
                    color={theme.colors.default.placeholder}
                    css={{
                        minWidth: 20,
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                    }}
                />
                <TextEdit
                    type="text"
                    level="REQUIRED"
                    css={{
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                        border: "none",
                    }}
                    inputStyling={css({
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                        border: "none",
                    })}
                    placeholder={translation({
                        sv: `Förnamn`,
                        en: "First name",
                    })}
                    onChange={setFirstName}
                    value={firstName}
                    onValidationFailed={() => {
                        return;
                    }}
                    fillParentWidth={false}
                    grow={1}
                    shrink={1}
                />
            </FlexRow>

            <HorizontalLine />
            <FlexRow
                fillParentWidth
                padding={{ left: 20 }}
                crossAxis="center"
                css={{
                    flex: 1,
                    backgroundColor: speedyInput
                        ? theme.colors.default.background.asString
                        : backgroundColor,
                }}>
                <Icon
                    icon={faSignature}
                    color={theme.colors.default.placeholder}
                    style={{
                        minWidth: 20,
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                    }}
                />
                <TextEdit
                    type="text"
                    level="REQUIRED"
                    css={{
                        flex: 1,
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                        border: "none",
                    }}
                    inputStyling={css({
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                        border: "none",
                    })}
                    placeholder={translation({
                        sv: "Efternamn",
                        en: "Last name",
                    })}
                    onChange={setLastName}
                    value={lastName}
                    onValidationFailed={() => {
                        return;
                    }}
                    fillParentWidth={false}
                />
            </FlexRow>
            <HorizontalLine />
            <FlexRow
                fillParentWidth
                padding={{ left: 20 }}
                crossAxis="center"
                css={{
                    flex: 1,
                    backgroundColor: speedyInput
                        ? theme.colors.default.background.asString
                        : backgroundColor,
                }}>
                <Icon
                    icon={faAt}
                    color={theme.colors.default.placeholder}
                    style={{
                        minWidth: 20,
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                    }}
                />
                <EmailInput
                    inputStyling={css({
                        fontWeight: 800,
                        backgroundColor: speedyInput
                            ? theme.colors.default.background.asString
                            : backgroundColor,
                        border: "none",
                    })}
                    placeholder={translation({
                        sv: "E-post",
                        en: "Email",
                    })}
                    initialValue={email}
                    onChange={setEmail}
                    fillParentWidth={false}
                    grow={1}
                    shrink={1}
                />
            </FlexRow>
            <FlexRow
                css={{
                    flex: 1,
                    backgroundColor: speedyInput
                        ? theme.colors.default.background.asString
                        : backgroundColor,
                }}>
                <VerticalDivider XXS />
            </FlexRow>
        </FlexColumn>
    );
};

export { InputContactInfo };
