import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import {
    getDailySchedulesForWeekday,
    Provider,
    sortBy,
    translation,
    WeekdayShortName,
    WeeklySchedule,
    WeeklyScheduleException,
} from "@towni/common";
import * as React from "react";

type Props = {
    /**
     * defaults to today
     * @type {Date}
     */
    day?: Date | WeekdayShortName;
    businessHourSchedule: WeeklySchedule;
    exceptions: WeeklyScheduleException[];
    hideIcon?: boolean;
    onClick?: () => void;
};

const BusinessHoursPill: React.FC<Props> = props => {
    const theme = useTheme();
    const daysBusinessHours = getDailySchedulesForWeekday(
        props.day ?? new Date(),
        props.businessHourSchedule,
        props.exceptions,
    );
    const isOpenOnDay = !!daysBusinessHours?.length;

    let openHours = "";
    if (isOpenOnDay) {
        const showMinutes = daysBusinessHours.some(item =>
            [
                // Show minutes only if either start or end
                // time is not a full hour
                item.timeRange.startInMinutes % 60 !== 0,
                item.timeRange.endInMinutes % 60 !== 0,
            ].some(Boolean),
        );

        const businessHoursSorted = daysBusinessHours.sort(
            sortBy(item => item.timeRange.startInMinutes),
        );
        openHours = businessHoursSorted
            .map(bh =>
                showMinutes
                    ? `${Math.floor(bh.timeRange.startInMinutes / 60)
                          .toString()
                          .padStart(2, "0")}:${(
                          bh.timeRange.startInMinutes % 60
                      )
                          .toString()
                          .padStart(2, "0")}-${Math.floor(
                          bh.timeRange.endInMinutes / 60,
                      )}:${(bh.timeRange.endInMinutes % 60)
                          .toString()
                          .padStart(2, "0")}`
                    : `${Math.floor(bh.timeRange.startInMinutes / 60)
                          .toString()
                          .padStart(2, "0")}-${Math.floor(
                          bh.timeRange.endInMinutes / 60,
                      )
                          .toString()
                          .padStart(2, "0")}`,
            )
            .join(", ");
    }

    return (
        <>
            <FlexRow onClick={props.onClick}>
                {props.hideIcon ? null : (
                    <>
                        <Icon
                            icon={faClock}
                            color={theme.colors.black.light80}
                        />
                        <HorizontalDivider />
                    </>
                )}
                {isOpenOnDay ? (
                    <TextBox text={openHours} weight="400" />
                ) : (
                    <TextBox
                        text={translation({
                            sv: "Stängt",
                            en: "Closed",
                        })}
                        weight="400"
                        color={theme.colors.black.light70}
                    />
                )}
            </FlexRow>
        </>
    );
};

const BusinessHoursPillForProvider: React.FC<{
    day?: Date;
    provider: Provider;
    hideIcon?: boolean;
    onClick?: () => void;
}> = props => {
    return (
        <BusinessHoursPill
            day={props.day ?? new Date()}
            businessHourSchedule={props.provider.options.businessHours}
            exceptions={props.provider.options.businessHourExceptions}
            hideIcon={props.hideIcon}
            onClick={props.onClick}
        />
    );
};

export { BusinessHoursPill, BusinessHoursPillForProvider };
