import { Provider } from "@towni/common";
import * as React from "react";

const ProviderContext = React.createContext<Provider | undefined>(undefined);
const ProvidersContext = React.createContext<Provider[] | undefined>(undefined);
const ProvidersForAreaContext = React.createContext<Provider[] | undefined>(
    undefined
);
const ProvidersForUserContext = React.createContext<Provider[] | undefined>(
    undefined
);

export {
    ProviderContext,
    ProvidersForAreaContext,
    ProvidersForUserContext,
    ProvidersContext,
};
