import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import {
    Paragraph,
    PriceTextBox,
    TextBox,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    IsoAndUnixTimestamp,
    PayRequestItem,
    Price,
    Translatable,
    absPrice,
    formatSEKNumber,
    negatePrice,
    nowIsoAndUnix,
    sortAmountsByVatRate,
    sortBy,
    translation,
} from "@towni/common";
import { useMemo } from "react";

const PayRequestItemPriceTable = (props: {
    payRequestPrice: Price;
    items: PayRequestItem[];
}) => {
    const theme = useTheme();
    const translate = useTranslate();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const price = props.payRequestPrice;
    const allItemsAdded = props.items.every(item => item.type === "ADDED");
    const groupedPayRequestItems = useMemo(() => {
        const items = props.items;
        const justNow = nowIsoAndUnix();
        const [groupDates, groupItems] = items.reduce(
            (grouped, item) => {
                const groupRef = item.group;
                const groupKey = groupRef?.key || "[ungrouped]";
                const groupName = groupRef?.title || "";
                const groupCreatedAt = groupRef?.eventAt || justNow;

                const sortMap = grouped[0];
                const groupMap = grouped[1];

                sortMap.set(groupKey, groupCreatedAt);
                const currentGroup = groupMap.get(groupKey) ?? {
                    groupKey,
                    groupName,
                    items: [] as PayRequestItem[],
                };
                currentGroup.items.push(item);
                groupMap.set(groupKey, currentGroup);
                return [sortMap, groupMap];
            },
            [
                new Map<string, IsoAndUnixTimestamp>(),
                new Map<
                    string,
                    {
                        groupName: Translatable;
                        groupKey: string;
                        items: PayRequestItem[];
                    }
                >(),
            ] as const,
        );
        return { groupDates, groupItems };
    }, [props.items]);

    const hasMoreThanOneGroup = groupedPayRequestItems.groupDates.size > 1;
    const groupKeysAndDates = useMemo(
        () =>
            Array.from(groupedPayRequestItems.groupDates.entries()).sort(
                sortBy(([_, date]) => date.iso),
            ),
        [groupedPayRequestItems.groupDates],
    );

    return (
        <FlexColumn css={{ flex: 1 }}>
            <ForEach itemOf={groupKeysAndDates}>
                {([groupKey, dateTime], _index) => {
                    const data =
                        groupedPayRequestItems.groupItems.get(groupKey);
                    if (!data) return null;
                    const isLast = _index === groupKeysAndDates.length - 1;
                    const isFirst = _index === 0;
                    return (
                        <table
                            css={{
                                flex: 1,
                                padding: 0,
                                paddingBottom: isLast ? 20 : 30,
                                borderSpacing: 0,
                                // "& > tbody > tr > td, & > thead > tr > th": {
                                //     padding: 10,
                                // },
                                "& > tbody > tr > td, & > thead > tr > th": {
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                },
                                "& > tbody > tr > td:not(:last-of-type), & > thead > tr > th:not(:last-of-type)":
                                    {
                                        paddingRight: 20,
                                    },
                            }}>
                            <thead>
                                <Conditional
                                    when={hasMoreThanOneGroup}
                                    render={() => (
                                        <tr>
                                            <th
                                                colSpan={isDesktop ? 4 : 3}
                                                style={{
                                                    paddingTop: isFirst
                                                        ? 10
                                                        : 10,
                                                    paddingBottom: 0,
                                                    fontWeight: "400",
                                                }}>
                                                <FlexColumn
                                                    crossAxis="flex-start"
                                                    mainAxis="space-between">
                                                    <TextBox
                                                        size={1}
                                                        weight="700"
                                                        text={
                                                            groupKey ===
                                                            "[ungrouped]"
                                                                ? {
                                                                      sv: "Betalning",
                                                                      en: "Payment",
                                                                  }
                                                                : data.groupName
                                                        }
                                                    />
                                                    <VerticalDivider XS />
                                                    <DateTimeTextBox
                                                        size={0.825}
                                                        color={
                                                            theme.colors.black
                                                                .light60
                                                                .asString
                                                        }
                                                        dateTime={dateTime}
                                                    />
                                                </FlexColumn>
                                            </th>
                                        </tr>
                                    )}
                                    else={() => <VerticalDivider S />}
                                />
                                <tr>
                                    <th></th>
                                    {isDesktop ? (
                                        <th align="right">
                                            <TextBox
                                                text={{
                                                    sv: "varav moms",
                                                    en: "of which VAT",
                                                }}
                                                css={{ color: "#ccc" }}
                                            />
                                        </th>
                                    ) : null}
                                    <th align="right">
                                        <TextBox
                                            text={{
                                                sv: "pris",
                                                en: "price",
                                            }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ForEach
                                    itemOf={data.items}
                                    beginWith={
                                        <tr>
                                            <td
                                                colSpan={isDesktop ? 4 : 3}
                                                style={{ padding: 0 }}>
                                                <hr
                                                    css={{
                                                        marginTop: 3,
                                                        border: "none",
                                                        borderBottom:
                                                            "1px solid #eee",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    endWith={
                                        <tr>
                                            <td
                                                colSpan={isDesktop ? 4 : 3}
                                                style={{ padding: 0 }}>
                                                <hr
                                                    css={{
                                                        border: "none",
                                                        borderBottom:
                                                            "1px solid #eee",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    divider={
                                        <tr>
                                            <td
                                                colSpan={isDesktop ? 4 : 3}
                                                style={{ padding: 0 }}>
                                                <hr
                                                    css={{
                                                        border: "none",
                                                        borderBottom:
                                                            "1px solid #eee",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    }>
                                    {item => (
                                        <tr>
                                            <td>
                                                <Paragraph
                                                    content={[
                                                        ...(item.parentTitle
                                                            ? [
                                                                  paragraphTextFactory(
                                                                      {
                                                                          text: item.parentTitle,
                                                                          css: {
                                                                              textTransform:
                                                                                  "uppercase",
                                                                              fontSize:
                                                                                  "0.725em",
                                                                              fontWeight: 400,
                                                                              paddingBottom: 3,
                                                                              color: theme.colors.default.text.withAlpha(
                                                                                  0.3,
                                                                              )
                                                                                  .asString,
                                                                          },
                                                                      },
                                                                  ),
                                                                  paragraphLineBreakFactory(),
                                                              ]
                                                            : []),
                                                        ...(allItemsAdded
                                                            ? []
                                                            : [
                                                                  paragraphTextFactory(
                                                                      {
                                                                          text:
                                                                              item.type ===
                                                                              "ADDED"
                                                                                  ? "+"
                                                                                  : "-",
                                                                          css: {
                                                                              width: 18,
                                                                              display:
                                                                                  "inline-block",
                                                                              textAlign:
                                                                                  "center",
                                                                          },
                                                                      },
                                                                  ),
                                                              ]),
                                                        item.quantity.toLocaleString(),
                                                        " x ",
                                                        item.name,
                                                        ...(item.description
                                                            ? [
                                                                  paragraphLineBreakFactory(),
                                                                  paragraphTextFactory(
                                                                      {
                                                                          text: item.description,
                                                                          css: {
                                                                              fontSize:
                                                                                  "0.725em",
                                                                              fontWeight: 400,
                                                                              paddingBottom: 3,
                                                                              color: theme.colors.default.text.withAlpha(
                                                                                  0.3,
                                                                              )
                                                                                  .asString,
                                                                          },
                                                                      },
                                                                  ),
                                                              ]
                                                            : []),
                                                    ]}
                                                />
                                            </td>
                                            {isDesktop ? (
                                                <td align="right">
                                                    <PriceTextBox
                                                        price={
                                                            item.type ===
                                                            "ADDED"
                                                                ? item.price
                                                                : negatePrice(
                                                                      absPrice(
                                                                          item.price,
                                                                      ),
                                                                  )
                                                        }
                                                        valueToFormat="VAT_AMOUNT"
                                                        fractions="TWO"
                                                        css={{
                                                            color: "#ccc",
                                                        }}
                                                    />
                                                </td>
                                            ) : null}
                                            <td align="right">
                                                <PriceTextBox
                                                    price={
                                                        item.type === "ADDED"
                                                            ? item.price
                                                            : negatePrice(
                                                                  absPrice(
                                                                      item.price,
                                                                  ),
                                                              )
                                                    }
                                                    fractions="TWO"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </ForEach>
                            </tbody>
                        </table>
                    );
                }}
            </ForEach>
            <FlexRow mainAxis="space-between" padding={{ top: 10 }}>
                <TextBox
                    text={translation({
                        sv: "Totalt",
                        en: "Total",
                    })}
                    weight="700"
                />
                <HorizontalDivider L />
                <PriceTextBox price={price} weight="700" />
            </FlexRow>
            <VerticalDivider XXS />
            <FlexRow mainAxis="space-between">
                <TextBox
                    text={translation({
                        sv: "Varav moms",
                        en: "Of which VAT",
                    })}
                    size={1}
                    color={theme.colors.default.text.withAlpha(0.6).asString}
                />
                <HorizontalDivider L />
                <PriceTextBox
                    price={price}
                    valueToFormat="VAT_AMOUNT"
                    size={1}
                    color={theme.colors.default.text.withAlpha(0.6).asString}
                />
            </FlexRow>
            <VerticalDivider M />
            <VatTable price={price} />
        </FlexColumn>
    );
};

export { PayRequestItemPriceTable };

const VatTable = (props: { price: Price }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const price = props.price;
    const amountsByVatRate = sortAmountsByVatRate(price);
    const vatKeys = amountsByVatRate ? Object.keys(amountsByVatRate) : [];

    return (
        <table
            css={{
                flex: 1,
                padding: 0,
                margin: 0,
                borderSpacing: 0,
                color: theme.colors.black.light60.asString,
            }}>
            <tbody>
                <tr css={{ whiteSpace: "nowrap" }}>
                    <td>
                        <TextBox
                            text={{
                                sv: `Moms%`,
                                en: `VAT%`,
                            }}
                            size={0.65}
                            weight="700"
                            case="UPPERCASE"
                        />
                    </td>
                    <td>
                        <TextBox
                            padding={{
                                leftRight: 30,
                            }}
                            size={0.65}
                            weight="700"
                            case="UPPERCASE"
                            text={translation({
                                sv: "Moms",
                                en: "VAT",
                            })}
                        />
                    </td>
                    <td>
                        <TextBox
                            size={0.65}
                            weight="700"
                            case="UPPERCASE"
                            text={translation({
                                sv: "Netto",
                                en: "Net",
                            })}
                        />
                    </td>
                    <td width={"100%"} css={{ textAlign: "right" }}>
                        <TextBox
                            size={0.65}
                            weight="700"
                            case="UPPERCASE"
                            text={translation({
                                sv: "Summa",
                                en: "Sum",
                            })}
                        />
                    </td>
                </tr>
                <ForEach itemOf={vatKeys} getKey={vk => vk}>
                    {key => {
                        if (!amountsByVatRate) return null;
                        const amountAndRates = amountsByVatRate[key];
                        return (
                            <tr>
                                <td>
                                    <TextBox
                                        size={0.825}
                                        text={`${
                                            amountAndRates.vatRate * 100
                                        }%`}
                                    />
                                </td>
                                <td>
                                    <TextBox
                                        size={0.825}
                                        padding={{
                                            leftRight: 30,
                                        }}
                                        display="block"
                                        css={{
                                            whiteSpace: "nowrap",
                                        }}
                                        text={formatSEKNumber(
                                            amountAndRates.vatAmount,
                                            translate(
                                                translation({
                                                    sv: "kr",
                                                    en: "SEK",
                                                }),
                                            ),
                                        )}
                                    />
                                </td>
                                <td>
                                    <TextBox
                                        size={0.825}
                                        display="block"
                                        css={{
                                            whiteSpace: "nowrap",
                                        }}
                                        text={formatSEKNumber(
                                            amountAndRates.amountExcludingVat,
                                            translate(
                                                translation({
                                                    sv: "kr",
                                                    en: "SEK",
                                                }),
                                            ),
                                        )}
                                    />
                                </td>
                                <td
                                    width={"100%"}
                                    css={{
                                        textAlign: "right",
                                    }}>
                                    <TextBox
                                        size={0.825}
                                        display="block"
                                        css={{
                                            whiteSpace: "nowrap",
                                        }}
                                        text={formatSEKNumber(
                                            amountAndRates.amountIncludingVat,
                                            translate(
                                                translation({
                                                    sv: "kr",
                                                    en: "SEK",
                                                }),
                                            ),
                                        )}
                                    />
                                </td>
                            </tr>
                        );
                    }}
                </ForEach>
            </tbody>
        </table>
    );
};

export { VatTable };
