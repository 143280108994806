import { browserLogger } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { HeadTitle } from "@@/shared/head-title";
import { useDelay } from "@@/shared/pictures/use-wait-for-it";
import { Paragraph, ParagraphContent, TextBox } from "@@/shared/text";
import { MILLISECONDS, translation } from "@towni/common";
import { useLocation } from "react-router-dom";

const PageNotFound = (props: {
    reason?: ParagraphContent;
    delayInMs?: MILLISECONDS;
}) => {
    const [waitForIt] = useDelay(props.delayInMs ?? (300 as MILLISECONDS));
    const location = useLocation();
    browserLogger.log("page not found", location);
    if (waitForIt) return null;
    return (
        <>
            <GlobalMaxWidthCss />
            <HeadTitle title={["404 Finns inte"]} />
            <FlexColumn fillParent crossAxis="center">
                <VerticalDivider size="3XL" />
                <VerticalDivider size="XL" />

                <TextBox
                    text={translation({
                        sv: "404",
                        en: "404",
                    })}
                    weight="900"
                    size={3}
                    padding={{ all: 20 }}
                />
                {props.reason ? (
                    <>
                        <VerticalDivider size="XL" />
                        <Paragraph
                            content={props.reason}
                            css={{
                                fontWeight: "700",
                                fontSize: "1.5rem",
                                padding: 20,
                                userSelect: "text",
                                textAlign: "center",
                                lineHeight: 1.6,
                            }}
                        />
                    </>
                ) : null}
            </FlexColumn>
        </>
    );
};
export { PageNotFound };
