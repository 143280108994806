import { RemSize, SizeName, sizeNames } from "@towni/common";
import * as React from "react";
import { calculateDividerSize, getDividerSizeInPixels } from "./divider-size";

type HorizontalProps = { [key in SizeName]?: boolean } & {
    readonly size?: RemSize | SizeName;
    readonly shrink?: React.CSSProperties["flexShrink"];
    readonly className?: string;
};

const HorizontalDividerComponent = (props: HorizontalProps) => {
    const { size: sizeInput, ...rest } = props;
    const size =
        typeof sizeInput === "number"
            ? `${sizeInput}rem`
            : sizeInput && sizeNames.includes(sizeInput)
            ? `${getDividerSizeInPixels(sizeInput)}px`
            : `${calculateDividerSize(rest, 7)}px`;

    return (
        <div
            css={{
                height: 1,
                maxHeight: 1,
                minHeight: 1,
                flexShrink: props.shrink ?? 0,
                width: size,
                maxWidth: size,
                minWidth: size,
            }}
            className={props.className}
        />
    );
};

export { HorizontalDividerComponent as HorizontalDivider };
