import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import {
    BookableSession,
    emptyArrayOf,
    ProductId,
    TimeRange,
} from "@towni/common";
import { sessionMainQueryKey } from "../main-sessions-query-key";
import { fetchSessions, fetchSessionsForProducts } from "./sessions.fetcher";

const useSessions = (productId: ProductId | undefined, timeRange?: TimeRange) =>
    useApiResources<BookableSession>(
        createQueryKey(sessionMainQueryKey, [
            productId ?? "",
            ...(timeRange
                ? [
                      timeRange.start.unix.toString(),
                      timeRange.end.unix.toString(),
                  ]
                : []),
        ]),
        fetchSessions(productId, timeRange),
        {
            enabled: !!productId,
        }
    );

const useSessionsByProducts = (
    productIds: ProductId[],
    timeRange?: TimeRange
) => {
    const ids = productIds ?? emptyArrayOf<ProductId>();
    return useApiResources<BookableSession>(
        createQueryKey(sessionMainQueryKey, [
            ...ids,
            ...(timeRange
                ? [
                      timeRange.start.unix.toString(),
                      timeRange.end.unix.toString(),
                  ]
                : []),
        ]),
        fetchSessionsForProducts(ids, timeRange),
        {
            enabled: !!ids.length,
        }
    );
};

export { useSessions, useSessionsByProducts };
