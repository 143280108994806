import { create } from "zustand";

type BuildVersion = {
    buildId: string;
    buildTimestamp: string;
};
type State = {
    server: BuildVersion;
    client: BuildVersion;
    minorMismatch: boolean;
    majorMismatch: boolean;
};

type UpdateActionParams = {
    serverBuildId: string;
    serverBuildTimestamp: string;
    minorMismatch: boolean;
    majorMismatch: boolean;
};
type Actions = {
    update: (params: UpdateActionParams) => void;
};

type Context = State & Actions;

function updateBuildVersionAction(params: UpdateActionParams) {
    const state = useBuildVersionStore.getState();
    if (
        params.majorMismatch === state.majorMismatch &&
        params.minorMismatch === state.minorMismatch &&
        params.serverBuildId === state.server.buildId &&
        params.serverBuildTimestamp === state.server.buildTimestamp
    ) {
        return;
    }

    if (state.minorMismatch === true && params.minorMismatch === false) {
        return; // Ignore, can only be reset to false on reload of page
    }
    if (state.majorMismatch === true && params.majorMismatch === false) {
        return; // Ignore, can only be set to false on reload of page
    }

    useBuildVersionStore.setState(state => ({
        ...state,
        server: {
            buildId: params.serverBuildId,
            buildTimestamp: params.serverBuildTimestamp,
        },
        minorMismatch: params.minorMismatch,
        majorMismatch: params.majorMismatch,
    }));
}

const useBuildVersionStore = create<State>((): Context => {
    const clientBuildId = import.meta.env.VITE_BUILD_ID;
    const clientBuildTimestamp = import.meta.env.VITE_BUILD_TIMESTAMP;

    if (!clientBuildId) throw new Error("Missing client build id");
    if (!clientBuildTimestamp)
        throw new Error("Missing client build timestamp");

    const result = {
        server: {
            buildId: "unchecked",
            buildTimestamp: "unchecked",
        },
        client: {
            buildId: clientBuildId,
            buildTimestamp: clientBuildTimestamp,
        },
        minorMismatch: false,
        majorMismatch: false,
        update: updateBuildVersionAction,
    };
    return result;
});

export { updateBuildVersionAction, useBuildVersionStore };
