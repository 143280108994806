import { generateId, PhoneNumber } from "@towni/common";
import * as React from "react";
import { CaptchaContextProvider } from "./steps/captcha.context";

type SignInOrUpMode = "UNKNOWN" | "SIGN_UP" | "SIGN_IN";
const verificationCodeLength = 6;

const SIGN_UP_STATE = "SIGN_UP_STATE" as const;
type SIGN_UP_STATE = typeof SIGN_UP_STATE;

type Action =
    | { readonly type: "SET_PHONE_NUMBER"; readonly value: PhoneNumber }
    | {
          readonly type: "SET_UNVALIDATED_PHONE_NUMBER";
          readonly value: PhoneNumber;
      }
    | {
          readonly type: "SET_VERIFICATION_CODE";
          readonly value: number | undefined;
      }
    | { readonly type: "SET_MODE"; readonly value: SignInOrUpMode };

type State = {
    readonly _type: SIGN_UP_STATE;
    readonly data: {
        readonly phoneNumber: PhoneNumber;
        readonly unvalidatedPhoneNumber: PhoneNumber;
        readonly verificationCode?: number;
        readonly mode: SignInOrUpMode;
        readonly _id: string;
    };
};

const createInitialState = (initialData?: Partial<State["data"]>): State => ({
    _type: SIGN_UP_STATE,
    data: {
        phoneNumber: "" as PhoneNumber,
        unvalidatedPhoneNumber: "" as PhoneNumber,
        mode: "UNKNOWN",
        _id: generateId({ prefix: "sign_up_state__" }),
        ...initialData,
    },
});

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_MODE": {
            return {
                ...state,
                data: {
                    ...state.data,
                    mode: action.value,
                },
            };
        }
        case "SET_PHONE_NUMBER": {
            return {
                ...state,
                data: {
                    ...state.data,
                    phoneNumber: action.value,
                },
            };
        }
        case "SET_UNVALIDATED_PHONE_NUMBER": {
            return {
                ...state,
                data: {
                    ...state.data,
                    unvalidatedPhoneNumber: action.value,
                },
            };
        }
        case "SET_VERIFICATION_CODE": {
            return {
                ...state,
                data: {
                    ...state.data,
                    verificationCode: action.value,
                },
            };
        }
        default:
            return state;
    }
};

const PhoneSignInOrUpStateContext = React.createContext<State | undefined>(
    undefined,
);
const PhoneSignInOrUpDispatchContext = React.createContext<
    Dispatch | undefined
>(undefined);

const PhoneSignInOrUpDataProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [state, dispatch] = React.useReducer(reducer, createInitialState());

    return (
        <PhoneSignInOrUpStateContext.Provider value={state}>
            <PhoneSignInOrUpDispatchContext.Provider value={dispatch}>
                <CaptchaContextProvider>{children}</CaptchaContextProvider>
            </PhoneSignInOrUpDispatchContext.Provider>
        </PhoneSignInOrUpStateContext.Provider>
    );
};

const usePhoneSignInOrUpState = () => {
    const context = React.useContext(PhoneSignInOrUpStateContext);
    if (context === undefined) {
        throw new Error(
            "usePhoneSignInOrUpState must be used within a PhoneSignInOrUpProvider",
        );
    }
    return context;
};

const usePhoneSignInOrUpDispatch = () => {
    const dispatch = React.useContext(PhoneSignInOrUpDispatchContext);
    if (dispatch === undefined) {
        throw new Error(
            "usePhoneSignInOrUpDispatch must be used within a PhoneSignInProvider",
        );
    }

    const setPhoneSignInOrUpData = {
        setMode: (value: SignInOrUpMode) =>
            dispatch({ type: "SET_MODE", value }),
        setPhoneNumber: (value: PhoneNumber) =>
            dispatch({ type: "SET_PHONE_NUMBER", value }),
        setUnvalidatedPhoneNumber: (value: PhoneNumber) =>
            dispatch({ type: "SET_UNVALIDATED_PHONE_NUMBER", value }),
        setVerificationCode: (value: string) => {
            // Code is numeric and of a specific length
            // Make sure it all adds up
            let code = value.replace(/\D/g, "");
            if (code.length > verificationCodeLength) {
                code = code.substr(0, verificationCodeLength);
            }
            if (parseInt(code).toString().length === verificationCodeLength)
                return dispatch({
                    type: "SET_VERIFICATION_CODE",
                    value: parseInt(code),
                });

            return dispatch({
                type: "SET_VERIFICATION_CODE",
                value: undefined,
            });
        },
    };

    return [setPhoneSignInOrUpData] as const;
};

export {
    PhoneSignInOrUpDataProvider,
    usePhoneSignInOrUpDispatch,
    usePhoneSignInOrUpState,
};
export type { Action as PhoneSignInOrUpAction, State as PhoneSignInOrUpState };
