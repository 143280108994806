import { useCheckSessionBookableStatus } from "@@/shared/buttons/bookables/use-check-session-bookable-status";
import { RowSelectButton } from "@@/shared/buttons/list-select-button";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import {
    BookableSession,
    Price,
    RemSize,
    TimeRange,
    TimeRangeResourceAvailability,
    calculatePossibleSessionLengths,
    timeToString,
    translation,
} from "@towni/common";
import * as React from "react";
import { DurationPicker } from "./duration-picker";

type Props = {
    selected: boolean;
    session: BookableSession;
    onTimeRangeChange: (timeRange: TimeRange, session: BookableSession) => void;
    readonly checkAvailability: (
        timeRange: TimeRange,
    ) => TimeRangeResourceAvailability;
    currentPrice: (session: BookableSession) => Price;
    isLoading: boolean;
};

const BookableSessionSelectListRowPure = React.memo((props: Props) => {
    return <BookableSessionSelectListRow {...props} />;
});
BookableSessionSelectListRowPure.displayName =
    "BookableSessionSelectListRowPure";

const BookableSessionSelectListRow = ({
    selected,
    session,
    checkAvailability,
    onTimeRangeChange,
    isLoading,
    currentPrice,
}: Props) => {
    const theme = useTheme();
    const price = React.useMemo(() => {
        return currentPrice(session);
    }, [session, currentPrice]);
    const [defaultTimeRange, setDefaultTimeRange] = React.useState<
        TimeRange | undefined
    >();

    const check = useCheckSessionBookableStatus();
    const allPossible = React.useMemo(() => {
        const list = calculatePossibleSessionLengths(session);
        if (list.length > 0) setDefaultTimeRange(list[0]);
        return list;
    }, [session]);

    const isSessionBookableByEveryone = check(session._id);
    const availableStyling = css`
        border-color: ${selected
            ? theme.colors.primary.asString
            : theme.colors.default.border.asString};
        background: ${selected
            ? theme.colors.primary.main.value.withAlpha(0.1).asString
            : theme.isLightTheme
              ? theme.colors.white.asString
              : theme.colors.black.light05.asString};
        border-style: solid;
        border-width: 1.5px;
        border-radius: 10px;
        width: 100%;
    `;

    const fullStyling = css`
        color: ${theme.colors.black.light70.asString};
        background: ${theme.colors.textInput.background.asString};
        border-color: ${theme.colors.black.light70.asString};
        border-style: solid;
        border-width: 1.5px;
        border-radius: 10px;
        width: 100%;
    `;

    const styling =
        session.status.type === "ACTIVE" ? availableStyling : fullStyling;

    const bookButtons =
        selected && allPossible.length > 1 ? (
            <FlexRow fillParentWidth css={{ width: "70%" }}>
                <DurationPicker
                    checkAvailability={checkAvailability}
                    selectedSession={session}
                    onDurationChange={timeRange =>
                        onTimeRangeChange(timeRange, session)
                    }
                    ranges={allPossible}
                />
                {/* )} */}
            </FlexRow>
        ) : (
            <RowSelectButton
                selected={selected}
                dataTestId={`bookable-session-select-list-row__${session._id}`}
                onClick={() => {
                    if (defaultTimeRange) {
                        onTimeRangeChange(defaultTimeRange, session);
                        return;
                    }
                }}
                spin={isLoading}
            />
        );

    return (
        <>
            <FlexRow
                padding={{
                    leftRight: 20,
                    topBottom: 20,
                }}
                styling={styling}
                crossAxis="center"
                mainAxis="space-between"
                fillParentWidth>
                <FlexColumn>
                    {defaultTimeRange ? (
                        <>
                            <TextBox
                                size={1.125 as RemSize}
                                weight="700"
                                text={timeToString(defaultTimeRange, {
                                    onlyStartTime: allPossible.length > 1,
                                })}
                            />
                            <Conditional
                                when={session.status.type === "ACTIVE"}
                                else={() => <VerticalDivider size={"XS"} />}>
                                <FlexRow>
                                    <TextBox
                                        color={theme.colors.black.light65}
                                        size={1 as RemSize}
                                        text={translation({
                                            sv: "fr",
                                            en: "from",
                                        })}
                                    />
                                    <HorizontalDivider XXS />
                                    <PriceTextBox
                                        price={price}
                                        color={theme.colors.black.light65}
                                    />
                                </FlexRow>
                            </Conditional>
                        </>
                    ) : null}
                    <Conditional
                        when={
                            !isSessionBookableByEveryone.isSessionBookableByEveryone
                        }>
                        <VerticalDivider S />
                        <ForEach itemOf={isSessionBookableByEveryone.messages}>
                            {message => (
                                <TextBox
                                    size={0.9 as RemSize}
                                    color={theme.colors.warning}
                                    text={message}
                                />
                            )}
                        </ForEach>
                    </Conditional>
                </FlexColumn>
                {/* Actions: */}
                <Conditional
                    when={session.status.type === "ACTIVE"}
                    render={() => bookButtons}
                    else={() => (
                        <TextBox
                            text={translation({
                                sv: "Fullbokad",
                                en: "Fully booked",
                            })}
                        />
                    )}
                />
            </FlexRow>
            <VerticalDivider M />
        </>
    );
};

export { BookableSessionSelectListRow };
