import {
    VirtualList,
    VirtualRow,
    VirtualRowId,
    VirtualRowRenderer,
} from "@@/backoffice/shared/virtual-list";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { browserLogger } from "@@/settings/browser-logger";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text/text-box";
import { CheckBoxWithText, TextInput } from "@@/shared/text/text-input";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    ResourceMap,
    ResourceMapId,
    Translatable,
    generateId,
    remSize,
    translation,
} from "@towni/common";
import * as React from "react";
import { ResourceMapPickerRow } from "./resource-map-picker-row";

type Props = {
    modalId: ModalId;
    title?: Translatable;
    resourceMaps: ResourceMap[];
    onToggle: (resourceId: ResourceMapId) => void;
    selected: Set<ResourceMapId>;
};

const ResourceMapPickerModal = (props: Props) => {
    const theme = useTheme();
    const [listId] = React.useState(() => generateId({ length: 6 }));
    const translate = useTranslate();
    const [textFilter, setTextFilter] = React.useState("");
    const [selectedFilter, setSelectedFilter] = React.useState(false);
    const { title, resourceMaps, selected, onToggle, modalId } = props;
    const { hide } = useModalActions(props.modalId);
    const filteredResourceMaps = React.useMemo(() => {
        return resourceMaps.filter(resourceMap => {
            // Filter by text value
            const textFilterMatch =
                !textFilter ||
                translate(resourceMap.title)
                    .toLocaleLowerCase()
                    .includes(textFilter.toLocaleLowerCase());

            // Filter if selected
            const selectedFilterMatch =
                !selectedFilter || selected.has(resourceMap._id);

            // Hide all inactive resourceMaps; that are not already selected
            // eslint-disable-next-line no-constant-condition
            if (false) {
                // resource.status.type !== "ACTIVE" && !selectedFilterMatch) {
                return false;
            }

            // TODO! filter by provider names as well
            return textFilterMatch && selectedFilterMatch;
        });
    }, [textFilter, selectedFilter, selected, resourceMaps]);

    const listData: VirtualRow<
        "RESOURCE_ROW",
        {
            _type: "RESOURCE_ROW";
            resource: ResourceMap;
        }
    >[] = React.useMemo(() => {
        const items = filteredResourceMaps.map(resource => {
            return {
                _id: `${resource._id}` as VirtualRowId,
                data: {
                    resource,
                    _type: "RESOURCE_ROW" as const,
                },
            };
        });
        return items;
    }, [filteredResourceMaps]);

    const renderRow: VirtualRowRenderer<
        "RESOURCE_ROW",
        {
            _type: "RESOURCE_ROW";
            resource: ResourceMap;
        }
    > = params => {
        const { item, onHeightChange, isLast } = params;

        return (
            <ResourceMapPickerRow
                key={item._id}
                resourceMap={item.data.resource}
                onSizeChange={onHeightChange}
                toggle={onToggle}
                selected={selected.has(item.data.resource._id)}
                padding={{ top: 12, bottom: isLast ? 40 : 0 }}
            />
        );
    };

    return (
        <Modal modalId={modalId}>
            <FlexColumn
                fillParentWidth
                crossAxis="flex-start"
                background={{ color: theme.colors.default.background }}
                css={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <ModalHeader
                    modalId={modalId}
                    title={
                        title ??
                        translation({
                            sv: "Välj karta",
                            en: "Select map",
                        })
                    }
                    topRightButton={{
                        _type: "TOP_RIGHT_ACTION_TEXT",
                        text: translation({
                            sv: "Klar",
                            en: "Ready",
                        }),
                        action: () => {
                            hide();
                            setSelectedFilter(false);
                            setTextFilter("");
                        },
                    }}
                />
                <FlexColumn
                    fillParentWidth
                    background={{ color: theme.colors.primary.light }}
                    crossAxis="flex-start"
                    padding={{ leftRight: 20, top: 0 }}>
                    <VerticalDivider S />
                    <FlexRow fillParentWidth mainAxis="space-between">
                        <TextInput
                            type="text"
                            grow={1}
                            padding={{ leftRight: 15, topBottom: 12 }}
                            size={remSize(0.875)}
                            placeholder={translation({
                                sv: "Filtrera",
                                en: "Filter",
                            })}
                            initialValue={textFilter}
                            onChange={setTextFilter}
                            onValidationFailed={browserLogger.log}
                        />
                        <HorizontalDivider S />
                        <CheckBoxWithText
                            checked={selectedFilter}
                            text={translation({
                                sv: "Endast valda",
                                en: "Only selected",
                            })}
                            onChange={setSelectedFilter}
                        />
                    </FlexRow>
                    <VerticalDivider S />
                </FlexColumn>
                <FlexRow fillParentWidth mainAxis="stretch" crossAxis="stretch">
                    <FlexColumn
                        fillParentWidth
                        grow={1}
                        shrink={1}
                        height="60vh">
                        <Conditional when={!filteredResourceMaps.length}>
                            <FlexRow
                                padding={{ all: 40 }}
                                fillParentWidth
                                mainAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "(inga kartor)",
                                        en: "(no maps)",
                                    })}
                                    align="center"
                                    size={0.875}
                                    css={{
                                        width: "100%",
                                    }}
                                    color={theme.colors.disabled.text.value.withAlpha(
                                        0.5,
                                    )}
                                />
                            </FlexRow>
                        </Conditional>
                        <Conditional when={!!filteredResourceMaps.length}>
                            <VirtualList
                                items={listData}
                                listId={listId}
                                rowRenderer={renderRow}
                                whenNoContent={
                                    <TextBox
                                        text={translation({
                                            sv: "(ingen karta vald)",
                                            en: "(no map selected)",
                                        })}
                                        align="center"
                                        size={0.875}
                                        padding={{ all: 20 }}
                                        color={theme.colors.disabled.text.value.withAlpha(
                                            0.5,
                                        )}
                                    />
                                }
                            />
                        </Conditional>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        </Modal>
    );
};

export { ResourceMapPickerModal };
