import { css, SerializedStyles } from "@emotion/react";
import {
    blackColor,
    colorAsString,
    ColorItem,
    whiteColor,
} from "@towni/common";
import * as React from "react";

type ContentSize = "S" | "M" | "L";
type Props = {
    readonly size?: number;
    readonly onClick?: (event: React.MouseEvent) => void;
    readonly backgroundColor?: ColorItem;
    readonly textColor?: ColorItem;
    readonly children?: React.ReactNode;
    readonly contentSize?: ContentSize;
    readonly transparent?: boolean; // bad name
    readonly styling?: SerializedStyles;
    readonly className?: string;
};

const defaultSize = 25;

const Circle = (props: Props) => {
    const size = props.size ?? defaultSize;
    const transparent = props.transparent ?? false;
    const contentSize = props.contentSize ?? "M";
    const textColor = colorAsString(props.textColor ?? blackColor);
    const backgroundColor = colorAsString(props.backgroundColor ?? whiteColor);
    const onClick = props.onClick;
    const children = props.children;

    // const { size, children, onClick, transparent } = props;

    const contentRatio: { [key in ContentSize]: number } = {
        S: 0.7 / defaultSize,
        M: 0.9 / defaultSize,
        L: 1.2 / defaultSize,
    };
    const fontSize = contentRatio[contentSize] * size;

    const styles = css`
        width: ${size}px;
        height: ${size}px;
        border-radius: ${Math.round(size / 2)}px;

        background-color: ${backgroundColor};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${fontSize}rem;
        color: ${textColor};
        flex-grow: 0;
        flex-shrink: 0;
        ${onClick ? `cursor: pointer;` : ""}
        ${(transparent && `opacity: 0.6;`) || ""} :active {
            background-color: ${backgroundColor};
            color: ${textColor};
            ${(transparent && `opacity: 0.9;`) || ""}
        }
        ${props.styling ? props.styling : ""}
    `;
    return (
        <div onClick={onClick} css={styles} className={props.className}>
            {children}
        </div>
    );
};

export { Circle };

// TODO needs refactoring/simplification
