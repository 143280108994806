import { OrderGroup, ReceiptForCustomer, assertNever } from "@towni/common";
import { MutableRefObject, ReactInstance } from "react";
import { OnPremiseReceipt } from "./on-premise-receipt";
import { PayRequestReceipt } from "./pay-request-receipt";
import { PaymentReceipt } from "./payment-receipt";
import { RefundReceipt } from "./refund-receipt";

type Props = {
    orderGroup: OrderGroup;
    receipt: ReceiptForCustomer;
    ref?: MutableRefObject<ReactInstance>;
};
const Receipt = (props: Props) => {
    const { receipt, orderGroup } = props;
    // if (props.financialOrderInfo.current.amountUnsettled !== 0)
    //     return <UnsettledReceipt {...props} />;

    if ("verifiedByAccessKey" in orderGroup && orderGroup.verifiedByAccessKey) {
        return null;
    }

    switch (receipt.type) {
        case "WAIVED_PAYMENT":
        case "PAYMENT":
            return <PaymentReceipt orderGroup={orderGroup} receipt={receipt} />;
        case "PAY_REQUEST_PAYMENT":
            return (
                <PayRequestReceipt orderGroup={orderGroup} receipt={receipt} />
            );
        case "ON_PREMISE_PAYMENT":
            return (
                <OnPremiseReceipt orderGroup={orderGroup} receipt={receipt} />
            );
        case "REFUND":
            return <RefundReceipt receipt={receipt} orderGroup={orderGroup} />;
        default:
            assertNever(receipt, "Unknown receipt type");
    }
};

export { Receipt };
