import { Button_v2 } from "@@/shared/buttons_v2/button";
import { Link } from "@@/shared/link";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    FooterPageBlock,
    HeaderPageBlock_V2,
    SingleContentPageBlock,
} from "@towni/common";

const CallToActionButton = (props: {
    block: FooterPageBlock | HeaderPageBlock_V2 | SingleContentPageBlock;
    disabled?: boolean;
}) => {
    const theme = useTheme();
    const { block } = props;
    return (
        <Link
            to={block.ctaButtonUrl ?? ""}
            target="_blank"
            disabled={props.disabled}
            css={{
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "none",
                },
            }}>
            <Button_v2
                renderAs="div"
                radius={3000}
                padding={{
                    topBottom: 10,
                    leftRight: 20,
                }}
                disabled={props.disabled}
                backgroundColor={
                    block.ctaButtonBackgroundColor || theme.colors.primary.light
                }
                borderColor={
                    block.ctaButtonBorderColor || theme.colors.primary.light
                }>
                <TextBox
                    text={block.ctaButtonText}
                    color={block.ctaButtonTextColor ?? theme.colors.primary}
                />
            </Button_v2>
        </Link>
    );
};

export { CallToActionButton };
