import { ModalId } from "@@/modals/context/modal-id";
import { css, SerializedStyles, useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    createGeoLocationPoint,
    createMapUrl,
    emptyAddress,
    metaContentFactory,
    translation,
    UserAddress,
} from "@towni/common";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button } from "../../buttons";
import { VerticalDivider } from "../../dividers";
import { FlexColumn, FlexRow } from "../../flex-containers";
import { MapHomeIconSvg } from "../../map-home-icon-svg";
import { Spacer } from "../../spacer";
import { TextBox } from "../../text";
import { ModalTitle } from "../../text/modal-title";
import { TogglerButton } from "../../toggler-button";
import { useScript } from "../../use-script";

// Prefer adresses in lidköping area,
// but do not restrict to them
const lidkopingCoordinates = () =>
    new google.maps.LatLng(58.50675442781556, 13.156808797751646);

type Props = {
    readonly current: Partial<UserAddress>;
    readonly modalId: ModalId;
    readonly update: (address: Partial<UserAddress>) => void;
    readonly save: (address: UserAddress) => void;
    readonly isPreview?: boolean;
    readonly toggleFullscreen: () => void;
    readonly styling?: SerializedStyles;
    readonly goBack: () => void;
};

const AddressPickerManualMap: FC<Props> = props => {
    const theme = useTheme();
    const mapRef = useRef<HTMLDivElement>(null);
    // const [mapRefId] = useState(() => generateId({ prefix: "map_ref_id_" }));

    // Todo: load google maps api key from settings
    const googleMapsApiKey = "AIzaSyAzLb42Hir4zVAduo1UTK8j3v3TmVybyQw";
    const googleMapsScriptLoadStatus = useScript(
        `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    );

    const [map, setMap] = useState<google.maps.Map | null>(null);

    //const [marker, setMarker] = useState<google.maps.Marker | null>(null);

    const update = (latLng: readonly [number, number], mapUrl: string) => {
        props.update({
            ...(props.current ?? {}),
            address: {
                ...(props.current.address ?? emptyAddress),
                geoLocation: createGeoLocationPoint({
                    coordinates: [...latLng],
                    meta: metaContentFactory({
                        title: "",
                    }),
                }),
                mapUrls: [createMapUrl({ url: mapUrl })],
            },
        });
    };
    useLayoutEffect(() => {
        if (googleMapsScriptLoadStatus !== "READY" || !mapRef.current) return;

        const geoJSON = props.current?.address?.geoLocation?.location;
        // console.log("INIT MAP", {
        //     geoJSON,
        //     lidkopingCoordinates: lidkopingCoordinates(),
        // });
        const initCenter =
            geoJSON?.geometry.type === "Point"
                ? new google.maps.LatLng(
                      geoJSON.geometry.coordinates[0],
                      geoJSON.geometry.coordinates[1],
                  )
                : lidkopingCoordinates();

        const newMap = new google.maps.Map(mapRef.current, {
            zoom: 15,
            center: initCenter,
            mapTypeControl: false,
            panControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            mapTypeId: "roadmap",
            scrollwheel: !props.isPreview,
            mapTypeControlOptions: {
                mapTypeIds: ["roadmap", "hybrid"],
            },
            styles: [
                {
                    featureType: "administrative",
                    elementType: "geometry",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "poi",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "transit",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
            ],
        });

        //const newMarker = new google.maps.Marker({ map: newMap });
        //setMarker(newMarker);
        const initialCenter = newMap.getCenter();
        if (initialCenter) {
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${initialCenter?.toUrlValue()}`;
            update([initialCenter.lat(), initialCenter.lng()], mapUrl);
        }
        setMap(newMap);
    }, [googleMapsScriptLoadStatus]);
    useLayoutEffect(() => {
        if (!map) return;
        const listenerId = map.addListener("center_changed", () => {
            const center = map.getCenter();
            if (!center) return;
            const latitude = center.lat();
            const longitude = center.lng();
            const mapUrl =
                "https://www.google.com/maps/search/?api=1&query=" +
                center.toUrlValue();
            // setUrl(center.toUrlValue());

            const latLng =
                latitude && longitude
                    ? ([latitude, longitude] as const)
                    : undefined;
            if (latLng) {
                // console.log("MAP EVENT UPDATE", mapUrl);
                update(latLng, mapUrl);
                //  if (marker) {
                //      marker.setPosition(center);
                //  }
            }
        });
        return () => {
            google.maps.event.removeListener(listenerId);
        };
    }, [map]);

    useEffect(() => {
        map?.setOptions({ scrollwheel: !props.isPreview });
    }, [props.isPreview]);

    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                height: 100%;
            `}
            onClick={() => {
                if (!props.isPreview) return;
                props.toggleFullscreen();
            }}>
            <div
                ref={mapRef}
                css={css`
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    pointer-events: auto;
                `}
            />
            <FlexColumn
                fillParent
                mainAxis="space-between"
                overflowY="auto"
                background={{
                    linearGradient: {
                        _type: "LINEAR_GRADIENT",
                        steps: [
                            { percentage: 0, color: theme.colors.transparent },
                            {
                                percentage: 0.8,
                                color: theme.colors.transparent,
                            },
                            {
                                percentage: 1,
                                color: theme.colors.white,
                            },
                        ],
                    },
                }}
                styling={css`
                    position: relative;
                    z-index: 10;
                    top: -100%;
                    pointer-events: none;
                `}>
                <FlexRow
                    fillParentWidth
                    basis={1}
                    padding={{ all: 20 }}
                    crossAxis="flex-start"
                    mainAxis="space-between">
                    <Spacer basis={0.2} shrink={0}>
                        <FontAwesomeIcon
                            onClick={props.goBack}
                            icon={faChevronLeft}
                            style={{
                                fontSize: 20,
                                cursor: "pointer",
                                pointerEvents: "auto",
                            }}
                        />
                    </Spacer>
                    <FlexColumn basis={0.6} crossAxis="center">
                        <ModalTitle
                            text={translation({
                                sv: "Flytta kartan till din adress",
                                en: "Move the map to your address",
                            })}
                            styling={css`
                                ${props.isPreview ? "visibility: hidden;" : ""}
                            `}
                        />
                    </FlexColumn>
                    <Spacer basis={0.2} shrink={0} />
                </FlexRow>
                <FlexRow
                    fillParentWidth
                    grow={1}
                    basis={2}
                    mainAxis="center"
                    crossAxis="center">
                    <MapHomeIconSvg />
                </FlexRow>
                <FlexColumn
                    fillParentWidth
                    mainAxis="flex-end"
                    basis={1}
                    padding={{ all: 20 }}
                    styling={css`
                        ${props.isPreview ? "visibility: hidden;" : ""}
                    `}>
                    <FlexRow fillParentWidth mainAxis="flex-end">
                        <TogglerButton
                            options={["KARTVY", "SATELLITVY"]}
                            showButtonTextAs="CURRENT"
                            onToggle={selected => {
                                map?.setMapTypeId(
                                    selected === "SATELLITVY"
                                        ? "hybrid"
                                        : "roadmap",
                                );
                            }}
                        />
                    </FlexRow>
                    <VerticalDivider S />
                    <FlexRow fillParentWidth mainAxis="stretch">
                        <Button
                            fillWidth
                            onClick={props.toggleFullscreen}
                            styling={css`
                                pointer-events: auto;
                            `}>
                            <FlexRow crossAxis="center" mainAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "Välj plats",
                                        en: "Pick spot",
                                    })}
                                    color={theme.colors.primary.text}
                                />
                            </FlexRow>
                        </Button>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </div>
    );
};

export { AddressPickerManualMap };
