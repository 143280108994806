import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { ColorItem, MILLISECONDS, RemSize, SizeName } from "@towni/common";
import { Icon } from "../icons/icon";

type Props = {
    /**
     * defaults to 1rem
     */
    size?: number | SizeName;
    /**
     * defaults to black with 50% transparency
     */
    color?: ColorItem;
    /**
     * defaults to 100
     */
    delayInMs?: MILLISECONDS;
    opacity?: number;
};

const CircleSpinner = (props: Props) => {
    const theme = useTheme();
    const size =
        typeof props.size === "undefined"
            ? 1
            : typeof props.size === "number"
              ? props.size
              : theme.sizes.inRem[props.size];

    return (
        <Icon
            icon={faSpinnerThird}
            color={props.color ?? theme.colors.black.value.withAlpha(0.5)}
            size={size as RemSize}
            fixedWidth={true}
            delayRenderingInMs={props.delayInMs}
            spin
        />
    );
};

export { CircleSpinner };
export type { Props as CircleSpinnerProps };
