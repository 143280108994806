import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { useNavenColorContext } from "../naven-color-context";

const NavenLackoFourCanoe: FC = () => {
    const getProductId = useProductIdWithFallback();
    const _colors = useNavenColorContext();

    const cannoe1 = React.useMemo(
        () => getProductId("product__y0ZaBI4i_Ah9EPKZSRkZ" as ProductId),
        []
    );
    const cannoe2 = React.useMemo(
        () => getProductId("product__y7ZswEniD-wTBe3s5Hy5" as ProductId),
        []
    );
    const cannoe3 = React.useMemo(
        () => getProductId("product__NjOMJ_zSSNEEwJMakmVB" as ProductId),
        []
    );
    const cannoe4 = React.useMemo(
        () => getProductId("product__8dM2Nz66sBhqMugOTNFs" as ProductId),
        []
    );
    const productIds = [cannoe1, cannoe2, cannoe3, cannoe4];

    return (
        <SectionArea
            title={translation({
                sv: "Kajaker för alla – start Naven",
                en: "Canoe for everyone – start Naven",
            })}
            subtitle={translation({
                sv: "Vi har kajaker för alla! Från nybörjaren, till den som vill ut på lite äventyr med packning till er som vill ta med barnen. Starta på Naven här nedan.",
                en: "We have canoes for everyone! From the beginner, to those who want to go on an adventure with packing to those who want to take the kids. Start on Naven here below.",
            })}
            sectionAreaId="naven-lacko-sea-4-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id}
                            productId={id}
                            aspectRatio={"1:1"}
                            contentTop={index === 3 ? true : index % 2 === 0}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={_colors.primary}
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Boka nu",
        en: "Book",
    }),
    explore: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
    seeTime: translation({
        sv: "Se tider",
        en: "Book",
    }),
};

const actionTexts = [text.book, text.explore, text.seeTime];

export { NavenLackoFourCanoe };
