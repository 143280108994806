import { pageTransition } from "@@/pages/page-navigation/page-animation-slide-and-fade";
import { MotionValue, useSpring } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

const getViewHeight = () => {
    if (typeof window === "undefined")
        throw new Error("window cannot be undefined to use this hook");
    return Math.max(
        document.documentElement?.clientHeight ?? 0,
        window.innerHeight ?? 0,
    );
};
const use100vh = (): number => {
    const [height, setHeight] = useState(getViewHeight);

    const calculateHeight = useCallback(() => {
        const newHeight = getViewHeight();
        if (height !== newHeight) setHeight(newHeight);
    }, [height]);

    useEffect(() => {
        window.addEventListener("resize", calculateHeight);
        return () => window.removeEventListener("resize", calculateHeight);
    }, [calculateHeight, height]);

    return height;
};

const use100vhMotionValue = (): MotionValue => {
    const height = useSpring(getViewHeight(), pageTransition);

    const updateHeight = useCallback(() => {
        const newHeight = getViewHeight();
        if (height.get() !== newHeight) height.set(newHeight);
    }, [height]);

    useEffect(() => {
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, [updateHeight, height]);

    return height;
};

export { getViewHeight, use100vh, use100vhMotionValue };
