import {
    BOX_TEXT,
    CARD_LANDSCAPE,
    CARD_PORTRAIT,
    CARD_SQUARE,
    CARD_TEXT,
    Product,
    ROW,
    SectionTheme,
} from "@towni/common";
import * as React from "react";
import { SectionItemBox } from "./box/section-content-item-box";
import { SectionItemCard } from "./card/section-content-item-card";
import { SectionItemRow } from "./row/section-content-item-row";
import { SectionItemTextCard } from "./text-card/section-content-item-text-card";

type Props = {
    item: Product;
    sectionTheme: SectionTheme;
    onClick: () => void;
};

const SectionItem = (props: Props) => {
    let Output: (props: Props) => JSX.Element | null;
    const itemDisplayType = props.sectionTheme.itemDisplayType;
    switch (itemDisplayType) {
        case BOX_TEXT:
            Output = SectionItemBox;
            break;
        case CARD_PORTRAIT:
        case CARD_LANDSCAPE:
        case CARD_SQUARE:
            Output = SectionItemCard;
            break;
        case CARD_TEXT:
            Output = SectionItemTextCard;
            break;
        case ROW:
            Output = SectionItemRow;
            break;
        default:
            throw new Error("Unknown item display type " + itemDisplayType);
    }
    return <Output {...props} />;
};

export type { Props as SectionItemProps };
export { SectionItem };
