import { useNavigate } from "react-router-dom";
import { useMountEffect } from "./use-mount-effect";

const Redirect = (props: { to: string }) => {
    const navigate = useNavigate();
    useMountEffect(() => {
        navigate(props.to);
    });
    return null;
};

export { Redirect };
