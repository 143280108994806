import { useTimeSpotFormatter } from "@@/carts/deliverypicker/delivery-method-text";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    Order,
    Provider,
    TranslatableValue,
    asDate,
    createTimeSpot,
    getChildOptionals,
    isProductOrderItem,
    translation,
} from "@towni/common";
import * as React from "react";
import { OrderRow } from "./order-home-delivery-receipt";

type Props = {
    provider: Provider;
    order: Order; // switch to order later
};

type OrderProps = {
    order: Order; // switch to order later
};

const OrderPickUpReceipt = ({ order, provider }: Props) => {
    const theme = useTheme();
    const timespotFormatter = useTimeSpotFormatter();

    const gray = theme.colors.black.light60;

    const PrepTimePendingConfirmation = () => {
        return (
            <>
                <TextBox
                    text={translation({
                        en: "Calculating:",
                        sv: "Bekräftas:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={translation({
                        en: `Awaiting confirmation from ${provider.meta.title}.`,
                        sv: `Inväntar bekräftelse från ${provider.meta.title}.`,
                    })}
                    color={theme.colors.black.light60}
                />
            </>
        );
    };

    const PrepTimeConfirmed = ({ order }: OrderProps) => {
        const [prepTimeText, setPrepTimeText] =
            React.useState<TranslatableValue>();

        React.useEffect(() => {
            if (order.delivery.estimatedDeliveryTime) {
                const prepTime = timespotFormatter({
                    prepTime: order.prepTime,
                    selected: createTimeSpot(
                        asDate(order.delivery.estimatedDeliveryTime),
                    ),
                    prefix: translation({ sv: "beräknas klar", en: "ready" }),
                });
                setPrepTimeText(prepTime);
            }
        }, [order]);
        return (
            <>
                <TextBox
                    text={translation({
                        en: "Pick up:",
                        sv: "Hämtas upp:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={prepTimeText}
                    color={theme.colors.black.light60}
                />
            </>
        );
    };
    const PrepTimeReadyForPickup = () => {
        return (
            <>
                <TextBox
                    text={translation({
                        en: "Ready:",
                        sv: "Färdig:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={translation({
                        en: "Your Order is ready!",
                        sv: "Din order kan hämtas upp!",
                    })}
                    color={theme.colors.black.light60}
                />
            </>
        );
    };

    const PrepTimeFullfilled = () => {
        return (
            <TextBox
                text={translation({
                    en: "Hope your happy with your order!",
                    sv: "Syns igen! Hoppas du är nöjd med din order!",
                })}
                color={theme.colors.black.light60}
            />
        );
    };

    const prepTimeComponent = ((order: Order) => {
        if (order.delivery.deliveryMethodType === "DIGITAL") return null;
        switch (order.status.type) {
            case "PENDING_CONFIRMATION":
                return <PrepTimePendingConfirmation />;
            case "CONFIRMED":
                return <PrepTimeConfirmed order={order} />;
            case "READY_FOR_PICK_UP":
                return <PrepTimeReadyForPickup />;
            case "FULFILLED":
                return <PrepTimeFullfilled />;
        }
        return null;
    })(order);

    return (
        <FlexColumn
            fillParentWidth
            background={{ color: theme.colors.white }}
            width="80%">
            <Conditional when={prepTimeComponent !== null}>
                <VerticalDivider size={1} />
                <>
                    <FlexRow padding={{ leftRight: 20 }} wrap={"wrap"}>
                        {prepTimeComponent}
                    </FlexRow>
                    <VerticalDivider size={1} />
                    <HorizontalLine />
                    <VerticalDivider size={1} />
                </>
                {order.delivery.deliveryMethodType === "PICK_UP" ? (
                    <FlexRow padding={{ leftRight: 20 }} wrap="wrap">
                        <TextBox
                            text={translation({
                                sv: "Plats:",
                                en: "Place:",
                            })}
                            weight="700"
                        />
                        <HorizontalDivider />
                        <TextBox
                            text={order.delivery.pickUpAddress.addressRows[0]}
                            color={gray}
                        />
                    </FlexRow>
                ) : null}
                <VerticalDivider size={1} />
            </Conditional>
            <HorizontalLine />
            <VerticalDivider size={1} />
            {order.delivery.deliveryMethodType === "PICK_UP" &&
                order.delivery.pickUpInstructions && (
                    <>
                        <FlexColumn padding={{ leftRight: 20 }}>
                            <TextBox
                                text={translation({
                                    sv: "Instruktioner:",
                                    en: "Instructions:",
                                })}
                                weight="700"
                            />
                            <VerticalDivider XS />
                            <TextBox
                                text={order.delivery.pickUpInstructions}
                                color={gray}
                            />
                        </FlexColumn>
                        <VerticalDivider size={1} />
                        <HorizontalLine />
                        <VerticalDivider size={1} />
                    </>
                )}
            {order.messageToProvider && (
                <>
                    <FlexColumn padding={{ leftRight: 20 }}>
                        <TextBox
                            text={translation({
                                sv: "Notis",
                                en: "Note",
                            })}
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox text={order.messageToProvider} color={gray} />
                    </FlexColumn>
                    <VerticalDivider size={1} />
                    <HorizontalLine />
                    <VerticalDivider size={1} />
                </>
            )}
            <Conditional when={!!order.orderItems.length}>
                <ForEach
                    itemOf={order.orderItems.filter(isProductOrderItem)}
                    getKey={item => item._id}
                    endWith={<VerticalDivider size={1.5} />}
                    divider={
                        <FlexColumn crossAxis="stretch">
                            <VerticalDivider size={1} />
                            <HorizontalLine />
                            <VerticalDivider size={1} />
                        </FlexColumn>
                    }>
                    {item => {
                        return (
                            <OrderRow
                                key={item._id}
                                item={item}
                                hidePrice
                                optionals={getChildOptionals(
                                    item,
                                    order.orderItems,
                                )}
                            />
                        );
                    }}
                </ForEach>
            </Conditional>
        </FlexColumn>
    );
};

export { OrderPickUpReceipt };
