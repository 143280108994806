import { css, SerializedStyles, useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    colorAsString,
    gradientToCssValue,
    LinearGradient,
    LinearGradientSimple,
    whiteColor,
} from "@towni/common";
import { useCallback } from "react";
import { useImageUrl } from "../image-url";

const linearGradientCss = (
    gradient?: LinearGradientSimple | LinearGradient,
) => {
    if (typeof gradient === "undefined") return "";
    return `background: ${gradientToCssValue(gradient)};`;
};

const useBackgroundStyleToCss = () => {
    const theme = useTheme();
    const getImageUrl = useImageUrl();

    const backgroundStyleToCss = useCallback(
        (
            background: BackgroundStyle,
            fallbackColor = whiteColor,
        ): SerializedStyles => {
            const backgroundUrl = background.imageUrl
                ? background.imageUrl
                : background.imageReference
                  ? getImageUrl(background.imageReference, {
                        format: "jpg",
                        ...(background.imageSize
                            ? { ...background.imageSize }
                            : {}),
                    })
                  : undefined;

            const result = css([
                {
                    backgroundColor: colorAsString(
                        background.color ?? fallbackColor,
                    ),
                    backgroundImage: backgroundUrl
                        ? `url(${backgroundUrl})`
                        : undefined,
                    backgroundSize: background.fit?.toLowerCase() || "unset",
                    backgroundPosition:
                        background.fit?.toLowerCase().trim() === "cover"
                            ? "50% 50%"
                            : "unset",
                    backgroundRepeat:
                        background.repeat?.toLowerCase() || "unset",
                    borderRadius: background.borderRadius,
                },
                linearGradientCss(background.linearGradient),
            ]);

            return result;
        },
        [theme],
    );
    return backgroundStyleToCss;
};

export { useBackgroundStyleToCss };
