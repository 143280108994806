import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { ContentBlock } from "@towni/common";

type Props = {
    contentBlock: ContentBlock;
    index: number;
};

const ContentBlockView = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const link = translate(props.contentBlock.link);
    return (
        <FlexRow>
            <FlexColumn width="100%">
                <FlexRow>
                    <TextBox
                        text={props.contentBlock.title}
                        userSelect="text"
                        dataTestId={`content_block_title_${props.index}`}
                        size={"L"}
                        weight={"700"}
                    />
                </FlexRow>
                <VerticalDivider S />
                <TextBox
                    renderMarkdown
                    text={props.contentBlock.content}
                    dataTestId={`content_block_text_content_${props.index}`}
                    userSelect="text"
                    size="S"
                    weight="400"
                    color={
                        theme.isLightTheme
                            ? theme.colors.black.light20
                            : theme.colors.black.light80
                    }
                />
            </FlexColumn>
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                data-testid={`content_block_link_${props.index}`}
                css={{
                    color: theme.colors.default.text.asString,
                }}>
                <Icon
                    icon={faChevronRight}
                    style={{
                        fontSize: theme.sizes.inPixels.ML,
                        visibility: link ? "visible" : "hidden",
                    }}
                />
            </a>
        </FlexRow>
    );
};

export { ContentBlockView };
