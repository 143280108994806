import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { useOptionalGroups } from "@@/optional-groups/state/optional-group-fetchers";
import { AppPage } from "@@/pages/app-page";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { AddToCartWithQuantity } from "@@/shared/buttons";
import { BackButton, HistoryBackButton } from "@@/shared/buttons/back-button";
import { Conditional } from "@@/shared/conditional";
import { HeadTitle } from "@@/shared/head-title";
import { isRunningAsPwa } from "@@/shared/is-running-as-pwa";
import { useNavigateTo } from "@@/shared/text/navigate-to";
import {
    BookableOptionId,
    ExtrasGroup,
    MinMaxRange,
    OrderItemId,
    Product,
    Provider,
    isAlfredItemActive,
    providerIdZodSchema,
    translation,
} from "@towni/common";
import { AnimatePresence } from "framer-motion";
import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { BuyableDetails } from "./buyable-details";
import { ProductDetailsContextProvider } from "./product-details-context";

type ExtrasProps = {
    readonly extras: ExtrasGroup;
    readonly parentOrderItemId?: OrderItemId;
    readonly bookableOptionId?: BookableOptionId;
    readonly disabled: boolean;
    readonly pickedAmount: number;
    readonly minMax: MinMaxRange;
};

const ProductDetailsPage = (props: {
    provider: Provider;
    product: Product;
    hideLinkToShop: boolean;
    pageAnimationDisabled?: boolean;
    modalMode: boolean; //Controls goback logic
    goBack?: () => void;
    extras?: ExtrasProps;
}) => {
    const { provider, product } = props;
    const { pathname } = useLocation();
    const navigateTo = useNavigateTo();
    const [optionalGroups] = useOptionalGroups(product.optionalGroupIds);
    // const [addToCartButtonsHidden, setAddToCartButtonsHidden] = useState(false);
    const [searchParams] = useSearchParams();

    const referrer = searchParams.get("ref");
    const referredFromProviderId = providerIdZodSchema.safeParse(
        searchParams.get("refPID"),
    );

    const providerHomePath = useHomePath(provider);
    const afterAddToCartPath = useMemo(() => {
        if (
            referredFromProviderId.success &&
            referredFromProviderId.data !== provider._id
        ) {
            // Referred from another providers page, or from unknown
            // Try to find the products providers default page
            return providerHomePath + "#goback";
        }
        if (referrer) {
            // Referred from another page
            return referrer;
        }

        return providerHomePath + "#goback";

        // return (provider?.slug ? `/shop/${provider.slug}` : "/") + "#goback";
    }, [provider, providerHomePath, referrer, referredFromProviderId]);

    const goBackPath = (referrer ? referrer : providerHomePath) + "#goback";

    const goBack = () => {
        // setAddToCartButtonsHidden(true);
        if (props.goBack) {
            props.goBack();
            return;
        }

        navigateTo(goBackPath);
    };

    const afterAddNavigation = () => {
        // setAddToCartButtonsHidden(true);
        if (props.goBack) {
            // e.g. used in booking wizard
            // to close modal instead of navigating to another page
            props.goBack();
            return;
        }
        navigateTo(afterAddToCartPath);
    };

    return (
        <ProductDetailsContextProvider
            product={product}
            provider={provider}
            extras={props.extras}>
            <HeadTitle
                title={[
                    translation({
                        sv: "Produkt",
                        en: "Product",
                    }),
                    product?.meta?.title,
                    provider?.meta?.title ?? provider.slug,
                ]}
            />
            <AppPage
                pageId={appPageIdFactory(pathname)}
                isLoading={!provider}
                anchors={[]}
                pageAnimationDisabled={props.pageAnimationDisabled}
                forgetScrollPosition={true}
                backButton={
                    <Conditional
                        when={props.modalMode}
                        render={() => <BackButton onClick={goBack} />}
                        else={() => (
                            <HistoryBackButton
                                fallbackButton={<BackButton onClick={goBack} />}
                            />
                        )}
                    />
                }>
                <BuyableDetails
                    provider={provider}
                    product={product}
                    optionalGroups={optionalGroups}
                    hideLinkToShop={props.hideLinkToShop}
                />
            </AppPage>
            <AnimatePresence>
                <Conditional
                    when={
                        // !addToCartButtonsHidden &&
                        isAlfredItemActive(product?.status)
                    }
                    render={() => (
                        <AddToCartWithQuantity
                            afterAddNavigation={afterAddNavigation}
                            clearBeforeAdding={false}
                            pickLimit={
                                props.extras
                                    ? {
                                          minMax: props.extras.minMax,
                                          alreadyPicked:
                                              props.extras.pickedAmount,
                                      }
                                    : undefined
                            }
                            padding={
                                isRunningAsPwa
                                    ? { top: 15, bottom: 25, leftRight: 20 }
                                    : { all: 15 }
                            }
                        />
                    )}
                />
            </AnimatePresence>
        </ProductDetailsContextProvider>
    );
};

export { ProductDetailsPage };
export type { ExtrasProps };
export default ProductDetailsPage;
