import { css, SerializedStyles, useTheme } from "@emotion/react";
import { blackColor, ColorItem, SizeName, Translatable } from "@towni/common";
import { MergeExclusive } from "type-fest";
import { FlexRow } from "./flex-containers";
import { TextBox } from "./text/text-box";

type LabelColorTheme = "RED" | "YELLOW" | "WHITE" | "PRIMARY" | "";
type LabelColors = {
    text: ColorItem;
    background: ColorItem;
};
type Props = {
    /**
     * Rem size (defaults to 0.6)
     * @type {number}
     */
    size?: number | SizeName;
    text: Translatable;
    styles?: SerializedStyles;
    className?: string;
} & MergeExclusive<
    {
        colors: LabelColors;
    },
    {
        colorTheme: LabelColorTheme;
    }
>;

// const redColor = colorFactory({ hue: 359, saturation: 0.86, light: 0.56 });
// const yellowBackgroundColor = colorFactory({
//     hue: 58,
//     saturation: 1,
//     light: 0.75,
// });
const useLabelColorTheme = (
    colorTheme?: LabelColorTheme
): {
    text: ColorItem;
    background: ColorItem;
} => {
    const theme = useTheme();
    // todo: future, maybe use color from picked theme as basis?
    // todo: make it themeable
    // eslint-disable-next-line prefer-const
    const lightTheme = theme.isLightTheme;
    switch (colorTheme) {
        case "RED":
            return {
                text: lightTheme
                    ? theme.colors.danger.asString
                    : theme.colors.danger.text.asString,
                background: lightTheme
                    ? theme.colors.danger.light.asString
                    : theme.colors.danger.asString,
            };
        case "YELLOW":
            return {
                text: theme.colors.tertiary.text.asString,
                background: theme.colors.tertiary.asString,
            };
        case "PRIMARY":
            return {
                text: lightTheme ? blackColor.light20 : blackColor.light80,
                background: lightTheme
                    ? theme.colors.primary.light.asString
                    : theme.colors.primary.dark.asString,
            };
        default:
            return {
                text: blackColor.light50,
                background: lightTheme
                    ? theme.colors.white
                    : theme.colors.black.light05,
            };
    }
};

const Label = (props: Props) => {
    const colorThemeColors = useLabelColorTheme(props.colorTheme);
    const colors = props.colors ?? colorThemeColors;

    return (
        <FlexRow
            mainAxis="center"
            crossAxis="center"
            background={{
                color: colors.background,
            }}
            className={props.className}
            css={[{ borderRadius: 3 }, props.styles]}
            padding={{
                leftRight: 7,
                topBottom: 3,
            }}>
            <TextBox
                text={props.text}
                size={props.size || 0.6}
                weight="700"
                styling={css`
                    text-transform: uppercase;
                `}
                color={colors.text}
            />
        </FlexRow>
    );
};

export { Label };
