import { useResourceMapsQuery } from "@@/backoffice/for-providers/inventory/use-resource-map-query";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { ResourceMapPicker } from "@@/shared/resource-maps/picker/resource-map-picker";
import { ResourceMap, ResourceMapId, emptyArrayOf } from "@towni/common";
import { Draft } from "immer";
import { useState } from "react";

type Value = ResourceMapId[] | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
};

const Form2ResourceMapPicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const provider = useProviderFromContext();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: formId,
    });
    const [_initialValue] = useState(field.value);
    const resourceMapsQuery = useResourceMapsQuery(provider._id);

    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
    });

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            fillParentWidth
            className={props.className}>
            <ResourceMapPicker
                key={resourceMapsQuery.isPending ? "pending" : "ready"}
                singleOnly
                required={field.isRequired}
                initiallySelected={
                    field.value?.[0] ? new Set([field.value[0]]) : new Set()
                }
                resourceMaps={
                    resourceMapsQuery.data ?? emptyArrayOf<ResourceMap>()
                }
                spin={resourceMapsQuery.isPending}
                onChange={newValue => {
                    field.setValue(newValue);
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2ResourceMapPicker };
