import { toObjectMap } from "@towni/common";

const breakpointSizes = [
    "XS",
    "S",
    "MS",
    "M",
    "SMALL_SCREEN",
    "L",
    "MOBILE",
    "DESKTOP",
    "📱",
    "🖥",
] as const;
type BreakpointSize = (typeof breakpointSizes)[number];
const breakPointSize = toObjectMap<BreakpointSize>([...breakpointSizes]);

export { breakPointSize, breakpointSizes };
export type { BreakpointSize };
