import { css, useTheme } from "@emotion/react";
import { ColorItem, Padding, colorAsString } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { FlexRow } from "./flex-containers";
import { paddingToCssValue } from "./padding";

type Props = {
    readonly onClick?: (mouseEvent: React.MouseEvent) => void;
    readonly backgroundColor?: ColorItem;
    readonly borderColor?: ColorItem;
    /**
     * defaults to topBottom 5, leftRight 15
     */
    readonly padding?: Padding;
    readonly cursor?: React.CSSProperties["cursor"];
    readonly className?: string;
    readonly children?: React.ReactNode;
};

/** @deprecated You probably want a button with borderRadius set to 3000 instead */
const Pillify: FC<Props> = props => {
    const theme = useTheme();
    const padding: Padding = props.padding ?? {
        topBottom: 5,
        leftRight: 15,
    };
    return (
        <FlexRow shrink={0} onClick={props.onClick}>
            <div
                className={props.className}
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 2000px;
                    cursor: ${props.cursor ?? "unset"};
                    border: 1px solid
                        ${colorAsString(
                            props.borderColor ?? theme.colors.black.light90,
                        )};
                    padding: ${paddingToCssValue(padding)};
                    background-color: ${colorAsString(
                        props.backgroundColor ??
                            theme.colors.default.background,
                    )};
                `}>
                {props.children}
            </div>
        </FlexRow>
    );
};

export { Pillify };
