import { useCallback } from "react";
import {
    Navigate,
    NavigateFunction,
    NavigateOptions,
    NavigateProps,
    To,
    useNavigate,
} from "react-router-dom";

/**
 * Allows external links as well as internal app navigation
 * @return {*}  {NavigateFunction}
 */
const useNavigateTo = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        ((to: To | number, options?: NavigateOptions & { target?: string }) => {
            if (typeof to === "number") {
                navigate(to);
                return;
            }
            if (typeof to === "string") {
                if (to.startsWith("http")) {
                    if (options?.replace) window.location.replace(to);
                    else
                        options?.target
                            ? window.open(to, options.target)
                            : (window.location.href = to);
                    return;
                }
            }

            navigate(to, options);
            return;
        }) satisfies NavigateFunction,
        [navigate],
    );
};

const NavigateTo = (props: NavigateProps & { target?: string }) => {
    if (typeof props.to === "string") {
        if (props.to.startsWith("http")) {
            if (props.replace) window.location.replace(props.to);
            else
                props.target
                    ? window.open(props.to, props.target)
                    : (window.location.href = props.to);
            return null;
        }
    }
    return <Navigate {...props} />;
};

export { NavigateTo, useNavigateTo };
