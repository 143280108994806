const moveWithinArrayImmutable = <T>(
    array: T[],
    fromIndex: number,
    toIndex: number
) => {
    array = [...array];
    moveWithinArrayMutable(array, fromIndex, toIndex);
    return array;
};

const moveWithinArrayMutable = <T>(
    array: T[],
    fromIndex: number,
    toIndex: number
) => {
    // where from?
    const from = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    // validate that there is a value at given position...
    if (from < 0) return;
    if (from > array.length) return;

    // where to?
    const to = toIndex < 0 ? array.length + toIndex : toIndex;

    // find item at from position
    const [item] = array.splice(from, 1);

    // insert item at position of value of `to`
    array.splice(to, 0, item);
};

export { moveWithinArrayMutable };
export { moveWithinArrayImmutable };
