import { Form } from "@@/shared/form/form.zustand-store";
import { Form2ResourcePickerSingleProps } from "@@/shared/form/form2-resource-picker-single";
import { PaddingProps } from "@@/shared/padding";
import { ResourceId, Translatable, resourceIdZodSchema } from "@towni/common";
import { ReactNode } from "react";
import { Except } from "type-fest";
import { z } from "zod";

const stateZodObject = z.object({
    value: z.string().optional(),
});
type State = z.infer<typeof stateZodObject>;

type Props = {
    readonly initialValue?: ResourceId;
    readonly onChange: (resourceId: ResourceId | undefined) => void;
    readonly children?: ReactNode;
    readonly label?: Translatable;
    readonly padding?: PaddingProps;
} & Except<
    Form2ResourcePickerSingleProps<State>,
    "fieldId" | "getter" | "setter"
>;

const ResourcePickerSingle = (props: Props) => {
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        resourceId: resourceIdZodSchema.optional(),
                    }),
                    initialState: {
                        resourceId: props.initialValue,
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.ResourcePickerSingle
                    {...props}
                    fieldId="textInput"
                    getter={data => data.resourceId}
                    setter={(data, newValue) => {
                        data.resourceId = newValue;
                        props.onChange(newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { ResourcePickerSingle };
