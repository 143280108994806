import { useTheme } from "@emotion/react";
import { Except } from "type-fest";
import type { ButtonColorPropNames, ButtonProps_v2 } from "./button";
import { Button_v2 } from "./button";
type CustomizedButtonProps = Except<ButtonProps_v2, ButtonColorPropNames>;

const ButtonGray = (props: CustomizedButtonProps) => {
    const theme = useTheme();
    return (
        <Button_v2
            {...props}
            textColor={theme.colors.black.light30}
            backgroundColor={theme.colors.black.light90}>
            {props.children}
        </Button_v2>
    );
};
const ButtonLightGrayBlack = (props: CustomizedButtonProps) => {
    const theme = useTheme();
    return (
        <Button_v2
            {...props}
            textColor={theme.colors.black}
            backgroundColor={theme.colors.black.light95}>
            {props.children}
        </Button_v2>
    );
};

const ButtonWhite = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.black.light40}
            backgroundColor={theme.colors.white.value}
            borderColor={theme.colors.white.light80}
            {...props}>
            {props.children}
        </Button_v2>
    );
};

const ButtonTransparent = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.black.light40}
            backgroundColor={theme.colors.transparent.value}
            borderColor={theme.colors.transparent.value}
            disabledColorSet={{
                border: theme.colors.transparent,
                main: theme.colors.transparent,
                text: theme.colors.lightGray.main,
            }}
            {...props}>
            {props.children}
        </Button_v2>
    );
};
const ButtonTransparentWithBorder = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.black.light50}
            backgroundColor={theme.colors.transparent}
            borderColor={theme.colors.black.light50}
            {...props}>
            {props.children}
        </Button_v2>
    );
};

const ButtonWhiteBlack = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.black}
            backgroundColor={theme.colors.white.value}
            borderColor={theme.colors.black}
            {...props}>
            {props.children}
        </Button_v2>
    );
};
const ButtonWhitePrimary = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.primary}
            backgroundColor={theme.colors.white}
            borderColor={theme.colors.white}
            {...props}>
            {props.children}
        </Button_v2>
    );
};

const ButtonBlack = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.white.light40}
            backgroundColor={theme.colors.black.value}
            borderColor={theme.colors.black.light80}
            {...props}>
            {props.children}
        </Button_v2>
    );
};
const ButtonDarkGray = (
    props: CustomizedButtonProps & Pick<ButtonProps_v2, "textColor">,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            textColor={theme.colors.white.light70}
            backgroundColor={theme.colors.black.light30}
            borderColor={theme.colors.black.light30}
            {...props}>
            {props.children}
        </Button_v2>
    );
};

const ButtonYellow = (props: CustomizedButtonProps) => {
    const theme = useTheme();
    return (
        <Button_v2
            {...props}
            textColor={theme.colors.black.light40}
            backgroundColor={theme.colors.tertiary.light}
            borderColor={theme.colors.orange}>
            {props.children}
        </Button_v2>
    );
};

const ButtonWarning = (props: CustomizedButtonProps) => {
    const theme = useTheme();
    return (
        <Button_v2
            {...props}
            textColor={theme.colors.black.light20}
            backgroundColor={theme.colors.orange.light}
            borderColor={theme.colors.orange.light}>
            {props.children}
        </Button_v2>
    );
};

export {
    ButtonBlack,
    ButtonDarkGray,
    ButtonGray,
    ButtonLightGrayBlack,
    ButtonTransparent,
    ButtonTransparentWithBorder,
    ButtonWarning,
    ButtonWhite,
    ButtonWhiteBlack,
    ButtonWhitePrimary,
    ButtonYellow,
};

export type { CustomizedButtonProps };
