import { QueryCache, QueryClient } from "@tanstack/react-query";
const queryCache = new QueryCache();
const queryClient = new QueryClient({
    queryCache,
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});
export { queryCache, queryClient };
