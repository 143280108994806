import { Route, Routes } from "react-router-dom";
import { LoaderErrorBoundary } from "../shared/loader-error-boundary";
import { ProductPage } from "./$productId/product.page";

const ProductsPage = () => {
    return (
        <LoaderErrorBoundary>
            <Routes>
                <Route path=":productId" element={<ProductPage />} />
            </Routes>
        </LoaderErrorBoundary>
    );
};

export { ProductsPage };
