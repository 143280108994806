import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import {
    emptyArrayOf,
    generateComboId,
    GetResponse,
    Provider,
} from "@towni/common";

const fetchProvidersForArea =
    (areas: string[]): QueryFunction<Provider[]> =>
    async ({ signal }) => {
        if (!areas.length) return emptyArrayOf<Provider>();
        const data = await apiFetchClient.get<GetResponse<Provider>>({
            route: `/providers/for-area/?areas=${encodeURIComponent(
                areas.join(",")
            )}&qid=${generateComboId()}`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };

export { fetchProvidersForArea };
