import { useUrlState } from "@@/shared/use-url-state";
import { hashCode } from "@towni/common";
import * as React from "react";
import { z } from "zod";
import { findFirstDefaultTabIndex } from "./find-first-default-tab-index";
import { Tab } from "./tab";

const useTabView = (params: {
    /**
     * Has to be unique on page, it's used to match url query of selected tab index to tabView. If it's not unique you might be switching view in multiple tabViews at the same time
     * @type {string}
     */
    id: string;
    tabs: Tab[];
}) => {
    const [tabViewId] = React.useState(`tabv${Math.abs(hashCode(params.id))}`);
    const [selectedTabIndex, setSelectedTabIndex] = useUrlState({
        key: tabViewId,
        schema: z.coerce.number(),
        defaultValue: findFirstDefaultTabIndex(params.tabs),
    });

    return React.useMemo(
        () => ({ tabViewId, selectedTabIndex, setSelectedTabIndex }),
        [tabViewId, selectedTabIndex, setSelectedTabIndex],
    );
};
export { useTabView };
