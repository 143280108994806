import type { History } from "history";
import { useLayoutEffect, useState } from "react";
import { Router, RouterProps } from "react-router-dom";
import { Except } from "type-fest";
type Props = {
    history: History;
} & Except<RouterProps, "navigator" | "navigationType" | "location">;

const HistoryReactRouter = ({ history, ...props }: Props) => {
    const [state, setState] = useState(() => ({
        action: history.action,
        location: history.location,
    }));

    useLayoutEffect(
        () =>
            history.listen(locationUpdate => {
                setState(current => {
                    if (
                        current.location.pathname ===
                            locationUpdate.location.pathname &&
                        current.location.search ===
                            locationUpdate.location.search
                    ) {
                        return current;
                    }
                    // console.log("locationUpdate", current, locationUpdate);
                    return {
                        action: locationUpdate.action,
                        location: locationUpdate.location,
                    };
                });
            }),
        [history, state],
    );

    return (
        <Router
            {...props}
            location={state.location}
            navigationType={state.action as RouterProps["navigationType"]}
            navigator={history}
        />
    );
};

export { HistoryReactRouter };
