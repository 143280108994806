import { useProduct } from "@@/products/state/queries/product/use-product";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { useImageUrl } from "@@/shared/image-url";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { FontWeight } from "@@/styles/theme";
import { css, useTheme } from "@emotion/react";
import {
    AspectRatio,
    ColorItem,
    ProductId,
    RemSize,
    Translatable,
    translation,
} from "@towni/common";
import { Link } from "react-router-dom";

type Props = {
    productId: ProductId;
    /**
     * default to `3:4`
     * @type {Translatable}
     */
    aspectRatio?: AspectRatio;
    imageIdOrUrl?: string;
    /**
     * defaults to product title
     * @type {Translatable}
     */
    title?: Translatable;
    titleColor?: ColorItem;
    titleSize?: RemSize;
    titleWeight?: FontWeight;
    /**
     * defaults to product kicker
     * @type {Translatable}
     */
    kicker?: Translatable;
    kickerColor?: ColorItem;
    kickerSize?: RemSize;
    kickerWeight?: FontWeight;

    showPrice?: boolean;
    priceColor?: ColorItem;
    priceSize?: RemSize;
    priceWeight?: FontWeight;
};

const StoreFrontGridProductCard = (props: Props) => {
    const theme = useTheme();
    const [product] = useProduct(props.productId);
    const getImageUrl = useImageUrl();
    if (!product) return null;

    const imageRef = props.imageIdOrUrl
        ? product.meta.images.find(image =>
              typeof image === "string"
                  ? image === props.imageIdOrUrl
                  : image._id === props.imageIdOrUrl,
          ) ?? product.meta.images[0]
        : product.meta.images[0];

    const imageUrl = getImageUrl(imageRef, { imageWidth: 500 });
    const title = props.title ?? product.meta.title;
    const kicker = props.kicker ?? product.meta.kicker;

    return (
        <Link to={`/products/${product._id}`}>
            <FlexColumn fillParentWidth>
                <SectionAreaCard
                    aspectRatio={props.aspectRatio ?? "3:4"}
                    background={{
                        fit: "COVER",
                        imageUrl,
                    }}
                />
                <FlexColumn fillParentWidth padding={{ all: 1 }}>
                    {kicker ? (
                        <>
                            <VerticalDivider XXS />
                            <TextBox
                                text={kicker}
                                case="UPPERCASE"
                                size={props.kickerSize ?? (0.625 as RemSize)}
                                weight={props.kickerWeight ?? "800"}
                                color={
                                    props.kickerColor ?? theme.colors.primary
                                }
                            />
                        </>
                    ) : null}
                    <VerticalDivider XXS />
                    <TextBox
                        text={title}
                        weight={props.titleWeight ?? "700"}
                        lineClamp={2}
                        size={props.titleSize ?? (1 as RemSize)}
                        color={props.titleColor ?? theme.colors.black.light15}
                        styling={css`
                            overflow-wrap: break-word;
                        `}
                    />
                    <VerticalDivider XXS />
                    {props.showPrice ? (
                        <>
                            <PriceTextBox
                                price={{
                                    ...product.price,
                                    prefix: translation({
                                        sv: "från",
                                        en: "from",
                                    }),
                                }}
                                fractions="AUTO"
                                weight={props.priceWeight ?? "500"}
                                size={props.priceSize ?? (0.75 as RemSize)}
                                color={
                                    props.priceColor ??
                                    theme.colors.black.light30
                                }
                            />
                            <VerticalDivider XXS />
                        </>
                    ) : null}
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

export { StoreFrontGridProductCard };
