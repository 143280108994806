import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { OneSquareLinkSection } from "@@/store-fronts/shared/one-square-link-section";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import { FC } from "react";

const SmartResandeTwo: FC = () => {
    return (
        <SectionArea
            title={"95 kr för egen transport!"}
            subtitle={
                "Japp! Du läste rätt! Testa framtidens kollektivtrafik och åk till restauranger, boenden eller andra besöksmål med nära och kära! In och boka direkt. Eller boka en egen elbil!"
            }
            sectionAreaId="smart-resande-2-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} gap={5} padding={{ leftRight: 20 }}>
                <OneSquareSection
                    padding={{ all: 0 }}
                    productId={"product__IG8ZXjoF9TW197TdJKI8" as ProductId}
                    actionText={translation({
                        sv: "Se & Boka",
                    })}
                />
                <OneSquareLinkSection
                    padding={{ top: 20 }}
                    imageUrl="https://we-are-alfred.imgix.net/destinations/destination-smart_elbil.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges"
                    link={`/destinations/smart-resande-elbil`}
                    title={translation({ sv: "Hyr elbil här" })}
                    subTitle={translation({
                        sv: "Här hittar du våra elbilar och vart du kan börja och fortsätta dina resor",
                    })}
                    actionText={translation({
                        sv: "Elektriskt!",
                    })}
                    contentTop={false}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { SmartResandeTwo };
