import { IsoAndUnixTimestamp } from "@towni/common";
import React from "react";
import { DateSelectCard } from "../date-select-card";
import { DateData } from "./hz-item-data";

type Props = {
    data: DateData;
    className?: string;
    index?: number;
    toggleDate: (date: IsoAndUnixTimestamp | undefined) => void;
};
const HzDateItem = React.forwardRef<HTMLDivElement, Props>(
    (props, elementRef) => {
        const { data, toggleDate } = props;
        return (
            <div
                key={data.date.unix + "hz"}
                className={props.className}
                ref={elementRef}
                css={{
                    display: "inline-block",
                    flexShrink: 0,
                    flexGrow: 1,
                    minWidth: 60,
                }}>
                <DateSelectCard
                    key={data.date.unix}
                    status={
                        (data.selected && "SELECTED") ||
                        (data.canBeBooked ? "AVAILABLE" : "DISABLED")
                    }
                    date={data.date}
                    onToggle={toggleDate}
                />
            </div>
        );
    }
);

export { HzDateItem };
