import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Label } from "@@/shared/label";
import { TextBox } from "@@/shared/text";
import { MinMaxRange, Padding, Translatable, translation } from "@towni/common";

const PickSkuHeader = () => {
    return (
        <FlexRow padding={{ leftRight: 20 }} mainAxis="space-between">
            <TextBox
                text={translation({
                    sv: "Välj variant",
                    en: "Pick variant",
                })}
                size={1.2}
                weight="700"
            />
            <LabelRequired />
        </FlexRow>
    );
};

const descriptionCreator = (minMax?: MinMaxRange): Translatable | undefined => {
    if (!minMax?.min && !minMax?.max) return undefined;
    if (minMax.min === 1 && minMax.max === 1) return undefined;
    if (minMax.min === minMax.max) return `Välj ${minMax.min}`;

    let descriptionSv = "Välj";
    let descriptionEn = "Select";

    if (minMax.min) {
        descriptionSv = `${descriptionSv} minst ${minMax.min}`;
        descriptionEn = `${descriptionEn} at least ${minMax.min}`;
    }
    if (minMax.max) {
        descriptionSv = `${descriptionSv} max ${minMax.max}`;
        descriptionEn = `${descriptionEn}, max ${minMax.max}`;
    }

    const description = translation({
        sv: descriptionSv,
        en: descriptionEn,
    });
    return description;
};

const LabelRequired = () => (
    <Label
        text={translation({
            sv: "obligatorisk",
            en: "mandatory",
        })}
        colorTheme="RED"
    />
);
const LabelOptional = () => (
    <Label
        text={translation({
            sv: "valfri",
            en: "optional",
        })}
        colorTheme="WHITE"
    />
);

const DetailsPickerHeader = (props: {
    title: Translatable;
    minMax?: MinMaxRange;
    padding?: Padding;
    hideDescription?: boolean;
}) => {
    const required = props.minMax?.min && props.minMax?.min > 0;
    const description = descriptionCreator(props.minMax);
    return (
        <FlexColumn padding={props.padding} crossAxis="stretch">
            <FlexRow mainAxis="space-between" crossAxis="center">
                <TextBox text={props.title} size="L" weight="900" />
                <Conditional
                    when={!!required}
                    render={() => <LabelRequired />}
                    else={() => <LabelOptional />}
                />
            </FlexRow>
            <Conditional
                when={!!description && !props.hideDescription}
                render={() => (
                    <>
                        <VerticalDivider />
                        <TextBox text={description} weight="300" size="XS" />
                    </>
                )}
            />
        </FlexColumn>
    );
};

export { PickSkuHeader, DetailsPickerHeader };
