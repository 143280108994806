import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox, TextSpan } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import QRCode from "react-qr-code";

type Props = { code: string; link?: string; dataTestId?: string };

const TicketCustomerViewFooter = (props: Props) => {
    const theme = useTheme();
    const qrCodeValue =
        props.link && props.link.length > 0 ? props.link : props.code;

    return (
        <FlexColumn
            fillParentWidth
            background={{ color: theme.colors.white }}
            crossAxis="center"
            data-testid={props.dataTestId}
            css={{}}>
            <VerticalDivider S />
            <TextBox
                text="Visa vid inlösen"
                align="center"
                size={0.875}
                color={theme.colors.black.light45}
            />
            <FlexRow padding={{ leftRight: 40, topBottom: 15 }}>
                <QRCode value={qrCodeValue} />
            </FlexRow>

            <TextBox
                size={1.1875}
                color={theme.colors.black.light45}
                align="center"
                css={{ userSelect: "text" }}>
                <TextSpan
                    text={props.code.slice(0, 3)}
                    css={{
                        letterSpacing: 5,
                        paddingRight: 5,
                    }}
                />
                <TextSpan
                    text={props.code.slice(3)}
                    css={{
                        letterSpacing: 5,
                        paddingLeft: 5,
                    }}
                />
            </TextBox>

            <VerticalDivider M />
        </FlexColumn>
    );
};

export { TicketCustomerViewFooter };
