import { assertNever, pageIdZodSchema, Provider } from "@towni/common";
import { useCallback, useMemo } from "react";
import { z } from "zod";

function useHomePath(
    provider: Provider | undefined,
    fallbackToShop?: true,
): string;
function useHomePath(
    provider: Provider | undefined,
    fallbackToShop: false,
): string | undefined;
function useHomePath(
    provider: Provider | undefined,
    fallbackToShop = true,
): string | undefined {
    const getHomePath = useCallback(() => {
        if (!provider) return fallbackToShop ? "/shop" : undefined;
        const home = provider.options.home;
        const shopPage = `/shop/${provider.slug}`;
        if (!home && !fallbackToShop) return undefined;
        if (!home || home.type === "LEGACY") {
            return shopPage;
        }
        if (home.type === "PAGE_ID") {
            if (!pageIdZodSchema.safeParse(home.pageId).success) {
                return fallbackToShop ? shopPage : undefined;
            }

            if (home.pageSlug?.trim()) {
                return `/pages/${provider.slug}/${home.pageSlug}`;
            }

            return `/pages/${home.pageId}`;
        }
        if (home.type === "URL") {
            const isUrl = z
                .string()
                .url(home.url?.trim())
                .safeParse(home.url).success;
            if (!isUrl) return fallbackToShop ? shopPage : undefined;
            return home.url;
        }
        assertNever(home);
    }, [fallbackToShop, provider]);

    return useMemo(getHomePath, [getHomePath]);
}

export { useHomePath };
