import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { BusinessHoursPillForProvider } from "@@/section-groups/business-hours-pill";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { css, useTheme } from "@emotion/react";
import {
    Provider,
    colorAsString,
    getNameOfDayFromDate,
    repeatIndex,
    translation,
} from "@towni/common";
import { addDays } from "date-fns";
import { ModalHeader } from "../modals/modal-header";
import { TextBox } from "./text";

type Props = {
    modalId: ModalId;
    provider: Provider;
};

const BusinessHoursModal = (props: Props) => {
    const theme = useTheme();
    const numberOfDays = 7;
    return (
        <Modal modalId={props.modalId}>
            <FlexColumn
                padding={{ bottom: 10 }}
                crossAxis="stretch"
                styling={css`
                    background-color: white;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                `}>
                <ModalHeader
                    modalId={props.modalId}
                    title={translation({
                        sv: "Öppettider",
                        en: "Business hours",
                    })}
                />
                <table
                    style={{
                        padding: 0,
                        margin: 0,
                        background: "none",
                        border: "none",
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        backgroundImage: "none",
                    }}
                    css={css`
                        td {
                            padding: 15px 30px 15px 20px;
                        }
                    `}>
                    <tbody>
                        {repeatIndex(numberOfDays).map(daysFromNow => {
                            const day = addDays(new Date(), daysFromNow);
                            const name =
                                daysFromNow === 0
                                    ? translation({
                                          sv: "Idag",
                                          en: "Today",
                                      })
                                    : daysFromNow === 1
                                      ? translation({
                                            sv: "Imorgon",
                                            en: "Tomorrow",
                                        })
                                      : getNameOfDayFromDate(day);
                            return (
                                <tr
                                    key={daysFromNow}
                                    style={{
                                        background:
                                            daysFromNow % 2 === 0
                                                ? colorAsString(
                                                      theme.colors.black
                                                          .light95,
                                                  )
                                                : "",
                                    }}>
                                    <td style={{ width: "20%" }}>
                                        <TextBox text={name} />
                                    </td>
                                    <td style={{ width: 10 }}></td>
                                    <td>
                                        <BusinessHoursPillForProvider
                                            provider={props.provider}
                                            day={day}
                                            hideIcon
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <VerticalDivider XS />
            </FlexColumn>
        </Modal>
    );
};

export { BusinessHoursModal };
