import { browserLogger } from "@@/settings/browser-logger";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import {
    Paragraph,
    paragraphButtonFactory,
    paragraphHzSpaceFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { FormImageGallery } from "@@/shared/text/form-inputs/form-image-gallery";
import { TextBox } from "@@/shared/text/text-box";
import { UploadError } from "@@/shared/text/upload-error";
import { uploadFile } from "@@/shared/text/upload-file";
import { UploadProgress } from "@@/shared/text/upload-progress";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { useStorageItemImagesForUpload } from "@@/storage-items/queries/use-storage-item-images-for-upload";
import { useImageStorageItems } from "@@/storage-items/queries/use-storage-items";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import { faCloudUpload } from "@fortawesome/pro-duotone-svg-icons";
import {
    ColorItem,
    Percentage,
    ProviderId,
    RemSize,
    StorageItemId,
    StorageItemImage,
    StorageItemImageId,
    Translatable,
    emptyArrayOf,
    imageContentTypesWithoutHeicHeif,
    isStorageItemImageId,
    remSize,
    sortBy,
    storageItemImageIdFactory,
    translation,
    unique,
    uploadIdFactory,
} from "@towni/common";
import { Draft } from "immer";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

type Value = StorageItemImageId[];

const arraysOrderedEqual = (a: unknown[], b: unknown[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (
        draft: Draft<Partial<State>>,
        newValue: NonNullable<Value> | undefined,
    ) => void;

    readonly imageRequirementDescription?: Translatable;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    readonly placeholder?: JSX.Element;
    readonly existingImages?: StorageItemImage[];
    readonly providerId: ProviderId | undefined;
    readonly size?: RemSize;
};

const Form2ImageUploadMultiple = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const toast = useToast();
    const [uploadId] = React.useState(uploadIdFactory);
    const [droppable, setDroppable] = React.useState(false);
    const [fileUploads, setFileUploads] = React.useState<
        {
            file: File;
            storageItemId: StorageItemImageId;
            progress: Percentage;
        }[]
    >();

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const [initialValue] = useState<NonNullable<Value>>(field.value ?? []);
    const [storageItemIds, setStorageItemIds] = React.useState<Value>(
        initialValue ?? ([] as Value),
    );

    useUpdateEffect(() => {
        const isOrdered = arraysOrderedEqual(
            field.value ?? ([] as Value),
            storageItemIds,
        );
        browserLogger.info({ isOrdered });
        if (arraysOrderedEqual(field.value || [], storageItemIds)) return;
        field.setValue(storageItemIds);
        field.setDirty(true);
        field.setTouched(true);
    }, [storageItemIds]);

    const [oldItems] = useImageStorageItems(
        initialValue.filter(isStorageItemImageId),
    );
    const storageItemsQuery = useStorageItemImagesForUpload(uploadId);
    const storageItems =
        storageItemsQuery.data ?? emptyArrayOf<StorageItemImage>();
    const storageItemsSorted = React.useMemo(
        () =>
            [...storageItems, ...oldItems]
                .filter(item => storageItemIds.includes(item._id))
                .sort(sortBy(item => storageItemIds.indexOf(item._id))),
        [oldItems, storageItemIds, storageItems],
    );

    const isMounted = useIsMountedRef();

    useEffect(() => {
        storageItemsQuery.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageItemIds]);

    const onProgress = React.useCallback(
        (storageItemId: StorageItemId, progress: Percentage) => {
            if (!isMounted.current) return;
            setFileUploads(uploads =>
                uploads?.map(upload =>
                    upload.storageItemId !== storageItemId
                        ? upload
                        : {
                              ...upload,
                              progress,
                          },
                ),
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setFileUploads],
    );

    const onImagesSelected = useCallback(
        async (fileList: FileList): Promise<void> => {
            // Create a storageItemId
            const files = Array.from(fileList)
                .filter(item =>
                    imageContentTypesWithoutHeicHeif.includes(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        item.type as unknown as any,
                    ),
                )
                .map(item => ({
                    file: item,
                    storageItemId: storageItemImageIdFactory(),
                    progress: 0 as Percentage,
                }));

            if (!files?.length && fileList.length) {
                toast.warning({
                    message: translation({
                        sv: "Det du försöker ladda upp är inte en eller flera bildfiler",
                        en: "The file(s) you are trying to upload is not an image file",
                    }),
                    title: translation({
                        sv: "Ingen bildfil",
                        en: "No image file",
                    }),
                });
                return;
            }

            const uploadTasks = files.map(item => {
                setFileUploads(uploads => [...(uploads ?? []), item]);
                return uploadFile({
                    file: item.file,
                    providerId: props.providerId,
                    storageItemId: item.storageItemId,
                    uploadId,
                    onProgress,
                });
            });
            uploadTasks.forEach(uploadTask => {
                uploadTask
                    .then(storageItemId => {
                        if (!isMounted.current) return;
                        // Add storageItemId to form

                        setStorageItemIds(current =>
                            unique([...(current ?? []), storageItemId]),
                        );
                        setFileUploads(uploads =>
                            uploads?.filter(
                                upload =>
                                    upload.storageItemId !== storageItemId,
                            ),
                        );
                        return;
                    })
                    .catch(error => {
                        if (!isMounted.current) return;
                        if (error instanceof UploadError) {
                            setFileUploads(uploads =>
                                uploads?.filter(
                                    upload =>
                                        upload.storageItemId !==
                                        error.storageItemId,
                                ),
                            );
                            toast.danger({
                                message: error.message,
                                sticky: true,
                            });
                            return undefined;
                        }
                        throw error;
                    });
            });
        },
        [toast, props.providerId, uploadId, onProgress, isMounted],
    );

    const onDragEvent = (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        if (event.dataTransfer.types.indexOf("Files") === -1) return;
        setDroppable(true);
        onDragEvent(event);
    };
    const onDragLeave = (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => {
        setDroppable(false);
        onDragEvent(event);
    };
    const onDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
        onImagesSelected(event.dataTransfer.files);
        onDragLeave(event);
    };

    const size = props.size ?? remSize(8);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // Handle paste from clipboard
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onPaste = (ev: Event) => {
            if (document.activeElement?.id !== uploadId) return;
            ev.stopPropagation();
            const fileInput = inputRef.current;
            if (!fileInput) return;
            fileInput.files =
                (ev as ClipboardEvent).clipboardData?.files || null;
            if (fileInput.files?.length) {
                onImagesSelected(fileInput.files);
            }
        };

        window.addEventListener("paste", onPaste);
        return () => {
            window.removeEventListener("paste", onPaste);
        };
    }, [onImagesSelected, uploadId]);
    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
    });

    function reorder(order: StorageItemImageId[]): void {
        setStorageItemIds(current => unique([...order, ...current]));
    }

    return (
        <FlexColumn fillParentWidth>
            <Conditional
                when={!!props.label}
                render={() => {
                    return (
                        <>
                            <FlexRow css={{ flex: 1 }} crossAxis="center">
                                <FieldTitle
                                    htmlFor={props.fieldId}
                                    text={props.label ?? ""} // already checked with conditional above
                                    color={props.labelColor}
                                    required={field.isRequired}
                                />
                                <Conditional when={!!props.labelDescription}>
                                    <HorizontalDivider XXS />
                                    <FieldTitle
                                        text={props.labelDescription ?? ""} // already checked with conditional above
                                        color={props.labelColor}
                                        weight="400"
                                        size="S"
                                    />
                                </Conditional>
                            </FlexRow>
                            <VerticalDivider XS />
                        </>
                    );
                }}
            />
            <LayoutGrid
                fillParentWidth
                id={uploadId}
                tabIndex={0}
                className={props.className}
                gridTemplateColumns={"1fr"}
                css={{
                    userSelect: "auto",
                    "&:focus": {
                        backgroundColor: theme.colors.primary.light.asString,
                        borderColor: theme.colors.primary.asString,
                    },
                    "&:focus > div": {
                        backgroundColor: theme.colors.primary.light.asString,
                    },
                    backgroundColor: droppable
                        ? theme.colors.primary.light.asString
                        : theme.colors.textInput.background.asString,
                    borderRadius: theme.radius,
                    borderWidth: 1,
                    borderColor: droppable
                        ? theme.colors.primary.asString
                        : theme.colors.textInput.border.asString,
                    borderStyle:
                        droppable || !storageItemsSorted.length
                            ? "dashed"
                            : "solid",
                    cursor: "pointer",
                    overflow: "hidden",
                }}>
                <LayoutCell
                    css={{
                        padding: 5,
                    }}>
                    {/* <label css={{ cursor: "pointer" }}> */}
                    <input
                        style={{ display: "none" }}
                        type="file"
                        ref={inputRef}
                        multiple={true}
                        accept={imageContentTypesWithoutHeicHeif.join(", ")}
                        onChange={event => {
                            const files = event.target.files;
                            if (files === null) return;
                            onImagesSelected(files);
                        }}
                    />
                    <FlexColumn
                        fillParent
                        crossAxis="center"
                        mainAxis="center"
                        onDrag={onDragEvent}
                        onDragStart={onDragEvent}
                        onDragOver={onDragEnter}
                        onDragEnter={onDragEnter}
                        onDragEnd={onDragLeave}
                        onDragLeave={onDragLeave}
                        onDrop={onDragDrop}
                        background={{
                            color:
                                storageItemsSorted.length ||
                                !!fileUploads?.length
                                    ? theme.colors.default.background
                                    : droppable
                                      ? theme.colors.primary.light
                                      : theme.colors.textInput.background,
                        }}
                        css={{
                            padding: 20,
                            pointerEvents: "all",
                            borderRadius: theme.radius,
                            border:
                                storageItemsSorted.length ||
                                !!fileUploads?.length
                                    ? `1px dashed ${
                                          droppable
                                              ? theme.colors.primary.asString
                                              : theme.colors.textInput.border
                                                    .asString
                                      }`
                                    : "none",
                        }}>
                        <FlexColumn
                            fillParentWidth
                            maxWidth={400}
                            crossAxis="center"
                            padding={{ leftRight: 20 }}>
                            <FlexRow fillParentWidth mainAxis="center">
                                <Icon
                                    icon={faCloudUpload}
                                    size={remSize(2)}
                                    color={
                                        droppable
                                            ? theme.colors.primary
                                            : theme.colors.default.placeholder
                                    }
                                />
                                <Conditional
                                    when={
                                        !!storageItemsSorted.length ||
                                        !!fileUploads?.length
                                    }>
                                    <HorizontalDivider M />
                                    <Paragraph
                                        css={{
                                            textAlign: "left",
                                            fontSize: "0.9rem",
                                            fontWeight: 400,
                                            lineHeight: "1.1rem",
                                            color: droppable
                                                ? theme.colors.primary.asString
                                                : theme.colors.default
                                                      .placeholder.asString,
                                        }}
                                        content={[
                                            paragraphTextFactory({
                                                text: translation({
                                                    sv: `Lägg till fler bilder genom att klistra in eller släppa dem här, eller `,
                                                    en: `Add more images by pasting or dropping them here, or `,
                                                }),
                                                css: { display: "inline" },
                                            }),
                                            paragraphButtonFactory({
                                                text: translation({
                                                    sv: `välj bilder`,
                                                    en: `choose images`,
                                                }),
                                                textCss: {
                                                    fontSize: "0.9rem",
                                                },
                                                onClick: event => {
                                                    event.preventDefault();
                                                    inputRef.current?.click();
                                                },
                                                button: ButtonPrimaryLight,
                                                css: {
                                                    margin: 0,
                                                    marginTop: 2,
                                                    display: "inline-block",
                                                    borderRadius: 5,
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    padding: "2px 6px",
                                                    borderColor:
                                                        theme.colors.primary.main.withAlpha(
                                                            0.2,
                                                        ).asString,
                                                },
                                            }),
                                        ]}
                                    />
                                </Conditional>
                            </FlexRow>
                            <Conditional
                                when={
                                    !storageItemsSorted.length &&
                                    !fileUploads?.length
                                }>
                                <VerticalDivider />
                                <Paragraph
                                    css={{
                                        textAlign: "center",
                                        fontSize: "1rem",
                                        fontWeight: 700,
                                        lineHeight: "1.3rem",
                                        color: droppable
                                            ? theme.colors.primary.asString
                                            : theme.colors.default.placeholder
                                                  .asString,
                                    }}
                                    content={[
                                        paragraphTextFactory({
                                            text: translation({
                                                sv: `Klistra in eller släpp här, eller`,
                                                en: `Paste or drop here, or `,
                                            }),
                                            css: {
                                                display: "inline",
                                                textTransform:
                                                    storageItemsSorted.length
                                                        ? "lowercase"
                                                        : undefined,
                                            },
                                        }),
                                        paragraphHzSpaceFactory(),
                                        paragraphButtonFactory({
                                            text: translation({
                                                sv: `välj bilder`,
                                                en: `choose images`,
                                            }),
                                            onClick: event => {
                                                event.preventDefault();
                                                inputRef.current?.click();
                                            },
                                            button: ButtonPrimaryLight,
                                            css: {
                                                marginTop: 0,
                                                display: "inline-block",
                                                borderRadius: 5,
                                                borderStyle: "solid",
                                                borderWidth: 1,
                                                padding: "2px 8px",
                                                borderColor:
                                                    theme.colors.primary.main.withAlpha(
                                                        0.2,
                                                    ).asString,
                                            },
                                        }),
                                    ]}
                                />
                                <Conditional
                                    when={!!props.imageRequirementDescription}>
                                    <VerticalDivider XS />
                                    <TextBox
                                        text={props.imageRequirementDescription}
                                        size={0.8}
                                        weight="400"
                                        align="center"
                                        color={theme.colors.default.placeholder}
                                    />
                                </Conditional>
                            </Conditional>
                        </FlexColumn>
                    </FlexColumn>
                    {/* </label> */}
                </LayoutCell>
                <Conditional
                    when={!!storageItemsSorted.length || !!fileUploads?.length}
                    render={() => {
                        return (
                            <LayoutCell
                                css={{
                                    padding: 5,
                                }}>
                                <FlexColumn
                                    fillParentWidth
                                    crossAxis="center"
                                    css={{ pointerEvents: "none" }}>
                                    <ForEach
                                        itemOf={fileUploads}
                                        divider={<VerticalDivider XS />}
                                        endWith={<VerticalDivider S />}>
                                        {upload => (
                                            <UploadProgress
                                                fileSize={upload.file.size}
                                                originalFilename={
                                                    upload.file.name
                                                }
                                                storageItemId={
                                                    upload.storageItemId
                                                }
                                                progress={upload.progress}
                                            />
                                        )}
                                    </ForEach>
                                    <FormImageGallery
                                        items={storageItemsSorted}
                                        padding={{ all: 1 }}
                                        imageSize={size}
                                        remove={itemId => {
                                            setStorageItemIds(current => {
                                                const update = current.filter(
                                                    id => id !== itemId,
                                                );
                                                return update;
                                            });
                                        }}
                                        reorder={reorder}
                                    />
                                    <VerticalDivider S />
                                </FlexColumn>
                            </LayoutCell>
                        );
                    }}
                />
            </LayoutGrid>
        </FlexColumn>
    );
};

export { Form2ImageUploadMultiple };
export type { Props as Form2ImageUploadProps };
