import {
    darkeningGradient,
    darkeningGradientReverse,
} from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTheme } from "@emotion/react";
import { AspectRatio, Padding, RemSize, Translatable } from "@towni/common";
import { Link } from "react-router-dom";

type Props = {
    imageUrl: string;
    link: string;
    title: Translatable;
    subTitle: Translatable;
    actionText: Translatable;
    contentTop?: boolean;
    padding?: Padding;
    aspectRatio?: AspectRatio;
};
const OneSquareLinkSection = (props: Props) => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const imageUrl = props.imageUrl;

    return (
        <Link to={props.link}>
            <FlexColumn
                fillParentWidth
                padding={props.padding ?? { leftRight: 20 }}
                crossAxis="flex-start"
                shrink={0}>
                <SectionAreaCard
                    aspectRatio={
                        props.aspectRatio ?? (isDesktop ? "3:2" : "1:1")
                    }
                    radius={16}
                    background={{
                        fit: "COVER",
                        imageUrl,
                    }}>
                    <FlexColumn
                        shrink={0}
                        grow={1}
                        padding={{ all: 20 }}
                        reversed={props.contentTop}
                        background={{
                            linearGradient: props.contentTop
                                ? darkeningGradientReverse
                                : darkeningGradient,
                        }}
                        mainAxis="space-between"
                        crossAxis="center">
                        <FlexRow fillParentWidth mainAxis="flex-end">
                            <StoreFrontCardPill text={props.actionText} />
                        </FlexRow>
                        <FlexColumn fillParentWidth shrink={0}>
                            <TextBox
                                text={props.title}
                                weight="900"
                                size={2 as RemSize}
                                color={theme.colors.white}
                                css={{
                                    textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            />
                            <VerticalDivider />
                            <TextBox
                                text={props.subTitle}
                                weight="500"
                                size={1 as RemSize}
                                color={theme.colors.white}
                                css={{
                                    textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            />
                            <VerticalDivider S />
                        </FlexColumn>
                    </FlexColumn>
                </SectionAreaCard>
            </FlexColumn>
        </Link>
    );
};

export { OneSquareLinkSection };
