import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import * as React from "react";
import { SectionDisplayProps } from "../section-content-display";

const SectionListVt = ({ children, ...props }: SectionDisplayProps) => {
    const Flexy = props.sectionTheme.wrap ? FlexRow : FlexColumn;

    return (
        <Flexy
            fillParentWidth
            {...(props.sectionTheme.itemDisplayType !== "ROW"
                ? {
                      padding: { leftRight: 20 },
                  }
                : {})}
            {...(props.sectionTheme.wrap
                ? {
                      wrap: "wrap",
                      wrapMargin: 5,
                      mainAxis: "space-around",
                  }
                : {})}>
            {children}
        </Flexy>
    );
};

export { SectionListVt };
