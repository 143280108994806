import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faFileCheck } from "@fortawesome/pro-solid-svg-icons";
import {
    Percentage,
    StorageItemId,
    Translatable,
    remSize,
} from "@towni/common";
import prettyBytes from "pretty-bytes";
import { HorizontalDivider, VerticalDivider } from "../dividers";
import { FlexColumn, FlexRow } from "../flex-containers";
import { Icon } from "../icons/icon";
import { TextBox } from "./text-box";
type Props = {
    storageItemId: StorageItemId;
    progress: Percentage;
    originalFilename: string;
    fileSize: number;
    title?: Translatable;
};
const UploadProgress = (props: Props) => {
    const theme = useTheme();
    const progress = props.progress;
    const progressInt = (progress * 100).toFixed(2);

    return (
        <div
            css={{
                width: "100%",
                borderWidth: 1,
                borderStyle: "dashed",
                borderRadius: 10,
                backgroundColor: theme.colors.white.asString,
                overflow: "hidden",
            }}>
            <FlexRow
                fillParentWidth
                crossAxis="center"
                css={{
                    padding: 10,
                    willChange: "background",
                    background: `linear-gradient(90deg, ${theme.colors.primary.light.light90.asString} ${progressInt}%, ${theme.colors.white.asString} ${progressInt}%)`,
                    transition: "background 0.5s",
                }}>
                <Icon
                    icon={progress < 1 ? faSpinnerThird : faFileCheck}
                    spin={progress < 1}
                    style={{
                        flexGrow: 0,
                        flexShrink: 0,
                    }}
                    color={
                        progress < 1
                            ? theme.colors.black.light60
                            : theme.colors.primary
                    }
                    size={remSize(1.8)}
                />
                <HorizontalDivider />
                <FlexColumn
                    grow={1}
                    crossAxis="flex-start"
                    css={{
                        backgroundColor: theme.colors.transparent.asString,
                    }}>
                    <TextBox
                        text={props.title ?? props.originalFilename}
                        weight="700"
                        size={0.8}
                        css={{
                            overflowWrap: "anywhere",
                            backgroundColor: theme.colors.transparent.asString,
                        }}
                        color={
                            progress < 1
                                ? theme.colors.black.light40
                                : theme.colors.primary
                        }
                    />
                    <VerticalDivider XXS />
                    <FlexRow
                        css={{
                            backgroundColor: theme.colors.transparent.asString,
                        }}>
                        <TextBox
                            text={prettyBytes(props.fileSize)}
                            weight="400"
                            size={0.7}
                            css={{
                                backgroundColor:
                                    theme.colors.transparent.asString,
                            }}
                            color={
                                progress < 1
                                    ? theme.colors.black.light40
                                    : theme.colors.primary
                            }
                        />
                        <HorizontalDivider />
                        <TextBox
                            text={`${progressInt}%`}
                            weight="400"
                            size={0.7}
                            css={{
                                backgroundColor:
                                    theme.colors.transparent.asString,
                            }}
                            color={
                                progress < 1
                                    ? theme.colors.black.light60
                                    : theme.colors.primary
                            }
                        />
                    </FlexRow>
                </FlexColumn>
            </FlexRow>
        </div>
    );
};

export { UploadProgress };
