import { useCart } from "@@/carts/multi-carts/cart.context";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { WithProvider } from "@@/providers/state/queries/provider/with-provider";
import { FlexColumn } from "@@/shared/flex-containers";
import { useTheme } from "@emotion/react";
import { OrderItemId } from "@towni/common";
import OrderItemDetailsPage from "../order-item-details-page";
import { ExtrasProps } from "../product-details-page";

type Props = {
    readonly orderItemId: OrderItemId | undefined;
    readonly modalId: ModalId;
    readonly extras?: ExtrasProps;
};

const ExtrasEditOrderItemModal = (props: Props) => {
    const theme = useTheme();
    const cart = useCart();
    const orderItem = cart.orderItems.find(
        item => item._id === props.orderItemId,
    );
    if (!orderItem) return null;

    return (
        <Modal height={"full"} modalId={props.modalId}>
            <FlexColumn
                fillParent
                padding={{ bottom: 20 }}
                crossAxis="stretch"
                css={{
                    backgroundColor: theme.colors.white.asString,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <WithProvider
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    providerIdOrSlug={orderItem.providerId}>
                    {provider => (
                        <OrderItemDetailsPage
                            pageAnimationDisabled={true}
                            provider={provider}
                            hideLinkToShop={true}
                            modalId={props.modalId}
                            orderItemId={orderItem._id}
                            extras={props.extras}
                        />
                    )}
                </WithProvider>
            </FlexColumn>
        </Modal>
    );
};

export { ExtrasEditOrderItemModal };
