import { AppPage } from "@@/pages/app-page";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { Wizard, wizardPage } from "@@/pages/wizard";
import { browserLogger } from "@@/settings";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { Spacer } from "@@/shared/spacer";
import { TabView } from "@@/shared/tabs/material/tab-view";
import { ModalTitle } from "@@/shared/text/modal-title";
import * as Sentry from "@sentry/react";
import { translation } from "@towni/common";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClassicSignInProvider } from "./classic/classic-sign-in-context";
import { ClassicSignInForm } from "./classic/steps/classic-sign-in-form";
import { useMe } from "./me/me-context";
import { PhoneSignInOrUpDataProvider } from "./phone/phone-sign-in-or-sign-in-context";
import { CaptchaWizardStep } from "./phone/steps/captcha-wizard-step";
import { PhoneSignIn } from "./phone/steps/phone-sign-in";
import { PhoneVerification } from "./phone/steps/phone-verification";

type Props = {
    close: () => void;
};

const SignIn = (props: Props) => {
    const [me] = useMe();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const refUrl = params.get("refUrl");

    const finish = () => {
        navigate(refUrl ?? "/");
    };
    useEffect(() => {
        // Check on first run if already signed in
        browserLogger.log("SIGNED IN", me, props);
        if (me) {
            browserLogger.log("ALREADY SIGNED IN", me, props);
            finish();
        }
    }, [me]);

    return (
        <Sentry.ErrorBoundary>
            <HeadTitle
                title={translation({
                    sv: "Logga in",
                    en: "Sign in",
                })}
            />
            <AppPage pageId={appPageIdFactory("sign-in")}>
                <FlexColumn padding={{ leftRight: 20 }}>
                    <FlexRow
                        mainAxis="space-between"
                        crossAxis="center"
                        padding={{ topBottom: 20 }}>
                        <Spacer basis={0.2} shrink={0} />
                        <FlexColumn basis={0.6} crossAxis="center">
                            <ModalTitle
                                text={translation({
                                    sv: "Logga in",
                                    en: "Sign in",
                                })}
                            />
                        </FlexColumn>
                        <Spacer basis={0.2} shrink={0} />
                    </FlexRow>
                    <HorizontalLine />
                    <VerticalDivider />
                    <TabView
                        id="SignIn"
                        tabs={[
                            {
                                id: "PHONE",
                                title: translation({
                                    sv: "Telefon",
                                    en: "Phone",
                                }),
                                content: (
                                    <>
                                        <VerticalDivider S />
                                        <PhoneSignInOrUpDataProvider>
                                            <Wizard
                                                ignoreAnimation={true}
                                                onCancel={props.close}
                                                onFinish={finish}>
                                                {[
                                                    wizardPage(
                                                        "phone-sign-in",
                                                        <PhoneSignIn />,
                                                    ),
                                                    wizardPage(
                                                        "captcha",
                                                        <CaptchaWizardStep />,
                                                    ),
                                                    wizardPage(
                                                        "verification",
                                                        <PhoneVerification />,
                                                    ),
                                                ]}
                                            </Wizard>
                                        </PhoneSignInOrUpDataProvider>
                                    </>
                                ),
                            },
                            {
                                id: "CLASSIC",
                                title: translation({
                                    sv: "Klassisk",
                                    en: "Classic",
                                }),
                                content: (
                                    <>
                                        <VerticalDivider S />
                                        <ClassicSignInProvider>
                                            <ClassicSignInForm
                                                finish={finish}
                                            />
                                        </ClassicSignInProvider>
                                    </>
                                ),
                            },
                        ]}
                    />
                </FlexColumn>
            </AppPage>
        </Sentry.ErrorBoundary>
    );
};

export { SignIn };
