import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import {
    getChildOptionals,
    getChildQuantities,
    isOrderItemBookable_V2,
    OrderItem,
} from "@towni/common";
import * as React from "react";
import { PickedOptionalRow } from "./picked-optional-row";
import { BookedResourcesCostRow } from "./resources-cost-row";

type Props = {
    orderItems: OrderItem[];
};

const BookedResourceCost = ({ orderItems }: Props) => {
    return (
        <FlexColumn css={{ flex: 1, label: "BOOKED_RESOURCE_COST" }}>
            {orderItems.filter(isOrderItemBookable_V2).map(orderItem => {
                return (
                    <React.Fragment key={orderItem._id}>
                        {/* // Will only show for quantity pickers */}
                        <BookedResourcesCostRow
                            items={getChildQuantities(orderItem, orderItems)}
                            item={orderItem}
                        />
                        <VerticalDivider size="3XS" />
                        <ForEach
                            itemOf={getChildOptionals(orderItem, orderItems)}
                            divider={<VerticalDivider size="3XS" />}>
                            {picked => <PickedOptionalRow picked={picked} />}
                        </ForEach>
                    </React.Fragment>
                );
            })}
        </FlexColumn>
    );
};

export { BookedResourceCost };
