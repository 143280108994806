import { colorFactory, LinearGradient, transparentColor } from "@towni/common";

/**
 * Whiter at the bottom, transparent from 0% to 50%
 *  @type {*} */
const white = colorFactory({
    hue: 0,
    light: 1,
    saturation: 0,
    alpha: 1,
});
const whiteningGradient: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: white,
        },
        {
            percentage: 0.5,
            color: white,
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
const whiteningGradient10: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: white,
        },
        {
            percentage: 0.1,
            color: white,
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
const whiteningGradient20: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: white,
        },
        {
            percentage: 0.2,
            color: white,
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
const blackeningGradient: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: "#000000",
        },
        {
            percentage: 0.5,
            color: "#000000",
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
const blackeningGradient10: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: "#000000",
        },
        {
            percentage: 0.1,
            color: "#000000",
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
const blackeningGradient20: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: "#000000",
        },
        {
            percentage: 0.2,
            color: "#000000",
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
/**
 * Lighter at the bottom, transparent from 0% to 50%
 *  @type {*} */
const lighteningGradient: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 1,
                saturation: 0,
                alpha: 0.4,
            }),
        },
        {
            percentage: 0.4,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: transparentColor,
        },
    ],
};
/**
 * Lighter at the top, transparent from 50% to 100%
 *  @type {*} */
const lighteningGradientReverse: LinearGradient = {
    ...lighteningGradient,
    degrees: 180,
};

const lighteningGradientDual: LinearGradient = {
    _type: "LINEAR_GRADIENT",
    steps: [
        {
            percentage: 0,
            color: colorFactory({
                hue: 0,
                light: 1,
                saturation: 0,
                alpha: 0.5,
            }),
        },
        {
            percentage: 0.5,
            color: transparentColor,
        },
        {
            percentage: 0.7,
            color: transparentColor,
        },
        {
            percentage: 1,
            color: colorFactory({
                hue: 0,
                light: 1,
                saturation: 0,
                alpha: 0.5,
            }),
        },
    ],
    degrees: 0,
};

export {
    lighteningGradient,
    lighteningGradientReverse,
    lighteningGradientDual,
    whiteningGradient,
    whiteningGradient10,
    whiteningGradient20,
    blackeningGradient,
    blackeningGradient10,
    blackeningGradient20,
};
