import { useOrderSocketEventsForMe } from "@@/backoffice/for-providers/orders/use-orders-events-for-me";
import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    FinancialOrderInformationForCustomerProjection,
    OrderGroupId,
    Price,
    orderGroupIdZodSchema,
    parseSafely,
} from "@towni/common";

type QueryResponse = {
    _type: "ORDER_GROUP_RECEIPTS_QUERY_RESPONSE";
    item: FinancialOrderInformationForCustomerProjection;
    serviceFee: Price;
};

const useOrderReceiptsQuery = (
    orderGroupId: OrderGroupId | undefined,
): UseQueryResult<QueryResponse> => {
    const fetchClient = useFetchClient();

    const queryFn: QueryFunction<QueryResponse> = ({ signal }) => {
        const _orderGroupId = parseSafely({
            schema: orderGroupIdZodSchema,
            value: orderGroupId,
        });
        if (!_orderGroupId)
            throw Error("Invalid order group id; " + orderGroupId);

        return fetchClient.get({
            route: `/order-groups/${_orderGroupId}/receipts`,
            customConfig: { signal },
        });
    };
    const query = useQuery<QueryResponse, Error>({
        queryKey: ["order-group-receipts", orderGroupId],
        enabled: !!orderGroupId,
        queryFn,
    });

    // Refetch order receipt data on server notifications
    useOrderSocketEventsForMe({
        onOrderEvent: (event, _room) => {
            if (event.data.orderGroupId === orderGroupId) {
                query.refetch();
            }
        },
    });

    return query;
};

export { useOrderReceiptsQuery };
