import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import { Price, Product, QueryResponse } from "@towni/common";

const useStartingPriceQuery = (
    product: Product,
): UseQueryResult<QueryResponse<Price | "NONE">> => {
    const queryFn: QueryFunction<QueryResponse<Price | "NONE">> = ({
        signal,
    }) => {
        return apiFetchClient.get<QueryResponse<Price | "NONE">>({
            route: `/products/${encodeURIComponent(
                product._id,
            )}/starting-price`,
            customConfig: {
                signal,
            },
        });
    };
    return useQuery({
        queryKey: ["products", product._id, "starting-price"],
        queryFn,
    });
};

export { useStartingPriceQuery };
