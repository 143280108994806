import { SizeName } from "@towni/common";
const getDividerSizeInPixels = (size: SizeName): number => {
    switch (size) {
        case "3XL":
            return 60;
        case "XXL":
            return 50;
        case "XL":
            return 40;
        case "L":
            return 30;
        case "ML":
            return 25;
        case "M":
            return 20;
        case "S":
            return 12;
        case "XS":
            return 7;
        case "XXS":
            return 3;
        case "3XS":
            return 1.5;
    }
};
const calculateDividerSize = (
    sizes: { [key in SizeName]?: boolean },
    fallback: number
): number => {
    const firstKeyOfEnabledSize: SizeName | undefined = Object.entries(
        sizes
    ).filter(([_, value]) => !!value)[0]?.[0] as SizeName | undefined;
    // console.log("CALC DIVIDER", { firstKeyOfEnabledSize, sizes, fallback });
    if (!firstKeyOfEnabledSize) return fallback;
    return getDividerSizeInPixels(firstKeyOfEnabledSize);
};

export { calculateDividerSize, getDividerSizeInPixels };
