import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { css, useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { kronocampingColors } from "../kronocamping-color-context";

const KronocampingSjokantenPromoSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <SectionArea
            titleSize="XXL"
            titleWeight="900"
            sectionAreaId="sjokanten-promo-section"
            padding={{ top: 0 }}>
            <Link to="/shop/sjokanten">
                <FlexRow fillParentWidth padding={{ leftRight: 20 }}>
                    <SectionAreaCard
                        aspectRatio={isDesktop ? "3:2" : "1:1"}
                        background={{
                            fit: "COVER",
                            imageUrl:
                                "https://we-are-alfred.imgix.net/kronocamping/images/poster-kronocamping.jpeg?dpr=2&q=60&fm=jpg&w=500&fit=crop",
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ all: 25 }}
                            background={{ linearGradient: darkeningGradient }}
                            mainAxis="space-between"
                            crossAxis="flex-start">
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={"Gott att äta"}
                                    colorSet={kronocampingColors.primary}
                                />
                            </FlexRow>
                            <FlexColumn shrink={0} crossAxis="flex-start">
                                <TextBox
                                    text="A la Carté & stenugnsbakade pizzor"
                                    weight="800"
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 12px
                                            rgba(0, 0, 0, 0.5);
                                    `}
                                />
                                <VerticalDivider XXS />
                                <TextBox
                                    text="Restaurang Sjökanten"
                                    weight="900"
                                    size={1.9}
                                    color={theme.colors.white}
                                    styling={css`
                                        text-shadow: 0 4px 12px
                                            rgba(0, 0, 0, 0.5);
                                    `}
                                />
                            </FlexColumn>
                        </FlexColumn>
                    </SectionAreaCard>
                </FlexRow>
            </Link>
        </SectionArea>
    );
};

export { KronocampingSjokantenPromoSection };
