import { Branded, genericId } from "@towni/common";

type ModalId = Branded<string, "ModalId">;
const modalIdPrefix = "modal__";
const {
    zodSchema: modalIdZodSchema,
    factory: _modalIdFactory,
    assert: isModalId,
} = genericId<ModalId>(modalIdPrefix);

const modalIdFactory = (nameSuffix?: `${string}__`): ModalId => {
    return `${_modalIdFactory()}__${nameSuffix}` as ModalId;
};

export { modalIdFactory, isModalId, modalIdPrefix, modalIdZodSchema };
export type { ModalId };
