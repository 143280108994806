import { useModal } from "@@/modals/use-modal";
import { BusinessHoursModal } from "@@/shared/business-hours-modal";
import { Provider } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { BusinessHoursPill } from "./business-hours-pill";

type Props = {
    provider: Provider;
};
const BusinessHoursView: FC<Props> = props => {
    const [{ toggle }, modalId] = useModal("business_hours_modal__");

    return (
        <>
            <BusinessHoursPill
                businessHourSchedule={props.provider.options.businessHours}
                exceptions={props.provider.options.businessHourExceptions}
                onClick={toggle}
            />
            <BusinessHoursModal provider={props.provider} modalId={modalId} />
        </>
    );
};

export { BusinessHoursView };
