import { FrontendEnvironmentSettingName } from "@towni/common";
import { atom } from "jotai";
import * as React from "react";

type State = Record<FrontendEnvironmentSettingName, string>;

const EnvironmentRuntimeSettingsContext = React.createContext<
    State | undefined
>(undefined);

const EnvironmentRuntimeSettingsProvider = (props: {
    readonly override?: State;
    readonly children?: React.ReactNode;
}) => {
    const [state] = React.useState<State>(
        props.override ?? window.runtimeSettings,
    );
    return (
        <EnvironmentRuntimeSettingsContext.Provider value={state}>
            {props.children}
        </EnvironmentRuntimeSettingsContext.Provider>
    );
};

const useEnvironmentRuntimeSettings = () => {
    const context = React.useContext(EnvironmentRuntimeSettingsContext);
    if (context === undefined) {
        throw new Error(
            "useEnvironmentRuntimeSettings must be used within a EnvironmentRuntimeSettingsContext",
        );
    }
    return context;
};

const runtimeSettingsAtom = atom(() => window.runtimeSettings);

export {
    EnvironmentRuntimeSettingsProvider,
    runtimeSettingsAtom,
    useEnvironmentRuntimeSettings,
};
