import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Provider, ProviderId } from "@towni/common";
import { useMemo } from "react";
import { ProviderNotFoundError } from "../errors/provider-not-found-error";

const useProviderLoader = (providerId: ProviderId | undefined) => {
    const [provider, providerQuery] = useProvider(providerId);
    if (providerId && !provider && !providerQuery.isPending)
        throw new ProviderNotFoundError(providerId);
    if (providerId && !provider && providerQuery.isError && providerQuery.error)
        throw new ProviderNotFoundError(providerId, providerQuery.error);
    if (providerId && !provider && !providerQuery.isPending)
        throw new ProviderNotFoundError(providerId, providerQuery.error);

    return useMemo(
        () =>
            [provider, providerQuery.isPending] as
                | [Provider, false]
                | [undefined, true],
        [provider, providerQuery.isPending],
    );
};

export { useProviderLoader };
