import { apiFetchClient } from "@@/shared/fetch-client";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { emptyArrayOf, QueryResponse, UserReference } from "@towni/common";
import { useMemo } from "react";

const mainQueryKey = "user-references";
const mainRoute = "user-references";

const useUserReference = (
    id?: string,
): readonly [
    UserReference | undefined,
    UseQueryResult<QueryResponse<UserReference | null>>,
] => {
    const queryResult = useQuery<QueryResponse<UserReference | null>>({
        queryKey: [mainQueryKey, id],
        queryFn: () =>
            apiFetchClient.get<QueryResponse<UserReference | null>>({
                route: `/${mainRoute}/${encodeURIComponent(id ?? "")}`,
            }),
        enabled: !!id,
    });
    return useMemo(
        () => [queryResult.data?.data || undefined, queryResult] as const,
        [queryResult],
    );
};
const useUserReferences = (
    ids: string[] = [],
): readonly [
    UserReference[],
    UseQueryResult<QueryResponse<UserReference[]>>,
] => {
    const queryResult = useQuery<QueryResponse<UserReference[]>>({
        enabled: !!ids.length,
        queryKey: [mainQueryKey, ...ids],
        queryFn: () =>
            apiFetchClient.get<QueryResponse<UserReference[]>>({
                route: `/${mainRoute}/?ids=${encodeURIComponent(
                    ids.join(","),
                )}`,
            }),
    });

    return useMemo(
        () =>
            [
                queryResult.data?.data ?? emptyArrayOf<UserReference>(),
                queryResult,
            ] as const,
        [queryResult],
    );
};

export { useUserReference, useUserReferences };
