import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { backendHost } from "@@/shared/fetch-client";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { useTheme } from "@emotion/react";
import {
    ImageUrl,
    ProductId,
    RemSize,
    translation,
    unique,
} from "@towni/common";
import * as React from "react";
import {
    ResponsiveStoreFrontHzCard,
    ResponsiveStoreFrontHzProductCard,
} from "../../shared/store-front-hz-product-card";

const LackoDiscoverMoreSection = () => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();
    const theme = useTheme(); // TODO!: SWITCH TO PROD PRODUCT IDS
    const productIds = React.useMemo(
        () => [
            getProductId("product__IG8ZXjoF9TW197TdJKI8" as ProductId), // Taxi Lidköping - Sista-Milen-Bilen
            getProductId("product__MbhGUu1a7OXrpkCCcxiF" as ProductId), // Sigird Storråda - Segla ut på vänern
            getProductId("product__sejNlbfid_V6ET3_FPZp" as ProductId), // Naven - Glasstur till spiken
            // getProductId("HYR EN ELBIL, ÅKESSONS, lkp.nu/elbil" as ProductId), //
            getProductId("product__DGklaR0Cgs4-mPhBH79m" as ProductId), // Krokens Alpacka - Alpackapromenad med go våffelfika
            getProductId("product_QPlrDRjoZzFoVvZCZ04V" as ProductId), // Kronocamping - Utomhusyoga vid stranden
            getProductId("product__ZQ4qlGJuSxOv4FLQsWeD" as ProductId), // Vänermuseet - Egen runsten
            // getProductId("HYR ELCYKEL, lkp.nu/elcykla" as ProductId), //
            getProductId("product_jB98q59qEZuqWPB7KI4P" as ProductId), // Motorstadion - Drop-in 9 min
            getProductId("product__-Au9W2Cla7UIpLEL8l_G" as ProductId), // Naven - Vraksafari PLUS med guide (ej aktiverad ännu)
            getProductId("product__Z9I0X7va6amyU06DlY9N" as ProductId), // Västgötaresan - Stadsvandring i Lidköping
            getProductId("product_mdE9Ndq074AEajGfkund" as ProductId), // Motorstadion - Le-mans
            getProductId("product__58tF0mQWD_36xWGIDhU3" as ProductId), // Vänermuseet - Dramavisning Berättelser från Lidköping
            // getProductId("Hitta mer på towni, lokal mat och upplevelser" as ProductId), //
        ],
        [],
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="lacko-discover-more-section"
            title={translation({
                sv: "Mer i närheten...",
                en: "More nearby...",
            })}
            subtitle={translation({
                sv: "Hitta fler härliga tillsammans med towni.se",
                en: "Find more great ones together with towni.se",
            })}
            background={{ color: theme.colors.primary.light }}
            css={{
                borderRadius: isDesktop ? 10 : 0,
            }}
            // background={{
            //     color: lackoColors.lightGreenBackground,
            // }}
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    let extraOutput: JSX.Element | null = null;
                    switch (index) {
                        case 3: {
                            // HYR EN ELBIL
                            extraOutput = (
                                <ResponsiveStoreFrontHzCard
                                    key={"hyrenelbil"}
                                    cardSize={1.2 as RemSize}
                                    aspectRatio="3:4"
                                    actionText={translation({
                                        sv: "Läs mer",
                                        en: "Read more",
                                    })}
                                    actionTextColorSet={theme.colors.primary}
                                    hideKicker
                                    title={translation({
                                        sv: "Hyr en elbil",
                                        en: "Rent an electric car",
                                    })}
                                    subtitle={translation({
                                        sv: "Åkessons Bil. Vi hjälper dig med bil!",
                                        en: "Åkessons Bil. We help you with the car!",
                                    })}
                                    linkTo={"https://lkp.nu/elbil"}
                                    imageUrl={
                                        `${backendHost}/public/storage/towni-brand/misc/elbil.jpg` as ImageUrl
                                    }
                                />
                            );
                            break;
                        }
                        case 7: {
                            // HYR ELCYKEL
                            extraOutput = (
                                <ResponsiveStoreFrontHzCard
                                    key={"hyrenelcykel"}
                                    cardSize={1.2 as RemSize}
                                    aspectRatio="3:4"
                                    actionText={translation({
                                        sv: "Läs mer",
                                        en: "Read more",
                                    })}
                                    actionTextColorSet={theme.colors.primary}
                                    hideKicker
                                    title={translation({
                                        sv: "Hyr en elcykel",
                                        en: "Rent an electric bike",
                                    })}
                                    subtitle={translation({
                                        sv: "Upptäck med el i benen!",
                                        en: "Discover the landscape all charged up!",
                                    })}
                                    linkTo={"https://lkp.nu/elcykla"}
                                    imageUrl={
                                        `${backendHost}/public/storage/towni-brand/misc/elcykla.jpg` as ImageUrl
                                    }
                                />
                            );
                            break;
                        }
                        default:
                            break;
                    }
                    return (
                        <>
                            {extraOutput}
                            <ResponsiveStoreFrontHzProductCard
                                key={hasDuplicates ? index : id}
                                cardSize={1.2 as RemSize}
                                aspectRatio="3:4"
                                actionText={translation({
                                    sv: "Läs mer",
                                    en: "Read more",
                                })}
                                actionTextColorSet={theme.colors.primary}
                                productId={id}
                                hideKicker
                            />
                            {index === productIds.length - 1 && (
                                <ResponsiveStoreFrontHzCard
                                    key={"hittemerpåtowni"}
                                    cardSize={1.2 as RemSize}
                                    aspectRatio="3:4"
                                    actionText={translation({
                                        sv: "Upptäck",
                                        en: "Discover",
                                    })}
                                    actionTextColorSet={theme.colors.primary}
                                    hideKicker
                                    title={translation({
                                        sv: "Hitta mer på towni.se »",
                                        en: "Find more on towni.se »",
                                    })}
                                    subtitle={translation({
                                        sv: "Lokal mat och upplevelser!",
                                        en: "Local food and experiences!",
                                    })}
                                    linkTo={"https://towni.se"}
                                    imageUrl={"" as ImageUrl}
                                />
                            )}
                        </>
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { LackoDiscoverMoreSection };
