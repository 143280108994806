import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import { Product, ProductId } from "@towni/common";
import { productMainQueryKey } from "../main-query-key";
import { fetchProduct } from "./product.fetcher";

const useProduct = (productId: ProductId | undefined) =>
    useSingleApiResource<Product>(
        [productMainQueryKey, productId],
        fetchProduct(productId),
        {
            enabled: !!productId,
        }
    );

export { useProduct };
