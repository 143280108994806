import { SectionId, Translatable } from "@towni/common";
import { AnchorId, anchorIdFactory } from "./anchor-id";

type Anchor = {
    _id: AnchorId;
    title: Translatable;
    description?: Translatable;
    containerItemElement: HTMLDivElement | null;
    navBarItemElement: HTMLDivElement | null;
    disabled: boolean;
    // >> Ugly quickfix to make anchor able to check if section has any items, and therefore should be hidden
    sectionId: SectionId;
    // << Ugly quickfix to make anchor able to check if section has any items, and therefore should be hidden
};

const anchorFactory = (params: {
    title: Translatable;
    description?: Translatable;
    sectionId: SectionId;
    disabled?: boolean;
    containerItemElement?: HTMLDivElement | null;
    navBarItemElement?: HTMLDivElement | null;
}) => {
    const anchor: Anchor = {
        _id: anchorIdFactory(),
        title: params.title,
        description: params.description,
        containerItemElement: params.containerItemElement ?? null,
        navBarItemElement: params.navBarItemElement ?? null,
        sectionId: params.sectionId,
        disabled: params.disabled ?? false,
    };

    return anchor;
};

export type { Anchor };
export { anchorFactory };
