import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { LinkToProviderHome } from "@@/shared/text/link-to-provider-home";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    AspectRatio,
    Provider,
    asDate,
    getProviderStatus,
    translation,
} from "@towni/common";
import { isFuture } from "date-fns";
import { FC } from "react";

type Props = {
    provider: Provider;
    aspectRatio: AspectRatio;
};
const ProviderCard: FC<Props> = ({ provider, aspectRatio }) => {
    const theme = useTheme();
    const translate = useTranslate();

    const posterImage = provider.meta.images[0];
    const providerStatus = getProviderStatus(provider);

    const isHidden = providerStatus === "HIDDEN";

    const isPaused =
        provider.options.paused &&
        isFuture(asDate(provider.options.paused?.until));

    const pauseReasonTitle = isHidden
        ? translate(
              translation({
                  sv: "gömd",
                  en: "hidden",
              }),
          )
        : isPaused
          ? translate(provider.options.paused?.reasonTitle)
          : null;

    return (
        <LinkToProviderHome key={provider._id} provider={provider}>
            <FlexColumn padding={{ all: 20 }}>
                <SectionAreaCard
                    aspectRatio={aspectRatio}
                    background={{
                        color: theme.colors.primary,
                        imageReference: posterImage,
                        fit: "COVER",
                        repeat: "NO-REPEAT",
                    }}>
                    <FlexColumn
                        fillParentWidth
                        height="50%"
                        tag="SECTION_AREA_CARD"
                        shrink={0}
                        grow={1}
                        padding={{ all: 20 }}
                        mainAxis="flex-end"
                        crossAxis="center"
                        background={{
                            linearGradient: posterImage
                                ? darkeningGradient
                                : undefined,
                        }}>
                        <TextBox
                            text={provider.meta.title}
                            color={theme.colors.white}
                            weight="700"
                            align="center"
                            size={"XXL"}
                            styling={css`
                                text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
                            `}
                        />
                        <VerticalDivider XXS />
                        {pauseReasonTitle ? (
                            <TextBox
                                text={`(${pauseReasonTitle})`}
                                case="LOWERCASE"
                                color={theme.colors.white.light90}
                                weight="700"
                                size="L"
                                styling={css`
                                    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
                                `}
                            />
                        ) : null}
                    </FlexColumn>
                </SectionAreaCard>
                <FlexColumn fillParentWidth padding={{ all: 2 }}>
                    <VerticalDivider />
                    <TextBox
                        lineClamp={1}
                        text={provider.meta.subtitle}
                        color={theme.colors.black}
                        weight="700"
                        size="ML"
                    />
                    <VerticalDivider XXS />
                    <TextBox
                        lineClamp={1}
                        text={provider.meta.description}
                        color={theme.colors.black.light30}
                        size="M"
                    />
                </FlexColumn>
            </FlexColumn>
        </LinkToProviderHome>
    );
};

export { ProviderCard };
