import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import { Invitation, InvitationId } from "@towni/common";
import { invitationMainQueryKey } from "../command-hooks/main-query-key";
import { fetchInvitation } from "./invitation.fetcher";

const useInvitation = (invitation: InvitationId | undefined) =>
    useSingleApiResource<Invitation>(
        [invitationMainQueryKey, invitation],
        fetchInvitation(invitation),
        {
            enabled: !!invitation,
        }
    );

export { useInvitation };
