import { browserLogger } from "@@/settings/browser-logger";
import {
    generateId,
    OrderGroupId,
    OrderId,
    PhoneNumber,
    Price,
} from "@towni/common";
import { Except } from "type-fest";

type PaymentDetails = {
    _id: string;
    orderGroupId: OrderGroupId;
    orderId: OrderId;
    price: Price;
    title?: string;
    type: "buy" | "book" | "donate";
    payerPhoneNumber: PhoneNumber | undefined;
};

const createPaymentDetails = (
    params: Except<PaymentDetails, "_id">
): PaymentDetails => {
    return {
        ...params,
        _id: generateId({ prefix: "payment_details__" }),
    };
};

const updatePaymentDetails = (details: PaymentDetails | undefined) => {
    if (!details) {
        browserLogger.log(
            "no original payment details provided, update helpers will error out if used"
        );
    }
    return {
        price: (price: Price): PaymentDetails => {
            if (!details)
                throw new Error("no original payment details provided yet");
            return {
                ...details,
                price,
            };
        },
        orderGroupId: (orderGroupId: OrderGroupId): PaymentDetails => {
            if (!details)
                throw new Error("no original payment details provided yet");
            return {
                ...details,
                orderGroupId,
            };
        },
        orderId: (orderId: OrderId): PaymentDetails => {
            if (!details)
                throw new Error("no original payment details provided yet");
            return {
                ...details,
                orderId,
            };
        },
        title: (title: string | undefined): PaymentDetails => {
            if (!details)
                throw new Error("no original payment details provided yet");
            return {
                ...details,
                title,
            };
        },
        type: (type: PaymentDetails["type"]): PaymentDetails => {
            if (!details)
                throw new Error("no original payment details provided yet");
            return {
                ...details,
                type,
            };
        },
    };
};

export type { PaymentDetails };
export { createPaymentDetails, updatePaymentDetails };
