import { useTheme } from "@emotion/react";
import * as React from "react";
import { FC } from "react";
import { FlexRow } from "../flex-containers";
import { PaddingProps } from "../padding";

type Props = {
    children: React.ReactNode;
    /**
     * defaults to { leftRight: 20, bottom: 20, top: 100 }
     *
     * @type {PaddingProps}
     */
    padding?: PaddingProps;
    position?: React.CSSProperties["position"];
};

/**
 * Faded bottom area
 *
 * ## NOTE
 * pointer events are disabled, så if you want your content to receive clicks, make sure to enable pointer-events on your content
 */
const FadedBottomContainer: FC<Props> = props => {
    const theme = useTheme();
    return (
        <FlexRow
            css={{
                position: props.position || "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 100,
                pointerEvents: "none",
            }}
            padding={props.padding ?? { leftRight: 20, bottom: 20, top: 80 }}
            background={{
                linearGradient: {
                    _type: "LINEAR_GRADIENT",
                    degrees: 0,
                    steps: [
                        {
                            percentage: 0.35,
                            color: theme.colors.default.background,
                        },
                        {
                            percentage: 1,
                            color: theme.colors.transparent,
                        },
                    ],
                },
            }}
            fillParentWidth>
            {props.children}
        </FlexRow>
    );
};

export { FadedBottomContainer };
