import { BookableExtrasRow } from "@@/carts/bookable-checkout/bookable-extras-row";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import {
    Order,
    getChildOptionals,
    groupBy,
    isOrderItemBuyable_V2,
    translation,
} from "@towni/common";
import * as React from "react";

type Props = { order: Order };

const OrderExtras = ({ order }: Props) => {
    const extras = order.orderItems.filter(isOrderItemBuyable_V2);
    if (extras.length === 0) return null;

    const extrasGroup = groupBy(
        extras,
        extra => extra.extraGroup?._id ?? "no_group",
    );
    const keys = Array.from(extrasGroup.keys());

    return (
        <>
            <FlexColumn tag="order_extras">
                <VerticalDivider M />
                <FlexRow padding={{ leftRight: 20 }} crossAxis="center">
                    <TextBox
                        size={1.25}
                        weight="800"
                        text={translation({
                            sv: "Extras",
                            en: "Extras",
                        })}
                    />
                </FlexRow>
                <VerticalDivider M />
                <HorizontalLine />

                <VerticalDivider S />
                <ForEach itemOf={keys} getKey={key => key}>
                    {key => {
                        const items = extrasGroup.get(key);
                        if (!items || items.length === 0) return null;

                        //Get meta from first item
                        const groupDetails = items[0].extraGroup;

                        return (
                            <>
                                {groupDetails ? (
                                    <FlexRow
                                        crossAxis="flex-start"
                                        shrink={0}
                                        fillParentWidth
                                        padding={{
                                            leftRight: 20,
                                            topBottom: 10,
                                        }}>
                                        <TextBox
                                            text={groupDetails.meta.title}
                                        />
                                    </FlexRow>
                                ) : null}

                                <ForEach
                                    itemOf={items}
                                    getKey={item => item._id}>
                                    {orderItem => (
                                        <React.Fragment key={orderItem._id}>
                                            <BookableExtrasRow
                                                actionsAllowed={false}
                                                cartItem={orderItem}
                                                optional={getChildOptionals(
                                                    orderItem,
                                                    order.orderItems,
                                                )}
                                            />
                                        </React.Fragment>
                                    )}
                                </ForEach>
                            </>
                        );
                    }}
                </ForEach>
                <VerticalDivider M />
            </FlexColumn>
        </>
    );
};

export { OrderExtras };
