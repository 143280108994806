import { useBookingContext } from "@@/products/bookables/booking-context";
import { useCheckBookableSession } from "@@/products/bookables/hooks/use-check-bookable-session";
import { browserLogger } from "@@/settings";
import { Conditional } from "@@/shared/conditional";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { TextBox, TimeTextBox } from "@@/shared/text";
import { DateTextBox } from "@@/shared/text/date-text-box";
import { useTheme } from "@emotion/react";
import {
    BookableSession,
    FullDate,
    asDate,
    createSessionStatus,
    sortBy,
    toFullDate,
    translation,
} from "@towni/common";
import { useCallback, useMemo } from "react";

type Props = {
    sessions: BookableSession[];
    fullDateFilter?: FullDate;
};

const BookableSessionSelectTimeGrid = (props: Props) => {
    const theme = useTheme();
    const state = useBookingContext(state => ({
        optionValues: state.optionValues,
        session: state.session,
        clearSession: state.clearSession,
        setSession: state.setSession,
    }));
    const [checkAvailability] = useCheckBookableSession();
    const sessions = useMemo(() => {
        const fullDates = new Set<FullDate>();
        const ordered = new Map<FullDate, BookableSession[]>();

        const sorted = props.sessions.sort(
            sortBy(session => session.start.unix),
        );

        for (const session of sorted) {
            const fullDate = toFullDate(session.start, currentTimezoneName);
            if (props.fullDateFilter && fullDate !== props.fullDateFilter)
                continue;
            const current = ordered.get(fullDate) ?? [];
            fullDates.add(fullDate);
            ordered.set(fullDate, [...current, session]);
        }
        return {
            fullDates: Array.from(fullDates).sort(),
            ordered,
        };
    }, [props.sessions, props.fullDateFilter]);

    const onSessionSelect = useCallback(
        (session: BookableSession | undefined) => {
            if (!session) {
                state.clearSession();
                return;
            }
            state.setSession(session, false);
        },
        [state.clearSession, state.setSession],
    );

    const colors = {
        backgroundSelected: theme.colors.primary.asString,
        textSelected: theme.colors.primary.text.asString,
        background: theme.colors.default.background.asString,
        text: theme.colors.default.text.asString,
        border: theme.colors.primary.border.asString,
        borderSelected: theme.colors.primary.border.asString,
    };

    return (
        <>
            <LayoutGrid
                css={{
                    gridTemplateColumns: "repeat(4, minmax(75px, 1fr))", // "repeat(auto-fit, minmax(75px, 1fr))",
                    gridAutoRows: "minmax(min-content, max-content)",
                    gap: 15,
                    flexGrow: 1,
                    paddingLeft: 20,
                    paddingRight: 20,
                    flexShrink: 1,
                    overflowY: "scroll",
                }}>
                <ForEach
                    itemOf={sessions.fullDates}
                    getKey={fullDate => fullDate}>
                    {(fullDate, index) => {
                        const isFirstDay = index === 0;
                        return (
                            <>
                                <LayoutCell
                                    css={{
                                        gridColumn: "1 / -1",
                                        backgroundColor:
                                            theme.colors.black.light95.asString,
                                        marginLeft: -20,
                                        marginRight: 20,
                                        width: "calc(100% + 40px)",
                                        padding: "15px 20px",
                                        marginBottom: 10,
                                        marginTop: isFirstDay ? 0 : 10,
                                    }}>
                                    <DateTextBox
                                        date={asDate(fullDate)}
                                        format="LONG"
                                        weight="700"
                                    />
                                </LayoutCell>
                                <ForEach
                                    itemOf={(
                                        sessions.ordered.get(fullDate) ?? []
                                    ).map(item => {
                                        //Check if session is available
                                        const resourceAvailability =
                                            checkAvailability(item);
                                        const isBookable =
                                            !resourceAvailability.resourceAvailabilities.some(
                                                a => !a.available,
                                            );

                                        browserLogger.info({
                                            resourceAvailability,
                                            isBookable,
                                        });

                                        if (isBookable) return item;
                                        else
                                            return {
                                                ...item,
                                                status: createSessionStatus({
                                                    type: "FULL",
                                                }),
                                            } as BookableSession;
                                    })}>
                                    {session => {
                                        const backgroundColor =
                                            state.session?._id === session._id
                                                ? colors.backgroundSelected
                                                : colors.background;
                                        const textColor =
                                            state.session?._id === session._id
                                                ? colors.textSelected
                                                : colors.text;
                                        const borderColor =
                                            state.session?._id === session._id
                                                ? colors.borderSelected
                                                : colors.border;
                                        return (
                                            <LayoutCell
                                                key={session._id}
                                                css={
                                                    session.status.type !==
                                                    "ACTIVE"
                                                        ? {
                                                              opacity: 0.4,
                                                              willChange:
                                                                  "filter",
                                                              filter: "grayscale(100%)",
                                                          }
                                                        : undefined
                                                }>
                                                <FlexColumn
                                                    fillParent
                                                    shrink={0}
                                                    onClick={
                                                        session.status.type ===
                                                        "ACTIVE"
                                                            ? () => {
                                                                  if (
                                                                      state
                                                                          .session
                                                                          ?._id ===
                                                                      session._id
                                                                  ) {
                                                                      onSessionSelect(
                                                                          undefined,
                                                                      );
                                                                      return;
                                                                  }

                                                                  onSessionSelect(
                                                                      session,
                                                                  );
                                                              }
                                                            : undefined
                                                    }
                                                    mainAxis="center"
                                                    crossAxis="center"
                                                    css={{
                                                        backgroundColor,
                                                        borderRadius:
                                                            theme.radius,
                                                        border: `2.5px solid ${borderColor}`,
                                                        padding: "12px 15px",
                                                    }}>
                                                    <TimeTextBox
                                                        time={session.start}
                                                        color={textColor}
                                                        size={1}
                                                        shrink={0}
                                                        align="center"
                                                        weight="900"
                                                        css={{
                                                            opacity:
                                                                session.status
                                                                    .type !==
                                                                "ACTIVE"
                                                                    ? 0.7
                                                                    : 1,
                                                        }}
                                                    />
                                                </FlexColumn>
                                            </LayoutCell>
                                        );
                                    }}
                                </ForEach>
                            </>
                        );
                    }}
                </ForEach>
                <LayoutCell css={{ gridColumn: "1 / -1" }}>
                    <Conditional
                        when={!!props.fullDateFilter}
                        render={() => (
                            <FlexRow
                                fillParentWidth
                                mainAxis="center"
                                crossAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "Det kan finnas fler tider att boka andra datum. Välj ett annat datum för att se tider för en annan dag.",
                                        en: "There may be more times to book other dates. Choose another date to see times for another day.",
                                    })}
                                    padding={{ all: 50 }}
                                    align="center"
                                    color={theme.colors.black.light80}
                                    italic
                                    size={0.9}
                                />
                            </FlexRow>
                        )}
                    />
                    <VerticalDivider XL />
                </LayoutCell>
            </LayoutGrid>
        </>
    );
};

export { BookableSessionSelectTimeGrid };
