import { HorizontalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ResponsiveStoreFrontHzProductCard } from "@@/store-fronts/shared/store-front-hz-product-card";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
import { useNavenColorContext } from "../naven-color-context";

// type Props = {};
const NavenExploreSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();
    const colors = useNavenColorContext();

    const productIds = React.useMemo(
        () => [
            // naven
            getProductId("product__H2lnnnkH_HoTneNhRsb6" as ProductId),
            // lackö
            getProductId("product__V_horfa-RDlQ3c5k-Pt-" as ProductId),
        ],
        [],
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="naven-explore"
            title={translation({
                sv: "Kanoter för utflykter",
                en: "Canoes for excursions",
            })}
            subtitle={translation({
                sv: "Passar hellre kanot bättre. En kanot är lite tryggare och enkelt att packa med packning i. Vi har flera så ta med dig gänget ut!",
                en: "Prefer canoe instead. A canoe is a little safer and easy to pack with packing in. We have several so take the gang out!",
            })}
            subtitleColor={theme.colors.black.light15}
            subTitleLineHeight={1.5 as RemSize}
            subtitleSize={1 as RemSize}
            padding={isDesktop ? { all: 10 } : { topBottom: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <React.Fragment key={hasDuplicates ? index : id}>
                            <ResponsiveStoreFrontHzProductCard
                                cardSize={1.35 as RemSize}
                                productId={id}
                                actionText={text.book}
                                aspectRatio={isDesktop ? "4:3" : "3:4"}
                                actionTextColorSet={colors.primary}
                            />
                        </React.Fragment>
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
};

export { NavenExploreSection };
