import * as React from "react";
import { PhoneSignInOrUpPage } from "../authentication/phone/phone-sign-in-or-up-page";

type Props = {
    closeModal: () => void;
};

const CheckoutAuthentication = ({ closeModal }: Props) => {
    return <PhoneSignInOrUpPage hide={closeModal} />;
};

export { CheckoutAuthentication };
