import { HorizontalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useTheme } from "@emotion/react";
import {
    categoryTagFilter,
    ColorItem,
    Provider,
    Translatable,
} from "@towni/common";
import { FC } from "react";
import { ProviderCard } from "./provider-sized-card";

type Props = {
    title: Translatable;
    subtitle: Translatable;
    providers: Provider[] | undefined;
    backgroundColor?: ColorItem;
    categoryFilter?: string[];
    storeFrontSectionId: string;
};

const ProviderCardSection: FC<Props> = props => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    let providers = props.providers ?? [];
    for (const category of props.categoryFilter ?? []) {
        providers = providers.filter(categoryTagFilter(category));
    }

    if (!providers?.length) return null;

    return (
        <SectionArea
            title={props.title}
            subtitle={props.subtitle}
            titleColor={theme.colors.default.text}
            subtitleColor={theme.colors.black.light20}
            sectionAreaId={props.storeFrontSectionId}
            css={{ borderRadius: isDesktop ? 10 : 0 }}
            background={{
                color: props.backgroundColor ?? "#ffffff",
            }}>
            <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {providers.map(provider => (
                    <ProviderCard key={provider._id} provider={provider} />
                ))}
                <HorizontalDivider XS />
            </SectionAreaList>
        </SectionArea>
    );
};

export { ProviderCardSection };
