import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { HorizontalLine } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { useTheme } from "@emotion/react";
import { Order, parseProviderPosterImage } from "@towni/common";
import { Receipt_OrderProviderDetails } from "../bookables/shared/receipt-order-provider-details";

type Props = {
    orders: Order[];
    posterDisabled?: boolean;
    children: React.ReactNode;
};

const Receipt_OrderGroupDetails = (props: Props) => {
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { orders } = props;
    const [provider, { isPending: _isLoadingProvider }] = useProvider(
        orders[0].providerId,
    );
    const poster = props.posterDisabled
        ? undefined
        : parseProviderPosterImage(provider);

    const maxWidth = 700;
    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 20px",
            }}>
            <FlexColumn
                fillParentWidth
                css={{
                    overflow: "hidden",
                    maxWidth,
                    borderRadius: 20,
                    backgroundColor: theme.colors.white.asString,
                }}>
                {/* Header */}
                <FlexColumn
                    fillParentWidth
                    crossAxis="stretch"
                    mainAxis={poster ? "flex-end" : "center"}
                    background={{
                        imageReference: poster,
                        imageSize: {
                            imageWidth: isMobile ? 400 : maxWidth,
                        },
                        fit: "COVER",
                    }}
                    padding={{ top: poster ? 140 : 10 }}>
                    <FlexRow
                        fillParentWidth
                        padding={{ all: 20, top: poster ? 140 : 20 }}
                        mainAxis="space-between"
                        crossAxis="center"
                        background={{
                            linearGradient: {
                                _type: "LINEAR_GRADIENT",
                                steps: [
                                    {
                                        color: theme.colors.white.withAlpha(1),
                                        percentage: 0,
                                    },
                                    {
                                        color: theme.colors.white.withAlpha(
                                            0.8,
                                        ),
                                        percentage: 0.4,
                                    },
                                    {
                                        color: theme.colors.white.withAlpha(0),
                                        percentage: 1,
                                    },
                                ],
                                degrees: 0,
                            },
                        }}>
                        <Receipt_OrderProviderDetails order={orders[0]} />
                        <HorizontalLine />
                    </FlexRow>
                </FlexColumn>
                <HorizontalLine size={8} color={theme.colors.black.light95} />
                {props.children}
            </FlexColumn>
        </div>
    );
};

export { Receipt_OrderGroupDetails };
