import * as React from "react";
type Props = {
    width?: React.CSSProperties["width"];
    height?: React.CSSProperties["height"];
};
const TicketSvg = (props: Props) => {
    return (
        <svg
            width={props.width ?? 335}
            height={props.height ?? 206}
            viewBox="0 0 335 206"
            preserveAspectRatio="none"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 0C7.163 0 0 7.163 0 16v75c7.255 0 13.137 5.868 13.137 13.107 0 7.238-5.882 13.106-13.137 13.106V190c0 8.837 7.163 16 16 16h303c8.836 0 16-7.163 16-16v-72.787c-7.256 0-13.138-5.868-13.138-13.106C321.862 96.868 327.744 91 335 91V16c0-8.837-7.164-16-16-16H16z"
                fill="#fff"
            />
        </svg>
    );
};

export { TicketSvg };
