import { useModal } from "@@/modals/use-modal";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { FlexRow } from "@@/shared/flex-containers";
import { EditAccommodationSubTypeModal } from "@@/shared/form/form2-accommodation-sub-types/edit-accommodation-sub-type.modal";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text/text-box";
import { useTheme } from "@emotion/react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { AccommodationSubType, AccommodationSubTypeId } from "@towni/common";

const AccommodationSubTypeRow = (props: {
    subType: AccommodationSubType;
    onEdit: (subType: AccommodationSubType) => Promise<void> | void;
    onRemove: (subTypeId: AccommodationSubTypeId) => Promise<void> | void;
}) => {
    const theme = useTheme();
    const subType = props.subType;
    const [{ show }, modalId] = useModal();
    return (
        <FlexRow
            mainAxis="flex-start"
            crossAxis="center"
            onClick={show}
            css={{
                cursor: "pointer",
                flex: 1,
                paddingLeft: 14,
                paddingTop: 8,
                paddingBottom: 8,
                paddingRight: 4,
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.colors.default.border.asString,
                backgroundColor: theme.colors.default.background.asString,
            }}>
            <FlexRow mainAxis="space-between" fillParentWidth>
                <FlexRow crossAxis="center">
                    <DynamicIcon icon={subType.iconName} fixedWidth />
                    <HorizontalDivider />
                    <TextBox text={subType.title} />
                </FlexRow>
                <HorizontalDivider />
                <ButtonTransparent
                    onClick={event => {
                        event.stopPropagation();
                        props.onRemove(subType._id);
                    }}
                    css={{
                        justifyContent: "flex-end",
                    }}>
                    <Icon icon={faTrash} />
                </ButtonTransparent>
                <EditAccommodationSubTypeModal
                    modalId={modalId}
                    subType={subType}
                    onEdit={props.onEdit}
                />
            </FlexRow>
        </FlexRow>
    );
};

export { AccommodationSubTypeRow };
