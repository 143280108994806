import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { SelectBox } from "@@/shared/select-box";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import { translation } from "@towni/common";
import { useStripePayment } from "../stripe-payment.context";

type Props = {
    value: boolean;
    toggle: (saveCard: boolean) => void;
};
const PaymentSaveCardCheckbox = (props: Props) => {
    const theme = useTheme();
    const [state] = useStripePayment();
    const disabled = state.status === "submitting";
    return (
        <FlexColumn fillParentWidth>
            <FlexRow
                mainAxis="space-between"
                crossAxis="flex-start"
                padding={{ leftRight: 33, bottom: 5, top: 4 }}
                fillParentWidth>
                <FlexRow
                    crossAxis="center"
                    shrink={0}
                    onClick={() => {
                        if (disabled) return;
                        props.toggle(!props.value);
                    }}>
                    <SelectBox
                        disabled={disabled}
                        selected={props.value}
                        shape="SQUARE"
                        size={18}
                    />
                    <HorizontalDivider S />
                    <TextBox
                        text={translation({
                            sv: "Spara kort",
                            en: "Save card",
                        })}
                        size={0.95}
                        color={
                            disabled
                                ? theme.colors.default.text.value.withAlpha(0.3)
                                : undefined
                        }
                        shrink={0}
                    />
                </FlexRow>
            </FlexRow>
            <FlexRow
                shrink={1}
                crossAxis="flex-start"
                padding={{ leftRight: 33, topBottom: 5 }}>
                <Icon icon={faShieldCheck} color={theme.colors.primary} />
                <HorizontalDivider />
                <TextBox
                    text={translation({
                        sv: "Ditt kort sparas säkert och krypterat hos vår betalningsleverantör",
                        en: "Your card is saved securely and encrypted by our payment provider",
                    })}
                    size={0.7}
                    css={{
                        maxWidth: 220,
                    }}
                    color={theme.colors.black.light60}
                />
                <HorizontalDivider XXL />
            </FlexRow>
        </FlexColumn>
    );
};
export { PaymentSaveCardCheckbox };
