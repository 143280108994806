import { ResourceIcon } from "@@/backoffice/for-providers/inventory/resources/resource-icon";
import { RowSelectButton as ListSelectButton } from "@@/shared/buttons/list-select-button";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useSizeTracker } from "@@/shared/use-size-tracker";
import { WidthAndHeight } from "@@/shared/width-and-height";
import { useTheme } from "@emotion/react";
import { Padding, ResourceGlobal, ResourceId } from "@towni/common";

type Props = {
    resource: ResourceGlobal;
    selected: boolean;
    onSizeChange?: (size: WidthAndHeight) => void;
    toggle: (resourceId: ResourceId) => void;
    /**
     * defaults to leftRight: 20
     */
    padding?: Padding;
};

const ResourcePickerRow = (props: Props) => {
    const theme = useTheme();
    const { resource, selected, toggle } = props;

    // Track size
    const [elementRef] = useSizeTracker(props.onSizeChange);

    const inactive = false; //resource.status.type !== "ACTIVE";
    const resourceColor =
        props.resource._type === "RESOURCE_GROUP"
            ? theme.colors.secondary.main.value.withAlpha(0.6).asString
            : theme.colors.primary.main.value.withAlpha(0.6).asString;
    return (
        <FlexColumn
            fillParentWidth
            ref={elementRef}
            mainAxis="center"
            crossAxis="flex-start"
            padding={{ leftRight: 20, ...(props.padding ?? {}) }}>
            <FlexRow
                fillParentWidth
                radius={7}
                background={{
                    color: inactive
                        ? theme.colors.disabled.background
                        : selected
                          ? theme.colors.primary.light
                          : theme.colors.default.background,
                }}
                css={{
                    padding: 15,
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: `1px solid
                    ${theme.colors.default.border.asString}`,
                    borderLeft: `7px solid ${resourceColor}`,
                }}
                mainAxis="space-between"
                crossAxis="center"
                onClick={() => toggle(resource._id)}>
                <FlexRow css={{ flex: 1 }}>
                    <ResourceIcon
                        resource={resource}
                        color={
                            inactive
                                ? theme.colors.disabled.text
                                : theme.colors.default.text
                        }
                        fixedWidth
                        size={1}
                    />
                    <HorizontalDivider M />
                    <TextBox
                        text={resource.meta.title}
                        color={
                            inactive
                                ? theme.colors.disabled.text
                                : theme.colors.default.text
                        }
                        lineClamp={2}
                        shrink={1}
                        grow={1}
                    />
                    <HorizontalDivider />
                </FlexRow>
                <ListSelectButton
                    selected={selected}
                    onClick={event => {
                        event.stopPropagation();
                        toggle(resource._id);
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
};

export { ResourcePickerRow };
