import { useMe } from "@@/authentication/me/me-context";
import { useProvidersForUser } from "@@/providers/state/queries/providers-for-user/use-providers-for-user";
import { HorizontalDivider, HorizontalLine } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox, TextEdit } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faUsersCog } from "@fortawesome/pro-solid-svg-icons";
import { sortBy, translation } from "@towni/common";
import * as React from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    divider?: JSX.Element;
};

const UserModalProviderLinks = (props: Props) => {
    const [me] = useMe();
    const translate = useTranslate();
    const theme = useTheme();
    const navigate = useNavigate();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [providersForUser] = useProvidersForUser(me?._id);
    const [filter, setFilter] = React.useState("");
    const providersSorted = useMemo(() => {
        return providersForUser
            .filter(item => {
                if (filter === "") return true;
                return translate(item.meta.title)
                    .toLowerCase()
                    .includes(filter);
            })
            .sort(sortBy(item => translate(item.meta.title)));
    }, [providersForUser, filter, translate]);

    if (!providersForUser?.length) {
        return null;
    }

    return (
        <>
            <FlexRow fillParentWidth>
                <TextEdit
                    ref={inputRef}
                    autoFocus
                    type={"search"}
                    onEnter={() => {
                        if (providersSorted.length > 0) {
                            navigate(
                                `/bo/providers/${providersSorted[0].slug}`,
                            );
                        }
                        return;
                    }}
                    autoComplete="off"
                    placeholder={translation({
                        sv: "filtrera",
                        en: "filter",
                    })}
                    description={translation({
                        sv: "filtrera leverantörer på titel",
                        en: "filter providers by title",
                    })}
                    css={{
                        width: "100%",
                        padding: "10px 15px",
                    }}
                    value={filter}
                    onChange={value => {
                        setFilter(value.toLowerCase());
                    }}
                />
            </FlexRow>
            <ForEach itemOf={providersSorted} getKey={provider => provider._id}>
                {provider => (
                    <React.Fragment key={provider._id}>
                        <FlexRow
                            fillParentWidth
                            padding={{ topBottom: 20 }}
                            shrink={0}
                            mainAxis="center"
                            crossAxis="center"
                            onClick={() =>
                                navigate(`/bo/providers/${provider.slug}`)
                            }
                            background={{
                                color: theme.isDarkTheme
                                    ? theme.colors.tertiary.dark
                                    : theme.colors.tertiary.light,
                            }}>
                            <Icon
                                icon={faUsersCog}
                                color={theme.colors.black}
                            />
                            <HorizontalDivider />
                            <TextBox
                                text={provider.meta.title}
                                weight="700"
                                size="ML"
                                color={theme.colors.black}
                            />
                        </FlexRow>
                        {props.divider ?? <HorizontalLine fillParentWidth />}
                    </React.Fragment>
                )}
            </ForEach>
        </>
    );
};

export { UserModalProviderLinks };
