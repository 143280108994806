import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { useCreateAccommodationReservationRequests } from "@@/products/accommodations/hooks/use-create-accommodation-reservation-requests";

import { useToast } from "@@/toasts/context/toast-context";
import {
    AccommodationInventory,
    CalculatedRatePrice,
    Product,
    ResourceAccommodationWithEnforcedCapacityHash,
    ResourceGroup,
    TimeRange,
    accommodationOrderItemFactory,
    getDistribution,
    isAccommodationResourceReservationRequest,
    sumPrices,
    translation,
} from "@towni/common";

import { browserLogger } from "@@/settings";
import * as Sentry from "@sentry/react";
const useAddAccommodationToCart = (product: Product) => {
    const toast = useToast();
    const wizardActions = useWizardDispatch();
    const cart = useCartContext();

    const { quantities, accommodation, preferredResourceIds } =
        useBookingAccommodationContext(state => ({
            quantities: state.quantities,
            accommodation: state.accommodation,
            preferredResourceIds: state.preferredResourceIds,
        }));

    const createReservationRequests =
        useCreateAccommodationReservationRequests(product);

    const addToCart = (params: {
        time: TimeRange;
        resource: ResourceGroup;
        rates: CalculatedRatePrice[];
        inventory: AccommodationInventory;
        quantity: number;
        resourceAccommodationWithEnforcedCapacityHash: ResourceAccommodationWithEnforcedCapacityHash[];
    }) => {
        const {
            time,
            resource,
            rates,
            inventory,
            quantity,
            resourceAccommodationWithEnforcedCapacityHash,
        } = params;
        const numberOfAccommodations = quantity; //Todo fill with correct value

        cart.removeProductFromCart(product._id);
        const newReservations = createReservationRequests(time, [
            {
                resourceGroupId: resource._id,
                quantity: numberOfAccommodations,
                quantities,
                displayResourceTitle: inventory.displayResourceTitle ?? false,
                requestedResources: preferredResourceIds,
            },
        ]);
        if (newReservations.length === 0) {
            //Todo: log to sentry? why did this happen?
            toast.danger({
                message: translation({
                    sv: "Det gick inte att skapa reservation",
                    en: "Could not create reservation",
                }),
            });
            cart.clearCart();
            return;
        }

        if (newReservations.length > 1) {
            //Todo: log to sentry? why did this happen?
        }
        const reservation = newReservations[0];
        if (!isAccommodationResourceReservationRequest(reservation)) {
            toast.danger({
                message: translation({
                    sv: "Det gick inte att skapa en reservation",
                    en: "Could not create reservation",
                }),
            });
            cart.clearCart();
            Sentry.withScope(scope => {
                scope.setExtra("reservation_request", reservation);
                scope.setExtra("add_to_cart_params", params);
                scope.captureException(
                    new Error(
                        "Could not create an accommodation reservation for a non-accommodation resource reservation request",
                    ),
                );
            });
            return;
        }

        if (typeof reservation.accommodationType === "undefined") {
            toast.danger({
                message: translation({
                    sv: "Det gick inte att skapa reservation",
                    en: "Could not create reservation",
                }),
            });
            cart.clearCart();
            return;
        }

        const resources = resourceAccommodationWithEnforcedCapacityHash.filter(
            r => newReservations.some(rr => rr.resourceId === r._id),
        );
        const dist = getDistribution({
            capacities: resources.map(r => r.capacity),
            requestedQuantity: quantities,
            doNotThrow: true,
        });

        if (!dist) {
            browserLogger.error("Could not get distribution");
            toast.danger({
                message: translation({
                    sv: "Det gick inte att skapa reservation för valda datum och antal personer",
                    en: "Could not create reservation",
                }),
            });
            return;
        }

        // Makes one accommodation order item for all resources with
        const items = accommodationOrderItemFactory({
            time,
            accommodation,
            resource,
            rate: rates,
            product: product,
            numberOfAccommodations,
            accommodationType: reservation.accommodationType,
            quantities: Array.from(dist.distributionPerCapacityIndex.values()),
        });

        cart.addItemsToCart(items);
        cart.setReservationRequests(newReservations);
        wizardActions.goForward();
    };

    const currentPrice = (params: {
        time: TimeRange;
        resource: ResourceGroup;
        //rate: CalculatedRatePrice;
        rates: CalculatedRatePrice[];
        inventory: AccommodationInventory;
        quantity: number;
        resourceAccommodationWithEnforcedCapacityHash: ResourceAccommodationWithEnforcedCapacityHash[];
    }) => {
        const {
            time,
            resource,
            rates,
            inventory,
            quantity,
            resourceAccommodationWithEnforcedCapacityHash,
        } = params;
        const numberOfAccommodations = quantity; //Todo fill with correct value

        const newReservations = createReservationRequests(time, [
            {
                resourceGroupId: resource._id,
                quantity: numberOfAccommodations,
                quantities,
                displayResourceTitle: inventory.displayResourceTitle ?? false,
                requestedResources: preferredResourceIds,
            },
        ]);

        const reservation = newReservations[0];

        const resources = resourceAccommodationWithEnforcedCapacityHash.filter(
            r => newReservations.some(rr => rr.resourceId === r._id),
        );
        const dist = getDistribution({
            capacities: resources.map(r => r.capacity),
            requestedQuantity: quantities,
            doNotThrow: true,
        });

        if (!dist) {
            return undefined;
        }
        if (!isAccommodationResourceReservationRequest(reservation)) {
            return undefined;
        }
        if (typeof reservation.accommodationType === "undefined") {
            return undefined;
        }

        try {
            // Makes one accommodation order item for all resources with
            const items = accommodationOrderItemFactory({
                time,
                accommodation,
                resource,
                rate: rates,
                product: product,
                numberOfAccommodations,
                accommodationType: reservation.accommodationType,
                quantities: Array.from(
                    dist.distributionPerCapacityIndex.values(),
                ),
            });

            return sumPrices(items.map(i => i.price));
        } catch (error) {
            return undefined;
        }
    };

    return [addToCart, currentPrice] as const;
};

export { useAddAccommodationToCart };
