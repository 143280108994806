import { useModal } from "@@/modals/use-modal";
import { useProduct } from "@@/products/state/queries/product/use-product";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Button_v2 } from "@@/shared/buttons_v2/button";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useImageUrl } from "@@/shared/image-url";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    AspectRatio,
    ProductId,
    RemSize,
    Translatable,
    translation,
} from "@towni/common";
import { ExtrasProps } from "../product-details-page";
import { BookableProductExtraModal } from "./bookable-product-extra-modal";

type Props = {
    productId: ProductId;
    /**
     * default to `3:4`
     * @type {Translatable}
     */
    aspectRatio?: AspectRatio;
    /**
     * default to product title
     * @type {Translatable}
     */
    title?: Translatable;
    /**
     * defaults to "Beställ"/"Order"
     * @type {Translatable}
     */
    actionText?: Translatable;
    /**
     * defaults to "Pickup eller hemleverans"/"Pickup or home delivery"
     * @type {Translatable}
     */
    kicker?: Translatable;
    imageIdOrUrl?: string;
    cardSize?: RemSize;

    extras?: ExtrasProps;
};

const BookableGridProductExtraCard = (props: Props) => {
    const theme = useTheme();
    const [product] = useProduct(props.productId);
    const [{ show: showProduct }, modalId] = useModal("product_modal__");
    const getImageUrl = useImageUrl();
    if (!product || product.status.type === "PAUSED") return null;

    const imageRef = props.imageIdOrUrl
        ? product.meta.images.find(image =>
              typeof image === "string"
                  ? image === props.imageIdOrUrl
                  : image._id === props.imageIdOrUrl,
          ) ?? product.meta.images[0]
        : product.meta.images[0];

    const imageUrl = getImageUrl(imageRef, { imageWidth: 500 });
    const actionText =
        props.actionText ?? translation({ sv: "Lägg till", en: "Add" });
    const title = props.title ?? product.meta.title;
    const kicker = props.kicker ?? "";

    return (
        <>
            <SectionAreaCard
                aspectRatio={props.aspectRatio ?? "3:4"}
                background={{
                    color: theme.colors.primary,
                    fit: "COVER",
                    repeat: "NO-REPEAT",
                    imageUrl,
                }}
                css={{
                    borderRadius: 10,
                    overflow: "hidden",
                }}>
                <FlexColumn
                    onClick={props.extras?.disabled ? undefined : showProduct}
                    fillParent
                    tag="SECTION_AREA_CARD"
                    shrink={0}
                    grow={1}
                    padding={{ all: 20 }}
                    background={{ linearGradient: darkeningGradient }}
                    mainAxis="space-between"
                    crossAxis="stretch">
                    <FlexColumn shrink={0} crossAxis="flex-start">
                        <TextBox
                            text={title}
                            weight="800"
                            size={1}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 2px 5px rgba(0, 0, 0, 0.4)",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={kicker}
                            weight="800"
                            size={1}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 2px 5px rgba(0, 0, 0, 0.4)",
                            }}
                        />
                    </FlexColumn>
                    <FlexRow mainAxis="flex-start">
                        <Conditional when={!props.extras?.disabled}>
                            <Button_v2
                                disabled={props.extras?.disabled}
                                css={{ label: "preorderbtn" }}
                                padding={{ all: 4 }}
                                colorSet={{
                                    main: theme.colors.transparent,
                                    text: theme.colors.white,
                                    border: theme.colors.white,
                                }}>
                                <FlexRow mainAxis="center">
                                    <TextBox
                                        text={actionText}
                                        size="XS"
                                        case="UPPERCASE"
                                        weight="800"
                                        align="center"
                                        padding={{
                                            leftRight: 9,
                                            topBottom: 7,
                                        }}
                                        color={theme.colors.white}
                                        css={{
                                            textShadow:
                                                "0 2px 5px rgba(0, 0, 0, 0.4)",
                                        }}
                                    />
                                </FlexRow>
                            </Button_v2>
                        </Conditional>
                    </FlexRow>
                </FlexColumn>
            </SectionAreaCard>
            <BookableProductExtraModal
                modalId={modalId}
                product={product}
                extras={props.extras}
            />
        </>
    );
};

export { BookableGridProductExtraCard };
