import { Circle } from "@@/shared/circle";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { translation } from "@towni/common";

const HowDoesItWorkSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <FlexColumn
            crossAxis="stretch"
            fillParentWidth
            background={{ color: "#E7F5F2" }}
            radius={isDesktop ? 10 : 0}
            padding={{ all: 30 }}>
            <TextBox
                text={translation({
                    sv: `Hur fungerar det?`,
                    en: "How does it work?",
                })}
                size="XL"
                weight={"800"}
            />
            <VerticalDivider ML />
            <FlexRow>
                <Circle backgroundColor={theme.colors.primary} size={45}>
                    <TextBox
                        text="1"
                        size="ML"
                        weight="800"
                        color={theme.colors.primary.text}
                        styling={css`
                            padding-bottom: 2px;
                        `}
                    />
                </Circle>
                <HorizontalDivider S />
                <TextBox
                    shrink={1}
                    color={theme.colors.black.light30}
                    text={translation({
                        sv: "Beställ och handla när och var som helst. 🚌 🛏 🚽 🏢 🚀",
                        en: "Order from anywhere anytime. 🚌 🛏 🚽 🏢 🚀",
                    })}
                />
                <HorizontalDivider ML />
            </FlexRow>
            <VerticalDivider M />
            <FlexRow>
                <Circle backgroundColor={theme.colors.primary} size={45}>
                    <TextBox
                        text="2"
                        size="ML"
                        weight="800"
                        color={theme.colors.primary.text}
                        styling={css`
                            padding-bottom: 2px;
                        `}
                    />
                </Circle>
                <HorizontalDivider S />
                <TextBox
                    shrink={1}
                    color={theme.colors.black.light30}
                    text={translation({
                        sv: "Hämta utan att köa (du har redan betalt). Snart även leverans. 🙌",
                        en: "Pickup without standing in line (you've already paid). Home delivery soon available. 🙌",
                    })}
                />
                <HorizontalDivider ML />
            </FlexRow>
            <VerticalDivider M />
            <FlexRow>
                <Circle backgroundColor={theme.colors.primary} size={45}>
                    <TextBox
                        text="3"
                        size="ML"
                        weight="800"
                        color={theme.colors.primary.text}
                        styling={css`
                            padding-bottom: 2px;
                        `}
                    />
                </Circle>
                <HorizontalDivider S />
                <TextBox
                    shrink={1}
                    color={theme.colors.black.light30}
                    text={translation({
                        sv: "Njut! och tipsa en vän, kollega eller familj att handla lokalt på towni.se",
                        en: "Enjoy! and tell a friend, coworker or family to shop locally on towni.se",
                    })}
                />
            </FlexRow>
            <VerticalDivider M />
            <FlexRow>
                <TextBox
                    text={translation({
                        sv: `Köa.`,
                        en: "Stand in line.",
                    })}
                    size="XL"
                    weight={"800"}
                    styling={css`
                        text-decoration: line-through;
                    `}
                />
                <HorizontalDivider XS />
                <TextBox
                    text={translation({
                        sv: `Beställ.`,
                        en: "Order.",
                    })}
                    size="XL"
                    weight={"800"}
                />
                <HorizontalDivider XS />
                <TextBox
                    text={translation({
                        sv: `Njut.`,
                        en: "Enjoy.",
                    })}
                    size="XL"
                    weight={"800"}
                />
            </FlexRow>
            <VerticalDivider XS />
            <TextBox
                shrink={1}
                text={translation({
                    sv: "Bra för nerver och smittspridning!",
                    en: "Good for the nerves and spread of covid!",
                })}
            />
        </FlexColumn>
    );
};

export { HowDoesItWorkSection };
