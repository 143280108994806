import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { OptionalAmountPicker } from "@@/shared/options/optional-amount-picker";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { AccommodationSubType } from "@towni/common";

type SubProps = {
    option: AccommodationSubType;
    value: number;
    maxValue: number;
    onIncrease: () => void;
    onDecrease: () => void;
};

const AccommodationCart_SubType_QuantityPicker = (props: SubProps) => {
    const theme = useTheme();

    return (
        <LayoutGrid
            css={{
                flex: 1,
                paddingLeft: 20,
                paddingRight: 20,
                gridTemplateColumns: "1fr 110px",
                backgroundColor: theme.isLightTheme
                    ? theme.colors.primary.light.asString
                    : theme.colors.primary.dark.asString,
            }}>
            <LayoutCell
                css={{ flex: 1, display: "flex", alignItems: "stretch" }}>
                <FlexRow crossAxis="center" css={{ flex: 1 }}>
                    <DynamicIcon
                        icon={props.option.iconName}
                        title={props.option.title}
                        css={{ color: theme.colors.black.asString }}
                        size={1}
                        fixedWidth
                    />
                    <HorizontalDivider S />
                    <TextBox
                        text={props.option.title}
                        css={{
                            flexShrink: 0,
                        }}
                    />
                </FlexRow>
            </LayoutCell>
            <LayoutCell>
                <OptionalAmountPicker
                    count={props.value}
                    minMax={{ min: 0, max: props.maxValue }}
                    increase={props.onIncrease}
                    decrease={props.onDecrease}
                    backgroundColor={theme.colors.default.background}
                />
            </LayoutCell>
        </LayoutGrid>
    );
};

export { AccommodationCart_SubType_QuantityPicker };
