import { Anchor } from "@@/pages/container-navigation/anchor";
import { browserLogger } from "@@/settings";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { Section } from "./section";
import { useSection } from "./state/section-fetchers";

type Props = {
    sectionId: string;
    anchor?: Anchor;
};

const SectionById = (props: Props) => {
    const [section, sectionQuery] = useSection(props.sectionId);

    if (!section && !sectionQuery.isStale) return null;
    if (sectionQuery.isPending)
        return (
            <TextShimmer
                rows={3}
                css={{ padding: "0px 20px", paddingTop: 30 }}
            />
        );
    if (!section)
        return (
            <TextShimmer
                rows={3}
                css={{ padding: "0px 20px", paddingTop: 30 }}
            />
        );
    if (sectionQuery.error) {
        browserLogger.error(sectionQuery.error, props.sectionId);
        throw sectionQuery.error;
    }

    return <Section section={section} anchor={props.anchor} />;
};

export { SectionById };
