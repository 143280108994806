import { compare, ComparisonType } from "@towni/common";
import { useLayoutEffect, useState } from "react";

const useIsContentScrolled = (
    containerElement: HTMLDivElement | undefined | null,
    /** defaults to `0` */
    scrollTop?: number,
    /** defaults to `largerThan` */
    comparison?: ComparisonType,
) => {
    const [isContentScrolled, setIsContentScrolled] = useState(false);

    useLayoutEffect(() => {
        if (!containerElement) return;
        let debouncer: NodeJS.Timeout | undefined;
        const listener = () => {
            if (!debouncer) {
                debouncer = setTimeout(() => {
                    const match = compare(comparison ?? "greaterThan")(
                        containerElement.scrollTop,
                        scrollTop ?? 0,
                    );
                    setIsContentScrolled(match);
                    debouncer = undefined;
                }, 200);
            }
        };
        containerElement.addEventListener("scroll", listener);
        return () => {
            if (containerElement) {
                containerElement.removeEventListener("scroll", listener);
            }
        };
    }, [comparison, containerElement, scrollTop]);

    return isContentScrolled;
};

export { useIsContentScrolled };
