import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormColors } from "@@/shared/form/use-form-colors";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { paddingToCssValue } from "@@/shared/padding";
import { FieldTitle } from "@@/shared/text/field-title";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, css, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { Padding, Translatable, asArray } from "@towni/common";
import { Draft } from "immer";
import { useCallback, useRef } from "react";
import { ZodSchema } from "zod";

type Value = number | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly fieldSchema?: ZodSchema;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly autoComplete?: string;
    /**
     * Optional element to be rendered before the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly preElement?: JSX.Element;
    /**
     * Optional element to be rendered after the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly postElement?: JSX.Element;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    readonly label?: Translatable;
    readonly placeholder?: Translatable;
    readonly charFilters?: RegExp | RegExp[];
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
};
// type PickByType<T, Value> = {
//     [P in keyof T as T[P] extends Value ? string : never]: T[P];
// };

const Form2NumberInput = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const translate = useTranslate();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: props.formId,
        fieldSchema: props.fieldSchema,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);
    const disabled = props.disabled ?? field.isSubmitting;

    const hasErrors = field.errors.length > 0;
    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
    });

    // Colors
    const { borderColor, backgroundColor, textColor, spinnerColor } =
        useFormColors({ hasErrors });

    // Styles
    const padding = paddingToCssValue(props.padding ?? { all: 14 });
    const containerStyles = css({
        cursor: !disabled ? "pointer" : "default",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor,
        backgroundColor,
        borderRadius: theme.radius,
        flex: 1,
        opacity: props.disabled ? 0.5 : 1,
        padding,
    });

    const inputRef = useRef<HTMLInputElement | null>(null);

    const parsedValue = (input: string): number => {
        const newValue = input?.replace(/^(?!-)\D/g, "").trim() ?? "";
        const newNumber = Math.round(Number(newValue));
        return newNumber;
    };
    const changeValue = useCallback(
        (incoming: string) => {
            const newValue = incoming?.replace(/^(?!-)\D/g, "").trim() ?? "";
            const newNumber = parsedValue(incoming);
            // console.log("NUMBER INPUT", {
            //     newValue,
            //     newNumber,
            //     incoming,
            //     value: field.value,
            //     undef: typeof newValue === "undefined",
            //     isNaN: isNaN(newNumber),
            // });
            if (newNumber === field.value) return;

            field.setDirty(true);
            field.setTouched(true);
            field.setValue(
                typeof newValue === "undefined" || isNaN(newNumber)
                    ? undefined
                    : newNumber,
            );
        },
        [field],
    );

    const onValueChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const incoming = event.target.value;
            return changeValue(incoming);
        },
        [changeValue],
    );

    // useUpdateEffect(() => {
    //     const currentInput = inputRef?.current;
    //     console.log("UPDATING INPUT FIELD", {
    //         fieldId: props.fieldId,
    //         value: field.value,
    //         inputRef,
    //         disabled,
    //         currentInput: !!currentInput,
    //         fieldValue: !!field.value,
    //         enabled: !disabled,
    //     });

    //     if (!disabled) return;
    //     if (!currentInput) return;
    //     if (!field.value) {
    //         currentInput.value = "";
    //         return;
    //     }
    //     currentInput.value === field.value.toString();
    //     console.log("VALUE SET", field.value.toString(), currentInput);
    // }, [disabled, field.value]);

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            tag={`form-field_${props.fieldId}`}
            className={props.className}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={field.isRequired}
                        disabled={disabled}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            // color={props.labelColor}
                            weight="400"
                            size="S"
                            disabled={disabled}
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            <FlexRow
                fillParentWidth
                css={[containerStyles, props.containerCss]}
                crossAxis="center"
                mainAxis="space-between"
                onClick={() => {
                    !disabled && inputRef.current?.focus();
                }}>
                <Conditional when={!!props.preElement}>
                    <FlexRow shrink={0} grow={0}>
                        {props.preElement ?? null}
                    </FlexRow>
                    <HorizontalDivider />
                </Conditional>
                <input
                    key={`${formId}_${props.fieldId}_input_${disabled.toString()}`}
                    ref={(node: HTMLInputElement | null) => {
                        inputRef.current = node;
                    }}
                    value={!disabled ? field.value : undefined}
                    placeholder={translate(props.placeholder)}
                    onBeforeInput={
                        // If charFilters is set,
                        // prevent input of characters that match the filter(s)
                        props.charFilters
                            ? event => {
                                  const input = (event as unknown as InputEvent)
                                      .data as string;
                                  const prevent = asArray(
                                      props.charFilters,
                                  ).some(filter => {
                                      filter.lastIndex = 0; // because of global flag weirdness
                                      return filter.test(input);
                                  });
                                  if (prevent) {
                                      event.preventDefault();
                                      return false;
                                  }
                                  return true;
                              }
                            : undefined
                    }
                    className={props.className}
                    autoComplete={props.autoComplete}
                    defaultValue={field.value}
                    onBlur={() => {
                        field.setTouched(true);
                        validationTrigger();
                    }}
                    disabled={disabled}
                    onChange={onValueChange}
                    css={[
                        {
                            width: "100%",
                            userSelect: "auto",
                            border: "none",
                            flex: 1,
                            fontSize: "1rem",
                            backgroundColor,
                            color: textColor,
                            textAlign: "right",
                        },
                        props.inputCss,
                        hasErrors && {
                            borderColor: theme.colors.danger.asString,
                        },
                    ]}
                />
                <Conditional when={!!props.postElement}>
                    <HorizontalDivider />
                    <FlexRow
                        css={{
                            flexShrink: 0,
                            flexGrow: 0,
                        }}>
                        {props.postElement ?? null}
                    </FlexRow>
                </Conditional>
                <Conditional when={!!props.spin}>
                    <FlexRow
                        css={{
                            flexShrink: 0,
                            flexGrow: 0,
                        }}>
                        <HorizontalDivider />
                        <Icon icon={faSpinnerThird} spin color={spinnerColor} />
                    </FlexRow>
                </Conditional>
            </FlexRow>
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2NumberInput };
