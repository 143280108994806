import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { useDimensions } from "@@/shared/section-area/card-size";
import { useBackgroundStyleToCss } from "@@/shared/stylable/background-style-css";
import { TextBox } from "@@/shared/text";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { css, useTheme } from "@emotion/react";
import {
    AspectRatio,
    BackgroundStyle,
    getOriantationFromItemDisplayType,
    orientationsMap,
} from "@towni/common";
import { SectionItemProps } from "../section-item";

const SectionItemTextCard = (props: SectionItemProps) => {
    const theme = useTheme();
    const getDimensions = useDimensions();
    const backgroundStyleToCss = useBackgroundStyleToCss();
    const [discountsForProduct] = useDiscountsForProduct(props.item);

    const { itemDisplayType: displayType, size } = props.sectionTheme;
    const orientation = getOriantationFromItemDisplayType(
        displayType,
        orientationsMap.SQUARE,
    );
    const aspectRatio: AspectRatio =
        orientation === "PORTRAIT"
            ? "3:4"
            : orientation === "SQUARE"
              ? "1:1"
              : "4:3";

    const { width, height } = getDimensions({ aspectRatio, size });

    const background: BackgroundStyle = props.item?.meta.background || {
        color: theme.colors.default.background.value.asCssHsla,
    };
    const cardStyles = css`
        width: ${width};
        height: ${height};
        border-radius: 3px;
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 0;
        text-align: left;
        ${backgroundStyleToCss(background, theme.colors.default.background) ||
        ""}
        background-color: hotpink;
        color: white;
        overflow-wrap: break-word;
    `;

    switch (size) {
        case "M":
        default:
            if (!props.item) {
                return (
                    <div css={cardStyles}>
                        <FlexColumn
                            onClick={props.onClick}
                            mainAxis="space-between"
                            crossAxis="flex-start"
                            padding={{ all: 5 }}
                            fillParent>
                            <TextShimmer rows={1} rowHeight="14px" />
                            <FlexRow>
                                <TextShimmer
                                    rows={1}
                                    rowHeight="12px"
                                    width="60px"
                                />
                            </FlexRow>
                        </FlexColumn>
                    </div>
                );
            }
            return (
                <div css={cardStyles}>
                    <FlexColumn
                        onClick={props.onClick}
                        mainAxis="space-between"
                        crossAxis="flex-start"
                        padding={{ all: 5 }}
                        fillParent>
                        <TextBox
                            size="S"
                            text={props.item.meta.title}
                            styling={css`
                                text-transform: uppercase;
                                overflow-wrap: break-word;
                            `}
                        />

                        <FlexRow>
                            <PriceWithDiscountsTextBox
                                discounts={discountsForProduct}
                                price={props.item.price}
                                size="S"
                                fractions="NONE"
                            />
                        </FlexRow>
                    </FlexColumn>
                </div>
            );
    }
};

export { SectionItemTextCard };
