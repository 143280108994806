import { useMe } from "@@/authentication/me/me-context";
import { HorizontalDivider, HorizontalLine } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faUsersCrown } from "@fortawesome/pro-solid-svg-icons";
import { hejalfredScope } from "@towni/common";
import { useNavigate } from "react-router-dom";

type Props = {
    divider?: JSX.Element;
};

const UserModalDashboardLink = (props: Props) => {
    const [me] = useMe();
    const theme = useTheme();
    const navigate = useNavigate();

    if (!me?.scopes.includes(hejalfredScope)) {
        return null;
    }

    return (
        <>
            <FlexRow
                tag="user-modal-dashboard-link"
                fillParentWidth
                padding={{ topBottom: 20 }}
                shrink={0}
                mainAxis="center"
                crossAxis="center"
                onClick={() => navigate(`/bo/dashboard`)}
                background={{
                    color: theme.isDarkTheme
                        ? theme.colors.tertiary.dark
                        : theme.colors.tertiary.light,
                }}>
                <Icon icon={faUsersCrown} color={theme.colors.black} />
                <HorizontalDivider />
                <TextBox
                    text={"Dashboard"}
                    weight="700"
                    size="ML"
                    color={theme.colors.black}
                />
            </FlexRow>
            {props.divider ?? <HorizontalLine fillParentWidth />}
        </>
    );
};

export { UserModalDashboardLink };
