import { Product, Provider } from "@towni/common";
import { useMemo } from "react";
import { VoucherCardSquare } from "./voucher-card-square";
import { VoucherCardWithChin } from "./voucher-card-with-chin";

const VoucherCard = (props: {
    voucher: Product | undefined;
    provider: Provider | undefined;
    index?: number;
    unavailable?: boolean;
}) => {
    const { voucher, index } = props;
    const VoucherComponent = useMemo(
        () =>
            typeof index === "undefined"
                ? Math.random() > 0.5
                    ? VoucherCardWithChin
                    : VoucherCardSquare
                : (index + 2) % 3 === 0
                  ? VoucherCardSquare
                  : VoucherCardWithChin,
        [index],
    );

    return (
        <VoucherComponent
            voucher={voucher}
            provider={props.provider}
            unavailable={props.unavailable}
        />
    );
};

export { VoucherCard };
