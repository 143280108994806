import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { HorizontalLine } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { MapUrl, translation } from "@towni/common";
import * as React from "react";
import { ModalHeader } from "../modals/modal-header";

type Props = {
    modalId: ModalId;
    mapUrls: MapUrl[];
};

const SelectMapUrlModal = (props: Props) => {
    const theme = useTheme();
    return (
        <Modal modalId={props.modalId}>
            <FlexColumn
                padding={{ bottom: 20 }}
                crossAxis="center"
                css={{
                    backgroundColor: "white",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <ModalHeader
                    modalId={props.modalId}
                    title={translation({
                        sv: "Visa på karta",
                        en: "Show on map",
                    })}
                />
                <ErrorBoundary>
                    {Array.from(props.mapUrls).map(mapUrl => {
                        return (
                            <React.Fragment key={mapUrl.url}>
                                <HorizontalLine fillParentWidth />
                                <TextBox
                                    text={mapUrl.title ?? mapUrl.type}
                                    weight="700"
                                    linkTo={mapUrl.url}
                                    linkTarget="_blank"
                                    padding={{ topBottom: 30, leftRight: 20 }}
                                    size={1}
                                    case="UPPERCASE"
                                    color={theme.colors.default.text.asString}
                                />
                            </React.Fragment>
                        );
                    })}
                </ErrorBoundary>
            </FlexColumn>
        </Modal>
    );
};

export { SelectMapUrlModal };
