import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { Slider } from "@@/shared/form/slider";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { FieldTitle } from "@@/shared/text/field-title";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import {
    MinMaxRange,
    Padding,
    Translatable,
    setWithinRange,
} from "@towni/common";
import { Draft } from "immer";
import { useMemo, useState } from "react";
import { ZodSchema } from "zod";

type PossibleValueTypes = MinMaxRange | undefined | null;
type DefaultValueType = MinMaxRange | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly minValue: number;
    readonly maxValue: number;

    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    readonly label?: Translatable;
    readonly charFilters?: RegExp | RegExp[];
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
};
const createFormRangeDualSliderComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2RangeDualSliderInput = <
        Value extends PossibleValueTypes = DefaultValueType,
    >(
        props: Props<State, Value>,
    ) => {
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            fieldSchema: props.fieldSchema,
            formId: props.formId,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );

        const [_initialValue] = useState(field.value);
        const hasErrors = field.errors.length > 0;
        useFormFieldValidation<State, Value>({
            field,
            initialValidationType: "automatic",
        });

        const maxValue = useMemo(() => {
            const maximumValue = props.maxValue;
            const currentValue = field.value?.max ?? 0;
            const step =
                currentValue >= 800
                    ? 2000
                    : currentValue >= 300
                      ? 500
                      : currentValue >= 150
                        ? 200
                        : currentValue >= 100
                          ? 100
                          : 40;
            const currentNumberOfSteps =
                currentValue === 0
                    ? 1
                    : Number.isInteger(currentValue / step)
                      ? currentValue / step + 1
                      : Math.ceil(currentValue / step);
            return setWithinRange(currentNumberOfSteps * step, {
                min: props.minValue,
                max: maximumValue,
            });
        }, [props.minValue, props.maxValue, field.value]);

        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                tag={props.fieldId}
                crossAxis="stretch">
                <Conditional when={!!props.label}>
                    <FlexRow
                        shrink={0}
                        crossAxis="center"
                        mainAxis="space-between">
                        <FieldTitle
                            htmlFor={props.fieldId}
                            padding={{ left: 2 }}
                            text={props.label ?? ""} // already checked with conditional above
                            required={field.isRequired}
                        />
                        <Conditional when={!!props.labelDescription}>
                            <HorizontalDivider XXS />
                            <FieldTitle
                                padding={{ left: 2 }}
                                text={props.labelDescription ?? ""} // already checked with conditional above
                                // color={props.labelColor}
                                weight="400"
                                size="S"
                                css={{
                                    opacity: 0.5,
                                }}
                            />
                        </Conditional>
                    </FlexRow>
                    <VerticalDivider M />
                </Conditional>
                <Slider
                    minValue={props.minValue}
                    maxValue={field.value?.max ?? maxValue}
                    initialValue={field.value?.min ?? 0}
                    label={"Min"}
                    required={field.isRequired}
                    onChange={value => {
                        const min = setWithinRange(value, {
                            min: props.minValue,
                            max: field.value?.max ?? props.maxValue,
                        });
                        field.setValue({
                            ...field.value,
                            min,
                        } as Value);
                    }}
                />
                <VerticalDivider L />
                <Slider
                    minValue={field.value?.min ?? props.minValue}
                    currentMaxValue={maxValue}
                    maxValue={props.maxValue}
                    initialValue={field.value?.max ?? 0}
                    label={"Max"}
                    required={field.isRequired}
                    onChange={value => {
                        const max = setWithinRange(value, {
                            min: field.value?.min ?? props.minValue,
                            max: props.maxValue,
                        });
                        const newValue = {
                            ...(field.value
                                ? field.value
                                : ({} as MinMaxRange)),
                            max,
                        } as Value;

                        field.setValue(newValue);
                    }}
                />
                <VerticalDivider />
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        marginLeft: -10,
                        marginTop: 10,
                        marginBottom: -5,
                    }}
                />
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2RangeDualSliderInput;
};
export { createFormRangeDualSliderComponent };
