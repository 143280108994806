import { apiFetchClient } from "@@/shared/fetch-client";
import { GetSingleResponse, StorageItem, StorageItemId } from "@towni/common";

const fetchStorageItem =
    <StorageItemType extends StorageItem = StorageItem>(
        storageItemId: StorageItemId | undefined,
        contentTypeVerifier?: (contentType: string) => boolean
    ) =>
    async () => {
        if (!storageItemId) throw new Error("missing storage item id");
        const data = await apiFetchClient.get<
            GetSingleResponse<StorageItemType>
        >({
            route: `/storage-items/${encodeURIComponent(storageItemId)}`,
        });
        if (contentTypeVerifier) {
            if (!contentTypeVerifier(data.item.contentType)) {
                throw new Error(
                    "Not the requested contentType: " + data.item.contentType
                );
            }
        }
        return data.item;
    };

export { fetchStorageItem };
