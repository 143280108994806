import { ordersMainQueryKey } from "@@/orders/order-fetchers";
import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { apiFetchClient } from "@@/shared/fetch-client";
import { useApiResources } from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import {
    AccessKeyId,
    emptyArrayOf,
    ExternalAccessId,
    GetResponse,
    OrderId,
    ProductExternalAccess,
} from "@towni/common";

const fetchProductExternalAccessesForOrder =
    (
        orderId: OrderId | undefined,
        accessId: ExternalAccessId | undefined,
        accessKeyId?: AccessKeyId
    ) =>
    async () => {
        if (typeof orderId === "undefined" || typeof accessId === "undefined")
            return emptyArrayOf<ProductExternalAccess>();

        const data = await apiFetchClient.get<
            GetResponse<ProductExternalAccess>
        >({
            route: `/orders/external-access/${encodeURIComponent(
                orderId
            )}/for-access/${encodeURIComponent(accessId)}${
                accessKeyId ? `?ak=${accessKeyId}` : ""
            }`,
        });
        return data.items;
    };

const useProductExternalAccessesForOrder = (
    orderId?: OrderId,
    accessId?: ExternalAccessId
): readonly [
    ProductExternalAccess[],
    UseQueryResult<ProductExternalAccess[]>
] => {
    const accessKeyId = useAccessKeyIdFromUrl();
    return useApiResources<ProductExternalAccess>(
        [ordersMainQueryKey, accessId ?? "", accessKeyId],
        fetchProductExternalAccessesForOrder(orderId, accessId, accessKeyId),
        {
            enabled: !!orderId,
        }
    );
};

export { useProductExternalAccessesForOrder };
