import { useBookingContext } from "@@/products/bookables/booking-context";
import { useAddBookableToCartOrGoNext } from "@@/products/bookables/hooks/use-add-bookable-to-cart";
import { useCheckBookableSession } from "@@/products/bookables/hooks/use-check-bookable-session";
import { useCheckReservationFromBookingContext } from "@@/products/bookables/hooks/use-create-reservation";
import { Conditional } from "@@/shared/conditional";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Paragraph, TextBox } from "@@/shared/text";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    Padding,
    Translatable,
    formatBookedTime,
    granularityTranslatable,
    isQuantityPickerBookableOptionValue,
    isTableBooking,
    isTimeRange,
    sum,
    translation,
} from "@towni/common";
import { motion } from "framer-motion";
import { AddToCartPillButton } from "../add-to-cart-with-quantity-picker/add-to-cart-pill-button";
import { useCheckSessionBookableStatus } from "./use-check-session-bookable-status";
import { useCurrentBookablePrice } from "./use-current-bookable-price";

type Props = {
    readonly addButtonText?: Translatable;
    readonly padding?: Padding;
    readonly background?: BackgroundStyle;
    readonly afterAddNavigation?: () => void;
};

const ConfirmTimeSlot = (props: Props) => {
    const theme = useTheme();
    const state = useBookingContext(context => ({
        sku: context.sku,
        product: context.product,
        timeRange: context.timeRange,
        session: context.session,

        messageToProvider: context.messageToProvider,
        bookable: context.bookable,
        freeBookable: context.freeBookable,
        optionValues: context.optionValues,
        settings: context.bookable.settings,
        requestedResources: context.requestedResources,
        pickedOptionalsMap: context.pickedOptionalsMap,
        resourcePickerEnabled: context.resourcePickerEnabled,
        originalSessions: context.originalSessions,
        isOnPremiseBooking: context.isOnPremiseBooking,
    }));
    const addToCartClick = useAddBookableToCartOrGoNext();
    const [checkAvailability] = useCheckBookableSession();
    const checkReservation = useCheckReservationFromBookingContext(
        state.product,
        false,
    );
    const check = useCheckSessionBookableStatus();

    const hasSession = isTimeRange(state.timeRange) && state.session;

    const quantity = sum(
        state.optionValues.filter(isQuantityPickerBookableOptionValue),
        ov => ov.quantity.value,
    );

    const canBeBooked =
        hasSession &&
        state.session &&
        isTimeRange(state.timeRange) &&
        !checkAvailability(
            state.session,
            undefined,
            state.isOnPremiseBooking,
        ).resourceAvailabilities.some(a => !a.available) &&
        quantity > 0 &&
        (checkReservation({
            session: state.session,
            optionValues: state.optionValues,
            requestedResources: state.requestedResources,
            timeRange: state.timeRange,
            settings: state.session.bookableSettings ?? state.settings,
            sku: state.sku,
        }).length === 0 ||
            state.resourcePickerEnabled);

    const calculateCurrentPrice = useCurrentBookablePrice();

    const isSessionBookableByEveryone = check(state.session?._id);

    return (
        <motion.div
            initial={{
                opacity: 1,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
            transition={theme.spring}
            css={{
                display: "block",
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "auto",
                pointerEvents: "none",
                zIndex: 99999,
                willChange: "filter",
                filter: `drop-shadow(0 -15px 15px ${
                    theme.colors.black.value.withAlpha(0.1).asString
                })`,
            }}>
            <FlexColumn
                fillParent
                mainAxis="flex-end"
                crossAxis="stretch"
                css={{
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    overflow: "hidden",
                }}>
                <FlexColumn
                    crossAxis="stretch"
                    fillParentWidth
                    background={{
                        color: theme.colors.default.background.value.withRelativeModifications(
                            { light: 0.1 },
                        ),
                    }}>
                    <FlexRow
                        css={{ pointerEvents: "all" }}
                        mainAxis="space-between"
                        padding={{
                            ...props.padding,
                            top: props.padding?.top || 15,
                        }}>
                        <HorizontalDivider />

                        <FlexColumn grow={1}>
                            <Conditional
                                when={
                                    !isSessionBookableByEveryone.isSessionBookableByEveryone
                                }>
                                <TextBox
                                    text={translation({
                                        sv: "⚠️ Vald tid ej tillgänglig för kund",
                                        en: "⚠️ Not available for customer",
                                    })}
                                />
                            </Conditional>
                            <Conditional
                                when={!!state.timeRange}
                                render={() => (
                                    <TextBox
                                        dataTestId="selected-time-range-label"
                                        text={formatBookedTime({
                                            time: state.timeRange,
                                            granularity:
                                                state.session
                                                    ?.durationGranularity,
                                            onlyStartTime: isTableBooking(
                                                state.bookable.type,
                                            ),
                                            currentTimezoneName:
                                                currentTimezoneName,
                                        })}
                                        weight="300"
                                    />
                                )}
                                else={() => (
                                    <Conditional
                                        when={!!state.session}
                                        render={() => {
                                            const session = state.session;
                                            if (!session) return null;
                                            return (
                                                <Paragraph
                                                    content={[
                                                        translation({
                                                            sv: "Välj minst ",
                                                            en: "Pick at least ",
                                                        }),
                                                        session.durationMinMax.min.toLocaleString(),
                                                        session.durationMinMax
                                                            .max
                                                            ? translation({
                                                                  sv: `, max ${session.durationMinMax.max.toLocaleString()} `,
                                                                  en: `, max ${session.durationMinMax.max.toLocaleString()} `,
                                                              })
                                                            : "",
                                                        granularityTranslatable(
                                                            session.durationGranularity,
                                                            (session
                                                                .durationMinMax
                                                                .max &&
                                                                session
                                                                    .durationMinMax
                                                                    .max > 1) ||
                                                                session
                                                                    .durationMinMax
                                                                    .min > 1
                                                                ? "plural"
                                                                : "singular",
                                                        ),
                                                    ]}
                                                    css={{ fontWeight: "300" }}
                                                />
                                            );
                                        }}
                                        else={() => (
                                            <TextBox
                                                text={translation({
                                                    sv: "Välj tid",
                                                    en: "Pick a time",
                                                })}
                                                weight="300"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <VerticalDivider XXS />
                            <Conditional
                                when={!state.freeBookable}
                                render={() => (
                                    <FlexRow grow={1}>
                                        <TextBox
                                            weight="700"
                                            dataTestId="price-label"
                                            text={
                                                hasSession
                                                    ? translation({
                                                          sv: "pris",
                                                          en: "price",
                                                      })
                                                    : translation({
                                                          sv: "från",
                                                          en: "from",
                                                      })
                                            }
                                            case="LOWERCASE"
                                        />
                                        <HorizontalDivider XS />
                                        <PriceWithDiscountsTextBox
                                            weight="700"
                                            dataTestId="price-value"
                                            price={calculateCurrentPrice(
                                                undefined,
                                            )}
                                            discounts={[]}
                                            fractions="NONE"
                                            reversed
                                        />
                                    </FlexRow>
                                )}
                                else={() => <FlexRow grow={1}></FlexRow>}
                            />
                        </FlexColumn>
                        <HorizontalDivider />
                        <FlexRow>
                            <AddToCartPillButton
                                disabled={!canBeBooked}
                                onClick={addToCartClick}
                                css={{
                                    width: "100%",
                                }}>
                                <FlexRow
                                    fillParentWidth
                                    mainAxis="center"
                                    crossAxis="center">
                                    <TextBox
                                        text={
                                            state.timeRange
                                                ? translation({
                                                      sv: "Boka",
                                                      en: "Book",
                                                  })
                                                : state.session
                                                  ? translation({
                                                        sv: "Välj slutdatum",
                                                        en: "Pick a time",
                                                    })
                                                  : translation({
                                                        sv: "Välj tid",
                                                        en: "Pick a time",
                                                    })
                                        }
                                        weight="700"
                                    />
                                </FlexRow>
                            </AddToCartPillButton>
                        </FlexRow>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </motion.div>
    );
};

export { ConfirmTimeSlot };
