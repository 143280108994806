import { cardBrandIcon } from "@@/payments/stored-payment-methods/stored-payment-method";
import { useStoredStripePaymentMethods } from "@@/payments/stored-payment-methods/stored-payment-method-fetchers";
import { useRemoveStoredPaymentMethod } from "@@/payments/stored-payment-methods/use-remove-stored-payment-method";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Join } from "@@/shared/join";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { css, useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
    capitalCase,
    createRemoveStoredPaymentMethodCommand,
    translation,
} from "@towni/common";

type Props = {
    selectedExternalCardId: string | undefined;
    onSelectCard: (selectedExternalCardId: string) => void;
    disabled?: boolean;
};
const PaymentStoredCardSelection = (props: Props) => {
    const theme = useTheme();
    const [stripeStoredCards] = useStoredStripePaymentMethods();
    const removeStoredCard = useRemoveStoredPaymentMethod();

    if (!stripeStoredCards.length) return null;
    return (
        <FlexColumn fillParentWidth>
            {stripeStoredCards.length ? (
                <>
                    <FieldTitle
                        text={translation({
                            sv: "Sparade kort",
                            en: "Saved cards",
                        })}
                        padding={{ left: 30 }}
                    />
                    <VerticalDivider />
                </>
            ) : null}
            <Join
                start
                end
                divider={
                    <HorizontalLine
                        color={theme.colors.default.border.value.withAlpha(0.5)}
                    />
                }>
                {stripeStoredCards.map(card => {
                    const isSelected =
                        card.externalId === props.selectedExternalCardId;
                    const borderColor = isSelected
                        ? theme.colors.transparent
                        : theme.colors.transparent;
                    return (
                        <FlexRow
                            key={card._id}
                            shrink={0}
                            padding={{ topBottom: 16, leftRight: 30 }}
                            onClick={() => {
                                if (props.disabled) return;
                                props.onSelectCard(card.externalId);
                            }}
                            mainAxis="space-between"
                            crossAxis="center"
                            color={theme.colors.default.text}
                            background={{
                                color: isSelected
                                    ? "#e8fbf6"
                                    : theme.colors.default.background,
                            }}
                            styling={css`
                                border-top: 1px solid ${borderColor.asString};
                                border-bottom: 1px solid ${borderColor.asString};
                                opacity: ${props.disabled ? 0.6 : 1};
                            `}>
                            <FlexRow shrink={0} crossAxis="center">
                                <Icon
                                    icon={cardBrandIcon(card.brand)}
                                    size="L"
                                />
                                <HorizontalDivider M />
                                <TextBox
                                    text={`${capitalCase(card.brand)} ... ${
                                        card.last4
                                    }`}
                                />
                            </FlexRow>
                            {(isSelected && (
                                <TextBox
                                    text={translation({
                                        sv: "VALD",
                                        en: "PICKED",
                                    })}
                                    size="S"
                                    weight="900"
                                    shrink={0}
                                />
                            )) || (
                                <Icon
                                    icon={faTimes}
                                    color={theme.colors.default.text.value.withAlpha(
                                        0.3,
                                    )}
                                    padding={{ leftRight: 8, topBottom: 2 }}
                                    onClick={event => {
                                        // Remove card
                                        event.stopPropagation(); // dont select the card whilst removing it
                                        const confirmation = confirm(
                                            `Är du säker på att du inte längre vill ha kortet **** **** **** ${card.last4} sparat?`,
                                        );
                                        if (!confirmation) return;
                                        removeStoredCard.mutateAsync(
                                            createRemoveStoredPaymentMethodCommand(
                                                {
                                                    externalId: card.externalId,
                                                    storedPaymentMethodId:
                                                        card._id,
                                                },
                                            ),
                                        );
                                    }}
                                />
                            )}
                        </FlexRow>
                    );
                })}
            </Join>
            <VerticalDivider L />
        </FlexColumn>
    );
};

export { PaymentStoredCardSelection };
