import { ContactProvider } from "@@/providers/contact-provider";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Picture } from "@@/shared/pictures/picture";
import { Pillify } from "@@/shared/pillify";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { Provider } from "@towni/common";

type Props = {
    provider: Provider;
};

const ContactProviderPanel = ({ provider }: Props) => {
    const theme = useTheme();
    return (
        <FlexRow mainAxis="space-between" crossAxis="center">
            {provider.logoImageRef && (
                <>
                    <div style={{ flexBasis: "15%", flexShrink: 0 }}>
                        <Picture
                            imageRef={provider.logoImageRef}
                            width="100%"
                            radius={5000}
                            disableBlurhash
                        />
                    </div>
                    <HorizontalDivider M />
                </>
            )}
            <FlexColumn grow={1}>
                <FlexRow
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="center">
                    <TextBox
                        text={provider.meta.title}
                        lineClamp={2}
                        color={theme.colors.default.text}
                        weight="800"
                        shrink={1}
                    />
                    <HorizontalDivider />
                    <Pillify>
                        <ContactProvider provider={provider} />
                    </Pillify>
                </FlexRow>
                <VerticalDivider />
                <FlexRow>
                    <TextBox
                        text={provider.meta.subtitle}
                        lineClamp={2}
                        color={theme.colors.black.light60}
                        styling={css`
                            flex-basis: ${provider.logoImageRef
                                ? "80%"
                                : "100%"};
                        `}
                    />
                </FlexRow>
            </FlexColumn>
        </FlexRow>
    );
};

export { ContactProviderPanel };
