import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Spacer } from "@@/shared/spacer";
import { PageTitle } from "@@/shared/text/page-title";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import { translation } from "@towni/common";
import * as React from "react";
import { useMe } from "../../me/me-context";
import { PhoneVerification } from "./phone-verification";

const PhoneVerificationPage = () => {
    const wizardState = useWizardState();
    const wizardDispatch = useWizardDispatch();

    const [me] = useMe();

    React.useEffect(() => {
        if (!me?._id) return;
        // User has been signed in
        // Nothing more to do
        wizardDispatch.finish();
    }, [me?._id, wizardDispatch]);

    return (
        <FlexColumn fillParent overflow="hidden">
            <FlexRow
                mainAxis="space-between"
                crossAxis="center"
                padding={{ leftRight: 20, topBottom: 20 }}>
                <Spacer width={40} shrink={0}>
                    <Icon
                        onClick={() => wizardDispatch.goToIndex(0)}
                        icon={!wizardState.isFirst ? faChevronLeft : faTimes}
                        size={1.25}
                    />
                </Spacer>
                <FlexColumn crossAxis="center">
                    <PageTitle
                        text={translation({
                            sv: "Verifiering",
                            en: "Verification",
                        })}
                    />
                </FlexColumn>
                <Spacer width={40} shrink={0} />
            </FlexRow>
            <HorizontalLine />
            <VerticalDivider L />
            <PhoneVerification />
            <VerticalDivider L />
        </FlexColumn>
    );
};

export { PhoneVerificationPage };
