import { useMe } from "@@/authentication/me/me-context";
import { ActionAndCancelButtons } from "@@/backoffice/shared/action-and-cancel-buttons";
import { browserLogger } from "@@/settings";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import {
    Paragraph,
    TextBox,
    TextEdit,
    paragraphTextFactory,
} from "@@/shared/text";
import { useToast } from "@@/toasts/context/toast-context";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faCheckCircle, faShare } from "@fortawesome/pro-regular-svg-icons";
import { faEdit, faEnvelope, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Padding, isApiError, translation } from "@towni/common";
import * as React from "react";
import { useInitSetUserEmailAddress } from "../use-init-set-user-email-address";
import { useSendUserEmailVerification } from "../use-send-user-email-verification";
import { VerificationBadge } from "./verified-badge";

type Props = {
    padding?: Padding;
};

const UserModalEditEmail = (props: Props) => {
    const theme = useTheme();
    const toast = useToast();
    const [me, { meQuery }] = useMe();
    const translate = useTranslate();
    const [verificationEmailSent, setVerificationEmailSent] =
        React.useState(false);
    const [status, setStatus] = React.useState<"VIEW" | "EDIT" | "VERIFY">(
        "VERIFY",
    );
    const initSetUserEmailAddress = useInitSetUserEmailAddress({
        onSuccess: () => {
            setStatus("VERIFY");
        },
    });
    const sendUserEmailVerificationMutation = useSendUserEmailVerification({
        onSuccess: () => {
            setVerificationEmailSent(true);
        },
        onError: error => {
            if (isApiError(error)) {
                toast.fromError(error);
            }
            browserLogger.error(error);
        },
    });
    const [updatedEmailAddress, setUpdatedEmailAddress] =
        React.useState<string>();
    const currentEmailAddress = me?.email?.emailAddress;
    const isVerified = !!me?.email?.verified;
    const isNew =
        typeof updatedEmailAddress !== "undefined" &&
        updatedEmailAddress !== currentEmailAddress;

    React.useEffect(() => {
        // TODO: Switch to sockets
        if (status !== "VERIFY") return;
        const interval = setInterval(() => {
            if (!meQuery.isFetching) {
                meQuery.refetch();
            }
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [status, meQuery, updatedEmailAddress, currentEmailAddress]);

    React.useEffect(() => {
        if (
            (typeof updatedEmailAddress === "undefined" ||
                updatedEmailAddress === currentEmailAddress) &&
            status === "VERIFY"
        ) {
            setStatus("VIEW");
        }
    }, [me?.email, updatedEmailAddress, currentEmailAddress, status]);

    return (
        <>
            <FlexRow fillParentWidth crossAxis="center" padding={props.padding}>
                {status === "VIEW" ? (
                    <FlexColumn fillParentWidth>
                        <FlexRow
                            fillParentWidth
                            mainAxis="space-between"
                            crossAxis="flex-start">
                            <Icon
                                icon={faEnvelope}
                                color={theme.colors.black.light65}
                                css={{
                                    minWidth: 16,
                                    paddingTop: 3,
                                }}
                            />
                            <HorizontalDivider S />
                            <TextBox
                                text={currentEmailAddress}
                                color={theme.colors.default.text}
                                grow={1}
                                shrink={1}
                                css={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                            />
                            <HorizontalDivider S />
                            <VerificationBadge
                                verified={!!me?.email?.verified}
                            />
                        </FlexRow>
                        <VerticalDivider />
                        <FlexRow>
                            <FlexRow
                                onClick={() => setStatus("EDIT")}
                                css={{
                                    color: theme.colors.default.text.value.withAlpha(
                                        0.5,
                                    ).asString,
                                    "&:hover": {
                                        color: theme.colors.primary.asString,
                                    },
                                }}>
                                <Icon icon={faEdit} size="XS" />
                                <HorizontalDivider XXS />
                                <TextBox
                                    text={translation({
                                        sv: "Ändra",
                                        en: "Edit",
                                    })}
                                    size="XS"
                                />
                            </FlexRow>
                            <Conditional
                                when={!!me && !!me.email && !me.email.verified}>
                                <HorizontalDivider S />
                                <FlexRow
                                    onClick={() => {
                                        sendUserEmailVerificationMutation.mutate(
                                            {
                                                emailAddress: me?.email
                                                    .emailAddress as string,
                                            },
                                        );
                                    }}
                                    css={{
                                        color: verificationEmailSent
                                            ? theme.colors.primary.asString
                                            : theme.colors.default.text.value.withAlpha(
                                                  0.5,
                                              ).asString,
                                        "&:hover": !verificationEmailSent
                                            ? {
                                                  color: theme.colors.primary
                                                      .asString,
                                              }
                                            : {},
                                    }}>
                                    <Icon
                                        spin={
                                            sendUserEmailVerificationMutation.isPending
                                        }
                                        icon={
                                            sendUserEmailVerificationMutation.isPending
                                                ? faSpinnerThird
                                                : verificationEmailSent
                                                  ? faCheckCircle
                                                  : faShare
                                        }
                                        size="S"
                                    />
                                    <HorizontalDivider XXS />
                                    <TextBox
                                        text={
                                            !verificationEmailSent
                                                ? translation({
                                                      en: "Resend verification email",
                                                      sv: "Skicka verifieringsmail",
                                                  })
                                                : translation({
                                                      sv: "Verifieringsmail skickat",
                                                      en: "Verification email sent",
                                                  })
                                        }
                                        size="XS"
                                    />
                                </FlexRow>
                            </Conditional>
                        </FlexRow>
                    </FlexColumn>
                ) : null}
                {status === "EDIT" ? (
                    <FlexColumn fillParentWidth>
                        <FlexRow crossAxis="center">
                            <TextEdit
                                type="email"
                                label={translation({
                                    sv: "Ändra e-postadress",
                                    en: "Edit email address",
                                })}
                                placeholder={translation({
                                    sv: "E-post",
                                    en: "Email",
                                })}
                                value={
                                    updatedEmailAddress ?? currentEmailAddress
                                }
                                onChange={setUpdatedEmailAddress}
                                disabled={meQuery.isPending}
                                showSpinner={meQuery.isPending}
                            />
                            {isNew ? (
                                <FlexRow
                                    padding={{ top: 30 }}
                                    crossAxis="center"
                                    onClick={() =>
                                        setUpdatedEmailAddress(undefined)
                                    }>
                                    <HorizontalDivider S />
                                    <FontAwesomeIcon
                                        icon={faUndo}
                                        color={
                                            theme.colors.black.light65.asString
                                        }
                                        style={{ fontSize: "1.5rem" }}
                                    />
                                </FlexRow>
                            ) : null}
                        </FlexRow>
                        <ActionAndCancelButtons
                            actionTitle={translation({
                                sv: "Verifiera",
                                en: "Verify",
                            })}
                            padding={{ top: 10, bottom: 0 }}
                            disabled={
                                (!isNew && isVerified) ||
                                initSetUserEmailAddress.isPending
                            }
                            isLoading={initSetUserEmailAddress.isPending}
                            onCancel={() => setStatus("VIEW")}
                            onAction={() => {
                                const emailAddress =
                                    !isNew && !isVerified
                                        ? currentEmailAddress
                                        : updatedEmailAddress;
                                if (!emailAddress) return;
                                if (emailAddress.endsWith("@gnail.com")) {
                                    const confirmation = confirm(
                                        translate(
                                            translation({
                                                sv: "Är du säker på att du ville skriva @gnail.com och inte @gmail.com?",
                                                en: "Are you sure you wanted to write @gnail.com and not @gmail.com?",
                                            }),
                                        ),
                                    );
                                    if (!confirmation) return;
                                }
                                if (emailAddress.endsWith("@gmali.com")) {
                                    const confirmation = confirm(
                                        translate(
                                            translation({
                                                sv: "Är du säker på att du ville skriva @gmali.com och inte @gmail.com?",
                                                en: "Are you sure you wanted to write @gmali.com and not @gmail.com?",
                                            }),
                                        ),
                                    );
                                    if (!confirmation) return;
                                }
                                if (emailAddress.endsWith("@gmail.co")) {
                                    const confirmation = confirm(
                                        translate(
                                            translation({
                                                sv: "Är du säker på att du ville skriva @gmail.co och inte @gmail.com?",
                                                en: "Are you sure you wanted to write @gmail.co and not @gmail.com?",
                                            }),
                                        ),
                                    );
                                    if (!confirmation) return;
                                }
                                if (emailAddress.endsWith("@icould.com")) {
                                    const confirmation = confirm(
                                        translate(
                                            translation({
                                                sv: "Är du säker på att du ville skriva @icould.com och inte @icloud.com?",
                                                en: "Are you sure you wanted to write @icould.com and not @icloud.com?",
                                            }),
                                        ),
                                    );
                                    if (!confirmation) return;
                                }
                                if (emailAddress.endsWith("@hotnail.com")) {
                                    const confirmation = confirm(
                                        translate(
                                            translation({
                                                sv: "Är du säker på att du ville skriva @hotnail.com och inte @hotmail.com?",
                                                en: "Are you sure you wanted to write @hotnail.com and not @hotmail.com?",
                                            }),
                                        ),
                                    );
                                    if (!confirmation) return;
                                }
                                initSetUserEmailAddress.mutateAsync({
                                    emailAddress,
                                });
                            }}
                        />
                    </FlexColumn>
                ) : null}
                {status === "VERIFY" ? (
                    <Paragraph
                        content={[
                            translation({
                                sv: "Ett mejl med en verifieringslänk har skickats till ",
                                en: "An email with a verification link has been emailed to ",
                            }),
                            paragraphTextFactory({
                                text: updatedEmailAddress ?? "...",
                                css: {
                                    fontWeight: "700",
                                    color: "#555",
                                    padding: "4px 6px",
                                    backgroundColor:
                                        theme.colors.white.light95.asString,
                                    borderRadius: 3,
                                },
                            }),
                            translation({
                                sv: ". För att slutföra bytet av din e-postadress klicka på länken i mailet för att verifiera att det är din adress.",
                                en: ". To complete the change of your email address, click on the link in the email to verify that it is your address.",
                            }),
                        ]}
                        css={{
                            lineHeight: 1.5,
                            color: "#999",
                        }}
                    />
                ) : null}
            </FlexRow>
        </>
    );
};

export { UserModalEditEmail };
