import { browserLogger } from "@@/settings/browser-logger";
import { apiFetchClient } from "@@/shared/fetch-client";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    generateRequestId,
    GetSingleResponse,
    InitiateSwishPaymentApiCommand,
    initiateSwishPaymentApiCommandFactory,
    InitiateSwishPaymentApiCommandResponse,
    MINUTES,
    support,
    SwishNumber,
    SwishPayment,
    SwishPaymentType,
    toSwishUUID,
    UUID,
} from "@towni/common";
import * as React from "react";
import { v4 } from "uuid";
import { usePaymentDetailsContext } from "../payment-details.context";
import { usePaymentSocketEvents } from "./use-swish-payment-events";

type State = {
    readonly paymentUUID: UUID;
    readonly swishPayment: SwishPayment | undefined;
    readonly isRequested: boolean;
    readonly isLoading: boolean;
    readonly swishPaymentQuery: UseQueryResult<
        GetSingleResponse<SwishPayment>,
        Error
    >;
};

type Actions = {
    readonly regeneratePaymentUUID: () => void;
    readonly initiateSwishPayment: <Type extends SwishPaymentType>(
        type: Type,
        payerSwishNumber: ["swish_on_device"] extends [Type]
            ? undefined
            : SwishNumber,
    ) => Promise<InitiateSwishPaymentApiCommandResponse | undefined>;
};

const SwishPaymentContext = React.createContext<
    readonly [State, Actions] | undefined
>(undefined);

const createSwishOnDeviceUrl = (paymentToken: string, callbackUrl: string) =>
    `swish://paymentrequest?token=${paymentToken}&callbackurl=${encodeURIComponent(
        callbackUrl,
    )}`;

const SwishPaymentProvider = (props: {
    readonly children?: React.ReactNode;
}) => {
    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails,
    );
    const [paymentUUID, setPaymentUUID] = React.useState<UUID>(
        toSwishUUID(v4()) as UUID,
    );
    const [isInitiated, setIsInitiated] = React.useState<boolean>(false);
    const [isFinished, setIsFinished] = React.useState<boolean>(false);
    const queryResult = useQuery<GetSingleResponse<SwishPayment>, Error>({
        queryKey: ["payments", paymentUUID],
        retry: 3,
        staleTime: 0,
        gcTime: 5 * MINUTES,
        enabled: isInitiated && !isFinished,
        refetchOnWindowFocus: true,
        refetchInterval: 2000,
        queryFn: ({ signal }) =>
            apiFetchClient.get<GetSingleResponse<SwishPayment>>({
                route: `/payments/swish/${paymentUUID}`,
                customConfig: {
                    signal,
                },
            }),
    });

    usePaymentSocketEvents({
        onPaymentSocketEvent: event => {
            if (event.data.paymentUUID !== paymentUUID) {
                browserLogger.log("incorrect id, ignoring");
                return;
            }
            if (event.data.paymentProvider === "SWISH") {
                browserLogger.log("incorrect payment provider, ignoring");
                return;
            }
            queryResult.refetch();
        },
    });

    React.useEffect(() => {
        if (!queryResult.data) return;
        if (queryResult.data.item.status !== "PENDING") {
            setIsFinished(true);
        }
    }, [queryResult.data?.item.status]);

    const initiateSwishPayment = useMutation<
        InitiateSwishPaymentApiCommandResponse,
        Error,
        InitiateSwishPaymentApiCommand
    >({
        mutationFn: async (command: InitiateSwishPaymentApiCommand) => {
            // Assert stuff
            if (
                command.type === "swish_on_another_device" &&
                !command.payerSwishNumber
            ) {
                const error = new Error(
                    "Cannot initiate swish on another device payment without payer swish number",
                );
                Sentry.captureException(error);
                throw error;
            }
            if (
                command.type === "swish_on_device" &&
                !!command.payerSwishNumber
            ) {
                command = { ...command, payerSwishNumber: undefined };
                Sentry.captureMessage(
                    "ignoring payer swish number because swish_on_device requested",
                );
            }
            if (!paymentDetails) {
                const error = new Error(
                    `Betalningsdetaljer saknas, försök igen eller kontakta ${support.towni.supportEmail} för assistans`,
                );
                Sentry.captureException(error);
                throw error;
            }

            const requestId = generateRequestId();
            const initResponse = await apiFetchClient.post<
                InitiateSwishPaymentApiCommand,
                InitiateSwishPaymentApiCommandResponse
            >({
                route: `/payments/swish`,
                body: command,
                customConfig: {
                    headers: {
                        "Towni-Request-Id": requestId,
                    },
                },
            });

            return initResponse;
        },
        onSuccess: (data, command) => {
            setIsFinished(false);
            setIsInitiated(true);
            if (command.type === "swish_on_device") {
                if (!data.paymentToken) {
                    const error = new Error(
                        "missing payment token for swish_on_device payment",
                    );
                    Sentry.captureException(error);
                    throw error;
                }
            }
        },
        onError: error => {
            throw error;
        },
    });

    const isLoading = queryResult.isLoading || initiateSwishPayment.isPending;
    const swishPayment = queryResult.data?.item;

    // Build state/dispatch
    const state: State = React.useMemo(
        () => ({
            paymentUUID,
            swishPayment,
            isRequested: isInitiated,
            isLoading,
            swishPaymentQuery: queryResult,
            swishMutationResult: initiateSwishPayment,
        }),
        [
            paymentUUID,
            swishPayment,
            isInitiated,
            isLoading,
            queryResult,
            initiateSwishPayment,
        ],
    );
    const actions: Actions = React.useMemo(
        () => ({
            regeneratePaymentUUID: () => {
                setPaymentUUID(toSwishUUID(v4()) as UUID);
            },
            initiateSwishPayment: async (
                type: SwishPaymentType,
                payerSwishNumber?: SwishNumber,
            ): Promise<InitiateSwishPaymentApiCommandResponse | undefined> => {
                if (!paymentDetails) {
                    const error = new Error(
                        `Betalningsdetaljer saknas, försök igen eller kontakta ${support.towni.supportEmail} för assistans`,
                    );
                    Sentry.captureException(error);
                    throw error;
                }

                setIsInitiated(false); //Reset
                const puuid = toSwishUUID(v4()) as UUID;
                setPaymentUUID(puuid);
                // Command swish payment to be initiated
                // (check mutation events for what happens next)
                return initiateSwishPayment.mutateAsync(
                    initiateSwishPaymentApiCommandFactory({
                        type,
                        orderGroupId: paymentDetails.orderGroupId,
                        paymentUUID: puuid,
                        payerSwishNumber,
                    }),
                );
            },
        }),
        [],
    );

    const output = React.useMemo(
        () => [state, actions] as const,
        [state, actions],
    );

    return (
        <SwishPaymentContext.Provider value={output}>
            {props.children}
        </SwishPaymentContext.Provider>
    );
};

// Hooks
const useSwishPayment = () => {
    const context = React.useContext(SwishPaymentContext);
    if (!context)
        throw new Error(
            "useSwishPayment must be used within a SwishPaymentProvider",
        );

    return context;
};

export { createSwishOnDeviceUrl, SwishPaymentProvider, useSwishPayment };
