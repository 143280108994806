import * as React from "react";

const WebpSupportContext = React.createContext<boolean | undefined>(undefined);

// Webp detection copied/based on https://stackoverflow.com/a/27232658
const WebpSupportProvider = ({
    children,
}: {
    readonly children?: React.ReactNode;
}) => {
    const [hasWebpSupport] = React.useState(() => {
        try {
            const element = document.createElement("canvas");
            const canvasSupport = !!(
                element.getContext && element.getContext("2d")
            );
            if (!canvasSupport) return false;

            // Possible to get webp representation of canvas?
            return (
                element.toDataURL("image/webp").indexOf("data:image/webp") === 0
            );
        } catch (error) {
            return false;
        }
    });

    return (
        <WebpSupportContext.Provider value={hasWebpSupport}>
            {children}
        </WebpSupportContext.Provider>
    );
};

const useHasWebpSupport = () => {
    const context = React.useContext(WebpSupportContext);
    if (context === undefined) {
        throw new Error(
            "useWebpSupportState must be used within a WebpSupportContext"
        );
    }
    return !!context;
};

export { WebpSupportProvider, useHasWebpSupport };
