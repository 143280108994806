/* eslint-disable react/prop-types */
// import { ReactQueryDevtools } from "react-query-devtools";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useTranslate } from "@@/translations/use-translate";
import {
    assertNever,
    exists,
    joinTranslatables,
    toTranslatableString,
    translatableString,
} from "@towni/common";
import { z } from "zod";

const svTranslateValue = (value: unknown) => {
    if (typeof value !== "string") return value;
    switch (value) {
        case "string":
            return "text";
        case "number":
            return "nummer";
        case "bigint":
            return "stort nummer";
        case "boolean":
            return "sant/falskt";
        case "symbol":
            return "symbol";
        case "undefined":
            return "undefined";
        case "object":
            return "objekt";
        case "function":
            return "funktion";
        case "map":
            return "map";
        case "nan":
            return "inte ett nummer";
        case "integer":
            return "heltal";
        case "float":
            return "flyttal";
        case "date":
            return "datum";
        case "null":
            return "null";
        case "array":
            return "array";
        case "unknown":
            return "okänt";
        case "promise":
            return "promise";
        case "void":
            return "void";
        case "never":
            return "never";
        case "set":
            return "set";
        case "datetime":
            return "datum och tid";
        case "email":
            return "e-postadress";
        default:
            return value;
    }
};

const SetTranslatedZodErrorMap = () => {
    const translate = useTranslate();
    const getErrorMessage = (
        issue: z.ZodIssueOptionalMessage,
        ctx: z.ErrorMapCtx | { defaultError: z.ErrorMapCtx["defaultError"] },
        depth = 0,
    ): string[] | null => {
        if (depth > 10) return null;
        switch (issue.code) {
            case "custom": {
                return [ctx.defaultError];
            }
            case "invalid_arguments":
                return [issue.argumentsError.flatten().formErrors.join(", ")];
            case "invalid_date":
                return [
                    toTranslatableString({
                        sv: "Ogiltigt datum",
                        en: "Invalid date",
                    }),
                ];
            case "invalid_enum_value":
                return [
                    toTranslatableString({
                        sv: `Endast följande alternativ är giltiga: ${issue.options.join(", ")}`,
                        en: `Only the following options are valid: ${issue.options.join(", ")}`,
                    }),
                ];
            case "invalid_intersection_types":
                return [
                    toTranslatableString({
                        sv: "Ogiltig kombination av typer",
                        en: "Invalid combination of types",
                    }),
                ];
            case "invalid_literal":
                return [
                    toTranslatableString({
                        sv: `Förväntat värde: ${svTranslateValue(issue.expected)}, fick: ${svTranslateValue(issue.received === "undefined" ? "inget" : issue.received)}`,
                        en: `Expected: ${issue.expected}, received: ${issue.received}`,
                    }),
                ];
            case "invalid_return_type":
                return [
                    toTranslatableString({
                        sv: `Ogiltig returtyp: ${issue.returnTypeError.flatten().formErrors.join(", ")}`,
                        en: `Invalid return type: ${issue.returnTypeError.flatten().formErrors.join(", ")}`,
                    }),
                ];
            case "invalid_string":
                return [
                    toTranslatableString({
                        sv: `Ogiltig ${svTranslateValue(issue.validation)}`,
                        en: `Invalid ${issue.validation}`,
                    }),
                ];
            case "invalid_type":
                return [
                    toTranslatableString({
                        sv: `Förväntat värde: ${svTranslateValue(issue.expected)}, fick: ${svTranslateValue(issue.received === "undefined" ? "inget" : issue.received)}`,
                        en: `Expected: ${issue.expected}, received: ${issue.received}`,
                    }),
                ];
            case "invalid_union":
                return issue.unionErrors
                    .flatMap(error =>
                        error.issues
                            .flatMap(issue => getErrorMessage(issue, ctx))
                            .filter(exists),
                    )
                    .filter(exists);
            case "invalid_union_discriminator":
                return [ctx.defaultError];
            // return {
            //     message: ctx.defaultError,
            // };
            case "not_finite":
                return [
                    toTranslatableString({
                        sv: "Förväntades ett ändligt tal",
                        en: "Expected a finite number",
                    }),
                ];
            case "not_multiple_of":
                return [
                    toTranslatableString({
                        sv: `Förväntades multipel av ${issue.multipleOf}`,
                        en: `Expected multiple of ${issue.multipleOf}`,
                    }),
                ];
            case "too_big":
                if (issue.type === "string")
                    return [
                        toTranslatableString({
                            sv: `För långt (max ${issue.maximum} tecken)`,
                            en: `Too long (max ${issue.maximum} characters)`,
                        }),
                    ];
                return [
                    toTranslatableString({
                        en: `Max value ${issue.maximum}`,
                        sv: `Maxvärde ${issue.maximum}`,
                    }),
                ];
            case "too_small":
                switch (issue.type === "string") {
                    case true:
                        return [
                            toTranslatableString({
                                sv: `För kort (minst ${issue.minimum} tecken)`,
                                en: `Too short (at least ${issue.minimum} characters)`,
                            }),
                        ];
                    default: {
                        return [
                            toTranslatableString({
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                sv: `Minsta värde ${issue.minimum} fick ${(ctx as any)?.data}`,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                en: `Min value ${issue.minimum} got ${(ctx as any)?.data}`,
                            }),
                        ];
                    }
                }
            case "unrecognized_keys":
                return [
                    toTranslatableString({
                        sv: `Okända nycklar: ${issue.keys.join(", ")}`,
                        en: `Unrecognized keys: ${issue.keys.join(", ")}`,
                    }),
                ];
            default:
                assertNever(issue);
        }
    };

    useMountEffect(() => {
        z.setErrorMap((issue, ctx) => {
            const customMessage = issue.message
                ? translate(
                      translatableString.fromTranslatableString(issue.message),
                  )
                : null;
            if (customMessage) {
                return { message: customMessage };
            }
            const messages = getErrorMessage(issue, ctx);
            return {
                message: messages
                    ? toTranslatableString(
                          joinTranslatables(
                              messages.map(message =>
                                  translatableString.fromTranslatableString(
                                      message,
                                  ),
                              ),
                              ", ",
                          ),
                      )
                    : ctx.defaultError,
            };
        });
    });
    return null;
};

export { SetTranslatedZodErrorMap };
