import { asArray, assertNever } from "@towni/common";
import * as React from "react";
import {
    BreakpointContext,
    BreakpointContextState,
} from "./breakpoint-context";
import { BreakpointSize, breakpointSizes } from "./breakpoint-sizes";

type UsePageBreakpointParams = {
    /**
     * Fallbacks to `some` if not provided
     * When some, and when or whenNot is an array, only has to be true
     * @type {("some" | "every")}
     */
    type?: "some" | "every";
    when?:
        | BreakpointSize
        | boolean
        | (() => boolean)
        | (BreakpointSize | boolean | (() => boolean))[];
    whenNot?:
        | BreakpointSize
        | boolean
        | (() => boolean)
        | (BreakpointSize | boolean | (() => boolean))[];
};

const validatePageBreakpoint = (
    breakpoints: BreakpointContextState["status"],
    input: undefined | (BreakpointSize | boolean | (() => boolean))
) => {
    // if no input provided, return true
    if (typeof input === "undefined") return true;

    // boolean check
    if (typeof input === "boolean") return input;

    // check breakpoint size by name
    if (typeof input === "string") {
        const _input = input
            .replace("🖥", "DESKTOP")
            .replace("📱", "MOBILE") as typeof input;
        if (!breakpointSizes.includes(_input))
            throw new Error(`Unknown breakpoint seize; ${_input}`);
        return breakpoints[_input];
    }

    return !!input();
};

function usePageBreakpoint(): BreakpointContextState;
function usePageBreakpoint(params: UsePageBreakpointParams): boolean;
function usePageBreakpoint(params: undefined): BreakpointContextState;
function usePageBreakpoint(
    params: UsePageBreakpointParams | undefined
): boolean | BreakpointContextState;
function usePageBreakpoint(
    params?: UsePageBreakpointParams | undefined
): boolean | BreakpointContextState {
    const context = React.useContext(BreakpointContext);
    if (context === undefined) {
        throw new Error(
            "useBreakpointContext must be used within a BreakpointContext"
        );
    }

    if (typeof params === "undefined") return context;

    const breakpointState = context.status;
    const { when, whenNot } = params;
    if (typeof when === "undefined" && typeof whenNot === "undefined") {
        return true;
    }

    if (
        Array.isArray(when) &&
        !when.length &&
        Array.isArray(whenNot) &&
        !whenNot.length
    ) {
        return true;
    }

    const type = params.type ?? "some";
    switch (type) {
        case "every": {
            const whens = asArray(when);
            const whenValidation =
                !!whens.length &&
                whens.every(item =>
                    validatePageBreakpoint(context.status, item)
                );

            const whenNots = asArray(whenNot);
            const whenNotValidation =
                !whenNots.length ||
                whenNots.every(
                    item => !validatePageBreakpoint(breakpointState, item)
                );
            return whenValidation && whenNotValidation;
        }
        case "some": {
            const whens = asArray(when);
            const whenValidation = whens.some(item =>
                validatePageBreakpoint(breakpointState, item)
            );
            const whenNots = asArray(whenNot);
            const whenNotValidation = whenNots.some(
                item => !validatePageBreakpoint(breakpointState, item)
            );

            return whenValidation || whenNotValidation;
        }
        default:
            assertNever(type);
    }
}

export type { UsePageBreakpointParams };
export { usePageBreakpoint };
