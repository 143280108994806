import { useLanguageContext } from "@@/settings/language-context";
import { createTranslator } from "@towni/common";
import { useCallback } from "react";

const useTranslator = () => {
    const { language } = useLanguageContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const translator = useCallback(createTranslator(language)(new Map()), [
        language,
    ]);
    return translator;
};
export { useTranslator };
