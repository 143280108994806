import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { useTheme } from "@emotion/react";
import { ProductId, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";

const BauerPopularFoodSection: FC = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });

    const bauermenu = React.useMemo(
        () => getProductId("product__1BiDwr2v0RZv-UP5vSE1" as ProductId),
        []
    );
    const tradrullar = React.useMemo(
        () => getProductId("product__4PqJnq59pfq-xZLFhxnx" as ProductId),
        []
    );

    const productIds = [bauermenu, tradrullar];

    return (
        <SectionArea
            title={translation({
                sv: "Våra populäraste menyer",
                en: "",
            })}
            subtitle={translation({
                sv: "Res tillbaka i tiden eller upplev skogens magiska kraft genom vår upplevelsemåltider. Njut av en måltid med inspiration från trolska skogar eller varför inte en pröva på en lokal matresa från svunnen tid. ",
                en: "",
            })}
            sectionAreaId="bauer-pupular-4-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <OneSquareSection
                            key={id}
                            productId={id}
                            aspectRatio={"4:3"}
                            contentTop={true}
                            actionText={
                                index >= actionTexts.length
                                    ? actionTexts[0]
                                    : actionTexts[index]
                            }
                            hideKicker
                            colorSet={theme.colors.primary}
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Boka",
        en: "Book",
    }),
    explore: translation({
        sv: "Se meny",
        en: "Menu",
    }),
};

const actionTexts = [text.book, text.explore];

export { BauerPopularFoodSection };
