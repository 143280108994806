import {
    isParagraphDateTime,
    ParagraphDateTime,
} from "@@/shared/text/paragraphs/paragraph-date-time";
import { useTranslate } from "@@/translations/use-translate";
import { asArray, exists, Translatable } from "@towni/common";
import { ForEach } from "../../for-each";
import { TextShimmer } from "../../pictures/shimmers";
import { isParagraphButton, ParagraphButton } from "./paragraph-button";
import { isParagraphHzSpace, ParagraphHzSpace } from "./paragraph-hz-space";
import { isParagraphIcon, ParagraphIcon } from "./paragraph-icon";
import {
    isParagraphJsxElement,
    ParagraphJsxElement,
} from "./paragraph-jsx-element";
import {
    isParagraphLineBreak,
    ParagraphLineBreak,
} from "./paragraph-line-break";
import { isParagraphLink, ParagraphLink } from "./paragraph-link";
import { isParagraphPrice, ParagraphPrice } from "./paragraph-price";
import { isParagraphText, ParagraphText } from "./paragraph-text";

type _ParagraphContent =
    | undefined
    | Translatable
    | ParagraphButton
    | ParagraphLink
    | ParagraphText
    | ParagraphPrice
    | ParagraphJsxElement
    | ParagraphIcon
    | ParagraphHzSpace
    | ParagraphLineBreak
    | ParagraphDateTime;

type ParagraphContent = _ParagraphContent | _ParagraphContent[];

type Props = {
    /**
     * When true indicates that the text content is loading and not yet available.
     * @type {boolean}
     */
    readonly spin?: boolean;
    readonly onClick?: () => void;
    readonly className?: string;
    readonly divider?: JSX.Element;
    readonly content: ParagraphContent;
    readonly dataTestId?: string;
};

const Paragraph = (props: Props) => {
    const translate = useTranslate();
    const content = asArray(props.content).map((item, index) => {
        if (typeof item === "undefined") return null;
        if (isParagraphButton(item))
            return <ParagraphButton key={index} params={item} />;
        if (isParagraphJsxElement(item)) return item.element;
        if (isParagraphLink(item))
            return <ParagraphLink key={index} link={item} />;
        if (isParagraphLineBreak(item))
            return <ParagraphLineBreak key={index} />;
        if (isParagraphText(item))
            return <ParagraphText key={index} text={item} />;
        if (isParagraphPrice(item))
            return <ParagraphPrice key={index} price={item} />;
        if (isParagraphIcon(item))
            return <ParagraphIcon key={index} iconData={item} />;
        if (isParagraphHzSpace(item))
            return <ParagraphHzSpace key={index} hzSpace={item} />;
        if (isParagraphDateTime(item))
            return <ParagraphDateTime key={index} dateTime={item} />;
        if (typeof item === "string") <span key={index}>{item}</span>;
        return <span key={index}>{translate(item)}</span>;
    });

    if (props.spin) return <TextShimmer rows={3} />;
    if (!content?.filter(exists).length) return null;
    return (
        <div
            className={props.className}
            css={{ margin: 0 }}
            data-testid={props.dataTestId}>
            <ForEach itemOf={content} divider={props.divider}>
                {item => item}
            </ForEach>
        </div>
    );
};

export { Paragraph };
export type { ParagraphContent, Props as ParagraphProps };
