import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { useMemo } from "react";

const useSingleApiResource = <Resource, TError = unknown>(
    queryKey: QueryKey,
    fetcher: QueryFunction<Resource>,
    options?: {
        enabled?: boolean;
    },
): readonly [Resource | undefined, UseQueryResult<Resource, TError>] => {
    const queryResult = useQuery<Resource, TError>({
        queryKey: queryKey,
        queryFn: fetcher,
        enabled:
            typeof options?.enabled === "undefined" ? true : !!options.enabled,
    });
    const result = useMemo(() => {
        return [queryResult.data, queryResult] as const;
    }, [queryResult]);
    return result;
};

export { useSingleApiResource };
