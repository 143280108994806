import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    RemoveStoredPaymentMethodCommand,
    isApiError,
    support,
} from "@towni/common";

const mainQueryKey = "payment-methods";

const removeStoredPaymentMethodCommand = (
    command: RemoveStoredPaymentMethodCommand,
) => {
    return apiFetchClient.delete<RemoveStoredPaymentMethodCommand>({
        route: `/payments/stripe/payment-methods/${command.storedPaymentMethodId}`,
        body: command,
    });
};

const useRemoveStoredPaymentMethod = (options?: {
    onSuccess?: () => void | Promise<void>;
    onError?: () => void | Promise<void>;
    disableNotifications?: boolean;
}) => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation<
        unknown,
        unknown,
        RemoveStoredPaymentMethodCommand
    >({
        mutationFn: removeStoredPaymentMethodCommand,
        onSuccess: _ => {
            queryClient.invalidateQueries({ queryKey: [mainQueryKey] });
            options?.onSuccess?.();
            !options?.disableNotifications &&
                toast.success({ message: `Betalningsmetod borttagen` });
        },
        onError: (error, _command) => {
            !options?.disableNotifications && isApiError(error)
                ? toast.fromError(error)
                : toast.danger({
                      message: `Kunde inte att ta bort betalningsmetoden. Försök igen och om felet kvarstår, kontakta ${support.towni.supportEmail}`,
                  });
            options?.onError?.();
            throw error;
        },
    });

    return mutation;
};

export { useRemoveStoredPaymentMethod };
