import { useCheckoutContext } from "@@/carts/checkout.context";
import { PageLoad } from "@@/pages/page-load";
import { useAccommodationFeed } from "@@/products/accommodations/hooks/use-accommodation-feed";
import { useQueryOrParam } from "@@/shared/use-query-or-param";
import {
    Accommodation,
    AccommodationFeed,
    AccommodationQuantityType,
    DateRange,
    IsoAndUnixTimestamp,
    Order,
    OrderId,
    Product,
    ResourceId,
    ResourceReservationRequestInfo,
    TimeRange,
    adjustTimeForAccommodation,
    asDate,
    dateRangeFactory,
    isAccommodation,
    isOrderItemAccommodation,
    isoAndUnixFactory,
    setWithinRange,
    timeRangeFactory,
    translation,
} from "@towni/common";
import { addDays, addMonths, addYears, startOfToday } from "date-fns";
import React, { ReactNode, createContext, useEffect, useState } from "react";
import { StoreApi } from "zustand";

import { create as zustand } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

type AccommodationQuantities = {
    [key in AccommodationQuantityType]: number;
};

type State = {
    accommodation: Accommodation;
    timeRange: TimeRange | IsoAndUnixTimestamp | undefined;
    quantities: AccommodationQuantities;
    requestedResourceQuantity: number;
    hasDatePickerBeenShown: boolean;
    hasQuantityPickerBeenShown: boolean;
    feed: AccommodationFeed;
    reservations: ResourceReservationRequestInfo[];
    onPremiseSettings?: {
        orderId?: OrderId;
    };
    preferredResourceIds?: ResourceId[];
    cutoffDate: IsoAndUnixTimestamp;
};

type Actions = {
    setTime(time: TimeRange | IsoAndUnixTimestamp | undefined): void;
    setQuantities(quantities: AccommodationQuantities): void;
    setRequestedResourceQuantity(value: number): void;
    setHasDatePickerBeenShown(shown: boolean): void;
    setHasQuantityPickerBeenShown(shown: boolean): void;
    setFeed(feed: AccommodationFeed): void;
};

type Context = State & Actions;

const BookingContext = createContext<StoreApi<Context>>(
    undefined as unknown as StoreApi<Context>,
);

const pickFirstBookableRange = (feed: AccommodationFeed) => {
    const today = isoAndUnixFactory(startOfToday());

    const first = feed.dates.find(
        date =>
            date.isAvailable &&
            date.settings.canCheckIn &&
            today.unix <= date.date.unix,
    );
    if (first === undefined) return undefined;
    const date = asDate(first.date);

    //Should check if the date is available
    const miniumStay = addDays(date, first.settings.minimumStay);

    if (first && miniumStay) {
        const range = timeRangeFactory(date, miniumStay);
        return adjustTimeForAccommodation(
            range,
            first.settings.checkInTime,
            first.settings.checkOutTime,
        );
    }
    return undefined;
};

const calcQuantities = (
    accommodation: Accommodation,
    order?: Order,
): AccommodationQuantities => {
    const defaultNumberOfAdults = accommodation.quantities.ADULT ? 2 : 0;
    const quantities: AccommodationQuantities = {
        ADULT: defaultNumberOfAdults,
        CHILD: 0,
        INFANT: 0,
        GUEST: 0,
        ANIMAL: 0,
    };
    if (order) {
        order.orderItems.forEach(item => {
            if (isOrderItemAccommodation(item)) {
                const booked = item.quantities;
                booked.forEach(q => {
                    quantities[q.type.type] = q.quantity.value;
                });
            }
        });
    }
    return quantities;
};

const BookingAccommodationContextProvider = (props: {
    product: Product;
    showDatePicker?: boolean;
    showQuantityPicker?: boolean;
    oldOrder?: Order;
    dateRange?: DateRange;
    preferredResourceIds?: ResourceId[];
    children: ReactNode;
}) => {
    // const product = props.product;
    const accommodation = props.product.skus[0].acquire;
    const checkout = useCheckoutContext();
    const params = useQueryOrParam("sudoOpenTime");

    const onPremise = checkout.onPremise;

    const [dateRange] = useState<DateRange>(
        props.dateRange ??
            dateRangeFactory(
                onPremise && params === "true"
                    ? addMonths(new Date(), -1)
                    : startOfToday(),
                addYears(new Date(), 2),
            ),
    );
    const [feed, reservations, _feedIsPending] = useAccommodationFeed({
        product: props.product,
        dateRange,
        options: {
            ignoreResourceReservationsForOrderId: props.oldOrder?._id,
            overrideTimeAvailability: onPremise || !!props.oldOrder, // If we have an order, than its a change and we want to show all times
        },
    });

    if (!isAccommodation(accommodation))
        return <div>Not an accommodation product</div>;

    if (feed.dates.length === 0)
        return (
            <PageLoad
                text={translation({
                    sv: "Bygger gästrummet",
                    en: "Building the rooms",
                })}
            />
        );

    const existingTime = props.oldOrder?.orderItems.find(
        isOrderItemAccommodation,
    )?.time;

    const defaultTimeRange = existingTime ?? pickFirstBookableRange(feed);
    const defaultQuantities = calcQuantities(accommodation, props.oldOrder);

    const cutoffDate = dateRange.start;

    // props.ignoreOrder
    // ? isoAndUnixFactory(addMonths(new Date(), -1))
    // : isoAndUnixFactory(startOfToday()),

    return (
        <_BookingAccommodationContextProvider
            product={props.product}
            accommodation={accommodation}
            reservations={reservations}
            defaultTimeRange={defaultTimeRange}
            defaultQuantities={defaultQuantities}
            showDatePicker={props.showDatePicker}
            showQuantityPicker={props.showQuantityPicker}
            preferredResourceIds={props.preferredResourceIds}
            defaultRequestedResourceQuantity={
                props.oldOrder?.orderItems.find(isOrderItemAccommodation)
                    ?.quantity.value ?? 1
            }
            feed={feed}
            cutoffDate={cutoffDate}
            onPremiseSettings={
                props.oldOrder?._id || onPremise
                    ? {
                          orderId: props.oldOrder?._id,
                      }
                    : undefined
            }>
            {props.children}
        </_BookingAccommodationContextProvider>
    );
};

const _BookingAccommodationContextProvider = (props: {
    accommodation: Accommodation;
    product: Product;
    feed: AccommodationFeed;
    reservations: ResourceReservationRequestInfo[];
    defaultTimeRange?: TimeRange;
    defaultQuantities: AccommodationQuantities;
    defaultRequestedResourceQuantity?: number;
    onPremiseSettings?: {
        orderId?: OrderId;
    };
    preferredResourceIds?: ResourceId[];
    showDatePicker?: boolean;
    showQuantityPicker?: boolean;
    cutoffDate: IsoAndUnixTimestamp;
    children: ReactNode;
}) => {
    const createStore = () => {
        return zustand<Context>((set, _get) => {
            const state: State = {
                accommodation: props.accommodation,
                timeRange: props.defaultTimeRange,
                quantities: props.defaultQuantities,
                requestedResourceQuantity:
                    props.defaultRequestedResourceQuantity ?? 1,
                hasDatePickerBeenShown:
                    props.showDatePicker === undefined ||
                    props.showDatePicker === true
                        ? false
                        : true,
                hasQuantityPickerBeenShown:
                    props.showQuantityPicker === true ? false : true,

                onPremiseSettings: props.onPremiseSettings
                    ? props.onPremiseSettings
                    : undefined,

                feed: props.feed,
                cutoffDate: props.cutoffDate,

                reservations: props.reservations,
                preferredResourceIds: props.preferredResourceIds,
            };
            const actions: Actions = {
                setTime: timeRange => {
                    set({ timeRange });
                },
                setQuantities: quantities => {
                    set({
                        quantities,
                    });
                },
                setRequestedResourceQuantity: value => {
                    set(_state => {
                        const newValue = setWithinRange(value, {
                            min: 1,
                        });
                        return {
                            requestedResourceQuantity: newValue,
                        };
                    });
                },
                setHasDatePickerBeenShown: shown => {
                    set({ hasDatePickerBeenShown: shown });
                },
                setHasQuantityPickerBeenShown: shown => {
                    set({ hasQuantityPickerBeenShown: shown });
                },
                setFeed: feed => {
                    set({ feed });
                },
            };

            const context: Context = {
                ...state,
                ...actions,
            };

            return context;
        });
    };

    const store = React.useRef(createStore());

    useEffect(() => {
        store.current.setState({
            feed: props.feed,
            reservations: props.reservations,
        });
    }, [props.feed, props.reservations]);

    return (
        <BookingContext.Provider value={store.current}>
            {props.children}
        </BookingContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useBookingAccommodationContext = <U extends unknown = Context>(
    selector: (context: Context) => U = context => context as unknown as U,
): U => {
    const store = React.useContext(BookingContext);
    if (store === undefined) {
        throw new Error(
            "useBookingContext must be used within a BookingContext",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { BookingAccommodationContextProvider, useBookingAccommodationContext };
