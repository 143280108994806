import {
    AutoSizedVirtualList,
    RenderListItem,
} from "@@/backoffice/shared/virtual-list-2";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import {
    IsoAndUnixTimestamp,
    TimeRange,
    asDate,
    endOfDayZoned,
    isoAndUnixFactory,
    nowIsoAndUnix,
    parseDates,
    sortBy,
    startOfDayZoned,
    timeRangeFactory,
    toFullDate,
} from "@towni/common";
import { isFuture, isSameDay, isToday } from "date-fns";
import React, { useEffect, useMemo, useRef } from "react";
import { VariableSizeList } from "react-window";
import { HzItem } from "./hz-item";
import { DateData, HzItemData } from "./hz-item-data";

type Props = {
    readonly timeRange: TimeRange | undefined;
    readonly selectableDates: IsoAndUnixTimestamp[];
    readonly onSelect: (date: IsoAndUnixTimestamp | undefined) => void;
    readonly initiallySelected?: IsoAndUnixTimestamp | Date;
    readonly showMonthHeader?: boolean;
    readonly spin?: boolean;
    /**
     * Always force one date to be selected
     * @type {boolean}
     */
    readonly forceSelection?: boolean;
    readonly initialScrollToToday?: boolean;
    readonly dataTestId?: string;
};

const findClosestMatchingDay = (
    closestTo: IsoAndUnixTimestamp,
    source: IsoAndUnixTimestamp[],
) => {
    const currentDate = asDate(closestTo);
    const sameDays = source.filter(d => isSameDay(currentDate, asDate(d)));
    if (sameDays.length === 1) return sameDays[0];

    //Get closest matching day
    const current = closestTo.unix;
    const sorted = source.sort(sortBy(date => date.unix));
    const closestNowAndInTheFuture = sorted.find(date => date.unix >= current);
    const closestNowAndInThePast = closestNowAndInTheFuture
        ? undefined
        : sorted[sorted.length - 1];
    return closestNowAndInTheFuture ?? closestNowAndInThePast;
};

const DateSelectionHzSingle = (props: Props) => {
    const spin = props.spin || !props.timeRange;
    const { initiallySelected, onSelect } = props;
    const listRef = useRef<VariableSizeList<HzItemData[]> | null>(null);
    const [scrollToColumn, setScrollToColumn] = React.useState<
        number | undefined
    >();

    // Make sure start is start of first day in current timezone
    // and end is end of last day in current timezone
    const timeRange = useMemo(() => {
        const current = props.timeRange;
        if (!current) return undefined;
        return timeRangeFactory(
            startOfDayZoned(asDate(current.start)),
            endOfDayZoned(asDate(current.end)),
        );
    }, [props.timeRange]);

    const datesInTimeRange = React.useMemo(() => {
        return parseDates(timeRange);
    }, [timeRange]);

    const [selectedDate, setSelectedDate] = React.useState<
        IsoAndUnixTimestamp | undefined
    >();

    const isSelected = (date: IsoAndUnixTimestamp) =>
        selectedDate && isSameDay(asDate(selectedDate), asDate(date));

    const toggleDateSelected = React.useCallback(
        (date: IsoAndUnixTimestamp | undefined) => {
            if (props.forceSelection && typeof date === "undefined") return;
            setSelectedDate(date);
            onSelect(date);
        },
        [props.forceSelection],
    );

    const renderItemId = (item: HzItemData) => {
        return `${
            item._type === "DATA" ? toFullDate(item.date) : item.index
        }_ITEM`;
    };

    const renderItem: RenderListItem<HzItemData> = params => {
        const { item, onSizeChange, index } = params;
        return (
            <HzItem
                key={renderItemId(item)}
                data={item}
                index={index}
                dataLength={datesInTimeRange.length}
                toggler={toggleDateSelected}
                onSizeChange={onSizeChange}
            />
        );
    };

    const datesWithinTimeRangeInfo = React.useMemo(() => {
        const dates = new Set(
            (props.selectableDates.map(asDate).filter(Boolean) as Date[]).map(
                date => toFullDate(date),
            ),
        );
        const data = datesInTimeRange.map((date): DateData => {
            const canBeBooked = dates.has(toFullDate(date));
            return {
                _type: "DATA",
                date,
                selected: isSelected(date),
                canBeBooked: canBeBooked ? asDate(date) : undefined,
            };
        });

        const filtered = data.reduce((agg, item, index) => {
            if (item.canBeBooked !== undefined) {
                agg.push(item);
                return agg;
            }

            const previous = agg.length > 0 && agg[agg.length - 1];
            const next = index !== data.length && data[index + 1];

            if (
                !previous ||
                (previous._type !== "SQUASH" && previous.canBeBooked) ||
                index === data.length - 1
            ) {
                agg.push(item);
                return agg;
            }

            if (previous._type === "SQUASH" && next && !next.canBeBooked) {
                return agg;
            }
            if (
                previous._type !== "SQUASH" &&
                !previous.canBeBooked &&
                next &&
                !next.canBeBooked
            ) {
                agg.push({ _type: "SQUASH", index: index });
                return agg;
            }

            agg.push(item);

            return agg;
        }, [] as HzItemData[]);
        //First time scroll to selected, after never scroll as the selected should always be in the window
        const index = filtered.findIndex(d => d._type === "DATA" && d.selected);
        if (index > 0 && undefined === scrollToColumn) setScrollToColumn(index);
        else {
            //Scroll to today if nothing is selected
            const todayIndex = filtered.findIndex(
                d => d._type === "DATA" && isToday(asDate(d.date)),
            );
            if (todayIndex === -1) {
                //Find closet to today
                const indexFirstFuture = filtered.findIndex(
                    d => d._type === "DATA" && isFuture(asDate(d.date)),
                );
                if (indexFirstFuture !== scrollToColumn)
                    setScrollToColumn(indexFirstFuture);
            }
            if (todayIndex !== scrollToColumn) setScrollToColumn(todayIndex);
        }

        return filtered;
    }, [datesInTimeRange, selectedDate, initiallySelected]);

    // Do stuff after first load of data
    const isMountedRef = useIsMountedRef();
    const hasBeenLoaded = useRef(false);
    const scrollCommandTimeout = useRef(null);
    useEffect(() => {
        if (hasBeenLoaded.current) return;
        if (spin) return;
        hasBeenLoaded.current = true;
        if (
            !initiallySelected &&
            !props.forceSelection &&
            !props.initialScrollToToday
        )
            return;
        const origin = isoAndUnixFactory(initiallySelected ?? nowIsoAndUnix());
        const closest = findClosestMatchingDay(origin, datesInTimeRange);
        if (closest) {
            if (props.forceSelection || !!initiallySelected) {
                setSelectedDate(closest);
            }

            // Since the list is not mounted yet always,
            // we need to wait for it to be mounted before scrolling,
            // sometimes, otherewise nothing to scroll to
            const scroll = (depth = 0) => {
                return setTimeout(() => {
                    if (depth > 30) return;
                    if (!isMountedRef.current) return;
                    if (!listRef.current) scroll(depth + 1);
                    listRef.current?.scrollToItem(
                        datesWithinTimeRangeInfo.findIndex(
                            item =>
                                item._type === "DATA" &&
                                item.date.unix === closest.unix,
                        ),
                    );
                }, 100);
            };
            if (scrollCommandTimeout.current)
                clearTimeout(scrollCommandTimeout.current);
            scroll();
        }
    }, [spin, datesWithinTimeRangeInfo, props.forceSelection]);

    return (
        <div
            data-testid={props.dataTestId}
            css={{
                width: "100%",
                height: 90,
                overflowX: "auto",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexShrink: 0,
            }}>
            <AutoSizedVirtualList<HzItemData[]>
                key={"date-hz-list-select"}
                dataTestId={props.dataTestId}
                renderItem={renderItem}
                overscanCount={10}
                listRef={listRef}
                layout="horizontal"
                itemData={datesWithinTimeRangeInfo}
                itemKey={(index, data) => {
                    const item = data[index];
                    return renderItemId(item);
                }}
                paddingStart={20}
                paddingEnd={20}
                gap={10}
                estimatedItemSize={100}
            />
        </div>
    );
};

export { DateSelectionHzSingle };
