import { useResourceMapQuery } from "@@/backoffice/for-providers/inventory/use-resource-map-query";
import {
    HexColor,
    MappableId,
    ResourceId,
    ResourceMapConnection,
} from "@towni/common";
import { useCallback, useEffect, useRef } from "react";
// TODO! these should probably not look for items in the whole document
// TODO! however, there should probably not be a problem with it for this feature

const useFindMappableIdForResourceId = (
    resourceMapConnection: ResourceMapConnection | undefined,
) => {
    const findMappableIdForResourceId = useCallback(
        (resourceId: ResourceId): MappableId | undefined => {
            if (!resourceMapConnection) return undefined;
            const mappableId =
                resourceMapConnection.resourceMapMap &&
                (Object.entries(resourceMapConnection.resourceMapMap)?.find(
                    ([, _resourceId]) => resourceId === _resourceId,
                )?.[0] as MappableId);
            return mappableId;
        },
        [resourceMapConnection],
    );

    return findMappableIdForResourceId;
};

const useFindHtmlElementForResourceId = (
    resourceMapConnection: ResourceMapConnection | undefined,
) => {
    const findElementForResourceId = useCallback(
        (resourceId: ResourceId): HTMLElement | undefined => {
            if (!resourceMapConnection) return undefined;
            const mappableId =
                resourceMapConnection.resourceMapMap &&
                Object.entries(resourceMapConnection.resourceMapMap)?.find(
                    ([, _resourceId]) => resourceId === _resourceId,
                )?.[0];
            return (
                (mappableId && document.getElementById(mappableId)) || undefined
            );
        },
        [resourceMapConnection],
    );

    return findElementForResourceId;
};

const useHtmlElementResourceMapSelection = (
    resourceMapConnection: ResourceMapConnection,
    selectionColor: HexColor = "#10c49e" as HexColor,
    requestedResources: ResourceId[],
) => {
    const colors = useRef(new Map<MappableId, HexColor>());
    const resourceMapQuery = useResourceMapQuery(
        resourceMapConnection.resourceMapId,
    );

    const allMappedItems = resourceMapConnection.resourceMapMap
        ? Object.keys(resourceMapConnection.resourceMapMap)
        : [];

    // Find all elements that are mapped could this be dangerous?
    const elements = allMappedItems
        .map(groupId => {
            return document.getElementById(groupId);
        })
        .filter(Boolean) as HTMLElement[];

    // const requestedResources = useBookingContext(
    //     context => context.requestedResources,
    // );
    // Keep track of selected items
    useEffect(() => {
        for (const element of elements) {
            // Find color switching child elements
            const colorSwitchingElementIds =
                resourceMapQuery.data?.colorSwitchingAreas
                    ?.filter(item => item.parentId === element.id)
                    .map(item => item.areaId) as MappableId[];
            const colorSwitchingElements = colorSwitchingElementIds
                .map(elementId => {
                    return document.getElementById(elementId);
                })
                .filter(Boolean) as HTMLElement[];

            // If selected set selection color
            const resourceId =
                resourceMapConnection.resourceMapMap?.[
                    element.id as MappableId
                ];
            const isSelected =
                resourceId && requestedResources.includes(resourceId);

            if (isSelected) {
                colorSwitchingElements.forEach(element => {
                    if (!colors.current.has(element.id as MappableId)) {
                        colors.current.set(
                            element.id as MappableId,
                            element.style.fill as HexColor,
                        );
                    }
                    element.style.fill = selectionColor;
                });
                continue;
            }

            // Reset color on unselected
            colorSwitchingElements.forEach(element => {
                if (!colors.current.has(element.id as MappableId)) return;
                element.style.fill =
                    colors.current.get(element.id as MappableId) ?? "";
            });
        }
    }, [
        elements,
        requestedResources,
        resourceMapConnection.resourceMapMap,
        resourceMapQuery.data?.colorSwitchingAreas,
        selectionColor,
    ]);
};

export {
    useFindHtmlElementForResourceId,
    useFindMappableIdForResourceId,
    useHtmlElementResourceMapSelection,
};
