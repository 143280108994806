import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { css, useTheme } from "@emotion/react";
import { RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { AppPage } from "../../pages/app-page";
import { appPageIdFactory } from "../../pages/page-navigation/page-navigation-context";
import {
    KronocampingColorContext,
    kronocampingColors,
} from "./kronocamping-color-context";
import { KronocampingDiscoverMoreSection } from "./sections/kronocamping-discover-more-section";
import { KronocampingExerciseMindSection } from "./sections/kronocamping-exercise-mind-section";
import { KronocampingFoodSection } from "./sections/kronocamping-food-section";
import { KronocampingGetOutAndMoveSection } from "./sections/kronocamping-get-out-and-move-section";
import { KronocampingKidsClubPromoSection } from "./sections/kronocamping-kids-club-section";
import { KronocampingKidsFourSection } from "./sections/kronocamping-kids-four-section";
import { KronocampingKidsAdventureGolfPromoSection } from "./sections/kronocamping-kids-padel-section";
import { KronocampingLateSummerSection } from "./sections/kronocamping-late-summer-section";
import { KronocampingMoreForYouthSection } from "./sections/kronocamping-more-for-youth-section";
import { KronoNextSeasonSection } from "./sections/kronocamping-next-season-section";
import { KronocampingSjokantenPromoSection } from "./sections/kronocamping-sjokanten-promo";
import { KronoCampingSummerBBQSection } from "./sections/kronocamping-summer-bbg-section";
import { KronocampingSupSection } from "./sections/kronocamping-sup-section";
import { KronocampingWellnesSections } from "./sections/kronocamping-wellnes-section";

type Props = {
    title?: string;
};

const KronocampingStoreFront = (props: Props) => {
    const vh100 = use100vh();
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <KronocampingColorContext.Provider value={kronocampingColors}>
                <Helmet>
                    <title>{props.title ?? "Kronocamping"}</title>
                </Helmet>
                <AppPage
                    pageId={appPageIdFactory("kronocamping-store-front")}
                    crossAxis={isDesktop ? "center" : undefined}
                    anchors={[]}
                    header={
                        <FlexRow
                            fillParentWidth
                            shrink={0}
                            mainAxis="flex-end"
                            crossAxis="center"
                            padding={{ leftRight: 20, topBottom: 10 }}
                            styling={css`
                                position: absolute;
                                z-index: 50;
                            `}>
                            <CurrentUserIcon theme="PRIMARY" />
                        </FlexRow>
                    }>
                    {/* Header Image >>>> */}
                    <FlexColumn
                        fillParentWidth
                        shrink={0}
                        padding={{ top: 0, leftRight: 32 }}
                        height={vh100 * 0.65}
                        maxHeight={675}
                        background={{
                            imageUrl:
                                "https://we-are-alfred.imgix.net/destinations/destination-kronocamping-poster.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop",
                            fit: "COVER",
                        }}>
                        <img
                            style={{
                                display: "block",
                                width: 120,
                                marginLeft: -21,
                                marginTop: -17,
                            }}
                            alt="kronocamping logo"
                            src="https://we-are-alfred.imgix.net/destinations/destination-kronocamping-flag-logo.png?dpr=2&q=60&fm=png&w=100"
                        />
                        <TextBox
                            text="Något för alla på"
                            color={"#ffffff"}
                            size={1.5 as RemSize}
                            styling={css`
                                text-shadow: 0px 4px 20px rgba(5, 32, 72, 0.6);
                            `}
                        />
                        <VerticalDivider size="3XS" />
                        <TextBox
                            text="Kronocamping"
                            color={"#ffffff"}
                            size={2.25 as RemSize}
                            weight="900"
                            styling={css`
                                text-shadow: 0px 4px 20px rgba(5, 32, 72, 0.6);
                            `}
                        />
                    </FlexColumn>
                    {/* <<<< Header Image */}

                    {/* Content >>>> */}
                    <FlexColumn fillParentWidth maxWidth={800}>
                        <VerticalDivider XL />
                        <TextBox
                            text="Upptäck och boka kronocamping på plats"
                            size={1.5 as RemSize}
                            padding={{ leftRight: 20 }}
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text="Kronocamping är en plats att spendera tid på. Här finns allt för alla!"
                            padding={{ leftRight: 20 }}
                            size={1.125 as RemSize}
                        />
                        <VerticalDivider L />
                        <KronocampingWellnesSections />
                        <VerticalDivider L />

                        {/* >> Nästa sommar */}
                        <KronoNextSeasonSection />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                opacity: 0.8,
                                filter: "grayscale(30%)",
                                background: theme.colors.primary.light.asString,
                                borderRadius: 15,
                            }}>
                            <VerticalDivider />
                            <KronoCampingSummerBBQSection />
                            <VerticalDivider />
                            <KronocampingSjokantenPromoSection />
                            <VerticalDivider XL />
                            <KronocampingGetOutAndMoveSection />
                            <VerticalDivider XL />
                            <KronocampingSupSection />
                            <VerticalDivider XL />
                            <KronocampingExerciseMindSection />
                            <VerticalDivider />
                            <KronocampingKidsClubPromoSection />
                            <VerticalDivider S />
                            <KronocampingKidsFourSection />
                            <VerticalDivider S />
                            <KronocampingLateSummerSection />
                            <VerticalDivider S />
                            <KronocampingKidsAdventureGolfPromoSection />

                            <VerticalDivider XL />
                            <KronocampingMoreForYouthSection />
                            <VerticalDivider />
                            <KronocampingFoodSection />
                            <VerticalDivider XL />
                            <FlexColumn crossAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "Upplev mer lokalt",
                                        en: "Experience more local",
                                    })}
                                    size={2}
                                    weight="700"
                                    align="center"
                                />
                                <VerticalDivider M />
                                <TextBox
                                    text={translation({
                                        sv: "Utforska äventyr, mat och upplevelser och ta dig runt med smart transport. Från guidade turer till fartfyllda motoräventry och naturupplevelser.",
                                        en: "Explore adventure, food and experiences and take yourself around with smart transport. From guided tours to full-featured motorized tours and nature experiences.",
                                    })}
                                    color={theme.colors.black.light50}
                                    size={1.125}
                                    weight="400"
                                    align="center"
                                    css={{
                                        lineHeight: "1.6rem",
                                    }}
                                />
                            </FlexColumn>
                            <VerticalDivider XXL />
                            <KronocampingDiscoverMoreSection />
                            {/* <VerticalDivider />
                        <KronocampingHarbourSection /> */}
                            <VerticalDivider XXL />
                            <VerticalDivider XXL />
                        </FlexColumn>
                    </FlexColumn>
                    {/* << Nästa sommar */}
                </AppPage>
            </KronocampingColorContext.Provider>
        </WideScreenWrapper>
    );
};

export { KronocampingStoreFront };
