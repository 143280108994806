import { usePageNavigationState } from "@@/pages/page-navigation/page-navigation-context";
import { Circle } from "@@/shared/circle";
import { getCurrentStorefrontPath } from "@@/store-fronts/shared/current-store-front";
import { useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { ColorItem, RemSize, SizeName } from "@towni/common";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icons/icon";

type Props = {
    readonly size?: SizeName | number;
    readonly color?: ColorItem;
} & (
    | {
          readonly onClick: () => void;
          readonly path?: never;
      }
    | {
          readonly onClick?: never;
          readonly path?: string;
      }
);

const BackButtonNoCircle = (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { path } = props;
    const size =
        typeof props.size === "undefined"
            ? 30
            : typeof props.size === "number"
              ? props.size
              : theme.sizes.inPixels[props.size];

    const onClick =
        typeof path === "string"
            ? () => navigate(path + "#goback")
            : typeof props.onClick !== "undefined"
              ? props.onClick
              : () => navigate(getCurrentStorefrontPath() + "#goback");

    return (
        <Circle
            onClick={onClick}
            size={size}
            backgroundColor={theme.colors.transparent}
            contentSize="L">
            <Icon
                icon={faChevronLeft}
                color={props.color}
                size={(size / theme.sizes.base) as RemSize}
            />
        </Circle>
    );
};

const HistoryBackButtonNoCircle = (
    props: Omit<Props, "onClick" | "path"> & { fallbackButton?: JSX.Element },
) => {
    const navigate = useNavigate();
    const { previous: previousPage } = usePageNavigationState();

    const fallbackButton = props.fallbackButton;

    if (!previousPage && fallbackButton) {
        return fallbackButton;
    }

    if (!previousPage && !fallbackButton) {
        return null;
    }

    return (
        <BackButtonNoCircle size={props.size} onClick={() => navigate(-1)} />
    );
};

export { BackButtonNoCircle, HistoryBackButtonNoCircle };
