import * as React from "react";
import { Ref } from "react";

type Props = Partial<React.HTMLAttributes<HTMLDivElement>> & {
    className?: string;
};

const LayoutCell = React.forwardRef(
    (props: Props, ref: Ref<HTMLDivElement>) => {
        return (
            <div ref={ref} {...props}>
                {props.children}
            </div>
        );
    }
);

export { LayoutCell };
export type { Props as LayoutCellProps };
