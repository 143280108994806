import { emptyArrayOf, MILLISECONDS } from "@towni/common";
import { DependencyList, useEffect } from "react";
import { useMountEffect } from "./use-mount-effect";
import { useTimeout } from "./use-timeout";

const useDebounce = <T>(
    callback: () => T,
    delay: MILLISECONDS = 0 as MILLISECONDS,
    dependencies: DependencyList = emptyArrayOf(),
) => {
    const { reset, clear } = useTimeout(callback, delay);
    useEffect(reset, [...dependencies, reset]);
    useMountEffect(clear);
};
export { useDebounce };
