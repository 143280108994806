import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    translation,
    type ColorItem,
    type OrderItemBookable_V2,
    type ResourceReservation,
    type ResourceReservationRequest,
} from "@towni/common";
import { BookedTimeRow } from "./booked-time-row";
import { ResourceReservationRequestList } from "./resource-reservation-request-list";

type Props = {
    orderItem: OrderItemBookable_V2;
    backgroundColor?: ColorItem;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
};

const BookableOrderItemBody = (props: Props) => {
    const theme = useTheme();
    const { orderItem, backgroundColor, resourceReservations } = props;

    return (
        <FlexColumn
            background={{
                color:
                    backgroundColor ??
                    theme.colors.primary.main.value.withAlpha(0.1),
            }}>
            <VerticalDivider M />
            <FlexRow
                padding={{ leftRight: 20 }}
                mainAxis="space-between"
                crossAxis="center">
                <TextBox
                    size="L"
                    weight="800"
                    text={translation({
                        sv: "Bokningsdetaljer",
                        en: "Booking details",
                    })}
                />
            </FlexRow>
            <VerticalDivider S />
            <BookedTimeRow
                time={orderItem.time}
                isTableBooking={orderItem.bookingType === "TABLE_BOOKING"}
            />
            <ResourceReservationRequestList
                resourceReservations={resourceReservations}
                providerId={orderItem.providerId}
            />
            <VerticalDivider S />
        </FlexColumn>
    );
};

export { BookableOrderItemBody };
