import { OrderHomeDeliveryReceipt } from "@@/orders/order-groups/order-home-delivery-receipt";
import { OrderPickUpReceipt } from "@@/orders/order-groups/order-pick-up-receipt";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { browserLogger } from "@@/settings";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { Order } from "@towni/common";

type Props = {
    order: Order;
};

const BuyableReceipt_OrderDetails = (props: Props) => {
    const { order } = props;
    const [provider, { isPending: isLoadingProvider, error }] = useProvider(
        order.providerId,
    );

    // TODO! No error handling here, when provider fails to load
    if (error) browserLogger.error(error);
    if (!provider || isLoadingProvider) {
        return <TextShimmer rows={5} />;
    }

    switch (order.delivery.deliveryMethodType) {
        case "PICK_UP":
            return (
                <OrderPickUpReceipt
                    key={order._id}
                    order={order}
                    provider={provider}
                />
            );
        case "HOME_DELIVERY":
            return (
                <OrderHomeDeliveryReceipt
                    key={order._id}
                    order={order}
                    provider={provider}
                />
            );
        case "DIGITAL":
            return (
                <OrderPickUpReceipt
                    key={order._id}
                    order={order}
                    provider={provider}
                />
            );

        default:
            throw new Error(
                `Unhandled delivery method type in order receipts (${order.delivery})`,
            );
    }
};

export { BuyableReceipt_OrderDetails };
