import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import { emptyArrayOf, GetResponse, Product } from "@towni/common";

const fetchProducts =
    (productIds: string[]): QueryFunction<Product[]> =>
    async ({ signal }) => {
        if (!productIds.length) return emptyArrayOf<Product>();
        const data = await apiFetchClient.get<GetResponse<Product>>({
            route: `/products/?ids=${encodeURIComponent(productIds.join(","))}`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };
const fetchAllProducts =
    (): QueryFunction<Product[]> =>
    async ({ signal }) => {
        const data = await apiFetchClient.get<GetResponse<Product>>({
            route: "/products",
            customConfig: {
                signal,
            },
        });
        return data.items;
    };

export { fetchProducts, fetchAllProducts };
