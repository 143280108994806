import { useFetchAll } from "../../shared/generic-fetchers";
import { StoredPaymentMethod as StoredPaymentMethod } from "./stored-payment-method";

const mainQueryKey = "payment-methods";
const mainRoute = "/payments/stripe/payment-methods";

const useStoredStripePaymentMethods = () =>
    useFetchAll<StoredPaymentMethod>(mainQueryKey, mainRoute, {
        staleTime: 0,
    });
// TODO! FIX API RESPONSE /payments/stripe/payment-methods`

export { useStoredStripePaymentMethods };
