import { StorageItemId } from "@towni/common";

class UploadError extends Error {
    constructor(
        public message: string,
        public storageItemId: StorageItemId,
        public innerError?: unknown,
        public errorCode = "unknown"
    ) {
        super(message);
    }
}

export { UploadError };
