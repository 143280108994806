import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { VerticalDivider } from "@@/shared/dividers";
import { translation } from "@towni/common";
import { ModalHeader } from "../modals/modal-header";
import { Cart } from "./cart";
import { DeliveryProvider } from "./deliverypicker/delivery-context";
import { useCartContext } from "./multi-carts/cart.context";
import { useVerifiedCartState } from "./use-verified-cart";

// COMPONENT
type Props = {
    modalId: ModalId;
};

const CartModal = (props: Props) => {
    const { cart, setCart, clearCart } = useCartContext(context => ({
        cart: context.cart,
        setCart: context.setCart,
        clearCart: context.clearCart,
    }));
    useVerifiedCartState(cart, validatedCart => {
        if (validatedCart) setCart(validatedCart);
        else clearCart();
    });
    const { hide: hideCart } = useModalActions(props.modalId);

    return (
        <DeliveryProvider>
            <Modal
                height={"full"}
                modalId={props.modalId}
                header={
                    <ModalHeader
                        modalId={props.modalId}
                        bottomBorder={true}
                        title={translation({
                            sv: "Kassa",
                            en: "Checkout",
                        })}
                    />
                }>
                <VerticalDivider M />
                <Cart hide={hideCart} />
            </Modal>
        </DeliveryProvider>
    );
};

export { CartModal };
