import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import {
    isStorageItemImageReference,
    StorageItemImage,
    StorageItemImageId,
    StorageItemImageReference,
} from "@towni/common";
import { storageItemMainQueryKey } from "../main-query-key";
import { fetchStorageItem } from "./storage-item.fetcher";

const useStorageItemImageReference = <
    StorageItemType extends StorageItemImage = StorageItemImage
>(
    storageItemImageId:
        | StorageItemImageId
        | StorageItemImageReference
        | undefined,
    contentTypeVerifier?: (contentType: string) => boolean
) => {
    const itemImageId = isStorageItemImageReference(storageItemImageId)
        ? storageItemImageId.storageItemId
        : storageItemImageId; // quickfix
    const result = useSingleApiResource<StorageItemType>(
        [storageItemMainQueryKey, itemImageId],
        fetchStorageItem(itemImageId, contentTypeVerifier),
        {
            enabled: !!itemImageId,
        }
    );
    return result;
};

export { useStorageItemImageReference };
