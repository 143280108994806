import { browserLogger } from "@@/settings";
import { useTextDispatch, useTextState } from "@@/shared/text-context";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import { Translatable, setWithinRange, translation } from "@towni/common";
import * as React from "react";
import { useState } from "react";

type Props = {
    initialHeight?: number;
    placeholder?: Translatable;
    hide: () => void;
};

const ESCAPE_KEY = 27;
// eslint-disable-next-line react/display-name
const AutoHeightTextArea = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const { text: message } = useTextState();
    const { setText: setMessage } = useTextDispatch();
    // const [weird, setWeird] = useState(false);
    const [textAreaElement, setTextAreaElement] =
        useState<HTMLTextAreaElement | null>(null);
    const { initialHeight, hide } = props;

    React.useLayoutEffect(() => {
        if (!textAreaElement) return;
        // Measure the height of the content
        textAreaElement.style.height = `0px`;
        const elementHeight = setWithinRange(textAreaElement.scrollHeight, {
            min: initialHeight ?? 87, // todo: move hardcoded 87
        });
        // Set the height of the element to the measured content height
        textAreaElement.style.height = `${elementHeight}px`;
    }, [textAreaElement?.scrollHeight]);

    return (
        <textarea
            ref={setTextAreaElement}
            maxLength={280}
            value={message}
            placeholder={translate(
                props.placeholder ??
                    translation({
                        sv: "Skriv ditt önskemål här...",
                        en: "Write your requirement here...",
                    }),
            )}
            // autoFocus
            onFocus={ev => {
                // Select all text in text area
                ev.target.select();
                browserLogger.log("FOCUS FOCUS");
            }}
            onBlur={_ev => {
                browserLogger.log("FOCUS BLUR", _ev);
            }}
            onClick={(ev: React.MouseEvent<HTMLTextAreaElement>) => {
                ev.stopPropagation();
            }}
            onChange={event => {
                setMessage(event.currentTarget.value);
            }}
            onKeyDown={e => {
                if (e.keyCode === ESCAPE_KEY) {
                    hide();
                    e.preventDefault();
                }
            }}
            onKeyUp={e => {
                e.preventDefault();
            }}
            css={css`
                display: "block";
                position: relative;
                width: 100%;
                border: none;
                border-radius: 0;
                line-height: 24px;
                min-height: ${initialHeight || 100}px;
                max-height: 300px;
                resize: none;
                overflow: hidden;
                box-sizing: border-box;
                background-color: ${theme.colors.default.background.asString};
                padding: 20px 20px;
                font-family: ${theme.fontFamily};
                font-size: 1.1rem;
                pointer-events: auto;
                user-select: auto;
            `}
        />
    );
};
export { AutoHeightTextArea };
