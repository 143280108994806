import { CartPage } from "@@/carts/cart-page";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { MultiCartContextProvider } from "@@/carts/multi-carts/multi-cart.context";
import { PageLoad } from "@@/pages/page-load";
import { Wizard, wizardPage } from "@@/pages/wizard";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { useProductLoader } from "@@/routes/shared/loaders/product-loader";
import { FlexColumn } from "@@/shared/flex-containers";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { ColorThemeProvider } from "@@/styles/app-theme-provider";
import {
    Product,
    ProductId,
    Provider,
    productIdZodSchema,
    translation,
} from "@towni/common";
import { useParams } from "react-router-dom";
import { ProductDetailsContextProvider } from "../../../products/product-details-context";
import { VoucherDetailsWizardPage } from "./voucher-details-wizard-page";

type Props = {
    voucherId?: ProductId;
    provider: Provider;
};

const VoucherPage = (props: Props) => {
    const paramVoucherId = useParams<{ voucherId?: string }>().voucherId;
    const voucherId = productIdZodSchema.parse(
        props.voucherId ?? paramVoucherId
    );
    const [voucher, isLoadingVoucher] = useProductLoader(voucherId);

    const { branding } = useBrandingContext();
    const backgroundCss = useBrandingBackgroundCss(
        branding?.product.background
    );
    const peripheralBackgroundCss = useBrandingBackgroundCss(
        branding?.product.peripheralBackground
    );

    if (isLoadingVoucher)
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar värdebevis...",
                    en: "Loading voucher...",
                })}
            />
        );

    const isLoading = isLoadingVoucher;
    if (isLoading) return <PageLoad />;

    return (
        <ColorThemeProvider colorTheme={branding?.colorThemes.light}>
            <WideScreenWrapper
                css={peripheralBackgroundCss}
                maxContentWidth={500}>
                <FlexColumn
                    css={[
                        {
                            height: "100%",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            flexGrow: 1,
                        },
                        backgroundCss,
                    ]}>
                    <VoucherPageWizard
                        voucher={voucher}
                        provider={props.provider}
                    />
                </FlexColumn>
            </WideScreenWrapper>
        </ColorThemeProvider>
    );
};

function VoucherPageWizard(props: {
    readonly voucher: Product;
    provider: Provider;
}) {
    const product = props.voucher;
    const provider = props.provider;
    return (
        <ProductDetailsContextProvider
            product={props.voucher}
            provider={props.provider}>
            <MultiCartContextProvider
                contextId={`ctx_${product._id}`}
                preservationDisabled={true}>
                <CartContextProvider
                    key={provider._id}
                    providerId={provider._id}
                    doNotPreserveCart>
                    <CheckoutContextProvider>
                        <Wizard ignoreAnimation={false}>
                            {[
                                wizardPage(
                                    "product",
                                    <VoucherDetailsWizardPage />
                                ),
                                wizardPage("cart", <CartPage />),
                            ]}
                        </Wizard>
                    </CheckoutContextProvider>
                </CartContextProvider>
            </MultiCartContextProvider>
        </ProductDetailsContextProvider>
    );
}

export { VoucherPage };
