import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useTheme } from "@emotion/react";
import { useBarcode } from "next-barcode";

type Props = { code: string; dataTestId?: string };

const TicketCustomerViewBarCode = (props: Props) => {
    const theme = useTheme();
    const { inputRef } = useBarcode({
        value: props.code,
        options: { displayValue: false },
    });

    return (
        <FlexColumn
            fillParentWidth
            background={{ color: theme.colors.white }}
            crossAxis="center"
            data-testid={props.dataTestId}
            css={{}}>
            <VerticalDivider S />

            <FlexRow padding={{ leftRight: 40, topBottom: 15 }}>
                <svg ref={inputRef} />
            </FlexRow>

            <VerticalDivider M />
        </FlexColumn>
    );
};

export { TicketCustomerViewBarCode };
