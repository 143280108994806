import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import {
    darkeningGradient,
    darkeningGradientReverse,
} from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { AspectRatio, BlockContent, PageBlock } from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: [
        BlockContent,
        BlockContent,
        BlockContent,
        BlockContent,
        BlockContent,
        BlockContent,
    ];

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const _Card = (props: {
    readonly imageWidth: number;
    readonly content: BlockContent;
    readonly block: PageBlock;
    readonly defaultTextShadow: string;
    readonly aspectRatio?: AspectRatio;
    readonly flip?: boolean;
    readonly defaultBorderRadius: number;
}) => {
    const { imageWidth, block, defaultTextShadow, defaultBorderRadius } = props;
    const providerId = usePageViewContext(context => context.providerId);
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const translate = useTranslate();

    const actionPill = (
        <FlexRow fillParentWidth mainAxis="flex-end">
            <StoreFrontCardPill
                text={props.content.actionText}
                accentColor={
                    "accentColor" in props.content && props.content.accentColor
                        ? props.content.accentColor
                        : block.accentColor
                }
            />
        </FlexRow>
    );

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <GridCardContainer
                aspectRatio={props.aspectRatio ?? "1:1"}
                css={props.content.inactive ? inactiveCss : undefined}>
                <FlexColumn
                    fillParent
                    css={{
                        borderRadius: defaultBorderRadius,
                        position: "relative",
                        overflow: "hidden",
                        label: "content-grid-card-3-large",
                    }}
                    background={{
                        fit: "COVER",
                        imageReference: props.content.poster,
                        color:
                            props.content.backgroundColor ??
                            theme.colors.default.background.asString,
                        imageSize: {
                            imageWidth,
                        },
                    }}>
                    <FlexColumn
                        fillParent
                        crossAxis="flex-start"
                        mainAxis="space-between"
                        padding={{ all: isMobile ? 25 : 25 }}
                        background={{
                            linearGradient: props.flip
                                ? darkeningGradientReverse
                                : darkeningGradient,
                        }}>
                        {!props.flip ? actionPill : null}
                        <FlexColumn>
                            <TextBox
                                text={props.content.title}
                                weight="800"
                                size={isMobile ? 1.5 : 1.5}
                                color={
                                    "textColor" in props.content &&
                                    props.content.textColor
                                        ? props.content.textColor
                                        : theme.colors.white
                                }
                                css={{
                                    overflowWrap: "break-word",
                                    wordBreak: "break-word",
                                    textShadow: defaultTextShadow,
                                }}
                            />
                            <Conditional when={!!props.content.subtitle}>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.subtitle}
                                    lineClamp={2}
                                    weight="400"
                                    size={isMobile ? 0.9 : 0.9}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                            <Conditional when={!!props.content.kicker}>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.kicker}
                                    case="UPPERCASE"
                                    size={isMobile ? 0.725 : 0.725}
                                    weight="800"
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                        </FlexColumn>
                        {props.flip ? actionPill : null}
                    </FlexColumn>
                </FlexColumn>
            </GridCardContainer>
        </Link>
    );
};

const ContentGrid_6_Large = (props: Props) => {
    const { width } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });
    const {
        pageMaxWidth,
        defaultGridGap,
        defaultTextShadow,
        defaultBorderRadius,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();
    const maxWidth = pageMaxWidth * 0.8;
    return (
        <LayoutGrid
            gap={defaultGridGap}
            // padding={{ all: gridCardGap }}
            gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr 1fr"}
            className={props.className}
            css={{
                maxWidth: maxWidth,
                paddingLeft: 20,
                paddingRight: 20,
                width: width ?? "100%",
                label: "content_grid_6_large",
            }}>
            <ForEach itemOf={props.content} getKey={content => content._id}>
                {content => (
                    <_Card
                        aspectRatio={isMobile ? "4:3" : "1:1"}
                        imageWidth={isMobile ? 500 : 500}
                        content={content}
                        block={block}
                        defaultTextShadow={defaultTextShadow}
                        defaultBorderRadius={defaultBorderRadius}
                        flip={!isMobile}
                    />
                )}
            </ForEach>
        </LayoutGrid>
    );
};

export { ContentGrid_6_Large };
