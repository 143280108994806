import { createAppUrl } from "@@/shared/create-app-url";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useToast } from "@@/toasts/context/toast-context";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faArrowUpFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { RemSize, Ticket, translation } from "@towni/common";
import copy from "copy-to-clipboard";

type ShareProps = { ticket: Ticket };
const ShareTicket = ({ ticket }: ShareProps) => {
    const theme = useTheme();
    const translate = useTranslate();
    const toast = useToast();
    const urlToShare = createAppUrl(`/tickets/c/${ticket.code}`);

    const share = async () => {
        if (navigator.share) {
            await navigator.share({
                title: translate(ticket.meta.title),
                url: urlToShare,
            });
        } else {
            copy(urlToShare);
            toast.success({
                message: translation({
                    sv: "Länk kopierad!",
                    en: "Link has been copied!",
                }),
            });
        }
    };

    return (
        <FlexColumn
            css={{
                opacity: ticket.status === "USED" ? 0.6 : 1,
            }}
            onClick={share}>
            <VerticalDivider S />
            <Icon
                icon={faArrowUpFromSquare}
                size={1.5 as RemSize}
                padding={{ right: 5 }}
                color={theme.colors.white}
                style={{
                    right: 0,
                }}
            />
            <VerticalDivider />
            <FlexRow mainAxis="center">
                <TextBox
                    color={theme.colors.white}
                    text={
                        ticket.type === "GIFTCARD"
                            ? translation({
                                  sv: "Dela ovan presentkort",
                                  en: "Share the giftcard",
                              })
                            : translation({
                                  sv: "Dela ovan värdecheck",
                                  en: "Share the voucher",
                              })
                    }
                    align="center"
                    size={1}
                    css={{
                        willChange: "filter",
                        maxWidth: 250,
                        filter: `drop-shadow(
        0px 4px 10px
            ${theme.colors.black.value.withAlpha(0.25).asString}
    );`,
                    }}
                />
            </FlexRow>
            <VerticalDivider XXS />
            <FlexRow mainAxis="center">
                <TextBox
                    color={theme.colors.white.value.withAlpha(0.75)}
                    text={
                        ticket.type === "GIFTCARD"
                            ? translation({
                                  sv: "Klicka här för att kopiera länk att dela som gåva eller bara för att låta någon annan lösa in ditt presentkort",
                                  en: "Click here to copy link to share as a gift or just to let someone else redeem the giftcard",
                              })
                            : translation({
                                  sv: "Klicka här för att kopiera länk att dela som gåva eller bara för att låta någon annan lösa in ditt värdebevis",
                                  en: "Click here to copy link to share as a gift or just to let someone else redeem the voucher",
                              })
                    }
                    align="center"
                    weight={"400"}
                    size="S"
                    css={{
                        willChange: "filter",
                        maxWidth: 250,
                        filter: `drop-shadow(
        0px 4px 10px
            ${theme.colors.black.value.withAlpha(0.25).asString}
    );`,
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
};
export { ShareTicket };
