import { css, Global } from "@emotion/react";
import * as React from "react";

type Props = {
    readonly maxWidth?: number;
};
const GlobalMaxWidthCss = ({ maxWidth }: Props) => {
    return (
        <Global
            styles={css`
                #app-container,
                #root {
                    max-width: ${maxWidth
                        ? maxWidth.toString() + "px"
                        : "unset"};
                }
            `}
        />
    );
};

export { GlobalMaxWidthCss };
