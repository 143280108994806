import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ProductId, RemSize, unique } from "@towni/common";
import * as React from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../../shared/store-front-hz-product-card";

const HzSectionStandardrace = () => {
    const getProductId = useProductIdWithFallback();

    const products: [ProductId, string][] = React.useMemo(
        () => [
            // Standardrace 2-15 personer
            [
                getProductId("product__z9xYumWEFzl2taQhmBsb" as ProductId),
                "5-15 pers",
            ],
            // Standardrace 16-30 personer
            [
                getProductId("product___9NvEWzHrNSEnK6yjJDx" as ProductId),
                "16-30 pers",
            ],
            // Standardrace 31-45 personer
            [
                getProductId("product__kap9mD6cf9ukZO1qqCNw" as ProductId),
                "31-45 pers",
            ],
            // Standardrace 46-60 personer
            [
                getProductId("product__vOcXQDHpGVutw9IGM9eW" as ProductId),
                "46-60 pers",
            ],
        ],
        []
    );

    const hasDuplicates =
        unique(products.map(item => item[0])).length !== products.length;

    return (
        <SectionArea
            sectionAreaId="motorstadion-skovde-standardrace"
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {products.map(([id, actionText], index) => {
                    return (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : id}
                            cardSize={1.4 as RemSize}
                            aspectRatio="1:1"
                            actionText={actionText}
                            productId={id}
                            hideKicker
                        />
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { HzSectionStandardrace };
