import { Header_PageBlock_Provider_v2 } from "@@/backoffice/for-providers/pages/blocks/poster-blocks/header-page-block/header-page-block-view-context";
import { CallToActionButton } from "@@/backoffice/for-providers/pages/blocks/shared/call-to-action-button";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ImageV2 } from "@@/shared/pictures/image";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { use100vh } from "@@/shared/use-100-vh";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useIsTranslatableEmpty } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    HeaderPageBlock_V2,
    PageBlockTitleSize,
    RemSize,
    assertNever,
    remSize,
    replacePlaceholderInTranslatable,
} from "@towni/common";
import { usePageViewContext } from "../../../editor/page-view-context";

type Props = {
    block: HeaderPageBlock_V2;
};

const Title = TextBox;
const Subtitle = TextBox;
const Kicker = TextBox;

const subtitleTextSizeRatio = (size: PageBlockTitleSize): number => {
    switch (size) {
        case "XS":
            return 0.7;
        case "S":
            return 0.85;
        case "M":
            return 1;
        case "L":
            return 1.1;
        case "XL":
            return 1.25;
        default:
            assertNever(size);
    }
};

const textSizeRatio = (size: PageBlockTitleSize): number => {
    switch (size) {
        case "XS":
            return 0.75;
        case "S":
            return 0.8;
        case "M":
            return 1;
        case "L":
            return 1.2;
        case "XL":
            return 1.5;
        default:
            assertNever(size);
    }
};
const textSize = (size: PageBlockTitleSize, medium = 2): RemSize => {
    const ratio = textSizeRatio(size);
    return remSize(medium * ratio);
};

const HeaderPageBlockView_V2 = (props: Props) => {
    const { pageInnerWidth, pageMaxWidth, providerId } = usePageViewContext();
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const [, providerQuery] = useProvider(providerId);

    const block = props.block;
    const theme = useTheme();
    const vh100 = use100vh();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });

    const [imageReference] = useStorageItemImageReference(
        block.backgroundImage,
    );
    const [customLogo] = useStorageItemImageReference(block.customLogo);
    // console.log(
    //     "customLogo",
    //     customLogo,
    //     block.logoEnabled,
    //     block.customLogoEnabled,
    //     block.customLogo,
    //     block,
    // );
    const titleSize = textSize(block.titleSize, isMobile ? 1.5 : 2) * 1.5; // 1.5 since poster sizes are bigger
    const subtitleSize = subtitleTextSizeRatio(block.subtitleSize) * 1.5; // 1.5 since poster sizes are bigger
    const kickerSize = subtitleTextSizeRatio(block.kickerSize ?? "M"); // Kicker a bit smaller
    const background: BackgroundStyle = imageReference
        ? {
              imageReference,
              fit: "COVER",
              imageSize: {
                  imageWidth: isMobile
                      ? breakpointRange.MOBILE.max
                      : pageMaxWidth,
              },
          }
        : {
              color: block.backgroundColor ?? theme.colors.default.background,
          };
    const logoSize: RemSize = ((): RemSize => {
        if (isMobile) return 4 as RemSize;
        if (isSmall) return 5.5 as RemSize;
        return 7 as RemSize;
    })();

    return (
        <Header_PageBlock_Provider_v2 block={block}>
            <FlexColumn
                fillParentWidth
                mainAxis="flex-start"
                crossAxis="stretch"
                padding={{ all: isMobile ? 20 : 30 }}
                height={vh100 * (props.block.height ?? 0.45)}
                tag="poster_block"
                css={{
                    position: "relative",
                    flexShrink: 0,
                    overflow: "hidden",
                }}
                background={background}>
                <FlexRow
                    mainAxis="center"
                    crossAxis="center"
                    padding={{ all: 0 }}
                    css={{ flex: 1 }}>
                    <FlexColumn
                        crossAxis="center"
                        css={{ width: "100%", maxWidth: pageInnerWidth }}>
                        <Conditional when={!isTranslatableEmpty(block.kicker)}>
                            <Kicker
                                text={replacePlaceholderInTranslatable(
                                    block.kicker || "",
                                    "*",
                                    "•",
                                )}
                                color={block.kickerColor || undefined}
                                size={kickerSize}
                                align="center"
                                css={{
                                    opacity: 0.8,
                                    lineHeight: 1.3,
                                    textShadow: block.textShadow
                                        ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                                        : undefined,
                                }}
                            />
                            <VerticalDivider S />
                        </Conditional>

                        <Title
                            text={block.title}
                            color={
                                block.titleColor ??
                                theme.colors.default.text.asString
                            }
                            size={titleSize}
                            weight="700"
                            align="center"
                            css={{
                                textShadow: block.textShadow
                                    ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                                    : undefined,
                                lineHeight: 1,
                            }}
                        />
                        <Conditional
                            when={!isTranslatableEmpty(block.subtitle)}>
                            <VerticalDivider S />
                            <Subtitle
                                text={block.subtitle}
                                color={
                                    block.subtitleColor ??
                                    theme.colors.default.text.asString
                                }
                                size={subtitleSize}
                                align="center"
                                css={{
                                    opacity: 0.8,
                                    lineHeight: 1.3,
                                    textShadow: block.textShadow
                                        ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                                        : undefined,
                                    maxWidth: pageInnerWidth * 0.8,
                                }}
                            />
                        </Conditional>

                        <Conditional
                            when={
                                block.ctaButtonEnabled &&
                                !isTranslatableEmpty(block.ctaButtonText)
                            }
                            render={() => (
                                <>
                                    <VerticalDivider ML />
                                    <FlexColumn crossAxis="center">
                                        <CallToActionButton block={block} />
                                    </FlexColumn>
                                </>
                            )}
                        />
                    </FlexColumn>
                </FlexRow>
                <FlexRow
                    key={`logo_${block._id}`}
                    mainAxis="flex-start"
                    css={{
                        zIndex: 2,
                        position: "absolute",
                        left: isMobile ? 20 : 40,
                        top: isMobile ? 20 : 40,
                    }}>
                    {/* LOGO */}
                    <Conditional when={block.logoEnabled}>
                        <ImageV2
                            radius={3000}
                            ignoreBlurhash
                            imageSource={
                                block.customLogoEnabled && customLogo
                                    ? customLogo
                                    : providerQuery.data?.logoImageRef
                            }
                            title={providerQuery.data?.meta.title ?? "Logo"}
                            size={logoSize}
                        />
                    </Conditional>
                </FlexRow>
            </FlexColumn>
        </Header_PageBlock_Provider_v2>
    );
};

export { HeaderPageBlockView_V2 };
