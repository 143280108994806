import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { useFormComponents } from "@@/shared/form/form-components";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { IconName, Padding, Translatable } from "@towni/common";
import { Draft } from "immer";
import { ZodSchema } from "zod";

type Value = IconName | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
};

const Form2IconNameInput = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const FormComponents = useFormComponents<State>();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    return (
        <FormComponents.TextInput
            {...props}
            key={`${formId}_${props.fieldId}`}
            preElement={
                <DynamicIcon
                    icon={field.value || ("fa-question" as IconName)}
                    fixedWidth
                    size={1}
                    css={{ marginRight: 10 }}
                    fallback={faQuestion}
                />
            }
        />
    );
};

export { Form2IconNameInput };
