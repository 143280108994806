import { BookableLegacyOptional } from "@@/carts/bookable-checkout/bookable-legacy-optional";
import { BookedTimeRow } from "@@/carts/bookable-checkout/booked-time-row";
import { ResourceReservationRequestList } from "@@/carts/bookable-checkout/resource-reservation-request-list";
import { useModal } from "@@/modals/use-modal";
import { useProduct } from "@@/products/state/queries/product/use-product";
import { useProviderPublicPhoneNumber } from "@@/providers/use-provider-public-phone-number";
import { CustomerCancelTableBookingModal } from "@@/receipts/bookables/table-bookings/table-booking-cancel-modal";
import { useCancellationAvailability } from "@@/receipts/bookables/table-bookings/use-cancellation-availability";
import { Button_v2 } from "@@/shared/buttons_v2/button";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    BookableTableBooking,
    ColorItem,
    Order,
    OrderItemBookable_V2,
    Provider,
    ResourceReservation,
    getChildOptionals,
    translation,
} from "@towni/common";

type Props = {
    order: Order;
    provider: Provider;
    orderItem: OrderItemBookable_V2;
    backgroundColor?: ColorItem;
    resourceReservations: ResourceReservation[];
    actionButton?: JSX.Element;
};

const BookableReceipt_OrderItemBody = (props: Props) => {
    const theme = useTheme();
    const {
        order,
        provider,
        orderItem,
        backgroundColor,
        resourceReservations,
    } = props;

    // Cancellable?
    // - For now only table reservation order items are cancellable
    // - by customer, and only when provider has allowed them to be
    const [{ show }, modalId] = useModal(
        "customer-cancel-table-booking-modal__",
    );
    const [product, { isPending: _isLoadingProduct }] = useProduct(
        orderItem.productId,
    );
    const bookable = product?.skus[0].acquire;
    if (bookable && bookable?._type !== "BOOKABLE")
        throw new Error(
            "Invalid product; should be a bookable; " + orderItem.productId,
        );
    const providerPublicPhoneNumber = useProviderPublicPhoneNumber(
        provider,
        true,
    );

    const { isCancellableInAnyWay } = useCancellationAvailability({
        orderItem,
        bookable: bookable as BookableTableBooking,
        order,
    });

    return (
        <FlexColumn
            background={{
                color:
                    backgroundColor ??
                    theme.colors.primary.main.value.withAlpha(0.1),
            }}
            css={{ label: "bookable_receipt_order_item_body" }}>
            <VerticalDivider L />
            <FlexRow
                padding={{ leftRight: 20 }}
                mainAxis="space-between"
                crossAxis="center">
                <TextBox
                    size="L"
                    weight="800"
                    text={translation({
                        sv: "Bokningsdetaljer",
                        en: "Booking details",
                    })}
                />
                <HorizontalDivider />
                <Conditional
                    when={isCancellableInAnyWay}
                    render={() => (
                        <Button_v2
                            onClick={show}
                            disabled={!isCancellableInAnyWay}
                            padding={{ topBottom: 8, leftRight: 20 }}
                            css={{
                                borderRadius: 3000,
                                "@media print": {
                                    display: "none",
                                },
                            }}
                            textColor={theme.colors.black.light50}
                            borderColor={theme.colors.black.light50}
                            backgroundColor={theme.colors.transparent}>
                            <TextBox
                                text={translation({
                                    sv: "Avboka",
                                    en: "Cancel",
                                })}
                            />
                        </Button_v2>
                    )}
                />
            </FlexRow>
            <VerticalDivider S />
            <BookedTimeRow
                time={orderItem.time}
                isTableBooking={orderItem.bookingType === "TABLE_BOOKING"}
            />
            <ResourceReservationRequestList
                resourceReservations={resourceReservations}
                providerId={orderItem.providerId}
            />

            <BookableLegacyOptional
                orderItems={getChildOptionals(orderItem, order.orderItems)}
            />
            <VerticalDivider XS />
            <CustomerCancelTableBookingModal
                modalId={modalId}
                tableBooking={orderItem}
                orderGroupId={props.order.orderGroupId}
                order={props.order}
                providerTitle={props.provider.meta.title}
                bookable={bookable as BookableTableBooking}
                providerPhoneNumber={providerPublicPhoneNumber}
            />
        </FlexColumn>
    );
};

export { BookableReceipt_OrderItemBody };
