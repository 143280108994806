import { browserLogger } from "@@/settings";
import {
    cartFactory,
    emptyArray,
    emptyArrayOf,
    zeroPrice,
    type BookableOptionValue,
    type Cart,
    type Discount,
    type OrderItem_V2,
    type ProviderId,
    type ResourceReservationRequest,
} from "@towni/common";
import { Except } from "type-fest";

/**
 * Creates a new empty cart
 * @param {Cart} cart
 * @returns {Price}
 */
const createEmptyCart = (
    providerId: ProviderId,
    preserve: boolean,
    hashCart: (input: Except<Cart, "hash">) => string,
): Cart => {
    browserLogger.info("createEmptyCart");
    const empty = cartFactory(
        {
            orderItems: emptyArray as OrderItem_V2[],
            discounts: emptyArray as Discount[],
            price: zeroPrice,
            totalPrice: zeroPrice,
            providerId,
            tip: 0,
            tipPrice: zeroPrice,
            messageToProvider: undefined,
            delivery: {
                method: "PICK_UP",
                slot: undefined,
                price: zeroPrice,
            },
            reservationRequests: emptyArrayOf<ResourceReservationRequest>(),
            optionValues: emptyArrayOf<BookableOptionValue>(),
            preserve,
        },
        hashCart,
    );

    return empty;
};

export { createEmptyCart };
