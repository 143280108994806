import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { PriceTextBox } from "@@/shared/text";
import { TextBox } from "@@/shared/text/text-box";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    Optional,
    OptionalPercentage,
    OrderItemOptional_V2,
} from "@towni/common";

type Props = {
    picked: OrderItemOptional_V2;
};

const PickedOptionalRow = ({ picked }: Props) => {
    const theme = useTheme();
    const translate = useTranslate();

    return (
        <Conditional
            when={picked.optional._type === "OPTIONAL_PERCENTAGE"}
            render={() => {
                const optional = picked.optional as OptionalPercentage;
                // const pricePercentage = priceFactory({
                //     amountIncludingVat:
                //         price.amountIncludingVat * optional.percentage,
                //     vat: price.vat,
                // });
                return (
                    <FlexRow
                        shrink={0}
                        mainAxis="space-between"
                        key={optional._id}>
                        <TextBox
                            text={optional.meta.title}
                            size="S"
                            color={theme.colors.black.light60}
                        />
                        <HorizontalDivider />
                        <PriceTextBox
                            price={picked.price}
                            size="S"
                            color={theme.colors.black.light60}
                        />
                    </FlexRow>
                );
            }}
            else={() => {
                const optional = picked.optional as Optional;

                return (
                    <FlexRow
                        shrink={0}
                        mainAxis="space-between"
                        key={optional._id}>
                        <TextBox
                            text={`+ ${translate(optional.meta.title)}`}
                            color={theme.colors.black.light60}
                        />
                        <HorizontalDivider />
                        <PriceTextBox
                            price={picked.price}
                            color={theme.colors.black.light60}
                        />
                    </FlexRow>
                );
            }}
        />
    );
};

export { PickedOptionalRow };
