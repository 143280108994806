import { useCallback, useMemo, useRef, useState } from "react";

type RadioListOption<T extends string> = {
    _id: T;
    selected: boolean;
};

const useRadioList = <T extends string>(
    initialOptions: RadioListOption<T>[],
    onSelect?: (id: T) => void,
) => {
    const onSelectRef = useRef(onSelect);
    onSelectRef.current = onSelect;
    // Validate: There can only be one selected option
    const moreThanOneSelected = useMemo(
        () => initialOptions.filter(option => !!option.selected).length > 1,
        [initialOptions],
    );
    const allHaveId = useMemo(
        () => initialOptions.filter(option => !option._id).length <= 0,
        [initialOptions],
    );
    if (moreThanOneSelected) {
        throw new Error("There can be only one selected item in a radio list");
    }
    if (!allHaveId) {
        throw new Error("All items in radio list must have an id");
    }

    const initiallySelectedId = useMemo(() => {
        const item = initialOptions.find(opt => opt.selected === true);
        return (item && item._id) || undefined;
    }, [initialOptions]);

    const [selectedId, setSelectedId] = useState(initiallySelectedId);
    const [options, setOptions] = useState(initialOptions);

    const select = useCallback(
        (id: T) => {
            const newOptions = [
                ...options.map(option => {
                    if (option.selected && option._id !== id)
                        return { ...option, selected: false };

                    if (option._id === id) return { ...option, selected: true };
                    return option;
                }),
            ];

            setOptions(newOptions);
            setSelectedId(id);
            onSelectRef.current?.(id);
        },
        [options],
    );

    return useMemo(
        () => [selectedId, select, options] as const,
        [selectedId, select, options],
    );
};

export { useRadioList };
export type { RadioListOption };
