import { AspectRatio, Branded } from "@towni/common";

type HeightInPercent = Branded<string, "HeightInPercent">;

/**
 * defaults to 1:1
 * @param {(AspectRatio | undefined)} aspectRatio
 * @returns {HeightInPercent}
 */
const getAspectRatioHeight = (
    aspectRatio: AspectRatio | undefined
): HeightInPercent => {
    switch (aspectRatio) {
        case "9:16":
            return "178%" as HeightInPercent;
        case "16:9":
            return "56%" as HeightInPercent;
        case "3:4":
            return "133%" as HeightInPercent;
        case "4:3":
            return "75%" as HeightInPercent;
        case "3:2":
            return "67%" as HeightInPercent;
        case "2:3":
            return "150%" as HeightInPercent;
        case "1:1":
        default:
            return "100%" as HeightInPercent;
    }
};

export { getAspectRatioHeight };
export type { HeightInPercent };
