import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../shared/store-front-hz-product-card";
import { kronocampingColors } from "../kronocamping-color-context";

const KronocampingFoodSection: FC = () => {
    const theme = useTheme();
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            // BBQ
            getProductId("product__ff87aRgl8w2EvRKkuwfE" as ProductId),
            // Taco
            getProductId("product__v5xjxgTbC64mjWJzMAi_" as ProductId),
        ],
        [],
    );

    return (
        <SectionArea
            sectionAreaId="kronocamping-special-taste"
            title="Mat och dryck kvällar"
            subtitle="Tillsammans med proffsen upptäcker vi smaker och nyanser vi inte visste om"
            background={{
                color: theme.colors.default.background,
            }}
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[0]}
                    actionText={text.seeAndBook}
                    actionTextColorSet={kronocampingColors.primary}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[1]}
                    actionText={text.explore}
                    actionTextColorSet={kronocampingColors.secondary}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[2]}
                    actionText={text.explore}
                    actionTextColorSet={kronocampingColors.primary}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={1.35 as RemSize}
                    productId={productIds[3]}
                    actionText={text.explore}
                    actionTextColorSet={kronocampingColors.primary}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                />
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

const text = {
    explore: translation({
        sv: "Utforska",
        en: "Explore",
    }),
    seeAndBook: translation({
        sv: "Se & boka",
        en: "See & book",
    }),
};

export { KronocampingFoodSection };
