import { css, keyframes, useTheme } from "@emotion/react";
import {
    MILLISECONDS,
    colorAsString,
    isNotUndefined,
    repeatIndex,
} from "@towni/common";
import { VerticalDivider } from "../dividers/vertical-divider";
import { ForEach } from "../for-each";
import { useDelay } from "./use-wait-for-it";

const expandingKeyframes = keyframes({
    "100%": {
        width: "100%",
    },
});
// `
//     100% {
//         width: 100%;
//     }
// `;
const shimmerWrapperCss = css`
    width: 0;
    animation: ${expandingKeyframes} 0.5s forwards linear;
`;

const shimmerKeyframes = keyframes({
    "0%": {
        backgroundPosition: "-1000px 0",
    },
    "100%": {
        backgroundPosition: "1000px 0",
    },
});

// `
//     0% {
//         background-position: -1000px 0;
//     }
//     100% {
//         background-position: 1000px 0;
//     }
// `;
const darkShade = "#e2e2e2";
const backgroundColor = "#eff1f3";
const shimmerAnimationCss = css`
    animation: ${shimmerKeyframes} 2s infinite;
    background: linear-gradient(
        to right,
        ${backgroundColor} 4%,
        ${darkShade} 25%,
        ${backgroundColor} 36%
    );
    background-size: 1000px 100%;
`;

const ImageShimmer = (props: {
    width?: string;
    height?: string;
    maxHeight?: string;
    radius?: number;
    /**
     * Default delay 100ms
     *
     * @type {number}
     */
    delayInMs?: MILLISECONDS;
}) => {
    const theme = useTheme();
    const [waitForIt] = useDelay(props.delayInMs ?? (100 as MILLISECONDS));

    if (waitForIt) return null;

    const width = props.width || "100%";
    const height = props.height || "100%";
    const myCss = css`
        position: relative;
        width: ${width};
        height: ${height};
        background-color: ${colorAsString(theme.colors.black.light70)};
        ${isNotUndefined(props.maxHeight)
            ? `max-height: ${props.maxHeight};`
            : ""}
        ${isNotUndefined(props.radius)
            ? `border-radius: ${props.radius}px;`
            : ""}
        ${shimmerAnimationCss}
    `;

    return (
        <div css={shimmerWrapperCss}>
            <div css={myCss} />
        </div>
    );
};

type TextShimmerProps = {
    width?: string;
    rowHeight?: string;
    rows: number;
    /**
     * delay shimmer to give other object a bit
     * of time to render first
     * defaults to 100ms
     * @type {number}
     */
    delayInMs?: MILLISECONDS;
    className?: string;
    disableFirstRowIndent?: boolean;
};

const TextShimmer = (props: TextShimmerProps) => {
    const theme = useTheme();
    const [waitForIt] = useDelay(props.delayInMs ?? (100 as MILLISECONDS));

    if (waitForIt) return null;

    return (
        <div className={props.className} css={shimmerWrapperCss}>
            <div
                css={{
                    display: "flex",
                    flexDirection: "column",
                    width: props.width ?? "100%",
                }}>
                <ForEach
                    itemOf={repeatIndex(props.rows)}
                    divider={<VerticalDivider S />}>
                    {index => (
                        <div
                            key={index}
                            css={css`
                                position: relative;
                                width: ${index === 0 ||
                                props.disableFirstRowIndent
                                    ? "100%"
                                    : "80%"};
                                height: ${props.rowHeight ||
                                `${theme.sizes.base}px`};
                                background-color: ${colorAsString(
                                    theme.colors.black.light70,
                                )};
                                ${shimmerAnimationCss}
                            `}
                        />
                    )}
                </ForEach>
            </div>
        </div>
    );
};

export { ImageShimmer, TextShimmer };
export type { TextShimmerProps };
