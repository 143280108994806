import { useEnvironmentRuntimeSettings } from "@@/settings/app-settings/environment-settings-context";
import { getImageUrlFactory } from "@towni/common";
import { useCallback } from "react";

const useImageUrl = () => {
    const { MEDIA_ENDPOINT } = useEnvironmentRuntimeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const result = useCallback(getImageUrlFactory(MEDIA_ENDPOINT), [
        MEDIA_ENDPOINT,
    ]);
    return result;
};

export { useImageUrl };
