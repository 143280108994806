import { accessKeyIdZodSchema, parseSafely } from "@towni/common";
import { useSearchParams } from "react-router-dom";

const useAccessKeyIdFromUrl = (queryParamName = "ak") => {
    const [searchParams] = useSearchParams();
    const accessKeyId = parseSafely({
        schema: accessKeyIdZodSchema,
        value: searchParams.get(queryParamName),
    });

    return accessKeyId;
};

export { useAccessKeyIdFromUrl };
