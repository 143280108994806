import { css } from "@emotion/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorAsString, ColorItem, Translatable } from "@towni/common";
import * as React from "react";
import { HorizontalDivider } from "./dividers";
import { FlexRow } from "./flex-containers";
import { TextBox } from "./text";

type Props = {
    color: ColorItem;
    icon?: IconDefinition;
    text: Translatable;
};

const Badge = (props: Props) => {
    return (
        <FlexRow
            padding={{ all: 3 }}
            styling={css`
                width: auto;
                flex-grow: 0;
                flex-shrink: 0;
                font-size: 0.75rem;
                border-radius: 4px;
                border: 1px solid ${colorAsString(props.color)};
            `}>
            {props.icon ? (
                <>
                    <FontAwesomeIcon
                        icon={faBadgeCheck}
                        color={colorAsString(props.color)}
                    />
                    <HorizontalDivider XXS />
                </>
            ) : null}
            <TextBox
                text={props.text}
                size="XXS"
                color={props.color}
                weight="700"
                case="UPPERCASE"
            />
        </FlexRow>
    );
};

export { Badge };
