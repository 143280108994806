import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { OrderItem } from "@towni/common";
import * as React from "react";

type Props = {
    cartItem: OrderItem;
};

const CartItemMessageToProvider = (props: Props) => {
    const theme = useTheme();
    const { cartItem } = props;

    return (
        <FlexRow fillParentWidth tag="comment">
            <FlexRow
                mainAxis="flex-start"
                crossAxis="flex-start"
                css={{
                    borderRadius: 5,
                    backgroundColor: theme.colors.black.light95.asString,
                    padding: 10,
                    paddingRight: 15,
                }}>
                <Icon icon={faComment} color={theme.colors.black.light80} />
                <HorizontalDivider XS />
                <TextBox
                    text={cartItem.messageToProvider}
                    color={theme.colors.default.text.light40}
                    size={0.75}
                    css={{ flex: 1 }}
                />
            </FlexRow>
        </FlexRow>
    );
};

export { CartItemMessageToProvider };
