import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import {
    darkeningGradient,
    darkeningGradientReverse,
} from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    AspectRatio,
    BlockContent,
    PageBlock,
    addAlphaToHexColor,
    isHexColor,
} from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: BlockContent[];

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const _Card = (props: {
    readonly content: BlockContent;
    readonly block: PageBlock;
    readonly defaultTextShadow: string;
    readonly aspectRatio?: AspectRatio;
    readonly flip?: boolean;
    readonly imageWidth: number;
    readonly defaultBorderRadius: number;
}) => {
    const { imageWidth, block, defaultTextShadow, defaultBorderRadius } = props;
    const providerId = usePageViewContext(context => context.providerId);
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });

    const actionPill = (
        <FlexRow fillParentWidth mainAxis="flex-end">
            <StoreFrontCardPill
                text={props.content.actionText}
                accentColor={
                    "accentColor" in props.content && props.content.accentColor
                        ? props.content.accentColor
                        : block.accentColor
                }
            />
        </FlexRow>
    );
    const translate = useTranslate();

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <GridCardContainer
                aspectRatio={props.aspectRatio ?? "1:1"}
                css={props.content.inactive ? inactiveCss : undefined}>
                <FlexColumn
                    fillParent
                    css={{
                        borderRadius: defaultBorderRadius,
                        position: "relative",
                        overflow: "hidden",
                        label: "content-grid-card-3-large",
                    }}
                    background={{
                        fit: "COVER",
                        imageReference: props.content.poster,
                        color:
                            props.content.backgroundColor ??
                            theme.colors.default.background.asString,
                        imageSize: {
                            imageWidth,
                        },
                    }}>
                    <FlexColumn
                        fillParent
                        crossAxis="flex-start"
                        mainAxis="space-between"
                        padding={{ all: isMobile ? 25 : 25 }}
                        background={{
                            linearGradient: props.flip
                                ? darkeningGradientReverse
                                : darkeningGradient,
                        }}>
                        {!props.flip ? actionPill : null}
                        <FlexColumn>
                            <TextBox
                                text={props.content.title}
                                weight="800"
                                size={isMobile ? 1.5 : 1.5}
                                color={
                                    "textColor" in props.content &&
                                    props.content.textColor
                                        ? props.content.textColor
                                        : theme.colors.white
                                }
                                css={{
                                    overflowWrap: "break-word",
                                    wordBreak: "break-word",
                                    textShadow: defaultTextShadow,
                                }}
                            />
                            <Conditional when={!!props.content.subtitle}>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.subtitle}
                                    lineClamp={2}
                                    weight="400"
                                    size={isMobile ? 0.9 : 0.9}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                            <Conditional when={!!props.content.kicker}>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.kicker}
                                    case="UPPERCASE"
                                    size={isMobile ? 0.725 : 0.725}
                                    weight="800"
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                        </FlexColumn>
                        {props.flip ? actionPill : null}
                    </FlexColumn>
                </FlexColumn>
            </GridCardContainer>
        </Link>
    );
};

const _CardWithTextBelow = (props: {
    readonly content: BlockContent;
    readonly block: PageBlock;
    readonly defaultTextShadow: string;
    readonly aspectRatio?: AspectRatio;
    readonly className?: string;
    readonly hideAction?: boolean;
    readonly imageWidth: number;
    readonly defaultBorderRadius: number;
}) => {
    const providerId = usePageViewContext(context => context.providerId);
    const { block, defaultBorderRadius } = props;
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const translate = useTranslate();
    const textColor = block.textColor ?? theme.colors.default.text;
    const textColorWithAlpha = isHexColor(textColor)
        ? addAlphaToHexColor(textColor, 0.5)
        : textColor.withAlpha(0.5);

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <FlexColumn
                className={props.className}
                css={props.content.inactive ? inactiveCss : undefined}>
                <GridCardContainer aspectRatio={props.aspectRatio ?? "1:1"}>
                    <FlexColumn
                        fillParent
                        css={{
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-3-medium",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: props.imageWidth,
                            },
                        }}>
                        <FlexColumn
                            fillParent
                            crossAxis="flex-start"
                            mainAxis="space-between"
                            padding={{ all: isMobile ? 15 : 25 }}>
                            {!props.hideAction ? (
                                <FlexRow fillParentWidth mainAxis="flex-end">
                                    <StoreFrontCardPill
                                        text={props.content.actionText}
                                        accentColor={
                                            "accentColor" in props.content &&
                                            props.content.accentColor
                                                ? props.content.accentColor
                                                : block.accentColor
                                        }
                                    />
                                </FlexRow>
                            ) : null}
                        </FlexColumn>
                    </FlexColumn>
                </GridCardContainer>
                <FlexColumn padding={{ topBottom: 6, leftRight: 2 }}>
                    <TextBox
                        text={props.content.title}
                        weight="800"
                        size={isMobile ? 1 : 1}
                        color={textColor}
                        css={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                    />
                    <Conditional when={!!props.content.subtitle}>
                        <VerticalDivider XXS />
                        <TextBox
                            text={props.content.subtitle}
                            lineClamp={2}
                            weight="400"
                            size={isMobile ? 1 : 1}
                            color={textColorWithAlpha}
                            css={{
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                            }}
                        />
                    </Conditional>
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

const ContentGrid_7x_Large = (props: Props) => {
    const { width } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });
    const {
        pageMaxWidth,
        defaultGridGap,
        defaultTextShadow,
        defaultBorderRadius,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();
    const maxWidth = pageMaxWidth * 0.85;

    return (
        <LayoutGrid
            gap={defaultGridGap}
            gridTemplateColumns={
                isMobile ? "1fr 1fr" : "repeat(auto-fit, minmax(250px, 1fr));"
            }
            className={props.className}
            css={{
                maxWidth: maxWidth,
                paddingLeft: 20,
                paddingRight: 20,
                width: width ?? "100%",
                label: "content_grid_7x_large",
            }}>
            <ForEach itemOf={props.content} getKey={content => content._id}>
                {content => (
                    <>
                        {isMobile ? (
                            <_CardWithTextBelow
                                aspectRatio={"2:3"}
                                imageWidth={250}
                                content={content}
                                block={block}
                                hideAction={true}
                                defaultTextShadow={defaultTextShadow}
                                defaultBorderRadius={defaultBorderRadius}
                            />
                        ) : (
                            <_Card
                                aspectRatio={"1:1"}
                                imageWidth={500}
                                content={content}
                                block={block}
                                defaultTextShadow={defaultTextShadow}
                                defaultBorderRadius={defaultBorderRadius}
                                flip={!isMobile}
                            />
                        )}
                    </>
                )}
            </ForEach>
        </LayoutGrid>
    );
};

export { ContentGrid_7x_Large };
