import { css, Global } from "@emotion/react";
import { colorAsString, ColorItem } from "@towni/common";

type Props = {
    readonly color?: ColorItem;
};
const GlobalPeripheralBackgroundColor = ({ color }: Props) => {
    return (
        <Global
            styles={css({
                body: {
                    backgroundColor: colorAsString(color) || undefined,
                },
            })}
        />
    );
};

export { GlobalPeripheralBackgroundColor };
