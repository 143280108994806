import { motion } from "framer-motion";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { pageTransition } from "./page-animation-slide-and-fade";

const WizardAnimationWrapper = (props: {
    children: React.ReactNode;
    ignoreAnimation?: boolean;
    wizardId: string;
    direction: "FORWARD" | "BACKWARD";
    // style?: MotionStyle;
}) => {
    const loc = useLocation();
    return (
        <motion.div
            key={loc.pathname + props.wizardId}
            css={{ label: "wiz_anim_wrapper" }}
            style={
                {
                    display: "flex",
                    flex: 1,
                    justifyContent: "stretch",
                    alignItems: "stretch",
                    flexDirection: "column",
                    height: "100%",
                    // position: "relative",
                }
                // props.style || {
                //     height: "100%",
                // }
            }
            initial={{ opacity: props.ignoreAnimation ? 1 : 0 }}
            animate={{
                opacity: 1,
            }}
            exit={{ opacity: props.ignoreAnimation ? 1 : 0 }}
            transition={
                props.ignoreAnimation
                    ? {
                          duration: 0,
                      }
                    : { ...pageTransition }
            }>
            {props.children}
        </motion.div>
    );
};

export { WizardAnimationWrapper };
