import * as React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "../use-previous";

const PreviousPageContext = React.createContext<
    | {
          previousPage: string;
          currentPage: string;
      }
    | undefined
>(undefined);

// Webp detection copied/based on https://stackoverflow.com/a/27232658
const PreviousPageProvider = ({
    children,
}: {
    readonly children?: React.ReactNode;
}) => {
    const location = useLocation();
    const previousPage =
        usePrevious(location.pathname, { startFromUndefined: true }) ?? "";
    // browserLogger.log("PREVPAGE", {
    //     previousPage,
    //     pathName: location.pathname,
    // });

    const state = useMemo(() => {
        return {
            previousPage,
            currentPage: location.pathname,
        };
    }, [location.pathname, previousPage]);

    return (
        <PreviousPageContext.Provider value={state}>
            {children}
        </PreviousPageContext.Provider>
    );
};

const usePreviousPageContext = () => {
    const context = React.useContext(PreviousPageContext);
    if (context === undefined) {
        throw new Error(
            "usePreviousPageContext must be used within a PreviousPageContext"
        );
    }
    return context;
};

export { PreviousPageProvider, usePreviousPageContext };
