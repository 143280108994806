import { getAspectRatioHeight } from "@@/shared/section-area/aspect-ratio";
import { aspectRatio, AspectRatio } from "@towni/common";

type Props = {
    aspectRatio: AspectRatio | undefined;
    className?: string;
    children?: React.ReactNode;
};
const GridCardContainer = (props: Props) => {
    if (!aspectRatio) throw new Error("Aspect ratio must be set");
    const heightInPercent = getAspectRatioHeight(props.aspectRatio);

    return (
        <div
            className={props.className}
            css={{
                position: "relative",
                width: "100%",
                flexShrink: 0,
                ":before": {
                    content: `""`,
                    display: "block",
                    paddingTop: heightInPercent,
                },
            }}>
            <div
                css={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}>
                {props.children}
            </div>
        </div>
    );
};

export { GridCardContainer };
