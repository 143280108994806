import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { BlockContent } from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: BlockContent;

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly height?: React.CSSProperties["height"];
    readonly className?: string;
};

const ContentGrid_1_Medium = (props: Props) => {
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { width, height } = props;
    const {
        pageInnerWidth: maxWidth,
        defaultTextShadow,
        defaultBorderRadius,
        providerId,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();
    const translate = useTranslate();

    return (
        <LayoutGrid
            gridTemplateColumns={"1fr"}
            className={props.className}
            css={{
                maxWidth: maxWidth,
                width: width ?? "100%",
                height: height ?? "100%",
                paddingLeft: 20,
                paddingRight: 20,
                label: "content_grid_1_medium",
            }}>
            <Link
                to={getBlockContentTargetUrl(props.content, providerId)}
                disabled={props.content.inactive}
                title={translate(props.content.title)}
                css={{ cursor: "pointer" }}>
                <GridCardContainer
                    aspectRatio={isMobile ? "2:3" : "16:9"}
                    css={props.content.inactive ? inactiveCss : undefined}>
                    <FlexColumn
                        fillParent
                        css={{
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-1-medium",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: isMobile ? 500 : 800,
                            },
                        }}>
                        <FlexColumn
                            fillParent
                            crossAxis="flex-start"
                            mainAxis="space-between"
                            padding={{ all: 20 }}
                            background={{ linearGradient: darkeningGradient }}>
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={props.content.actionText}
                                    accentColor={
                                        "accentColor" in props.content &&
                                        props.content.accentColor
                                            ? props.content.accentColor
                                            : block.accentColor
                                    }
                                />
                            </FlexRow>
                            <FlexColumn>
                                <TextBox
                                    text={props.content.title}
                                    lineClamp={2}
                                    weight="800"
                                    size={isMobile ? 2 : 2.75}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        // overflow: "unset",
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                                <Conditional when={!!props.content.subtitle}>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={props.content.subtitle}
                                        lineClamp={2}
                                        weight="400"
                                        size={isMobile ? 1 : 1}
                                        color={
                                            "textColor" in props.content &&
                                            props.content.textColor
                                                ? props.content.textColor
                                                : theme.colors.white
                                        }
                                        css={{
                                            // overflow: "unset",
                                            overflowWrap: "break-word",
                                            wordBreak: "break-word",
                                            textShadow: defaultTextShadow,
                                        }}
                                    />
                                </Conditional>
                                <Conditional when={!!props.content.kicker}>
                                    <VerticalDivider M />
                                    <TextBox
                                        text={props.content.kicker}
                                        case="UPPERCASE"
                                        size={isMobile ? 0.8 : 0.8}
                                        weight="800"
                                        color={
                                            "textColor" in props.content &&
                                            props.content.textColor
                                                ? props.content.textColor
                                                : theme.colors.white
                                        }
                                        css={{
                                            textShadow: defaultTextShadow,
                                        }}
                                    />
                                </Conditional>
                            </FlexColumn>
                        </FlexColumn>
                    </FlexColumn>
                </GridCardContainer>
            </Link>
        </LayoutGrid>
    );
};

export { ContentGrid_1_Medium };
