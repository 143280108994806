import { useSpring, MotionValue } from "framer-motion";
import { useLayoutEffect } from "react";

const useScrollPositionXForElement = (element: HTMLDivElement | null) => {
    const scrollPositionX: MotionValue<number> = useSpring(0, {
        damping: 30,
        stiffness: 200,
    });

    useLayoutEffect(() => {
        if (!element) return;
        const unsubscribe = scrollPositionX.onChange(posX => {
            // console.count(`POSITION X: ${posX}`);
            if (isNaN(posX)) return;
            element.scrollTo(posX, 0);
        });
        return () => {
            unsubscribe();
        };
    }, [element]);

    return scrollPositionX;
};

const useScrollPositionYForElement = (element: HTMLDivElement | null) => {
    const scrollPositionY: MotionValue<number> = useSpring(0, {
        damping: 30,
        stiffness: 200,
    });

    useLayoutEffect(() => {
        if (!element) return;
        const unsubscribe = scrollPositionY.onChange(posY => {
            if (isNaN(posY)) return;
            element.scrollTo(0, posY);
        });
        return () => {
            unsubscribe();
        };
    }, [element]);

    return scrollPositionY;
};

export { useScrollPositionXForElement, useScrollPositionYForElement };
