import { backendHost } from "@@/shared/fetch-client";
import { FlexColumn } from "@@/shared/flex-containers";
import { css } from "@emotion/react";
import { FC } from "react";

const BeAPartOfTowniSection: FC = () => {
    return (
        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
            <a
                href={`mailto:kontakt@towni.se`}
                title="Bli en del av Towni"
                css={css`
                    display: block;
                    width: 100%;
                    text-decoration: none;
                `}>
                <img
                    src={`${backendHost}/public/storage/towni-brand/misc/be-a-part.jpg`}
                    title="Bli en del av Towni"
                    style={{ display: "block", width: "100%" }}
                />
            </a>
        </FlexColumn>
    );
};

export { BeAPartOfTowniSection };
