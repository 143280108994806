import { useTheme } from "@emotion/react";
import { Except } from "type-fest";
import type { ButtonColorPropNames, ButtonProps_v2 } from "./button";
import { Button_v2 } from "./button";

const ButtonDanger = (props: Except<ButtonProps_v2, ButtonColorPropNames>) => {
    const theme = useTheme();
    const colorSet = props.disabled
        ? {
              main: theme.colors.disabled.light,
              text: theme.colors.disabled.main,
              border: theme.colors.disabled.light,
          }
        : {
              main: theme.colors.danger.main,
              text: theme.colors.danger.text,
              border: theme.colors.danger.border,
          };
    return (
        <Button_v2 {...props} colorSet={colorSet}>
            {props.children}
        </Button_v2>
    );
};

export { ButtonDanger };
