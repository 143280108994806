import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../shared/store-front-hz-product-card";

const SmartLateSummerSection = () => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const productIds = React.useMemo(
        () => [
            getProductId("product__l8vWZxpjVouA6KBIAuTI" as ProductId), // Stadt Lidköping - Hyr elcykel på Stadshotellet!
            getProductId("product__w0-X7qQjedZqQMzfhyG_" as ProductId), // Stadt Lidköping - Transportvagn
            getProductId("product__crFXUZ4n-_bAEvrg2YGd" as ProductId), // Skara Konsthotell - Hyr elcykel från Konsthotellet
            getProductId("product__5vZhbMfYXj775Y9AILq7" as ProductId), // Skara Konsthotell - Transportvagn
        ],
        [],
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="smart-late-summer-section"
            title={"Sensommarutbudet"}
            subtitle={
                "Nu börjar den fina sensommaren. Än finns det saker att upptäcka. En del av vårt utbud är nu på uppehåll, men nedan är exempel på ställen att cykla från!"
            }
            background={{ color: theme.colors.primary.light }}
            css={{
                borderRadius: isDesktop ? 10 : 0,
            }}
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                <ForEach itemOf={productIds}>
                    {(productId: ProductId, index) => (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : productId}
                            cardSize={1.2 as RemSize}
                            aspectRatio="3:4"
                            actionText={translation({
                                sv: "Läs mer",
                                en: "Read more",
                            })}
                            actionTextColorSet={theme.colors.primary}
                            productId={productId}
                            hideKicker
                        />
                    )}
                </ForEach>
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { SmartLateSummerSection };
