import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { PageLoad } from "@@/pages/page-load";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { NavigateTo } from "@@/shared/text/navigate-to";
import { BauerGardenstoreFront } from "@@/store-fronts/baurgarden/baurergarden-store-front";
import { DaftoStoreFront } from "@@/store-fronts/dafto/dafto-store-front";
import { KronocampingStoreFront } from "@@/store-fronts/kronocamping/kronocamping-store-front";
import { LackoStoreFront } from "@@/store-fronts/lacko/lacko-store-front";
import { MotorstadionLidkopingRaceroomStoreFront } from "@@/store-fronts/motorstadion/lidkoping/motorstadion-lidkoping-raceroom-store-front";
import { MotorstadionLidkopingStoreFront } from "@@/store-fronts/motorstadion/lidkoping/motorstadion-lidkoping-store-front";
import { MotorstadionSkovdeStoreFront } from "@@/store-fronts/motorstadion/skovde/motorstadion-skovde-store-front";
import { MotorstadionUddevallaStoreFront } from "@@/store-fronts/motorstadion/uddevalla/motorstadion-uddevalla-store-front";
import { NavenStoreFront } from "@@/store-fronts/naven/naven-store-front";
import { setCurrentStorefront } from "@@/store-fronts/shared/current-store-front";
import { SmartStoreFrontElCar } from "@@/store-fronts/smart-resande/smart-store-front-el-car";
import { SmartStoreFrontGotene } from "@@/store-fronts/smart-resande/smart-store-front-gotene";
import { SmartStoreFrontGrastorp } from "@@/store-fronts/smart-resande/smart-store-front-grastorp";
import { SmartStoreFrontLidkoping } from "@@/store-fronts/smart-resande/smart-store-front-lidkoping";
import { SmartStoreFrontSkara } from "@@/store-fronts/smart-resande/smart-store-front-skara";
import { SmartStoreFrontStart } from "@@/store-fronts/smart-resande/smart-store-front-start";
import { SmartStoreFrontSummer2022 } from "@@/store-fronts/smart-resande/smart-store-front-summer";
import { SmartStoreFrontVara } from "@@/store-fronts/smart-resande/smart-store-front-vara";
import {
    DestinationName,
    ProviderSlug,
    destinationTargets,
} from "@towni/common";
import { Navigate, useParams } from "react-router-dom";

const DestinationPage = () => {
    const params = useParams<{ destination: string }>();
    const destinationName: DestinationName | undefined = params.destination
        ?.trim()
        .toLocaleLowerCase() as DestinationName;
    if (!destinationName) throw new Error("Invalid destination");
    // Här ska vi visa antingen hårdkodad storefront som fallback
    // I annat fall om destinationen är en provider, ska det kontrolleras om det finns ett hem
    // valt så ska det visas
    const target = destinationTargets.get(destinationName) || {
        _type: "DESTINATION_TARGET",
        type: "PROVIDER_HOME",
        providerSlug: encodeURIComponent(destinationName) as ProviderSlug,
    };

    const [, providerQuery] = useProvider(
        target.type === "PROVIDER_HOME" ? target.providerSlug : undefined,
    );
    const homePath = useHomePath(providerQuery.data, false);
    if (target && target.type !== "LEGACY") {
        setCurrentStorefront(destinationName);
        if (target.type === "PATH") return <NavigateTo to={target.path} />;
        if (providerQuery.isPending) return <PageLoad />;
        if (providerQuery.isError) throw providerQuery.error;
        if (!providerQuery.data) throw new Error("Provider not found");
        if (homePath) return <NavigateTo to={homePath} />;
    }

    // No target or no home path...
    // Show fallback, old hard coded storefront if any
    switch (destinationName.toLocaleLowerCase()) {
        case "lacko":
        case "läckö":
            setCurrentStorefront("läckö");
            return <LackoStoreFront />;
        case "dafto":
        case "daftö":
            setCurrentStorefront("daftö");
            return <DaftoStoreFront />;
        case "kronocamping":
            setCurrentStorefront("kronocamping");
            return <KronocampingStoreFront />;
        case "smart-resande":
            setCurrentStorefront("smart-resande");
            return <SmartStoreFrontSummer2022 />;
        case "smart-resande-start":
            setCurrentStorefront("smart-resande-start");
            return <SmartStoreFrontStart />;
        case "smart-resande-elbil":
            setCurrentStorefront("smart-resande-elbil");
            return <SmartStoreFrontElCar />;
        case "smart-resande-skara":
            setCurrentStorefront("smart-resande-skara");
            return <SmartStoreFrontSkara />;
        case "smart-resande-gotene":
            setCurrentStorefront("smart-resande-gotene");
            return <SmartStoreFrontGotene />;
        case "smart-resande-lidkoping":
            setCurrentStorefront("smart-resande-lidkoping");
            return <SmartStoreFrontLidkoping />;
        case "smart-resande-grastorp":
            setCurrentStorefront("smart-resande-grastorp");
            return <SmartStoreFrontGrastorp />;
        case "smart-resande-vara":
            setCurrentStorefront("smart-resande-vara");
            return <SmartStoreFrontVara />;
        case "motorstadion":
        case "motorstadion-lidkoping":
            setCurrentStorefront("motorstadion-lidkoping");
            return <MotorstadionLidkopingStoreFront />;
        case "motorstadion-raceroom":
            setCurrentStorefront("motorstadion-raceroom");
            return <MotorstadionLidkopingRaceroomStoreFront />;
        case "motorstadion-skovde":
            setCurrentStorefront("motorstadion-skovde");
            return <MotorstadionSkovdeStoreFront />;
        case "motorstadion-uddevalla":
            setCurrentStorefront("motorstadion-uddevalla");
            return <MotorstadionUddevallaStoreFront />;
        case "bauergarden-i-bunn":
            setCurrentStorefront("bauergarden-i-bunn");
            return <BauerGardenstoreFront />;
        case "naven-outdoor":
            setCurrentStorefront("naven-outdoor");
            return <NavenStoreFront />;

        default:
            setCurrentStorefront("towni");
            return <Navigate to="/" replace />;
    }
};

export { DestinationPage };
