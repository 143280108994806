import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { Order, isAccommodation, isBookable, translation } from "@towni/common";
import { OrderExternalKeyAccess } from "./order-external-access";

type Props = { order: Order };

const OrderKeyAccess = ({ order }: Props) => {
    const orderItemsWithKeyAccess = order.orderItems.filter(
        o =>
            (isBookable(o) && o.hasAccess) ||
            (isAccommodation(o) && o.hasAccess),
    );
    if (orderItemsWithKeyAccess.length === 0) return null;
    return (
        <>
            <FlexColumn tag="order_extras">
                <VerticalDivider M />

                <FlexRow padding={{ leftRight: 20 }} crossAxis="center">
                    <TextBox
                        size={1.25}
                        weight="800"
                        text={translation({
                            sv: "Nycklar för din bokning",
                            en: "Keys for your booking",
                        })}
                    />
                </FlexRow>
                <VerticalDivider M />
                <HorizontalLine />

                <VerticalDivider M />
                <OrderExternalKeyAccess order={order} />
                <VerticalDivider M />
            </FlexColumn>
        </>
    );
};

export { OrderKeyAccess };
