import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { motion } from "framer-motion";
import { useLocation, useMatch } from "react-router-dom";
import { pageAnimationFade } from "./page-animation-fade";
import {
    pageAnimationSlideAndFade,
    pageTransition,
} from "./page-animation-slide-and-fade";
import { usePageNavigationState } from "./page-navigation-context";

type PageAnimationHide = "initially" | "animate";
type PageAnimationType = "fade" | "slideAndFade";

const PageAnimationWrapper = (props: {
    pageId?: string;
    children: React.ReactNode;
    ignoreAnimation?: boolean;
    hide?: PageAnimationHide;
}) => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const { pathname } = useLocation();
    const { transitionType, direction } = usePageNavigationState();
    const ignoreAnimation =
        (props.ignoreAnimation || transitionType === "NONE") && !props.hide;
    const isBackofficeRoute = !!useMatch({
        path: "/bo/*",
        end: false,
    });
    const animationType: PageAnimationType =
        isBackofficeRoute || isDesktop ? "fade" : "slideAndFade";

    const pageAnimation =
        animationType === "fade"
            ? pageAnimationFade
            : pageAnimationSlideAndFade(direction ?? "FORWARD");

    const initial = props.hide === "initially" ? "exit" : "initial";
    const animate =
        props.hide === "initially"
            ? false
            : props.hide === "animate"
              ? "exit"
              : "enter";

    return (
        <motion.div
            style={{ width: "100%", height: "100%" }}
            css={{ label: "page_anim_wrapper" }}
            key={props.pageId ?? pathname}
            initial={ignoreAnimation ? animate : initial}
            animate={animate}
            exit={"exit"}
            variants={pageAnimation}
            transition={{
                ...pageTransition,
                ...(ignoreAnimation ? { delay: 0 } : {}),
            }}>
            {props.children}
        </motion.div>
    );
};

export { PageAnimationWrapper };
export type { PageAnimationHide };
