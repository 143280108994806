import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { ResponsiveStoreFrontHzProductCard } from "../../shared/store-front-hz-product-card";

const LackoEnglishToursSection: FC = () => {
    const getProductId = useProductIdWithFallback();
    const theme = useTheme();
    const productIds = React.useMemo(
        () => [
            getProductId("product_06y6HULTCd80PZrU1NwG" as ProductId), // lake vänern
            getProductId("product_8lTgcwwGXiFc3IQeWzst" as ProductId), // Guided tours in English 11 Jun – 28 Aug
            getProductId("product_71wiOAJKqvxTVtwCp_te" as ProductId), // Guided tour in English at 2 pm from 29th Aug
        ],
        [],
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            title={"Welcome to Läckö - english"}
            subtitle={
                "There are also written information in English, German and French are available during tours in Swedish. Here are two tours in spoken english."
            }
            sectionAreaId="lacko-guided-tours"
            // background={{
            //     color: lackoColors.lightGreenBackground,
            // }}
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : id}
                            cardSize={1.4 as RemSize}
                            aspectRatio="1:1"
                            actionText={text.action}
                            actionTextColorSet={theme.colors.primary}
                            productId={id}
                            hideSubtitle
                            hideKicker
                        />
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

const text = {
    action: translation({
        sv: "Look & Book",
        en: "Buy",
    }),
};

export { LackoEnglishToursSection };
