import { useMe } from "@@/authentication/me/me-context";
import {
    useDeliveryDispatch,
    useDeliveryState,
} from "@@/carts/deliverypicker/delivery-context";
import { useAddressesForMeQuery } from "@@/carts/deliverypicker/use-address-for-me-query";
import { useModal } from "@@/modals/use-modal";
import { useTheme } from "@emotion/react";
import { faChevronRight, faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
    RemSize,
    UserAddress,
    addAddressCommandFactory,
    colorAsString,
    createEditAddressCommand,
    emptyArrayOf,
    translation,
} from "@towni/common";
import * as React from "react";
import { Circle } from "../../circle";
import { HorizontalLine, VerticalDivider } from "../../dividers";
import { FlexColumn, FlexRow } from "../../flex-containers";
import { Icon } from "../../icons/icon";
import { Join } from "../../join";
import { SelectBox } from "../../select-box";
import { TextBox } from "../../text";
import { AddressPickerModal } from "./address-picker-modal";
import { useAddUserAddress } from "./use-add-user-address";
import { useEditUserAddress } from "./use-edit-user-address";

const leftColumnWidth = 60;
type Props = {
    onSelect: () => void;
};

const AddressPicker = (props: Props) => {
    const theme = useTheme();
    const [me] = useMe();
    const userAddressesQuery = useAddressesForMeQuery();
    const { setAddress } = useDeliveryDispatch();
    const addUserAddress = useAddUserAddress({
        onSuccess: async userAddressId => {
            const addresses = await userAddressesQuery.refetch();
            if (!addresses) return;
            const newAddress = addresses.data?.items.find(
                item => item._id === userAddressId,
            );
            if (!newAddress) return;
            setAddress(newAddress);
        },
    });
    const state = useDeliveryState();
    const [{ show: showModal }, addressModalId] = useModal(
        "checkout_delivery_add_address_modal__",
    );

    const addresses = React.useMemo(() => {
        if (!me && state.homeDelivery.deliveryAddress) {
            return [state.homeDelivery.deliveryAddress];
        }
        if (me) {
            return (
                userAddressesQuery.data?.items ?? emptyArrayOf<UserAddress>()
            );
        }
        return [];
    }, [
        state.homeDelivery.deliveryAddress,
        userAddressesQuery.data?.items,
        me,
    ]);

    const isAnonAndHasAddress = React.useMemo(() => {
        if (!me && state.homeDelivery.deliveryAddress) {
            return true;
        }
        return false;
    }, [state.homeDelivery.deliveryAddress, me]);

    React.useEffect(() => {
        if (
            state.homeDelivery.deliveryAddress === undefined &&
            addresses.length > 0
        ) {
            setAddress(addresses[0]);
        }
        if (addresses.length > 0) {
            const findAddress = addresses.find(
                a => a._id === state.homeDelivery.deliveryAddress?._id,
            );
            if (findAddress) setAddress(findAddress);
            else setAddress(addresses[0]);
        }
    }, [addresses, setAddress, state.homeDelivery.deliveryAddress]);

    const addressList = addresses.map(userAddress => {
        const selected =
            userAddress._id === state.homeDelivery.deliveryAddress?._id;
        return (
            <AddressRow
                key={userAddress._id}
                address={userAddress}
                selected={selected}
                onSelect={() => {
                    setAddress(userAddress);
                    props.onSelect();
                }}
                onUpdate={async () => {
                    userAddressesQuery.refetch();
                }}
            />
        );
    });

    const primaryVeryLightColor =
        theme.colors.primary.main.value.withAlpha(0.1);

    return (
        <>
            <Join divider={<HorizontalLine />}>
                {[
                    ...addressList,
                    !isAnonAndHasAddress ? (
                        <FlexRow
                            key={"add-address"}
                            padding={{ topBottom: 10 }}
                            height={64}
                            shrink={0}
                            color={theme.colors.default.text}
                            onClick={showModal}>
                            <FlexColumn
                                width={leftColumnWidth}
                                minWidth={leftColumnWidth}
                                crossAxis="center">
                                <Circle
                                    size={32}
                                    backgroundColor={primaryVeryLightColor}
                                    // styling={css`
                                    //     &:active {
                                    //         background-color: ${colorAsString(
                                    //             theme.colors.primary.dark
                                    //         )};
                                    //     }
                                    // `}
                                >
                                    <Icon
                                        icon={faPlus}
                                        size={0.8 as RemSize}
                                        color={colorAsString(
                                            theme.colors.primary,
                                        )}
                                    />
                                </Circle>
                            </FlexColumn>
                            <FlexColumn width="100%">
                                <TextBox
                                    text={translation({
                                        sv: "Lägg till ny adress",
                                        en: "Add a new address",
                                    })}
                                    weight="600"
                                    size={1}
                                />
                            </FlexColumn>
                        </FlexRow>
                    ) : null,
                ]}
            </Join>
            <AddressPickerModal
                modalId={addressModalId}
                save={userAddress => {
                    if (!me && userAddress) {
                        setAddress(userAddress);
                        return;
                    }
                    addUserAddress.mutateAsync(
                        addAddressCommandFactory({
                            ...userAddress,
                            inputType:
                                userAddress.inputType ?? "GOOGLE_AUTO_COMPLATE",
                        }),
                    );
                }}
            />
        </>
    );
};

type AddressProps = {
    address: UserAddress;
    onSelect: () => void;
    onUpdate: () => Promise<void>;
    selected: boolean;
};
const AddressRow = ({
    address,
    onSelect,
    onUpdate,
    selected,
}: AddressProps) => {
    const theme = useTheme();
    const [me] = useMe();
    const { setAddress } = useDeliveryDispatch();
    const editUserAddress = useEditUserAddress({
        onSuccess: onUpdate,
    });

    const [{ show }, addressEditModalId] = useModal(
        "checkout_delivery_edit_address_modal__",
    );
    const primaryVeryLightColor =
        theme.colors.primary.main.value.withAlpha(0.1);
    return (
        <>
            <FlexRow
                padding={{ topBottom: 10 }}
                height={64}
                shrink={0}
                background={{
                    color: selected
                        ? primaryVeryLightColor
                        : theme.colors.default.background,
                }}
                color={theme.colors.default.text}
                onClick={_ => {
                    if (selected) {
                        show();
                        return;
                    }
                    onSelect();
                }}>
                <FlexColumn
                    width={leftColumnWidth}
                    minWidth={leftColumnWidth}
                    crossAxis="center">
                    <SelectBox selected={selected} shape="CIRCLE" size="L" />
                </FlexColumn>

                <FlexColumn width="100%">
                    {address.name ? (
                        <>
                            <TextBox
                                text={address.name}
                                weight="600"
                                size={1}
                            />
                            <VerticalDivider XXS />
                        </>
                    ) : null}
                    <TextBox
                        text={`${address.address.addressRows[0]}, ${address.address.zipCode}`}
                        size="S"
                        weight="400"
                        color={theme.colors.default.text.value.withAlpha(0.6)}
                    />
                </FlexColumn>
                <FlexColumn
                    onClick={e => {
                        e.stopPropagation();
                        show();
                    }}>
                    <Icon
                        icon={faChevronRight}
                        size={"ML"}
                        padding={{ all: 20 }}
                    />
                </FlexColumn>
            </FlexRow>
            <AddressPickerModal
                initialAddress={address}
                onHide={onUpdate}
                modalId={addressEditModalId}
                save={userAddress => {
                    if (!me && userAddress) {
                        setAddress(userAddress);
                        return;
                    }
                    editUserAddress.mutateAsync(
                        createEditAddressCommand({
                            address: userAddress.address,
                            addressId: userAddress._id,
                            deliveryInstructions:
                                userAddress.deliveryInstructions,
                            details: userAddress.details,
                            name: userAddress.name,
                            inputType:
                                userAddress.inputType ?? "GOOGLE_AUTO_COMPLATE",
                        }),
                    );
                }}
            />
        </>
    );
};

export { AddressPicker, leftColumnWidth };
