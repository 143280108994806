import { PhoneSignInOrUpPage } from "@@/authentication/phone/phone-sign-in-or-up-page";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { FlexColumn } from "@@/shared/flex-containers";
import { useTheme } from "@emotion/react";

type Props = {
    modalId: ModalId;
    onHide?: () => void;
};

const UserSignInOrUpModal = (props: Props) => {
    const theme = useTheme();
    const { hide } = useModalActions(props.modalId);

    return (
        <Modal modalId={props.modalId} onHide={props.onHide}>
            <FlexColumn
                crossAxis="center"
                background={{ color: theme.colors.default.background }}
                css={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <PhoneSignInOrUpPage hide={hide} />
            </FlexColumn>
        </Modal>
    );
};

export { UserSignInOrUpModal };
