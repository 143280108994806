import { useTimeFormatter } from "@@/translations/use-time-formatter";
import { IsoAndUnixTimestamp } from "@towni/common";
import { Except } from "type-fest";
import { TextBox, TextBoxProps } from "./text-box";

type Props = Except<TextBoxProps, "text" | "children"> & {
    time: IsoAndUnixTimestamp;
};

const TimeTextBox = (props: Props) => {
    const { time, ...textBoxProps } = props;
    const formatter = useTimeFormatter();
    const formattedTime = formatter(time, "HH:mm");
    return <TextBox {...textBoxProps} text={formattedTime} />;
};

export { TimeTextBox };
