import {
    useAutoRestoreScrollPosition,
    useScrollPositionAutoRecorder,
} from "@@/pages/scroll-position-context";
import { useBreadCrumbPathState } from "@@/shared/bread-crumb-id-context";
import { FlexRow } from "@@/shared/flex-containers";
import { paddingToCssValue } from "@@/shared/padding";
import { css } from "@emotion/react";
import { useRef } from "react";
import { SectionDisplayProps } from "../section-content-display";

const SectionListHz = (props: SectionDisplayProps) => {
    const scroll = props.sectionTheme.scroll;
    const container = css`
        width: 100%;
        overflow-x: ${scroll ? "scroll" : "hidden"};
        padding: ${paddingToCssValue({
            topBottom: 5,
            left: 5,
            right: 0,
        })};
    `;

    // >> Remember scroll position
    // TODO: MAKE A CUSTOM HOOK AND WRAP THE FUNCTIONALITY
    const [breadCrumbPath] = useBreadCrumbPathState();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    useAutoRestoreScrollPosition(breadCrumbPath, scrollContainerRef);
    useScrollPositionAutoRecorder(breadCrumbPath, scrollContainerRef);
    // << Remember scroll position

    return (
        <div css={container} ref={scrollContainerRef}>
            <FlexRow
                crossAxis="flex-start"
                {...(props.sectionTheme.wrap
                    ? {
                          wrap: "wrap",
                          wrapMargin: 5,
                      }
                    : {})}>
                {props.children}
            </FlexRow>
        </div>
    );
};

export { SectionListHz };
