import { useProduct } from "@@/products/state/queries/product/use-product";
import { Product, ProductId } from "@towni/common";
import { useMemo } from "react";
import { ProductNotFoundError } from "../errors/product-not-found-error";

const useProductLoader = (productId: ProductId | undefined) => {
    const [product, productQuery] = useProduct(productId);
    if (!product && !productQuery.isPending)
        throw new ProductNotFoundError(productId);
    if (!product && productQuery.isError && productQuery.error)
        throw new ProductNotFoundError(productId, productQuery.error);

    return useMemo(
        () =>
            [product, productQuery.isPending] as
                | [Product, false]
                | [undefined, true],
        [product, productQuery.isPending],
    );
};

export { useProductLoader };
