import { ModalId } from "@@/modals/context/modal-id";
import { css } from "@emotion/react";
import { emptyAddress, translation, UserAddress } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { BottomButton } from "../../buttons/bottom-button";
import { HorizontalDivider, VerticalDivider } from "../../dividers";
import { FlexColumn, FlexRow } from "../../flex-containers";
import { TextEdit } from "../../text";

type Props = {
    readonly current: Partial<UserAddress>;
    readonly modalId: ModalId;
    readonly update: (address: Partial<UserAddress>) => void;
    readonly save: () => void;
    readonly isEdit: boolean;
    readonly hideAddressNameField?: boolean;
};

const AddressPickerManualForm: FC<Props> = props => {
    const address = props.current.address ?? emptyAddress;
    return (
        <>
            <FlexColumn
                padding={{ leftRight: 20 }}
                shrink={0}
                styling={css`
                    pointer-events: auto;
                `}>
                <VerticalDivider L />
                <TextEdit
                    type="text"
                    level="RECOMMENDED"
                    label="Gatuadress"
                    labelDescription="(inkl gatunummer)"
                    onChange={addressRow =>
                        props.update({
                            ...props.current,
                            address: { ...address, addressRows: [addressRow] },
                        })
                    }
                    value={
                        address.addressRows?.length > 1
                            ? address.addressRows.join(", ")
                            : address.addressRows[0] ?? ""
                    }
                />
                <VerticalDivider L />
                <FlexRow fillParentWidth>
                    <TextEdit
                        type="text"
                        level="RECOMMENDED"
                        label="Postnummer"
                        onChange={zipCode =>
                            props.update({
                                ...props.current,
                                address: { ...address, zipCode },
                            })
                        }
                        value={address.zipCode ?? ""}
                    />
                    <HorizontalDivider L />
                    <TextEdit
                        type="text"
                        level="RECOMMENDED"
                        label="Ort"
                        onChange={city =>
                            props.update({
                                ...props.current,
                                address: { ...address, city },
                            })
                        }
                        value={props.current.address?.city ?? ""}
                    />
                </FlexRow>
                <VerticalDivider L />
                <TextEdit
                    type="text"
                    level="RECOMMENDED"
                    label="Adressdetaljer"
                    description={"Portkod, våning etc"}
                    onChange={details =>
                        props.update({ ...props.current, details })
                    }
                    value={props.current.details ?? ""}
                />
                <VerticalDivider L />
                <TextEdit
                    type="text"
                    level="RECOMMENDED"
                    label="Leveransinstruktioner"
                    description="Ex. ring på vid leverans, ställ brevid..."
                    onChange={deliveryInstructions =>
                        props.update({ ...props.current, deliveryInstructions })
                    }
                    value={props.current.deliveryInstructions ?? ""}
                />
                {props.hideAddressNameField ? null : (
                    <>
                        <VerticalDivider L />
                        <TextEdit
                            type="text"
                            level="RECOMMENDED"
                            label="Namnge adressen"
                            description="Hem, jobb, stugan"
                            onChange={name =>
                                props.update({ ...props.current, name })
                            }
                            value={props.current.name ?? ""}
                        />
                    </>
                )}

                <VerticalDivider size="3XL" />
                <VerticalDivider size="3XL" />
            </FlexColumn>
            <BottomButton
                disabled={!address.zipCode || !address.city}
                onClick={props.save}
                text={
                    props.isEdit
                        ? translation({
                              sv: "Spara",
                              en: "Save",
                          })
                        : translation({
                              sv: "Lägg till",
                              en: "Add",
                          })
                }
            />
        </>
    );
};

export { AddressPickerManualForm };
