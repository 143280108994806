import { useTranslate } from "@@/translations/use-translate";
import { SerializedStyles } from "@emotion/react";
import { Dimensions, ImageUrl, Translatable } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { ForEach } from "../for-each";
import { ImageSrc } from "./image-src";

type Props = {
    readonly title: Translatable;
    readonly sources: ImageSrc | ImageSrc[] | undefined;
    readonly style: React.CSSProperties | undefined;
    readonly styling?: SerializedStyles;
    readonly imageDimensions: Map<ImageUrl, Dimensions>;
    readonly onImageLoad?: (
        event: React.SyntheticEvent<HTMLImageElement>,
    ) => void;
};

const Img: FC<Props> = props => {
    const translate = useTranslate();
    const { imageDimensions } = props;

    const multipleSources = Array.isArray(props.sources);

    if (!props.sources || (multipleSources && !props.sources.length)) {
        return null;
    }

    if (!multipleSources) {
        const alt = `${translate(props.title)} [${
            imageDimensions.get(props.sources.url)?.width
        }x${imageDimensions.get(props.sources.url)?.height}]`;

        return (
            <img
                key="img"
                onLoad={props.onImageLoad}
                src={props.sources.url}
                style={props.style}
                css={props.styling}
                alt={alt}
                title={translate(props.title)}
            />
        );
    }

    const defaultSrc =
        props.sources.find(
            item => item.mime.includes("jpg") || item.mime.includes("jpeg"),
        )?.url ?? props.sources[0]?.url;

    return (
        <picture>
            <ForEach itemOf={props.sources} getKey={source => source._id}>
                {src => (
                    <source key={src._id} srcSet={src.url} type={src.mime} />
                )}
            </ForEach>
            <img
                key={"img"}
                onLoad={props.onImageLoad}
                src={defaultSrc}
                style={props.style}
                css={props.styling}
                alt={translate(props.title)}
            />
        </picture>
    );
};

export { Img };
