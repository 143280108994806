import { useTheme } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
    status: "FINISHED" | "ACTIVE" | "INACTIVE";
    duration: number;
};

const ProgressPill = (props: Props) => {
    const theme = useTheme();
    const { status, duration } = props;
    const isActive = status === "ACTIVE";
    const isFinished = status === "FINISHED";
    const fillColor = theme.colors.white.light80.asString;
    const bgColor = theme.colors.white.light80.withAlpha(0.4).asString;

    return (
        <>
            <div css={{ width: 5 }} />
            <div
                css={{
                    display: "flex",
                    flexGrow: 0,
                    flexShrink: 1,
                    borderRadius: 3,
                    backgroundColor: isFinished ? fillColor : bgColor,
                    justifyContent: "stretch",
                    alignItems: "stretch",
                    minWidth: 25,
                    minHeight: 4,
                    overflow: "hidden",
                }}>
                {isActive && (
                    <AnimatePresence>
                        <motion.div
                            style={{
                                backgroundColor: fillColor,
                                opacity: 1,
                                height: 5,
                            }}
                            initial={{ width: "0%" }}
                            animate={{
                                width: "100%",
                            }}
                            transition={{
                                type: "tween",
                                duration: duration / 1000,
                                ease: "linear",
                            }}
                        />
                    </AnimatePresence>
                )}
            </div>
            <div css={{ width: 5 }} />
        </>
    );
};

export { ProgressPill };
