import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import { GetSingleResponse, Product, ProductId } from "@towni/common";

const fetchProduct =
    (productId: ProductId | undefined): QueryFunction<Product> =>
    async ({ signal }) => {
        if (!productId) throw new Error("missing product id");
        const data = await apiFetchClient.get<GetSingleResponse<Product>>({
            route: `/products/${encodeURIComponent(productId)}`,
            customConfig: {
                signal,
            },
        });
        return data.item || null;
    };

export { fetchProduct };
