import { useShipping } from "@@/delivery/shipping-fetcher";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useScrollPositionYForElement } from "@@/pages/container-navigation/use-scroll-position-x-for-element";
import { AddressPicker } from "@@/shared/address-pickers/user-address-picker/address-picker";
import { BottomButton } from "@@/shared/buttons/bottom-button";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useElementSize } from "@@/shared/use-element-size";
import { useIsContentScrolled } from "@@/shared/use-is-content-scrolled";
import { css, useTheme } from "@emotion/react";
import {
    Provider,
    pickupTimeByProvider,
    translation,
    zeroPrice,
} from "@towni/common";
import * as React from "react";
import { useRef } from "react";
import { useCheckoutContext } from "../checkout.context";
import { useCartContext } from "../multi-carts/cart.context";
import { useDeliveryState } from "./delivery-context";
import { DeliveryMethodPicker } from "./delivery-method-picker";
import { PickupTimePicker } from "./pickup-time-picker";
import { ShipmentOptionPicker } from "./shipment-option-picker";

type Props = {
    modalId: ModalId;
    provider: Provider;
    onHide?: () => void;
};

const DeliveryPickerModal = (props: Props) => {
    const state = useDeliveryState();
    const theme = useTheme();
    const setDeliverySelection = useCartContext(
        context => context.setDeliverySelection,
    );
    const setMadeDeliveryChoice = useCheckoutContext(
        context => context.setMadeDeliveryChoice,
    );
    const [shipping] = useShipping(state.homeDelivery.shippingId);
    const { hide } = useModalActions(props.modalId);

    const topBarRef = useRef<HTMLDivElement>(null);

    const [topBarSize] = useElementSize({ targetRef: topBarRef });

    const [contentRef, setContentRef] = React.useState<HTMLDivElement | null>(
        null,
    );
    const [shippingRef, setShippingRef] = React.useState<HTMLDivElement | null>(
        null,
    );
    const isContentScrolled = useIsContentScrolled(contentRef);

    const continueDisabled = !(
        state.selected === "PICK_UP" ||
        (state.selected === "HOME_DELIVERY" &&
            shipping !== undefined &&
            state.homeDelivery.deliveryAddress?.address !== undefined)
    );

    const continueClick = async () => {
        switch (state.selected) {
            case "PICK_UP":
                setDeliverySelection({
                    method: "PICK_UP",
                    slot: state.pickupState.slot,
                    price: zeroPrice,
                });
                setMadeDeliveryChoice(true);
                break;
            case "HOME_DELIVERY":
                if (
                    shipping &&
                    state.homeDelivery.deliveryAddress?.address &&
                    state.homeDelivery.zone &&
                    state.homeDelivery.price
                ) {
                    setDeliverySelection({
                        method: "HOME_DELIVERY",
                        shipping: shipping._id,
                        slot: state.homeDelivery.slot,
                        destination: state.homeDelivery.deliveryAddress,
                        shippingTime: pickupTimeByProvider(
                            props.provider,
                            shipping,
                        ),
                        price: state.homeDelivery.price,
                        zone: state.homeDelivery.zone,
                    });
                    setMadeDeliveryChoice(true);
                }
                break;
        }
        hide();
    };

    const scrollY = useScrollPositionYForElement(contentRef);

    return (
        <Modal
            height={"full"}
            modalId={props.modalId}
            header={
                <ModalHeader
                    ref={topBarRef}
                    modalId={props.modalId}
                    title={translation({
                        sv: "Orderdetaljer",
                        en: "Order details",
                    })}
                    css={
                        isContentScrolled
                            ? css({
                                  ":before": {
                                      content: '""',
                                      position: "absolute",
                                      top: topBarSize?.height ?? 0,
                                      left: 0,
                                      width: "100%",
                                      height: 50,
                                      pointerEvents: "none",
                                      zIndex: 100,
                                      background:
                                          "linear-gradient(180deg, #DDDDDD44, rgba(255,255,255,0.0001))",
                                  },
                              })
                            : undefined
                    }
                />
            }>
            <FlexColumn
                fillParentWidth
                grow={1}
                mainAxis="space-between"
                css={{
                    position: "relative",
                }}>
                <FlexColumn
                    fillParentWidth
                    grow={1}
                    ref={setContentRef}
                    onWheel={() => {
                        scrollY.stop();
                        if (contentRef) {
                            scrollY.set(contentRef.scrollTop, false);
                        }
                    }}
                    onTouchMove={() => {
                        scrollY.stop();
                        if (contentRef) {
                            scrollY.set(contentRef.scrollTop, false);
                        }
                    }}
                    overflowY="auto"
                    background={{
                        color: theme.colors.default.background,
                    }}>
                    <TextBox
                        padding={{ leftRight: 20, topBottom: 16 }}
                        text={translation({
                            sv: "Hur?",
                            en: "How?",
                        })}
                        weight="800"
                        size="ML"
                    />
                    <HorizontalDivider />
                    <HorizontalLine />
                    <DeliveryMethodPicker provider={props.provider} />
                    <HorizontalLine />
                    <VerticalDivider ML />

                    {state.selected === "PICK_UP" ? (
                        <PickupTimePicker provider={props.provider} />
                    ) : (
                        <FlexColumn shrink={0}>
                            <TextBox
                                padding={{ leftRight: 20 }}
                                text={translation({
                                    sv: "Var?",
                                    en: "Where?",
                                })}
                                weight="800"
                                size="ML"
                                shrink={0}
                            />

                            <VerticalDivider ML />
                            {/* <AddressPickerRow /> */}
                            <AddressPicker
                                onSelect={() => {
                                    if (shippingRef && contentRef) {
                                        const topBar = topBarSize?.height ?? 80;
                                        scrollY.set(
                                            shippingRef.offsetTop - topBar,
                                        );
                                    }
                                }}
                            />
                            <VerticalDivider ML />
                            {state.homeDelivery.deliveryAddress && (
                                <>
                                    <FlexColumn ref={setShippingRef}>
                                        <TextBox
                                            shrink={0}
                                            padding={{ leftRight: 20 }}
                                            text={translation({
                                                sv: "När?",
                                                en: "When?",
                                            })}
                                            weight="800"
                                            size="ML"
                                        />
                                        <VerticalDivider M />
                                        <ShipmentOptionPicker
                                            provider={props.provider}
                                        />
                                    </FlexColumn>

                                    <VerticalDivider ML />
                                </>
                            )}
                            <TextBox
                                padding={{ leftRight: 50 }}
                                align="center"
                                size="S"
                                css={css`
                                    line-height: 18px;
                                `}
                                text={translation({
                                    sv: "Vi öppnar upp nya frakttider titt som tätt. Anmäl intresse så hjälper du oss att prioritera.",
                                    en: "",
                                })}
                                color={theme.colors.black.light60.asString}
                            />
                        </FlexColumn>
                    )}
                    <VerticalDivider size="3XL" />
                    <VerticalDivider size="3XL" />
                    <VerticalDivider size="L" />
                </FlexColumn>
                <BottomButton
                    disabled={continueDisabled}
                    onClick={continueClick}
                    text={translation({
                        sv: "Fortsätt",
                        en: "Next",
                    })}
                />
            </FlexColumn>
        </Modal>
    );
};

export { DeliveryPickerModal };
