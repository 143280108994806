import { PageLoad } from "@@/pages/page-load";
import { Affirmation } from "@@/receipts/shared/affirmation";
import { useResourceReservationsForOrders } from "@@/reservations/state/reservations-for-orders/use-resource-reservation-requests-for-orders";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { ConditionalSpin } from "@@/shared/show-or-spin";
import {
    Order,
    OrderGroup,
    OrderProjection,
    UserReference,
    isOrderItemBookable_V2,
    isVerifiedByAccessKey,
} from "@towni/common";
import { MutableRefObject } from "react";
import { Receipt } from "../shared/receipt";
import { Receipt_OrderGroupDetails } from "../shared/receipt-order-group-details";
import { useOrderReceiptsQuery } from "../use-order-receipts-query";
import { BookableReceipt_OrderDetails } from "./shared/bookable-receipt-order-details";
import { BookableReceipt_Tickets } from "./shared/bookable-receipt-tickets";

type Props = {
    orderGroup: OrderGroup;
    orders: Order[];
    customer: UserReference;
    printRef: MutableRefObject<HTMLDivElement | null>;
};

const BookableReceipt = (props: Props) => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const { orders, orderGroup, customer } = props;
    const { branding } = useBrandingContext();
    const [resourceReservations, _resourceReservationsQuery] =
        useResourceReservationsForOrders(orders.map(order => order._id));
    const receiptsQuery = useOrderReceiptsQuery(orderGroup._id);

    const paymentIsPending = orders.some(
        order =>
            order.status.type === "PENDING_PAYMENT_CONFIRMATION" ||
            order.status.type === "CREATED",
    );

    if (receiptsQuery.isPending) return <PageLoad />;
    const order: OrderProjection | undefined = orders?.[0];

    return (
        <>
            <BookableReceipt_Tickets
                customer={customer}
                orderGroup={orderGroup}
                orders={orders}
                resourceReservations={resourceReservations}
            />
            <VerticalDivider L />
            <div
                css={{
                    width: "100%",
                    filter: order?.inactive ? "grayscale(100%)" : "none",
                    opacity: order?.inactive ? 0.6 : 1,
                }}>
                <Receipt_OrderGroupDetails orders={orders}>
                    <ForEach
                        itemOf={orders}
                        getKey={order => order._id}
                        divider={<HorizontalSectionDivider />}>
                        {order => (
                            <BookableReceipt_OrderDetails
                                order={order}
                                resourceReservations={resourceReservations}
                            />
                        )}
                    </ForEach>
                </Receipt_OrderGroupDetails>
            </div>
            <Conditional
                // If all order items are table reservations
                // then pricing and receipt should not be shown
                when={orders.some(order =>
                    order.orderItems
                        .filter(isOrderItemBookable_V2)
                        .some(
                            item =>
                                item.bookingType !== "TABLE_BOOKING" ||
                                order.totalPrice.amountIncludingVat > 0,
                        ),
                )}
                render={() => {
                    return (
                        <ConditionalSpin
                            spinWhen={receiptsQuery.isPending}
                            spinner={<TextShimmer rows={4} />}>
                            <ForEach
                                itemOf={receiptsQuery.data?.item.receipts ?? []}
                                getKey={receipt => receipt._id}
                                divider={
                                    <VerticalDivider
                                        size={isDesktop ? "XL" : "L"}
                                    />
                                }
                                beginWith={
                                    <VerticalDivider
                                        size={isDesktop ? "XL" : "L"}
                                    />
                                }>
                                {receipt => {
                                    return (
                                        <Receipt
                                            orderGroup={orderGroup}
                                            receipt={receipt}
                                        />
                                    );
                                }}
                            </ForEach>
                        </ConditionalSpin>
                    );
                }}
            />
            <Affirmation
                printRef={props.printRef}
                isPaymentPending={paymentIsPending}
                nextUrlOverride={branding?.storefrontUrl}
                verifiedByAccessKey={isVerifiedByAccessKey(orderGroup)}
            />
        </>
    );
};

export { BookableReceipt };
