import { useCart } from "@@/carts/multi-carts/cart.context";
import { ModalId } from "@@/modals/context/modal-id";
import { useModalContext } from "@@/modals/context/modal.context";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { browserLogger } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import {
    Percentage,
    Product,
    isOrderItemBuyable_V2,
    translation,
} from "@towni/common";
import { useEffect, useMemo } from "react";
import { ExtrasProps } from "../product-details-page";
import { ExtrasOrderItemsV2 } from "./bookable-extras-order-item-list-v2";
import { ExtrasEditOrderItemModal } from "./extras-edit-order-item-modal";

type Props = {
    readonly product: Product;
    readonly modalId: ModalId;
    extras: ExtrasProps[];
};

const BookableProductExtrasGroupModal = (props: Props) => {
    const cart = useCart();
    const { hide } = useModalActions(props.modalId);

    const modals = useModalContext(context => context.modals);
    const modal = useMemo(
        () => modals.get(props.modalId),
        [props.modalId, modals],
    );
    const isModalVisible = modal?.status === "SHOW";

    //   const {hide} = useModalContext(props.modalId   )
    const oi = cart.orderItems.filter(
        item =>
            isOrderItemBuyable_V2(item) &&
            item.productId === props.product._id &&
            props.extras
                .map(e => e.extras._id)
                .some(e => e === item.extraGroup?._id),
    );

    useEffect(() => {
        if (oi.length === 0 && isModalVisible) {
            browserLogger.warn(
                "No order items found for extras group modal",
                isModalVisible,
            );
        }
    }, [hide, isModalVisible, oi.length]);

    //If there is only one order item, we can edit it directly
    if (oi.length === 1)
        return (
            <ExtrasEditOrderItemModal
                modalId={props.modalId}
                orderItemId={oi[0]._id}
                extras={props.extras[0]}
            />
        );

    return (
        <Modal height={0.9 as Percentage} modalId={props.modalId}>
            <FlexColumn
                fillParent
                padding={{ bottom: 20 }}
                crossAxis="stretch"
                css={{
                    backgroundColor: "white",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <ModalHeader
                    modalId={props.modalId}
                    title={translation({
                        sv: "Bokningsbara tillval",
                        en: "Bookable extras",
                    })}
                    bottomBorder
                />
                <FlexColumn fillParentWidth crossAxis="center">
                    <VerticalDivider ML />
                    <TextBox
                        size={"ML"}
                        weight="700"
                        text={translation({
                            sv: "Tillagda tillval",
                            en: "Added extras",
                        })}
                    />
                    <VerticalDivider S />
                </FlexColumn>
                <ExtrasOrderItemsV2
                    extras={props.extras}
                    productIdsToIgnore={[]}
                    disableActions={false}
                    onRemove={() => {
                        if (oi.length <= 1) hide();
                    }}
                />
            </FlexColumn>
        </Modal>
    );
};

export { BookableProductExtrasGroupModal };
