import { useModal } from "@@/modals/use-modal";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SectionGroup, Translatable } from "@towni/common";
// import { Gravatar } from "./gravatar";
import { OptionPickerModal } from "./option-picker-modal";

type Props = {
    sectionGroups: SectionGroup[];
    providerName: Translatable;
    currentSectionGroup: SectionGroup | undefined;
    onSelect: (sectionGroupId: string) => void;
};

const ProviderSectionGroupPicker = (props: Props) => {
    const theme = useTheme();
    const [{ show: showPicker }, modalId] = useModal("option_picker_modal__");

    if (!props.currentSectionGroup) return null;

    return (
        <>
            <FlexColumn crossAxis="center">
                <TextBox
                    text={props.providerName}
                    color={theme.colors.primary}
                    size={0.7}
                    weight="900"
                    case="UPPERCASE"
                />
                <VerticalDivider XS />
                <FlexRow onClick={showPicker}>
                    <TextBox
                        text={props.currentSectionGroup.meta.title}
                        weight="900"
                    />
                    <HorizontalDivider />
                    <FontAwesomeIcon icon={faChevronDown} />
                </FlexRow>
            </FlexColumn>
            <OptionPickerModal
                modalId={modalId}
                onSelect={props.onSelect}
                options={props.sectionGroups.map(sg => ({
                    id: sg._id,
                    name: sg.meta.title,
                }))}
            />
        </>
    );
};

export { ProviderSectionGroupPicker };
