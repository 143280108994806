import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    AcceptInvitationCommand,
    isApiError,
    support,
    translation,
} from "@towni/common";
import { invitationMainQueryKey } from "./main-query-key";

const putCommand = (command: AcceptInvitationCommand) => {
    return apiFetchClient.put<AcceptInvitationCommand>({
        route: `/invitations`,
        body: command,
    });
};

const useAcceptInviteCommand = (options?: {
    onSuccess?: () => void | Promise<void>;
    onError?: () => void | Promise<void>;
    disableNotifications?: boolean;
}) => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation<unknown, unknown, AcceptInvitationCommand>({
        mutationFn: putCommand,
        onSuccess: (_, _command) => {
            queryClient.invalidateQueries({
                queryKey: [invitationMainQueryKey],
            });
            options?.onSuccess?.();
            !options?.disableNotifications &&
                toast.success({
                    message: translation({
                        sv: `Välkommen! Du är nu en del av gänget!`,
                        en: `Welcome! You are now part of the gang!`,
                    }),
                });
        },
        onError: (error, _command) => {
            !options?.disableNotifications && isApiError(error)
                ? toast.fromError(error)
                : toast.danger({
                      message: translation({
                          sv: `Kunde inte acceptera inbjudan. Om felet kvarstår, kontakta ${support.towni.supportEmail}`,
                          en: `Could not accept invitation. If the error persists, contact ${support.towni.supportEmail}`,
                      }),
                  });
            options?.onError?.();
        },
    });

    return mutation;
};

export { useAcceptInviteCommand };
