import { AppPage } from "@@/pages/app-page";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { BackButton } from "@@/shared/buttons/back-button";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { HzSectionRaceroomGrandPrix } from "./sections/hz-section-raceroom-grand-prix";
import { HzSectionRaceroomQuickrace } from "./sections/hz-section-raceroom-quickrace";
import { HzSectionRaceroomStandardrace } from "./sections/hz-section-raceroom-standardrace";
import { OneSquareSection } from "./sections/one-square-section";

type Props = {
    title?: string;
};

const MotorstadionLidkopingRaceroomStoreFront = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>
                    {props.title ?? "Motorstadion Lidköping Raceroom"}
                </title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory(
                    "motorstadion-lidkoping-raceroom-store-front"
                )}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="space-between"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <BackButton path="/destinations/motorstadion" />
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/destination-motorstadion-raceroom-poster.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <TextBox
                        text="Raceroom"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Verklighetstrogen körsimulator och senaste mjukvaran"
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider XL />
                    <TextBox
                        text="Raceroom Quick Race"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Quick race är det minsta alternativet där varje grupp kör 30 min."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionRaceroomQuickrace />

                    <VerticalDivider XS />
                    <TextBox
                        text="Raceroom Grand Prix"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Förfinal, final och vinnaren står på pallen."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionRaceroomGrandPrix />
                    <VerticalDivider XS />

                    <TextBox
                        text="Raceroom Standard Race"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Bästa varvtid avgör startposition till finalen."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionRaceroomStandardrace />
                    <VerticalDivider XS />

                    <TextBox
                        text="Mer från motorstadion"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Komplettera med möten och raceroom för en helhetsupplevelse"
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />

                    <VerticalDivider />
                    <OneSquareSection
                        // Le mans
                        productId={"product_mdE9Ndq074AEajGfkund" as ProductId}
                        actionText={translation({
                            sv: "Utmaning",
                            en: "Challenge",
                        })}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Konferenslokal
                        productId={"product__XGRIXsOvKCd74UEUJHVp" as ProductId}
                        titleTopPlacement={true}
                        actionText={translation({
                            sv: "Boka nu",
                            en: "Book now",
                        })}
                    />
                    {/* <VerticalDivider /> */}
                    {/* <OneSquareSection
                    // Hyr hela banan
                    productId={"" as ProductId}
                    actionText={translation({
                        sv: "Boka nu",
                        en: "Book now",
                    })}
                /> */}
                    <VerticalDivider XL />

                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>
                {/* <<<< Content */}
            </AppPage>
        </WideScreenWrapper>
    );
};

export { MotorstadionLidkopingRaceroomStoreFront };
