import { ButtonDanger } from "@@/shared/buttons_v2/button-danger";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useIsTranslatableEmpty } from "@@/translations/use-translate";
import { Modal as JoyModal, ModalDialog } from "@mui/joy";
import { Translatable, translation } from "@towni/common";

// import {
//     Button, Dialog, DialogActions,
//     DialogContent, DialogContentText, DialogTitle,
//   } from '@mui/material';

import { useCallback, useMemo, useState } from "react";

type ConfirmParams = {
    title?: Translatable;
    message?: Translatable;
    theme?: "danger" | "default";
    cancelBtnText?: Translatable;
    confirmBtnText?: Translatable;
};

const defaultConfirmParams: ConfirmParams = {
    message: translation({
        sv: "Är du säker?",
        en: "Are you sure?",
    }),
    theme: "default",
};

const useConfirm = () => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const [promiseActions, setPromiseActions] = useState<{
        resolve: (value: boolean | PromiseLike<boolean>) => void;
        reject: (value: boolean | PromiseLike<boolean>) => void;
    }>();

    const [params, setParams] = useState<ConfirmParams>(() => ({
        message: translation({
            sv: "Är du säker?",
            en: "Are you sure?",
        }),
        theme: "default",
    }));

    const confirm = useCallback(
        (params: ConfirmParams = {}): Promise<boolean> =>
            new Promise<boolean>((resolve, reject) => {
                setParams({
                    ...defaultConfirmParams,
                    ...params,
                });
                setPromiseActions({ resolve, reject });
            }),
        [setPromiseActions],
    );

    const onClose = useCallback(() => {
        // closing with button or outside click
        // is the same as canceling
        promiseActions?.resolve(false);
        setPromiseActions(undefined);
    }, [promiseActions]);
    const handleConfirm = useCallback(
        (_event: React.MouseEvent) => {
            promiseActions?.resolve(true);
            setPromiseActions(undefined);
            // event.stopPropagation();
        },
        [promiseActions],
    );
    const handleCancel = useCallback(
        (_event: React.MouseEvent) => {
            promiseActions?.resolve(false);
            setPromiseActions(undefined);
        },
        [promiseActions],
    );

    const ConfirmButton = params.theme ? ButtonDanger : ButtonPrimary;

    const Confirmation = useMemo(
        () => () => {
            return (
                <JoyModal open={promiseActions !== undefined} onClose={onClose}>
                    <ModalDialog
                        variant="plain"
                        role="alertdialog"
                        css={{ padding: 0 }}>
                        <FlexColumn
                            padding={{ all: 20 }}
                            onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                            }}>
                            <Conditional
                                when={!isTranslatableEmpty(params.title)}>
                                <FlexRow>
                                    {/* <Conditional when={buttonTheme === "danger"}>
                                    <Icon
                                        icon={faWarning}
                                        color={theme.colors.danger}
                                    />
                                    <HorizontalDivider />
                                </Conditional> */}
                                    <TextBox
                                        text={params.title}
                                        css={{ opacity: 0.7 }}
                                        size={1}
                                    />
                                </FlexRow>
                                <VerticalDivider />
                            </Conditional>
                            <TextBox text={params.message} size={1} />
                            <VerticalDivider M />
                            <FlexRow mainAxis="flex-end" crossAxis="center">
                                <ButtonWhite
                                    onClick={handleCancel}
                                    padding={{
                                        leftRight: 20,
                                        topBottom: 5,
                                    }}>
                                    <TextBox
                                        text={
                                            params.cancelBtnText ?? {
                                                sv: "Avbryt",
                                                en: "Cancel",
                                            }
                                        }
                                    />
                                </ButtonWhite>
                                <HorizontalDivider />
                                <ConfirmButton
                                    onClick={handleConfirm}
                                    padding={{
                                        leftRight: 25,
                                        topBottom: 5,
                                    }}>
                                    <TextBox
                                        text={
                                            params.confirmBtnText ?? {
                                                sv: "Ja",
                                                en: "Yes",
                                            }
                                        }
                                    />
                                </ConfirmButton>
                            </FlexRow>
                        </FlexColumn>
                    </ModalDialog>
                </JoyModal>
            );
        },
        [
            promiseActions,
            isTranslatableEmpty,
            params.title,
            params.message,
            params.cancelBtnText,
            params.confirmBtnText,
            handleCancel,
            ConfirmButton,
            handleConfirm,
            onClose,
        ],
    );

    return useMemo(
        () => [Confirmation, confirm] as const,
        [Confirmation, confirm],
    );
};

export default useConfirm;
