import {
    Cart,
    DeliveryMethodType,
    DeliveryZone,
    generateId,
    Price,
    Shipping,
    ShippingId,
    Time,
    TimeSpot,
    UserAddress,
} from "@towni/common";
import * as React from "react";
import { useCart } from "../multi-carts/cart.context";

type State = {
    readonly _id: string;
    readonly _type: "DELIVERY_STATE";
    readonly selected: DeliveryMethodType;
    readonly homeDelivery: HomeDeliveryState;
    readonly pickupState: PickupState;
};

type PickupState = {
    readonly slot?: TimeSpot;
};

type HomeDeliveryState = {
    readonly slot?: Time;
    readonly deliveryAddress?: UserAddress;
    readonly zone?: DeliveryZone;
    readonly shippingId?: ShippingId;
    readonly history: HomeDeliveryState[];
    readonly price?: Price;
};

const createInitialState = (cart: Cart): State => {
    switch (cart.delivery.method) {
        case "HOME_DELIVERY":
            return {
                _id: generateId({ prefix: "delivery_state__" }),
                _type: "DELIVERY_STATE",
                selected: "HOME_DELIVERY",
                pickupState: { slot: undefined },
                homeDelivery: {
                    deliveryAddress: cart.delivery.destination,
                    slot: cart.delivery.slot,
                    shippingId: cart.delivery.shipping,
                    zone: cart.delivery.zone,
                    price: cart.delivery.price,
                    history: [],
                },
            };
        case "PICK_UP":
            return {
                _id: generateId({ prefix: "delivery_state__" }),
                _type: "DELIVERY_STATE",
                selected: "PICK_UP",
                pickupState: { slot: cart.delivery.slot },
                homeDelivery: {
                    deliveryAddress: undefined,
                    slot: undefined,
                    history: [],
                },
            };
            break;

        default:
            break;
    }
    return {
        _id: generateId({ prefix: "delivery_state__" }),
        _type: "DELIVERY_STATE",
        selected: "PICK_UP",
        pickupState: { slot: undefined },
        homeDelivery: {
            deliveryAddress: undefined,
            slot: undefined,
            history: [],
        },
    };
};

type Action =
    | {
          type: "SET_DELIVERY_METHOD";
          method: DeliveryMethodType;
      }
    | {
          type: "SET_DELIVERY_ADDRESS";
          userAddress: UserAddress;
      }
    | {
          type: "SET_TIME_SLOT";
          slot?: TimeSpot;
      }
    | {
          type: "SET_HOME_DELIVERY_SLOT";
          slot: Time | undefined;
          zone: DeliveryZone;
          shipping: Shipping;
          price: Price;
      }
    | {
          type: "CLEAR_HOME_DELIVERY";
      }
    | {
          type: "SET_DELIVERY_INSTRUCTIONS";
      }
    | { type: "RESET_STATE"; cart: Cart };

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_DELIVERY_METHOD": {
            return {
                ...state,
                selected: action.method,
            };
        }
        case "SET_DELIVERY_ADDRESS": {
            if (state.selected !== "HOME_DELIVERY") return state;
            if (
                state.homeDelivery.deliveryAddress?._id ===
                action.userAddress._id
            )
                return {
                    ...state,
                    homeDelivery: {
                        ...state.homeDelivery,
                        deliveryAddress: action.userAddress,
                    },
                };

            const prevValue = state.homeDelivery.history.find(
                f => f.deliveryAddress?._id === action.userAddress._id
            );
            const hist = state.homeDelivery.history
                .filter(
                    h =>
                        h.deliveryAddress?._id !==
                        state.homeDelivery.deliveryAddress?._id
                )
                .filter(f => f.deliveryAddress !== undefined);

            return {
                ...state,
                homeDelivery: {
                    ...state.homeDelivery,
                    deliveryAddress: action.userAddress,
                    shippingId: prevValue?.shippingId,
                    zone: prevValue?.zone,
                    slot: prevValue?.slot,
                    history: [...hist, state.homeDelivery],
                },
            };
        }
        case "SET_TIME_SLOT": {
            if (state.selected === "HOME_DELIVERY") return state;
            return {
                ...state,
                pickupState: {
                    ...state.pickupState,
                    slot: action.slot,
                },
            };
        }
        case "SET_HOME_DELIVERY_SLOT": {
            if (state.selected === "PICK_UP") return state;
            return {
                ...state,
                homeDelivery: {
                    ...state.homeDelivery,
                    slot: action.slot,
                    zone: action.zone,
                    shippingId: action.shipping._id,
                    price: action.price,
                },
            };
        }
        case "CLEAR_HOME_DELIVERY": {
            if (state.selected === "PICK_UP") return state;
            return {
                ...state,
                homeDelivery: {
                    ...state.homeDelivery,

                    slot: undefined,
                    zone: undefined,
                    shippingId: undefined,
                    history: [],
                },
            };
        }
        case "RESET_STATE": {
            return createInitialState(action.cart);
        }

        default:
            return state;
    }
};

const DeliveryStateContext = React.createContext<State | undefined>(undefined);
const DeliveryDispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

const DeliveryProvider = ({
    children,
}: {
    readonly children?: React.ReactNode;
}) => {
    const cart = useCart();
    const [state, dispatch] = React.useReducer(
        reducer,
        createInitialState(cart)
    );

    return (
        <DeliveryStateContext.Provider value={state}>
            <DeliveryDispatchContext.Provider value={dispatch}>
                {children}
            </DeliveryDispatchContext.Provider>
        </DeliveryStateContext.Provider>
    );
};

const useDeliveryState = () => {
    const context = React.useContext(DeliveryStateContext);
    if (context === undefined) {
        throw new Error(
            "useDeliveryState must be used within a DeliveryProvider"
        );
    }
    return context;
};

const useDeliveryDispatch = () => {
    const dispatch = React.useContext(DeliveryDispatchContext);
    if (dispatch === undefined) {
        throw new Error(
            "useDeliveryDispatch must be used within a DeliveryProvider"
        );
    }
    const setMethod = (method: DeliveryMethodType) => {
        dispatch({
            type: "SET_DELIVERY_METHOD",
            method,
        });
    };

    const setPickupSlot = (slot?: TimeSpot) => {
        dispatch({
            type: "SET_TIME_SLOT",
            slot,
        });
    };

    const setAddress = (userAddress: UserAddress) => {
        dispatch({
            type: "SET_DELIVERY_ADDRESS",
            userAddress,
        });
    };
    const setHomeDelivery = (
        slot: Time | undefined,
        zone: DeliveryZone,
        shipping: Shipping,
        price: Price
    ) => {
        dispatch({
            type: "SET_HOME_DELIVERY_SLOT",
            slot,
            zone,
            shipping,
            price,
        });
    };

    const clearHomeDelivery = () => {
        dispatch({
            type: "CLEAR_HOME_DELIVERY",
        });
    };

    const reset = (cart: Cart) => {
        dispatch({
            type: "RESET_STATE",
            cart: cart,
        });
    };
    return {
        setMethod,
        setPickupSlot,
        setAddress,
        setHomeDelivery,
        clearHomeDelivery,
        reset,
    };
};

export { DeliveryProvider, useDeliveryDispatch, useDeliveryState };
