import { VerticalDivider } from "@@/shared/dividers";
import { Join } from "@@/shared/join";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Provider } from "@towni/common";
import { FC } from "react";
import { ProviderCard } from "./provider-card";

type Props = {
    providers: Provider[];
    isLoading: boolean;
};

const ProviderCardVerticalSection: FC<Props> = ({ isLoading, providers }) => {
    const theme = useTheme();
    return (
        <Join divider={<VerticalDivider />}>
            {isLoading
                ? [
                      <FontAwesomeIcon
                          key="spinner"
                          icon={faSpinnerThird}
                          color={theme.colors.disabled.text.asString}
                          spin
                          size="5x"
                      />,
                  ]
                : providers.map(provider => {
                      return (
                          <ProviderCard
                              aspectRatio="3:2"
                              key={provider._id}
                              provider={provider}
                          />
                      );
                  })}
        </Join>
    );
};

export { ProviderCardVerticalSection };
