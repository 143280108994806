import { useTheme } from "@emotion/react";
import { TextBox, TextBoxProps } from "./text-box";

const ModalTitle = (props: TextBoxProps) => {
    const theme = useTheme();
    return (
        <TextBox
            align="center"
            size={1.125}
            weight="700"
            color={theme.colors.default.text}
            {...props}
            lineClamp={2}
            shrink={1}
            grow={0}
        />
    );
};

export { ModalTitle };
