import { apiFetchClient } from "@@/shared/fetch-client";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { GetResponse, Provider, ProviderIdOrSlug } from "@towni/common";
import { useMemo } from "react";

const mainQueryKey = "providers";

const useProvider = (
    providerIdOrSlug: ProviderIdOrSlug | undefined,
): readonly [Provider | undefined, UseQueryResult<Provider, Error>] => {
    const queryResult = useQuery<Provider>({
        enabled: !!providerIdOrSlug,
        queryKey: [mainQueryKey, providerIdOrSlug],
        queryFn: async () => {
            const result = await apiFetchClient.get<GetResponse<Provider>>({
                route: `/providers/${encodeURIComponent(
                    providerIdOrSlug ?? "",
                )}`,
            });
            return result?.items?.[0] || null;
        },
    });
    const output = useMemo(() => {
        const output = [queryResult.data || undefined, queryResult] as const;
        return output;
    }, [queryResult]);

    return output;
};

export { useProvider };
