import { useApiResources } from "@@/shared/state/use-api-resources";
import {
    Branded,
    BulkPurchaseGroupId,
    OrderGroupId,
    OrderId,
    ProviderId,
    Ticket,
} from "@towni/common";
import {
    fetchTicketsForBulkGroup,
    fetchTicketsForProvider,
    fetchTicketsForSearch,
} from "./ticket.fetcher";

export const ticketMainQueryKey = "tickets";

type _TicketShortLink = `https://${string}`;
type _TicketCode = Branded<string>;
type _TicketRef =
    | OrderId
    | OrderGroupId
    | _TicketShortLink
    | _TicketCode
    | string;

const useTicketsForSearch = (search: _TicketRef | undefined) =>
    useApiResources<Ticket>(
        [ticketMainQueryKey, search],
        fetchTicketsForSearch(search),
        {
            enabled: !!search,
        }
    );

const useTicketSearchForProvider = (
    providerId: ProviderId,
    search: _TicketRef | undefined,
    scannerId: string | undefined
) =>
    useApiResources<Ticket>(
        [ticketMainQueryKey, search],
        fetchTicketsForProvider(providerId, search, scannerId),
        {
            enabled: !!search,
            refetchOnWindowFocus: false,
        }
    );
const useTicketForBulk = (bulk: BulkPurchaseGroupId) =>
    useApiResources<Ticket>(
        [ticketMainQueryKey, bulk],
        fetchTicketsForBulkGroup(bulk),
        {
            enabled: !!bulk,
            refetchOnWindowFocus: false,
        }
    );

const useTicketSearchForScanner = (
    providerId: ProviderId,
    search: _TicketRef | undefined,
    scannerId: string | undefined
) =>
    useApiResources<Ticket>(
        [search],
        fetchTicketsForProvider(providerId, search, scannerId),
        {
            enabled: !!search,
            refetchOnWindowFocus: false,
        }
    );

export {
    useTicketsForSearch,
    useTicketSearchForProvider,
    useTicketSearchForScanner,
    useTicketForBulk,
};
