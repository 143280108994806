import { SafeParseReturnType, ZodObject, ZodSchema, z } from "zod";

import { browserLogger } from "@@/settings";
import type { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormFieldIds, useFormId } from "@@/shared/form/form-id.context";
import {
    useFormStore,
    type FormWorkingCopyActions,
} from "@@/shared/form/form.zustand-store";
import { SchemaGetter } from "@@/shared/form/use-form-field-validation";
import { ZodObjectOf, emptyArrayOf, parseSafely } from "@towni/common";
import type { Draft } from "immer";
import { useMemo, useRef, useState } from "react";
import { useShallow } from "zustand/react/shallow";

const getChildSchemas = <State extends Record<string, unknown>>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: ZodObjectOf<State>,
    depth = 0,
): Partial<State> => {
    if (depth > 500) throw new Error("Depth limit reached");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {};
    for (const key in schema.shape) {
        const value = schema.shape[key];
        if (value && "shape" in value) {
            result[key] = getChildSchemas(value, depth + 1);
        } else {
            result[key] = value;
        }
    }
    return result;
};

const getFieldSchema = <ValueForSchema, State extends Record<string, unknown>>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rootSchema: ZodObjectOf<State>,
    getter: SchemaGetter<State, ValueForSchema>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ZodObject<any, any, any, ValueForSchema> | undefined => {
    const schemaObject = getChildSchemas<State>(rootSchema);
    try {
        const fieldSchema = getter(schemaObject);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return fieldSchema as ZodObject<any, any, any, ValueForSchema>;
    } catch (error) {
        browserLogger.error(error);
        throw Error(
            "Can't parse field schema from getter function, probably doing something funky in the getter. Try providing a specific getter for the schema as well.",
        );
    }
};

const useFormField = <State extends Record<string, unknown>, Value>(params: {
    readonly formId?: FormId;
    readonly fieldId: FieldId;
    readonly getter: (state: Partial<State>) => Value;
    readonly fieldSchema?: ZodSchema;
    readonly setter: (draft: Draft<Partial<State>>, newValue: Value) => void;
}) => {
    const { fieldId, getter } = params;
    const contextFormId = useFormId({
        doNotThrow: true,
    });
    const formId = params.formId || contextFormId;
    if (!formId)
        throw new Error("Form id not set and no form id provider is available");
    const fieldIds = useFormFieldIds(params.formId);
    if (!fieldIds?.has(fieldId)) {
        fieldIds?.add(fieldId);
    }

    const getterRef = useRef<typeof params.getter | null>(null);
    getterRef.current = params.getter;
    const setterRef = useRef<typeof params.setter | null>(null);
    setterRef.current = params.setter;

    const formSchema = useFormStore(
        store => store.getForm<State>(formId)?.formSchema,
    );
    const fieldSchema = useMemo(() => {
        if (params.fieldSchema) return params.fieldSchema;
        if (!formSchema) return undefined;
        if (getterRef.current)
            return getFieldSchema<Value | undefined | null, State>(
                formSchema,
                getterRef.current,
            );
        return undefined;
    }, [formSchema, params.fieldSchema]);

    const fieldSchemaMeta = useMemo(() => {
        const isArraySchema = (() => {
            if (!fieldSchema) return false;
            if (!fieldSchema._def) return false;
            if (
                "typeName" in fieldSchema._def &&
                fieldSchema._def.typeName === "ZodArray"
            )
                return true;
            return false;
        })();
        const ifArrayCanBeEmpty =
            isArraySchema &&
            (() => {
                if (!fieldSchema) return true;
                if (!fieldSchema._def) return true;
                const minValue = parseSafely({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value: (fieldSchema._def as any)?.minLength?.value,
                    schema: z.number(),
                });
                return typeof minValue === "undefined" || minValue === 0;
            })();
        const isOptional = !!fieldSchema?.isOptional?.();
        const isNullable = !!fieldSchema?.isNullable?.();
        const isRequired = !isOptional && !isNullable && !ifArrayCanBeEmpty;

        return {
            isRequired,
            isNullable,
            isOptional,
            isArraySchema,
            ifArrayCanBeEmpty,
        };
    }, [fieldSchema]);

    const output = useFormStore(
        useShallow(store => {
            return {
                formId,
                fieldId,
                setDirty: store.setDirty(formId)(fieldId),
                value: store.getFieldValue<State>(formId, fieldId)(getter),
                dirty: Boolean(
                    store.getForm<State>(formId)?.dirty.get(fieldId),
                ),
                touched: Boolean(
                    store.getForm<State>(formId)?.touched.get(fieldId),
                ),
                isSubmitting: Boolean(
                    store.getForm<State>(formId)?.isSubmitting,
                ),
                errors:
                    store.getForm<State>(formId)?.errors.get(fieldId) ??
                    emptyArrayOf<string>(),
                setValue: (newValue: Value) => {
                    store.updateForm<State>(formId)(
                        (_draft: Draft<Partial<State>>) => {
                            params.setter(_draft, newValue);
                        },
                    );
                },
                setErrors: store.setErrors(formId)(fieldId),
                setTouched: store.setTouched(formId)(fieldId),
                formSchema,
                fieldSchema,
                getter: getterRef.current,
                setter: setterRef.current,
                ...fieldSchemaMeta,
            };
        }),
    );

    const [initialValue] = useState(output.value);

    return useMemo(
        () => ({
            ...output,
            initialValue,
        }),
        [initialValue, output],
    );
};

type FormField = ReturnType<typeof useFormField>;

const useFormFieldActions = <State extends Record<string, unknown>>(
    formId: FormId | undefined,
) => {
    const formStore = useFormStore();
    const result = useMemo(
        (): FormWorkingCopyActions<State> | undefined =>
            formId
                ? {
                      setFieldValue: formStore.updateForm<State>(formId),
                      setTouched: formStore.setTouched(formId),
                      setDirty: formStore.setDirty(formId),
                      setErrors: formStore.setErrors(formId),
                      setIsSubmitting: formStore.setIsSubmitting(formId),
                      deleteForm: () => formStore.deleteForm(formId),
                      parse: () => {
                          const form = formStore.getForm<State>(formId);
                          if (!form)
                              throw new Error(`Form "${formId}" not found`);
                          return form.formSchema.parse(form.state);
                      },
                      safeParse: (): SafeParseReturnType<
                          Partial<State>,
                          State
                      > => {
                          const form = formStore.getForm<State>(formId);
                          if (!form)
                              throw new Error(`Form "${formId}" not found`);
                          return form.formSchema.safeParse(form.state);
                      },
                      resetForm: formStore.resetForm<State>(formId),
                      parseSafely: () => {
                          const form = formStore.getForm<State>(formId);
                          if (!form) return undefined;
                          return parseSafely({
                              schema: form.formSchema,
                              value: form.state,
                          });
                      },
                  }
                : undefined,
        [formId, formStore],
    );
    if (!formId) return undefined;
    return result;
};

export { useFormField, useFormFieldActions };
export type { FormField };
