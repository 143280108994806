import { browserLogger } from "@@/settings";
import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunctionContext } from "@tanstack/react-query";
import { DAYS, GetSingleResponse, HOURS, Me } from "@towni/common";

const meQuery = {
    queryKey: ["me"],
    initialData: undefined,
    retry: false,
    staleTime: 1 * HOURS,
    gcTime: 3 * DAYS,
    queryFn: async ({ signal }: QueryFunctionContext) => {
        try {
            const response = await apiFetchClient.get<GetSingleResponse<Me>>({
                route: `/authentications/me`,
                customConfig: {
                    signal,
                },
            });
            return response.item;
        } catch (error) {
            browserLogger.warn(error);
            return null;
        }
    },
};

export { meQuery };
