import { TextShimmer } from "@@/shared/pictures/shimmers";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, useTheme } from "@emotion/react";
import { Percentage, Translatable } from "@towni/common";
import { MouseEventHandler } from "react";
import { Except } from "type-fest";

type ParagraphText = {
    _type: "PARAGRAPH_TEXT";
    text: Translatable | undefined;
    textWhenEmpty?: Translatable;
    onClick?: MouseEventHandler<HTMLSpanElement>;
    disabled?: boolean;
    css?: Interpolation<AppTheme>;
    spin?: boolean;
    spinWidth?: number;
    spinOpacity?: Percentage;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphText = (item: any): item is ParagraphText => {
    return item?._type === "PARAGRAPH_TEXT";
};

const paragraphTextFactory = (
    params: Except<ParagraphText, "_type">,
): ParagraphText => {
    return {
        _type: "PARAGRAPH_TEXT",
        ...params,
    };
};

const ParagraphText = (props: { text: ParagraphText; className?: string }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const linkColor = theme.colors.primary.asString;
    const textContent =
        translate(props.text.text) || translate(props.text.textWhenEmpty);
    const onClick = props.text.disabled ? undefined : props.text.onClick;
    const styles = [
        { display: "inline-block" },
        ...(onClick
            ? [
                  {
                      color: linkColor,
                      ":hover,:visited,:focus,:active": {
                          color: linkColor,
                          textDecoration: "none",
                      },
                      ":hover": {
                          color: theme.colors.secondary.asString,
                          textDecoration: "underline",
                      },
                      cursor: "pointer",
                  },
              ]
            : []),
        props.text.css,
    ];
    return props.text.spin ? (
        <span
            css={{
                display: "inline-block",
                float: "left",
                width: props.text.spinWidth ?? 100,
                clear: "both",
                marginTop: 3,
                opacity: props.text.spinOpacity ?? 1,
            }}>
            <TextShimmer rows={1} />
        </span>
    ) : (
        <span className={props.className} css={styles} onClick={onClick}>
            {textContent}
        </span>
    );
};

export { ParagraphText, isParagraphText, paragraphTextFactory };
