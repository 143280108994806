import {
    accessKeyIdZodSchema,
    isPageId,
    PageId,
    PageIdOrSlug,
    parseSafely,
    ProviderId,
} from "@towni/common";
import { useParams, useSearchParams } from "react-router-dom";
import { usePageById, usePageForProvider } from "./use-page";

/**
 * Fetched page id from a react-router Route url parameter \
 * Checks for following url params: \
 * `pageId` \
 *
 * @returns
 */
const usePageIdFromUrl = (options?: { throwOnInvalidPageId?: boolean }) => {
    const urlParams = useParams<{ pageId?: PageId }>();
    const pageIdOrSlug = urlParams.pageId;
    const [searchParams] = useSearchParams();
    const accessKeyId = parseSafely({
        schema: accessKeyIdZodSchema,
        value: searchParams.get("preview"),
    });

    if (!isPageId(urlParams.pageId) && options?.throwOnInvalidPageId) {
        throw new Error("Missing or invalid page id; " + urlParams.pageId);
    }

    return usePageById(pageIdOrSlug, accessKeyId);
};

/**
 * Fetched page id from a react-router Route url parameter \
 * Checks for following url params: \
 * `pageId` \
 *
 * @returns
 */
const usePageForProviderFromUrl = (options?: {
    /** Uses given provider id instead of trying to read it from URL */
    providerId?: ProviderId;
}) => {
    const urlParams = useParams<{
        pageId?: PageId;
        providerIdOrSlug?: string;
        pageIdOrSlug?: PageIdOrSlug;
    }>();
    const [searchParams] = useSearchParams();
    const accessKeyId = parseSafely({
        schema: accessKeyIdZodSchema,
        value: searchParams.get("preview"),
    });

    const providerIdOrSlug = options?.providerId ?? urlParams.providerIdOrSlug;
    const pageIdOrSlug = urlParams.pageId ?? urlParams.pageIdOrSlug;

    return usePageForProvider(providerIdOrSlug, pageIdOrSlug, accessKeyId);
};

export { usePageIdFromUrl as usePageForIdFromUrl, usePageForProviderFromUrl };
