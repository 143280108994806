import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { kronocampingColors } from "@@/store-fronts/kronocamping/kronocamping-color-context";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ResponsiveStoreFrontHzProductCard } from "@@/store-fronts/shared/store-front-hz-product-card";
import { useHzCardSize } from "@@/store-fronts/use-hz-card-size";
import { ProductId, RemSize, translation } from "@towni/common";
import * as React from "react";
import { FC } from "react";

const KronocampingExerciseMindSectionOnTowni: FC = () => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const cardSize = useHzCardSize(1.35 as RemSize);
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            // Core & Balance
            getProductId("product_asSqY7EHtzhMw8rHBRgf" as ProductId),
            // Utomhus Yoga
            getProductId("product_QPlrDRjoZzFoVvZCZ04V" as ProductId),
            // Semester träning
            getProductId("product_5AtASLvkOCGyjYBklh7J" as ProductId),
        ],
        []
    );
    return (
        <SectionArea
            sectionAreaId="kronocamping-exercise-mind"
            title="Mer träning för sinnet"
            subtitle="Att få en stund för sig själv och låta sin kropp få andas och röra sig stärker semesterupplevelsen"
            background={{
                color: kronocampingColors.lightBackground,
            }}
            css={{
                borderRadius: isDesktop ? 10 : 0,
            }}
            padding={isDesktop ? { all: 10 } : { topBottom: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                <ResponsiveStoreFrontHzProductCard
                    cardSize={cardSize}
                    productId={productIds[0]}
                    actionText={text.explore}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                    hideKicker
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={cardSize}
                    productId={productIds[1]}
                    actionText={text.explore}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                    hideKicker
                />
                <ResponsiveStoreFrontHzProductCard
                    cardSize={cardSize}
                    productId={productIds[2]}
                    actionText={text.train}
                    aspectRatio="3:4"
                    subtitleSize={0.93 as RemSize}
                    hideKicker
                />
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

const text = {
    explore: translation({
        sv: "Utforska",
        en: "Explore",
    }),
    train: translation({
        sv: "Träna",
        en: "Explore",
    }),
};

export { KronocampingExerciseMindSectionOnTowni };
