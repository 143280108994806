import { OptionalGroupId, PickedOptional } from "@towni/common";

const _mapPickedOptionalsByOptionalGroupId =
    (pickedOptionals: PickedOptional[]) => () => {
        const result = pickedOptionals.reduce((result, pickedOptional) => {
            const optionalGroupId = pickedOptional.optional
                .optionalGroupId as OptionalGroupId;
            const current = result.get(optionalGroupId) ?? [];
            result.set(optionalGroupId, [...current, pickedOptional]);
            return result;
        }, new Map<OptionalGroupId, PickedOptional[]>());
        return result ?? new Map<OptionalGroupId, PickedOptional[]>();
    };

export { _mapPickedOptionalsByOptionalGroupId };
