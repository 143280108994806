import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { SelectBox } from "@@/shared/select-box";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { AccommodationSubType } from "@towni/common";

type SubProps = {
    option: AccommodationSubType;
    selected: boolean;
    toggle: () => void;
};

const SelectAccommodationSubType = (props: SubProps) => {
    const theme = useTheme();
    const { toggle, selected, option } = props;

    return (
        <FlexRow
            onClick={toggle}
            mainAxis="space-between"
            crossAxis="center"
            css={[
                {
                    padding: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                ...(selected
                    ? [
                          {
                              backgroundColor: theme.isLightTheme
                                  ? theme.colors.primary.light.asString
                                  : theme.colors.primary.dark.asString,
                          },
                      ]
                    : [{}]),
            ]}>
            <FlexRow crossAxis="center">
                <SelectBox selected={selected} shape={"CIRCLE"} size="L" />
                <HorizontalDivider M />
                <DynamicIcon
                    icon={option.iconName}
                    title={option.title}
                    css={{ color: theme.colors.black.light50.asString }}
                    size={1}
                    fixedWidth
                />
                <HorizontalDivider XS />
                <TextBox
                    text={option.title}
                    css={{
                        flexShrink: 0,
                    }}
                />
            </FlexRow>
        </FlexRow>
    );
};

export { SelectAccommodationSubType };
