import { Form } from "@@/shared/form/form.zustand-store";
import { useDebouncedState } from "@@/shared/use-debounced-state";
import type { AppTheme } from "@@/styles/theme";
import type { Interpolation } from "@emotion/react";
import {
    MILLISECONDS,
    phoneNumberZodSchema,
    type ColorItem,
    type Padding,
    type PhoneNumber,
    type RemSize,
    type Translatable,
} from "@towni/common";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useEffect, useRef } from "react";
import { z } from "zod";
type Change =
    | {
          phoneNumber: PhoneNumber;
          isValid: true;
      }
    | {
          phoneNumber: PhoneNumber | undefined;
          isValid: false;
      };
type Props = {
    readonly fillParentWidth?: boolean;
    readonly initialPhoneNumber?: PhoneNumber;
    readonly onChange: (change: Change) => void;
    readonly placeholder?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    readonly fontSize?: RemSize;
    readonly disabled?: boolean;
    readonly hideIcon?: boolean;
    readonly hideErrors?: boolean;
    readonly spin?: boolean;
    /** Visar fältet som required, även om det inte krävs enligt zod schemat. */
    readonly showAsRequired?: boolean;
    readonly onErrorsUpdate?: (errors: string[]) => void;

    // Css properties
    readonly autoComplete?: string;
    readonly padding?: Padding;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
};

const PhoneNumberInput = (props: Props) => {
    const { initialPhoneNumber, onChange, containerCss, ...passOnProps } =
        props;
    const [value, setValue] = useDebouncedState(
        () => initialPhoneNumber,
        300 as MILLISECONDS,
    );
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    useEffect(() => {
        const parsedPhoneNumber = parsePhoneNumber(value ?? "");
        const output = parsedPhoneNumber.valid
            ? (parsedPhoneNumber.number.e164 as PhoneNumber)
            : value;
        const valid = parsedPhoneNumber.valid && !!output;
        onChangeRef.current(
            valid
                ? {
                      phoneNumber: output,
                      isValid: true,
                  }
                : {
                      phoneNumber: undefined,
                      isValid: false,
                  },
        );
    }, [value]);

    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        phoneNumber: phoneNumberZodSchema,
                    }),
                    initialState: {
                        phoneNumber: initialPhoneNumber,
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.PhoneNumberInput
                    fieldId="phoneNumber"
                    getter={data => data.phoneNumber}
                    setter={(draft, newValue) => {
                        draft.phoneNumber = newValue;
                        setValue(newValue);
                    }}
                    containerCss={[
                        ...(props.fillParentWidth ? [{ width: "100%" }] : []),
                        ...(containerCss ? [containerCss] : []),
                    ]}
                    {...passOnProps}
                />
            )}
        </Form>
    );
};

export { PhoneNumberInput };
