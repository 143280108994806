import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    emptyArrayOf,
    findGraphInGraph,
    FlattenedResourceGraphItem,
    flattenGraphKeepParents,
    Resource,
    ResourceGraph,
    ResourceId,
} from "@towni/common";
import { useMemo } from "react";
import { useBookingContext } from "../../booking-context";
import { useCheckBookableSession } from "../../hooks/use-check-bookable-session";

const useAvailableResourcesForProductAndSession = () => {
    const state = useBookingContext(context => ({
        product: context.product,
        session: context.session,
        sessions: context.sessions,
        timeRange: context.timeRange,
    }));

    // Finds resource graph for product
    const [productResourceGraphs, { isPending: isPendingResources }] =
        useBookableResources(state.product._id);

    // Load session resource availability data
    const [checkSession, isPendingResourceAvailability] =
        useCheckBookableSession();

    const resourcesAvailable = useMemo(() => {
        // Find availability for current session
        const sessionResourceAvailability = state.session
            ? checkSession(state.session, state.timeRange)
            : undefined;

        // Return available resources for current session
        return sessionResourceAvailability?.resourceAvailabilities.filter(
            item => item.available,
        );
    }, [checkSession, state.session, state.timeRange]);
    // Find available resource graphs for product
    const resourceGraphsWithAvailableResources = useMemo(() => {
        if (!resourcesAvailable?.length) return emptyArrayOf<ResourceGraph>();

        // Filter out graphs that are available
        const resourceGraphsAvailable = productResourceGraphs.filter(graph => {
            for (const resourceAvailable of resourcesAvailable) {
                const result = findGraphInGraph(
                    graph,
                    resourceAvailable.resourceId,
                );
                if (result) return true;
            }
            return false;
        });

        return resourceGraphsAvailable;
    }, [productResourceGraphs, resourcesAvailable]);

    // Flatten all resources in available graphs
    const flattenedAvailableResources =
        useMemo((): FlattenedResourceGraphItem[] => {
            if (!resourceGraphsWithAvailableResources?.length)
                return emptyArrayOf<FlattenedResourceGraphItem>();
            return resourceGraphsWithAvailableResources?.flatMap(graph => {
                if (graph) return flattenGraphKeepParents(graph);
                return emptyArrayOf<FlattenedResourceGraphItem>();
            });
        }, [resourceGraphsWithAvailableResources]);

    // Sort resources by resource group ids
    const availableResourcesByParent = useMemo((): Map<
        ResourceId,
        Resource[]
    > => {
        const byParent = new Map<ResourceId, Resource[]>();
        if (!flattenedAvailableResources) return byParent;
        const parentIds = flattenedAvailableResources.flatMap(
            item => item.parentIds,
        );
        for (const parentId of parentIds) {
            byParent.set(
                parentId,
                flattenedAvailableResources
                    .filter(item => item.parentIds.includes(parentId))
                    .filter(item =>
                        resourcesAvailable
                            ?.flatMap(ra => ra.availableSubResources)
                            ?.some(ra => ra._id === item.resource._id),
                    )
                    .map(item => item.resource),
            );
        }

        return byParent;
    }, [flattenedAvailableResources, resourcesAvailable]);

    const isPending = isPendingResources || isPendingResourceAvailability;

    return [availableResourcesByParent, isPending] as const;
};
export { useAvailableResourcesForProductAndSession };
