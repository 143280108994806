import { useAppSettings } from "@@/settings/app-settings-context";
import { ProductId, randomInt } from "@towni/common";

export const localFallbackProductIds = [
    "product_9hKNjFR9h_CJ4z6OV7Sc", // jakob
    //"product_XwcfwcB9gVetSxl3F8ue", // emanuel
    //"product_AsCF9XwGJSaNU6yqu7Sa", // emanuel
] as ProductId[];
export const sandboxFallbackProductIds = [
    "product_pj8Hd_WE2vpxYE4tAcgV",
    "product_1fnFV7pcd7epSuVfGVoy",
    "product_faFDuKoESzSisOGROlIF",
    "product_VegGTM5zdvqr0LHSTQik",
    "product_jzxa6M4zbvI34fiPieGA",
    "product_ANQw9sjuAPUMBPt4KYMO",
] as ProductId[];

const getRandomLocalFallackProductId = () => {
    const randomIndex = randomInt({
        min: 0,
        max: localFallbackProductIds.length - 1,
    });
    return localFallbackProductIds[randomIndex];
};
const getRandomSandboxFallackProductId = () => {
    const randomIndex = randomInt({
        min: 0,
        max: sandboxFallbackProductIds.length - 1,
    });
    return sandboxFallbackProductIds[randomIndex];
};

const useProductIdWithFallback = () => {
    const { isProdEnv, isSandboxEnv } = useAppSettings();

    return (productId: ProductId): ProductId => {
        return isProdEnv
            ? productId
            : isSandboxEnv
            ? getRandomSandboxFallackProductId()
            : getRandomLocalFallackProductId();
    };
};

export {
    useProductIdWithFallback,
    getRandomLocalFallackProductId,
    getRandomSandboxFallackProductId,
};
