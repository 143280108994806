import { apiFetchClient } from "@@/shared/fetch-client";
import { useSingleApiResource } from "@@/shared/state/use-api-resource";
import { createQueryKey } from "@@/shared/state/use-api-resources";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation } from "@tanstack/react-query";
import {
    DiscountCode,
    DiscountCodeId,
    GetSingleResponse,
    ProviderId,
    isApiError,
    translation,
} from "@towni/common";

const mainQueryKey = "discount-codes";

const fetch = (providerId: ProviderId, id: string) => async () => {
    const result = await apiFetchClient.get<GetSingleResponse<DiscountCode>>({
        route: `/discounts/codes/for-provider/${encodeURIComponent(
            providerId ?? "",
        )}/check/${encodeURIComponent(id ?? "")}`,
    });
    return result.item;
};

type Params = { providerId: ProviderId; id: string };
const fetch2 = async (params: Params) => {
    const result = await apiFetchClient.get<GetSingleResponse<DiscountCode>>({
        route: `/discounts/codes/for-provider/${encodeURIComponent(
            params.providerId ?? "",
        )}/check/${encodeURIComponent(params.id ?? "")}`,
    });
    return result.item;
};

const useDiscountCodeCheck = (
    providerId: ProviderId | undefined,
    codeId: string | undefined,
) => {
    const id = codeId ?? ("" as DiscountCodeId);
    const pid = providerId ?? ("" as ProviderId);

    return useSingleApiResource<DiscountCode>(
        createQueryKey(mainQueryKey, id),
        fetch(pid, id),
        {
            enabled: !!codeId,
        },
    );
};

const useDiscountCodeCheckMutation = () => {
    const toast = useToast();
    return useMutation<DiscountCode, Error, Params>({
        mutationFn: fetch2,
        onError: (error, _command, _previous) => {
            if (isApiError(error)) {
                toast.warning({
                    message: translation({
                        sv: "Ogiltig rabattkod: " + _command.id,
                        en: "Invalid discount code: " + _command.id,
                    }),
                });
            } else {
                throw error;
            }
        },
    });
};

export { useDiscountCodeCheck, useDiscountCodeCheckMutation };
