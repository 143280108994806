import { darkeningGradientReverse } from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTheme } from "@emotion/react";
import { RemSize, translation } from "@towni/common";
import { Link } from "react-router-dom";

// type Props = {};
const OneSquareLinkSectionVara = () => {
    const theme = useTheme();
    const imageUrl =
        "https://we-are-alfred.imgix.net/destinations/vara-destination.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges";

    return (
        <SectionArea
            sectionAreaId="stay-the-night-section"
            padding={{ top: 10 }}>
            <Link to={`/destinations/smart-resande-vara`}>
                <FlexColumn
                    fillParentWidth
                    padding={{ leftRight: 20 }}
                    crossAxis="flex-start"
                    shrink={0}>
                    <SectionAreaCard
                        aspectRatio="16:9"
                        radius={16}
                        background={{
                            fit: "COVER",
                            imageUrl,
                        }}>
                        <FlexColumn
                            shrink={0}
                            grow={1}
                            padding={{ all: 20 }}
                            background={{
                                linearGradient: darkeningGradientReverse,
                            }}
                            mainAxis="space-between"
                            crossAxis="center">
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={translation({
                                        sv: "Utforska",
                                        en: "Explore",
                                    })}
                                />
                            </FlexRow>
                            <FlexColumn fillParentWidth shrink={0}>
                                <TextBox
                                    text={translation({
                                        sv: "Utforska Vara",
                                        en: "See Vara",
                                    })}
                                    weight="900"
                                    size={2 as RemSize}
                                    color={theme.colors.white}
                                    css={{
                                        textShadow:
                                            "0 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                />
                                <VerticalDivider />
                                <TextBox
                                    text={"Vecka 20-22, 29-30 i Vara"}
                                    weight="500"
                                    size={1 as RemSize}
                                    color={theme.colors.white}
                                    css={{
                                        textShadow:
                                            "0 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                />
                                <VerticalDivider S />
                            </FlexColumn>
                        </FlexColumn>
                    </SectionAreaCard>
                </FlexColumn>
            </Link>
        </SectionArea>
    );
};

export { OneSquareLinkSectionVara };
