import { ResourceMapMapperModal } from "@@/backoffice/for-providers/inventory/resource-map-mapper-modal";
import { useModal } from "@@/modals/use-modal";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { TextBox } from "@@/shared/text";
import {
    ResourceGlobal,
    ResourceMapId,
    emptyObjectOf,
    translation,
    type MappableId,
    type ResourceId,
} from "@towni/common";
import { Draft } from "immer";
import { useState } from "react";

type Value = Partial<Record<MappableId, ResourceId>> | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly resourceMapId: ResourceMapId;
    readonly resources: ResourceGlobal[];
};

const Form2ResourceMapMapper = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const [{ show: showResourceMapMapperModal }, resourceMapMapperModalId] =
        useModal("resource_map_mapper_modal__");
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: formId,
    });
    const [_initialValue] = useState(field.value);

    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
    });

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            fillParentWidth
            className={props.className}>
            <FlexRow fillParentWidth>
                <ButtonPrimaryLight
                    onClick={showResourceMapMapperModal}
                    css={{
                        padding: "20px",
                    }}
                    fillParentWidth>
                    <TextBox
                        text={translation({
                            sv: "Koppla resurser till karta",
                            en: "Map resources to map",
                        })}
                    />
                </ButtonPrimaryLight>
            </FlexRow>
            <ResourceMapMapperModal
                modalId={resourceMapMapperModalId}
                resources={props.resources}
                resourceMapId={props.resourceMapId}
                resourceMapMap={
                    field.value ?? emptyObjectOf<NonNullable<Value>>()
                }
                onSave={mapMap => {
                    field.setValue(mapMap);
                }}
            />

            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2ResourceMapMapper };
