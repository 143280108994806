import { useProduct } from "@@/products/state/queries/product/use-product";
import { FlexColumn } from "@@/shared/flex-containers";
import {
    type ColorItem,
    type OrderItemBookable_V2,
    type ResourceReservation,
    type ResourceReservationRequest,
} from "@towni/common";
import { BookableOrderItemBody } from "./bookable-order-item-body";
import { BookableOrderItemHeader } from "./bookable-order-item-header";

type Props = {
    orderItem: OrderItemBookable_V2;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
    onRequestUpdate?: () => void;
    backgroundColor?: ColorItem;
};

const BookablePanel = (props: Props) => {
    const { orderItem, resourceReservations, backgroundColor } = props;
    const [product, _] = useProduct(orderItem.productId);
    return (
        <FlexColumn>
            <BookableOrderItemHeader
                orderItem={orderItem}
                imageReference={product?.meta.images[0]}
            />

            <BookableOrderItemBody
                orderItem={orderItem}
                backgroundColor={backgroundColor}
                resourceReservations={resourceReservations}
            />
        </FlexColumn>
    );
};

export { BookablePanel };
