import {
    FrontendEnvironmentSettingName,
    languageCodeMap,
    Translation,
    Unit,
} from "@towni/common";

// Language
// const browserLanguage = navigator.language;
// const languageMatch = languageCodes.find(
//     languageCode => browserLanguage === languageCode
// );
// const languageClosestMatch = languageCodes.find(languageCode =>
//     languageCode.startsWith(navigator.language.substr(0, 2))
// );
const defaultLocale = languageCodeMap.sv;
// languageMatch || languageClosestMatch || languageCodeMap.sv;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const translations: Translation = {
    // ...require("../../assets/translations/en.json"),
};

type AppSettings = {
    env: Record<FrontendEnvironmentSettingName, string>;
    isProduction: boolean;
    isProdEnv: boolean;
    isDevEnv: boolean;
    isSandboxEnv: boolean;
    isDemoEnv: boolean;
    apiEndpoint: string;
    /**
     * Boolean value to let the app decide if it wants to do
     * special things when run in debug mode
     *
     * _Set if environment variable DEBUG exists and doesn't have a false value_
     */
    isDebug: boolean;

    /**
     * List of available units for the app
     * todo: move to non static list
     */
    units: {
        pcs: Unit;
        portion: Unit;
        bottle: Unit;
        glass: Unit;
        cl: Unit;
        hour: Unit;
        day: Unit;
        morning: Unit;
        afternoon: Unit;
        ticket: Unit;
        participant: Unit;
    };
    /**
     * E.g. https://dev.towni.se, https://towni.se or http://localhost:9000
     */
    host: string;
    /**
     * Is app running as a PWA or in a web browser
     */
    mode: "PWA" | "WEB";
};

export { defaultLocale as defaultLanguage, translations };
export type { AppSettings };
