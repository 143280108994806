import { Animate } from "@@/shared/animations";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import {
    Paragraph,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { TranslatableString } from "@towni/common";

type Props = {
    errors: (string | TranslatableString)[];
    className?: string;
};

const FormErrorMessages = (props: Props) => {
    const theme = useTheme();
    const errors = props.errors;

    return (
        <Animate.FadeInOutAndHeight>
            {errors.length ? (
                <FlexRow
                    crossAxis="flex-start"
                    className={props.className}
                    css={{
                        paddingTop: 8,
                        paddingRight: 10,
                        cursor: "default",
                    }}>
                    <Icon
                        icon={faExclamationCircle}
                        fixedWidth
                        css={{
                            display: "inline",
                            color: theme.colors.danger.asString,
                            marginTop: 1,
                            marginRight: 5,
                        }}
                    />
                    <Paragraph
                        css={{ userSelect: "text" }}
                        content={errors.flatMap((error, index) => [
                            paragraphTextFactory({
                                text: error,
                                css: {
                                    color: theme.colors.danger.asString,
                                },
                            }),
                            ...(index < errors.length - 1
                                ? [paragraphLineBreakFactory()]
                                : []),
                        ])}
                    />
                </FlexRow>
            ) : null}
        </Animate.FadeInOutAndHeight>
    );
};

export { FormErrorMessages };
