import { useCheckoutContext } from "@@/carts/checkout.context";
import { ModalId } from "@@/modals/context/modal-id";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { wizardPage, WizardPages } from "@@/pages/wizard";
import { WithWizardProvider, WizardProvider } from "@@/pages/wizard-context";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { MultiContextProvider } from "@@/shared/multi-provider";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text/text-box";
import { useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import {
    ApiError,
    assertNever,
    Me,
    OrderGroupId,
    OrderId,
    translation,
} from "@towni/common";
import * as React from "react";
import { FlexColumn, FlexRow } from "../shared/flex-containers";
import { FreePaymentForm } from "./v2/free/free-payment";
import { OnPremisePaymentForm } from "./v2/on-premise/on-premise-payment-form";
import { createPaymentDetails } from "./v2/payment-details";
import { usePaymentDetailsContext } from "./v2/payment-details.context";
import { PaymentTypeSelection } from "./v2/payment-type-selection";
import { PaymentCardForm } from "./v2/stripe/card-form/payment-card-form";
import { StripePaymentProvider } from "./v2/stripe/stripe-payment.context";
import { SwishPaymentOnAnotherDevice } from "./v2/swish/swish-on-another-device-payment";
import { SwishPaymentOnDevice } from "./v2/swish/swish-on-device-payment";
import { SwishPaymentProvider } from "./v2/swish/swish.context";
import { useInitCheckout } from "./v2/use-init-checkout";

type Props = {
    modalId: ModalId;
    me: Me;
    onFinished: (orderGroupId: OrderGroupId, orderId: OrderId) => void;
};

const CheckoutPayment = ({ me, ...props }: Props) => {
    const theme = useTheme();
    const paymentContext = usePaymentDetailsContext();
    const { hide: closeModal } = useModalActions(props.modalId);
    const { onPremise, totalCheckoutPrice } = useCheckoutContext(context => ({
        onPremise: context.onPremise,
        totalCheckoutPrice: context.totalCheckoutPrice,
    }));
    const [checkoutError, setCheckoutError] = React.useState<ApiError>();

    const checkoutData = useInitCheckout({
        onVerificationError: closeModal,
        onCheckoutError: (error: ApiError) => {
            setCheckoutError(error);
        },
    });
    const [isReady, setReady] = React.useState(false);

    React.useEffect(() => {
        if (!checkoutData) {
            paymentContext.setPaymentDetails(undefined);
            paymentContext.selectPaymentType(undefined);
            return;
        }

        if (onPremise) {
            paymentContext.selectPaymentType({
                name: "ONPREMISE",
                type: "on_premise_pay_later",
            });
        }

        if (
            totalCheckoutPrice.amountIncludingVat === 0 &&
            !paymentContext.paymentFinished
        ) {
            paymentContext.selectPaymentType({
                name: "FREE",
                type: "free",
            });
        }

        paymentContext.setPaymentDetails(
            createPaymentDetails({
                orderGroupId: checkoutData.orderGroupId,
                orderId: checkoutData.orderId,
                type: "buy",
                price: totalCheckoutPrice,
                payerPhoneNumber: me?.phone.phoneNumber,
            }),
        );
        setReady(true);
    }, [checkoutData, totalCheckoutPrice, me?.phone.phoneNumber]);

    const MakePayment = React.useMemo(() => {
        if (!paymentContext.selectedPaymentType) return <></>;
        switch (paymentContext.selectedPaymentType.type) {
            case "stripe_payment_request_button":
                return (
                    <FlexColumn
                        fillParentWidth
                        padding={{ all: 20 }}
                        mainAxis="center"
                        crossAxis="center">
                        <CircleSpinner size="XXL" />;
                    </FlexColumn>
                );
            case "stripe_card":
                return <PaymentCardForm />;
            case "stripe_preselected_card":
                return (
                    <FlexColumn
                        fillParentWidth
                        crossAxis="center"
                        padding={{ all: 40 }}>
                        <TextBox text="Initierar betalning..." />
                        <VerticalDivider ML />
                        <CircleSpinner size="3XL" />
                    </FlexColumn>
                );
            case "swish_on_device":
                return <SwishPaymentOnDevice />;
            case "swish_on_another_device":
                return <SwishPaymentOnAnotherDevice />;
            case "on_premise_pay_later":
            case "on_premise_paid":
            case "pay_request":
                return <OnPremisePaymentForm />;
            case "free":
                return <FreePaymentForm />;
            default:
                assertNever(paymentContext.selectedPaymentType);
        }
    }, [paymentContext.selectedPaymentType]);

    const noChoiceNeeded =
        paymentContext.selectedPaymentType?.type === "free" ||
        ["on_premise_pay_later", "on_premise_paid", "pay_request"].some(
            item => item === paymentContext.selectedPaymentType?.type,
        );
    const pages = noChoiceNeeded
        ? [wizardPage("make-payment", MakePayment)]
        : [
              wizardPage(
                  "select-payment-method",
                  <PaymentTypeSelection me={me} />,
              ),
              wizardPage("make-payment", MakePayment),
          ];

    if (checkoutError) {
        return (
            <FlexColumn fillParentWidth padding={{ all: 20 }}>
                <FlexRow
                    fillParentWidth
                    padding={{ all: 20 }}
                    mainAxis="center"
                    crossAxis="flex-start"
                    css={{
                        borderRadius: 10,
                        backgroundColor: theme.colors.black.light95.asString,
                    }}>
                    {/* <Icon
                        icon={faWarning}
                        padding={{ top: 3 }}
                        color={theme.colors.danger}
                    />
                    <HorizontalDivider S /> */}
                    <TextBox
                        text={checkoutError.reason}
                        color={theme.colors.danger}
                        css={{ flex: 1 }}
                    />
                </FlexRow>
                <VerticalDivider S />
                <FlexRow>
                    <ButtonWhite fillParentWidth onClick={() => closeModal()}>
                        <TextBox
                            text={translation({
                                sv: "Okej",
                                en: "Okey",
                            })}
                        />
                    </ButtonWhite>
                </FlexRow>
            </FlexColumn>
        );
    }
    if (!isReady || paymentContext.paymentFinished)
        return (
            <FlexRow fillParentWidth padding={{ all: 20 }} mainAxis="center">
                <CircleSpinner size="XXL" />
            </FlexRow>
        );

    return (
        <ErrorBoundary>
            <WizardProvider
                onCancel={closeModal}
                ignoreAnimation={true}
                onFinish={() => {
                    if (!paymentContext.paymentDetails)
                        throw new Error(
                            "Payment finished, but payment details not available",
                        );
                    paymentContext.setPaymentFinished();
                    props.onFinished(
                        paymentContext.paymentDetails.orderGroupId,
                        paymentContext.paymentDetails.orderId,
                    );
                }}>
                <MultiContextProvider
                    providers={[SwishPaymentProvider, StripePaymentProvider]}>
                    <FlexColumn
                        shrink={1}
                        grow={1}
                        fillParentWidth
                        background={{ color: theme.colors.default.background }}
                        crossAxis="stretch">
                        <WithWizardProvider>
                            {(wizardState, actions) => (
                                <ModalHeader
                                    modalId={props.modalId}
                                    topLeftButton={
                                        wizardState.isFirst
                                            ? undefined
                                            : {
                                                  icon: faChevronLeft,
                                                  action: () =>
                                                      actions.goBack(1),
                                              }
                                    }
                                    title={translation({
                                        sv: "Betala",
                                        en: "Pay",
                                    })}
                                    bottomBorder
                                />
                            )}
                        </WithWizardProvider>
                        <VerticalDivider />
                        <WizardPages
                            ignoreAnimation={true}
                            initialPageId={
                                paymentContext.selectedPaymentType === undefined
                                    ? "select-payment-method"
                                    : "make-payment"
                            }>
                            {pages}
                        </WizardPages>
                    </FlexColumn>
                </MultiContextProvider>
            </WizardProvider>
        </ErrorBoundary>
    );
};

export { CheckoutPayment };
