import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { DetailsPickerHeader } from "@@/products/pick-sku-header";
import { useTheme } from "@emotion/react";
import {
    OptionalId,
    Padding,
    PickedOptional,
    Optional as TOptional,
    OptionalGroup as TOptionalGroup,
    OptionalPercentage as TOptionalPercentage,
    defaultQuantity,
    sortBy,
    svTranslator,
} from "@towni/common";
import * as React from "react";
import { Conditional } from "../conditional";
import { HorizontalLine, VerticalDivider } from "../dividers";
import { FlexColumn, FlexRow } from "../flex-containers";
import { ForEach } from "../for-each";
import { TextBox } from "../text/text-box";
import { useMountEffect } from "../use-mount-effect";
import { Optional } from "./optional";
import { OptionalPercentage } from "./optional-percentage";

type Props = {
    optionalGroup: TOptionalGroup;
    padding?: Padding;
    optionPadding?: Padding;
    onChange: (pickedOptions: PickedOptional[]) => void;
    hidePrices?: boolean;
    initialPickedOptions?: OptionalId[];
};
const OptionalGroup = (props: Props) => {
    const theme = useTheme();
    const [selectedOptionalIds, setSelectedOptionalIds] = React.useState<
        OptionalId[]
    >(props.initialPickedOptions ?? []);

    const { title, subtitle } = props.optionalGroup.meta;
    const minMax = props.optionalGroup.minMax;
    const type =
        minMax?.min === 1 && minMax?.max === 1
            ? "REQUIRED_SINGLE"
            : minMax?.min
              ? "REQUIRED"
              : "OPTIONAL";

    const shape = type === "REQUIRED_SINGLE" ? "CIRCLE" : "SQUARE";
    const nativePickerEnabled =
        type === "REQUIRED_SINGLE" && props.optionalGroup.optionals.length > 12;
    const onChange = (optionIds: OptionalId[]) => {
        const pickedOptionals: PickedOptional[] = props.optionalGroup.optionals
            .filter(optional => {
                return optionIds.includes(optional._id);
            })
            .map(optional => ({
                optional,
                quantity: defaultQuantity, // TODO, fix when UI has ability to select quantity
            }));
        props.onChange(pickedOptionals);
    };
    const toggle = (optionId: OptionalId) => () => {
        let update = [...selectedOptionalIds];
        switch (type) {
            case "REQUIRED_SINGLE": {
                update = [optionId];
                break;
            }
            case "REQUIRED":
            case "OPTIONAL":
            default: {
                // does it already exist in list?
                // and without it there are still more than
                // required min in the list; then toggle it off
                if (update.includes(optionId)) {
                    if (!minMax?.min || update.length > minMax.min) {
                        update = update.filter(r => r !== optionId);
                    }
                } else {
                    // to many options selected?
                    if (minMax?.max && update.length === minMax.max) {
                        update.shift();
                    }
                    update.push(optionId);
                }
            }
        }

        setSelectedOptionalIds(update);
        onChange(update);
    };

    const isRequired =
        props.optionalGroup.minMax?.min && props.optionalGroup.minMax?.min > 0;
    const onlyOneOption = props.optionalGroup.optionals.length === 1;
    useMountEffect(() => {
        // If there is only one option, and the optional groups
        // is required. Then we should select the option by default.
        if (isRequired && onlyOneOption) {
            toggle(props.optionalGroup.optionals[0]._id)();
        }
    });

    return (
        <FlexColumn>
            <DetailsPickerHeader
                title={title}
                padding={props.padding}
                minMax={props.optionalGroup.minMax}
                hideDescription={props.optionalGroup.optionals.length === 1}
            />
            <VerticalDivider S />
            <Conditional
                when={!!subtitle}
                render={() => (
                    <>
                        <TextBox
                            text={subtitle}
                            size="ML"
                            color={theme.colors.default.text.light50}
                        />
                        <VerticalDivider />
                    </>
                )}
            />

            <Conditional
                when={nativePickerEnabled}
                render={() => (
                    <FlexRow
                        fillParentWidth
                        padding={{ leftRight: 20, bottom: 20 }}>
                        <SelectPicker
                            data={[
                                ...(!selectedOptionalIds.length
                                    ? [
                                          {
                                              name: "- välj -",
                                              value: "none" as OptionalId,
                                          },
                                      ]
                                    : []),
                                ...props.optionalGroup.optionals.map(
                                    optional => ({
                                        name: optional.meta.title,
                                        value: optional._id,
                                    }),
                                ),
                            ]}
                            selectedValue={selectedOptionalIds[0]}
                            onSelect={({ value }) => {
                                toggle(value)();
                            }}
                        />
                        {/* <TextBox
                            text={
                                "NATIVE PICKER " +
                                props.optionalGroup.optionals.length.toString()
                            }
                        /> */}
                    </FlexRow>
                )}
                else={() => (
                    <ForEach
                        itemOf={props.optionalGroup.optionals.sort(
                            sortBy(s => svTranslator(s.meta.title)),
                        )}
                        divider={<HorizontalLine />}>
                        {item => (
                            <>
                                <Conditional
                                    when={item._type === "OPTIONAL"}
                                    render={() => {
                                        const optional = item as TOptional;
                                        return (
                                            <Optional
                                                key={optional._id}
                                                hidePrice={props.hidePrices}
                                                option={optional}
                                                shape={shape}
                                                toggle={toggle(optional._id)}
                                                padding={props.optionPadding}
                                                selected={selectedOptionalIds.includes(
                                                    optional._id,
                                                )}
                                            />
                                        );
                                    }}
                                />
                                <Conditional
                                    when={item._type === "OPTIONAL_PERCENTAGE"}
                                    render={() => {
                                        const optional =
                                            item as TOptionalPercentage;
                                        return (
                                            <OptionalPercentage
                                                key={optional._id}
                                                hidePrice={props.hidePrices}
                                                option={optional}
                                                padding={props.optionPadding}
                                                shape={shape}
                                                toggle={toggle(optional._id)}
                                                selected={selectedOptionalIds.includes(
                                                    optional._id,
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </>
                        )}
                    </ForEach>
                )}
            />
        </FlexColumn>
    );
};

export { OptionalGroup };
