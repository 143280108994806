import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Paragraph, PriceTextBox, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    Order,
    OrderItem,
    OrderItemOptional_V2,
    Provider,
    acquireType,
    getChildOptionals,
    isOrderItem_V2,
    remSize,
    sumPrices,
    timeToString,
    translation,
} from "@towni/common";
import { ProductOptionalsTextBox } from "../../products/product-optionals-text-box";
import { ProductTitleTextBox } from "../../products/product-title-text-box";
import { ContactProviderPanel } from "./contact-provider-panel";

type Props = {
    provider: Provider;
    order: Order;
};

const OrderHomeDeliveryReceipt = ({ order, provider }: Props) => {
    const theme = useTheme();
    if (order.delivery.deliveryMethodType !== "HOME_DELIVERY") {
        throw `Can't show pickup receipt for an order with a delivery method other than pickup (${order._id})`;
    }

    const gray = theme.colors.black.light60;

    const PrepTimePendingConfirmation = () => {
        return (
            <>
                <TextBox
                    text={translation({
                        en: "Calculating:",
                        sv: "Bekräftas:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={translation({
                        en: `Awaiting confirmation from ${provider.meta.title}.`,
                        sv: `Inväntar bekräftelse från ${provider.meta.title}.`,
                    })}
                    color={theme.colors.black.light60}
                />
            </>
        );
    };

    const OrderConfirmed = () => {
        return (
            <>
                <TextBox
                    text={translation({
                        en: "Recived:",
                        sv: "Mottagen:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={translation({
                        en: "Your order is OK",
                        sv: "Din order har blivit godkänd!",
                    })}
                    color={theme.colors.black.light60}
                />
            </>
        );
    };
    const PrepTimeReadyForPickup = () => {
        return (
            <>
                <TextBox
                    text={translation({
                        en: "Ready:",
                        sv: "Färdig:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={translation({
                        en: "Your Order is on its way!",
                        sv: "Din order är på väg ut",
                    })}
                    color={theme.colors.black.light60}
                />
            </>
        );
    };

    const PrepTimeFullfilled = () => {
        return (
            <TextBox
                text={translation({
                    en: "Hope your happy with your order!",
                    sv: "Syns igen! Hoppas du är nöjd med din order!",
                })}
                color={theme.colors.black.light60}
            />
        );
    };

    const prepTimeComponent = (order: Order) => {
        switch (order.status.type) {
            case "PENDING_CONFIRMATION":
                return <PrepTimePendingConfirmation />;
            case "CONFIRMED":
                return <OrderConfirmed />;
            case "READY_FOR_PICK_UP":
                return <PrepTimeReadyForPickup />;
            case "FULFILLED":
                return <PrepTimeFullfilled />;
        }
        return <></>;
    };

    return (
        <FlexColumn
            fillParentWidth
            background={{ color: theme.colors.white }}
            width="80%">
            <VerticalDivider size={1} />
            <FlexRow padding={{ leftRight: 20 }} crossAxis="center">
                <ContactProviderPanel provider={provider} />
            </FlexRow>
            <VerticalDivider size={1} />
            <HorizontalLine />
            <VerticalDivider size={1} />
            <FlexRow padding={{ leftRight: 20 }}>
                {prepTimeComponent(order)}
            </FlexRow>
            <VerticalDivider size={1} />
            <HorizontalLine />
            <VerticalDivider size={1} />
            <FlexRow padding={{ leftRight: 20 }}>
                <TextBox
                    text={translation({
                        sv: "Leveras till:",
                        en: "Delivered to:",
                    })}
                    weight="700"
                />
                <HorizontalDivider />
                <TextBox
                    text={order.delivery.destination.address.addressRows[0]}
                    color={gray}
                />
            </FlexRow>
            <VerticalDivider size={1} />
            <HorizontalLine />
            <VerticalDivider size={1} />
            {order.delivery.destination.notesToDeliverer && (
                <>
                    <FlexColumn padding={{ leftRight: 20 }}>
                        <TextBox
                            text={translation({
                                sv: "Instruktioner:",
                                en: "Instructions:",
                            })}
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={order.delivery.destination.notesToDeliverer}
                            color={gray}
                        />
                    </FlexColumn>
                    <VerticalDivider size={1} />
                    <HorizontalLine />
                    <VerticalDivider size={1} />
                </>
            )}
            {order.messageToProvider && (
                <>
                    <FlexColumn padding={{ leftRight: 20 }}>
                        <TextBox
                            text={translation({
                                sv: "Notis",
                                en: "Note",
                            })}
                            weight="700"
                        />
                        <VerticalDivider XS />
                        <TextBox text={order.messageToProvider} color={gray} />
                    </FlexColumn>
                    <VerticalDivider size={1} />
                    <HorizontalLine />
                    <VerticalDivider size={1} />
                </>
            )}
            <Conditional when={!!order.orderItems.length}>
                <ForEach
                    itemOf={order.orderItems}
                    endWith={<VerticalDivider key="end" size={0.5} />}
                    divider={
                        <FlexColumn crossAxis="stretch">
                            <VerticalDivider size={1} />
                            <HorizontalLine />
                            <VerticalDivider size={1} />
                        </FlexColumn>
                    }>
                    {item => {
                        return (
                            <OrderRow
                                key={item._id}
                                item={item}
                                optionals={
                                    isOrderItem_V2(item)
                                        ? getChildOptionals(
                                              item,
                                              order.orderItems,
                                          )
                                        : [] //Todo a bit of a smell
                                }
                            />
                        );
                    }}
                </ForEach>
            </Conditional>
        </FlexColumn>
    );
};

type OrderRowProps = {
    item: OrderItem;
    optionals: OrderItemOptional_V2[];
    hidePrice?: boolean;
};

const OrderRow = (props: OrderRowProps) => {
    const { item, optionals, hidePrice } = props;
    const theme = useTheme();
    const quantity =
        item.acquireType === acquireType.BOOKABLE
            ? timeToString(item.time)
            : `${item.quantity.value}`;

    const gray = theme.colors.black.light60;

    const totalPrice = sumPrices([item.price, ...optionals.map(o => o.price)]);
    const pricePerItem = sumPrices([
        item.pricePerItem,
        ...optionals.map(o => o.pricePerItem),
    ]);

    return (
        <FlexRow
            key={item._id}
            css={{
                label: "OrderRow",
            }}
            crossAxis="flex-start"
            mainAxis="space-between"
            padding={{ leftRight: 20 }}>
            <FlexRow crossAxis="flex-start">
                <TextBox
                    text={quantity}
                    weight="700"
                    color={theme.colors.black.light50}
                    css={{
                        flexShrink: 0,
                        flexGrow: 1,
                        whiteSpace: "nowrap",
                    }}
                />
                <HorizontalDivider size={remSize(0.8)} />

                <FlexColumn>
                    <ProductTitleTextBox meta={item.meta} weight="700" />
                    <VerticalDivider XXS />
                    <ProductOptionalsTextBox
                        pickedOptionals={optionals.map(p => p.optional)}
                        size={0.8}
                        color={gray}
                    />
                    {(item.messageToProvider && (
                        <FlexRow wrap={"wrap"}>
                            <Paragraph
                                css={{
                                    color: gray.asString,
                                    fontSize: "0.8rem",
                                }}
                                content={[
                                    translation({
                                        sv: "Instruktioner: ",
                                        en: "Instructions: ",
                                    }),
                                    item.messageToProvider,
                                ]}
                            />
                        </FlexRow>
                    )) ||
                        ""}
                </FlexColumn>
            </FlexRow>
            <HorizontalDivider />
            <Conditional when={!hidePrice}>
                <FlexColumn crossAxis="flex-end">
                    <PriceTextBox
                        price={totalPrice}
                        //originalPrice={totalPrice}
                        direction="VT"
                    />
                    <VerticalDivider XXS />
                    {item.quantity.value > 1 ? (
                        <FlexRow>
                            <TextBox
                                text={`${quantity}x`}
                                size={0.8}
                                color={gray}
                            />
                            <PriceTextBox
                                price={pricePerItem}
                                size={0.8}
                                color={gray}
                            />
                        </FlexRow>
                    ) : (
                        ""
                    )}
                </FlexColumn>
            </Conditional>
        </FlexRow>
    );
};

export { OrderHomeDeliveryReceipt, OrderRow };
