import { useWizardDispatch } from "@@/pages/wizard-context";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faMobileAlt } from "@fortawesome/pro-solid-svg-icons";
import { remSize } from "@towni/common";
import * as React from "react";
import { SwishMessage } from "./swish-status-message";
import { useSwishPayment } from "./swish.context";

const SwishPaymentOnAnotherDevice = () => {
    const theme = useTheme();
    const [state, _actions] = useSwishPayment();
    const { finish } = useWizardDispatch();

    React.useEffect(() => {
        if (!state.swishPayment) return;
        if (state.swishPayment.status === "PAID") {
            finish();
            return;
        }
    }, [finish, state.swishPayment]);

    return (
        <FlexColumn
            padding={{ leftRight: 20, bottom: 40 }}
            fillParentWidth
            crossAxis="center">
            {!state.swishPayment ? (
                <>
                    <TextBox text={"Initierar betalning"} />
                    <VerticalDivider XL />
                    <CircleSpinner size="XL" />
                    <VerticalDivider XL />
                </>
            ) : null}
            {
                // PENDING
                state.swishPayment?.status === "PENDING" ? (
                    <>
                        <FlexRow
                            fillParentWidth
                            crossAxis="center"
                            mainAxis="flex-start"
                            padding={{ leftRight: 20 }}>
                            {/* Icon here is just for getting the correct distance from left border
                            >>>>>>>>> */}
                            <Icon
                                icon={faMobileAlt}
                                color={theme.colors.primary}
                                size={remSize(2)}
                                padding={{ top: 3 }}
                                style={{ opacity: 0 }}
                            />
                            <HorizontalDivider M />
                            {/* <<<<<<<<<< */}
                            <FlexRow crossAxis="center">
                                <TextBox
                                    text={`Avvaktar svar från Swish`}
                                    weight="700"
                                    size={1}
                                />
                                <HorizontalDivider />
                                <CircleSpinner size="ML" />
                            </FlexRow>
                        </FlexRow>
                        <VerticalDivider S />
                        <FlexRow
                            fillParentWidth
                            padding={{ leftRight: 20 }}
                            crossAxis="flex-start">
                            <Icon
                                icon={faMobileAlt}
                                color={theme.colors.primary}
                                size={remSize(2)}
                                padding={{ top: 3 }}
                            />
                            <HorizontalDivider M />
                            <FlexColumn crossAxis="flex-start">
                                <TextBox
                                    text={`Betalning begärd från: +${state.swishPayment?.request.payerAlias}`}
                                    color={theme.colors.default.text.light40}
                                />
                                <VerticalDivider />
                                <TextBox
                                    text={`Öppna swishappen och slutför betalningen, kom sedan tillbaka hit.`}
                                    color={theme.colors.default.text.light40}
                                />
                            </FlexColumn>
                        </FlexRow>
                    </>
                ) : null
            }
            <SwishMessage swishPayment={state.swishPayment} />
        </FlexColumn>
    );
};

export { SwishPaymentOnAnotherDevice };
