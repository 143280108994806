import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { PageLoad } from "@@/pages/page-load";
import { SectionGroupPage } from "@@/section-groups";
import { AddressLink } from "@@/section-groups/address-link";
import { BusinessHoursView } from "@@/section-groups/business-hours-view";
import {
    useDefaultSectionGroupForProvider,
    useSectionGroupsForProvider,
} from "@@/section-groups/state/section-group-fetchers";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { Pillify } from "@@/shared/pillify";
import { useTheme } from "@emotion/react";
import {
    ProviderId,
    isApiError,
    parseProviderPosterImage,
} from "@towni/common";
import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ProviderSectionGroupPicker } from "./provider-section-group-picker";
import { useProviderFromUrl } from "./state/queries/provider/use-provider-from-url";

const throwError = (error: Error | null) => {
    throw error || new Error("Provider page by slug render failed; " + error);
};

const ProviderShopPage = (props: {
    /** If not given, providerId or slug is read from the url */
    providerId?: ProviderId;
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [
        provider,
        {
            isLoading: isProviderLoading,
            error: providerError,
            ...providerQuery
        },
    ] = useProviderFromUrl({
        providerId: props.providerId,
    });
    const [
        sectionGroups,
        { isLoading: isSectionGroupsLoading, error: sectionGroupsError },
    ] = useSectionGroupsForProvider(provider?._id);

    const defaultSectionGroup = useDefaultSectionGroupForProvider(
        provider?._id,
        sectionGroups,
    );
    const posterImage = React.useMemo(
        () => parseProviderPosterImage(provider),
        [provider],
    );

    if (providerError) {
        if (isApiError(providerError) && providerError.statusCode === 404) {
            // console.log("PROVIDER ERROR", providerError);
            return <PageNotFound reason={providerError.reason} />;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throwError(providerError as any); // TODO: FIX THIS AND REMOVE ANY
    }

    if (sectionGroupsError) {
        if (
            isApiError(sectionGroupsError) &&
            sectionGroupsError.statusCode === 404
        ) {
            // console.log("SECTION GROUPS ERROR", sectionGroupsError);
            return <PageNotFound reason={sectionGroupsError.reason} />;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throwError(sectionGroupsError as any); // TODO: FIX THIS AND REMOVE ANY
    }

    if (isSectionGroupsLoading || isProviderLoading) {
        return (
            <PageLoad
                css={{
                    backgroundColor: theme.colors.default.background.asString,
                }}
            />
        );
    }

    if (!provider && !providerQuery.isStale) {
        // console.log("RETURN REDIRECT /");
        return <Navigate to="/" replace />;
    }

    if (!provider || !defaultSectionGroup) {
        // console.log("PAGE LOAD2", { provider: !!provider });
        return (
            <PageLoad
                css={{
                    backgroundColor: theme.colors.default.background.asString,
                }}
            />
        );
    }

    const moreThanOneSectionGroup = (sectionGroups || []).length > 1;
    // console.log("PAGE RENDER", { posterImage, moreThanOneSectionGroup });
    return (
        <CartContextProvider key={provider._id} providerId={provider._id}>
            <CheckoutContextProvider>
                <HeadTitle title={provider.meta.title} />
                <SectionGroupPage
                    key={defaultSectionGroup._id}
                    title={provider.meta.title}
                    hidden={provider.options.hidden}
                    subtitle={provider.meta.subtitle}
                    sectionGroupId={defaultSectionGroup._id}
                    posterImage={posterImage}
                    headerMiddle={
                        <Conditional when={moreThanOneSectionGroup}>
                            <ProviderSectionGroupPicker
                                providerName={provider.meta.title}
                                sectionGroups={sectionGroups || []}
                                currentSectionGroup={defaultSectionGroup}
                                onSelect={(sectionGroupId: string) => {
                                    navigate(
                                        `/shop/${provider.slug}/${sectionGroupId}`,
                                    );
                                }}
                            />
                        </Conditional>
                    }
                    information={
                        <FlexRow
                            wrap="wrap"
                            wrapMargin={7}
                            padding={{ leftRight: 20 }}
                            crossAxis="center"
                            mainAxis="flex-start">
                            <Pillify>
                                <BusinessHoursView provider={provider} />
                            </Pillify>
                            <Pillify>
                                <AddressLink address={provider.address} />
                            </Pillify>
                            <HorizontalDivider S />
                        </FlexRow>
                    }
                />
            </CheckoutContextProvider>
        </CartContextProvider>
    );
};

export { ProviderShopPage };
