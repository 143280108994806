import { AnimatePresence, motion } from "framer-motion";

/**
 * Funkar inte i alla lägen, gjordes för ett ställe, behöver mycket
 * mer kärlek för att bli generell tänker jag
 */
const Animate = {
    /**
     * Funkar inte i alla lägen, gjordes för ett ställe, behöver mycket
     * mer kärlek för att bli generell tänker jag
     */
    FadeInOut: (props: { children: React.ReactNode }) => {
        return (
            <AnimatePresence>
                {props.children ? (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        {props.children}
                    </motion.div>
                ) : null}
            </AnimatePresence>
        );
    },
    /**
     * Funkar inte i alla lägen, gjordes för ett ställe, behöver mycket
     * mer kärlek för att bli generell tänker jag
     */
    FadeInOutAndHeight: (props: { children: React.ReactNode }) => {
        return (
            <AnimatePresence>
                {props.children ? (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}>
                        {props.children}
                    </motion.div>
                ) : null}
            </AnimatePresence>
        );
    },
};

export { Animate };
