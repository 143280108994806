import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { useResource } from "@@/resources/resources/use-resources";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import {
    Paragraph,
    TextBox,
    paragraphHzSpaceFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTheme } from "@emotion/react";
import {
    capitalCase,
    isManifestBookableOptionValue,
    isQuantityPickerBookableOptionValue,
    translation,
    type ResourceReservation,
    type ResourceReservationRequest,
} from "@towni/common";

type Props = {
    readonly resourceReservation:
        | ResourceReservation
        | ResourceReservationRequest;
    readonly displayTime: boolean;
    readonly index?: number;
};

const ResourceReservationRequestRow = (props: Props) => {
    const theme = useTheme();
    const dateTimeFormatter = useDateTimeFormatter();
    const optionValues = props.resourceReservation.optionValues.filter(
        b => isQuantityPickerBookableOptionValue(b) && b.quantity.value > 0,
    );
    const numberOfOptions = optionValues.length;

    const ak = useAccessKeyIdFromUrl();
    const [resource, _] = useResource(props.resourceReservation.resourceId, ak);

    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    //   weight="900"
                    text={capitalCase(
                        props.resourceReservation.resourceUnit.name.sv.plural,
                    )}
                    dataTestId={`bd-resource-title-${props.index ?? 0}`}
                    color={theme.colors.white.light50}
                />
                <VerticalDivider XXS />

                <FlexColumn>
                    {optionValues.map((optionValue, index) => {
                        if (isManifestBookableOptionValue(optionValue))
                            return null;
                        return (
                            <Paragraph
                                key={optionValue.parentId}
                                css={{ fontWeight: 800, fontSize: "1.1rem" }}
                                dataTestId={`bd-resource-title-${
                                    props.index
                                }-value-${index ?? 0}`}
                                content={[
                                    optionValue.quantity.value.toLocaleString(),
                                    paragraphHzSpaceFactory(),
                                    optionValue.meta.title,
                                    numberOfOptions !== index + 1
                                        ? paragraphTextFactory({
                                              css: {
                                                  whiteSpace: "pre",
                                                  color: theme.colors.white
                                                      .light50.asString,
                                              },
                                              text: translation({
                                                  sv: ", ",
                                              }),
                                          })
                                        : undefined,
                                ]}
                            />
                        );
                    })}
                    {resource ? (
                        <FlexRow>
                            <HorizontalDivider />
                            <TextBox
                                text={"-"}
                                color={theme.colors.black.light30}
                                weight="800"
                                size={1.1}
                            />
                            <HorizontalDivider />
                            <TextBox
                                size={1.1}
                                weight="800"
                                text={resource.meta.title}
                            />
                            <HorizontalDivider />
                            <Conditional when={props.displayTime}>
                                <TextBox
                                    weight="800"
                                    color={theme.colors.white.light50}
                                    text={dateTimeFormatter.formatDayDateAndTimeMedium(
                                        props.resourceReservation.timeRange,
                                    )}
                                />
                            </Conditional>
                        </FlexRow>
                    ) : null}
                </FlexColumn>
            </FlexColumn>
        </FlexRow>
    );
};

export { ResourceReservationRequestRow };
