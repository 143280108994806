import { useCompanyDetails } from "@@/backoffice/dashboard/providers/tabs/company-details/company-details-query/use-company-details-for-provider";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import {
    Paragraph,
    TextBox,
    paragraphHzSpaceFactory,
    paragraphLineBreakFactory,
} from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { Provider, translation } from "@towni/common";

type Props = {
    provider: Provider;
    options?: {
        hideProviderTitleAndId?: boolean;
    };
};
const ReceiptCompanyDetails = ({ provider, options }: Props) => {
    const theme = useTheme();
    const [companyDetails] = useCompanyDetails(
        provider.companyDetailsId ? provider._id : undefined,
    );
    const titleColor = theme.colors.black.light30;
    const textColor = theme.colors.black.light60;

    if (!companyDetails && !!options?.hideProviderTitleAndId) return null;
    return (
        <>
            <FlexColumn
                fillParentWidth
                crossAxis="flex-start"
                mainAxis="center">
                <TextBox
                    text={translation({
                        sv: "Säljare",
                        en: "Seller",
                    })}
                    case="UPPERCASE"
                    userSelect="text"
                    size={0.7}
                    weight="900"
                    color={titleColor}
                />
            </FlexColumn>
            <VerticalDivider XXS />
            {companyDetails ? (
                <Paragraph
                    css={{
                        fontWeight: 400,
                        fontSize: "0.8rem",
                        color: textColor.asString,
                        userSelect: "text",
                    }}
                    content={[
                        companyDetails.legalName,
                        paragraphLineBreakFactory(),
                        companyDetails.address.addressRows.join(","),
                        paragraphLineBreakFactory(),
                        companyDetails.address.zipCode,
                        paragraphHzSpaceFactory(),
                        companyDetails.address.city,
                        ...(companyDetails.organisationNumber
                            ? [
                                  paragraphLineBreakFactory(),
                                  translation({
                                      sv: "Org.nr: ",
                                      en: "Reg No: ",
                                  }),
                                  companyDetails.organisationNumber,
                              ]
                            : []),
                        ...(companyDetails.vatNo
                            ? [
                                  paragraphLineBreakFactory(),
                                  translation({
                                      sv: "Momsregnr: ",
                                      en: "VAT Reg No: ",
                                  }),
                                  companyDetails.vatNo,
                              ]
                            : []),
                    ]}
                />
            ) : null}
            {!options?.hideProviderTitleAndId ? (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        text={provider?.meta.title}
                        weight="400"
                        userSelect="text"
                        size={0.8}
                        color={textColor}
                    />
                    <TextBox
                        css={{ width: "100%" }}
                        text={provider._id}
                        weight="400"
                        userSelect="text"
                        size={0.8}
                        color={textColor}
                    />
                </>
            ) : null}
        </>
    );
};

export { ReceiptCompanyDetails };
