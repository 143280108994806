import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Join } from "@@/shared/join";
import { Label } from "@@/shared/label";
import { TextBox, TextEdit } from "@@/shared/text";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { css } from "@emotion/react";
import {
    isManifestBookableOptionValue,
    sortBy,
    translation,
    type ManifestBookableOptionValue,
    type ResourceReservationRequest,
} from "@towni/common";
import { useCartContext } from "../multi-carts/cart.context";

type Props = {
    reservations: ResourceReservationRequest[];
};

const BookableManifest = ({ reservations }: Props) => {
    const manifests = reservations.flatMap(resourceReservation => {
        return resourceReservation.optionValues.flatMap(option => {
            if (isManifestBookableOptionValue(option) && option.manifest.length)
                return [option];
            return [];
        });
    });
    if (manifests.length === 0) return null;
    return (
        <FlexColumn>
            <Join divider={<HorizontalSectionDivider />} end>
                {manifests.map(m => (
                    <Manifest key={m._id} manifest={m} />
                ))}
            </Join>
        </FlexColumn>
    );
};

type ManifestProps = {
    manifest: ManifestBookableOptionValue;
};

const Manifest = ({ manifest }: ManifestProps) => {
    const unitTranslate = useUnitTranslator();
    const setManifest = useCartContext(context => context.setManifestRow);
    return (
        <FlexColumn>
            <VerticalDivider L />
            <FlexRow padding={{ leftRight: 20 }} crossAxis="center">
                <TextBox
                    size="L"
                    weight="800"
                    text={manifest.meta.title}
                    grow={1}
                />

                <Label
                    text={translation({
                        sv: "obligatorisk",
                        en: "mandatory",
                    })}
                    colorTheme="RED"
                />
            </FlexRow>
            <VerticalDivider M />
            <Join divider={<HorizontalLine />} start end>
                {manifest.manifest.sort(sortBy(f => f.index)).map(i => {
                    return (
                        <TextEdit
                            key={i.index}
                            type="text"
                            level="REQUIRED"
                            styling={css`
                                background-color: white;
                                border: none;
                            `}
                            inputStyling={css`
                                background-color: white;
                            `}
                            placeholder={translation({
                                sv: `Namn ${unitTranslate(
                                    1,
                                    manifest.unit,
                                    "long",
                                )} ${i.index + 1}...`,
                            })}
                            onChange={value => {
                                //TODO add maxlenght?
                                i.data.NAME = value;
                                setManifest(manifest._id, i);
                            }}
                            value={i.data.NAME}
                            onValidationFailed={() => {
                                return;
                            }}
                        />
                    );
                })}
            </Join>
        </FlexColumn>
    );
};

export { BookableManifest };
