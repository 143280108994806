import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { PriceTextBox, TextBox, TextSpan } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { Price, subtractFromPrice } from "@towni/common";

type Props = {
    amount: Price;
    amountUsed: Price;
    code: string;
    link?: string;
    dataTestId?: string;
};

const TicketGiftcardCustomerViewFooter = (props: Props) => {
    const theme = useTheme();
    const length = props.code.length;
    return (
        <FlexColumn
            fillParentWidth
            background={{ color: theme.colors.white }}
            crossAxis="center"
            data-testid={props.dataTestId}
            css={{}}>
            <VerticalDivider S />
            <TextBox
                text="Värdekod"
                align="center"
                size={0.875}
                color={theme.colors.black.light45}
            />
            <TextBox
                size={1.1875}
                color={theme.colors.black.light45}
                align="center"
                css={{ userSelect: "text" }}>
                <TextSpan
                    text={props.code.slice(0, length / 2)}
                    css={{
                        letterSpacing: 5,
                        paddingRight: 5,
                    }}
                />
                <TextSpan
                    text={props.code.slice(length / 2)}
                    css={{
                        letterSpacing: 5,
                        paddingLeft: 5,
                    }}
                />
            </TextBox>
            <VerticalDivider M />
            <TextBox
                text="Värde"
                align="center"
                size={0.875}
                color={theme.colors.black.light45}
            />
            <PriceTextBox
                price={props.amount}
                size={1.1875}
                color={theme.colors.black.light45}
                align="center"
                css={{ userSelect: "text" }}
            />
            <VerticalDivider M />
            <TextBox
                text="Värde kvar"
                align="center"
                size={0.875}
                color={theme.colors.black.light45}
            />
            <PriceTextBox
                price={subtractFromPrice(props.amount, [props.amountUsed])}
                size={1.1875}
                color={theme.colors.black.light45}
                align="center"
                css={{ userSelect: "text" }}
            />
            <VerticalDivider XL />
        </FlexColumn>
    );
};

export { TicketGiftcardCustomerViewFooter };
