import { DetailsPickerHeader } from "@@/products/pick-sku-header";
import { useTheme } from "@emotion/react";
import {
    Padding,
    PickedOptional,
    OptionalAmount as TOptionalAmount,
    defaultAmount,
    setWithinRange,
} from "@towni/common";
import * as React from "react";
import { VerticalDivider } from "../dividers";
import { FlexColumn } from "../flex-containers";
import { TextBox } from "../text/text-box";
import { OptionalWithAmountPicker } from "./optional-with-amount-picker";

type Props = {
    optionalGroup: TOptionalAmount;
    padding?: Padding;
    optionPadding?: Padding;
    onChange: (pickedOptions: PickedOptional[]) => void;
    hidePrices?: boolean;
};
const OptionalAmount = (props: Props) => {
    const theme = useTheme();
    const [count, setCount] = React.useState(0);
    const { title, subtitle } = props.optionalGroup.meta;
    const minMax = props.optionalGroup.minMax;

    const increase = () => {
        setCount(oldCount => setWithinRange(++oldCount, minMax));
    };
    const decrease = () => {
        setCount(oldCount => setWithinRange(--oldCount, minMax));
    };

    React.useEffect(() => {
        if (count === 0) {
            props.onChange([]);
            return;
        }
        props.onChange([
            {
                optional: props.optionalGroup.optional,
                quantity: {
                    amount:
                        props.optionalGroup.optional.amount || defaultAmount,
                    value: count,
                },
            },
        ]);
    }, [count]);

    return (
        <FlexColumn>
            <DetailsPickerHeader
                title={title}
                padding={props.padding}
                minMax={props.optionalGroup.minMax}
            />
            <VerticalDivider S />
            {subtitle && (
                <>
                    <TextBox
                        text={subtitle}
                        size="ML"
                        color={theme.colors.default.text.light50}
                    />
                    <VerticalDivider />
                </>
            )}
            <OptionalWithAmountPicker
                count={count}
                increase={increase}
                decrease={decrease}
                price={props.optionalGroup.optional.price}
                padding={props.optionPadding}
            />
        </FlexColumn>
    );
};

export { OptionalAmount };
