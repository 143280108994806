import { ordersMainQueryKey } from "@@/orders/order-fetchers";
import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { apiFetchClient } from "@@/shared/fetch-client";
import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { UseQueryResult } from "@tanstack/react-query";
import {
    AccessKeyId,
    emptyArrayOf,
    GetResponse,
    OrderExternalAccess,
    OrderId,
} from "@towni/common";

const fetchOrderExternalAccessesForOrder =
    (orderId: OrderId | undefined, accessKeyId?: AccessKeyId) => async () => {
        if (typeof orderId === "undefined")
            return emptyArrayOf<OrderExternalAccess>();

        const data = await apiFetchClient.get<GetResponse<OrderExternalAccess>>(
            {
                route: `/orders/external-access/${encodeURIComponent(orderId)}${
                    accessKeyId ? `?ak=${accessKeyId}` : ""
                }`,
            }
        );
        return data.items;
    };

const useOrderExternalAccessesForOrder = (
    orderId?: OrderId
): readonly [OrderExternalAccess[], UseQueryResult<OrderExternalAccess[]>] => {
    const id = orderId ?? "";
    const accessKeyId = useAccessKeyIdFromUrl();
    return useApiResources<OrderExternalAccess>(
        createQueryKey(ordersMainQueryKey, id),
        fetchOrderExternalAccessesForOrder(orderId, accessKeyId),
        {
            enabled: !!orderId,
        }
    );
};

export { useOrderExternalAccessesForOrder };
