import { VerticalDivider } from "@@/shared/dividers";
import { ForEach } from "@@/shared/for-each";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { TextBox } from "@@/shared/text";
import { AspectRatio, RemSize, translation } from "@towni/common";
import { BookableGridProductExtraCard } from "./bookable-grid-product-extra-card";
import { useBookingContext } from "./booking-context";
import { useAvailableExtrasQuery } from "./use-available-extras";
type Props = {
    aspectRatio?: AspectRatio;
};
const BookableExtrasGrid = (props: Props) => {
    const product = useBookingContext(state => state.product);
    const availableProductIdsQuery = useAvailableExtrasQuery(product);
    if (availableProductIdsQuery.isInitialLoading)
        return (
            <TextShimmer rows={3} css={{ paddingLeft: 10, paddingRight: 10 }} />
        );
    if (!availableProductIdsQuery.data?.items.length) return null;
    const extrasProductIds = availableProductIdsQuery.data.items;
    return (
        <>
            <TextBox
                text={translation({
                    sv: "Lägg till",
                    en: "Add",
                })}
                size={1.25 as RemSize}
                weight="900"
            />
            <VerticalDivider M />
            <LayoutGrid
                css={{
                    gridTemplateColumns: "1fr 1fr",

                    gap: 20,
                }}>
                <ForEach
                    itemOf={extrasProductIds}
                    getKey={productId => productId}>
                    {(productId, _index) => {
                        return (
                            <LayoutCell css={{ position: "relative" }}>
                                <BookableGridProductExtraCard
                                    aspectRatio={props.aspectRatio}
                                    productId={productId}
                                />
                            </LayoutCell>
                        );
                    }}
                </ForEach>
            </LayoutGrid>
        </>
    );
};

export { BookableExtrasGrid };
