import { browserLogger } from "@@/settings/browser-logger";
import * as Sentry from "@sentry/react";

const KittensAndRainbowsError = () => {
    const error = new Error("Kittens and rainbows are wrong");
    Sentry.captureException(error);
    browserLogger.error(error);
    throw error;
};

export { KittensAndRainbowsError };
