import { useContainerNavigationContext } from "@@/pages/container-navigation.context";
import { Anchor } from "@@/pages/container-navigation/anchor";
import { PageLoad } from "@@/pages/page-load";
import { SectionById } from "@@/sections";
import { BreadCrumbPathProvider } from "@@/shared/bread-crumb-id-context";
import { VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useObjectTranslator } from "@@/translations/use-object-translator";
import {
    ImageReference,
    SectionGroupId,
    // parseStyles,
    Section as TSection,
} from "@towni/common";
import { useCallback } from "react";
import { useSectionGroup } from "./state/section-group-fetchers";

type Props = {
    sectionGroupId: SectionGroupId;
    posterImage?: ImageReference;
    anchor?: Anchor;
};
type SectionLookup = { [sectionId: string]: TSection };

const SectionGroup = ({ sectionGroupId, anchor }: Props) => {
    // const theme = useTheme();
    const [sectionGroup, { status, error, isLoading }] =
        useSectionGroup(sectionGroupId);
    const objectTranslator = useObjectTranslator();
    const setAnchorContainerItemElement = useContainerNavigationContext(
        context => context.setAnchorContainerItemElement,
    );

    const anchorRef = useCallback(
        (anchorElement: HTMLDivElement) => {
            if (!anchor || !anchorElement) return;
            setAnchorContainerItemElement(anchor._id)(anchorElement);
        },
        [anchor],
    );

    if (isLoading) return <PageLoad />;
    switch (status) {
        case "success": {
            if (!sectionGroup) return null;

            const content = sectionGroup.items
                // .sort(sortBy(item => item.sortNumber))
                .map(item => {
                    switch (item.type) {
                        case "SECTION":
                            return (
                                <SectionById
                                    key={item.itemId}
                                    sectionId={item.itemId}
                                />
                            );
                        case "SECTION_GROUP":
                            return (
                                <SectionGroup
                                    key={item.itemId}
                                    sectionGroupId={item.itemId}
                                />
                            );
                        default:
                            throw new Error("NOT IMPLEMENTED");
                    }
                });

            const meta = objectTranslator(sectionGroup.meta);
            return (
                <ErrorBoundary>
                    <BreadCrumbPathProvider node={sectionGroupId}>
                        <FlexColumn
                            ref={anchorRef}
                            crossAxis="stretch"
                            background={meta.background}>
                            <VerticalDivider L />
                            <TextBox
                                text={meta.title}
                                padding={{ leftRight: 20 }}
                                size={1.4}
                                weight="700"
                                // styling={parseStyles(meta.title)}
                            />
                            {meta.subtitle && (
                                <>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={meta.subtitle}
                                        padding={{ leftRight: 20 }}
                                        // styling={parseStyles(meta.subtitle)}
                                    />
                                </>
                            )}
                            {meta.description && (
                                <>
                                    <VerticalDivider S />
                                    <TextBox
                                        text={meta.description}
                                        padding={{ leftRight: 20 }}
                                        // styling={parseStyles(meta.description)}
                                    />
                                </>
                            )}
                            <VerticalDivider />
                            {content}
                        </FlexColumn>
                    </BreadCrumbPathProvider>
                </ErrorBoundary>
            );
        }
        case "error":
        default:
            throw (
                error ||
                new Error("SectionGroup render failed; " + sectionGroupId)
            );
    }
};

export { SectionGroup };
export type { SectionLookup };
