import { useEffect, useState } from "react";

const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(
        !!window?.matchMedia?.(query).matches,
    );
    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);
        setMatches(mediaQueryList.matches);
        const handleChange = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", handleChange);
            return () =>
                mediaQueryList.removeEventListener("change", handleChange);
        } else {
            //Fallback for old safari browsers, safari 13 and below
            mediaQueryList.addListener(handleChange);
            return () => mediaQueryList.removeListener(handleChange);
        }
    }, [query]);
    return matches;
};

export { useMediaQuery };
