import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { UseSetStateResult, useSet } from "@@/shared/use-set";
import { createContext, useContext } from "react";

const FormFieldIdsContext = createContext<
    UseSetStateResult<FieldId> | undefined
>(undefined);
const FormFieldIdsProvider = (props: {
    formId?: FormId;
    initialFieldIds?: FieldId[];
    children: React.ReactNode;
}) => {
    const fieldIds = useSet(new Set(props.initialFieldIds));
    const contextFormId = useFormId({ doNotThrow: true });
    const _formId = props.formId ?? contextFormId;
    if (!_formId)
        throw new Error("Form id not set and no form id provider is available");

    return (
        <FormFieldIdsContext.Provider value={fieldIds}>
            {props.children}
        </FormFieldIdsContext.Provider>
    );
};

const useFormFieldIds = (formId?: FormId) => {
    const contextFormId = useFormId({ doNotThrow: true });
    const _formId = formId ?? contextFormId;
    if (!_formId)
        throw new Error("Form id not set and no form id provider is available");
    return useContext(FormFieldIdsContext);
};

const FormIdContext = createContext<FormId | undefined>(undefined);
const FormIdProvider = (props: {
    formId: FormId;
    children: React.ReactNode;
}) => {
    return (
        <FormIdContext.Provider value={props.formId}>
            <FormFieldIdsProvider formId={props.formId}>
                {props.children}
            </FormFieldIdsProvider>
        </FormIdContext.Provider>
    );
};

function useFormId(): FormId;
function useFormId<T extends boolean>(options: {
    requestedFormId?: FormId;
    doNotThrow?: T;
}): T extends true ? FormId | undefined : FormId;
function useFormId<T extends boolean>(options?: {
    requestedFormId?: FormId;
    doNotThrow?: T;
}): FormId | undefined {
    const requestedFormId = options?.requestedFormId;
    const _formId = useContext(FormIdContext);
    const formId = requestedFormId || _formId;
    if (typeof formId === "undefined" && options?.doNotThrow) return undefined;
    if (typeof formId === "undefined") {
        throw new Error("useFormId must be used within a FormIdProvider");
    }
    return formId;
}

export { FormIdContext, FormIdProvider, useFormFieldIds, useFormId };
