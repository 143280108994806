import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { ContactProvider } from "@@/providers/contact-provider";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { ImageShimmer, TextShimmer } from "@@/shared/pictures/shimmers";
import { Pillify } from "@@/shared/pillify";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useNavigateTo } from "@@/shared/text/navigate-to";
import { LogoCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { Order, RemSize, translation } from "@towni/common";

type Props = {
    order: Order;
};

const Receipt_OrderProviderDetails = (props: Props) => {
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const navigateTo = useNavigateTo();
    const { order } = props;
    const [provider, providerQuery] = useProvider(order.providerId);

    const logo = provider?.logoImageRef;
    if (!provider && !providerQuery.isPending)
        throw (
            providerQuery.error ||
            new Error("Provider not found; " + order.providerId)
        );

    const providerHomePath = useHomePath(provider);

    return (
        <LayoutGrid
            css={{
                flex: 1,
                padding: 0,
                columnGap: 20,
                label: "order_group_details_header_grid",
                gridTemplateColumns: isDesktop
                    ? "min-content 1fr min-content"
                    : "1fr",
                gridTemplateRows: "min-content",
                gridTemplateAreas: isDesktop
                    ? `
                        "logo provider-title contact"
                        "logo provider-subtitle contact"
                    `
                    : `
                    "logo"
                    "provider-title"
                    "provider-subtitle"
                    "contact"
                `,
            }}>
            <LayoutCell
                css={{
                    gridArea: "logo",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    display: "flex",
                    paddingBottom: 8,
                }}>
                {/* Logo */}
                <Conditional
                    when={!providerQuery.isPending}
                    render={() => (
                        <LogoCircle
                            onClick={() => {
                                if (!provider) return;
                                navigateTo(providerHomePath);
                            }}
                            logo={logo}
                            size={
                                isDesktop ? (0.5 as RemSize) : (0.5 as RemSize)
                            }
                        />
                    )}
                    else={() => (
                        <ImageShimmer
                            width={"80px"}
                            height={"80px"}
                            radius={10}
                        />
                    )}
                />
            </LayoutCell>
            <LayoutCell
                css={{
                    gridArea: "provider-title",
                    justifyContent: isDesktop ? "flex-start" : "center",
                    alignItems: "flex-end",
                    display: "flex",
                }}>
                {/* Provider title */}
                <FlexColumn
                    crossAxis={isDesktop ? "flex-start" : "center"}
                    onClick={() => {
                        if (!provider) return;
                        navigateTo(providerHomePath);
                    }}>
                    <Conditional
                        when={!providerQuery.isPending}
                        render={() => (
                            <TextBox
                                text={provider?.meta.title}
                                size={1.8}
                                weight="700"
                            />
                        )}
                        else={() => <TextShimmer rows={2} />}
                    />
                </FlexColumn>
            </LayoutCell>
            <LayoutCell
                css={{
                    gridArea: "provider-subtitle",
                    height: "max-content",
                    display: "flex",
                    justifyContent: isDesktop ? "flex-start" : "center",
                    alignItems: "flex-end",
                    flex: 1,
                    paddingTop: 3,
                }}>
                {/* Provider subtitle */}
                <FlexColumn crossAxis={isDesktop ? "flex-start" : "center"}>
                    <Conditional
                        when={!providerQuery.isPending}
                        render={() => (
                            <TextBox
                                text={provider?.meta.subtitle}
                                size={1.2}
                                css={{ opacity: 0.7 }}
                            />
                        )}
                        else={() => <TextShimmer rows={2} />}
                    />
                </FlexColumn>
            </LayoutCell>

            <LayoutCell
                css={{
                    gridArea: "contact",
                    display: "flex",
                    paddingTop: isDesktop ? 0 : 10,
                    justifyContent: isDesktop ? "flex-end" : "center",
                }}>
                <FlexColumn
                    crossAxis={isDesktop ? "flex-end" : "center"}
                    padding={{ all: isDesktop ? 0 : 10 }}
                    css={{ flexShrink: 0 }}>
                    {/* Contact */}
                    {provider ? (
                        <Pillify>
                            <ContactProvider provider={provider} />
                        </Pillify>
                    ) : null}
                    <VerticalDivider M />
                    <TextBox
                        text={translation({
                            sv: "Orderreferens:",
                            en: "Order reference:",
                        })}
                    />
                    <VerticalDivider XXS />
                    <TextBox
                        disableAutoTranslate
                        text={order.orderGroupReference}
                        case="UPPERCASE"
                        weight="700"
                    />
                </FlexColumn>
            </LayoutCell>
        </LayoutGrid>
    );
};

export { Receipt_OrderProviderDetails };
