/* eslint-disable @typescript-eslint/no-unused-vars */
import { useResourceReservationRequestInfoForProduct } from "@@/resource-reservations/state/resource-reservation-info/use-resource-reservation-info-for-product";
import { useBookableResources } from "@@/resources/resources/use-resources";
import {
    BookableSession,
    BookableSettings,
    DurationInMinutes,
    isSessionAvailable,
    minSessionTimeRange,
    sortBy,
} from "@towni/common";
import { useMemo } from "react";
import { useBookingContext } from "../booking-context";

const useBookingForSessions = () => {
    const state = useBookingContext(state => ({
        sku: state.sku,
        session: state.session,
        bookable: state.bookable,
        product: state.product,
        sessions: state.sessions,
        ignoreOrder: state.ignoreOrder,
    }));

    const [globalResources, { isPending: isLoadingGlobalResources }] =
        useBookableResources(state.sku.productId);

    const [
        resourceReservationInfo,
        { isLoading: isLoadingResourceReservationInfo },
    ] = useResourceReservationRequestInfoForProduct(
        state.product._id,
        state.ignoreOrder,
    );
    const isLoading =
        isLoadingGlobalResources || isLoadingResourceReservationInfo;

    const sessions = useMemo(() => {
        if (isLoading) return [];

        const result = state.sessions
            .filter((session: BookableSession) => {
                const override: BookableSettings = {
                    ...session.bookableSettings,
                    advanceNotice: 0 as DurationInMinutes,
                    prepTime: 0 as DurationInMinutes,
                };
                return isSessionAvailable({
                    settings: override,
                    timeRange: minSessionTimeRange(session),
                    ignoreSessionAvailability: false,
                });
            })
            .sort(sortBy(f => f.start.unix))
            .filter(f => f.status.type === "ACTIVE");

        return result;
    }, [state.sessions, isLoading]);

    return [sessions, isLoading] as const;
};

export { useBookingForSessions };
