import { ColorPicker } from "@@/shared/colors/color-picker";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";
import { HexColor, RequiredTranslatable } from "@towni/common";
import { Draft } from "immer";
import { ZodSchema } from "zod";

type Value = HexColor | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly title: RequiredTranslatable;
    readonly defaultColor?: HexColor;
};

const Form2ColorPicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    return (
        <FlexColumn
            className={props.className}
            key={`${formId}_${props.fieldId}`}
            crossAxis="stretch">
            <ColorPicker
                key={`${formId}_${props.fieldId}`}
                color={field.value}
                title={props.title}
                onSelect={hexColor => {
                    field.setValue(hexColor);
                }}
            />
        </FlexColumn>
    );
};

export { Form2ColorPicker };
