import { ProductPicker } from "@@/products/picker/product-picker";
import { useProductsForProvider } from "@@/products/state/queries/products-for-provider/use-products-for-provider";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import {
    Product,
    ProductId,
    ProviderId,
    emptyArrayOf,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { useState } from "react";
import { ZodSchema } from "zod";

type Value = ProductId | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly productsToPickFrom?: Product[];
    readonly spin?: boolean;
    readonly disabled?: boolean;
    readonly providerId?: ProviderId;
};

const Form2ProductPickerSingle = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const provider = useProviderFromContext();
    const providerId = props.providerId || provider._id;
    if (!providerId)
        throw new Error("ProviderId is required to fetch products");

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const _disabled = props.disabled || props.spin || field.isSubmitting;
    const [__, productQuery] = useProductsForProvider(
        providerId,
        undefined,
        !!props.productsToPickFrom,
    );
    const spin = productQuery.isPending || !!props.spin || field.isSubmitting;
    const [initiallySelected] = useState(
        new Set<ProductId>([
            ...(field.initialValue ? [field.initialValue] : []),
        ]),
    );
    const productsToPickFrom =
        props.productsToPickFrom ??
        productQuery.data ??
        emptyArrayOf<Product>();

    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
        customFieldValidationErrorMessage: translation({
            sv: "Du måste välja en produkt",
            en: "You must choose a product",
        }),
    });

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            className={props.className}
            crossAxis="stretch">
            <ProductPicker
                key={productQuery.isPending ? "pending" : "ready"}
                title={translation({
                    sv: "Produkt",
                    en: "Product",
                })}
                spin={spin}
                mode="SINGLE"
                required={field.isRequired}
                initiallySelected={initiallySelected}
                products={productsToPickFrom}
                onChange={selected => {
                    field.setValue(selected?.[0]);
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2ProductPickerSingle };
