import { useTheme } from "@emotion/react";
import { Except } from "type-fest";
import type { ButtonColorPropNames, ButtonProps_v2 } from "./button";
import { Button_v2 } from "./button";

const ButtonPrimary = (props: Except<ButtonProps_v2, ButtonColorPropNames>) => {
    const theme = useTheme();
    return (
        <Button_v2 {...props} colorSet={theme.colors.primary}>
            {props.children}
        </Button_v2>
    );
};

export { ButtonPrimary };
