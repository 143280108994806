import { ResourceIcon } from "@@/backoffice/for-providers/inventory/resources/resource-icon";
import { useLanguageToEditContext } from "@@/backoffice/for-providers/shared/language-to-edit.context";
import { SmallGrip } from "@@/pages/grips";
import { useLanguageContext } from "@@/settings/language-context";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    RemSize,
    ResourceGlobal,
    ResourceId,
    valueTranslator,
} from "@towni/common";

type Props = {
    resource: ResourceGlobal;
    toggle: (resourceId: ResourceId) => void;
    dragListeners?: DraggableSyntheticListeners;
};

const ResourcePickedRow = (props: Props) => {
    const theme = useTheme();
    const { resource, toggle } = props;
    const inactive = false; // resource.status.type !== "ACTIVE";
    const { language } = useLanguageToEditContext();
    const langContext = useLanguageContext();
    const translator = valueTranslator(language || langContext.language);
    const resourceColor =
        props.resource._type === "RESOURCE_GROUP"
            ? theme.colors.secondary.main.value.withAlpha(0.6).asString
            : theme.colors.primary.main.value.withAlpha(0.6).asString;

    return (
        <FlexRow
            fillParentWidth
            radius={7}
            background={{
                color: inactive
                    ? theme.colors.disabled.background
                    : theme.colors.default.background,
            }}
            css={{
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.colors.default.border.asString,
                borderLeft: `7px solid ${resourceColor}`,
            }}
            mainAxis="space-between"
            crossAxis="stretch"
            {...(props.dragListeners ?? {})}>
            <Conditional
                when={!!props.dragListeners}
                render={() => (
                    <FlexColumn
                        mainAxis="center"
                        {...props.dragListeners}
                        css={{
                            // backgroundColor: resourceColor,
                            paddingLeft: 10,
                            paddingRight: 12,
                        }}>
                        <SmallGrip
                            size={0.8 as RemSize}
                            color={theme.colors.black.light70.asString}
                            dragListeners={props.dragListeners}
                        />
                    </FlexColumn>
                )}
            />
            <FlexRow css={{ flex: 1, paddingTop: 15, paddingBottom: 15 }}>
                <ResourceIcon resource={resource} />
                <HorizontalDivider S />
                <TextBox
                    text={translator(resource.meta.title)}
                    color={
                        inactive
                            ? theme.colors.disabled.text
                            : theme.colors.default.text
                    }
                    lineClamp={2}
                    shrink={1}
                    grow={1}
                />
                <HorizontalDivider />
            </FlexRow>
            <ButtonTransparent
                onPointerDown={event => {
                    // Let's ignore any drag events form dnd-kit
                    event.stopPropagation();
                }}
                onClick={event => {
                    event.stopPropagation();
                    toggle(resource._id);
                }}>
                <Icon
                    icon={faTimes}
                    cursor="pointer"
                    color={theme.colors.black.light60}
                    padding={{ all: 0 }}
                />
            </ButtonTransparent>
        </FlexRow>
    );
};

export { ResourcePickedRow };
