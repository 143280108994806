import { useTheme } from "@emotion/react";
import { colorAsString, ColorItem, Padding, SizeName } from "@towni/common";
import { paddingToCssValue } from "../padding";

type HorizontalProps = { [key in SizeName]?: boolean } & {
    size?: number;
    color?: ColorItem;
    padding?: Padding;
    fillParentWidth?: boolean;
    className?: string;
};

const HorizontalLine = (props: HorizontalProps) => {
    const theme = useTheme();
    const { fillParentWidth } = props;

    const backgroundColor = props.color || theme.colors.default.border;
    const color =
        (backgroundColor && colorAsString(backgroundColor)) || "unset";

    return (
        <div
            className={props.className}
            css={{
                height: props.size ?? 4,
                maxHeight: props.size ?? 1,
                minHeight: props.size ?? 1,
                flexShrink: 0,
                backgroundColor: color,
                padding: paddingToCssValue(props.padding),
                width: fillParentWidth ? "100%" : undefined,
            }}
        />
    );
};

export { HorizontalLine };
