import { browserLogger } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexAlignment, FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useTheme } from "@emotion/react";
import {
    HeaderPageBlock_V1,
    HzAlignment,
    PageBlockTitleSize,
    RemSize,
    assertNever,
    remSize,
} from "@towni/common";
import { usePageViewContext } from "../../../../editor/page-view-context";
import { Header_PageBlock_Provider_v1 } from "./header.page-block.view-context";

type Props = {
    block: HeaderPageBlock_V1;
};

const hzAlignmentToFlex = (
    hzAlignment: HzAlignment,
    isSmallView: boolean,
): FlexAlignment => {
    switch (hzAlignment) {
        case "center":
            return "center";
        case "left":
            return "flex-start";
        case "right":
            return "flex-end";
        case "auto":
            return isSmallView ? "flex-start" : "center";
        default:
            browserLogger.error(`Unknown horizontal alignment: ${hzAlignment}`);
            assertNever(hzAlignment);
    }
};

const hzTextAlignment = (
    hzAlignment: HzAlignment,
    isSmallView: boolean,
): Exclude<HzAlignment, "auto"> => {
    switch (hzAlignment) {
        case "auto":
            return isSmallView ? "left" : "center";
        case "center":
            return hzAlignment;
        case "left":
            return hzAlignment;
        case "right":
            return hzAlignment;
        default:
            browserLogger.error(`Unknown horizontal alignment: ${hzAlignment}`);
            assertNever(hzAlignment);
    }
};

const subtitleTextSizeRatio = (size: PageBlockTitleSize): number => {
    switch (size) {
        case "XS":
            return 0.83;
        case "S":
            return 0.85;
        case "M":
            return 1;
        case "L":
            return 1.1;
        case "XL":
            return 1.25;
        default:
            assertNever(size);
    }
};

const textSizeRatio = (size: PageBlockTitleSize): number => {
    switch (size) {
        case "XS":
            return 0.75;
        case "S":
            return 0.8;
        case "M":
            return 1;
        case "L":
            return 1.2;
        case "XL":
            return 1.5;
        default:
            assertNever(size);
    }
};
const textSize = (size: PageBlockTitleSize, medium = 2): RemSize => {
    const ratio = textSizeRatio(size);
    return remSize(medium * ratio);
};

const Header_PageBlock_View_v1 = (props: Props) => {
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { pageInnerWidth, pageMaxWidth } = usePageViewContext();
    const block = props.block;
    const theme = useTheme();
    const horizontally = hzAlignmentToFlex(block.alignment, isMobile);

    const [imageReference] = useStorageItemImageReference(
        block.backgroundImage,
    );

    const titleSize = textSize(block.titleSize, isMobile ? 1.5 : 2);
    const subtitleSize = subtitleTextSizeRatio(block.titleSize) * 1.125;

    const title = (
        <TextBox
            text={block.title}
            color={block.textColor ?? theme.colors.black.asString}
            size={titleSize}
            weight="700"
            align={hzTextAlignment(block.alignment, isMobile)}
            css={{
                textShadow: block.textShadow
                    ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                    : undefined,
            }}
        />
    );

    const subtitle = (
        <TextBox
            text={block.subtitle}
            color={block.textColor ?? theme.colors.black.asString}
            size={subtitleSize}
            weight="500"
            align={hzTextAlignment(block.alignment, isMobile)}
            css={{
                opacity: 0.6,
                lineHeight: 1.3,
                textShadow: block.textShadow
                    ? "0px 4px 20px rgba(5, 32, 72, 0.6)"
                    : undefined,
            }}
        />
    );

    return (
        <Header_PageBlock_Provider_v1 block={block}>
            <FlexRow
                fillParentWidth
                mainAxis={"center"}
                crossAxis={"center"}
                padding={{ all: 0 }}
                shrink={0}
                css={{ label: "header" }}
                background={
                    imageReference
                        ? {
                              imageReference,
                              fit: "COVER",
                              imageSize: {
                                  imageWidth: isMobile
                                      ? breakpointRange.MOBILE.max
                                      : Math.min(
                                            breakpointRange.DESKTOP.max,
                                            pageMaxWidth,
                                        ),
                              },
                          }
                        : {
                              color:
                                  block.backgroundColor ??
                                  theme.colors.default.background,
                          }
                }>
                <FlexRow
                    fillParent
                    maxWidth={pageInnerWidth}
                    mainAxis={horizontally ?? "flex-start"}
                    padding={{ all: 20 }}
                    crossAxis={"center"}>
                    <FlexColumn fillParentWidth>
                        {block.subtitlePlacement === "above" ? subtitle : title}
                        <VerticalDivider size="XS" />
                        {block.subtitlePlacement === "below" ? subtitle : title}
                    </FlexColumn>
                </FlexRow>
            </FlexRow>
        </Header_PageBlock_Provider_v1>
    );
};

export { Header_PageBlock_View_v1 };
