import { apiFetchClient } from "@@/shared/fetch-client";
import { useApiResources } from "@@/shared/state/use-api-resources";
import {
    GetResponse,
    OrderGroupId,
    OrderId,
    TicketGiftCard,
} from "@towni/common";
export const giftcardsMainQueryKey = "giftcards";

const fetch =
    (search: (OrderId | OrderGroupId | string | undefined)[]) => async () => {
        if (!search) throw new Error("missing orderId id");
        const data = await apiFetchClient.get<GetResponse<TicketGiftCard>>({
            route: `/tickets/giftcards/for-many/${encodeURIComponent(
                search.join(",")
            )}`,
        });
        return data.items;
    };

const useManyGiftcardsForSearch = (
    search: (OrderId | OrderGroupId | string | undefined)[]
) =>
    useApiResources<TicketGiftCard>(
        [giftcardsMainQueryKey, ...search],
        fetch(search),
        {
            enabled: !!search.length,
        }
    );

export { useManyGiftcardsForSearch };
