import { browserLogger } from "@@/settings";
import {
    DiscountCode,
    OrderItemDiscount_V2,
    OrderItem_V2,
    Price,
    ProviderId,
    conditionFilter,
    defaultAmount,
    emptyArrayOf,
    groupBy,
    isOrderItemDiscount_V2,
    isOrderItemGiftCard_V2,
    orderItemDiscountFactory_V2,
    priceFactory,
    sumPrices,
    zeroPrice,
} from "@towni/common";

const calculateDiscountRows = (
    providerId: ProviderId,
    orderItems: OrderItem_V2[],
    discountCode: DiscountCode,
) => {
    if (orderItems.some(isOrderItemDiscount_V2))
        return emptyArrayOf<OrderItemDiscount_V2>();

    const entitledItems = conditionFilter(discountCode, orderItems);
    browserLogger.info({ orderItems, entitledItems });

    const prices = entitledItems
        .filter(oi => !isOrderItemDiscount_V2(oi))
        .filter(oi => !isOrderItemGiftCard_V2(oi))
        .map(oi => oi.price);

    const price = calculateDiscountPrice(prices, discountCode);

    const item = orderItemDiscountFactory_V2({
        meta: discountCode.meta,
        providerId: providerId,
        quantity: {
            amount: defaultAmount,
            value: 1,
        },
        messageToProvider: null,
        price: price,
        pricePerItem: price,
        discounts: [],
        discountCodeId: discountCode._id,
        code: discountCode.code,
        type: discountCode.type,
        value: discountCode.value,
    });
    return [item];
};

const calculateDiscountPrice = (
    prices: Price[],
    discountCode: DiscountCode,
) => {
    const totalCost = sumPrices(prices);

    const maxDiscountAmount =
        discountCode.type === "PERCENTAGE"
            ? totalCost.amountIncludingVat * Math.min(discountCode.value, 1) //Cap 100%
            : Math.min(discountCode.value, totalCost.amountIncludingVat);
    //To string to avoid 0 being removed as falsy, as we want to keep 0 vat
    const groupPrice = groupBy(prices, p => p.vat.toString());

    const vatKeys = Array.from(groupPrice.keys());

    //Group by vat to avoid rounding errors as much as possible
    //Can probably be done better, may need to have a cap/price rounding thingy
    const totalDiscount = sumPrices(
        vatKeys.map(k => {
            const prices = groupPrice.get(k);
            if (!prices) return zeroPrice;

            const price = sumPrices(prices);
            if (price.amountIncludingVat === 0) return zeroPrice;

            const howMuch =
                price.amountIncludingVat / totalCost.amountIncludingVat;

            const discountAmount =
                discountCode.type === "PERCENTAGE"
                    ? price.amountIncludingVat * Math.min(discountCode.value, 1)
                    : maxDiscountAmount * howMuch;

            const amount = priceFactory({
                amountIncludingVat: -discountAmount,
                vat: price.vat,
            });
            return amount;
        }),
    );

    // Old code
    // const _price = sumPrices(
    //     prices.map(a => {
    //         //Calculate how much of this price is of the total, to get a weight vat
    //         const howMuch = a.amountIncludingVat / totalCost.amountIncludingVat;

    //         const discountAmount =
    //             discountCode.type === "PERCENTAGE"
    //                 ? a.amountIncludingVat * discountCode.value
    //                 : maxDiscountAmount * howMuch;

    //         const amount = priceFactory({
    //             amountIncludingVat: -Math.ceil(discountAmount),
    //             vat: a.vat,
    //         });
    //         return amount;
    //     }),
    // );

    return totalDiscount;
};

export { calculateDiscountPrice, calculateDiscountRows };
