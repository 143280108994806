import { useZonesForZip } from "@@/delivery/delivery-zone-fetcher";
import { useCustomerShippingForZones } from "@@/delivery/shipping-fetcher";
import { useOrdersForMe } from "@@/orders/order-fetchers";
import { useLanguageContext } from "@@/settings/language-context";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { SelectBox } from "@@/shared/select-box";
import { TextBox, TextSpan } from "@@/shared/text";
import { usePriceFormatter } from "@@/translations/use-price-formatter";
import { useTranslate } from "@@/translations/use-translate";
import { SerializedStyles, css, useTheme } from "@emotion/react";
import {
    Address,
    ColorItem,
    DeliveryPrice,
    DeliveryZone,
    DurationInMinutes,
    IsoAndUnixTimestamp,
    Order,
    Price,
    Provider,
    Shipping,
    ShippingSlot,
    Time,
    TranslatableValue,
    asDate,
    capitalCase,
    cutOffByProvider,
    getNameOfDay,
    getTimeOfDay,
    isExpressShipping,
    isLoopShipping,
    isoAndUnixFactory,
    now,
    ordersForMyAddress,
    shippingPriceByOrder,
    shippingSlotStatus,
    sortBy,
    timeToString,
    toFullDate,
    translation,
} from "@towni/common";
import { isPast, isToday, isTomorrow, subMinutes } from "date-fns";
import * as React from "react";
import { useMemo } from "react";
import { useDeliveryDispatch, useDeliveryState } from "./delivery-context";
import { humanizeDate } from "./pickup-time-picker";

type Props = {
    provider: Provider;
};

type ShippingAndSlot = {
    slot: ShippingSlot | undefined;
    shipping: Shipping;
};

const useOrdersForMyAddress = (deliveryAddress?: Address) => {
    const [orders] = useOrdersForMe();

    const ordersNotFulfilled = useMemo(() => {
        return ordersForMyAddress(orders, deliveryAddress);
    }, [orders, deliveryAddress]);

    return ordersNotFulfilled;
};

const useShipmentOptions = (shippings: Shipping[]) => {
    const shippingGroupedByDay = useMemo(() => {
        return shippings
            .map(o => {
                if (isLoopShipping(o)) {
                    return o.availability.map(a => {
                        return {
                            slot: a,
                            shipping: o,
                        } as ShippingAndSlot;
                    });
                }

                return [{ slot: undefined, shipping: o } as ShippingAndSlot];
            })
            .flat()
            .filter(f => {
                return f.slot?.status !== "INACTIVE";
            })
            .reduce(
                (prev, current) => {
                    if (current.slot) {
                        const fullDate = toFullDate(
                            current.slot.slot.start,
                        ).toString();
                        prev[fullDate] = prev[fullDate] || [];
                        prev[fullDate].push(current);
                    } else {
                        const fullDate = toFullDate(now());
                        prev[fullDate] = prev[fullDate] || [];
                        prev[fullDate].push(current);
                    }
                    return prev;
                },
                {} as Record<string, ShippingAndSlot[]>,
            );
    }, [shippings]);

    return shippingGroupedByDay;
};

const ShipmentOptionPicker = (props: Props) => {
    const state = useDeliveryState();
    const dispatch = useDeliveryDispatch();

    const orders = useOrdersForMyAddress(
        state.homeDelivery.deliveryAddress?.address,
    );

    const [zones] = useZonesForZip(
        state.homeDelivery.deliveryAddress?.address.zipCode,
    );
    const [shippings, query] = useCustomerShippingForZones(
        zones.map(z => z._id),
        [props.provider._id],
    );

    React.useEffect(() => {
        if (state.homeDelivery.shippingId !== undefined && !query.isStale) {
            const shipping = shippings.find(
                s => s._id === state.homeDelivery.shippingId,
            );
            if (!shipping) dispatch.clearHomeDelivery();
        }
    }, [shippings, query.isStale]);

    const shipmentOptions = useShipmentOptions(shippings);

    return (
        <OptionList
            provider={props.provider}
            options={shipmentOptions}
            orders={orders}
            zones={zones}
            select={(slot, zone, shipping, price) =>
                dispatch.setHomeDelivery(slot, zone, shipping, price)
            }
            selected={(slot, shipping) => {
                return (
                    state.homeDelivery.shippingId === shipping._id &&
                    state.homeDelivery.slot?._id === slot?.slot._id
                );
            }}
        />
    );
};

type OptionListProps = {
    provider: Provider;
    options: Record<string, ShippingAndSlot[]>;
    orders: Order[];
    zones: DeliveryZone[];
    extraCost?: Price;
    select: (
        slot: Time | undefined,
        zone: DeliveryZone,
        shipping: Shipping,
        price: Price,
    ) => void;
    selected: (slot: ShippingSlot | undefined, shipping: Shipping) => boolean;
};

const OptionList = (props: OptionListProps) => {
    const { options, orders, zones } = props;

    const keys = Object.keys(options);

    return (
        <FlexColumn shrink={0} padding={{ leftRight: 20 }}>
            {keys.length === 0 ? (
                <NoShippingOption />
            ) : (
                keys.map((day, index) => {
                    const optionDay = options[day];

                    return (
                        <FlexColumn key={day}>
                            <DayHeader day={optionDay[0].slot?.slot.start} />

                            <VerticalDivider M />

                            {optionDay
                                .sort(
                                    sortBy(f =>
                                        f.slot ? f.slot.slot.start.unix : 0,
                                    ),
                                )
                                .map(f => {
                                    const cutOff = cutOffByProvider(
                                        props.provider,
                                        f.shipping,
                                    );
                                    const isLate = f.slot
                                        ? isPast(
                                              subMinutes(
                                                  asDate(f.slot?.slot.start),
                                                  cutOff,
                                              ),
                                          )
                                        : false;
                                    const zone =
                                        zones.find(z =>
                                            f.shipping.meta.tags.delivery.includes(
                                                z._id,
                                            ),
                                        ) ?? zones[0];

                                    const price = shippingPriceByOrder(
                                        f.shipping,
                                        f.slot,
                                        orders,
                                        zone?._id,
                                        props.extraCost,
                                    );

                                    return (
                                        <FlexColumn
                                            onClick={() => {
                                                if (
                                                    (f.slot === undefined ||
                                                        f.slot.status ===
                                                            "ACTIVE" ||
                                                        price.dicountFrom ===
                                                            "ALREADY_ORDERED") &&
                                                    !isLate
                                                ) {
                                                    props.select(
                                                        f.slot?.slot,
                                                        zone,
                                                        f.shipping,
                                                        price.price,
                                                    );
                                                }
                                            }}
                                            key={
                                                f.shipping._id +
                                                f.slot?.slot._id
                                            }
                                            padding={{ topBottom: 10 }}>
                                            <ShippingOption
                                                shipping={f.shipping}
                                                shippingSlot={f.slot}
                                                selected={props.selected(
                                                    f.slot,
                                                    f.shipping,
                                                )}
                                                cutOff={cutOff}
                                                isLate={isLate}
                                                price={price}
                                                overrideStatus={
                                                    price.dicountFrom ===
                                                    "ALREADY_ORDERED"
                                                        ? "ACTIVE"
                                                        : undefined
                                                }
                                            />
                                        </FlexColumn>
                                    );
                                })}
                            {index === 0 ? (
                                <FlexColumn padding={{ topBottom: 10 }}>
                                    <ExpressShippingOptionComingSoon />
                                </FlexColumn>
                            ) : null}
                            <VerticalDivider M />
                        </FlexColumn>
                    );
                })
            )}
        </FlexColumn>
    );
};

type optionProps = {
    shipping: Shipping;
    shippingSlot: ShippingSlot | undefined;
    selected: boolean;
    cutOff: DurationInMinutes;
    isLate: boolean;
    price: DeliveryPrice;
    overrideStatus?: shippingSlotStatus;
};

type expressProps = {
    selected: boolean;
    price: DeliveryPrice;
};

const timeFormat = (time?: Time) => {
    if (time) return timeToString(time);
    return "";
};

const dayFormat = (time?: Time) => {
    if (!time) return "";
    if (isToday(asDate(time.start))) return "Idag"; //Todo Översätt
    if (isTomorrow(asDate(time.start))) return "Imorgon"; //Todo Översätt
    return capitalCase(getNameOfDay(time.start)); //Todo Översätt
};

const formatOrderBefore = (
    time: Time | undefined,
    cutOf: DurationInMinutes,
) => {
    if (!time) return "";

    return getTimeOfDay(
        isoAndUnixFactory(subMinutes(asDate(time.start), cutOf)),
    );
};

type DayProps = {
    day: IsoAndUnixTimestamp | undefined;
};
const DayHeader = (props: DayProps) => {
    const translate = useTranslate();
    const { language } = useLanguageContext();

    if (props.day === undefined) return <FlexRow>{"???"}</FlexRow>;
    const date = asDate(props?.day);

    return (
        <FlexRow>
            <TextBox
                weight="700"
                text={humanizeDate(date, translate, language)}
            />
        </FlexRow>
    );
};

const NoShippingOption = () => {
    const theme = useTheme();
    const title = translation({
        sv: "Tid ej tillgänglig",
    });

    const subTitle = translation({
        sv: "Vald adress har för tillfället ingen tillgänglig frakttid",
    });

    return (
        <GenericBoxOptionsCenteredWithoutSelect
            styling={css`
                color: ${theme.colors.black.light70.asString};
                background: ${theme.colors.textInput.background.asString};
                border-color: ${theme.colors.black.light70.asString};

                border-style: solid;
                border-radius: 6px;
            `}
            title={title}
            titleColor={theme.colors.black.light75}
            subTitle={subTitle}
            subTitleColor={theme.colors.black.light75}
            selected={false}
        />
    );
};

const ShippingOption = (props: optionProps) => {
    const theme = useTheme();
    if (isExpressShipping(props.shipping))
        return (
            <ExpressShippingOption
                selected={props.selected}
                price={props.price}
            />
        );

    const status = props.overrideStatus ?? props.shippingSlot?.status;

    if (!props.shippingSlot) return <></>;

    if (status === "ACTIVE" && !props.isLate)
        return <LoopShippingOption {...props} />;

    if (status === "INACTIVE") return <></>;

    if (status !== "FULL" && props.isLate)
        return <ToLateLoopShippingOption {...props} />;

    const title = translation({
        sv:
            dayFormat(props.shippingSlot?.slot) +
            " " +
            timeFormat(props.shippingSlot?.slot),
    });

    const subTitle = translation({
        sv: "Fullbokad, nya platser kan släppas",
    });

    return (
        <GenericBoxOptions
            styling={css`
                color: ${theme.colors.black.light70.asString};
                background: ${theme.colors.textInput.background.asString};
                border-color: ${theme.colors.black.light70.asString};

                border-style: solid;
                border-radius: 6px;
            `}
            title={title}
            titleColor={theme.colors.black.light75}
            subTitle={subTitle}
            subTitleColor={theme.colors.black.light75}
            selected={props.selected}
        />
    );
};

const ExpressShippingOption = (props: expressProps) => {
    const theme = useTheme();
    const priceFormatter = usePriceFormatter();

    const expressColor = theme.colors.danger;
    const cutoffMinutes = 45; //TODO make dynamic

    const title = translation({
        sv: "Express inom ~" + cutoffMinutes + "min",
    });

    const subTitle = translation({
        sv:
            priceFormatter(props.price.price) +
            " - tid preciseras efter konfirmering",
    });

    return (
        <GenericBoxOptions
            styling={css`
                color: ${theme.colors.default.text.asString};
                background: ${props.selected
                    ? theme.colors.primary.main.value.withAlpha(0.1).asString
                    : theme.colors.textInput.background.asString};
                border-color: ${props.selected
                    ? theme.colors.primary.asString
                    : theme.colors.black.light70.asString};
                border-style: solid;
                border-radius: 6px;
            `}
            title={title}
            titleColor={expressColor}
            subTitle={subTitle}
            subTitleColor={theme.colors.default.text.value.withAlpha(0.6)}
            selected={props.selected}
        />
    );
};

const ExpressShippingOptionComingSoon = () => {
    const theme = useTheme();
    const title = translation({
        sv: "Express - kommer snart",
    });

    const subTitle = translation({
        sv: "fr 79kr - tid preciseras efter konfirmering",
    });

    return (
        <div
            css={css`
                opacity: 0.5;
            `}>
            <GenericBoxOptions
                styling={css`
                    color: ${theme.colors.black.light70.asString};
                    background: ${theme.colors.textInput.background.asString};
                    border-color: ${theme.colors.black.light70.asString};

                    border-style: solid;
                    border-radius: 6px;
                `}
                title={title}
                titleColor={theme.colors.black.light75}
                subTitle={subTitle}
                subTitleColor={theme.colors.black.light75}
                selected={false}
            />
        </div>
    );
};

const usePriceTextHelper = (price: DeliveryPrice) => {
    const priceFormatter = usePriceFormatter();
    const priceText =
        price.price.amountIncludingVat === 0
            ? "Gratis"
            : priceFormatter(price.price);

    return priceText;
};

const LoopShippingOption = (props: optionProps) => {
    const theme = useTheme();
    const title = translation({
        sv:
            dayFormat(props.shippingSlot?.slot) +
            " " +
            timeFormat(props.shippingSlot?.slot),
    });

    const priceText = usePriceTextHelper(props.price);
    const subTitle = translation({
        sv:
            " - beställ innan " +
            formatOrderBefore(props.shippingSlot?.slot, props.cutOff),
    });
    const subTitleOrderedBefore = translation({
        sv: " - beställ mer till denna tid",
    });

    if (!props.shippingSlot) return <></>;

    return (
        <GenericBoxOptions
            styling={css`
                color: ${theme.colors.default.text.asString};
                background: ${props.selected
                    ? theme.colors.primary.main.value.withAlpha(0.1).asString
                    : theme.colors.textInput.background.asString};
                border-color: ${props.selected
                    ? theme.colors.primary.asString
                    : theme.colors.black.light70.asString};
                border-style: solid;
                border-radius: 6px;
            `}
            title={title}
            titleColor={theme.colors.primary}
            subTitleColor={theme.colors.default.text.value.withAlpha(0.6)}
            selected={props.selected}
            subTitle={subTitle}
            subTitleChildren={[
                <TextSpan
                    key="1"
                    color={theme.colors.default.text.value.withAlpha(0.6)}
                    weight={props.price.discountedPrice ? "700" : "400"}
                    text={priceText}
                />,
                <TextSpan
                    key="2"
                    weight="500"
                    color={theme.colors.default.text.value.withAlpha(0.6)}
                    text={
                        props.price.discountedPrice
                            ? subTitleOrderedBefore
                            : subTitle
                    }
                />,
            ]}
        />
    );
};

const ToLateLoopShippingOption = (props: optionProps) => {
    const theme = useTheme();
    const title = translation({
        sv:
            "Ej tillgänglig - " +
            dayFormat(props.shippingSlot?.slot) +
            " " +
            timeFormat(props.shippingSlot?.slot),
    });
    const subTitle = translation({
        sv:
            usePriceTextHelper(props.price) +
            " - beställ innan " +
            formatOrderBefore(props.shippingSlot?.slot, props.cutOff),
    });

    if (!props.shippingSlot) return <></>;

    return (
        <GenericBoxOptions
            styling={css`
                color: ${theme.colors.black.light70.asString};
                background: ${theme.colors.textInput.background.asString};
                border-color: ${theme.colors.black.light70.asString};

                border-style: solid;
                border-radius: 6px;
            `}
            titleStyling={css`
                text-decoration: line-through;
            `}
            title={title}
            titleColor={theme.colors.black.light75}
            subTitle={subTitle}
            subTitleColor={theme.colors.black.light75}
            selected={false}
        />
    );
};

type GenericBoxOptionProps = {
    styling: SerializedStyles;
    titleStyling?: SerializedStyles;
    title: TranslatableValue;
    titleColor: ColorItem;
    subTitle: TranslatableValue;
    subTitleColor: ColorItem;
    selected: boolean;

    subTitleChildren?: JSX.Element[];
};

const GenericBoxOptions = ({
    styling,
    title,
    titleStyling,
    subTitle,
    subTitleColor,
    titleColor,
    selected,
    subTitleChildren,
}: GenericBoxOptionProps) => {
    const theme = useTheme();
    return (
        <FlexColumn styling={styling}>
            <FlexRow padding={{ topBottom: 19, leftRight: 18 }}>
                <SelectBox
                    selected={selected}
                    shape={"CIRCLE"}
                    size="L"
                    backgroundColor={theme.colors.textInput.background}
                />
                <HorizontalDivider S />
                <FlexColumn>
                    <TextBox
                        color={titleColor}
                        text={title}
                        weight="900"
                        size={"M"}
                        styling={titleStyling}
                    />
                    {subTitleChildren ? (
                        <TextBox size={0.875}>{subTitleChildren}</TextBox>
                    ) : (
                        <TextBox
                            text={subTitle}
                            size={0.875}
                            weight="500"
                            color={subTitleColor}
                        />
                    )}
                </FlexColumn>
            </FlexRow>
        </FlexColumn>
    );
};

const GenericBoxOptionsCenteredWithoutSelect = ({
    styling,
    title,
    titleStyling,
    subTitle,
    subTitleColor,
    titleColor,
}: GenericBoxOptionProps) => {
    return (
        <FlexColumn styling={styling} padding={{ all: 20 }}>
            <FlexColumn crossAxis="center">
                <TextBox
                    color={titleColor}
                    text={title}
                    weight="900"
                    align="center"
                    size={"ML"}
                    styling={titleStyling}
                />
                <VerticalDivider S />
                <TextBox
                    text={subTitle}
                    size={0.875}
                    weight="500"
                    align="center"
                    color={subTitleColor}
                />
            </FlexColumn>
        </FlexColumn>
    );
};

export { OptionList, ShipmentOptionPicker, ShippingOption, useShipmentOptions };
