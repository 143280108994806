import { useResourcesForResourceGroupQuery } from "@@/backoffice/for-providers/inventory/resources/use-resources-for-resource-group.query";
import { Conditional } from "@@/shared/conditional";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import {
    ColorItem,
    Resource,
    ResourceGlobal,
    colorAsString,
    isResourceAccommodation,
    isResourceGroup,
    resourceTypeMeta,
    type RemSize,
} from "@towni/common";
import { useMemo } from "react";

type Props = {
    resource: Resource | undefined;
    size?: number | RemSize;
    options?: {
        doNotUseChildTypeIconForResourceGroup?: boolean;
        ignoreOverlayForResourceGroup?: boolean;
    };
    color?: ColorItem;
    fixedWidth?: boolean;
};

const ResourceIcon = (props: Props) => {
    // const theme = useTheme();
    const resource = props.resource;
    const size = (props.size ?? 1) as RemSize;
    const tagSize = size * 0.6;

    const resourcesWithin = useResourcesForResourceGroupQuery(
        !props.options?.doNotUseChildTypeIconForResourceGroup &&
            isResourceGroup(resource)
            ? resource._id
            : undefined,
    );

    const icon = useMemo(() => {
        // Get the default resource type icon
        const defaultIcon = resource
            ? resourceTypeMeta[resource._type].iconName
            : resourceTypeMeta.RESOURCE_GENERIC.iconName;

        // If the resource is an accommodation,
        // use the accommodation type icon if it exists
        if (isResourceAccommodation(resource)) {
            return (
                resource.accommodationType.iconName ??
                // else fall back to default resource type icon
                defaultIcon
            );
        }

        if (isResourceGroup(resource)) {
            // What resources types are set within the group?
            const childResources = resourcesWithin.data ?? [];
            const counted = childResources.reduce(
                (counted, res) => {
                    counted[res._type] = [
                        (counted[res._type]?.[0] ?? 0) + 1,
                        res,
                    ] as const;
                    return counted;
                },
                {} as Record<ResourceGlobal["_type"], [number, ResourceGlobal]>,
            );
            const max = Object.entries(counted).reduce(
                (max, entry) => {
                    const [type, [count, resource]] = entry;
                    if (count > max.count) {
                        return {
                            type: type as ResourceGlobal["_type"],
                            count,
                            resource,
                        };
                    }
                    return max;
                },
                { count: 0, type: undefined, resource: undefined } as {
                    count: number;
                    type: ResourceGlobal["_type"] | undefined;
                    resource: ResourceGlobal | undefined;
                },
            );

            if (isResourceAccommodation(max.resource)) {
                return (
                    max.resource.accommodationType.iconName ??
                    // else fall back to default resource type icon
                    defaultIcon
                );
            }

            return max.type ? resourceTypeMeta[max.type].iconName : defaultIcon;
        }

        return resourceTypeMeta[resource?._type ?? "RESOURCE_GENERIC"].iconName;
    }, [resource, resourcesWithin.data]);
    const extra = isResourceGroup(resource) ? size * 16 * 0.3125 : 0;
    return (
        <div
            css={{
                position: "relative",
            }}>
            <Conditional
                when={
                    isResourceGroup(resource) &&
                    !props.options?.ignoreOverlayForResourceGroup
                }>
                <Icon
                    icon={faPlusCircle}
                    size={tagSize}
                    color={props.color ? colorAsString(props.color) : undefined}
                    fixedWidth={props.fixedWidth ?? true}
                    css={{
                        position: "absolute",
                        zIndex: 100,
                        top: -extra,
                        right: -extra,
                    }}
                />
            </Conditional>
            <DynamicIcon
                icon={icon}
                css={{
                    color: props.color ? colorAsString(props.color) : undefined,
                }}
                title={
                    resourceTypeMeta[resource?._type ?? "RESOURCE_GENERIC"]
                        .title
                }
                size={size}
                fixedWidth={props.fixedWidth ?? true}
            />
        </div>
    );
};

export { ResourceIcon };
