import { WithAlfred } from "@@/authentication/me/with-alfred";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { CheckmarkCircleSvg } from "@@/orders/order-groups/checkmark-circle-svg";
import { PageLoad } from "@@/pages/page-load";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import {
    BrandingProvider,
    useBrandingContext,
} from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { LinkTo } from "@@/shared/text/link-to";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import {
    DurationInSeconds,
    PayRequestForPayerProjection,
    PayRequestPaymentId,
    PayRequestStatusProjection,
    TranslatableValue,
    accessKeyIdZodSchema,
    assertNever,
    parseSafely,
    payRequestIdZodSchema,
    payRequestPaymentIdZodSchema,
    translation,
} from "@towni/common";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usePayRequestForPayerQuery } from "./use-pay-request-for-payer-query";
import { usePayRequestStatusQuery } from "./use-pay-request-status-query";
import { useRecheckPayRequestPaymentStatus } from "./use-recheck-pay-request-status";

type Props = {
    readonly payRequest: PayRequestForPayerProjection;
};

const PayRequestProcessing = (props: Props) => {
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const ak = parseSafely({
        schema: accessKeyIdZodSchema,
        value: searchParams.get("ak"),
    });
    const payRequestPaymentId = parseSafely({
        schema: payRequestPaymentIdZodSchema,
        value: searchParams.get("payReqPayId"),
    });

    const [provider, _providerQuery] = useProvider(
        props.payRequest.paymentFor.providerId
    );
    useRecheckPayRequestPaymentStatus(
        props.payRequest._id,
        payRequestPaymentId,
        30 as DurationInSeconds
    );
    const statusQuery = usePayRequestStatusQuery(
        props.payRequest._id,
        5 as DurationInSeconds
    );

    const { branding } = useBrandingContext();
    const backgroundCss = useBrandingBackgroundCss(
        branding?.receipt.background
    );

    const payRequestStatus = statusQuery.data?.item;
    const payRequestPaymentEvents = useMemo(
        () =>
            statusQuery.isInitialLoading
                ? []
                : payRequestPaymentId
                ? payRequestStatus?.paymentEvents
                      // Only check events for the current pay request payment id
                      // Not for other payments belonging to the same pay request
                      .filter(item => item.paymentId === payRequestPaymentId) ??
                  []
                : payRequestStatus?.paymentEvents ?? [],
        [
            statusQuery.isInitialLoading,
            payRequestStatus?.paymentEvents,
            payRequestPaymentId,
        ]
    );

    const paymentEvents = useMemo(() => {
        return payRequestPaymentEvents
            .sort((a, b) => {
                // First sort på happenedAt data
                if (a._happenedAt.iso > b._happenedAt.iso) return -1;
                if (a._happenedAt.iso < b._happenedAt.iso) return 1;

                // Then by event type, e.g. fulfilled should happen after all other payment events
                // and pending should happen before all other payment events
                if (a.status === "PENDING") return -1;
                if (a.status === "FULFILLED") return 1;
                return 0;
            })
            .reduce((grouped, event) => {
                const current = grouped.get(event.paymentId) ?? [];
                current.push(event);
                grouped.set(event.paymentId, current);
                return grouped;
            }, new Map<PayRequestPaymentId, PayRequestStatusProjection["paymentEvents"]>());
    }, [payRequestPaymentEvents]);

    const paymentIds = useMemo(
        () => Array.from(paymentEvents.keys()),
        [paymentEvents]
    );
    const navigate = useNavigate();
    const isFulfilled = payRequestPaymentEvents.some(
        item => item.status === "FULFILLED"
    );
    const successUrl = `/receipts/${props.payRequest.paymentFor.orderGroupId}${
        ak ? `?ak=${ak}` : ""
    }`;

    useEffect(() => {
        if (isFulfilled) {
            // alert("Payment is fulfilled");
            navigate(successUrl);
        }
    }, [
        props.payRequest.paymentFor.orderGroupId,
        payRequestStatus?.status,
        ak,
        navigate,
        isFulfilled,
        successUrl,
        payRequestPaymentEvents,
    ]);
    if (!provider) return <PageLoad />;

    return (
        <FlexColumn
            mainAxis="stretch"
            crossAxis="center"
            css={[
                {
                    width: "100%",
                    height: "100%",
                    backgroundColor: theme.colors.primary.asString,
                },
                backgroundCss,
            ]}>
            <GlobalMaxWidthCss />
            <FlexColumn
                crossAxis="stretch"
                css={{
                    margin: 40,
                    marginTop: 80,
                    borderRadius: 10,
                    padding: 20,
                    backgroundColor: theme.colors.white.asString,
                }}>
                <VerticalDivider XXL />
                <Conditional when={isFulfilled}>
                    <FlexColumn crossAxis="center">
                        <CheckmarkCircleSvg
                            size={"4.4rem"}
                            circleColor={theme.colors.primary.main.withAlpha(
                                0.5
                            )}
                            checkmarkColor={theme.colors.primary}
                        />
                        <VerticalDivider L />
                        <TextBox
                            css={{ maxWidth: 200, textAlign: "center" }}
                            size={2}
                            color={theme.colors.primary}
                            weight="900"
                            text={translation({
                                sv: "Klar!",
                                en: "Done!",
                            })}
                        />
                        <VerticalDivider S />
                    </FlexColumn>
                </Conditional>
                <Conditional when={!isFulfilled}>
                    <FlexColumn crossAxis="center">
                        <CircleSpinner size={4} />
                        <VerticalDivider M />
                        <TextBox
                            css={{ maxWidth: 200, textAlign: "center" }}
                            text={translation({
                                sv: "Väntar på betalningsbekräftelse...",
                                en: "Waiting for payment confirmation...",
                            })}
                        />
                    </FlexColumn>
                </Conditional>
                <VerticalDivider />
                {/* <Conditional
                    when={
                        payRequestStatus &&
                        payRequestStatus.status !== "FULFILLED"
                    }> */}

                {/* </Conditional> */}
                <FlexColumn css={{ minWidth: 350, padding: 20 }}>
                    <WithAlfred>
                        <ForEach
                            itemOf={paymentIds}
                            divider={
                                <>
                                    <VerticalDivider M />
                                    <HorizontalLine />
                                    <VerticalDivider M />
                                </>
                            }
                            beginWith={<VerticalDivider M />}>
                            {paymentId => (
                                <>
                                    <ForEach
                                        itemOf={paymentEvents.get(paymentId)}
                                        divider={<VerticalDivider S />}>
                                        {(event, index) => (
                                            <FlexColumn>
                                                <FlexRow
                                                    mainAxis="space-between"
                                                    css={{ width: "auto" }}>
                                                    <TextBox
                                                        text={eventStatusToText(
                                                            event.status
                                                        )}
                                                    />
                                                    <Conditional
                                                        when={index === 0}>
                                                        <HorizontalDivider />
                                                        <TextBox
                                                            text={paymentId.slice(
                                                                -8
                                                            )}
                                                            size={0.825}
                                                            css={{
                                                                padding: 5,
                                                                borderRadius: 5,
                                                                backgroundColor:
                                                                    theme.colors
                                                                        .black
                                                                        .light95
                                                                        .asString,
                                                            }}
                                                        />
                                                    </Conditional>
                                                </FlexRow>

                                                <DateTimeTextBox
                                                    dateTime={event._happenedAt}
                                                    size={0.9}
                                                    color={
                                                        theme.colors.black
                                                            .light60
                                                    }
                                                />
                                            </FlexColumn>
                                        )}
                                    </ForEach>
                                </>
                            )}
                        </ForEach>
                        <VerticalDivider M />
                    </WithAlfred>
                    <LinkTo
                        to={
                            isFulfilled
                                ? successUrl
                                : `/pay-requests/${props.payRequest._id}`
                        }>
                        <ButtonWhite
                            renderAs="div"
                            css={{
                                width: "auto",
                                marginTop: 20,
                                paddingLeft: 40,
                                paddingRight: 40,
                            }}>
                            <TextBox
                                size={1.1}
                                text={
                                    isFulfilled
                                        ? translation({
                                              sv: "Fortsätt",
                                              en: "Continue",
                                          })
                                        : translation({
                                              sv: "Börja om",
                                              en: "Start over",
                                          })
                                }
                            />
                        </ButtonWhite>
                    </LinkTo>
                </FlexColumn>
                {/* <JSONPretty json={payRequestStatus?.item} /> */}
            </FlexColumn>
        </FlexColumn>
    );
};

const PayRequestProcessingPage = () => {
    const payRequestId = payRequestIdZodSchema.safeParse(
        useParams<{ payRequestId: string }>()?.payRequestId
    );
    const payRequestQuery = usePayRequestForPayerQuery(
        payRequestId.success ? payRequestId.data : undefined
    );

    // console.log({ payReq: payRequestQuery.data?.item });
    if (payRequestQuery.isInitialLoading) return <PageLoad />;
    if (!payRequestQuery.data?.item) return <PageNotFound />;
    return (
        <BrandingProvider
            providerId={payRequestQuery.data.item.paymentFor.providerId}>
            <PayRequestProcessing payRequest={payRequestQuery.data.item} />
        </BrandingProvider>
    );
};
export { PayRequestProcessingPage };

const eventStatusToText = (
    status: PayRequestStatusProjection["paymentEvents"][0]["status"]
): TranslatableValue => {
    switch (status) {
        case "PENDING":
            return translation({
                sv: "Betalning påbörjad",
                en: "Payment initiated",
            });
        case "CANCELLED":
            return translation({
                sv: "Betalning avbruten",
                en: "Payment cancelled",
            });
        case "FULFILLED":
            return translation({
                sv: "Betalning bekräftad",
                en: "Payment confirmed",
            });
        case "EXPIRED":
            return translation({
                sv: "Betalning avbruten",
                en: "Payment expired",
            });
        case "FAILED":
            return translation({
                sv: "Betalning misslyckades",
                en: "Payment failed",
            });

        default:
            assertNever(status);
            Sentry.withScope(scope => {
                scope.setExtra("status", status);
                scope.setExtra("page", "Pay request processing page");
                Sentry.captureException(
                    new Error(`Unhandled payment status: ${status}`)
                );
            });
            return translation({
                sv: "Okänd betalstatus",
                en: "Unknown payment status",
            });
    }
};
