import { emptyArrayOf } from "@towni/common";
import { createContext, useContext, useRef } from "react";
import { Except } from "type-fest";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { ToastType } from "./toast-type";
import {
    Toast,
    ToastFactoryDefaultParams,
    ToastFactoryFromErrorParams,
    toastFactory,
} from "./toast.model";

type ContextState = State & Actions;
type State = {
    readonly toasts: Toast[];
    readonly activeToasts: Toast[];
};
type Actions = {
    add: (toast: Toast) => void;
    hide: (toastId: string) => void;
    success: (params: Except<ToastFactoryDefaultParams, "type">) => void;
    danger: (params: Except<ToastFactoryDefaultParams, "type">) => void;
    warning: (params: Except<ToastFactoryDefaultParams, "type">) => void;
    info: (params: Except<ToastFactoryDefaultParams, "type">) => void;
    fromError: (
        error: ToastFactoryFromErrorParams["error"],
        options?: Except<ToastFactoryFromErrorParams, "error">,
    ) => void;
};

const ToastContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    children: React.ReactNode;
};
const ToastContextProvider = (props: Props) => {
    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, _get) => {
            const add = (toast: Toast): void => {
                set(context => {
                    if (
                        toast.key &&
                        context.activeToasts.some(
                            currentToast => currentToast.key === toast.key,
                        )
                    ) {
                        // Notification already exists (same key)
                        // Returns current context as it is
                        return context;
                    }

                    return {
                        ...context,
                        toasts: [...context.toasts, toast],
                        activeToasts: [...context.activeToasts, toast],
                    };
                });
                return;
            };
            const _helper =
                (type: ToastType) =>
                (params: Except<ToastFactoryDefaultParams, "type">): void => {
                    add(
                        toastFactory({
                            ...params,
                            type,
                        }),
                    );
                    return;
                };
            const success = _helper("SUCCESS");
            const danger = _helper("DANGER");
            const info = _helper("INFORMATION");
            const warning = _helper("WARNING");
            const fromError = (
                error: ToastFactoryFromErrorParams["error"],
                options?: Except<ToastFactoryFromErrorParams, "error">,
            ): void => {
                add(
                    toastFactory({
                        ...(options ?? {}),
                        error,
                    }),
                );
                return;
            };

            const hide = (toastId: string): void => {
                set(context => ({
                    ...context,
                    activeToasts: context.activeToasts.filter(
                        toast => toast._id !== toastId,
                    ),
                }));
                return;
            };

            return {
                // State
                toasts: emptyArrayOf<Toast>(),
                activeToasts: emptyArrayOf<Toast>(),
                // Actions
                add,
                success,
                danger,
                warning,
                info,
                hide,
                fromError,
            };
        }),
    );

    return (
        <ToastContext.Provider value={store.current}>
            {props.children}
        </ToastContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useToastContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(ToastContext);
    if (store === undefined) {
        throw new Error(
            "useToastContext must be used within a ToastContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};
const useToast = () => {
    const toast = useToastContext(context => ({
        warning: context.warning,
        info: context.info,
        danger: context.danger,
        success: context.success,
        fromError: context.fromError,
    }));
    return toast;
};
type Toaster = ReturnType<typeof useToast>;

export { ToastContextProvider, useToast, useToastContext };
export type { Toaster };
