import { css, useTheme } from "@emotion/react";
import { Padding, OptionalPercentage as TOption } from "@towni/common";
import { HorizontalDivider } from "../dividers";
import { FlexRow } from "../flex-containers";
import { SelectBox } from "../select-box";
import { TextBox } from "../text";

type Props = {
    option: TOption;
    selected: boolean;
    toggle: () => void;
    shape: "CIRCLE" | "SQUARE";
    padding?: Padding;
    hidePrice?: boolean;
};
const OptionalPercentage = (props: Props) => {
    const _theme = useTheme();

    const { toggle, selected, option, shape, padding } = props;

    const selectedStyle = css`
        background-color: #e8fbf6;
    `;
    const _priceTextStyle = css`
        opacity: 0.6;
        font-weight: 300;
    `;
    // console.log(shape);
    return (
        <FlexRow
            onClick={toggle}
            padding={padding}
            mainAxis="space-between"
            crossAxis="center"
            styling={selected ? selectedStyle : undefined}>
            <FlexRow crossAxis="center">
                <SelectBox selected={selected} shape={shape} size="L" />
                <HorizontalDivider M />
                <TextBox text={option.meta.title} shrink={1} />
            </FlexRow>
            {/* {(!props.hidePrice && option.price?.amountIncludingVat && (
                <FlexRow mainAxis="flex-end">
                    <TextBox
                        text="+"
                        shrink={1}
                        color={theme.colors.default.text}
                        padding={{ leftRight: 5 }}
                        styling={priceTextStyle}
                    />
                    <PriceTextBox
                        price={option.price}
                        fractions="NONE"
                        color={theme.colors.default.text}
                        styling={priceTextStyle}
                    />
                </FlexRow>
            )) ||
                null} */}
        </FlexRow>
    );
};

export { OptionalPercentage };
