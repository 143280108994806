import { Provider } from "@towni/common";
import { createContext, useContext, useRef } from "react";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { PaymentOption } from "./payment-option";

type ContextState = State & Actions;
type State = {
    selected: PaymentOption["type"] | undefined;
};
type Actions = {
    paymentOptions: Map<PaymentOption["type"], boolean>;
    selectPayment: (option: PaymentOption["type"] | undefined) => void;
};

const PaymentOptionContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    provider: Provider;
    children: React.ReactNode;
};

const PaymentOptionContextProvider = (props: Props) => {
    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, _get) => {
            const hasSwish = !!props.provider.options.acceptSwish;
            return {
                paymentOptions: new Map([
                    ["swish_on_another_device", hasSwish],
                    ["swish_on_device", hasSwish],
                    ["stripe_payment_request_button", true],
                    ["stripe_card", true],
                    ["stripe_preselected_card", true],
                ]),
                selected: undefined,
                selectPayment: (option: PaymentOption["type"] | undefined) => {
                    set({ selected: option });
                },
            };
        }),
    );
    return (
        <PaymentOptionContext.Provider value={store.current}>
            {props.children}
        </PaymentOptionContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const usePaymentOptionContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
) => {
    const store = useContext(PaymentOptionContext);
    if (store === undefined) {
        throw new Error(
            "usePaymentOptionContext must be used within a PaymentOptionContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export {
    PaymentOptionContext,
    PaymentOptionContextProvider,
    usePaymentOptionContext,
};
