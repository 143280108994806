import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useCachedState = <T extends unknown = unknown>(
    value: T,
): [T, (value: T) => void] => {
    const [lastValue, setLastValue] = useState(value);
    const [currentValue, setCurrentValue] = useState(value);
    if (value !== lastValue) {
        setLastValue(value);
        setCurrentValue(value);
    }

    return [
        currentValue,
        (value: T) => {
            setCurrentValue(value);
        },
    ];
};

export { useCachedState };
