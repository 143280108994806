import { Provider } from "@towni/common";
import * as React from "react";
import { ProviderContext } from "./provider-contexts";

function useProviderFromContext(): Provider;
function useProviderFromContext(params: {
    disableThrowWhenMissingContext: true;
}): Provider | undefined;
function useProviderFromContext(params: {
    disableThrowWhenMissingContext: false;
}): Provider;
function useProviderFromContext(params: {
    disableThrowWhenMissingContext: boolean;
}): Provider | undefined;
function useProviderFromContext(params?: {
    disableThrowWhenMissingContext: boolean;
}): Provider | undefined {
    const providerFromContext = React.useContext(ProviderContext);
    const disableThrow = params?.disableThrowWhenMissingContext;
    if (!providerFromContext && !disableThrow) {
        throw new Error(
            "useProviderFromContext must be used within a ProviderContext"
        );
    }

    return providerFromContext;
}

export { useProviderFromContext };
