import { useMe } from "@@/authentication/me/me-context";
import { useFetchClient } from "@@/shared/fetch-client";
import { useQuery } from "@tanstack/react-query";
import { GetResponse, UserAddress } from "@towni/common";

const useAddressesForMeQuery = () => {
    const [me] = useMe();
    const fetchClient = useFetchClient();
    const query = useQuery<GetResponse<UserAddress>>({
        queryKey: [me?._id, "addresses"],
        enabled: !!me,
        queryFn: async ({ signal }) => {
            return await fetchClient.get<GetResponse<UserAddress>>({
                route: `/users/me/addresses`,
                customConfig: { signal },
            });
        },
    });
    return query;
};

export { useAddressesForMeQuery };
