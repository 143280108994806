import * as React from "react";
import { useMemo } from "react";
import { breakpointRange } from "./breakpoint-ranges";
import { BreakpointSize } from "./breakpoint-sizes";
import { useMediaQuery } from "./use-media-query";

type State = {
    status: { [key in BreakpointSize]: boolean };
};
const BreakpointContext = React.createContext<State | undefined>(undefined);

type Props = {
    /**
     * if set overrides media query width
     */
    width?: number;
    children?: React.ReactNode;
};
const BreakpointProvider = (props: Props) => {
    const XS = useMediaQuery(`(max-width: ${breakpointRange.XS.max}px)`);
    const MS = useMediaQuery(
        `(min-width: ${breakpointRange.MS.min}px) and (max-width: ${breakpointRange.MS.max}px)`,
    );
    const S = useMediaQuery(
        `(min-width: ${breakpointRange.S.min}px) and (max-width: ${breakpointRange.S.max}px)`,
    );
    const M = useMediaQuery(
        `(min-width: ${breakpointRange.M.min}px) and (max-width: ${breakpointRange.M.max}px)`,
    );
    const L = useMediaQuery(`(min-width: ${breakpointRange.L.min}px)`);
    const MOBILE = XS || S;
    const SMALL_SCREEN = useMediaQuery(
        `(min-width: ${breakpointRange.SMALL_SCREEN.min}px) and (max-width: ${breakpointRange.SMALL_SCREEN.max}px)`,
    );
    const DESKTOP = M || L;

    const mediaQueryBreakpoints: Record<BreakpointSize, boolean> = useMemo(
        () => ({
            XS,
            MS,
            S,
            M,
            L,
            MOBILE,
            SMALL_SCREEN,
            DESKTOP,
            "📱": MOBILE,
            "🖥": DESKTOP,
        }),
        [XS, MS, S, M, L, SMALL_SCREEN, MOBILE, DESKTOP],
    );

    const current = useMemo((): Record<BreakpointSize, boolean> => {
        if (typeof props.width === "undefined") return mediaQueryBreakpoints;

        const XS = props.width <= breakpointRange.XS.max;
        const MS =
            props.width > breakpointRange.MS.min &&
            props.width <= breakpointRange.MS.max;
        const S =
            props.width > breakpointRange.S.min &&
            props.width <= breakpointRange.S.max;
        const M =
            props.width > breakpointRange.M.min &&
            props.width <= breakpointRange.M.max;
        const L = props.width > breakpointRange.L.min;
        const MOBILE = S || XS;
        const SMALL_SCREEN =
            props.width > breakpointRange.SMALL_SCREEN.min &&
            props.width <= breakpointRange.SMALL_SCREEN.max;
        const DESKTOP = M || L;

        return {
            XS,
            MS,
            S,
            M,
            L,
            MOBILE,
            SMALL_SCREEN,
            DESKTOP,
            "📱": MOBILE,
            "🖥": DESKTOP,
        };
    }, [props.width, mediaQueryBreakpoints]);

    const state = useMemo((): State => {
        const desktop = current.L || current.M;
        const mobile = current.S || current.XS;
        return {
            status: {
                XS: current.XS,
                MS: current.MS,
                S: current.S,
                M: current.M,
                L: current.L,
                MOBILE: mobile,
                SMALL_SCREEN: current.SMALL_SCREEN,
                DESKTOP: desktop,
                "📱": mobile,
                "🖥": desktop,
            },
        };
    }, [current]);

    return (
        <BreakpointContext.Provider value={state}>
            {props.children}
        </BreakpointContext.Provider>
    );
};

export { BreakpointContext, BreakpointProvider };
export type { State as BreakpointContextState };
