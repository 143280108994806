import { useCart } from "@@/carts/multi-carts/cart.context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Label } from "@@/shared/label";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { TextBox } from "@@/shared/text";
import { css } from "@emotion/react";
import {
    AspectRatio,
    ExtrasGroup,
    MinMaxRange,
    Product,
    isProductOrderItem,
    sum,
    translation,
} from "@towni/common";
import { BookableGridProductExtraCardV2 } from "../../bookable-grid-product-extra-card-v2";
import { useAvailableExtrasQuery } from "../../use-available-extras";
type Props = {
    aspectRatio?: AspectRatio;
    product: Product;
    extraGroup: ExtrasGroup;
    minMax: MinMaxRange;
};
const BookableExtrasGroupGrid = (props: Props) => {
    const { product, minMax, extraGroup, aspectRatio } = props;

    const cart = useCart();

    const availableProductIdsQuery = useAvailableExtrasQuery(product);

    if (availableProductIdsQuery.isInitialLoading)
        return (
            <TextShimmer rows={3} css={{ paddingLeft: 10, paddingRight: 10 }} />
        );

    if (!availableProductIdsQuery.data?.items.length) return null;
    const extrasProductIds = extraGroup.products
        .map(p => p.productId)
        .flatMap(productId => {
            if (!availableProductIdsQuery.data.items.includes(productId)) {
                return [];
            }
            return [productId];
        });

    const items = cart.orderItems.filter(
        oi => oi.extraGroup?._id === extraGroup._id && isProductOrderItem(oi),
    );
    const _amountPicked = sum(items, oi => oi.quantity.value);

    const allIsPicked =
        _amountPicked >= (minMax.max ?? Number.MAX_SAFE_INTEGER);

    return (
        <>
            <FlexRow>
                <FlexColumn grow={1} crossAxis="center">
                    <Conditional
                        when={extraGroup.settings._type === "FREE_AMOUNT"}
                        else={() => {
                            if (
                                extrasProductIds.length === 1 &&
                                minMax.min &&
                                minMax.min === minMax.max
                            )
                                return null;

                            return (
                                <Label
                                    text={
                                        // Om fler än 0 och ett exakt antal:
                                        minMax.min && minMax.min === minMax.max
                                            ? translation({
                                                  sv: `Välj ${minMax.min}`,
                                                  en: `Choose ${minMax.min}`,
                                              })
                                            : // Om max är satt min min är 0
                                              minMax.max && !minMax.min
                                              ? translation({
                                                    sv: `Välj upp till ${minMax.max}`,
                                                    en: `Choose up to ${minMax.max}`,
                                                })
                                              : // Om min är satt men max inte är satt
                                                minMax.min && !minMax.max
                                                ? translation({
                                                      sv: `Välj minst ${minMax.min}`,
                                                      en: `Choose at least ${minMax.min}`,
                                                  })
                                                : // Om min och max är satta
                                                  minMax.min && minMax.max
                                                  ? translation({
                                                        sv: `Välj mellan ${minMax.min ?? 0} och ${minMax.max}`,
                                                        en: `Choose between ${minMax.min ?? 0} and ${minMax.max}`,
                                                    })
                                                  : translation({
                                                        sv: "Välj fritt antal",
                                                        en: "Choose freely",
                                                    })
                                    }
                                    colorTheme={
                                        (minMax.min ?? 0) === 0
                                            ? "PRIMARY"
                                            : "RED"
                                    }
                                />
                            );
                        }}>
                        <Label
                            text={translation({
                                sv: "Välj fritt",
                                en: "Choose freely",
                            })}
                            colorTheme="PRIMARY"
                        />
                    </Conditional>
                </FlexColumn>
            </FlexRow>
            <VerticalDivider />
            <FlexColumn padding={{ leftRight: 20 }} crossAxis="center">
                <TextBox text={extraGroup.meta.title} weight="700" size={"L"} />
                <VerticalDivider />
                <TextBox
                    size="S"
                    css={css`
                        line-height: 1.5;
                    `}
                    text={extraGroup.meta.subtitle}
                    align="center"
                />
            </FlexColumn>

            <VerticalDivider L />
            <LayoutGrid
                css={{
                    gridTemplateColumns:
                        extrasProductIds.length > 1 ? "1fr 1fr" : "1fr",

                    gap: 20,
                }}>
                <ForEach itemOf={extrasProductIds} getKey={pid => pid}>
                    {(productId, _index) => {
                        return (
                            <LayoutCell css={{ position: "relative" }}>
                                <BookableGridProductExtraCardV2
                                    aspectRatio={
                                        extrasProductIds.length === 1
                                            ? "16:9"
                                            : aspectRatio
                                    }
                                    productId={productId}
                                    extras={{
                                        extras: extraGroup,
                                        parentOrderItemId: undefined,
                                        minMax: minMax,
                                        pickedAmount: _amountPicked,
                                        disabled: allIsPicked,
                                    }}
                                />
                            </LayoutCell>
                        );
                    }}
                </ForEach>
            </LayoutGrid>
        </>
    );
};

export { BookableExtrasGroupGrid };
