import { AccommodationCard } from "@@/products/accommodations/accommodation-card";
import {
    AccommodationDatePill,
    AccommodationQuantityPill,
} from "@@/products/accommodations/accommodation-pill/accommodation-pill";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { getAccommodationSKU } from "@@/products/accommodations/booking-accommodation-modal";

import { useCalculatedInventory } from "@@/products/accommodations/hooks/use-calculated-accommodation";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { Accommodation, Product, isTimeRange, sortBy } from "@towni/common";
import React from "react";

type Props = {
    product: Product;
    accommodation: Accommodation;
};

const _AccommodationPicker = (props: Props) => {
    const theme = useTheme();

    const { time } = useBookingAccommodationContext(state => ({
        quantities: state.quantities,
        time: state.timeRange,
    }));

    const timeRange = isTimeRange(time) ? time : undefined;

    const calculatedInventoryResult = useCalculatedInventory(
        props.product,
        props.accommodation,
    );

    return (
        <>
            <LayoutGrid
                padding={{ leftRight: 20, topBottom: 20 }}
                css={{
                    width: "100%",
                    height: "100%",
                    gridTemplateColumns: `1fr`,
                    overflowY: "scroll",
                }}>
                <LayoutCell>
                    <ForEach
                        itemOf={calculatedInventoryResult.sort(
                            sortBy(
                                i =>
                                    i.inventoryDetails.filter(
                                        r => r.isAvailable,
                                    ).length,
                                "DESCENDING",
                            ),
                        )}
                        divider={<VerticalDivider L />}>
                        {i => {
                            return (
                                <React.Fragment key={i.inventory._id}>
                                    <Conditional when={!!i.inventory.title}>
                                        <FlexColumn
                                            padding={{ top: 10, bottom: 20 }}
                                            crossAxis="center">
                                            <TextBox
                                                text={i.inventory.title}
                                                weight="700"
                                                size={1.125}
                                            />
                                            <VerticalDivider XS />
                                            <TextBox
                                                text={i.inventory.description}
                                                align="center"
                                                weight="400"
                                                size={0.875}
                                                color={
                                                    theme.colors.default.text
                                                        .light40
                                                }
                                            />
                                        </FlexColumn>
                                    </Conditional>
                                    <ForEach
                                        itemOf={i.inventoryDetails.sort(
                                            sortBy(s =>
                                                s.isAvailable ? 0 : 1,
                                            ),
                                        )}
                                        divider={<VerticalDivider M />}>
                                        {group => {
                                            return (
                                                <AccommodationCard
                                                    product={props.product}
                                                    resource={
                                                        group.resourceGroup
                                                    }
                                                    availability={
                                                        group.availability
                                                    }
                                                    range={timeRange}
                                                    allResources={
                                                        group.resources
                                                    }
                                                    calculatedRates={
                                                        group.rates
                                                    }
                                                    inventory={group.inventory}
                                                />
                                            );
                                        }}
                                    </ForEach>
                                </React.Fragment>
                            );
                        }}
                    </ForEach>
                </LayoutCell>
            </LayoutGrid>
        </>
    );
};

type PickerProps = {
    product: Product;
};
const AccommodationPicker = (props: PickerProps) => {
    const theme = useTheme();

    const acc = getAccommodationSKU(props.product);

    if (!acc) return <div>No accommodation</div>;
    if (acc._type !== "ACCOMMODATION") return <div>Not an accommodation</div>;

    return (
        <FlexColumn
            fillParent
            css={{
                flexGrow: 1,
                flexShrink: 0,
            }}
            background={{
                color: theme.colors.default.background,
            }}>
            <FlexRow
                fillParentWidth
                mainAxis="center"
                padding={{ topBottom: 14 }}
                css={{
                    boxShadow: `0px 5px 10px 0px ${theme.colors.default.border.asString}`,
                }}>
                <AccommodationDatePill
                    product={props.product}
                    accommodation={acc}
                />
                <HorizontalDivider />
                <AccommodationQuantityPill
                    product={props.product}
                    accommodation={acc}
                />
            </FlexRow>
            <_AccommodationPicker accommodation={acc} product={props.product} />
        </FlexColumn>
    );
};

export { AccommodationPicker };
