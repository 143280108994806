import { AppPage } from "@@/pages/app-page";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation } from "@towni/common";
import { Helmet } from "react-helmet-async";
import { HzSectionDropIn } from "./sections/hz-section-drop-in";
import { HzSectionGrandPrix } from "./sections/hz-section-grand-prix";
import { HzSectionQuickrace } from "./sections/hz-section-quickrace";
import { HzSectionStandardrace } from "./sections/hz-section-standardrace";
import { HzSectionVouchers } from "./sections/hz-section-vouchers";
import { OneSquareLink } from "./sections/one-square-raceroom-link";
import { OneSquareSection } from "./sections/one-square-section";

type Props = {
    title?: string;
};

const MotorstadionLidkopingStoreFront = (props: Props) => {
    const theme = useTheme();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <WideScreenWrapper>
            <Helmet>
                <title>{props.title ?? "Motorstadion Lidköping"}</title>
            </Helmet>
            <AppPage
                pageId={appPageIdFactory("motorstadion-lidkoping-store-front")}
                crossAxis={isDesktop ? "center" : undefined}
                anchors={[]}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ leftRight: 20, topBottom: 10 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <CurrentUserIcon theme="PRIMARY" />
                    </FlexRow>
                }>
                {/* Header Image >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    padding={{ bottom: 25, leftRight: 32 }}
                    mainAxis="flex-end"
                    height={vh100 * 0.55}
                    maxHeight={675}
                    background={{
                        imageUrl:
                            "https://we-are-alfred.imgix.net/destinations/destination-motorstadion-poster.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges",
                        fit: "COVER",
                    }}>
                    <TextBox
                        text="Motorstadion Lidköping"
                        color={"#ffffff"}
                        size={2.5 as RemSize}
                        weight="900"
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                    <VerticalDivider size="XS" />
                    <TextBox
                        text="Tävla med vänner, kollegor och kunder!"
                        color={"#ffffff"}
                        size={1.45 as RemSize}
                        css={{
                            textShadow: "0 4px 20px rgba(5, 32, 72, 0.6)",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header Image */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    <VerticalDivider XL />
                    <TextBox
                        text="Grand Prix - som på riktigt"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Grand Prix är för de inbitna som vill ha mycket körning på en av de bästa gokartbanorna."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionGrandPrix />

                    <VerticalDivider XS />
                    <TextBox
                        text="Standardrace - mest populär"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Vårt populäraste race, passar såväl familjen, svensexan som företaget. Svårslagen racingupplevelse som garanterat ger stora leenden."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionStandardrace />
                    <VerticalDivider XS />
                    <TextBox
                        text="Quickrace - vårt minsta race"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Vårt minsta race för de som har en begränsad budget eller ont om tid."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionQuickrace />
                    <VerticalDivider XS />
                    <TextBox
                        text="En av världens bästa banor"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Där racinglegender samlas. Gocart, möten, simulatorer, köruppleveser och tävlingar."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Le mans
                        productId={"product_mdE9Ndq074AEajGfkund" as ProductId}
                        actionText={translation({
                            sv: "Utmaning",
                            en: "Challenge",
                        })}
                    />
                    {/* <VerticalDivider /> */}
                    {/* <OneSquareSection
                    // Boka hela banan
                    productId={"" as ProductId}
                    actionText={translation({
                        sv: "Se lediga tider",
                        en: "See availability",
                    })}
                /> */}
                    <VerticalDivider XL />

                    <TextBox
                        text="Drop in"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Har du missat att boka i förväg? Här släpps lediga drop-in tider kontinuerligt för 3 dagar framåt. Först till kvarn!"
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionDropIn />
                    <VerticalDivider XS />

                    <TextBox
                        text="Mer från motorstadion"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Komplettera med möten och raceroom för en helhetsupplevelse"
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <VerticalDivider />
                    <OneSquareLink
                        // Raceroom
                        title={"Raceroom"}
                        subtitle={translation({
                            sv: "Digital simulering som pricken över i't",
                            en: "Digital simulation as the icing on the cake",
                        })}
                        kicker={translation({
                            sv: "SIMULATOR • AKTIVITET",
                            en: "SIMULATOR • ACTIVITY",
                        })}
                        imageUrl="https://we-are-alfred.imgix.net/destinations/motorstadion-raceroom.jpg?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges"
                        relativeUrl="/destinations/motorstadion-raceroom"
                        actionText={translation({
                            sv: "Visa",
                            en: "View",
                        })}
                    />
                    <VerticalDivider />
                    <OneSquareSection
                        // Konferenslokal
                        productId={"product__XGRIXsOvKCd74UEUJHVp" as ProductId}
                        titleTopPlacement={true}
                        actionText={translation({
                            sv: "Boka nu",
                            en: "Book now",
                        })}
                    />
                    <VerticalDivider XL />

                    <TextBox
                        text="Upplevelsepresentkort"
                        size={1.5 as RemSize}
                        padding={{ leftRight: 20 }}
                        weight="700"
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text="Ge någon eller dig själv ett presentkort på en upplevelse på Motorstadion."
                        color={theme.colors.black.light30}
                        padding={{ leftRight: 20 }}
                        size={1.125 as RemSize}
                    />
                    <HzSectionVouchers />
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                    {/* <<<< Content */}
                </FlexColumn>
            </AppPage>
        </WideScreenWrapper>
    );
};

export { MotorstadionLidkopingStoreFront };
