import { apiFetchClient } from "@@/shared/fetch-client";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    AccessKeyId,
    GetResponse,
    OrderGroup,
    OrderGroupId,
} from "@towni/common";
import { useMemo } from "react";

const mainQueryKey = "order-groups";
const mainRoute = "order-groups";

const useOrderGroup = (
    orderGroupId?: OrderGroupId,
    accessKeyId?: AccessKeyId,
    retries?: number,
): readonly [
    OrderGroup | undefined,
    UseQueryResult<GetResponse<OrderGroup>>,
] => {
    const queryResult = useQuery<GetResponse<OrderGroup>>({
        enabled: !!orderGroupId,
        retry: retries ?? undefined,
        queryKey: [mainQueryKey, orderGroupId],
        queryFn: () =>
            apiFetchClient.get<GetResponse<OrderGroup>>({
                route: `/${mainRoute}/${encodeURIComponent(
                    orderGroupId ?? "",
                )}${accessKeyId ? `?ak=${accessKeyId}` : ""}`,
            }),
    });
    return useMemo(
        () => [queryResult.data?.items?.[0] || undefined, queryResult] as const,
        [queryResult],
    );
};

export { useOrderGroup };
