import { useTheme } from "@emotion/react";
import {
    faToggleOff,
    faToggleOn,
    IconDefinition,
} from "@fortawesome/pro-duotone-svg-icons";
import { ColorItem, SizeName } from "@towni/common";
import * as React from "react";
import { Icon } from "../icons/icon";

type Props = {
    readonly toggled: boolean | undefined;
    readonly toggle: () => void | Promise<void>;
    readonly size?:
        | SizeName
        | number
        | [on: SizeName | number, off: SizeName | number];
    readonly style?: React.CSSProperties;
    readonly title?: string;
    readonly offIcon?: IconDefinition;
    readonly onIcon?: IconDefinition;
    readonly color?: ColorItem;
    readonly disabled?: boolean; // TODO! HANDLE DISABLED STATE
};

const ToggleIconButton = (props: Props) => {
    const theme = useTheme(); // Color of the toggle circle
    const primaryColor = theme.isDarkTheme
        ? props.toggled
            ? theme.colors.primary // enabled: background
            : theme.colors.black.light35 // disabled: switch
        : props.toggled
          ? theme.colors.primary.text // enabled: background
          : theme.colors.black.light75; //disabled: switch

    // Color of the border
    const secondaryColor = theme.isDarkTheme
        ? props.toggled
            ? theme.colors.primary.main.withAlpha(0.8) // enabled: switch
            : theme.colors.black.light30 // disabled: background;
        : props.toggled
          ? theme.colors.primary.main.withAlpha(0.8) // enabled: switch
          : theme.colors.black.light80; // disabled: background;

    const colorProps = props.color
        ? { color: props.color }
        : {
              colors: {
                  primaryColor: primaryColor,
                  secondaryColor: secondaryColor,
                  secondaryOpacity: 1,
              },
          };

    const parseSize = React.useCallback(
        (value: SizeName | number | undefined) => {
            return typeof value === "string"
                ? theme.sizes.inRem[value]
                : typeof value === "number"
                  ? value
                  : theme.sizes.inRem.XXL;
        },
        [],
    );

    const onSize = Array.isArray(props.size)
        ? parseSize(props.size[0])
        : parseSize(props.size);
    const offSize = Array.isArray(props.size)
        ? parseSize(props.size[1])
        : parseSize(props.size);

    const sizeInRem = props.toggled ? onSize : offSize;

    return (
        <Icon
            icon={
                props.toggled
                    ? props.onIcon ?? faToggleOn
                    : props.offIcon ?? faToggleOff
            }
            onClick={props.toggle}
            title={props.title}
            style={{
                fontSize: `${sizeInRem}rem`,
                cursor: "pointer",
                ...(props.style ? props.style : {}),
            }}
            {...colorProps}
        />
    );
};

export { ToggleIconButton };
