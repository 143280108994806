import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import { FC } from "react";

const LackoNatureExploration: FC = () => {
    return (
        <SectionArea
            title={"Naturupplevelser"}
            subtitle={
                "Båtturer med guide till våra härliga väneröar med gudie och orörd natur, skärgård och hela Vänern som fond."
            }
            sectionAreaId="lacko-sea-2-section"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                <OneSquareSection
                    productId={"product_3ku3Fv1Ud_UtztKnkRM1" as ProductId}
                    actionText={translation({
                        sv: "Se & Boka",
                    })}
                />
                <OneSquareSection
                    productId={"product_mzjqqLIkXeDWymmZWZfG" as ProductId}
                    actionText={translation({
                        sv: "Utforska",
                    })}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { LackoNatureExploration };
