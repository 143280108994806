import { useMe } from "@@/authentication/me/me-context";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { ProviderLogoLinkCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { useTheme } from "@emotion/react";
import {
    Product,
    Provider,
    Translatable,
    hejalfredScope,
    isVoucher,
    translation,
} from "@towni/common";
import { useNavigate } from "react-router-dom";

type Props = {
    voucher: Product | undefined;
    unavailable?: boolean;
    provider: Provider | undefined;
    actionText?: Translatable;
};

/**
 * Note! It's not always square since it's now responsive for widescreen
 */
const VoucherCardSquare = (props: Props) => {
    const { voucher, provider } = props;
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const [me] = useMe();
    const navigate = useNavigate();
    const theme = useTheme();
    if (voucher && !isVoucher(voucher)) return null;

    const poster = voucher?.meta.images[0];

    if (!voucher) return null;
    const hasHejAlfredScope = me?.scopes.includes(hejalfredScope);
    const isInactive = !!props.unavailable;
    if (!hasHejAlfredScope && isInactive) {
        return null;
    }

    const onClick = () => {
        navigate(`/vouchers/${voucher._id}`);
    };

    return (
        <div
            css={{
                willChange: "filter",
                width: "100%",
                height: 0,
                paddingBottom: isDesktop ? "70%" : "100%",
                position: "relative",
                overflow: "hidden",
                filter: isInactive ? "blur(1px) grayscale(100%)" : "none",
                opacity: isInactive ? 0.5 : 1,
            }}>
            <FlexColumn
                fillParent
                radius={10}
                mainAxis="space-between"
                css={{
                    overflow: "hidden",
                    position: "absolute",
                    cursor: "pointer",
                }}
                onClick={onClick}
                background={{
                    imageReference: poster,
                    fit: "COVER",
                    imageSize: { imageWidth: 500 },
                }}>
                <FlexColumn
                    fillParent
                    mainAxis="space-between"
                    css={{
                        background:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0) 53.12%, rgba(0, 0, 0, 0.6) 100%)",
                    }}>
                    <FlexRow
                        fillParentWidth
                        padding={{ all: 15 }}
                        mainAxis="space-between"
                        crossAxis="flex-start">
                        <ProviderLogoLinkCircle
                            provider={provider}
                            size="XXS"
                            hideTitle
                        />
                        <div style={{ padding: 10 }}>
                            <StoreFrontCardPill
                                text={props.actionText ?? text.explore}
                            />
                        </div>
                    </FlexRow>
                    <VerticalDivider />
                    <FlexRow
                        fillParent
                        css={{ flex: 0 }}
                        padding={{ all: 20 }}
                        mainAxis="space-between"
                        crossAxis="center">
                        <FlexColumn>
                            <TextBox
                                text={voucher.meta.title}
                                weight="900"
                                size={2}
                                color={theme.colors.white}
                                lineClamp={3}
                                css={{
                                    textShadow:
                                        "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            />
                            <VerticalDivider size="3XS" />
                            <TextBox
                                text={
                                    voucher.meta.kicker ?? voucher.meta.subtitle
                                }
                                weight="400"
                                color={theme.colors.white}
                                case={
                                    voucher.meta.kicker
                                        ? "UPPERCASE"
                                        : undefined
                                }
                                size={0.8}
                                lineClamp={1}
                                css={{
                                    textShadow:
                                        "0px 4px 10px rgba(0, 0, 0, 0.25);",
                                }}
                            />
                        </FlexColumn>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </div>
    );
};

const text = {
    explore: translation({
        sv: "Utforska",
        en: "Explore",
    }),
};
export { VoucherCardSquare };
