import { FlexRow } from "@@/shared/flex-containers";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { useTheme } from "@emotion/react";
import { SpacerPageBlock } from "@towni/common";
import { usePageViewContext } from "../../../editor/page-view-context";
import { SpacerPageBlockProvider } from "./spacer-page-block-view-context";

type Props = {
    block: SpacerPageBlock; // todo, remove dividerblock
};

const SpacerPageBlockView = (props: Props) => {
    const { pageMaxWidth } = usePageViewContext();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const block = props.block;
    const theme = useTheme();
    const [imageReference] = useStorageItemImageReference(
        block.backgroundImage,
    );

    const height =
        typeof block.height === "string"
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              isNaN(block.height as any)
                ? block.height
                : block.height + "px"
            : block.height;

    return (
        <SpacerPageBlockProvider block={block}>
            <FlexRow
                fillParentWidth
                mainAxis={"flex-start"}
                crossAxis={"stretch"}
                height={height ?? `${theme.sizes.base * 1.25}px`}
                shrink={0}
                background={
                    imageReference
                        ? {
                              imageReference,
                              fit: "COVER",
                              imageSize: {
                                  imageWidth: isMobile
                                      ? breakpointRange.MOBILE.max
                                      : Math.min(
                                            breakpointRange.DESKTOP.max,
                                            pageMaxWidth,
                                        ),
                              },
                          }
                        : {
                              color:
                                  block.backgroundColor ??
                                  theme.colors.default.background,
                          }
                }
            />
        </SpacerPageBlockProvider>
    );
};

export { SpacerPageBlockView };
