import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import * as React from "react";
import { RefObject, useRef } from "react";
import { DOMRectJSON } from "./use-size-tracker";

const ResizeObserver = window.ResizeObserver || Polyfill;

type OnResizeCallback = (size: ResizeResult) => void;
type ResizeResult = {
    contentBoxSize: DOMRectJSON;
    borderBoxSize: DOMRectJSON;
};
const useResizeObserver = <T extends HTMLElement = HTMLElement>(params: {
    targetRef: RefObject<T>;
    onResize?: OnResizeCallback;
    /** defaults to content-box */
    sizeToWatch?: ResizeObserverBoxOptions;
}): ResizeResult | undefined => {
    const { targetRef } = params;
    const [contentSize, setContentSize] = React.useState<
        DOMRectJSON | undefined
    >(undefined);
    const [result, setResult] = React.useState<ResizeResult | undefined>(
        undefined,
    );

    const isMountedRef = useIsMountedRef();
    const previousContentSize = React.useRef<DOMRectJSON | undefined>(
        undefined,
    );
    previousContentSize.current = contentSize;

    const onResize = useRef<OnResizeCallback | undefined>(params.onResize);
    onResize.current = params.onResize;

    React.useEffect(() => {
        const element = targetRef.current;
        if (!element) return;
        if (!isMountedRef.current) return;

        const resizeObserver = new ResizeObserver((entries, _observer) => {
            for (const entry of entries) {
                const hasChanged =
                    previousContentSize.current?.width !==
                        entry.contentRect.width ||
                    previousContentSize.current?.height !==
                        entry.contentRect.height;

                if (!hasChanged) return;

                const result: ResizeResult = {
                    borderBoxSize: entry.target.getBoundingClientRect(),
                    contentBoxSize: entry.contentRect,
                };

                setContentSize(entry.contentRect);
                setResult(result);

                if (onResize.current) onResize.current(result);
            }
        });

        resizeObserver.observe(element, {
            box: params?.sizeToWatch,
        });
        return () => {
            resizeObserver.unobserve(element);
            resizeObserver.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.sizeToWatch, targetRef.current]);

    return result;
};

export { useResizeObserver };
export type { OnResizeCallback };
