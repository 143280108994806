import { useModal } from "@@/modals/use-modal";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address, SizeName, sizeNameMap } from "@towni/common";
import { SelectMapUrlModal } from "./select-map-url-modal";

type Props = {
    address: Address;
    size?: SizeName;
};

const AddressLink = (props: Props) => {
    const theme = useTheme();
    const { address } = props;
    const [{ toggle }, modalId] = useModal("select_map_modal__");

    const firstAddressRow = address?.addressRows?.[0];
    const sizeName = props.size ?? sizeNameMap.M;

    const addressRows =
        firstAddressRow.length > 0 ? firstAddressRow : "Adress saknas";

    return (
        <>
            <FlexRow onClick={() => address?.mapUrls?.length && toggle()}>
                <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    color={theme.colors.black.light80.asString}
                    style={{ fontSize: theme.sizes.inPixels[sizeName] }}
                />
                <HorizontalDivider />
                <TextBox
                    text={addressRows
                        .replace("gatan", "g.")
                        .replace("vägen", "v.")}
                    weight="400"
                    size={sizeName}
                />
                {/* todo: make above replacements dynamic as a global setting (regex based) to easily support more in the future without app deploys */}
            </FlexRow>
            {address?.mapUrls?.length > 0 ? (
                <SelectMapUrlModal
                    modalId={modalId}
                    mapUrls={props.address.mapUrls}
                />
            ) : null}
        </>
    );
};

export { AddressLink };
