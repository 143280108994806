import { useHasAlfredScope, useMe } from "@@/authentication/me/me-context";
import { InfoBox_FlexColumn } from "@@/backoffice/shared/info-box.flex-column";
import { SelectPicker } from "@@/carts/deliverypicker/select-picker";
import { VerticalDivider } from "@@/shared/dividers";
import { useFetchClient } from "@@/shared/fetch-client";
import { FlexColumn } from "@@/shared/flex-containers";
import { Paragraph } from "@@/shared/text";
import { useToast } from "@@/toasts/context/toast-context";
import {
    MutateFunction,
    MutationOptions,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import {
    LanguageCode,
    SetUserLanguageCommand,
    languageCodes,
    languageName,
    setUserLanguageCommandFactory,
    translation,
} from "@towni/common";
import { Except } from "type-fest";

const useUserPreferredLanguageMutation = (
    options?: Except<
        MutationOptions<unknown, unknown, SetUserLanguageCommand>,
        "mutationFn" | "mutationKey" | "_defaulted"
    >,
) => {
    const queryClient = useQueryClient();
    const fetchClient = useFetchClient();
    const mutationFn: MutateFunction<
        unknown,
        unknown,
        SetUserLanguageCommand
    > = command => {
        return fetchClient.post<SetUserLanguageCommand>({
            route: `/users`,
            body: command,
        });
    };

    return useMutation<unknown, unknown, SetUserLanguageCommand>({
        mutationFn,
        ...(options || {}),
        onSuccess: (...params) => {
            // Invalidate the cache to make
            // sure the new resource is shown
            queryClient.invalidateQueries({ queryKey: ["users"] });

            // Run provided onSuccess callback
            // if it exists
            if (options?.onSuccess) {
                options.onSuccess(...params);
            }
        },
    });
};

const PreferredUserLanguageSelection = () => {
    const [me, meQuery] = useMe();
    const toast = useToast();
    const mutation = useUserPreferredLanguageMutation({
        onError: error => {
            toast.fromError(error);
        },
        onSuccess: () => {
            meQuery.meQuery.refetch();
            toast.success({
                message: translation({
                    en: "Language updated",
                    sv: "Språk uppdaterat",
                }),
            });
        },
    });
    const currentLanguage = me?.preferences?.language || "none";

    const hasAlfredScope = useHasAlfredScope();
    if (!hasAlfredScope) return null;
    if (!me) return null;

    return (
        <FlexColumn fillParentWidth>
            <SelectPicker<LanguageCode | "none">
                title={translation({
                    sv: "Föredraget språk",
                    en: "Preferred language",
                })}
                data={[
                    {
                        value: "none",
                        name: translation({
                            en: "None",
                            sv: "Inget",
                        }),
                    },
                    ...languageCodes.map(language => {
                        return {
                            value: language,
                            name: languageName[language],
                        };
                    }),
                ]}
                spin={mutation.isPending}
                selectedValue={currentLanguage}
                onSelect={selected => {
                    mutation.mutate(
                        setUserLanguageCommandFactory({
                            userId: me._id,
                            language:
                                selected.value === "none"
                                    ? null
                                    : selected.value,
                        }),
                    );
                }}
            />
            <VerticalDivider />
            <InfoBox_FlexColumn>
                <Paragraph
                    content={[
                        translation({
                            sv: `Sätts för dig privat på "user.settings.language" nivå. Används idag för att testa sms-utskick på olika språk.`,
                            en: `Set privately for you on the "user.settings.language" level. Used today to test SMS sending in different languages.`,
                        }),
                    ]}
                />
            </InfoBox_FlexColumn>
        </FlexColumn>
    );
};

export { PreferredUserLanguageSelection };
