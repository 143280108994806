import { useModal } from "@@/modals/use-modal";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { Sortable } from "@@/shared/pictures/sortable";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { moveWithinArrayImmutable } from "@@/shared/text/move-within-array";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";
import {
    Product,
    ProductId,
    Translatable,
    assertNever,
    generateId,
    translation,
} from "@towni/common";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { ProductPickedRow } from "./product-picked-row";
import { ProductPickerModal } from "./product-picker-modal";

type Props = {
    title?: Translatable;
    modalTitle?: Translatable;
    actionTitle?: Translatable;
    products: Product[];
    spin?: boolean;
    initiallySelected: Set<ProductId>;
    onChange: (selected: ProductId[]) => void;
    required?: boolean;
    mode?: "SINGLE" | "MULTI";
};

const ProductPicker = (props: Props) => {
    const mode: Props["mode"] = props.mode || "MULTI";
    const [pickerId] = useState(
        generateId({ length: 8, prefix: "product_picker__" }),
    );
    const theme = useTheme();
    const { products, modalTitle } = props;
    const [selected, setSelected] = useState(
        props.initiallySelected ?? new Set<ProductId>(),
    );
    const [{ show }, modalId] = useModal("product_picker_modal__");
    const productLookup = useMemo(() => {
        return new Map(
            products.map(product => [product._id, product] as const),
        );
    }, [products]);
    const [productSortOrder, setProductSortOrder] = useState<ProductId[]>([]);
    const productSortOrderRef = React.useRef(productSortOrder);
    productSortOrderRef.current = productSortOrder;
    const productSortOrderHash = productSortOrder.join(",");

    useEffect(() => {
        // filter out products that are not selected
        const updated = productSortOrderRef.current.filter(id =>
            selected.has(id),
        );
        // add new products
        const additions = Array.from(selected).filter(
            id => !productSortOrderRef.current.includes(id),
        );
        const newOrder = [...updated, ...additions];
        setProductSortOrder(newOrder);
    }, [productSortOrderHash, selected]);

    useUpdateEffect(() => {
        props.onChange(productSortOrderRef.current);
    }, [productSortOrderHash]);

    const onToggle = (productId: ProductId) => {
        switch (mode) {
            case "MULTI":
                setSelected(items => {
                    const update = new Set(items);
                    if (update.has(productId)) {
                        update.delete(productId);
                        return update;
                    }

                    update.add(productId);
                    return update;
                });
                return;
            case "SINGLE":
                setSelected(prev => {
                    // Is it already selected
                    const isSelected = prev.has(productId);
                    if (isSelected) return new Set();
                    else return new Set([productId]);
                });
                return;
            default:
                assertNever(mode);
        }
    };

    const handleDragEnd = React.useCallback(
        ({ active, over }: DragEndEvent) => {
            // if (active.id) setSelected(active.id);
            // browserLogger.log("SET SELECTED - DRAG END", selected, active.id);
            if (!over) return;
            if (active.id !== over?.id) {
                const from = productSortOrder.findIndex(
                    value => value === active.id,
                );
                const target = productSortOrder.findIndex(
                    value => value === over.id,
                );
                const newOrder = moveWithinArrayImmutable(
                    productSortOrder,
                    from,
                    target,
                );
                setProductSortOrder(newOrder);
            }
        },
        [productSortOrder, setProductSortOrder],
    );

    return (
        <>
            <DndContext
                id={pickerId}
                key={pickerId}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}>
                <FlexColumn fillParentWidth>
                    <FlexRow fillParentWidth mainAxis="space-between">
                        <FieldTitle
                            required={props.required}
                            text={
                                props.title ??
                                (mode === "MULTI"
                                    ? translation({
                                          sv: "Valda produkter",
                                          en: "Selected products",
                                      })
                                    : translation({
                                          sv: "Vald produkt",
                                          en: "Selected product",
                                      }))
                            }
                        />
                        <ButtonPrimaryLight
                            disabled={props.spin}
                            padding={{ leftRight: 10, topBottom: 5 }}
                            onClick={show}>
                            <TextBox
                                size={0.9}
                                weight="400"
                                text={
                                    props.actionTitle ??
                                    (mode === "MULTI"
                                        ? translation({
                                              sv: "Välj produkter",
                                              en: "Select products",
                                          })
                                        : translation({
                                              sv: "Välj produkt",
                                              en: "Select product",
                                          }))
                                }
                            />
                        </ButtonPrimaryLight>
                    </FlexRow>
                    <VerticalDivider />
                    <Conditional
                        when={props.spin}
                        render={() => (
                            <TextShimmer rows={2} css={{ paddingTop: 5 }} />
                        )}
                    />
                    <Conditional
                        whenNot={props.spin}
                        render={() => (
                            <Conditional
                                when={!!productSortOrder.length}
                                render={() => (
                                    <SortableContext
                                        items={productSortOrder}
                                        strategy={verticalListSortingStrategy}>
                                        <ForEach
                                            itemOf={productSortOrder}
                                            getKey={productId => productId}>
                                            {productId => {
                                                const product =
                                                    productLookup.get(
                                                        productId,
                                                    );
                                                if (!product) return null;
                                                return (
                                                    <Sortable
                                                        key={product._id}
                                                        id={product._id}
                                                        style={{
                                                            // note: this below is to make the grid
                                                            // note: a responsive square grid
                                                            width: "100%",
                                                            overflow: "hidden",
                                                            position:
                                                                "relative",
                                                        }}
                                                        withoutDragOverlay
                                                        noDefaultDraggableHandle>
                                                        {({
                                                            dragListeners,
                                                        }) => (
                                                            <ProductPickedRow
                                                                key={
                                                                    product._id
                                                                }
                                                                product={
                                                                    product
                                                                }
                                                                toggle={
                                                                    onToggle
                                                                }
                                                                dragListeners={
                                                                    mode ===
                                                                    "MULTI"
                                                                        ? dragListeners
                                                                        : undefined
                                                                }
                                                            />
                                                        )}
                                                    </Sortable>
                                                );
                                            }}
                                        </ForEach>
                                    </SortableContext>
                                )}
                                else={() => (
                                    <TextBox
                                        onClick={() => {
                                            show();
                                        }}
                                        text={
                                            mode === "MULTI"
                                                ? translation({
                                                      sv: "+ Lägg till produkter",
                                                      en: "+ Add products",
                                                  })
                                                : translation({
                                                      sv: "+ Lägg till produkt",
                                                      en: "+ Add produkt",
                                                  })
                                        }
                                        align="center"
                                        size={0.875}
                                        padding={{ all: 30 }}
                                        color={theme.colors.disabled.text}
                                        css={{
                                            backgroundColor:
                                                theme.colors.textInput
                                                    .background.asString,
                                            borderRadius: theme.radius,
                                            borderStyle: "dashed",
                                            borderWidth: 1,
                                            borderColor:
                                                theme.colors.textInput.border
                                                    .asString,
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                </FlexColumn>
            </DndContext>
            <ProductPickerModal
                modalId={modalId}
                products={products}
                selected={selected}
                onToggle={onToggle}
                title={modalTitle}
                mode={mode}
            />
        </>
    );
};

export { ProductPicker };
export type { Props as ProductPickerProps };
