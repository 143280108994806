import { useFetchClient } from "@@/shared/fetch-client";
import { useMap } from "@@/shared/use-map";
import {
    MutateFunction,
    UseMutationOptions,
    useMutation,
} from "@tanstack/react-query";
import { TranslateCommand, TranslateCommandResponse } from "@towni/common";

const useTextTranslationMutation = (
    options?: UseMutationOptions<
        TranslateCommandResponse,
        unknown,
        TranslateCommand
    >,
) => {
    // If the translation is already in the cache, return it
    // Otherwise, fetch the translation and cache it (these are very local, prevents
    // unnecessary API calls, on multiple button clicks for example)
    const localCache = useMap(new Map<string, TranslateCommandResponse>());
    const fetchClient = useFetchClient();
    const mutationFn: MutateFunction<
        TranslateCommandResponse,
        unknown,
        TranslateCommand
    > = async command => {
        const cached = localCache.get(
            `lang:${command.from}-${command.to}:${command.text}`,
        );
        if (cached) return Promise.resolve(cached);
        const uncached = await fetchClient.post<
            TranslateCommand,
            TranslateCommandResponse
        >({
            route: "/translations/translate",
            body: command,
        });
        localCache.set(
            `lang:${command.from}-${command.to}:${command.text}`,
            uncached,
        );
        return uncached;
    };

    return useMutation<TranslateCommandResponse, unknown, TranslateCommand>({
        ...(options ?? {}),
        mutationFn,
    });
};

export { useTextTranslationMutation };
