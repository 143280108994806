class ProviderNotFoundError extends Error {
    public readonly name = "ProviderNotFoundError" as const;
    constructor(
        public providerId: string,
        public loadingError: unknown = null
    ) {
        super(
            `Provider not found: ${providerId}${
                loadingError
                    ? `; error: ${
                          (loadingError as Error)?.message ||
                          typeof loadingError === "string"
                              ? loadingError
                              : "Loading Error"
                      }`
                    : ""
            }`
        );
    }
}

export { ProviderNotFoundError };
