import {
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import type { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { EntityState, IsoAndUnixTimestamp, toObjectMap } from "@towni/common";

const storedPaymentMethodTypes = ["CARD", "APPLE_GOOGLE_MICROSOFT"];
type StoredPaymentMethodType = typeof storedPaymentMethodTypes[number];
const storedPaymentMethodType = toObjectMap<StoredPaymentMethodType>([
    ...storedPaymentMethodTypes,
]);

type StoredPaymentMethodId = string;

const cardBrands = [
    "amex",
    "diners",
    "discover",
    "jcb",
    "mastercard",
    "unionpay",
    "visa",
    "unknown",
] as const;
type CardBrand = typeof cardBrands[number];
const cardBrand = toObjectMap<CardBrand>([...cardBrands]);

type StoredPaymentMethod = {
    _id: StoredPaymentMethodId;
    _type: "PAYMENT_METHOD_CARD" | "STORED_PAYMENT_METHOD_DETAILS";
    _status: EntityState;
    _createdAt: IsoAndUnixTimestamp;
    provider: "STRIPE";
    userId?: string;
    externalId: string;
    type: "CARD";
    brand: CardBrand;
    country?: string;
    last4: string;
    expiration: {
        month: number;
        year: number;
    };
};

const cardBrandIcon = (brand: CardBrand): IconDefinition => {
    switch (brand) {
        case "amex":
            return faCcAmex;
        case "diners":
            return faCcDinersClub;
        case "discover":
            return faCcDiscover;
        case "jcb":
            return faCcJcb;
        case "mastercard":
            return faCcMastercard;
        case "visa":
            return faCcVisa;
        case "unionpay":
        case "unknown":
        default:
            return faCreditCard;
    }
};

export {
    storedPaymentMethodTypes,
    storedPaymentMethodType,
    cardBrandIcon,
    cardBrand,
};
export type {
    StripePaymentMethod,
    CardBrand,
    StoredPaymentMethodType,
    StoredPaymentMethodId as StoredPaymentMethodId,
    StoredPaymentMethod as StoredPaymentMethod,
};
