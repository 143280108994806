import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import { AcquireType, GetResponse, Product, ProviderId } from "@towni/common";

const fetchProductsForProvider =
    (
        providerId: ProviderId | undefined,
        type?: AcquireType
    ): QueryFunction<Product[]> =>
    async ({ signal }) => {
        if (!providerId) throw new Error("missing provider id or slug");
        const data = await apiFetchClient.get<GetResponse<Product>>({
            route: `/products/for-provider/${encodeURIComponent(providerId)}${
                type ? "?type=" + type : ""
            }`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };

export { fetchProductsForProvider };
