import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
// import { translation } from "@towni/common";

// const actionTexts = [
//     translation({
//         sv: "Boka",
//         en: "Explore",
//     }),
//     translation({
//         sv: "Läs mer",
//         en: "Read more",
//     }),
//     translation({
//         sv: "Utforska",
//         en: "Explore",
//     }),
// ];

const DaftoCycleSection = () => {
    // const getProductId = useProductIdWithFallback();
    // const colors = useDaftoColorContext();
    // const productIds = React.useMemo(
    //     () => [
    //         getProductId("product__21f_wBJ2Il78x4OlfJSq" as ProductId), // cykel när
    //         getProductId("product__kAaG8d20Q-T08UAPH3Oy" as ProductId), // mtb
    //         getProductId("product__UavabqULHDlV95duln6C" as ProductId), // guide mtb
    //     ],
    //     []
    // );

    // const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            title={"Ut över stock och sten"}
            subtitle={
                "Familjeäventyr på Daftö Resort i Strömstad bland tång, salt och pirater. Upplevelser och aktiviteter för alla. Här hittar du ett stort utbud – från bryggbastu till cyklar och våra kända showmiddag."
            }
            userSelect="text"
            sectionAreaId="dafto-cycle"
            padding={{ top: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={15}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {/* {productIds.map((id, index) => {
                    return (
                        <ResponsiveStoreFrontHzProductCard
                            key={hasDuplicates ? index : id}
                            cardSize={1.4 as RemSize}
                            aspectRatio="1:1"
                            actionText={actionTexts[index]}
                            productId={id}
                            actionTextColorSet={colors.primary}
                            hideKicker
                        />
                    );
                })} */}
                <HorizontalDivider size="L" />
            </SectionAreaList>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { DaftoCycleSection };
