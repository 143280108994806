import { Provider, toUnix } from "@towni/common";
import { useEffect, useState } from "react";

const isProviderPaused = (provider: Provider, date = new Date()) => {
    return (
        provider.options?.paused &&
        provider.options.paused.until.unix > toUnix(date)
    );
};

const useIsProviderPaused = (provider: Provider) => {
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        setIsPaused(!!(provider && isProviderPaused(provider)));
    }, [provider]);

    return isPaused;
};

export { isProviderPaused, useIsProviderPaused };
