import { browserLogger } from "@@/settings";
import { css, SerializedStyles } from "@emotion/react";
import {
    Dimensions,
    ImageUrl,
    isNumber,
    SECONDS,
    Translatable,
} from "@towni/common";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { useEffect, useState } from "react";
import { Conditional } from "../conditional";
import { FlexColumn } from "../flex-containers";
import { useBackgroundStyleToCss } from "../stylable/background-style-css";
import { useIsMountedRef } from "../use-is-mounted-ref";
import { ImageSrc } from "./image-src";
import { Img } from "./img";
import { useBlurhash } from "./use-blurhash";

type Props = {
    readonly sources: ImageSrc | ImageSrc[] | undefined;
    readonly title: Translatable;
    readonly blurhash?: string;
    readonly styling?: SerializedStyles;
    /**
     * container width
     * @type {(number | string)}
     */
    readonly width?: number | string;
    /**
     * container height
     * @type {(number | string)}
     */
    readonly height?: number | string;
    /**
     * container min height
     * @type {(number | string)}
     */
    readonly minHeight?: number | string;
    /**
     * container min width
     * @type {(number | string)}
     */
    readonly minWidth?: number | string;
    /**
     * defaults to 0.2
     * @type {SECONDS}
     */
    readonly radius?: number;
    readonly fade?: SECONDS;
    /**
     * defaults to true
     *
     * @type {boolean}
     */
    readonly fit?: boolean;
    readonly fallback?: JSX.Element;
    readonly preload?: boolean; //todo create a real preload comp or hook or something
    readonly spinner?: JSX.Element;
    readonly alwaysUseSpace?: boolean;
    readonly className?: string;
    readonly onImageLoad?: (
        event: React.SyntheticEvent<HTMLImageElement>,
    ) => void;
};

const ImageContainer = (props: Props) => {
    const backgroundStyleToCss = useBackgroundStyleToCss();
    const blurhashDataUrl = useBlurhash(props.blurhash);

    const [status, setStatus] = useState<"LOADED" | "NO_IMAGE" | "LOADING">(
        "LOADING",
    );
    const [imageDimensions, setImageDimensions] = useState(
        new Map<ImageUrl, Dimensions>(),
    );

    // Track if component is mounted
    const isMounted = useIsMountedRef();

    const onImageLoad = (element: HTMLImageElement) => {
        const image = element;
        if (!isMounted.current) return;
        setImageDimensions(old => {
            const update = new Map(old);
            update.set(image.src as ImageUrl, {
                width: image.naturalWidth,
                height: image.naturalHeight,
                aspectRatio: image.naturalWidth / image.naturalHeight,
                orientation:
                    image.naturalWidth === image.naturalHeight
                        ? "SQUARE"
                        : image.naturalWidth > image.naturalHeight
                          ? "LANDSCAPE"
                          : "PORTRAIT",
            });
            return update;
        });
        setStatus("LOADED");
    };

    // Load image
    useEffect(() => {
        if (!props.sources) {
            setStatus("NO_IMAGE");
            return;
        }

        setTimeout(() => {
            if (!isMounted.current) return;
            const sources = Array.isArray(props.sources)
                ? props.sources
                : [props.sources];

            (sources.filter(Boolean) as ImageSrc[]).forEach(source => {
                const img = new Image();
                img.src = source.url;
                img.onload = () => {
                    isMounted.current && onImageLoad(img);
                };
                img.onerror = () => {
                    browserLogger.warn(
                        "failed to load image from url: " + img.src,
                    );
                    isMounted.current && setStatus("NO_IMAGE");
                };
                if (img.complete) {
                    isMounted.current && onImageLoad(img);
                }
            });
        }, 0);
    }, [props.sources]);

    const fit = typeof props.fit === "undefined" ? true : props.fit;
    const width = isNumber(props.width)
        ? `${props.width}px`
        : props.width ?? "100%";
    const height = isNumber(props.height)
        ? `${props.height}px`
        : props.height ?? "100%";
    const minHeight = isNumber(props.minHeight)
        ? `${props.minHeight}px`
        : props.minHeight;
    const minWidth = isNumber(props.minWidth)
        ? `${props.minWidth}px`
        : props.minWidth;
    const radius = isNumber(props.radius) ? `${props.radius}px` : props.radius;

    const styling = css({
        position: "relative",
        display: "block",
        objectFit: fit ? "cover" : undefined,
        width,
        height,
        minWidth: minWidth ?? width,
        minHeight: minHeight ?? height ?? "unset",
        borderRadius: radius,
        label: "image_container",
    });
    const backgroundStyles = backgroundStyleToCss({
        imageUrl: blurhashDataUrl,
        fit: "COVER",
        imageSize: {
            imageWidth: 128,
        },
    });

    if (props.preload) return null;
    if (status === "NO_IMAGE" && props.fallback) return props.fallback;
    if (
        (!props.alwaysUseSpace && status !== "LOADING" && !props.sources) ||
        (Array.isArray(props.sources) && props.sources.length === 0)
    )
        return null;

    const image = (
        <Img
            sources={props.sources}
            title={props.title}
            onImageLoad={props.onImageLoad}
            imageDimensions={imageDimensions}
            style={{
                width,
                height,
                borderRadius: radius,
                objectFit: fit ? "cover" : "unset",
                minHeight: minHeight ?? height ?? "unset",
            }}
        />
    );

    if (status === "LOADED" && !props.blurhash && image && !props.fade)
        return image;

    return (
        <div
            css={[styling, backgroundStyles, props.styling]}
            className={props.className}>
            <Conditional
                when={status === "LOADING" && !!props.spinner}
                render={() => (
                    <FlexColumn fillParent crossAxis="center" mainAxis="center">
                        {props.spinner}
                    </FlexColumn>
                )}
            />
            <Conditional
                when={status === "LOADED" && image && !props.fade}
                render={() => <>{image}</>}
            />
            <AnimatePresence>
                <Conditional
                    when={
                        !(
                            status === "LOADED" &&
                            image &&
                            !props.fade &&
                            !!props.sources
                        )
                    }
                    render={() => (
                        <motion.div
                            key={"image"}
                            style={{
                                zIndex: 5,
                                width,
                                height,
                                minHeight: minHeight ?? height ?? "unset",
                            }}
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                            }}
                            exit={{
                                opacity: 0,
                            }}
                            transition={{
                                type: "tween",
                                ease: "anticipate",
                                duration: props.fade ?? 0,
                            }}>
                            {image}
                        </motion.div>
                    )}
                />
            </AnimatePresence>
        </div>
    );
};

export { ImageContainer };
