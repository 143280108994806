import { useModal } from "@@/modals/use-modal";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { Sortable } from "@@/shared/pictures/sortable";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { moveWithinArrayImmutable } from "@@/shared/text/move-within-array";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";
import {
    ResourceMap,
    ResourceMapId,
    Translatable,
    generateId,
    translation,
} from "@towni/common";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { ResourceMapPickedRow } from "./resource-map-picked-row";
import { ResourceMapPickerModal } from "./resource-map-picker-modal";

type Props = {
    title?: Translatable;
    resourceMaps: ResourceMap[];
    spin?: boolean;
    required?: boolean;
    singleOnly?: boolean;
    initiallySelected: Set<ResourceMapId>;
    onChange: (selected: ResourceMapId[]) => void;
};

const ResourceMapPicker = (props: Props) => {
    const theme = useTheme();
    const { resourceMaps, title } = props;
    const [pickerId] = useState(
        generateId({ length: 8, prefix: "resource_picker__" }),
    );
    const [selected, setSelected] = useState(
        props.initiallySelected ?? new Set<ResourceMapId>(),
    );
    const [{ show }, modalId] = useModal("resource_picker_modal__");
    const resourceLookup = useMemo(() => {
        return new Map(
            resourceMaps.map(resource => [resource._id, resource] as const),
        );
    }, [resourceMaps]);
    const [resourceSortOrder, setResourceSortOrder] = useState<ResourceMapId[]>(
        [],
    );

    // Quick fix to prevent endless loop in useEffect
    const resourceSortOrderRef = React.useRef(resourceSortOrder);
    resourceSortOrderRef.current = resourceSortOrder;

    useEffect(() => {
        // filter out resources that are not selected
        const updated = resourceSortOrderRef.current.filter(id =>
            selected.has(id),
        );
        // add new resources
        const additions = Array.from(selected).filter(
            id => !resourceSortOrderRef.current.includes(id),
        );
        const newOrder = [...updated, ...additions];
        setResourceSortOrder(newOrder);
    }, [selected]);

    useUpdateEffect(() => {
        props.onChange(resourceSortOrder);
    }, [resourceSortOrder]);

    const onToggle = (resourceMapId: ResourceMapId) => {
        setSelected(items => {
            if (props.singleOnly) {
                // Only allow one item to be selected
                return items.has(resourceMapId)
                    ? new Set([])
                    : new Set([resourceMapId]);
            }
            const update = new Set(items);
            if (update.has(resourceMapId)) {
                update.delete(resourceMapId);
                return update;
            }

            update.add(resourceMapId);
            return update;
        });
    };

    const handleDragEnd = React.useCallback(
        ({ active, over }: DragEndEvent) => {
            // if (active.id) setSelected(active.id);
            // browserLogger.log("SET SELECTED - DRAG END", selected, active.id);
            if (!over) return;
            if (active.id !== over?.id) {
                const from = resourceSortOrder.findIndex(
                    value => value === active.id,
                );
                const target = resourceSortOrder.findIndex(
                    value => value === over.id,
                );
                const newOrder = moveWithinArrayImmutable(
                    resourceSortOrder,
                    from,
                    target,
                );
                setResourceSortOrder(newOrder);
            }
        },
        [resourceSortOrder, setResourceSortOrder],
    );

    return (
        <>
            <DndContext
                id={pickerId}
                key={pickerId}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}>
                <FlexColumn fillParentWidth>
                    <FlexRow fillParentWidth mainAxis="space-between">
                        <FieldTitle
                            text={translation({
                                sv: "Karta",
                                en: "Map",
                            })}
                            required={props.required}
                        />
                        <ButtonPrimaryLight
                            disabled={props.spin}
                            padding={{ leftRight: 10, topBottom: 5 }}
                            onClick={show}>
                            <TextBox
                                size={0.9}
                                weight="400"
                                text={translation({
                                    sv: "Välj karta",
                                    en: "Select map",
                                })}
                            />
                        </ButtonPrimaryLight>
                    </FlexRow>
                    <VerticalDivider />
                    <Conditional
                        when={props.spin}
                        render={() => (
                            <TextShimmer rows={2} css={{ paddingTop: 5 }} />
                        )}
                    />
                    <Conditional
                        whenNot={props.spin}
                        render={() => (
                            <Conditional
                                when={!!resourceSortOrder.length}
                                render={() => (
                                    <SortableContext
                                        items={resourceSortOrder}
                                        strategy={verticalListSortingStrategy}>
                                        <ForEach
                                            itemOf={resourceSortOrder}
                                            getKey={resMapId => resMapId}>
                                            {resourceId => {
                                                const resourceMap =
                                                    resourceLookup.get(
                                                        resourceId,
                                                    );
                                                if (!resourceMap) return null;
                                                return (
                                                    <Sortable
                                                        key={resourceMap._id}
                                                        id={resourceMap._id}
                                                        style={{
                                                            // note: this below is to make the grid
                                                            // note: a responsive square grid
                                                            width: "100%",
                                                            overflow: "hidden",
                                                            position:
                                                                "relative",
                                                        }}
                                                        withoutDragOverlay
                                                        noDefaultDraggableHandle>
                                                        {({
                                                            dragListeners,
                                                        }) => (
                                                            <ResourceMapPickedRow
                                                                key={
                                                                    resourceMap._id
                                                                }
                                                                resourceMap={
                                                                    resourceMap
                                                                }
                                                                toggle={
                                                                    onToggle
                                                                }
                                                                dragListeners={
                                                                    dragListeners
                                                                }
                                                            />
                                                        )}
                                                    </Sortable>
                                                );
                                            }}
                                        </ForEach>
                                    </SortableContext>
                                )}
                                else={() => (
                                    <TextBox
                                        onClick={() => {
                                            show();
                                        }}
                                        text={translation({
                                            sv: "+ Lägg till karta",
                                            en: "+ Add map",
                                        })}
                                        align="center"
                                        size={0.875}
                                        padding={{
                                            leftRight: 20,
                                            topBottom: 25,
                                        }}
                                        color={theme.colors.disabled.text}
                                        css={{
                                            backgroundColor:
                                                theme.colors.textInput
                                                    .background.asString,
                                            borderRadius: theme.radius,
                                            borderStyle: "dashed",
                                            borderWidth: 1,
                                            borderColor:
                                                theme.colors.textInput.border
                                                    .asString,
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                </FlexColumn>
            </DndContext>
            <ResourceMapPickerModal
                modalId={modalId}
                resourceMaps={resourceMaps}
                selected={selected}
                onToggle={onToggle}
                title={title}
            />
        </>
    );
};

export { ResourceMapPicker };
