import { Conditional } from "@@/shared/conditional";
import { FlexColumn } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import {
    IconDefinition,
    faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import { Percentage, isFunction } from "@towni/common";
import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { Except } from "type-fest";

type ContextState = {
    collapsible: boolean;
    collapsed: boolean;
    toggleCollapsed: (collapsed?: boolean) => void;
};
type CollapseBoxRenderProp = (params: ContextState) => React.ReactNode;
const CollapseBoxContext = createContext<ContextState | undefined>(undefined);

const CollapseBoxContextProvider = (props: {
    readonly collapsible: boolean;
    readonly noRecollapse?: boolean;
    readonly initiallyCollapsed?: boolean;
    readonly children: React.ReactNode;
}) => {
    const collapsible = props.collapsible ?? true;
    const [collapsed, setCollapsed] = useState(
        collapsible && (props.initiallyCollapsed ?? true),
    );
    const collapsedRef = useRef(collapsed);
    collapsedRef.current = collapsed;

    const toggleCollapsed = useCallback(
        (value?: boolean) => {
            if (!collapsible) return;
            if (props.noRecollapse && collapsedRef.current === false) return;
            setCollapsed(current => {
                const newValue = value ?? !current;
                return newValue;
            });
        },
        [props.noRecollapse, collapsible, setCollapsed],
    );

    const value = useMemo(
        () => ({ collapsed, toggleCollapsed, collapsible }),
        [collapsed, toggleCollapsed, collapsible],
    );

    return (
        <CollapseBoxContext.Provider value={value}>
            {props.children}
        </CollapseBoxContext.Provider>
    );
};

const useCollapseBoxContext = () => {
    const context = useContext(CollapseBoxContext);
    if (!context) {
        throw new Error(
            "useCollapseBoxContext must be used within a CollapseBoxContextProvider",
        );
    }
    return context;
};

type Props = {
    readonly className?: string;
    readonly fillParentWidth?: boolean;
    readonly children?: React.ReactNode | CollapseBoxRenderProp;
    readonly collapsible?: boolean;
    readonly collapsedHeight?: number;
    readonly fadeHeight?: Percentage;
    /** If box has been uncollapsed, never collapse it again, no toggle */
    readonly noRecollapse?: boolean;
    readonly hideIcon?: boolean;
    readonly icon?: IconDefinition;
    /** Defaults to true */
    readonly initiallyCollapsed?: boolean;
    readonly iconCss?: Interpolation<AppTheme>;
};

const _CollapseBox = (
    props: Except<Props, "collapsible" | "noRecollapse" | "initiallyCollapsed">,
) => {
    const theme = useTheme();
    const { collapsible, collapsed, toggleCollapsed } = useCollapseBoxContext();
    const collapsedHeight = props.collapsedHeight ?? 80;
    const defaultTextColor = theme.colors.default.text.withAlpha(0.6).asString;
    const minHeight = collapsed ? collapsedHeight : undefined;
    const maxHeight = collapsed ? collapsedHeight : undefined;
    const height = collapsed ? collapsedHeight : undefined;
    const fadeAndClickHeight = collapsedHeight * (props.fadeHeight ?? 0.9);
    const icon = props.icon ?? faChevronDown;
    const toggle = useCallback(() => {
        toggleCollapsed();
    }, [toggleCollapsed]);
    return (
        <FlexColumn
            fillParentWidth
            mainAxis="stretch"
            crossAxis="stretch"
            className={props.className}
            onClick={toggle}
            css={[
                {
                    position: "relative",
                    overflow: collapsed ? "hidden" : "unset",
                    color: defaultTextColor,
                    minHeight,
                    height,
                    maxHeight,
                    backgroundColor: theme.colors.transparent.asString,
                    label: "collapse_box",
                },
                props.fillParentWidth
                    ? {
                          width: "100%",
                      }
                    : {},
            ]}>
            <FlexColumn
                css={{
                    flex: 1,
                    padding: 0,
                    label: "info_box_inner_container",
                    overflow: "hidden",
                    minHeight,
                    maxHeight,
                }}>
                {isFunction(props.children)
                    ? props.children({
                          collapsible,
                          collapsed,
                          toggleCollapsed,
                      })
                    : props.children}
            </FlexColumn>
            <Conditional when={collapsed}>
                <div
                    css={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        padding: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        minHeight: fadeAndClickHeight,
                        zIndex: 100,
                        paddingBottom: 10,
                        background: `linear-gradient(180deg, ${theme.colors.transparent.asString} 0%, ${theme.colors.default.background.asString} 100%)`,
                    }}>
                    <Conditional when={!props.hideIcon}>
                        <Icon icon={icon} css={props.iconCss} />
                    </Conditional>
                </div>
            </Conditional>
        </FlexColumn>
    );
};

const CollapseBox = (props: Props) => {
    const { collapsible, initiallyCollapsed, noRecollapse, ...passOnProps } =
        props;
    return (
        <CollapseBoxContextProvider
            collapsible={collapsible ?? true}
            initiallyCollapsed={initiallyCollapsed ?? (collapsible && true)}
            noRecollapse={noRecollapse}>
            <_CollapseBox {...passOnProps} />
        </CollapseBoxContextProvider>
    );
};

export { CollapseBox };
