import { apiFetchClient } from "@@/shared/fetch-client";
import {
    emptyArrayOf,
    GetResponse,
    ProductId,
    ProviderId,
    ResourceGlobal,
    ResourceGraph,
} from "@towni/common";

const fetchResourcesForProviderRequests =
    (providerId: ProviderId | undefined) => async () => {
        if (typeof providerId === "undefined")
            return emptyArrayOf<ResourceGlobal>();

        const data = await apiFetchClient.get<GetResponse<ResourceGlobal>>({
            route: `/resources/for-provider/${encodeURIComponent(providerId)}`,
        });
        return data.items;
    };

const fetchBookableResourcesForProduct =
    (productId: ProductId | undefined) => async () => {
        if (typeof productId === "undefined")
            return emptyArrayOf<ResourceGraph>();
        const data = await apiFetchClient.get<GetResponse<ResourceGraph>>({
            route: `/resources/for-product/${encodeURIComponent(productId)}`,
        });
        return data.items;
    };
export { fetchBookableResourcesForProduct, fetchResourcesForProviderRequests };
