import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { useCart } from "@@/carts/multi-carts/cart.context";
import { ModalId } from "@@/modals/context/modal-id";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useOptionalGroups } from "@@/optional-groups/state/optional-group-fetchers";
import { AppPage } from "@@/pages/app-page";
import { PageLoad } from "@@/pages/page-load";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { EditInCartWithQuantity } from "@@/shared/buttons/add-to-cart-with-quantity-picker/edit-in-cart-with-quantity";
import { BackButton } from "@@/shared/buttons/back-button";
import { Conditional } from "@@/shared/conditional";
import { HeadTitle } from "@@/shared/head-title";
import { isRunningAsPwa } from "@@/shared/is-running-as-pwa";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    isAlfredItemActive,
    isBuyable,
    OrderItem,
    OrderItemId,
    Provider,
    translation,
} from "@towni/common";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { BuyableDetails } from "./buyable-details";
import { ProductDetailsContextProvider } from "./product-details-context";
import { ExtrasProps } from "./product-details-page";
import { useProduct } from "./state/queries/product/use-product";
const useOrderItemFromCart = (orderItemId: OrderItemId): OrderItem => {
    const cart = useCart();
    const orderItem = cart.orderItems.find(item => item._id === orderItemId);
    if (!orderItem)
        throw new Error(`Order item ${orderItemId} not found in cart`);
    return orderItem;
};
const OrderItemDetailsPage = (props: {
    orderItemId: OrderItemId;
    provider: Provider;
    hideLinkToShop: boolean;
    pageAnimationDisabled?: boolean;
    modalId: ModalId;
    extras?: ExtrasProps;
}) => {
    const { provider } = props;
    const { pathname } = useLocation();
    const { hide } = useModalActions(props.modalId);
    const orderItem = useOrderItemFromCart(props.orderItemId);

    const [product, productQuery] = useProduct(
        isBuyable(orderItem) ? orderItem.productId : undefined,
    );

    const [optionalGroups] = useOptionalGroups(product?.optionalGroupIds);
    const [addToCartButtonsHidden] = useState(false);

    if (productQuery.isPending) return <PageLoad />;
    if (productQuery.error) throw new Error();
    if (!product)
        return (
            <PageNotFound
                reason={translation({
                    sv: "Produkten fanns inte i kundkorgen",
                    en: "Product not found in cart",
                })}
            />
        );

    return (
        <ProductDetailsContextProvider
            product={product}
            provider={provider}
            extras={props.extras}
            orderItemId={props.orderItemId}>
            <HeadTitle
                title={[
                    translation({
                        sv: "Produkt",
                        en: "Product",
                    }),
                    product.meta.title,
                    provider.meta.title ?? provider.slug,
                ]}
            />
            <AppPage
                pageId={appPageIdFactory(pathname)}
                isLoading={!provider}
                anchors={[]}
                pageAnimationDisabled={props.pageAnimationDisabled}
                forgetScrollPosition={true}
                backButton={<BackButton icon={faTimes} onClick={hide} />}>
                <BuyableDetails
                    provider={provider}
                    product={product}
                    optionalGroups={optionalGroups}
                    hideLinkToShop={props.hideLinkToShop}
                />
            </AppPage>
            <AnimatePresence>
                <Conditional
                    when={
                        !addToCartButtonsHidden &&
                        isAlfredItemActive(product?.status)
                    }
                    render={() => (
                        <EditInCartWithQuantity
                            orderItemId={props.orderItemId}
                            hide={hide}
                            clearBeforeAdding={false}
                            pickLimit={
                                props.extras
                                    ? {
                                          minMax: props.extras.minMax,
                                          alreadyPicked:
                                              props.extras.pickedAmount,
                                      }
                                    : undefined
                            }
                            padding={
                                isRunningAsPwa
                                    ? { top: 15, bottom: 25, leftRight: 20 }
                                    : { all: 15 }
                            }
                        />
                    )}
                />
            </AnimatePresence>
        </ProductDetailsContextProvider>
    );
};

export { OrderItemDetailsPage };
export default OrderItemDetailsPage;
