import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { css } from "@emotion/react";
import * as React from "react";
import { FC } from "react";

const WhatIsTowniSection: FC = () => {
    return (
        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
            <TextBox
                text={`Vad är Towni?`}
                size="XL"
                weight={"800"}
                padding={{ right: 50 }}
            />
            <VerticalDivider S />
            <TextBox
                text={`En tjänst för att lättare ta del av Lidköpings utbud! Vi bygger det tillsammans med alla våra lokala lidköpingshjältar och vi har jobbat dag och natt för att få igång det.`}
                size="M"
                styling={css`
                    line-height: 24px;
                `}
            />
            <TextBox
                text={`Beställ till jobbet, picknick eller kvällsmys! Allt lokalt samt klart och betalt vid hämtning/leverans. 🙌`}
                size="M"
                styling={css`
                    line-height: 24px;
                `}
            />
        </FlexColumn>
    );
};

export { WhatIsTowniSection };
