import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox, TextEdit } from "@@/shared/text";
import { useGiftcardsForSearch } from "@@/tickets/giftcards/use-giftcards-search";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { TicketGiftCard, translation } from "@towni/common";
import { useState } from "react";

type Props = {
    modalId: ModalId;
    addGiftCard: (ticket: TicketGiftCard) => void;
};

const AddGiftCardModal = (props: Props) => {
    const theme = useTheme();
    const { hide } = useModalActions(props.modalId);

    const toast = useToast();
    const [code, setCode] = useState<string>("");

    const [giftcards, _query] = useGiftcardsForSearch(
        code.toUpperCase().replace(/\s/g, ""),
    );

    return (
        <Modal modalId={props.modalId}>
            <FlexColumn fillParentWidth crossAxis="center" mainAxis="flex-end">
                <FlexRow fillParentWidth>
                    <TextEdit
                        placeholder={translation({
                            sv: "Lägg till presentkortskod",
                            en: "Add giftcard code",
                        })}
                        type="text"
                        onChange={setCode}
                        value={code}
                        showSpinner={_query.isFetching}
                        icon={giftcards.length > 0 ? faCheckCircle : undefined}
                    />
                </FlexRow>
                <div
                    onClick={() => {
                        if (giftcards.length > 0) {
                            const giftcard = giftcards[0];
                            if (giftcard.status === "ACTIVE") {
                                props.addGiftCard(giftcards[0]);
                            } else {
                                toast.warning({
                                    message: translation({
                                        sv: "Presentkortet har utgått",
                                        en: "Giftcard has expired",
                                    }),
                                });
                            }
                        } else if (code.length > 0 && _query.isFetched) {
                            toast.warning({
                                message: translation({
                                    sv:
                                        "Inget presentkort hittad med kod: " +
                                        code,
                                    en: "No giftcard found",
                                }),
                            });
                        }

                        setCode("");
                        hide();
                    }}
                    css={{
                        padding: 20,
                        width: "100%",
                        height: 80,
                        backgroundColor: theme.colors.primary.asString,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Bekräfta presentkort",
                            en: "Use giftcard",
                        })}
                        size="L"
                        color={theme.colors.primary.text}
                    />
                </div>
            </FlexColumn>
        </Modal>
    );
};

export { AddGiftCardModal };
