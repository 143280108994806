import { useOrderSocketEventsForMe } from "@@/backoffice/for-providers/orders/use-orders-events-for-me";
import { useOrderExternalAccessesForOrder } from "@@/backoffice/for-providers/products/bookable/digital-doors/queries/use-order-external-accesses";
import { useProductExternalAccessesForOrder } from "@@/backoffice/for-providers/products/bookable/digital-doors/queries/use-product-external-access-for-order";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    faBinaryLock,
    faCheckCircle,
    faMobile,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Order,
    OrderExternalAccess,
    ProductExternalAccess,
    isWithinTimeRange,
    now,
    translation,
} from "@towni/common";
import { useEffect } from "react";

type Props = {
    order: Order;
};

const OrderExternalKeyAccess = (props: Props) => {
    const theme = useTheme();
    const [orderExternalAccesses, query] = useOrderExternalAccessesForOrder(
        props.order._id,
    );

    useOrderSocketEventsForMe({
        onOrderEvent: (event, _room) => {
            if (event.data.orderId === props.order._id) {
                query.refetch();
            }
        },
    });
    if (query.isLoading)
        return (
            <FlexColumn fillParent padding={{ leftRight: 5 }}>
                <VerticalDivider />
                <TextShimmer rows={6} disableFirstRowIndent />
            </FlexColumn>
        );

    if (orderExternalAccesses.length === 0)
        return (
            <>
                <FlexColumn fillParent padding={{ leftRight: 20 }}>
                    <TextBox
                        text={translation({
                            sv: "Din bokning innehåller nycklar som kommer att aktiveras och bli tillgängliga här strax innan din bokningstid",
                            en: "Your booking contains keys that will be activated and become available here shortly before your booking time",
                        })}
                        size="M"
                        color={theme.colors.black.light60}
                    />
                </FlexColumn>
                <VerticalDivider />
            </>
        );

    return (
        <>
            <FlexColumn padding={{ leftRight: 20 }}>
                <ForEach
                    itemOf={orderExternalAccesses}
                    divider={<VerticalDivider />}>
                    {access => (
                        <OrderExternalAccessBox externalAccess={access} />
                    )}
                </ForEach>
                <VerticalDivider />
            </FlexColumn>
        </>
    );
};

const OrderExternalAccessBox = ({
    externalAccess,
}: {
    externalAccess: OrderExternalAccess;
}) => {
    const theme = useTheme();
    const [productAccesses, query] = useProductExternalAccessesForOrder(
        externalAccess.orderId,
        externalAccess.accessId,
    );

    const isValid =
        externalAccess.valid && isWithinTimeRange(now(), externalAccess.valid);

    useEffect(() => {
        query.refetch();
    }, [isValid, query]);

    if (productAccesses.length === 0) return null;
    //TODO FIX SO YOU GET ONE
    const productAccess = productAccesses[0];

    switch (productAccess.type) {
        case "PARAKEY":
            return (
                <_ParakeyAccess
                    externalAccess={externalAccess}
                    productAccess={productAccess}
                />
            );
        case "PASSCODE":
            return (
                <_PassCodeAccess
                    externalAccess={externalAccess}
                    productAccess={productAccess}
                />
            );
        case "LOCKY":
            return (
                <_LockyAccess
                    externalAccess={externalAccess}
                    productAccess={productAccess}
                />
            );
    }
};

type AccessProps = {
    externalAccess: OrderExternalAccess;
    productAccess: ProductExternalAccess;
};
const _ParakeyAccess = (props: AccessProps) => {
    const { externalAccess, productAccess } = props;
    const theme = useTheme();

    if (props.productAccess.type !== "PARAKEY") return null;

    return (
        <FlexColumn
            fillParentWidth
            css={{
                borderRadius: 8,
                borderWidth: 1,
                borderColor: theme.colors.black.light70.asString,
                borderStyle: "solid",
                backgroundColor:
                    theme.colors.information.light.light95.asString,
            }}>
            <FlexRow
                mainAxis="flex-start"
                padding={{ leftRight: 15, topBottom: 10 }}>
                <FlexRow fillParent mainAxis="flex-start">
                    <FlexColumn
                        padding={{ all: 10 }}
                        css={{
                            borderRadius: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faMobile}
                            style={{
                                fontSize: "1.5rem",
                                width: "30px",
                                color: theme.colors.information.asString,
                            }}
                        />
                    </FlexColumn>
                    <HorizontalDivider M />

                    <FlexColumn>
                        <TextBox
                            text={productAccess.name}
                            weight="700"
                            color={theme.colors.white.light40}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={translation({
                                sv: "Parakey-instruktioner kommer via e-post",
                            })}
                            weight="700"
                            color={theme.colors.white.light40}
                        />
                    </FlexColumn>
                    <HorizontalDivider XS />
                </FlexRow>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                        fontSize: "2rem",
                        color: theme.colors.information.asString,
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
};

const _PassCodeAccess = (props: AccessProps) => {
    const { productAccess } = props;
    const theme = useTheme();

    if (productAccess.type !== "PASSCODE") return null;

    return (
        <FlexColumn
            fillParentWidth
            css={{
                borderRadius: 8,
                borderWidth: 1,
                borderColor: theme.colors.black.light70.asString,
                borderStyle: "solid",
                backgroundColor:
                    theme.colors.information.light.light95.asString,
            }}>
            <FlexRow
                mainAxis="flex-start"
                padding={{ leftRight: 15, topBottom: 10 }}>
                <FlexRow fillParent mainAxis="flex-start">
                    <FlexColumn
                        padding={{ all: 10 }}
                        css={{
                            borderRadius: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faBinaryLock}
                            style={{
                                fontSize: "1.5rem",
                                width: "30px",
                                color: theme.colors.information.asString,
                            }}
                        />
                    </FlexColumn>
                    <HorizontalDivider M />

                    <FlexColumn>
                        <TextBox
                            text={productAccess.name}
                            weight="700"
                            color={theme.colors.white.light40}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={productAccess.data.code}
                            weight="700"
                            color={theme.colors.white.light40}
                        />
                    </FlexColumn>
                    <HorizontalDivider XS />
                </FlexRow>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                        fontSize: "2rem",
                        color: theme.colors.information.asString,
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
};

const _LockyAccess = (props: AccessProps) => {
    const { externalAccess, productAccess } = props;
    const theme = useTheme();

    if (props.productAccess.type !== "LOCKY") return null;

    return (
        <FlexColumn
            fillParentWidth
            css={{
                borderRadius: 8,
                borderWidth: 1,
                borderColor: theme.colors.black.light70.asString,
                borderStyle: "solid",
                backgroundColor:
                    theme.colors.information.light.light95.asString,
            }}>
            <FlexRow
                mainAxis="flex-start"
                padding={{ leftRight: 15, topBottom: 10 }}>
                <FlexRow fillParent mainAxis="flex-start">
                    <FlexColumn
                        padding={{ all: 10 }}
                        css={{
                            borderRadius: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faBinaryLock}
                            style={{
                                fontSize: "1.5rem",
                                width: "30px",
                                color: theme.colors.information.asString,
                            }}
                        />
                    </FlexColumn>
                    <HorizontalDivider M />

                    <FlexColumn>
                        <TextBox
                            text={productAccess.name}
                            weight="700"
                            color={theme.colors.white.light40}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={translation({
                                sv: `Kod: ${externalAccess.data?._type === "LOCKY_ORDER_DATA" ? externalAccess.data.code : "SAKNAS"}`,
                            })}
                            weight="700"
                            color={theme.colors.white.light40}
                        />
                    </FlexColumn>
                    <HorizontalDivider XS />
                </FlexRow>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                        fontSize: "2rem",
                        color: theme.colors.information.asString,
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
};

export { OrderExternalKeyAccess };
