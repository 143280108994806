import * as React from "react";
import { SectionListHz } from "./section-content-list-hz";
import { SectionListVt } from "./section-content-list-vt";
import { SectionDisplayProps } from "../section-content-display";
import { directionsMap } from "@towni/common";

const SectionList = ({
    sectionTheme,
    children,
    ...props
}: SectionDisplayProps) => {
    const { direction } = sectionTheme;
    const { VT, HZ } = directionsMap;

    let ListOutput: (props: SectionDisplayProps) => JSX.Element;
    switch (direction) {
        case VT:
            ListOutput = SectionListVt;
            break;
        case HZ:
        default:
            ListOutput = SectionListHz;
            break;
    }

    return (
        <ListOutput sectionId={props.sectionId} sectionTheme={sectionTheme}>
            {children}
        </ListOutput>
    );
};

export { SectionList };
