import { Cart_Accommodation_ResourceReservationRequestList } from "@@/carts/accommodation-checkout/cart_resource-reservation-request-list";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Paragraph, TextBox, paragraphTextFactory } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    OrderItemAccommodation_V2,
    Resource,
    ResourceReservation,
    ResourceReservationRequest,
    TimeRange,
    asDate,
    getShortDateWeekDayMonth,
    getTimeOfDay,
    joinTranslatables,
    sum,
    translation,
    type ColorItem,
} from "@towni/common";

type Props = {
    orderItem: OrderItemAccommodation_V2;
    backgroundColor?: ColorItem;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
    resources: Resource[];
};

const AccommodationOrderItemBody = (props: Props) => {
    const theme = useTheme();
    const { orderItem, backgroundColor, resourceReservations } = props;

    return (
        <FlexColumn
            background={{
                color:
                    backgroundColor ??
                    theme.colors.primary.main.value.withAlpha(0.1),
            }}>
            <VerticalDivider M />
            <FlexRow
                padding={{ leftRight: 20 }}
                mainAxis="space-between"
                crossAxis="center">
                <TextBox
                    size="L"
                    weight="800"
                    text={translation({
                        sv: "Bokningsdetaljer",
                        en: "Booking details",
                    })}
                />
            </FlexRow>
            <VerticalDivider S />
            <_BookedRoom orderItem={orderItem} />
            <_CheckInOutTime time={orderItem.time} />
            {/* <_NumberOfAccommodation orderItem={orderItem} /> */}
            <BookedQuantities orderItem={orderItem} />

            <Cart_Accommodation_ResourceReservationRequestList
                resourceReservations={resourceReservations}
                providerId={orderItem.providerId}
                resources={props.resources}
            />

            <VerticalDivider S />
        </FlexColumn>
    );
};

type Props2 = {
    orderItem: OrderItemAccommodation_V2;
};

const BookedQuantities = (props: Props2) => {
    const theme = useTheme();
    const quantity = props.orderItem.quantities.map(q => {
        return q.quantity.value;
    });
    const total = sum(quantity);

    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.light50}
                    text={{ sv: "Antal", en: "Quantity" }}
                />
                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time"
                    text={translation({
                        sv: `${total} gäster`,
                        en: `${total} guests`,
                    })}
                />
                <Conditional when={props.orderItem.quantity.value > 1}>
                    <VerticalDivider XXS />
                    <TextBox
                        size="ML"
                        weight="800"
                        dataTestId="bd-booked-time"
                        text={joinTranslatables([
                            translation({
                                sv: `${props.orderItem.quantity.value} x `,
                                en: `${props.orderItem.quantity.value} x `,
                            }),
                            props.orderItem.meta.title,
                        ])}
                    />
                </Conditional>
            </FlexColumn>
        </FlexRow>
    );
};
const _BookedRoom = (props: Props2) => {
    const theme = useTheme();
    const has = !!props.orderItem.accommodation.type;
    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.light50}
                    text={translation({
                        en: "Selected accommodation:",
                        sv: "Valt boende:",
                    })}
                />

                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time"
                    text={props.orderItem.accommodation.title}
                />

                {/* Filter for old orders */}
                {has ? (
                    <Paragraph
                        css={{
                            color: theme.colors.white.light50.asString,
                        }}
                        content={[
                            paragraphTextFactory({
                                text: props.orderItem.accommodation.type.title,
                            }),

                            ...(props.orderItem.accommodation.type.subType
                                ? [
                                      " - ",
                                      paragraphTextFactory({
                                          text: props.orderItem.accommodation
                                              .type.subType?.title,
                                      }),
                                  ]
                                : []),
                        ]}
                    />
                ) : null}
            </FlexColumn>
        </FlexRow>
    );
};

const _NumberOfAccommodation = (props: Props2) => {
    const theme = useTheme(); //if (props.orderItem.quantity.value === 1) return null;

    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.light50}
                    text={translation({
                        en: "Selected accommodation:",
                        sv: "Antal boenden:",
                    })}
                />

                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time"
                    text={props.orderItem.quantity.value + " st"}
                />
            </FlexColumn>
        </FlexRow>
    );
};

type Props3 = {
    time: TimeRange;
};

const _CheckInOutTime = (props: Props3) => {
    const theme = useTheme();
    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.light50}
                    text={translation({
                        sv: "Incheckning",
                        en: "Check-in",
                    })}
                />

                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time-check-in"
                    text={`${getShortDateWeekDayMonth(
                        asDate(props.time.start),
                    )} ${getTimeOfDay(props.time.start)}`}
                />
            </FlexColumn>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.light50}
                    text={translation({
                        sv: "Utcheckning",
                        en: "Check-out",
                    })}
                />
                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time-check-out"
                    text={`${getShortDateWeekDayMonth(
                        asDate(props.time.end),
                    )} ${getTimeOfDay(props.time.end)}`}
                />
            </FlexColumn>
        </FlexRow>
    );
};

export { AccommodationOrderItemBody };
