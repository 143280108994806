import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
    DateRange,
    GetResponse,
    ProductId,
    RatePriceAdjustment,
    dateRangeToString,
    isProductId,
} from "@towni/common";

const useRateAdjustments = (
    productId: ProductId | undefined,
    dateRange: DateRange | undefined,
) => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<RatePriceAdjustment[]> = async ({
        signal,
    }) => {
        if (!dateRange) return [];
        const data = await fetchClient.get<GetResponse<RatePriceAdjustment>>({
            route: `/products/accommodation/${productId}/adjustments/rates/for-date-range/${dateRangeToString(dateRange)}`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };

    return useQuery({
        queryKey: [
            "rate-adjustments",
            productId,
            dateRange?.start.iso,
            dateRange?.end.iso,
        ],
        queryFn,
        enabled: !!isProductId(productId) && !!dateRange,
    });
};

export { useRateAdjustments };
