import { CollapseBox } from "@@/backoffice/shared/collapse-box";
import { useModal } from "@@/modals/use-modal";
import { ResourcePickedPill } from "@@/resources/picker/resource-picked-pill";
import { ResourcePickedRow } from "@@/resources/picker/resource-picked-row";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { LayoutGrid } from "@@/shared/layout-grid";
import { PaddingProps, paddingToCssValue } from "@@/shared/padding";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { Sortable } from "@@/shared/pictures/sortable";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { moveWithinArrayImmutable } from "@@/shared/text/move-within-array";
import { useElementSize } from "@@/shared/use-element-size";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
    SortableContext,
    rectSortingStrategy,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import {
    Percentage,
    Resource,
    ResourceId,
    Translatable,
    assertNever,
    emptyArrayOf,
    exists,
    generateId,
    isResourceGlobal,
    resourceTypeMeta,
    translation,
} from "@towni/common";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { ResourcePickerModal } from "./resource-picker-modal";

type Props = {
    readonly title?: Translatable;
    readonly resources: Resource[];
    readonly spin?: boolean;
    readonly required?: boolean;
    readonly initiallySelected: Set<ResourceId>;
    readonly onChange: (selected: ResourceId[]) => void;
    readonly padding?: PaddingProps;
} & (
    | {
          mode: "SINGLE";
          renderMulti?: never;
          renderSingle?: (
              resource: Resource | undefined,
              showPicker: () => void,
          ) => JSX.Element;
      }
    | {
          mode?: "MULTI";
          renderSingle?: never;
          renderMulti?: (
              resources: Resource[],
              showPicker: () => void,
          ) => JSX.Element;
      }
);

const ResourcePicker = (props: Props) => {
    const theme = useTheme();
    const mode: Props["mode"] = props.mode || "MULTI";
    const { resources, title } = props;
    const [pickerId] = useState(
        generateId({ length: 8, prefix: "resource_picker__" }),
    );
    const [selected, setSelected] = useState(
        props.initiallySelected ?? new Set<ResourceId>(),
    );
    const [{ show, hide }, modalId] = useModal("resource_picker_modal__");
    const resourceLookup = useMemo(() => {
        return new Map(
            resources.map(resource => [resource._id, resource] as const),
        );
    }, [resources]);
    const [resourceSortOrder, setResourceSortOrder] =
        useState<ResourceId[]>(emptyArrayOf<ResourceId>());
    const resourceSortOrderRef = React.useRef(resourceSortOrder);
    resourceSortOrderRef.current = resourceSortOrder;
    const resourceSortOrderHash = resourceSortOrder.join(",");

    useEffect(() => {
        // filter out resources that are not selected
        const updated = resourceSortOrderRef.current.filter(id =>
            selected.has(id),
        );
        // add new resources
        const additions = Array.from(selected).filter(
            id => !resourceSortOrderRef.current.includes(id),
        );
        const newOrder = [...updated, ...additions];
        setResourceSortOrder(newOrder);
    }, [resourceSortOrderHash, selected]);

    useUpdateEffect(() => {
        props.onChange(resourceSortOrderRef.current);
    }, [resourceSortOrderHash]);

    const [multiContainerSize, containerRef] = useElementSize();

    const onToggle = (resourceId: ResourceId) => {
        switch (mode) {
            case "MULTI":
                setSelected(items => {
                    const update = new Set(items);
                    if (update.has(resourceId)) {
                        update.delete(resourceId);
                        return update;
                    }

                    update.add(resourceId);
                    return update;
                });
                return;
            case "SINGLE":
                setSelected(prev => {
                    // Is it already selected
                    const isSelected = prev.has(resourceId);
                    if (isSelected) return new Set();
                    else return new Set([resourceId]);
                });
                hide();
                return;
            default:
                assertNever(mode);
        }
    };

    const handleDragEnd = React.useCallback(
        ({ active, over }: DragEndEvent) => {
            // if (active.id) setSelected(active.id);
            // browserLogger.log("SET SELECTED - DRAG END", selected, active.id);
            if (!over) return;
            if (active.id !== over?.id) {
                const from = resourceSortOrder.findIndex(
                    value => value === active.id,
                );
                const target = resourceSortOrder.findIndex(
                    value => value === over.id,
                );
                const newOrder = moveWithinArrayImmutable(
                    resourceSortOrder,
                    from,
                    target,
                );
                setResourceSortOrder(newOrder);
            }
        },
        [resourceSortOrder, setResourceSortOrder],
    );

    switch (mode) {
        case "SINGLE": {
            const resource = resourceLookup.get(Array.from(selected)[0]);
            return (
                <>
                    {props.renderSingle ? (
                        props.renderSingle(resource, show)
                    ) : (
                        <>
                            <FlexRow
                                crossAxis="center"
                                mainAxis="space-between">
                                <FieldTitle
                                    padding={{ left: 2 }}
                                    text={translation({
                                        sv: "Välj en resurs",
                                        en: "Select a resource",
                                    })} // already checked with conditional above
                                    required={props.required}
                                />
                            </FlexRow>
                            <VerticalDivider XS />
                            <FlexRow
                                fillParentWidth
                                mainAxis="space-between"
                                crossAxis="center"
                                onClick={() => {
                                    show();
                                }}
                                css={{
                                    cursor: "pointer",
                                    padding: props.padding
                                        ? paddingToCssValue(props.padding)
                                        : 15,
                                    backgroundColor:
                                        theme.colors.textInput.background
                                            .asString,
                                    borderRadius: theme.radius,
                                    border: `1px solid ${theme.colors.textInput.border.asString}`,
                                }}>
                                {resource ? (
                                    <>
                                        <FlexRow>
                                            <FlexRow
                                                mainAxis="center"
                                                crossAxis="center"
                                                css={{
                                                    borderRadius: 3000,
                                                    backgroundColor:
                                                        resource._type ===
                                                        "RESOURCE_GROUP"
                                                            ? theme.colors.secondary.main.value.withAlpha(
                                                                  0.6,
                                                              ).asString
                                                            : theme.colors.primary.main.value.withAlpha(
                                                                  0.6,
                                                              ).asString,
                                                    width: 42,
                                                    height: 42,
                                                }}>
                                                <DynamicIcon
                                                    icon={
                                                        resourceTypeMeta[
                                                            resource._type ??
                                                                "RESOURCE_GENERIC"
                                                        ].iconName
                                                    }
                                                    title={
                                                        resourceTypeMeta[
                                                            resource._type ??
                                                                "RESOURCE_GENERIC"
                                                        ].title
                                                    }
                                                    size={1.3}
                                                    fixedWidth
                                                    css={{
                                                        textAlign: "center",
                                                        color: theme.colors
                                                            .white.asString,
                                                    }}
                                                />
                                            </FlexRow>
                                            <HorizontalDivider S />
                                            <TextBox
                                                text={resource.meta?.title}
                                                lineClamp={2}
                                                shrink={1}
                                                grow={1}
                                            />
                                            <HorizontalDivider />
                                        </FlexRow>
                                        <FlexRow>
                                            <Icon
                                                icon={faTrashAlt}
                                                cursor="pointer"
                                                color={
                                                    theme.colors.black.light60
                                                }
                                                css={{ padding: 10 }}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    onToggle(resource._id);
                                                }}
                                            />
                                        </FlexRow>
                                    </>
                                ) : (
                                    <>
                                        <TextBox
                                            text={translation({
                                                sv: "Välj resurs",
                                                en: "Choose resource",
                                            })}
                                            css={{ flex: 1 }}
                                            color={
                                                theme.colors.textInput
                                                    .placeholder.asString
                                            }
                                        />
                                        <HorizontalDivider />
                                        <FlexRow crossAxis="center">
                                            <DynamicIcon
                                                icon={
                                                    resourceTypeMeta
                                                        .RESOURCE_GENERIC
                                                        .iconName
                                                }
                                                fixedWidth
                                            />
                                        </FlexRow>
                                    </>
                                )}
                            </FlexRow>
                        </>
                    )}
                    <ResourcePickerModal
                        key={modalId}
                        modalId={modalId}
                        resources={resources}
                        selected={selected}
                        onToggle={onToggle}
                        title={title}
                        mode={mode}
                    />
                </>
            );
        }
        case "MULTI": {
            const mini = false; // resourceSortOrder.length > 8;
            return (
                <>
                    {props.renderMulti ? (
                        props.renderMulti(
                            resourceSortOrder
                                .map(resourceId =>
                                    resourceLookup.get(resourceId),
                                )
                                .filter(exists),
                            show,
                        )
                    ) : (
                        <>
                            <DndContext
                                id={pickerId}
                                key={pickerId}
                                onDragEnd={handleDragEnd}
                                collisionDetection={closestCenter}>
                                <FlexColumn fillParentWidth>
                                    <FlexRow
                                        fillParentWidth
                                        mainAxis="space-between">
                                        <FieldTitle
                                            required={props.required}
                                            text={
                                                props.title ??
                                                (mode === "MULTI"
                                                    ? translation({
                                                          sv: "Valda resurser",
                                                          en: "Selected resources",
                                                      })
                                                    : translation({
                                                          sv: "Vald resurs",
                                                          en: "Selected resources",
                                                      }))
                                            }
                                        />
                                        <ButtonPrimaryLight
                                            disabled={props.spin}
                                            padding={{
                                                leftRight: 10,
                                                topBottom: 5,
                                            }}
                                            onClick={show}>
                                            <TextBox
                                                size={0.9}
                                                weight="400"
                                                text={translation({
                                                    sv: "Välj resurser",
                                                    en: "Select resources",
                                                })}
                                            />
                                        </ButtonPrimaryLight>
                                    </FlexRow>
                                    <VerticalDivider />
                                    <Conditional
                                        when={props.spin}
                                        render={() => (
                                            <TextShimmer
                                                rows={2}
                                                css={{ paddingTop: 5 }}
                                            />
                                        )}
                                    />
                                    <Conditional
                                        whenNot={props.spin}
                                        render={() => (
                                            <Conditional
                                                when={
                                                    !!resourceSortOrder.length
                                                }
                                                render={() => (
                                                    <SortableContext
                                                        items={
                                                            resourceSortOrder
                                                        }
                                                        strategy={
                                                            mini
                                                                ? rectSortingStrategy
                                                                : verticalListSortingStrategy
                                                        }>
                                                        <Conditional
                                                            when={mini}
                                                            render={() => (
                                                                <CollapseBox
                                                                    collapsible
                                                                    fadeHeight={
                                                                        0.5 as Percentage
                                                                    }
                                                                    collapsedHeight={
                                                                        180
                                                                    }>
                                                                    <div
                                                                        css={{
                                                                            width: "100%",
                                                                            containerName:
                                                                                "picked",
                                                                            containerType:
                                                                                "inline-size",
                                                                        }}>
                                                                        <LayoutGrid
                                                                            ref={
                                                                                containerRef
                                                                            }
                                                                            css={{
                                                                                gridTemplateColumns:
                                                                                    "repeat(2, minmax(0, 1fr))",
                                                                                gridTemplateRows:
                                                                                    "1fr",
                                                                                gap: 5,
                                                                                "@container picked (width > 600px)":
                                                                                    {
                                                                                        gridTemplateColumns:
                                                                                            "repeat(3, minmax(0, 1fr))",
                                                                                    },
                                                                            }}>
                                                                            <ForEach
                                                                                itemOf={
                                                                                    resourceSortOrder
                                                                                }>
                                                                                {resourceId => {
                                                                                    const resource =
                                                                                        resourceLookup.get(
                                                                                            resourceId,
                                                                                        );
                                                                                    if (
                                                                                        !isResourceGlobal(
                                                                                            resource,
                                                                                        )
                                                                                    )
                                                                                        return null;
                                                                                    return (
                                                                                        <Sortable
                                                                                            key={
                                                                                                resource._id
                                                                                            }
                                                                                            id={
                                                                                                resource._id
                                                                                            }
                                                                                            style={{
                                                                                                // note: this below is to make the grid
                                                                                                // note: a responsive square grid
                                                                                                overflow:
                                                                                                    "hidden",
                                                                                                position:
                                                                                                    "relative",
                                                                                                width: "100%",
                                                                                            }}
                                                                                            withoutDragOverlay
                                                                                            noDefaultDraggableHandle>
                                                                                            {({
                                                                                                dragListeners,
                                                                                            }) => (
                                                                                                <ResourcePickedPill
                                                                                                    key={
                                                                                                        resource._id
                                                                                                    }
                                                                                                    resource={
                                                                                                        resource
                                                                                                    }
                                                                                                    toggle={
                                                                                                        onToggle
                                                                                                    }
                                                                                                    containerWidth={
                                                                                                        multiContainerSize?.width
                                                                                                    }
                                                                                                    dragListeners={
                                                                                                        mode ===
                                                                                                        "MULTI"
                                                                                                            ? dragListeners
                                                                                                            : undefined
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </Sortable>
                                                                                    );
                                                                                }}
                                                                            </ForEach>
                                                                        </LayoutGrid>
                                                                    </div>
                                                                </CollapseBox>
                                                            )}
                                                            else={() => (
                                                                <ForEach
                                                                    itemOf={
                                                                        resourceSortOrder
                                                                    }
                                                                    divider={
                                                                        <VerticalDivider />
                                                                    }>
                                                                    {resourceId => {
                                                                        const resource =
                                                                            resourceLookup.get(
                                                                                resourceId,
                                                                            );
                                                                        if (
                                                                            !resource ||
                                                                            !isResourceGlobal(
                                                                                resource,
                                                                            )
                                                                        )
                                                                            return null;
                                                                        return (
                                                                            <Sortable
                                                                                key={
                                                                                    resource._id
                                                                                }
                                                                                id={
                                                                                    resource._id
                                                                                }
                                                                                style={{
                                                                                    // note: this below is to make the grid
                                                                                    // note: a responsive square grid
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    position:
                                                                                        "relative",
                                                                                    width: "100%",
                                                                                }}
                                                                                withoutDragOverlay
                                                                                noDefaultDraggableHandle>
                                                                                {({
                                                                                    dragListeners,
                                                                                }) => (
                                                                                    <ResourcePickedRow
                                                                                        key={
                                                                                            resource._id
                                                                                        }
                                                                                        resource={
                                                                                            resource
                                                                                        }
                                                                                        toggle={
                                                                                            onToggle
                                                                                        }
                                                                                        dragListeners={
                                                                                            mode ===
                                                                                            "MULTI"
                                                                                                ? dragListeners
                                                                                                : undefined
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Sortable>
                                                                        );
                                                                    }}
                                                                </ForEach>
                                                            )}
                                                        />
                                                    </SortableContext>
                                                )}
                                                else={() => (
                                                    <TextBox
                                                        onClick={() => {
                                                            show();
                                                        }}
                                                        text={translation({
                                                            sv: "+ Lägg till resurser",
                                                            en: "+ Add resources",
                                                        })}
                                                        align="center"
                                                        size={0.875}
                                                        padding={{
                                                            all: 30,
                                                        }}
                                                        color={
                                                            theme.colors
                                                                .disabled.text
                                                        }
                                                        css={{
                                                            backgroundColor:
                                                                theme.colors
                                                                    .textInput
                                                                    .background
                                                                    .asString,
                                                            borderRadius:
                                                                theme.radius,
                                                            borderStyle:
                                                                "dashed",
                                                            borderWidth: 1,
                                                            borderColor:
                                                                theme.colors
                                                                    .textInput
                                                                    .border
                                                                    .asString,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </FlexColumn>
                            </DndContext>
                        </>
                    )}
                    <ResourcePickerModal
                        key={modalId}
                        modalId={modalId}
                        resources={resources}
                        selected={selected}
                        onToggle={onToggle}
                        title={title}
                        mode={mode}
                    />
                </>
            );
        }
        default:
            assertNever(mode);
    }
};

export { ResourcePicker };
