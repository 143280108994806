import { VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { FC } from "react";

const KronoNextSeasonSection: FC = () => {
    return (
        <SectionArea
            title={"På återseende säsongen 2023"}
            subtitle={
                "Sommaren 2022 var härlig och vi vill tacka alla som bokat och upplevt Kronocampings sommarutbud. Nedan utbud återkommer på ett nytt sätt sommaren 2023"
            }
            sectionAreaId="krono-next-session"
            background={
                {
                    // color: kronocampingColors.lightBackground,
                }
            }
            padding={{ top: 10 }}>
            <VerticalDivider M />
        </SectionArea>
    );
};

export { KronoNextSeasonSection };
