import { getBlockContentTargetUrl } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/get-block-content-target-url";
import { inactiveCssWithoutHeightSetting } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/inactive-css";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { HzScrollContainer } from "@@/shared/hz-scroll-container";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { ImageV2 } from "@@/shared/pictures/image";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    BlockContent,
    ContentAutoPageBlock,
    ProviderId,
    RemSize,
} from "@towni/common";
import { useMemo } from "react";

type Props = {
    readonly block: ContentAutoPageBlock;
    readonly content: BlockContent[];
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const ContentCircleView = (props: Props) => {
    const { block } = props;

    switch (block.layout) {
        case "hzScroll":
            return <ContentCirclesHzScroll {...props} />;
        default:
            return <ContentCirclesGridCentered {...props} />;
    }
};

const ContentCirclesGridCentered = (props: Props) => {
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const { width, block, content } = props;
    const {
        pageInnerWidth: maxWidth,
        defaultGridGap,
        // defaultTextShadow,
        providerId,
    } = usePageViewContext();

    return (
        <LayoutGrid
            css={{
                maxWidth: maxWidth * 0.85,
                width: width ?? "100%",
                gap: defaultGridGap,
                justifyContent: "center",
                alignItems: "flex-start",
                gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
                padding: isSmallScreen ? 20 : 40,
                paddingTop: 10,
                paddingBottom: 30,
            }}>
            <ForEach itemOf={content} getKey={item => item._id}>
                {item => (
                    <ContentCircleWithLabel
                        block={block}
                        content={item}
                        providerId={providerId}
                    />
                )}
            </ForEach>
        </LayoutGrid>
    );
};

const ContentCirclesHzScroll = (props: Props) => {
    const theme = useTheme();
    const { block, content } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });

    const { providerId } = usePageViewContext();
    const maxItemRemSize = ((): RemSize => {
        const mediumSize: RemSize = (isMobile ? 7 : 8) as RemSize;
        switch (block.preferredContentSize) {
            case "S":
                return (mediumSize * 0.75) as RemSize;
            case "L":
                return (mediumSize * 1.25) as RemSize;
            case "M":
            default:
                return mediumSize as RemSize;
        }
    })();
    const items = useMemo(
        () =>
            content.map(item => (
                <ContentCircleWithLabel
                    key={item._id}
                    block={block}
                    content={item}
                    providerId={providerId}
                    css={{
                        maxWidth: maxItemRemSize * 1.1 * theme.sizes.base,
                    }}
                />
            )),
        [content, block, providerId, maxItemRemSize, theme.sizes.base],
    );

    return (
        <HzScrollContainer
            gap={{
                startGap: 20,
                dividingGap: 20,
                endGap: 20,
                bottomGap: 20,
            }}
            items={items}
        />
    );
};

const ContentCircleWithLabel = (props: {
    block: ContentAutoPageBlock;
    content: BlockContent;
    providerId: ProviderId | undefined;
    className?: string;
}) => {
    const theme = useTheme();
    const { block, content: item, providerId } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });

    const circleRemSize = ((): RemSize => {
        const mediumSize: RemSize = (isMobile ? 7 : 8) as RemSize;
        switch (block.preferredContentSize) {
            case "S":
                return (mediumSize * 0.75) as RemSize;
            case "L":
                return (mediumSize * 1.25) as RemSize;
            case "M":
            default:
                return mediumSize as RemSize;
        }
    })();
    const textSize = ((): number => {
        const mediumSize = theme.sizes.base;
        switch (block.preferredContentSize) {
            case "S":
                return mediumSize * 0.925;
            case "L":
                return mediumSize * 1.25;
            case "M":
            default:
                return mediumSize;
        }
    })();
    const textColor = block.textColor ?? theme.colors.default.text;

    return (
        <Link
            to={getBlockContentTargetUrl(item, providerId)}
            title={item.title}
            disabled={item.inactive}
            css={{ cursor: "pointer" }}>
            <LayoutCell
                key={item._id}
                className={props.className}
                css={{
                    textAlign: "center",
                }}>
                <FlexColumn
                    crossAxis="center"
                    css={
                        item.inactive
                            ? inactiveCssWithoutHeightSetting
                            : undefined
                    }>
                    <ImageV2
                        imageSource={item.poster}
                        title={item.title}
                        radius={3000}
                        size={circleRemSize}
                    />
                    <VerticalDivider XS />
                    <TextBox
                        text={item.title}
                        color={textColor}
                        css={{
                            fontSize: `${textSize}px`,
                        }}
                        lineClamp={2}
                    />
                </FlexColumn>
            </LayoutCell>
        </Link>
    );
};

export { ContentCircleView };
