import { ProviderId, ProviderIdOrSlug } from "@towni/common";
import { useParams } from "react-router-dom";
import { useProvider } from "./use-provider";

/**
 * Fetched provider id or slug from a react-router Route url parameter \
 * Checks for following url params: \
 * `providerId` \
 * `providerSlug` \
 * `providerIdOrSlug`
 *
 * @returns
 */
const useProviderFromUrl = (options?: {
    /** Uses given provider id instead of trying to read it from URL */
    providerId?: ProviderId;
    throwOnMissingIdOrSlug?: boolean;
}) => {
    const urlParams = useParams<{
        providerId?: ProviderId;
        providerSlug?: string;
        providerIdOrSlug?: string;
    }>();

    const idOrSlug = (options?.providerId ??
        urlParams.providerId ??
        urlParams.providerSlug ??
        urlParams.providerIdOrSlug) as ProviderIdOrSlug | undefined;

    if (typeof idOrSlug === "undefined" && options?.throwOnMissingIdOrSlug) {
        throw new Error(
            "Missing provider id or slug; is useProviderFromUrl used within a react-router Route?",
        );
    }

    return useProvider(idOrSlug);
};

export { useProviderFromUrl };
