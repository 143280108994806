import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { MouseEventHandler } from "@@/shared/mouse-event-handler";
import { SectionAreaCircle } from "@@/shared/section-area/section-area-circle";
import { useNavigateTo } from "@@/shared/text/navigate-to";
import {
    generateId,
    MetaImage,
    Provider,
    RemSize,
    SizeName,
    StorageItemImage,
} from "@towni/common";
import * as React from "react";

type Props = {
    provider: Provider | undefined;
    hideTitle?: boolean;
    size?: SizeName;
    disableLink?: boolean;
};
const ProviderLogoLinkCircle = ({ provider, ...props }: Props) => {
    const navigateTo = useNavigateTo();
    const key = provider ? provider._id : generateId({ length: 6 });
    const providerHomePath = useHomePath(provider);
    const to = provider ? providerHomePath : "";

    return (
        <SectionAreaCircle
            key={key}
            onClick={event => {
                if (props.disableLink) return;
                navigateTo(to);
                event.stopPropagation();
            }}
            padding={{ all: 5 }}
            css={{
                /* border: "2px solid #ffffff" */
                boxShadow: "0px 0px 0px 1px #e0e0e0",
                cursor: props.disableLink ? undefined : "pointer",
                flexShrink: 1,
            }}
            background={{
                color: "#ffffff",
                imageReference: provider?.logoImageRef,
                repeat: "NO-REPEAT",
                fit: "COVER",
            }}
            title={props.hideTitle ? undefined : provider?.meta.title}
            size={props.size || "XXS"}
        />
    );
};
const LogoCircle = (props: {
    logo?: MetaImage | StorageItemImage;
    onClick?: MouseEventHandler;
    size: RemSize;
    boxShadow?: React.CSSProperties["boxShadow"];
    "data-testid"?: string;
}) => {
    return (
        <SectionAreaCircle
            onClick={props.onClick}
            padding={{ all: 5 }}
            data-testid={props["data-testid"]}
            css={{
                /* border: "2px solid #ffffff" */
                boxShadow: props.boxShadow ?? "0px 0px 0px 1px #e0e0e0",
                cursor: props.onClick ? undefined : "pointer",
                flexShrink: 1,
            }}
            background={{
                color: "#ffffff",
                imageReference: props.logo,
                repeat: "NO-REPEAT",
                fit: "COVER",
            }}
            size={props.size || "XXS"}
        />
    );
};

export { LogoCircle, ProviderLogoLinkCircle };
