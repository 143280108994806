import { z } from "zod";
import { BreakpointSize } from "./breakpoint-sizes";

const breakpointRangeZodSchema = z.object({
    min: z.number().int().min(0),
    max: z.number().int().min(0),
});
type BreakpointRange = z.infer<typeof breakpointRangeZodSchema>;

const XS: BreakpointRange = { min: 0, max: 376 };
const S: BreakpointRange = { min: 376, max: 440 };
const MS: BreakpointRange = { min: 440, max: 750 };
const M: BreakpointRange = { min: 440, max: 900 };
const L: BreakpointRange = { min: 900, max: Infinity };
const MOBILE: BreakpointRange = { min: XS.min, max: Math.min(M.min, S.max) };
const SMALL_SCREEN: BreakpointRange = { min: 0, max: 640 };
const DESKTOP: BreakpointRange = { min: M.min, max: Infinity };

const breakpointRange: Record<BreakpointSize, BreakpointRange> = {
    XS,
    S,
    MS,
    M,
    L,
    MOBILE,
    SMALL_SCREEN,
    DESKTOP,
    "📱": MOBILE,
    "🖥": DESKTOP,
};

export { breakpointRange };
export type { BreakpointRange };
