class ProductNotFoundError extends Error {
    public readonly name = "ProductNotFoundError" as const;
    constructor(
        public productId: string | undefined,
        public loadingError: unknown = null
    ) {
        super(
            `Product not found: ${productId}${
                loadingError
                    ? `; error: ${
                          (loadingError as Error)?.message ||
                          typeof loadingError === "string"
                              ? loadingError
                              : "Loading Error"
                      }`
                    : ""
            }`
        );
    }
}

export { ProductNotFoundError };
