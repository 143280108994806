import { useWizardDispatch } from "@@/pages/wizard-context";
import { BackButton } from "@@/shared/buttons/back-button";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { Spacer } from "@@/shared/spacer";
import { ModalTitle } from "@@/shared/text/modal-title";
import { useTheme } from "@emotion/react";
import { translation } from "@towni/common";
import { Cart } from "./cart";
import { useCartContext } from "./multi-carts/cart.context";
import { useVerifiedCartState } from "./use-verified-cart";

const CartPage = () => {
    const theme = useTheme();
    const wizardDispatch = useWizardDispatch();
    const { cart, setCart, clearCart } = useCartContext(context => ({
        cart: context.cart,
        setCart: context.setCart,
        clearCart: context.clearCart,
    }));
    useVerifiedCartState(cart, validatedCart => {
        if (validatedCart) setCart(validatedCart);
        else clearCart();
    });

    return (
        <FlexColumn
            fillParent
            maxWidth={500}
            background={{ color: theme.colors.default.background }}>
            <HeadTitle
                title={[
                    translation({
                        sv: "Bekräfta och betala",
                        en: "Confirm and pay",
                    }),
                ]}
            />
            <FlexRow
                fillParentWidth
                padding={{ topBottom: 20, leftRight: 20 }}
                crossAxis="flex-start"
                mainAxis="space-evenly"
                tag="cartpage">
                <BackButton
                    onClick={() => {
                        wizardDispatch.goBack();
                    }}
                />
                <FlexRow grow={1} mainAxis="center" fillParent>
                    <ModalTitle
                        text={translation({
                            sv: "Bekräfta och betala",
                            en: "Confirm and pay",
                        })}
                    />
                </FlexRow>

                <Spacer basis={0.1} shrink={0} />
            </FlexRow>

            <HorizontalLine />
            <VerticalDivider M />
            <Cart hide={wizardDispatch.goBack} />
        </FlexColumn>
    );
};

export { CartPage };
