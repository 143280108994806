import { useDiscountsForProduct } from "@@/backoffice/for-providers/products/use-discounts-for-product";
import { AvailabilityVisualization } from "@@/backoffice/shared/availability-visualization";
import { useCalculatedProviderStatus } from "@@/providers/calculate-provider-status";
import { CartProviderCheckModal } from "@@/providers/cart-provider-check-modal";
import { ProviderPauseCheckModal } from "@@/providers/provider-pause-check-modal";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Join } from "@@/shared/join";
import { Label } from "@@/shared/label";
import { OptionalAmount } from "@@/shared/options/optional-amount-group";
import { Picture } from "@@/shared/pictures/picture";
import { ImageShimmer, TextShimmer } from "@@/shared/pictures/shimmers";
import { SlideShow } from "@@/shared/pictures/slide-show";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { Paragraph, TextBox } from "@@/shared/text";
import { LinkToProviderHome } from "@@/shared/text/link-to-provider-home";
import { PriceWithDiscountsTextBox } from "@@/shared/text/price-with-discounts-text-box";
import { linkCss } from "@@/styles/themes/default.theme";
import { useTranslate } from "@@/translations/use-translate";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { css, useTheme } from "@emotion/react";
import {
    MILLISECONDS,
    OptionalGroupId,
    PickedOptional,
    Product,
    Provider,
    SECONDS,
    OptionalAmount as TOptionalAmount,
    OptionalGroup as TOptionalGroup,
    generateId,
    isAlfredItemActive,
    isUndefined,
    isVoucher,
    providerStatusTypeMap,
    sumPrices,
    translation,
    zeroPrice,
} from "@towni/common";
import * as React from "react";
import { OptionalGroup } from "../shared/options";
import { AddMessageRow } from "./add-message-row";
import { DetailsPickerHeader } from "./pick-sku-header";
import { PickSkuList } from "./pick-sku-list";
import { useProductDetailsContext } from "./product-details-context";

type Props = {
    readonly product: Product;
    readonly provider: Provider;
    readonly hideLinkToShop: boolean;
    readonly optionalGroups: Array<TOptionalGroup>;
};

const BuyableDetails = (props: Props) => {
    const { provider, product, optionalGroups } = props;
    const unitTranslate = useUnitTranslator();
    const theme = useTheme();
    const translate = useTranslate();
    const state = useProductDetailsContext();
    const [discountsForProduct, isLoadingDiscounts] =
        useDiscountsForProduct(product);
    const providerStatus = useCalculatedProviderStatus(provider);
    const pickedOptionsMap = React.useMemo(() => {
        const result = state.pickedOptionals?.reduce(
            (result, pickedOptional) => {
                const optionalGroupId = pickedOptional.optional
                    .optionalGroupId as OptionalGroupId;
                const current = result.get(optionalGroupId) ?? [];
                result.set(optionalGroupId, [...current, pickedOptional]);
                return result;
            },
            new Map<OptionalGroupId, PickedOptional[]>(),
        );
        return result ?? new Map<OptionalGroupId, PickedOptional[]>();
    }, [state.pickedOptionals]);

    const messageToProvider =
        useProductDetailsContext(context => context.messageToProvider) ?? "";
    const setMessageToProvider = useProductDetailsContext(
        context => context.setMessageToProvider,
    );

    React.useEffect(() => {
        if (isLoadingDiscounts) return;
        state.setDiscounts(discountsForProduct);
    }, [discountsForProduct, isLoadingDiscounts]);

    const disabled =
        providerStatus === providerStatusTypeMap.PAUSED ||
        (product?.status && !isAlfredItemActive(product?.status));

    const isHidden = providerStatus === providerStatusTypeMap.HIDDEN;

    const slideContents = product?.meta.images?.map(imageRef => {
        return (
            <Picture
                key={typeof imageRef === "string" ? imageRef : imageRef._id}
                imageRef={imageRef}
                width="100%"
                height="100%"
                minHeight="250px"
                imageOptions={{
                    imageWidth: theme.sizes.imageSizes.large,
                    disabled,
                    fit: true,
                }}
                fade={0.1 as SECONDS}
                loadingSpinner={
                    <CircleSpinner size={5} delayInMs={300 as MILLISECONDS} />
                }
            />
        );
    });

    React.useEffect(() => {
        const addToCartDisabled =
            disabled ||
            !state.selectedSku ||
            // where there is an option group...
            !!optionalGroups
                // with a required minimal number of options...
                .some(optionalGroupOrOptionalAmount => {
                    if (!optionalGroupOrOptionalAmount.minMax.min) return false; // undefined or 0
                    const picked = pickedOptionsMap.get(
                        optionalGroupOrOptionalAmount._id,
                    );
                    if (!picked) return true;

                    const notEnough =
                        picked.length <
                        optionalGroupOrOptionalAmount.minMax.min;
                    return notEnough;
                });

        if (addToCartDisabled !== state.addToCartDisabled) {
            state.setAddToCartDisabled(addToCartDisabled);
        }
    }, [state.selectedSku, optionalGroups, pickedOptionsMap]);

    const onChange =
        (optionalGroup: TOptionalGroup | TOptionalAmount) =>
        (pickedOptionals: PickedOptional[]) => {
            const optionalsToKeep =
                state.pickedOptionals?.filter(
                    picked =>
                        picked.optional.optionalGroupId !== optionalGroup._id,
                ) ?? [];
            const update = [...optionalsToKeep, ...pickedOptionals];
            state.setPickedOptionals(update);
        };

    const cartItem = state.getOrderItem();
    const descriptionInCurrentLanguage = translate(
        product?.meta.description,
        undefined,
        true,
    );
    const currentPrice = cartItem
        ? sumPrices(cartItem.map(p => p.price))
        : null;

    return (
        <>
            <FlexColumn
                fillParent
                mainAxis="space-between"
                crossAxis="stretch"
                css={{
                    minHeight: 500,
                    backgroundColor: theme.colors.default.asString,
                }}>
                <FlexColumn grow={1} shrink={0}>
                    {slideContents && slideContents.length > 1 && (
                        // todo: vet inte hur height 110vw funkar med slideshow
                        // todo: stod 40% tidigare
                        <SlideShow
                            styling={css({
                                width: "100%",
                                height: "110vw",
                                minHeight: 250,
                                maxHeight: 500,
                                flexShrink: 0,
                            })}
                            slideContents={slideContents}
                        />
                    )}
                    {slideContents && slideContents.length === 1 && (
                        <div
                            css={{
                                width: "100%",
                                height: "110vw",
                                minHeight: 250,
                                maxHeight: 500,
                                flexShrink: 0,
                            }}>
                            {slideContents[0]}
                        </div>
                    )}
                    {(isUndefined(slideContents) && (
                        <ImageShimmer height="110vw" maxHeight="500px" />
                    )) ||
                        null}
                    {slideContents && slideContents.length <= 0 && (
                        <VerticalDivider size={3.5} />
                    )}
                    <VerticalDivider M />
                    {(isUndefined(product) && (
                        <TextShimmer rowHeight="22px" rows={1} />
                    )) ||
                        null}
                    <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
                        {product.meta.kicker && isVoucher(product) ? (
                            <>
                                <VerticalDivider M />
                                <TextBox
                                    case={"UPPERCASE"}
                                    color={theme.colors.primary}
                                    text={product.meta.kicker}
                                    size={"XXS"}
                                    weight={"900"}
                                />
                            </>
                        ) : null}
                        <FlexRow mainAxis="space-between">
                            <TextBox
                                size={2}
                                shrink={1}
                                weight="900"
                                text={product?.meta.title}
                                userSelect="text"
                            />
                            {disabled ? (
                                <Label
                                    text={translation({
                                        sv: "Ej tillgänglig",
                                        en: "Not available",
                                    })}
                                    colorTheme="YELLOW"
                                    size="L"
                                />
                            ) : isHidden ? (
                                <Label
                                    text={translation({
                                        sv: "Gömd",
                                        en: "Hidden",
                                    })}
                                    colorTheme="RED"
                                    size="L"
                                />
                            ) : null}
                        </FlexRow>
                    </FlexColumn>
                    <VerticalDivider M />
                    <Conditional
                        when={!product}
                        render={() => <TextShimmer rows={2} />}
                    />
                    <Conditional
                        when={
                            !!descriptionInCurrentLanguage &&
                            isNaN(Number(descriptionInCurrentLanguage.trim()))
                        }
                        render={() => (
                            <>
                                <TextBox
                                    text={product.meta.description}
                                    color={theme.colors.white.light50}
                                    userSelect="text"
                                    padding={{ leftRight: 20 }}
                                    renderMarkdown
                                    styling={css`
                                        h1,
                                        h2,
                                        h3,
                                        h4 {
                                            color: ${theme.colors.white.light20
                                                .asString};
                                        }
                                    `}
                                />
                                <VerticalDivider L />
                            </>
                        )}
                        else={() => (
                            <Conditional
                                when={!!product?.meta.subtitle}
                                render={() => (
                                    <>
                                        <TextBox
                                            padding={{ leftRight: 20 }}
                                            text={product?.meta.subtitle}
                                            color={theme.colors.white.light50}
                                            userSelect="text"
                                        />
                                        <VerticalDivider L />
                                    </>
                                )}
                            />
                        )}
                    />
                    {product?.availability ? (
                        <FlexColumn padding={{ leftRight: 20 }}>
                            <TextBox
                                text={translation({
                                    sv: "Tillgänglighet",
                                    en: "Availability",
                                })}
                                color={theme.colors.white.light60}
                                userSelect="text"
                            />
                            <VerticalDivider />
                            <TextBox
                                text={translation({
                                    sv: "Produkten är tillgänglig för leverans följande dagar/tider:",
                                    en: "The product is available for delivery on the following days/times:",
                                })}
                                color={theme.colors.white.light70}
                                userSelect="text"
                                size="S"
                            />
                            <VerticalDivider />
                            <AvailabilityVisualization
                                availability={product.availability}
                            />
                            <VerticalDivider L />
                        </FlexColumn>
                    ) : null}
                    <Conditional
                        when={!!state.selectedSku?.articleNumber?.trim()}
                        render={() => (
                            <>
                                <FlexRow padding={{ leftRight: 20 }}>
                                    <TextBox
                                        text={translation({
                                            sv: "Artikelnr: ",
                                            en: "Article number: ",
                                        })}
                                        color={theme.colors.white.light70}
                                        userSelect="text"
                                        size="XS"
                                    />
                                    <HorizontalDivider XS />
                                    <TextBox
                                        text={product.skus[0].articleNumber}
                                        color={theme.colors.white.light70}
                                        userSelect="text"
                                        size="XS"
                                    />
                                </FlexRow>
                                <VerticalDivider XXS />
                            </>
                        )}
                    />
                    <Conditional
                        when={
                            state.selectedSku?.acquire?.amount?.unit?.type !==
                                "pcs" ||
                            state.selectedSku?.acquire?.amount?.value !== 1 ||
                            (typeof state.selectedSku?.acquire?.amount
                                ?.quantity !== "undefined" &&
                                state.selectedSku?.acquire?.amount?.quantity >
                                    1)
                        }
                        render={() => {
                            const amount = state.selectedSku?.acquire?.amount;
                            const unit = amount?.unit;

                            if (!amount) return null;
                            if (!unit) return null;

                            return (
                                <>
                                    <FlexRow padding={{ leftRight: 20 }}>
                                        <TextBox
                                            text={translation({
                                                sv: "Per köp: ",
                                                en: "Per purchase: ",
                                            })}
                                            color={theme.colors.white.light70}
                                            userSelect="text"
                                            size="XS"
                                        />
                                        <HorizontalDivider XS />
                                        <Paragraph
                                            css={{
                                                color: theme.colors.white
                                                    .light70.asString,
                                                userSelect: "text",
                                                fontSize: "0.75rem",
                                            }}
                                            content={[
                                                ...(amount.quantity
                                                    ? [`${amount.quantity} x `]
                                                    : []),
                                                ...(amount.value &&
                                                (typeof amount.quantity !==
                                                    "undefined" ||
                                                    amount.value > 1)
                                                    ? [`${amount.value} `]
                                                    : []),
                                                ...(unit.name
                                                    ? [
                                                          unitTranslate(
                                                              amount.value,
                                                              unit,
                                                              "short",
                                                          ),
                                                      ]
                                                    : []),
                                            ]}
                                        />
                                    </FlexRow>
                                    <VerticalDivider XXS />
                                </>
                            );
                        }}
                    />
                    <Conditional
                        when={provider && !props.hideLinkToShop}
                        render={() => (
                            <>
                                <VerticalDivider M />
                                <FlexRow mainAxis="center">
                                    <LinkToProviderHome
                                        provider={provider}
                                        data-testid="link-to-provider-home">
                                        <TextBox
                                            text={translation({
                                                sv: `Se fler produkter från ${provider.meta.title}`,
                                                en: `See more products from ${provider.meta.title}`,
                                            })}
                                            size="XXS"
                                            weight="400"
                                            case="UPPERCASE"
                                            styling={linkCss(
                                                theme.colors.white.light70,
                                            )}
                                        />
                                    </LinkToProviderHome>
                                </FlexRow>
                                <VerticalDivider M />
                            </>
                        )}
                    />
                    <Conditional
                        when={!disabled && !!product}
                        render={() => (
                            <>
                                <HorizontalSectionDivider />
                                {((product && product.skus.length > 1) ||
                                    !state.selectedSku) && (
                                    <React.Fragment>
                                        <VerticalDivider L />
                                        <DetailsPickerHeader
                                            title={translation({
                                                sv: "Välj variant",
                                                en: "Pick variant",
                                            })}
                                            minMax={{ min: 1, max: 1 }}
                                            padding={{ leftRight: 20 }}
                                            hideDescription={
                                                product?.skus.length === 1
                                            }
                                        />
                                        <VerticalDivider L />
                                        <PickSkuList
                                            skus={product?.skus || []}
                                            productTitle={
                                                product?.meta.title || null
                                            }
                                            selectedId={state.selectedSku?._id}
                                            select={state.selectSku}
                                        />
                                        <HorizontalSectionDivider />
                                    </React.Fragment>
                                )}
                                <Conditional when={optionalGroups.length > 0}>
                                    <Join
                                        end
                                        divider={
                                            <HorizontalSectionDivider
                                                key={generateId({ length: 4 })}
                                            />
                                        }>
                                        {Array.from(
                                            optionalGroups?.values() || [],
                                        )
                                            .reduce(
                                                (agg, next) => {
                                                    // Sort required first
                                                    next.minMax.min ?? 0 > 0
                                                        ? agg.unshift(next)
                                                        : agg.push(next);
                                                    return agg;
                                                },
                                                [] as (
                                                    | TOptionalGroup
                                                    | TOptionalAmount
                                                )[],
                                            )
                                            .map(optionalGroup => {
                                                switch (optionalGroup._type) {
                                                    case "OPTIONAL_AMOUNT": {
                                                        return (
                                                            <OptionalAmount
                                                                optionalGroup={
                                                                    optionalGroup
                                                                }
                                                                onChange={onChange(
                                                                    optionalGroup,
                                                                )}
                                                                padding={{
                                                                    leftRight: 20,
                                                                    top: 20,
                                                                }}
                                                                optionPadding={{
                                                                    leftRight: 20,
                                                                    topBottom: 15,
                                                                }}
                                                            />
                                                        );
                                                    }
                                                    case "OPTIONAL_GROUP":
                                                    default:
                                                        return (
                                                            <OptionalGroup
                                                                key={
                                                                    optionalGroup._id
                                                                }
                                                                padding={{
                                                                    leftRight: 20,
                                                                    top: 20,
                                                                }}
                                                                optionPadding={{
                                                                    leftRight: 20,
                                                                    topBottom: 15,
                                                                }}
                                                                initialPickedOptions={state.pickedOptionals
                                                                    ?.filter(
                                                                        item =>
                                                                            item
                                                                                .optional
                                                                                .optionalGroupId ===
                                                                            optionalGroup._id,
                                                                    )
                                                                    .map(
                                                                        item =>
                                                                            item
                                                                                .optional
                                                                                ._id,
                                                                    )}
                                                                optionalGroup={
                                                                    optionalGroup
                                                                }
                                                                onChange={onChange(
                                                                    optionalGroup,
                                                                )}
                                                            />
                                                        );
                                                }
                                            })}
                                    </Join>
                                </Conditional>
                                <AddMessageRow
                                    onSetMessage={setMessageToProvider}
                                    initialMessage={messageToProvider}
                                />
                                <HorizontalLine />
                                {provider?.meta.tags.categories.includes(
                                    "edible",
                                ) && (
                                    <>
                                        <TextBox
                                            padding={{ all: 20 }}
                                            text={translation({
                                                sv: "För frågor kring matallergier eller speciella kostbehov, vänligen kontakta restaurangen innan du beställer.",
                                                en: "For questions about allergies or any special food requirements, please contact the restaurant before ordering.",
                                                de: "Für Fragen zu Lebensmittelallergien oder speziellen Ernährungsbedürfnissen wenden Sie sich bitte vor der Bestellung an das Restaurant.",
                                            })}
                                            size="XS"
                                            color={
                                                theme.colors.textInput
                                                    .placeholder
                                            }
                                        />
                                        <HorizontalLine />
                                    </>
                                )}
                                <FlexRow
                                    padding={{ all: 20 }}
                                    mainAxis="space-between">
                                    <TextBox
                                        text={translation({
                                            sv: "Totalt",
                                            en: "Total",
                                            de: "Gesamt",
                                        })}
                                    />
                                    <PriceWithDiscountsTextBox
                                        price={currentPrice || zeroPrice}
                                        discounts={discountsForProduct}
                                        fractions="NONE"
                                        dataTestId="tbx-total-price"
                                        reversed
                                    />
                                </FlexRow>
                                <VerticalDivider XXL />
                                <VerticalDivider XXL />
                            </>
                        )}
                    />
                </FlexColumn>
                <VerticalDivider M />
                <VerticalDivider ML />
                <VerticalDivider XXL />
            </FlexColumn>
            <CartProviderCheckModal providerId={product?.providerId} />
            <ProviderPauseCheckModal providerId={product?.providerId} />
        </>
    );
};

export { BuyableDetails };
