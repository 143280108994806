import { apiFetchClient } from "@@/shared/fetch-client";
import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import { QueryFunction, UseQueryResult } from "@tanstack/react-query";
import { GetResponse, Product, ProviderId, emptyArrayOf } from "@towni/common";
import { productMainQueryKey } from "../state/queries/main-query-key";

const fetchProducts =
    (providerId?: ProviderId): QueryFunction<Product[]> =>
    async ({ signal }) => {
        if (!providerId) return emptyArrayOf<Product>();
        const data = await apiFetchClient.get<GetResponse<Product>>({
            route: `/products/${encodeURIComponent(
                providerId ?? ""
            )}/available-for-provider?type=BOOKABLE`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };

const useAvailableProducts = (
    providerId?: ProviderId
): readonly [Product[], UseQueryResult<Product[]>] => {
    return useApiResources<Product>(
        createQueryKey(productMainQueryKey, [providerId ?? "", "available"]),
        fetchProducts(providerId),
        {
            enabled: !!providerId,
        }
    );
};

export { useAvailableProducts };
