import { FormDebug } from "@@/shared/form/form-debug";
import { createFormAccommodationSubTypesComponent } from "@@/shared/form/form2-accommodation-sub-types/form2-accommodation-sub-types";
import { createFormAccommodationTypePickerComponent } from "@@/shared/form/form2-accommodation-type-picker";
import { createFormAccommodationTypePickersComponent } from "@@/shared/form/form2-accommodation-type-pickers";
import { createFormAmenityPickerComponent } from "@@/shared/form/form2-amenity-picker";
import { createFormCheckboxComponent } from "@@/shared/form/form2-checkbox";
import { Form2ColorPicker } from "@@/shared/form/form2-color-picker";
import { Form2CsvNumberInput } from "@@/shared/form/form2-csv-number-input";
import { createFormDatePickerComponent } from "@@/shared/form/form2-date-picker";
import { Form2DateRangePicker } from "@@/shared/form/form2-date-range-picker";
import { Form2DateTimePicker } from "@@/shared/form/form2-date-time-picker";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { Form2IconNameInput } from "@@/shared/form/form2-icon-name-input";
import { Form2ImageUploadMultiple } from "@@/shared/form/form2-image-upload-multiple";
import { Form2ImageUploadSingle } from "@@/shared/form/form2-image-upload-single";
import { Form2NumberInput } from "@@/shared/form/form2-number-input";
import { Form2PhoneNumberInput } from "@@/shared/form/form2-phone-number-input";
import { Form2PriceInput } from "@@/shared/form/form2-price-input";
import { Form2ProductPickerMulti } from "@@/shared/form/form2-product-picker-multi";
import { Form2ProductPickerSingle } from "@@/shared/form/form2-product-picker-single";
import { createFormRangeDualSliderComponent } from "@@/shared/form/form2-range-dual-slider-input";
import { createFormRangeSliderComponent } from "@@/shared/form/form2-range-slider-input";
import { Form2RangeTextInput } from "@@/shared/form/form2-range-text-input";
import { Form2ResourceMapMapper } from "@@/shared/form/form2-resource-map-mapper";
import { Form2ResourceMapPicker } from "@@/shared/form/form2-resource-map-picker";
import { Form2ResourcePickerMultiple } from "@@/shared/form/form2-resource-picker-multi";
import { Form2ResourcePickerSingle } from "@@/shared/form/form2-resource-picker-single";
import { createFormSelectComponent } from "@@/shared/form/form2-select";
import { createFormTextInputComponent } from "@@/shared/form/form2-text-input";
import { Form2TimePicker } from "@@/shared/form/form2-time-picker";
import { createFormTranslatableInputComponent } from "@@/shared/form/form2-translatable-input";
import { Form2WeekdayPicker } from "@@/shared/form/form2-weekday-picker";
import { useMemo } from "react";

const useFormComponents = <State extends Record<string, unknown>>() => {
    return useMemo(() => {
        return {
            AccommodationSubTypes:
                createFormAccommodationSubTypesComponent<State>(),
            AccommodationTypePicker:
                createFormAccommodationTypePickerComponent<State>(),
            AccommodationTypePickers:
                createFormAccommodationTypePickersComponent<State>(),
            AmenityPicker: createFormAmenityPickerComponent<State>(),
            Checkbox: createFormCheckboxComponent<State>(),
            ColorPicker: Form2ColorPicker<State>,
            DatePicker: createFormDatePickerComponent<State>(),
            DateRangePicker: Form2DateRangePicker<State>,
            DateTimePicker: Form2DateTimePicker<State>,
            FormDebug: FormDebug<State>,
            FormErrorMessages,
            TimePicker: Form2TimePicker<State>,
            IconNameInput: Form2IconNameInput<State>,
            ImageUploadMultiple: Form2ImageUploadMultiple<State>,
            ImageUploadSingle: Form2ImageUploadSingle<State>,
            NumberInput: Form2NumberInput<State>,
            NumberCsvInput: Form2CsvNumberInput<State>,
            PhoneNumberInput: Form2PhoneNumberInput<State>,
            PriceInput: Form2PriceInput<State>,
            ProductPickerSingle: Form2ProductPickerSingle<State>,
            ProductPickerMulti: Form2ProductPickerMulti<State>,
            RangeDualSliderInput: createFormRangeDualSliderComponent<State>(),
            RangeSliderInput: createFormRangeSliderComponent<State>(),
            RangeTextInput: Form2RangeTextInput<State>,
            ResourceMapMapper: Form2ResourceMapMapper<State>,
            ResourceMapPicker: Form2ResourceMapPicker<State>,
            ResourcePickerMultiple: Form2ResourcePickerMultiple<State>,
            ResourcePickerSingle: Form2ResourcePickerSingle<State>,
            Select: createFormSelectComponent<State>(),
            TextInput: createFormTextInputComponent<State>(),
            TranslatableInput: createFormTranslatableInputComponent<State>(),
            WeekdayPicker: Form2WeekdayPicker<State>,
        };
    }, []);
};

export { useFormComponents };
