import { FontWeight } from "@@/styles/theme";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    ColorItem,
    Padding,
    RemSize,
    SizeName,
    Translatable,
} from "@towni/common";
import { Link } from "react-router-dom";
import { BreadCrumbPathProvider } from "../bread-crumb-id-context";
import { VerticalDivider } from "../dividers/vertical-divider";
import { FlexAlignment, FlexColumn } from "../flex-containers";
import { SectionAreaSubtitle } from "./section-area-subtitle";
import { SectionAreaTitle } from "./section-area-title";

type Props = {
    title?: Translatable;
    titleTarget?: string;
    subtitle?: Translatable;
    subtitleSize?: SizeName | RemSize;
    subtitleWeight?: FontWeight;
    subTitleLineHeight?: RemSize;
    background?: BackgroundStyle;
    titleColor?: ColorItem;
    subtitleColor?: ColorItem;
    padding?: Padding;
    titlePadding?: Padding;
    titleSize?: SizeName | RemSize;
    titleWeight?: FontWeight;
    sectionAreaId: string;
    className?: string;
    mainAxis?: FlexAlignment;
    crossAxis?: FlexAlignment;
    children?: React.ReactNode;
    userSelect?: React.CSSProperties["userSelect"];
};

const SectionArea = (props: Props) => {
    const theme = useTheme();
    return (
        <BreadCrumbPathProvider node={props.sectionAreaId}>
            <FlexColumn
                fillParentWidth
                background={props.background}
                shrink={0}
                className={props.className}
                crossAxis="stretch"
                mainAxis={props.mainAxis ?? "flex-start"}
                tag="SECTION_AREA"
                padding={props.padding}>
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    mainAxis={props.mainAxis ?? "flex-start"}
                    crossAxis={props.crossAxis ?? "stretch"}
                    padding={
                        !props.title && !props.subtitle
                            ? { top: 20 }
                            : props.titlePadding ?? {
                                  leftRight: 20,
                                  topBottom: 20,
                              }
                    }>
                    {props.title ? (
                        props.titleTarget ? (
                            <Link to={props.titleTarget}>
                                <SectionAreaTitle
                                    title={props.title}
                                    color={
                                        props.titleColor ?? theme.colors.black
                                    }
                                    size={props.titleSize}
                                    userSelect={props.userSelect}
                                    weight={props.titleWeight}
                                />
                            </Link>
                        ) : (
                            <SectionAreaTitle
                                title={props.title}
                                color={props.titleColor}
                                size={props.titleSize}
                                userSelect={props.userSelect}
                                weight={props.titleWeight}
                            />
                        )
                    ) : null}
                    {props.subtitle && (
                        <>
                            <VerticalDivider XS />
                            <SectionAreaSubtitle
                                subtitle={props.subtitle}
                                size={props.subtitleSize}
                                weight={props.subtitleWeight}
                                color={props.subtitleColor}
                                userSelect={props.userSelect}
                                lineHeight={props.subTitleLineHeight}
                            />
                        </>
                    )}
                </FlexColumn>
                <FlexColumn fillParentWidth shrink={0}>
                    {props.children}
                </FlexColumn>
            </FlexColumn>
        </BreadCrumbPathProvider>
    );
};

export { SectionArea };
