import { Badge } from "@@/shared/badge";
import { useTheme } from "@emotion/react";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";

type Props = {
    verified: boolean;
};

const _Unverified = () => {
    return <Badge color="#ccc" icon={faBadgeCheck} text="ej verifierad" />;
};
const _Verified = () => {
    const theme = useTheme();
    return (
        <Badge
            color={theme.colors.primary}
            icon={faBadgeCheck}
            text="verifierad"
        />
    );
};

const VerificationBadge = (props: Props) => {
    switch (props.verified) {
        case true:
            return _Verified();
        case false:
            return _Unverified();
    }
};

export { VerificationBadge };
