import { pageBlockViewContextFactory } from "@@/backoffice/for-providers/pages/page-block-view-context-factory";
import type { SingleContentPageBlock } from "@towni/common";

const [
    SingleContentBlockViewContextProvider,
    useSingleContentBlockViewContext,
] = pageBlockViewContextFactory<SingleContentPageBlock>();
export {
    SingleContentBlockViewContextProvider,
    useSingleContentBlockViewContext,
};
