import { SectionArea } from "@@/shared/section-area";
import { OneSquareLinkSection } from "@@/store-fronts/shared/one-square-link-section";
import { translation } from "@towni/common";
import { FC } from "react";

const SmartResandeElectricalCar: FC = () => {
    return (
        <SectionArea
            title={"Elektrisk!"}
            subtitle={
                "Ta dig ut i sommaren med din egna elbil. Hyr för 599 kr från ett antal platser. Tyst, hållbart och resande!"
            }
            sectionAreaId="smart-electrical-car"
            //crossAxis="center"
            padding={{ top: 0 }}>
            <OneSquareLinkSection
                imageUrl="https://we-are-alfred.imgix.net/destinations/destination-smart_elbil.png?dpr=2&q=60&fm=jpg&w=500&fit=crop&crop=edges"
                link={`/destinations/smart-resande-elbil`}
                title={translation({ sv: "Hyr elbil här" })}
                subTitle={translation({
                    sv: "Här hittar du våra elbilar och vart du kan börja och fortsätta dina resor",
                })}
                actionText={translation({
                    sv: "Elektriskt!",
                })}
                contentTop={false}
            />
        </SectionArea>
    );
};

export { SmartResandeElectricalCar };
