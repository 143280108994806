import { useCheckoutContext } from "@@/carts/checkout.context";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { swishSettingsAtom } from "@@/settings/swish-settings/swish-settings.atom";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import {
    Me,
    SwishNumber,
    isApiError,
    remSize,
    support,
    translation,
} from "@towni/common";
import { useAtomValue } from "jotai";
import * as React from "react";
import { usePaymentDetailsContext } from "./payment-details.context";
import { PaymentTypeCardSelection } from "./payment-type-card-selection";
import { StripePaymentRequestButton } from "./stripe/stripe-payment-request-button";
import { SwishHzLogo } from "./swish/swish-hz-logo";
import { useSwishPayment } from "./swish/swish.context";

const PaymentTypeSelection = (props: { me: Me }) => {
    const theme = useTheme();
    const [hasSelected, setHasSelected] = React.useState(false);
    const { onPremise, totalCheckoutPrice } = useCheckoutContext(context => ({
        onPremise: context.onPremise,
        totalCheckoutPrice: context.totalCheckoutPrice,
    }));
    const { acceptSwish, paymentDetails, selectPaymentType } =
        usePaymentDetailsContext(context => ({
            paymentDetails: context.paymentDetails,
            acceptSwish: context.acceptSwish,

            selectPaymentType: context.selectPaymentType,
        }));
    const toast = useToast();

    const [_, swishActions] = useSwishPayment();
    const swishSettings = useAtomValue(swishSettingsAtom);
    const swishEnabled =
        typeof swishSettings?.enabled === "undefined" || swishSettings.enabled;
    // TODO! MAKE SURE PAYM REQ BUTTON NEVER EXECUTED ON ZEROPRICE
    const { goForward } = useWizardDispatch();
    const isMounted = useIsMountedRef();
    useMountEffect(() => {
        // Clear selection on component load
        if (
            !onPremise &&
            !(totalCheckoutPrice.amountIncludingVat === 0) //Smellycode
        ) {
            selectPaymentType(undefined);
        }
    });

    // If not payment details, throw
    if (!paymentDetails)
        throw new Error(
            "Betalningsdetaljer måste vara satta innan betalningsmetod väljs",
        );

    // Show payment type selection
    return (
        <>
            <FlexColumn
                grow={1}
                shrink={1}
                fillParentWidth
                mainAxis="flex-end"
                padding={{ leftRight: 20, bottom: 20 }}>
                {/* // Browser based payment (e.g. apple, google or microsoft pay) */}
                <StripePaymentRequestButton
                    onClick={() => {
                        setHasSelected(true);
                        selectPaymentType({
                            name: "STRIPE",
                            type: "stripe_payment_request_button",
                        });
                    }}
                    onCancel={() => {
                        selectPaymentType(undefined);
                        setHasSelected(false);
                    }}
                />

                {acceptSwish ? (
                    <>
                        <FlexRow
                            css={{
                                flexGrow: 0,
                                flexShrink: 0,
                                maxHeight: 55,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "hsl(194, 78%, 84%)",
                                borderRadius: 5,
                                padding: 13,
                                paddingLeft: 15,
                                opacity: swishEnabled ? 1 : 0.3,
                                cursor: swishEnabled ? "pointer" : "default",
                            }}
                            onClick={async () => {
                                if (!swishEnabled) return;
                                setHasSelected(true);
                                //     swishActions.regeneratePaymentUUID();
                                selectPaymentType({
                                    name: "SWISH",
                                    type: "swish_on_device",
                                });
                                try {
                                    await swishActions.initiateSwishPayment(
                                        "swish_on_device",
                                        undefined,
                                    );
                                    goForward();
                                } catch (error) {
                                    selectPaymentType(undefined);
                                    setTimeout(() => {
                                        if (!isMounted.current) return;
                                        setHasSelected(false);
                                    }, 2000);
                                    if (
                                        isApiError(error) ||
                                        error instanceof Error
                                    ) {
                                        const message = isApiError(error)
                                            ? error.reason
                                            : translation({
                                                  sv: `Försök igen om en liten stund. Om felet kvarstår kontakta ${support.towni.supportEmail}`,
                                                  en: `Try again in a little while. If issue remains, please contact ${support.towni.supportEmail}`,
                                              });
                                        toast.danger({
                                            title: translation({
                                                sv: "Misslyckades att starta swishbetalning",
                                                en: "Failed to initiate swish payment",
                                            }),
                                            message: message,
                                            sticky: true,
                                        });

                                        return;
                                    }

                                    Sentry.captureException(error);
                                    throw error;
                                }
                            }}
                            mainAxis="center"
                            crossAxis="center"
                            fillParentWidth
                            background={{ color: "hsl(195, 100%, 95%)" }}>
                            <SwishHzLogo style={{ width: 85 }} />
                        </FlexRow>
                        <VerticalDivider />
                        <FlexRow
                            css={{
                                maxHeight: 55,
                                border: "1px solid hsl(0, 0%, 80%)",
                                padding: 13,
                                paddingLeft: 15,
                                flexGrow: 0,
                                flexShrink: 0,
                                borderRadius: 5,
                                opacity: swishEnabled ? 1 : 0.3,
                                cursor: swishEnabled ? "pointer" : "default",
                            }}
                            onClick={async () => {
                                if (!swishEnabled) return;
                                setHasSelected(true);
                                //       swishActions.regeneratePaymentUUID();
                                selectPaymentType({
                                    name: "SWISH",
                                    type: "swish_on_another_device",
                                });
                                try {
                                    await swishActions.initiateSwishPayment(
                                        "swish_on_another_device",
                                        // TODO! Make sure phone number exists
                                        // TODO! allow to input another phone number
                                        props.me.phone
                                            .phoneNumber as SwishNumber,
                                    );
                                    goForward();
                                } catch (error) {
                                    selectPaymentType(undefined);
                                    setTimeout(() => {
                                        if (!isMounted.current) return;
                                        setHasSelected(false);
                                    }, 2000);
                                    if (
                                        isApiError(error) ||
                                        error instanceof Error
                                    ) {
                                        const message = isApiError(error)
                                            ? error.reason
                                            : translation({
                                                  sv: `Försök igen om en liten stund. Om felet kvarstår kontakta ${support.towni.supportEmail}`,
                                                  en: `Try again in a little while. If issue remains, please contact ${support.towni.supportEmail}`,
                                              });

                                        toast.danger({
                                            title: translation({
                                                sv: "Misslyckades att starta swishbetalning",
                                                en: "Failed to initiate swish payment",
                                            }),
                                            message: message,
                                            sticky: true,
                                        });

                                        return;
                                    }

                                    Sentry.captureException(error);
                                    throw error;
                                }
                            }}
                            fillParentWidth
                            mainAxis="center"
                            crossAxis="center"
                            background={{ color: theme.colors.white }}>
                            <SwishHzLogo style={{ width: 85 }} />
                            <HorizontalDivider size={remSize(0.7)} />
                            <TextBox
                                text="på annan enhet"
                                padding={{ bottom: 3 }}
                                size={1.125}
                                color={theme.colors.black.light10}
                            />
                        </FlexRow>
                        <Conditional
                            when={
                                !swishEnabled &&
                                !!swishSettings.disabledMessage?.trim()
                            }>
                            <FlexRow
                                fillParentWidth
                                css={{ padding: 15, fontStyle: "italic" }}
                                mainAxis="center">
                                <TextBox
                                    text={swishSettings?.disabledMessage}
                                />
                            </FlexRow>
                            <VerticalDivider S />
                        </Conditional>
                    </>
                ) : null}

                <VerticalDivider />
                <PaymentTypeCardSelection
                    goForward={goForward}
                    maxHeight={55}
                    onSelection={() => setHasSelected(true)}
                />
            </FlexColumn>
            {hasSelected ? (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: "white",
                        opacity: 0.6,
                    }}>
                    <FlexColumn fillParent crossAxis="center" mainAxis="center">
                        <CircleSpinner size="3XL" />
                    </FlexColumn>
                </div>
            ) : null}
        </>
    );
};

export { PaymentTypeSelection };
