import { useMe } from "@@/authentication/me/me-context";
import { queryClient } from "@@/shared/queries/query-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation } from "@tanstack/react-query";
import { isApiError, translation } from "@towni/common";
import { useAddressService } from "./use-address-service";

const useEditUserAddress = (params?: { onSuccess?: () => void }) => {
    const [me] = useMe();
    const toast = useToast();
    const { editAddressCommand } = useAddressService();

    return useMutation({
        mutationFn: editAddressCommand,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [me?._id, "addresses"],
            });
            params?.onSuccess?.();
        },
        onError: (error, _command) => {
            if (isApiError(error)) toast.fromError(error);
            else
                toast.danger({
                    message: translation({
                        sv: "Något fick fel. Försök igen om en liten stund.",
                        en: "Something went wrong. Try again in a little while.",
                    }),
                });
            throw error;
        },
    });
};

export { useEditUserAddress };
