import { AppTheme } from "@@/styles/theme";
import { usePriceFormatter } from "@@/translations/use-price-formatter";
import { Interpolation } from "@emotion/react";
import { Price } from "@towni/common";
import { Except } from "type-fest";

type ParagraphPrice = {
    _type: "PARAGRAPH_PRICE";
    price: Price;
    css?: Interpolation<AppTheme>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isParagraphPrice = (item: any): item is ParagraphPrice => {
    return item?._type === "PARAGRAPH_PRICE";
};

const paragraphPriceFactory = (
    params: Except<ParagraphPrice, "_type">,
): ParagraphPrice => {
    return {
        _type: "PARAGRAPH_PRICE",
        ...params,
    };
};

const ParagraphPrice = (props: {
    price: ParagraphPrice;
    className?: string;
}) => {
    const priceFormat = usePriceFormatter();

    return (
        <span
            className={props.className}
            css={[
                { display: "inline-block", whiteSpace: "nowrap" },
                props.price.css,
            ]}>
            {priceFormat(props.price.price)}
        </span>
    );
};

export { ParagraphPrice, isParagraphPrice, paragraphPriceFactory };
