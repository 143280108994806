import { FontWeight } from "@@/styles/theme";
import { ColorItem, RemSize, SizeName, Translatable } from "@towni/common";
import { FC } from "react";
import { TextBox } from "../text/text-box";

const SectionAreaTitle: FC<{
    title: Translatable;
    color?: ColorItem;
    size?: SizeName | RemSize;
    weight?: FontWeight;
    userSelect?: React.CSSProperties["userSelect"];
}> = props => {
    return (
        <TextBox
            renderMarkdown
            text={props.title}
            color={props.color}
            size={props.size ?? (1.5 as RemSize)}
            weight={props.weight ?? "700"}
            userSelect={props.userSelect}
        />
    );
};

export { SectionAreaTitle };
