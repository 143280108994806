import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { css, Interpolation, SerializedStyles } from "@emotion/react";
import {
    BackgroundStyle,
    colorAsString,
    ColorItem,
    Padding,
    Translatable,
} from "@towni/common";
import { motion } from "framer-motion";
import * as React from "react";
import { ReactNode } from "react";
import { paddingToCssValue } from "./padding";
import { useBackgroundStyleToCss } from "./stylable/background-style-css";

type FlexAlignment =
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-evenly"
    | "space-around"
    | "stretch";

type Props = {
    /**
     * only to mark for part of name of generated css class in development
     * @type {("div" | "ol" | "ul" | "li" | "section" | "article" | "footer" | "header" | "main" | "aside")}
     */
    readonly tag?: string;
    readonly id?: string;
    readonly title?: Translatable;
    readonly className?: string;
    readonly background?: BackgroundStyle | null;
    readonly color?: ColorItem;
    readonly crossAxis?: FlexAlignment;
    readonly mainAxis?: FlexAlignment;
    readonly children?: React.ReactNode;
    /** @deprecated Use css within css prop instead */
    readonly styling?: SerializedStyles;
    readonly wrap?: "nowrap" | "wrap" | "wrap-reverse";
    readonly wrapMargin?: number;
    /** @deprecated Use css within css prop instead */
    readonly grow?: React.CSSProperties["flexGrow"];
    /** @deprecated Use css within css prop instead */
    readonly shrink?: React.CSSProperties["flexShrink"];
    /** @deprecated Use css within css prop instead */
    readonly basis?: number | string;
    /** @deprecated Use css within css prop instead */
    readonly radius?: number;
    readonly width?: number | string;
    readonly height?: number | string;
    readonly tabIndex?: HTMLElement["tabIndex"];
    readonly minWidth?: React.CSSProperties["minWidth"];
    readonly minHeight?: React.CSSProperties["minHeight"];
    readonly maxWidth?: React.CSSProperties["maxWidth"];
    readonly maxHeight?: React.CSSProperties["maxHeight"];
    readonly padding?: Padding;
    readonly style?: React.CSSProperties;
    readonly animated?: boolean;
    readonly reversed?: boolean;
    readonly overflow?: "hidden" | "auto" | "scroll" | "unset";
    readonly overflowX?: "hidden" | "auto" | "scroll" | "unset";
    readonly overflowY?: "hidden" | "auto" | "scroll" | "unset";
    readonly "data-testid"?: string;
    readonly onClick?: (event: React.MouseEvent) => void;
    readonly onMouseEnter?: (event: React.MouseEvent) => void;
    readonly onMouseLeave?: (event: React.MouseEvent) => void;
    readonly onMouseDown?: (event: React.MouseEvent) => void;
    readonly onTouchStart?: (event: React.TouchEvent) => void;
    readonly onTouchEnd?: (event: React.TouchEvent) => void;
    readonly onTouchMove?: (event: React.TouchEvent) => void;

    readonly onMouseUp?: () => void;
    readonly onPointerDown?: () => void;
    readonly onPointerUp?: () => void;
    readonly onWheel?: (event: React.WheelEvent) => void;

    readonly onDrag?: (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => void;
    readonly onDragStart?: (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => void;
    readonly onDragEnd?: (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => void;
    readonly onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
    readonly onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void;
    readonly onDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void;
    readonly onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
} & (
    | {
          readonly fillParent?: boolean;
          readonly fillParentWidth?: never | false;
          readonly fillParentHeight?: never | false;
      }
    | {
          readonly fillParent?: never | false;
          readonly fillParentWidth?: boolean | undefined;
          readonly fillParentHeight?: boolean | undefined;
      }
);

// eslint-disable-next-line react/display-name
const FlexContainer = React.forwardRef(
    (
        props: Props & { direction?: "row" | "column" },
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const translate = useTranslate();
        const backgroundStyleToCss = useBackgroundStyleToCss();
        const basis =
            typeof props.basis === "undefined"
                ? undefined
                : typeof props.basis === "string"
                  ? props.basis // defined by dev
                  : props.basis >= 0 && props.basis <= 1 // percentage
                    ? props.basis * 100 + "%"
                    : props.basis + "px"; // fallback to px

        const direction = `${props.direction}${
            props.reversed ? "-reverse" : ""
        }` as React.CSSProperties["flexDirection"];
        const style: Partial<Interpolation<AppTheme>> = {
            WebkitOverflowScrolling: "touch",
            display: "flex",
            justifyContent: props.mainAxis || "flex-start",
            alignItems: props.crossAxis || "flex-start",
            width:
                props.fillParent || props.fillParentWidth
                    ? props.wrapMargin
                        ? `calc(100% + ${props.wrapMargin}px)`
                        : "100%"
                    : props.width,
            height:
                props.fillParent || props.fillParentHeight
                    ? props.wrapMargin
                        ? `calc(100% + ${props.wrapMargin}px)`
                        : "100%"
                    : props.height,
            flexDirection: direction,
            flexWrap: props.wrap,
            color: props.color ? colorAsString(props.color) : undefined,
            padding: paddingToCssValue(props.padding),
            minWidth: props.minWidth,
            maxWidth: props.maxWidth,
            minHeight: props.minHeight,
            maxHeight: props.maxHeight,
            flexBasis: basis,
            overflow: props.overflow,
            overflowX: props.overflowX,
            overflowY: props.overflowY,
            flexGrow: props.grow,
            flexShrink: props.shrink,
            cursor: props.onClick ? "pointer" : undefined,
            borderRadius: props.radius,
            ...(props.style ?? {}),
            ...(props.wrapMargin
                ? {
                      marginTop: props.wrapMargin
                          ? -props.wrapMargin
                          : undefined,
                      marginLeft: props.wrapMargin
                          ? -props.wrapMargin
                          : undefined,
                      "&>*": {
                          marginTop: props.wrapMargin
                              ? props.wrapMargin
                              : undefined,
                          marginLeft: props.wrapMargin
                              ? props.wrapMargin
                              : undefined,
                      },
                  }
                : {}),
        };

        const Container = props.animated ? motion.div : "div";
        return (
            <Container
                id={props.id}
                ref={ref}
                tabIndex={props.tabIndex}
                data-testid={props["data-testid"]}
                {...(props.animated
                    ? {
                          layout: true,
                      }
                    : {})}
                css={[
                    style,
                    props.styling,
                    props.background
                        ? backgroundStyleToCss(props.background)
                        : undefined,
                    props.tag
                        ? {
                              label: props.tag
                                  .replace(/\s/g, "_")
                                  .replace(/\./g, "_")
                                  .toLowerCase(),
                          }
                        : undefined,
                ]}
                title={translate(props.title)}
                className={props.className}
                // style={style}
                onClick={props.onClick}
                onTouchStart={props.onTouchStart}
                onTouchEnd={props.onTouchEnd}
                onTouchMove={props.onTouchMove}
                onMouseDown={props.onMouseDown}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onMouseUp={props.onMouseUp}
                onPointerDown={props.onPointerDown}
                onPointerUp={props.onPointerUp}
                onWheel={props.onWheel}
                onDrag={props.onDrag}
                onDragStart={props.onDragStart}
                onDragOver={props.onDragOver}
                onDragEnter={props.onDragEnter}
                onDragEnd={props.onDragEnd}
                onDragLeave={props.onDragLeave}
                onDrop={props.onDrop}>
                {props.children}
            </Container>
        );
    },
);

// eslint-disable-next-line react/display-name
const FlexColumn = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLDivElement>) => {
        return (
            <FlexContainer
                mainAxis="flex-start"
                crossAxis="stretch"
                {...props}
                direction="column"
                ref={ref}>
                {props.children}
            </FlexContainer>
        );
    },
);

// eslint-disable-next-line react/display-name
const FlexRow = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLDivElement>) => {
        return (
            <FlexContainer
                crossAxis="center"
                {...props}
                direction="row"
                ref={ref}>
                {props.children}
            </FlexContainer>
        );
    },
);

const FlexGrid = (
    props: {
        readonly columnWidth: number;
        readonly children: ReactNode;
        readonly padding?: Padding;
        /**
         * defaults to auto-fit
         */
        readonly autoSizeType?: "auto-fit" | "auto-fill";
    } & React.HTMLAttributes<HTMLElement>,
) => {
    const {
        columnWidth,
        children,
        padding,
        autoSizeType,
        ...htmlElementProps
    } = props;
    const styling = css`
        display: grid;
        grid-auto-flow: row;
        width: 100%;
        grid-gap: 10px;
        grid-row-gap: 1.5rem;
        grid-template-columns: repeat(
            ${autoSizeType ?? "auto-fit"},
            minmax(${columnWidth}px, 1fr)
        );
        ${padding ? `padding: ${paddingToCssValue(padding)};` : ""}
    `;

    return (
        <section css={styling} {...htmlElementProps}>
            {children}
        </section>
    );
};

export { FlexColumn, FlexContainer, FlexGrid, FlexRow };
export type { FlexAlignment };
