import { css } from "@emotion/react";

const inactiveCss = css({
    willChange: "filter",
    opacity: 0.5,
    filter: "grayscale(100%)",
    height: "min-content",
});

const inactiveCssWithoutHeightSetting = css({
    willChange: "filter",
    opacity: 0.5,
    filter: "grayscale(100%)",
});

export { inactiveCss, inactiveCssWithoutHeightSetting };
