import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalLine } from "@@/shared/dividers/horizontal-line";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Join } from "@@/shared/join";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Provider,
    deliveryMethodFaIconMap,
    deliveryMethods,
    negateTag,
    translation,
} from "@towni/common";
import { leftColumnWidth } from "../../shared/address-pickers/user-address-picker/address-picker";
import { useCart } from "../multi-carts/cart.context";
import { useDeliveryDispatch, useDeliveryState } from "./delivery-context";

type Props = {
    provider: Provider;
};

const DeliveryMethodPicker = ({ ...props }: Props) => {
    const theme = useTheme();
    const state = useDeliveryState();
    const cart = useCart();
    const { setMethod } = useDeliveryDispatch();

    const methods = deliveryMethods
        .filter(f => f.status !== "DISABLED")
        .map(m => {
            const itemsThatCannotBeHomeDelivered = cart.orderItems.find(o =>
                o.meta.tags.delivery.includes(negateTag(m.type)),
            );

            const enabled =
                !itemsThatCannotBeHomeDelivered &&
                m.status === "ENABLED" &&
                (props.provider.meta.tags.delivery.includes(m.type) ||
                    m.type === "PICK_UP"); //Extra safety as prod has pickup

            const subTitle = m.subTitle; // enabled
            // ? m.subTitle
            // : translation({
            //       sv: `(Kommer snart till ${props.provider.meta.title})`,
            //   });
            return (
                <FlexRow
                    padding={{ topBottom: 10 }}
                    key={m.type}
                    height={64}
                    shrink={0}
                    fillParentWidth
                    background={{
                        color:
                            m.type === state.selected
                                ? theme.colors.primary.main.value.withAlpha(0.1)
                                : theme.colors.default.background,
                    }}
                    color={theme.colors.default.text}
                    styling={css`
                        opacity: ${enabled ? 1 : 0.4};
                    `}
                    tag="DeliveryMethodPicker"
                    onClick={() => {
                        if (enabled) setMethod(m.type);
                    }}>
                    <FlexColumn
                        width={leftColumnWidth}
                        minWidth={leftColumnWidth}
                        shrink={0}
                        crossAxis="center">
                        <FontAwesomeIcon
                            icon={deliveryMethodFaIconMap[m.type]}
                            fixedWidth
                            style={{
                                right: 0,
                                fontSize: "1.2rem",
                            }}
                        />
                    </FlexColumn>
                    <FlexColumn grow={1} shrink={0}>
                        <TextBox
                            text={m.title}
                            weight="600"
                            size={1}
                            grow={0}
                            shrink={0}
                        />
                        <VerticalDivider XXS />
                        <TextBox
                            text={subTitle}
                            size="S"
                            weight="400"
                            color={theme.colors.default.text.value.withAlpha(
                                0.6,
                            )}
                        />
                    </FlexColumn>
                    {m.type === state.selected ? (
                        <FlexColumn shrink={0}>
                            <TextBox
                                text={translation({
                                    sv: "VALD",
                                    en: "SELECTED",
                                })}
                                weight="700"
                                size={0.7}
                                padding={{ right: 20 }}
                            />
                        </FlexColumn>
                    ) : (
                        <></>
                    )}
                </FlexRow>
            );
        });

    return <Join divider={<HorizontalLine />}>{methods}</Join>;
};

export { DeliveryMethodPicker };
