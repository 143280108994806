import { BlockContent, ProviderId } from "@towni/common";

const getBlockContentTargetUrl = (
    content: BlockContent,
    providerId: ProviderId | undefined,
    fallback = ""
) => {
    const referrerUrl = encodeURIComponent(
        window.location.pathname + window.location.search
    );
    // "provider__none" IS QUICK FIX FOR GLOBAL PAGES >>
    const referrerProviderId = `&refPID=${providerId ?? "provider__none"}`;
    switch (content.contentType) {
        case "PRODUCT":
            return `/products/${content.contentId}?ref=${referrerUrl}${referrerProviderId}`;
        case "PAGE":
            return `/pages/${content.contentId}`;
        default: {
            const url = content.url ? content.url : fallback;
            // Make url relative if it is on the same main domain
            if (url.startsWith("http")) {
                const _url = new URL(url);
                // if domain is not the same, return the full url
                if (_url.hostname !== window.location.hostname) return url;
                // when domain is the same as current url, return relative path
                return _url.pathname + _url.search;
            }
            return url;
        }
    }
};

export { getBlockContentTargetUrl };
