import * as React from "react";
import { useMountEffect } from "../use-mount-effect";

const PageVisibilityContext = React.createContext<boolean>(false);

type Props = {
    readonly children?: React.ReactNode;
};
/**
 * Keeps track of page visibility, if page is visible and active
 * on the users device or not
 * @param {*} props
 * @returns
 */
const PageVisibilityProvider = (props: Props) => {
    const [hidden, setHidden] = React.useState(false);

    useMountEffect(() => {
        const handleVisibilityChange = () => {
            setHidden(document.visibilityState === "hidden");
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    });

    return (
        <PageVisibilityContext.Provider value={hidden}>
            {props.children}
        </PageVisibilityContext.Provider>
    );
};

/**
 * Keeps track of page visibility, if page is visible and active
 * on the users device or not
 * @param {*} props
 * @returns
 */
const useIsAppHidden = () => {
    return React.useContext(PageVisibilityContext);
};

export { PageVisibilityProvider, useIsAppHidden };
