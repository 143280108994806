import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import {
    Paragraph,
    TextBox,
    paragraphLineBreakFactory,
    paragraphPriceFactory,
} from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { Order, priceFactory, translation } from "@towni/common";

type Props = {
    order: Order | undefined;
    className?: string;
};
const OrderStatusCustomerBanner = (props: Props) => {
    const { order } = props;
    const theme = useTheme();
    if (!order) return null;
    if (order.amountUnpaid === 0) {
        return null;
    }

    if (order.hasBeenCancelled || order.hasBeenRejected) {
        return (
            <FlexColumn
                crossAxis="center"
                padding={{ leftRight: 20, topBottom: 30 }}
                css={{ borderRadius: 10, minWidth: 350, lineHeight: 1.5 }}
                className={props.className}
                background={{
                    color: theme.colors.danger.light.value.withLight(0.97),
                }}>
                <TextBox
                    text={
                        order.hasBeenCancelled
                            ? translation({
                                  sv: "AVBOKAD",
                                  en: "CANCELLED",
                              })
                            : translation({
                                  sv: "NEKAD",
                                  en: "REJECTED",
                              })
                    }
                    weight="900"
                    size="M"
                    color={theme.colors.danger.dark}
                />
                <VerticalDivider />
                <Paragraph
                    css={{
                        fontSize: `${0.825}rem`,
                        color: theme.colors.danger.dark.asString,
                    }}
                    content={
                        order.hasBeenCancelled
                            ? [
                                  translation({
                                      sv: "Ordern är avbokad",
                                      en: "Ordern has been cancelled",
                                  }),
                              ]
                            : [
                                  translation({
                                      sv: "Ordern är nekad",
                                      en: "Order has been rejected ",
                                  }),
                              ]
                    }
                />
            </FlexColumn>
        );
    }

    return (
        <FlexColumn
            crossAxis="center"
            padding={{ leftRight: 20, topBottom: 30 }}
            css={{ borderRadius: 10, minWidth: 350, lineHeight: 1.5 }}
            className={props.className}
            background={{
                color: theme.colors.danger.light.value.withLight(0.97),
            }}>
            <TextBox
                text={translation({
                    sv: "OBETALD",
                    en: "UNPAID",
                })}
                weight="900"
                size="M"
                color={theme.colors.danger.dark}
            />
            <VerticalDivider />
            <Paragraph
                css={{
                    fontSize: `${0.825}rem`,
                    textAlign: "center",
                    color: theme.colors.danger.dark.asString,
                }}
                content={[
                    ...(order.amountSettled > 0
                        ? [
                              translation({
                                  sv: "Denna order är delvis betald.",
                                  en: "This order is partially paid.",
                              }),
                              paragraphLineBreakFactory(),
                              translation({
                                  sv: "Det finns ytterligare ",
                                  en: "There is an additional ",
                              }),
                              paragraphPriceFactory({
                                  price: priceFactory({
                                      amountIncludingVat: order.amountUnsettled,
                                  }),
                              }),
                              translation({
                                  sv: " att betala.",
                                  en: " to pay.",
                              }),
                              ...(order.amountPendingRefund > 0
                                  ? [
                                        paragraphLineBreakFactory(),
                                        translation({
                                            sv: "Utöver det är ",
                                            en: "In addition ",
                                        }),
                                        paragraphPriceFactory({
                                            price: priceFactory({
                                                amountIncludingVat:
                                                    order.amountPendingRefund,
                                            }),
                                        }),
                                        translation({
                                            sv: " på väg att återbetalas.",
                                            en: " is on its way to be refunded.",
                                        }),
                                    ]
                                  : []),
                          ]
                        : [
                              translation({
                                  sv: "Denna order är ännu ej betald.",
                                  en: "This order is not yet paid.",
                              }),
                              paragraphLineBreakFactory(),
                              translation({
                                  sv: "Kvar att betala: ",
                                  en: "To pay: ",
                              }),
                              paragraphPriceFactory({
                                  price: priceFactory({
                                      amountIncludingVat: order.amountUnsettled,
                                  }),
                              }),
                              ".",
                          ]),
                ]}
            />
        </FlexColumn>
    );
};
export { OrderStatusCustomerBanner };
