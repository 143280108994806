import { css, useTheme } from "@emotion/react";
import { Padding } from "@towni/common";
import * as React from "react";
import { useState } from "react";
import { paddingToCssValue } from "./padding";
import { TextBox } from "./text";

type Props = {
    options: string[];
    initialValue?: string;
    onToggle: (selected: string, nextOption: string) => void;

    padding?: Padding;
    showButtonTextAs: "CURRENT" | "NEXT";
    children?: React.ReactNode;
};

const TogglerButton = (props: Props) => {
    const theme = useTheme();
    const padding: Padding = props.padding ?? {
        topBottom: 10,
        leftRight: 20,
    };

    const [currentIndex, setCurrentIndex] = useState(() => {
        return Math.max(props.options.indexOf(props.initialValue ?? ""), 0);
    });
    const nextIndex = (currentIndex + 1) % props.options.length;

    const currentOption = props.options[currentIndex];
    const nextOption = props.options[nextIndex];

    const toggle = () => {
        setCurrentIndex(nextIndex);
        props.onToggle(
            nextOption,
            props.options[(nextIndex + 1) % props.options.length],
        );
    };

    return (
        <button
            css={css`
                background: ${theme.colors.default.asString};
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                border-radius: 100px;
                padding: ${paddingToCssValue(padding)};
                pointer-events: auto;
                cursor: pointer;
            `}
            onClick={toggle}>
            <TextBox
                text={
                    props.showButtonTextAs === "CURRENT"
                        ? currentOption
                        : nextOption
                }
                color={theme.colors.primary}
                weight={"700"}
                size={0.875}
            />
        </button>
    );
};

export { TogglerButton };
