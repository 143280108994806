import { Conditional } from "@@/shared/conditional";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { Product, Provider } from "@towni/common";
import { _ContentBlocks } from "./_content-blocks";

type Props = {
    product: Product;
    provider: Provider;
};

const _ColumnOne = (props: Props) => {
    const theme = useTheme();
    const { product } = props;
    return (
        <FlexColumn fillParentWidth tag="_column_one">
            {/* Kicker */}
            <Conditional
                when={!!product.meta.kicker}
                render={() => (
                    <TextBox
                        text={product.meta.kicker}
                        color={theme.colors.primary}
                        weight="900"
                        dataTestId="kicker"
                        size={0.8}
                        css={{
                            label: "kicker",
                        }}
                    />
                )}
            />
            <VerticalDivider XS />
            {/* Title */}
            <TextBox
                text={product.meta.title}
                size={2.2}
                weight="900"
                dataTestId="product_title"
                userSelect="text"
                css={{
                    label: "title",
                }}
            />
            <VerticalDivider S />
            {/* Subtitle */}
            <Conditional
                when={!!product.meta.subtitle}
                render={() => (
                    <>
                        <TextBox
                            text={product.meta.subtitle}
                            size={1.2}
                            dataTestId="product_subtitle"
                            color={theme.colors.black.light70}
                            weight="500"
                            userSelect="text"
                            css={{
                                label: "subtitle",
                            }}
                        />
                        <VerticalDivider M />
                        <HorizontalLine />
                        <VerticalDivider M />
                    </>
                )}
            />

            {/* Description */}
            <Conditional
                when={!!product.meta.description}
                render={() => (
                    <>
                        <TextBox
                            text={product.meta.description}
                            userSelect="text"
                            dataTestId="product_description"
                            lineClamp={{
                                lines: 5,
                                expandable: true,
                            }}
                            size={1}
                            renderMarkdown={true}
                            css={{
                                label: "description",
                            }}
                        />
                        <VerticalDivider M />
                        <HorizontalLine />
                        <VerticalDivider M />
                    </>
                )}
            />

            {/* Info block */}
            <_ContentBlocks product={product} />
        </FlexColumn>
    );
};

export { _ColumnOne };
