// Adapted from https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?file=/src/Example.tsx
import { css, SerializedStyles, useTheme } from "@emotion/react";
import {
    colorAsString,
    ImageReference,
    StorageItemImageReference,
} from "@towni/common";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { useEffect, useState } from "react";
import { Picture } from "./picture";
import { SlideShowProgress } from "./slide-show-progress";
import { slideTransition, slideVariants } from "./slide-transition";

type Props = {
    slideContents: React.ReactElement[] | undefined;
    duration?: number;
    styling?: SerializedStyles;
    hideProgress?: boolean;
};

const SlideShow = (props: Props) => {
    const theme = useTheme();
    const { styling, hideProgress } = props;
    const slides = props.slideContents || [];
    const duration = props.duration || 6000;
    const [[slideIndex, direction], setCurrentSlideIndex] = useState<
        [number, "FORWARD" | "BACKWARD"]
    >([0, "FORWARD"]);
    const wrapperStyles = css`
        width: 100%;
        height: 100%;
        ${styling || ""}
        position: relative;
        background-color: ${colorAsString(theme.colors.default.background)};
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        font-size: 25em;
        will-change: transform, opacity;
        text-shadow:
            0px 2px 40px #00000020,
            0px 2px 5px #00000030;
        overflow: hidden;
    `;

    const next = (direction: "FORWARD" | "BACKWARD" = "FORWARD") => {
        if (slides.length <= 1) return;
        const nextIndex = (slideIndex + 1) % (slides?.length || 1);
        (slides?.length || 0) > 1 &&
            setCurrentSlideIndex([nextIndex, direction]);
        return;
    };

    useEffect(() => {
        const timeout = setTimeout(next, duration);
        return () => clearTimeout(timeout);
    }, [slideIndex]);

    return (
        <section css={wrapperStyles}>
            {/* ({ item: itemIndex, props: slideStyles, key }) => { */}
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={slideIndex}
                    initial="in"
                    animate="center"
                    exit="out"
                    variants={slideVariants}
                    transition={slideTransition}
                    onClick={() => next("FORWARD")}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(_, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);
                        if (swipe < -swipeConfidenceThreshold) {
                            next("FORWARD");
                        } else if (swipe > swipeConfidenceThreshold) {
                            next("BACKWARD");
                        }
                    }}
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                    }}>
                    {slides[slideIndex]}
                </motion.div>
            </AnimatePresence>
            {!hideProgress && (
                <SlideShowProgress
                    count={slides.length}
                    index={slideIndex}
                    duration={duration}
                />
            )}
        </section>
    );
};

const imageRefsToPictureSlides = (
    imageRefs: (ImageReference | StorageItemImageReference)[] | undefined,
    minHeight = "250px",
) => {
    if (!imageRefs) return [];
    return imageRefs.map(img => {
        return (
            <Picture
                key={img._id}
                imageRef={img}
                width="100%"
                height="100%"
                minHeight={minHeight}
            />
        );
    });
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

export { imageRefsToPictureSlides, SlideShow };
