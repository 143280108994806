import { browserLogger } from "@@/settings";
import { useEnvironmentRuntimeSettings } from "@@/settings/app-settings/environment-settings-context";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { loadStripe as stripeLoader, Stripe } from "@stripe/stripe-js";
import { useCallback, useRef } from "react";

const useStripeInit = (retries = 2) => {
    const { STRIPE_PUBLIC_KEY } = useEnvironmentRuntimeSettings();
    if (!STRIPE_PUBLIC_KEY) {
        throw new Error("💰 Missing stripe public key");
    }
    const isMounted = useIsMountedRef();
    const loadStripe = useCallback(
        (depth = 0): Promise<Stripe | null> => {
            return new Promise((resolve, reject) => {
                stripeLoader(STRIPE_PUBLIC_KEY)
                    .then(resolve)
                    .catch((error: unknown) => {
                        if (!isMounted.current) return resolve(null);
                        if (depth >= retries)
                            throw new Error(
                                `Stripe failed to load, max retries reached (${retries})`,
                                { cause: error }
                            );
                        browserLogger.log(
                            `Stripe failed to load, retry number ${
                                depth + 1
                            } in ${depth + 2} seconds`,
                            error
                        );
                        setTimeout(() => {
                            if (!isMounted.current) return resolve(null);
                            loadStripe(++depth).then(resolve).catch(reject);
                        }, (depth + 2) * 1000);
                    });
            });
        },
        [STRIPE_PUBLIC_KEY, stripeLoader]
    );
    const stripeLoaderRef = useRef<Promise<Stripe | null>>(loadStripe());
    return stripeLoaderRef.current;
};

export { useStripeInit };
