import { useMe } from "@@/authentication/me/me-context";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { translation } from "@towni/common";

type Props = {
    afterSignOut?: () => void;
};

const UserModalSignOut = (props: Props) => {
    const theme = useTheme();
    const [, { signOut }] = useMe();

    return (
        <TextBox
            onClick={async () => {
                await signOut();
                props.afterSignOut?.();
            }}
            align="center"
            weight="700"
            color={theme.colors.default.text}
            padding={{ topBottom: 20 }}
            text={translation({
                sv: "Logga ut",
                en: "Sign out",
            })}
            size="ML"
        />
    );
};

export { UserModalSignOut };
