import { VerticalDivider } from "@@/shared/dividers";
import { SectionArea } from "@@/shared/section-area";
import { VoucherListHz } from "@@/store-fronts/vouchers/sections/voucher-list-hz";
import { useTheme } from "@emotion/react";

const VoucherSection = () => {
    const theme = useTheme();
    return (
        <SectionArea
            sectionAreaId="vouchers-shop-section"
            title={"Upplevelsegåvor"}
            titleTarget="/vouchers"
            subtitle="Fina, lokala gåvor lätta att ge bort. 🙂"
            titleSize="XXL"
            titleWeight="900"
            subtitleColor={theme.colors.black.light20}
            padding={{ topBottom: 0 }}>
            <VoucherListHz
                numberOfVouchers={5}
                divider={<VerticalDivider L />}
            />
        </SectionArea>
    );
};

export { VoucherSection };
