import { useLanguageContext } from "@@/settings/language-context";
import { useTranslate } from "@@/translations/use-translate";
import { Translatable } from "@towni/common";
import {
    LinkProps,
    Link as RouterLink,
    createSearchParams,
} from "react-router-dom";
import { Except } from "type-fest";

// Extends react router link with possibility of external links..
const Link = (
    props: Except<LinkProps, "title"> & {
        disabled?: boolean;
        title?: Translatable;
    },
) => {
    const translate = useTranslate();
    const { title, ...passOnProps } = props;
    const translatedTitle = translate(title);
    if (passOnProps.disabled || !passOnProps.to) {
        return (
            <a
                {...passOnProps}
                title={translatedTitle}
                style={{
                    pointerEvents: "none",
                    cursor: "pointer",
                }}
            />
        );
    }
    return typeof passOnProps.to === "string" &&
        (passOnProps.target || /^https?:\/\//.test(passOnProps.to)) ? (
        <a href={passOnProps.to} {...passOnProps} title={translatedTitle} />
    ) : (
        <RouterLink {...passOnProps} title={translatedTitle} />
    );
};

const LinkWithLanguage = (props: Except<LinkProps, "to"> & { to: string }) => {
    const { language } = useLanguageContext();
    const urlParams = new URLSearchParams(props.to.split("?")[1]);
    const searchParams = createSearchParams({
        ...Array.from(urlParams.entries()),
        ...(language !== "sv" ? { lang: language } : {}),
    });
    const targetParams = searchParams.toString();

    const target =
        props.to?.split("?")[0] +
        (targetParams.trim().length ? "?" + targetParams : "");

    return typeof target === "string" && /^https?:\/\//.test(target) ? (
        <a href={target} {...props} />
    ) : (
        <RouterLink {...props} />
    );
};

export { Link, LinkWithLanguage };
