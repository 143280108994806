import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontGridProductCard } from "@@/store-fronts/shared/store-front-grid-product-card";
import { ProductId, RemSize, unique } from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { kronocampingColors } from "../kronocamping-color-context";

const KronocampingKidsFourSection: FC = () => {
    const getProductId = useProductIdWithFallback();

    const productIds = React.useMemo(
        () => [
            // shakeAssId
            getProductId("product_IcrXh4nmO43N85d1ROrH" as ProductId),
            // magicShowId
            getProductId("product_YdSOCN_q30a8S_f2Ovl3" as ProductId),
            // skattjakt
            getProductId("product__kjSukSkvx2RtAFHTO848" as ProductId),
            // ANISKTSBURK
            getProductId("product_xwPgPmwzGXoCLj9wphYb" as ProductId),
            // PYSSEL OCH MÅLA
            getProductId("product__-3_ouabYHJr55-UFds_y" as ProductId),
            // MÖTE EN RIKTIGT RAPTOR
            getProductId("product__KyuY8dEVz3zCen7RBEWd" as ProductId),
            // DISCO BARNKLUBB
            getProductId("product__g1nAxukz55WAEIxQTCH1" as ProductId),
            //product_7mRaVhM3y19QyYP1L1LX
            getProductId("product_7mRaVhM3y19QyYP1L1LX" as ProductId),
        ],
        []
    );

    // const productIds = [ treasureHuntId, shakeAssId, magicShowId];
    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="kronocamping-kids-4-section"
            padding={{ top: 0 }}>
            <SimpleGrid
                size={0.9 as RemSize}
                padding={{ leftRight: 20, bottom: 10 }}>
                {productIds.map((id, index) => {
                    return (
                        <StoreFrontGridProductCard
                            key={hasDuplicates ? index : id}
                            productId={id}
                            kickerColor={kronocampingColors.primary}
                            showPrice
                        />
                    );
                })}
            </SimpleGrid>
        </SectionArea>
    );
};

export { KronocampingKidsFourSection };
