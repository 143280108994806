import { useTheme } from "@emotion/react";
import { colorAsString, ColorItem } from "@towni/common";
import * as React from "react";

type Props = {
    size?: React.CSSProperties["width"];
    circleColor?: ColorItem;
    checkmarkColor?: ColorItem;
    className?: string;
};

const CheckmarkCircleSvg = (props: Props) => {
    const theme = useTheme();
    const width = props.size ?? "39px";
    const height = props.size ?? "40px";
    const circleColor = colorAsString(
        props.circleColor ?? theme.colors.white.value.withAlpha(0.7)
    );
    const checkmarkColor = colorAsString(
        props.checkmarkColor ?? theme.colors.white
    );
    return (
        <div
            className={props.className}
            css={{
                width,
                height,
            }}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M37 18.354v1.656a18.342 18.342 0 01-3.484 10.768c-2.26 3.108-5.435 5.383-9.054 6.483a17.04 17.04 0 01-11.027-.377c-3.54-1.345-6.562-3.83-8.616-7.087a18.395 18.395 0 01-2.781-10.981A18.28 18.28 0 016.197 8.305c2.452-2.95 5.765-5.007 9.446-5.862a17.053 17.053 0 0110.98 1.115"
                    stroke={circleColor}
                    strokeOpacity={0.8}
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37 6.425L19.5 24.443l-5.25-5.4"
                    stroke={checkmarkColor}
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export { CheckmarkCircleSvg };
