import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    IsoAndUnixTimestamp,
    RemSize,
    asDate,
    firstOf,
    getDayOfWeekAsNumber,
    getNameOfMonth,
    isoAndUnixFactory,
    lastOf,
    repeatIndex,
} from "@towni/common";
import {
    addDays,
    compareAsc,
    isBefore,
    isSameDay,
    lastDayOfMonth,
    startOfDay,
} from "date-fns";
import { useCallback, useMemo } from "react";
import { DateSelect } from "./date";

type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

type Props = {
    hideWeekdays?: boolean;
    viewOnly?: boolean;
    year: number;
    month: MonthNumber;
    selectableDates: IsoAndUnixTimestamp[];
    selectableDatesForTimeRange: IsoAndUnixTimestamp[];
    bookedDates: IsoAndUnixTimestamp[];
    selectedDates: IsoAndUnixTimestamp[];
    //Dates before this date will be marked as passed
    cutoffDate?: IsoAndUnixTimestamp;
} & (
    | {
          viewOnly: true;
          onDateSelect?: never;
      }
    | {
          viewOnly?: false;
          onDateSelect: (date: IsoAndUnixTimestamp) => void;
      }
);

const isSelected = (date: Date, sortedDates: Date[]) => {
    if (sortedDates.length <= 0) return false;

    return date >= firstOf(sortedDates) && date <= lastOf(sortedDates);
};

const isFirst = (date: Date, sortedDates: Date[]) => {
    if (sortedDates.length <= 0) return false;
    return isSameDay(date, firstOf(sortedDates));
};

const isLast = (date: Date, sortedDates: Date[]) => {
    if (sortedDates.length <= 0) return false;

    return isSameDay(date, lastOf(sortedDates));
};

const hasActivity = (date: Date, selectableDates: IsoAndUnixTimestamp[]) => {
    const y = date.getFullYear();
    const m = date.getMonth();
    return !!selectableDates.find(
        item =>
            asDate(item).getMonth() === m &&
            asDate(item).getDate() === date.getDate() &&
            asDate(item).getFullYear() === y,
    );
};

const hasActivitySet = (date: Date, selectableDates: Set<string>) => {
    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    return selectableDates.has(dateString);
};

const DateSelectionMonth = (props: Props) => {
    const theme = useTheme();
    const {
        month,
        year,
        selectableDates,
        selectedDates,
        bookedDates,
        selectableDatesForTimeRange,
        viewOnly,
        onDateSelect,
    } = props;

    const [firstDay, lastDay] = useMemo(() => {
        const firstDay = new Date(year, month, 1);
        const lastDay = lastDayOfMonth(firstDay);
        return [firstDay, lastDay];
    }, [year, month]);

    const firstWeekday = getDayOfWeekAsNumber(isoAndUnixFactory(firstDay));

    const sortedDates = useMemo(
        () => selectedDates.map(a => asDate(a)).sort(compareAsc),
        [selectedDates],
    );

    const allDays = useMemo(() => {
        return repeatIndex(lastDay.getDate() + firstWeekday).map(index => {
            index -= firstWeekday;
            const day = addDays(firstDay, index);
            const isoDay = isoAndUnixFactory(day);
            return { day, isoDay, index };
        });
    }, [firstDay, firstWeekday, lastDay]);

    const onToggle = useCallback(
        (date: IsoAndUnixTimestamp) => {
            console.log("onToggle", date);
            if (!viewOnly) onDateSelect(date);
        },
        [viewOnly, onDateSelect],
    );

    const dates = useMemo(() => {
        const cutoffDateDay = startOfDay(
            props.cutoffDate ? asDate(props.cutoffDate) : new Date(),
        );
        //Move set calculations outside of component
        const selectableDatesSet = new Set(
            selectableDates.map(item => {
                const date = asDate(item); // Assuming asDate converts ISO string or Unix timestamp to Date object
                return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
            }),
        );

        const selectableDateForTimeRangeSet = new Set(
            selectableDatesForTimeRange.map(item => {
                const date = asDate(item); // Assuming asDate converts ISO string or Unix timestamp to Date object
                return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
            }),
        );

        const result = allDays.map(({ day, isoDay, index }) => {
            //index -= firstWeekday;
            // const day = addDays(firstDay, index);
            // const isoDay = isoAndUnixFactory(day);
            const dayNotPartOfMonth = (
                <div
                    key={`${year}${month}${index}`}
                    css={{
                        width: 10,
                        height: 10,
                        background: theme.colors.transparent.asString,
                    }}
                />
            );

            const selected = isSelected(day, sortedDates);
            const firstSelected = isFirst(day, sortedDates);
            const lastSelected = isLast(day, sortedDates);
            const isBooked = hasActivity(day, bookedDates);

            const isSelectable = hasActivitySet(
                day,
                selectableDateForTimeRangeSet,
            );

            const isPassed = isBefore(day, cutoffDateDay);

            return index < 0 ? (
                dayNotPartOfMonth
            ) : (
                <DateSelect
                    key={index.toString()}
                    status={
                        isPassed
                            ? "PASSED"
                            : selected
                              ? sortedDates.length === 1
                                  ? "SOLO_SELECTED"
                                  : firstSelected
                                    ? "FIRST_SELECTED"
                                    : lastSelected
                                      ? "LAST_SELECTED"
                                      : "SELECTED"
                              : hasActivitySet(day, selectableDatesSet)
                                ? isSelectable
                                    ? "AVAILABLE_IN_TIMERANGE"
                                    : "AVAILABLE"
                                : isBooked
                                  ? "BOOKED"
                                  : "DISABLED"
                    }
                    date={isoDay}
                    onToggle={() => {
                        onToggle(isoDay);
                    }}
                />
            );
        });
        return result;
    }, [
        selectableDates,
        selectableDatesForTimeRange,
        allDays,
        year,
        month,
        sortedDates,
        bookedDates,
        onToggle,
        props.cutoffDate,
    ]);

    if (!props.hideWeekdays) {
        // Todo! translation if available
        ["SÖN", "LÖR", "FRE", "TOR", "ONS", "TIS", "MÅN"]
            .map(dayName => (
                <FlexColumn
                    key={dayName}
                    crossAxis="flex-start"
                    fillParent
                    mainAxis="center"
                    padding={{ topBottom: 5 }}
                    css={{
                        borderTop: `1px solid ${theme.colors.black.light95.asString}`,
                        borderBottom: `1px solid ${theme.colors.black.light95.asString}`,
                    }}>
                    <FlexRow key={dayName} mainAxis="center" width={44}>
                        <TextBox
                            key={dayName}
                            text={dayName}
                            align="center"
                            size={0.5625 as RemSize}
                            color={theme.colors.black.light50}
                        />
                    </FlexRow>
                </FlexColumn>
            ))
            .forEach(item => dates.unshift(item));
    }
    return (
        <FlexColumn key={`${year}${month}`} padding={{ leftRight: 20 }}>
            <FlexRow padding={{ bottom: 3, left: 5 }}>
                <TextBox
                    size={1 as RemSize}
                    color={theme.colors.black.light15}
                    weight="800"
                    text={getNameOfMonth(isoAndUnixFactory(firstDay))}
                />
                <HorizontalDivider XS />
                <TextBox
                    weight="400"
                    size={1 as RemSize}
                    color={theme.colors.black.light15}
                    text={firstDay.getFullYear().toString()}
                />
            </FlexRow>
            <VerticalDivider XS />
            <div
                key={`${year}${props.month}`}
                translate="no" // Disable translation because it's not needed and it breaks react sometimes #3896
                css={{
                    display: "grid",
                    gridAutoFlow: "row",
                    gridRowGap: 10,
                    gridColumnGap: 0,
                    gridTemplateColumns: "repeat(7, 1fr)",
                    gridTemplateRows: "repeat(auto, 1fr)",
                    justifyItems: "center",
                    alignItems: "center",
                }}>
                {dates}
            </div>
        </FlexColumn>
    );
};

export { DateSelectionMonth };
export type { MonthNumber };
