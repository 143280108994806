import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox, TextSpan } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons";
import { translation } from "@towni/common";

const FeedbackSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    return (
        <FlexRow padding={{ leftRight: 20 }} fillParentWidth>
            <a
                href={`mailto:kontakt@towni.se`}
                css={{
                    display: "block",
                    width: "100%",
                    textDecoration: "none",
                    color: theme.colors.default.text.asString,
                    "&:hover": {
                        color: theme.colors.default.text.asString,
                        textDecoration: "none",
                    },
                }}>
                <FlexRow
                    fillParentWidth
                    padding={{ topBottom: 10 }}
                    mainAxis={isDesktop ? "center" : "flex-start"}
                    crossAxis={isDesktop ? "center" : "flex-start"}>
                    <Icon
                        icon={faExclamationCircle}
                        size={2}
                        colors={{
                            primaryColor: theme.colors.primary,
                            primaryOpacity: 1,
                            secondaryColor: theme.colors.primary,
                            secondaryOpacity: 0.2,
                        }}
                    />
                    <HorizontalDivider size="S" />
                    <TextBox
                        shrink={1}
                        css={{
                            lineHeight: "20px",
                        }}>
                        <TextSpan
                            color={theme.colors.black.light30}
                            text={translation({
                                sv: "Har du feedback på hur vi kan bli bättre? Kul! Vi läser allt! ",
                                en: "Do you have feedback on how we can get better? Tell is! We read everything! ",
                            })}
                        />
                        <TextSpan
                            text={translation({
                                sv: "Skicka »",
                                en: "Send »",
                            })}
                            weight="700"
                            color={theme.colors.primary}
                        />
                    </TextBox>
                </FlexRow>
            </a>
        </FlexRow>
    );
};

export { FeedbackSection };
