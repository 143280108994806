import { HorizontalDivider } from "@@/shared/dividers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaList } from "@@/shared/section-area/section-area-list";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { ResponsiveStoreFrontHzProductCard } from "@@/store-fronts/shared/store-front-hz-product-card";
import { useTheme } from "@emotion/react";
import { ProductId, RemSize, translation, unique } from "@towni/common";
import * as React from "react";
import { useNavenColorContext } from "../naven-color-context";

// type Props = {};
const NavenExperienceSection = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const getProductId = useProductIdWithFallback();
    const colors = useNavenColorContext();

    const productIds = React.useMemo(
        () => [
            //kväll
            getProductId("product__9LvFA2_9BDdgSAtIp895" as ProductId),
            //Vrak
            getProductId("product__DEt4UXdFsRjGAuPfysKE" as ProductId),
        ],
        [],
    );

    const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="naven-explore-exp-2"
            title={translation({
                sv: "Unika upplevelser vid Naven",
                en: "Unique experiences at Naven",
            })}
            subtitle={translation({
                sv: "Naven och vänerhavet. Västra kållandsö skärgård är unik och helt magisk att paddla i. Låt oss guida dig med gåra turer eller hyr för fritt paddlande bland skären.",
                en: "Naven and the archipelago. The western part of Kållandsö is unique and magical to paddle in. Let us guide you with guided tours or rent for free paddling among the rocks.",
            })}
            subtitleColor={theme.colors.black.light15}
            subTitleLineHeight={1.5 as RemSize}
            subtitleSize={1 as RemSize}
            padding={isDesktop ? { all: 10 } : { topBottom: 10 }}>
            <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <React.Fragment key={hasDuplicates ? index : id}>
                            <ResponsiveStoreFrontHzProductCard
                                cardSize={1.6 as RemSize}
                                productId={id}
                                actionText={
                                    index === 0 ? text.book : text.bookNow
                                }
                                aspectRatio={isDesktop ? "4:3" : "3:4"}
                                actionTextColorSet={colors.primary}
                            />
                        </React.Fragment>
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList>
        </SectionArea>
    );
};

const text = {
    book: translation({
        sv: "Se & Boka",
        en: "Book",
    }),
    bookNow: translation({
        sv: "Boka nu",
        en: "Book",
    }),
};

export { NavenExperienceSection };
