import { ProductPicker } from "@@/products/picker/product-picker";
import { useProductsForProvider } from "@@/products/state/queries/products-for-provider/use-products-for-provider";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import {
    Product,
    ProductId,
    ProviderId,
    emptyArrayOf,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { useState } from "react";
import { ZodSchema } from "zod";

type Value = ProductId[] | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly productsToPickFrom?: Product[];
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly providerId?: ProviderId;
};

const Form2ProductPickerMulti = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const provider = useProviderFromContext();
    const providerId = props.providerId || provider._id;
    if (!providerId)
        throw new Error("ProviderId is required to fetch products");

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const _disabled = props.disabled || props.spin || field.isSubmitting;
    const [__, productQuery] = useProductsForProvider(
        providerId,
        undefined,
        !!props.productsToPickFrom,
    );
    const spin =
        props.spin ||
        field.isSubmitting ||
        (!props.productsToPickFrom && productQuery.isPending);
    const [initiallySelected] = useState(
        () => new Set(field.initialValue ?? []),
    );
    const productsToPickFrom =
        props.productsToPickFrom ??
        productQuery.data ??
        emptyArrayOf<Product>();

    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
        customFieldValidationErrorMessage: translation({
            sv: "Minst en produkt krävs",
            en: "At least one product is required",
        }),
    });
    return (
        <FlexColumn
            className={props.className}
            key={`${formId}_${props.fieldId}`}
            crossAxis="stretch">
            <ProductPicker
                key={productQuery.isPending ? "pending" : "ready"}
                spin={spin}
                mode="MULTI"
                required={field.isRequired}
                products={productsToPickFrom}
                initiallySelected={initiallySelected}
                onChange={selected => {
                    field.setValue(selected);
                    validationTrigger();
                    field.setTouched(true);
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2ProductPickerMulti };
