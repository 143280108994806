import { ContentPageBlockView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view";
import { SingleContentPageBlockView } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/single-content-page-block/single-content.page-block.view";
import { FooterPageBlockView } from "@@/backoffice/for-providers/pages/blocks/footer-blocks/footer-page-block-view";
import { HeaderPageBlockView_V2 } from "@@/backoffice/for-providers/pages/blocks/poster-blocks/header-page-block/header-page-block-view";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { useTheme } from "@emotion/react";
import { assertNever, HexColor, PageBlock } from "@towni/common";
import { useMemo } from "react";
import { Header_PageBlock_View_v1 } from "./blocks/header-blocks/header-v1/header-page-block/header.page-block.view";
import { PosterPageBlockView } from "./blocks/poster-blocks/poster-page-block/poster-page-block-view";
import { SpacerPageBlockView } from "./blocks/spacer-blocks/spacer-page-block/spacer-page-block-view";
import { usePageViewContext } from "./editor/page-view-context";
import { PageBlockViewProvider } from "./page-block-view-context-factory";
import { usePageSettingsFromFormContext } from "./use-page-blocks-from-edit-context";

type Props = {
    blocksSorted: PageBlock[];
};

const PageBlocksView = (
    props: Props & {
        pageSettings?: {
            backgroundColor?: HexColor;
        };
    },
) => {
    const { pageMaxWidth } = usePageViewContext();

    const theme = useTheme();
    const { blocksSorted: blocks } = props;
    const jsxBlocks = useMemo(
        () =>
            blocks.map(block => {
                const _block: PageBlock = { ...block };
                let blockView: JSX.Element | null = null;
                switch (_block.type) {
                    case "HEADER_PAGE_BLOCK":
                        blockView = (
                            <Header_PageBlock_View_v1
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    case "POSTER_PAGE_BLOCK":
                        blockView = (
                            <PosterPageBlockView
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    case "SPACER_PAGE_BLOCK":
                        blockView = (
                            <SpacerPageBlockView
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    case "CONTENT_AUTO_PAGE_BLOCK":
                        blockView = (
                            <ContentPageBlockView
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    case "SINGLE_CONTENT_PAGE_BLOCK":
                        blockView = (
                            <SingleContentPageBlockView
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    case "FOOTER_PAGE_BLOCK": {
                        blockView = (
                            <FooterPageBlockView
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    }
                    case "HEADER_PAGE_BLOCK_V2": {
                        blockView = (
                            <HeaderPageBlockView_V2
                                key={_block._id}
                                block={_block}
                            />
                        );
                        break;
                    }
                    default:
                        assertNever(_block);
                }
                return (
                    <PageBlockViewProvider key={_block._id} block={_block}>
                        {blockView}
                    </PageBlockViewProvider>
                );
            }),
        [blocks],
    );

    return (
        <FlexColumn
            fillParentWidth
            css={{
                flex: 1,
                overflow: "hidden",
                backgroundColor:
                    props.pageSettings?.backgroundColor ??
                    theme.colors.default.background.asString,
            }}
            crossAxis="center">
            <FlexColumn
                fillParentWidth
                maxWidth={pageMaxWidth}
                background={{ color: theme.colors.default.background }}>
                <ForEach
                    itemOf={jsxBlocks}
                    getKey={(block, index) => block.key || index}>
                    {block => block}
                </ForEach>
            </FlexColumn>
        </FlexColumn>
    );
};

const PageBlocksPreview = (props: Props) => {
    const { backgroundColor } = usePageSettingsFromFormContext();
    return <PageBlocksView pageSettings={{ backgroundColor }} {...props} />;
};

export { PageBlocksPreview, PageBlocksView };
