import { Conditional } from "@@/shared/conditional";
import { FlexColumn } from "@@/shared/flex-containers";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { ImageV2 } from "@@/shared/pictures/image";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { DurationInSeconds, Product, Provider } from "@towni/common";
import { _ImageSlideShow } from "./_image_slide_show";

type Props = { product: Product; provider: Provider };
const _ProductImages = (props: Props) => {
    // const isMediumSmall = usePageBreakpoint({ when: "MS" });
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { product } = props;

    const productImages = product.meta.images;

    if (productImages.length === 0) return null;

    return (
        <div
            css={{
                width: "100%",
                minHeight: 200,
                maxHeight: !isMobile ? "60vh" : "40vh",
                position: "relative",
                label: "product_images_container",
            }}>
            <Conditional
                when={!isMobile}
                render={() => (
                    <>
                        <LayoutGrid
                            css={{
                                overflow: "hidden",
                                width: "100%",
                                height: "100%",
                                gap: 5,
                                borderRadius: !isMobile ? 10 : 0,
                                label: "product_images",
                                gridArea:
                                    !isMobile && productImages.length > 1
                                        ? `
                            "image_one image_two"
                        `
                                        : `
                            "image_one"
                        `,
                                gridTemplateColumns:
                                    !isMobile && productImages.length > 1
                                        ? "minmax(0, 2fr) minmax(0, 1fr)"
                                        : "1fr",
                            }}>
                            <Conditional when={!!productImages.length}>
                                <LayoutCell
                                    css={{
                                        overflow: "hidden",
                                        label: "image_one",
                                    }}>
                                    <ImageV2
                                        imageSource={productImages?.[0]}
                                        title={product.meta.title}
                                        fit="cover"
                                        css={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </LayoutCell>
                            </Conditional>
                            <Conditional
                                when={!isMobile && productImages.length > 1}>
                                <LayoutCell
                                    css={{
                                        overflow: "hidden",
                                        label: "image_two",
                                    }}>
                                    <FlexColumn
                                        fillParent
                                        overflow="hidden"
                                        mainAxis="stretch"
                                        crossAxis="stretch">
                                        <ImageV2
                                            imageSource={productImages?.[1]}
                                            title={product.meta.title}
                                            css={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                        <Conditional
                                            when={productImages.length > 2}>
                                            <div
                                                style={{
                                                    minHeight: 5,
                                                    maxHeight: 5,
                                                    width: "100%",
                                                }}
                                            />
                                            <ImageV2
                                                imageSource={productImages?.[2]}
                                                title={product.meta.title}
                                                css={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </Conditional>
                                    </FlexColumn>
                                </LayoutCell>
                            </Conditional>
                        </LayoutGrid>
                    </>
                )}
                else={() => (
                    <_ImageSlideShow
                        images={product?.meta.images}
                        duration={6 as DurationInSeconds}
                    />
                )}
            />
        </div>
    );
};

export { _ProductImages };
