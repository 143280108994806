import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    AspectRatio,
    BlockContent,
    PageBlock,
    addAlphaToHexColor,
    isHexColor,
} from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: [
        BlockContent,
        BlockContent,
        BlockContent,
        BlockContent,
        BlockContent,
    ];

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

type CardProps = {
    readonly imageWidth: number;
    readonly content: BlockContent;
    readonly block: PageBlock;
    readonly defaultTextShadow: string;
    readonly hideAction?: boolean;
    readonly defaultBorderRadius: number;
};

const _Card = (props: CardProps & { aspectRatio: AspectRatio }) => {
    const { imageWidth: maxWidth, block, defaultBorderRadius } = props;
    const providerId = usePageViewContext(context => context.providerId);
    const theme = useTheme();
    const translate = useTranslate();
    const textColor = block.textColor ?? theme.colors.default.text;
    const textColorWithAlpha = isHexColor(textColor)
        ? addAlphaToHexColor(textColor, 0.5)
        : textColor.withAlpha(0.5);

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <FlexColumn css={props.content.inactive ? inactiveCss : undefined}>
                <GridCardContainer aspectRatio={props.aspectRatio}>
                    <FlexColumn
                        fillParent
                        css={{
                            padding: 20,
                            borderRadius: defaultBorderRadius,
                            position: "relative",
                            overflow: "hidden",
                            label: "content-grid-card-2-small",
                        }}
                        background={{
                            fit: "COVER",
                            imageReference: props.content.poster,
                            color:
                                props.content.backgroundColor ??
                                theme.colors.default.background.asString,
                            imageSize: {
                                imageWidth: maxWidth,
                            },
                        }}>
                        <Conditional when={!props.hideAction}>
                            <FlexRow fillParentWidth mainAxis="flex-end">
                                <StoreFrontCardPill
                                    text={props.content.actionText}
                                    accentColor={
                                        "accentColor" in props.content &&
                                        props.content.accentColor
                                            ? props.content.accentColor
                                            : block.accentColor
                                    }
                                />
                            </FlexRow>
                        </Conditional>
                    </FlexColumn>
                </GridCardContainer>
                <FlexColumn padding={{ top: 8, leftRight: 2 }}>
                    <TextBox
                        text={props.content.title}
                        lineClamp={2}
                        weight="800"
                        size={1}
                        color={textColor}
                        css={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                    />
                    <Conditional when={!!props.content.subtitle}>
                        <VerticalDivider XXS />
                        <TextBox
                            text={props.content.subtitle}
                            lineClamp={2}
                            weight="400"
                            size={0.75}
                            color={textColorWithAlpha}
                            css={{
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                            }}
                        />
                    </Conditional>
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

const ContentGrid_5_Small = (props: Props) => {
    const { width } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });
    const {
        pageMaxWidth: maxWidth,
        defaultGridGap,
        defaultTextShadow,
        defaultBorderRadius,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();

    const containerMaxWidth = maxWidth * 0.85;

    return (
        <LayoutGrid
            gap={defaultGridGap}
            // padding={{ all: gridCardGap }}
            gridTemplateColumns={isMobile ? "1fr 1fr" : "1fr 1fr 1fr 1fr 1fr"}
            className={props.className}
            css={{
                maxWidth: containerMaxWidth,
                paddingLeft: 20,
                paddingRight: 20,
                width: width ?? "100%",
                label: "content_grid_5_small_mobile_2x",
            }}>
            <_Card
                aspectRatio={isMobile ? "3:4" : "1:1"}
                hideAction={isMobile}
                imageWidth={isMobile ? 250 : 500}
                content={props.content[0]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
            <_Card
                aspectRatio={isMobile ? "3:4" : "1:1"}
                hideAction={isMobile}
                imageWidth={isMobile ? 250 : 500}
                content={props.content[1]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
            <_Card
                aspectRatio={isMobile ? "3:4" : "1:1"}
                hideAction={isMobile}
                imageWidth={isMobile ? 250 : 500}
                content={props.content[2]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
            <_Card
                aspectRatio={isMobile ? "3:4" : "1:1"}
                hideAction={isMobile}
                imageWidth={isMobile ? 250 : 500}
                content={props.content[3]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
            <_Card
                aspectRatio={isMobile ? "3:4" : "1:1"}
                hideAction={isMobile}
                imageWidth={isMobile ? 250 : 500}
                content={props.content[4]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
        </LayoutGrid>
    );
};

export { ContentGrid_5_Small };
