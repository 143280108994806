import { css, useTheme } from "@emotion/react";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { colorAsString, hslaToHex } from "@towni/common";

const useDefaultCardElementOptions = () => {
    const theme = useTheme();
    const cardElementOptions: StripeCardElementOptions = {
        style: {
            base: {
                color: "#333333", // hslaToHex(theme.colors.default.text.value),
                fontFamily: theme.fontFamily,
                fontSmoothing: "antialiased",
                fontSize: "14px",
                fontWeight: "600",
                "::placeholder": {
                    color: "#cccccc", // hslaToHex(theme.colors.textInput.placeholder.value),
                },
            },
            invalid: {
                color: "#eb5656", // hslaToHex(theme.colors.danger.main.value),
                iconColor: "#eb5656", // hslaToHex(theme.colors.danger.main.value),
            },
        },
    };

    return cardElementOptions;
};

const usePaymentFieldContainerCss = () => {
    const theme = useTheme();
    const fieldContainerCss = css`
        background-color: ${colorAsString(theme.colors.textInput.background)};
        border-radius: ${theme.radius}px;
        border: 1px solid ${colorAsString(theme.colors.textInput.border)};
        padding: 14px;
    `;
    return fieldContainerCss;
};
const usePaymentFieldCss = () => {
    const theme = useTheme();
    const fieldCss = css`
        border: none;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: ${colorAsString(theme.colors.textInput.text)};

        display: flex;
        flex: 1;
        > div {
            width: 100%;
        }
        ::placeholder {
            font-family: "Lato", sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: ${hslaToHex(theme.colors.textInput.placeholder.value)};
        }
    `;
    return fieldCss;
};

const usePaymentStyles = () => {
    const fieldContainerCss = usePaymentFieldContainerCss();
    const fieldCss = usePaymentFieldCss();
    const defaultCardElementOptions = useDefaultCardElementOptions();
    return { defaultCardElementOptions, fieldCss, fieldContainerCss } as const;
};

export {
    useDefaultCardElementOptions,
    usePaymentFieldContainerCss,
    usePaymentFieldCss,
    usePaymentStyles,
};
