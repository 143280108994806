import { css, SerializedStyles, useTheme } from "@emotion/react";
import {
    AspectRatio,
    BackgroundStyle,
    Padding,
    RemSize,
    SizeName,
} from "@towni/common";
import * as React from "react";
import { FC } from "react";
import { FlexColumn } from "../flex-containers";
import { getAspectRatioHeight } from "./aspect-ratio";
import { useDimensions } from "./card-size";

type Props = {
    readonly background?: BackgroundStyle;
    readonly styling?: SerializedStyles;
    readonly children?: React.ReactNode;
    readonly aspectRatio: AspectRatio;
    /**
     * defaults to 6px
     */
    readonly radius?: number;
    readonly padding?: Padding;
    readonly onClick?: () => void;
    readonly className?: string;
};

const SectionAreaCard: FC<Props> = props => {
    const theme = useTheme();
    const aspectRatioHeight = getAspectRatioHeight(props.aspectRatio);

    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                flex-shrink: 0;
                :before {
                    content: "";
                    display: block;
                    padding-top: ${aspectRatioHeight};
                }
            `}>
            <div
                css={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                <FlexColumn
                    radius={props.radius ?? 8}
                    width={"100%"}
                    height={"100%"}
                    overflow="hidden"
                    padding={props.padding}
                    styling={props.styling}
                    tag="AREA_CARD"
                    onClick={props.onClick}
                    background={{
                        color: theme.colors.default.background,
                        ...(props.background ? props.background : {}),
                        imageSize: {
                            imageWidth:
                                props.background?.imageSize?.imageWidth ??
                                theme.sizes.imageSizes.large,
                            imageHeight: props.background?.fit
                                ? undefined
                                : props.background?.imageSize?.imageHeight,
                        },
                    }}>
                    {props.children}
                </FlexColumn>
            </div>
        </div>
    );
};

const SectionAreaSizedCard: FC<
    Props & { size: SizeName | RemSize }
> = props => {
    const { size, aspectRatio, ...passThroughProps } = props;
    const getDimensions = useDimensions();
    const dimensions = getDimensions({
        aspectRatio,
        size,
    });

    return (
        <div
            style={{
                width: dimensions.width,
                minWidth: dimensions.width,
                height: dimensions.height,
                minHeight: dimensions.height,
            }}>
            <SectionAreaCard {...passThroughProps} aspectRatio={aspectRatio} />
        </div>
    );
};

export { SectionAreaCard, SectionAreaSizedCard };
export type { Props as SectionAreaCardProps };
