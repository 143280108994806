import { PayRequestItemPriceTable } from "@@/pay-requests/pay-request-item-price-table";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { browserLogger } from "@@/settings";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { useMediaQuery } from "@@/shared/responsiveness/use-media-query";
import { TextBox } from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { useUserReference } from "@@/users/state/user-reference-fetchers";
import { ClassNames, useTheme } from "@emotion/react";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";
import {
    fullName,
    translation,
    type OrderGroup,
    type PaymentReceiptForCustomer,
    type WaivedReceiptForCustomer,
} from "@towni/common";
import { MutableRefObject, ReactInstance, useRef } from "react";
import ReactToPrint from "react-to-print";
import { ReceiptCompanyDetails } from "./receipt-company-details";

type Props = {
    orderGroup: OrderGroup;
    receipt: PaymentReceiptForCustomer | WaivedReceiptForCustomer;
    ref?: MutableRefObject<ReactInstance>;
};

/**
 * Since a regular payment receipt only can be available for
 * orders that are paid for by customer directly, we know that
 * this is the original receipt for the order group.
 * This type of payment can only be made for an order created event.
 * @param props
 * @returns
 */
const PaymentReceipt = (props: Props) => {
    const theme = useTheme();
    const mediumZoom = useMediaQuery(`(max-width: 520px)`);
    const smallZoom = useMediaQuery(`(max-width: 380px)`);
    // const isExtraSmall = usePageBreakpoint({ when: "XS" });
    // const isMobile = usePageBreakpoint({ when: "📱" });
    const { orderGroup, receipt } = props;
    const [customer, customerQuery] = useUserReference(orderGroup?.customerId);
    const [provider, _providerQuery] = useProvider(
        receipt.receiptFor.providerId,
    );
    const items = receipt.items;
    const printRef = useRef(null);

    const titleColor = theme.colors.black.light30;
    const textColor = theme.colors.black.light60;

    browserLogger.info({ receipt: receipt });

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 20px",
                zoom: mediumZoom ? (smallZoom ? 0.6 : 0.8) : 1,
            }}>
            <FlexColumn
                fillParentWidth
                ref={printRef}
                css={{
                    maxWidth: 700,
                    borderRadius: 10,
                    backgroundColor: theme.colors.white.asString,
                    overflow: "hidden",
                }}>
                {/* Header */}
                <FlexRow
                    fillParentWidth
                    crossAxis="center"
                    mainAxis="space-between"
                    css={{ opacity: 0.8 }}
                    padding={{ all: 20, bottom: 10 }}>
                    <FlexColumn>
                        <TextBox
                            text={
                                receipt.status !== "FULFILLED"
                                    ? translation({
                                          sv: "Sammanställning",
                                          en: "Summary",
                                      })
                                    : translation({
                                          sv: "Kvitto",
                                          en: "Receipt",
                                      })
                            }
                            weight="700"
                            size={1.125}
                        />
                        <VerticalDivider XXS />
                        <DateTimeTextBox
                            dateTime={receipt._createdAt}
                            userSelect="text"
                            weight="700"
                            size={0.9}
                            color={textColor}
                            forceIncludeYear
                        />
                        <Conditional when={receipt.status === "PENDING"}>
                            <VerticalDivider ML />
                            <FlexRow>
                                <TextBox
                                    text={translation({
                                        sv: "Detta är inget kvitto",
                                        en: "This is not a receipt",
                                    })}
                                    case="UPPERCASE"
                                    size={0.7}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                            <FlexRow>
                                <TextBox
                                    text={translation({
                                        sv: "Betalningen är ännu inte registrerad",
                                        en: "The payment is not yet registered",
                                    })}
                                    case="UPPERCASE"
                                    size={0.7}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                        </Conditional>
                        <Conditional when={receipt.status === "CANCELLED"}>
                            <VerticalDivider ML />
                            <FlexRow>
                                <TextBox
                                    text={translation({
                                        sv: "Detta är inget kvitto",
                                        en: "This is not a receipt",
                                    })}
                                    case="UPPERCASE"
                                    size={0.7}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                            <FlexRow>
                                <TextBox
                                    text={translation({
                                        sv: "Betalningen är inte fullföljd utan har avbrutits",
                                        en: "The payment is not completed but has been aborted",
                                    })}
                                    case="UPPERCASE"
                                    size={0.7}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                        </Conditional>
                    </FlexColumn>
                    <ButtonWhite css={{ border: "none", paddingRight: 8 }}>
                        <ClassNames>
                            {({ css }) => (
                                <ReactToPrint
                                    copyStyles={true}
                                    bodyClass={css({
                                        overflow: "visible",
                                        height: "auto",
                                        width: "100%",
                                        backgroundColor: "white",
                                        boxSizing: "border-box",
                                        justifyContent: "stretch",
                                        alignItems: "center",
                                        display: "flex",
                                        fontFamily: theme.fontFamily,
                                        fontSize: theme.sizes.base,
                                        letterSpacing: "0.01em",
                                        zoom: 1,
                                        margin: 40,
                                        userSelect: "none",
                                    })}
                                    trigger={() => (
                                        <Icon
                                            icon={faPrint}
                                            size={1.1}
                                            css={{
                                                "@media print": {
                                                    display: "none",
                                                },
                                            }}
                                        />
                                    )}
                                    content={() => printRef?.current || null}
                                />
                            )}
                        </ClassNames>
                    </ButtonWhite>
                </FlexRow>
                <FlexRow
                    fillParentWidth
                    padding={{ leftRight: 20, topBottom: 10 }}>
                    <HorizontalLine fillParentWidth />
                </FlexRow>

                {receipt.status === "PENDING" ? (
                    <FlexColumn fillParentWidth padding={{ all: 20 }}>
                        <TextBox
                            text={translation({
                                sv: "Väntar på bekräftelse att betalningen är fullföljd från betalningsleverantör...",
                                en: "Waiting for payment confirmation from payment provider...",
                            })}
                            align="center"
                            color={theme.colors.black.light60}
                        />
                        <VerticalDivider M />
                        <TextShimmer rows={3} />
                    </FlexColumn>
                ) : (
                    <FlexColumn fillParentWidth>
                        {/* <Conditional when={!!bookableOrderItems.length}>
                            <FlexColumn
                                mainAxis="space-between"
                                padding={{ leftRight: 20 }}>
                                <ReceiptBookedResourceCostRows items={items} />
                            </FlexColumn>
                        </Conditional> */}
                        <FlexColumn
                            fillParentWidth
                            css={{
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                            <PayRequestItemPriceTable
                                items={items}
                                payRequestPrice={receipt.amount}
                            />
                        </FlexColumn>
                        <VerticalDivider L />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                paddingTop: 30,
                                backgroundColor:
                                    theme.colors.primary.main.withAlpha(0.1)
                                        .asString,
                                // theme.colors.black.light95.asString,
                            }}>
                            <FlexRow fillParentWidth mainAxis="space-between">
                                <FlexColumn
                                    css={{
                                        height: "100%",
                                        paddingLeft: 20,
                                        maxWidth: "50%",
                                    }}
                                    crossAxis="flex-start"
                                    mainAxis="flex-end">
                                    <Conditional when={!!provider}>
                                        {provider ? (
                                            <ReceiptCompanyDetails
                                                provider={provider}
                                            />
                                        ) : null}
                                    </Conditional>
                                    <VerticalDivider M />
                                    <FlexColumn
                                        crossAxis="flex-start"
                                        mainAxis="center">
                                        <TextBox
                                            text={translation({
                                                sv: "Köpare",
                                                en: "Buyer",
                                            })}
                                            case="UPPERCASE"
                                            size={0.7}
                                            weight="900"
                                            userSelect="text"
                                            color={titleColor}
                                        />
                                        <VerticalDivider XXS />
                                        <TextBox
                                            text={
                                                customer
                                                    ? fullName(customer)
                                                    : ""
                                            }
                                            spin={customerQuery.isPending}
                                            weight="400"
                                            userSelect="text"
                                            size={0.8}
                                            color={textColor}
                                        />
                                    </FlexColumn>
                                    <VerticalDivider XXS />
                                    <FlexRow
                                        mainAxis="flex-start"
                                        crossAxis="center">
                                        <TextBox
                                            text={orderGroup.customerId}
                                            weight="400"
                                            userSelect="text"
                                            size={0.8}
                                            color={textColor}
                                        />
                                    </FlexRow>
                                </FlexColumn>
                                <FlexColumn
                                    css={{
                                        height: "100%",
                                        maxWidth: "50%",
                                    }}
                                    crossAxis="flex-end"
                                    mainAxis="space-between">
                                    <FlexColumn
                                        fillParentWidth
                                        crossAxis="flex-end">
                                        <TextBox
                                            text={translation({
                                                sv: "Orderdatum",
                                                en: "Order date",
                                            })}
                                            case="UPPERCASE"
                                            size={0.7}
                                            weight="900"
                                            userSelect="text"
                                            padding={{ leftRight: 20 }}
                                            color={titleColor}
                                        />
                                        <VerticalDivider XXS />
                                        <FlexRow
                                            mainAxis="flex-end"
                                            crossAxis="center"
                                            padding={{ leftRight: 20 }}>
                                            <DateTimeTextBox
                                                dateTime={orderGroup._createdAt}
                                                weight="400"
                                                userSelect="text"
                                                size={0.8}
                                                align="right"
                                                color={textColor}
                                            />
                                        </FlexRow>
                                    </FlexColumn>
                                    <VerticalDivider M />
                                    <FlexColumn
                                        fillParentWidth
                                        crossAxis="flex-end">
                                        <FlexRow
                                            mainAxis="flex-end"
                                            crossAxis="center"
                                            padding={{ leftRight: 20 }}>
                                            <TextBox
                                                text={orderGroup.slug}
                                                weight="700"
                                                userSelect="text"
                                                size={0.8}
                                                align="right"
                                                color={textColor}
                                            />
                                        </FlexRow>
                                        <VerticalDivider XXS />
                                        <FlexRow
                                            mainAxis="flex-end"
                                            crossAxis="center"
                                            padding={{ leftRight: 20 }}>
                                            <TextBox
                                                text={orderGroup.reference}
                                                weight="700"
                                                userSelect="text"
                                                size={0.8}
                                                align="right"
                                                color={textColor}
                                            />
                                        </FlexRow>
                                        <VerticalDivider XXS />
                                        <FlexRow
                                            mainAxis="flex-end"
                                            crossAxis="center"
                                            padding={{
                                                leftRight: 20,
                                            }}>
                                            <TextBox
                                                text={
                                                    receipt.receiptFor.orderId
                                                }
                                                weight="400"
                                                userSelect="text"
                                                size={0.8}
                                                align="right"
                                                color={textColor}
                                            />
                                        </FlexRow>
                                    </FlexColumn>
                                </FlexColumn>
                            </FlexRow>
                            <VerticalDivider L />
                        </FlexColumn>
                    </FlexColumn>
                )}
            </FlexColumn>
        </div>
    );
};

export { PaymentReceipt };
