import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { ForEach } from "@@/shared/for-each";
import { OptionalAmount as OptionalAmountView } from "@@/shared/options/optional-amount-group";
import { OptionalGroup as OptionalGroupView } from "@@/shared/options/optional-group";
import {
    assertNever,
    OptionalAmount,
    OptionalGroup,
    PickedOptional,
    sortBy,
    svTranslator,
} from "@towni/common";
import { useEffect, useMemo, useState } from "react";

type Props = {
    readonly initialValues?: Map<string, PickedOptional[]>;
    readonly optionals: Array<OptionalGroup | OptionalAmount>;
    readonly onChange: (picked: Map<string, PickedOptional[]>) => void;
};

const BookableDetailsOptionals = (props: Props) => {
    const { optionals } = props;
    const sortedOptionals = useMemo(() => {
        // if optionals is not defined, just return an empty array
        if (!optionals) {
            return [];
        }

        const sorted = Array.from(optionals.values()).sort(
            sortBy(o => svTranslator(o.meta.title), "ASCENDING")
        );

        // separate required and non-required optionals
        const requiredOptionals: (OptionalGroup | OptionalAmount)[] = [];
        const nonRequiredOptionals: (OptionalGroup | OptionalAmount)[] = [];
        sorted.forEach(optional => {
            if (optional.minMax.min ?? 0 > 0) {
                requiredOptionals.push(optional);
            } else {
                nonRequiredOptionals.push(optional);
            }
        });

        // concatenate required and non-required optionals
        return [...requiredOptionals, ...nonRequiredOptionals];
    }, [optionals]);

    const [pickedOptionsMap, setPickedOptionalsMap] = useState<
        Map<string, PickedOptional[]>
    >(props.initialValues ?? new Map());

    const onChange =
        (optionalGroup: OptionalGroup | OptionalAmount) =>
        (po: PickedOptional[]) => {
            const newPickedOptionals = new Map(pickedOptionsMap);
            newPickedOptionals.set(optionalGroup._id, po);
            setPickedOptionalsMap(newPickedOptionals);
        };

    useEffect(() => {
        props.onChange(pickedOptionsMap);
    }, [pickedOptionsMap]);

    return (
        <ForEach
            itemOf={sortedOptionals}
            divider={<HorizontalSectionDivider />}>
            {optional => {
                switch (optional._type) {
                    case "OPTIONAL_AMOUNT": {
                        return (
                            <OptionalAmountView
                                optionalGroup={optional}
                                onChange={onChange(optional)}
                                padding={{
                                    leftRight: 20,
                                    top: 20,
                                }}
                                optionPadding={{
                                    leftRight: 20,
                                    topBottom: 15,
                                }}
                            />
                        );
                    }
                    case "OPTIONAL_GROUP": {
                        const picked = pickedOptionsMap.get(optional._id) ?? [];

                        return (
                            <OptionalGroupView
                                key={optional._id}
                                padding={{
                                    leftRight: 20,
                                    top: 20,
                                }}
                                optionPadding={{
                                    leftRight: 20,
                                    topBottom: 15,
                                }}
                                optionalGroup={optional}
                                onChange={onChange(optional)}
                                initialPickedOptions={picked.map(
                                    p => p.optional._id
                                )}
                            />
                        );
                    }

                    default:
                        assertNever(optional);
                        throw new Error(
                            "unknown optional type; " + optional?._type
                        );
                }
            }}
        </ForEach>
    );
};

export { BookableDetailsOptionals };
