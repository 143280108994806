import { apiFetchClient } from "@@/shared/fetch-client";
import {
    BulkPurchaseGroupId,
    GetResponse,
    OrderGroupId,
    OrderId,
    ProviderId,
    Ticket,
} from "@towni/common";

const fetchTicketsForOrder = (order: OrderId | undefined) => async () => {
    if (!order) throw new Error("missing orderId id");
    const data = await apiFetchClient.get<GetResponse<Ticket>>({
        route: `/tickets/for-order/${encodeURIComponent(order)}`,
    });
    return data.items;
};

const fetchTicketsForSearch =
    (search: OrderId | OrderGroupId | string | undefined) => async () => {
        if (!search) throw new Error("missing search id");
        const data = await apiFetchClient.get<GetResponse<Ticket>>({
            route: `/tickets/for-search/${encodeURIComponent(search)}`,
        });
        return data.items;
    };
const fetchTicketsForBulkGroup =
    (search: BulkPurchaseGroupId | undefined) => async () => {
        if (!search) throw new Error("missing search id");
        const data = await apiFetchClient.get<GetResponse<Ticket>>({
            route: `/tickets/for-bulk/${encodeURIComponent(search)}`,
        });
        return data.items;
    };
const fetchTicketsForProvider =
    (
        providerId: ProviderId,
        search: OrderId | OrderGroupId | string | undefined,
        scannerId: string | undefined
    ) =>
    async () => {
        if (!search) throw new Error("missing search id");
        const data = await apiFetchClient.get<GetResponse<Ticket>>({
            route: `/tickets/for-provider/${encodeURIComponent(
                providerId
            )}/${encodeURIComponent(search)}?scannerId=${scannerId}`,
        });
        return data.items;
    };

export {
    fetchTicketsForOrder,
    fetchTicketsForSearch,
    fetchTicketsForProvider,
    fetchTicketsForBulkGroup,
};
