import { useSessionStorage } from "@@/shared/use-session-storage";
import {
    hashCode,
    LanguageCode,
    languageCodes,
    languageCodeZodSchema,
    parseSafely,
} from "@towni/common";
import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";

type State = {
    language: LanguageCode;
    availableLanguages: LanguageCode[];
};

type Actions = {
    setLanguage: (language: LanguageCode) => void;
    setAvailableLanguages: (languages: LanguageCode[]) => void;
};

type Context = State & Actions;

const languageToEditContext = createContext<Context | undefined>(undefined);
const searchParamKey = "lang-edit";
const LanguageToEditProvider = (props: {
    id: string;
    initialLanguage?: LanguageCode;
    children?: React.ReactNode;
    initiallyAvailableLanguages?: LanguageCode[];
}) => {
    // const provider = useProviderFromContext({
    //     disableThrowWhenMissingContext: true,
    // });
    const [sessionStorageValue, setSessionStorageValue] = useSessionStorage<
        LanguageCode | undefined
    >(`language-edit_${hashCode(props.id)}`, undefined);
    // Read language from URL if any query parameter is present
    const [searchParams, setSearchParams] = useSearchParams();
    const [language, setLanguage] = useState<LanguageCode>(() => {
        // Initiate language setting
        const languageFromUrl = parseSafely({
            value: searchParams.get(searchParamKey),
            schema: languageCodeZodSchema,
        });
        // if query param is present and valid, use it
        if (languageFromUrl) return languageFromUrl;
        // else if provider is given a default language as prop, use it
        if (props.initialLanguage) return props.initialLanguage;
        // does value exist in local storage?
        if (sessionStorageValue) return sessionStorageValue;
        // else if parent context exists, use it
        return "sv";
    });

    const [availableLanguages, _setAvailableLanguages] = useState<
        LanguageCode[]
    >(props?.initiallyAvailableLanguages ?? [...languageCodes]);

    const setAvailableLanguages = useCallback(
        (languages: LanguageCode[]) => {
            _setAvailableLanguages(languages);
            if (!languages.includes(language)) setLanguage(languages[0]);
        },
        [availableLanguages],
    );

    useEffect(() => {
        // When language is changed, store it session storage for a bit
        // to keep it safe from monsters and other evil things
        if (sessionStorageValue !== language) setSessionStorageValue(language);
        if (language === "sv") searchParams.delete(searchParamKey);
        else searchParams.set(searchParamKey, language);
        setSearchParams(searchParams, {
            replace: true,
        });
    }, [language]);

    const context = useMemo(() => {
        return {
            language,
            availableLanguages: [...availableLanguages].sort(),
            setLanguage,
            setAvailableLanguages,
        };
    }, [language, setLanguage, availableLanguages]);

    return (
        <languageToEditContext.Provider value={context}>
            {props.children}
        </languageToEditContext.Provider>
    );
};

const useLanguageToEditContext = () => {
    const context = React.useContext(languageToEditContext);
    if (context === undefined) {
        throw new Error(
            "useLanguageToEditContext must be used within a LanguageToEditProvider",
        );
    }
    return context;
};

export { LanguageToEditProvider, useLanguageToEditContext };
