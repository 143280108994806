import { SectionArea } from "@@/shared/section-area";
import { SimpleGrid } from "@@/shared/simple-grid";
import { OneSquareSection } from "@@/store-fronts/shared/one-square-section";
import { ProductId, translation } from "@towni/common";
import { FC } from "react";

const HistoricalMeetingsFourSections: FC = () => {
    return (
        <SectionArea
            title={"Historiska möten"}
            subtitle={
                "Blanda dina besök med djupdykningar i historia med proffs på ämnet"
            }
            sectionAreaId="vaner-museum"
            //crossAxis="center"
            padding={{ top: 0, leftRight: 40 }}>
            <SimpleGrid size={"XL"} padding={{ leftRight: 20, bottom: 10 }}>
                <OneSquareSection
                    padding={{ leftRight: 0 }}
                    productId={"product_clVXRoiqMOnNkT5QInJN" as ProductId}
                    actionText={translation({
                        sv: "Boka nu",
                    })}
                />
                <OneSquareSection
                    padding={{ leftRight: 0 }}
                    productId={"product__vIlx7sskPx3Ya7HP0avm" as ProductId}
                    actionText={translation({
                        sv: "Se & Boka",
                    })}
                />
                <OneSquareSection
                    padding={{ leftRight: 0 }}
                    productId={"product__Z9I0X7va6amyU06DlY9N" as ProductId}
                    actionText={translation({
                        sv: "Se & Boka",
                    })}
                />
                <OneSquareSection
                    padding={{ leftRight: 0 }}
                    productId={"product_-j341SVK8lJR5FaRV8da" as ProductId}
                    actionText={translation({
                        sv: "Boka nu",
                    })}
                />
            </SimpleGrid>
        </SectionArea>
    );
};

export { HistoricalMeetingsFourSections };
