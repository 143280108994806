import { apiFetchClient } from "@@/shared/fetch-client";
import { useMutation } from "@tanstack/react-query";
import {
    AccessKeyId,
    OrderGroupId,
    VerifyStripePaymentCommand,
    verifyStripePaymentCommandFactory,
} from "@towni/common";
import { useEffect } from "react";

/**
 * Data required to verify a Stripe payment.
 */
type VerifyData = {
    /**
     * The Stripe payment verification command.
     */
    data: VerifyStripePaymentCommand;
    /**
     * The access key ID to use for the request.
     */
    ak?: AccessKeyId;
};

/**
 * Posts a Stripe payment verification command to the API.
 * @param data The data required to verify the payment.
 * @returns A Promise that resolves to the verification result.
 */
const postVerifyStripePaymentCommand = (data: VerifyData) => {
    return apiFetchClient.post<VerifyStripePaymentCommand>({
        route: `/commands/verify-stripe-payment${
            data.ak ? `?ak=${data.ak}` : ""
        }`,
        body: data.data,
    });
};

//TODO VERIFY SWISH and OTHER PAYMENTS
/**
 * A hook that verifies a Stripe payment for a given order group ID and access key ID.
 * @param orderGroupId The ID of the order group to verify the payment for.
 * @param ak The access key ID to use for the request.
 */
const useVerifyStripePayment = (
    orderGroupId: OrderGroupId,
    ak: AccessKeyId | undefined,
): void => {
    const verifyStripePaymentCommandMutation = useMutation({
        mutationFn: postVerifyStripePaymentCommand,
    });

    useEffect(() => {
        verifyStripePaymentCommandMutation.mutate({
            data: verifyStripePaymentCommandFactory({
                orderGroupId,
            }),
            ak,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ak, orderGroupId]);
};

export { useVerifyStripePayment };
