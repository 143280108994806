import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation } from "@tanstack/react-query";
import {
    CreatePhoneSignInInitiateCommandParams,
    DurationInSeconds,
    PhoneSignInInitiateCommand,
    createPhoneSignInInitiateCommand,
    isApiError,
    support,
    translation,
} from "@towni/common";
import { useCaptchaContext } from "./steps/captcha.context";

const phoneSignInInitiateCommand = async (
    params: CreatePhoneSignInInitiateCommandParams & { captchaValue?: string },
) => {
    const { captchaValue, ...rest } = params;
    return await apiFetchClient.post<PhoneSignInInitiateCommand>({
        route: `/authentications/phone-sign-in/init`,
        body: createPhoneSignInInitiateCommand(rest),
        captchaValue,
    });
};

const usePhoneSignInInitiateCommand = () => {
    const wizardState = useWizardState();
    const wizardDispatch = useWizardDispatch();
    const captcha = useCaptchaContext(context => ({
        captchaFailedCount: context.failedCount,
        setErrorText: context.setErrorText,
        increaseFailedCount: context.increaseFailedCount,
        resetFailedCount: context.resetFailedCount,
    }));
    const toast = useToast();

    return useMutation<
        unknown,
        unknown,
        CreatePhoneSignInInitiateCommandParams & { captchaValue?: string }
    >({
        mutationFn: phoneSignInInitiateCommand,
        retry: false,
        onError: (error, _command, _context) => {
            if (isApiError(error)) {
                if (
                    error.errorCode === "captcha_verification_failed" ||
                    (error.errorCode === "captcha_required" &&
                        captcha.captchaFailedCount > 0)
                ) {
                    if (captcha.captchaFailedCount > 3) {
                        // Too many failures, cancel sign up/in
                        toast.danger({
                            message: translation({
                                sv: `För många misslyckade försök att bevisa att du inte är en robot, försök igen senare. För support kontakta ${support.towni.supportEmail}`,
                                en: `Too many failed attempts to prove you're not a robot, try again later. For support contact ${support.towni.supportEmail}`,
                            }),
                            timeout: 15 as DurationInSeconds,
                        });
                        wizardDispatch.cancel();
                        return;
                    } else {
                        captcha.increaseFailedCount(1);
                        captcha.setErrorText(
                            translation({
                                sv: `Felaktigt svar, försök igen.`,
                                en: `Incorrect answer, try again.`,
                            }),
                        );
                        if (
                            wizardState.index !==
                            wizardState.pages.indexOf("captcha")
                        ) {
                            wizardDispatch.goTo("captcha");
                        }
                        return;
                    }
                }
                if (error.errorCode === "captcha_required") {
                    captcha.increaseFailedCount(1);
                    if (
                        wizardState.index !==
                        wizardState.pages.indexOf("captcha")
                    ) {
                        wizardDispatch.goTo("captcha");
                    }
                    return;
                }
            }
            if (isApiError(error)) {
                toast.fromError(error);
                return;
            }
            throw error;
        },
        onSuccess: () => {
            captcha.setErrorText(undefined);
            captcha.resetFailedCount();
            wizardDispatch.goTo("verification");
            return;
        },
    });
};

export { usePhoneSignInInitiateCommand };
