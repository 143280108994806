import {
    VirtualList,
    VirtualRow,
    VirtualRowId,
    VirtualRowRenderer,
} from "@@/backoffice/shared/virtual-list";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { browserLogger } from "@@/settings/browser-logger";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text/text-box";
import { CheckBoxWithText, TextInput } from "@@/shared/text/text-input";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    faSortAlphaAsc,
    faSortAlphaDesc,
} from "@fortawesome/pro-regular-svg-icons";
import {
    Resource,
    ResourceGlobal,
    ResourceId,
    Translatable,
    generateId,
    isInventoryResource,
    isResourceAccommodation,
    isResourceGlobal,
    isResourceGroup,
    isResourceTableGroup,
    remSize,
    sortAlphabeticallyAndNumerically,
    translation,
} from "@towni/common";
import * as React from "react";
import { useState } from "react";
import { ResourcePickerRow } from "./resource-picker-row";

type Props = {
    modalId: ModalId;
    title?: Translatable;
    resources: Resource[];
    onToggle: (resourceId: ResourceId) => void;
    selected: Set<ResourceId>;
    mode: "SINGLE" | "MULTI";
};

const ResourcePickerModalV1 = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const [listId] = useState(() => generateId({ length: 6 }));
    const [textFilter, setTextFilter] = useState("");
    const [selectedFilter, setSelectedFilter] = useState(false);
    const [groupsFirst, setGroupsFirst] = useState(true);
    const [sort, setSort] = useState<"AlphaAsc" | "AlphaDesc">("AlphaAsc");
    const { title, resources, selected, onToggle, modalId } = props;
    const { hide } = useModalActions(props.modalId);
    const filteredResources = React.useMemo(() => {
        const filtered: ResourceGlobal[] = resources
            .filter(isResourceGlobal)
            .filter(resource => {
                // Filter by text value
                const titleFilterMatch =
                    !textFilter ||
                    translate(resource.meta.title)
                        .toLocaleLowerCase()
                        .includes(textFilter.toLocaleLowerCase());

                const accTypeMatch =
                    !textFilter ||
                    (isResourceAccommodation(resource) &&
                        translate(resource.accommodationType.title)
                            ?.toLocaleLowerCase()
                            .includes(textFilter.toLocaleLowerCase()));

                const textFilterMatch = titleFilterMatch || accTypeMatch;

                // Filter if selected
                const selectedFilterMatch =
                    !selectedFilter || selected.has(resource._id);

                // TODO! filter by provider names as well
                return textFilterMatch && selectedFilterMatch;
            });

        const sorted = (resources: ResourceGlobal[]) => {
            const alphabeticallySorted = resources.sort(
                sortAlphabeticallyAndNumerically(item =>
                    translate(item.meta.title),
                ),
            );
            if (sort === "AlphaDesc") {
                return alphabeticallySorted.reverse();
            }
            return alphabeticallySorted;
        };

        if (groupsFirst) {
            const groups = filtered.filter(isResourceGroup);
            const others = filtered.filter(
                r => isInventoryResource(r) || isResourceTableGroup(r),
            );
            return [...sorted(groups), ...sorted(others)];
        }

        return sorted(filtered);
    }, [
        resources,
        groupsFirst,
        textFilter,
        translate,
        selectedFilter,
        selected,
        sort,
    ]);

    const listData: VirtualRow<
        "RESOURCE_ROW",
        {
            _type: "RESOURCE_ROW";
            resource: ResourceGlobal;
        }
    >[] = React.useMemo(() => {
        const items = filteredResources.map(resource => {
            return {
                _id: `${resource._id}` as VirtualRowId,
                data: {
                    resource,
                    _type: "RESOURCE_ROW" as const,
                },
            };
        });
        return items;
    }, [filteredResources]);

    const renderRow: VirtualRowRenderer<
        "RESOURCE_ROW",
        {
            _type: "RESOURCE_ROW";
            resource: ResourceGlobal;
        }
    > = params => {
        const { item, onHeightChange, isLast } = params;

        return (
            <ResourcePickerRow
                key={item._id}
                resource={item.data.resource}
                onSizeChange={onHeightChange}
                toggle={onToggle}
                selected={selected.has(item.data.resource._id)}
                padding={{ top: 12, bottom: isLast ? 40 : 0 }}
            />
        );
    };

    const allChecked =
        filteredResources.length > 0 &&
        filteredResources.every(r => selected.has(r._id));

    return (
        <Modal modalId={modalId}>
            <FlexColumn
                fillParentWidth
                crossAxis="flex-start"
                background={{ color: theme.colors.default.background }}
                css={css`
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                `}>
                <ModalHeader
                    modalId={modalId}
                    title={
                        title ??
                        (props.mode === "MULTI"
                            ? translation({
                                  sv: "Välj resurser",
                                  en: "Select resources",
                              })
                            : translation({
                                  sv: "Välj resurs",
                                  en: "Select resource",
                              }))
                    }
                    topRightButton={
                        props.mode === "MULTI"
                            ? {
                                  _type: "TOP_RIGHT_ACTION_TEXT",
                                  text: translation({
                                      sv: "Klar",
                                      en: "Ready",
                                  }),
                                  action: () => {
                                      hide();
                                      setSelectedFilter(false);
                                      setTextFilter("");
                                  },
                              }
                            : undefined
                    }
                />
                <FlexColumn
                    fillParentWidth
                    background={{ color: theme.colors.primary.light }}
                    crossAxis="flex-start"
                    padding={{ leftRight: 20, top: 0 }}>
                    <VerticalDivider S />
                    <FlexRow fillParentWidth mainAxis="space-between">
                        <TextInput
                            type="text"
                            grow={1}
                            padding={{ leftRight: 15, topBottom: 12 }}
                            size={remSize(0.875)}
                            placeholder={translation({
                                sv: "Filtrera",
                                en: "Filter",
                            })}
                            initialValue={textFilter}
                            onChange={setTextFilter}
                            onValidationFailed={browserLogger.log}
                        />
                        <HorizontalDivider S />
                        <CheckBoxWithText
                            checked={selectedFilter}
                            text={translation({
                                sv: "Endast valda",
                                en: "Only selected",
                            })}
                            onChange={setSelectedFilter}
                        />
                    </FlexRow>
                    <VerticalDivider XS />
                    <FlexRow
                        fillParentWidth
                        mainAxis="flex-start"
                        crossAxis="stretch">
                        <FlexRow
                            fillParentWidth
                            mainAxis="flex-start"
                            crossAxis="stretch">
                            <ButtonWhite
                                padding={{
                                    all: 4,
                                }}
                                onClick={() => {
                                    setSort(current =>
                                        current === "AlphaAsc"
                                            ? "AlphaDesc"
                                            : "AlphaAsc",
                                    );
                                }}>
                                <Icon
                                    icon={
                                        sort === "AlphaAsc"
                                            ? faSortAlphaAsc
                                            : faSortAlphaDesc
                                    }
                                    fixedWidth
                                />
                            </ButtonWhite>
                            <HorizontalDivider M />
                            <CheckBoxWithText
                                disabled={filteredResources.length === 0}
                                checked={groupsFirst}
                                text={translation({
                                    sv: `Grupper först`,
                                    en: `Groups first`,
                                })}
                                onChange={() => {
                                    setGroupsFirst(current => !current);
                                }}
                            />
                            <Conditional
                                when={
                                    props.mode === "MULTI" &&
                                    resources.length > 0
                                }>
                                <HorizontalDivider M />
                                <FlexRow
                                    mainAxis="flex-start"
                                    css={{
                                        minWidth: "165px",
                                    }}>
                                    <CheckBoxWithText
                                        disabled={
                                            filteredResources.length === 0
                                        }
                                        checked={allChecked}
                                        text={
                                            allChecked
                                                ? translation({
                                                      sv: `Avmarkera alla (${filteredResources.length})`,
                                                      en: `Deselect all (${filteredResources.length})`,
                                                  })
                                                : translation({
                                                      sv: `Välj alla (${filteredResources.length})`,
                                                      en: `Select all (${filteredResources.length})`,
                                                  })
                                        }
                                        onChange={() => {
                                            if (allChecked) {
                                                filteredResources.forEach(
                                                    resource => {
                                                        onToggle(resource._id);
                                                    },
                                                );
                                            } else {
                                                filteredResources.forEach(
                                                    resource => {
                                                        if (
                                                            !selected.has(
                                                                resource._id,
                                                            )
                                                        ) {
                                                            onToggle(
                                                                resource._id,
                                                            );
                                                        }
                                                    },
                                                );
                                            }
                                        }}
                                    />
                                </FlexRow>
                            </Conditional>
                        </FlexRow>
                    </FlexRow>
                    <VerticalDivider S />
                </FlexColumn>
                <FlexRow fillParentWidth mainAxis="stretch" crossAxis="stretch">
                    <FlexColumn
                        fillParentWidth
                        grow={1}
                        shrink={1}
                        height="60vh">
                        <Conditional when={!filteredResources.length}>
                            <FlexRow
                                padding={{ all: 40 }}
                                fillParentWidth
                                mainAxis="center">
                                <TextBox
                                    text={translation({
                                        sv: "(inga resurser)",
                                        en: "(no resources)",
                                    })}
                                    align="center"
                                    size={0.875}
                                    css={{ width: "100%" }}
                                    color={theme.colors.disabled.text.value.withAlpha(
                                        0.5,
                                    )}
                                />
                            </FlexRow>
                        </Conditional>
                        <Conditional when={!!filteredResources.length}>
                            <VirtualList
                                key={`${sort}-${groupsFirst}-${textFilter}-${selectedFilter}`}
                                items={listData}
                                listId={listId}
                                rowRenderer={renderRow}
                                whenNoContent={
                                    <TextBox
                                        text={
                                            props.mode === "MULTI"
                                                ? translation({
                                                      sv: "(inga valda resurser)",
                                                      en: "(no selected resources)",
                                                  })
                                                : translation({
                                                      sv: "(ingen resurs vald)",
                                                      en: "(no resource selected)",
                                                  })
                                        }
                                        align="center"
                                        size={0.875}
                                        padding={{ all: 20 }}
                                        color={theme.colors.disabled.text.value.withAlpha(
                                            0.5,
                                        )}
                                    />
                                }
                            />
                        </Conditional>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        </Modal>
    );
};

const ResourcePickerModal = (props: Props) => {
    //ResourcePickerModalV2
    return <ResourcePickerModalV1 {...props} />;
};

export { ResourcePickerModal };
