import { DateRangePreset } from "@@/backoffice/for-providers/reports/transactions/transaction-date-presets";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { Form } from "@@/shared/form/form.zustand-store";
import {
    DateRange,
    Translatable,
    dateRangeFactory,
    dateRangeZodObject,
    endOfMonthZoned,
    isDateRange,
    startOfMonthZoned,
} from "@towni/common";
import { ReactNode } from "react";
import { z } from "zod";

const defaultFrom = () => startOfMonthZoned(new Date(), currentTimezoneName);
const defaultTo = () => endOfMonthZoned(new Date(), currentTimezoneName);

type Props = {
    readonly initialDateRange?: DateRange;
    readonly onChange: (dateRange: DateRange) => void;
    readonly presets?: DateRangePreset[];
    readonly children?: ReactNode;
    readonly label?: Translatable;
};

const DateRangePicker = (props: Props) => {
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        dateRange: dateRangeZodObject,
                    }),
                    initialState: {
                        dateRange:
                            props.initialDateRange ??
                            dateRangeFactory(defaultFrom(), defaultTo()),
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.DateRangePicker
                    fieldId="dateRange"
                    label={props.label}
                    customPresets={props.presets}
                    getter={data => data.dateRange}
                    setter={(data, newValue) => {
                        if (!isDateRange(newValue)) return;
                        data.dateRange = newValue;
                        props.onChange(newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { DateRangePicker };
