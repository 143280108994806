import { FontWeight } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { css, SerializedStyles, useTheme } from "@emotion/react";
import {
    colorAsString,
    ColorItem,
    Padding,
    RemSize,
    shallowEqualObject,
    SizeName,
    sizeNameMap,
    Translatable,
    TranslatableValueFactoryParams,
    translation,
} from "@towni/common";
import * as React from "react";
import { useRef } from "react";
import ReactMarkdown from "react-markdown";
import { ButtonWhite } from "../buttons_v2/button-gray";
import { Conditional } from "../conditional";
import { isProbablySafari } from "../ios-check";
import { paddingToCssValue } from "../padding";
import { CircleSpinner } from "../spinners/circle-spinner";
import { LinkTo } from "./link-to";

type TextProps = {
    readonly spin?: boolean;
    readonly onClick?: (
        mouseEvent: React.MouseEvent<Element, MouseEvent>,
    ) => void;
    readonly linkTo?: string;
    readonly linkTarget?: string;
    readonly color?: ColorItem;
    readonly hoverColor?: ColorItem;
    readonly weight?: FontWeight;
    readonly styling?: SerializedStyles;
    readonly className?: string;
    readonly case?: "UPPERCASE" | "LOWERCASE" | "CAPITALIZE";
} & (
    | {
          readonly children: JSX.Element[] | undefined;
          readonly text?: never;
      }
    | {
          readonly children?: never;
          readonly text:
              | Translatable
              | TranslatableValueFactoryParams
              | undefined;
      }
);

type Props = {
    readonly display?: React.CSSProperties["display"];
    readonly align?: "left" | "right" | "center";
    readonly size?: SizeName | number;
    readonly lineClamp?:
        | number
        | {
              readonly lines: number;
              readonly expandable?: boolean;
          }; // when this is set, it also sets ellipsis and display to -webkit-inline-box
    readonly weight?: FontWeight;
    readonly padding?: Padding;
    readonly backgroundColor?: ColorItem;
    readonly cursor?: React.CSSProperties["cursor"];
    readonly grow?: React.CSSProperties["flexGrow"];
    readonly basis?: React.CSSProperties["flexBasis"];
    readonly shrink?: React.CSSProperties["flexShrink"];
    readonly radius?: React.CSSProperties["borderRadius"];
    readonly styling?: SerializedStyles;
    readonly case?: "UPPERCASE" | "LOWERCASE" | "CAPITALIZE";
    readonly italic?: boolean;
    readonly maxLength?: number;
    readonly renderMarkdown?: boolean;
    readonly userSelect?: React.CSSProperties["userSelect"];
    readonly dataTestId?: string;
    readonly title?: Translatable;
    readonly disableAutoTranslate?: boolean; //Sets translate to "no" disable auto translation that breaks react
} & TextProps;

const TextBox = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const text = translate(props.text);
    const [showHidden, setShowHidden] = React.useState(false);
    const [isClamped, setIsClamped] = React.useState(false);
    const textElementRef = useRef<HTMLDivElement | null>(null);
    const lineClamp = React.useMemo(
        () =>
            typeof props.lineClamp !== "undefined"
                ? typeof props.lineClamp === "number"
                    ? { lines: props.lineClamp, expandable: false }
                    : props.lineClamp
                : undefined,
        [props.lineClamp],
    );

    const textElementRefCallback = React.useCallback(
        (node: HTMLDivElement) => {
            textElementRef.current = node;
            setIsClamped(
                !!lineClamp && !!node && node.scrollHeight > node.clientHeight,
            );
        },
        [lineClamp],
    );

    if (!text && !props.children) return null;

    const fontRemSize =
        typeof props.size === "number"
            ? (props.size as RemSize)
            : (theme.sizes.inRem[props.size || sizeNameMap.M] as RemSize);

    const forceInlineBlockForSafari =
        props.renderMarkdown && props.lineClamp && isProbablySafari
            ? "inline-block"
            : undefined;
    const styling = css({
        maxWidth: "100%",
        p: {
            margin: 0,
            marginBottom: "0.8rem",
            display: forceInlineBlockForSafari,
        },
        // whiteSpace: props.display === "block" ? "nowrap" : undefined,
        fontSize: `${fontRemSize}rem`,
        padding: props.padding ? paddingToCssValue(props.padding) : undefined,
        flexGrow: props.grow,
        flexBasis: props.basis,
        flexShrink: props.shrink || 0,
        fontWeight: props.weight,
        color: props.color ? colorAsString(props.color) : undefined,
        "&:hover": {
            color: props.hoverColor
                ? colorAsString(props.hoverColor)
                : undefined,
        },
        cursor: props.cursor || props.onClick ? "pointer" : "inherit",
        userSelect: props.userSelect,
        textAlign: props.align,
        textTransform: props.case?.toLowerCase() as
            | "uppercase"
            | "lowercase"
            | "capitalize",
        fontStyle: props.italic ? "italic" : undefined,
        ...(lineClamp
            ? lineClamp?.lines === 1
                ? {
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                  }
                : {
                      display: "-webkit-box",
                      WebkitLineClamp: showHidden ? "none" : lineClamp.lines,
                      WebkitBoxOrient: "vertical",
                      boxOrient: "vertical",
                      overflow: "hidden",
                      visibility: "visible",
                      overflowWrap: "break-word",
                  }
            : { display: props.display || "inline-block" }),
        borderRadius: props.radius,
        backgroundColor: props.backgroundColor
            ? colorAsString(props.backgroundColor)
            : undefined,
    });

    const textContent =
        typeof props.maxLength === "number" && text.length > props.maxLength - 1
            ? text.substring(0, props.maxLength - 1).trimEnd() + "…"
            : text;

    return (
        <>
            <div
                {...(props.title ? { title: translate(props.title) } : {})}
                ref={textElementRefCallback}
                css={[styling, props.styling]}
                className={props.className}
                onClick={props.onClick}
                data-testid={props.dataTestId}
                translate={props.disableAutoTranslate ? "no" : undefined}>
                <LinkTo
                    to={props.linkTo}
                    target={props.linkTarget}
                    color={props.color}>
                    {(props.spin && (
                        <CircleSpinner size={fontRemSize} color={props.color} />
                    )) ||
                        (props.children ? (
                            props.children
                        ) : props.renderMarkdown ? (
                            <ReactMarkdown>{textContent}</ReactMarkdown>
                        ) : (
                            <span>{textContent}</span>
                        ))}
                </LinkTo>
            </div>
            <Conditional
                when={isClamped && lineClamp?.expandable && !showHidden}>
                <div style={{ paddingTop: 5 }}>
                    <ButtonWhite
                        css={{ border: "none", padding: 0 }}
                        textColor={theme.colors.primary}
                        onClick={() => {
                            setShowHidden(old => !old);
                        }}>
                        <TextBox
                            size={props.size}
                            text={translation({
                                sv: "Läs mer »",
                                en: "Read more »",
                            })}
                        />
                    </ButtonWhite>
                </div>
            </Conditional>
        </>
    );
};

const isTextBoxEqual = (prev: Props, next: Props): boolean => {
    const prevKeys = Object.keys(prev) as (keyof typeof prev)[];
    const nextKeys = Object.keys(next) as (keyof typeof next)[];
    if (prevKeys.length !== nextKeys.length) return false;
    if (prevKeys.some(key => nextKeys.includes(key))) return false;
    for (const key of nextKeys) {
        if (key === "text") {
            if (next.text === null && prev.text !== null) return false;
            if (next.text !== null && prev.text === null) return false;
            if (typeof next.text !== typeof prev.text) return false;
            if (typeof next.text === "string") {
                if (next.text !== prev.text) return false;
                continue;
            }
            if (typeof prev.text === "string") return false;
            if (typeof next.text === "object") {
                if (!next.text) return false;
                if (!prev.text) return false;
                if (!shallowEqualObject(next.text, prev.text)) return false;
                continue;
            }
            if (!Object.is(next.text, prev.text)) return false;
            continue;
        }
    }
    return true;
};

const MemoizedTextBox = React.memo<Props>(TextBox, isTextBoxEqual);

export { MemoizedTextBox as TextBox };
export type { Props as TextBoxProps };
