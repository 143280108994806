import { AccommodationCart_SubTypePicker_Multi } from "@@/carts/accommodation-checkout/accommodation-cart.sub-type-picker.multi";
import { AccommodationCart_SubTypePicker_Single } from "@@/carts/accommodation-checkout/accommodation-cart.sub-type-picker.single";
import { useAccommodationCartResourcesWithSubType } from "@@/carts/accommodation-checkout/use-accommodation-sub-type-picked";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";

const AccommodationCart_SubTypePicker = () => {
    const resourcesWithPickableSubType =
        useAccommodationCartResourcesWithSubType();

    if (resourcesWithPickableSubType.length <= 0)
        return <HorizontalSectionDivider />;
    if (resourcesWithPickableSubType.length === 1)
        return <AccommodationCart_SubTypePicker_Single />;

    return <AccommodationCart_SubTypePicker_Multi />;
};

export { AccommodationCart_SubTypePicker };
