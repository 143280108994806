import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import { GetResponse, Provider, UserId } from "@towni/common";

const fetchProvidersForUser =
    (userId: UserId | undefined): QueryFunction<Provider[]> =>
    async ({ signal }): Promise<Provider[]> => {
        if (!userId) throw new Error("missing user id or slug");
        const data = await apiFetchClient.get<GetResponse<Provider>>({
            route: `/providers/for-user/${encodeURIComponent(userId)}`,
            customConfig: {
                signal,
            },
        });
        return data.items;
    };

export { fetchProvidersForUser };
