import { AppPage } from "@@/pages/app-page";
import { PageLoad } from "@@/pages/page-load";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { _BackButton } from "@@/routes/products/$productId/_bookable/generic/_back-button";
import { HistoryBackButton } from "@@/shared/buttons/back-button";
import { HeadTitle } from "@@/shared/head-title";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { VoucherDetails } from "../../../products/vouchers/voucher-details";
import { useVoucherData } from "./use-voucher-data";

const VoucherDetailsWizardPage = () => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const { isLoading, provider, voucher, optionalGroups } = useVoucherData();
    if (isLoading) {
        return <PageLoad backgroundColor={theme.colors.default.background} />;
    }

    return (
        <>
            <HeadTitle
                title={[
                    voucher?.meta.title,
                    provider?.meta.title ?? provider?.slug,
                ]}
            />
            <AppPage
                pageId={appPageIdFactory(pathname)}
                anchors={[]}
                backButton={
                    <HistoryBackButton
                        fallbackButton={<_BackButton provider={provider} />}
                    />
                }
                forgetScrollPosition={true}>
                <HeadTitle title={voucher?.meta.title} />
                <VoucherDetails
                    provider={provider}
                    product={voucher}
                    optionalGroups={optionalGroups}
                    hideLinkToShop={false}
                />
            </AppPage>
        </>
    );
};

export { VoucherDetailsWizardPage };
