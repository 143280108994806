import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { useTheme } from "@emotion/react";
import { RemSize } from "@towni/common";

// type Props = {};
const DaftoSectionTableAndBreakfest = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    // const _getProductId = useProductIdWithFallback();
    // const colors = useDaftoColorContext();

    // const productIds = React.useMemo(
    //     () => [
    //         // // bordsbokning
    //         // getProductId("product__n7n1z_oIc50G5XjkHlU9" as ProductId),
    //         // // frukost
    //         // getProductId("product__CakGsfQEt6RDfix7PFTT" as ProductId),
    //     ],
    //     []
    // );

    // const hasDuplicates = unique(productIds).length !== productIds.length;

    return (
        <SectionArea
            sectionAreaId="dafto-table-booking"
            title="Boka bord och frukost"
            subtitle="På Daftö bor, äter och leker vi som Pirater! Kom in i Packhuset vettja!"
            subtitleColor={theme.colors.black.light15}
            subTitleLineHeight={1.5 as RemSize}
            subtitleSize={1 as RemSize}
            userSelect="text"
            padding={isDesktop ? { all: 10 } : { topBottom: 10 }}>
            {/* <SectionAreaList
                scroll={true}
                contentMargin={10}
                padding={{
                    bottom: 20,
                    left: 20,
                }}>
                {productIds.map((id, index) => {
                    return (
                        <React.Fragment key={hasDuplicates ? index : id}>
                            <ResponsiveStoreFrontHzProductCard
                                cardSize={1.35 as RemSize}
                                productId={id}
                                actionText={
                                    index >= actionTexts.length
                                        ? actionTexts[0]
                                        : actionTexts[index]
                                }
                                aspectRatio={"1:1"}
                                actionTextColorSet={colors.primary}
                                hideKicker
                                hideSubtitle
                            />
                        </React.Fragment>
                    );
                })}
                <HorizontalDivider size="L" />
            </SectionAreaList> */}
        </SectionArea>
    );
};

// const text = {
//     book: translation({
//         sv: "Boka nu",
//         en: "Book",
//     }),
//     seeTime: translation({
//         sv: "Se tider",
//         en: "Book",
//     }),
// };

// const actionTexts = [text.book, text.seeTime];

export { DaftoSectionTableAndBreakfest };
