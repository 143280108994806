import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { ResourcePicker } from "@@/resources/picker/resource-picker";
import { useResourcesForProvider } from "@@/resources/resources/use-resources";
import { FlexColumn } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import {
    ProviderId,
    Resource,
    ResourceGlobal,
    ResourceId,
    emptyArrayOf,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { useMemo, useState } from "react";
import { ZodSchema } from "zod";

type Value = ResourceId[] | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly spin?: boolean;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly fieldSchema?: ZodSchema;
    readonly providerId?: ProviderId;

    // Css properties
    readonly containerCss?: Interpolation<AppTheme>;

    readonly resources?: ResourceGlobal[];

    //Resources to hide
    readonly hideResourceIds?: ResourceId[];

    /** Filter */
    readonly filter?: (resource: Resource) => boolean;
};

const Form2ResourcePickerMultiple = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const provider = useProviderFromContext();
    const providerId = props.providerId || provider._id;
    if (!providerId)
        throw new Error("ProviderId is required to fetch resources");

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: formId,
    });
    const [_initialValue] = useState(field.value);

    const [__, resourceQuery] = useResourcesForProvider(providerId);
    const spin = resourceQuery.isPending || !!props.spin;

    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
        customFieldValidationErrorMessage: translation({
            sv: "Minst en resurs krävs",
            en: "At least one resource is required",
        }),
    });

    const resourcesToPickFrom = useMemo(() => {
        const resources =
            props.resources ??
            resourceQuery.data ??
            emptyArrayOf<ResourceGlobal>();

        return resources
            .filter(resource =>
                props.hideResourceIds
                    ? !props.hideResourceIds.includes(resource._id)
                    : true,
            )
            .filter(resource => !props.filter || props.filter(resource));
    }, [props, resourceQuery.data]);

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            fillParentWidth
            css={props.containerCss}>
            <ResourcePicker
                key={resourceQuery.isPending ? "pending" : "ready"}
                title={translation({
                    sv: "Välj resurser",
                    en: "Choose resources",
                })}
                spin={spin}
                mode="MULTI"
                required={field.isRequired}
                initiallySelected={new Set(field.value)}
                resources={resourcesToPickFrom}
                onChange={newValues => {
                    field.setValue(newValues);
                    // console.log("NEW VALUE", newValues);
                    validationTrigger();
                    field.setTouched(true);
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2ResourcePickerMultiple };
