import { useTranslate } from "@@/translations/use-translate";
import { PhoneNumber, Provider } from "@towni/common";

function useProviderPublicPhoneNumber(
    provider: Provider,
    dontThrowWhenMissing?: undefined
): PhoneNumber;
function useProviderPublicPhoneNumber(
    provider: Provider,
    dontThrowWhenMissing: boolean
): PhoneNumber | undefined;
function useProviderPublicPhoneNumber(
    provider: Provider,
    dontThrowWhenMissing: boolean | undefined
): PhoneNumber | undefined {
    const translate = useTranslate();
    const publicPhone = provider?.phones.find(
        phone => phone.categoryTags?.includes("public") && phone.verified
    );
    if (!dontThrowWhenMissing) {
        if (!provider) {
            throw new Error("No provider");
        }
        if (!publicPhone?.phoneNumber.trim()) {
            throw new Error(
                "Provider has no public phone number; provider: " +
                    provider._id +
                    ", provider: " +
                    translate(provider.meta.title)
            );
        }
        if (!publicPhone.verified) {
            throw new Error(
                "Provider has no verified public phone number; provider: " +
                    provider._id +
                    ", provider: " +
                    translate(provider.meta.title)
            );
        }
    }
    return publicPhone?.phoneNumber;
}

export { useProviderPublicPhoneNumber };
