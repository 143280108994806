import { formatSEK, Price, translation } from "@towni/common";
import { useCallback } from "react";
import { useTranslate } from "./use-translate";

const usePriceFormatter = () => {
    const translate = useTranslate();

    return useCallback(
        (
            price: Price,
            amountType:
                | "AMOUNT_INCLUDING_VAT"
                | "AMOUNT_EXCLUDING_VAT"
                | "VAT_AMOUNT" = "AMOUNT_INCLUDING_VAT",
            fractions: "AUTO" | "NONE" | "TWO" = "AUTO",
        ) => {
            const prefix = translate(price.prefix);
            return formatSEK({
                prefix,
                price,
                amountType,
                fractions,
                symbol: translate(
                    translation({
                        sv: "kr",
                        en: "SEK",
                        de: "SEK",
                    }),
                ),
            });
        },
        [translate],
    );
};
export { usePriceFormatter };
