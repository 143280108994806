import { generateId } from "@towni/common";
import type { History, Update as LocationUpdate } from "history";
import * as React from "react";
import { useContext, useRef, useState } from "react";
import { TransitionType } from "../page-type";
import { HistoryReactRouter } from "./history-react-router";
import {
    AppPageId,
    PageNavigationProvider,
    usePageNavigationDispatch,
} from "./page-navigation-context";
import { towniHistory } from "./towni-history";

type HistoryUpdate = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    location: LocationUpdate["location"];
    action: LocationUpdate["action"];
    animate?: boolean;
};

// >> Quick and ugly rxjs subject replacement
const historyListeners: ((update: HistoryUpdate) => void)[] = [];
const next = (update: HistoryUpdate) => {
    historyListeners.forEach(listener => listener(update));
};
let historyCurrent: HistoryUpdate | undefined;

const historySubject = {
    listeners: historyListeners,
    next,
    current: () => historyCurrent,
};
// << Quick and ugly rxjs subject replacement

const createAppHistory = (): History => {
    const history = towniHistory;

    let animate = false;

    const rewiredHistory = {
        ...history,
        goBack: function () {
            // browserLogger.log("HISTORY BACK");
            animate = true;
            history.back();
        },
        goForward: function () {
            // browserLogger.log("HISTORY FORW");
            animate = true;
            history.forward();
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        push: function <State = unknown>(path: string, state?: State): void {
            // browserLogger.log("HISTORY PUSH");
            animate = true;
            history.push(path, state);
        } as History["push"],
        replace: function <State = unknown>(path: string, state?: State): void {
            animate = false;
            history.replace(path, state);
        } as History["replace"],
        keys: [],
    };

    rewiredHistory.listen(locationUpdate => {
        // browserLogger.log("HISTORY LISTEN", location, action, animate);
        if (!historySubject) throw new Error("history subject must exist");
        historySubject.next({
            location: locationUpdate.location,
            action: locationUpdate.action,
            animate,
        });
    });
    rewiredHistory.listen(_location => {
        setTimeout(() => {
            animate = false;
        }, 0);
    });

    return rewiredHistory;
};

const appHistory = createAppHistory();
const HistoryContext = React.createContext<readonly [History, string[]]>([
    appHistory,
    [],
]);

const HistoryRouter = ({ children }: { children: React.ReactNode }) => {
    const actions = usePageNavigationDispatch();
    const [firstKey] = useState(() => generateId({ prefix: "history_key__" }));
    const keys = useRef<string[]>([firstKey]);
    const listen = React.useCallback(
        (update: HistoryUpdate) => {
            //browserLogger.log("🏰 HISTORY UPDATE", update);
            switch (update.action) {
                case "PUSH": {
                    const key = update.location.key ?? firstKey;
                    keys.current.push(key);

                    const isNavBack = update.location.hash.includes("#goback");
                    const ignoreAnimation =
                        update.location.hash.includes("#ignoreanimation");
                    const transitionType: TransitionType =
                        (
                            update.location.state as {
                                transitionType: TransitionType;
                            }
                        )?.transitionType ?? "PAGE";

                    actions.navigateTo({
                        path: update.location.pathname,
                        pageId:
                            (update.location.state as { pageId: AppPageId })
                                ?.pageId ?? update.location.pathname,
                        direction: isNavBack ? "BACKWARD" : "FORWARD",
                        transitionType: ignoreAnimation
                            ? "NONE"
                            : transitionType,
                    });

                    // Remove #goback #ignoreanimation from url
                    if (isNavBack || ignoreAnimation) {
                        window.history.replaceState(
                            window.history.state,
                            document.title,
                            location.pathname + (location.search ?? ""),
                        );
                    }
                    break;
                }
                case "POP": {
                    const prevKey = keys.current?.[keys.current.length - 2];
                    const key = update.location.key || firstKey;

                    const isNavBack = prevKey === key;
                    const isManualNavBack = !location.hash.includes("#goback");
                    const ignoreAnimation =
                        isManualNavBack ||
                        location.hash.includes("#ignoreanimation");
                    const transitionType: TransitionType = update.animate
                        ? (
                              update.location.state as {
                                  transitionType: TransitionType;
                              }
                          )?.transitionType ?? "PAGE"
                        : "NONE";

                    // browserLogger.log("HISTORY POP", {
                    //     prevKey,
                    //     key,
                    //     keys: keys.current,
                    //     isNavBack,
                    //     ignoreAnimation,
                    //     transitionType,
                    // });

                    if (isNavBack) {
                        keys.current.pop();
                        actions.navigateBack({
                            direction: "BACKWARD",
                            transitionType,
                        });
                    } else {
                        keys.current.push(key);
                        actions.navigateBack({
                            direction: "FORWARD",
                            transitionType,
                        });
                    }

                    // Remove #goback #ignoreanimation from url
                    if (isNavBack || ignoreAnimation) {
                        window.history.replaceState(
                            window.history.state,
                            document.title,
                            location.pathname + (location.search ?? ""),
                        );
                    }
                    break;
                }
                case "REPLACE": {
                    const key = update.location.key || firstKey;
                    keys.current.splice(-1, 1, key);
                    window.history.replaceState(
                        window.history.state,
                        document.title,
                        location.pathname + (location.search ?? ""),
                    );
                }
            }
        },
        [actions],
    );

    React.useEffect(() => {
        historySubject.listeners.push(listen);
        return () => {
            const index = historySubject.listeners.findIndex(
                item => item === listen,
            );
            if (index >= 0) {
                historySubject.listeners.splice(index, 1);
            }
        };
    }, [listen]);

    const state = React.useMemo(
        () => [appHistory, keys.current] as const,
        [appHistory],
    );

    return (
        <HistoryContext.Provider value={state}>
            <HistoryReactRouter history={state[0]}>
                {children}
            </HistoryReactRouter>
        </HistoryContext.Provider>
    );
};

type Props = {
    readonly children?: React.ReactNode;
};
const NavigationProvider = (props: Props) => {
    return (
        <PageNavigationProvider>
            <HistoryRouter>{props.children}</HistoryRouter>
        </PageNavigationProvider>
    );
};

const useHistoryContext = () => useContext(HistoryContext);

export { NavigationProvider, useHistoryContext };
