import { useProducts } from "@@/products/state/queries/products/use-products";
import { isProviderHidden } from "@@/providers/is-provider-hidden";
import { useProviders } from "@@/providers/state/queries/providers/use-providers";
import { browserLogger } from "@@/settings";
import { backendHost, fetchClient } from "@@/shared/fetch-client";
import { FlexRow } from "@@/shared/flex-containers";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { VoucherCardSquare } from "@@/store-fronts/vouchers/sections/voucher-card-square";
import {
    ProductId,
    emptyArrayOf,
    emptySetOf,
    generateId,
    isAlfredItemActive,
    isVoucher,
    shuffle,
    translation,
} from "@towni/common";
import * as React from "react";

const voucherEndpoint = `${backendHost}/public/storage/towni-brand`;
let voucherPath = "/misc/voucher-ids-for-storefront.json";
if (window.runtimeSettings.APP_ENV !== "GCP_PROD") {
    voucherPath = voucherPath.replace(
        ".json",
        `-${encodeURIComponent(
            window.runtimeSettings.APP_ENV?.toLowerCase() ?? "unknown",
        )}.json`,
    );
}

const VoucherSingleSection = () => {
    const [mountId] = React.useState(() => generateId({ length: 8 }));
    const [voucherIds, setVoucherIds] =
        React.useState(emptyArrayOf<ProductId>());
    const [vouchers, voucherQuery] = useProducts(voucherIds);
    const [unavailableVoucherIds, setUnavailableVouchers] =
        React.useState(emptySetOf<ProductId>());
    const providerIds = React.useMemo(
        () => vouchers.map(voucher => voucher.providerId),
        [vouchers],
    );
    const [providers, providerQuery] = useProviders(providerIds);
    const isLoading = voucherQuery.isPending || providerQuery.isPending;

    useMountEffect(() => {
        // Fetch voucher ids from cloud storage json file
        fetchClient(voucherEndpoint)
            .get<ProductId[]>({
                route: voucherPath + "?" + mountId,
            })
            .then(voucherIds => {
                setVoucherIds(voucherIds);
            })
            .catch(error => {
                browserLogger.error(error);
            });
    });

    React.useEffect(() => {
        // Filter out unavailable vouchers
        // - If we're still loading data, wait for everything to finish
        if (isLoading) return;
        // - If there are no vouchers or providers, then there are no unavailable vouchers either
        if (!vouchers.length || !providers.length) {
            setUnavailableVouchers(emptySetOf<ProductId>());
            return;
        }

        // - Check for voucher availability
        const unavailableVouchers = vouchers.filter(voucher => {
            const provider = providers.find(
                provider => provider._id === voucher.providerId,
            );
            // -- if provider doesn't exist, then the voucher is unavailable
            if (!provider) return true;

            // -- if product is not a voucher, then the "voucher" is unavailable
            if (!isVoucher(voucher)) return true;

            // -- if product isn't active, then it's unavailable
            if (!isAlfredItemActive(voucher.status)) return true;

            // -- if provider is hidden, then the voucher is unavailable
            if (isProviderHidden(provider)) return true;

            // -- otherwise, the voucher is available
            return false;
        });

        setUnavailableVouchers(
            new Set(unavailableVouchers.map(voucher => voucher._id)),
        );
    }, [vouchers, providers, isLoading]);

    // Randomize the order of the vouchers
    const randomizedVoucherId = React.useMemo(
        () =>
            shuffle(
                voucherIds.filter(
                    voucherId => !unavailableVoucherIds.has(voucherId),
                ),
            )[0],
        [voucherIds, unavailableVoucherIds],
    );
    const voucher = React.useMemo(
        () => vouchers.find(voucher => voucher._id === randomizedVoucherId),
        [vouchers, randomizedVoucherId],
    );

    if (!randomizedVoucherId || !voucher) return null;
    if (isLoading) {
        // When we are still loading data, show a shimmer
        return (
            <FlexRow mainAxis="center" crossAxis="center" padding={{ all: 10 }}>
                <TextShimmer rows={3} />
            </FlexRow>
        );
    }
    const provider = providers.find(
        provider => provider._id === voucher?.providerId,
    );
    if (!provider) return null;
    return (
        <div css={{ padding: 20 }}>
            <VoucherCardSquare
                key={voucher._id}
                voucher={voucher}
                provider={provider}
                actionText={text.voucher}
            />
        </div>
    );
};

const text = {
    voucher: translation({
        sv: "Presentkort",
        en: "Gift card",
    }),
};

export { VoucherSingleSection };
