import { useModal } from "@@/modals/use-modal";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import {
    AccommodationDatePickerModal,
    formatAccommodationTimeRange,
} from "@@/products/accommodations/modals/accommodation-date-picker-modal";
import { AccommodationQuantityPickerModal } from "@@/products/accommodations/modals/accommodation-quantity-picker-modal";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { paddingToCssValue } from "@@/shared/padding";
import {
    NumberTextBox,
    Paragraph,
    paragraphHzSpaceFactory,
    paragraphIconFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faChevronDown, faHouse } from "@fortawesome/pro-solid-svg-icons";
import {
    Accommodation,
    Product,
    accommodationQuantityTypeMeta,
    formatBookedTime,
    isTimeRange,
    translation,
} from "@towni/common";

type PillProps = {
    product: Product;
    accommodation: Accommodation;
};

const useGuestQuantity = () => {
    const { quantities } = useBookingAccommodationContext(state => ({
        quantities: state.quantities,
        setQuantities: state.setQuantities,
    }));
    if (quantities.ADULT || quantities.CHILD)
        return quantities.ADULT + quantities.CHILD;
    return quantities.GUEST;
};

const _ComboAccommodationPill = (props: PillProps) => {
    const theme = useTheme();
    const { time } = useBookingAccommodationContext(state => ({
        time: state.timeRange,
    }));
    const total = useGuestQuantity();

    const [{ show }, modalId] = useModal("accommodation_date__");

    const [{ show: showQuantityPicker }, quantityModalId] =
        useModal("picker__");
    const timeString = isTimeRange(time)
        ? formatBookedTime({
              time: time,
              granularity: "DAY",
              currentTimezoneName: "Europe/Stockholm",
          })
        : "Välj datum";

    const borderRadius = 3000;

    const backgroundColor = theme.colors.primary.asString;
    const styles = css({
        display: "flex",
        padding: paddingToCssValue({ topBottom: 10, leftRight: 20 }),
        border: `1 px solid ${theme.colors.primary}`,
        borderRadius: borderRadius,
        background: backgroundColor,
        color: theme.colors.primary.text.asString,
        textAlign: "center",
        cursor: "pointer",

        alignItems: "center",
        flexShrink: 0,
        justifyContent: "center",
    });

    return (
        <FlexRow
            css={styles}
            onClick={() => {
                //  show();
            }}
            padding={{ topBottom: 10, leftRight: 20 }}>
            <FlexRow>
                <Paragraph
                    content={[
                        paragraphTextFactory({
                            css: { color: theme.colors.primary.text.asString },
                            text: timeString,
                            onClick: show,
                        }),

                        " | ",
                        paragraphTextFactory({
                            css: { color: theme.colors.primary.text.asString },
                            text: translation({
                                sv: `${total} gäster`,
                                en: `${total} guests`,
                            }),
                            onClick: showQuantityPicker,
                        }),
                    ]}
                />
            </FlexRow>
            <AccommodationDatePickerModal
                modalId={modalId}
                product={props.product}
                accommodation={props.accommodation}
            />
            <AccommodationQuantityPickerModal
                modalId={quantityModalId}
                product={props.product}
                accommodation={props.accommodation}
            />
        </FlexRow>
    );
};

const AccommodationDatePill = (props: PillProps) => {
    const theme = useTheme();
    const { time } = useBookingAccommodationContext(state => ({
        time: state.timeRange,
    }));

    const [{ show }, modalId] = useModal("accommodation_date__");

    const timeString = isTimeRange(time)
        ? formatAccommodationTimeRange({
              time: time,
              currentTimezoneName: "Europe/Stockholm",
          })
        : "Välj datum";

    const borderRadius = 3000;

    const backgroundColor = theme.colors.primary.asString;
    const styles = css({
        display: "flex",
        padding: paddingToCssValue({ topBottom: 10, leftRight: 20 }),
        border: `1 px solid ${theme.colors.primary}`,
        borderRadius: borderRadius,
        background: backgroundColor,
        color: theme.colors.primary.text.asString,
        textAlign: "center",
        cursor: "pointer",

        alignItems: "center",
        flexShrink: 0,
        justifyContent: "center",
    });

    return (
        <FlexRow
            css={styles}
            onClick={() => {
                show();
            }}
            padding={{ topBottom: 10, leftRight: 20 }}>
            <FlexRow>
                <Paragraph
                    content={[
                        paragraphTextFactory({
                            css: { color: theme.colors.primary.text.asString },
                            text: timeString,
                        }),
                        paragraphHzSpaceFactory(),
                        paragraphIconFactory({
                            icon: faChevronDown,
                        }),
                    ]}
                />
            </FlexRow>
            <AccommodationDatePickerModal
                modalId={modalId}
                product={props.product}
                accommodation={props.accommodation}
            />
        </FlexRow>
    );
};

const AccommodationQuantityPill = (props: PillProps) => {
    const theme = useTheme();
    const total = useGuestQuantity();
    const [{ show: show }, quantityModalId] = useModal("picker__");
    const requestedResourceQuantity = useBookingAccommodationContext(
        context => context.requestedResourceQuantity,
    );

    return (
        <FlexRow
            css={{
                display: "flex",
                padding: paddingToCssValue({ topBottom: 10, leftRight: 20 }),
                border: `1 px solid ${theme.colors.primary}`,
                borderRadius: 3000,
                background: theme.colors.primary.asString,
                color: theme.colors.primary.text.asString,
                textAlign: "center",
                cursor: "pointer",
                alignItems: "center",
                flexShrink: 0,
                justifyContent: "center",
            }}
            onClick={() => {
                show();
            }}
            padding={{ topBottom: 10, leftRight: 20 }}>
            <FlexRow
                css={{ flex: 1 }}
                mainAxis="space-between"
                crossAxis="center">
                <FlexRow>
                    <DynamicIcon
                        icon={accommodationQuantityTypeMeta.GUEST.iconName}
                        library="solid"
                        size={0.825}
                    />
                    <HorizontalDivider XXS />
                    <NumberTextBox value={total} />
                    {/* <span>,</span> */}
                    <HorizontalDivider S />
                    <Icon icon={faHouse} size={0.825} />
                    <HorizontalDivider XXS />
                    <NumberTextBox value={requestedResourceQuantity} />
                </FlexRow>
                <HorizontalDivider S />
                <Icon icon={faChevronDown} />
            </FlexRow>

            <AccommodationQuantityPickerModal
                modalId={quantityModalId}
                product={props.product}
                accommodation={props.accommodation}
            />
        </FlexRow>
    );
};

export { AccommodationDatePill, AccommodationQuantityPill, useGuestQuantity };
