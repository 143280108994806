import { ContentBlockView } from "@@/products/bookables/content-block-view";
import { ProductNotFoundError } from "@@/routes/shared/errors/product-not-found-error";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import {
    ContentBlock,
    Product,
    emptyArrayOf,
    isAccommodation,
    isBookable,
} from "@towni/common";

type Props = {
    product: Product;
};
const _ContentBlocks = (props: Props) => {
    const { product } = props;
    const sku = product.skus?.[0];
    if (!sku)
        throw new ProductNotFoundError(product._id, new Error("No skus found"));
    if (!isBookable(sku.acquire) && !isAccommodation(sku.acquire))
        throw new Error("Product is not a bookable");

    const contentBlocks =
        sku.acquire.contentBlocks ?? emptyArrayOf<ContentBlock>();

    const divider = (
        <>
            <VerticalDivider M />
            <HorizontalLine />
            <VerticalDivider M />
        </>
    );

    return (
        <ForEach
            itemOf={contentBlocks}
            getKey={block => block._id}
            divider={divider}>
            {(block, index) => (
                <FlexColumn key={block._id} fillParentWidth>
                    <ContentBlockView
                        key={block._id}
                        contentBlock={block}
                        index={index}
                    />
                </FlexColumn>
            )}
        </ForEach>
    );
};

export { _ContentBlocks };
