import { apiFetchClient } from "@@/shared/fetch-client";
import { QueryFunction } from "@tanstack/react-query";
import {
    AccessKeyId,
    GetSingleResponse,
    Page,
    PageId,
    PageIdOrSlug,
    ProviderId,
} from "@towni/common";

const fetchPageById =
    (
        pageId: PageId | undefined,
        accessKeyId?: AccessKeyId
    ): QueryFunction<Page> =>
    async ({ signal }) => {
        if (!pageId) throw new Error("missing page id");
        const searchParams = [
            ...(accessKeyId
                ? [`preview=${encodeURIComponent(accessKeyId)}`]
                : []),
        ];
        const data = await apiFetchClient.get<GetSingleResponse<Page>>({
            route: `/pages/${encodeURIComponent(pageId)}${
                searchParams.length ? `?${searchParams.join("&")}` : ""
            }`,
            customConfig: {
                signal,
            },
        });
        return data.item;
    };
const fetchPageForProvider =
    (
        providerIdOrSlug: ProviderId | string | undefined,
        pageIdOrSlug: PageIdOrSlug | undefined,
        accessKeyId?: AccessKeyId
    ): QueryFunction<Page> =>
    async ({ signal }) => {
        if (!providerIdOrSlug) throw new Error("missing page provider id");
        if (!pageIdOrSlug) throw new Error("missing page id or slug");
        const searchParams = [
            ...(accessKeyId
                ? [`preview=${encodeURIComponent(accessKeyId)}`]
                : []),
        ];
        const data = await apiFetchClient.get<GetSingleResponse<Page>>({
            route: `/pages/for-provider/${encodeURIComponent(
                providerIdOrSlug
            )}/${encodeURIComponent(pageIdOrSlug)}${
                searchParams.length ? `?${searchParams.join("&")}` : ""
            }`,
            customConfig: {
                signal,
            },
        });
        return data.item;
    };

export { fetchPageById, fetchPageForProvider };
